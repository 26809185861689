import { useEffect } from 'react'
import { ContentContainer, PageContentLayout } from 'components/layout-containers'
import { Card, message } from 'antd'

import { useFindPerson } from 'services/styx/person-search'
import ListPersons from './list-persons'
import CursorPaginationControls from 'components/cursor-pagination-controls'

const PersonSearch = () => {
    const {
        persons,
        isValidating,
        error,
        searchQuery,
        nextPage,
        resetFirstPage,
        previousPage,
        limit,
        updateLimit,
        pagination,
    } = useFindPerson()

    useEffect(() => {
        if (error) {
            message.error(error.message)
            searchQuery({})
        }
    }, [searchQuery, error])

    const onChangeLimit = (newLimit: number) => updateLimit(newLimit)

    const loading = isValidating && !error

    return (
        <PageContentLayout>
            <Card title="Search for a KYC person">
                <ContentContainer>
                    <ListPersons
                        loading={loading}
                        persons={persons}
                        onSubmit={(values) => searchQuery(values)}
                    />
                    {pagination && (
                        <CursorPaginationControls
                            isFetching={!persons}
                            pagination={pagination}
                            onChangeLimit={onChangeLimit}
                            limit={limit}
                            onNextPage={nextPage}
                            onPrevPage={previousPage}
                            onFirstPage={resetFirstPage}
                        />
                    )}
                </ContentContainer>
            </Card>
        </PageContentLayout>
    )
}

export default PersonSearch
