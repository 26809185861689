import { message } from 'antd'
import {
    getRevenueRecognitionPresignedLink,
    useRevenueRecognitionReportList,
} from 'services/cadmus/revenue-recognition'
import type { ReportLink } from 'types/cadmus-reports'
import ReportTable from '../shared/report-table'

const RevenueRecognition = () => {
    const { data } = useRevenueRecognitionReportList()

    const onDownloadFile = async (reportFileName: string, version: string) => {
        try {
            const response = await getRevenueRecognitionPresignedLink(reportFileName, version)
            const reportLink: ReportLink = await response.json()
            window.open(reportLink.presignedUrl)
        } catch (e) {
            message.error(`Failed to download: ${(e as Error).message}`)
        }
    }

    return <ReportTable data={data?.data} onDownloadFile={onDownloadFile} />
}
export default RevenueRecognition
