import React, { useState, FC } from 'react'
import { Select, message, Tooltip } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import type { SizeType } from 'antd/lib/config-provider/SizeContext'
import { googlePlacesQuery, googlePlaceDetailsQuery } from 'services/deimos/google-places'

import type { AutoCompleteResults } from 'types/google-places'
import type { DetailedAddress } from 'types/styx'

const { Option } = Select

interface Props {
    country?: string
    setAddress: (address: DetailedAddress) => void
    size?: SizeType
}

const AddressSearch: FC<React.PropsWithChildren<Props>> = ({ country, setAddress, size }) => {
    const [searchResults, setSearchResults] = useState<AutoCompleteResults>([])

    const handleSearch = async (input: string) => {
        if (input) {
            try {
                const res = await googlePlacesQuery(input, country)
                const results = await res.json()
                setSearchResults(results)
            } catch {
                message.error('Something went wrong searching for the address')
            }
        }
    }

    const handleChange = async (place_id: string) => {
        if (place_id) {
            try {
                const res = await googlePlaceDetailsQuery(place_id)
                const detailedAddress: DetailedAddress = await res.json()
                setAddress(detailedAddress)
            } catch {
                message.error('Something went wrong getting the address')
            }
        }
    }

    const showTooltip = (description: string) => description.length > 40

    return (
        <Select
            size={size}
            optionFilterProp="label"
            allowClear
            placeholder="Search for an address"
            filterOption={false}
            defaultActiveFirstOption={false}
            showSearch
            onSearch={handleSearch}
            onChange={handleChange}
            suffixIcon={<SearchOutlined />}
            style={{ width: '100%' }}
        >
            {searchResults.map(({ description, place_id }) => (
                <Option label={description} key={place_id} value={place_id}>
                    {showTooltip(description) ? (
                        <Tooltip overlay={description}>
                            <span>{description}</span>
                        </Tooltip>
                    ) : (
                        description
                    )}
                </Option>
            ))}
        </Select>
    )
}

export default AddressSearch
