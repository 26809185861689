import { Card } from 'antd'
import { CompanyRevenueData } from '../sources-of-funds/sources-of-funds'
import NarrativeContainer from '../shared/narrative'
import PersonNarrative from '../shared/person-narrative'
import { useCompanyRevenueInfo, useStyxV2Company } from 'services/styx/company'

export const SourceOfFunds = ({ companyId }: { companyId: string }) => {
    const { company } = useStyxV2Company()
    const { data: companyRevenue, mutations } = useCompanyRevenueInfo(companyId)
    return (
        <>
            <Card type="inner" style={{ marginBottom: '1rem' }}>
                <CompanyRevenueData
                    title="Revenue information"
                    companyRevenue={companyRevenue}
                    country={company?.address?.country}
                    onSubmitEdit={async (value) => {
                        await mutations.editRevenueData(value)
                    }}
                />
            </Card>
            <Card title="Source Of Wealth Narratives" style={{ marginBottom: '1rem' }}>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <NarrativeContainer
                        label="Company Source Of Wealth"
                        companyId={companyId}
                        category={'COMPANY_SOURCE_OF_WEALTH'}
                    />
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <PersonNarrative
                        label="UBO Source Of Wealth"
                        companyId={companyId}
                        category={'UBO_SOURCE_OF_WEALTH'}
                    />
                </Card.Grid>
            </Card>
        </>
    )
}

const gridStyle: React.CSSProperties = {
    width: '50%',
}
