import type { FC } from 'react'
import { Button, notification, Tooltip } from 'antd'

import type { WalletUnloadResponse } from 'bff/moons/generated/cupid-v1'
import { WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'
import { useWalletUnloads } from 'services/deimos/wallet'

export interface Props {
    walletUnload: WalletUnloadResponse
    status: WalletUnloadResponseStatus
    disabled: boolean
    validationChecks: any
    setDisableActionButtons: (value: boolean) => void
}

export const Approve: FC<React.PropsWithChildren<Props>> = ({
    walletUnload,
    status,
    disabled,
    validationChecks,
    setDisableActionButtons,
}) => {
    const {
        mutations: { updateWalletUnloadStatus },
    } = useWalletUnloads(status)
    const onClick = async () => {
        try {
            setDisableActionButtons(true)
            await updateWalletUnloadStatus(walletUnload.id, WalletUnloadResponseStatus.APPROVED)
            notification.success({
                message: 'Wallet unload successfully approved',
            })
        } catch (e) {
            notification.error({
                message: 'Failed to approve wallet unload',
            })
        } finally {
            setDisableActionButtons(false)
        }
    }

    const tooltipMessage = () => {
        if (
            validationChecks.nothingToUnload === true ||
            validationChecks.isUnloadFormValid === false
        )
            return (
                <>
                    <p>Unable to approve because:</p>
                    <ul>
                        {validationChecks.nothingToUnload && <li>Wallet is empty</li>}
                        {!validationChecks.isUnloadFormValid && <li>Form is incomplete</li>}
                    </ul>
                </>
            )

        return <p>Ready to approve</p>
    }

    return (
        <Tooltip title={tooltipMessage}>
            <Button type="link" onClick={onClick} disabled={disabled}>
                Approve
            </Button>
        </Tooltip>
    )
}
