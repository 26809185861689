import { Navigate } from 'react-router'

import ProtectedRoute from 'router/protected-route'

import CompaniesUsersSearchHeader from 'components/companies-users-search-header/companies-users-search-header'
import CustomSpendRules from 'components/custom-spend-rules/custom-spend-rules'
import ViewExpense from 'components/view-expense/view-expense'

import CardOrders from './card-orders/card-orders'
import Company from './company/company'
import Entitlements from './entitlements/entitlements'
import Partner from './partner/partner'
import Search from './search/search'
import Menu from './sidebar-menu/sidebar-menu'
import Transactions from './transactions/transactions'
import User from './user/user'

import { Alert } from 'antd'
import { FallbackForbidden } from 'components/fallback-forbidden'
import Page from 'pages/page-layout'
import { Route } from 'react-router-dom'
import { useUsers } from 'services/auth/users'
import { useSearchPartners } from 'services/calypso/partner'
import { useSearchCompanies } from 'services/deimos/companies'
import { BillInvoices } from './bill-invoices/bill-invoices'
import { Cashback } from './cashback/screens/cashback'
import Company360 from './company-360/routes'
import Employee from './employee/routes'
import EmployeePage from './employeeOrphan/employee'
import Exports from './exports/routes'
import InvitePage from './invites/invite'
import LoginEventsTab from './login-events/routes'
import Offboarding from './offboarding/companies-offboarding-list'
import OffboardingOverview from './offboarding/company-offboarding-overview'
import { CompanyReviewStructurePage } from './review-structure/routes'
import { useStatusAlert } from './utils'
import CreditPage from './credit/screens'
import { PermissionTag } from 'components/permissions/permission-tag'

const DEFAULT_ROUTE = 'company-360'

const CustomerSuccessPage = () => {
    const { partners, setQuery: setPartnerQuery } = useSearchPartners()
    const { companies, setQuery: setCompanyQuery } = useSearchCompanies()
    const { users, setUsersQuery } = useUsers()
    const { isShowingAlert, statusDetails } = useStatusAlert()

    return (
        <>
            {isShowingAlert && statusDetails && (
                <Alert description={statusDetails.description} type={statusDetails.type} closable />
            )}

            <Page
                sideBarLabel="Customer Success"
                sideBar={
                    <Route element={<ProtectedRoute />}>
                        <Route path=":id/*" element={<Menu />} />
                    </Route>
                }
                header={
                    <Route element={<ProtectedRoute />}>
                        <Route
                            path=":id/*"
                            element={
                                <CompaniesUsersSearchHeader
                                    companies={companies}
                                    onCompanySearch={setCompanyQuery}
                                    onPartnerSearch={setPartnerQuery}
                                    onUserSearch={setUsersQuery}
                                    partners={partners}
                                    users={users}
                                />
                            }
                        />
                    </Route>
                }
            >
                <>
                    <Route element={<ProtectedRoute />}>
                        <Route path="*" element={<Navigate to={DEFAULT_ROUTE} />} />
                        <Route
                            path="search/*"
                            element={
                                <Search companies={companies} partners={partners} users={users} />
                            }
                        />
                        <Route path="invites/:id" element={<InvitePage />} />
                        <Route path="employees/:id" element={<EmployeePage />} />
                        <Route
                            element={
                                <ProtectedRoute
                                    requiredPermissions={['credit-ops']}
                                    fallback={<PermissionTag permission="credit-ops" />}
                                />
                            }
                        >
                            <Route path="credit/*" element={<CreditPage />} />
                        </Route>
                        <Route path="companies/:id/*" element={<Company />} />
                        <Route path="partners/:id/*" element={<Partner />} />
                        <Route path="users/:id/*" element={<User />} />
                        <Route path="card-orders" element={<CardOrders />} />
                        <Route path="company-360/*" element={<Company360 />} />
                        <Route path="entitlements/*" element={<Entitlements />} />
                        <Route path="view-expense" element={<ViewExpense />} />
                        <Route path="transactions" element={<Transactions />} />
                        <Route path="exports/*" element={<Exports />} />
                        <Route path="invoices" element={<BillInvoices />} />
                        <Route path="invoices/company/:companyId/*" element={<BillInvoices />} />
                        <Route path="invoices/:invoiceId/*" element={<BillInvoices />} />
                        <Route path="invoices/supplier/:supplierId/*" element={<BillInvoices />} />
                        <Route path="custom-spend-rules" element={<CustomSpendRules />} />
                        <Route path="review-structure/*" element={<CompanyReviewStructurePage />} />
                    </Route>
                    <Route
                        element={
                            <ProtectedRoute
                                requiredPermissions={['login-events']}
                                fallback={<FallbackForbidden goBackLink="/customer-success" />}
                            />
                        }
                    >
                        <Route path="login-events" element={<LoginEventsTab />} />
                    </Route>
                    <Route
                        element={
                            <ProtectedRoute
                                requiredPermissions={['hydra']}
                                fallback={<FallbackForbidden goBackLink="/customer-success" />}
                            />
                        }
                    >
                        <Route path="cashback/:companyId?" element={<Cashback />} />
                    </Route>
                    <Route
                        element={
                            <ProtectedRoute
                                requiredPermissions={['offboarding-tools']}
                                fallback={<FallbackForbidden goBackLink="/customer-success" />}
                            />
                        }
                    >
                        <Route path="offboarding" element={<Offboarding />} />
                        <Route path="offboarding/:id" element={<OffboardingOverview />} />
                    </Route>
                    <Route
                        element={
                            <ProtectedRoute
                                requiredPermissions={['employee-360']}
                                fallback={<FallbackForbidden goBackLink="/customer-success" />}
                            />
                        }
                    >
                        <Route path="employee-360/*" element={<Employee />} />
                    </Route>
                </>
            </Page>
        </>
    )
}

export default CustomerSuccessPage
