import { notification } from 'antd'
import React, { FC, useState } from 'react'
import * as api from 'services/deimos/transactions'
import HideExpense, { UpdateVisibility } from './hide-expense'

const HideExpenseContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const [fetching, setFetching] = useState(false)

    const updateVisibility = async ({ expenseId, hidden }: UpdateVisibility) => {
        setFetching(true)
        try {
            await api.updateExpenseVisibility(expenseId, hidden)
            notification.success({
                message: `Expense visibility successfully changed to ${
                    hidden ? 'hidden' : 'shown'
                }.`,
            })
        } catch (e) {
            notification.error({
                message: `Could not update expense visibility.`,
                description: (e as Error).message,
            })
        } finally {
            setFetching(false)
        }
    }

    return <HideExpense fetching={fetching} updateVisibility={updateVisibility} />
}

export default HideExpenseContainer
