import type { FC, MouseEventHandler } from 'react'
import { Button, Card, message, Modal, notification, Space } from 'antd'

import {
    secondKycWarningEmail,
    sendKycInformationRequest,
    useStyxV2Company,
} from 'services/styx/company'

interface Props {
    confirmSendRequest: (recurring?: boolean) => MouseEventHandler<HTMLElement>
    confirmSecondWarning: () => void
    companyId: string
}

export const RequestInformation: FC<React.PropsWithChildren<Props>> = ({
    confirmSendRequest,
    confirmSecondWarning,
}) => {
    return (
        <Card title="Request Information from Company">
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <Button
                    block
                    onClick={confirmSendRequest()}
                    data-testid="request-information-button"
                >
                    Request Information
                </Button>
                <Button
                    block
                    onClick={confirmSendRequest(true)}
                    data-testid="request-updated-button"
                >
                    Request Updated Information
                </Button>
                <Button
                    block
                    onClick={() => confirmSecondWarning()}
                    data-testid="request-second-warning-button"
                >
                    2nd warning email
                </Button>
            </Space>
        </Card>
    )
}

const RequestInformationContainer: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    const { company } = useStyxV2Company()
    const companyName = company?.legalName

    const onRequestInformation = async (recurring?: boolean) => {
        try {
            await sendKycInformationRequest(companyId, recurring)
            message.success(`Successfully sent a mail to admin of ${companyName}`)
        } catch (e) {
            notification.error({
                message: 'Failed to send email',
                description: (e as Error).message,
            })
        }
    }

    const onRequestInformationSecondWarning = async () => {
        try {
            await secondKycWarningEmail(companyId)
            message.success(`Successfully sent a mail to admin of ${companyName}`)
        } catch (e) {
            notification.error({
                message: 'Failed to send email',
                description: (e as Error).message,
            })
        }
    }

    return (
        <RequestInformation
            companyId={companyId}
            confirmSendRequest={(recurring) => () =>
                Modal.confirm({
                    onOk: () => onRequestInformation(recurring),
                    centered: true,
                    title: 'Request information',
                    content: (
                        <>
                            <p>
                                This will send an e-mail to the admin of {companyName} asking them
                                to provide extra information.
                            </p>
                            <p>Proceed?</p>
                        </>
                    ),
                    okButtonProps: {
                        type: 'primary',
                    },
                    okText: 'Send email',
                })}
            confirmSecondWarning={() =>
                Modal.confirm({
                    onOk: () => onRequestInformationSecondWarning(),
                    centered: true,
                    title: 'Request information - second warning',
                    content: (
                        <>
                            <p>
                                This will send an e-mail to the admin of {companyName} with a one
                                week warning for them them to provide extra information.
                            </p>
                            <p>Proceed?</p>
                        </>
                    ),
                    okButtonProps: {
                        type: 'primary',
                    },
                    okText: 'Send email',
                })
            }
        />
    )
}

export default RequestInformationContainer
