import { DownloadOutlined } from '@ant-design/icons'
import type { GetBillInvoiceResponse } from '@pleo-io/deimos'
import { Button, Space } from 'antd'
import { downloadOriginalInvoice } from '../../../components/supplier/download-invoice'
import { InvoicePDFPreview } from 'components/supplier/invoice-pdf-preview'
import { bff } from './bff'
import type { FC } from 'react'

export const BillInvoiceDocument: FC<{
    invoice: GetBillInvoiceResponse
}> = ({ invoice }) => {
    const { data: originalFile } = bff.invoiceDocument.getInvoicePdfDownloadUrl.useQuery({
        invoiceId: invoice.id,
    })

    return (
        <Space direction="vertical">
            <Space>
                <Button onClick={() => downloadOriginalInvoice(originalFile?.url)}>
                    <DownloadOutlined />
                    Download original
                </Button>
            </Space>
            <InvoicePDFPreview invoiceId={invoice.id} />
        </Space>
    )
}
