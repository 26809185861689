import type { Address } from 'types/deimos-company'

export interface Timestamps {
    createdAt: string
    updatedAt: string | null
    deletedAt: string | null
}

export interface Partner extends Timestamps {
    id: string
    ownCompanyId: string | null
    registrationNumber: string
    name: string
    status: Record<string, any>
    tradingName?: string
    address: Address
    type?: string
}

export interface DisconnectedPartnerCompany {
    companyId: string
    level: PartnerAccessLevel
    createdAt: string
    deletedAt: string | null
}

export enum PartnerAccessLevel {
    BASIC = 'basic',
    EXTENDED = 'extended',
}

export interface CompanyPartner {
    id: string
    name: string
    accessLevel: PartnerAccessLevel
    bookkeepers: Bookkeeper[]
}

interface Bookkeeper {
    id: string
    userId: string
    firstName: string
    lastName: string
    email: string
    avatar?: string | null
}
