import { green, red } from '@ant-design/colors'
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    FileSearchOutlined,
    MinusCircleOutlined,
} from '@ant-design/icons'
import type { TransactionCheckResult, UAVTransaction } from 'bff/moons/generated/pandora-v1'
import { Button, Card, Modal, Select, Skeleton, Space, Table, Tooltip, Typography } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import { DayjsDatePicker } from 'packages/form/date-picker'
import React, { useReducer } from 'react'
import styled from 'styled-components'
import { useTransactionChecks, useTransactions } from '../api/use-transactions'
import { useMetabaseTransactionLink } from '../lib/use-metabase-transaction-link'

const { Text, Link } = Typography
interface TransactionModal {
    open: boolean
    transactionId: UAVTransaction['id']
}

const transactionModalReducer = (
    state: TransactionModal,
    action: { type: 'open' | 'close'; transactionId?: UAVTransaction['id'] }
) => {
    switch (action.type) {
        case 'close':
            return { open: false, transactionId: '' }
        case 'open':
            return { open: true, transactionId: action?.transactionId || '' }
    }
}

const FirstColumn = styled('div')`
    padding-left: 16px;
`

const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;

    .ant-pagination {
        margin-right: 16px;
    }

    .ant-table-thead th {
        padding-left: 16px;
    }
`

const Check: React.FC<{ check: TransactionCheckResult }> = ({ check }) => {
    const icons: Record<TransactionCheckResult['checkStatus'], any> = {
        FAILED: <ExclamationCircleOutlined style={{ color: red[4] }} />,
        INDETERMINATE: <MinusCircleOutlined />,
        SUCCESS: <CheckCircleOutlined style={{ color: green[4] }} />,
    }

    return (
        <Space direction="vertical">
            <Text strong>
                {icons[check.checkStatus]} {check.checkType}
            </Text>
            <Text>{check?.checkMessage}</Text>
        </Space>
    )
}

const Filters: React.FC<{
    handleRangeChange: (values: any) => void
    handleStatusChange: (value: string) => void
}> = ({ handleRangeChange, handleStatusChange }) => {
    return (
        <Space>
            <Select
                placeholder="Filter by status"
                allowClear
                options={[
                    {
                        label: 'Refunded',
                        value: 'REFUNDED',
                    },
                    {
                        label: 'Settled',
                        value: 'SETTLED',
                    },
                    {
                        label: 'Cleared',
                        value: 'CLEARED',
                    },
                    {
                        label: 'Approved',
                        value: 'APPROVED',
                    },
                    {
                        label: 'Declined',
                        value: 'DECLINED',
                    },
                ]}
                onChange={handleStatusChange}
                data-testid="transaction-filter-status"
            />
            <DayjsDatePicker.RangePicker
                onChange={handleRangeChange}
                data-testid="transaction-filter-range"
            />
        </Space>
    )
}

const TransactionChecks: React.FC<{ transactionId: string }> = ({ transactionId }) => {
    const { checks, reason, error } = useTransactionChecks(transactionId)
    const isLoading = !checks && !error

    return (
        <>
            {isLoading && <Skeleton />}
            {!isLoading && checks && (
                <Space direction="vertical">
                    {reason && <Text>{reason}</Text>}
                    {checks.map((check) => (
                        <Check key={check.checkMessage} check={check} />
                    ))}
                </Space>
            )}
        </>
    )
}

const TransactionId: React.FC<{ id?: string }> = ({ id }) => {
    const metabaseTransactionLink = useMetabaseTransactionLink(id)

    return (
        <Tooltip title="Opens in Metabase" placement="bottom">
            <Link href={metabaseTransactionLink} target="_blank">
                {id}
            </Link>
        </Tooltip>
    )
}

export const Transactions = ({ employeeId }: { employeeId?: string }) => {
    const { transactions, error, setPagination, setRange, setStatus } = useTransactions(employeeId)
    const [transactionModalState, transactionModalDispatch] = useReducer(transactionModalReducer, {
        open: false,
        transactionId: '',
    })

    const isTransactionsDataLoading = !transactions && !error

    const columns: ColumnsType<UAVTransaction> = [
        {
            title: <FirstColumn>Performed</FirstColumn>,
            dataIndex: 'performed',
            render: (performed: UAVTransaction['performed']) => (
                <FirstColumn>{dayjs(performed).format('lll')}</FirstColumn>
            ),
        },
        {
            title: 'Transaction ID',
            dataIndex: 'id',
            render: (id: UAVTransaction['id']) => <TransactionId id={id} />,
        },
        {
            title: 'Merchant',
            dataIndex: 'merchant',
            render: (merchant: UAVTransaction['merchant']) => merchant.name,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount: UAVTransaction['amount']) => (
                <Text>
                    {amount.value} {amount.currency}
                </Text>
            ),
        },
        {
            title: 'Card PAN',
            dataIndex: 'lastFourCardDigits',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status: UAVTransaction['status']) => capitalize(status),
        },
        {
            title: 'Checks',
            dataIndex: 'id',
            render: (transactionId: UAVTransaction['id']) => (
                <Button
                    type="link"
                    onClick={() => transactionModalDispatch({ type: 'open', transactionId })}
                    icon={<FileSearchOutlined />}
                    style={{ padding: 0 }}
                >
                    View checks
                </Button>
            ),
        },
    ]

    return (
        <Card
            title="Transactions"
            type="inner"
            bodyStyle={{ padding: 0, margin: 0 }}
            style={{ margin: '0 0 24px 0' }}
            extra={
                <Filters
                    handleRangeChange={(range) => setRange(range)}
                    handleStatusChange={(value) => setStatus(value)}
                />
            }
        >
            <TableStyleProvider data-testid="transactions-table">
                <Table
                    columns={columns}
                    dataSource={transactions?.data}
                    size="small"
                    loading={isTransactionsDataLoading}
                    onChange={(pagination) => setPagination(pagination)}
                    pagination={{ total: parseInt(transactions?.totalCount || '0', 10) }}
                />
            </TableStyleProvider>
            <Modal
                open={transactionModalState.open}
                title="Checks"
                onCancel={() => transactionModalDispatch({ type: 'close' })}
                footer={null}
            >
                <TransactionChecks transactionId={transactionModalState.transactionId} />
            </Modal>
        </Card>
    )
}
