import { Card } from 'antd'
import { ContentContainer } from 'components/layout-containers'
import type { FC } from 'react'
import { Link } from 'react-router-dom'

export const TransactionOverview: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    return (
        <Card title="Transaction Overview" style={{ marginBottom: '1rem' }}>
            <ContentContainer>
                <Link
                    target="_blank"
                    to={`https://metabase.pleo.io/dashboard/2080-customer-dashabord-cdd-fincrime?company_id=${companyId}&date_filter=past30days~`}
                >
                    {' '}
                    Past 30 days transactions
                </Link>
            </ContentContainer>
        </Card>
    )
}
