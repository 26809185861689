import { Alert, Card, Descriptions, Tag } from 'antd'
import type { DirectDebitAgreementModelStatus } from 'bff/moons/generated/cupid-v1'
import Spinner from 'components/content-spinner'
import { useDirectDebitAgreement } from 'services/deimos/direct-debit'

const getDirectDebitStatusColor = (status: DirectDebitAgreementModelStatus | undefined) => {
    switch (status) {
        case 'READY':
            return 'green'
        case 'PENDING':
        case 'PENDING_CANCELLATION':
            return 'yellow'
        case 'SUSPENDED':
            return 'orange'
        default:
            return 'default'
    }
}

export const DirectDebitCard = ({ companyId }: { companyId: string }) => {
    const { data, error, isLoading } = useDirectDebitAgreement(companyId)

    if (isLoading) {
        return (
            <Card title="Direct Debit mandate">
                <Spinner />
            </Card>
        )
    }

    if (error) {
        return (
            <Card title="Direct Debit mandate">
                <Alert message="Failed to fetch Direct Debit mandate." type="error" />
            </Card>
        )
    }

    if (!data) {
        return (
            <Card title="Direct Debit mandate">
                <Alert message="Direct Debit mandate for this company wasn't found." type="info" />
            </Card>
        )
    }

    return (
        <Card title="Direct Debit mandate">
            <Descriptions column={4}>
                <Descriptions.Item label="Status">
                    <Tag color={getDirectDebitStatusColor(data.status)}>{data.status}</Tag>
                </Descriptions.Item>
            </Descriptions>
        </Card>
    )
}
