import React, { FC, useRef, useEffect } from 'react'
import { Network, Data } from 'vis-network/standalone/esm/vis-network'
import options from './graph-options'
import { color } from 'theme/tokens'
import { Space } from 'antd'

const Graph: FC<
    React.PropsWithChildren<{
        dataSet: Data
    }>
> = ({ dataSet }) => {
    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (ref.current) {
            const network = new Network(ref.current, dataSet, options)
            return () => network.destroy()
        }
    }, [dataSet])

    return (
        <Space
            direction="vertical"
            style={{
                width: '100%',
            }}
        >
            <div
                ref={ref}
                style={{
                    width: 'auto',
                    height: '800px',
                    border: `1px solid ${color.border}`,
                }}
            />
            <p style={{ float: 'right' }}>
                For zooming you can also use the <b>+ / -</b> keyboard buttons
            </p>
        </Space>
    )
}

export default Graph
