import { put, takeLatest, call, select } from 'redux-saga/effects'
import { notification, message } from 'antd'
import type { PayloadAction } from '@reduxjs/toolkit'

import {
    getSuppliers,
    getSupplier,
    getInvoices,
    putSupplier,
    postSupplierStatus,
    UpdateSupplierPayload,
    UpdateSupplierStatusPayload,
} from 'services/deimos/suppliers'
import type { PaginatedResponse, Supplier, SupplierInvoice } from 'types/deimos-supplier'
import { actions } from './slice'
import {
    selectSuppliersPagination,
    selectInvoicesPagination,
    selectSuppliersRequestParams,
    selectInvoicesRequestParams,
    selectSupplier,
} from './selectors'
import type { CursorPaginationCommand } from 'types/cursor-pagination'

export function* fetchSuppliers({
    payload: command,
}: PayloadAction<CursorPaginationCommand | undefined>): any {
    try {
        if (!command) {
            yield put(actions.resettingSuppliers())
        }
        yield put(actions.startingFetchingSuppliers())
        const pagination = yield select(selectSuppliersPagination)
        const requestParams = yield select(selectSuppliersRequestParams)
        const res = yield call(getSuppliers, command, pagination, requestParams)
        const suppliersResponse: PaginatedResponse<Supplier> = yield res.json()
        yield put(actions.settingSuppliers(suppliersResponse))
    } catch (e) {
        yield call(notification.error, {
            message: 'Failed to search for suppliers.',
            description: (e as Error).message,
        })
        yield put(actions.failingFetchingSuppliers())
    }
}

export function* fetchSupplierInvoices({
    payload,
}: PayloadAction<{ supplierId: string; command?: CursorPaginationCommand }>): any {
    try {
        yield put(actions.startingFetchingCurrentSupplierInvoices())
        const pagination = yield select(selectInvoicesPagination)
        const requestParams = yield select(selectInvoicesRequestParams)
        const res = yield call(
            getInvoices,
            payload.supplierId,
            payload.command,
            pagination,
            requestParams
        )
        const invoicesResponse: PaginatedResponse<SupplierInvoice> = yield res.json()
        yield put(actions.settingCurrentSupplierInvoices(invoicesResponse))
    } catch (e) {
        yield call(notification.error, {
            message: 'Failed to fetch supplier invoices.',
            description: (e as Error).message,
        })
        yield put(actions.failingFetchingCurrentSupplierInvoices())
    }
}

export function* fetchSupplier({ payload: supplierId }: PayloadAction<string>): any {
    try {
        yield put(actions.startingFetchingCurrentSupplier())
        const res = yield call(getSupplier, supplierId)
        const supplier = yield res.json()
        yield put(actions.settingCurrentSupplier(supplier))
    } catch (e) {
        yield call(notification.error, {
            message: 'Unable to fetch supplier.',
            description: (e as Error).message,
        })
        yield put(actions.failingFetchingCurrentSupplier())
    }
}

export function* updateSupplier({
    payload,
}: PayloadAction<{ supplierId: string; data: UpdateSupplierPayload }>): any {
    try {
        yield put(actions.startingFetchingCurrentSupplier())
        const res = yield call(putSupplier, payload.supplierId, payload.data)
        const updatedSupplier = yield res.json()
        yield put(actions.settingCurrentSupplier(updatedSupplier))
        yield put(actions.fetchSuppliers({ refresh: true }))
        call(message.success, `Supplier updated successfully.`)
    } catch (e) {
        const supplier = yield select(selectSupplier)
        yield put(actions.settingCurrentSupplier(supplier))
        yield call(notification.error, {
            message: `Unable to update supplier.`,
            description: (e as Error).message,
        })
    }
}

export function* updateSupplierStatus({
    payload,
}: PayloadAction<{ supplierId: string; data: UpdateSupplierStatusPayload }>): any {
    try {
        yield put(actions.startingFetchingCurrentSupplier())
        const res = yield call(postSupplierStatus, payload.supplierId, payload.data)
        const updatedSupplier = yield res.json()
        yield put(actions.settingCurrentSupplier(updatedSupplier))
        call(message.success, `Supplier's status updated successfully.`)
    } catch (e) {
        const supplier = yield select(selectSupplier)
        yield put(actions.settingCurrentSupplier(supplier))
        yield call(notification.error, {
            message: `Unable to update supplier's status.`,
            description: (e as Error).message,
        })
    }
}

export function* suppliersSaga() {
    yield takeLatest(actions.fetchSuppliers, fetchSuppliers)
    yield takeLatest(actions.fetchSupplier, fetchSupplier)
    yield takeLatest(actions.fetchSupplierInvoices, fetchSupplierInvoices)
    yield takeLatest(actions.updateSupplier, updateSupplier)
    yield takeLatest(actions.updateSupplierStatus, updateSupplierStatus)
}
