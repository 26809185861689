import { Space, Typography } from 'antd'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import type { FC } from 'react'
import { inputWidth } from 'theme/tokens'
import * as Yup from 'yup'

const { Title } = Typography

const validationSchema = Yup.object()
    .shape({
        primaryExpenseId: Yup.string().required('Expense ID is a required field'),
        secondaryExpenseId: Yup.string().required('Expense ID is a required field'),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

const initialValues: FormValues = {
    primaryExpenseId: '',
    secondaryExpenseId: '',
}

export interface LinkExpensesPayload {
    expenseIds: string[]
}

interface Props {
    fetching: boolean
    linkExpenses: (payload: LinkExpensesPayload) => void
}

const LinkExpenses: FC<React.PropsWithChildren<Props>> = ({ fetching, linkExpenses }) => (
    <Formik
        initialValues={initialValues}
        onSubmit={({ primaryExpenseId, secondaryExpenseId }) => {
            linkExpenses({ expenseIds: [primaryExpenseId, secondaryExpenseId] })
        }}
        validationSchema={validationSchema}
        validateOnMount
    >
        {({ errors }) => {
            const disabled = !!errors.primaryExpenseId || !!errors.secondaryExpenseId
            return (
                <Form layout="inline">
                    <Space direction="vertical" size="middle">
                        <Title level={5}>Link expenses</Title>
                        <Space size="large">
                            <Input
                                name="primaryExpenseId"
                                placeholder="Expense ID 1"
                                style={{ width: inputWidth.huge }}
                            />
                            <Input
                                name="secondaryExpenseId"
                                placeholder="Expense ID 2"
                                style={{ width: inputWidth.huge }}
                            />
                        </Space>
                        <SubmitButton type="primary" loading={fetching} disabled={disabled}>
                            Link
                        </SubmitButton>
                    </Space>
                </Form>
            )
        }}
    </Formik>
)

export default LinkExpenses
