import { Modal } from 'antd'
import type { FC } from 'react'

import type { ModalActionType } from 'store/modules/support/card-orders/types'

import ExecuteGhostOrderFormContainer from './forms/execute-ghost-form.container'
import TrackingCodeFormContainer from './forms/tracking-code-form.container'

interface Props {
    actionActive: ModalActionType | null
    closeModal: () => void
}
const CardOrdersModal: FC<React.PropsWithChildren<Props>> = ({ actionActive, closeModal }) => {
    const getModalTitleAndChildren = (action: ModalActionType | null) => {
        switch (action) {
            case 'ghost':
                return {
                    title: 'Execute order for the ghost wallet',
                    children: <ExecuteGhostOrderFormContainer closeModal={closeModal} />,
                }
            case 'tracking':
                return {
                    title: 'Upload card order tracking codes',
                    children: <TrackingCodeFormContainer />,
                }
        }
    }

    const modal = getModalTitleAndChildren(actionActive)
    return (
        <Modal
            maskClosable={false}
            title={modal && modal.title}
            open={!!actionActive}
            onCancel={closeModal}
            footer={null}
            width={500}
            centered
        >
            {modal && modal.children}
        </Modal>
    )
}

export default CardOrdersModal
