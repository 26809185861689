import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'

import type { Employee, EmployeePayload, EmployeeRole } from 'types/employee'
import type { CurrentStatus } from 'types/status'

export interface EmployeeState {
    employees: Employee[]
    status: CurrentStatus
}

export const initialState: EmployeeState = {
    employees: [],
    status: 'default',
}

const { actions: generatedActions, reducer } = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        modifiedStatus: (state, { payload: status }: PayloadAction<CurrentStatus>) => {
            state.status = status
        },
        updatedCompanyEmployees: (state, { payload }: PayloadAction<Employee[]>) => {
            state.employees = payload
        },
        addedEmployee: (state, { payload }: PayloadAction<Employee>) => {
            state.employees.push(payload)
        },
        resetCompanyEmployees: (state) => {
            state.employees = []
        },
        deactivatedEmployee: (state, { payload: employeeId }: PayloadAction<string>) => {
            state.employees = state.employees.filter((employee) => employee.id !== employeeId)
        },
        changedEmployeeRole: (
            state,
            { payload: { role, employeeId } }: PayloadAction<{ role: EmployeeRole; employeeId: string }>
        ) => {
            const nextEmployees = state.employees.map((employee) =>
                employee.id === employeeId ? { ...employee, role } : employee
            )

            state.employees = nextEmployees
        },
        updateEmployee: (state, { payload }: PayloadAction<Employee>) => {
            const nextEmployees = state.employees.map((employee) =>
                employee.id === payload.id ? payload : employee
            )

            state.employees = nextEmployees
        },
    },
})

const fetchCompanyEmployees = createAction<{ deimosId: string; includeDeleted: boolean }>(
    'employees/fetchCompanyEmployees'
)
const deactivateEmployees = createAction<EmployeePayload[]>('employees/deactivateEmployees')
const inviteEmployee = createAction<{ email: string; firstName?: string; companyId: string }>(
    'employees/invite'
)
const reactivateEmployee = createAction<{ employeeId: string; userId: string }>(
    'employees/reactivate'
)

export const employeesReducer = reducer
export const actions = {
    ...generatedActions,
    fetchCompanyEmployees,
    deactivateEmployees,
    inviteEmployee,
    reactivateEmployee,
}
