import type { FC } from 'react'
import { Upload, message, Button } from 'antd'
import type { UploadChangeParam } from 'antd/lib/upload'
import { UploadOutlined } from '@ant-design/icons'
import { uploadCardOrderTrackingCodeFile } from 'services/deimos/card-orders'
import type { RcCustomRequestOptions } from 'types/custom-request'

const onChange = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed: ${info.file.error?.message}`)
    }
}

const customRequest = async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
    const formData = new FormData()
    formData.append('file', file)

    try {
        const response = await uploadCardOrderTrackingCodeFile(formData).json()
        onSuccess && onSuccess({ response }, file as any)
    } catch (e) {
        onError && onError(e as Error)
    }
}

const TrackingCodeFormContainer: FC<React.PropsWithChildren<unknown>> = () => (
    <Upload
        accept=".xlsx, .csv, .xls"
        onChange={onChange}
        // Use customRequest to override POST behavior
        customRequest={customRequest}
        // File removal is not supported
        showUploadList={{ showRemoveIcon: false }}
    >
        <Button>
            <UploadOutlined /> Click to Upload
        </Button>
    </Upload>
)

export default TrackingCodeFormContainer
