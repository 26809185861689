import { Breadcrumb, Button, Card, Form, message, Select, Space, Spin } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { EditAppForm } from '../components/edit-app-form'
import {
    AppMarketplaceAPIContext,
    AppMarketplaceContext,
    useAppMarketplaceAPIContext,
    useAppMarketplaceContext,
} from '../contexts/app-markeplace-context'
import {
    EditAppContext,
    LoadAppContext,
    useEditAppContext,
    useLoadAppContext,
} from '../contexts/view-edit-app-context'
import { useAppMarketplaceBackend } from '../hooks/use-app-marketplace-backend'
import { useLoadApp } from '../hooks/use-load-app-backend'
import type { PersistedApp } from '../models'
import { type EditAppFormValues, fromApp } from '../models/edit-app-form'

import * as Result from '../../fp/result'
import { APP_STATUS_OPTIONS } from '../models/app-form-options'

export const ViewEditApp = () => {
    const { data, actions } = useAppMarketplaceBackend()

    const { slug } = useParams()
    const ctx = useLoadApp(slug)
    return (
        <AppMarketplaceContext.Provider value={data}>
            <AppMarketplaceAPIContext.Provider value={actions}>
                <LoadAppContext.Provider value={ctx}>
                    <PageContentLayout>
                        <ViewEditAppScreen />
                    </PageContentLayout>
                </LoadAppContext.Provider>
            </AppMarketplaceAPIContext.Provider>
        </AppMarketplaceContext.Provider>
    )
}

const ViewEditAppScreen = () => {
    const ctx = useLoadAppContext()

    if (ctx.isLoading) {
        return <ViewEditAppScreen.Loading />
    }
    if (ctx.app === null) {
        return <ViewEditAppScreen.NotFound />
    }

    return <ViewEditAppScreen.Editing app={ctx.app} />
}

const ViewEditAppLoading = () => <Spin />
ViewEditAppScreen.Loading = ViewEditAppLoading

const ViewEditAppNotFound = () => <Card>App not found</Card>
ViewEditAppScreen.NotFound = ViewEditAppNotFound

const showFeedbackMessage = Result.match<string, {}, void>(
    (error) => message.error(`Failed to update app: ${error}`),
    (_data) => message.success('App updated')
)

const ViewEditAppEditing = ({ app }: { app: PersistedApp }) => {
    const [form] = Form.useForm<EditAppFormValues>()
    const { updateApp } = useAppMarketplaceAPIContext()
    const navigate = useNavigate()

    useEffect(() => {
        form.setFieldsValue(fromApp(app))
    }, [app, form])

    const saveApp = async (values: EditAppFormValues) => {
        const result = await updateApp(values)
        showFeedbackMessage(result)

        if (Result.isOk(result)) {
            navigate('/partnerships/app-marketplace')
        }
    }

    return (
        <EditAppContext.Provider value={app}>
            <Form form={form} onFinish={saveApp} layout="vertical">
                <ViewEditAppScreen.Top />
                <EditAppForm />
            </Form>
        </EditAppContext.Provider>
    )
}
ViewEditAppScreen.Editing = ViewEditAppEditing

const ViewEditAppTopSection = () => {
    const app = useEditAppContext()
    return (
        <Card style={{ position: 'sticky', insetBlockStart: '0', zIndex: 9 }}>
            <SpaceBetween>
                <Breadcrumb>
                    <Breadcrumb.Item href="/partnerships/app-marketplace">
                        App Marketplace
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{app.name}</Breadcrumb.Item>
                </Breadcrumb>
                <Space size="small" direction="horizontal">
                    <ViewEditAppTopSection.AppStatusInput />
                    <ViewEditAppScreen.SaveApp />
                </Space>
            </SpaceBetween>
        </Card>
    )
}
ViewEditAppScreen.Top = ViewEditAppTopSection

const AppStatusInput = () => (
    <Form.Item name="status" style={{ marginBottom: 'unset' }}>
        <Select style={{ width: '140px' }}>
            {APP_STATUS_OPTIONS.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                    {label}
                </Select.Option>
            ))}
        </Select>
    </Form.Item>
)
ViewEditAppTopSection.AppStatusInput = AppStatusInput

const SpaceBetween = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SaveAppButton = () => (
    <Button type="primary" htmlType="submit" loading={useAppMarketplaceContext().isUpdating}>
        Save
    </Button>
)
ViewEditAppScreen.SaveApp = SaveAppButton
