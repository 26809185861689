import { Button, Result } from 'antd'
import { deSnakify } from 'utils/strings'

interface Props {
    goBack: () => void
    errorMessage: string
}
const ErrorMessage = ({ goBack, errorMessage }: Props) => {
    return (
        <Result
            status="error"
            title="Something went wrong!"
            subTitle={deSnakify(errorMessage)}
            extra={<Button onClick={goBack}>Go back and try again</Button>}
        />
    )
}

export default ErrorMessage
