// eslint-disable-next-line no-restricted-imports
import { createTRPCReact } from '@trpc/react-query'
import type { AnyRouter } from '@trpc/server'
import merge from 'lodash.merge'

type CreateTRPCReactOptions = Parameters<typeof createTRPCReact>[0]

export function createBffHooks<T extends AnyRouter>(overrides?: Partial<CreateTRPCReactOptions>) {
    const defaultOptions: CreateTRPCReactOptions = {
        /**
         * To prevent requesting invalid calls when users switch accounts/entities.
         * BFF will automatically re-fetch when the auth token changes,
         * but the user may change to a role that isn't authorised
         * or query params might make no sense for the new user/entity,
         * so this prevents unnecessary 404 or 403 calls (IN-1628, GAIA-663).
         */
        abortOnUnmount: true,
        unstable_overrides: {
            useMutation: {
                /**
                 * This function is called whenever a `.useMutation` succeeds
                 **/
                async onSuccess(opts) {
                    /**
                     * @note that order here matters:
                     * The order here allows route changes in `onSuccess` without
                     * having a flash of content change whilst redirecting.
                     **/
                    // Calls the `onSuccess` defined in the `useQuery()`-options:
                    await opts.originalFn()
                    // Invalidate all queries in the react-query cache:
                    await opts.queryClient.invalidateQueries()
                },
            },
        },
    }
    const options = merge(defaultOptions, overrides)
    return createTRPCReact<T>(options)
}
