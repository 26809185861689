import * as Yup from 'yup'

export const validationSchema = Yup.object()
    .shape({
        exportOption: Yup.string().oneOf(['all', 'dates']),
        exportDates: Yup.array().optional(),
        compressedReceipts: Yup.boolean(),
    })
    .required()

export type FormValues = Yup.InferType<typeof validationSchema>
