import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Tabs, Empty, Space, Result } from 'antd'

import Spinner from 'components/content-spinner'
import { DocumentFiles } from 'pages/compliance/company/shared/document-files/document-files'
import {
    getLinkToComplianceDocumentFile,
    useCompanyComplianceDocuments,
} from 'services/deimos/styx-company/styx-company'
import { CreateComplianceDocument } from './create-compliance-document/create-compliance-document'

const { TabPane } = Tabs

interface Props {
    maxFileSize: number
    companyId: string
}

export const ComplianceDocuments: FC<React.PropsWithChildren<Props>> = ({
    companyId,
    maxFileSize,
}) => {
    const {
        data: documents,
        mutations,
        error,
        isValidating,
    } = useCompanyComplianceDocuments(companyId)

    const [activeKey, setActiveKey] = useState('')
    const hasNoDocuments = documents ? documents?.length < 1 : true

    const handleCreateDocumentFile = (documentId: string) => async (file: FormData) => {
        await mutations.createDocumentFile(documentId, file)
    }

    const handleGetLinkToFile =
        (documentId: string) => async (fileId: string, download?: boolean) => {
            const { url }: { url: string } = await getLinkToComplianceDocumentFile(
                companyId,
                documentId,
                fileId,
                download
            ).json()

            return url
        }

    if (hasNoDocuments) {
        return (
            <Space align="center" direction="vertical" style={{ width: '100%' }} size={16}>
                <Empty data-testid="empty" description="There are currently no documents." />
                <CreateComplianceDocument companyId={companyId}></CreateComplianceDocument>
            </Space>
        )
    }

    if (error) {
        return (
            <Result
                status="error"
                title="Failed to load content"
                subTitle="If this issue persists, contact Team Atlas"
            />
        )
    } else if (isValidating || !documents) {
        return <Spinner />
    } else {
        return (
            <Tabs
                tabPosition="left"
                defaultActiveKey={activeKey}
                onTabClick={(key: string) => setActiveKey(key)}
                tabBarExtraContent={<CreateComplianceDocument companyId={companyId} />}
            >
                {documents.map((document) => {
                    const title = document.title
                    return (
                        <TabPane tab={`${title} (${document.files.length})`} key={document.id}>
                            <Content>
                                <DocumentTitle>
                                    <h2>{title}</h2>
                                </DocumentTitle>
                                <div>{document.description}</div>
                                <DocumentFiles
                                    maxFileSize={maxFileSize}
                                    document={document}
                                    createDocumentFile={handleCreateDocumentFile(document.id)}
                                    getLinkToFile={handleGetLinkToFile(document.id)}
                                />
                            </Content>
                        </TabPane>
                    )
                })}
            </Tabs>
        )
    }
}

const Content = styled.div`
    min-height: 250px;
`

const DocumentTitle = styled.div`
    display: flex;
    justify-content: space-between;
`
