import request from 'services/kerberos/request'
import useSWR from 'swr'
import { kerberosRequest, buildFetcher } from 'services/request'
import type { PermissionsResponse } from 'types/groupPermissions'

export const useGroup = (teamName?: string) => {
    const result = useSWR<PermissionsResponse>(
        teamName ? `rest/v1/admin/groups/${teamName}/permissions` : null,
        buildFetcher(kerberosRequest),
        { shouldRetryOnError: false }
    )

    const addPermissions = async (permissions?: string[]) => {
        await request().post(`rest/v1/admin/groups/${teamName}/permissions`, {
            json: {
                resourceIds: permissions,
            },
        })
        result.mutate()
    }

    const removePermissions = async (permissions?: string[]) => {
        await request().delete(`rest/v1/admin/groups/${teamName}/permissions`, {
            json: {
                resourceIds: permissions,
            },
        })
        result.mutate()
    }

    return { ...result, addPermissions, removePermissions }
}
