import { callistoRequest } from 'services/request'
import { downloadFileFromBlob } from 'utils/download-file-blob'

export const downloadFetchConnectedEmployeesAsCSV = async (companyId: string) => {
    const result = await callistoRequest().get(`rest/v1/employees/export`, {
        searchParams: {
            company_id: companyId,
        },
    })
    const blobData = await result.blob()

    if (!blobData) {
        return
    }

    downloadFileFromBlob(blobData, `fetch_connected_employees_${companyId}.csv`)
}
