import type { FC } from 'react'
import { Card } from 'antd'

import { useLocation, useNavigate, Routes, Route } from 'react-router-dom'

import ProtectedRoute from 'router/protected-route'
import Employees from './employees/employees'

import Documents from './documents/documents'
import People from './people/people'
import RiskAssessment from './risk-assessment/risk-assessment'
import { peopleSectionLabel } from '../company/utils'

const ClosedCompanyTabs: FC<
    React.PropsWithChildren<{
        companyId: string
        companyCountry: string
        onboardingSource?: string
    }>
> = ({ companyId, companyCountry, onboardingSource }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const defaultActiveKey = location.pathname.split('/').reverse()[0] ?? 'documents'
    const activeTabKey = defaultActiveKey != companyId ? defaultActiveKey : 'documents'

    const tabList = [
        { key: 'documents', tab: 'Documents' },
        { key: 'people', tab: peopleSectionLabel(onboardingSource) },
        { key: 'employees', tab: 'Employees' },
        { key: 'risk-assessment', tab: 'Risk Assessment' },
    ]

    return (
        <Card
            tabList={tabList}
            activeTabKey={activeTabKey}
            defaultActiveTabKey={defaultActiveKey}
            onTabChange={(tabKey: string) => {
                navigate(tabKey, { replace: true })
            }}
        >
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Documents companyId={companyId} />} />
                    <Route path="documents" element={<Documents companyId={companyId} />} />
                    <Route
                        path="people"
                        element={<People companyId={companyId} companyCountry={companyCountry} />}
                    />
                    <Route path="risk-assessment" element={<RiskAssessment />} />
                    {/* <ProtectedRoute path="ownership" element={<Ownership />} /> */}
                    <Route path="employees" element={<Employees companyId={companyId} />} />
                </Route>
            </Routes>
        </Card>
    )
}

export default ClosedCompanyTabs
