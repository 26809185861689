import type { FC } from 'react'
import { Table } from 'antd'
import type { DirectDebitRequestInfo } from 'bff/moons/generated/cupid-v1'

const columns = [
    {
        title: 'Reference',
        dataIndex: 'invoiceReference',
        key: 'invoiceReference',
    },
    {
        title: 'Amount',
        dataIndex: ['amount', 'value'],
        key: 'amount.value',
    },
    {
        title: 'Currency',
        dataIndex: ['amount', 'currency'],
        key: 'amount.currency',
    },
    {
        title: 'Scheduled At',
        dataIndex: 'scheduledAt',
        key: 'scheduledAt',
    },
    {
        title: 'Sent At',
        dataIndex: 'sentAt',
        key: 'sentAt',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Provider',
        dataIndex: 'provider',
        key: 'provider',
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
    },
]

interface Props {
    data: DirectDebitRequestInfo[]
}

const DirectDebitTransfers: FC<Props> = ({ data }) => (
    <Table dataSource={data} columns={columns} pagination={false} size="small" bordered />
)

export default DirectDebitTransfers
