import styled from 'styled-components'
import { spacing } from 'theme/tokens'

export const InputGrid = styled.div`
    display: grid;
    column-gap: ${spacing.space24};
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    & label {
        grid-column: 1/-1;
    }
`
export const SpanRowItem = styled.div`
    grid-column: 1/-1;
`

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
`
