import { countries } from 'countries-list'
import { Select, SelectProps } from 'formik-antd'

const { Option } = Select

type Alpha2Code = keyof typeof countries

const CountrySelect = (props: SelectProps) => {
    const countriesByAlpha2Code = Object.keys(countries)

    return (
        <Select {...props} optionFilterProp="label">
            {countriesByAlpha2Code.map((alpha2code) => (
                <Option
                    key={alpha2code}
                    value={alpha2code}
                    label={countries[alpha2code as Alpha2Code].name}
                    data-testid={`country-select-option-${alpha2code}`}
                >
                    {countries[alpha2code as Alpha2Code].name}
                </Option>
            ))}
        </Select>
    )
}

export default CountrySelect
