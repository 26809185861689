import type { CashbackResponse } from 'bff/moons/generated/tarvos'
import { useParams } from 'react-router-dom'
import { buildFetcher, tarvosRequest } from 'services/request'
import useSWR from 'swr'

export const useCompanyCashback = (dates: string[]) => {
    const { companyId } = useParams()

    const searchParams = new URLSearchParams({
        from: dates[0],
        to: dates[1],
    })

    const response = useSWR<CashbackResponse[], Error>(
        // Expects dates as YYYY-MM-DD
        `rest/v1/cashback/history/${companyId}/for-expense-period?${searchParams}`,
        buildFetcher(tarvosRequest),
        { revalidateOnFocus: false }
    )

    return { cashback: response.data, ...response }
}
