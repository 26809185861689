import { Card, Switch } from 'antd'
import { spacing } from 'theme/tokens'
import type { FC } from 'react'
import styled from 'styled-components'

import { UserPermission, userPermissions } from 'types/user-permissions'
import { withPermissionGuard } from 'components/permission-guard/permission-guard'
import type { User } from 'types/user'

interface Props {
    permissions: string[]
    onPermissionChange: (userId: string, permission: UserPermission, checked: boolean) => void
    user: User
}

const userPermissionMap: Record<UserPermission, string> = {
    receipts: 'Reclaim VAT (view receipts)',
}

export const UserPermissions: FC<React.PropsWithChildren<Props>> = ({
    permissions,
    onPermissionChange,
    user,
}) => {
    return (
        <Card title="Permissions">
            <PermissionsListContainer>
                {userPermissions.map((permission, index) => (
                    <PermissionContainer key={index}>
                        <Switch
                            size="small"
                            data-testid={`${permission}Switch`}
                            checked={permissions.includes(permission)}
                            onChange={(checked: boolean) =>
                                onPermissionChange(user.id, permission, checked)
                            }
                        />
                        {userPermissionMap[permission]}
                    </PermissionContainer>
                ))}
            </PermissionsListContainer>
        </Card>
    )
}

export default withPermissionGuard(UserPermissions, ['super'])

const PermissionsListContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: ${spacing.space8} ${spacing.space24};
`

const PermissionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.space8};
`
