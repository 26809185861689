const ALGORITHM = 'RSA-OAEP'

export function arrayBufferToString(buffer: ArrayBuffer) {
    const byteArray = new Uint8Array(buffer)
    let byteString = ''
    for (let i = 0; i < byteArray.byteLength; i++) {
        byteString += String.fromCodePoint(byteArray[i])
    }
    return byteString
}

export function stringToArrayBuffer(str: string) {
    const strLength = str.length
    const arrayBuffer = new ArrayBuffer(strLength)
    const bufView = new Uint8Array(arrayBuffer)

    for (let i = 0; i < strLength; i++) {
        bufView[i] = str.charCodeAt(i)
    }

    return arrayBuffer
}

export const generateKeyPair = async (): Promise<{
    publicKey: string
    privateKey?: JsonWebKey
}> => {
    const options = {
        name: ALGORITHM,
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: { name: 'SHA-256' },
    }
    const keyPair = await window.crypto.subtle.generateKey(options, true, [
        'encrypt',
        'decrypt',
        'unwrapKey',
    ])

    const exportedKeyPair = await Promise.all([
        window.crypto.subtle.exportKey('spki', keyPair.publicKey),
        window.crypto.subtle.exportKey('jwk', keyPair.privateKey),
    ])

    return {
        publicKey: btoa(arrayBufferToString(exportedKeyPair[0])),
        privateKey: exportedKeyPair[1],
    }
}

export const decryptPayload = async (cipherText: string, privateKey: JsonWebKey) => {
    const key = await window.crypto.subtle.importKey(
        'jwk',
        privateKey,
        { name: ALGORITHM, hash: { name: 'SHA-256' } },
        false,
        ['decrypt']
    )

    const decrypted = await window.crypto.subtle.decrypt(
        { name: ALGORITHM },
        key,
        stringToArrayBuffer(atob(cipherText))
    )

    return arrayBufferToString(decrypted)
}
