export const downloadFileFromBlob = (blob: Blob, filename = 'untitled') => {
    // Create download link
    const fileURL = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.download = filename
    a.target = '_blank'
    a.href = fileURL

    // Trigger download
    document.body.appendChild(a)
    a.click()

    // Cleanup
    window.URL.revokeObjectURL(fileURL)
    a.remove()
}
