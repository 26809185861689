import type { Company360Overview } from 'bff/moons/generated/pandora-v1'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { buildFetcher, pandoraRequest } from 'services/request'

const fetcher = buildFetcher(pandoraRequest)

export const useCompany360 = () => {
    const { id: companyId } = useParams()

    const url = companyId ? `billing/company/${companyId}` : ''

    const { data: company360, ...rest } = useSWR<Company360Overview, Error>(url, fetcher, {
        revalidateOnFocus: false,
    })

    return { company360, ...rest }
}
