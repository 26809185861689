import React, { FC, useState, useEffect, ReactElement } from 'react'
import { Card, Modal, Typography, Button, Tooltip, message, Popover } from 'antd'
import { Formik, Form } from 'formik'
import { Input, FormItem } from 'formik-antd'
import * as Yup from 'yup'
import Skeleton from 'components/content-skeleton'
import styled from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'
import { extractedTransactionIds } from './utils'
import { ProcessTransactionsModal } from './process-transactions-modal'
import { useTransaction } from 'services/deimos/transactions'
import { TransactionDetails } from './transaction-details'

export enum TransactionType {
    chargeback = 'chargeback',
    refund = 'refund',
}

enum ActionType {
    process = 'process',
    undo = 'undo',
    get = 'get',
}

const { Text } = Typography

const validationSchema = Yup.object()
    .shape({
        ids: Yup.string().required('A transaction ID is required.').min(2),
    })
    .required()

interface ProcessTransactionsProps {
    onSubmit: (ids: string, actionType: ActionType) => void
    title: string
    InfoPopoverText?: ReactElement
    transactionType: TransactionType
}

export const ProcessTransactions: FC<ProcessTransactionsProps> = ({
    onSubmit,
    InfoPopoverText,
    title,
    transactionType,
}) => {
    const [textArea, setTextArea] = useState('')
    const lines = textArea.split('\n').filter((line) => line.trim() !== '')
    const isMultipleLines = lines.length > 1

    let actionType: ActionType

    const InfoPopover = () => (
        <Popover
            title={title}
            content={
                <PopoverContent>
                    <Text>{InfoPopoverText}</Text>
                </PopoverContent>
            }
        >
            <InfoCircleOutlined data-testid="info" />
        </Popover>
    )

    const renderButtons = (hasMultipleLines: boolean, isValid: boolean) => {
        if (hasMultipleLines) {
            return [
                <Button
                    htmlType="submit"
                    type="link"
                    disabled={!isValid}
                    data-testid="submit-process-transactions"
                    key="submit-process-transactions"
                    onClick={() => (actionType = ActionType.process)}
                >
                    {`Process ${transactionType}s`}
                </Button>,
                <Button
                    htmlType="submit"
                    type="link"
                    disabled={!isValid}
                    data-testid="submit-undo-transactions"
                    key="submit-undo-transactions"
                    onClick={() => (actionType = ActionType.undo)}
                >
                    {`Undo ${transactionType}s`}
                </Button>,
            ]
        } else {
            return [
                <Button
                    htmlType="submit"
                    type="link"
                    disabled={!isValid}
                    data-testid="submit-get-transaction"
                    key="submit-get-transaction"
                    onClick={() => (actionType = ActionType.get)}
                >
                    Get transaction
                </Button>,
                <Button
                    htmlType="submit"
                    type="link"
                    disabled={!isValid}
                    data-testid="submit-undo-transaction"
                    key="submit-undo-transactions"
                    onClick={() => (actionType = ActionType.undo)}
                >
                    {`Undo ${transactionType}`}
                </Button>,
            ]
        }
    }

    return (
        <Formik
            initialValues={{ ids: '' }}
            onSubmit={(values) => onSubmit(values.ids, actionType)}
            validationSchema={validationSchema}
            validateOnMount
        >
            {({ isValid }) => (
                <Form>
                    <Card
                        title={title}
                        extra={<InfoPopover />}
                        actions={renderButtons(isMultipleLines, isValid)}
                    >
                        <FormItem name="ids">
                            <Input.TextArea
                                data-testid="ids"
                                name="ids"
                                placeholder="Original transaction ID / Multiple transaction IDs"
                                rows={isMultipleLines ? 5 : 1}
                                onChange={(value) => setTextArea(value.target.value)}
                            />
                        </FormItem>
                    </Card>
                </Form>
            )}
        </Formik>
    )
}

interface ProcessTransactionsContainerProps {
    title: string
    transactionType: TransactionType
    InfoPopoverText?: ReactElement
    process: (ids: string[]) => void
    undo: (ids: string[]) => void
    currentTransaction: string
    failedTransaction: string
    successfulTransaction: string
    processTransactionsError?: Error
}

const ProcessTransactionsContainer: FC<
    React.PropsWithChildren<ProcessTransactionsContainerProps>
> = ({
    title,
    transactionType,
    InfoPopoverText,
    process,
    undo,
    currentTransaction,
    failedTransaction,
    successfulTransaction,
    processTransactionsError,
}) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const [isProcessTransactionsModalOpen, setIsProcessTransactionsModalOpen] = useState(false)
    const [isGetTransactionModalOpen, setIsGetTransactionModalOpen] = useState(false)
    const [isUndoTransactionsModalOpen, setIsUndoTransactionsModalOpen] = useState(false)
    const [allTransactions, setAllTransactions] = useState<string[]>([])
    const [failedTransactions, setFailedTransactions] = useState<string[]>([])
    const [successfulTransactions, setSuccessfulTransactions] = useState<string[]>([])
    const { data: transaction, error: getTransactionError } = useTransaction(allTransactions[0])

    const clearTransactionState = () => {
        setAllTransactions([])
        setFailedTransactions([])
        setSuccessfulTransactions([])
    }

    useEffect(() => {
        if (processTransactionsError || getTransactionError) {
            message.error(processTransactionsError?.message || getTransactionError?.message)
            setAllTransactions([])
        }
    }, [processTransactionsError, getTransactionError])

    if (failedTransaction && !failedTransactions.includes(failedTransaction)) {
        setFailedTransactions([...failedTransactions, failedTransaction])
    }

    if (successfulTransaction && !successfulTransactions.includes(successfulTransaction)) {
        setSuccessfulTransactions([...successfulTransactions, successfulTransaction])
    }

    function onSubmit(ids: string, actionType: ActionType) {
        clearTransactionState()
        const transactionIdArray = extractedTransactionIds(ids)
        setAllTransactions(transactionIdArray)

        switch (actionType) {
            case ActionType.get:
                setIsGetTransactionModalOpen(true)
                break
            case ActionType.process:
                processTransactions(transactionIdArray)
                break
            case ActionType.undo:
                undoTransactions(transactionIdArray)
                break
        }
    }

    async function processTransactions(ids: string[]) {
        try {
            setIsGetTransactionModalOpen(false)
            setIsProcessTransactionsModalOpen(true)
            setIsProcessing(true)
            await process(ids)
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setIsProcessing(false)
        }
    }

    async function undoTransactions(ids: string[]) {
        try {
            setIsGetTransactionModalOpen(false)
            setIsUndoTransactionsModalOpen(true)
            setIsProcessing(true)
            await undo(ids)
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setIsProcessing(false)
        }
    }

    return (
        <>
            <ProcessTransactions
                onSubmit={onSubmit}
                InfoPopoverText={InfoPopoverText}
                title={title}
                transactionType={transactionType}
            />
            <ProcessTransactionsModal
                title={`Process ${transactionType}s`}
                isModalOpen={{
                    get: isProcessTransactionsModalOpen,
                    set: setIsProcessTransactionsModalOpen,
                }}
                allTransactions={allTransactions}
                currentTransaction={currentTransaction}
                failedTransactions={failedTransactions}
                successfulTransactions={successfulTransactions}
            />
            <ProcessTransactionsModal
                title={`Undo ${transactionType}s`}
                isModalOpen={{
                    get: isUndoTransactionsModalOpen,
                    set: setIsUndoTransactionsModalOpen,
                }}
                allTransactions={allTransactions}
                currentTransaction={currentTransaction}
                failedTransactions={failedTransactions}
                successfulTransactions={successfulTransactions}
            />

            <Modal
                open={allTransactions.length === 1 && isGetTransactionModalOpen === true}
                onCancel={() => {
                    setAllTransactions([])
                    setIsGetTransactionModalOpen(false)
                }}
                title="Transaction details"
                width={500}
                footer={[
                    <Button
                        htmlType="button"
                        onClick={() => processTransactions([allTransactions[0]])}
                        danger
                        loading={isProcessing}
                        disabled={isProcessing}
                        key="process-transactions-button"
                        data-testid="process-transactions-button"
                    >
                        <Captialise>{transactionType}</Captialise>
                    </Button>,
                    <Tooltip
                        title={transaction?.provider === 'GPS' && 'Unavailable for GPS'}
                        key={`undo-${transactionType}-button`}
                    >
                        <Button
                            htmlType="button"
                            onClick={() => undoTransactions([allTransactions[0]])}
                            disabled={transaction?.provider === 'GPS' || isProcessing}
                            loading={isProcessing}
                            key="undo-transactions-button"
                            data-testid="undo-transactions-button"
                        >
                            {`Undo ${transactionType}`}
                        </Button>
                    </Tooltip>,
                ]}
            >
                {transaction ? <TransactionDetails transaction={transaction} /> : <Skeleton />}
            </Modal>
        </>
    )
}

const PopoverContent = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
`

const Captialise = styled.span`
    text-transform: capitalize;
`

export default ProcessTransactionsContainer
