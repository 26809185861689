/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Ersa API
 * Ersa open api definitions for interacting with account balances
 * OpenAPI spec version: 24.2.1
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('ersa')

export type GetCompanyFeatureConfigurationParams = {
    with_migration_timestamp?: boolean
}

export type GetCompanyBalanceParams = {
    show_allocations?: boolean
}

export type GetCompanyFeatureConfigurationsPrimaryProcessor =
    (typeof GetCompanyFeatureConfigurationsPrimaryProcessor)[keyof typeof GetCompanyFeatureConfigurationsPrimaryProcessor]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCompanyFeatureConfigurationsPrimaryProcessor = {
    EXTERNAL: 'EXTERNAL',
    INTERNAL: 'INTERNAL',
} as const

export type GetCompanyFeatureConfigurationsParams = {
    cardProcessingEnabled?: boolean
    primaryProcessor?: GetCompanyFeatureConfigurationsPrimaryProcessor
    limit?: number
    offset?: number
}

export type SoftMigrateParams = {
    allowReRun?: boolean
}

export type HardMigrateNewOwner = (typeof HardMigrateNewOwner)[keyof typeof HardMigrateNewOwner]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HardMigrateNewOwner = {
    ERSA: 'ERSA',
    ENFUCE: 'ENFUCE',
} as const

export type HardMigrateParams = {
    newOwner?: HardMigrateNewOwner
    blockSpend?: boolean
}

export type VoidRequestVModelMetadata = { [key: string]: { [key: string]: any } }

export interface VoidRequestV2Model {
    companyId?: string
    metadata?: VoidRequestVModelMetadata
    referenceId?: string
}

export type UpdateAllocationFundsRequestModelDirectionType =
    (typeof UpdateAllocationFundsRequestModelDirectionType)[keyof typeof UpdateAllocationFundsRequestModelDirectionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateAllocationFundsRequestModelDirectionType = {
    CUSTOM_TO_DEFAULT: 'CUSTOM_TO_DEFAULT',
    DEFAULT_TO_CUSTOM: 'DEFAULT_TO_CUSTOM',
} as const

export interface UpdateAllocationFundsRequestModel {
    amount?: number
    directionType?: UpdateAllocationFundsRequestModelDirectionType
}

export type SpendAuthVModelStatus =
    (typeof SpendAuthVModelStatus)[keyof typeof SpendAuthVModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpendAuthVModelStatus = {
    AUTHORIZED: 'AUTHORIZED',
    PARTIALLY_SETTLED: 'PARTIALLY_SETTLED',
    SETTLED: 'SETTLED',
    VOIDED: 'VOIDED',
} as const

export type SpendAuthVModelSpendType =
    (typeof SpendAuthVModelSpendType)[keyof typeof SpendAuthVModelSpendType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpendAuthVModelSpendType = {
    'balance-amendment': 'balance-amendment',
    bill: 'bill',
    cashback: 'cashback',
    'card-purchase': 'card-purchase',
    'load-v2': 'load-v2',
    'unload-v2': 'unload-v2',
    'wallet-withdrawal': 'wallet-withdrawal',
    'personal-transfer': 'personal-transfer',
    'pocket-bank-transfer-reimbursement': 'pocket-bank-transfer-reimbursement',
    'pleo-invoice': 'pleo-invoice',
    'pleo-invoice-refund': 'pleo-invoice-refund',
    'manual-chargeback': 'manual-chargeback',
    'manual-pleo-refund': 'manual-pleo-refund',
    'manual-chargeback-reversal': 'manual-chargeback-reversal',
    'admin-adjustment': 'admin-adjustment',
    'overdraft-fee': 'overdraft-fee',
    'overdraft-interest': 'overdraft-interest',
} as const

export type SpendAuthVModelPricingBreakdown = { [key: string]: number }

export type SpendAuthVModelMetadata = { [key: string]: { [key: string]: any } }

export type SpendAuthVModelDebitOrCredit =
    (typeof SpendAuthVModelDebitOrCredit)[keyof typeof SpendAuthVModelDebitOrCredit]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpendAuthVModelDebitOrCredit = {
    DEBIT: 'DEBIT',
    CREDIT: 'CREDIT',
} as const

export type SpendAuthVModelCurrencyContext = {
    empty?: boolean
    providerName?: string
}

export type SpendAuthVModelCurrency = {
    context?: SpendAuthVModelCurrencyContext
    currencyCode?: string
    defaultFractionDigits?: number
    numericCode?: number
}

export type SpendAuthVModelAction =
    (typeof SpendAuthVModelAction)[keyof typeof SpendAuthVModelAction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpendAuthVModelAction = {
    AUTHORIZE: 'AUTHORIZE',
    REPLACE: 'REPLACE',
    SETTLE: 'SETTLE',
    VOID: 'VOID',
} as const

export interface SpendAuthV2Model {
    accountId?: string
    action?: SpendAuthVModelAction
    allocationId?: string
    approvedAmount?: number
    chainId?: string
    createdAt?: string
    currency?: SpendAuthVModelCurrency
    debitOrCredit?: SpendAuthVModelDebitOrCredit
    effectiveDate?: string
    id?: string
    idempotencyKey?: string
    metadata?: SpendAuthVModelMetadata
    pendingAmount?: number
    pricingBreakdown?: SpendAuthVModelPricingBreakdown
    referenceId?: string
    requestedAmount?: number
    spendType?: SpendAuthVModelSpendType
    status?: SpendAuthVModelStatus
}

export type SettleRequestVModelPricingBreakdown = { [key: string]: number }

export type SettleRequestVModelMetadata = { [key: string]: { [key: string]: any } }

export interface SettleRequestV2Model {
    allocationId?: string
    amount?: DirectedAmountV2Model
    companyId?: string
    effectiveDate?: string
    final?: boolean
    metadata?: SettleRequestVModelMetadata
    pricingBreakdown?: SettleRequestVModelPricingBreakdown
    referenceId?: string
}

export interface SetOverdraftLimitRequestV2Model {
    amount?: DirectedAmountV2Model
    companyId?: string
    referenceId?: string
}

export interface RequestedCompanies {
    companyIds?: string[]
}

export type ReplaceRequestVModelMetadata = { [key: string]: { [key: string]: any } }

export interface ReplaceRequestV2Model {
    amount?: DirectedAmountV2Model
    companyId?: string
    effectiveDate?: string
    metadata?: ReplaceRequestVModelMetadata
    referenceId?: string
}

export type HardMigrationReadinessCheckParametersCurrencyContext = {
    empty?: boolean
    providerName?: string
}

export type HardMigrationReadinessCheckParametersCurrency = {
    context?: HardMigrationReadinessCheckParametersCurrencyContext
    currencyCode?: string
    defaultFractionDigits?: number
    numericCode?: number
}

export interface HardMigrationReadinessCheckParameters {
    analyseLastDays?: number
    checkErsaExcessFunds?: CheckParametersDouble
    checkInSyncAtNight?: CheckParametersDouble
    checkPhobosExcessFunds?: CheckParametersDouble
    currency?: HardMigrationReadinessCheckParametersCurrency
    maxContinuousHrsOutOfSync?: CheckParametersLong
    maxOutOfSyncRate?: CheckParametersDouble
    maxPresentBalanceDifference?: CheckParametersLong
}

export interface HardMigrationRequestModel {
    companyIds?: string[]
    parameters?: HardMigrationReadinessCheckParameters
}

export interface GlAccountUpdateRequestV2Model {
    informationalName?: string
}

export interface GlAccountCreationResponseV2Model {
    accounts?: AccountV2Model[]
}

export type GlAccountCreationRequestVModelCurrenciesItemContext = {
    empty?: boolean
    providerName?: string
}

export type GlAccountCreationRequestVModelCurrenciesItem = {
    context?: GlAccountCreationRequestVModelCurrenciesItemContext
    currencyCode?: string
    defaultFractionDigits?: number
    numericCode?: number
}

export interface GlAccountCreationRequestV2Model {
    aliasName?: string
    currencies?: GlAccountCreationRequestVModelCurrenciesItem[]
}

export type DirectedAmountVModelDebitOrCredit =
    (typeof DirectedAmountVModelDebitOrCredit)[keyof typeof DirectedAmountVModelDebitOrCredit]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectedAmountVModelDebitOrCredit = {
    DEBIT: 'DEBIT',
    CREDIT: 'CREDIT',
} as const

export type DirectedAmountVModelCurrency = {
    context?: DirectedAmountVModelCurrencyContext
    currencyCode?: string
    defaultFractionDigits?: number
    numericCode?: number
}

export interface DirectedAmountV2Model {
    currency?: DirectedAmountVModelCurrency
    debitOrCredit?: DirectedAmountVModelDebitOrCredit
    value?: number
}

export type DirectedAmountVModelCurrencyContext = {
    empty?: boolean
    providerName?: string
}

export interface DeleteAllocationResponseModel {
    amountMovedToDefault?: number
    companyId?: string
    deletedAt?: string
    id?: string
}

export interface CreateAllocationRequestModel {
    allocationId?: string
    cap?: number
}

export type CompanySpendStatusUpdateRequestVModelStatus =
    (typeof CompanySpendStatusUpdateRequestVModelStatus)[keyof typeof CompanySpendStatusUpdateRequestVModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanySpendStatusUpdateRequestVModelStatus = {
    LIVE: 'LIVE',
    BLOCKED: 'BLOCKED',
    CLOSED: 'CLOSED',
} as const

export interface CompanySpendStatusUpdateRequestV2Model {
    status?: CompanySpendStatusUpdateRequestVModelStatus
    statusReason?: string
}

export type CompanySpendStatusResponseVModelStatus =
    (typeof CompanySpendStatusResponseVModelStatus)[keyof typeof CompanySpendStatusResponseVModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanySpendStatusResponseVModelStatus = {
    LIVE: 'LIVE',
    BLOCKED: 'BLOCKED',
    CLOSED: 'CLOSED',
} as const

export interface CompanySpendStatusResponseV2Model {
    companyId?: string
    status?: CompanySpendStatusResponseVModelStatus
    statusReason?: string
}

export type CompanyFeatureConfigurationResponseVModelSecondaryProcessorType =
    (typeof CompanyFeatureConfigurationResponseVModelSecondaryProcessorType)[keyof typeof CompanyFeatureConfigurationResponseVModelSecondaryProcessorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyFeatureConfigurationResponseVModelSecondaryProcessorType = {
    EXTERNAL: 'EXTERNAL',
    INTERNAL: 'INTERNAL',
} as const

export type CompanyFeatureConfigurationResponseVModelPrimaryProcessorType =
    (typeof CompanyFeatureConfigurationResponseVModelPrimaryProcessorType)[keyof typeof CompanyFeatureConfigurationResponseVModelPrimaryProcessorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyFeatureConfigurationResponseVModelPrimaryProcessorType = {
    EXTERNAL: 'EXTERNAL',
    INTERNAL: 'INTERNAL',
} as const

export type CompanyFeatureConfigurationResponseVModelBalanceMigrationMode =
    (typeof CompanyFeatureConfigurationResponseVModelBalanceMigrationMode)[keyof typeof CompanyFeatureConfigurationResponseVModelBalanceMigrationMode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyFeatureConfigurationResponseVModelBalanceMigrationMode = {
    HARD: 'HARD',
    SOFT: 'SOFT',
    NOT_MIGRATED: 'NOT_MIGRATED',
} as const

export interface CompanyFeatureConfigurationResponseV2Model {
    balanceMigrationMode?: CompanyFeatureConfigurationResponseVModelBalanceMigrationMode
    companyId?: string
    primaryProcessorType?: CompanyFeatureConfigurationResponseVModelPrimaryProcessorType
    secondaryProcessorType?: CompanyFeatureConfigurationResponseVModelSecondaryProcessorType
    shouldProcessCardPurchase?: boolean
    shouldProcessTransaction?: boolean
    shouldShowCardPurchaseExpense?: boolean
    shouldShowTransactionExpense?: boolean
    softMigratedAt?: string
}

export interface CompanyBalanceV2Model {
    allocations?: AllocationModel[]
    balance?: BalanceV2Model
    companyId?: string
}

export interface CheckParametersLong {
    enabled?: boolean
    valueAvailable?: number
    valueCurrent?: number
}

export interface CheckParametersDouble {
    enabled?: boolean
    valueAvailable?: number
    valueCurrent?: number
}

export type BatchUpdateAllocationConfigurationRequestModelAction =
    (typeof BatchUpdateAllocationConfigurationRequestModelAction)[keyof typeof BatchUpdateAllocationConfigurationRequestModelAction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BatchUpdateAllocationConfigurationRequestModelAction = {
    ENABLE: 'ENABLE',
    DISABLE: 'DISABLE',
} as const

export interface BatchUpdateAllocationConfigurationRequestModel {
    action?: BatchUpdateAllocationConfigurationRequestModelAction
    companyIds?: string[]
}

export interface BatchProcessFailedBalanceShadowingEventsResponseV2Model {
    jobId?: string
}

export type BalanceVModelCurrencyContext = {
    empty?: boolean
    providerName?: string
}

export interface BalanceV2Model {
    available?: number
    currency?: string
    current?: number
    overdraftLimit?: number
    pending?: number
    usedOverdraft?: number
}

export type BalanceShadowingEventStatusUpdateResponseVModelStatus =
    (typeof BalanceShadowingEventStatusUpdateResponseVModelStatus)[keyof typeof BalanceShadowingEventStatusUpdateResponseVModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BalanceShadowingEventStatusUpdateResponseVModelStatus = {
    PUBLISHED: 'PUBLISHED',
    PROCESSING: 'PROCESSING',
    BLOCKED: 'BLOCKED',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
} as const

export interface BalanceShadowingEventStatusUpdateResponseV2Model {
    accountId?: string
    chainId?: string
    id?: string
    idempotencyKey?: string
    referenceId?: string
    spendAuthId?: string
    status?: BalanceShadowingEventStatusUpdateResponseVModelStatus
}

export type BalanceShadowingEventStatusUpdateRequestVModelStatus =
    (typeof BalanceShadowingEventStatusUpdateRequestVModelStatus)[keyof typeof BalanceShadowingEventStatusUpdateRequestVModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BalanceShadowingEventStatusUpdateRequestVModelStatus = {
    PUBLISHED: 'PUBLISHED',
    PROCESSING: 'PROCESSING',
    BLOCKED: 'BLOCKED',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
} as const

export interface BalanceShadowingEventStatusUpdateRequestV2Model {
    status?: BalanceShadowingEventStatusUpdateRequestVModelStatus
}

export type AuthorizeRequestVModelMetadata = { [key: string]: { [key: string]: any } }

export interface AuthorizeRequestV2Model {
    allocationId?: string
    amount?: DirectedAmountV2Model
    companyId?: string
    effectiveDate?: string
    metadata?: AuthorizeRequestVModelMetadata
    referenceId?: string
}

export type AllocationResponseModelType =
    (typeof AllocationResponseModelType)[keyof typeof AllocationResponseModelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AllocationResponseModelType = {
    CUSTOM: 'CUSTOM',
    DEFAULT: 'DEFAULT',
} as const

export interface AllocationResponseModel {
    available?: number
    cap?: number
    companyId?: string
    counter?: number
    id?: string
    type?: AllocationResponseModelType
}

export type AllocationModelType = (typeof AllocationModelType)[keyof typeof AllocationModelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AllocationModelType = {
    CUSTOM: 'CUSTOM',
    DEFAULT: 'DEFAULT',
} as const

export interface AllocationModel {
    available?: number
    id?: string
    type?: AllocationModelType
}

export interface AllocationConfigurationResponseModel {
    allocationsEnabled?: boolean
    companyId?: string
}

export type AccountVModelStatus = (typeof AccountVModelStatus)[keyof typeof AccountVModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountVModelStatus = {
    LIVE: 'LIVE',
    BLOCKED: 'BLOCKED',
    CLOSED: 'CLOSED',
} as const

export type AccountVModelCurrencyContext = {
    empty?: boolean
    providerName?: string
}

export type AccountVModelCurrency = {
    context?: AccountVModelCurrencyContext
    currencyCode?: string
    defaultFractionDigits?: number
    numericCode?: number
}

export type AccountVModelAccountType =
    (typeof AccountVModelAccountType)[keyof typeof AccountVModelAccountType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountVModelAccountType = {
    STANDARD: 'STANDARD',
    GL: 'GL',
} as const

export interface AccountV2Model {
    accountType?: AccountVModelAccountType
    aliasName?: string
    availableBalance?: number
    codeName?: string
    companyId?: string
    createdAt?: string
    currency?: AccountVModelCurrency
    currentBalance?: number
    id?: string
    informationalName?: string
    overdraftLimit?: number
    status?: AccountVModelStatus
    statusReason?: string
    updatedAt?: string
}

export type AccountCreationRequestVModelCurrencyContext = {
    empty?: boolean
    providerName?: string
}

export type AccountCreationRequestVModelCurrency = {
    context?: AccountCreationRequestVModelCurrencyContext
    currencyCode?: string
    defaultFractionDigits?: number
    numericCode?: number
}

export interface AccountCreationRequestV2Model {
    currency?: AccountCreationRequestVModelCurrency
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * @summary Batch process failed balance shadowing events
 */
export const batchProcessFailedBalanceShadowingEvents = (
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BatchProcessFailedBalanceShadowingEventsResponseV2Model>(
        { url: `/v2/admin/balance-shadowing/batch`, method: 'post' },
        options
    )
}

/**
 * @summary Update balance shadowing event status
 */
export const updateBalanceShadowingEventStatus = (
    eventId: string,
    balanceShadowingEventStatusUpdateRequestV2Model: BalanceShadowingEventStatusUpdateRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BalanceShadowingEventStatusUpdateResponseV2Model>(
        {
            url: `/v2/admin/balance-shadowing/${eventId}/status`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: balanceShadowingEventStatusUpdateRequestV2Model,
        },
        options
    )
}

/**
 * @summary Batch enable or disable allocation feature for a list of companies
 */
export const updateAllocationConfig = (
    batchUpdateAllocationConfigurationRequestModel: BatchUpdateAllocationConfigurationRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/admin/companies/allocation-feature-config`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: batchUpdateAllocationConfigurationRequestModel,
        },
        options
    )
}

/**
 * @summary [migration operation] - (batch) synchronises balances between Phobos and Ersa, with Phobos as source of truth.
 */
export const batchBalancesSynchronisation = (
    requestedCompanies: RequestedCompanies,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/admin/companies/async-balance-sync`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: requestedCompanies,
        },
        options
    )
}

/**
 * @summary Only to be used with permission from FinCore! Updates the ownership of the companies balance. Possible values are newOwner=ENFUCE or newOwner=ERSA.Companies need to be in the soft-migrated state at least 7 days before the hard migration.
 */
export const hardMigrate = (
    hardMigrationRequestModel: HardMigrationRequestModel,
    params: HardMigrateParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/admin/companies/hard-migrate`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: hardMigrationRequestModel,
            params,
        },
        options
    )
}

/**
 * @summary Only to be used with permission from FinCore! Softly migrates a batch (advised size < 100) of companies.  Soft migration means that the balances are not migrated, but Eras balance supposed to match Enfuce's at all times. For this the balances are synchronised and the feature configuration is updated.
 */
export const softMigrate = (
    requestedCompanies: RequestedCompanies,
    params: SoftMigrateParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v2/admin/companies/soft-migrate`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: requestedCompanies,
            params,
        },
        options
    )
}

/**
 * @summary Deletes a company account
 */
export const deleteAccount = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v2/admin/companies/${companyId}/accounts`, method: 'delete' },
        options
    )
}

/**
 * @summary Disables the allocation feature for the company and deletes all existing allocations
 */
export const disableAndDeleteAllocations = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AllocationConfigurationResponseModel>(
        { url: `/v2/admin/companies/${companyId}/disable-allocations`, method: 'post' },
        options
    )
}

/**
 * @summary Enable allocations for a company
 */
export const enableAllocations = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AllocationConfigurationResponseModel>(
        { url: `/v2/admin/companies/${companyId}/enable-allocations`, method: 'post' },
        options
    )
}

/**
 * @summary Updates company spend status in Ersa account only, skipping a call to Phobos
 */
export const updateInternalCompanySpendStatus = (
    companyId: string,
    companySpendStatusUpdateRequestV2Model: CompanySpendStatusUpdateRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanySpendStatusResponseV2Model>(
        {
            url: `/v2/admin/companies/${companyId}/internal-spend-status`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companySpendStatusUpdateRequestV2Model,
        },
        options
    )
}

/**
 * @summary Gets company spend status
 */
export const getCompanySpendStatus = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanySpendStatusResponseV2Model>(
        { url: `/v2/admin/companies/${companyId}/spend-status`, method: 'get' },
        options
    )
}

/**
 * @summary Updates company spend status
 */
export const updateCompanySpendStatus = (
    companyId: string,
    companySpendStatusUpdateRequestV2Model: CompanySpendStatusUpdateRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanySpendStatusResponseV2Model>(
        {
            url: `/v2/admin/companies/${companyId}/spend-status`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: companySpendStatusUpdateRequestV2Model,
        },
        options
    )
}

/**
 * @summary Creates General Ledger accounts for an aliasName
 */
export const createGlAccounts = (
    glAccountCreationRequestV2Model: GlAccountCreationRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GlAccountCreationResponseV2Model>(
        {
            url: `/v2/admin/general-ledger-accounts`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: glAccountCreationRequestV2Model,
        },
        options
    )
}

/**
 * @summary Updates General Ledger account
 */
export const updateGlAccount = (
    accountId: string,
    glAccountUpdateRequestV2Model: GlAccountUpdateRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AccountV2Model>(
        {
            url: `/v2/admin/general-ledger-accounts/${accountId}`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: glAccountUpdateRequestV2Model,
        },
        options
    )
}

/**
 * @summary Publish all existing record in database to kafka
 */
export const publishFeatureConfiguration = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v2/admin/publish/feature-configuration`, method: 'post' },
        options
    )
}

export const removePendingSpendAuths = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AccountV2Model>(
        { url: `/v2/admin/${companyId}/remove-pending`, method: 'post' },
        options
    )
}

/**
 * @summary Voids existing spend - internal balance only
 */
export const voidInternalBalance = (
    spendType:
        | 'balance-amendment'
        | 'bill'
        | 'cashback'
        | 'card-purchase'
        | 'load-v2'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'personal-transfer'
        | 'pocket-bank-transfer-reimbursement'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'manual-chargeback'
        | 'manual-pleo-refund'
        | 'manual-chargeback-reversal'
        | 'admin-adjustment'
        | 'overdraft-fee'
        | 'overdraft-interest',
    chainId: string,
    voidRequestV2Model: VoidRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SpendAuthV2Model>(
        {
            url: `/v2/admin/${spendType}/${chainId}/void`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: voidRequestV2Model,
        },
        options
    )
}

/**
 * @summary Force settles chargeback
 */
export const chargeback = (
    settleRequestV2Model: SettleRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SpendAuthV2Model>(
        {
            url: `/v2/backoffice/chargeback`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: settleRequestV2Model,
        },
        options
    )
}

/**
 * @summary Get company feature configurations based on filters
 */
export const getCompanyFeatureConfigurations = (
    params: GetCompanyFeatureConfigurationsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyFeatureConfigurationResponseV2Model[]>(
        { url: `/v2/companies/feature-config`, method: 'get', params },
        options
    )
}

/**
 * @summary Sets a company's overdraft limit
 */
export const setCompanyOverdraftLimit = (
    setOverdraftLimitRequestV2Model: SetOverdraftLimitRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyBalanceV2Model>(
        {
            url: `/v2/companies/overdraft-limit`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: setOverdraftLimitRequestV2Model,
        },
        options
    )
}

/**
 * @summary Creates a company account
 */
export const createAccount = (
    companyId: string,
    accountCreationRequestV2Model: AccountCreationRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AccountV2Model>(
        {
            url: `/v2/companies/${companyId}/accounts`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: accountCreationRequestV2Model,
        },
        options
    )
}

/**
 * @summary Create an allocation for a company
 */
export const createCompanyAllocation = (
    companyId: string,
    createAllocationRequestModel: CreateAllocationRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AllocationResponseModel>(
        {
            url: `/v2/companies/${companyId}/allocations`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createAllocationRequestModel,
        },
        options
    )
}

/**
 * @summary Deletes a companies allocation
 */
export const deleteAllocation = (
    companyId: string,
    allocationId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DeleteAllocationResponseModel>(
        { url: `/v2/companies/${companyId}/allocations/${allocationId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Moves funds between a companies custom and default allocations
 */
export const updateAllocationFunds = (
    companyId: string,
    allocationId: string,
    updateAllocationFundsRequestModel: UpdateAllocationFundsRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<AllocationResponseModel>(
        {
            url: `/v2/companies/${companyId}/allocations/${allocationId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateAllocationFundsRequestModel,
        },
        options
    )
}

/**
 * @summary Fetches company balance
 */
export const getCompanyBalance = (
    companyId: string,
    params: GetCompanyBalanceParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyBalanceV2Model>(
        { url: `/v2/companies/${companyId}/balance`, method: 'get', params },
        options
    )
}

/**
 * @summary Gets company processor feature configuration from Ersa database
 */
export const getCompanyFeatureConfiguration = (
    companyId: string,
    params: GetCompanyFeatureConfigurationParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyFeatureConfigurationResponseV2Model>(
        { url: `/v2/companies/${companyId}/feature-config`, method: 'get', params },
        options
    )
}

/**
 * @summary Fetches company balance internal balance regardless the configuration
 */
export const getCompanyInternalBalance = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyBalanceV2Model>(
        { url: `/v2/companies/${companyId}/internal-balance`, method: 'get' },
        options
    )
}

/**
 * @summary Authorizes customer spend
 */
export const authorize = (
    spendType:
        | 'balance-amendment'
        | 'bill'
        | 'cashback'
        | 'card-purchase'
        | 'load-v2'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'personal-transfer'
        | 'pocket-bank-transfer-reimbursement'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'manual-chargeback'
        | 'manual-pleo-refund'
        | 'manual-chargeback-reversal'
        | 'admin-adjustment'
        | 'overdraft-fee'
        | 'overdraft-interest',
    chainId: string,
    authorizeRequestV2Model: AuthorizeRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SpendAuthV2Model>(
        {
            url: `/v2/spend-auths/${spendType}/${chainId}/authorize`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: authorizeRequestV2Model,
        },
        options
    )
}

/**
 * @summary Authorizes customer spend without a check for sufficient funds
 */
export const forceAuthorize = (
    spendType:
        | 'balance-amendment'
        | 'bill'
        | 'cashback'
        | 'card-purchase'
        | 'load-v2'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'personal-transfer'
        | 'pocket-bank-transfer-reimbursement'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'manual-chargeback'
        | 'manual-pleo-refund'
        | 'manual-chargeback-reversal'
        | 'admin-adjustment'
        | 'overdraft-fee'
        | 'overdraft-interest',
    chainId: string,
    authorizeRequestV2Model: AuthorizeRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SpendAuthV2Model>(
        {
            url: `/v2/spend-auths/${spendType}/${chainId}/force-authorize`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: authorizeRequestV2Model,
        },
        options
    )
}

/**
 * @summary Settles customer spend without a check for sufficient funds
 */
export const forceSettle = (
    spendType:
        | 'balance-amendment'
        | 'bill'
        | 'cashback'
        | 'card-purchase'
        | 'load-v2'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'personal-transfer'
        | 'pocket-bank-transfer-reimbursement'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'manual-chargeback'
        | 'manual-pleo-refund'
        | 'manual-chargeback-reversal'
        | 'admin-adjustment'
        | 'overdraft-fee'
        | 'overdraft-interest',
    chainId: string,
    settleRequestV2Model: SettleRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SpendAuthV2Model>(
        {
            url: `/v2/spend-auths/${spendType}/${chainId}/force-settle`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: settleRequestV2Model,
        },
        options
    )
}

/**
 * @summary Replaces existing spend auth
 */
export const replace = (
    spendType:
        | 'balance-amendment'
        | 'bill'
        | 'cashback'
        | 'card-purchase'
        | 'load-v2'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'personal-transfer'
        | 'pocket-bank-transfer-reimbursement'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'manual-chargeback'
        | 'manual-pleo-refund'
        | 'manual-chargeback-reversal'
        | 'admin-adjustment'
        | 'overdraft-fee'
        | 'overdraft-interest',
    chainId: string,
    replaceRequestV2Model: ReplaceRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SpendAuthV2Model>(
        {
            url: `/v2/spend-auths/${spendType}/${chainId}/replace`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: replaceRequestV2Model,
        },
        options
    )
}

/**
 * @summary Settles initial or existing spend
 */
export const settle = (
    spendType:
        | 'balance-amendment'
        | 'bill'
        | 'cashback'
        | 'card-purchase'
        | 'load-v2'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'personal-transfer'
        | 'pocket-bank-transfer-reimbursement'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'manual-chargeback'
        | 'manual-pleo-refund'
        | 'manual-chargeback-reversal'
        | 'admin-adjustment'
        | 'overdraft-fee'
        | 'overdraft-interest',
    chainId: string,
    settleRequestV2Model: SettleRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SpendAuthV2Model>(
        {
            url: `/v2/spend-auths/${spendType}/${chainId}/settle`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: settleRequestV2Model,
        },
        options
    )
}

/**
 * @summary Voids existing spend
 */
export const _void = (
    spendType:
        | 'balance-amendment'
        | 'bill'
        | 'cashback'
        | 'card-purchase'
        | 'load-v2'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'personal-transfer'
        | 'pocket-bank-transfer-reimbursement'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'manual-chargeback'
        | 'manual-pleo-refund'
        | 'manual-chargeback-reversal'
        | 'admin-adjustment'
        | 'overdraft-fee'
        | 'overdraft-interest',
    chainId: string,
    voidRequestV2Model: VoidRequestV2Model,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SpendAuthV2Model>(
        {
            url: `/v2/spend-auths/${spendType}/${chainId}/void`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: voidRequestV2Model,
        },
        options
    )
}

export type BatchProcessFailedBalanceShadowingEventsResult = NonNullable<
    Awaited<ReturnType<typeof batchProcessFailedBalanceShadowingEvents>>
>
export type UpdateBalanceShadowingEventStatusResult = NonNullable<
    Awaited<ReturnType<typeof updateBalanceShadowingEventStatus>>
>
export type UpdateAllocationConfigResult = NonNullable<
    Awaited<ReturnType<typeof updateAllocationConfig>>
>
export type BatchBalancesSynchronisationResult = NonNullable<
    Awaited<ReturnType<typeof batchBalancesSynchronisation>>
>
export type HardMigrateResult = NonNullable<Awaited<ReturnType<typeof hardMigrate>>>
export type SoftMigrateResult = NonNullable<Awaited<ReturnType<typeof softMigrate>>>
export type DeleteAccountResult = NonNullable<Awaited<ReturnType<typeof deleteAccount>>>
export type DisableAndDeleteAllocationsResult = NonNullable<
    Awaited<ReturnType<typeof disableAndDeleteAllocations>>
>
export type EnableAllocationsResult = NonNullable<Awaited<ReturnType<typeof enableAllocations>>>
export type UpdateInternalCompanySpendStatusResult = NonNullable<
    Awaited<ReturnType<typeof updateInternalCompanySpendStatus>>
>
export type GetCompanySpendStatusResult = NonNullable<
    Awaited<ReturnType<typeof getCompanySpendStatus>>
>
export type UpdateCompanySpendStatusResult = NonNullable<
    Awaited<ReturnType<typeof updateCompanySpendStatus>>
>
export type CreateGlAccountsResult = NonNullable<Awaited<ReturnType<typeof createGlAccounts>>>
export type UpdateGlAccountResult = NonNullable<Awaited<ReturnType<typeof updateGlAccount>>>
export type PublishFeatureConfigurationResult = NonNullable<
    Awaited<ReturnType<typeof publishFeatureConfiguration>>
>
export type RemovePendingSpendAuthsResult = NonNullable<
    Awaited<ReturnType<typeof removePendingSpendAuths>>
>
export type VoidInternalBalanceResult = NonNullable<Awaited<ReturnType<typeof voidInternalBalance>>>
export type ChargebackResult = NonNullable<Awaited<ReturnType<typeof chargeback>>>
export type GetCompanyFeatureConfigurationsResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyFeatureConfigurations>>
>
export type SetCompanyOverdraftLimitResult = NonNullable<
    Awaited<ReturnType<typeof setCompanyOverdraftLimit>>
>
export type CreateAccountResult = NonNullable<Awaited<ReturnType<typeof createAccount>>>
export type CreateCompanyAllocationResult = NonNullable<
    Awaited<ReturnType<typeof createCompanyAllocation>>
>
export type DeleteAllocationResult = NonNullable<Awaited<ReturnType<typeof deleteAllocation>>>
export type UpdateAllocationFundsResult = NonNullable<
    Awaited<ReturnType<typeof updateAllocationFunds>>
>
export type GetCompanyBalanceResult = NonNullable<Awaited<ReturnType<typeof getCompanyBalance>>>
export type GetCompanyFeatureConfigurationResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyFeatureConfiguration>>
>
export type GetCompanyInternalBalanceResult = NonNullable<
    Awaited<ReturnType<typeof getCompanyInternalBalance>>
>
export type AuthorizeResult = NonNullable<Awaited<ReturnType<typeof authorize>>>
export type ForceAuthorizeResult = NonNullable<Awaited<ReturnType<typeof forceAuthorize>>>
export type ForceSettleResult = NonNullable<Awaited<ReturnType<typeof forceSettle>>>
export type ReplaceResult = NonNullable<Awaited<ReturnType<typeof replace>>>
export type SettleResult = NonNullable<Awaited<ReturnType<typeof settle>>>
export type _VoidResult = NonNullable<Awaited<ReturnType<typeof _void>>>
