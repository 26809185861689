import type { FC } from 'react'
import {
    overridePleoReserveEligibility,
    usePleoReserveEligibility,
    usePleoReserveMetadata,
} from 'services/deimos/pleo-reserve'
import { Form, Select, SubmitButton, Input } from 'formik-antd'
import * as yup from 'yup'
import type { PleoReserveEligibilityEligibilityEnum } from '@pleo-io/narvi-ts-models'
import {
    selectCompanyCountry,
    selectCompanyCurrency,
    selectCompanyId,
} from 'store/modules/support/company/selectors'
import { useSelector } from 'react-redux'
import { Formik, FormikHelpers, useFormikContext } from 'formik'
import { Modal, notification } from 'antd'

const { TextArea } = Input

const useDefaultReserveLimitByCurrency = (currency: string) => {
    const { data } = usePleoReserveMetadata()
    return (
        data?.defaultLimitByCurrency?.find(
            (limitByCurrency) => limitByCurrency.currency === currency
        )?.limit ?? 0
    )
}

const getValidationSchema = ({ minLimit }: { minLimit: number }) =>
    yup.object().shape({
        eligibility: yup.string().required('This field is required'),
        updateReason: yup.string().required('This field is required'),
        limit: yup.number().required('This field is required').min(minLimit),
    })

type EligibilityFormValues = {
    eligibility: PleoReserveEligibilityEligibilityEnum
    updateReason: string
    limit: number
}

type PleoReserveEligibilityModalProps = {
    isVisible: boolean
    closeModal: () => void
}

export const PleoReserveEligibilityModal: FC<PleoReserveEligibilityModalProps> = ({
    closeModal,
    isVisible,
}) => {
    const country = useSelector(selectCompanyCountry)
    const currency = useSelector(selectCompanyCurrency)
    const companyId = useSelector(selectCompanyId)
    const pleoReserve = usePleoReserveEligibility(companyId)
    const { productState, terms } = pleoReserve.data ?? {}
    const defaultLimit = useDefaultReserveLimitByCurrency(currency)

    const onSubmit = async (
        formValues: EligibilityFormValues,
        { resetForm }: FormikHelpers<EligibilityFormValues>
    ) => {
        try {
            await overridePleoReserveEligibility({
                companyId,
                currency,
                limit: formValues.limit,
                eligibility: formValues.eligibility,
                updateReason: formValues.updateReason,
                state: productState ?? 'INACTIVE',
                region: country,
            })
            notification.success({
                message: 'Eligibility state change successful!',
            })
            pleoReserve.mutate()
            resetForm()
            closeModal()
        } catch (error) {
            notification.error({
                message: 'Eligibility state change failed!',
            })
        }
    }
    const initialValues: EligibilityFormValues = {
        updateReason: '',
        eligibility: pleoReserve.data?.eligibility ?? 'INELIGIBLE',
        limit: terms?.reserveLimit ?? defaultLimit,
    }
    if (!isVisible || pleoReserve.isLoading) {
        return null
    }
    return (
        <Modal
            open={isVisible}
            onCancel={closeModal}
            footer={false}
            centered
            title="Pleo Reserve eligibility settings"
        >
            <Formik
                enableReinitialize
                validationSchema={getValidationSchema({ minLimit: defaultLimit })}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                <PleoReserveEligibilityFormContent />
            </Formik>
        </Modal>
    )
}

const PleoReserveEligibilityFormContent = () => {
    const { dirty, isValid } = useFormikContext()
    const currency = useSelector(selectCompanyCurrency)
    return (
        <Form layout="vertical">
            <Form.Item name="eligibility" label="Eligibility" required>
                <Select name="eligibility">
                    <Select.Option value="ELIGIBLE">ELIGIBLE</Select.Option>
                    <Select.Option value="INELIGIBLE">INELIGIBLE</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="limit" label="Limit">
                <Input name="limit" suffix={currency} />
            </Form.Item>
            <Form.Item name="updateReason" label="Update reason" required>
                <TextArea
                    name="updateReason"
                    placeholder="Describe the reason for this eligibility change..."
                />
            </Form.Item>
            <SubmitButton disabled={!dirty || !isValid}>Submit</SubmitButton>
        </Form>
    )
}
