import { useState } from 'react'
import { Card, Button, message, Space, Descriptions, Typography } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import { Formik } from 'formik'
import { Input as AntInput, Form } from 'formik-antd'
import * as Yup from 'yup'
import { createBffHooks } from '../../packages/bff/create-bff-hooks'
import type { ComponentsRouter } from 'components/index.bff'
import styled from 'styled-components'

const bff = createBffHooks<ComponentsRouter>().components.replayTransaction
const validationSchema = Yup.object().shape({
    transactionId: Yup.string().required('Transaction ID is required'),
})

const ReplayTransaction = () => {
    const [isLoading, setLoading] = useState(false)
    const [transactionReplayed, setTransactionReplayed] = useState(false)
    const [confirmedTransactionIds, setConfirmedTransactionIds] = useState<string[]>([])
    const { mutateAsync: replayTransaction } = bff.replayTransaction.useMutation()
    const notionLink = 'https://www.notion.so/pleo/Replay-Transactions-4b4e662a94e34533a5616feb31fc6ccd?pvs=4'
    const InputLarge = styled(AntInput)`
        width: 312px
    `

    const confirmationLink = (transactionId: string) => {
        return `https://app.datadoghq.eu/logs?query=env%3Aproduct-production%20service%3Adeimos%20endor%20expense%20${encodeURIComponent(
            transactionId
        )}%20&agg_m=count&agg_m_source=base&agg_t=count&cols=host%2Cservice&fromUser=true&messageDisplay=inline&refresh_mode=sliding&storage=hot&stream_sort=desc&viz=stream&from_ts=1716213424552&to_ts=1716217024552&live=true`
    }

    const replay = async (transactionId: string) => {
        try {
            setLoading(true)
            if (confirmedTransactionIds.includes(transactionId)) {
                throw new Error('Transaction already replayed')
            }

            await replayTransaction({ transactionId })
            setTransactionReplayed(true)
            setConfirmedTransactionIds([...confirmedTransactionIds, transactionId])
            message.success(`Successfully replayed transaction ${transactionId}.`)
        } catch (e) {
            message.error(`Error replaying transaction: ${(e as Error).message}`)
        } finally {
            setLoading(false)
        }
    }

    const transactionInfo = () => {
        return (
            <Descriptions column={1}>
                {confirmedTransactionIds.map((value) => (
                    <Descriptions.Item label={'Confirmation logs for'} key={value}>
                        <a href={confirmationLink(value)} target="_blank" rel="noopener noreferrer">
                            {value}
                        </a>
                    </Descriptions.Item>
                ))}
            </Descriptions>
        )
    }

    return (
        <PageContentLayout>
            <Typography.Link
                href={notionLink}
                target="_blank"
            >
                Documentation
            </Typography.Link>
            <Card title="Replay Transaction">
                <Formik
                    validationSchema={validationSchema}
                    validateOnMount
                    initialValues={{ transactionId: '' }}
                    onSubmit={({ transactionId }) => {
                        replay(transactionId.trim())
                    }}
                >
                    {({ values }) => (
                        <Form layout="inline">
                            <Space direction={'vertical'} size="large">
                                <Space>
                                    <Form.Item name="transactionId" label="Transaction ID">
                                        <InputLarge
                                            name="transactionId"
                                            placeholder="16596047400"
                                        />
                                    </Form.Item>
                                    <Form.Item name="submit">
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={isLoading}
                                            disabled={!values.transactionId}
                                        >
                                            Replay Transaction
                                        </Button>
                                    </Form.Item>
                                </Space>
                                {transactionReplayed && transactionInfo()}
                            </Space>
                        </Form>
                    )}
                </Formik>
            </Card>
        </PageContentLayout>
    )
}

export default ReplayTransaction
