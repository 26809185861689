import type { Employee } from '@pleo-io/deimos'
import type { GetEmployeeExtendedResponse } from '@pleo-io/deimos/dist/contracts/v1/employee'
import { fetcher } from 'services/deimos/request'
import useSWR from 'swr'

export function useEmployees(deimosCompanyId?: string) {
    const result = useSWR<Employee[]>(
        deimosCompanyId ? `rest/v1/companies/${deimosCompanyId}/employees` : null,
        fetcher,
        {
            shouldRetryOnError: false,
        }
    )

    return result
}

export const useEmployee = (employeeId?: string) => {
    const url = `rest/v1/employees/${employeeId}`
    const result = useSWR<GetEmployeeExtendedResponse>(employeeId ? url : null, fetcher, {
        revalidateOnFocus: false,
    })

    return {
        employee: result.data,
        ...result,
    }
}
