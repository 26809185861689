import type { EmployeeType } from '@pleo-io/deimos'

export interface Employee {
    type?: EmployeeType
    id: string
    verified?: boolean
    active?: boolean
    acceptedTerms?: boolean
    emailChange?: string
    userId: string
    companyId?: string | null
    partnerId?: string | null
    firstName: string
    lastName: string
    jobTitle?: string
    email: string
    phone?: string
    avatar?: Avatar
    code?: string
    createdAt: string
    updatedAt?: string
    deletedAt?: string
    role?: EmployeeRole | null
    departments?: Department[]
    limits?: Limit[]
    reviewer?: string[]
    permissions?: EmployeePermission[]
    isSpendingEntity?: boolean
}

export interface OberonEmployee extends Omit<Employee, 'role'> {
    role?: OberonEmployeeRole | null
}

export interface EmployeePlusInsurance extends Employee {
    isInsured: boolean
    planCode?: string
    insuranceEndDate?: string
}

export interface PartnerEmployee {
    id: string
    userId: string
    partnerId: string
    firstName: string
    lastName: string
    phone?: string
    avatar?: Avatar
    email: string
    role: PartnerRole
    type?: EmployeeType
    createdAt: string
    updatedAt?: string
    deletedAt?: string
    clientCompanyIds: string[]
}

export interface EmployeePayload {
    email: string
    employeeId: string
}
export interface Avatar {
    url: string
}

export interface Department {
    id: string
    name: string
    code: string
    role: string
}

export interface Limit {
    id: string
    employeeId: string
    group: string
    type: string
    value: LimitValue
    createdAt: string
    updatedAt: string
    deletedAt: string
    spend: number
    currency: string
    left: number
}

export interface LimitValue {
    value: string
    date?: string
}

export enum EmployeeRole {
    OWNER = 'owner',
    MEMBER = 'member',
    BOOKKEEPER = 'bookkeeper',
    BOOKKEEPER_BASIC = 'bookkeeper-basic',
    BOOKKEEPER_EXTENDED = 'bookkeeper-extended',
    BOOKKEEPER_MANAGED = 'bookkeeper-managed',
}

/**
 * Oberon employee role are in uppercase with underscores
 */
export enum OberonEmployeeRole {
    OWNER = 'OWNER',
    MEMBER = 'MEMBER',
    BOOKKEEPER = 'BOOKKEEPER',
    BOOKKEEPER_BASIC = 'BOOKKEEPER_BASIC',
    BOOKKEEPER_EXTENDED = 'BOOKKEEPER_EXTENDED',
    BOOKKEEPER_MANAGED = 'BOOKKEEPER_MANAGED',
}

export enum PartnerRole {
    OWNER = 'owner',
    MEMBER = 'member',
}

export enum DisplayRole {
    ADMIN = 'Admin',
    EMPLOYEE = 'Employee',
    BOOKKEEPER_BASIC = 'bookkeeper-basic',
    BOOKKEEPER_EXTENDED = 'bookkeeper-extended',
}

export enum PartnerDisplayRole {
    ADMIN = 'Admin',
    MEMBER = 'Member',
}
export enum EmployeePermission {
    CARD_ACCESS = 'cardAccess',
}

export type BookkeeperDisplayedRole = 'basic' | 'extended' | 'partner'

export interface Bookkeeper {
    userId: string
    firstName: string
    lastName: string
    email: string
    displayedRole?: BookkeeperDisplayedRole
}

export type EmployeeRoleType =
    | 'Admin'
    | 'Employee'
    | 'Bookkeeper (extended)'
    | 'Bookkeeper (basic)'
    | 'Orphan Employee'
    | 'Partner Admin'
    | 'Partner Member'
    | 'Unknown'
