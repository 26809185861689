import { deSnakify } from 'utils/strings'
import { Tag, Typography } from 'antd'
import type {
    InitiateOffboardingRequestReasonsItem,
    InitiateOffboardingRequestCategory,
} from 'bff/moons/generated/thanatos'

const { Paragraph } = Typography

export enum DisplayOffboardingReason {
    REJECTED = 'Rejected',
    DUPLICATE = 'Duplicate',
    BANKRUPTCY = 'Bankruptcy',
    COMPETITOR = 'Competitor',
    OTHER_BREACH_OF_TERMS_AND_CONDITIONS = 'Other breach of T&C',
    AML = 'AML',
    CTF = 'CTF',
    RISK_APPETITE = 'Risk appetite',
    BAD_TIMING_ONE_MONTH = 'Bad timing - One month',
    BAD_TIMING_THREE_MONTH = 'Bad timing - Three months',
    BAD_TIMING_SIX_MONTH = 'Bad timing - Six months',
    BAD_TIMING_ONE_YEAR = 'Bad timing - One year',
    BAD_TIMING_UNKNOWN = 'Bad timing - Unknown',
    BAD_TIMING_OTHER = 'Bad timing - Other',
    COMPLICATED_SETUP_WALLET_LOAD = 'Complicated setup - Wallet load',
    COMPLICATED_SETUP_CARD_ACTIVATION = 'Complicated setup - Card activation',
    COMPLICATED_SETUP_ACCOUNTING_INTEGRATION = 'Complicated setup - Accounting integration',
    COMPLICATED_SETUP_PERSONAL_VERIFICATION = 'Complicated setup - Personal verification',
    COMPLICATED_SETUP_COMPANY_VERIFICATION = 'Complicated setup - Company verification',
    MISSING_FEATURE_ACCOUNTING_INTEGRATION = 'Missing feature - Accounting integrations',
    MISSING_FEATURE_CREDIT = 'Missing feature - Credit',
    MISSING_FEATURE_MULTI_CURRENCY = 'Missing feature - Multi currency',
    MISSING_FEATURE_OPEN_API = 'Missing feature - Open API',
    MISSING_FEATURE_REPRESENTATION = 'Missing feature - Representation',
    MISSING_FEATURE_SPLIT_RECEIPT = 'Missing feature - Split receipt',
    MISSING_FEATURE_TEAM_HIERARCHY = 'Missing feature - Team hierarchy',
    MISSING_FEATURE_AUTO_VAT = 'Missing feature - Auto VAT',
    MISSING_FEATURE_PDF_RECEIPTS = 'Missing feature - PDF receipts',
    MISSING_FEATURE_EMAIL_RECEIPTS = 'Missing feature - Email receipts',
    MISSING_FEATURE_PROJECT_MANAGEMENT = 'Missing feature - Project management',
    MISSING_FEATURE_BILLABLE_EXPENSES = 'Missing feature - Billable expenses',
    MISSING_FEATURE_INVOICE_MANAGEMENT = 'Missing feature - Invoice management',
    MISSING_FEATURE_LOUNGE_PASS = 'Missing feature - Lounge pass',
    MISSING_FEATURE_MULTI_LOGIN = 'Missing feature - Multi login',
    MISSING_FEATURE_TEAM_LIMITS = 'Missing feature - Team limits',
    MISSING_FEATURE_OTHER = 'Missing feature - Other',
    MISUNDERSTOOD_PRODUCT_BANK_ACCOUNT = 'Misunderstood product - Bank account',
    MISUNDERSTOOD_PRODUCT_OTHER = 'Misunderstood product - Other',
    MOVED_TO_COMPETITOR = 'Moved to competitor',
    MOVED_TO_COMPETITOR_SPENDESK = 'Moved to competitor - Spendesk',
    MOVED_TO_COMPETITOR_SOLDO = 'Moved to competitor - Soldo',
    MOVED_TO_COMPETITOR_MOSS = 'Moved to competitor - Moss',
    MOVED_TO_COMPETITOR_RAMP = 'Moved to competitor - Ramp',
    MOVED_TO_COMPETITOR_OTHER = 'Moved to competitor - Other',
    ORGANIZATION_CLOSING_COMPANY = 'Organization - Closing company',
    ORGANIZATION_MERGING_ENTITY = 'Organization - Merging entity',
    ORGANIZATION_OTHER = 'Organization - Other',
    ORGANIZATION_PLEO_CHAMPION_LEFT = 'Organization - Pleo Champion left the company',
    PRICING_LACK_FUNCTIONALITY = 'Pricing - Lack functionality',
    PRICING_STRUCTURE = 'Pricing - Missing Pricing Plan fit',
    PRICING_TOO_EXPENSIVE = 'Pricing - Too expensive',
    PRICING_OTHER = 'Pricing - Other',
    OTHER = 'Other',
    OTHER_INVALID_SIGN_UP_FAKE = 'Other - Invalid sign up - Fake',
    OTHER_INVALID_SIGN_UP_DUPLICATED = 'Other - Invalid sign up - Duplicate',
    OTHER_LOW_EXPENSE_VOLUME = 'Other - Expense volume too low',
    OTHER_FEEDBACK_REFUSED = 'Other - No feedback - Refused',
    OTHER_FEEDBACK_UNANSWERED = 'Other - No feedback - Unanswered',
}

export const categoryTagColor = (category: string) => {
    switch (category) {
        case 'ACTIVE':
            return 'magenta'
        case 'LEGAL':
            return 'orange'
        case 'OTHER':
            return 'purple'
        case 'SILENT':
            return 'cyan'
        default:
            return ''
    }
}

export const showReasons = (reasons: InitiateOffboardingRequestReasonsItem[] | undefined) => {
    if (reasons === undefined || reasons.length === 0) return '-'

    return reasons.map((reason: InitiateOffboardingRequestReasonsItem) => (
        <Tag key="content-1">{DisplayOffboardingReason[reason]}</Tag>
    ))
}

export const showCategory = (category: InitiateOffboardingRequestCategory | undefined) => {
    if (!category) return '-'

    return <Tag color={categoryTagColor(category)}>{deSnakify(category)}</Tag>
}

export const showNotes = (notes: string | null | undefined) => {
    if (!notes) return '-'

    return (
        <Paragraph
            style={{ margin: 0 }}
            type="secondary"
            ellipsis={{
                rows: 1,
                expandable: true,
                symbol: 'more',
            }}
        >
            {notes}
        </Paragraph>
    )
}
