import { Card, Typography } from 'antd'
import * as s from './company-spending.styles'
import { bff } from 'pages/compliance/bff'
import type { CompanySpendStatusResponseVModelStatus as CompanySpendStatus } from 'bff/moons/generated/ersa'
import { CompanySpendingCard } from './company-spending-card'

const { Text } = Typography

const CompanySpendingStatusContainer = ({ companyId }: { companyId: string }) => {
    const {
        data: companySpend,
        isLoading,
        isFetching,
        error,
    } = bff.companySpending.getCompanySpendStatus.useQuery({ companyId })
    const { mutateAsync: updateCompanySpendStatus } =
        bff.companySpending.updateCompanySpendStatus.useMutation()

    const onUpdateSpendingStatus = (newStatus: CompanySpendStatus, reason: string) => {
        updateCompanySpendStatus({
            companyId,
            status: newStatus,
            reason,
        })
    }

    if (error || !companySpend) {
        return (
            <Card title="Company Spending Status">
                <s.Container>
                    <s.IconWrapper>
                        <s.QuestionIcon />
                    </s.IconWrapper>
                    <Text>{error?.message || 'An unknown error occurred.'}</Text>
                </s.Container>
            </Card>
        )
    }

    const { status, statusReason, providerName } = companySpend
    const loading = isLoading || isFetching

    return (
        <Card title="Company Spending Status" loading={loading}>
            <CompanySpendingCard
                statusReason={statusReason}
                spendingStatus={status}
                providerName={providerName}
                onUpdateSpendingStatus={onUpdateSpendingStatus}
            />
        </Card>
    )
}

export default CompanySpendingStatusContainer
