import useSWR from 'swr'
import { buildFetcher, pandoraRequest } from 'services/request'
import type { RolesHistoryResponse } from '../types'
import { useSearchParams } from 'react-router-dom'

const pandoraFetcher = buildFetcher(pandoraRequest)

export const useRolesHistory = () => {
    const [searchParams] = useSearchParams()

    const userId = searchParams.get('userId')
    const isRevoked = getIsRevoked(searchParams.get('changeType'))
    const from = searchParams.get('from')

    const qs = new URLSearchParams()

    if (isRevoked) {
        qs.append('is_revoked', isRevoked)
    }

    if (from) {
        qs.append('from', from)
    }

    const url = `roles-history${userId ? `/${userId}?` : '?'}${qs.toString()}`
    const response = useSWR<RolesHistoryResponse>(url, pandoraFetcher, { revalidateOnFocus: false })

    return {
        rolesHistory: response.data,
        isLoading: !response.data && !response.error,
        ...response,
    }
}

const getIsRevoked = (changeType?: string | null) => {
    switch (changeType) {
        case 'granted':
            return 'false'
        case 'revoked':
            return 'true'
        default:
            return undefined
    }
}
