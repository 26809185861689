import { fetcher } from 'services/pandora/request'
import useSWR from 'swr'
import type { TimelineResponse } from 'types/timeline'

export const useCompanyTimeline = (
    companyId?: string,
    dateRange?: { from: string; to: string }
) => {
    const params = new URLSearchParams(dateRange)
    params.sort()
    const stableParams = '?' + params.toString()

    const url = `timeline/company/${companyId}${stableParams ? stableParams : null}`

    const response = useSWR<TimelineResponse, Error>(companyId ? url : null, fetcher, {
        revalidateOnFocus: false,
    })

    return response
}
