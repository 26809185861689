import type { GetAllBillInvoiceResponse, GetBillInvoiceResponse } from '@pleo-io/deimos'
import useSWR from 'swr'
import type { CursorRequestParams } from 'types/cursor-pagination'

import request, { fetcher } from './request'

export const useBillInvoice = (invoiceId: string | undefined) => {
    const response = useSWR<GetBillInvoiceResponse, Error>(
        invoiceId ? `rest/v1/bill-invoices/${invoiceId}` : null,
        fetcher
    )

    return {
        ...response,
        mutations: {
            failInternalValidation: async () => {
                await request().post(`rest/v1/bill/admin/invoices/${invoiceId}/reject`)
                response.mutate()
            },
            failPayment: async () => {
                await request().post(`rest/v1/bill/admin/invoices/${invoiceId}/release-funds`)
                response.mutate()
            },
            delete: async () => {
                await request().delete(`rest/v1/bill-invoices/${invoiceId}`)
                response.mutate()
            },
            cancel: async () => {
                await request().post(`rest/v1/bill-invoices/${invoiceId}/cancel`)
                response.mutate()
            },
            mutate: async () => {
                response.mutate()
            },
        },
    }
}
export type UseBillInvoiceMutations = ReturnType<typeof useBillInvoice>['mutations']

export const useCompanyBillInvoices = (
    companyId: string | undefined,
    paginationRequest: CursorRequestParams
) => {
    const searchParams = new URLSearchParams()
    searchParams.append('limit', (paginationRequest.limit ?? 10).toString())

    if (paginationRequest.after) {
        searchParams.append('after', paginationRequest.after)
    } else if (paginationRequest.before) {
        searchParams.append('before', paginationRequest.before)
    }
    const response = useSWR<GetAllBillInvoiceResponse, Error>(
        companyId
            ? `rest/v1/companies/${companyId}/bill-invoices?${searchParams.toString()}`
            : null,
        fetcher
    )

    return response
}

export interface BillInvoicePaymentAttempt {
    id: string
    createdAt?: string
    updatedAt?: string
    status: string
    statusReason?: string
}

export const useBillInvoicePaymentAttempts = (invoiceId: string | undefined) => {
    return useSWR<BillInvoicePaymentAttempt[], Error>(
        invoiceId ? `rest/v1/bill/admin/invoices/${invoiceId}/payment-attempts` : null,
        fetcher
    )
}
