import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Tabs, Empty, Tooltip } from 'antd'
import { EyeTwoTone } from '@ant-design/icons'

import { deSnakify } from 'utils/strings'
import Spinner from 'components/content-spinner'
import type * as styx from 'types/styx'
import { DocumentFiles } from 'pages/compliance/closed-company/documents/document-files'
import FileList from 'pages/compliance/company/shared/file-list/file-list'
import { color } from 'theme/tokens'

const { TabPane } = Tabs

interface Props {
    documents: styx.Document[]
    companyId?: string
    handleGetLinkToFile: (documentId: string) => (fileId: string) => Promise<string>
}

export const DocumentsTabs: FC<React.PropsWithChildren<Props>> = ({
    documents,
    handleGetLinkToFile,
}) => {
    const [activeKey, setActiveKey] = useState('')
    const hasNoDocuments = documents.length < 1

    if (hasNoDocuments) {
        return <Empty data-testid="empty" description="There are no documents for this company." />
    }

    return (
        <Tabs
            tabPosition="left"
            defaultActiveKey={activeKey}
            onTabClick={(key: string) => setActiveKey(key)}
        >
            {documents.map((document) => {
                const title = document.title ?? deSnakify(document.category)
                return (
                    <TabPane tab={`${title} (${document.files.length})`} key={document.id}>
                        <Content>
                            <DocumentTitle>
                                <h2>
                                    {title}{' '}
                                    {!document.hidden && (
                                        <Tooltip title="Document is visible to users.">
                                            <EyeTwoTone twoToneColor={color.gold} />
                                        </Tooltip>
                                    )}
                                </h2>
                            </DocumentTitle>
                            <div>{document.description || 'No description'}</div>
                            <DocumentFiles
                                document={document}
                                getLinkToFile={handleGetLinkToFile(document.id)}
                            />
                        </Content>
                    </TabPane>
                )
            })}
        </Tabs>
    )
}

interface ReadOnlyProps {
    handleGetLinkToFile: (
        documentId: string,
        snapshot?: string
    ) => (fileId: string) => Promise<string>
    snapshot: string
    snapshotDocuments: styx.Document[]
    loading: boolean
}

export const ReadOnlyDocumentTabs: FC<React.PropsWithChildren<ReadOnlyProps>> = ({
    snapshotDocuments,
    handleGetLinkToFile,
    loading,
    snapshot,
}) => {
    const hasNoDocuments = snapshotDocuments.length < 1

    if (loading) {
        return <Spinner />
    }

    if (hasNoDocuments) {
        return (
            <Empty
                data-testid="empty"
                description="There are currently no documents for this company."
            />
        )
    }

    return (
        <Tabs tabPosition="left">
            {snapshotDocuments.map((document) => {
                const title = document.title ?? deSnakify(document.category)
                return (
                    <TabPane tab={`${title} (${document.files.length})`} key={document.id}>
                        <Content>
                            <DocumentTitle>
                                <h2>
                                    {title}{' '}
                                    {!document.hidden && (
                                        <Tooltip title="Document is visible to users.">
                                            <EyeTwoTone twoToneColor={color.gold} />
                                        </Tooltip>
                                    )}
                                </h2>
                            </DocumentTitle>
                            <div>{document.description || 'No description'}</div>
                            <FileList
                                files={document.files}
                                getLinkToFile={handleGetLinkToFile(document.id, snapshot)}
                            />
                        </Content>
                    </TabPane>
                )
            })}
        </Tabs>
    )
}

const Content = styled.div`
    min-height: 250px;
`

const DocumentTitle = styled.div`
    display: flex;
    justify-content: space-between;
`
