import type { Company } from '@pleo-io/deimos'
import { useGetCompany } from 'services/deimos/companies'
import { fetcher } from 'services/deimos/request'
import useSWR from 'swr'
import { useDebounce } from 'utils/use-debounce'
import * as uuid from 'uuid'

export const useSearchCompanies = (query: string | null) => {
    const debouncedQuery = useDebounce(query, 500)
    const { data: companyById } = useGetCompany(uuid.validate(query || '') ? query : '')
    const { data: companies } = useSWR<Company[]>(
        `rest/v1/admin/companies?query=${debouncedQuery}`,
        fetcher,
        { revalidateOnFocus: false }
    )

    return { companies, companyById, loading: !companyById && !companies }
}
