import { Card } from 'antd'
import { LoginEventsTable } from '../components/login-events-table'
import { AppStoreLink } from '../components/app-store-link'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Params } from '../enums'

export const LoginEventsPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const [currentPage, setCurrentPage] = useState(searchParams.get(Params.CURRENT_PAGE) || '1')
    const [pageSize, setPageSize] = useState(searchParams.get(Params.PAGE_SIZE) || '10')
    const [domain, setDomain] = useState(searchParams.get(Params.DOMAIN) || undefined)
    const [email, setEmail] = useState(searchParams.get(Params.EMAIL) || undefined)
    const [event, setEvent] = useState(searchParams.get(Params.EVENT) || undefined)
    const [fromDate, setFromDate] = useState(searchParams.get(Params.FROM_DATE) || undefined)
    const [toDate, setToDate] = useState(searchParams.get(Params.TO_DATE) || undefined)

    useEffect(() => {
        searchParams.set(Params.CURRENT_PAGE, currentPage)
        searchParams.set(Params.PAGE_SIZE, pageSize)
        domain ? searchParams.set(Params.DOMAIN, domain) : searchParams.delete(Params.DOMAIN)
        email ? searchParams.set(Params.EMAIL, email) : searchParams.delete(Params.EMAIL)
        event ? searchParams.set(Params.EVENT, event) : searchParams.delete(Params.EVENT)
        fromDate
            ? searchParams.set(Params.FROM_DATE, fromDate)
            : searchParams.delete(Params.FROM_DATE)
        toDate ? searchParams.set(Params.TO_DATE, toDate) : searchParams.delete(Params.TO_DATE)

        setSearchParams(searchParams)
    }, [
        email,
        domain,
        event,
        fromDate,
        toDate,
        currentPage,
        pageSize,
        searchParams,
        setSearchParams,
    ])

    const handleFilterChange = (filter: Params, value: string | undefined) => {
        if (filter !== Params.CURRENT_PAGE) setCurrentPage('1')

        switch (filter) {
            case Params.DOMAIN:
                setDomain(value)
                break
            case Params.EMAIL:
                setEmail(value)
                break
            case Params.EVENT:
                setEvent(value)
                break
            case Params.TO_DATE:
                setToDate(value)
                break
            case Params.FROM_DATE:
                setFromDate(value)
                break
            case Params.CURRENT_PAGE:
                setCurrentPage(value || '1')
                break
            case Params.PAGE_SIZE:
                setPageSize(value || '10')
                break
        }
    }

    return (
        <Card
            title="Login events log"
            extra={<AppStoreLink />}
            bodyStyle={{ padding: '16px 24px' }}
            style={{ margin: 10 }}
        >
            <LoginEventsTable
                showEmailFilter={true}
                showDomainFilter={true}
                currentPageParam={currentPage}
                eventParam={event}
                fromDateParam={fromDate}
                pageSizeParam={pageSize}
                toDateParam={toDate}
                domainParam={domain}
                emailParam={email}
                onFilterChange={handleFilterChange}
            />
        </Card>
    )
}
