import useSWR from 'swr'
import type { ListCadmusReportsResponse } from 'types/cadmus-reports'
import request, { fetcher } from './request'

export const useRevenueRecognitionReportList = () => {
    return useSWR<ListCadmusReportsResponse>(
        'rest/v1/finance-tooling/revenue-recognition/reports?include_history=false',
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        }
    )
}

export const getRevenueRecognitionPresignedLink = async (fileName: string, version: string) => {
    return request().get(
        `rest/v1/finance-tooling/revenue-recognition/reports/${fileName}/${version}`
    )
}
