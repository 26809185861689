import type { StyxCompany } from 'types/styx'
import { STANDARD_DATAPOINTS, PEOPLE_DATA_POINTS, HIGH_RISK_DATAPOINTS } from './constants'
import type { DataPoints, KycVerificationDetail, MappedData, Person } from './types'

/**
 * Checks if the given person is missing data.
 * @param person The person to check.
 * @returns True if the person is missing data.
 */
const isPersonMissingData = (person: Person[]): boolean => {
    return person.some((p) => Object.values(p).includes(false))
}

/**
 * Maps the given data points to a standard and high-risk set of mapped data.
 * @param dataPoints The data points to map.
 * @returns The mapped data.
 */
export const mapKycResponse = (dataPoints?: DataPoints, company?: StyxCompany): MappedData => {
    if (!dataPoints) {
        return {
            standard: [],
            highRisk: [],
        }
    }

    const unsortedStandard: KycVerificationDetail[] = []
    const unsortedHighRisk: KycVerificationDetail[] = []
    const isGbCompany = company?.address?.country === 'GB'

    const filterPeopleDataPointsPerCountry = (datapoints: DataPoints): DataPoints => {
        const filterKey = isGbCompany ? 'hasNationality' : 'hasCitizenship'
        const filteredDatapoints = { ...datapoints }

        Object.keys(filteredDatapoints)
            .filter((key) => PEOPLE_DATA_POINTS.includes(key))
            .forEach((key) => {
                filteredDatapoints[key] = (filteredDatapoints[key] as Person[]).map((person) => {
                    const { [filterKey]: _, ...rest } = person
                    return rest
                })
            })

        return filteredDatapoints
    }

    const filteredDatapoints = filterPeopleDataPointsPerCountry(dataPoints)

    Object.keys(filteredDatapoints).forEach((key) => {
        if (Object.keys(STANDARD_DATAPOINTS).includes(key)) {
            unsortedStandard.push({
                label: STANDARD_DATAPOINTS[key as keyof typeof STANDARD_DATAPOINTS],
                key,
                isMissing: PEOPLE_DATA_POINTS.includes(key)
                    ? isPersonMissingData(filteredDatapoints[key] as Person[])
                    : filteredDatapoints[key] === false,
                people: PEOPLE_DATA_POINTS.includes(key)
                    ? (filteredDatapoints[key] as Person[])
                    : undefined,
                isGbCompany: isGbCompany,
            })
        }
        if (Object.keys(HIGH_RISK_DATAPOINTS).includes(key)) {
            unsortedHighRisk.push({
                label: HIGH_RISK_DATAPOINTS[key as keyof typeof HIGH_RISK_DATAPOINTS],
                key,
                isMissing: PEOPLE_DATA_POINTS.includes(key)
                    ? isPersonMissingData(filteredDatapoints[key] as Person[])
                    : filteredDatapoints[key] === false,
                people: PEOPLE_DATA_POINTS.includes(key)
                    ? (filteredDatapoints[key] as Person[])
                    : undefined,
                isGbCompany: isGbCompany,
            })
        }
    })

    return {
        standard: unsortedStandard.sort((a, b) => a.label.localeCompare(b.label)),
        highRisk: unsortedHighRisk.sort((a, b) => a.label.localeCompare(b.label)),
    }
}
