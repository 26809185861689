import { Button } from 'antd'
import { FileOutlined, FilePdfOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import type { SupplierInvoice } from 'types/deimos-supplier'
import apiRequest from 'services/deimos/request'

export async function downloadInvoice(invoiceId: string) {
    const response = await apiRequest().get(`rest/v1/bill-invoices/${invoiceId}/pdf`)
    const data = await response.json()
    const a = document.createElement('a')
    a.href = data.url
    a.click()
}

export async function downloadOriginalInvoice(url: string | undefined) {
    if (!url) return null
    const a = document.createElement('a')
    a.href = url
    a.click()
}

export const LinkButton = styled(Button)`
    padding: 0;
    height: auto;
`

export const DownloadInvoiceButton: React.FC<
    React.PropsWithChildren<{ invoice: SupplierInvoice; original?: boolean }>
> = ({ invoice, original }) => {
    const originalExtension = invoice?.fileExtension ?? 'unknown'

    return (
        <LinkButton
            type="link"
            onClick={(e) => {
                e.preventDefault()
                if (original) {
                    downloadOriginalInvoice(invoice.id)
                } else {
                    downloadInvoice(invoice.id)
                }
            }}
        >
            {originalExtension === 'pdf' || !original ? <FilePdfOutlined /> : <FileOutlined />}{' '}
            {original && `[${originalExtension}]`}
            {!original && '[pdf]'}
            {!original && originalExtension !== 'pdf' && ` (from ${originalExtension})`}
        </LinkButton>
    )
}
