import React, { FC, useEffect, useState } from 'react'
import { actions as supplierActions } from 'store/modules/suppliers/slice'
import { useCompanyBillInvoices, useBillInvoice } from 'services/deimos/bill-invoice'
import { Card, message, Button, Collapse, Table, Typography, Col, Row, Modal, Space } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import type { ColumnProps } from 'antd/lib/table'
import type { GetBillInvoiceResponse } from '@pleo-io/deimos'
import { InvoiceStatus } from '@pleo-io/deimos'
import { PageContentLayout } from 'components/layout-containers'
import { Formik } from 'formik'
import { Input as AntInput, Form } from 'formik-antd'
import { inputWidth } from 'theme/tokens'
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { BillInvoiceInformation } from './bill-invoice-information'
import { BillInvoiceSupplierInformation } from './bill-invoice-supplier-information'
import { BillInvoiceOwnerInformation } from './bill-invoice-owner-information'
import { BillInvoiceDocument } from './bill-invoice-document'
import { BillInvoicePaymentAttempts } from './bill-invoices-payment-attempts'
import { useCursorPagination } from 'utils/use-cursor-pagination'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import { SupplierDetails, useSupplier } from 'components/supplier/supplier'
import EditDetailsForm from '../../../components/supplier/edit-details-form'
import { blue } from '@ant-design/colors'
import dayjs from 'dayjs'
export const BillInvoices = () => {
    const {
        invoiceId: invoiceIdParam,
        companyId: companyIdParam,
        supplierId: supplierIdParam,
    } = useParams()
    const navigate = useNavigate()
    const [isInvoiceLoaded, setIsInvoiceLoaded] = useState<boolean>(false)
    const [isCompanyInvoicesLoaded, setIsCompanyInvoicesLoaded] = useState<boolean>(false)
    const [isSupplierLoaded, setIsSupplierLoaded] = useState<boolean>(false)

    const clearLoadedsState = () => {
        setIsInvoiceLoaded(false)
        setIsCompanyInvoicesLoaded(false)
        setIsSupplierLoaded(false)
    }

    return (
        <PageContentLayout>
            <Row>
                <Col span={8}>
                    <Card
                        title="Invoice by ID"
                        headStyle={{ backgroundColor: invoiceIdParam ? blue[0] : '#fff' }}
                    >
                        <SearchForm
                            name="invoiceId"
                            initialValue={invoiceIdParam}
                            navigate={(invId) => navigate(`/customer-success/invoices/${invId}`)}
                            loading={isInvoiceLoaded}
                            placeholder="Enter invoice ID (e.g. C1E724E8EVU00)"
                            formId="invoiceId"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        title="List of Invoices by Company ID"
                        headStyle={{ backgroundColor: companyIdParam ? blue[0] : '#fff' }}
                    >
                        <SearchForm
                            name="companyId"
                            initialValue={companyIdParam}
                            navigate={(compId) =>
                                navigate(`/customer-success/invoices/company/${compId}`)
                            }
                            loading={isCompanyInvoicesLoaded}
                            placeholder="Enter Company ID (e.g. edee088b-3c92-4640-b698-49b46b52598f)"
                            formId="companyId"
                        />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        title="Supplier ID"
                        headStyle={{ backgroundColor: supplierIdParam ? blue[0] : '#fff' }}
                    >
                        <SearchForm
                            name="supplierId"
                            initialValue={supplierIdParam}
                            navigate={(supplierId) =>
                                navigate(`/customer-success/invoices/supplier/${supplierId}`)
                            }
                            loading={isSupplierLoaded}
                            placeholder="Enter Supplier ID (e.g. C70J0LMI37A00)"
                            formId="supplierId"
                        />
                    </Card>
                </Col>
            </Row>
            {invoiceIdParam && (
                <ViewBillInvoice
                    {...{
                        navigate,
                        invoiceIdParam,
                    }}
                    setIsInvoiceLoaded={() => {
                        clearLoadedsState()
                        setIsInvoiceLoaded(true)
                    }}
                />
            )}
            {companyIdParam && (
                <div>
                    <ViewBillInvoicesCompany
                        navigate={navigate}
                        companyIdParam={companyIdParam}
                        setIsCompanyInvoicesLoaded={() => {
                            clearLoadedsState()
                            setIsCompanyInvoicesLoaded(true)
                        }}
                    />
                </div>
            )}
            {supplierIdParam && (
                <ViewSupplierDetails
                    navigate={navigate}
                    supplierIdParam={supplierIdParam}
                    setIsSupplierLoaded={() => {
                        clearLoadedsState()
                        setIsSupplierLoaded(true)
                    }}
                />
            )}
            {!invoiceIdParam && !companyIdParam && !supplierIdParam && null}
        </PageContentLayout>
    )
}

const ViewBillInvoice = ({
    navigate,
    invoiceIdParam,
    setIsInvoiceLoaded,
}: {
    navigate: (id: string) => void
    invoiceIdParam: string
    setIsInvoiceLoaded: () => void
}) => {
    const { data: invoice, error, isValidating, mutations } = useBillInvoice(invoiceIdParam)
    const [modalActive, setModalActive] = useState(false)

    if (invoice) {
        setIsInvoiceLoaded()
    }

    useEffect(() => {
        if (error) {
            message.error(error.message)
            navigate(`/customer-success/invoices`)
        }
    }, [error, navigate])

    const onDelete = React.useCallback(() => {
        navigate(`/support/invoices`)
    }, [navigate])

    return (
        <>
            {isValidating && !invoice ? (
                <LoadingOutlined />
            ) : (
                invoice && (
                    <>
                        <Card title={`Invoice ${invoiceIdParam} details`}>
                            <Collapse defaultActiveKey={['overview', 'document']}>
                                <Collapse.Panel header="Overview" key="overview">
                                    <BillInvoiceInformation
                                        invoice={invoice}
                                        mutations={mutations}
                                        onDelete={onDelete}
                                    />
                                </Collapse.Panel>
                                <Collapse.Panel header="Supplier" key="supplier">
                                    <Space direction="vertical" size="middle">
                                        <SupplierEditButton
                                            onEdit={() => setModalActive(true)}
                                            supplierId={invoice.supplier?.id}
                                        />
                                        <BillInvoiceSupplierInformation invoice={invoice} />
                                    </Space>
                                </Collapse.Panel>
                                <Collapse.Panel header="Owner" key="owner">
                                    <BillInvoiceOwnerInformation
                                        employee={invoice?.employee}
                                        companyId={invoice?.companyId}
                                    />
                                </Collapse.Panel>
                                <Collapse.Panel header="Payment Attempts" key="payment_attempts">
                                    <BillInvoicePaymentAttempts invoice={invoice} />
                                </Collapse.Panel>
                                <Collapse.Panel header="Document" key="document">
                                    <BillInvoiceDocument invoice={invoice} />
                                </Collapse.Panel>
                            </Collapse>
                        </Card>
                        <SupplierEditModal
                            supplierId={invoice.supplier?.id ?? ''}
                            invoiceId={invoice.id}
                            modalActive={modalActive}
                            onCancelClose={() => {
                                setModalActive(false)
                                mutations.mutate()
                            }}
                        />
                    </>
                )
            )}
        </>
    )
}

const SupplierEditButton = ({
    onEdit,
    supplierId,
}: {
    onEdit: () => void
    supplierId: string | undefined
}) => {
    return (
        <>
            <Button
                onClick={onEdit}
                type="primary"
                disabled={!supplierId}
                key="edit-information-button"
            >
                Edit supplier information
            </Button>
        </>
    )
}

const SupplierEditModal = ({
    supplierId,
    invoiceId,
    modalActive,
    onCancelClose,
}: {
    supplierId: string
    invoiceId: string
    modalActive: boolean
    onCancelClose: () => void
}) => {
    const { supplier, status, dispatch } = useSupplier(supplierId)

    return (
        <Modal
            title="Edit supplier information"
            open={!!modalActive}
            onCancel={onCancelClose}
            footer={null}
            width={'95vw'}
            centered
        >
            {supplier && (
                <EditDetailsForm
                    supplier={supplier}
                    status={status}
                    onSubmit={(values) => {
                        dispatch(
                            supplierActions.updateSupplier({
                                supplierId: supplier.id,
                                data: {
                                    ...values,
                                    invoiceId,
                                    accounts: [
                                        {
                                            updateType: 'UPDATE',
                                            id: supplier.bankAccounts?.[0]?.id,
                                            bic: values.bic,
                                            iban: values.iban,
                                            bankCode: values.bankCode,
                                            accountNumber: values.accountNumber,
                                        },
                                    ],
                                },
                            })
                        )
                        onCancelClose()
                    }}
                    onCancel={() => onCancelClose()}
                />
            )}
        </Modal>
    )
}

const ViewBillInvoicesCompany = ({
    companyIdParam,
    setIsCompanyInvoicesLoaded,
    navigate,
}: {
    companyIdParam: string
    setIsCompanyInvoicesLoaded: () => void
    navigate: (id: string) => void
}) => {
    const { paginationRequest, setCurrentPagination, ...paginationMutations } = useCursorPagination(
        { limit: 10, sorting: [] }
    )
    const {
        data: companyInvoices,
        error,
        isValidating,
    } = useCompanyBillInvoices(companyIdParam, paginationRequest)

    useEffect(() => {
        if (companyInvoices?.pagination) {
            setCurrentPagination(companyInvoices.pagination)
        }
    }, [companyInvoices, setCurrentPagination])

    useEffect(() => {
        if (error) {
            message.error(error.message)
            navigate(`/customer-success/invoices`)
        }
    }, [error, navigate])
    if (!companyInvoices) return null
    else if (isValidating) return <LoadingOutlined />

    setIsCompanyInvoicesLoaded()
    const { Text } = Typography
    const formatDate = (name: string) => <Text>{name ? dayjs(name).format('L') : '-'}</Text>

    const statusFilter = Object.keys(InvoiceStatus).map((status: string) => ({
        text: status,
        value: status,
    }))

    const columns: ColumnProps<GetBillInvoiceResponse>[] = [
        {
            title: 'Invoice ID',
            dataIndex: 'id',
            render: (name, record) => (
                <Link to={`/customer-success/invoices/${record.id}`}>{name}</Link>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (name) => <Text>{name}</Text>,
            filters: statusFilter,
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Due at',
            dataIndex: 'dueAt',
            render: (name) => formatDate(name),
        },
        {
            title: 'Scheduled at',
            dataIndex: 'scheduledAt',
            render: (name) => formatDate(name),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (name) => <Text>{name}</Text>,
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            render: (name) => <Text>{name}</Text>,
        },
        {
            title: 'Billing Amount',
            dataIndex: 'billingAmount',
            render: (name) => <Text>{name}</Text>,
        },
        {
            title: 'Billing Currency',
            dataIndex: 'billingCurrency',
            render: (name) => <Text>{name}</Text>,
        },
        {
            title: 'Employee ID',
            dataIndex: 'employeeId',
            render: (name) => <Text>{name}</Text>,
        },
        {
            title: 'Paid at',
            dataIndex: 'paidAt',
            render: (name) => formatDate(name),
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (name) => formatDate(name),
        },
        {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            render: (name) => formatDate(name),
        },
    ]

    return (
        <Card title={`Invoices listing for ${companyIdParam}`}>
            <Table
                dataSource={companyInvoices.data}
                loading={isValidating}
                rowKey="id"
                columns={columns}
                size="small"
                pagination={false}
            />
            {paginationRequest && (
                <CursorPaginationControls
                    isFetching={!companyInvoices}
                    pagination={companyInvoices?.pagination}
                    limit={paginationRequest.limit}
                    onNextPage={paginationMutations.nextPage}
                    onPrevPage={paginationMutations.previousPage}
                    onFirstPage={paginationMutations.firstPage}
                    onChangeLimit={paginationMutations.updateLimit}
                />
            )}
        </Card>
    )
}

const ViewSupplierDetails = ({
    navigate,
    supplierIdParam,
    setIsSupplierLoaded,
}: {
    navigate: (id: string) => void
    supplierIdParam: string
    setIsSupplierLoaded: () => void
}) => {
    const { supplier, status } = useSupplier(supplierIdParam)

    React.useEffect(() => {
        if (supplier?.id && supplier.id !== supplierIdParam) {
            navigate(`/customer-success/invoices/supplier/${supplierIdParam}`)
        }
    }, [supplierIdParam, supplier?.id, navigate])

    React.useEffect(() => {
        if (supplier) {
            setIsSupplierLoaded()
        }
    }, [supplier, status, setIsSupplierLoaded])

    return <SupplierDetails status={status} supplier={supplier} />
}

const SearchForm: FC<{
    navigate: (id: string) => void
    name: string
    initialValue: string
    loading: boolean
    placeholder: string
    formId: string
}> = ({ navigate, name, initialValue, loading, placeholder, formId }) => {
    return (
        <Formik
            initialValues={{ [name]: initialValue }}
            onSubmit={(values) => navigate(values[name])}
        >
            {({ values, isValidating: isFormValidating }) => (
                <Form id={formId} layout="horizontal">
                    <FormItem name={name}>
                        <AntInput
                            name={name}
                            placeholder={placeholder}
                            style={{ width: `${inputWidth.large}` }}
                        />
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading && isFormValidating}
                            disabled={!values[name]}
                        >
                            <SearchOutlined />
                        </Button>
                    </FormItem>
                </Form>
            )}
        </Formik>
    )
}

const FormItem = styled(Form.Item)`
    margin-bottom: 0;
`
