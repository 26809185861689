import type { countries } from 'countries-list'

export type Alpha2Code = keyof typeof countries

export const supportedCountries = [
    'DK',
    'DE',
    'SE',
    'GB',
    'ES',
    'IE',
    'AT',
    'FI',
    'FR',
    'NL',
    'BE',
    'PT',
    'NO',
] as const

export type SupportedCountry = (typeof supportedCountries)[number]

export type SupportedCountryResponse = {
    id: string
    countryCode: Alpha2Code
    locale: string
    createdAt: Date
    updatedAt: Date
    deletedAt?: Date
}

export const soleTraderSupportedCountries = ['DK', 'SE', 'FI', 'NL', 'EE', 'IT']
