import request from './request'

export const googlePlacesQuery = (input: string, country?: string) => {
    const searchParams = new URLSearchParams()

    searchParams.append('input', input)

    if (country) {
        searchParams.append('country', country)
    }

    return request().get(`rest/v1/address-service/autocomplete`, {
        searchParams,
    })
}

export const googlePlaceDetailsQuery = (place_id: string) =>
    request().get(`rest/v1/address-service/details`, {
        searchParams: {
            place_id,
        },
    })
