export default function formatCurrency(
    amount: string | number,
    currency: string,
    options?: Intl.NumberFormatOptions
) {
    const userLanguage = window.navigator.language
    return new Intl.NumberFormat([userLanguage, 'da-DK'], {
        style: 'currency',
        currency,
        ...options,
    }).format(Number(amount))
}
