import {
    Button,
    Col,
    Divider,
    Empty,
    Pagination,
    Row,
    Select,
    Space,
    Tag,
    Timeline,
    Typography,
} from 'antd'
import React, { FC, useState } from 'react'
import dayjs from 'packages/dayjs'
import { TimelineSource, useTimeline } from '../lib/use-timeline'
import { DayjsDatePicker } from 'packages/form/date-picker'
import { useCompanyTimeline } from '../api/use-company-timeline'
import { bff } from '../bff'

type RangeValue = Parameters<
    NonNullable<React.ComponentProps<typeof DayjsDatePicker.RangePicker>['onChange']>
>[0]

interface FilterBarProps {
    selectedFilters?: TimelineSource['selectedFilters']
    updateSelectedFilters: TimelineSource['setSelectedFilters']
    allFilters: TimelineSource['allFilters']
    setDateRange: (dates: RangeValue) => void
    dates?: DateRange
}

interface DateRange {
    from: string
    to: string
}

interface AuditLogTimelineProps {
    timelineData: TimelineSource['timelineData']
    pagination: TimelineSource['pagination']
}

const FilterBar: FC<FilterBarProps> = ({
    allFilters,
    selectedFilters,
    updateSelectedFilters,
    setDateRange,
    dates,
}) => {
    return (
        <Row gutter={16} justify="space-between">
            <Col span={16}>
                <Select
                    style={{ width: '100%' }}
                    labelInValue
                    mode="multiple"
                    allowClear
                    value={selectedFilters}
                    onChange={updateSelectedFilters}
                >
                    {allFilters.map((category) => (
                        <Select.Option value={category.value} key={category.key}>
                            {category.label}
                        </Select.Option>
                    ))}
                </Select>
            </Col>
            <Col span={8}>
                <DayjsDatePicker.RangePicker
                    ranges={{
                        'Last 30 days': [dayjs().subtract(30, 'days'), dayjs()],
                    }}
                    value={dates ? [dayjs(dates.from), dayjs(dates.to)] : undefined}
                    style={{ width: '100%' }}
                    onChange={setDateRange}
                />
            </Col>
        </Row>
    )
}

const AuditLogTimeline: FC<AuditLogTimelineProps> = ({ timelineData, pagination }) => {
    return (
        <>
            <Timeline>
                {timelineData.map((timelineItem) => (
                    <Timeline.Item key={timelineItem.id}>
                        <div>
                            <Tag style={{ alignSelf: 'flex-start' }}>{timelineItem.timestamp}</Tag>
                            <Space direction="vertical">
                                {timelineItem.label}
                                {timelineItem?.description && (
                                    <Typography.Text type="secondary">
                                        {timelineItem.description}
                                    </Typography.Text>
                                )}
                            </Space>
                        </div>
                    </Timeline.Item>
                ))}
            </Timeline>
            <Pagination
                current={pagination.current}
                total={pagination.count}
                onChange={pagination.onChange}
            />
        </>
    )
}

export const AuditLog: FC<{ companyId?: string }> = ({ companyId }) => {
    const [dateRange, setDateRange] = useState<DateRange | undefined>()
    const from = dateRange?.from ? { from: dateRange?.from } : undefined
    const to = dateRange?.to ? { to: dateRange?.to } : undefined

    const { data: auditLogData, error: auditLogError } = bff.auditLogRouter.getAuditLogs.useQuery({
        companyId: companyId,
        from,
        to,
    })
    const { data: companyTimelineData, error: companyTimelineError } = useCompanyTimeline(
        companyId,
        dateRange
    )
    const { timelineData, allFilters, selectedFilters, setSelectedFilters, pagination } =
        useTimeline(auditLogData, companyTimelineData)

    const isLoading =
        (!auditLogData && !auditLogError) || (!companyTimelineData && !companyTimelineError)

    const resetFilters = () => {
        setSelectedFilters(allFilters)
        setDateRange(undefined)
    }

    return (
        <>
            {!auditLogData && !companyTimelineData && !isLoading && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
            )}
            {!isLoading && (
                <>
                    <FilterBar
                        selectedFilters={selectedFilters}
                        updateSelectedFilters={setSelectedFilters}
                        setDateRange={(dates: RangeValue) =>
                            setDateRange(
                                dates
                                    ? {
                                          from: dayjs(dates[0]).toISOString(),
                                          to: dayjs(dates[1]).toISOString(),
                                      }
                                    : undefined
                            )
                        }
                        dates={dateRange}
                        allFilters={allFilters}
                    />
                    <Divider style={{ marginTop: '16px' }} />
                    {timelineData.length > 0 ? (
                        <AuditLogTimeline timelineData={timelineData} pagination={pagination} />
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="Nothing to show for the current selection"
                        >
                            <Button onClick={() => resetFilters()}>Reset filters</Button>
                        </Empty>
                    )}
                </>
            )}
        </>
    )
}
