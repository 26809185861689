import { buildFetcher, kerberosRequest } from 'services/request'
import useSWR from 'swr'
import type { Invite } from 'types/invite'

const fetcher = buildFetcher(kerberosRequest)

export function deleteInvite(inviteId: string) {
    return kerberosRequest().delete(`api/v1/invites/${inviteId}`)
}

export const useInvite = (inviteId?: string) => {
    const { mutate, ...rest } = useSWR<Invite, Error>(
        inviteId ? `rest/v1/admin/invites/${inviteId}` : null,
        fetcher,
        {
            revalidateOnFocus: false,
            onErrorRetry: (requestError, key, config, revalidate, { retryCount }) => {
                if (requestError.message === 'invite_not_found') return

                revalidate({ retryCount })
            },
        }
    )

    const deleteInviteMutation = async () => {
        if (!inviteId) {
            return
        }

        await deleteInvite(inviteId)
        await mutate(undefined, { revalidate: false })
    }

    return {
        ...rest,
        mutations: {
            deleteInvite: deleteInviteMutation,
        },
    }
}
