import React, { FC, useState } from 'react'
import { Button, Divider, Form as AntdForm, Modal, notification, Popover, Typography } from 'antd'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

import { AccountType } from '@pleo-io/deimos'
import { useGetCompany } from 'services/deimos/companies'
import { useWalletUnloads } from 'services/deimos/wallet'
import dayjs from 'packages/dayjs'
import { useGetLatestProofOfFunds } from 'services/deimos/proof-of-funds'
import type { Store } from 'antd/lib/form/interface'
import {
    getBankInformation,
    getCountryForWalletUnload,
    getTypeForWalletUnload,
    getAmountForWalletUnload,
} from './utils'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getBankInfoForDisplay } from './common'
import { Form, FormItem, Input, InputNumber, Select } from 'formik-antd'
import * as Yup from 'yup'
import { Formik } from 'formik'
import type { WalletUnloadResponse } from 'bff/moons/generated/cupid-v1'
import { WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'

const { Text } = Typography
const { Option } = Select

export interface Props {
    walletUnload: WalletUnloadResponse
    status: WalletUnloadResponseStatus
    disabled: boolean
    setDisableActionButtons: (value: boolean) => void
}

export const validationSchema = Yup.object().shape({
    requesterAccountNumber: Yup.string().when('accountType', {
        is: AccountType.ACCOUNT_NUMBER,
        then: (schema) => schema.required(`Required when type is ${AccountType.ACCOUNT_NUMBER}`),
    }),
    requesterBankCode: Yup.string().when('accountType', {
        is: AccountType.ACCOUNT_NUMBER,
        then: (schema) => schema.required(`Required when type is ${AccountType.ACCOUNT_NUMBER}`),
    }),
    requesterBic: Yup.string().when('accountType', {
        is: AccountType.IBAN,
        then: (schema) => schema.required(`Required when type is ${AccountType.IBAN}`),
    }),
    requesterIban: Yup.string().when('accountType', {
        is: AccountType.IBAN,
        then: (schema) => schema.required(`Required when type is ${AccountType.IBAN}`),
    }),
    accountType: Yup.string().required('Account type is required'),
    otherAccountIdentifier: Yup.string().when('accountType', {
        is: AccountType.NEM_KONTO_CVR,
        then: (schema) => schema.required(`Required when type is ${AccountType.NEM_KONTO_CVR}`),
    }),
    bankName: Yup.string(),
    reason: Yup.string(),
    amount: Yup.number().required('Amount is required'),
    name: Yup.string().required('Name is required'),
    addressLine1: Yup.string().required('Address line 1 is required'),
    addressLine2: Yup.string(),
    postalCode: Yup.string().required('Postal code is required'),
    locality: Yup.string().required('City is required'),
    region: Yup.string(),
    country: Yup.string()
        .matches(/^[A-Z]{2}$/, 'Please use valid country code, e.g. DK')
        .required('Country code is required'),
})

const showIbanOnEdit = (
    country: string,
    accountNumber?: string,
    bankCode?: string,
    iban?: string
) => {
    const noAccountNumberAndBankCode = !accountNumber && !bankCode
    const hasIbanOrIsCountryThatUsesIban = !!iban || !['GB', 'IE'].includes(country)
    return noAccountNumberAndBankCode && hasIbanOrIsCountryThatUsesIban
}

export const Edit: FC<React.PropsWithChildren<Props>> = ({
    walletUnload,
    status,
    disabled,
    setDisableActionButtons,
}) => {
    const [modalActive, setModalActive] = useState(false)
    const [disableSaveButton, setDisableSaveButton] = useState(false)
    const { data: company } = useGetCompany(walletUnload.companyId)
    const {
        mutations: { updateWalletUnload },
    } = useWalletUnloads(status)
    const { senderBank, senderInformation } = useGetLatestProofOfFunds(walletUnload.companyId)
    const { iban, accountNumber, bankCode } = getBankInformation(
        walletUnload,
        company,
        senderBank,
        senderInformation
    )
    const country = getCountryForWalletUnload(walletUnload, company)
    const initialIbanActive = showIbanOnEdit(country, accountNumber, bankCode, iban)
    const notEditable =
        status === WalletUnloadResponseStatus.EXPORTED ||
        status === WalletUnloadResponseStatus.UNLOADED

    const initialValues = {
        requesterAccountNumber: walletUnload.account.accountNumber || '',
        requesterBankCode: walletUnload.account.bankCode || '',
        requesterBic: walletUnload.account.bic || '',
        requesterIban: walletUnload.account.iban || '',
        accountType: walletUnload.account.accountType,
        otherAccountIdentifier: walletUnload.account.otherAccountIdentifier || '',
        bankName: walletUnload.account.bankName || '',
        reason: walletUnload.statusReason,
        amount: walletUnload.amount.value,
        name: walletUnload.beneficiaryAddress.name,
        addressLine1: walletUnload.beneficiaryAddress.addressLine1,
        addressLine2: walletUnload.beneficiaryAddress.addressLine2,
        locality: walletUnload.beneficiaryAddress.locality,
        postalCode: walletUnload.beneficiaryAddress.postalCode,
        region: walletUnload.beneficiaryAddress.region,
        country: walletUnload.beneficiaryAddress.country,
    }

    const onConfirm = async (values: Store) => {
        const updatedWalletUnload = { ...walletUnload }
        updatedWalletUnload.account.accountNumber = values.requesterAccountNumber
        updatedWalletUnload.account.bankCode = values.requesterBankCode
        updatedWalletUnload.account.bic = values.requesterBic
        updatedWalletUnload.account.iban = values.requesterIban
        updatedWalletUnload.account.accountType = values.accountType
        updatedWalletUnload.account.otherAccountIdentifier = values.otherAccountIdentifier
        updatedWalletUnload.account.bankName = values.bankName
        updatedWalletUnload.statusReason = values.reason
        updatedWalletUnload.amount.value = values.amount
        updatedWalletUnload.beneficiaryAddress.name = values.name
        updatedWalletUnload.beneficiaryAddress.addressLine1 = values.addressLine1
        updatedWalletUnload.beneficiaryAddress.addressLine2 = values.addressLine2
        updatedWalletUnload.beneficiaryAddress.locality = values.locality
        updatedWalletUnload.beneficiaryAddress.postalCode = values.postalCode
        updatedWalletUnload.beneficiaryAddress.region = values.region
        updatedWalletUnload.beneficiaryAddress.country = values.country

        try {
            setDisableActionButtons(true)
            setDisableSaveButton(true)
            await updateWalletUnload(walletUnload.id, updatedWalletUnload)
            setModalActive(false)
            notification.success({
                message: 'Wallet unload successfully updated',
            })
        } catch (e) {
            notification.error({
                message: 'Failed to update wallet unload',
            })
        } finally {
            setDisableActionButtons(false)
            setDisableSaveButton(false)
        }
    }

    const initialTouched = {
        amount: true,
        name: true,
        addressLine1: true,
        postalCode: true,
        locality: true,
        accountType: true,
        requesterBankCode: true,
        requesterAccountNumber: true,
        requesterBic: true,
        requesterIban: true,
        otherAccountIdentifier: true,
        country: true,
    }

    const accountTypes = Object.values(AccountType).map((accountType) => (
        <Option key={accountType} value={accountType}>
            {accountType}
        </Option>
    ))

    return (
        <>
            <Button
                type="link"
                onClick={() => setModalActive(true)}
                disabled={disabled}
                data-testid="edit-button"
            >
                Edit
            </Button>
            <Modal
                title="Edit wallet unload information"
                open={modalActive}
                footer={null}
                onCancel={() => setModalActive(false)}
                width={800}
                centered
                destroyOnClose
            >
                <InfoContainer>
                    <InfoElem>
                        <span>Company</span>
                        <Text type="secondary"> {company?.name || 'Failed to retrieve'} </Text>
                    </InfoElem>
                    <InfoElem>
                        <span>Country</span>
                        <Text type="secondary">{country}</Text>
                    </InfoElem>
                    <InfoElem>
                        <span>Date</span>
                        {dayjs(walletUnload.createdAt).format('DD-MM-YY')}{' '}
                    </InfoElem>
                    <InfoElem>
                        <span>Type</span>
                        <Text type="secondary"> {getTypeForWalletUnload(walletUnload)} </Text>
                    </InfoElem>
                    <InfoElem>
                        <span>Amount</span>
                        <Text type="secondary"> {getAmountForWalletUnload(walletUnload)} </Text>
                    </InfoElem>
                    <InfoElem>
                        <span>Sender bank</span>
                        <Popover
                            title="Sender bank"
                            content={getBankInfoForDisplay(senderBank, senderInformation)}
                        >
                            <span>
                                <InfoCircleOutlined />
                            </span>
                        </Popover>
                    </InfoElem>
                </InfoContainer>
                <Divider />
                <Formik
                    initialTouched={initialTouched}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnMount
                    onSubmit={onConfirm}
                >
                    {({ isValid }) => (
                        <Form>
                            {!initialIbanActive && (
                                <RowContainer>
                                    <AntdForm.Item
                                        colon={false}
                                        label="Pleo account number"
                                        labelCol={labelCol}
                                    >
                                        <Text type="secondary">
                                            {accountNumber || 'No bank details'}
                                        </Text>
                                    </AntdForm.Item>
                                    <AntdForm.Item
                                        colon={false}
                                        label="Pleo sort code"
                                        labelCol={labelCol}
                                    >
                                        <Text type="secondary">
                                            {bankCode || 'No bank details'}
                                        </Text>
                                    </AntdForm.Item>
                                </RowContainer>
                            )}
                            {initialIbanActive && (
                                <AntdForm.Item colon={false} label="Pleo IBAN" labelCol={labelCol}>
                                    <Text type="secondary">{iban || 'No bank details'}</Text>
                                </AntdForm.Item>
                            )}
                            <RowContainer>
                                <FormItem
                                    colon={false}
                                    name="requesterBankCode"
                                    label="Requester bank code"
                                    labelCol={labelCol}
                                >
                                    <Input name="requesterBankCode" />
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    name="requesterBic"
                                    label="Requester BIC"
                                    labelCol={labelCol}
                                >
                                    <Input name="requesterBic" />
                                </FormItem>
                            </RowContainer>
                            <RowContainer>
                                <FormItem
                                    colon={false}
                                    name="requesterAccountNumber"
                                    label="Requester account number"
                                    labelCol={labelCol}
                                >
                                    <Input name="requesterAccountNumber" />
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    name="requesterIban"
                                    label="Requester IBAN"
                                    labelCol={labelCol}
                                >
                                    <Input name="requesterIban" />
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    name="accountType"
                                    label="Account type"
                                    labelCol={labelCol}
                                >
                                    <Select
                                        name="accountType"
                                        placeholder="Account type"
                                        data-testid="accountTypes-selector"
                                    >
                                        {accountTypes}
                                    </Select>
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    name="otherAccountIdentifier"
                                    label="Other Account Identifier"
                                    labelCol={labelCol}
                                >
                                    <Input name="otherAccountIdentifier" />
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    name="bankName"
                                    label="Bank name"
                                    labelCol={labelCol}
                                >
                                    <Input name="bankName" />
                                </FormItem>
                            </RowContainer>
                            <FormItem colon={false} name="reason" label="Reason">
                                <Input.TextArea name="reason" disabled={notEditable} />
                            </FormItem>
                            <RowContainer>
                                <FormItem
                                    colon={false}
                                    name="amount"
                                    label="Amount"
                                    labelCol={labelCol}
                                >
                                    <InputNumber name="amount" min={0} disabled={notEditable} />
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    name="name"
                                    label="Beneficiary name"
                                    labelCol={labelCol}
                                >
                                    <Input name="name" />
                                </FormItem>
                            </RowContainer>
                            <RowContainer>
                                <FormItem
                                    colon={false}
                                    name="addressLine1"
                                    label="Address line 1"
                                    labelCol={labelCol}
                                >
                                    <Input name="addressLine1" />
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    name="addressLine2"
                                    label="Address line 2"
                                    labelCol={labelCol}
                                >
                                    <Input name="addressLine2" />
                                </FormItem>
                            </RowContainer>
                            <RowContainer>
                                <FormItem
                                    colon={false}
                                    name="postalCode"
                                    label="Postal code"
                                    labelCol={labelCol}
                                >
                                    <Input name="postalCode" />
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    name="locality"
                                    label="City"
                                    labelCol={labelCol}
                                >
                                    <Input name="locality" />
                                </FormItem>
                            </RowContainer>
                            <RowContainer>
                                <FormItem
                                    colon={false}
                                    name="region"
                                    label="Region"
                                    labelCol={labelCol}
                                >
                                    <Input name="region" />
                                </FormItem>
                                <FormItem
                                    colon={false}
                                    name="country"
                                    label="Country code"
                                    labelCol={labelCol}
                                >
                                    <Input name="country" />
                                </FormItem>
                            </RowContainer>
                            <Divider />
                            <ButtonContainer>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    disabled={disableSaveButton || !isValid}
                                    data-testid="save-button"
                                >
                                    Save
                                </Button>
                            </ButtonContainer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}

const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const InfoElem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 300px;
`

const RowContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${spacing.space24};
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const labelCol = {
    span: 24,
}
