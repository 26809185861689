import { Button, Form, Input, notification, Typography } from 'antd'
import { cashFlowBff } from './bff'
import type { FC } from 'react'

interface Props {
    companyId: string
}

const InitiateDirectDebitView: FC<Props> = ({ companyId }) => {
    const { data: companyBalance } = cashFlowBff.getCurrentAvailableBalance.useQuery({
        companyId,
    })
    const { data: directDebitEligibility } = cashFlowBff.getDirectDebitEligibility.useQuery({
        companyId,
    })
    const { mutate: initiateDirectDebit } = cashFlowBff.initiateDirectDebit.useMutation({
        onSuccess: () => {
            notification.success({
                message: 'Success',
                description: 'The direct debit was successfully initiated.',
            })
        },
        onError: () => {
            notification.error({
                message: 'Error',
                description: 'An error occurred durint the direct debit initiation.',
            })
        },
    })

    const currency = companyBalance?.balance?.current?.currency

    if (!currency) {
        return (
            <Typography.Text>
                No currency found using the available company balance.
            </Typography.Text>
        )
    }

    const onSubmit = async (values: { amount: string }) => {
        initiateDirectDebit({
            companyId,
            amount: Number(values.amount),
            currency,
            isAutomatic: false,
        })
    }

    if (!directDebitEligibility) {
        return (
            <Typography.Text>The company doesn't have a direct debit eligibility.</Typography.Text>
        )
    }

    if (directDebitEligibility.status !== 'ELIGIBLE') {
        return <Typography.Text>The company is not direct debit eligible.</Typography.Text>
    }

    return (
        <Form
            name="basic"
            wrapperCol={{ span: 4 }}
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            autoComplete="off"
        >
            <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true, message: 'Please input the amount.' }]}
            >
                <Input addonAfter={currency} />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

export default InitiateDirectDebitView
