import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js'
import {
    CSVHeaders,
    CustomOrderFormValues,
    CustomOrders,
    OrderReadyForUpdate,
    Order,
    ShippingAddress,
    Manufacturer,
    DeliveryType,
} from 'store/modules/support/card-orders/types'
import type { Address } from 'types/deimos-company'
import * as Yup from 'yup'
import { groupByAddress } from '../utils'

export const validationSchema = Yup.object().shape({
    companyId: Yup.string().required('Required'),
    companyEmbossLine: Yup.string().required('Required').max(21, 'Max 21 characters'),
    manufacturer: Yup.string().required('Required'),
    orders: Yup.array()
        .of(
            Yup.object().shape({
                attention: Yup.string().required('Required'),
                phone: Yup.string().when('address', {
                    is: (address: Address) => !!address.country,
                    then: (schema) => schema.phone(Yup.ref('country')),
                }),
                address: Yup.object().shape({
                    addressLine1: Yup.string().required('AddressLine1 is required'),
                    addressLine2: Yup.string().notRequired(),
                    locality: Yup.string().required('Locality is required'),
                    region: Yup.string().notRequired().max(32, 'Max 32 characters'),
                    postalCode: Yup.string().required('Postal code is required'),
                    country: Yup.string()
                        .matches(/^[A-Z]{2}$/, 'Country code like "DK"')
                        .required('Country is required'),
                }),
                deliveryType: Yup.string().required('Delivery type is required'),
                cardOwners: Yup.array()
                    .of(
                        Yup.object().shape({
                            employeeId: Yup.string().notRequired(),
                            name: Yup.string().required('Required').max(21, 'Max 21 characters'),
                        })
                    )
                    .required('Required')
                    .min(1),
            })
        )
        .required('Required')
        .min(1),
})

export const mapValuesToRequest = (
    values: CustomOrderFormValues,
    isGPS: boolean,
    companyName: string,
    allowExpress: boolean
): CustomOrders => {
    const orders: OrderReadyForUpdate[] = values.orders.map((order) => {
        let internationalNumber: string | undefined
        if (order.phone) {
            internationalNumber = parsePhoneNumberFromString(
                order.phone,
                order.address.country as CountryCode
            )?.number.toString()
        }

        const shippingAddress: ShippingAddress = {
            attention: order.attention,
            company: companyName,
            phone: internationalNumber,
            ...order.address,
        }
        const isIdemia = values.manufacturer === Manufacturer.IDEMIA
        const isSE = order.address.country === 'SE'
        const deliveryTypeForNiteCrest = isSE ? DeliveryType.EXPRESS : DeliveryType.STANDARD
        const deliveryTypeForIdemia = allowExpress ? order.deliveryType : DeliveryType.STANDARD
        const deliveryType = isIdemia ? deliveryTypeForIdemia : deliveryTypeForNiteCrest
        return {
            amount: order.cardOwners.length,
            address: shippingAddress,
            embossLine: values.companyEmbossLine,
            manufacturer: isGPS ? Manufacturer.NITECREST : values.manufacturer,
            deliveryType,
            free: !values.fee,
            cardOwners: order.cardOwners.map(({ employeeId, name }) => ({
                employeeId: employeeId || null,
                name,
            })),
        }
    })
    const customOrders: CustomOrders = { orders, companyId: values.companyId }
    return customOrders
}

export const mapCSVToValues = (results: any, companyId: string, isGPS: boolean) => {
    let companyEmbossLine = ''
    const orders: Order[] = results.map((result: any) => {
        companyEmbossLine = result[CSVHeaders.EMBOSSCOMPANY]
        const shippingAddress: Address = {
            addressLine1: result[CSVHeaders.ADDRESSLINE1],
            addressLine2: result[CSVHeaders.ADDRESSLINE2],
            postalCode: result[CSVHeaders.POSTALCODE],
            locality: result[CSVHeaders.LOCALITY],
            country: result[CSVHeaders.COUNTRY],
        }
        return {
            attention: result[CSVHeaders.ATTENTION],
            phone: result[CSVHeaders.PHONE],
            amount: result[CSVHeaders.AMOUNT],
            address: shippingAddress,
            employeeEmbossLine: result[CSVHeaders.EMBOSSEMPLOYEE],
            deliveryType: DeliveryType.STANDARD,
            cardOwners: [{ employeeId: '', name: result[CSVHeaders.EMBOSSEMPLOYEE] }],
        }
    })
    const formValues: CustomOrderFormValues = {
        companyId,
        companyEmbossLine,
        manufacturer: isGPS ? Manufacturer.NITECREST : Manufacturer.IDEMIA,
        orders: groupByAddress(orders),
        fee: false,
    }
    return formValues
}
