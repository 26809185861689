import {
    CompanyOffboardingReason,
    CompanyOffboardingState,
    OffboardingCompany,
    PaginatedOffboardingCompanies,
} from '@pleo-io/deimos'
import { Card, Popover, Space, Spin, Table, Tag, Typography } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import { DisplayOffboardingReason, categoryTagColor } from 'components/company-offboarding/utils'
import { PageContentLayout } from 'components/layout-containers'
import { getEmojiFlag } from 'countries-list'
import { useUser } from 'providers/user-context'
import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import {
    DisplayedStates,
    useOffboardingCompanies,
    useOffboardingCompanyOverview,
} from 'services/deimos/companies'
import { deSnakify } from 'utils/strings'
import FilterCompanyList from 'components/filter-company-list/filter-company-list'
import { ClosedCompaniesList } from 'components/closed-companies/closed-companies-list'
import dayjs from 'packages/dayjs'

const { Text } = Typography

const showReasons = (company: OffboardingCompany) => {
    const reasons = company?.status?.reasons
    if (reasons === undefined || reasons.length === 0)
        return <Text type="secondary">Not yet defined</Text>

    return reasons.map((reason: CompanyOffboardingReason, index) => (
        <div key={index}>{DisplayOffboardingReason[reason]}</div>
    ))
}

const showCategory = (company: OffboardingCompany) => {
    const category = company.status?.category
    if (!category) return '-'

    return (
        <Popover title="Reason" content={showReasons(company)}>
            <Tag color={categoryTagColor(category)}>{deSnakify(category)}</Tag>
        </Popover>
    )
}

export const StepActionability: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    const { data: companyOverview } = useOffboardingCompanyOverview(companyId)
    if (!companyOverview) return <Spin data-testid="actionableSpinner" size="small" />
    const offboardingSteps = Array.from(companyOverview.offboardingSteps)
    const areStepsToPerform = offboardingSteps.some((step) => {
        return step.available && !step.completed
    })
    const completedSteps = offboardingSteps.filter((step) => {
        return step.completed
    }).length

    let tag
    if (completedSteps === offboardingSteps.length)
        tag = (
            <Tag data-testid="readyToCloseTag" color="blue">
                Ready to close
            </Tag>
        )
    else if (areStepsToPerform)
        tag = (
            <Tag data-testid="actionableTag" color="green">
                Actionable
            </Tag>
        )
    else
        tag = (
            <Tag data-testid="nonActionableTag" color="orange">
                Non-actionable
            </Tag>
        )

    return (
        <Link to={`/customer-success/offboarding/${companyId}`}>
            <Popover title={`Completed steps: ${completedSteps}/${offboardingSteps.length}`}>
                {tag}
            </Popover>
        </Link>
    )
}

const OffboardingCompaniesList: FC<
    React.PropsWithChildren<{
        paginatedCompanies: PaginatedOffboardingCompanies
        setLimit: (limit: number) => void
        setPage: (page: number) => void
        currentPage: number
        limit: number
        loading: boolean
    }>
> = ({ paginatedCompanies, setLimit, setPage, currentPage, limit, loading }) => {
    const { totalCount, companies } = paginatedCompanies

    const offboardingColumns: ColumnProps<OffboardingCompany>[] = [
        {
            title: 'Actionable',
            render: (record) => <StepActionability companyId={record.id} />,
        },
        {
            title: 'Country',
            dataIndex: 'country',
            render: (country) => (
                <Space size={4}>
                    {getEmojiFlag(country)} {country}
                </Space>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (name, record) => (
                <Link to={`/customer-success/offboarding/${record.id}`}>{name}</Link>
            ),
        },
        {
            title: 'Category',
            render: (record) => showCategory(record),
        },
        {
            title: 'Initiated by',
            dataIndex: ['status', 'initiatedBy'],
        },
        {
            title: 'Initiated at',
            dataIndex: ['status', 'initiatedAt'],
            defaultSortOrder: 'descend',
            render: (date?: string) => (date ? dayjs(date).format('lll') : ''),
            sorter: (a, b) =>
                dayjs(a.status?.initiatedAt || new Date()).valueOf() -
                dayjs(b.status?.initiatedAt || new Date()).valueOf(),
        },
    ]

    return (
        <Table
            dataSource={companies}
            columns={offboardingColumns}
            rowKey="id"
            pagination={{
                onChange: (page, pageSize) => {
                    if (pageSize) {
                        setLimit(pageSize)
                        setPage(page)
                    }
                },
                current: currentPage,
                total: totalCount,
                pageSize: limit,
            }}
            loading={loading}
        />
    )
}

const Offboarding: FC<React.PropsWithChildren<unknown>> = () => {
    const { email } = useUser()
    const [activeTab, setActiveTab] = useState<DisplayedStates>('CLOSING')

    const {
        data: paginatedClosingCompanies,
        pagination: {
            setLimit: setClosingLimit,
            setPage: setClosingPage,
            page: closingPage,
            limit: closingLimit,
        },
        setInitiatedBy,
        initiatedBy,
        isValidating: isClosingCompaniesValidating,
    } = useOffboardingCompanies(CompanyOffboardingState.CLOSING, email)

    const {
        data: paginatedAutomatedCompanies,
        pagination: {
            setLimit: setAutomatedLimit,
            setPage: setAutomatedPage,
            page: automatedPage,
            limit: automatedLimit,
        },
        setCompanyName: setAutomatedCompany,
        companyName: automatedCompany,
        isValidating: isAutomatedCompaniesValidating,
    } = useOffboardingCompanies(CompanyOffboardingState.CLOSING, null, true)

    const {
        data: paginatedClosedCompanies,
        pagination: {
            setLimit: setClosedLimit,
            setPage: setClosedPage,
            page: closedPage,
            limit: closedLimit,
        },
        setCompanyName,
        companyName,
        isValidating: isClosedCompaniesValidating,
    } = useOffboardingCompanies(CompanyOffboardingState.CLOSED)

    const tabList = [
        { key: 'CLOSING', tab: 'All Offboarding' },
        { key: 'AUTO_OFFBOARDING', tab: 'Auto Offboarding' },
        { key: 'CLOSED', tab: 'Closed' },
    ]
    const contentList = {
        CLOSING: (
            <OffboardingCompaniesList
                currentPage={closingPage}
                limit={closingLimit}
                paginatedCompanies={paginatedClosingCompanies || { companies: [], totalCount: 0 }}
                loading={isClosingCompaniesValidating}
                setPage={(page) => setClosingPage(page)}
                setLimit={(limit) => setClosingLimit(limit)}
            />
        ),
        AUTO_OFFBOARDING: (
            <OffboardingCompaniesList
                currentPage={automatedPage}
                limit={automatedLimit}
                paginatedCompanies={paginatedAutomatedCompanies || { companies: [], totalCount: 0 }}
                loading={isAutomatedCompaniesValidating}
                setPage={(page) => setAutomatedPage(page)}
                setLimit={(limit) => setAutomatedLimit(limit)}
            />
        ),
        CLOSED: (
            <ClosedCompaniesList
                currentPage={closedPage}
                limit={closedLimit}
                paginatedCompanies={paginatedClosedCompanies || { companies: [], totalCount: 0 }}
                loading={isClosedCompaniesValidating}
                setPage={(page) => setClosedPage(page)}
                setLimit={(limit) => setClosedLimit(limit)}
            />
        ),
    }

    return (
        <PageContentLayout>
            <Card
                tabList={tabList}
                onTabChange={(key) => {
                    setActiveTab(key as DisplayedStates)
                }}
                loading={!paginatedClosingCompanies}
            >
                {activeTab === 'CLOSING' && (
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                        <FilterCompanyList
                            label="Filter by initiated by:"
                            placeholder="Initiator"
                            defaultValue={initiatedBy}
                            onSearch={(initiator: string) => setInitiatedBy(initiator)}
                        />
                        {contentList[activeTab]}
                    </Space>
                )}
                {activeTab === 'AUTO_OFFBOARDING' && (
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                        <Text>
                            Only showing companies that are auto-offboarding (have initiated the
                            offboarding through the product)
                        </Text>
                        <FilterCompanyList
                            label="Filter by company name:"
                            placeholder="Company name"
                            defaultValue={automatedCompany}
                            onSearch={(company: string) => setAutomatedCompany(company)}
                        />
                        {contentList[activeTab]}
                    </Space>
                )}
                {activeTab === 'CLOSED' && (
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                        <FilterCompanyList
                            label="Filter by company name:"
                            placeholder="Company name"
                            defaultValue={companyName}
                            onSearch={(company: string) => setCompanyName(company)}
                        />
                        {contentList[activeTab]}
                    </Space>
                )}
            </Card>
        </PageContentLayout>
    )
}

export default Offboarding
