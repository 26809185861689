import { useEffect, useState } from 'react'
import config from 'config'
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next'

const useAnalytics = () => {
    const [analytics, setAnalytics] = useState<Analytics | null>(null)
    const [writeKey] = useState(config.segment.key)

    useEffect(() => {
        const loadAnalytics = async () => {
            const [response] = await AnalyticsBrowser.load({ writeKey })
            setAnalytics(response)
        }
        loadAnalytics()
    }, [writeKey])
    return analytics
}

export default useAnalytics
