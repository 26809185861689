import type { FC } from 'react'
import styled from 'styled-components'

import type { Document } from 'types/styx'
import FileList from 'pages/compliance/company/shared/file-list/file-list'

export interface DocumentFilesProps {
    document: Document
    getLinkToFile: (fileId: string, download?: boolean) => Promise<string>
}

export const DocumentFiles: FC<React.PropsWithChildren<DocumentFilesProps>> = ({
    document,
    getLinkToFile,
}) => {
    return (
        <DocumentWrapper data-testid="document">
            <FileList files={document.files} getLinkToFile={getLinkToFile} />
        </DocumentWrapper>
    )
}

const DocumentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
