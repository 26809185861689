import { Modal, notification, Space, Typography } from 'antd'
import { Formik, FormikHelpers, useFormikContext } from 'formik'
import { FC, useMemo } from 'react'
import { Form, SubmitButton, Input } from 'formik-antd'
import { overridePleoReserveLimit, usePleoReserveEligibility } from 'services/deimos/pleo-reserve'
import { useSelector } from 'react-redux'
import { selectCompanyCurrency, selectCompanyId } from 'store/modules/support/company/selectors'
import * as yup from 'yup'
import { useCompanyBalance } from 'services/deimos/companies'
import { usePleoReserveEligibleLimit } from './pleo-reserve'

const { TextArea } = Input
const { Text } = Typography

type ReserveLimitFormValues = {
    reserveLimit: number
    updateReason: string
}

type PleoReserveLimitModalProps = {
    isVisible: boolean
    closeModal: () => void
}

/**
 * Reserve limit must be lower or equal to
 * 1) available wallet balance (current + reserve limit)
 * 2) Auto Top-up transfer amount - threshold
 */
const useMaximumReserveLimit = () => {
    const companyId = useSelector(selectCompanyId)
    const companyBalance = useCompanyBalance(companyId)
    return companyBalance.data?.balance?.available?.value ?? 0
}

const useValidationSchema = () => {
    const maximumReserveLimit = useMaximumReserveLimit()
    return useMemo(
        () =>
            yup.object().shape({
                reserveLimit: yup
                    .number()
                    .max(maximumReserveLimit)
                    .required('This field is required'),
                updateReason: yup.string().required('This field is required'),
            }),
        [maximumReserveLimit]
    )
}

export const PleoReserveLimitModal: FC<PleoReserveLimitModalProps> = ({
    closeModal,
    isVisible,
}) => {
    const companyId = useSelector(selectCompanyId)
    const pleoReserve = usePleoReserveEligibility(companyId)
    const pleoReserveLimit = usePleoReserveEligibleLimit()
    const validationSchema = useValidationSchema()
    const companyBalance = useCompanyBalance(companyId)

    const onSubmit = async (
        formValues: ReserveLimitFormValues,
        { resetForm }: FormikHelpers<ReserveLimitFormValues>
    ) => {
        try {
            await overridePleoReserveLimit({
                amount: formValues.reserveLimit,
                updateReason: formValues.updateReason,
                companyId,
            })
            notification.success({
                message: 'Reserve limit change successful!',
            })
            pleoReserve.mutate()
            companyBalance.mutate()
            resetForm()
            closeModal()
        } catch (error) {
            notification.error({
                message: 'Reserve limit change failed!',
            })
        }
    }
    const initialValues: ReserveLimitFormValues = {
        reserveLimit: pleoReserveLimit?.value ?? 0,
        updateReason: '',
    }
    if (!isVisible) {
        return null
    }
    return (
        <Modal
            open={isVisible}
            onCancel={closeModal}
            footer={false}
            centered
            title="Pleo Reserve limit"
        >
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                <PleoReserveLimitFormContent />
            </Formik>
        </Modal>
    )
}

const PleoReserveLimitFormContent = () => {
    const { dirty, isValid } = useFormikContext()
    const currency = useSelector(selectCompanyCurrency)
    const maximumReserveLimit = useMaximumReserveLimit()
    const companyId = useSelector(selectCompanyId)
    const pleoReserve = usePleoReserveEligibility(companyId)

    return (
        <Form layout="vertical">
            <Form.Item
                name="reserveLimit"
                label={`Reserve limit (${currency})`}
                help={`Maximum allowed limit is ${maximumReserveLimit}`}
                required
            >
                <Input name="reserveLimit" />
            </Form.Item>
            <Form.Item name="updateReason" label="Update reason" required>
                <TextArea
                    name="updateReason"
                    placeholder="Describe the reason for this limit change..."
                />
            </Form.Item>
            <Space direction="vertical" size="middle">
                {!pleoReserve.data?.enabled && (
                    <Text type="secondary">
                        Pleo Reserve must be active in order to set a custom limit.
                    </Text>
                )}
                <SubmitButton disabled={!dirty || !isValid || !pleoReserve.data?.enabled}>
                    Submit
                </SubmitButton>
            </Space>
        </Form>
    )
}
