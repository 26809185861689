import type { FC } from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { Divider, Space, Descriptions } from 'antd'

import type { Person } from 'types/styx'
import { getLatestKycResult } from 'pages/compliance/company/people/helpers'

import { useParams } from 'react-router-dom'
import { DocumentsTabs } from 'pages/compliance/closed-company/documents/document-tabs'
import dayjs from 'packages/dayjs'
import { useClosedCompanySnapshot } from 'services/deimos/companies'
import { getLinkToPersonDocumentFile } from 'services/styx/person'

interface Props {
    person: Person
}

export const DirectorPanel: FC<React.PropsWithChildren<Props>> = ({ person, children }) => {
    return (
        <>
            <Row>
                <Space>
                    <Descriptions bordered size="small" layout="vertical">
                        <Descriptions.Item label="PEP">
                            {getLatestKycResult(person.pepChecks) || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="SIS">
                            {getLatestKycResult(person.sisChecks) || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="IDV">
                            {getLatestKycResult(person.idvChecks) || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="eKYC">
                            {getLatestKycResult(person.ekycChecks) || '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Space>
            </Row>
            <Divider />
            <PersonDetails>
                <Descriptions bordered size="small" layout="vertical">
                    <Descriptions.Item label="National ID no.">
                        {person.nationalId || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date of birth" data-testId="dob">
                        {person?.dateOfBirth?.month
                            ? dayjs()
                                  .month(person.dateOfBirth.month - 1)
                                  .format('MMM')
                            : '-'}
                        {person?.dateOfBirth?.day ? ` ${person.dateOfBirth.day}, ` : ' -, '}
                        {person?.dateOfBirth?.year ? person.dateOfBirth.year : '-'}
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions bordered column={7} size="small" layout="vertical">
                    <Descriptions.Item label="Street">
                        {person.address?.street || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Number">
                        {person.address?.houseNumber || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Floor">
                        {person.address?.floor || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Locality">
                        {person.address?.locality || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Region">
                        {person.address?.region || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Postal code">
                        {person.address?.postalCode || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Country">
                        {person.address?.country || '-'}
                    </Descriptions.Item>
                </Descriptions>
            </PersonDetails>
            <Divider />
            {children}
        </>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing.space8};
`

const PersonDetails = styled.div`
    display: flex;
    gap: ${spacing.space24};
    flex-wrap: wrap;
`

const DirectorPanelContainer: FC<React.PropsWithChildren<{ person: Person }>> = ({ person }) => {
    const { id: companyId } = useParams()
    const snapshot = useClosedCompanySnapshot(companyId)

    const subjectId = person.id

    const handleGetLinkToFile =
        (documentId: string) => async (fileId: string, download?: boolean) => {
            const { url }: { url: string } = await getLinkToPersonDocumentFile({
                subjectId,
                documentId,
                fileId,
                snapshot,
                download,
            }).json()

            return url
        }

    return (
        <DirectorPanel person={person}>
            <DocumentsTabs handleGetLinkToFile={handleGetLinkToFile} documents={person.documents} />
        </DirectorPanel>
    )
}

export default DirectorPanelContainer
