import { Alert, Button, Input, List, Result, Space, Typography } from 'antd'
import { bff } from '../bff'
import { useState } from 'react'
import { useDebounce } from 'utils/use-debounce'
import styled from 'styled-components'
import { ArrowRightOutlined } from '@ant-design/icons'
import { plural } from 'packages/strings/plural'

const BulkEmailUpdate = ({ companyId }: { companyId: string }) => {
    const [currentDomain, setCurrentDomain] = useState<string | undefined>()
    const [newDomain, setNewDomain] = useState<string | undefined>()
    const debouncedCurrentDomain = useDebounce(currentDomain, 500)
    const debouncedNewDomain = useDebounce(newDomain, 500)

    const {
        data: updateEmployeeEmailDomainResponse,
        mutateAsync: updateEmployeeEmailDomain,
        isLoading,
        error,
    } = bff.bulkEmailUpdate.updateEmployeeEmailDomain.useMutation()

    const { data: currentEmails } = bff.bulkEmailUpdate.getCurrentDomainEmployeeCount.useQuery(
        {
            companyId,
            currentDomain: debouncedCurrentDomain,
        },
        { enabled: !!debouncedCurrentDomain && !!debouncedNewDomain }
    )

    const allowSubmit =
        !isLoading &&
        debouncedCurrentDomain &&
        debouncedNewDomain &&
        currentEmails?.emailsToChange &&
        currentEmails?.emailsToChange?.length > 0

    const handleSubmit = async () => {
        if (!debouncedCurrentDomain || !debouncedNewDomain) return
        await updateEmployeeEmailDomain({
            companyId,
            currentDomain: debouncedCurrentDomain,
            newDomain: debouncedNewDomain,
        })
    }

    return (
        <Space size={'large'} direction="vertical" style={{ width: '40rem' }}>
            <div>
                <Typography.Title level={3}>Bulk Email Update</Typography.Title>
                <Typography.Text>
                    Update all email addresses associated with this company to a new domain.
                </Typography.Text>
            </div>
            {!updateEmployeeEmailDomainResponse && (
                <>
                    <div>
                        <label htmlFor="currentDomain">Current Domain</label>
                        <Input
                            id="currentDomain"
                            onChange={(e) => setCurrentDomain(e.target.value)}
                            addonBefore="@"
                        />
                        {debouncedCurrentDomain && currentEmails?.total === 0 && (
                            <Typography.Text type="danger">
                                No employees found with this domain.
                            </Typography.Text>
                        )}
                    </div>
                    <div>
                        <label htmlFor="newDomain">New Domain</label>
                        <Input
                            id="newDomain"
                            onChange={(e) => setNewDomain(e.target.value)}
                            addonBefore="@"
                        />
                    </div>
                    <ScrollingListContainer>
                        <List
                            size="small"
                            dataSource={currentEmails?.emailsToChange}
                            renderItem={(currentEmail) => (
                                <List.Item key={currentEmail}>
                                    <Typography.Text>{currentEmail}</Typography.Text>
                                    <ArrowRightOutlined />
                                    <Typography.Text>
                                        {replaceDomain({ currentEmail, currentDomain, newDomain })}
                                    </Typography.Text>
                                </List.Item>
                            )}
                        />
                    </ScrollingListContainer>
                    <Alert message="This preview list may include deleted users, however their email addresses will not be affected." />
                    <Button
                        type="primary"
                        loading={isLoading}
                        disabled={!allowSubmit}
                        onClick={handleSubmit}
                    >
                        Change email domain
                    </Button>{' '}
                    {error && (
                        <Typography.Text type="danger">
                            Something went wrong. Please double check the domains and try again.
                        </Typography.Text>
                    )}
                </>
            )}
            {updateEmployeeEmailDomainResponse && (
                <Result
                    status="success"
                    title="Email domain updated."
                    subTitle={`${
                        updateEmployeeEmailDomainResponse?.emailsScheduledToChange
                    } ${plural(
                        'user',
                        'users',
                        updateEmployeeEmailDomainResponse?.emailsScheduledToChange
                    )} will be sent an email requesting them to confirm the change.`}
                />
            )}
        </Space>
    )
}

export default BulkEmailUpdate

const ScrollingListContainer = styled.div`
    height: 10rem;
    overflow: auto;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
`

const replaceDomain = ({
    currentEmail,
    currentDomain,
    newDomain,
}: {
    currentEmail: string | null
    currentDomain?: string
    newDomain?: string
}) =>
    currentEmail &&
    currentDomain &&
    newDomain &&
    currentEmail.replace(`@${currentDomain}`, `@${newDomain}`)
