import React, { FC, useState } from 'react'
import { Country, getEmojiFlag } from 'countries-list'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { BlockOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import {
    Button,
    Descriptions,
    Typography,
    Modal,
    Tag,
    message,
    Menu,
    Space,
    Dropdown,
    Popover,
    Breadcrumb,
} from 'antd'
import type { FormValues } from 'pages/compliance/company/edit-details/validation-schema'
import {
    StyxCompany,
    KycStatus,
    IndustryCodes,
    StyxCompanyV2,
    CompanyBusinessOffering,
} from 'types/styx'
import Spinner from 'components/content-spinner'
import CompanyAddress from 'components/company-address/company-address'
import EditDetailsForm from 'pages/compliance/company/edit-details/edit-details-form'
import type { ClosedCompany, CompanyOffboardingStatus } from '@pleo-io/deimos'
import DeactivateCompanyButton from './deactivate-company-button'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import RegistryIdSearch from './registry-id-search/registry-id-search'
import UpdateVelocityForm from './update-velocity-form/update-velocity-form'
import CompanyTabs from './company-tabs'
import CompanyCollapse from './company-collapse'
import { PageContentLayout } from 'components/layout-containers'
import { useAccessToken } from 'providers/auth-context'
import {
    useStyxCompany,
    updateDeimosCompanyFromStyx,
} from 'services/deimos/styx-company/styx-company'
import { getCodeFromNace, useCompanyIndustryCodes } from 'services/styx/industry-codes'
import OffboardingForm from 'components/company-offboarding/offboarding-form'

import {
    useClosedCompaniesByRegistration,
    useGetCompany,
    useRelatedCompaniesByRegistration,
} from 'services/deimos/companies'
import dayjs from 'packages/dayjs'
import { onDeleteOffboarding } from 'components/company-offboarding/cancel-offboarding-confirmation'
import { getCompanyRegistryLink, onGetZipFile } from '../shared/utils'
import CompanyErrorResult from '../shared/company-error-result'
import { showCategory, showNotes, showReasons } from 'components/company-offboarding/utils'
import { CopyIcon, DetailsContainer, RegNumberRowContent } from '../shared/styled'
import config, { chromeExtension } from 'config'
import useAnalytics from 'utils/analytics'
import { usePreferences, useUser } from 'providers/user-context'
import { RelatedByOrganization } from './shared/related-by-organization/relatated-by-organization'
import NaceCodeContainer from './nace-codes/nace-codes'
import CountriesOfManufacturingContainer from './countries-of-manufacturing/countries-of-manufacturing'
import { useCountriesOfManufacturing } from 'services/styx/countries-of-manufacturing'
import { useStyxV2Company } from 'services/styx/company'
import { useManagement } from 'services/styx/management'
import { difference, negate } from 'lodash'
import { getCompanySizeLabel } from 'utils/company'
import { OnboardedViaTag } from '../shared/company-onboarded-via-tag'
import type { OnboardingSource } from 'types/deimos-company'
import { downloadPdfDocument } from 'services/styx/b4b-companies'
import { useKycUserResponse } from 'services/styx/kyc-user-response'
import { useBusinessOfferings, useCompanyBusinessOffering } from 'services/styx/business-offering'
import { isDueDiligenceAgentInput } from 'services/styx/utils'
import { AdverseMediaScreening } from './shared/company-kyc-screenings/components/adverse-media-screening'
import { SanctionsScreening } from './shared/company-kyc-screenings/components/sanctions-screening'
import { PartnerReferralTag } from '../shared/partner-referral-tag/partner-referral-tag'
import { formatAddress, getFddMetadata } from './utils'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { CompanyContextProvider } from './context'

const { Title, Link, Text } = Typography
const { confirm } = Modal

export enum RiskScore {
    LOW = 'LOW',
    MEDIUM_LOW = 'MEDIUM_LOW',
    MEDIUM = 'MEDIUM',
    MEDIUM_HIGH = 'MEDIUM_HIGH',
    HIGH = 'HIGH',
}

export interface RiskFactorScore {
    name: string
    weight: number
    risk: number
    isOverride: boolean
}
export interface CustomerRiskRating {
    score: RiskScore
    numericScore: number
    totalNumberOfFactors: number
    riskFactorScores: RiskFactorScore[]
}

interface Props {
    isManualSignup: boolean
    company: StyxCompany
    companyV2?: StyxCompanyV2
    organizationId?: string
    currentKycStatus: KycStatus
    naceCodes: IndustryCodes[] | null
    countriesOfManufacturing?: Country[]
    hasManuallyApprovedPep?: boolean
    goBack: () => void
    onEdit: () => void
    updateDeimosFromStyx: () => void
    revalidateCompany: () => void
    handleVelocityChange: (values: { velocity: KycStatus }) => Promise<void>
    onUpdateRegistryId: (registryId: string) => Promise<void>
    onClosedArchiveClick: (closedCompany: ClosedCompany) => void
    onRelatedCompanyClick: (relatedCompany: string) => void
    businessOffering: CompanyBusinessOffering[]
}

const Breadcrumbs = ({ companyName }: { companyName?: string }) => {
    return (
        <div style={{ margin: '24px 16px 0' }}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <RouterLink to={'/compliance'}>Compliance</RouterLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <RouterLink to={'/compliance/companies'}>Companies</RouterLink>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{companyName}</Breadcrumb.Item>
            </Breadcrumb>
        </div>
    )
}

const Tags = ({
    onboardedVia,
    isManualSignup,
    offboardingStatus,
    companyId,
    isPreviouslyOffboarded,
}: {
    onboardedVia: OnboardingSource
    isManualSignup: boolean
    offboardingStatus?: CompanyOffboardingStatus | null
    companyId: string
    isPreviouslyOffboarded: boolean
}) => {
    return (
        <div style={{ fontWeight: 400 }}>
            <Space>
                <OnboardedViaTag onboardedVia={onboardedVia as OnboardingSource} />
                {isManualSignup && <Tag color="warning">Manual signup</Tag>}
                {!!offboardingStatus && (
                    <Tag color="blue">
                        <Link href={`/customer-success/offboarding/${companyId}`}>Offboarding</Link>
                    </Tag>
                )}
                {isPreviouslyOffboarded && <Tag color="warning">Previously offboarded</Tag>}
                <PartnerReferralTag deimosCompanyId={companyId} />
            </Space>
        </div>
    )
}

export const Company: FC<React.PropsWithChildren<Props>> = ({
    company,
    companyV2,
    organizationId,
    onEdit,
    isManualSignup,
    currentKycStatus,
    handleVelocityChange,
    updateDeimosFromStyx,
    onUpdateRegistryId,
    revalidateCompany,
    children,
    onClosedArchiveClick,
    onRelatedCompanyClick,
    naceCodes,
    countriesOfManufacturing,
    hasManuallyApprovedPep,
    businessOffering,
}) => {
    const emojiFlag = getEmojiFlag(company?.address?.country || 'GB')
    const companyRegistryLink = getCompanyRegistryLink(
        company.registryId,
        company.registrationNumber,
        company.legalName
    )
    const [offboardingModalActive, setOffboardingModal] = useState(false)
    const hasInitiateOffboarding = useHasPermissions(['initiate-offboarding'])
    const { data: closedCompanyArchive } = useClosedCompaniesByRegistration(
        company.registrationNumber
    )
    const accessToken = useAccessToken()
    const relatedCompanies = useRelatedCompaniesByRegistration(company.registrationNumber)
    const isOnboardedViaB4B = company.onboardedVia?.toUpperCase() === 'B4B'

    const fddMetadata = getFddMetadata(company.kycCheckpoints)

    const relatedCompaniesMenu = (
        <Menu style={{ overflow: 'scroll', maxHeight: '40vh' }}>
            {relatedCompanies.map((relatedCompany) => {
                return (
                    <Menu.Item
                        key={relatedCompany.id}
                        onClick={() => onRelatedCompanyClick(relatedCompany.id)}
                    >
                        {relatedCompany.name}
                    </Menu.Item>
                )
            })}
        </Menu>
    )

    const closedCompanyArchiveMenu = (
        <Menu style={{ overflow: 'scroll', maxHeight: '40vh' }}>
            {closedCompanyArchive?.companies.map((closedCompany: ClosedCompany) => {
                return (
                    <Menu.Item
                        key={closedCompany.id}
                        onClick={() => onClosedArchiveClick(closedCompany)}
                    >
                        {dayjs(closedCompany.deletedAt).format('lll')}
                    </Menu.Item>
                )
            })}
        </Menu>
    )

    const actionsMenu = (
        <Menu>
            {!isOnboardedViaB4B && (
                <Menu.Item key="1" onClick={() => onGetZipFile(company)}>
                    Get ZIP file
                </Menu.Item>
            )}
            {isOnboardedViaB4B && (
                <Menu.Item
                    key="2"
                    onClick={() =>
                        downloadPdfDocument(company.deimosId, company.registryId, () => {})
                    }
                >
                    Get ZIP file with KYC PDF
                </Menu.Item>
            )}
            {hasInitiateOffboarding && (
                <Menu.Item key="3" onClick={() => setOffboardingModal(!offboardingModalActive)}>
                    {company.offboardingStatus ? 'Edit offboarding' : 'Initiate offboarding'}
                </Menu.Item>
            )}
            {hasInitiateOffboarding && !!company.offboardingStatus && (
                <Menu.Item key="4" onClick={() => confirmCancelOffboarding()} danger={true}>
                    Cancel offboarding
                </Menu.Item>
            )}
            <Menu.Item key="5" onClick={updateDeimosFromStyx}>
                Sync with Deimos
            </Menu.Item>
        </Menu>
    )

    const confirmCancelOffboarding = () => {
        confirm({
            centered: true,
            icon: <ExclamationCircleOutlined />,
            title: 'Cancel offboarding?',
            content: `Are you sure you want to cancel the offboarding of ${company.legalName}`,
            okButtonProps: { id: 'confirm-offboarding' },
            onOk: async () => {
                await onDeleteOffboarding(company.deimosId)
                revalidateCompany()
            },
        })
    }

    const closeOffboardingModal = () => {
        setOffboardingModal(false)
        revalidateCompany()
    }

    const scorePopoverContent = () => {
        return (
            <Space direction="vertical">
                <Text>
                    Owner: <Text strong>{company.riskAssessment?.ownerName}</Text>
                </Text>
                <Text>
                    Date:{' '}
                    <Text strong>
                        {dayjs(company.riskAssessment?.assessmentDate).format('lll')}
                    </Text>
                </Text>
            </Space>
        )
    }

    // Send data to the Chrome extension which is used for capturing Proof of Business screenshots
    const isChrome = typeof chrome !== 'undefined'
    if (isChrome && chrome?.runtime?.sendMessage && company.documents) {
        const pobDocumentId = company.documents.find(
            (document) => document.category === 'PROOF_OF_BUSINESS'
        )?.id
        if (pobDocumentId) {
            chrome.runtime.sendMessage(chromeExtension.inspectorGadget.id, {
                type: chromeExtension.inspectorGadget.msgType.infoForPob,
                companyName: String(company?.legalName),
                companyId: company.deimosId,
                userToken: String(accessToken),
                documentId: pobDocumentId,
                urlPrefix: config.endpoints.api,
            })
        } else {
            chrome.runtime.sendMessage(chromeExtension.inspectorGadget.id, {
                type: chromeExtension.inspectorGadget.msgType.clearInfoForPob,
            })
        }
    }

    const kycUserResponse = useKycUserResponse(company.globalId).data
    const tradingName =
        companyV2?.tradingName ||
        (kycUserResponse?.hasTradingName === false ? 'Same as Legal Name' : null)
    const tradingAddress =
        companyV2?.tradingAddress ||
        (kycUserResponse?.hasTradingAddress === false ? 'Same as Legal Address' : null)

    const countryOfOperation =
        companyV2?.countriesOfOperation?.join(', ') ||
        (kycUserResponse?.hasCountriesOfOperation === false ? 'Same as Registration Country' : null)

    return (
        <>
            <Breadcrumbs companyName={company.legalName} />
            <PageHeader
                style={{ width: '100%' }}
                title={
                    <>
                        <Title
                            copyable={{ icon: [<CopyIcon key="copy-icon" />] }}
                            style={{ whiteSpace: 'normal', margin: 0 }}
                            level={4}
                        >
                            {company.legalName}
                        </Title>
                        <Text style={{ fontWeight: 'normal' }}>
                            {emojiFlag} {formatAddress(company.address)}
                        </Text>
                        <Tags
                            companyId={company.deimosId}
                            isManualSignup={isManualSignup}
                            isPreviouslyOffboarded={!!closedCompanyArchive?.companies.length}
                            offboardingStatus={company.offboardingStatus}
                            onboardedVia={company.onboardedVia as OnboardingSource}
                        />
                    </>
                }
                extra={
                    <Space.Compact>
                        <Button onClick={onEdit} key="edit-company">
                            Edit company
                        </Button>
                        <Dropdown key="actions-dropdown" overlay={actionsMenu}>
                            <Button>
                                Actions <DownOutlined />
                            </Button>
                        </Dropdown>
                        {!!closedCompanyArchive?.companies.length && (
                            <Dropdown
                                key="closed-archive-dropdown"
                                overlay={closedCompanyArchiveMenu}
                                trigger={['click']}
                            >
                                <Button>
                                    Closed archive <DownOutlined />
                                </Button>
                            </Dropdown>
                        )}
                        {relatedCompanies.length > 1 && (
                            <Dropdown
                                key="related-companies-dropdown"
                                overlay={relatedCompaniesMenu}
                                trigger={['click']}
                            >
                                <Button>
                                    Related companies <DownOutlined />
                                </Button>
                            </Dropdown>
                        )}
                        {organizationId && (
                            <RelatedByOrganization
                                organizationId={organizationId}
                                entityId={company?.deimosId}
                                onChangeEntity={onRelatedCompanyClick}
                            />
                        )}
                    </Space.Compact>
                }
            >
                <Descriptions size="small">
                    <Descriptions.Item>
                        <Link href={companyRegistryLink} target="_blank" rel="noopener noreferrer">
                            Go to company registry <BlockOutlined />
                        </Link>
                    </Descriptions.Item>
                </Descriptions>
                <DetailsContainer>
                    <Descriptions column={1} size="small">
                        <Descriptions.Item label="Reg. no.">
                            <RegNumberRowContent>
                                {company.registryId}
                                {company?.address?.country && (
                                    <RegistryIdSearch
                                        country={company.address.country}
                                        onUpdateRegistryId={onUpdateRegistryId}
                                    />
                                )}
                            </RegNumberRowContent>
                        </Descriptions.Item>
                        <Descriptions.Item label="Trading name">
                            <RegNumberRowContent>
                                <div data-testid="trading-name">
                                    {tradingName !== 'Same as Legal Name' ? (
                                        tradingName ? (
                                            <Text copyable>{tradingName}</Text>
                                        ) : (
                                            <Text>-</Text>
                                        )
                                    ) : (
                                        <Text>{tradingName}</Text>
                                    )}
                                </div>
                            </RegNumberRowContent>
                        </Descriptions.Item>
                        <Descriptions.Item label="Domain">
                            {company.domainName ? (
                                <a
                                    href={`https://${company.domainName}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {company.domainName}
                                </a>
                            ) : (
                                <Text>No domain found</Text>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="CACP">
                            {company.cacp ? <Text copyable>{company.cacp}</Text> : <Text>-</Text>}
                        </Descriptions.Item>
                        <Descriptions.Item label="Styx ID">
                            <Text copyable>{company.id}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Deimos ID">
                            <Text copyable>{company.deimosId}</Text>
                        </Descriptions.Item>
                    </Descriptions>
                    {company.offboardingStatus?.initiatedAt && (
                        <Descriptions column={1} size="small">
                            <Descriptions.Item label="Offboarding category">
                                {showCategory(company.offboardingStatus?.category)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Offboarding reasons">
                                <Space direction="vertical" size="small">
                                    {showReasons(company.offboardingStatus?.reasons)}
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label="Offboarding notes">
                                {showNotes(company.offboardingStatus?.notes)}
                            </Descriptions.Item>
                        </Descriptions>
                    )}
                    <Descriptions column={1}>
                        <Descriptions.Item label="Trading address">
                            <div data-testid="trading-address">
                                {tradingAddress ? (
                                    typeof tradingAddress === 'string' ? (
                                        <Text>{tradingAddress}</Text>
                                    ) : (
                                        <CompanyAddress address={tradingAddress} />
                                    )
                                ) : (
                                    <Text>-</Text>
                                )}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Number of Employees">
                            <div data-testid="employee-number">
                                {getCompanySizeLabel(companyV2?.companySize)}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Incorporation Date">
                            <div data-testid="incorporation-date">
                                {companyV2?.incorporationDate ?? 'N/A'}
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Incorporation Type">
                            <div data-testid="incorporation-type">
                                {companyV2?.incorporationType ?? 'N/A'}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions column={1} size="small">
                        <Descriptions.Item label="KYC status">
                            <GroupedItems>
                                <UpdateVelocityForm
                                    onSubmit={handleVelocityChange}
                                    currentVelocity={currentKycStatus}
                                />
                                {fddMetadata && (
                                    <Tag color={'blue'} data-testid={'fdd-metadata-tag'}>
                                        {fddMetadata}
                                    </Tag>
                                )}
                            </GroupedItems>
                        </Descriptions.Item>
                        <Descriptions.Item label="Business offering">
                            {businessOffering.length === 0
                                ? '-'
                                : businessOffering.map((bo) => bo.name).join(', ')}
                        </Descriptions.Item>
                        {company.riskAssessment?.score && (
                            <Descriptions.Item label="Risk Score">
                                <Space>
                                    <Popover content={scorePopoverContent()}>
                                        <Text>{company.riskAssessment.score}</Text>
                                    </Popover>
                                    {hasManuallyApprovedPep && <Tag color="warning">Has PEP</Tag>}
                                </Space>
                            </Descriptions.Item>
                        )}
                        <Descriptions.Item>
                            <AdverseMediaScreening
                                data-testid="adverse-media-checks"
                                companyId={company.id}
                                companyName={company.legalName}
                                companyTradingName={
                                    companyV2?.tradingName?.trim() ? companyV2.tradingName : null
                                }
                            />
                        </Descriptions.Item>
                        <Descriptions.Item>
                            <SanctionsScreening
                                data-testid="sanctions-checks"
                                companyId={company.id}
                                companyName={company.legalName}
                                companyTradingName={
                                    companyV2?.tradingName?.trim() ? companyV2.tradingName : null
                                }
                            />
                        </Descriptions.Item>
                    </Descriptions>
                </DetailsContainer>
                {!!naceCodes?.length && (
                    <Descriptions>
                        <Descriptions.Item label="NACE">
                            <NaceCodeContainer naceCodes={naceCodes} />
                        </Descriptions.Item>
                    </Descriptions>
                )}
                {!!countriesOfManufacturing?.length && (
                    <Descriptions>
                        <Descriptions.Item label="Countries of Manufacturing">
                            <CountriesOfManufacturingContainer
                                countriesOfManufacturing={countriesOfManufacturing}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                )}
                {countryOfOperation && (
                    <Descriptions>
                        <Descriptions.Item label="Countries of Operation">
                            {countryOfOperation}
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </PageHeader>
            <PageContentLayout>{children}</PageContentLayout>
            <Modal
                title={company.offboardingStatus ? 'Edit offboarding' : 'Initiate offboarding'}
                open={offboardingModalActive}
                onCancel={() => setOffboardingModal(false)}
                footer={null}
                width={'auto'}
                centered
            >
                <OffboardingForm
                    offboardingStatus={company.offboardingStatus}
                    companyId={company?.deimosId}
                    closeModal={() => closeOffboardingModal()}
                    revalidateCompany={revalidateCompany}
                />
            </Modal>
        </>
    )
}

const CompanyContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const [modalActive, setModalActive] = useState(false)
    const navigate = useNavigate()
    const preferences = usePreferences()
    const { company, mutations, error, kycStatus, isManualSignup } = useStyxCompany()
    const { company: companyV2, mutations: mutationsV2 } = useStyxV2Company()
    const companyResponse = useGetCompany(company?.deimosId)
    const hasInitiateOffboarding = useHasPermissions(['initiate-offboarding'])
    const user = useUser()
    const analytics = useAnalytics()
    const { naceCodes, mutations: industryCodeMutations } = useCompanyIndustryCodes(
        company?.deimosId
    )
    const countriesOfManufacturing = useCountriesOfManufacturing(company?.deimosId)
    const { hasManuallyApprovedPep } = useManagement()
    const {
        data: companyBusinessOffering,
        insertCompanyBusinessOffering,
        deleteCompanyBusinessOffering,
    } = useCompanyBusinessOffering(company?.deimosId)
    const { data: businessOfferings } = useBusinessOfferings()

    const layout = preferences?.userPreferences.complianceView

    const goBack = () => {
        navigate(-1)
    }

    const onClosedArchiveClick = (closedCompany: ClosedCompany) => {
        navigate(`/compliance/closed-companies/${closedCompany.id}`)
    }

    const onRelatedCompanyClick = (relatedCompanyId: string) => {
        navigate(`/compliance/companies/${relatedCompanyId}`)
    }

    const onEditSubmit = async ({
        registryId,
        legalName,
        domainName,
        tradingName,
        addressLine1,
        addressLine2,
        locality,
        region,
        postalCode,
        country,
        tradingAddressLine1,
        tradingAddressLine2,
        tradingLocality,
        tradingRegion,
        tradingPostalCode,
        tradingCountry,
        industryCodes,
        companySize,
        incorporationDate,
        incorporationType,
        countriesOfOperation,
        companyBusinessOffering: selectedCompanyBusinessOffering,
    }: FormValues) => {
        try {
            const updateRequestV2 = {
                registryId,
                legalName,
                domainName,
                address: {
                    addressLine1,
                    addressLine2,
                    locality,
                    postalCode,
                    region,
                    country,
                },
                tradingName,
                tradingAddress: {
                    addressLine1: tradingAddressLine1,
                    addressLine2: tradingAddressLine2,
                    locality: tradingLocality,
                    postalCode: tradingPostalCode,
                    region: tradingRegion,
                    country: tradingCountry,
                },
                companySize,
                incorporationDate,
                incorporationType,
                countriesOfOperation,
            }

            const industryCodesToBeDeleted = difference(
                getCodeFromNace(naceCodes.filter(isDueDiligenceAgentInput)),
                industryCodes
            )

            const industryCodesToBeAdded = difference(industryCodes, getCodeFromNace(naceCodes))

            const businessOfferingsToBeDeleted = difference(
                companyBusinessOffering
                    ?.filter(isDueDiligenceAgentInput)
                    ?.map((item) => item.name) || [],
                selectedCompanyBusinessOffering
            )

            const businessOfferingsToBeAdded = difference(
                selectedCompanyBusinessOffering,
                companyBusinessOffering?.map((item) => item.name) || []
            )

            setModalActive(false)
            await Promise.all([
                mutationsV2.editCompany(updateRequestV2),
                industryCodeMutations.insertCompanyIndustryCodes(industryCodesToBeAdded),
                industryCodeMutations.deleteCompanyIndustryCodes(industryCodesToBeDeleted),
                insertCompanyBusinessOffering(businessOfferingsToBeAdded),
                deleteCompanyBusinessOffering(businessOfferingsToBeDeleted),
            ])

            await mutations.revalidate()
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const onUpdateRegistryId = async (registryId: string) => {
        try {
            await mutations.editCompany({
                registryId,
            })
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const handleVelocityChange = async ({ velocity }: { velocity: KycStatus }) => {
        try {
            await mutations.updateVelocity(velocity)
            message.success(`Updated velocity to ${velocity}`)
            if (velocity === KycStatus.FDD && !company?.fdd) {
                analytics?.track('Company velocity changed to FDD', {
                    companyId: company?.deimosId,
                    userId: user.id,
                })
            }
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const updateDeimosFromStyx = async () => {
        try {
            message.info('Started sync. This takes a while and will happen in the background.')
            await updateDeimosCompanyFromStyx(company?.deimosId!)
            message.success('Successfully synced Deimos with Styx')
        } catch (e) {
            message.error(`Failed to sync: ${(e as Error).message}`)
        }
    }

    if (error) {
        return <CompanyErrorResult error={error} goBack={goBack} />
    }

    if (!company || !companyV2) {
        return <Spinner />
    }

    // Values that should not be editable will be handled as disabled
    const immutableIndustryCodes = getCodeFromNace(
        naceCodes.filter(negate(isDueDiligenceAgentInput))
    )
    const codes = getCodeFromNace(naceCodes)

    const immutableBusinessOfferings = companyBusinessOffering
        ?.filter(negate(isDueDiligenceAgentInput))
        .map((bo) => bo.name)
    const companyBusinessOfferingNames = companyBusinessOffering?.map((bo) => bo.name)

    return (
        <CompanyContextProvider>
            <Company
                company={company}
                companyV2={companyV2}
                organizationId={companyResponse?.data?.organizationId}
                currentKycStatus={kycStatus}
                isManualSignup={isManualSignup}
                goBack={goBack}
                onEdit={() => setModalActive(true)}
                onUpdateRegistryId={onUpdateRegistryId}
                updateDeimosFromStyx={updateDeimosFromStyx}
                handleVelocityChange={handleVelocityChange}
                revalidateCompany={mutations.revalidate}
                onClosedArchiveClick={onClosedArchiveClick}
                onRelatedCompanyClick={onRelatedCompanyClick}
                naceCodes={naceCodes}
                countriesOfManufacturing={countriesOfManufacturing}
                hasManuallyApprovedPep={hasManuallyApprovedPep}
                businessOffering={companyBusinessOffering || []}
            >
                {layout === 'tabs' ? (
                    <CompanyTabs
                        companyId={company.deimosId}
                        companyCountry={company?.address?.country}
                        onboardingSource={company.onboardedVia}
                    />
                ) : (
                    <CompanyCollapse
                        companyId={company.deimosId}
                        companyCountry={company?.address?.country}
                        onboardingSource={company.onboardedVia}
                    />
                )}
            </Company>
            <Modal
                title="Edit company information"
                open={!!modalActive}
                onCancel={() => setModalActive(false)}
                footer={null}
                width={900}
                centered
            >
                <EditDetailsForm
                    company={company}
                    onSubmit={onEditSubmit}
                    companyV2={companyV2}
                    industryCodes={codes}
                    immutableIndustryCodes={immutableIndustryCodes}
                    immutableBusinessOfferings={immutableBusinessOfferings || []}
                    businessOfferings={businessOfferings || []}
                    companyBusinessOffering={companyBusinessOfferingNames || []}
                >
                    <div>
                        {!hasInitiateOffboarding && (
                            <DeactivateCompanyButton
                                companyId={company.deimosId}
                                onSuccess={() => goBack()}
                            />
                        )}
                    </div>
                </EditDetailsForm>
            </Modal>
        </CompanyContextProvider>
    )
}

const GroupedItems = styled('div')`
    display: flex;
    align-items: center;
    vertical-align: middle;
    gap: ${spacing.space4};
`

export default CompanyContainer
