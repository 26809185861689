import styled from 'styled-components'

export const Container = styled.div`
    .ant-table-footer {
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
    }
`

export const UpdateMessage = styled.div`
    margin-top: 3em;
    margin-bottom: 2em;

    & p {
        margin-bottom: 1em;
    }
`
