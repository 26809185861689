import { useState } from 'react'
import { Button, Modal, Table } from 'antd'
import type { ColumnFilterItem, ColumnsType } from 'antd/lib/table/interface'

import UserPermissions from 'components/permissions/user-permissions'
import type { User } from 'types/user'
import type { UsersMutations } from 'services/auth/users'
import { ActionsContainer, ContentContainer } from 'components/layout-containers'
import { userPermissions, UserPermission } from 'types/user-permissions'
import { getUserName } from 'utils/user'

interface Props {
    grantReclaimVatPermission: UsersMutations['grantReclaimVatPermission']
    revokeReclaimVatPermission: UsersMutations['revokeReclaimVatPermission']
    setUsersQuery: (query: string) => void
    users?: User[]
}

const permissionFilters: ColumnFilterItem[] = userPermissions.map((permission) => ({
    value: permission,
    text: permission,
}))

const Users = ({ grantReclaimVatPermission, revokeReclaimVatPermission, users }: Props) => {
    const [activeUser, setActiveUser] = useState<User | null>(null)

    const addPermissionToActiveUser = (nextPermission: string) => {
        setActiveUser((prevState) => {
            if (prevState === null) {
                return prevState
            } else {
                return {
                    ...prevState,
                    permissions: prevState.permissions.concat(nextPermission),
                }
            }
        })
    }

    const removePermissionFromActiveUser = (permissionToRemove: string) => {
        setActiveUser((prevState) => {
            if (prevState === null) {
                return prevState
            } else {
                return {
                    ...prevState,
                    permissions: prevState.permissions.filter(
                        (permission) => permission !== permissionToRemove
                    ),
                }
            }
        })
    }

    const onPermissionChange = async (
        userId: string,
        permission: UserPermission,
        checked: boolean
    ) => {
        if (checked) {
            addPermissionToActiveUser(permission)
            await grantReclaimVatPermission(userId, permission)
        } else {
            removePermissionFromActiveUser(permission)
            await revokeReclaimVatPermission(userId, permission)
        }
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Name',
            render: (_: string, user: User) => getUserName(user),
            sorter: (a: User, b: User) => getUserName(a).localeCompare(getUserName(b)),
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'User ID',
            dataIndex: 'id',
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            defaultSortOrder: 'descend',
            filters: permissionFilters,
            sorter: (a, b) => a.permissions.length - b.permissions.length,
            onFilter: (value, record) => record.permissions.includes(String(value)),
            render: (_, record) => (
                <ActionsContainer>
                    <Button type="link" onClick={() => setActiveUser(record)}>
                        View permissions ({record.permissions.length})
                    </Button>
                </ActionsContainer>
            ),
        },
    ]

    return (
        <ContentContainer>
            <Table dataSource={users} columns={columns} rowKey="id" />
            <Modal
                title="View permissions"
                centered
                width={800}
                footer={null}
                open={activeUser !== null}
                onCancel={() => setActiveUser(null)}
            >
                {activeUser && (
                    <UserPermissions
                        onPermissionChange={onPermissionChange}
                        permissions={activeUser.permissions}
                        user={activeUser}
                    />
                )}
            </Modal>
        </ContentContainer>
    )
}

export default Users
