import { Button, Select, Space } from 'antd'
import styled from 'styled-components'

import type { PaginationData } from '@pleo-io/deimos'

interface CursorPaginationControlsProps {
    pagination: PaginationData<string>
    isFetching: boolean
    limit?: number
    onChangeLimit: (newLimit: number) => void
    onFirstPage: () => void
    onPrevPage: () => void
    onNextPage: () => void
}
const CursorPaginationControls: React.FC<
    React.PropsWithChildren<CursorPaginationControlsProps>
> = ({ onFirstPage, onPrevPage, onNextPage, pagination, isFetching, limit, onChangeLimit }) => {
    return (
        <Wrapper>
            <Space>
                <Button
                    onClick={onFirstPage}
                    type="dashed"
                    disabled={!pagination.hasPreviousPage || isFetching}
                >
                    ← Back to first page
                </Button>
                <Button onClick={onPrevPage} disabled={!pagination.hasPreviousPage || isFetching}>
                    Previous
                </Button>
                <Button onClick={onNextPage} disabled={!pagination.hasNextPage || isFetching}>
                    Next
                </Button>
                <Select value={limit} onChange={onChangeLimit}>
                    <Select.Option value={10}>10 per page</Select.Option>
                    <Select.Option value={30}>30 per page</Select.Option>
                    <Select.Option value={50}>50 per page</Select.Option>
                    <Select.Option value={100}>100 per page</Select.Option>
                </Select>
            </Space>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default CursorPaginationControls
