import React, { FC, useState } from 'react'
import { Select, Space, Typography } from 'antd'

import type { Company } from 'types/deimos-company'
import * as s from 'pages/customer-success/card-orders/filters/filters.styles'
import { useGetCompanies } from 'services/deimos/companies'

const { Option } = Select
const { Text } = Typography

const FilterByCompany: FC<
    React.PropsWithChildren<{
        onCompanyChange: (companyId: string) => void
        allowClear?: boolean
    }>
> = ({ onCompanyChange, allowClear = true }) => {
    const [query, setQuery] = useState('')
    const { data: searchedCompanies } = useGetCompanies(query)
    const companies = searchedCompanies || []

    const handleSearch = (value: string) => setQuery(value)

    return (
        <Space>
            <Text>Filter by company:</Text>
            <s.Select
                id="search"
                allowClear={allowClear}
                placeholder="Search for a company"
                filterOption={false}
                onSearch={handleSearch}
                showSearch
                onChange={onCompanyChange}
                showArrow={false}
            >
                {companies.map((company: Company) => (
                    <Option key={company.id} value={company.id}>
                        {company.name}
                    </Option>
                ))}
            </s.Select>
        </Space>
    )
}

export default FilterByCompany
