import { Button } from 'antd'
import { Formik } from 'formik'
import { Input, Form, FormItem, InputNumber } from 'formik-antd'
import type * as Yup from 'yup'
import dayjs from 'packages/dayjs'

import SupportedLanguageSelect from 'components/supported-language-select'
import SupportedCountrySelect from 'components/supported-countries-select'

import { supportedCountries, SupportedCountry } from 'types/countries'
import { SupportedLanguage } from 'types/supported-language'
import { isPast } from 'utils/dayjs'
import DatePicker from 'packages/form/date-picker'
import ExpiresAtTooltip from '../expires-at-tooltip'
import { FormItemLong, FormLayout } from '../form.styles'
import { validationSchema } from './validation'
import { MAX_EMPLOYEES, MAX_EXPENSES, MAX_WALLET_LOAD } from './constants'

export type FormValuesExtended = Yup.InferType<typeof validationSchema> & {
    country: SupportedCountry
    language: SupportedLanguage
}

interface Props {
    onSubmit: (values: FormValuesExtended) => void
}

const GenerateExtendedDemoForm = ({ onSubmit }: Props) => {
    const twoWeekFromNow = dayjs().add(2, 'week').toISOString()

    return (
        <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
                companyName: '',
                country: supportedCountries[0],
                numberOfEmployees: 5,
                walletBalance: 1000,
                numberOfExpenses: 5,
                language: SupportedLanguage.EN,
                expiresAt: twoWeekFromNow,
            }}
        >
            <Form layout="vertical">
                <FormLayout>
                    <FormItemLong name="companyName" label="Company name" hasFeedback>
                        <Input name="companyName" placeholder="Company name" />
                    </FormItemLong>
                    <FormItem name="country" label="Country">
                        <SupportedCountrySelect name="country" placeholder="Country" />
                    </FormItem>
                    <FormItem name="numberOfEmployees" label="Number of employees" hasFeedback>
                        <InputNumber
                            name="numberOfEmployees"
                            min={0}
                            max={MAX_EMPLOYEES}
                            precision={0}
                        />
                    </FormItem>
                    <FormItem name="walletBalance" label="Wallet balance" hasFeedback>
                        <InputNumber
                            name="walletBalance"
                            min={0}
                            max={MAX_WALLET_LOAD}
                            step="0.01"
                            precision={2}
                        />
                    </FormItem>
                    <FormItem name="numberOfExpenses" label="Number of expenses" hasFeedback>
                        <InputNumber
                            name="numberOfExpenses"
                            min={0}
                            max={MAX_EXPENSES}
                            precision={0}
                        />
                    </FormItem>
                    <FormItem name="language" label="Language">
                        <SupportedLanguageSelect name="language" />
                    </FormItem>
                    <FormItem name="expiresAt" label="Expires at">
                        <DatePicker name="expiresAt" disabledDate={isPast} allowClear />
                    </FormItem>
                </FormLayout>
                <ExpiresAtTooltip>
                    <Button type="primary" htmlType="submit">
                        Publish
                    </Button>
                </ExpiresAtTooltip>
            </Form>
        </Formik>
    )
}

export default GenerateExtendedDemoForm
