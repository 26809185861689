import { List, Space, Tooltip } from 'antd'
import { useFormikContext } from 'formik'
import { Checkbox, Form } from 'formik-antd'
import type { ExpenseProfile, FormValuesProfile } from '../../types'
import ListItem from './list-item'
import { QuestionCircleOutlined } from '@ant-design/icons'

const renderListItem = ({ name, expenseType, merchantProfiles }: ExpenseProfile, index: number) => (
    <ListItem
        key={name}
        name={name}
        expenseType={expenseType}
        merchantProfiles={merchantProfiles}
        index={index}
    />
)

const ExpensesStep = () => {
    const { values } = useFormikContext<FormValuesProfile>()
    const { expenses } = values

    return (
        <Space direction="vertical">
            <List dataSource={expenses} grid={{ column: 1 }} renderItem={renderListItem} />
            <Form.Item name="additionalExpenses">
                <Checkbox name="additionalExpenses">Advanced expense types</Checkbox>
                <Tooltip title="Generates all types of expenses, for the purposes of testing accounting integrations">
                    <QuestionCircleOutlined />
                </Tooltip>
            </Form.Item>
        </Space>
    )
}

export default ExpensesStep
