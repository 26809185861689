function setItem(key: string, value: string): void {
    window.sessionStorage.setItem(key, value)
}

function getItem(key: string): string | null {
    return window.sessionStorage.getItem(key)
}

function removeItem(key: string): void {
    window.sessionStorage.removeItem(key)
}

function clear(): void {
    window.sessionStorage.clear()
}

export const sessionStorage = { setItem, getItem, removeItem, clear }
