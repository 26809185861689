import { Button } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem, Input } from 'formik-antd'
import type { FC } from 'react'
import styled from 'styled-components'
import { FormValues, validationSchema } from './validation-schema'

interface Props {
    onSubmit: (values: FormValues) => void
}

const TransactionLinkForm: FC<React.PropsWithChildren<Props>> = ({ onSubmit }) => {
    return (
        <Formik
            validationSchema={validationSchema}
            validateOnMount
            initialValues={{
                bankTransactionId: '',
            }}
            onSubmit={onSubmit}
        >
            {({ isValid }) => {
                return (
                    <Form>
                        <FormItem
                            name="bank-transaction-id"
                            colon={true}
                            label="Bank Transaction ID"
                            hasFeedback
                        >
                            <Input
                                data-testid="bank-transaction-id"
                                name="bankTransactionId"
                                placeholder="Bank Transaction ID"
                            />
                        </FormItem>
                        <ButtonContainer>
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={!isValid}
                                data-testid="submit"
                            >
                                Link
                            </Button>
                        </ButtonContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default TransactionLinkForm
