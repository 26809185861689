import { useState } from 'react'
import type { FC } from 'react'
import { Select, Space } from 'antd'
import OverviewView from './overview/overview-view'
import WalletSettingsView from './wallet-settings-view'
import InitiateDirectDebitView from './initiate-direct-debit-view'

interface Props {
    companyId: string
}

const CashFlow: FC<Props> = ({ companyId }) => {
    const [view, setView] = useState<'overview' | 'wallet-settings' | 'initiatie-direct-debit'>(
        'overview'
    )

    const views = [
        { value: 'overview', label: 'Overview', component: <OverviewView companyId={companyId} /> },
        {
            value: 'wallet-settings',
            label: 'Wallet Settings',
            component: <WalletSettingsView companyId={companyId} />,
        },
        {
            value: 'initiate-direct-debit',
            label: 'Initiate Direct Debit',
            component: <InitiateDirectDebitView companyId={companyId} />,
        },
    ]

    return (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                <Select
                    style={{ width: 200 }}
                    defaultValue={view}
                    onChange={setView}
                    options={views}
                    size="large"
                />

                {views.find(({ value }) => value == view)?.component}
            </Space>
        </>
    )
}

export default CashFlow
