import type { FC } from 'react'
import { message } from 'antd'
import { Formik, FormikHelpers } from 'formik'
import { Form, Select, InputNumber, SubmitButton } from 'formik-antd'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

import type { Entity, Node } from 'types/styx'
import { getEntityName } from 'pages/compliance/shared/utils'
import KycPopover from 'pages/compliance/company/ownership/edit-ownership/kyc-popover'

import { validationSchema, FormValues, mapValuesToRequest } from './validation-schema'
import type { OwnershipMutations } from 'services/deimos/styx-company/ownership'

const { Option, OptGroup } = Select

interface Props {
    disabled: boolean
    companies: Node[]
    assigned: Entity[]
    unassigned: Entity[]
    onAddShareholder: OwnershipMutations['addShareholder']
}

const AddShareholderForm: FC<React.PropsWithChildren<Props>> = ({
    companies,
    unassigned,
    assigned,
    disabled,
    onAddShareholder,
}) => {
    const onSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        try {
            const allEntities = [...assigned, ...unassigned, ...companies]
            await onAddShareholder(mapValuesToRequest(values, allEntities))
            resetForm()
        } catch (e) {
            message.error(`Failed to add shareholder: ${(e as Error).message}`)
        }
    }

    const initialValues: FormValues = {
        selectedEntity: '',
        sharePercentage: null,
        votingRights: null,
    }

    return (
        <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            <Form layout="vertical">
                <Form.Item name="selectedEntity" label="Add shareholder">
                    <Select
                        showSearch
                        disabled={disabled}
                        name="selectedEntity"
                        placeholder="Select entity to add"
                        optionFilterProp="label"
                    >
                        <OptGroup label="Unassigned Entities">
                            {unassigned.map((entity) => (
                                <Option
                                    label={getEntityName(entity)}
                                    key={entity.id}
                                    value={entity.id}
                                >
                                    <KycPopover entity={entity}>{getEntityName(entity)}</KycPopover>
                                </Option>
                            ))}
                        </OptGroup>
                        <OptGroup label="Assigned People">
                            {assigned.map((entity) => (
                                <Option
                                    label={getEntityName(entity)}
                                    key={entity.id}
                                    value={entity.id}
                                >
                                    <KycPopover entity={entity}>{getEntityName(entity)}</KycPopover>
                                </Option>
                            ))}
                        </OptGroup>
                        <OptGroup label="Assigned Companies">
                            {companies.map((node) => (
                                <Option label={node.name} key={node.id} value={node.id}>
                                    {node.name}
                                </Option>
                            ))}
                        </OptGroup>
                    </Select>
                </Form.Item>
                <InputNumberContainer>
                    <Form.Item name="sharePercentage">
                        <InputNumber
                            disabled={disabled}
                            name="sharePercentage"
                            placeholder="Shares"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item name="votingRights">
                        <InputNumber
                            disabled={disabled}
                            name="votingRights"
                            placeholder="Voting rights"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </InputNumberContainer>
                <SubmitButton data-testid="submit">Add</SubmitButton>
            </Form>
        </Formik>
    )
}

const InputNumberContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${spacing.space24};
`

export default AddShareholderForm
