import type { FC } from 'react'
import { Formik, useFormikContext } from 'formik'
import { Select, FormItem, Checkbox, Form, SubmitButton } from 'formik-antd'

import { Input, TextArea } from 'components/input/input'
import { documentCategories } from 'types/styx'
import { validationSchema, FormValues } from './validation-schema'

const { Option } = Select

const VisibilityCheckbox = () => {
    const { values } = useFormikContext<FormValues>()
    const visibility = values.hidden ? 'hidden from' : 'visible to'
    return <Checkbox name="hidden">{`Document is ${visibility} users`}</Checkbox>
}

interface Props {
    onSubmit: (values: FormValues) => void
    initialValues?: Partial<FormValues>
}

const DocumentForm: FC<React.PropsWithChildren<Props>> = ({ initialValues, onSubmit }) => {
    return (
        <Formik
            initialValues={{
                title: initialValues?.title ?? '',
                category: initialValues?.category ?? 'OTHER',
                description: initialValues?.description ?? '',
                hidden: initialValues?.hidden ?? true,
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form layout="vertical" style={{ width: '100%' }}>
                <Input name="title" label="Document Title" placeholder="title" testId="title" />
                <FormItem name="category" label="Category" colon={false}>
                    <Select name="category" placeholder="category" data-testid="category">
                        {documentCategories.map((category) => (
                            <Option value={category} key={category}>
                                {category}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
                <TextArea
                    name="description"
                    label="Description"
                    placeholder="Description"
                    rows={5}
                />
                <FormItem name="hidden" label="Hide document from users?" colon={false}>
                    <VisibilityCheckbox />
                </FormItem>
                <SubmitButton aria-label="submit">Save</SubmitButton>
            </Form>
        </Formik>
    )
}

export default DocumentForm
