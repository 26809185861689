import styled from 'styled-components'
import { spacing } from 'theme/tokens'

import OwnershipGraph from './ownership-structure/ownership-structure'
import EditOwnership from './edit-ownership/edit-ownership'

const Ownership = () => {
    return (
        <Container>
            <OwnershipGraph />
            <EditOwnership />
        </Container>
    )
}

const Container = styled.div`
    display: grid;
    row-gap: ${spacing.space24};
`

export default Ownership
