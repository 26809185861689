import type { Employee } from '../../types'
import { useState } from 'react'
import EditableEmployee from './editable-employee'
import ReadableEmployee from './readable-employee'

type ListItemProps = Employee

const ListItem = ({ name, count, limits, mileage, virtualCard }: ListItemProps, index: number) => {
    const [isEditing, setIsEditing] = useState(false)

    return isEditing ? (
        <EditableEmployee
            toggleEditing={setIsEditing}
            name={name}
            count={count}
            limits={limits}
            virtualCard={virtualCard}
            mileage={mileage}
            index={index}
        />
    ) : (
        <ReadableEmployee
            toggleEditing={setIsEditing}
            name={name}
            count={count}
            limits={limits}
            mileage={mileage}
            virtualCard={virtualCard}
        />
    )
}

export default ListItem
