import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Card, Descriptions, Popover, Result, Skeleton, Space, notification } from 'antd'
import dayjs from 'dayjs'
import { bff } from 'pages/compliance/bff'

import React from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { exhaustiveCheck } from 'utils/exhaustive-check'

// Countries that always require admin verification
const COUNTRIES_ALWAYS_REQUIRING_ADMIN_VERIFICATION = ['SE', 'GB']

// UNSET is used when the admin verification has not been requested
// before and Styx returns a 404 error
const adminVerificationRequestSupportedStatus = ['REQUESTED', 'CANCELLED', 'UNSET'] as const

type AdminVerificationRequestSupportedStatus =
    (typeof adminVerificationRequestSupportedStatus)[number]

type AdminVerificationSectionProps = {
    companyId: string
    companyCountry?: string
    readOnly?: boolean
    snapshot?: string
}

export const AdminVerificationRequestSection = ({
    companyId,
    companyCountry,
    readOnly = false,
    snapshot,
}: AdminVerificationSectionProps) => {
    const [isLoading, setIsLoading] = React.useState(false)

    const shouldFetchAdminVerificationRequest = companyCountry
        ? !COUNTRIES_ALWAYS_REQUIRING_ADMIN_VERIFICATION.includes(companyCountry)
        : false

    const { data: adminVerificationRequestData, error: adminVerificationRequestDataError } =
        bff.adminVerificationRequestSection.getAdminVerificationRequest.useQuery(
            {
                companyId,
                snapshot,
            },
            { enabled: shouldFetchAdminVerificationRequest }
        )
    const { mutateAsync: requestVerification } =
        bff.adminVerificationRequestSection.requestAdminVerification.useMutation()
    const { mutateAsync: cancelVerification } =
        bff.adminVerificationRequestSection.cancelAdminVerification.useMutation()

    const requestAdminVerification = async () => {
        setIsLoading(true)

        try {
            await requestVerification({ companyId })
        } catch (error) {
            notification.error({ message: 'Failed to request admin verification' })
        } finally {
            setIsLoading(false)
        }
    }

    const cancelAdminVerification = async () => {
        setIsLoading(true)

        try {
            await cancelVerification({ companyId })
        } catch (error) {
            notification.error({ message: 'Failed to cancel admin verification' })
        } finally {
            setIsLoading(false)
        }
    }

    if (!shouldFetchAdminVerificationRequest) {
        return null
    }

    if (adminVerificationRequestDataError) {
        return (
            <Result
                title="Could not fetch admin verification request status"
                subTitle={adminVerificationRequestDataError.message}
                status="error"
            />
        )
    }

    if (!adminVerificationRequestData) {
        return (
            <StyledCard>
                <Space direction="vertical">
                    <Skeleton.Input active size="small" />
                    <Skeleton.Button active size="small" shape="square" />
                </Space>
            </StyledCard>
        )
    }

    if (!isAdminVerificationRequestStatus(adminVerificationRequestData.status)) {
        return (
            <Result
                title="Admin verification request status is not supported"
                subTitle="Please contact team Heimdall"
                status="error"
            />
        )
    }

    const adminVerificationRequestContent = getAdminVerificationRequestContent(
        adminVerificationRequestData.status,
        adminVerificationRequestData.isAdminVerificationInRequiredKycInformation,
        adminVerificationRequestData.companyHasPartner
    )

    return (
        <StyledCard>
            <Descriptions column={1} size="small">
                <Descriptions.Item label="Admin verification request status">
                    <WithPopover helperText={adminVerificationRequestContent?.helperText}>
                        {adminVerificationRequestContent?.screenStatus}
                    </WithPopover>
                </Descriptions.Item>

                {!readOnly && adminVerificationRequestData.updatedAt && (
                    <Descriptions.Item label="Last updated on">
                        {dayjs(adminVerificationRequestData.updatedAt).format('llll')}
                    </Descriptions.Item>
                )}

                {!readOnly && adminVerificationRequestData.updatedBy && (
                    <Descriptions.Item label="Last updated by">
                        {adminVerificationRequestData.updatedBy}
                    </Descriptions.Item>
                )}
            </Descriptions>

            {!readOnly && (
                <Button
                    type="primary"
                    danger={adminVerificationRequestData.status === 'REQUESTED'}
                    loading={isLoading}
                    disabled={
                        (adminVerificationRequestData.isAdminVerificationInRequiredKycInformation &&
                            ['CANCELLED', 'UNSET'].includes(adminVerificationRequestData.status)) ||
                        adminVerificationRequestData.companyHasPartner
                    }
                    onClick={() => {
                        switch (adminVerificationRequestData.status) {
                            case 'REQUESTED':
                                cancelAdminVerification()
                                return

                            case 'CANCELLED':
                            case 'UNSET':
                                requestAdminVerification()
                                return

                            default:
                                return exhaustiveCheck(adminVerificationRequestData.status)
                        }
                    }}
                >
                    {adminVerificationRequestContent?.buttonText}
                </Button>
            )}
        </StyledCard>
    )
}

type WithPopoverProps = {
    helperText?: string
    children: React.ReactNode
}

const WithPopover = ({ helperText = '', children }: WithPopoverProps) => (
    <Popover content={helperText} trigger="click">
        <Space>
            {children}

            <QuestionCircleOutlined />
        </Space>
    </Popover>
)

const getAdminVerificationRequestContent = (
    status: AdminVerificationRequestSupportedStatus,
    isAdminVerificationInRequiredKycInformation: boolean,
    companyHasPartner: boolean
) => {
    if (companyHasPartner) {
        return {
            screenStatus: 'Not available',
            helperText: 'This company is linked to a partner',
            buttonText: 'Request admin verification',
        }
    }

    switch (status) {
        case 'REQUESTED':
            return {
                screenStatus: 'Requested',
                helperText: 'Admin verification has been manually requested for this company',
                buttonText: 'Cancel admin verification request',
            }
        case 'CANCELLED':
        case 'UNSET':
            if (isAdminVerificationInRequiredKycInformation) {
                return {
                    screenStatus: 'Requested',
                    helperText: 'Admin verification requested due to the company risk rating',
                    buttonText: 'Cancel admin verification request',
                }
            }

            return {
                screenStatus: 'Not requested',
                helperText: 'Admin verification is currently not requested for this company',
                buttonText: 'Request admin verification',
            }
        default:
            return exhaustiveCheck(status)
    }
}

const isAdminVerificationRequestStatus = (
    status: string
): status is AdminVerificationRequestSupportedStatus =>
    adminVerificationRequestSupportedStatus.some((s) => s === status)

const StyledCard = styled(Card)`
    margin-top: ${spacing.space16};
`
