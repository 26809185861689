import useSWR, { mutate } from 'swr'
import request, { fetcher } from './request'

import qs from 'qs'
import type {
    CompanyRevenue,
    Document,
    DocumentRequest,
    RevenueRange,
    StyxCompanyV2,
    StyxCompanyV2UpdateRequest,
    GetAllCompanyKycCheckResponse,
    Narrative,
    UpdateRiskCheck,
    KycCheckpoint,
    DocumentFileRequest,
} from 'types/styx'
import { useParams } from 'react-router-dom'
import { message } from 'antd'

export function useStyxV2Company() {
    const { id: deimosCompanyId } = useParams()
    const baseUrl = `rest/v2/styx-companies/${deimosCompanyId}`

    const response = useSWR<StyxCompanyV2>(deimosCompanyId ? baseUrl : null, fetcher, {
        shouldRetryOnError: false,
    })

    const company = response.data

    const editCompany = async (body: StyxCompanyV2UpdateRequest) => {
        if (!company) {
            return
        }

        await request().patch(`rest/v2/styx-companies/${company.styxId}`, {
            json: body,
        })

        response.mutate()
    }

    return {
        company,
        mutations: {
            editCompany,
        },
    }
}

export const useCompanyDocumentsWithDateSnapshot = (globalId: string, snapshot?: string) => {
    const snapshotQuery = qs.stringify({ snapshot })
    const response = useSWR<Document[]>(
        globalId
            ? `rest/v2/styx-companies/${globalId}/documents?includeFileCreationDate=true&${snapshotQuery}`
            : null,
        fetcher,
        { shouldRetryOnError: false, revalidateOnFocus: true }
    )
    const createDocumentFile = async (documentId: string, file: FormData) => {
        await createCompanyDocumentFile(globalId, documentId, file)
        response.mutate()
    }

    const deleteDocument = async (documentId: string) => {
        await deleteCompanyDocument(globalId, documentId)
        response.mutate()
    }

    const deleteDocumentFile = async (documentId: string, fileId: string) => {
        await deleteCompanyDocumentFile(globalId, documentId, fileId)
        response.mutate()
    }

    const editDocument = async (documentId: string, body: DocumentRequest) => {
        await updateCompanyDocument(globalId, documentId, body)
        response.mutate()
    }
    const createDocument = async (body: DocumentRequest, autoRetrieve = false) => {
        await createCompanyDocument(globalId, body, autoRetrieve)
        response.mutate()
    }
    const editDocumentFile = async (
        documentId: string,
        fileId: string,
        body: DocumentFileRequest
    ) => {
        await editCompanyDocumentFile(globalId, documentId, fileId, body)
        response.mutate()
    }

    const documents = response.data
    const isValidating = response.isValidating
    const error = response.error

    return {
        documents,
        isValidating,
        mutations: {
            createDocumentFile,
            deleteDocument,
            deleteDocumentFile,
            editDocument,
            createDocument,
            editDocumentFile,
        },
        error,
    }
}
function createCompanyDocumentFile(companyId: string, documentId: string, file: FormData) {
    return request().post(`rest/v1/companies/${companyId}/styx/documents/${documentId}/files`, {
        body: file,
    })
}
function createCompanyDocument(companyId: string, body: DocumentRequest, autoRetrieve = false) {
    return request().post(`rest/v1/companies/${companyId}/styx/documents`, {
        json: { ...body, isHidden: body.hidden },
        searchParams: { autoRetrieve: autoRetrieve.toString() },
    })
}

function deleteCompanyDocument(companyId: string, documentId: string) {
    return request().delete(`rest/v1/companies/${companyId}/styx/documents/${documentId}`)
}
function deleteCompanyDocumentFile(companyId: string, documentId: string, fileId: string) {
    return request().delete(
        `rest/v1/companies/${companyId}/styx/documents/${documentId}/files/${fileId}`
    )
}

function updateCompanyDocument(companyId: string, documentId: string, body: DocumentRequest) {
    return request().patch(`rest/v1/companies/${companyId}/styx/documents/${documentId}`, {
        json: { ...body, isHidden: body.hidden },
    })
}

export function useRevenueSources() {
    const url = `rest/v2/revenue/sources`
    return useSWR<string[]>(url, fetcher)
}

export function useRevenueRanges(country?: string) {
    const url = `rest/v2/revenue/ranges?country=${country}`
    return useSWR<RevenueRange[]>(url, fetcher)
}

export function useCompanyRevenueInfo(companyId: string) {
    const url = `rest/v2/revenue/companies/${companyId}`
    const response = useSWR<CompanyRevenue>(companyId ? url : null, fetcher)

    const editRevenueData = async (revenueData: CompanyRevenue) => {
        await request().put(`rest/v2/revenue/companies/${companyId}`, {
            json: revenueData,
        })

        response.mutate()
    }

    return {
        data: response.data,
        mutations: {
            editRevenueData,
        },
    }
}

export const useAdverseMediaChecks = (companyId: string) => {
    const url = `rest/v2/styx-companies/${companyId}/adverse-media`
    const response = useSWR<GetAllCompanyKycCheckResponse, Error>(companyId ? url : null, fetcher)

    const toggleCheckFalsePositive = async (checkId: string, falsePositive: boolean) => {
        try {
            await toggleKycFalsePositive(checkId, falsePositive)
            response.mutate()
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const createScreening = async () => {
        await createCompanyKycScreening(companyId)
        mutate(`rest/v2/styx-companies/${companyId}/sanctions`)
        return response.mutate()
    }

    return { ...response, mutations: { toggleCheckFalsePositive, createScreening } }
}

export const useSanctionsChecks = (companyId: string) => {
    const url = `rest/v2/styx-companies/${companyId}/sanctions`
    const response = useSWR<GetAllCompanyKycCheckResponse, Error>(companyId ? url : null, fetcher)

    const toggleCheckFalsePositive = async (checkId: string, falsePositive: boolean) => {
        try {
            await toggleKycFalsePositive(checkId, falsePositive)
            response.mutate()
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const createScreening = async () => {
        await createCompanyKycScreening(companyId)
        mutate(`rest/v2/styx-companies/${companyId}/adverse-media`)
        return response.mutate()
    }

    return { ...response, mutations: { toggleCheckFalsePositive, createScreening } }
}

const toggleKycFalsePositive = (checkId: string, falsePositive: boolean) =>
    request().patch(`rest/v2/kyc-checks/${checkId}/falsepositive`, {
        json: { falsePositive: String(falsePositive) },
    })

const createCompanyKycScreening = (companyId: string) =>
    request().post(`rest/v2/styx-companies/${companyId}/screenings`)

export const useCompanyNarrative = (globalId: string) => {
    const response = useSWR<Narrative[]>(
        globalId ? `rest/v2/styx-companies/${globalId}/narrative` : null,
        fetcher,
        { shouldRetryOnError: false, revalidateOnFocus: false }
    )
    const createNarrative = async (narrative: Narrative) => {
        await createCompanyNarrative(globalId, narrative)
        response.mutate()
    }

    const updateNarrative = async (narrative: Narrative) => {
        await updateCompanyNarrative(globalId, narrative)
        response.mutate()
    }

    const narratives = response.data
    const isValidating = response.isValidating
    const error = response.error

    return {
        narratives,
        isValidating,
        mutations: {
            createNarrative,
            updateNarrative,
        },
        error,
    }
}

function createCompanyNarrative(companyId: string, narrative: Narrative) {
    return request().post(`rest/v2/styx-companies/${companyId}/narrative`, {
        json: narrative,
    })
}

function updateCompanyNarrative(companyId: string, narrative: Narrative) {
    return request().patch(`rest/v2/styx-companies/${companyId}/narrative/${narrative.id}`, {
        json: narrative,
    })
}

function editCompanyDocumentFile(
    companyId: string,
    documentId: string,
    fileId: string,
    body: DocumentFileRequest
) {
    return request().patch(
        `rest/v2/styx-companies/${companyId}/documents/${documentId}/files/${fileId}`,
        {
            json: body,
        }
    )
}

export const sendKycInformationRequest = (companyId: string, recurring = false) =>
    request().post(`rest/v2/rfi/companies/${companyId}/send-request-kyc-information`, {
        searchParams: { isRecurring: String(recurring) },
    })

export const secondKycWarningEmail = (companyId: String) => {
    const url = `rest/v2/rfi/companies/${companyId}/send-request-kyc-information-warning`

    return request().post(url)
}

export const updateRiskAssessmentCheck = (
    companyId: string,
    checkId: string,
    body: UpdateRiskCheck
) => request().patch(`rest/v2/styx-companies/${companyId}/risk/checks/${checkId}`, { json: body })

export const useCurrentKycStatus = (globalId: string) => {
    const response = useSWR<KycCheckpoint>(
        globalId ? `rest/v2/styx-companies/${globalId}/kyc` : null,
        fetcher,
        { shouldRetryOnError: false, revalidateOnFocus: false }
    )

    const kyc = response.data
    const error = response.error

    return {
        kyc,
        error,
    }
}

export const useClosedCompanyDocumentsWithDateSnapshot = (
    companyId?: string,
    snapshot?: string
) => {
    const snapshotQuery = qs.stringify({ snapshot })
    const url = `rest/v2/styx-companies/${companyId}/closed-company/documents?includeFileCreationDate=true&${snapshotQuery}`

    return useSWR<Document[]>(companyId && snapshot ? url : null, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })
}
