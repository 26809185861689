import type { FC } from 'react'
import type { User } from 'types/user'
import dayjs from 'packages/dayjs'
import InfoElemVertical from 'components/info-elem/info-elem-vertical'

import styled from 'styled-components'
import { spacing } from 'theme/tokens'

interface Props {
    user: User
}

export const DeletedUser: FC<React.PropsWithChildren<Props>> = ({ user }) => {
    return (
        <InfoContainer>
            <InfoElemVertical label="User Id" value={user.id} copyable />
            <InfoElemVertical label="Email" value={user.email} copyable />
            <InfoElemVertical label="Created at" value={dayjs(user.createdAt).format('lll')} />
            <InfoElemVertical
                label="Deleted at"
                value={user.deletedAt ? dayjs(user.deletedAt).format('lll') : ''}
            />
        </InfoContainer>
    )
}

export const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${spacing.space32};
`

export default DeletedUser
