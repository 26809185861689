import { useState } from 'react'
import { PageOrder, SearchCasesStateItem } from 'bff/moons/generated/case-manager'
import { bff } from './bff-hooks'
import dayjs from 'dayjs'

export const oddListTabItems = [
    {
        key: 'new',
        tab: 'Todo',
    },
    {
        key: 'inProgress',
        tab: 'In Progress',
    },
    {
        key: 'waitingForCustomer',
        tab: 'Waiting for Customer',
    },
    {
        key: 'offboarding',
        tab: 'Offboarding',
    },
    {
        key: 'oddCompleted',
        tab: 'ODD Completed',
    },
]

export const DEFAULT_PAGE_SIZE = 10

export const tabNameToCasesStateMapper: { [key: string]: SearchCasesStateItem[] } = {
    new: [SearchCasesStateItem.NEW, SearchCasesStateItem.INFORMATION_RECEIVED],
    inProgress: [SearchCasesStateItem.IN_PROGRESS],
    waitingForCustomer: [SearchCasesStateItem.WAITING_FOR_CUSTOMER],
    offboarding: [SearchCasesStateItem.OFFBOARDING_INITIATED, SearchCasesStateItem.WALLET_BLOCKED],
    oddCompleted: [SearchCasesStateItem.ODD_COMPLETED],
}

export const mapStateToTab = (state: SearchCasesStateItem[] | undefined) => {
    if (!state) return 'new'

    const stateKey = Object.keys(tabNameToCasesStateMapper).find((key) =>
        tabNameToCasesStateMapper[key].includes(state[0])
    )

    return stateKey || 'new'
}

const getSorting = (state: string[] | undefined) => {
    if (state?.includes('INFORMATION_RECEIVED')) {
        return { sorting_keys: ['state'], sorting_order: [PageOrder.ASC] }
    }
    return {
        sorting_keys: undefined,
        sorting_order: undefined,
    }
}

export interface FilterParams {
    country?: string
    state?: SearchCasesStateItem[]
    dueDateFrom?: string
    dueDateTo?: string
    lastStateChangeAtFrom?: string
    lastStateChangeAtTo?: string
    assignedAtFrom?: string
    assignedAtTo?: string
    assigneeId?: string
    entityId?: string
    unassigned_only?: boolean
}

export const useFilters = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const countryUrlParam = urlSearchParams.get('country') || undefined
    const stateUrlParam = urlSearchParams.get('state') || tabNameToCasesStateMapper['new'].join(',')
    const dueDateFromUrlParam = urlSearchParams.get('dueDateFrom') || undefined
    const dueDateToUrlParam = urlSearchParams.get('dueDateTo') || undefined
    const lastStateChangeAtFromUrlParam = urlSearchParams.get('lastStateChangeAtFrom') || undefined
    const lastStateChangeAtToUrlParam = urlSearchParams.get('lastStateChangeAtTo') || undefined
    const assignedAtFromUrlParam = urlSearchParams.get('assignedAtFrom') || undefined
    const assignedAtToUrlParam = urlSearchParams.get('assignedAtTo') || undefined
    const assigneeIdUrlParam = urlSearchParams.get('assigneeId') || undefined
    const entityIdUrlParam = urlSearchParams.get('entityId') || undefined
    const unassignedOnlyUrlParam =
        urlSearchParams.get('unassigned_only') === 'true' ? true : undefined

    const [activeTab, setActiveTab] = useState(
        mapStateToTab((stateUrlParam?.split(',') as SearchCasesStateItem[]) || 'new')
    )

    const [searchParams, setSearchParams] = useState({
        metadata: countryUrlParam
            ? JSON.stringify({ companyCountryCode: countryUrlParam })
            : undefined,
        assigneeId: assigneeIdUrlParam,
        entityId: entityIdUrlParam,
        state: stateUrlParam?.split(',') as SearchCasesStateItem[],
        include_deleted: true,
        unassigned_only: unassignedOnlyUrlParam,
        offset: undefined,
        limit: DEFAULT_PAGE_SIZE,
        due_from: dueDateFromUrlParam
            ? dayjs.utc(dueDateFromUrlParam)?.startOf('D').toISOString()
            : undefined,
        due_to: dueDateToUrlParam
            ? dayjs.utc(dueDateToUrlParam)?.endOf('D').toISOString()
            : undefined,
        last_state_change_at_from: lastStateChangeAtFromUrlParam
            ? dayjs.utc(lastStateChangeAtFromUrlParam)?.startOf('D').toISOString()
            : undefined,
        last_state_change_at_to: lastStateChangeAtToUrlParam
            ? dayjs.utc(lastStateChangeAtToUrlParam)?.endOf('D').toISOString()
            : undefined,
        assigned_at_from: assignedAtFromUrlParam
            ? dayjs.utc(assignedAtFromUrlParam)?.startOf('D').toISOString()
            : undefined,
        assigned_at_to: assignedAtToUrlParam
            ? dayjs.utc(assignedAtToUrlParam)?.endOf('D').toISOString()
            : undefined,
        ...getSorting(stateUrlParam.split(',')),
    })

    const { data, isFetching } = bff.oddListPage.searchOddList.useQuery(
        {
            ...searchParams,
        },
        {
            refetchOnWindowFocus: false,
        }
    )

    const updateSearchParams = (params: Record<string, any>) =>
        setSearchParams((prev) => ({ ...prev, ...params }))

    const updateUrl = (params: FilterParams) => {
        const currentParams = {
            country: countryUrlParam,
            state: stateUrlParam?.split(',') as SearchCasesStateItem[],
            dueDateFrom: dueDateFromUrlParam,
            dueDateTo: dueDateToUrlParam,
            lastStateChangeAtFrom: lastStateChangeAtFromUrlParam,
            lastStateChangeAtTo: lastStateChangeAtToUrlParam,
            assignedAtFrom: assignedAtFromUrlParam,
            assignedAtTo: assignedAtToUrlParam,
            assigneeId: assigneeIdUrlParam,
            entityId: entityIdUrlParam,
            unassigned_only: unassignedOnlyUrlParam,
        }

        const newParams = {
            ...currentParams,
            ...params,
        }

        const newSearchParams = new URLSearchParams()

        if (newParams?.country) newSearchParams.set('country', newParams?.country)
        if (newParams?.state) {
            const newParamsState = newParams?.state as string[] | undefined
            newSearchParams.set(
                'state',
                newParamsState && newParamsState.length > 0 && Array.isArray(newParamsState)
                    ? newParamsState.join(',')
                    : ''
            )
        }
        if (newParams?.dueDateTo) newSearchParams.set('dueDateTo', newParams?.dueDateTo)
        if (newParams?.dueDateFrom) newSearchParams.set('dueDateFrom', newParams?.dueDateFrom)
        if (newParams?.lastStateChangeAtFrom)
            newSearchParams.set('lastStateChangeAtFrom', newParams?.lastStateChangeAtFrom)
        if (newParams?.lastStateChangeAtTo)
            newSearchParams.set('lastStateChangeAtTo', newParams?.lastStateChangeAtTo)
        if (newParams?.assignedAtFrom)
            newSearchParams.set('assignedAtFrom', newParams?.assignedAtFrom)
        if (newParams?.assignedAtTo) newSearchParams.set('assignedAtTo', newParams?.assignedAtTo)
        if (newParams?.assigneeId) newSearchParams.set('assigneeId', newParams?.assigneeId)
        if (newParams?.entityId) newSearchParams.set('entityId', newParams?.entityId)
        if (newParams?.unassigned_only)
            newSearchParams.set('unassigned_only', newParams?.unassigned_only.toString())
        window.history.pushState({}, '', `?${newSearchParams.toString()}`)
    }

    const clearParams = () => {
        const clearSearchParams = new URLSearchParams()

        clearSearchParams.delete('country')
        clearSearchParams.delete('dueDateFrom')
        clearSearchParams.delete('dueDateTo')
        clearSearchParams.delete('lastStateChangeAtFrom')
        clearSearchParams.delete('lastStateChangeAtTo')
        clearSearchParams.delete('assignedAtFrom')
        clearSearchParams.delete('assignedAtTo')
        clearSearchParams.delete('assigneeId')
        clearSearchParams.delete('entityId')
        clearSearchParams.delete('unassigned_only')

        clearSearchParams.set('state', tabNameToCasesStateMapper[activeTab].join(','))

        window.history.pushState({}, '', `?${clearSearchParams.toString()}`)

        updateSearchParams({
            metadata: undefined,
            state: tabNameToCasesStateMapper[activeTab],
            unassigned_only: undefined,
            assigneeId: undefined,
            entityId: undefined,
            offset: undefined,
            limit: DEFAULT_PAGE_SIZE,
            due_from: undefined,
            due_to: undefined,
            last_state_change_at_from: undefined,
            last_state_change_at_to: undefined,
            assigned_at_from: undefined,
            assigned_at_to: undefined,
        })
    }

    const updateActiveTab = (key: string) => {
        const statuses = tabNameToCasesStateMapper[key].map((status) => status)
        updateUrl({ state: statuses })
        updateSearchParams({ state: statuses, offset: undefined, limit: DEFAULT_PAGE_SIZE })
        setActiveTab(key)
    }

    return {
        filters: {
            country: countryUrlParam,
            state: stateUrlParam?.split(',') as SearchCasesStateItem[],
            dueDateTo: dueDateToUrlParam,
            dueDateFrom: dueDateFromUrlParam,
            lastStateChangeAtFrom: lastStateChangeAtFromUrlParam,
            lastStateChangeAtTo: lastStateChangeAtToUrlParam,
            assignedAtFrom: assignedAtFromUrlParam,
            assignedAtTo: assignedAtToUrlParam,
            assigneeId: assigneeIdUrlParam,
            entityId: entityIdUrlParam,
            unassigned_only: unassignedOnlyUrlParam,
        },
        data,
        isFetching,
        activeTab,
        updateSearchParams,
        updateUrl,
        clearParams,
        updateActiveTab,
    }
}
