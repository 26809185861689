import { useEffect } from 'react'
import { Card, Form, Space, DatePicker, Button, Input } from 'antd'
import * as uuid from 'uuid'
import type { Dayjs } from 'dayjs'
import type { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'
import { useMatch, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import dayjs from 'packages/dayjs'

type CompanyAndDateForm = {
    companyId: string
    dateRange: NoUndefinedRangeValueType<Dayjs>
}

const formatValuesToSearchParams = (dateRange: CompanyAndDateForm['dateRange']) =>
    new URLSearchParams({
        from: dayjs((dateRange || [])[0]).format('YYYY-MM-DD'),
        to: dayjs((dateRange || [])[1]).format('YYYY-MM-DD'),
    })

export const CompanyAndDateSelector = ({ defaultDates }: { defaultDates?: string[] }) => {
    const navigate = useNavigate()
    const { companyId: companyIdFromParams } = useParams()
    const match = useMatch('/customer-success/cashback/*')
    const [form] = Form.useForm<CompanyAndDateForm>()
    const [searchParams] = useSearchParams()

    const updateUrlWithFormValues = (
        companyId: string,
        params: URLSearchParams,
        pathnameBase?: string
    ) => navigate(`${pathnameBase}/${companyId}?${params.toString()}`)

    const getDatesFromSearchParams = () => [
        dayjs(searchParams.get('from') || (defaultDates && defaultDates[0])),
        dayjs(searchParams.get('to') || (defaultDates && defaultDates[1])),
    ]

    const onSubmit = (values: CompanyAndDateForm) => {
        updateUrlWithFormValues(
            values.companyId,
            formatValuesToSearchParams(values.dateRange),
            match?.pathnameBase
        )
    }

    // Updates the form from the URl
    useEffect(() => {
        form.setFieldValue('companyId', companyIdFromParams)
        form.setFieldValue('dateRange', getDatesFromSearchParams())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyIdFromParams])

    return (
        <Card>
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    dateRange: defaultDates && [dayjs(defaultDates[0]), dayjs(defaultDates[1])],
                }}
            >
                <Space.Compact>
                    <Form.Item
                        name="companyId"
                        label="Company ID"
                        rules={[
                            {
                                validator: async (_, value) => {
                                    if (value && !uuid.validate(value)) {
                                        throw new Error('Invalid format for Company ID')
                                    }
                                },
                            },
                        ]}
                    >
                        <Input allowClear style={{ width: '24rem' }} spellCheck={false} />
                    </Form.Item>
                    <Form.Item label="Date range" name="dateRange">
                        <DatePicker.RangePicker allowClear={false} />
                    </Form.Item>
                    <Form.Item style={{ alignSelf: 'flex-end' }}>
                        <Button htmlType="submit">Submit</Button>
                    </Form.Item>
                </Space.Compact>
            </Form>
        </Card>
    )
}
