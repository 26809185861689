import { Alert, Card, Divider } from 'antd'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectCompanyId } from 'store/modules/support/company/selectors'
import XeroBankfeed from './systems/xero-bankfeed'
import BankFeedErrors from './systems/all-systems-bankfeed'
import { bff } from '../bff'

export const Bankfeed: FC<React.PropsWithChildren<unknown>> = () => {
    const companyId = useSelector(selectCompanyId)
    const [error, setError] = useState<string | null>()
    const [success, setSuccess] = useState<string | null>()
    const [title, setTitle] = useState<string>('No bank feed')
    const { xero, lexoffice } =
        bff.bankfeed.getActiveAccountingSystem.useQuery({
            companyId,
        }).data ?? {}

    const getErrorContent = () => {
        if (!error) {
            return null
        }
        return (
            <>
                <Divider />
                <Alert message={error} type="error" showIcon />
            </>
        )
    }

    const getSuccessContent = () => {
        if (!success) {
            return null
        }
        return (
            <>
                <Divider />
                <Alert message={success} type="success" showIcon />
            </>
        )
    }

    const getNoBankfeedContent = () => {
        return <>{'Company does not have a bankfeed'}</>
    }

    const checkAndSetTitle = (t: string) => {
        if (title === t) {
            return
        }
        setTitle(t)
    }

    const clearAndSetSuccess = (s: string) => {
        setError(null)
        setSuccess(s)
    }

    const clearAndSetError = (e: string) => {
        setSuccess(null)
        setError(e)
    }

    const getContent = () => {
        switch (true) {
            case xero:
                return (
                    <XeroBankfeed
                        companyId={companyId}
                        setTitle={checkAndSetTitle}
                        setError={clearAndSetError}
                        setSuccess={clearAndSetSuccess}
                    />
                )
            case lexoffice:
                return (
                    <BankFeedErrors
                        companyId={companyId}
                        setTitle={checkAndSetTitle}
                        setError={clearAndSetError}
                        setSuccess={clearAndSetSuccess}
                        replayExpenseId={null}
                    />
                )
            default:
                return getNoBankfeedContent()
        }
    }

    return (
        <Card title={title}>
            {getContent()}
            {getErrorContent()}
            {getSuccessContent()}
        </Card>
    )
}

export default Bankfeed
