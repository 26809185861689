import { Spin } from 'antd'
import styled from 'styled-components'

const Spinner = ({ className }: { className?: string }) => (
    <SpinnerContainer data-testid="spinner" className={className}>
        <Spin size="large" />
    </SpinnerContainer>
)

const SpinnerContainer = styled.div`
    padding-top: 10vh;
    padding-bottom: 10vh;
    display: flex;
    justify-content: center;
    align-content: center;
`

export default Spinner
