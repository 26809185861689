import type { AfterResponseHook, Hooks } from 'ky'
import { refreshAccessToken } from './refresh-access-token'

const getServerErrorResponse: AfterResponseHook = async (_request, _options, response) => {
    const isJsonResponse = response.headers.get('content-type')?.includes('application/json')

    if (!isJsonResponse) {
        return
    }

    const res = await response.json()
    if (res.error || response.status === 400 || response.status === 500) {
        const message = res.meta?.message ?? res.message
        throw Error(message)
    }
}

export const hooks: Hooks = {
    afterResponse: [refreshAccessToken, getServerErrorResponse],
}
