const MINOR_CURRENCY_MULTIPLICATOR = 100

export function convertToMajorCurrency(minorCurrencyAmount: number): number {
    if (!Number.isInteger(minorCurrencyAmount)) {
        throw new Error('Value must be currency in minors')
    }
    return minorCurrencyAmount / MINOR_CURRENCY_MULTIPLICATOR
}

export function convertToMinorCurrency(amount: number): number {
    return amount * MINOR_CURRENCY_MULTIPLICATOR
}
