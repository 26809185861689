import { Navigate } from 'react-router'

import ProtectedRoute from 'router/protected-route'

import Page from 'pages/page-layout'
import { Route, useParams } from 'react-router-dom'
import Menu from './sidebar-menu/sidebar-menu'
import { deKebabify } from 'utils/strings'
import Header from 'components/header/header'
import { B4BCompanies } from 'pages/compliance/b4b/companies/screens/b4b-companies'

const DEFAULT_ROUTE = 'companies'

const PageHeader = () => {
    const { id: selectedSidebarItem } = useParams()
    const headerTitle = deKebabify(selectedSidebarItem)
    return <Header title={headerTitle}></Header>
}

const B4BPage = () => {
    return (
        <Page
            hideSidebarDropdown={true}
            sideBarLabel="B4B"
            sideBar={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id/*" element={<Menu />} />
                </Route>
            }
            header={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id" element={<PageHeader />} />
                </Route>
            }
        >
            <Route element={<ProtectedRoute />}>
                <Route path="*" element={<Navigate to={DEFAULT_ROUTE} />} />
                <Route path="companies/*" element={<B4BCompanies />} />
                {/* <Route path="closed-companies/*" element={<B4BClosedCompanies />} /> */}
            </Route>
        </Page>
    )
}

export default B4BPage
