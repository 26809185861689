import { Alert, Card, Form, Input, Space, Table, TableColumnProps, Typography } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import { getEmojiFlag } from 'countries-list'
import dayjs from 'dayjs'
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { creditBff } from '../bff'
import type { inferProcedureOutput } from '@trpc/server'
import type { CreditRouter } from '../index.bff'
import { useDebounce } from 'utils/use-debounce'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

const { Text } = Typography

type CompanyParams = inferProcedureOutput<CreditRouter['credit']['getCompanyLookup']>[number]

const companyColumns: TableColumnProps<CompanyParams>[] = [
    {
        title: 'Country',
        dataIndex: 'country',
        render: (country) => (
            <Space>
                <Text>{getEmojiFlag(country)}</Text>
                <Text>{country}</Text>
            </Space>
        ),
    },
    {
        title: 'Company name',
        dataIndex: 'companyName',
        render: (companyName) => <Text>{companyName}</Text>,
    },
    {
        title: 'Company ID',
        dataIndex: 'companyId',
        render: (companyId) => (
            <Text onClick={(e) => e.stopPropagation()} style={{ display: 'inline-block' }} copyable>
                {companyId}
            </Text>
        ),
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('lll'),
    },
]

export default function CompanyLookup() {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const [query, setQuery] = useState(searchParams.get('query') || '')
    const debouncedQuery = useDebounce(query, 500)

    const { data, isLoading, isError } = creditBff.getCompanyLookup.useQuery({
        query: debouncedQuery,
    })

    useEffect(() => {
        setSearchParams({ query: debouncedQuery })
    }, [debouncedQuery, searchParams, setSearchParams])

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
    }

    const handleRowClick = (companyId: string) => (e: MouseEvent) => {
        if (e.ctrlKey || e.metaKey) {
            window.open(`/customer-success/credit/${companyId}`)
        } else {
            navigate(`/customer-success/credit/${companyId}`)
        }
    }

    return (
        <PageContentLayout>
            <Card bodyStyle={{ padding: 0 }}>
                <Form
                    layout="vertical"
                    style={{ padding: '16px 16px 0' }}
                    initialValues={{ queryText: query }}
                >
                    <Form.Item name="queryText" label={<strong>Search by name or ID</strong>}>
                        <Input onChange={handleSearchChange} autoFocus />
                    </Form.Item>
                </Form>
                {isError ? (
                    <Alert message="There was an error fetching companies." type="error" />
                ) : (
                    <StyledTableContainer>
                        <Table
                            dataSource={data}
                            columns={companyColumns}
                            rowKey="companyId"
                            onRow={(company) => ({
                                onClick: handleRowClick(company.companyId),
                            })}
                            loading={isLoading}
                        />
                    </StyledTableContainer>
                )}
            </Card>
        </PageContentLayout>
    )
}

const StyledTableContainer = styled('div')`
    border-top: 1px solid #f0f0f0;

    .ant-pagination {
        margin-right: 16px;
    }

    & .ant-table-tbody tr {
        cursor: pointer;
    }
`
