export const categoriesOptions = new Map([
    ['da2d9f26-3b15-48d3-801d-e2eb3ef5279e', 'Entertainment'],
    ['8d16b5e2-a288-4255-8399-1934b7e6ae4e', 'Equipment & hardware'],
    ['605d305b-92ec-44d0-aba0-377dbd6fb391', 'Marketing & advertising'],
    ['280a095e-6660-440b-bd5a-42d3aeb16c84', 'Meals & drinks'],
    ['d14f80e0-a3d6-44cf-90af-442be7ad5336', 'Office expenses'],
    ['ace8f8cb-47f4-47c9-af4e-2be0d04b7b7c', 'Phone & internet'],
    ['228be17a-b706-4efa-b220-64b8416923aa', 'Software'],
    ['c7f7e733-0615-492b-b26d-7ab9a2be505f', 'Travel'],
    ['d3abfaf0-620f-45e6-bcd9-a6978ba077e3', 'Other'],
])
