import { memo, ReactNode } from 'react'

type RowProps = {
    children: ReactNode
    'data-row-key': string
}

const Row = ({ children }: RowProps) => {
    return <tr>{children}</tr>
}

export default memo(Row)
