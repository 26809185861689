import { bff } from './bff-hooks'
import { useState } from 'react'
import { Button, Checkbox, Col, DatePicker, Input, Row, Select, Space, Spin } from 'antd'
import { tabNameToCasesStateMapper, FilterParams } from 'pages/compliance/odd/utils'
import type { SearchCasesParams } from 'bff/moons/generated/case-manager'
import { snakeCaseToTitleCase } from 'utils/strings'
import { useCountriesList } from 'utils/countries'
import type { Alpha2Code } from 'types/countries'
import { countries } from 'countries-list'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker

type FilterBarProps = {
    activeTab: string
    updateUrl: (params: FilterParams) => void
    filters: FilterParams
    updateSearchParams: (params: SearchCasesParams) => void
    clearParams: () => void
}

export const FilterBar = ({
    activeTab,
    updateUrl,
    updateSearchParams,
    filters,
    clearParams,
}: FilterBarProps) => {
    const signupCountries = useCountriesList()
    const [kerberosEmailSearch, setKerberosEmailSearch] = useState<string | undefined>()
    const { data: kerberosUsers, isLoading: isLoadingKerberosUsers } =
        bff.filters.getKerberosUsers.useQuery({ admin: true, query: kerberosEmailSearch })
    const onlyOneStatus =
        tabNameToCasesStateMapper[activeTab] && tabNameToCasesStateMapper[activeTab].length === 1

    const getCountry = (alpha2code: Alpha2Code) => countries[alpha2code as Alpha2Code]

    return (
        <Col>
            <Row>
                <Space style={{ margin: '0.5rem', marginLeft: '0' }}>
                    <Select
                        placeholder="Country"
                        options={signupCountries?.map(({ countryCode }) => ({
                            value: countryCode,
                            label: `${getCountry(countryCode).emoji} ${getCountry(countryCode).name}`,
                        }))}
                        style={{ minWidth: 160 }}
                        onChange={(value) => {
                            updateUrl({ country: value })
                            updateSearchParams({
                                metadata: value
                                    ? JSON.stringify({ companyCountryCode: value })
                                    : undefined,
                            })
                        }}
                        allowClear
                        value={filters?.country}
                    />
                    <Input
                        placeholder="Company ID"
                        width={320}
                        onChange={(e) => {
                            updateUrl({ entityId: e.target.value })
                            updateSearchParams({ entityId: e.target.value })
                        }}
                        allowClear
                        value={filters?.entityId}
                    />
                    {!onlyOneStatus && (
                        <Select
                            placeholder="Status"
                            style={{ minWidth: 200 }}
                            options={tabNameToCasesStateMapper[activeTab].map((status) => ({
                                value: status,
                                label: snakeCaseToTitleCase(status),
                            }))}
                            onChange={(value) => {
                                if (value) {
                                    updateUrl({ state: [value] })
                                    updateSearchParams({ state: [value] })
                                } else {
                                    updateUrl({ state: tabNameToCasesStateMapper[activeTab] })
                                    updateSearchParams({
                                        state: tabNameToCasesStateMapper[activeTab],
                                    })
                                }
                            }}
                            allowClear
                            value={
                                filters?.state && filters?.state.length === 1
                                    ? filters?.state[0]
                                    : undefined
                            }
                        />
                    )}
                    <Select
                        showSearch
                        placeholder="Assignee"
                        style={{ minWidth: 160 }}
                        labelInValue
                        filterOption={false}
                        onSearch={(value) => setKerberosEmailSearch(value)}
                        notFoundContent={isLoadingKerberosUsers ? <Spin size="small" /> : null}
                        options={kerberosUsers?.map((user: any) => ({
                            value: user.id,
                            label: user.email,
                        }))}
                        onChange={(assignee) => {
                            updateUrl({ assigneeId: assignee?.value })
                            updateSearchParams({ assigneeId: assignee?.value })
                        }}
                        allowClear
                        value={filters?.assigneeId ? { value: filters?.assigneeId } : undefined}
                    />
                    <RangePicker
                        placeholder={['Due date from', 'Due date to']}
                        value={[
                            filters?.dueDateFrom ? dayjs(filters?.dueDateFrom) : undefined,
                            filters?.dueDateTo ? dayjs(filters?.dueDateTo) : undefined,
                        ]}
                        onChange={(values) => {
                            updateUrl({
                                dueDateFrom: values
                                    ? dayjs(values[0]).format('YYYY-MM-DD')
                                    : undefined,
                                dueDateTo: values
                                    ? dayjs(values[1]).format('YYYY-MM-DD')
                                    : undefined,
                            })
                            updateSearchParams({
                                due_from: values
                                    ? values[0]?.startOf('D').toISOString()
                                    : undefined,
                                due_to: values ? values[1]?.endOf('D').toISOString() : undefined,
                            })
                        }}
                    />
                </Space>
            </Row>
            <Row>
                <Space style={{ margin: '0.5rem', marginLeft: '0' }}>
                    <RangePicker
                        style={{ minWidth: 375 }}
                        placeholder={['Last state change from', 'Last state change to']}
                        value={[
                            filters?.lastStateChangeAtFrom
                                ? dayjs(filters?.lastStateChangeAtFrom)
                                : undefined,
                            filters?.lastStateChangeAtTo
                                ? dayjs(filters?.lastStateChangeAtTo)
                                : undefined,
                        ]}
                        onChange={(values) => {
                            updateUrl({
                                lastStateChangeAtFrom: values
                                    ? dayjs(values[0]).format('YYYY-MM-DD')
                                    : undefined,
                                lastStateChangeAtTo: values
                                    ? dayjs(values[1]).format('YYYY-MM-DD')
                                    : undefined,
                            })
                            updateSearchParams({
                                last_state_change_at_from: values
                                    ? values[0]?.startOf('D').toISOString()
                                    : undefined,
                                last_state_change_at_to: values
                                    ? values[1]?.endOf('D').toISOString()
                                    : undefined,
                            })
                        }}
                    />
                    <RangePicker
                        placeholder={['Assigned at from', 'Assigned at to']}
                        value={[
                            filters?.assignedAtFrom ? dayjs(filters?.assignedAtFrom) : undefined,
                            filters?.assignedAtTo ? dayjs(filters?.assignedAtTo) : undefined,
                        ]}
                        onChange={(values) => {
                            updateUrl({
                                assignedAtFrom: values
                                    ? dayjs(values[0]).format('YYYY-MM-DD')
                                    : undefined,
                                assignedAtTo: values
                                    ? dayjs(values[1]).format('YYYY-MM-DD')
                                    : undefined,
                            })
                            updateSearchParams({
                                assigned_at_from: values
                                    ? values[0]?.startOf('D').toISOString()
                                    : undefined,
                                assigned_at_to: values
                                    ? values[1]?.endOf('D').toISOString()
                                    : undefined,
                            })
                        }}
                    />
                    <Checkbox
                        onChange={(e) => {
                            updateUrl({ unassigned_only: e.target.checked })
                            updateSearchParams({ unassigned_only: e.target.checked })
                        }}
                        checked={
                            filters?.unassigned_only === undefined
                                ? false
                                : Boolean(filters?.unassigned_only)
                        }
                    >
                        Unassigned only
                    </Checkbox>
                    <Button onClick={() => clearParams()}>Clear filters</Button>
                </Space>
            </Row>
        </Col>
    )
}
