import { FC, useState } from 'react'
import styled from 'styled-components'
import { Document, Page, pdfjs } from 'react-pdf'
import apiRequest from 'services/deimos/request'
import { LoadingOutlined } from '@ant-design/icons'
import useSWR from 'swr'
import type { GetBillInvoiceOriginalFileResponse } from '@pleo-io/deimos'

const fetcher = async (url: string) => {
    const response = await apiRequest().get(url)
    return response.json()
}

// Setup worker script to offload PDF rendering from the main thread
// See more https://github.com/wojtekmaj/react-pdf#standard-browserify-and-others
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

export const InvoicePDFPreview: FC<React.PropsWithChildren<{ invoiceId: string }>> = ({
    invoiceId,
}) => {
    const result = useSWR<GetBillInvoiceOriginalFileResponse>(
        `rest/v1/bill-invoices/${invoiceId}/pdf`,
        fetcher,
        { revalidateOnFocus: false }
    )

    const [pageCount, setPageCount] = useState(0)
    const pageNumbers = Array.from(Array(pageCount), (_, index) => index + 1)

    return (
        <PDFPreviewWrapper>
            {result.data?.url ? (
                // @ts-ignore
                <Document
                    loading={() => <Loader />}
                    file={result.data?.url}
                    onLoadSuccess={({ numPages }) => setPageCount(numPages)}
                >
                    {pageNumbers.map((pageNumber) => (
                        // @ts-ignore
                        <Page key={`page_${pageNumber}`} pageNumber={pageNumber} />
                    ))}
                </Document>
            ) : (
                <Loader />
            )}
        </PDFPreviewWrapper>
    )
}

const Loader = styled(LoadingOutlined)`
    margin-top: 20vh;
`

const PDFPreviewWrapper = styled.div`
    height: 80vh;
    overflow-y: auto;
    text-align: center;
`
