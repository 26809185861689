import type { PaginationData, BillBankDetailsError } from '@pleo-io/deimos'

interface Timestamps {
    createdAt: string
    updatedAt: string | null
}

export enum InvoiceStatus {
    RECEIVED = 'RECEIVED',
    MISSING_INFORMATION = 'MISSING_INFORMATION',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    READY = 'READY',
    SCHEDULED = 'SCHEDULED',
    PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
    PAID = 'PAID',
    MARKED_AS_PAID = 'MARKED_AS_PAID',
    REJECTED_BY_USER = 'REJECTED_BY_USER',
    VOIDED_BY_USER = 'VOIDED_BY_USER',
    FAILED_INTERNAL_VALIDATION = 'FAILED_INTERNAL_VALIDATION',
    FAILED_PAYMENT = 'FAILED_PAYMENT',
}

export interface SupplierInvoice extends Timestamps {
    id: string
    fileExtension?: string
    companyId: string
    departmentId?: string
    employeeId?: string
    status: InvoiceStatus
    amount?: number
    currency?: string
    supplier?: Supplier
    mimeType: string
    fileHash: string
    displayFilename: string
    companyName?: string
    createdAt: string
    updatedAt: string
}

export enum SupplierStatus {
    DRAFT = 'DRAFT',
    INCORRECT_BANK_DETAILS = 'INCORRECT_BANK_DETAILS',
    INCORRECT_BANK_DETAILS_MANUAL = 'INCORRECT_BANK_DETAILS_MANUAL',
    UNVERIFIED = 'UNVERIFIED',
    REQUIRES_VERIFICATION = 'REQUIRES_VERIFICATION',
    VERIFIED = 'VERIFIED',
    BLOCKED = 'BLOCKED',
    DEPRECATED = 'DEPRECATED',
}

type SupplierBankAccount = {
    id: string
    countryCode: string | null
    bankName: string | null
    accountNumber: string | null
    bankCode: string | null
    bic: string | null
    iban: string | null
    createdAt: Date
    updatedAt: Date
}

interface DeimosSupplier {
    id: string
    name: string | null
    vatNumber: string | null
    registryId: string | null
    iban: string | null
    bic: string | null
    email: string | null
    address: {
        address1?: string
        address2?: string
        city?: string
        countryCode?: string
        region?: string
        zipCode?: string
    } | null
    domesticBankDetails: {
        bankCode?: string
        accountNumber?: string
    } | null
    bankDetailsErrors: BillBankDetailsError[] | null
    bankAccounts: SupplierBankAccount[] | null
    status: SupplierStatus
}

interface GetSupplierResponse extends DeimosSupplier {
    createdAt: string
    updatedAt: string
}

export interface Supplier extends GetSupplierResponse {
    reviewedAt: string | null
    reviewedBy: string | null
    note?: String
    firstInvoice?: SupplierInvoice
}

export interface PaginatedResponse<T> {
    data: T[]
    pagination: PaginationData<string>
}
