import React, { useState, FC } from 'react'
import { Button, Card, Divider, message, Popover, Space, Table, Tag, Typography } from 'antd'
import { getEmojiFlag } from 'countries-list'
import dayjs from 'packages/dayjs'
import type { ColumnProps } from 'antd/lib/table'
import { WarningTwoTone, LoadingOutlined } from '@ant-design/icons'

import { theme } from 'theme/styled'
import getCompanyKycStatus from 'utils/get-company-kyc-status'
import type { Company } from 'types/deimos-company'
import { KycStatus } from 'types/styx'

import { useUnloadDocumentUrl } from 'services/deimos/wallet'
import { PageContentLayout, PointerContainer } from 'components/layout-containers'
import { withTeamErrorHandler } from 'providers/error-handler'
import { Team } from 'utils/teams'

import { CountryCode } from '../wallet-unloads/utils'

import { bff } from 'pages/compliance/bff'

const { Text } = Typography
interface Props {
    companies?: Company[]
    onRowClick: (record: Company) => void
    registerInStyx: (companyId: string, manual?: boolean) => Promise<Company[] | undefined>
    setCountries: (country: string[]) => void
    children?: React.ReactNode
}

const Companies: FC<React.PropsWithChildren<Props>> = ({
    companies,
    onRowClick,
    registerInStyx,
    setCountries,
}) => {
    const [registering, setRegistering] = useState(false)
    const handleStyxRegistration = async (companyId: string, manual?: boolean) => {
        try {
            setRegistering(true)
            await registerInStyx(companyId, manual)
            message.success(manual ? 'Created company manually!' : 'KYC ran successfully!')
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setRegistering(false)
        }
    }

    useUnloadDocumentUrl()

    const handleChange = (_: any, filters: any) => {
        if (!filters.address) {
            return
        }
        setCountries(filters.address)
    }

    const columns: ColumnProps<Company>[] = [
        {
            title: 'Country',
            dataIndex: 'address',
            render: (address) => `${getEmojiFlag(address.country)} ${address.country}`,
            filters: Object.values(CountryCode).map((country) => ({
                text: `${getEmojiFlag(country)} ${country}`,
                value: country,
            })),
            filterMultiple: true,
        },
        {
            title: 'Company name',
            dataIndex: 'name',
            render: (companyName, { styxId, id }) =>
                styxId ? (
                    <Text>{companyName}</Text>
                ) : (
                    <Popover
                        trigger="click"
                        title="No Styx ID Found"
                        content={
                            <>
                                <Button type="primary" onClick={() => handleStyxRegistration(id)}>
                                    Run KYC
                                </Button>
                                <Divider type="vertical"></Divider>
                                <Button onClick={() => handleStyxRegistration(id, true)}>
                                    Register Manually
                                </Button>
                            </>
                        }
                    >
                        <Space>
                            <Text type="secondary">{companyName}</Text>{' '}
                            {registering ? (
                                <LoadingOutlined />
                            ) : (
                                <WarningTwoTone twoToneColor={theme.colors.warn} />
                            )}
                        </Space>
                    </Popover>
                ),
        },
        {
            title: 'Company ID',
            dataIndex: 'id',
            render: (companyId) => <Text copyable>{companyId}</Text>,
        },
        {
            title: 'KYC Status',
            dataIndex: 'status',
            render: (_, company) => getCompanyKycStatus(company).toUpperCase(),
            filters: Object.values(KycStatus).map((status) => ({
                text: status.toUpperCase(),
                value: status,
            })),
            onFilter: (status, company) => status === getCompanyKycStatus(company).toUpperCase(),
            filterMultiple: true,
        },
        {
            title: 'STP Status',
            dataIndex: 'stpStatus',
            render: (_, company) => {
                return <StpStatus companyId={company.id} />
            },
        },
        {
            title: 'Organization ID',
            dataIndex: 'organizationId',
            render: (organizationId) =>
                organizationId ? <Text copyable>{organizationId}</Text> : <Text>-</Text>,
        },
        {
            title: 'Registration number',
            dataIndex: 'registrationNumber',
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (createdAt) => dayjs(createdAt).format('lll'),
            sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
        },
        {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            render: (updatedAt) => dayjs(updatedAt).format('lll'),
            sorter: (a, b) =>
                dayjs(a?.updatedAt ?? a.createdAt).valueOf() -
                dayjs(b?.updatedAt ?? b.createdAt).valueOf(),
        },
    ]

    return (
        <PageContentLayout>
            <Card title="Latest companies">
                <PointerContainer>
                    <Table
                        loading={!companies}
                        pagination={{ defaultPageSize: 20 }}
                        rowKey="id"
                        dataSource={companies}
                        columns={columns}
                        onRow={(record) => ({
                            onClick: () => onRowClick(record),
                        })}
                        onChange={handleChange}
                    />
                </PointerContainer>
            </Card>
        </PageContentLayout>
    )
}

const StpStatus = ({ companyId }: { companyId: string }) => {
    const { data: stpStatus, isLoading } = bff.companiesList.hasStpCheckForCompany.useQuery({
        companyId,
    })

    if (isLoading) {
        return <LoadingOutlined />
    }

    if (stpStatus) {
        return (
            <Tag color="blue" data-testid="stp-status-run">
                Checks run
            </Tag>
        )
    } else {
        return (
            <Tag color="gold" data-testid="stp-status-no-checks">
                No checks run
            </Tag>
        )
    }
}

export default withTeamErrorHandler<Props>(Team.BOE, Companies)
