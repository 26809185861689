import { Alert, Button } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem } from 'formik-antd'
import dayjs, { Dayjs } from 'packages/dayjs'
import type { FC } from 'react'
import styled from 'styled-components'

import { validationSchema } from './validation-schema'

import DatePicker from 'packages/form/date-picker'

interface Props {
    onSubmit: (date: string) => void
    mismatch?: boolean
}

const labelCol = {
    sm: { span: 9 },
}

export const RequestBalanceStatementForm: FC<React.PropsWithChildren<Props>> = ({
    onSubmit,
    mismatch = false,
}) => {
    const isFuture = (currentDate: Dayjs) => currentDate.valueOf() > dayjs().valueOf()

    return (
        <Formik
            validationSchema={validationSchema}
            validateOnMount
            initialValues={{
                date: dayjs().toISOString(),
            }}
            onSubmit={({ date }) => onSubmit(date)}
        >
            {({ isValid, isSubmitting }) => {
                return (
                    <Form labelCol={labelCol}>
                        {mismatch && (
                            <FormItem name="alert">
                                <Alert
                                    type="error"
                                    showIcon
                                    message={`Oopsie daisy! There is a balance mismatch for this company's wallet.
                                     Please make sure to only download a balance statement for a date that precedes the date that the balance mismatch started;
                                     as the balance on dates following the start of this  mismatch is subject to change.`}
                                    data-testid="mismatchAlert"
                                ></Alert>
                            </FormItem>
                        )}
                        <FormItem name="date" label="Date" hasFeedback>
                            <DatePicker
                                name="date"
                                data-testid="date"
                                disabledDate={isFuture}
                                allowClear={false}
                            />
                        </FormItem>
                        <ButtonContainer>
                            <Button
                                htmlType="submit"
                                type="primary"
                                danger={mismatch}
                                disabled={!isValid}
                                data-testid="submit"
                                loading={isSubmitting}
                            >
                                Request balance statement
                            </Button>
                        </ButtonContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default RequestBalanceStatementForm
