import type { Employee } from 'types/employee'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getEmployeeName } from 'utils/employees'

export default function EmployeeLink({ employee }: { employee: Employee }) {
    const navigate = useNavigate()

    return (
        <Button
            onClick={() => navigate(`/customer-success/users/${employee.userId}`)}
            type="link"
            style={{ padding: 0 }}
        >
            {getEmployeeName(employee)} ({employee.role})
        </Button>
    )
}
