import type { PaginationData } from '@pleo-io/deimos'
import { useState, useEffect } from 'react'

import type { CursorRequestParams } from 'types/cursor-pagination'
import { usePrevious } from './use-previous'

export function useCursorPagination<SortableProperties extends string>(
    defaultPagination: CursorRequestParams<SortableProperties>
) {
    const [paginationRequest, setPaginationRequest] =
        useState<CursorRequestParams<SortableProperties>>(defaultPagination)

    const [currentPagination, setCurrentPagination] = useState<PaginationData<SortableProperties>>()
    const [firstPageCursor, setFirstPageCursor] = useState<string | null>()
    const previousPagination = usePrevious(currentPagination)

    useEffect(() => {
        if (!previousPagination && currentPagination) {
            setFirstPageCursor(currentPagination.startCursor)
        }
    }, [previousPagination, currentPagination])

    const nextPage = () => {
        if (currentPagination?.hasNextPage) {
            setPaginationRequest(({ limit, sorting }) => ({
                limit,
                sorting,
                after: currentPagination.endCursor!,
            }))
        }
    }

    const previousPage = () => {
        if (currentPagination?.hasPreviousPage) {
            setPaginationRequest(({ limit, sorting }) => ({
                limit,
                sorting,
                before: currentPagination.startCursor!,
            }))
        }
    }

    const updateSorting = (sorting: typeof defaultPagination.sorting) => {
        setPaginationRequest((prev) => ({
            ...prev,
            sorting,
        }))
    }

    const updateLimit = (limit: number) => {
        setPaginationRequest((prev) => ({
            ...prev,
            limit,
        }))
    }

    const firstPage = () => {
        if (firstPageCursor) {
            setPaginationRequest(({ limit, sorting }) => ({
                limit,
                sorting,
                after: firstPageCursor,
            }))
        }
    }

    const resetFirstPage = () => {
        setPaginationRequest(({ limit, sorting }) => ({
            limit,
            sorting,
        }))
    }

    return {
        paginationRequest,
        updateLimit,
        updateSorting,
        previousPage,
        nextPage,
        firstPage,
        setCurrentPagination,
        resetFirstPage,
    }
}
