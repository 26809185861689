import React, { FC, useState } from 'react'
import { Card, Modal } from 'antd'
import { ProofOfFundsRefundStatus, Status, Transaction } from 'types/proof-of-funds'
import {
    useGetCompanyProofOfFunds,
    useGetProofOfFundsByStatus,
} from 'services/deimos/proof-of-funds'
import CompanyHistory from './company-history-table/company-history'
import TransactionsTable from './transaction-table/transactions-table'
import { PageContentLayout } from 'components/layout-containers'

type ActiveKey = 'pending' | 'investigating' | 'refunds' | 'failedRefunds'
const INITIAL_PAGE = 1

interface Props {
    status: Status
    onShowHistory: (selectedCompanyId: string) => void
    refundStatus?: ProofOfFundsRefundStatus
}

const ProofOfFundsTransactions: FC<React.PropsWithChildren<Props>> = ({
    status,
    onShowHistory,
    refundStatus,
}) => {
    const [page, setPage] = useState(1)
    const { transactions, totalTransactions, mutations, isValidating } = useGetProofOfFundsByStatus(
        status,
        page,
        refundStatus
    )

    const handleUpdateTransaction = async (transaction: Transaction, newStatus: Status) =>
        await mutations.updateTransactionStatus(transaction, newStatus)

    return (
        <TransactionsTable
            fetching={!transactions && isValidating}
            dataSource={transactions ?? []}
            page={page}
            total={totalTransactions ?? 0}
            setPage={(newPage: number) => setPage(newPage)}
            onUpdateTransaction={handleUpdateTransaction}
            onShowHistory={onShowHistory}
            onRejectRefund={mutations.rejectRefund}
        />
    )
}

const ProofOfFunds = () => {
    const [activeTab, setActiveTab] = useState<ActiveKey>('pending')
    const [companyId, setCompanyId] = useState<string | null>(null)
    const [historyVisible, setHistoryVisibility] = useState(false)

    const { totalTransactions: totalPendingTransactions } = useGetProofOfFundsByStatus(
        Status.PENDING,
        INITIAL_PAGE
    )
    const { totalTransactions: totalInvestigatingTransactions } = useGetProofOfFundsByStatus(
        Status.INVESTIGATING,
        INITIAL_PAGE
    )

    const { totalTransactions: totalRefundTransactions } = useGetProofOfFundsByStatus(
        Status.REJECTED_REFUND,
        INITIAL_PAGE,
        ProofOfFundsRefundStatus.RECEIVED
    )

    const { totalTransactions: totalFailedRefundTransactions } = useGetProofOfFundsByStatus(
        Status.REJECTED_REFUND,
        INITIAL_PAGE,
        ProofOfFundsRefundStatus.FAILED
    )

    const { data: transactionsHistory, isValidating: historyIsValidating } =
        useGetCompanyProofOfFunds(companyId)

    const onShowHistory = (selectedCompanyId: string) => {
        setHistoryVisibility(true)
        setCompanyId(selectedCompanyId)
    }

    const tabList: { key: ActiveKey; tab: string }[] = [
        { key: 'pending', tab: `Pending (${totalPendingTransactions ?? 0})` },
        { key: 'investigating', tab: `Investigating (${totalInvestigatingTransactions ?? 0})` },
        { key: 'refunds', tab: `Refunds (${totalRefundTransactions ?? 0})` },
        { key: 'failedRefunds', tab: `Failed refunds (${totalFailedRefundTransactions ?? 0})` },
    ]

    const tabContent: Record<ActiveKey, JSX.Element> = {
        pending: <ProofOfFundsTransactions status={Status.PENDING} onShowHistory={onShowHistory} />,
        investigating: (
            <ProofOfFundsTransactions status={Status.INVESTIGATING} onShowHistory={onShowHistory} />
        ),
        refunds: (
            <ProofOfFundsTransactions
                status={Status.REJECTED_REFUND}
                onShowHistory={onShowHistory}
                refundStatus={ProofOfFundsRefundStatus.RECEIVED}
            />
        ),
        failedRefunds: (
            <ProofOfFundsTransactions
                status={Status.REJECTED_REFUND}
                onShowHistory={onShowHistory}
                refundStatus={ProofOfFundsRefundStatus.FAILED}
            />
        ),
    }

    return (
        <PageContentLayout>
            <Card tabList={tabList} onTabChange={(key) => setActiveTab(key as ActiveKey)}>
                {tabContent[activeTab]}
            </Card>

            <Modal
                title="Company's previous proof of funds"
                open={historyVisible}
                destroyOnClose
                onCancel={() => {
                    setHistoryVisibility(false)
                    setCompanyId(null)
                }}
                footer={null}
                width={800}
                centered
            >
                <CompanyHistory dataSource={transactionsHistory} loading={historyIsValidating} />
            </Modal>
        </PageContentLayout>
    )
}

export default ProofOfFunds
