import { Alert, Divider, List, Skeleton, Typography } from 'antd'

import { Link, useParams } from 'react-router-dom'
import { bff } from '../bff'
import type { RootRouter } from 'bff'
import { isTRPCClientError } from 'packages/bff/errors'
import type { TRPCClientError } from '@trpc/client'

const { Text } = Typography
const { Item } = List

export const Organization = () => {
    const { id: companyId } = useParams()
    const { data, error, isLoading } = bff.organization.getOrganization.useQuery({ companyId })

    return (
        <>
            {isLoading && <Skeleton />}
            {data && !error && (
                <>
                    <>
                        <Text strong>{data.name}</Text>
                        <br />
                        <Text type="secondary">{data.id}</Text>
                        <Divider style={{ margin: '12px 0 8px' }} />
                    </>
                    <List split={false} header={'Related companies'} size="small">
                        {(data.companies || []).map((company) => (
                            <Item style={{ padding: '0 0 8px' }} key={company.companyId}>
                                <Link to={`/customer-success/company-360/${company?.companyId}`}>
                                    {company.name}
                                </Link>
                            </Item>
                        ))}
                    </List>
                </>
            )}
            {error && isTRPCClientError(error) && <ErrorMessage error={error} />}
        </>
    )
}

const ErrorMessage = ({ error }: { error: TRPCClientError<RootRouter> }) => {
    switch (error.data?.code) {
        case 'NOT_FOUND':
            return (
                <Alert
                    type="info"
                    message={
                        <>
                            <Text>This company does not belong to an organization</Text>
                        </>
                    }
                />
            )
        default:
            return (
                <Alert
                    type="error"
                    message="Something went wrong while fetching the organization data"
                />
            )
    }
}
