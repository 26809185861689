import type { ApplicationState } from 'bff/moons/generated/kale'
import dayjs from 'dayjs'

export const getOverdraftApplicationStatusColor = (status: ApplicationState | undefined) => {
    switch (status) {
        case 'APPROVED':
            return 'green'
        case 'PENDING':
        case 'PROCESSING':
            return 'yellow'
        case 'REJECTED':
        case 'ERROR':
            return 'red'
        case 'INFO_REQUIRED':
            return 'blue'
        default:
            return 'default'
    }
}

export function isSameDay(date1: string, date2: string) {
    const d1 = dayjs(date1).startOf('day')
    const d2 = dayjs(date2).startOf('day')

    if (d1.isBefore(d2) || d1.isAfter(d2)) {
        return false
    } else {
        return true
    }
}

export const getHighApprovalLimit = (currency: string) => {
    switch (currency) {
        case 'EUR':
        case 'GBP':
            return 100_000
        case 'SEK':
            return 1_000_000
        case 'DKK':
            return 750_000
        default:
            return 0
    }
}
