import { type UboResponse, type Person, Role } from 'types/styx'
import type { CompanyStakeholderResponse } from '../bff/moons/generated/styx-v1'

export const isUbo = (entity: Person | UboResponse): entity is UboResponse =>
    (entity as UboResponse).ownershipPercentageRange !== undefined

export const isUboPerson = (entity: Person | UboResponse): entity is Person =>
    (entity as Person).roles?.includes(Role.UBO) ?? false

export const isUnknownShareholder = (entity: CompanyStakeholderResponse | undefined) =>
    entity
        ? entity.ownershipPercentageRange !== undefined && entity.entityType === 'UNKNOWN'
        : false
