import { GlobalOutlined } from '@ant-design/icons'
import type { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { SideMenu } from 'pages/page-layout-components'
import { useColorScheme } from 'hooks/use-color-scheme'

const Menu: FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const { colorScheme } = useColorScheme()

    const menuItems = [
        {
            key: 'companies',
            icon: <GlobalOutlined />,
            label: <Link to="../companies">Companies</Link>,
        },
        // {
        //     key: 'closed-companies',
        //     icon: <CloseCircleOutlined />,
        //     label: <Link to="../closed-companies">Closed companies</Link>,
        // },
    ]

    return (
        <SideMenu items={menuItems} selectedKeys={[id]} theme="dark" $colorScheme={colorScheme} />
    )
}

export default Menu
