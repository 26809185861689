import { FC, useCallback, useEffect } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Breadcrumb, Button, notification, Skeleton, Space, Tag, Tooltip, Typography } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
    AlertOutlined,
    CloseCircleOutlined,
    CopyFilled,
    CopyOutlined,
    FileSearchOutlined,
} from '@ant-design/icons'
import { get } from 'lodash'
import type { CompanyOverviewStatus } from 'bff/moons/generated/pandora-v1'
import { useGetCompany } from '../api/use-company'
import { useCompany360 } from '../api/use-company-360'
import { CompanySpendingState, useCompanySpendStatus } from '../api/use-company-spend-status'
import type { OnboardingSource } from 'types/deimos-company'
import { useColorScheme } from 'hooks/use-color-scheme'
import { useCurrentKycStatus } from 'services/styx/company'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { bff } from '../bff'
import type { DataResponseListVulnerabilityTypeResponse } from 'bff/moons/generated/customer-vulnerability-tracker'

const renderStatus = (status: CompanyOverviewStatus) => {
    const mapStatusToTag = {
        Active: <Tag color="success">Active</Tag>,
        Delinquent: <Tag color="warning">Delinquent</Tag>,
        Churning: <Tag color="default">Churning</Tag>,
        Deleted: (
            <Tag icon={<CloseCircleOutlined />} color="error">
                Deleted
            </Tag>
        ),
    }

    return get(mapStatusToTag, status)
}

const { Title, Text, Link: AntdLink } = Typography

const Extra: FC<{ companyId: string }> = ({ companyId }) => {
    const navigate = useNavigate()
    const { shouldUseDarkMode } = useColorScheme()

    const copyCompanyId = useCallback(() => {
        navigator.clipboard.writeText(companyId)
        notification.success({ message: 'Copied Company ID' })
    }, [companyId])

    const handleKeyPress = useCallback(
        (event: any) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'i') {
                copyCompanyId()
            }
        },
        [copyCompanyId]
    )

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress, { passive: true })
        return () => window.removeEventListener('keydown', handleKeyPress)
    })

    return (
        <Space direction="vertical" align="end">
            <Tooltip
                color={shouldUseDarkMode ? 'black' : 'white'}
                title={
                    <Text>
                        Copy Company ID <Text keyboard>⌘</Text>+<Text keyboard>I</Text>
                    </Text>
                }
            >
                <AntdLink type="secondary" onClick={() => copyCompanyId()}>
                    {companyId} <CopyFilled />
                </AntdLink>
            </Tooltip>
            <Space align="end">
                <Button
                    key="compliance-button"
                    onClick={() => navigate(`/compliance/companies/${companyId}`)}
                    type="primary"
                >
                    Compliance
                </Button>
                <Button
                    key="customer-success-button"
                    onClick={() => navigate(`/customer-success/companies/${companyId}`)}
                    type="primary"
                >
                    Customer Success
                </Button>
                <Button>
                    <a
                        href={`https://pleo.vitally.io/customers/${companyId}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Vitally
                    </a>
                </Button>
            </Space>
        </Space>
    )
}

interface TagsProps {
    status?: CompanyOverviewStatus
    velocity?: string
    spendStatusData?: CompanySpendingState
    onboardedVia?: OnboardingSource
    vulnerabilities?: DataResponseListVulnerabilityTypeResponse
    hasReadUkVulnerability?: boolean
}

const Tags: FC<TagsProps> = ({
    status,
    velocity,
    spendStatusData,
    onboardedVia,
    vulnerabilities,
    hasReadUkVulnerability,
}) => {
    return (
        <Space>
            {onboardedVia && (
                <Tooltip title={'Whether the company has been onboarded via PLEO or B4B'}>
                    <Tag color={onboardedVia === 'B4B' ? 'warning' : ''}>{onboardedVia}</Tag>
                </Tooltip>
            )}
            {status && renderStatus(status)}
            {velocity && (
                <Tooltip title="KYC Velocity. See full breakdown on this company's compliance page.">
                    <Tag icon={<FileSearchOutlined />}>{velocity}</Tag>
                </Tooltip>
            )}
            {spendStatusData?.status === 'BLOCKED' && (
                <Tooltip title={spendStatusData?.statusReason ?? null}>
                    <Tag icon={<AlertOutlined />} color="error">
                        {spendStatusData?.status}
                    </Tag>
                </Tooltip>
            )}
            {hasReadUkVulnerability &&
                vulnerabilities &&
                vulnerabilities?.data &&
                vulnerabilities?.data?.length > 0 && (
                    <>
                        <Tooltip title="This company has a vulnerable admin">
                            <Tag icon={<AlertOutlined />} color="error">
                                Vulnerable Admin
                            </Tag>
                        </Tooltip>
                        {vulnerabilities?.data.map((vulnerability) => (
                            <Tooltip title={vulnerability.description} key={vulnerability.id}>
                                <Tag color="error">{vulnerability.label}</Tag>
                            </Tooltip>
                        ))}
                    </>
                )}
        </Space>
    )
}

const OneLineSkeleton = () => (
    <Skeleton paragraph={false} active title={{ width: '10em' }} style={{ marginTop: '4px' }} />
)

export const Header = () => {
    const { id: companyId } = useParams()
    const hasReadUkVulnerability = useHasPermissions(['uk-vulnerability-read'])
    const { company360 } = useCompany360()
    const { kyc } = useCurrentKycStatus(companyId)
    const { data: companySpendStatusData } = useCompanySpendStatus()
    const { company } = useGetCompany(companyId)
    const { shouldUseDarkMode } = useColorScheme()
    const { data: vulnerabilities } = bff.vulnerabilities.getVulnerabilities.useQuery({ companyId })

    const copyCompanyName = useCallback(() => {
        navigator.clipboard.writeText(company360?.companyOverview?.companyName || '')
        notification.success({ message: 'Copied Company Name' })
    }, [company360?.companyOverview?.companyName])

    const handleKeyPress = useCallback(
        (event: any) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'u') {
                copyCompanyName()
            }
        },
        [copyCompanyName]
    )

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress, { passive: true })
        return () => window.removeEventListener('keydown', handleKeyPress)
    })

    return (
        <div data-testid="company-360-header">
            <PageHeader
                extra={<Extra companyId={companyId} />}
                breadcrumb={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success'}>Customer Success</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success/company-360'}>Company 360</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {company360?.companyOverview?.companyName || <OneLineSkeleton />}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
                title={
                    <Space direction="vertical" size={0}>
                        <Tooltip
                            color={shouldUseDarkMode ? 'black' : 'white'}
                            title={
                                <Text>
                                    Copy Name <Text keyboard>⌘</Text>+<Text keyboard>U</Text>
                                </Text>
                            }
                        >
                            <AntdLink type="secondary" onClick={() => copyCompanyName()}>
                                <Title level={3} style={{ margin: 0 }}>
                                    {company360?.companyOverview?.companyName ? (
                                        <>
                                            {company360?.companyOverview?.companyName}{' '}
                                            <CopyOutlined />
                                        </>
                                    ) : (
                                        <OneLineSkeleton />
                                    )}
                                </Title>
                            </AntdLink>
                        </Tooltip>
                        <Tags
                            status={company360?.companyOverview?.status}
                            velocity={kyc?.level}
                            spendStatusData={companySpendStatusData}
                            onboardedVia={
                                company?.address.country === 'GB'
                                    ? company?.onboardedVia
                                    : undefined
                            }
                            hasReadUkVulnerability={hasReadUkVulnerability}
                            vulnerabilities={vulnerabilities}
                        />
                    </Space>
                }
            />
        </div>
    )
}
