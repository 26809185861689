import { Button, notification, Tooltip } from 'antd'
import { Formik } from 'formik'
import dayjs from 'packages/dayjs'
import { Form, Radio, FormItem, Checkbox } from 'formik-antd'
import type { FC } from 'react'

import { ExpenseExportFilters, exportExpenses } from 'services/deimos/companies'

import styled from 'styled-components'
import { FormValues, validationSchema } from './validation-schema'
import { spacing, inputWidth } from 'theme/tokens'
import DatePicker from 'packages/form/date-picker'

const { RangePicker } = DatePicker

interface Props {
    companyId: string
    closeModal: () => void
}

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space16};
    padding-bottom: ${spacing.space16};
`
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const ExpenseExportForm: FC<React.PropsWithChildren<Props>> = ({
    companyId,
    closeModal,
}) => {
    const onSubmit = async ({ exportOption, exportDates, compressedReceipts }: FormValues) => {
        if (!companyId) {
            return
        }

        let request: ExpenseExportFilters = {}

        try {
            if (exportOption !== 'all') {
                request = {
                    fromDate:
                        exportDates && exportDates[0]
                            ? dayjs(exportDates[0]).startOf('day').toISOString()
                            : undefined,
                    untilDate:
                        exportDates && exportDates[1]
                            ? dayjs(exportDates[1]).endOf('day').toISOString()
                            : undefined,
                    ...request,
                }
            }

            const exportExpenseResponse = await exportExpenses({
                companyId,
                filters: request,
                compressedReceipts,
            })
            const exportExpensesData: { url: string } = await exportExpenseResponse.json()
            closeModal()
            notification.info({ message: 'Successfully exported expense' })
            window.open(exportExpensesData.url)
        } catch (e) {
            notification.error({ message: 'Could not export data.' })
        }
    }

    return (
        <Formik
            validationSchema={validationSchema}
            validateOnMount
            initialValues={{
                exportOption: 'all',
                exportDates: [dayjs().subtract(1, 'month'), dayjs()],
                compressedReceipts: false,
            }}
            onSubmit={onSubmit}
            data-testId="expenseExportForm"
        >
            {({ isValid, values, isSubmitting }) => {
                const disableDates = values.exportOption === 'all'
                return (
                    <Form>
                        <FlexContainer style={{ width: inputWidth.large }}>
                            <label htmlFor="exportOption">Select Export Option</label>
                            <Radio.Group name="exportOption" data-testid="exportOption">
                                <Radio
                                    name="exportOption"
                                    value="all"
                                    data-testid="exportOptionAll"
                                >
                                    All Expenses
                                </Radio>
                                <Radio
                                    name="exportOption"
                                    value="dates"
                                    data-testid="exportOptionDates"
                                >
                                    Select Date Range
                                </Radio>
                            </Radio.Group>
                            <FormItem
                                name="exportDates"
                                hasFeedback
                                style={{
                                    width: inputWidth.large,
                                    display: disableDates ? 'none' : 'block',
                                }}
                                data-testid="date-range"
                            >
                                <RangePicker
                                    disabled={disableDates}
                                    name="exportDates"
                                    data-testid="exportDates"
                                    allowClear={false}
                                ></RangePicker>
                            </FormItem>
                            <Checkbox name="compressedReceipts" data-testid="compressedReceipts">
                                <Tooltip
                                    title={
                                        'This option might be useful if the archive is too big or the export fails'
                                    }
                                    mouseEnterDelay={1}
                                >
                                    Use compressed receipts
                                </Tooltip>
                            </Checkbox>
                        </FlexContainer>
                        <ButtonContainer>
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={!isValid}
                                data-testid="submit-btn"
                                loading={isSubmitting}
                            >
                                Export Expenses
                            </Button>
                        </ButtonContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default ExpenseExportForm
