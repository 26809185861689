import { Tag } from 'antd'
import {
    InboxOutlined,
    HourglassOutlined,
    InfoCircleOutlined,
    StopOutlined,
    SyncOutlined,
    CheckCircleOutlined,
    SolutionOutlined,
} from '@ant-design/icons'

import type { CaseOutputState } from 'bff/moons/generated/case-manager'

export const StatusTag = ({ status }: { status: CaseOutputState }) => {
    return (
        <Tag color={caseOutputStateMap[status].color} icon={caseOutputStateMap[status].icon}>
            {caseOutputStateMap[status].title}
        </Tag>
    )
}

const caseOutputStateMap: Record<
    CaseOutputState,
    {
        title: string
        color: string
        icon?: JSX.Element
    }
> = {
    NEW: {
        title: 'New',
        color: 'blue',
        icon: <InboxOutlined />,
    },
    IN_PROGRESS: {
        title: 'In Progress',
        color: 'blue',
        icon: <SolutionOutlined />,
    },
    WAITING_FOR_CUSTOMER: {
        title: 'Waiting for Customer',
        color: 'orange',
        icon: <HourglassOutlined />,
    },
    INFORMATION_RECEIVED: {
        title: 'Information Received',
        color: 'blue',
        icon: <InfoCircleOutlined />,
    },
    WALLET_BLOCKED: {
        title: 'Wallet Blocked',
        color: 'orange',
        icon: <StopOutlined />,
    },
    OFFBOARDING_INITIATED: {
        title: 'Offboarding Initiated',
        color: 'orange',
        icon: <SyncOutlined />,
    },
    OFFBOARDING_COMPLETED: {
        title: 'Offboarding Completed',
        color: 'green',
        icon: <CheckCircleOutlined />,
    },
    ODD_COMPLETED: {
        title: 'ODD Completed',
        color: 'green',
        icon: <CheckCircleOutlined />,
    },
}
