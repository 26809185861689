import { notification } from 'antd'
import React, { FC, useState } from 'react'
import * as api from 'services/deimos/transactions'
import LinkExpenses, { LinkExpensesPayload } from './link-expenses'

const LinkExpensesContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const [fetching, setFetching] = useState(false)

    const linkExpenses = async ({ expenseIds }: LinkExpensesPayload) => {
        setFetching(true)
        try {
            await api.linkExpenses(expenseIds)
            notification.success({
                message: 'Expenses successfully linked',
            })
        } catch (e) {
            notification.error({
                message: `Could not link expenses.`,
                description: (e as Error).message,
            })
        } finally {
            setFetching(false)
        }
    }

    return <LinkExpenses fetching={fetching} linkExpenses={linkExpenses} />
}

export default LinkExpensesContainer
