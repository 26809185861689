import React, { FC, useState } from 'react'
import { Button, message } from 'antd'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

import type { Person, KycCheck } from 'types/styx'
import { getLatestKycCheck } from 'pages/compliance/company/people/helpers'
import KycTimeline from 'pages/compliance/shared/kyc-timeline/kyc-timeline'
import { RDC } from 'types/styx'
import { useManagement } from 'services/styx/management'

interface Props {
    personFullName: string
    isFalsePositiveSis: boolean
    runKycScreening: () => void
    handleSisFalsePositive: (checked: boolean) => void
}

export const Sis: FC<React.PropsWithChildren<Props>> = ({
    personFullName,
    runKycScreening,
    children,
}) => {
    return (
        <>
            <HistoryContainer>{children}</HistoryContainer>
            <ActionContainer>
                <a
                    data-testid="search-rdc"
                    href={`${RDC.SEARCH_URL}${personFullName}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Search in RDC
                </a>
                <Button data-testid="new-check" type="primary" onClick={runKycScreening}>
                    Re-run KYC screening (RDC)
                </Button>
            </ActionContainer>
        </>
    )
}

export const HistoryContainer = styled.div`
    display: block;
    column-gap: ${spacing.space24};
    grid-template-columns: 1fr 1fr;
    margin-bottom: ${spacing.space24};
`
const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const SisContainer: FC<React.PropsWithChildren<{ person: Person; refetchPeople: () => void }>> = ({
    person,
    refetchPeople,
}) => {
    const [fetching, setFetching] = useState(false)
    const { mutations } = useManagement()

    const latestSis = getLatestKycCheck(person.sisChecks)
    const isFalsePositiveSis = !!latestSis && !!latestSis.falsePositive

    const personFullName = [person.name.givenName, person.name.middleName, person.name.familyName]
        .filter((name) => !!name)
        .join('+')

    const handleSisFalsePositive = async (checked: boolean) => {
        if (!latestSis) {
            return
        }

        try {
            setFetching(true)
            await mutations.toggleKycCheckFalsePositive(latestSis.id, checked)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to mark SIS check as false positive: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    const runKycScreening = async () => {
        try {
            setFetching(true)
            await mutations.createPepSisScreening(person.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to create screening: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    const handleToggleFalsePositive = async (check: KycCheck, checked: boolean) => {
        try {
            await mutations.toggleKycCheckFalsePositive(check.id, checked)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to toggle false positive status: ${(e as Error).message}`)
        }
    }

    return (
        <Sis
            personFullName={personFullName}
            isFalsePositiveSis={isFalsePositiveSis}
            handleSisFalsePositive={handleSisFalsePositive}
            runKycScreening={runKycScreening}
        >
            <KycTimeline
                kycChecks={person.sisChecks}
                title="SIS History"
                fetching={fetching}
                toggleFalsePositive={handleToggleFalsePositive}
                showRecurringMonitoring={true}
            />
        </Sis>
    )
}

export default SisContainer
