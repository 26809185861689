import { Button, Card, Spin } from 'antd'
import { Formik } from 'formik'
import { Input, Form, FormItem, InputNumber } from 'formik-antd'
import styled from 'styled-components'
import * as Yup from 'yup'
import { spacing } from 'theme/tokens'
import ErrorResult from './error-result/error-result'

import SupportedCountrySelect from 'components/supported-countries-select'

import { PageContentLayout } from 'components/layout-containers'

import { supportedCountries, SupportedCountry } from 'types/countries'

import { useCreateBulkDemoAccounts } from 'services/dionysus/bulk-demo-accounts'
import SuccessResult from './success-result/success-result'

const validationSchema = Yup.object()
    .shape({
        companyName: Yup.string().required('Required'),
        requesterEmail: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        amount: Yup.number().required('Required'),
    })
    .required()

export type FormValues = Yup.InferType<typeof validationSchema> & {
    country: SupportedCountry
}

export const formValuesToCreateDemoAccountRequest = (formValues: FormValues): any => ({
    companyName: formValues.companyName,
    country: formValues.country,
    requesterEmail: formValues.requesterEmail,
    amount: formValues.amount,
})

const BulkDemoAccounts = () => {
    const { loading, error, setCreateBulkDemoAccountRequest, data, dismissError } =
        useCreateBulkDemoAccounts()

    const onSubmit = (values: FormValues) => setCreateBulkDemoAccountRequest(values)

    return (
        <PageContentLayout>
            {data.success && <SuccessResult />}
            {error && <ErrorResult goBack={dismissError} errorMessage={error.message} />}
            {loading && <Spin />}
            {data.success === null && !loading && !error && (
                <Card title="Generate Demo Account">
                    <Formik
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        initialValues={{
                            companyName: '',
                            country: supportedCountries[0],
                            amount: 2,
                            requesterEmail: '',
                        }}
                    >
                        <Form layout="vertical">
                            <FormLayout>
                                <FormItem name="companyName" label="Company name" hasFeedback>
                                    <Input name="companyName" placeholder="Company name" />
                                </FormItem>
                                <FormItem name="country" label="Country">
                                    <SupportedCountrySelect name="country" placeholder="Country" />
                                </FormItem>
                                <FormItem name="requesterEmail" label="Requester email" hasFeedback>
                                    <Input name="requesterEmail" placeholder="Requester email" />
                                </FormItem>
                                <FormItem name="amount" label="Amount to be created" hasFeedback>
                                    <InputNumber name="amount" min={1} max={100} />
                                </FormItem>
                            </FormLayout>
                            <Button type="primary" htmlType="submit">
                                Publish
                            </Button>
                        </Form>
                    </Formik>
                </Card>
            )}
        </PageContentLayout>
    )
}

const FormLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 ${spacing.space24};
    & .ant-input-number {
        width: 100%;
    }
`

export default BulkDemoAccounts
