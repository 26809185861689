import React, { useState } from 'react'
import {
    Table,
    Divider,
    Tag,
    Typography,
    Button,
    Modal,
    Card,
    Space,
    message,
    notification,
    Popover,
} from 'antd'
import styled from 'styled-components'
import { inputWidth } from 'theme/tokens'
import dayjs, { Dayjs } from 'packages/dayjs'
import { Link, useNavigate } from 'react-router-dom'

import type { GetFlagResponse, FlagRow, Employee } from '@pleo-io/deimos'
import type { ColumnProps } from 'antd/lib/table'

import type { Company } from 'types/deimos-company'
import { RiskScore } from 'types/styx'
import { snakeCaseToTitleSnakeCase, snakeCaseToTitleCase } from 'utils/strings'
import ManuallyFlagTransactionForm from 'pages/compliance/transaction-monitoring/manually-flag-transaction-form/manually-flag-transaction-form'
import { useArielFlagsPaginated } from 'services/deimos/transaction-monitoring'

import {
    investigationStatuses,
    getDateOfLatestFlag,
    DisplayInvestigationStatusAml,
    getFlagFamily,
    getEntityId,
    datePopoverText,
} from 'pages/compliance/transaction-monitoring/utils'
import { PageContentLayout, PointerContainer } from 'components/layout-containers'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { getAmlBillsPocketRules } from 'services/ariel/rules-report'
import { DayjsDatePicker } from 'packages/form/date-picker'
import { FlagCategory, InvestigationStatus } from 'types/ariel'

const { Text, Paragraph: AntParagraph } = Typography
const { RangePicker } = DayjsDatePicker

const columns = (status: InvestigationStatus): ColumnProps<GetFlagResponse>[] => [
    {
        title: () => <Popover content={datePopoverText(status)}>Date</Popover>,
        dataIndex: 'rows',
        key: 'date',
        render: (rows: FlagRow[]) =>
            getDateOfLatestFlag(rows) ? dayjs(getDateOfLatestFlag(rows)).format('lll') : null,
    },
    {
        title: 'Payment',
        render: (record: GetFlagResponse) =>
            record.card ? (
                <>
                    <span>{record.card.pan}</span>
                    <Divider type="vertical" />
                    <Tag color="blue">{record.card.status}</Tag>
                </>
            ) : record.bill ? (
                <span>INVOICE</span>
            ) : record.personalTransfer ? (
                <span>PERSONAL CARD TRANSFER</span>
            ) : record.employeeBankTransfer ? (
                <span>EMPLOYEE BANK TRANSFER</span>
            ) : record.walletWithdrawal ? (
                <span>WALLET WITHDRAWAL</span>
            ) : (
                '-'
            ),
    },
    {
        title: 'Rows',
        dataIndex: 'rows',
        key: 'rows',
        render: (rows: FlagRow[]) => rows.length,
    },
    {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        render: (company?: Company) =>
            company?.name ? (
                <Link
                    onClick={(e) => e.stopPropagation()}
                    to={`/compliance/companies/${company?.id}`}
                >
                    {company?.name}
                </Link>
            ) : (
                '-'
            ),
    },
    {
        title: 'Employee email',
        dataIndex: 'employee',
        key: 'employee',
        render: (employee?: Employee) => <BreakText>{employee?.email ?? '-'}</BreakText>,
    },
    {
        title: 'Risk Score',
        dataIndex: 'riskScore',
        key: 'company',
        render: (riskScore?: RiskScore) =>
            riskScore ? (
                <Text type={riskScore === RiskScore.HIGH ? 'danger' : undefined}>
                    {snakeCaseToTitleSnakeCase(String(riskScore))}
                </Text>
            ) : (
                '-'
            ),
    },
    {
        title: 'Type of business',
        dataIndex: 'areaOfBusiness',
        key: 'areaOfBusiness',
        width: '15%',
        render: (text: string) => <Paragraph ellipsis={{ rows: 2 }}>{text}</Paragraph>,
    },
]

const BreakText = styled(Text)`
    word-break: break-all;
`

const Paragraph = styled(AntParagraph)`
    margin: 0 !important;
`

const tomorrow = dayjs().add(1, 'day')
// AML Investigations last up to three months
const threeMonthsAgo = dayjs().subtract(3, 'months')

const Aml = () => {
    const hasGetAmlRulesReport = useHasPermissions(['get-ariel-aml-rules-report'])
    const defaultActiveInvestigationStatus = InvestigationStatus.REQ_INVESTIGATION
    const [visible, setVisible] = React.useState(false)
    const [investigationStatus, setInvestigationStatus] = useState<InvestigationStatus[]>([
        defaultActiveInvestigationStatus,
    ])
    const [paginationOptions, setPaginationOptions] = useState<{
        pageSize: number
        pageOffset: number
    }>({ pageSize: 50, pageOffset: 0 })

    let [category] = React.useState<FlagCategory[]>([
        FlagCategory.AML,
        FlagCategory.BILL_FRAUD,
        FlagCategory.PERSONAL_TRANSFER_FRAUD,
        FlagCategory.EMPLOYEE_BANK_TRANSFER_FRAUD,
        FlagCategory.WALLET_WITHDRAWAL_FRAUD,
    ])

    // Temporarily filtering out the card transaction related flags in order for the 1st line of defence team to start working card transaction related cases in the new transaction monitoring system Lucinity.
    if (investigationStatus.includes(InvestigationStatus.NEW)) {
        category = category.filter((item) => item !== FlagCategory.AML)
    }

    const [{ to, from }, setDateRange] = React.useState<{ to: Dayjs; from: Dayjs }>({
        to: tomorrow,
        from: threeMonthsAgo,
    })

    const navigate = useNavigate()

    const { data: flagsPaginated, mutations } = useArielFlagsPaginated({
        to: to.toISOString(),
        from: from.toISOString(),
        status: investigationStatus,
        category,
        pageSize: paginationOptions.pageSize,
        pageOffset: paginationOptions.pageOffset,
    })

    const flags = flagsPaginated?.rows

    const onRowClick = (record: GetFlagResponse) => {
        const entityId = getEntityId(record)
        const pathToAmlTransactionList = `${entityId}?investigationStatus=${investigationStatus}&to=${to.toISOString()}&from=${from.toISOString()}&pageSize=${
            paginationOptions.pageSize
        }&pageOffset=${paginationOptions.pageOffset}`
        navigate(pathToAmlTransactionList)
    }

    const tabList = investigationStatuses
        .filter((status) => status !== InvestigationStatus.NEW)
        .map((status) => ({
            key: status,
            tab: snakeCaseToTitleCase(status),
        }))

    const onClickReport = async () => {
        try {
            const res = await getAmlBillsPocketRules()
            const rules = await res.text()
            const readableRules = rules.replace(/"/g, '').split('\\n').join('\n')
            await navigator.clipboard.writeText(readableRules)
            message.success('AMl, Invoices and Pocket rules copied to clipboard')
        } catch (e) {
            notification.error({
                description: 'Unable to retrieve rules report from Ariel',
                message: (e as Error).message,
            })
        }
    }

    return (
        <PageContentLayout>
            <Card>
                <Row>
                    <Space>
                        <Text>Filter by date:</Text>
                        <RangePicker
                            value={[from, to]}
                            onChange={(range) =>
                                setDateRange({
                                    from: range?.[0] ?? threeMonthsAgo,
                                    to: range?.[1] ?? tomorrow,
                                })
                            }
                            allowClear={false}
                            style={{ width: `${inputWidth.large}` }}
                        />
                    </Space>
                    {hasGetAmlRulesReport && (
                        <Button type="primary" onClick={() => onClickReport()}>
                            AML rules report
                        </Button>
                    )}
                    <Button type="primary" onClick={() => setVisible(true)}>
                        Flag transaction
                    </Button>
                </Row>
            </Card>
            <Card
                defaultActiveTabKey={defaultActiveInvestigationStatus}
                tabList={tabList}
                onTabChange={(status) => {
                    setInvestigationStatus([status as InvestigationStatus])
                }}
            >
                <PointerContainer>
                    <Table
                        pagination={{
                            pageSize: 50,
                            total: flagsPaginated?.totalCount,
                            onChange: (page, pageSize) => {
                                setPaginationOptions({
                                    pageSize: pageSize,
                                    pageOffset: page - 1,
                                })
                            },
                        }}
                        onRow={(record) => ({
                            onClick: () => onRowClick(record),
                        })}
                        loading={!flags}
                        columns={columns(investigationStatus[0])}
                        dataSource={flags ?? []}
                        rowKey={(record) => record.rows[0]?.flag.id ?? '1'}
                    />
                </PointerContainer>
            </Card>
            <Modal
                destroyOnClose
                title="Flag a transaction as confirmed money laundering concern"
                open={visible}
                onCancel={() => setVisible(false)}
                footer={null}
            >
                <ManuallyFlagTransactionForm
                    statuses={Object.entries(DisplayInvestigationStatusAml)}
                    allowBill
                    onSubmit={(values) => {
                        mutations.createManualFlag({
                            ...values,
                            investigationStatus: values.investigationStatus as InvestigationStatus,
                            category: values.category as FlagCategory,
                            family: getFlagFamily(values.category as FlagCategory),
                        })
                        setVisible(false)
                    }}
                />
            </Modal>
        </PageContentLayout>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

export default Aml
