import { useState } from 'react'
import { FormOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'
import { Dropdown, Avatar, Space, MenuProps } from 'antd'
import { color } from 'theme/tokens'

import { useLogout } from 'providers/auth-context'
import { useUser } from 'providers/user-context'
import styled from 'styled-components'
import { SettingsModal } from 'components/settings-modal/settings-modal'
import { RequestPermissionsModal } from 'components/request-permissions-modal/request-permissions-modal'

const UserDropdown = () => {
    const { email } = useUser()
    const logout = useLogout()
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
    const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false)

    const menuItems: MenuProps['items'] = [
        {
            key: 'settings',
            label: 'Settings',
            onClick: () => setIsSettingsModalOpen(true),
            icon: <SettingOutlined />,
        },
        {
            key: 'self-request-permissions',
            label: 'Self-request permissions',
            onClick: () => setIsPermissionsModalOpen(true),
            icon: <FormOutlined />,
        },
        {
            key: 'logout',
            label: 'Logout',
            icon: <LogoutOutlined />,
            onClick: () => logout(),
        },
    ]

    return (
        <>
            <Dropdown menu={{ items: menuItems }}>
                <Space
                    className="ant-dropdown-link"
                    style={{ cursor: 'pointer' }}
                    data-testid="dropdown-link"
                >
                    <ColoredAvatar icon={<UserOutlined />} size="small" />
                    {email}
                </Space>
            </Dropdown>
            <SettingsModal
                open={isSettingsModalOpen}
                onCancel={() => setIsSettingsModalOpen(false)}
            />
            <RequestPermissionsModal
                open={isPermissionsModalOpen}
                onCancel={() => setIsPermissionsModalOpen(false)}
            />
        </>
    )
}

const ColoredAvatar = styled(Avatar)`
    background-color: ${color.darkblue};
`

export default UserDropdown
