import { Button } from 'antd'
import type { FC } from 'react'

import styled from 'styled-components'
import { Form, Select } from 'formik-antd'
import { Formik } from 'formik'
import type { FormValues } from './validation-schema'
import { CpqBillingPeriodType, CpqRatePlanType } from 'bff/moons/generated/beyond'
import { inputWidth, spacing } from 'theme/tokens'

interface Props {
    onSubmit: (values: FormValues) => void
    isLoadingUpdate: boolean
}

export const ModifySubscriptionModalForm: FC<React.PropsWithChildren<Props>> = ({
    onSubmit,
    isLoadingUpdate,
}) => {
    return (
        <Formik
            validateOnMount
            initialValues={{
                ratePlanType: CpqRatePlanType.STARTER,
                billingPeriodType: CpqBillingPeriodType.MONTH,
                billingPeriodCommitment: undefined,
            }}
            onSubmit={onSubmit}
        >
            {({ isValid }) => {
                return (
                    <Form>
                        <Select
                            key="plan"
                            showSearch
                            placeholder="Select a plan"
                            optionFilterProp="label"
                            options={planOptions}
                            name={'ratePlanType'}
                            style={{ width: `${inputWidth.large}`, margin: `${spacing.space4}` }}
                        />
                        <Select
                            key="billingPeriod"
                            showSearch
                            placeholder="Select a billing period"
                            optionFilterProp="label"
                            options={billingPeriodTypes}
                            name={'billingPeriodType'}
                            style={{ width: `${inputWidth.large}`, margin: `${spacing.space4}` }}
                        />
                        <Select
                            key="commitment"
                            showSearch
                            placeholder="Select a billing period commitment"
                            optionFilterProp="label"
                            options={billingPeriodCommitment}
                            name={'billingPeriodCommitment'}
                            style={{ width: `${inputWidth.large}`, margin: `${spacing.space4}` }}
                        />
                        <ButtonContainer>
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={!isValid}
                                data-testid="submit"
                                loading={isLoadingUpdate}
                            >
                                Update subscription
                            </Button>
                        </ButtonContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}

const planOptions = [
    {
        value: CpqRatePlanType.STARTER,
        label: 'Starter',
    },
    {
        value: CpqRatePlanType.ESSENTIAL,
        label: 'Essential',
    },
    {
        value: CpqRatePlanType.ADVANCED,
        label: 'Advanced',
    },
]

const billingPeriodTypes = [
    {
        value: CpqBillingPeriodType.MONTH,
        label: 'Month',
    },
    {
        value: CpqBillingPeriodType.YEAR,
        label: 'Year',
    },
]

const billingPeriodCommitment = [
    {
        value: undefined,
        label: 'No period commitment',
    },
    {
        value: 1,
        label: '1 period',
    },
    {
        value: 12,
        label: '12 periods',
    },
]

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default ModifySubscriptionModalForm
