import { useState } from 'react'
import { Card, Button, message } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import { Formik } from 'formik'
import { Input as AntInput, Form } from 'formik-antd'
import { inputWidth } from 'theme/tokens'
import { linkPaymentToTransaction } from 'services/telesto/bank-transactions'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    transactionId: Yup.string().required('Transaction ID is required'),
    paymentId: Yup.string().required('Payment ID is required'),
})

const LinkPayment = () => {
    const [isLoading, setLoading] = useState(false)

    const link = async (transactionId: string, paymentId: string) => {
        try {
            setLoading(true)
            await linkPaymentToTransaction(transactionId.trim(), paymentId.trim())
            message.success('Successfully linked payment.')
        } catch (e) {
            message.error(`Error linking payment: ${(e as Error).message}`)
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageContentLayout>
            <Card title="Link payment">
                <Formik
                    validationSchema={validationSchema}
                    validateOnMount
                    initialValues={{ transactionId: '', paymentId: '' }}
                    onSubmit={({ transactionId, paymentId }) => {
                        link(transactionId, paymentId)
                    }}
                >
                    {({ values }) => (
                        <Form layout="inline">
                            <Form.Item name="transactionId" label="Transaction ID">
                                <AntInput
                                    name="transactionId"
                                    placeholder="ac2ba3a33bc41c48bab3fcf247a54c74"
                                    style={{ width: `${inputWidth.large}` }}
                                />
                            </Form.Item>
                            <Form.Item name="paymentId" label="Payment ID">
                                <AntInput
                                    name="paymentId"
                                    title="Payment ID"
                                    placeholder="C26GNA6M51U00"
                                    style={{ width: `${inputWidth.large}` }}
                                />
                            </Form.Item>
                            <Form.Item name="submit">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                    disabled={!values.transactionId || !values.paymentId}
                                >
                                    Link payment
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                </Formik>
            </Card>
        </PageContentLayout>
    )
}

export default LinkPayment
