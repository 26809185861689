import type { FC } from 'react'
import { Empty } from 'antd'
import styled from 'styled-components'

const EmptyState: FC<React.PropsWithChildren<unknown>> = () => (
    <EmptyContainer>
        <Empty />
    </EmptyContainer>
)

export default EmptyState

const EmptyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
