import type { PostalAddressResponse } from 'bff/moons/generated/styx-v1'
import { KycCheckpoint, KycStatus } from 'types/styx'

export const peopleSectionLabel = (onboardingSource?: string) => {
    const isOnboardedViaB4B = onboardingSource?.toUpperCase() === 'B4B'
    return isOnboardedViaB4B ? 'People and Parent entities' : 'People'
}

export const getFddMetadata = (kycCheckpoints: KycCheckpoint[]) => {
    if (!kycCheckpoints || kycCheckpoints.length === 0) {
        return null
    }

    return kycCheckpoints
        .filter((checkpoint) => checkpoint.level === KycStatus.FDD)
        .reduce((acc: KycCheckpoint | null, checkpoint) => {
            if (!acc) {
                return checkpoint
            }

            // return the most recent FDD checkpoint
            return acc.performed > checkpoint.performed ? acc : checkpoint
        }, null)
        ?.notes?.replace('First', 'Initial') // replace 'First' with 'Initial', 'First FDD.' -> 'Initial FDD.'
}

export const formatAddress = (address?: PostalAddressResponse) => {
    return (
        address &&
        Object.values(address)
            .filter((v) => v)
            .join(', ')
    )
}
