import { Table, Tag, Typography } from 'antd'
import { useRolesHistory } from './api/use-roles-history'
import type { RolesHistory } from './types'
import type { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import { RolesHistoryFilters } from './components/roles-history-filters'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useGetUser } from './api/use-get-user'
import type { FC } from 'react'
import { formatTimestamp } from 'packages/dates/dates'

const UserLink = ({ userId }: { userId: string }) => {
    const { user } = useGetUser(userId, true)

    return (
        <Link target="_blank" to={`/customer-success/users/${userId}`}>
            <Typography.Text>{user?.email || userId}</Typography.Text>
        </Link>
    )
}

interface RolesHistoryTableProps {
    rolesHistory?: RolesHistory[]
    isValidating?: boolean
}

const RolesHistoryTable: FC<RolesHistoryTableProps> = ({ rolesHistory, isValidating }) => {
    const columns: ColumnsType<RolesHistory> = [
        {
            title: 'User',
            dataIndex: 'userId',
            key: 'userId',
            render: (userId) => <UserLink userId={userId} />,
        },
        {
            title: 'Resource',
            dataIndex: 'resource',
            key: 'resource',
            render: (resource) => <Tag color="blue">{resource}</Tag>,
        },
        {
            title: 'Resource ID',
            dataIndex: 'resourceId',
            key: 'resourceId',
        },
        {
            title: 'Granted at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => formatTimestamp(createdAt),
        },
        {
            title: 'Revoked at',
            dataIndex: 'deletedAt',
            key: 'deletedAt',
            render: (deletedAt) => formatTimestamp(deletedAt),
        },
    ]

    return (
        <Table
            loading={isValidating}
            caption={<RolesHistoryFilters />}
            dataSource={rolesHistory}
            columns={columns}
            footer={() => (
                <Typography.Text type="secondary">
                    <InfoCircleOutlined style={{ marginRight: '0.5rem' }} />
                    Up to 100 results will be fetched.
                </Typography.Text>
            )}
        />
    )
}

export const RolesHistoryPage = () => {
    const { rolesHistory, isValidating } = useRolesHistory()

    return <RolesHistoryTable rolesHistory={rolesHistory} isValidating={isValidating} />
}
