import { FC, useEffect, useState } from 'react'
import { Descriptions, message } from 'antd'
import { Form, Formik, FormikHelpers } from 'formik'
import { Input, Select, SubmitButton } from 'formik-antd'
import dayjs from 'packages/dayjs'
import { deSnakify } from 'utils/strings'
import {
    ResultScore,
    RiskAssessmentV2,
    RiskScoreV2,
    SubmitRiskAssessmentRequestV2,
} from 'types/styx'

import { B4BFormValues, b4bValidationSchema } from './validation-schema'
import { useStyxCompany } from 'services/deimos/styx-company/styx-company'
import { inputWidth } from 'theme/tokens'
import { useUser } from 'providers/user-context'
import useAnalytics from 'utils/analytics'
import Spinner from 'components/content-spinner'

interface Props {
    riskAssessment?: RiskAssessmentV2
    onSubmit: (values: B4BFormValues, formikHelpers: any) => void
    isValidating: boolean
}

const { Option } = Select
const { TextArea } = Input

enum SubmitButtonLabel {
    APPROVE = 'Approve',
    REJECT = 'Reject',
}

export const AssessmentResultB4B: FC<React.PropsWithChildren<Props>> = ({
    riskAssessment,
    onSubmit,
    isValidating,
}) => {
    const scoreResults = Object.values(RiskScoreV2)
    const riskResults = Object.values(ResultScore)
    const riskAssessmentV2 = riskAssessment as unknown as RiskAssessmentV2
    const [hasMounted, setHasMounted] = useState(false)
    const [submitButtonLabel, setSubmitButtonLabel] = useState<SubmitButtonLabel>(
        SubmitButtonLabel.APPROVE
    )

    if (isValidating && !hasMounted) {
        return (
            <div style={{ height: inputWidth.medium }}>
                <Spinner />
            </div>
        )
    }

    return (
        <Formik
            validationSchema={b4bValidationSchema}
            onSubmit={onSubmit}
            initialValues={{
                score: riskAssessmentV2?.score || null,
                result: riskAssessmentV2?.result || null,
                notes: riskAssessmentV2?.notes ?? '',
            }}
        >
            {({ validateForm, isValid, values }) => {
                useEffect(() => {
                    setHasMounted(true)
                }, [])

                useEffect(() => {
                    validateForm()
                }, [validateForm])

                useEffect(() => {
                    setSubmitButtonLabel(
                        values['result']?.toString() === ResultScore.FAIL
                            ? SubmitButtonLabel.REJECT
                            : SubmitButtonLabel.APPROVE
                    )
                }, [values])

                return (
                    <Form>
                        <Descriptions
                            layout="vertical"
                            bordered
                            column={4}
                            title="Assessment Result"
                        >
                            <Descriptions.Item label="Risk score">
                                <Select
                                    name="score"
                                    placeholder="Risk Score"
                                    style={{ width: inputWidth.medium }}
                                    data-testid="risk-score-select"
                                >
                                    {scoreResults.map((result) => (
                                        <Option
                                            value={result}
                                            key={result}
                                            data-testId={`risk-score-option-${result}`}
                                        >
                                            {deSnakify(result)}
                                        </Option>
                                    ))}
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label="Result">
                                <Select
                                    name="result"
                                    placeholder="result"
                                    data-testid="risk-result-score"
                                >
                                    {riskResults.map((result) => (
                                        <Option
                                            value={result}
                                            key={result}
                                            data-testId={`risk-score-option-${result}`}
                                        >
                                            {deSnakify(result)}
                                        </Option>
                                    ))}
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label="Notes">
                                <TextArea
                                    name="notes"
                                    placeholder="Write a note"
                                    autoSize={{ maxRows: 5 }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Action">
                                <SubmitButton
                                    danger={values['result'] === ResultScore.FAIL}
                                    disabled={!isValid || isValidating}
                                    data-testid="submit"
                                >
                                    {submitButtonLabel}
                                </SubmitButton>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                )
            }}
        </Formik>
    )
}

export const AssessmentResultV2Container: FC<React.PropsWithChildren<unknown>> = () => {
    const { company, mutations, isValidating } = useStyxCompany()
    const riskAssessmentV2 = company?.riskAssessment as unknown as RiskAssessmentV2
    const user = useUser()
    const analytics = useAnalytics()

    const checkSubmitIsValid = (
        { score, result, notes }: B4BFormValues,
        formikHelpers: FormikHelpers<B4BFormValues>
    ) => {
        if (!result || !score) {
            message.error('Risk assessment result and score are required')
            return
        }
        onSubmit(result, notes || '', score)
        formikHelpers.setSubmitting(false)
    }

    const onSubmit = async (result: ResultScore, notes: string, score?: RiskScoreV2) => {
        const body: SubmitRiskAssessmentRequestV2 = {
            result,
            notes,
            assessmentDate: dayjs().toISOString(),
            ...(score && { score }),
        }

        try {
            await mutations.submitB4BRiskAssessmentV2(body)
            analytics?.track('Risk Assessment Submitted', {
                companyId: company?.globalId,
                userId: user.id,
            })

            message.success('Successfully submitted risk assessment!')
        } catch (e) {
            message.error(`Failed to submit risk assessment: ${(e as Error).message}`)
        }
    }

    return (
        <>
            <AssessmentResultB4B
                isValidating={isValidating}
                riskAssessment={riskAssessmentV2}
                onSubmit={checkSubmitIsValid}
            />
        </>
    )
}

export default AssessmentResultV2Container
