import { takeLatest, call, put, cancel } from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'
import { notification } from 'antd'

import { actions } from '../slice'
import { actions as userActions } from './slice'
import { actions as employeeActions } from 'store/modules/employees/slice'
import * as api from 'services/auth/users'
import * as employeeApi from 'services/deimos/employees'
import * as cardsApi from 'services/deimos/cards'

import type { User } from 'types/user'
import type { StatusUpdatePayload, VelocityUpdatePayload } from './types'
import type { EmployeeRole } from 'types/employee'

export function* fetchUser({ payload: userId }: { payload: string }): any {
    try {
        yield put(actions.modifiedStatus({ status: 'fetching', api: 'user' }))
        const res = yield call(api.getUser, userId)
        const users = yield res.json()
        const user = users[0]

        yield put(userActions.updatedUser(user))
        yield put(actions.modifiedStatus({ status: 'resolved', api: 'user' }))
    } catch (e) {
        yield put(actions.modifiedStatus({ status: 'error', api: 'user' }))
        yield call(notification.error, {
            message: `Failed to fetch user.`,
            description: (e as Error).message,
        })
    }
}

export function* fetchCards({
    payload: { companyId, employeeId },
}: PayloadAction<{ companyId: string; employeeId: string }>): any {
    try {
        yield put(userActions.setCardsFetchingStatus('fetching'))
        const res = yield call(cardsApi.getCards, companyId, employeeId)
        const cards = yield res.json()

        yield put(userActions.updatedCards(cards))
        yield put(userActions.setCardsFetchingStatus('resolved'))
    } catch (e) {
        yield put(userActions.setCardsFetchingStatus('error'))
        yield call(notification.error, {
            message: `Failed to fetch cards.`,
            description: (e as Error).message,
        })
    }
}

export function* updateCardStatus({ payload }: PayloadAction<StatusUpdatePayload>) {
    try {
        yield call(cardsApi.updateCard, payload.cardId, payload.status)

        yield put(userActions.setCardStatus(payload))
        yield call(notification.info, {
            message: `Card status successfully updated to ${payload.status}`,
        })
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to update card status.`,
            description: (e as Error).message,
        })
    }
}

export function* updateCardVelocity({ payload }: PayloadAction<VelocityUpdatePayload>): any {
    try {
        const res = yield call(cardsApi.updateCard, payload.cardId, undefined, payload.velocity)
        const resolved = yield res.json()

        yield put(
            userActions.setCardVelocity({ cardId: payload.cardId, velocity: resolved.velocity })
        )
        yield call(notification.info, {
            message: `Card velocity successfully updated to ${payload.velocity}`,
        })
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to update card velocity.`,
            description: (e as Error).message,
        })
    }
}

export function* resetPinTries({ payload: cardId }: PayloadAction<string>) {
    try {
        yield call(cardsApi.resetPinTries, cardId)

        yield call(notification.info, {
            message: `Card PIN tries successfully reset.`,
        })
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to reset card PIN tries.`,
            description: (e as Error).message,
        })
    }
}

export function* resendInvite({
    payload: { companyId, employeeId },
}: PayloadAction<{ companyId?: string | null; employeeId: string }>) {
    try {
        if (companyId) {
            yield call(employeeApi.resendInvite, companyId, employeeId)
        }

        yield call(notification.info, {
            message: `Invitation successfully sent.`,
        })
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to send invitation.`,
            description: (e as Error).message,
        })
    }
}

export function* resendChangeEmail({ payload: userId }: PayloadAction<string>) {
    try {
        yield call(api.resendChangeEmail, userId)
        yield call(notification.info, {
            message: `A verification email is sent to the new address.`,
            description:
                'NOTE! Until the user has followed the verification link, the old email is still in effect.',
        })
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to send email change confirmation.`,
            description: (e as Error).message,
        })
    }
}

export function* resetPasscode({ payload: userId }: PayloadAction<string>): any {
    try {
        const res = yield call(api.resetPasscode, userId)
        const resolvedRes = yield res.json()

        if (resolvedRes.error) {
            yield call(notification.error, {
                message: `Failed to send email for reset passcode.`,
            })
            return
        }

        yield call(notification.info, {
            message: `User passcode reset mail has been sent`,
            description:
                'NOTE! The reset passcode token expires after 24 hours, so the user must reset within 24 hours',
        })
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to send email for reset passcode.`,
            description: (e as Error).message,
        })
    }
}

export function* resetPinAndInactivateRefreshToken({
    payload: userId,
}: PayloadAction<string>): any {
    try {
        const res = yield call(api.resetPinAndInactivateRefreshToken, userId)
        const resolvedRes = yield res.json()

        if (resolvedRes.error) {
            yield call(notification.error, {
                message: `Failed`,
            })
            return
        }

        yield call(notification.info, {
            message: `Pin reset and refresh token inactivated`,
        })
    } catch (e) {
        yield call(notification.error, {
            message: `Failed.`,
            description: (e as Error).message,
        })
    }
}

export function* deleteRefreshTokens({ payload: userId }: PayloadAction<string>): any {
    try {
        const res = yield call(api.deleteRefreshTokens, userId)
        const resolvedRes = yield res.json()

        if (resolvedRes.error) {
            yield call(notification.error, {
                message: `Failed`,
            })
            return
        }

        yield call(notification.info, {
            message: `Refresh tokens deleted`,
        })
    } catch (e) {
        yield call(notification.error, {
            message: `Failed.`,
            description: (e as Error).message,
        })
    }
}

export function* resetTwoFactor({ payload: userId }: PayloadAction<string>): any {
    try {
        const res = yield call(api.resetTwoFactor, userId)

        const resolvedRes = yield res.json()

        if (resolvedRes.error) {
            yield call(notification.error, {
                message: `Failed to send email for reset two-factor.`,
            })
            return
        }

        yield call(notification.info, {
            message: `User two-factor reset mail has been sent`,
            description:
                'NOTE! The reset two-factor token expires after 24 hours, so the user must reset within 24 hours.',
        })
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to send email for reset two-factor.`,
            description: (e as Error).message,
        })
    }
}

export function* changeEmail({
    payload: { userId, oldEmail, newEmail },
}: PayloadAction<{ userId: string; oldEmail: string; newEmail: string }>): any {
    try {
        const res = yield call(api.changeEmail, userId, newEmail)
        const resolvedRes = yield res.json()

        if (resolvedRes.success) {
            if (oldEmail === newEmail) {
                yield put(userActions.updatedChangeEmail(''))
                yield put(userActions.updatedEmail(oldEmail))
                yield call(notification.info, {
                    message: `User email changed back to the current email.`,
                    description:
                        'NOTE! The verification link in the new email is no longer in effect.',
                })
            } else {
                yield put(userActions.updatedChangeEmail(newEmail))
                yield call(notification.info, {
                    message: `User email changed. A verification email is sent to the new address.`,
                    description:
                        'NOTE! Until the user has followed the verification link, the old email is still in effect.',
                })
            }
        }
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to update email.`,
            description: (e as Error).message,
        })
    }
}

export function* changeRole({
    payload,
}: PayloadAction<{ userId: string; employeeId: string; role: EmployeeRole }>) {
    try {
        yield call(employeeApi.updateEmployeeRole, payload.employeeId, payload.role)

        yield call(notification.info, {
            message: `Successfully changed role to '${payload.role}'.`,
        })
        yield put(userActions.updatedRole(payload.role))
        yield put(
            employeeActions.changedEmployeeRole({
                role: payload.role,
                employeeId: payload.employeeId,
            })
        )
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to change role.`,
            description: (e as Error).message,
        })
    }
}

export function* deactivateEmployee({
    payload,
}: PayloadAction<{ employeeId: string; email: string }>): any {
    try {
        const res = yield call(employeeApi.deactivateEmployee, payload.employeeId)
        const resolvedRes = yield res.json()

        if (resolvedRes.success) {
            yield call(notification.info, {
                message: `Successfully deleted user.`,
            })
        }
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to delete user.`,
            description: (e as Error).message,
        })
    }
}

export function* setUserAdmin({ payload: { userId } }: PayloadAction<{ userId: string }>): any {
    try {
        const res = yield call(api.setUserAdmin, userId)
        const { success }: { success: boolean } = yield res.json()

        if (success) {
            yield put(userActions.enableUserAdmin())
        }
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to grant backoffice access.`,
            description: (e as Error).message,
        })
    }
}

export function* unsetUserAdmin({ payload: { userId } }: PayloadAction<{ userId: string }>): any {
    try {
        const res = yield call(api.unsetUserAdmin, userId)
        const resolvedRes = yield res.json()

        if (resolvedRes.success) {
            yield call(notification.info, {
                message: `Successfully removed backoffice access.`,
            })
            yield put(userActions.revokeUserAdmin())
        }
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to remove backoffice access.`,
            description: (e as Error).message,
        })
    }
}

export function* setUserAdminPermission({
    payload: { userId, resourceId },
}: PayloadAction<{ userId: string; resourceId: string }>) {
    try {
        yield call(api.setUserAdminPermission, userId, resourceId)
        yield put(userActions.setPermission(resourceId))
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to set user permission.`,
            description: (e as Error).message,
        })
    }
}

export function* unsetUserAdminPermission({
    payload: { userId, resourceId },
}: PayloadAction<{ userId: string; resourceId: string }>) {
    try {
        yield call(api.unsetUserAdminPermission, userId, resourceId)
        yield put(userActions.unsetPermission(resourceId))
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to unset user permission.`,
            description: (e as Error).message,
        })
    }
}

export function* unsetUserAdminIfConfirmationFailed({
    payload: { user },
}: PayloadAction<{ user: User }>): any {
    if (user.admin && user.permissions.includes('admin')) {
        yield cancel()
    }
    let numberOfTries = 5
    let resolvedRes = { success: false }
    while (!resolvedRes.success && numberOfTries) {
        try {
            const res = yield call(api.unsetUserAdmin, user.id)
            resolvedRes = yield res.json()
        } catch (e) {
            numberOfTries--
        }
    }
}

export function* enableCardAccess({
    payload: { employeeId },
}: PayloadAction<{ employeeId: string }>) {
    try {
        yield call(employeeApi.setCardAccess, employeeId)
        yield put(userActions.enabledCardAccess(employeeId))
    } catch (e) {
        yield call(notification.error, {
            message: `Failed to enable card access permission.`,
            description: (e as Error).message,
        })
    }
}
export function* supportUserSaga() {
    yield takeLatest(userActions.fetchUser, fetchUser)
    yield takeLatest(userActions.fetchCards, fetchCards)
    yield takeLatest(userActions.updateCardStatus, updateCardStatus)
    yield takeLatest(userActions.updateCardVelocity, updateCardVelocity)
    yield takeLatest(userActions.resetPinTries, resetPinTries)
    yield takeLatest(userActions.resetPasscode, resetPasscode)
    yield takeLatest(userActions.resetTwoFactor, resetTwoFactor)
    yield takeLatest(userActions.resendChangeEmail, resendChangeEmail)
    yield takeLatest(userActions.resendInvite, resendInvite)
    yield takeLatest(userActions.changeEmail, changeEmail)
    yield takeLatest(userActions.changeRole, changeRole)
    yield takeLatest(userActions.deleteEmployee, deactivateEmployee)
    yield takeLatest(userActions.setUserAdmin, setUserAdmin)
    yield takeLatest(userActions.unsetUserAdmin, unsetUserAdmin)
    yield takeLatest(userActions.setUserAdminPermission, setUserAdminPermission)
    yield takeLatest(userActions.unsetUserAdminPermission, unsetUserAdminPermission)
    yield takeLatest(
        userActions.unsetUserAdminIfConfirmationFailed,
        unsetUserAdminIfConfirmationFailed
    )
    yield takeLatest(userActions.enableCardAccess, enableCardAccess)
    yield takeLatest(userActions.deleteToken, deleteRefreshTokens)
}
