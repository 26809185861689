import { createSlice, createAction, PayloadAction, Draft } from '@reduxjs/toolkit'

import type { PaginatedResponse, Supplier, SupplierInvoice } from 'types/deimos-supplier'
import type { SuppliersState } from 'store/modules/state'
import type { UpdateSupplierPayload, UpdateSupplierStatusPayload } from 'services/deimos/suppliers'
import type { CursorPaginationCommand } from 'types/cursor-pagination'
import { parseSorting } from 'services/deimos/get-cursor-paginated-resource'
import { PageOrder } from '@pleo-io/deimos'

const urlParams = new URLSearchParams(window.location.search)
const urlLimit = urlParams.get('limit')
const defaultSupplierStatusSelection = ['']
export const initialState: SuppliersState = {
    suppliers: [],
    suppliersStatus: 'default',
    suppliersRequestParams: {
        before: urlParams.get('before') ?? undefined,
        after: urlParams.get('after') ?? undefined,
        sorting: urlParams.has('sorting')
            ? parseSorting(urlParams.get('sorting') ?? '')
            : [{ id: PageOrder.DESC }],
        limit: urlLimit ? parseInt(urlLimit ?? '10', 10) : 10,
        parameters: {
            status: urlParams.get('status')?.split(',') ?? defaultSupplierStatusSelection,
        },
    },
    suppliersPagination: null,

    currentSupplier: null,
    currentSupplierStatus: 'default',

    currentSupplierInvoices: [],
    currentSupplierInvoicesStatus: 'default',
    currentSupplierInvoicesRequestParams: {
        sorting: [{ id: PageOrder.DESC }],
        limit: 10,
        parameters: {},
    },
    currentSupplierInvoicesPagination: null,
}

const fetchSupplier = createAction<string>('searchSupplier/fetchSupplier')
const fetchSuppliers = createAction<CursorPaginationCommand | undefined>(
    'searchSupplier/fetchSuppliers'
)
const fetchSupplierInvoices = createAction<{
    supplierId: string
    command?: CursorPaginationCommand
}>('searchSupplier/fetchSupplierInvoices')
const updateSupplier = createAction<{
    supplierId: string
    data: UpdateSupplierPayload
}>('searchSupplier/updateSupplier')
const updateSupplierStatus = createAction<{
    supplierId: string
    data: UpdateSupplierStatusPayload
}>('searchSupplier/updateSupplierStatus')

const { actions: generatedActions, reducer: generatedReducer } = createSlice({
    name: 'suppliers',
    initialState,
    reducers: {
        resettingSuppliers: (state) => {
            state.suppliersRequestParams = initialState.suppliersRequestParams
        },
        settingSuppliers: (state, { payload }: PayloadAction<PaginatedResponse<Supplier>>) => {
            state.suppliers = payload.data
            state.suppliersPagination = payload.pagination
            state.suppliersRequestParams = payload.pagination.currentRequestPagination
            state.suppliersStatus = 'resolved'
        },
        settingCurrentSupplier: (
            state: Draft<SuppliersState>,
            { payload }: PayloadAction<Supplier>
        ) => {
            if (state.currentSupplier?.id !== payload.id) {
                state.currentSupplierInvoices = []
                state.currentSupplierInvoicesPagination = null
            }

            state.currentSupplier = payload
            state.currentSupplierStatus = 'resolved'
        },
        settingCurrentSupplierInvoices: (
            state: Draft<SuppliersState>,
            { payload }: PayloadAction<PaginatedResponse<SupplierInvoice>>
        ) => {
            state.currentSupplierInvoices = payload.data
            state.currentSupplierInvoicesPagination = payload.pagination
            state.currentSupplierInvoicesRequestParams = payload.pagination.currentRequestPagination
            state.currentSupplierInvoicesStatus = 'resolved'
        },
        startingFetchingSuppliers: (state) => {
            state.suppliersStatus = 'fetching'
        },
        startingFetchingCurrentSupplier: (state) => {
            state.currentSupplierStatus = 'fetching'
        },
        startingFetchingCurrentSupplierInvoices: (state) => {
            state.currentSupplierInvoicesStatus = 'fetching'
        },
        failingFetchingSuppliers: (state) => {
            state.suppliersStatus = 'error'
        },
        failingFetchingCurrentSupplier: (state) => {
            state.currentSupplierStatus = 'error'
        },
        failingFetchingCurrentSupplierInvoices: (state) => {
            state.currentSupplierInvoicesStatus = 'error'
        },
        clearSupplier: (state) => {
            state.currentSupplier = null
            state.currentSupplierStatus = 'default'
        },
    },
})

export const suppliersReducer = generatedReducer
export const actions = {
    ...generatedActions,
    fetchSupplier,
    updateSupplier,
    fetchSuppliers,
    fetchSupplierInvoices,
    updateSupplierStatus,
}
