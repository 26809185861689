import type { FC } from 'react'
import type { GetBillInvoiceResponse } from '@pleo-io/deimos'
import { Table, Typography, Button, Input } from 'antd'
import {
    BillInvoicePaymentAttempt,
    useBillInvoicePaymentAttempts,
} from 'services/deimos/bill-invoice'

import type { ColumnProps, ColumnType } from 'antd/lib/table'
import { SearchOutlined } from '@ant-design/icons'
import dayjs from 'packages/dayjs'
const { Text } = Typography
export const BillInvoicePaymentAttempts: FC<{
    invoice?: GetBillInvoiceResponse
}> = ({ invoice }) => {
    const { data, isValidating } = useBillInvoicePaymentAttempts(invoice?.id)

    const dataSource = data?.map((obj) => ({ ...obj, key: obj.id }))

    const columns: ColumnProps<any>[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (name) => <Text>{name}</Text>,
            ...tableColumnTextFilterConfig<BillInvoicePaymentAttempt>(),
            onFilter: (value, record) => {
                return record.id.toString().toLowerCase().includes(value.toString().toLowerCase())
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (name) => <Text>{name}</Text>,
            width: '38%',
        },
        {
            title: 'Status Reason',
            dataIndex: 'statusReason',
            render: (name) => <Text>{name}</Text>,
        },
        {
            title: 'Attempt created at',
            dataIndex: 'updatedAt',
            render: (name) => <Text>{dayjs(name).format('DD-MM-YY HH:mm')}</Text>,
        },
    ]

    function tableColumnTextFilterConfig<T>(): ColumnType<T> {
        const searchInputHolder: { current: any | null } = { current: null }

        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input
                            ref={(node) => (searchInputHolder.current = node)}
                            placeholder={'Search ID'}
                            value={selectedKeys[0]}
                            onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            onPressEnter={() => confirm()}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90, marginRight: 8 }}
                        >
                            Search
                        </Button>
                        <Button size="small" style={{ width: 90 }} onClick={clearFilters}>
                            Reset
                        </Button>
                    </div>
                )
            },
            filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInputHolder.current?.select())
                }
            },
        }
    }
    return (
        <Table
            data-testid="attempts-table"
            dataSource={dataSource}
            columns={columns}
            loading={isValidating}
            rowKey="id"
            pagination={false}
            scroll={{
                y: 240,
            }}
        />
    )
}
