import styled from 'styled-components'
import type { FC } from 'react'
import { useAmlAnswers } from 'services/deimos/styx-company/styx-company'
import { Card, Descriptions } from 'antd'
import { ContentContainer } from 'components/layout-containers'
import { snakeCaseToTitleCase, deSnakify } from 'utils/strings'
import { AmlCountries } from './aml-countries'
import { AmlAverageAmountRanges } from 'pages/compliance/company/compliance-info/components/aml-average-amount-ranges'
import { categoriesOptions } from 'services/deimos/accounting-categories'
import { amlAndBillsAnswers } from 'config'
import { BillsAmlMaxSupplierPerMonth, BillsAmlUsage } from '@pleo-io/deimos'
import type { AmountRange } from 'bff/moons/generated/styx-v1'
import { bff } from 'pages/compliance/bff'

export const AML = ({ companyId }: { companyId: string }) => {
    return (
        <AmlContainer>
            <AmlAndBillsAmlAnswers companyId={companyId} />
        </AmlContainer>
    )
}

const AmlContainer = styled.div`
    margin-bottom: 1rem;
`
interface Props {
    companyId: string
}

export const AmlAndBillsAmlAnswers: FC<React.PropsWithChildren<Props>> = ({ companyId }) => {
    const { data: amlAnswers } = useAmlAnswers(companyId)
    const { data } = bff.aml.getData.useQuery({ companyId })
    const billsAmlAnswers = data?.billsAmlAnswers

    return (
        <Card title="AML and Invoices AML answers">
            <ContentContainer>
                <Descriptions
                    bordered
                    size="small"
                    title="AML"
                    column={1}
                    contentStyle={{ width: '80%' }}
                >
                    <Descriptions.Item label="Categories" span={2}>
                        {formatCategories(amlAnswers?.categories)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Expected spend">
                        {formatExpectedAmount(amlAnswers?.amountRange)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Continents">
                        {amlAnswers?.continents && amlAnswers?.continents.length !== 0
                            ? amlAnswers.continents
                                  .map((continent) => snakeCaseToTitleCase(continent))
                                  .join(', ')
                            : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Countries frequently used in">
                        <AmlCountries companyId={companyId} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Anticipated value of transactions per month">
                        <AmlAverageAmountRanges companyId={companyId} />
                    </Descriptions.Item>
                </Descriptions>

                <Descriptions bordered size="small" title="Bills AML" column={3}>
                    <Descriptions.Item label="Is recurring">
                        {formatBoolean(billsAmlAnswers?.isRecurring)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Usage">
                        {formatUsage(billsAmlAnswers?.usage)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Highest payment">
                        {formatHighestPayment(billsAmlAnswers?.highestPayment)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Suppliers per month">
                        {formatSuppliersPerMonth(billsAmlAnswers?.maxSuppliersPerMonth)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Payments outside EU / UK">
                        {formatBoolean(billsAmlAnswers?.paymentsOutsideUkAndEu)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Countries outside EU / UK">
                        {billsAmlAnswers?.countriesOutsideUkAndEu || '-'}
                    </Descriptions.Item>
                </Descriptions>
            </ContentContainer>
        </Card>
    )
}

export const formatCategories = (categories?: string[]) => {
    if (!categories || categories.length === 0) return '-'

    const nameCategories = categories.map((category) => categoriesOptions.get(category))

    return nameCategories.join(', ')
}

export const formatExpectedAmount = (amountRange?: AmountRange) => {
    if (!amountRange) return '-'

    const currency = amountRange.currency
    const min = amountRange.minimum
    const max = amountRange.maximum

    if (!min && !max) return '-'
    if (!max) return `${currency} ≥ ${min?.toLocaleString()}`
    if (!min) return `${currency} ≤ ${max.toLocaleString()}`

    return `${currency} ${min.toLocaleString()}-${max.toLocaleString()}`
}

export const formatUsage = (usage?: string) => {
    if (!usage) return '-'
    if (usage === BillsAmlUsage.BOTH) return 'Goods and services'

    return deSnakify(usage)
}

export const formatHighestPayment = (highestPayment?: string) => {
    if (!highestPayment) return '-'

    const lst = highestPayment.split('_')

    if (Number(lst[1])) return `${lst[0]} ${Number(lst[1]).toLocaleString()}`
    return `${lst[0]} > ${amlAndBillsAnswers.highestPaymentThreshold.toLocaleString()}`
}

export const formatBoolean = (boolean?: boolean) => {
    if (boolean === true) return 'Yes'
    if (boolean === false) return 'No'
    return '-'
}

export const formatSuppliersPerMonth = (maxSuppliersPerMonth?: string) => {
    if (!maxSuppliersPerMonth) return '-'

    const lst = maxSuppliersPerMonth.split('_')

    if (maxSuppliersPerMonth === BillsAmlMaxSupplierPerMonth.MORE_THAN_10)
        return `> ${lst[lst.length - 1]}`
    return `≤ ${lst[lst.length - 1]}`
}
