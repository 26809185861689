import type { FC } from 'react'
import { Collapse } from 'antd'

import Employees from 'components/employees/employees'

import Documents from './documents/documents'
import People from './people/people'
import RiskAssessment from './risk-assessment/risk-assessment'
import Ownership from './ownership/ownership'
import Subscription from 'pages/customer-success/company/billing/subscription/subscription'
import SourcesOfFunds from './sources-of-funds/sources-of-funds'
import { Entitlements } from 'pages/compliance/company/entitlements'
import { ComplianceInfo } from './compliance-info/compliance-info'
import { KycOverview } from './kyc-overview/kyc-overview'
import { peopleSectionLabel } from './utils'
import { ProofOfBusiness } from 'pages/compliance/company/proof-of-business/proof-of-business'
import { StpCheck } from './stp-check/stp-check'
import { OddCases } from './odd-cases/odd-cases'

const { Panel } = Collapse

const CompanyCollapse: FC<
    React.PropsWithChildren<{
        companyId: string
        companyCountry?: string
        onboardingSource?: string
    }>
> = ({ companyId, companyCountry, onboardingSource }) => {
    return (
        <Collapse defaultActiveKey={['documents', 'people', 'risk-assessment']}>
            <Panel header="STP Check" key="stp-check">
                <StpCheck companyId={companyId} />
            </Panel>
            <Panel header="KYC Overview" key="kyc-overview">
                <KycOverview companyId={companyId} />
            </Panel>
            <Panel header="ODD Cases" key="odd-cases">
                <OddCases />
            </Panel>
            <Panel header="Compliance Info" key="compliance-info">
                <ComplianceInfo companyId={companyId} />
            </Panel>
            <Panel header="Proof of Business" key="proof-of-business">
                <ProofOfBusiness companyId={companyId} />
            </Panel>
            <Panel header="Employees" key="employees">
                <Employees companyId={companyId} />
            </Panel>
            <Panel header="Sources of funds" key="sources-of-funds">
                <SourcesOfFunds companyId={companyId} />
            </Panel>
            <Panel header="Ownership Structure" key="ownership">
                <Ownership />
            </Panel>
            <Panel header="Subscription" key="subscription">
                <Subscription />
            </Panel>
            <Panel header="Documents" key="documents">
                <Documents companyId={companyId} />
            </Panel>
            <Panel header={peopleSectionLabel(onboardingSource)} key="people">
                <People companyId={companyId} companyCountry={companyCountry} />
            </Panel>
            <Panel header="Risk Assessment" key="risk-assessment">
                <RiskAssessment />
            </Panel>
            <Panel header="Entitlements" key="entitlements">
                <Entitlements companyId={companyId} />
            </Panel>
        </Collapse>
    )
}

export default CompanyCollapse
