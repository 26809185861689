import qs from 'qs'
import request from './request'

interface GetUserQuery {
    email?: string
    userId?: string
}

export async function getUsers({ email, userId }: GetUserQuery) {
    if (!email && !userId) {
        return []
    }
    return await request().get(`api/v1/admin/users?${qs.stringify({ query: email, userId })}`)
}
