import React, { useState, FC, useEffect } from 'react'
import { Select, Typography, message } from 'antd'
import type { SizeType } from 'antd/lib/config-provider/SizeContext'

import { useDebounce } from 'utils/use-debounce'

import { bff } from '../bff-hooks'

const { Option } = Select
const { Text } = Typography

interface Props {
    country: string
    handleSearchChange: (id: string) => void
    size?: SizeType
}

const RegistrySearch: FC<React.PropsWithChildren<Props>> = ({
    country,
    handleSearchChange,
    size,
}) => {
    const [query, setQuery] = useState<string>('')
    const debouncedQuery = useDebounce(query, 500)

    const { data: searchResults, error } =
        bff.components.registrySearch.getCompanySearchResults.useQuery({
            country,
            searchTerm: debouncedQuery,
        })

    useEffect(() => {
        if (error) {
            message.error(error.message)
        }
    }, [error])

    return (
        <Select
            size={size}
            optionFilterProp="label"
            placeholder="Search for a company"
            showArrow={false}
            filterOption={false}
            defaultActiveFirstOption={false}
            showSearch
            onSearch={setQuery}
            onChange={handleSearchChange}
            style={{ width: '100%' }}
        >
            {searchResults
                // Vembi can produce search results that are lacking sufficient info, so we filter those based on missing id
                ?.filter(({ id }) => !!id)
                .map(({ legalName, id }) => (
                    <Option label={legalName} key={id} value={id!} disabled={!id}>
                        <Text>{legalName}</Text>,{' '}
                        <Text type="secondary">Company Reg. No. {id}</Text>
                    </Option>
                ))}
        </Select>
    )
}

export default RegistrySearch
