import React, { FC, useState } from 'react'
import { SettlementModel, SettlementStatusModel } from '@pleo-io/deimos'
import { Tag, Typography, Button, Space, Modal, Descriptions, Tooltip, Input, message } from 'antd'
import type { PresetStatusColorType } from 'antd/lib/_util/colors'
import { deSnakify } from 'utils/strings'
import formatCurrency from 'utils/format-currency'
import { EditOutlined } from '@ant-design/icons'

const { Text } = Typography
const { TextArea } = Input

const getStatusColour = (status: SettlementStatusModel): PresetStatusColorType => {
    switch (status) {
        case SettlementStatusModel.FAILED:
            return 'error'
        case SettlementStatusModel.PAID:
        case SettlementStatusModel.PAID_MANUALLY:
            return 'success'
        case SettlementStatusModel.MANUAL_PAYMENT_REQUIRED:
            return 'warning'
        case SettlementStatusModel.NOT_RECEIVED:
        case SettlementStatusModel.PENDING:
        case SettlementStatusModel.SCHEDULED:
            return 'processing'
        case SettlementStatusModel.SKIPPED:
        case SettlementStatusModel.CANCELLED:
        default:
            return 'default'
    }
}

const AddNote: FC<React.PropsWithChildren<{ onClick: () => void; note?: string }>> = ({
    onClick,
    note,
}) => (
    <Tooltip title={note || 'Add a note'}>
        <EditOutlined onClick={onClick} />
    </Tooltip>
)

const SettlementModalContent: FC<React.PropsWithChildren<{ settlement: SettlementModel }>> = ({
    settlement,
}) => (
    <Descriptions column={1}>
        <Descriptions.Item label="Currency">{settlement.currency}</Descriptions.Item>
        <Descriptions.Item label="Provider">{settlement.paymentProvider}</Descriptions.Item>
        <Descriptions.Item label="Amount">{settlement.amount ?? '-'}</Descriptions.Item>
    </Descriptions>
)

interface Props {
    settlement: SettlementModel
    onCancelSettlement: () => Promise<any>
    onMarkAsPaidManually: () => Promise<any>
    onSetNote: (note: string) => Promise<any>
}

const Settlement: FC<React.PropsWithChildren<Props>> = ({
    settlement,
    onCancelSettlement,
    onMarkAsPaidManually,
    onSetNote,
}) => {
    const [note, setNote] = useState(settlement.note ?? '')
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const status = settlement.status
    const cancellable =
        status === SettlementStatusModel.NOT_RECEIVED ||
        status === SettlementStatusModel.SCHEDULED ||
        status === SettlementStatusModel.MANUAL_PAYMENT_REQUIRED

    const canMarkPaid = status === SettlementStatusModel.MANUAL_PAYMENT_REQUIRED
    const openModal = () => setVisible(true)
    const closeModal = () => setVisible(false)

    const handleSetNote = async () => {
        try {
            setLoading(true)
            await onSetNote(note)
            closeModal()
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setLoading(false)
        }
    }
    return (
        <>
            <Space direction="vertical">
                <span>
                    <Text type="secondary">
                        {formatCurrency(settlement.amount ?? 0, settlement.currency)}
                    </Text>{' '}
                    <AddNote note={note} onClick={openModal} />
                </span>
                <Tag color={getStatusColour(status)}>{deSnakify(status)}</Tag>
                <div>
                    <Space direction="horizontal">
                        <Button
                            disabled={!canMarkPaid}
                            size="small"
                            onClick={() =>
                                Modal.confirm({
                                    title: 'Confirm Settlement is Manually Paid',
                                    content: <SettlementModalContent settlement={settlement} />,
                                    centered: true,
                                    onOk: onMarkAsPaidManually,
                                    okButtonProps: { danger: true },
                                    okText: 'Confirm',
                                })
                            }
                        >
                            Mark Paid
                        </Button>
                        <Button
                            disabled={!cancellable}
                            size="small"
                            onClick={() =>
                                Modal.confirm({
                                    title: 'Confirm Settlement Cancellation',
                                    content: <SettlementModalContent settlement={settlement} />,
                                    centered: true,
                                    onOk: onCancelSettlement,
                                    okButtonProps: { danger: true },
                                    okText: 'Confirm',
                                })
                            }
                        >
                            Cancel
                        </Button>
                    </Space>
                </div>
            </Space>

            <Modal
                open={visible}
                title="Add note"
                confirmLoading={loading}
                onOk={handleSetNote}
                onCancel={closeModal}
            >
                <TextArea
                    autoSize
                    autoFocus
                    placeholder="Add a note"
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                        setNote(event.target.value)
                    }
                    value={note}
                />
            </Modal>
        </>
    )
}

export default Settlement
