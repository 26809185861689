export interface ManualFlagRequest {
    category: FlagCategory
    transactionId: string
    notes?: string
    investigationStatus: InvestigationStatus
    family: TransactionFamily
}

export interface MultipleManualFlags {
    category: FlagCategory
    transactionIds: string[]
    notes?: string
    investigationStatus: InvestigationStatus
    family: TransactionFamily
}

export enum InvestigationStatus {
    NEW = 'NEW',
    FALSE_POSITIVE = 'FALSE_POSITIVE',
    REQ_INVESTIGATION = 'REQ_INVESTIGATION',
    FRAUD = 'FRAUD',
    NOT_REQUIRED = 'NOT_REQUIRED',
}
export enum TransactionFamily {
    BILL = 'BILL',
    CARD = 'CARD',
    PERSONAL_TRANSFER = 'PERSONAL_TRANSFER',
    EMPLOYEE_BANK_TRANSFER = 'EMPLOYEE_BANK_TRANSFER',
    WALLET_WITHDRAWAL = 'WALLET_WITHDRAWAL',
}
export enum FlagCategory {
    CARD_BALANCE_ALTERATION = 'CARD_BALANCE_ALTERATION',
    WALLET_BALANCE_ALTERATION = 'WALLET_BALANCE_ALTERATION',
    AML = 'AML',
    CARD_FRAUD = 'CARD_FRAUD',
    MANUAL_KEY_ENTRY = 'MANUAL_KEY_ENTRY',
    CHIP_AND_SIGNATURE = 'CHIP_AND_SIGNATURE',
    COMPLIANCE = 'COMPLIANCE',
    BILL_FRAUD = 'BILL_FRAUD',
    PERSONAL_TRANSFER_FRAUD = 'PERSONAL_TRANSFER_FRAUD',
    EMPLOYEE_BANK_TRANSFER_FRAUD = 'EMPLOYEE_BANK_TRANSFER_FRAUD',
    WALLET_WITHDRAWAL_FRAUD = 'WALLET_WITHDRAWAL_FRAUD',
}

export interface DetectedFraud {
    id: string
    transactionId: string
    createdAt: string
    updatedAt: string
    triggeredRules: TriggeredRuleWithScore[]
    categories: FlagCategory[]
    investigationStatus: InvestigationStatus | null
    investigationOfficer: string | null
    notes?: string
    transactionFamily: TransactionFamily
}

export interface TriggeredRuleWithScore {
    ruleName: string
    score?: number
}
