import type { FC } from 'react'

import { Content, Header, Layout, Sider } from 'pages/page-layout-components'
import { Container, Divider, LogoArea } from 'components/sidebar-title/sidebar-title'
import { useColorScheme } from 'hooks/use-color-scheme'

export const PlaceholderPage: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { colorScheme } = useColorScheme()
    return (
        <Layout $colorScheme={colorScheme}>
            <Sider theme="dark" $colorScheme={colorScheme} width={240}>
                <Container>
                    <LogoArea />
                    <Divider />
                </Container>
            </Sider>
            <Header $colorScheme={colorScheme} />
            <Content>{children}</Content>
        </Layout>
    )
}
