import type { FC } from 'react'
import { Collapse, Empty, Result } from 'antd'

import Skeleton from 'components/content-skeleton'
import { isEmpty } from 'lodash'
import { CompanyShareholderHeader } from 'pages/compliance/company/people/company-shareholders/company-shareholder-header'
import { bff } from 'pages/compliance/bff'
import { companyShareholderSharePercentageThreshold } from 'services/styx/ownership'
import type { CompanyStakeholderResponse } from 'bff/moons/generated/styx-v1'
import { CompanyShareholderDetails } from './company-shareholder-details'

interface Props {
    companyId: string
}

const { Panel } = Collapse

export const CompanyShareholderList: FC<React.PropsWithChildren<Props>> = ({ companyId }) => {
    const { data, error, isLoading } =
        bff.significantCompanyShareholder.getSignificantCompanyShareholders.useQuery({
            companyId: companyId,
            sharePercentageThreshold: companyShareholderSharePercentageThreshold,
        })

    const shareholders = data as CompanyStakeholderResponse[]

    if (error) {
        return (
            <Result
                title="Could not fetch company shareholders"
                subTitle={error.message}
                status="error"
            />
        )
    }

    if (!shareholders && isLoading) {
        return <Skeleton data-testid="skeleton" />
    }

    if (isEmpty(shareholders)) {
        return (
            <Empty description="There are no significant company shareholders currently associated with this company." />
        )
    }

    return (
        <Collapse>
            {shareholders.map((shareholder) => {
                return (
                    <Panel
                        key={shareholder.id}
                        header={<CompanyShareholderHeader shareholder={shareholder} />}
                    >
                        <CompanyShareholderDetails shareholder={shareholder} />
                    </Panel>
                )
            })}
        </Collapse>
    )
}

export default CompanyShareholderList
