import * as Yup from 'yup'

export const vulnerabilityValidationSchema = Yup.object().shape({
    vulnerabilityTypeId: Yup.string().required('Type is required'),
    notes: Yup.string().notRequired(),
})

export const revokeValidationSchema = Yup.object().shape({
    reason: Yup.string().required('Reason is required'),
})

export type VulnerabilityFormValues = Yup.InferType<typeof vulnerabilityValidationSchema>
export type RevokeFormValues = Yup.InferType<typeof revokeValidationSchema>
