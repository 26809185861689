import { Button, Result } from 'antd'
import { deSnakify } from 'utils/strings'

interface CompanyErrorResultProps {
    errorCode?: string
    errorMessage?: string
    goBack: () => void
}

const ClosedCompanyErrorResult = ({ errorCode, errorMessage, goBack }: CompanyErrorResultProps) => {
    if (errorCode === 'NOT_FOUND') {
        return (
            <Result
                status="404"
                title="Company not found"
                extra={<Button onClick={goBack}>Go back</Button>}
            />
        )
    } else {
        return (
            <Result
                status="500"
                title="Something went wrong"
                subTitle={errorMessage && deSnakify(errorMessage)}
                extra={<Button onClick={goBack}>Go back</Button>}
            />
        )
    }
}

export default ClosedCompanyErrorResult
