// @ts-check
import { config as productDevConfig } from './config.product-dev.mjs'

// To override the local developer configuration, create a config.local.json
// file in the project root. Learn more in the README
/**
 * @type {import("./config.type").Config}
 */
export const config = {
    ...productDevConfig,
    environment: 'local',
    version: '__LOCAL__',
    endpoints: {
        ...productDevConfig.endpoints,
        bff: 'http://localhost:2023',
    },
}
