import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'router/protected-route'

import ExportsPage from './exports'

export default function Exports() {
    return (
        <Routes>
            <Route element={<ProtectedRoute />}>
                <Route path="/" element={<ExportsPage />} />
                <Route path="/:companyId" element={<ExportsPage />} />
            </Route>
        </Routes>
    )
}
