import { Table, message } from 'antd'
import React, { FC, useEffect } from 'react'

import { getPartnerEmployeeName, getUserDisplayRoleByUserRole } from 'utils/partner-employees'
import type { ColumnProps } from 'antd/lib/table'
import { PartnerEmployee, PartnerDisplayRole } from 'types/employee'
import type { SortOrder } from 'antd/lib/table/interface'
import { useGetDeactivatedPartnerEmployees } from 'services/calypso/partner'
import dayjs from 'packages/dayjs'

import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { withPermissionGuard } from 'components/permission-guard/permission-guard'
import { BreakText } from 'components/layout-containers'

interface Props {
    partnerId: string
}

export const DeactivatedPartnerEmployees: FC<React.PropsWithChildren<Props>> = ({ partnerId }) => {
    const {
        data: employeesData,
        error,
        isValidating,
    } = useGetDeactivatedPartnerEmployees(partnerId) || []

    useEffect(() => {
        if (error) {
            message.error(error.message)
        }
    }, [error])

    const columns: ColumnProps<PartnerEmployee>[] = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            sorter: (a: PartnerEmployee, b: PartnerEmployee) =>
                getPartnerEmployeeName(a).localeCompare(getPartnerEmployeeName(b)),
            defaultSortOrder: 'ascend' as SortOrder,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            filters: Object.values(PartnerDisplayRole).map((value) => ({
                value,
                text: value,
            })),
            onFilter: (value, employee) => getUserDisplayRoleByUserRole(employee.role) === value,
            render: (role: PartnerDisplayRole) => <span>{getUserDisplayRoleByUserRole(role)}</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (email) => <BreakText>{email}</BreakText>,
        },
        {
            title: 'Deactivated at',
            dataIndex: 'status',
            render: (status) => (status ? dayjs(status.deactivated).format('lll') : ''),
        },
    ]
    return (
        <Content>
            <Table
                dataSource={employeesData}
                columns={columns}
                loading={isValidating}
                rowKey="id"
                size="small"
            />
        </Content>
    )
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space24};
`

export default withPermissionGuard(DeactivatedPartnerEmployees, ['company-role'])
