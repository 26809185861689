import React, { FC, useState } from 'react'
import { Button, Checkbox as AntCheckbox, Modal, Space, Table } from 'antd'
import * as Yup from 'yup'
import { Form, Input, Checkbox } from 'formik-antd'
import { Formik } from 'formik'
import styled from 'styled-components'
import { ActionsContainer, ContentContainer } from 'components/layout-containers'
import type { ColumnProps } from 'antd/es/table'

import { bff as complianceBff } from '../../bff/bff'
import type {
    StrictCompanyMerchantBlocklistRequest,
    StrictCompanyMerchantBlocklistResponse,
} from '../../../../bff/moons/generated/ariel'

const validationSchema = Yup.object()
    .shape({
        exactEntry: Yup.bool(),
        merchantName: Yup.string().nullable(),
        merchantId: Yup.string().nullable(),
        companyId: Yup.string().uuid(),
        notes: Yup.string(),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface FormProps {
    onSubmit: (values: FormValues) => void
    merchant?: StrictCompanyMerchantBlocklistRequest | null
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}
const MerchantForm: FC<React.PropsWithChildren<FormProps>> = ({ onSubmit, merchant }) => {
    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
                exactEntry: merchant?.exactEntry ?? false,
                merchantName: merchant?.merchantNameRegex ?? '',
                merchantId: merchant?.merchantId ?? '',
                companyId: merchant?.companyId,
                notes: merchant?.notes ?? '',
            }}
        >
            <Form {...layout}>
                <Form.Item name="exactEntry" label="Exact entry">
                    <Checkbox name="exactEntry" />
                </Form.Item>
                <Form.Item name="merchantName" label="Merchant Name Regex">
                    <Input name="merchantName" placeholder="Merchant Name Regex" />
                </Form.Item>
                <Form.Item name="merchantId" label="Merchant ID">
                    <Input name="merchantId" placeholder="Merchant ID" />
                </Form.Item>
                <Form.Item name="companyId" label="Company ID">
                    <Input name="companyId" placeholder="Company ID" />
                </Form.Item>
                <Form.Item name="notes" label="Notes">
                    <Input.TextArea name="notes" placeholder="Notes" />
                </Form.Item>
                <RowReverse>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </RowReverse>
            </Form>
        </Formik>
    )
}

const RowReverse = styled.div`
    display: flex;
    justify-content: flex-end;
`

interface Props {
    merchants: StrictCompanyMerchantBlocklistResponse[]
    loading: boolean
    onEdit: (merchant: StrictCompanyMerchantBlocklistRequest) => void
    onCreate: () => void
    onDelete: (id: string) => void
}

export const MerchantCompanyBlocklist: FC<React.PropsWithChildren<Props>> = ({
    merchants,
    loading,
    onEdit,
    onCreate,
    onDelete,
}) => {
    const columns: ColumnProps<StrictCompanyMerchantBlocklistResponse>[] = [
        {
            title: 'Exact entry',
            dataIndex: 'exactEntry',
            render: (exactEntry: boolean) => <AntCheckbox disabled checked={exactEntry} />,
        },
        {
            title: 'RegEx',
            dataIndex: 'merchantNameRegex',
        },
        {
            title: 'Merchant ID',
            dataIndex: 'merchantId',
        },
        {
            title: 'Company ID',
            dataIndex: 'companyId',
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
        },
        {
            title: 'Action',
            render: (_, record) => (
                <ActionsContainer>
                    <Button type="link" onClick={() => onEdit(record)}>
                        Edit
                    </Button>
                    <Button type="link" danger onClick={() => onDelete(record.id)}>
                        Delete
                    </Button>
                </ActionsContainer>
            ),
        },
    ]
    return (
        <ContentContainer>
            <Space>
                <Button type="primary" onClick={onCreate}>
                    Add merchant to blocklist
                </Button>
            </Space>
            <Table
                pagination={false}
                columns={columns}
                dataSource={merchants}
                rowKey="id"
                loading={loading}
            />
        </ContentContainer>
    )
}

const MerchantBlocklistContainer = () => {
    const [activeMerchant, setActiveMerchant] =
        useState<StrictCompanyMerchantBlocklistRequest | null>(null)
    const [editVisible, setEditVisible] = useState(false)
    const [createVisible, setCreateVisible] = useState(false)
    const { data: merchants } =
        complianceBff.companyBlocklistedMerchants.getBlocklistedMerchants.useQuery({})
    const { mutateAsync: createBlocklistedMerchant } =
        complianceBff.companyBlocklistedMerchants.createBlocklistedMerchant.useMutation()
    const { mutateAsync: updateBlocklistedMerchant } =
        complianceBff.companyBlocklistedMerchants.updateBlocklistedMerchant.useMutation()
    const { mutateAsync: deleteBlocklistedMerchant } =
        complianceBff.companyBlocklistedMerchants.deleteBlocklistedMerchant.useMutation()

    const blockedMerchants = merchants as StrictCompanyMerchantBlocklistResponse[]
    return (
        <>
            <MerchantCompanyBlocklist
                merchants={blockedMerchants ?? []}
                loading={!blockedMerchants}
                onCreate={() => setCreateVisible(true)}
                onDelete={(id) => {
                    Modal.confirm({
                        onOk: () => deleteBlocklistedMerchant({ id }),
                        centered: true,
                        title: 'Are you sure you want to delete this entry?',
                        okButtonProps: {
                            danger: true,
                        },
                    })
                }}
                onEdit={(merchant) => {
                    setActiveMerchant(merchant)
                    setEditVisible(true)
                }}
            />
            <Modal
                title="Edit merchant"
                onCancel={() => setEditVisible(false)}
                open={editVisible}
                footer={null}
                centered
            >
                <MerchantForm
                    onSubmit={(values) => {
                        if (activeMerchant) {
                            updateBlocklistedMerchant({
                                companyMerchantBlocklistId: activeMerchant.id!,
                                companyId: values.companyId!,
                                merchantId: values.merchantId ?? undefined,
                                merchantName: values.merchantName ?? undefined,
                                exactEntry: values.exactEntry ?? false,
                                notes: values.notes,
                            })
                        }
                        setEditVisible(false)
                    }}
                    merchant={activeMerchant}
                />
            </Modal>
            <Modal
                title="Create merchant"
                onCancel={() => setCreateVisible(false)}
                open={createVisible}
                footer={null}
                centered
            >
                <MerchantForm
                    onSubmit={(values) => {
                        createBlocklistedMerchant({
                            companyId: values.companyId!,
                            merchantId: values.merchantId ?? undefined,
                            merchantName: values.merchantName ?? undefined,
                            exactEntry: values.exactEntry ?? false,
                            notes: values.notes,
                        })
                        setCreateVisible(false)
                    }}
                />
            </Modal>
        </>
    )
}

export default MerchantBlocklistContainer
