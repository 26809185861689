import React, { FC, useEffect, useState } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Card, Typography, Space, Table, Tooltip, Tag, Descriptions, Statistic } from 'antd'
import styled from 'styled-components'
import { color, spacing } from 'theme/tokens'
import { ContentContainer, PageContentLayout } from 'components/layout-containers'
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom'
import { parse } from 'qs'
import dayjs from 'packages/dayjs'

import mccCodeMap from 'utils/mcc-codes'
import {
    GetFlagResponse,
    FlagRow,
    InvestigationStatus,
    RiskScore,
    GetAllFlagResponse,
    TriggeredRuleWithScore,
    Amount,
    FlagCategory,
    FlaggedBillTransaction,
    FlaggedPersonalTransferTransaction,
    FlaggedEmployeeBankTransferTransaction,
    FlaggedWalletWithdrawalTransaction,
} from '@pleo-io/deimos'

import type { Company, OnboardingSource } from 'types/deimos-company'
import getHighestKycStatus from 'utils/get-company-kyc-status'
import type { ColumnProps } from 'antd/es/table'
import { useArielFlagsPaginated } from 'services/deimos/transaction-monitoring'

import { merchantNameSorter } from 'pages/compliance/transaction-monitoring/utils'
import { snakeCaseToTitleSnakeCase, snakeCaseToTitleCase } from 'utils/strings'
import type { IndustryCodes } from 'types/styx'
import { useCompanyOnboardedVia } from 'services/deimos/styx-company/styx-company'
import NaceCode from 'components/nace-codes/nace-code'
import { OnboardedViaTag } from 'pages/compliance/shared/company-onboarded-via-tag'
import { useIndustryCodes } from 'services/styx/industry-codes'

const { Text, Paragraph, Link: AntLink } = Typography

interface Props {
    goBack: () => void
    flag?: GetFlagResponse
    loading: boolean
    onRowSelection: (selectedRows: FlagRow[]) => void
    naceCodes?: IndustryCodes[]
    onboardedVia?: OnboardingSource
}

const getInvestigationStatus = (flag?: GetFlagResponse) => flag?.rows[0]?.flag.investigationStatus

const getFlag = (flags: GetAllFlagResponse, entityId: string) =>
    flags.find((flag) => {
        const hasUndefinedEntityId =
            !flag.bill?.billInvoiceId &&
            !flag.card?.id &&
            !flag.personalTransfer?.id &&
            !flag.employeeBankTransfer?.id &&
            !flag.walletWithdrawal?.id &&
            entityId === 'undefined'
        return (
            hasUndefinedEntityId ||
            flag.bill?.billInvoiceId === entityId ||
            flag.personalTransfer?.id === entityId ||
            flag.employeeBankTransfer?.id === entityId ||
            flag.walletWithdrawal?.id === entityId ||
            flag.card?.id === entityId
        )
    })

export const TransactionList: FC<React.PropsWithChildren<Props>> = ({
    goBack,
    flag,
    loading,
    children,
    onRowSelection,
    naceCodes,
    onboardedVia,
}) => {
    const copyCompanyIdAndRedirect = async (url: string) => {
        await navigator.clipboard.writeText(flag?.company?.id ?? 'ID unknown')
        window.open(url, '_blank')
    }

    const merchantColumn = {
        title: 'Merchant',
        dataIndex: ['transaction', 'merchant', 'name'],
        render: (merchantName: string) => (
            <AntLink
                disabled={!merchantName}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.dk/search?q=${encodeURIComponent(merchantName)}`}
            >
                {merchantName}
            </AntLink>
        ),
        sorter: merchantNameSorter,
    }

    const supplierColumn = {
        title: 'Supplier',
        dataIndex: ['transaction'],
        render: (transaction: FlaggedBillTransaction) => (
            <Link
                to={`/compliance/suppliers/${transaction.supplierId}`}
                onClick={(e) => e.stopPropagation()}
            >
                {transaction.supplierName}
            </Link>
        ),
    }

    const employeeColumn = {
        title: 'Employee',
        dataIndex: ['transaction'],
        render: (
            transaction:
                | FlaggedPersonalTransferTransaction
                | FlaggedEmployeeBankTransferTransaction
                | FlaggedWalletWithdrawalTransaction
        ) => (
            <BreakText type="secondary" copyable>
                {transaction.employee?.id}
            </BreakText>
        ),
    }

    const beneficiaryColumn = flag?.bill
        ? supplierColumn
        : flag?.personalTransfer
        ? employeeColumn
        : flag?.employeeBankTransfer
        ? employeeColumn
        : flag?.walletWithdrawal
        ? employeeColumn
        : merchantColumn

    const columns: ColumnProps<FlagRow>[] = [
        {
            title: 'Flag ID',
            dataIndex: ['flag', 'id'],
        },
        {
            title: 'Performed',
            dataIndex: ['flag', 'createdAt'],
            render: (timestamp: string) => dayjs(timestamp).format('lll'),
        },
        beneficiaryColumn,
        {
            title: 'Amount',
            dataIndex: ['transaction', 'amount'],
            render: (amount: Amount) => {
                return amount
                    ? `${Math.floor(100 * amount.value) / 100} ${amount.currency ?? ''}`
                    : ''
            },
        },
        {
            title: 'MCC',
            dataIndex: ['transaction', 'merchant', 'mcc'],
            width: '7%',
            render: (mcc: string) => (
                <Tooltip title={mccCodeMap[mcc]?.edited_description ?? 'Unknown'}>
                    <span>{flag?.card ? mcc : 'N/A'}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Triggered Rules',
            dataIndex: ['flag', 'triggeredRules'],
            key: 'triggeredRules',
            width: '20%',
            render: (rules: TriggeredRuleWithScore[]) => (
                <Space direction="vertical">
                    {rules.map((rule, index) => (
                        <Tag key={index} color="red">
                            <div>{rule.ruleName}</div>
                            <div>{rule.score && `Score: ${rule.score}`}</div>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: 'Transaction ID',
            dataIndex: ['flag', 'transactionId'],
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Paragraph style={{ marginBottom: 0 }} ellipsis copyable>
                    {id}
                </Paragraph>
            ),
        },
    ]

    useEffect(() => {
        if (!loading && !flag?.rows.length) {
            goBack()
        }
    }, [loading, flag, goBack])

    const title = snakeCaseToTitleCase(String(getInvestigationStatus(flag)))

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={goBack}
                title={title}
                subTitle={<OnboardedViaTag onboardedVia={onboardedVia} />}
            >
                <DetailsContainer>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Card ID">
                            <Text copyable={!!flag?.card?.id}>
                                {(flag?.bill && 'None') || flag?.card?.id}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="PAN">
                            {(flag?.bill && 'None') || flag?.card?.pan}
                        </Descriptions.Item>
                        <Descriptions.Item label="Type">
                            {(flag?.bill && 'Invoice') || flag?.card?.type}
                        </Descriptions.Item>
                        <Descriptions.Item label="Card status">
                            {(flag?.bill && 'None') || flag?.card?.status}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="User email">
                            <BreakText copyable={!!flag?.employee?.email}>
                                {flag?.employee?.email ?? 'None'}
                            </BreakText>
                        </Descriptions.Item>
                        <Descriptions.Item label="Company">
                            <AntLink href={`/compliance/companies/${flag?.company?.id}`}>
                                {flag?.company?.name}
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="KYC status">
                            {flag?.company &&
                                getHighestKycStatus(flag?.company as Company).toUpperCase()}
                        </Descriptions.Item>
                        <Descriptions.Item label="Type of business">
                            {flag?.areaOfBusiness ? (
                                <Paragraph
                                    style={{ maxWidth: 300 }}
                                    type="secondary"
                                    ellipsis={{
                                        rows: 1,
                                        expandable: true,
                                        symbol: 'more',
                                    }}
                                >
                                    {flag?.areaOfBusiness}
                                </Paragraph>
                            ) : (
                                'None'
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                disabled={!flag?.card?.id}
                                href={`https://metabase.pleo.io/dashboard/443?card_id=${flag?.card?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                AML Review
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                disabled={!flag?.company?.id}
                                href={`https://metabase.pleo.io/dashboard/3?company_id=${flag?.company?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Company details
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                onClick={() =>
                                    copyCompanyIdAndRedirect(
                                        'https://metabase.pleo.io/question/11110'
                                    )
                                }
                                rel="noopener noreferrer"
                            >
                                Whole company activity
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                onClick={() =>
                                    copyCompanyIdAndRedirect(
                                        'https://metabase.pleo.io/question/11111'
                                    )
                                }
                                rel="noopener noreferrer"
                            >
                                Wallet loads
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                disabled={!flag?.card?.id}
                                href={`https://metabase.pleo.io/dashboard/1321-all-transactions-view?card_id=${flag?.card?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                AML company cards
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                href={`https://metabase.pleo.io/dashboard/1436-bank-reimbursement-tm-dashboard`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Bank reimbursements
                            </AntLink>
                        </Descriptions.Item>
                    </Descriptions>
                    <Statistic
                        title="Risk score"
                        value={flag?.riskScore ? snakeCaseToTitleSnakeCase(flag.riskScore) : '-'}
                        valueStyle={
                            flag?.riskScore === RiskScore.HIGH ? { color: color.red } : undefined
                        }
                    />
                </DetailsContainer>
                {!!naceCodes?.length && (
                    <Descriptions>
                        <Descriptions.Item label="NACE">
                            <Paragraph
                                ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                                style={{ maxWidth: '100vw', lineHeight: '2' }}
                            >
                                {naceCodes.map((naceCode, index) => (
                                    <NaceCode key={index} naceCode={naceCode} />
                                ))}
                            </Paragraph>
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </PageHeader>
            <PageContentLayout>
                <Card>
                    <ContentContainer>
                        <Table
                            pagination={false}
                            loading={loading}
                            data-testid="transactions"
                            rowKey={(row) => row.flag.id}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: (_, selectedFlagRows) => onRowSelection(selectedFlagRows),
                            }}
                            title={() => 'Transactions'}
                            columns={columns}
                            dataSource={flag?.rows}
                        />
                        {children}
                    </ContentContainer>
                </Card>
            </PageContentLayout>
        </>
    )
}

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: ${spacing.space24};
    .ant-descriptions * {
        width: auto;
    }
`

const BreakText = styled(Text)`
    word-break: break-all;
`

const TransactionListContainer = () => {
    const [selectedRows, setSelectedRows] = useState<FlagRow[]>([])
    const params = useParams()
    const { search } = useLocation()
    const navigate = useNavigate()
    const { naceCodes } = useIndustryCodes(params.companyId)

    const { investigationStatus, to, from, pageSize, pageOffset } = parse(search, {
        ignoreQueryPrefix: true,
    })
    const goBack = () => navigate(-1)

    const [status] = React.useState<InvestigationStatus[]>([investigationStatus])
    let [category] = React.useState<FlagCategory[]>([
        FlagCategory.AML,
        FlagCategory.BILL_FRAUD,
        FlagCategory.PERSONAL_TRANSFER_FRAUD,
        FlagCategory.EMPLOYEE_BANK_TRANSFER_FRAUD,
        FlagCategory.WALLET_WITHDRAWAL_FRAUD,
    ])

    // The category list has to match the one from the AML page, in order for the pagination to work as expected and contain the flag clicked.
    if (status.includes(InvestigationStatus.NEW)) {
        category = category.filter((item) => item !== FlagCategory.AML)
    }

    const {
        data: paginatedFlags,
        mutations,
        isValidating,
    } = useArielFlagsPaginated({
        to: to,
        from: from,
        status,
        category,
        pageSize,
        pageOffset,
    })

    const flags = paginatedFlags?.rows

    const flag = getFlag(flags ?? [], params.id)
    const disabled = !selectedRows.length

    const { onboardedVia } = useCompanyOnboardedVia(flag?.company?.id ?? undefined)

    const onUpdateFlagInvestigationStatus = (nextStatus: InvestigationStatus) => {
        mutations.updateInvestigationStatus(selectedRows, nextStatus)
        setSelectedRows([])
    }
    return (
        <TransactionList
            goBack={goBack}
            flag={flag}
            loading={!flags && isValidating}
            onRowSelection={setSelectedRows}
            naceCodes={naceCodes}
            onboardedVia={onboardedVia}
        >
            <Space>
                <Button
                    type="primary"
                    disabled={disabled}
                    onClick={() =>
                        onUpdateFlagInvestigationStatus(InvestigationStatus.FALSE_POSITIVE)
                    }
                >
                    False positive
                </Button>
                <Button
                    type="primary"
                    disabled={disabled}
                    onClick={() =>
                        onUpdateFlagInvestigationStatus(InvestigationStatus.REQ_INVESTIGATION)
                    }
                >
                    Requires investigation
                </Button>
                <Button
                    type="primary"
                    disabled={disabled}
                    onClick={() => onUpdateFlagInvestigationStatus(InvestigationStatus.FRAUD)}
                >
                    Fraud
                </Button>
            </Space>
        </TransactionList>
    )
}

export default TransactionListContainer
