import { RedoOutlined } from '@ant-design/icons'
import { Button, Table, Tag } from 'antd'
import type { Permission, PermissionChangeRequest } from 'bff/moons/generated/pandora-v1'
import styled from 'styled-components'

interface Props {
    loading: boolean
    permissionChangeRequests: PermissionChangeRequest[]
    oktaPermissions: Permission[] | undefined
    sendNotification: (requestId: string) => Promise<void>
    buttonLoading: string | null
}

const HistoricRequestPermissionsList = ({
    loading,
    permissionChangeRequests,
    oktaPermissions,
    sendNotification,
    buttonLoading,
}: Props) => {
    const columns = [
        {
            title: 'Permissions to Add',
            dataIndex: 'idsToAdd',
            key: 'idsToAdd',
            render: (text: string[]) => {
                return text.map((id) => {
                    const permission = oktaPermissions?.find((perm) => perm.id === id)
                    return `${permission?.tag} - (${permission?.description})`
                })
            },
        },
        {
            title: 'Permissions to Remove',
            dataIndex: 'idsToRemove',
            key: 'idsToRemove',
            render: (text: string[]) => {
                return text.map((id) => {
                    const permission = oktaPermissions?.find((perm) => perm.id === id)
                    return `${permission?.tag} - (${permission?.description})`
                })
            },
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => {
                if (text === 'PENDING') {
                    return <Tag color={'blue'}>{text}</Tag>
                } else if (text === 'APPROVED') {
                    return <Tag color={'green'}>{text}</Tag>
                } else if (text === 'DENIED') {
                    return <Tag color={'red'}>{text}</Tag>
                } else if (text === 'ENACTED') {
                    return <Tag>{text}</Tag>
                }
            },
        },
        {
            title: 'Request Sent To',
            dataIndex: 'notificationSendTo',
            key: 'notificationSendTo',
            render: (text: string) => (text ? text : 'N/A'),
        },
        {
            title: 'Request Send At',
            dataIndex: 'notificationSendAt',
            key: 'notificationSendAt',
            render: (text: string) => (text ? new Date(text).toLocaleDateString() : '-'),
        },
        {
            title: 'Permissions Applied',
            dataIndex: 'enactedAt',
            key: 'enactedAt',
            render: (text: string) => (text ? new Date(text).toLocaleDateString() : 'Not applied'),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (id: string, record: any) => {
                const canRetry =
                    record.status === 'PENDING' && record.notificationSendTo !== undefined
                return (
                    <Button
                        key={id}
                        type="primary"
                        disabled={!canRetry}
                        onClick={() => {
                            sendNotification(id)
                        }}
                        loading={buttonLoading === id}
                        data-testid={'send-notification-button'}
                    >
                        <GroupedActions>
                            <RedoOutlined />
                            <div>Notify again</div>
                        </GroupedActions>
                    </Button>
                )
            },
        },
    ]

    return (
        <Table
            rowKey="id"
            loading={loading}
            dataSource={permissionChangeRequests}
            columns={columns}
            pagination={false}
        />
    )
}

const GroupedActions = styled('div')`
    display: flex;
    align-items: center;
    vertical-align: middle;
    gap: 0.375rem;
    flex-wrap: nowrap;
`

export default HistoricRequestPermissionsList
