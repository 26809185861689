import useSWR from 'swr'
import qs from 'qs'
import type { ListTransactionExportObjects, ErrorResponse } from '@pleo-io/deimos'
import type { Transaction } from 'types/transaction'
import request, { fetcher } from './request'
import { useState } from 'react'

const chargebackTransactionsFetcher = (url: string, cardId: string) =>
    request()
        .get(url, { searchParams: { cardId, types: 'CHARGE_BACK' } })
        .then((res) => res.json())

export const useChargebackTransactions = (cardId: string | null) =>
    useSWR<Transaction[], Error>(
        cardId ? ['rest/v1/admin/transactions', cardId] : null,
        chargebackTransactionsFetcher
    )

export const useTransaction = (transactionId: string | null) => {
    const [currentTransaction, setCurrentTransaction] = useState<string>('')
    const [failedTransaction, setFailedTransaction] = useState<string>('')
    const [successfulTransaction, setSuccessfulTransaction] = useState<string>('')

    const clearTransactionState = () => {
        setCurrentTransaction('')
        setFailedTransaction('')
        setSuccessfulTransaction('')
    }

    const response = useSWR<Transaction, Error>(
        transactionId && `rest/v1/transactions/${transactionId}`,
        fetcher
    )

    const processSingleRefund = async (id: string) => {
        await request().post(`rest/v1/admin/transactions/${id}/pleo-refund`)
        return response.mutate()
    }

    const processMultipleRefunds = async (ids: string[]) => {
        for (const id of ids) {
            try {
                setCurrentTransaction(id)
                await processSingleRefund(id)
                setSuccessfulTransaction(id)
                response.mutate()
            } catch (e) {
                setFailedTransaction(id)
            }
        }
    }

    const processChargebacks = async (ids: string[]) => {
        clearTransactionState()

        for (const id of ids) {
            try {
                setCurrentTransaction(id)
                await request().post(`rest/v1/admin/transactions/${id}/chargeback`)
                setSuccessfulTransaction(id)
                response.mutate()
            } catch (e) {
                setFailedTransaction(id)
            }
        }
    }

    const undoChargebacks = async (ids: string[]) => {
        clearTransactionState()

        for (const id of ids) {
            try {
                setCurrentTransaction(id)
                await request().post(`rest/v1/admin/transactions/${id}/chargeback/undo`)
                setSuccessfulTransaction(id)
                response.mutate()
            } catch (e) {
                setFailedTransaction(id)
            }
        }
    }

    return {
        ...response,
        currentTransaction,
        successfulTransaction,
        failedTransaction,
        mutations: {
            undoChargebacks,
            processChargebacks,
            processSingleRefund,
            processMultipleRefunds,
        },
    }
}

export function updateExpenseVisibility(expenseId: string, hidden: boolean): Promise<Response> {
    return request().put('rest/v1/expenses/batch/visibility', {
        json: { expenseIds: [expenseId], hidden },
    })
}

export function linkExpenses(expenseIds: string[]): Promise<Response> {
    return request().post('rest/v1/admin/expenses/merge', {
        json: { expenseIds },
    })
}

export const useTransactionsExport = () => {
    const response = useSWR<ListTransactionExportObjects, ErrorResponse>(
        'rest/v1/admin/transactions/exports',
        fetcher
    )

    const createExport = async (to: string, from: string) => {
        const query = qs.stringify({ to, from })
        await request().post(`rest/v1/admin/transactions/export?${query}`)
        response.mutate()
    }

    const downloadFile = async (key: string) => {
        const query = qs.stringify({ key })
        const url: string = await request()
            .get(`rest/v1/admin/transactions/export?${query}`)
            .then((res) => res.json())

        return url
    }
    return { ...response, mutations: { createExport, downloadFile } }
}
