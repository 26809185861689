import styled from 'styled-components'
import { spacing } from 'theme/tokens'

export const FiltersContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: ${spacing.space48};
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: ${spacing.space16};
    padding-bottom: ${spacing.space16};
`

export const InfoElem = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
`
