import { ContentContainer } from 'components/layout-containers'
import { bff } from 'pages/compliance/bff'

import KycCaseList from 'pages/compliance/company/proof-of-business/components/kyc-case-list'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import { useCursorPagination } from 'utils/use-cursor-pagination'
import { useEffect } from 'react'
import {
    defaultPageSize,
    defaultSorting,
} from 'pages/compliance/company/proof-of-business/constants'
import type { KycCaseRecordDto } from 'bff/moons/generated/business-proof-collector'
import type { PaginationData } from '@pleo-io/deimos'

interface KycCaseContainerProps {
    companyId: string
}

const KycCaseContainer = ({ companyId }: KycCaseContainerProps) => {
    const { paginationRequest, setCurrentPagination, ...paginationMutations } = useCursorPagination(
        {
            limit: defaultPageSize,
            sorting: defaultSorting,
        }
    )

    const { data, isLoading, error } = bff.proofOfBusiness.getKycCaseForCompany.useQuery({
        deimosCompanyId: companyId,
        pagination: paginationRequest,
    })

    useEffect(() => {
        if (data?.pagination) {
            setCurrentPagination(data.pagination as PaginationData<string>)
        }
    }, [data, setCurrentPagination])

    if (!data) {
        return null
    }

    const { data: kycCases, pagination } = data as {
        data: KycCaseRecordDto[]
        pagination: PaginationData<string>
    }

    const loading = isLoading && !error

    return (
        <ContentContainer>
            <KycCaseList loading={loading} kycCases={kycCases} />
            {paginationRequest && (
                <CursorPaginationControls
                    isFetching={!kycCases}
                    pagination={pagination}
                    onChangeLimit={paginationMutations.updateLimit}
                    limit={paginationRequest.limit}
                    onNextPage={paginationMutations.nextPage}
                    onPrevPage={paginationMutations.previousPage}
                    onFirstPage={paginationMutations.resetFirstPage}
                />
            )}
        </ContentContainer>
    )
}

export default KycCaseContainer
