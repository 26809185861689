import { useState } from 'react'
import {
    Alert,
    Button,
    Card,
    Descriptions,
    Modal,
    Space,
    Statistic,
    Table,
    Tooltip,
    Typography,
} from 'antd'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { bff } from '../bff'
import { formatTimestamp } from 'packages/dates/dates'
import Spinner from 'components/content-spinner'
import { shareholderSharePercentageThreshold } from 'services/styx/ownership'

import type { KnowYourCustomerSummaryResponse } from '../index.bff'
import { CompanyResponseVOnboardedVia } from 'bff/moons/generated/styx-v1'

const { Text } = Typography

export const KnowYourCustomerSummary = ({ companyId }: { companyId: string }) => {
    const [missingInfoModalOpen, setMissingInfoModalOpen] = useState(false)
    const [verifiedAdminsModalOpen, setVerifiedAdminsModalOpen] = useState(false)
    const [ekycModalOpen, setEkycModalOpen] = useState(false)

    const { data, error, isLoading } = bff.knowYourCustomerSummary.getKnowYourCustomer.useQuery({
        companyId,
        sharePercentageThreshold: shareholderSharePercentageThreshold,
    })

    const {
        mutate: postSendKycInformationRequest,
        isLoading: isPostingSendKycInformationRequest,
        isSuccess,
    } = bff.knowYourCustomerSummary.postSendKycInformationRequest.useMutation()

    const isFdd = data?.isFdd
    const isB4b = data?.onboardedVia === CompanyResponseVOnboardedVia.B4B

    const riskScore = () => {
        if (!isFdd && !isB4b) {
            return (
                <Descriptions.Item label="Preliminary Risk Score">
                    <Text>{data?.preliminaryRiskScore}</Text>
                </Descriptions.Item>
            )
        }

        if (isFdd) {
            return (
                <Descriptions.Item label="Risk Score">
                    <Text>{data?.riskScore}</Text>
                </Descriptions.Item>
            )
        }

        return null
    }

    return error ? (
        <Card type="inner" title="Know Your Customer (KYC)" style={{ margin: '0 24px 24px 0' }}>
            <Alert
                message="Something went wrong loading Know Your Customer (KYC) information. Please try again later."
                type="error"
            />
        </Card>
    ) : (
        <Card type="inner" title="Know Your Customer (KYC)" style={{ margin: '0 24px 24px 0' }}>
            <Card.Grid style={{ width: '50%' }} hoverable={false}>
                <Descriptions column={3} layout="vertical" size="small" colon={false}>
                    <Descriptions.Item label="Level">
                        <Tooltip
                            title={`Performed at ${formatTimestamp(data?.kycStatus.performedAt)}`}
                        >
                            {data?.kycStatus?.level}
                        </Tooltip>
                    </Descriptions.Item>
                    {!isFdd && (
                        <Descriptions.Item label="Time to FDD">{data?.timeToFdd}</Descriptions.Item>
                    )}
                    {riskScore()}
                </Descriptions>
            </Card.Grid>
            <CardStatistic style={{ width: '16.6%' }} onClick={() => setMissingInfoModalOpen(true)}>
                <Statistic
                    loading={isLoading || data?.missingInformation?.count === undefined}
                    title="Missing Data"
                    value={data?.missingInformation?.count}
                    valueStyle={{
                        color: data?.missingInformation?.count !== 0 ? '#cf1322' : '#3f8600',
                    }}
                />
            </CardStatistic>
            <CardStatistic
                style={{ width: '16.6%' }}
                onClick={() => setVerifiedAdminsModalOpen(true)}
            >
                <Statistic
                    loading={isLoading || data?.admins?.total === undefined}
                    title="Verified admins"
                    value={
                        data?.admins.needsAnAdminVerified ? data?.admins?.verified : 'Not required'
                    }
                    suffix={
                        data?.admins.needsAnAdminVerified ? `/ ${data?.admins?.total}` : undefined
                    }
                />
            </CardStatistic>
            <CardStatistic style={{ width: '16.6%' }} onClick={() => setEkycModalOpen(true)}>
                <Statistic
                    loading={isLoading || data?.eKyc?.total === undefined}
                    title={`eKYC passes`}
                    value={data?.eKyc?.checkPassed}
                    suffix={`/ ${data?.eKyc?.total}`}
                />
            </CardStatistic>
            <MissingInfoModal
                missingItems={data?.missingInformation?.items}
                open={missingInfoModalOpen}
                onSendInformationRequest={() => postSendKycInformationRequest({ companyId })}
                onClose={() => setMissingInfoModalOpen(false)}
                loading={isLoading || data?.missingInformation?.count === undefined}
                isSubmitting={isPostingSendKycInformationRequest}
                isSuccess={isSuccess}
            />
            <VerifiedAdminsModal
                admins={data?.admins?.all as KnowYourCustomerSummaryResponse['admins']['all']}
                open={verifiedAdminsModalOpen}
                onClose={() => setVerifiedAdminsModalOpen(false)}
                loading={isLoading}
            />
            <EkycModal
                managers={data?.eKyc?.all as KnowYourCustomerSummaryResponse['eKyc']['all']}
                open={ekycModalOpen}
                onClose={() => setEkycModalOpen(false)}
                loading={isLoading}
            />
        </Card>
    )
}

const MissingInfoModal = ({
    missingItems,
    open,
    onClose,
    loading,
    onSendInformationRequest,
    isSubmitting,
    isSuccess,
}: {
    missingItems?: ({ name: string; missingDetail: string } | null)[]
    open: boolean
    onClose: () => void
    loading?: boolean
    onSendInformationRequest: () => void
    isSubmitting: boolean
    isSuccess?: boolean
}) => {
    return (
        <Modal
            title="Missing Customer Datapoints"
            data-testid="missing-info-modal"
            open={open}
            onCancel={() => onClose()}
            footer={null}
        >
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <Alert
                        type="info"
                        style={{ margin: '16px 0' }}
                        message={
                            <>
                                <Button
                                    block
                                    style={{ marginBottom: '8px' }}
                                    type="primary"
                                    onClick={() => onSendInformationRequest()}
                                    loading={isSubmitting}
                                    disabled={isSuccess}
                                    icon={isSuccess ? <CheckCircleOutlined /> : undefined}
                                >
                                    {isSuccess ? 'Email sent to admin' : 'Send information request'}
                                </Button>
                                <Text type="secondary">
                                    This will send an email to the admin of this customer account
                                    asking them to provide extra information.
                                </Text>
                            </>
                        }
                    />
                    <Space size="small" direction="vertical">
                        <Text strong>Missing Customer Datapoints</Text>
                        {missingItems?.map((item, index) => (
                            <Space key={index}>
                                <Text>{item?.name}</Text>
                                <Text type="danger">{item?.missingDetail}</Text>
                            </Space>
                        ))}
                    </Space>
                </>
            )}
        </Modal>
    )
}

const CardStatistic = styled(Card.Grid)`
    width: 16.6%;
    cursor: pointer;
`

const VerifiedAdminsModal = ({
    admins,
    open,
    onClose,
    loading,
}: {
    admins: KnowYourCustomerSummaryResponse['admins']['all']
    open: boolean
    onClose: () => void
    loading?: boolean
}) => {
    const navigate = useNavigate()
    return (
        <Modal
            title="Admins"
            open={open}
            onCancel={() => onClose()}
            footer={null}
            data-testid="verified-admins-modal"
        >
            {loading ? (
                <Spinner />
            ) : (
                <TableStyleProvider>
                    <Table
                        dataSource={admins}
                        rowKey={(record) => record.id}
                        onRow={(record) => ({
                            onClick: () => {
                                record?.userId &&
                                    navigate(`/customer-success/users/${record.userId}`)
                            },
                        })}
                        rowClassName={(record) => (record.userId ? 'clickable' : '')}
                        bordered
                        columns={[
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Verified',
                                dataIndex: 'isVerified',
                                key: 'isVerified',
                                render: (isVerified) =>
                                    isVerified ? (
                                        <CheckCircleOutlined style={{ color: '#52c41a' }} />
                                    ) : (
                                        <ExclamationCircleOutlined style={{ color: '#fb6060' }} />
                                    ),
                            },
                        ]}
                        pagination={false}
                    />
                </TableStyleProvider>
            )}
        </Modal>
    )
}

const TableStyleProvider = styled('div')`
    & .ant-table-tbody tr.clickable {
        cursor: pointer;
    }
`

const EkycModal = ({
    managers,
    open,
    onClose,
    loading,
}: {
    managers: KnowYourCustomerSummaryResponse['eKyc']['all']
    open: boolean
    onClose: () => void
    loading?: boolean
}) => {
    const navigate = useNavigate()
    return (
        <Modal
            title="eKYC"
            open={open}
            onCancel={() => onClose()}
            footer={null}
            data-testid="ekyc-modal"
        >
            {loading ? (
                <Spinner />
            ) : (
                <TableStyleProvider>
                    <Table
                        dataSource={managers}
                        rowKey={(record) => record.id}
                        onRow={(record) => ({
                            onClick: () => {
                                record?.userId &&
                                    navigate(`/customer-success/users/${record.userId}`)
                            },
                        })}
                        rowClassName={(record) => (record.userId ? 'clickable' : '')}
                        bordered
                        columns={[
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'eKYC Check',
                                dataIndex: 'eKycChecks',
                                key: 'eKycChecks',
                                render: (eKycChecks) => {
                                    switch (eKycChecks) {
                                        case 'PASS':
                                            return (
                                                <Text type="success">
                                                    <CheckCircleOutlined
                                                        style={{ color: '#52c41a' }}
                                                    />{' '}
                                                    PASS
                                                </Text>
                                            )
                                        case 'FAIL':
                                            return (
                                                <Text type="danger">
                                                    <ExclamationCircleOutlined
                                                        style={{ color: '#fb6060' }}
                                                    />{' '}
                                                    FAIL
                                                </Text>
                                            )
                                        default:
                                            return <Text type="secondary">{eKycChecks}</Text>
                                    }
                                },
                            },
                        ]}
                        pagination={false}
                    />
                </TableStyleProvider>
            )}
        </Modal>
    )
}
