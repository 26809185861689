import React, { FC, createContext, useContext } from 'react'
import { useAccessToken } from './auth-context'
import useSWR from 'swr'

import { kerberosRequest, buildFetcher } from 'services/request'
import { useUserPreferences } from 'hooks/use-user-preferences'

interface User {
    id: string
    email: string
    permissions: Permission[]
    data?: {
        firstName: string
        lastName: string
    }
}
type Permission = {
    id: string
    userId: string
    resource: string
    resourceId: string
    type: string
}

interface UserContextApi {
    id?: string | null
    email?: string | null
    permissions: string[]
    firstName?: string
    lastName?: string
    preferences?: ReturnType<typeof useUserPreferences>
    isLoading?: boolean | null
}

export const UserContext = createContext<UserContextApi>({
    email: null,
    permissions: [],
    firstName: '',
    lastName: '',
})

const getKerberos = buildFetcher(kerberosRequest)

export const UserProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const accessToken = useAccessToken()
    const { userPreferences, dispatch } = useUserPreferences()
    const { data: user } = useSWR<User>(accessToken ? 'api/v1/user' : null, getKerberos)
    const loading = Boolean(accessToken && !user)

    const userContextValue = {
        email: user?.email,
        permissions: getAdminPermissions(user?.permissions),
        id: user?.id,
        firstName: user?.data?.firstName,
        lastName: user?.data?.lastName,
        preferences: { userPreferences, dispatch },
        isLoading: loading,
    }

    return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>
}

function getAdminPermissions(permissions: Permission[] = []) {
    return permissions.filter((it) => it.resource === 'admin').map((it) => it.resourceId)
}

export function usePermissions() {
    const { permissions } = useContext(UserContext)
    return permissions
}

export function useUser() {
    const user = useContext(UserContext)
    return user
}

export function usePreferences() {
    const { preferences } = useContext(UserContext)
    return preferences
}
