import type { FC } from 'react'
import { ModifySubscriptionModalForm } from './modify-subscription-modal-form'
import { message, Modal } from 'antd'
import { bff } from '../../../bff'
import React from 'react'

export interface Props {
    companyId: string
    isVisible: boolean
    setVisible: (visible: boolean) => {}
}

export const ModifySubscriptionModal: FC<React.PropsWithChildren<Props>> = ({
    companyId,
    isVisible,
    setVisible,
}) => {
    const {
        mutateAsync: updateSubscriptionRequest,
        isLoading,
        error,
        isSuccess,
    } = bff.subscription.updateSubscription.useMutation()

    React.useEffect(() => {
        if (error) {
            message.error(error.message)
        }
    }, [error])

    React.useEffect(() => {
        if (isSuccess) {
            message.success('Successfully updated subscription')
        }
    }, [isSuccess])

    return (
        <Modal
            title="Update subscription"
            open={isVisible}
            onCancel={() => setVisible(false)}
            footer={null}
            width={400}
            centered
            destroyOnClose
            data-testid="modifySubscriptionModal"
        >
            <ModifySubscriptionModalForm
                onSubmit={async (values) => {
                    await updateSubscriptionRequest({
                        companyId,
                        ratePlanType: values.ratePlanType,
                        billingPeriodType: values.billingPeriodType,
                        billingPeriodCommitment: values.billingPeriodCommitment,
                    })

                    setVisible(false)
                }}
                isLoadingUpdate={isLoading}
            />
        </Modal>
    )
}

export default ModifySubscriptionModal
