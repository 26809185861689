import { Button, Space, Table, Modal, message, Typography, Row } from 'antd'
import { ActionsContainer, ContentContainer } from 'components/layout-containers'
import { bff } from '../bff'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'packages/dayjs'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { AddReferral } from './add-referral'
import Spinner from 'components/content-spinner'
const { Link, Text } = Typography
const { confirm } = Modal

export const PartnerReferrals = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { data, isLoading } = bff.partnerReferrals.getPartnerReferrals.useQuery({ partnerId: id })
    const { mutateAsync: deletePartnerReferral } =
        bff.partnerReferrals.deletePartnerReferral.useMutation({
            onSuccess: () => {
                message.success(`Partner referral has been successfully deleted.`)
            },
            onError: (error) => {
                message.error(`Error: ${error.message}`)
            },
        })

    const goToCompany = (companyId: string) => navigate(`/customer-success/companies/${companyId}`)

    return (
        <ContentContainer>
            <Space direction="vertical" size="middle">
                <Row justify="end">
                    <AddReferral />
                </Row>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <Table
                        loading={false}
                        rowKey="id"
                        dataSource={data?.partnerReferrals || []}
                        columns={[
                            {
                                title: 'Company ID',
                                dataIndex: 'companyId',
                                render: (companyId) => <Text copyable>{companyId}</Text>,
                            },
                            {
                                title: 'Company name',
                                dataIndex: 'companyName',
                                render: (companyName, { companyId }) => (
                                    <Link onClick={() => goToCompany(companyId)}>
                                        {companyName}
                                    </Link>
                                ),
                            },
                            {
                                title: 'Referral Date',
                                dataIndex: 'referredAt',
                                render: (referredAt) => (
                                    <Text>{dayjs(referredAt).format('MMMM DD, YYYY')}</Text>
                                ),
                            },
                            {
                                title: 'FDD Date',
                                dataIndex: 'fddDate',
                                render: (fddDate) => (
                                    <Text>{ dayjs(fddDate).format('MMMM DD, YYYY') }</Text>
                                ),
                            },
                            {
                                title: 'Notes',
                                dataIndex: 'actorMessage',
                                render: (actorMessage, { actor }) => (
                                    <Text>{actor + ': ' + actorMessage}</Text>
                                ),
                            },
                            {
                                title: 'Action',
                                dataIndex: 'companyId',
                                render: (companyId, { companyName }) => (
                                    <ActionsContainer>
                                        <Button
                                            type="link"
                                            danger
                                            data-testid="disconnect"
                                            onClick={() => {
                                                confirm({
                                                    icon: <ExclamationCircleOutlined />,
                                                    title: 'Are you sure?',
                                                    content: `This action will remove ${companyName} from the partner referrals.`,
                                                    okText: 'Delete referral',
                                                    okType: 'danger',
                                                    async onOk() {
                                                        await deletePartnerReferral({
                                                            partnerId: id,
                                                            companyId,
                                                        })
                                                    },
                                                    width: 500,
                                                })
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </ActionsContainer>
                                ),
                            },
                        ]}
                    />
                )}
            </Space>
        </ContentContainer>
    )
}
