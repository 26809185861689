import { Modal } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { actions as supplierActions } from 'store/modules/suppliers/slice'

import EditDetailsForm from '../../../components/supplier/edit-details-form'
import { SupplierDetails, useSupplier } from '../../../components/supplier/supplier'
import { withTeamErrorHandler } from 'providers/error-handler'
import { Team } from 'utils/teams'
import { type FC, useState, useEffect } from 'react'

const SupplierContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const [modalActive, setModalActive] = useState(false)
    const { supplier, status, dispatch } = useSupplier(id)
    const navigate = useNavigate()

    useEffect(() => {
        if (supplier?.id && supplier.id !== id) {
            navigate(`/compliance/suppliers/${supplier.id}`, { replace: true })
        }
    }, [id, supplier?.id, navigate])

    return (
        <>
            <SupplierDetails
                status={status}
                supplier={supplier}
                onEdit={() => setModalActive(true)}
                canUpdateStatus
            />
            {supplier && (
                <Modal
                    title="Edit supplier information"
                    open={!!modalActive}
                    onCancel={() => setModalActive(false)}
                    footer={null}
                    width={'95vw'}
                    centered
                >
                    <EditDetailsForm
                        supplier={supplier}
                        status={status}
                        onSubmit={(values) => {
                            dispatch(
                                supplierActions.updateSupplier({
                                    supplierId: id,
                                    data: {
                                        ...values,
                                        vatNumber: values?.vatNumber || undefined,
                                        registryId: values?.registryId || undefined,
                                        iban: values?.iban || undefined,
                                        address1: values?.address1 || undefined,
                                        address2: values?.address2 || undefined,
                                        city: values?.city || undefined,
                                        countryCode: values?.countryCode || undefined,
                                        region: values?.region || undefined,
                                        zipCode: values?.zipCode || undefined,
                                        bankCode: values?.bankCode || undefined,
                                        accountNumber: values?.accountNumber || undefined,
                                    },
                                })
                            )
                            setModalActive(false)
                        }}
                        onCancel={() => setModalActive(false)}
                    />
                </Modal>
            )}
        </>
    )
}

export default withTeamErrorHandler(Team.Invoices, SupplierContainer)
