import { Form, Modal, Select, Space } from 'antd'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { usePreferences } from 'providers/user-context'

export const SettingsModal = ({ open, onCancel }: { open: boolean; onCancel: () => void }) => {
    const preferences = usePreferences()
    const hasStyxPermissions = useHasPermissions(['styx'])

    return (
        <Modal open={open} onCancel={onCancel} title="Settings" footer={null}>
            <Form layout="vertical">
                <Space direction="vertical">
                    <Form.Item
                        label="Compliance layout"
                        help="Choose the layout for companies to be displayed in Compliance."
                    >
                        <Select
                            disabled={!hasStyxPermissions}
                            onChange={(e) =>
                                preferences?.dispatch({ type: 'SET_COMPLIANCE_VIEW', payload: e })
                            }
                            value={preferences?.userPreferences.complianceView}
                            options={[
                                { label: 'Tabs', value: 'tabs' },
                                { label: 'Collapse', value: 'collapse' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Open on start"
                        help="Choose what to show when Backoffice starts."
                    >
                        <Select
                            onChange={(e) =>
                                preferences?.dispatch({ type: 'SET_LANDING_PAGE', payload: e })
                            }
                            value={preferences?.userPreferences.landingPage}
                            options={[
                                {
                                    label: 'Compliance',
                                    value: 'compliance',
                                    disabled: !hasStyxPermissions,
                                },
                                { label: 'Customer Success', value: 'customer-success' },
                                { label: 'Product', value: 'product' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Colour scheme"
                        help="Customize how Backoffice looks on your device."
                    >
                        <Select
                            onChange={(e) =>
                                preferences?.dispatch({ type: 'SET_COLOR_SCHEME', payload: e })
                            }
                            value={preferences?.userPreferences.colorScheme}
                            options={[
                                { label: 'Use system setting', value: 'system' },
                                { label: 'Light', value: 'light' },
                                { label: 'Dark (Beta)', value: 'dark' },
                            ]}
                        />
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    )
}
