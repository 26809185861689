import { useEffect, useState } from 'react'
import { Alert, Button, Collapse, Input, Modal, Space } from 'antd'
import type { SearchProps } from 'antd/es/input/Search'
import Invoices from './invoices'
import WalletLoads from './wallet-loads'
import type { FC } from 'react'
import ProofOfFundsSection from './proof-of-funds-section'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import DirectDebitAgreements from './direct-debit-agreements'
import DirectDebitTransfers from './direct-debit-transfers'
import { cashFlowBff } from '../bff'
import type {
    DirectDebitAgreementModel,
    DirectDebitRequestInfo,
    FullInvoice,
    FullWalletLoad,
} from 'bff/moons/generated/cupid-v1'
import type { ProofOfFundsBasicResponse } from 'bff/moons/generated/styx-v1'

const { Search } = Input
const { Panel } = Collapse

interface Alert {
    message: string
}

const searchArray = (data: any[], query: string) =>
    data.filter((item) =>
        Object.values(item).some(
            (value) =>
                typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase())
        )
    )

// used for expanding/collapsing all the sections
enum SectionKeys {
    ALERTS = 'alerts',
    INVOICES = 'invoices',
    WALLET_LOADS = 'wallet-loads',
    PROOF_OF_FUNDS = 'proof-of-funds',
    DIRECT_DEBIT_AGREEMENTS = 'direct-debit-agreements',
    DIRECT_DEBIT_TRANSFERS = 'direct-debit-transfers',
    VRP_MANDATES = 'vrp-mandates',
    VRP_TRANSFERS = 'vrp-transfers',
}

const findApprovedInvoicesWithoutPendingOrLoadedWalletLoads = (
    invoices: FullInvoice[],
    walletLoads: FullWalletLoad[]
) => {
    const approvedAndPendingWalletLoadInvoiceReferences = new Set(
        walletLoads
            .filter((walletLoad) =>
                ['PROCESSING', 'QUEUED', 'APPROVED', 'BLOCKED', 'LOADED'].includes(
                    walletLoad.status!
                )
            )
            .map((walletLoad) => walletLoad.invoiceReference)
    )

    return invoices
        .filter((invoice) => invoice.status === 'APPROVED')
        .map((invoice) => invoice.reference)
        .filter((invoice) => !approvedAndPendingWalletLoadInvoiceReferences.has(invoice))
}

interface Props {
    companyId: string
}

const OverviewView: FC<Props> = ({ companyId }) => {
    const [alerts, setAlerts] = useState<Alert[]>([])

    const [openedSections, setOpenedSections] = useState<string | string[]>([])

    const [invoices, setInvoices] = useState<FullInvoice[]>([])
    const [walletLoads, setWalletLoads] = useState<FullWalletLoad[]>([])
    const [proofOfFunds, setProofOfFunds] = useState<ProofOfFundsBasicResponse[]>([])
    const [directDebitAgreements, setDirectDebitAgreements] = useState<DirectDebitAgreementModel[]>(
        []
    )
    const [directDebitTransfers, setDirectDebitTransfers] = useState<DirectDebitRequestInfo[]>([])

    const { mutate: mutateDirectDebitEligibility } =
        cashFlowBff.changeDirectDebitEligibility.useMutation({
            onSuccess: () => reloadAllData(),
        })

    const { data: allData, refetch: reloadAllData } = cashFlowBff.getOverviewData.useQuery(
        { companyId },
        {
            enabled: false,
            onSuccess: (data) => {
                setInvoices(data.invoices)
                setWalletLoads(data.walletLoads)
                setProofOfFunds(data.proofOfFunds)
                setDirectDebitAgreements(data.directDebitAgreements)
                setDirectDebitTransfers(data.directDebitTransfers)
            },
        }
    )

    const changeDirectDebitEligibility = (isEligible: boolean) => {
        Modal.confirm({
            title: 'Do you want to make the company direct debit eligible?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                mutateDirectDebitEligibility({ companyId, isEligible })
            },
            onCancel() {},
        })
    }

    // performs the text search if the field is populated, otherwise resets the sections to the initial data
    const onSearch: SearchProps['onSearch'] = (
        value: string,
        _event?:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLElement>
            | React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (value == '') {
            setInvoices(allData?.invoices ?? [])
            setWalletLoads(allData?.walletLoads ?? [])
            setProofOfFunds(allData?.proofOfFunds ?? [])
            setDirectDebitAgreements(allData?.directDebitAgreements ?? [])
            setDirectDebitTransfers(allData?.directDebitTransfers ?? [])
        } else {
            setInvoices(searchArray(allData?.invoices ?? [], value))
            setWalletLoads(searchArray(allData?.walletLoads ?? [], value))
            setProofOfFunds(searchArray(allData?.proofOfFunds ?? [], value))
            setDirectDebitAgreements(searchArray(allData?.directDebitAgreements ?? [], value))
            setDirectDebitTransfers(searchArray(allData?.directDebitTransfers ?? [], value))
        }
    }

    const directDebitEligibility = allData?.directDebitEligibility?.status
    const isFrontLoadingEligible = Boolean(allData?.isFrontLoadingEligible)

    useEffect(() => {
        const calculateAlerts = () => {
            const alertsList: Alert[] = []
            const approvedInvoicesWithoutWalletLoads =
                findApprovedInvoicesWithoutPendingOrLoadedWalletLoads(invoices, walletLoads)

            if (approvedInvoicesWithoutWalletLoads.length > 0) {
                alertsList.push({
                    message: `Approved invoices [${approvedInvoicesWithoutWalletLoads.join(
                        ', '
                    )}] don't have PENDING/LOADED wallet loads.`,
                })
            }

            const spendStatus = allData?.spendStatus
            if (!!spendStatus && spendStatus.status == 'BLOCKED') {
                alertsList.push({
                    message: `Company spend is blocked with reason: ${spendStatus.statusReason}`,
                })
            }

            const hasPendingAutomaticInvoice = Boolean(allData?.hasPendingAutomaticInvoice)
            if (hasPendingAutomaticInvoice) {
                alertsList.push({
                    message: `The company has an ATU in progress. Another ATU cannot be initiated until 
                    the current one is completed.`,
                })
            }

            setAlerts(alertsList)
        }
        calculateAlerts()
    }, [invoices, walletLoads, allData?.spendStatus, allData?.hasPendingAutomaticInvoice])

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Space>
                <Button onClick={() => reloadAllData()} type="primary">
                    Load Data
                </Button>
                <Button onClick={() => setOpenedSections(Object.values(SectionKeys))}>
                    Expand All
                </Button>
                <Button onClick={() => setOpenedSections([])}>Collapse All</Button>
                <Search
                    placeholder="Search"
                    style={{ width: 350 }}
                    enterButton
                    allowClear
                    onSearch={onSearch}
                />
                Direct Debit Eligible: {directDebitEligibility}
                {directDebitEligibility !== 'ELIGIBLE' && directDebitEligibility !== null && (
                    <Button onClick={() => changeDirectDebitEligibility(true)}>
                        Make DD Eligible
                    </Button>
                )}
                {directDebitEligibility === 'ELIGIBLE' && (
                    <Button onClick={() => changeDirectDebitEligibility(false)}>
                        Make DD Ineligible
                    </Button>
                )}
                Front Loading Eligibility: {isFrontLoadingEligible ? 'Eligible' : 'Ineligible'}
            </Space>

            <Collapse activeKey={openedSections} ghost onChange={(keys) => setOpenedSections(keys)}>
                <Panel
                    header="Alerts"
                    key={SectionKeys.ALERTS}
                    collapsible={alerts.length > 0 ? 'header' : 'disabled'}
                >
                    <Space direction="vertical" style={{ width: '100%' }}>
                        {alerts.map((alert, index) => (
                            <Alert
                                key={index}
                                type="warning"
                                showIcon
                                description={alert.message}
                            />
                        ))}
                    </Space>
                </Panel>
                <Panel
                    header="Invoices"
                    key={SectionKeys.INVOICES}
                    collapsible={invoices.length > 0 ? 'header' : 'disabled'}
                >
                    <Invoices data={invoices} />
                </Panel>
                <Panel
                    header="Wallet Loads"
                    key={SectionKeys.WALLET_LOADS}
                    collapsible={walletLoads.length > 0 ? 'header' : 'disabled'}
                >
                    <WalletLoads data={walletLoads} reloadData={reloadAllData} />
                </Panel>
                <Panel
                    header="Direct Debit Agreements"
                    key={SectionKeys.DIRECT_DEBIT_AGREEMENTS}
                    collapsible={directDebitAgreements.length > 0 ? 'header' : 'disabled'}
                >
                    <DirectDebitAgreements
                        data={directDebitAgreements}
                        reloadData={reloadAllData}
                        companyId={companyId}
                    />
                </Panel>
                <Panel
                    header="Direct Debit Transfers"
                    key={SectionKeys.DIRECT_DEBIT_TRANSFERS}
                    collapsible={directDebitTransfers.length > 0 ? 'header' : 'disabled'}
                >
                    <DirectDebitTransfers data={directDebitTransfers} />
                </Panel>
                <Panel
                    header="VRP Mandates"
                    key={SectionKeys.VRP_MANDATES}
                    collapsible={'disabled'}
                ></Panel>
                <Panel
                    header="VRP Transfers"
                    key={SectionKeys.VRP_TRANSFERS}
                    collapsible={'disabled'}
                ></Panel>
                <Panel
                    header="Approved Proof Of Funds"
                    key={SectionKeys.PROOF_OF_FUNDS}
                    collapsible={proofOfFunds.length > 0 ? 'header' : 'disabled'}
                >
                    <ProofOfFundsSection data={proofOfFunds} />
                </Panel>
            </Collapse>
        </Space>
    )
}

export default OverviewView
