import type { FC } from 'react'
import { UserOutlined, ShopOutlined, WarningOutlined } from '@ant-design/icons'

import type { EntityType } from 'types/styx'
import { exhaustiveCheck } from 'utils/exhaustive-check'

const EntityIcon: FC<React.PropsWithChildren<{ type: EntityType }>> = ({ type }) => {
    switch (type) {
        case 'PERSON':
            return <UserOutlined />
        case 'COMPANY':
            return <ShopOutlined />
        case 'UNKNOWN':
            return <WarningOutlined />
        default:
            return exhaustiveCheck(type)
    }
}

export default EntityIcon
