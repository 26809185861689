import type { FC } from 'react'
import { Card, Table } from 'antd'
import type { ColumnProps } from 'antd/es/table'
import dayjs from 'packages/dayjs'

import { PageContentLayout, PointerContainer } from 'components/layout-containers'
import { useEmployeeBankTransfers } from 'services/eirene/bank-transfers'
import type { BackOfficeReimbursementResponseModel } from 'bff/moons/generated/eirene-v1'
import { WalletReimbursementTypeResponseModel } from 'bff/moons/generated/eirene-v1'
import { getEmojiFlag } from 'countries-list'
import { useNavigate } from 'react-router-dom'

interface Props {
    transfers: BackOfficeReimbursementResponseModel[]
    loading: boolean
    onTransferClick: (transfer: BackOfficeReimbursementResponseModel) => void
}

export const BankTransfers: FC<Props> = ({ transfers, loading, onTransferClick }) => {
    const reimbursementTypeMap = new Map<WalletReimbursementTypeResponseModel, string>([
        [WalletReimbursementTypeResponseModel.BANK_TRANSFER, 'BANK TRANSFER'],
        [WalletReimbursementTypeResponseModel.CARD, 'CARD'],
    ])
    const columns: ColumnProps<BackOfficeReimbursementResponseModel>[] = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Bank Name',
            dataIndex: 'bankDetails',
            render: (bankDetails) =>
                `${bankDetails.name} (${getEmojiFlag(bankDetails.countryCode)} ${
                    bankDetails.countryCode
                })`,
        },
        {
            title: 'Employee',
            dataIndex: 'employeeDetails',
            render: (employeeDetails) => employeeDetails.employeeName,
        },
        {
            title: 'Company',
            dataIndex: 'company',
            render: (company) => company.name,
        },
        {
            title: 'Bank Screening Status',
            dataIndex: 'screeningStatus',
            render: (screeningStatus) => screeningStatus.bank,
        },
        {
            title: 'Employee Screening Status',
            dataIndex: 'screeningStatus',
            render: (screeningStatus) => screeningStatus.employee,
        },
        {
            title: 'Reimbursement Type',
            dataIndex: 'reimbursementType',
            render: (type) => reimbursementTypeMap.get(type),
        },
        {
            title: 'Requested At',
            dataIndex: 'requestedAt',
            render: (requestedDate: string) => dayjs(requestedDate).format('lll'),
        },
    ]
    return (
        <Table
            columns={columns}
            dataSource={transfers}
            rowKey="id"
            loading={loading}
            onRow={(record) => ({
                onClick: () => onTransferClick(record),
            })}
            pagination={false}
        />
    )
}

const BankTransfersContainer = () => {
    const { bankTransfers, isValidating } = useEmployeeBankTransfers()
    const navigate = useNavigate()

    return (
        <PageContentLayout>
            <Card title="Latest reimbursements pending for screening review">
                <PointerContainer>
                    <BankTransfers
                        transfers={bankTransfers ?? []}
                        loading={!bankTransfers && isValidating}
                        onTransferClick={({ id }: BackOfficeReimbursementResponseModel) =>
                            navigate(`${id}`)
                        }
                    />
                </PointerContainer>
            </Card>
        </PageContentLayout>
    )
}

export default BankTransfersContainer
