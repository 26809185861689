import type { FC } from 'react'
import {
    Button as AntdButton,
    ButtonProps,
    Descriptions,
    message,
    notification,
    Popconfirm,
    Space,
    Typography,
} from 'antd'
import { ReactComponent as VirtualCard } from 'resources/virtual_card_icon.svg'
import { ReactComponent as PhysicalCard } from 'resources/physical_card_icon.svg'
import { ReactComponent as VendorCard } from 'resources/vendor_card_icon.svg'
import { ReactComponent as TemporaryCard } from 'resources/temporary_card_icon.svg'
import { bff } from '../../bff-hooks'

import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { Type } from 'types/card'
import { uppercaseFirstLetter } from 'utils/strings'
import { ActionsContainer } from 'components/layout-containers'
import { spacing } from '../../../theme/tokens'
import styled from 'styled-components'
import { LinkOutlined } from '@ant-design/icons'
import { buildCardStatusMBLink, buildArielMBLink, buildDeimosTransactionsMBLink } from './utils'
import type { EmployeeCards } from '../../index.bff'
import { decryptPayload, generateKeyPair } from '../../../utils/crypto'

const { Text } = Typography

const getCardTypeIcon = (cardType: EmployeeCards['cardType']) => {
    switch (cardType) {
        case Type.VIRTUAL:
            return <VirtualCard />
        case Type.PHYSICAL:
            return <PhysicalCard />
        case Type.DEDICATED:
            return <VendorCard />
        case Type.TEMPORARY:
            return <TemporaryCard />
        default:
            return null
    }
}

const CardType: FC<
    React.PropsWithChildren<{ cardType: EmployeeCards['cardType']; cardName?: string }>
> = ({ cardType, cardName }) => (
    <Space>
        {getCardTypeIcon(cardType)}
        {uppercaseFirstLetter(cardType === 'DEDICATED' ? 'Vendor' : cardType)}
        {cardName && cardType === 'DEDICATED' && `• ${cardName}`}
    </Space>
)

interface CardProps extends ActionsProps {
    card: EmployeeCards
}

interface ActionsProps {
    onChangeStatus: (cardId: string) => void
    onResetPinTries: (cardId: string) => void
}

const ResetPinTriesConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Reset PIN tries?</Text>
        <Text>Doing this will enable the user to re-enter their PIN code.</Text>
    </Space>
)

const getCardStatus = (card: EmployeeCards) => {
    const status = uppercaseFirstLetter(card.status)
    if (card.status !== 'RESTRICTED') return status
    if (card.pinTriesExceeded) return `${status} - Pin tries exceeded`
    if (card.disabledByAdmin) return `${status} - Disabled by admin`
    if (card.disabledByLimit) return `${status} - Disabled by limit`
    return `${status} - Reason unknown`
}

const Button = (props: ButtonProps) => (
    <AntdButton onMouseDown={(e) => e.preventDefault()} {...props} />
)

const CardTitleRoot = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: ${spacing.space16};
`

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    & > button {
        margin: 0 4px;
    }
`

export const Card: FC<React.PropsWithChildren<CardProps>> = ({
    card,
    onChangeStatus,
    onResetPinTries,
}) => {
    const { mutateAsync: fetchCardPan } =
        bff.components.employeeCards.getDecryptedCardPan.useMutation()

    const onViewCardPan = async () => {
        try {
            const keyPair = await generateKeyPair()
            if (keyPair.privateKey == undefined) {
                message.error('Unable to generate Key pair')
                return
            }

            const encryptedCardPan = await fetchCardPan({
                cardId: card.id,
                publicKey: keyPair.publicKey,
            })
            const decryptedPan = await decryptPayload(
                encryptedCardPan.encryptedPan,
                keyPair.privateKey
            )

            delete keyPair.privateKey

            await navigator.clipboard.writeText(decryptedPan!)
            message.success('Copied pan to clipboard')
        } catch (e) {
            notification.error({
                description: 'Unable to retrieve PAN',
                message: (e as Error).message,
            })
        }
    }

    const CardTitle = () => (
        <CardTitleRoot>
            <CardType cardType={card.cardType} cardName={card.cardName} />
            <ButtonContainer>
                <Button onClick={() => onChangeStatus(card.id)}>Change status</Button>
                {card.cardType === Type.PHYSICAL && (
                    <Popconfirm
                        title={<ResetPinTriesConfirmation />}
                        onConfirm={() => onResetPinTries(card.id)}
                        okText="Yes"
                        cancelText="No"
                        overlayStyle={{ width: '350px' }}
                        placement="topRight"
                    >
                        <Button>Reset PIN tries</Button>
                    </Popconfirm>
                )}
            </ButtonContainer>
        </CardTitleRoot>
    )
    return (
        <>
            <Descriptions column={2} size="small" title={<CardTitle />}>
                <Descriptions.Item label="Card Id." span={2}>
                    <Text copyable>{card.id}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Status">{getCardStatus(card)}</Descriptions.Item>
                <Descriptions.Item label="PAN">
                    <Text copyable>{card.panLast4}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Storebox">
                    {card.storebox ? 'Yes' : 'No'}
                </Descriptions.Item>
                {useHasPermissions(['card:pan']) && (
                    <Descriptions.Item label="Full PAN">
                        <ActionsContainer>
                            <Button type="link" onClick={onViewCardPan}>
                                Copy to clipboard
                            </Button>
                        </ActionsContainer>
                    </Descriptions.Item>
                )}
            </Descriptions>
            <Space>
                <Space wrap>
                    <AntdButton
                        href={buildCardStatusMBLink(card.id)}
                        rel="no-referrer noopener"
                        target="_blank"
                        icon={<LinkOutlined />}
                    >
                        Card Status
                    </AntdButton>
                </Space>
                <Space wrap>
                    <AntdButton
                        href={buildArielMBLink(card.id)}
                        rel="no-referrer noopener"
                        target="_blank"
                        icon={<LinkOutlined />}
                    >
                        Ariel
                    </AntdButton>
                </Space>
                <Space wrap>
                    <AntdButton
                        href={buildDeimosTransactionsMBLink(card.id)}
                        rel="no-referrer noopener"
                        target="_blank"
                        icon={<LinkOutlined />}
                    >
                        Deimos Transactions
                    </AntdButton>
                </Space>
            </Space>
        </>
    )
}
