import { Typography } from 'antd'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

const { Text } = Typography

export const PageContentLayout = styled.div`
    padding: ${spacing.space24};
    display: flex;
    flex-direction: column;
    gap: ${spacing.space24};
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space24};
`

export const PointerContainer = styled.div`
    & .ant-table-tbody tr {
        cursor: pointer;
    }
`

export const ActionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 0 ${spacing.space16};

    & button {
        padding: 0;
        height: auto;
    }
`

export const BreakText = styled(Text)`
    word-break: break-all;
`
