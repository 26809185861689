import type { FC } from 'react'
import { message } from 'antd'
import { Formik, FormikHelpers } from 'formik'
import { Form, Select, SubmitButton } from 'formik-antd'

import { Role, Entity } from 'types/styx'
import { getEntityName } from 'pages/compliance/shared/utils'
import { deSnakify } from 'utils/strings'
import KycPopover from 'pages/compliance/company/ownership/edit-ownership/kyc-popover'
import type { OwnershipMutations } from 'services/deimos/styx-company/ownership'

import { validationSchema, FormValues, mapValuesToRequest } from './validation-schema'

const { Option, OptGroup } = Select

interface Props {
    disabled: boolean
    unassigned: Entity[]
    assigned: Entity[]
    onAddDirectors: OwnershipMutations['addDirectors']
}

const AddDirectorForm: FC<React.PropsWithChildren<Props>> = ({
    unassigned,
    assigned,
    disabled,
    onAddDirectors,
}) => {
    const onSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        try {
            const allEntities = [...unassigned, ...assigned]
            await onAddDirectors(mapValuesToRequest(values, allEntities))
            resetForm()
            message.success(`Added ${values.selectedEntities.length} directors!`)
        } catch (e) {
            message.error(`Failed to add directors: ${(e as Error).message}`)
        }
    }

    const initialValues: FormValues = { selectedEntities: [], role: '' }

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            <Form layout="vertical">
                <Form.Item name="selectedEntities" label="Add directors">
                    <Select
                        optionFilterProp="label"
                        disabled={disabled}
                        name="selectedEntities"
                        mode="multiple"
                        placeholder="Select entities to add"
                    >
                        <OptGroup label="Unassigned Entities">
                            {unassigned.map((entity) => (
                                <Option
                                    label={getEntityName(entity)}
                                    key={entity.id}
                                    value={entity.id}
                                >
                                    <KycPopover entity={entity}>{getEntityName(entity)}</KycPopover>
                                </Option>
                            ))}
                        </OptGroup>
                        <OptGroup label="Assigned Entities">
                            {assigned.map((entity) => (
                                <Option
                                    label={getEntityName(entity)}
                                    key={entity.id}
                                    value={entity.id}
                                >
                                    <KycPopover entity={entity}>{getEntityName(entity)}</KycPopover>
                                </Option>
                            ))}
                        </OptGroup>
                    </Select>
                </Form.Item>
                <Form.Item name="role" help="Role applied to all directors">
                    <Select
                        disabled={disabled}
                        name="role"
                        placeholder="Select director role"
                        showSearch
                    >
                        {Object.values(Role).map((role) => (
                            <Option key={role} value={role}>
                                {deSnakify(role)}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <SubmitButton data-testid="submit">Add</SubmitButton>
            </Form>
        </Formik>
    )
}

export default AddDirectorForm
