import type { ReactElement } from 'react'
import { useFormikContext } from 'formik'
import { Tooltip } from 'antd'
import dayjs from '../../../../../../packages/dayjs'
import type { FormValuesExtended } from '../generate-extended-demo-form'

const ExpiresAtTooltip = ({ children }: { children: ReactElement }) => {
    const { values } = useFormikContext<FormValuesExtended>()

    if (!values.expiresAt) {
        return children
    }

    return (
        <Tooltip
            color="red"
            placement="right"
            title={`This account will expire ${dayjs(values.expiresAt).fromNow()}`}
        >
            {children}
        </Tooltip>
    )
}
export default ExpiresAtTooltip
