import { ExportOutlined, LineChartOutlined, RetweetOutlined } from '@ant-design/icons'
import type { FC } from 'react'
import { Link, Navigate, Route, useParams } from 'react-router-dom'

import Header from 'components/header/header'
import ProtectedRoute from 'router/protected-route'

import Page from 'pages/page-layout'
import { deKebabify } from 'utils/strings'

import { FallbackForbidden } from 'components/fallback-forbidden'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { useColorScheme } from 'hooks/use-color-scheme'
import { SideMenu } from 'pages/page-layout-components'
import CashbackReports from './cashback/cashback-reports'
import MastercardAdvisements from './mastercard-advisements/mastercard-advisements'
import RevenueRecognition from './revenue-recognition/revenue-recognition'
import TransactionsExport from './transaction-exports/transaction-exports'

const Navigation: FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const { colorScheme } = useColorScheme()

    const hasCadmusPermissions = useHasPermissions(['cadmus'])

    return (
        <SideMenu $colorScheme={colorScheme} selectedKeys={[id]} theme="dark">
            <SideMenu.Item key="mastercard-advisements">
                <RetweetOutlined />
                <span>MasterCard Advisements</span>
                <Link to="../mastercard-advisements" />
            </SideMenu.Item>
            <SideMenu.Item key="transactions-export">
                <ExportOutlined />
                <span>Transactions Export</span>
                <Link to="../transactions-export" />
            </SideMenu.Item>
            {hasCadmusPermissions && (
                <SideMenu.Item key="revenue-recognition">
                    <LineChartOutlined />
                    <span>Revenue Recognition</span>
                    <Link to="../revenue-recognition" />
                </SideMenu.Item>
            )}
            {hasCadmusPermissions && (
                <SideMenu.Item key="cashback-reports">
                    <LineChartOutlined />
                    <span>Cashback reports</span>
                    <Link to="../cashback-reports" />
                </SideMenu.Item>
            )}
        </SideMenu>
    )
}

const PageHeader = () => {
    const { id: selectedSidebarItem } = useParams()
    const headerTitle = deKebabify(selectedSidebarItem)
    return <Header title={headerTitle}></Header>
}

const FinancePage: FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <Page
            sideBarLabel="Finance"
            sideBar={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id" element={<Navigation />} />
                </Route>
            }
            header={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id" element={<PageHeader />} />
                </Route>
            }
        >
            <Route element={<ProtectedRoute />}>
                <Route path="*" element={<Navigate to="mastercard-advisements" />} />
                <Route
                    element={
                        <ProtectedRoute
                            fallback={<FallbackForbidden goBackLink="/customer-success" />}
                            requiredPermissions={['endor', 'phobos']}
                        />
                    }
                >
                    <Route path="mastercard-advisements" element={<MastercardAdvisements />} />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            requiredPermissions={['endor']}
                            fallback={<FallbackForbidden goBackLink="/customer-success" />}
                        />
                    }
                >
                    <Route path="transactions-export" element={<TransactionsExport />} />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            requiredPermissions={['cadmus']}
                            fallback={<FallbackForbidden goBackLink="/customer-success" />}
                        />
                    }
                >
                    <Route path="revenue-recognition" element={<RevenueRecognition />} />
                    <Route path="cashback-reports" element={<CashbackReports />} />
                </Route>
            </Route>
        </Page>
    )
}

export default FinancePage
