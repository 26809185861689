/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Cupid Client
 * OpenAPI spec version: 1.0.0
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('cupidV1')

export type GetSummariesParams = {
    /**
     * Unload summary (CSV) after (time window start)
     */
    createdAfter?: string
    /**
     * Unload summary (CSV)  before (time window end)
     */
    createdBefore?: string
}

export type GetUnloadsByStatusStatusesItem =
    (typeof GetUnloadsByStatusStatusesItem)[keyof typeof GetUnloadsByStatusStatusesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUnloadsByStatusStatusesItem = {
    NEW: 'NEW',
    CANCELLED: 'CANCELLED',
    UNDER_INVESTIGATION: 'UNDER_INVESTIGATION',
    APPROVED: 'APPROVED',
    UNLOADED: 'UNLOADED',
    PENDING_REFUND: 'PENDING_REFUND',
    EXPORTED: 'EXPORTED',
    FAILED: 'FAILED',
    PROCESSING: 'PROCESSING',
} as const

export type GetUnloadsByStatusParams = {
    statuses?: GetUnloadsByStatusStatusesItem[]
    /**
     * Unload created after (time window start)
     */
    createdAfter?: string
    /**
     * Unload created before (time window end)
     */
    createdBefore?: string
}

export type RunSchedulerJobType = (typeof RunSchedulerJobType)[keyof typeof RunSchedulerJobType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RunSchedulerJobType = {
    AUTOGIRO_AGREEMENT: 'AUTOGIRO_AGREEMENT',
    CHECKS: 'CHECKS',
    DAILY_MONITOR: 'DAILY_MONITOR',
    INVOICE: 'INVOICE',
    NETS_AGREEMENT: 'NETS_AGREEMENT',
    SCHEDULED_WALLET_LOADS: 'SCHEDULED_WALLET_LOADS',
    WALLET_LOAD_MONITOR: 'WALLET_LOAD_MONITOR',
} as const

export type RunSchedulerJobParams = {
    type?: RunSchedulerJobType
}

export type GetInvoiceLoadsPaginatedParams = {
    /**
     * The (zero-based) offset of the first item in the collection to return
     */
    offset?: number
    /**
     * The maximum number of entries to return. Default is 20 and maximum is 100 entries per request
     */
    limit?: number
}

export type CreateVrpInvoiceAndPublishEventParams = {
    companyId?: string
}

export type CreateVrpInvoiceParams = {
    companyId?: string
}

export type CreateDirectDebitInvoiceAndPublishEventParams = {
    companyId?: string
}

export type CreateDirectDebitInvoiceParams = {
    companyId?: string
}

export type GetPaginatedCompanyInvoicesParams = {
    /**
     * The (zero-based) offset of the first item in the collection to return
     */
    offset?: number
    /**
     * The maximum number of entries to return. Default is 20 and maximum is 100 entries per request
     */
    limit?: number
    company_id?: string
}

export type GetDirectDebitTransferCurrency =
    (typeof GetDirectDebitTransferCurrency)[keyof typeof GetDirectDebitTransferCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDirectDebitTransferCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export type GetDirectDebitTransferParams = {
    currency?: GetDirectDebitTransferCurrency
}

export type GetDirectDebitEligibilityParams = {
    companyId?: string
}

export type GetWalletLoadsStatus = (typeof GetWalletLoadsStatus)[keyof typeof GetWalletLoadsStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWalletLoadsStatus = {
    BLOCKED: 'BLOCKED',
    APPROVED: 'APPROVED',
    FAILED: 'FAILED',
    LOADED: 'LOADED',
} as const

export type GetWalletLoadsParams = {
    /**
     * The (zero-based) offset of the first item in the collection to return
     */
    offset?: number
    /**
     * The maximum number of entries to return. Default is 20 and maximum is 100 entries per request
     */
    limit?: number
    /**
     * The entries filtered by a specified statuses. If not specified (null or empty), filtering by statuses will be ignored
     */
    status?: GetWalletLoadsStatus
}

export type GetWalletFeaturesFeature =
    (typeof GetWalletFeaturesFeature)[keyof typeof GetWalletFeaturesFeature]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWalletFeaturesFeature = {
    AUTO_TOPUP: 'AUTO_TOPUP',
    OPEN_BANKING: 'OPEN_BANKING',
    WALLET_WITHDRAWAL: 'WALLET_WITHDRAWAL',
    DIRECT_DEBIT: 'DIRECT_DEBIT',
    SCHEDULED_TOPUP: 'SCHEDULED_TOPUP',
    SPEND_PROJECTION: 'SPEND_PROJECTION',
    VRP: 'VRP',
    SUB_WALLET: 'SUB_WALLET',
} as const

export type GetWalletFeaturesParams = {
    feature?: GetWalletFeaturesFeature
}

export type GetWalletActivitiesStatus =
    (typeof GetWalletActivitiesStatus)[keyof typeof GetWalletActivitiesStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWalletActivitiesStatus = {
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    PENDING: 'PENDING',
} as const

export type GetWalletActivitiesParams = {
    /**
     * The (zero-based) offset of the first item in the collection to return
     */
    offset?: number
    /**
     * The maximum number of entries to return. Default is 20 and maximum is 100 entries per request
     */
    limit?: number
    /**
     * The entries filtered by a specified status. If not specified, status will be ignored
     */
    status?: GetWalletActivitiesStatus
}

export type GetConsentTextParams = {
    companyId?: string
}

export type GenerateBacsAgreementIdParams = {
    companyId?: string
}

export type UpdateWalletLoadParams = {
    scheduledAtNow?: boolean
}

export type GetCompanyCache = { [key: string]: string }

export type FetchDirectDebitInvoiceParams = {
    statuses?: string
}

export type WalletWithdrawalResponseStatus =
    (typeof WalletWithdrawalResponseStatus)[keyof typeof WalletWithdrawalResponseStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletWithdrawalResponseStatus = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
} as const

export interface WalletWithdrawalResponse {
    amount: Money
    companyId: string
    createdAt: string
    id: string
    sourceId: string
    status: WalletWithdrawalResponseStatus
    updatedAt: string
}

export interface WalletWithdrawalRequest {
    amount: Money
    senderBank: string
    senderInformation?: string
}

export interface WalletUnloadSummary {
    bank: string
    createdAt: string
    createdBy: string
    id: string
    s3key: string
}

export type WalletUnloadResponseStatus =
    (typeof WalletUnloadResponseStatus)[keyof typeof WalletUnloadResponseStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletUnloadResponseStatus = {
    NEW: 'NEW',
    CANCELLED: 'CANCELLED',
    UNDER_INVESTIGATION: 'UNDER_INVESTIGATION',
    APPROVED: 'APPROVED',
    UNLOADED: 'UNLOADED',
    PENDING_REFUND: 'PENDING_REFUND',
    EXPORTED: 'EXPORTED',
    FAILED: 'FAILED',
    PROCESSING: 'PROCESSING',
} as const

export type WalletUnloadResponseRequestSource =
    (typeof WalletUnloadResponseRequestSource)[keyof typeof WalletUnloadResponseRequestSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletUnloadResponseRequestSource = {
    CLIENT: 'CLIENT',
    OFFBOARDING: 'OFFBOARDING',
    REFUND: 'REFUND',
    RETURNED_DIRECT_DEBIT: 'RETURNED_DIRECT_DEBIT',
} as const

export type WalletUnloadResponseRefundStatus =
    (typeof WalletUnloadResponseRefundStatus)[keyof typeof WalletUnloadResponseRefundStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletUnloadResponseRefundStatus = {
    NON_REFUNDABLE: 'NON_REFUNDABLE',
    PENDING: 'PENDING',
    EXPORTED: 'EXPORTED',
} as const

export interface WalletUnloadResponse {
    account: BeneficiaryAccount
    amount: Money
    beneficiaryAddress: PostalAddress
    companyId: string
    createdAt: string
    createdBy: string
    documentUrls: string[]
    fullAmount: boolean
    id: string
    internalReference?: string
    note: string
    refundStatus?: WalletUnloadResponseRefundStatus
    requestSource?: WalletUnloadResponseRequestSource
    status: WalletUnloadResponseStatus
    statusReason?: string
    updatedAt: string
    updatedBy: string
}

export type WalletUnloadRequestStatus =
    (typeof WalletUnloadRequestStatus)[keyof typeof WalletUnloadRequestStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletUnloadRequestStatus = {
    NEW: 'NEW',
    CANCELLED: 'CANCELLED',
    UNDER_INVESTIGATION: 'UNDER_INVESTIGATION',
    APPROVED: 'APPROVED',
    UNLOADED: 'UNLOADED',
    PENDING_REFUND: 'PENDING_REFUND',
    EXPORTED: 'EXPORTED',
    FAILED: 'FAILED',
    PROCESSING: 'PROCESSING',
} as const

export type WalletUnloadRequestRequestSource =
    (typeof WalletUnloadRequestRequestSource)[keyof typeof WalletUnloadRequestRequestSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletUnloadRequestRequestSource = {
    CLIENT: 'CLIENT',
    OFFBOARDING: 'OFFBOARDING',
    REFUND: 'REFUND',
    RETURNED_DIRECT_DEBIT: 'RETURNED_DIRECT_DEBIT',
} as const

export type WalletUnloadRequestRefundStatus =
    (typeof WalletUnloadRequestRefundStatus)[keyof typeof WalletUnloadRequestRefundStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletUnloadRequestRefundStatus = {
    NON_REFUNDABLE: 'NON_REFUNDABLE',
    PENDING: 'PENDING',
    EXPORTED: 'EXPORTED',
} as const

export interface WalletUnloadRequest {
    account: BeneficiaryAccount
    amount: Money
    beneficiaryAddress: PostalAddress
    companyId: string
    documentUrls: string[]
    fullAmount: boolean
    internalReference?: string
    note: string
    refundStatus?: WalletUnloadRequestRefundStatus
    requestSource?: WalletUnloadRequestRequestSource
    status: WalletUnloadRequestStatus
    statusReason?: string
}

export type WalletActivityType = (typeof WalletActivityType)[keyof typeof WalletActivityType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletActivityType = {
    LOAD: 'LOAD',
    UNLOAD: 'UNLOAD',
    WITHDRAWAL: 'WITHDRAWAL',
} as const

export type WalletActivityStatus = (typeof WalletActivityStatus)[keyof typeof WalletActivityStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletActivityStatus = {
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    PENDING: 'PENDING',
} as const

export type WalletActivityBlockedReason =
    (typeof WalletActivityBlockedReason)[keyof typeof WalletActivityBlockedReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletActivityBlockedReason = {
    DAILY_LOAD_AMOUNT_LIMIT_REACHED: 'DAILY_LOAD_AMOUNT_LIMIT_REACHED',
    YEARLY_LOAD_AMOUNT_LIMIT_REACHED: 'YEARLY_LOAD_AMOUNT_LIMIT_REACHED',
    PENDING_VALIDATION: 'PENDING_VALIDATION',
    PENDING_FRAUD_CHECK: 'PENDING_FRAUD_CHECK',
} as const

export interface WalletActivity {
    activityUpdatedAt?: string
    amount: Money
    blockedReason?: WalletActivityBlockedReason
    companyId: string
    failedAt?: string
    isAutomatic: boolean
    loadScheduledAt?: string
    performedAt?: string
    referenceId: string
    status: WalletActivityStatus
    type: WalletActivityType
}

export type VWalletFeatureResponseFeaturesItem =
    (typeof VWalletFeatureResponseFeaturesItem)[keyof typeof VWalletFeatureResponseFeaturesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VWalletFeatureResponseFeaturesItem = {
    AUTO_TOPUP: 'AUTO_TOPUP',
    OPEN_BANKING: 'OPEN_BANKING',
    WALLET_WITHDRAWAL: 'WALLET_WITHDRAWAL',
    DIRECT_DEBIT: 'DIRECT_DEBIT',
    SCHEDULED_TOPUP: 'SCHEDULED_TOPUP',
    SPEND_PROJECTION: 'SPEND_PROJECTION',
    VRP: 'VRP',
    SUB_WALLET: 'SUB_WALLET',
} as const

export interface V4WalletFeatureResponse {
    autoTopupMinimumAmount?: number
    features: VWalletFeatureResponseFeaturesItem[]
    openBankingTransactionLimit?: number
}

export interface V4VrpInvoiceCreateRequestModel {
    amount: number
    currency: string
    reference: string
}

export interface V4SenderDetailsResponseModel {
    senderBank?: string
    senderInformation?: string
}

export type VNewFraudCheckInputStatus =
    (typeof VNewFraudCheckInputStatus)[keyof typeof VNewFraudCheckInputStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VNewFraudCheckInputStatus = {
    BLOCKED: 'BLOCKED',
    PASS: 'PASS',
    WARN: 'WARN',
    SUSPEND: 'SUSPEND',
} as const

export interface V4NewFraudCheckInput {
    companyId: string
    status: VNewFraudCheckInputStatus
}

export type VInvoiceResponseModelStatus =
    (typeof VInvoiceResponseModelStatus)[keyof typeof VInvoiceResponseModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VInvoiceResponseModelStatus = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    CANCELLED: 'CANCELLED',
    MANUALLY_APPROVED: 'MANUALLY_APPROVED',
} as const

export type VInvoiceResponseModelPaymentSystem =
    (typeof VInvoiceResponseModelPaymentSystem)[keyof typeof VInvoiceResponseModelPaymentSystem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VInvoiceResponseModelPaymentSystem = {
    INPAY: 'INPAY',
    TRUSTLY: 'TRUSTLY',
    INTERNAL: 'INTERNAL',
    TELESTO: 'TELESTO',
    OPEN_BANKING: 'OPEN_BANKING',
    ACH: 'ACH',
} as const

export interface V4InvoiceResponseModel {
    amount: Money
    bank?: Bank
    callbackId?: string
    companyId: string
    createdAt: string
    directDebit?: DirectDebit
    id: string
    isAutomatic: boolean
    loads?: string[]
    paymentSystem: VInvoiceResponseModelPaymentSystem
    receivedSum?: number
    reference: string
    status: VInvoiceResponseModelStatus
}

export type VFraudCheckResponseStatus =
    (typeof VFraudCheckResponseStatus)[keyof typeof VFraudCheckResponseStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VFraudCheckResponseStatus = {
    BLOCKED: 'BLOCKED',
    PASS: 'PASS',
    WARN: 'WARN',
    SUSPEND: 'SUSPEND',
} as const

export interface V4FraudCheckResponse {
    companyId: string
    createdAt: string
    id: string
    status: VFraudCheckResponseStatus
}

export type VFraudCheckRequestStatus =
    (typeof VFraudCheckRequestStatus)[keyof typeof VFraudCheckRequestStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VFraudCheckRequestStatus = {
    BLOCKED: 'BLOCKED',
    PASS: 'PASS',
    WARN: 'WARN',
    SUSPEND: 'SUSPEND',
} as const

export interface V4FraudCheckRequest {
    status: VFraudCheckRequestStatus
}

export interface V4DirectDebitTransferGetResponse {
    amount: number
    companyId: string
    invoiceReference: string
    transferId: string
}

export interface V4DirectDebitInvoiceResponseModel {
    amount: Money
    callbackId?: string
    companyId: string
    createdAt: string
    id: string
    projectedLoadedAt?: string
    status: string
}

export interface V4DirectDebitInvoiceRequestModel {
    amount: Money
    isAutomatic: boolean
}

export interface V4DirectDebitInvoiceCreateRequestModel {
    amount: Money
    isAutomatic: boolean
    senderBank: string
    senderInformation?: string
}

export type VDirectDebitEligibilityResponseStatus =
    (typeof VDirectDebitEligibilityResponseStatus)[keyof typeof VDirectDebitEligibilityResponseStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const VDirectDebitEligibilityResponseStatus = {
    ELIGIBLE: 'ELIGIBLE',
    INELIGIBLE: 'INELIGIBLE',
} as const

export interface V4DirectDebitEligibilityResponse {
    companyId: string
    status: VDirectDebitEligibilityResponseStatus
}

export type UpdateUnloadStatusRequestNewStatus =
    (typeof UpdateUnloadStatusRequestNewStatus)[keyof typeof UpdateUnloadStatusRequestNewStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateUnloadStatusRequestNewStatus = {
    NEW: 'NEW',
    CANCELLED: 'CANCELLED',
    UNDER_INVESTIGATION: 'UNDER_INVESTIGATION',
    APPROVED: 'APPROVED',
    UNLOADED: 'UNLOADED',
    PENDING_REFUND: 'PENDING_REFUND',
    EXPORTED: 'EXPORTED',
    FAILED: 'FAILED',
    PROCESSING: 'PROCESSING',
} as const

export interface UpdateUnloadStatusRequest {
    newStatus: UpdateUnloadStatusRequestNewStatus
}

export type UpdateInvoiceRequestStatus =
    (typeof UpdateInvoiceRequestStatus)[keyof typeof UpdateInvoiceRequestStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateInvoiceRequestStatus = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    CANCELLED: 'CANCELLED',
    MANUALLY_APPROVED: 'MANUALLY_APPROVED',
} as const

export interface UpdateInvoiceRequest {
    receivedSum?: number
    status?: UpdateInvoiceRequestStatus
}

export type UnloadFileResponseUrls = { [key: string]: string }

export interface UnloadFileResponse {
    urls?: UnloadFileResponseUrls
}

export interface UnloadCsvRequest {
    ids: string[]
}

export interface SepaTransferSyncRequest {
    ids: string[]
}

export interface SepaAgreementStatusUpdate {
    agreementId: string
    status: string
}

export interface RetryWalletLoadRequest {
    targetDate?: string
    walletLoadIds: string[]
}

export type ResultDetails = { [key: string]: { [key: string]: any } }

export type ResultComponents = { [key: string]: ComponentResult }

export interface Result {
    components: ResultComponents
    details?: ResultDetails
    healthy: boolean
    message?: string
    time: string
}

export interface PresignedUrl {
    fileType: string
    url: string
}

export type PostalAddressCountry = (typeof PostalAddressCountry)[keyof typeof PostalAddressCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostalAddressCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface PostalAddress {
    addressLine1?: string
    addressLine2?: string
    country?: PostalAddressCountry
    locality?: string
    name?: string
    postalCode?: string
    region?: string
}

export interface PaymentResponse {
    paymentId: string
}

export interface PaymentRequest {
    consentToken: string
}

export interface PaymentAuthorizationResponse {
    institutionAuthorizationUrl: string
}

export interface PaymentAuthorizationRequest {
    accountIdentifications?: AccountIdentificationV4Model[]
    amount: Money
    callBackUrl: string
    institutionId: string
}

export interface PaginatedResponseWalletActivity {
    result: WalletActivity[]
    totalItems: number
}

export interface PaginatedResponseLoad {
    result: Load[]
    totalItems: number
}

export interface PaginatedResponseFullWalletLoad {
    result: FullWalletLoad[]
    totalItems: number
}

export interface PaginatedResponseFullInvoice {
    result: FullInvoice[]
    totalItems: number
}

export interface NetsMappingResolutionModel {
    companyId?: string
    cvr: string
}

export interface NetsCompanyMappingModel {
    companyId: string
    cvr: string
    id: string
}

export interface Money {
    currency?: string
    value?: number
}

export type LoadStatus = (typeof LoadStatus)[keyof typeof LoadStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LoadStatus = {
    BLOCKED: 'BLOCKED',
    APPROVED: 'APPROVED',
    QUEUED: 'QUEUED',
    PROCESSING: 'PROCESSING',
    FAILED: 'FAILED',
    LOADED: 'LOADED',
    CANCELLED: 'CANCELLED',
    RETURNED: 'RETURNED',
} as const

export interface Load {
    amount: Money
    createdAt: string
    id: string
    invoice: string
    loadedAt?: string
    scheduledAt?: string
    status: LoadStatus
}

export interface InvoiceLoadRequest {
    amount: Money
    bankTransactionId: string
    callbackId?: string
    reference?: string
    senderBank?: string
    senderInformation?: string
}

export interface InvoiceLoadByReferenceRequest {
    bankTransactionId: string
    mandateId?: string
    receivedAmount: Money
    senderBank?: string
    senderInformation?: string
}

export type FullWalletLoadPaymentSystem =
    (typeof FullWalletLoadPaymentSystem)[keyof typeof FullWalletLoadPaymentSystem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FullWalletLoadPaymentSystem = {
    INPAY: 'INPAY',
    TRUSTLY: 'TRUSTLY',
    INTERNAL: 'INTERNAL',
    TELESTO: 'TELESTO',
    OPEN_BANKING: 'OPEN_BANKING',
    ACH: 'ACH',
} as const

export type FullWalletLoadCurrency =
    (typeof FullWalletLoadCurrency)[keyof typeof FullWalletLoadCurrency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FullWalletLoadCurrency = {
    UNDEFINED: 'UNDEFINED',
    AED: 'AED',
    AFN: 'AFN',
    ALL: 'ALL',
    AMD: 'AMD',
    ANG: 'ANG',
    AOA: 'AOA',
    ARS: 'ARS',
    AUD: 'AUD',
    AWG: 'AWG',
    AZN: 'AZN',
    BAM: 'BAM',
    BBD: 'BBD',
    BDT: 'BDT',
    BGN: 'BGN',
    BHD: 'BHD',
    BIF: 'BIF',
    BMD: 'BMD',
    BND: 'BND',
    BOB: 'BOB',
    BOV: 'BOV',
    BRL: 'BRL',
    BSD: 'BSD',
    BTN: 'BTN',
    BWP: 'BWP',
    BYN: 'BYN',
    BYR: 'BYR',
    BZD: 'BZD',
    CAD: 'CAD',
    CDF: 'CDF',
    CHE: 'CHE',
    CHF: 'CHF',
    CHW: 'CHW',
    CLF: 'CLF',
    CLP: 'CLP',
    CNY: 'CNY',
    COP: 'COP',
    COU: 'COU',
    CRC: 'CRC',
    CUC: 'CUC',
    CUP: 'CUP',
    CVE: 'CVE',
    CZK: 'CZK',
    DJF: 'DJF',
    DKK: 'DKK',
    DOP: 'DOP',
    DZD: 'DZD',
    EGP: 'EGP',
    ERN: 'ERN',
    ETB: 'ETB',
    EUR: 'EUR',
    FJD: 'FJD',
    FKP: 'FKP',
    GBP: 'GBP',
    GEL: 'GEL',
    GHS: 'GHS',
    GIP: 'GIP',
    GMD: 'GMD',
    GNF: 'GNF',
    GTQ: 'GTQ',
    GYD: 'GYD',
    HKD: 'HKD',
    HNL: 'HNL',
    HRK: 'HRK',
    HTG: 'HTG',
    HUF: 'HUF',
    IDR: 'IDR',
    ILS: 'ILS',
    INR: 'INR',
    IQD: 'IQD',
    IRR: 'IRR',
    ISK: 'ISK',
    JMD: 'JMD',
    JOD: 'JOD',
    JPY: 'JPY',
    KES: 'KES',
    KGS: 'KGS',
    KHR: 'KHR',
    KMF: 'KMF',
    KPW: 'KPW',
    KRW: 'KRW',
    KWD: 'KWD',
    KYD: 'KYD',
    KZT: 'KZT',
    LAK: 'LAK',
    LBP: 'LBP',
    LKR: 'LKR',
    LRD: 'LRD',
    LSL: 'LSL',
    LTL: 'LTL',
    LYD: 'LYD',
    MAD: 'MAD',
    MDL: 'MDL',
    MGA: 'MGA',
    MKD: 'MKD',
    MMK: 'MMK',
    MNT: 'MNT',
    MOP: 'MOP',
    MRO: 'MRO',
    MRU: 'MRU',
    MUR: 'MUR',
    MVR: 'MVR',
    MWK: 'MWK',
    MXN: 'MXN',
    MXV: 'MXV',
    MYR: 'MYR',
    MZN: 'MZN',
    NAD: 'NAD',
    NGN: 'NGN',
    NIO: 'NIO',
    NOK: 'NOK',
    NPR: 'NPR',
    NZD: 'NZD',
    OMR: 'OMR',
    PAB: 'PAB',
    PEN: 'PEN',
    PGK: 'PGK',
    PHP: 'PHP',
    PKR: 'PKR',
    PLN: 'PLN',
    PYG: 'PYG',
    QAR: 'QAR',
    RON: 'RON',
    RSD: 'RSD',
    RUB: 'RUB',
    RUR: 'RUR',
    RWF: 'RWF',
    SAR: 'SAR',
    SBD: 'SBD',
    SCR: 'SCR',
    SDG: 'SDG',
    SEK: 'SEK',
    SGD: 'SGD',
    SHP: 'SHP',
    SLL: 'SLL',
    SOS: 'SOS',
    SRD: 'SRD',
    SSP: 'SSP',
    STD: 'STD',
    STN: 'STN',
    SVC: 'SVC',
    SYP: 'SYP',
    SZL: 'SZL',
    THB: 'THB',
    TJS: 'TJS',
    TMT: 'TMT',
    TND: 'TND',
    TOP: 'TOP',
    TRY: 'TRY',
    TTD: 'TTD',
    TWD: 'TWD',
    TZS: 'TZS',
    UAH: 'UAH',
    UGX: 'UGX',
    USD: 'USD',
    USN: 'USN',
    USS: 'USS',
    UYI: 'UYI',
    UYU: 'UYU',
    UZS: 'UZS',
    VEF: 'VEF',
    VES: 'VES',
    VND: 'VND',
    VUV: 'VUV',
    WST: 'WST',
    XAF: 'XAF',
    XAG: 'XAG',
    XAU: 'XAU',
    XBA: 'XBA',
    XBB: 'XBB',
    XBC: 'XBC',
    XBD: 'XBD',
    XCD: 'XCD',
    XDR: 'XDR',
    XOF: 'XOF',
    XPD: 'XPD',
    XPF: 'XPF',
    XPT: 'XPT',
    XSU: 'XSU',
    XTS: 'XTS',
    XUA: 'XUA',
    XXX: 'XXX',
    YER: 'YER',
    ZAR: 'ZAR',
    ZMW: 'ZMW',
    ZWL: 'ZWL',
} as const

export type FullWalletLoadBlockedReason =
    (typeof FullWalletLoadBlockedReason)[keyof typeof FullWalletLoadBlockedReason]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FullWalletLoadBlockedReason = {
    DAILY_LOAD_AMOUNT_LIMIT_REACHED: 'DAILY_LOAD_AMOUNT_LIMIT_REACHED',
    YEARLY_LOAD_AMOUNT_LIMIT_REACHED: 'YEARLY_LOAD_AMOUNT_LIMIT_REACHED',
    PENDING_VALIDATION: 'PENDING_VALIDATION',
} as const

export interface FullWalletLoad {
    amount?: number
    blockedReason?: FullWalletLoadBlockedReason
    companyId: string
    createdAt?: string
    currency?: FullWalletLoadCurrency
    id: string
    invoiceReference: string
    loadedAt?: string
    paymentSystem?: FullWalletLoadPaymentSystem
    scheduledAt?: string
    status?: string
    updatedAt?: string
}

export type FullInvoiceStatus = (typeof FullInvoiceStatus)[keyof typeof FullInvoiceStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FullInvoiceStatus = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    CANCELLED: 'CANCELLED',
    MANUALLY_APPROVED: 'MANUALLY_APPROVED',
} as const

export type FullInvoicePaymentSystem =
    (typeof FullInvoicePaymentSystem)[keyof typeof FullInvoicePaymentSystem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FullInvoicePaymentSystem = {
    INTERNAL: 'INTERNAL',
    OPEN_BANKING: 'OPEN_BANKING',
    TELESTO: 'TELESTO',
    INPAY: 'INPAY',
    TRUSTLY: 'TRUSTLY',
    ACH: 'ACH',
} as const

export interface FullInvoice {
    amount: number
    callbackId?: string
    companyId: string
    createdAt: string
    currency: FullInvoiceCurrency
    initiatedBy?: string
    isAutomatic: boolean
    paymentSystem: FullInvoicePaymentSystem
    projectedLoadedAt?: string
    receivedAmount?: number
    reference: string
    senderBank?: string
    senderInformation?: string
    status: FullInvoiceStatus
    updatedAt: string
}

export type FullInvoiceCurrencyContext = {
    isEmpty?: boolean
    providerName?: string
}

export type FullInvoiceCurrency = {
    context?: FullInvoiceCurrencyContext
    currencyCode?: string
    defaultFractionDigits?: number
    numericCode?: number
}

export type DirectDebitRequestInfoStatus =
    (typeof DirectDebitRequestInfoStatus)[keyof typeof DirectDebitRequestInfoStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectDebitRequestInfoStatus = {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
    SENT: 'SENT',
} as const

export type DirectDebitRequestInfoProvider =
    (typeof DirectDebitRequestInfoProvider)[keyof typeof DirectDebitRequestInfoProvider]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectDebitRequestInfoProvider = {
    AUTOGIRO: 'AUTOGIRO',
    NETS_OVERFOERSELSSERVICE: 'NETS_OVERFOERSELSSERVICE',
    SEPA: 'SEPA',
    BACS: 'BACS',
} as const

export interface DirectDebitRequestInfo {
    amount: Money
    createdAt: string
    id: string
    invoiceReference: string
    provider: DirectDebitRequestInfoProvider
    scheduledAt: string
    sentAt?: string
    status: DirectDebitRequestInfoStatus
    updatedAt: string
}

export type DirectDebitAgreementModelStatus =
    (typeof DirectDebitAgreementModelStatus)[keyof typeof DirectDebitAgreementModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectDebitAgreementModelStatus = {
    PENDING: 'PENDING',
    READY: 'READY',
    PENDING_CANCELLATION: 'PENDING_CANCELLATION',
    INACTIVE: 'INACTIVE',
    SUSPENDED: 'SUSPENDED',
} as const

export interface DirectDebitAgreementModel {
    bankAccountNumber: string
    bankIdentifierNumber: string
    bankName?: string
    firstDisposalDate?: string
    reference: string
    status: DirectDebitAgreementModelStatus
}

export interface DirectDebit {
    enabled: boolean
    projectedLoadedAt: string
}

export interface DataResponseResult {
    data: Result
}

export interface DataResponseBoolean {
    data: boolean
}

export interface CreateDirectDebitAgreementRequestModel {
    accountHolderName?: string
    bankAccountNumber: string
    bankAddress?: string
    bankAddressLocality?: string
    bankAddressPostalCode?: string
    bankAddressRegion?: string
    bankIdentifierNumber: string
    payerBankName?: string
    signatoryEmployeeId: string
    signerHasSignatureRights?: boolean
}

export type ComponentResultErrorStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ComponentResultError = {
    localizedMessage?: string
    message?: string
    stackTrace?: ComponentResultErrorStackTraceItem[]
}

export type ComponentResultDetails = { [key: string]: { [key: string]: any } }

export interface ComponentResult {
    details?: ComponentResultDetails
    enabled: boolean
    error?: ComponentResultError
    healthy: boolean
    message?: string
    time: string
}

export type CompanyDetailsAgreementStatus =
    (typeof CompanyDetailsAgreementStatus)[keyof typeof CompanyDetailsAgreementStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyDetailsAgreementStatus = {
    PENDING: 'PENDING',
    READY: 'READY',
    PENDING_CANCELLATION: 'PENDING_CANCELLATION',
    INACTIVE: 'INACTIVE',
    SUSPENDED: 'SUSPENDED',
} as const

export type BeneficiaryAccountAccountType =
    (typeof BeneficiaryAccountAccountType)[keyof typeof BeneficiaryAccountAccountType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BeneficiaryAccountAccountType = {
    NEM_KONTO_CVR: 'NEM_KONTO_CVR',
    IBAN: 'IBAN',
    ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
} as const

export interface BeneficiaryAccount {
    accountNumber?: string
    accountType?: BeneficiaryAccountAccountType
    bankCode?: string
    bankCountry?: string
    bankName?: string
    bic?: string
    iban?: string
    otherAccountIdentifier?: string
}

export interface BankInformation {
    accountNumber: string
    bankName?: string
    registrationNumber: string
}

export interface CompanyDetails {
    agreementStatus?: CompanyDetailsAgreementStatus
    directDebitEnabled: boolean
    id: string
    name: string
    netsEnabled: boolean
    payerBankInformation?: BankInformation
    registrationNumber: string
}

export interface Bank {
    accountNumber?: string
    bankCode?: string
    bicOrSwift?: string
    iban?: string
    name?: string
}

export type BacsTransferStatus = (typeof BacsTransferStatus)[keyof typeof BacsTransferStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BacsTransferStatus = {
    CREATED: 'CREATED',
    SUBMITTED: 'SUBMITTED',
    SETTLED: 'SETTLED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',
    REFUNDED: 'REFUNDED',
    RETURNED: 'RETURNED',
    SUBMISSION_FAILURE: 'SUBMISSION_FAILURE',
    ADVANCE_NOTICE_SENDING_FAILURE: 'ADVANCE_NOTICE_SENDING_FAILURE',
} as const

export interface BacsTransfer {
    advanceNoticeSentAt?: string
    agreementId: string
    amount: number
    createdAt: string
    createdBy: string
    expectedLoadDate: string
    failureReasonCode?: string
    id: string
    invoiceId: string
    receivedAmount?: number
    settledAt?: string
    status: BacsTransferStatus
    submittedAt?: string
    updatedAt: string
    updatedBy: string
}

export interface BacsRejectTransferRequest {
    reason: string
}

export interface BacsAgreementIdResponse {
    agreementId: string
}

export interface ApproveInvoiceRequest {
    callbackId: string
    receivedAmount: Money
    scheduledAt?: string
}

export type AdminSyncRequestDelayBetweenBatchesUnitsItem = {
    isDateBased?: boolean
    isDurationEstimated?: boolean
    isTimeBased?: boolean
}

export type AdminSyncRequestDelayBetweenBatches = {
    isNegative?: boolean
    isZero?: boolean
    nano?: number
    seconds?: number
    units?: AdminSyncRequestDelayBetweenBatchesUnitsItem[]
}

export interface AdminSyncRequest {
    batchSize: number
    delayBetweenBatches: AdminSyncRequestDelayBetweenBatches
    from?: string
    ids?: string[]
    to?: string
}

export type AccountIdentificationVModelType =
    (typeof AccountIdentificationVModelType)[keyof typeof AccountIdentificationVModelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountIdentificationVModelType = {
    IBAN: 'IBAN',
} as const

export interface AccountIdentificationV4Model {
    identification: string
    type: AccountIdentificationVModelType
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * @summary Returns the health of the service
 */
export const check = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health`, method: 'get' }, options)
}

/**
 * @summary Returns the liveness of the service
 */
export const liveness = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health/liveness`, method: 'get' }, options)
}

/**
 * @summary Fetches default direct debit payment agreement
 */
export const getDefaultDirectDebitAgreement = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DirectDebitAgreementModel>(
        { url: `/v3/companies/${companyId}/agreements`, method: 'get' },
        options
    )
}

/**
 * @summary Schedules a cancellation for a direct debit payment agreement
 */
export const cancelDirectDebitAgreement = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v3/companies/${companyId}/agreements/cancel`, method: 'post' },
        options
    )
}

/**
 * @summary Fetch direct debit requests
 */
export const fetchDirectDebitInvoice = (
    companyId: string,
    params: FetchDirectDebitInvoiceParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DirectDebitRequestInfo[]>(
        { url: `/v3/companies/${companyId}/direct-debit/requests`, method: 'get', params },
        options
    )
}

/**
 * @summary Updates agreement status
 */
export const updateAgreementStatus = (
    sepaAgreementStatusUpdate: SepaAgreementStatusUpdate,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/agreements/sepa`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: sepaAgreementStatusUpdate,
        },
        options
    )
}

/**
 * @summary Attempt to approve pending SEPA agreements
 */
export const approvePendingAgreements = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/agreements/sepa/approve-pending`, method: 'post' },
        options
    )
}

/**
 * @summary Force approves a direct debit payment agreement
 */
export const approveSepaDirectDebitAgreement = (
    agreementId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DirectDebitAgreementModel>(
        { url: `/v4/admin/agreements/sepa/${agreementId}/approve`, method: 'post' },
        options
    )
}

/**
 * @summary Force rejects a direct debit payment agreement
 */
export const rejectSepaDirectDebitAgreement = (
    agreementId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DirectDebitAgreementModel>(
        { url: `/v4/admin/agreements/sepa/${agreementId}/reject`, method: 'post' },
        options
    )
}

/**
 * @summary Approve BACS agreements with status 'CREATED' older than `bacs.approve.days_after_creation` days
 */
export const approveBacsAgreements = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/bacs/agreements/approve-pending`, method: 'post' },
        options
    )
}

/**
 * @summary Approve pending BACS agreement cancellations older than `bacs.agreement.cancellation.days-before-approval` days
 */
export const approveBacsAgreementCancellations = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/bacs/agreements/cancellation/approve-pending`, method: 'post' },
        options
    )
}

/**
 * @summary Forcefully cancels a BACS agreement. If no unforeseen error occurs then it will always return success.
 */
export const cancelAgreementSkippingTransitionStatusCheck = (
    agreementId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/bacs/agreements/${agreementId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Rejects BACS transfer by InvoiceId
 */
export const rejectBacsTransfer = (
    invoiceId: string,
    bacsRejectTransferRequest: BacsRejectTransferRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BacsTransfer>(
        {
            url: `/v4/admin/bacs/reject/${invoiceId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: bacsRejectTransferRequest,
        },
        options
    )
}

/**
 * @summary Marks the transfer as submitted.
 */
export const markTransferAsSubmitted = (
    transferId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/bacs/transfers/${transferId}:mark-as-submitted`, method: 'post' },
        options
    )
}

/**
 * @summary Delete company cache entries for currency and features
 */
export const deleteCompanyCache = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/companies/${companyId}/cache`, method: 'delete' },
        options
    )
}

/**
 * @summary Get company cache entries for currency and features
 */
export const getCompanyCache = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetCompanyCache>(
        { url: `/v4/admin/companies/${companyId}/cache`, method: 'get' },
        options
    )
}

/**
 * @summary Delete feature for a company.
 */
export const deleteCompanyFeature = (
    feature:
        | 'OPEN_BANKING'
        | 'SCHEDULED_TOPUP'
        | 'DD_FRAUD_CHECK'
        | 'SPEND_PROJECTION'
        | 'VRP'
        | 'SUB_WALLET',
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/features/${feature}/companies/${companyId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Add feature for a company.
 */
export const addCompanyFeature = (
    feature:
        | 'OPEN_BANKING'
        | 'SCHEDULED_TOPUP'
        | 'DD_FRAUD_CHECK'
        | 'SPEND_PROJECTION'
        | 'VRP'
        | 'SUB_WALLET',
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/features/${feature}/companies/${companyId}`, method: 'post' },
        options
    )
}

/**
 * @summary Toggle a feature.
 */
export const toggleFeature = (
    feature:
        | 'OPEN_BANKING'
        | 'SCHEDULED_TOPUP'
        | 'DD_FRAUD_CHECK'
        | 'SPEND_PROJECTION'
        | 'VRP'
        | 'SUB_WALLET',
    status: 'RESTRICTED' | 'PUBLIC' | 'DISABLED',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/features/${feature}/status/${status}`, method: 'put' },
        options
    )
}

/**
 * @summary Sync invoice with styx. If no record for a given bank details exist in Styx, initiate request to create new proof of funds
 */
export const syncInvoiceWithStyx = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/invoices/${id}/styx-sync`, method: 'post' },
        options
    )
}

/**
 * @summary Cancel invoice
 */
export const updateInvoiceStatus = (
    id: string,
    status: 'NEW' | 'PROCESSING' | 'PENDING' | 'APPROVED' | 'CANCELLED' | 'MANUALLY_APPROVED',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/invoices/${id}/${status}`, method: 'put' },
        options
    )
}

/**
 * @summary Approve invoice and load a wallet
 */
export const approveAndLoadInvoice = (
    invoiceId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/invoices/${invoiceId}/load`, method: 'post' },
        options
    )
}

/**
 * @summary Retries wallet loads
 */
export const retryWalletLoads = (
    retryWalletLoadRequest: RetryWalletLoadRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/loads/retry`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: retryWalletLoadRequest,
        },
        options
    )
}

/**
 * @summary Update wallet load with a status RETURNED
 */
export const updateWalletLoadReturned = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<Load>({ url: `/v4/admin/loads/${id}/RETURNED`, method: 'put' }, options)
}

/**
 * @summary Update wallet load with a status
 */
export const updateWalletLoad = (
    id: string,
    status:
        | 'BLOCKED'
        | 'APPROVED'
        | 'QUEUED'
        | 'PROCESSING'
        | 'FAILED'
        | 'LOADED'
        | 'CANCELLED'
        | 'RETURNED',
    params: UpdateWalletLoadParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<Load>(
        { url: `/v4/admin/loads/${id}/${status}`, method: 'put', params },
        options
    )
}

/**
 * @summary Marks a NETS agreement as expired.
 */
export const markNetsAgreementAsExpired = (
    agreementId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/nets/agreements/${agreementId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Returnes list of all CVRs that have broken mapping with a company in `nets_company_mapping` table
 */
export const getBrokenNetsMapping = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<string[]>({ url: `/v4/admin/nets/broken-mappings`, method: 'get' }, options)
}

/**
 * @summary Runs NETS mapping resolution for given CVR. Uses companyId if provided, otherwise auto-resolves.
 */
export const resolveNetsCompanyMapping = (
    netsMappingResolutionModel: NetsMappingResolutionModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<NetsCompanyMappingModel>(
        {
            url: `/v4/admin/nets/resolve-mapping`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: netsMappingResolutionModel,
        },
        options
    )
}

/**
 * @summary Sync sepa transfers from Telesto.
 */
export const syncSepaTransfers = (
    sepaTransferSyncRequest: SepaTransferSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sepa/sync/transfers`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: sepaTransferSyncRequest,
        },
        options
    )
}

/**
 * @summary Publishes autogiro agreements to Kafka.
 */
export const syncAutogiroAgreements = (
    adminSyncRequest: AdminSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sync/agreements/autogiro`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: adminSyncRequest,
        },
        options
    )
}

/**
 * @summary Publishes nets agreements to Kafka.
 */
export const syncNetsAgreements = (
    adminSyncRequest: AdminSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sync/agreements/nets`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: adminSyncRequest,
        },
        options
    )
}

/**
 * @summary Publishes Autgiro direct debit transfers to Kafka.
 */
export const syncAutogiroDirectDebitTransfers = (
    adminSyncRequest: AdminSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sync/direct-debit-transfers/autogiro`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: adminSyncRequest,
        },
        options
    )
}

/**
 * @summary Publishes Nets direct debit transfers to Kafka.
 */
export const syncNetsDirectDebitTransfers = (
    adminSyncRequest: AdminSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sync/direct-debit-transfers/nets`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: adminSyncRequest,
        },
        options
    )
}

/**
 * @summary Publishes sepa direct debit transfers to Kafka.
 */
export const syncSepaDirectDebitTransfers = (
    adminSyncRequest: AdminSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sync/direct-debit-transfers/sepa`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: adminSyncRequest,
        },
        options
    )
}

/**
 * @summary Publishes invoices to Kafka.
 */
export const syncInvoices = (
    adminSyncRequest: AdminSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sync/invoices`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: adminSyncRequest,
        },
        options
    )
}

/**
 * @summary Publishes wallet loads to Kafka.
 */
export const syncWalletLoads = (
    adminSyncRequest: AdminSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sync/wallet-loads`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: adminSyncRequest,
        },
        options
    )
}

/**
 * @summary Publishes wallet unloads to Kafka.
 */
export const syncWalletUnloads = (
    adminSyncRequest: AdminSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sync/wallet-unloads`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: adminSyncRequest,
        },
        options
    )
}

/**
 * @summary Publishes wallet withdrawals to Kafka.
 */
export const syncWalletWithdrawals = (
    adminSyncRequest: AdminSyncRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v4/admin/sync/wallet-withdrawals`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: adminSyncRequest,
        },
        options
    )
}

/**
 * @summary Update wallet withdrawal status
 */
export const updateWalletWithdrawal = (
    id: string,
    status: 'INITIATED' | 'COMPLETED' | 'FAILED' | 'PROCESSING',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/admin/withdrawals/${id}/${status}`, method: 'put' },
        options
    )
}

/**
 * @summary Generates a new BACS agreement ID.
 */
export const generateBacsAgreementId = (
    params: GenerateBacsAgreementIdParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BacsAgreementIdResponse>(
        { url: `/v4/bacs/agreement-id`, method: 'post', params },
        options
    )
}

/**
 * @summary Gets the current consent text for the FE
 */
export const getConsentText = (
    params: GetConsentTextParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<string>({ url: `/v4/bacs/consent-text`, method: 'get', params }, options)
}

/**
 * @summary Retrieves wallet top up information for a company
 */
export const getDetails = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CompanyDetails>(
        { url: `/v4/companies/${companyId}`, method: 'get' },
        options
    )
}

/**
 * @summary Get the presigned URL of the company's direct debit agreement pdf
 */
export const getDirectDebitAgreementPdfUrl = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PresignedUrl>(
        { url: `/v4/companies/${companyId}/agreement/url`, method: 'get' },
        options
    )
}

/**
 * @summary Fetches default direct debit payment agreement
 */
export const getDefaultDirectDebitAgreement1 = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DirectDebitAgreementModel>(
        { url: `/v4/companies/${companyId}/agreements`, method: 'get' },
        options
    )
}

/**
 * @summary DEPRECATED: Use GET: /v4/companies/{id}/agreements
 */
export const getDirectDebitAgreement = (
    companyId: string,
    country: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DirectDebitAgreementModel>(
        { url: `/v4/companies/${companyId}/agreements/${country}`, method: 'get' },
        options
    )
}

/**
 * @summary Creates a pending direct debit agreement
 */
export const createDirectDebitAgreement = (
    companyId: string,
    country: string,
    createDirectDebitAgreementRequestModel: CreateDirectDebitAgreementRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DirectDebitAgreementModel>(
        {
            url: `/v4/companies/${companyId}/agreements/${country}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createDirectDebitAgreementRequestModel,
        },
        options
    )
}

/**
 * @summary Schedules a cancellation for a company's direct debit payment agreement
 */
export const cancelDirectDebitAgreement1 = (
    companyId: string,
    country: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/companies/${companyId}/agreements/${country}/cancel`, method: 'post' },
        options
    )
}

/**
 * @summary Get feature toggle by a company and feature name
 */
export const hasCompanyFeature = (
    companyId: string,
    feature:
        | 'OPEN_BANKING'
        | 'SCHEDULED_TOPUP'
        | 'DD_FRAUD_CHECK'
        | 'SPEND_PROJECTION'
        | 'VRP'
        | 'SUB_WALLET',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        { url: `/v4/companies/${companyId}/features/${feature}`, method: 'get' },
        options
    )
}

/**
 * @summary Get the first successful wallet load for a company
 */
export const getFirstWalletLoad = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FullWalletLoad>(
        { url: `/v4/companies/${companyId}/first-wallet-load`, method: 'get' },
        options
    )
}

/**
 * Returns true if a company has at least 1 wallet load with status `LOADED`, false if it does not have any wallet loads, or only wallet loads with any other status than `LOADED`
 * @summary Returns true if a company has a wallet load
 */
export const hasLoadedWalletLoad = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseBoolean>(
        { url: `/v4/companies/${companyId}/has-loaded-wallet-load`, method: 'get' },
        options
    )
}

/**
 * @summary Determine if company have any pending automatic invoices. Automatic invoice is when `isAutomatic` is set to true and invoice have not been loaded yet
 */
export const hasPendingAutomaticInvoice = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<boolean>(
        { url: `/v4/companies/${companyId}/has-pending-automatic-invoice`, method: 'get' },
        options
    )
}

/**
 * @summary Creates an invoice and loads wallet.
 */
export const saveInvoice = (
    companyId: string,
    invoiceLoadRequest: InvoiceLoadRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4InvoiceResponseModel>(
        {
            url: `/v4/companies/${companyId}/invoices`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: invoiceLoadRequest,
        },
        options
    )
}

/**
 * @summary Get the last wallet load for a company
 */
export const getLastWalletLoad = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<FullWalletLoad>(
        { url: `/v4/companies/${companyId}/last-wallet-load`, method: 'get' },
        options
    )
}

/**
 * @summary Get the last wallet unload of a company. Endpoint used only to verify off-boarding process
 */
export const getLastWalletUnload = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletUnloadResponse>(
        { url: `/v4/companies/${companyId}/last-wallet-unload`, method: 'get' },
        options
    )
}

/**
 * @summary Get the max wallet load for a company
 */
export const getMaxWalletLoad = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<number>(
        { url: `/v4/companies/${companyId}/max-wallet-load`, method: 'get' },
        options
    )
}

/**
 * @summary Creates open banking payment for auto top-up.
 */
export const createOpenBankingPayment = (
    companyId: string,
    paymentRequest: PaymentRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PaymentResponse>(
        {
            url: `/v4/companies/${companyId}/payments`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: paymentRequest,
        },
        options
    )
}

/**
 * @summary Performs open banking payments authorization for auto top-up.
 */
export const createOpenBankingAuthorization = (
    companyId: string,
    paymentAuthorizationRequest: PaymentAuthorizationRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PaymentAuthorizationResponse>(
        {
            url: `/v4/companies/${companyId}/payments/authorize`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: paymentAuthorizationRequest,
        },
        options
    )
}

/**
 * @summary Create an unload. In case of the company off-boarding, wallet withdrawal will be created.
 */
export const createUnload = (
    companyId: string,
    walletUnloadRequest: WalletUnloadRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletUnloadResponse>(
        {
            url: `/v4/companies/${companyId}/unload`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: walletUnloadRequest,
        },
        options
    )
}

/**
 * @summary Get wallet unloads
 */
export const getUnloads = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<WalletUnloadResponse[]>(
        { url: `/v4/companies/${companyId}/unloads`, method: 'get' },
        options
    )
}

/**
 * Request supports pagination by providing 'limit' and 'offset' query parameter.
 * @summary Retrieves wallet activities for a company
 */
export const getWalletActivities = (
    companyId: string,
    params: GetWalletActivitiesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PaginatedResponseWalletActivity>(
        { url: `/v4/companies/${companyId}/wallet-activities`, method: 'get', params },
        options
    )
}

/**
 * @summary Returns eligible features for a company wallet. If `feature` query parameter is provided, only that feature will be verified if eligible for a company.
 */
export const getWalletFeatures = (
    companyId: string,
    params: GetWalletFeaturesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4WalletFeatureResponse>(
        { url: `/v4/companies/${companyId}/wallet-features`, method: 'get', params },
        options
    )
}

/**
 * @summary Retrieves wallet top up information for a company
 */
export const getWalletLoads = (
    companyId: string,
    params: GetWalletLoadsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PaginatedResponseFullWalletLoad>(
        { url: `/v4/companies/${companyId}/wallet-loads`, method: 'get', params },
        options
    )
}

/**
 * @summary Unblock wallet loads for a company post fraud check.
 */
export const unblockCompanyWalletLoadsPostFraudCheck = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v4/companies/${companyId}/wallet-loads:unblock`, method: 'post' },
        options
    )
}

/**
 * @summary Create a wallet withdrawal
 */
export const createWithdrawal = (
    companyId: string,
    walletWithdrawalRequest: WalletWithdrawalRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletWithdrawalResponse>(
        {
            url: `/v4/companies/${companyId}/withdrawals`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: walletWithdrawalRequest,
        },
        options
    )
}

/**
 * @summary Get Direct Debit Eligibility
 */
export const getDirectDebitEligibility = (
    params: GetDirectDebitEligibilityParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4DirectDebitEligibilityResponse>(
        { url: `/v4/direct-debits/eligibility`, method: 'get', params },
        options
    )
}

/**
 * @summary Get Direct Debit Transfer
 */
export const getDirectDebitTransfer = (
    transferId: string,
    params: GetDirectDebitTransferParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4DirectDebitTransferGetResponse>(
        { url: `/v4/direct-debits/transfers/${transferId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Get all active fraud checks. Active fraud check is considered if it has SUSPEND or WARN status
 */
export const getFraudChecks = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<V4FraudCheckResponse[]>({ url: `/v4/fraud-checks`, method: 'get' }, options)
}

/**
 * @summary Creates a new fraud check
 */
export const newFraudCheck = (
    v4NewFraudCheckInput: V4NewFraudCheckInput,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4FraudCheckResponse>(
        {
            url: `/v4/fraud-checks`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: v4NewFraudCheckInput,
        },
        options
    )
}

/**
 * @summary Update status of a fraud check
 */
export const updateFraudCheck = (
    checkId: string,
    v4FraudCheckRequest: V4FraudCheckRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4FraudCheckResponse>(
        {
            url: `/v4/fraud-checks/${checkId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: v4FraudCheckRequest,
        },
        options
    )
}

/**
 * @summary Fetches paginated company invoices
 */
export const getPaginatedCompanyInvoices = (
    params: GetPaginatedCompanyInvoicesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PaginatedResponseFullInvoice>(
        { url: `/v4/invoices`, method: 'get', params },
        options
    )
}

/**
 * @summary Creates direct debit invoice in a NEW state. Background job will pick up all new invoices and submit transfers to corresponding payment systems. Updated status of an invoice can be checked by calling GET: /invoice/{id}
 */
export const createDirectDebitInvoice = (
    v4DirectDebitInvoiceRequestModel: V4DirectDebitInvoiceRequestModel,
    params: CreateDirectDebitInvoiceParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4DirectDebitInvoiceResponseModel>(
        {
            url: `/v4/invoices/direct-debit`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: v4DirectDebitInvoiceRequestModel,
            params,
        },
        options
    )
}

/**
 * @summary Creates direct debit invoice in a NEW state. Background job will pick up all new invoices and submit transfers to corresponding payment systems. Updated status of an invoice can be checked by calling GET: /invoice/{id}
 */
export const createDirectDebitInvoiceAndPublishEvent = (
    v4DirectDebitInvoiceCreateRequestModel: V4DirectDebitInvoiceCreateRequestModel,
    params: CreateDirectDebitInvoiceAndPublishEventParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4DirectDebitInvoiceResponseModel>(
        {
            url: `/v4/invoices/direct-debit/temp`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: v4DirectDebitInvoiceCreateRequestModel,
            params,
        },
        options
    )
}

/**
 * @summary 
            Gets open banking invoice by a reference. If invoice by a given reference does not exist, 
            system will attempt to fetch invoice by callback_id column.
            
 */
export const getOpenBankingInvoice = (
    reference: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4InvoiceResponseModel>(
        { url: `/v4/invoices/open-banking/${reference}`, method: 'get' },
        options
    )
}

/**
 * @summary Creates VRP payment and return an invoice
 */
export const createVrpInvoice = (
    params: CreateVrpInvoiceParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4DirectDebitInvoiceResponseModel>(
        { url: `/v4/invoices/vrp`, method: 'post', params },
        options
    )
}

/**
 * @summary Creates a VRP invoice
 */
export const createVrpInvoiceAndPublishEvent = (
    v4VrpInvoiceCreateRequestModel: V4VrpInvoiceCreateRequestModel,
    params: CreateVrpInvoiceAndPublishEventParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4DirectDebitInvoiceResponseModel>(
        {
            url: `/v4/invoices/vrp/temp`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: v4VrpInvoiceCreateRequestModel,
            params,
        },
        options
    )
}

/**
 * @summary Retrieve an invoice
 */
export const getInvoice = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<V4InvoiceResponseModel>(
        { url: `/v4/invoices/${id}`, method: 'get' },
        options
    )
}

/**
 * @summary Cancel invoice and wallet loads
 */
export const cancelInvoiceAndLoads = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<V4InvoiceResponseModel>(
        { url: `/v4/invoices/${id}/cancel`, method: 'post' },
        options
    )
}

/**
 * @summary Cancel pending invoice
 */
export const cancelPendingInvoice = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<V4InvoiceResponseModel>(
        { url: `/v4/invoices/${id}/cancel-pending`, method: 'post' },
        options
    )
}

/**
 * @summary Cancel wallet loads for an invoice
 */
export const cancelInvoiceLoads = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<Load[]>({ url: `/v4/invoices/${id}/loads`, method: 'delete' }, options)
}

/**
 * @summary Retrieve invoice wallet loads
 */
export const getInvoiceLoads = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<Load[]>({ url: `/v4/invoices/${id}/loads`, method: 'get' }, options)
}

/**
 * @summary Retrieve invoice wallet loads paginated
 */
export const getInvoiceLoadsPaginated = (
    id: string,
    params: GetInvoiceLoadsPaginatedParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PaginatedResponseLoad>(
        { url: `/v4/invoices/${id}/loads-paginated`, method: 'get', params },
        options
    )
}

/**
 * @summary Update an invoice details
 */
export const updateInvoice = (
    id: string,
    updateInvoiceRequest: UpdateInvoiceRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4InvoiceResponseModel>(
        {
            url: `/v4/invoices/${id}/manually-approve`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateInvoiceRequest,
        },
        options
    )
}

/**
 * @summary Retrieve an invoice sender details
 */
export const getInvoiceSenderDetails = (
    id: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4SenderDetailsResponseModel>(
        { url: `/v4/invoices/${id}/sender-details`, method: 'get' },
        options
    )
}

/**
 * @summary Approves an invoice and loads wallet.
 */
export const approveInvoiceAndLoad = (
    reference: string,
    invoiceLoadByReferenceRequest: InvoiceLoadByReferenceRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4InvoiceResponseModel>(
        {
            url: `/v4/invoices/${reference}/approve`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: invoiceLoadByReferenceRequest,
        },
        options
    )
}

/**
 * @summary Approves an invoice and loads wallet.
 */
export const updateBankInfoAndApproveInvoiceAndLoad = (
    reference: string,
    invoiceLoadByReferenceRequest: InvoiceLoadByReferenceRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4InvoiceResponseModel>(
        {
            url: `/v4/invoices/${reference}/approve/temp`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: invoiceLoadByReferenceRequest,
        },
        options
    )
}

/**
 * @summary Approves a pending invoice.
 */
export const approvePendingInvoice = (
    reference: string,
    approveInvoiceRequest: ApproveInvoiceRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<V4InvoiceResponseModel>(
        {
            url: `/v4/invoices/${reference}:approve`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: approveInvoiceRequest,
        },
        options
    )
}

export const cancelExpiredInvoices = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v4/scheduler/cancel-expired-invoices`, method: 'post' },
        options
    )
}

export const runSchedulerJob = (
    params: RunSchedulerJobParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>({ url: `/v4/scheduler/run`, method: 'post', params }, options)
}

/**
 * @summary Get unloads in a particular status
 */
export const getUnloadsByStatus = (
    params: GetUnloadsByStatusParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletUnloadResponse[]>(
        { url: `/v4/unloads`, method: 'get', params },
        options
    )
}

/**
 * @summary Create a wallet unload in a refund state
 */
export const createRefund = (
    companyId: string,
    walletUnloadRequest: WalletUnloadRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletUnloadResponse>(
        {
            url: `/v4/unloads/companies/${companyId}/refund`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: walletUnloadRequest,
        },
        options
    )
}

/**
 * @summary Export some unloads
 */
export const exportUnloads = (
    unloadCsvRequest: UnloadCsvRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<UnloadFileResponse>(
        {
            url: `/v4/unloads/export`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: unloadCsvRequest,
        },
        options
    )
}

/**
 * @summary Get wallet unload summaries (CSV files)
 */
export const getSummaries = (
    params: GetSummariesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletUnloadSummary[]>(
        { url: `/v4/unloads/summaries`, method: 'get', params },
        options
    )
}

/**
 * @summary Get the pre-signed URL of a particular summary to download the CSV file
 */
export const getSummaryUrl = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<PresignedUrl>(
        { url: `/v4/unloads/summaries/${id}/url`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieve an unload by its id
 */
export const getUnload = (id: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<WalletUnloadResponse>({ url: `/v4/unloads/${id}`, method: 'get' }, options)
}

/**
 * @summary Update an unload
 */
export const updateUnload = (
    id: string,
    walletUnloadRequest: WalletUnloadRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletUnloadResponse>(
        {
            url: `/v4/unloads/${id}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: walletUnloadRequest,
        },
        options
    )
}

/**
 * @summary Set the status an unload
 */
export const setUnloadStatus = (
    id: string,
    updateUnloadStatusRequest: UpdateUnloadStatusRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletUnloadResponse>(
        {
            url: `/v4/unloads/${id}/status`,
            method: 'patch',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateUnloadStatusRequest,
        },
        options
    )
}

/**
 * @summary Fail a wallet withdrawal and send an email notification
 */
export const failAndNotify = (
    walletWithdrawalId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletWithdrawalResponse>(
        { url: `/v4/wallet-withdrawals/${walletWithdrawalId}:fail`, method: 'post' },
        options
    )
}

export type CheckResult = NonNullable<Awaited<ReturnType<typeof check>>>
export type LivenessResult = NonNullable<Awaited<ReturnType<typeof liveness>>>
export type GetDefaultDirectDebitAgreementResult = NonNullable<
    Awaited<ReturnType<typeof getDefaultDirectDebitAgreement>>
>
export type CancelDirectDebitAgreementResult = NonNullable<
    Awaited<ReturnType<typeof cancelDirectDebitAgreement>>
>
export type FetchDirectDebitInvoiceResult = NonNullable<
    Awaited<ReturnType<typeof fetchDirectDebitInvoice>>
>
export type UpdateAgreementStatusResult = NonNullable<
    Awaited<ReturnType<typeof updateAgreementStatus>>
>
export type ApprovePendingAgreementsResult = NonNullable<
    Awaited<ReturnType<typeof approvePendingAgreements>>
>
export type ApproveSepaDirectDebitAgreementResult = NonNullable<
    Awaited<ReturnType<typeof approveSepaDirectDebitAgreement>>
>
export type RejectSepaDirectDebitAgreementResult = NonNullable<
    Awaited<ReturnType<typeof rejectSepaDirectDebitAgreement>>
>
export type ApproveBacsAgreementsResult = NonNullable<
    Awaited<ReturnType<typeof approveBacsAgreements>>
>
export type ApproveBacsAgreementCancellationsResult = NonNullable<
    Awaited<ReturnType<typeof approveBacsAgreementCancellations>>
>
export type CancelAgreementSkippingTransitionStatusCheckResult = NonNullable<
    Awaited<ReturnType<typeof cancelAgreementSkippingTransitionStatusCheck>>
>
export type RejectBacsTransferResult = NonNullable<Awaited<ReturnType<typeof rejectBacsTransfer>>>
export type MarkTransferAsSubmittedResult = NonNullable<
    Awaited<ReturnType<typeof markTransferAsSubmitted>>
>
export type DeleteCompanyCacheResult = NonNullable<Awaited<ReturnType<typeof deleteCompanyCache>>>
export type GetCompanyCacheResult = NonNullable<Awaited<ReturnType<typeof getCompanyCache>>>
export type DeleteCompanyFeatureResult = NonNullable<
    Awaited<ReturnType<typeof deleteCompanyFeature>>
>
export type AddCompanyFeatureResult = NonNullable<Awaited<ReturnType<typeof addCompanyFeature>>>
export type ToggleFeatureResult = NonNullable<Awaited<ReturnType<typeof toggleFeature>>>
export type SyncInvoiceWithStyxResult = NonNullable<Awaited<ReturnType<typeof syncInvoiceWithStyx>>>
export type UpdateInvoiceStatusResult = NonNullable<Awaited<ReturnType<typeof updateInvoiceStatus>>>
export type ApproveAndLoadInvoiceResult = NonNullable<
    Awaited<ReturnType<typeof approveAndLoadInvoice>>
>
export type RetryWalletLoadsResult = NonNullable<Awaited<ReturnType<typeof retryWalletLoads>>>
export type UpdateWalletLoadReturnedResult = NonNullable<
    Awaited<ReturnType<typeof updateWalletLoadReturned>>
>
export type UpdateWalletLoadResult = NonNullable<Awaited<ReturnType<typeof updateWalletLoad>>>
export type MarkNetsAgreementAsExpiredResult = NonNullable<
    Awaited<ReturnType<typeof markNetsAgreementAsExpired>>
>
export type GetBrokenNetsMappingResult = NonNullable<
    Awaited<ReturnType<typeof getBrokenNetsMapping>>
>
export type ResolveNetsCompanyMappingResult = NonNullable<
    Awaited<ReturnType<typeof resolveNetsCompanyMapping>>
>
export type SyncSepaTransfersResult = NonNullable<Awaited<ReturnType<typeof syncSepaTransfers>>>
export type SyncAutogiroAgreementsResult = NonNullable<
    Awaited<ReturnType<typeof syncAutogiroAgreements>>
>
export type SyncNetsAgreementsResult = NonNullable<Awaited<ReturnType<typeof syncNetsAgreements>>>
export type SyncAutogiroDirectDebitTransfersResult = NonNullable<
    Awaited<ReturnType<typeof syncAutogiroDirectDebitTransfers>>
>
export type SyncNetsDirectDebitTransfersResult = NonNullable<
    Awaited<ReturnType<typeof syncNetsDirectDebitTransfers>>
>
export type SyncSepaDirectDebitTransfersResult = NonNullable<
    Awaited<ReturnType<typeof syncSepaDirectDebitTransfers>>
>
export type SyncInvoicesResult = NonNullable<Awaited<ReturnType<typeof syncInvoices>>>
export type SyncWalletLoadsResult = NonNullable<Awaited<ReturnType<typeof syncWalletLoads>>>
export type SyncWalletUnloadsResult = NonNullable<Awaited<ReturnType<typeof syncWalletUnloads>>>
export type SyncWalletWithdrawalsResult = NonNullable<
    Awaited<ReturnType<typeof syncWalletWithdrawals>>
>
export type UpdateWalletWithdrawalResult = NonNullable<
    Awaited<ReturnType<typeof updateWalletWithdrawal>>
>
export type GenerateBacsAgreementIdResult = NonNullable<
    Awaited<ReturnType<typeof generateBacsAgreementId>>
>
export type GetConsentTextResult = NonNullable<Awaited<ReturnType<typeof getConsentText>>>
export type GetDetailsResult = NonNullable<Awaited<ReturnType<typeof getDetails>>>
export type GetDirectDebitAgreementPdfUrlResult = NonNullable<
    Awaited<ReturnType<typeof getDirectDebitAgreementPdfUrl>>
>
export type GetDefaultDirectDebitAgreement1Result = NonNullable<
    Awaited<ReturnType<typeof getDefaultDirectDebitAgreement1>>
>
export type GetDirectDebitAgreementResult = NonNullable<
    Awaited<ReturnType<typeof getDirectDebitAgreement>>
>
export type CreateDirectDebitAgreementResult = NonNullable<
    Awaited<ReturnType<typeof createDirectDebitAgreement>>
>
export type CancelDirectDebitAgreement1Result = NonNullable<
    Awaited<ReturnType<typeof cancelDirectDebitAgreement1>>
>
export type HasCompanyFeatureResult = NonNullable<Awaited<ReturnType<typeof hasCompanyFeature>>>
export type GetFirstWalletLoadResult = NonNullable<Awaited<ReturnType<typeof getFirstWalletLoad>>>
export type HasLoadedWalletLoadResult = NonNullable<Awaited<ReturnType<typeof hasLoadedWalletLoad>>>
export type HasPendingAutomaticInvoiceResult = NonNullable<
    Awaited<ReturnType<typeof hasPendingAutomaticInvoice>>
>
export type SaveInvoiceResult = NonNullable<Awaited<ReturnType<typeof saveInvoice>>>
export type GetLastWalletLoadResult = NonNullable<Awaited<ReturnType<typeof getLastWalletLoad>>>
export type GetLastWalletUnloadResult = NonNullable<Awaited<ReturnType<typeof getLastWalletUnload>>>
export type GetMaxWalletLoadResult = NonNullable<Awaited<ReturnType<typeof getMaxWalletLoad>>>
export type CreateOpenBankingPaymentResult = NonNullable<
    Awaited<ReturnType<typeof createOpenBankingPayment>>
>
export type CreateOpenBankingAuthorizationResult = NonNullable<
    Awaited<ReturnType<typeof createOpenBankingAuthorization>>
>
export type CreateUnloadResult = NonNullable<Awaited<ReturnType<typeof createUnload>>>
export type GetUnloadsResult = NonNullable<Awaited<ReturnType<typeof getUnloads>>>
export type GetWalletActivitiesResult = NonNullable<Awaited<ReturnType<typeof getWalletActivities>>>
export type GetWalletFeaturesResult = NonNullable<Awaited<ReturnType<typeof getWalletFeatures>>>
export type GetWalletLoadsResult = NonNullable<Awaited<ReturnType<typeof getWalletLoads>>>
export type UnblockCompanyWalletLoadsPostFraudCheckResult = NonNullable<
    Awaited<ReturnType<typeof unblockCompanyWalletLoadsPostFraudCheck>>
>
export type CreateWithdrawalResult = NonNullable<Awaited<ReturnType<typeof createWithdrawal>>>
export type GetDirectDebitEligibilityResult = NonNullable<
    Awaited<ReturnType<typeof getDirectDebitEligibility>>
>
export type GetDirectDebitTransferResult = NonNullable<
    Awaited<ReturnType<typeof getDirectDebitTransfer>>
>
export type GetFraudChecksResult = NonNullable<Awaited<ReturnType<typeof getFraudChecks>>>
export type NewFraudCheckResult = NonNullable<Awaited<ReturnType<typeof newFraudCheck>>>
export type UpdateFraudCheckResult = NonNullable<Awaited<ReturnType<typeof updateFraudCheck>>>
export type GetPaginatedCompanyInvoicesResult = NonNullable<
    Awaited<ReturnType<typeof getPaginatedCompanyInvoices>>
>
export type CreateDirectDebitInvoiceResult = NonNullable<
    Awaited<ReturnType<typeof createDirectDebitInvoice>>
>
export type CreateDirectDebitInvoiceAndPublishEventResult = NonNullable<
    Awaited<ReturnType<typeof createDirectDebitInvoiceAndPublishEvent>>
>
export type GetOpenBankingInvoiceResult = NonNullable<
    Awaited<ReturnType<typeof getOpenBankingInvoice>>
>
export type CreateVrpInvoiceResult = NonNullable<Awaited<ReturnType<typeof createVrpInvoice>>>
export type CreateVrpInvoiceAndPublishEventResult = NonNullable<
    Awaited<ReturnType<typeof createVrpInvoiceAndPublishEvent>>
>
export type GetInvoiceResult = NonNullable<Awaited<ReturnType<typeof getInvoice>>>
export type CancelInvoiceAndLoadsResult = NonNullable<
    Awaited<ReturnType<typeof cancelInvoiceAndLoads>>
>
export type CancelPendingInvoiceResult = NonNullable<
    Awaited<ReturnType<typeof cancelPendingInvoice>>
>
export type CancelInvoiceLoadsResult = NonNullable<Awaited<ReturnType<typeof cancelInvoiceLoads>>>
export type GetInvoiceLoadsResult = NonNullable<Awaited<ReturnType<typeof getInvoiceLoads>>>
export type GetInvoiceLoadsPaginatedResult = NonNullable<
    Awaited<ReturnType<typeof getInvoiceLoadsPaginated>>
>
export type UpdateInvoiceResult = NonNullable<Awaited<ReturnType<typeof updateInvoice>>>
export type GetInvoiceSenderDetailsResult = NonNullable<
    Awaited<ReturnType<typeof getInvoiceSenderDetails>>
>
export type ApproveInvoiceAndLoadResult = NonNullable<
    Awaited<ReturnType<typeof approveInvoiceAndLoad>>
>
export type UpdateBankInfoAndApproveInvoiceAndLoadResult = NonNullable<
    Awaited<ReturnType<typeof updateBankInfoAndApproveInvoiceAndLoad>>
>
export type ApprovePendingInvoiceResult = NonNullable<
    Awaited<ReturnType<typeof approvePendingInvoice>>
>
export type CancelExpiredInvoicesResult = NonNullable<
    Awaited<ReturnType<typeof cancelExpiredInvoices>>
>
export type RunSchedulerJobResult = NonNullable<Awaited<ReturnType<typeof runSchedulerJob>>>
export type GetUnloadsByStatusResult = NonNullable<Awaited<ReturnType<typeof getUnloadsByStatus>>>
export type CreateRefundResult = NonNullable<Awaited<ReturnType<typeof createRefund>>>
export type ExportUnloadsResult = NonNullable<Awaited<ReturnType<typeof exportUnloads>>>
export type GetSummariesResult = NonNullable<Awaited<ReturnType<typeof getSummaries>>>
export type GetSummaryUrlResult = NonNullable<Awaited<ReturnType<typeof getSummaryUrl>>>
export type GetUnloadResult = NonNullable<Awaited<ReturnType<typeof getUnload>>>
export type UpdateUnloadResult = NonNullable<Awaited<ReturnType<typeof updateUnload>>>
export type SetUnloadStatusResult = NonNullable<Awaited<ReturnType<typeof setUnloadStatus>>>
export type FailAndNotifyResult = NonNullable<Awaited<ReturnType<typeof failAndNotify>>>
