export const highSecurityPermissions: string[] = [
    'credit-ops',
    'ariel:card',
    'ariel',
    'super',
    'styx',
    'phobos',
    'prospero',
    'prospero:balance-adjustments',
    'ariel:aml',
    'card:pan',
    'app-marketplace',
]
