import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Button, Col, Divider, Row, Typography } from 'antd'
import { Input, Form, Select } from 'formik-antd'
import { Formik } from 'formik'

import { PartnershipType } from '@pleo-io/deimos'

import { inputWidth, spacing } from 'theme/tokens'

import { useGetCompany } from 'services/deimos/companies'
import type { Company } from 'types/deimos-company'
import type { Partner } from 'types/partner-portal'
import CountrySelect from 'components/country-select/country-select'
import CompanyAddress from 'components/company-address/company-address'
import { capitalizeFirstLetter } from 'utils/strings'
import { validationSchema, FormValues } from './partner-info-validation-schema'

const { Text } = Typography

interface Props {
    isLoading: boolean
    partner: Partner
    ownCompany?: Company
    onSubmit: (values: FormValues) => void
}

export const EditDetailsForm: FC<React.PropsWithChildren<Props>> = ({
    isLoading,
    partner,
    ownCompany,
    onSubmit,
}) => {
    const [companyId, setCompanyId] = useState<string | null>(null)
    const { data: company, mutate } = useGetCompany(companyId)

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
                name: partner.name,
                tradingName: partner.tradingName || partner.name,
                ownCompanyId: ownCompany?.id || null,
                registrationNumber: partner.registrationNumber,
                addressLine1: partner.address.addressLine1,
                addressLine2: partner.address.addressLine2 || '',
                locality: partner.address.locality,
                region: partner.address.region || '',
                postalCode: partner.address.postalCode,
                country: partner.address.country,
                type: partner.type,
            }}
        >
            {({ isValid, values, setFieldValue }) => {
                const onChangeOwnCompanyId = (ownCompanyId: string) => {
                    if (ownCompanyId === '') {
                        setCompanyId('')
                        setFieldValue('ownCompanyId', null, true)
                        return
                    }
                    setCompanyId(ownCompanyId)
                    setFieldValue('ownCompanyId', ownCompanyId)
                    mutate()
                }

                return (
                    <Form>
                        <SectionContainer>
                            <Form.Item name="name" label="Partner name" hasFeedback colon={false}>
                                <LargeInput name="name" placeholder="Partner name" />
                            </Form.Item>
                            <Form.Item
                                name="tradingName"
                                label="Trading name"
                                hasFeedback
                                colon={false}
                            >
                                <LargeInput name="tradingName" placeholder="Trading name" />
                            </Form.Item>
                            <Form.Item
                                name="registrationNumber"
                                label="Registry No."
                                hasFeedback
                                colon={false}
                            >
                                <LargeInput name="registrationNumber" placeholder="Registry No." />
                            </Form.Item>
                            <Form.Item name="type" label="Partnership Type" colon={false}>
                                <Select style={{ width: inputWidth.large }} name="type">
                                    {Object.values(PartnershipType).map((value, index) => (
                                        <Select.Option key={index} value={value}>
                                            {capitalizeFirstLetter(value)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </SectionContainer>
                        <Divider dashed style={{ marginTop: 0 }} />
                        <SectionContainer>
                            <Form.Item
                                name="addressLine1"
                                label="Address line 1"
                                hasFeedback
                                colon={false}
                            >
                                <LargeInput name="addressLine1" placeholder="Address line 1" />
                            </Form.Item>
                            <Form.Item
                                name="addressLine2"
                                label="Address line 2"
                                hasFeedback
                                colon={false}
                            >
                                <LargeInput name="addressLine2" placeholder="Address line 2" />
                            </Form.Item>
                            <Form.Item name="locality" label="City" hasFeedback colon={false}>
                                <LargeInput name="locality" placeholder="City" />
                            </Form.Item>
                            <Form.Item name="region" label="Region" hasFeedback colon={false}>
                                <LargeInput name="region" placeholder="Region" />
                            </Form.Item>
                            <Form.Item
                                name="postalCode"
                                label="Postal Code"
                                hasFeedback
                                colon={false}
                            >
                                <LargeInput name="postalCode" placeholder="Postal Code" />
                            </Form.Item>

                            <Form.Item name="country" label="Country" hasFeedback colon={false}>
                                <CountrySelect
                                    name="country"
                                    placeholder="Country"
                                    style={{ width: `${inputWidth.large}` }}
                                    showSearch
                                />
                            </Form.Item>
                        </SectionContainer>
                        <Divider dashed style={{ marginTop: 0 }} />
                        <SectionContainer>
                            <Form.Item
                                name="ownCompanyId"
                                label="Own Company"
                                hasFeedback
                                colon={false}
                            >
                                <SectionContainer>
                                    <HugeInput
                                        name="ownCompanyId"
                                        placeholder="Own Company ID"
                                        onChange={(e) => onChangeOwnCompanyId(e.target.value)}
                                    />
                                    {values.ownCompanyId === ownCompany?.id ? (
                                        <Button
                                            data-testid="deactivate-owncompany-button"
                                            danger
                                            disabled={!ownCompany}
                                            onClick={() =>
                                                setFieldValue('ownCompanyId', null, true)
                                            }
                                        >
                                            Clear own company
                                        </Button>
                                    ) : (
                                        <Button
                                            data-testid="deactivate-owncompany-button"
                                            disabled={
                                                values.ownCompanyId === (ownCompany?.id || null)
                                            }
                                            onClick={() =>
                                                setFieldValue(
                                                    'ownCompanyId',
                                                    ownCompany?.id ?? null,
                                                    true
                                                )
                                            }
                                        >
                                            Undo
                                        </Button>
                                    )}
                                </SectionContainer>
                            </Form.Item>
                        </SectionContainer>
                        <Row>
                            {ownCompany && (
                                <Col span={12}>
                                    <Text
                                        delete={values.ownCompanyId !== ownCompany?.id}
                                        disabled={values.ownCompanyId !== ownCompany?.id}
                                        strong={values.ownCompanyId === ownCompany?.id}
                                    >
                                        {ownCompany.name}
                                    </Text>
                                    <CompanyAddress
                                        address={ownCompany?.address}
                                        textProps={{
                                            delete: values.ownCompanyId !== ownCompany?.id,
                                            disabled: values.ownCompanyId !== ownCompany?.id,
                                        }}
                                    />
                                </Col>
                            )}
                            {company &&
                                company?.id === values.ownCompanyId &&
                                ownCompany?.id !== values.ownCompanyId && (
                                    <Col span={12}>
                                        <Text strong>{company.name}</Text>
                                        <CompanyAddress address={company?.address} />
                                    </Col>
                                )}
                        </Row>
                        <Row justify="end">
                            <Button
                                htmlType="submit"
                                type="primary"
                                loading={isLoading}
                                disabled={!isValid}
                                data-testid="submit"
                            >
                                Save
                            </Button>
                        </Row>
                    </Form>
                )
            }}
        </Formik>
    )
}

const SectionContainer = styled.div`
    display: grid;
    grid-gap: 0 ${spacing.space24};
    grid-template-columns: 1fr 1fr;
`
const HugeInput = styled(Input)`
    width: ${inputWidth.huge};
`
const LargeInput = styled(Input)`
    width: ${inputWidth.large};
`

export default EditDetailsForm
