import { Typography } from 'antd'

import * as s from './company-spending.styles'
import type { CompanyStatusResponseModelProviderName } from 'bff/moons/generated/phobos'
import { CompanySpendStatusResponseVModelStatus } from 'bff/moons/generated/ersa'
import { UpdateSpendingForm } from './update-spending-form'

const { Text } = Typography

interface CompanySpendingCardProps {
    statusReason: string
    providerName: CompanyStatusResponseModelProviderName | null
    spendingStatus?: CompanySpendStatusResponseVModelStatus
    onUpdateSpendingStatus: (status: CompanySpendStatusResponseVModelStatus, reason: string) => void
}

export const CompanySpendingCard = ({
    statusReason,
    providerName,
    spendingStatus,
    onUpdateSpendingStatus,
}: CompanySpendingCardProps) => {
    switch (spendingStatus) {
        case CompanySpendStatusResponseVModelStatus.LIVE:
            return (
                <s.Container>
                    <s.StatusContainer>
                        <s.IconWrapper>
                            <s.LiveIcon />
                        </s.IconWrapper>
                        <Text>
                            Company spending is <strong>LIVE</strong>.<br />
                            The company can spend normally.
                        </Text>
                    </s.StatusContainer>
                    <UpdateSpendingForm
                        statusReason={statusReason}
                        nextSpendingStatus="BLOCKED"
                        confirmationMessage="Are you sure you want to BLOCK all spending for this company?"
                        buttonText="Block Spending"
                        providerName={providerName}
                        onUpdate={onUpdateSpendingStatus}
                    />
                </s.Container>
            )
        case CompanySpendStatusResponseVModelStatus.BLOCKED:
            return (
                <s.Container>
                    <s.StatusContainer>
                        <s.IconWrapper>
                            <s.BlockedIcon />
                        </s.IconWrapper>
                        <Text>
                            Company spending is <strong>BLOCKED</strong>.<br />
                            No spending on any cards allowed.
                        </Text>
                    </s.StatusContainer>
                    <UpdateSpendingForm
                        statusReason={statusReason}
                        nextSpendingStatus="LIVE"
                        confirmationMessage="Are you sure you want to UNBLOCK all spending for this company?"
                        buttonText="Unblock Spending"
                        providerName={providerName}
                        onUpdate={onUpdateSpendingStatus}
                    />
                </s.Container>
            )
        case CompanySpendStatusResponseVModelStatus.CLOSED:
            return (
                <s.Container>
                    <s.StatusContainer>
                        <s.IconWrapper>
                            <s.ClosedIcon />
                        </s.IconWrapper>
                        <Text>
                            Company spending is <strong>CLOSED</strong>.<br />
                            Spending has been stopped permanently.
                        </Text>
                    </s.StatusContainer>
                </s.Container>
            )
        default:
            return (
                <s.Container>
                    <s.IconWrapper>
                        <s.QuestionIcon />
                    </s.IconWrapper>
                    <Text>Encountered an unknown spending status.</Text>
                </s.Container>
            )
    }
}
