import { FC, useEffect, useState } from 'react'
import {
    Button,
    Descriptions,
    message,
    Modal,
    Result,
    Select as AntSelect,
    Space,
    Typography,
} from 'antd'
import { Form, Formik, FormikHelpers } from 'formik'
import { Input, Select, SubmitButton } from 'formik-antd'
import dayjs from 'packages/dayjs'
import { deSnakify } from 'utils/strings'
import {
    ResultScore,
    RiskAssessmentV2,
    RiskScoreV2,
    SubmitRiskAssessmentRequestV2,
} from 'types/styx'

import { FormValues, validationSchema } from './validation-schema'
import { hasCompletedAllRiskChecksV2, useIsNewComplianceOfficer } from '../utils'
import { useStyxCompany } from 'services/deimos/styx-company/styx-company'
import { inputWidth } from 'theme/tokens'
import { useUser } from 'providers/user-context'
import useAnalytics from 'utils/analytics'
import Spinner from 'components/content-spinner'

enum ValidRiskScore {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}

interface Props {
    companyName: string
    isNewComplianceOfficer: boolean
    riskAssessment?: RiskAssessmentV2
    onSubmit: (values: FormValues, formikHelpers: any) => void
    isValidating: boolean
}

const { Option } = Select
const { TextArea } = Input
const { Text, Paragraph } = Typography

enum SubmitButtonLabel {
    APPROVE = 'Approve',
    REJECT = 'Reject',
}

export const AssessmentResultV2: FC<React.PropsWithChildren<Props>> = ({
    riskAssessment,
    companyName,
    onSubmit,
    isNewComplianceOfficer,
    isValidating,
}) => {
    const riskResults = Object.values(ResultScore)
    const riskAssessmentV2 = riskAssessment as unknown as RiskAssessmentV2
    const hasCompletedAllRiskChecksBool = hasCompletedAllRiskChecksV2(riskAssessmentV2?.checks)
    const [hasMounted, setHasMounted] = useState(false)
    const [submitButtonLabel, setSubmitButtonLabel] = useState<SubmitButtonLabel>(
        SubmitButtonLabel.APPROVE
    )

    const isLowOrMedium =
        riskAssessmentV2?.customerRiskRating?.score === RiskScoreV2.MEDIUM ||
        riskAssessmentV2?.customerRiskRating?.score === RiskScoreV2.LOW

    const requiresSecondReview =
        hasCompletedAllRiskChecksBool &&
        !isLowOrMedium &&
        !isNewComplianceOfficer &&
        !riskAssessmentV2?.result

    if (isValidating && !hasMounted) {
        return (
            <div style={{ height: inputWidth.medium }}>
                <Spinner />
            </div>
        )
    }

    if (!hasCompletedAllRiskChecksBool) {
        return (
            <div style={{ height: inputWidth.medium }}>
                <Result status="info" title="Risk checks Incomplete" />
            </div>
        )
    }

    if (requiresSecondReview) {
        return (
            <Result
                status="success"
                title="Risk checks complete. Ready for 2nd verification!"
                subTitle={
                    <span>
                        Copy the text below and paste in{' '}
                        <a
                            href="https://getpleo.slack.com/archives/G8HSZFN9X"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            #2nd_verification_req
                        </a>
                    </span>
                }
                extra={[<Text key="text" strong copyable>{`${companyName} ready`}</Text>]}
            />
        )
    }

    return (
        <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
                result: riskAssessmentV2?.result || null,
                notes: riskAssessmentV2?.notes ?? '',
            }}
        >
            {({ validateForm, isValid, values }) => {
                useEffect(() => {
                    setHasMounted(true)
                }, [])

                useEffect(() => {
                    validateForm()
                }, [validateForm])

                useEffect(() => {
                    setSubmitButtonLabel(
                        values['result']?.toString() === ResultScore.FAIL
                            ? SubmitButtonLabel.REJECT
                            : SubmitButtonLabel.APPROVE
                    )
                }, [values])

                return (
                    <Form>
                        <Descriptions
                            layout="vertical"
                            bordered
                            column={4}
                            title="Assessment Result"
                        >
                            <Descriptions.Item label="Suggested risk score">
                                {riskAssessmentV2?.customerRiskRating?.score}
                            </Descriptions.Item>
                            <Descriptions.Item label="Result">
                                <Select
                                    name="result"
                                    placeholder="result"
                                    data-testid="risk-score-select"
                                >
                                    {riskResults.map((result) => (
                                        <Option
                                            value={result}
                                            key={result}
                                            data-testId={`risk-score-option-${result}`}
                                        >
                                            {deSnakify(result)}
                                        </Option>
                                    ))}
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label="Notes">
                                <TextArea
                                    name="notes"
                                    placeholder="Write a note"
                                    autoSize={{ maxRows: 5 }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Action">
                                <SubmitButton
                                    danger={values['result'] === ResultScore.FAIL}
                                    disabled={!isValid || isValidating}
                                    data-testid="submit"
                                >
                                    {submitButtonLabel}
                                </SubmitButton>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                )
            }}
        </Formik>
    )
}

export const AssessmentResultV2Container: FC<React.PropsWithChildren<unknown>> = () => {
    const { company, mutations, isValidating } = useStyxCompany()
    const isNewComplianceOfficer = useIsNewComplianceOfficer()
    const riskAssessmentV2 = company?.riskAssessment as unknown as RiskAssessmentV2
    const companyName = company?.legalName ?? ''
    const user = useUser()
    const analytics = useAnalytics()
    const [isForceRiskScoreModalOpen, setIsForceRiskScoreModalOpen] = useState(false)
    const [manuallySelectedRiskScore, setManuallySelectedRiskScore] = useState<
        RiskScoreV2 | undefined
    >(undefined)
    const [note, setNote] = useState<string>('')
    const [resultScore, setResultScore] = useState<ResultScore | undefined>()

    const checkSubmitIsValid = (
        { result, notes }: FormValues,
        formikHelpers: FormikHelpers<FormValues>
    ) => {
        const score = riskAssessmentV2.customerRiskRating?.score

        if (!result || !score) {
            message.error('Risk assessment result and score are required')
            return
        }

        setNote(notes || '')
        setResultScore(result)

        if (score === RiskScoreV2.PENDING && result === ResultScore.PASS) {
            setIsForceRiskScoreModalOpen(true)
        } else {
            onSubmit(result, notes || '')
        }

        formikHelpers.setSubmitting(false)
    }

    const onSubmit = async (result: ResultScore, notes: string, score?: RiskScoreV2) => {
        const body: SubmitRiskAssessmentRequestV2 = {
            result,
            notes,
            assessmentDate: dayjs().toISOString(),
            ...(score && { score }),
        }

        try {
            await mutations.submitRiskAssessmentV2(body)
            analytics?.track('Risk Assessment Submitted', {
                companyId: company?.globalId,
                userId: user.id,
            })

            message.success('Successfully submitted risk assessment!')
        } catch (e) {
            message.error(`Failed to submit risk assessment: ${(e as Error).message}`)
        }
    }

    const submitManuallySelectedRiskScore = () => {
        setIsForceRiskScoreModalOpen(false)
        onSubmit(resultScore!, note, manuallySelectedRiskScore)
    }

    const handleCancelModal = () => {
        setNote('')
        setResultScore(undefined)
        setManuallySelectedRiskScore(undefined)
        setIsForceRiskScoreModalOpen(false)
    }

    return (
        <>
            <AssessmentResultV2
                isValidating={isValidating}
                companyName={companyName}
                riskAssessment={riskAssessmentV2}
                onSubmit={checkSubmitIsValid}
                isNewComplianceOfficer={isNewComplianceOfficer}
            />
            <Modal
                title="Change assessment score before submitting"
                open={isForceRiskScoreModalOpen}
                onCancel={handleCancelModal}
                footer={[
                    <Button key="cancel-modal-button" onClick={handleCancelModal}>
                        Cancel
                    </Button>,
                    <Button
                        key="approve-modal-button"
                        type="primary"
                        disabled={!manuallySelectedRiskScore}
                        onClick={submitManuallySelectedRiskScore}
                    >
                        Approve
                    </Button>,
                ]}
            >
                <Space direction="vertical" size="large">
                    <div>
                        <Paragraph>
                            You cannot approve a risk assessment with a score of{' '}
                            <strong>Pending</strong>.
                        </Paragraph>
                        <Paragraph>Please select a new score from the drop-down below.</Paragraph>
                    </div>
                    <div>
                        <Paragraph>New score:</Paragraph>
                        <AntSelect
                            value={manuallySelectedRiskScore}
                            style={{ width: inputWidth.medium }}
                            placeholder="Risk assessment score"
                            onChange={(value) => setManuallySelectedRiskScore(value!)}
                            data-testid="new-risk-score-select"
                        >
                            {Object.values(ValidRiskScore).map((result) => (
                                <Option value={result} key={result}>
                                    {deSnakify(result)}
                                </Option>
                            ))}
                        </AntSelect>
                    </div>
                </Space>
            </Modal>
        </>
    )
}

export default AssessmentResultV2Container
