import { useState, useEffect } from 'react'
import { message, Button, Modal, Space, Descriptions } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Formik } from 'formik'
import { Input, Form } from 'formik-antd'

import type { Status } from 'types/card'
import { useCard } from 'services/deimos/cards'
import CardStatusForm, { FormValues } from 'components/card-status-form'
import { inputWidth } from 'theme/tokens'

const CardStatusWidget = () => {
    const [cardId, setCardId] = useState('')

    const { data, isValidating, error, mutations } = useCard(cardId)

    const closeModal = () => setCardId('')

    useEffect(() => {
        if (error) {
            message.error(error.message)
            setCardId('')
        }
    }, [error])

    const initialValues = { cardId }

    return (
        <Formik initialValues={initialValues} onSubmit={(values) => setCardId(values.cardId)}>
            {({ values, resetForm }) => (
                <>
                    <Form layout="horizontal">
                        <Space>
                            <Input
                                style={{ minWidth: `${inputWidth.small}` }}
                                name="cardId"
                                placeholder="Search by Card ID"
                            />
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={!!cardId && !data && isValidating}
                                disabled={!values.cardId}
                            >
                                <SearchOutlined />
                            </Button>
                        </Space>
                    </Form>
                    <Modal
                        destroyOnClose
                        width={450}
                        title={`Card ID: ${cardId}`}
                        open={!!cardId && !!data}
                        onCancel={closeModal}
                        footer={null}
                    >
                        <Descriptions column={1} size="small">
                            <Descriptions.Item label="Company ID">
                                {data?.companyId}
                            </Descriptions.Item>
                            <Descriptions.Item label="Wallet ID">
                                {data?.walletId}
                            </Descriptions.Item>
                            <Descriptions.Item label="Employee">
                                {data?.employee
                                    ? `${data?.employee.firstName} ${data?.employee.lastName} (${data?.employee.email})`
                                    : 'No employee'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Type">{data?.type}</Descriptions.Item>
                            <Descriptions.Item label="Status">{data?.status}</Descriptions.Item>
                            <Descriptions.Item label="Velocity">{data?.velocity}</Descriptions.Item>
                            <Descriptions.Item label="PAN">{data?.pan}</Descriptions.Item>
                            <Descriptions.Item label="Disabled by admin">
                                {data?.disabledByAdmin ? 'Yes' : 'No'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Disabled by limit">
                                {data?.disabledByLimit ? 'Yes' : 'No'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Blocked by exceeding PIN tries">
                                {data?.pinTriesExceeded ? 'Yes' : 'No'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Storebox">
                                {data?.storebox ? 'Yes' : 'No'}
                            </Descriptions.Item>
                        </Descriptions>
                        {data?.status && (
                            <CardStatusForm
                                initialStatus={data.status as Status}
                                onSubmit={async ({ status }: FormValues) => {
                                    try {
                                        await mutations.updateStatus(status)
                                        closeModal()
                                        resetForm({})
                                        message.success(`Updated card status to ${status}`)
                                    } catch (e) {
                                        message.error((e as Error).message)
                                    }
                                }}
                            />
                        )}
                    </Modal>
                </>
            )}
        </Formik>
    )
}

export default CardStatusWidget
