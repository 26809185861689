import request from './request'
import type { ChangeExpensesStatusRequest } from '@pleo-io/deimos'

export interface ParrotKafkaPayload {
    from?: string
}

export function getXml(companyId: string, dateFrom: string, dateTo: string) {
    return request().post(`rest/v1/companies/${companyId}/export/receipts/xml`, {
        json: {
            dateFrom,
            dateTo,
        },
    })
}

export const changeExpensesStatus = (payload: ChangeExpensesStatusRequest) =>
    request().put('rest/v1/admin/expenses/status', {
        json: payload,
    })

export const markExportAsFailed = (exportHistoryId: string): Promise<any> =>
    request().post(`rest/v1/admin/export/${exportHistoryId}/mark-as-failed`)
