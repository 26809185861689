import { FC, useState } from 'react'
import styled from 'styled-components'
import { Button, Modal } from 'antd'
import * as Yup from 'yup'
import { Input, TextArea } from 'components/input/input'

import { Formik } from 'formik'
import type { DocumentFile, DocumentFileRequest } from 'types/styx'
import { Form, SubmitButton } from 'formik-antd'
import { EditOutlined } from '@ant-design/icons'

interface Props {
    documentFile: DocumentFile
    editDocumentFile: (fileId: string, documentFileRequest: DocumentFileRequest) => void
}

const validationSchema = Yup.object()
    .shape({
        title: Yup.string().required(),
        description: Yup.string(),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

const EditDocumentFileModal: FC<React.PropsWithChildren<Props>> = ({
    documentFile,
    editDocumentFile,
}) => {
    const [editVisible, setEditVisible] = useState(false)
    return (
        <>
            <Button
                onClick={() => setEditVisible(!editVisible)}
                type="link"
                data-testid="edit-document-file"
            >
                <EditOutlined /> Edit
            </Button>
            <Modal
                title="Edit document file"
                open={editVisible}
                onCancel={() => setEditVisible(false)}
                footer={null}
                destroyOnClose
            >
                <FormContainer>
                    <Formik
                        initialValues={{
                            title: documentFile.fileName ?? documentFile.id,
                            description: documentFile?.description,
                        }}
                        onSubmit={(values: FormValues) => {
                            setEditVisible(false)
                            editDocumentFile(documentFile.id, {
                                title: values.title,
                                description: values.description || undefined,
                            })
                        }}
                        validationSchema={validationSchema}
                    >
                        <Form layout="vertical" style={{ width: '100%' }}>
                            <Input
                                name="title"
                                label="File name"
                                placeholder="title"
                                testId="title"
                                disabled={true}
                            />
                            <TextArea
                                name="description"
                                label="Description"
                                placeholder="Description"
                                testId="document-file-description-textarea"
                            />
                            <SubmitButton
                                aria-label="submit"
                                data-testid="edit-document-file-submit"
                            >
                                Save
                            </SubmitButton>
                        </Form>
                    </Formik>
                </FormContainer>
            </Modal>
        </>
    )
}

const FormContainer = styled.div`
    display: flex;
    .ant-btn-danger {
        align-self: flex-end;
    }
`
export default EditDocumentFileModal
