import { Card, Table } from 'antd'

import { DEFAULT_TABLE_ROW_COUNT } from '../constants'
import { useAppMarketplaceContext } from '../contexts/app-markeplace-context'
import { useAppListTableConfig } from '../hooks/use-app-list-columns'
import { AppSearchSection } from './app-search'

export function AppListSection() {
    const { apps, isLoading } = useAppMarketplaceContext()
    const { columns, needsPagination } = useAppListTableConfig(apps)

    return (
        <Card title="List of apps">
            <AppSearchSection />
            <Table
                dataSource={apps}
                columns={columns}
                loading={isLoading}
                rowKey="id"
                size="small"
                pagination={needsPagination && { pageSize: DEFAULT_TABLE_ROW_COUNT }}
            />
        </Card>
    )
}
