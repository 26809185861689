import { FC, useState } from 'react'

import InvoiceTable from './invoice-table/invoice-table'
import { bff } from '../../bff'
import { Pagination } from 'antd'

const InvoicesContainer: FC<React.PropsWithChildren<{ companyId: string }>> = ({ companyId }) => {
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)

    const {
        data: invoices,
        error: invoiceError,
        isLoading: isLoadingInvoices,
    } = bff.invoices.listInvoices.useQuery({ params: { companyId, limit, offset } })

    return (
        <>
            <InvoiceTable
                invoices={invoices?.data}
                error={invoiceError as any}
                loading={isLoadingInvoices}
            />
            <Pagination
                onChange={(page, pageSize) => {
                    setOffset(page > 1 ? (page - 1) * pageSize : 0)
                    setLimit(pageSize)
                }}
            />
        </>
    )
}

export default InvoicesContainer
