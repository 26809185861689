import { Space, Tag, Tooltip } from 'antd'
import type { FC } from 'react'
import type { OnboardingSource } from 'types/deimos-company'
import { onboardedViaExternal } from 'utils/company'
import { fontSize } from 'theme/tokens'

interface OnboardedViaTagProps {
    onboardedVia?: OnboardingSource
}

export const OnboardedViaTag: FC<OnboardedViaTagProps> = ({ onboardedVia }) => {
    const displayOnboardedVia = onboardedViaExternal(onboardedVia)

    return displayOnboardedVia ? (
        <Space>
            <Tooltip title={`The company has been onboarded via ${onboardedVia}`}>
                <Tag
                    color={'warning'}
                    data-testid={'onboarded-via-external-tag'}
                    style={{ fontSize: fontSize.size12 }}
                >
                    Onboarded via {onboardedVia}
                </Tag>
            </Tooltip>
        </Space>
    ) : null
}
