import React, { FC, useState } from 'react'
import {
    Timeline,
    Typography,
    Divider,
    Descriptions,
    Input,
    Button,
    Tooltip,
    message,
    Modal,
} from 'antd'
import dayjs from 'packages/dayjs'
import {
    Person,
    VerificationResult,
    VerificationProvider as Provider,
    Verification as VerificationType,
} from 'types/styx'
import { pathOr, compose } from 'ramda'
import { QuestionCircleFilled, RedoOutlined } from '@ant-design/icons'
import { color } from 'theme/tokens'
import { bff } from 'pages/compliance/bff'
import Spinner from 'components/content-spinner'
import { useParams } from 'react-router-dom'
const { Paragraph, Text, Title } = Typography

type Props = Required<Pick<Person, 'verification'>>

interface InitialUserData {
    firstName: string
    lastName: string
    email?: string
}

const getProviderResponse = (input: any) => {
    try {
        return compose(JSON.parse, pathOr('{}', ['providerResponse']))(input)
    } catch (e) {
        return input
    }
}

const getProviderInfo = (authType: ProviderAuthType) => (input: any) => {
    const authData = getProviderResponse(input)?.providerInfo?.[authType]
    return authData ?? input
}

type ProviderAuthType = 'onfidoAuth' | 'dkNemIDAuth' | 'seBankID' | 'dkMitID' | 'fiTupasAuth'

const getInfoForProvider = (provider?: Provider, verification?: VerificationType) => {
    switch (provider) {
        case 'onfido':
            return getProviderInfo('onfidoAuth')(verification) ?? verification
        case 'dkNemID':
            return getProviderInfo('dkNemIDAuth')(verification) ?? verification
        case 'seBankID':
            return getProviderInfo('seBankID')(verification) ?? verification
        case 'dkMitID':
            return getProviderInfo('dkMitID')(verification) ?? verification
        case 'dkNemIDOrMitID':
            return (
                getProviderInfo('dkMitID')(verification) ??
                getProviderInfo('dkNemIDAuth')(verification) ??
                verification
            )
        case 'fiTupas':
            return getProviderInfo('fiTupasAuth')(verification) ?? verification
        default:
            return verification
    }
}

// params user has registered with
const getInitialOnfidoData = compose(
    pathOr({ firstName: '', lastName: '' }, ['providerParameters', 'auth', 'onfido']),
    getProviderResponse
)

const resultColor = {
    [VerificationResult.NOT_EXECUTED]: color.gray,
    [VerificationResult.PENDING]: color.blue,
    [VerificationResult.FAIL]: color.red,
    [VerificationResult.PASS]: color.green,
}

const Verification: FC<React.PropsWithChildren<Props>> = ({ verification }) => {
    const [syncing, setSyncing] = useState(false)
    const { id: deimosCompanyId } = useParams()
    const { data, error } = bff.verification.getData.useQuery({
        personId: verification?.personId,
        deimosCompanyId,
    })
    const { mutateAsync: addExtraAttempts, isLoading: isAddAttemptInProgress } =
        bff.verification.addExtraAttempts.useMutation()

    const { mutateAsync: syncVerificationBff, isLoading: isSyncVerificationInProgress } =
        bff.verification.sync.useMutation()
    const { mutateAsync: verificationStateBff, isLoading: isVerificationStateInProgress } =
        bff.verification.state.useMutation()

    if (error) {
        return null
    }

    if (!data) {
        return <Spinner />
    }

    const oppositeVerificationResult = (current: VerificationResult) => {
        switch (current) {
            case VerificationResult.FAIL:
                return VerificationResult.PASS
            case VerificationResult.PASS:
                return VerificationResult.FAIL
            case VerificationResult.NOT_EXECUTED:
                return VerificationResult.PASS
            case VerificationResult.PENDING:
                return VerificationResult.PASS
        }
    }

    const syncVerification = async () => {
        try {
            setSyncing(true)
            await syncVerificationBff({ verificationId: verification.id })
        } catch (e) {
            message.error(`Failed to sync: ${(e as any).message}`)
        } finally {
            setSyncing(false)
        }
    }

    const setVerificationState = async () => {
        try {
            setSyncing(true)
            const opposite = oppositeVerificationResult(verification.result)
            if (!opposite) return
            await verificationStateBff({ verificationId: verification.id, state: opposite })
        } catch (e) {
            message.error(`Failed to sync: ${(e as any).message}`)
        } finally {
            setSyncing(false)
        }
    }

    const provider = verification.provider
    const providerInfo = getInfoForProvider(provider, verification)
    const completionData = providerInfo?.completionData
    const applicantId = providerInfo?.applicantId
    const initialUserData: InitialUserData = getInitialOnfidoData(verification)
    const formattedCompletionData = JSON.stringify(completionData, null, 4)

    return (
        <>
            <Title level={5}>Status</Title>
            <Timeline
                items={[{ color: resultColor[verification.result], children: verification.result }]}
            />

            <Paragraph>
                <Text type="secondary">Last update:</Text>
                <Text type="secondary"> {dayjs(verification.updatedAt).format('lll')} </Text>
                <Tooltip title="Sync Scrive verification">
                    <Button
                        loading={isSyncVerificationInProgress}
                        size="small"
                        shape="circle"
                        aria-label="sync"
                        disabled={syncing}
                        icon={<RedoOutlined spin={syncing} />}
                        onClick={syncVerification}
                    />
                </Tooltip>
            </Paragraph>
            <Paragraph>
                <Button
                    loading={isVerificationStateInProgress}
                    type="default"
                    onClick={setVerificationState}
                >
                    <span
                        style={{
                            color: resultColor[oppositeVerificationResult(verification.result)],
                        }}
                    >
                        Change status to {oppositeVerificationResult(verification.result)}
                    </span>
                </Button>
            </Paragraph>
            {data.shouldShowAttemptsSection && (
                <>
                    <Divider />
                    <Descriptions
                        title={
                            <Title level={5}>
                                Attempts{' '}
                                <Tooltip
                                    title={
                                        'Admins undergoing verification have three attempts to prevent bot abuse and control costs. Attempts can be increased by three if needed.'
                                    }
                                >
                                    <QuestionCircleFilled />
                                </Tooltip>
                            </Title>
                        }
                        column={2}
                    >
                        <Descriptions.Item>Attempts used: </Descriptions.Item>
                        <Descriptions.Item>
                            <span data-testid="numberOfAttempts">{data.numberOfAttempts}</span>
                        </Descriptions.Item>
                        <Descriptions.Item>Maximum number of attempts:</Descriptions.Item>
                        <Descriptions.Item>
                            <span data-testid="maximumNumberOfAttempts">
                                {data.maximumNumberOfAttempts}
                            </span>
                        </Descriptions.Item>
                    </Descriptions>
                    <Button
                        type="default"
                        loading={isAddAttemptInProgress}
                        disabled={data.numberOfAttempts < data.maximumNumberOfAttempts}
                        onClick={() => {
                            Modal.confirm({
                                centered: true,
                                title: 'Adding 3 extra attempts',
                                content: (
                                    <p>
                                        Are you sure you want to add 3 extra attempts? This action
                                        cannot be reversed and may incurs costs for Pleo.
                                    </p>
                                ),
                                onOk: () => {
                                    addExtraAttempts({ personId: verification.personId })
                                },
                            })
                        }}
                    >
                        Allow 3 extra attempts
                    </Button>
                </>
            )}

            {verification.notes && (
                <>
                    <Divider />

                    <Text id="notes-label">Notes</Text>
                    <Input.TextArea
                        rows={3}
                        readOnly
                        value={verification.notes}
                        aria-labelledby="notes-label"
                    />
                </>
            )}

            <Divider />

            <Descriptions title="User-entered info" column={2}>
                <Descriptions.Item>First name:</Descriptions.Item>
                <Descriptions.Item>{initialUserData.firstName || 'N/A'}</Descriptions.Item>
                <Descriptions.Item>Last name:</Descriptions.Item>
                <Descriptions.Item>{initialUserData.lastName || 'N/A'}</Descriptions.Item>
            </Descriptions>
            <Descriptions title="Scrive Provider data" column={2}>
                <Descriptions.Item>Provider:</Descriptions.Item>
                <Descriptions.Item>{provider}</Descriptions.Item>
            </Descriptions>
            <Input.TextArea readOnly rows={18} defaultValue={formattedCompletionData} />
            {provider === 'onfido' && applicantId && (
                <Descriptions>
                    <Descriptions.Item>ApplicantId:</Descriptions.Item>
                    <Descriptions.Item>
                        <Tooltip title="Copy to clipboard">
                            <Button
                                type="text"
                                onClick={async () => {
                                    await navigator.clipboard.writeText(applicantId)
                                    message.success('Copied applicantId to clipboard')
                                }}
                            >
                                {applicantId}
                            </Button>
                        </Tooltip>
                    </Descriptions.Item>
                </Descriptions>
            )}
        </>
    )
}

export default Verification
