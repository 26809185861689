import { Button, Input, Select, TableColumnsType, Typography, message } from 'antd'
import { RiskCheckDataV2, RiskCheckScoreV2, RiskTypesV2, UpdateRiskCheck } from 'types/styx'
import { ActionsContainer } from 'components/layout-containers'
import { deSnakify } from 'utils/strings'
import { inputWidth } from 'theme/tokens'

import { useEffect, useRef, useState, type ChangeEvent } from 'react'
import type { Analytics } from '@segment/analytics-next'
import { hasCompletedAllRiskChecksV2 } from 'pages/compliance/shared/risk-assessment/utils'
import { riskScoreOptions } from '../utils/risk-assessment-utils'
const { Option } = Select

const { TextArea } = Input
const { Text } = Typography

const MIN_NOTE_LENGTH = 3

interface ColumnFunctions {
    onUpdateRiskCheck: (checkId: string, body: UpdateRiskCheck) => Promise<void>
}

interface ColumnProps {
    dataSource: RiskCheckDataV2[]
    analytics: Analytics | null
    companyId?: string
    userId?: string | null
}

export const riskFactorNamesMap = {
    [RiskTypesV2.ADVERSE_MEDIA_COMPANY_KYC_CHECKS]: 'Adverse media',
    [RiskTypesV2.COMPANY_LEGAL_FORM]: 'Legal form',
    [RiskTypesV2.COMPANY_LEGAL_NAME_CHANGE]: 'Legal name change',
    [RiskTypesV2.COMPANY_SANCTIONS]: 'Sanctions',
    [RiskTypesV2.COMPLEX_OWNERSHIP_AND_CONTROL_STRUCTURE]: 'Ownership structure',
    [RiskTypesV2.COUNTRY_COMPANY_INCORPORATION]: 'Country of incorporation',
    [RiskTypesV2.COUNTRY_RELATED_PERSONS_ADDRESS]: 'Address of people in scope (non-UBOs)',
    [RiskTypesV2.DATE_COMPANY_INCORPORATION]: 'Incorporation date',
    [RiskTypesV2.INDUSTRY_CODE_COMPANY_INDUSTRIES]: 'Industry',
    [RiskTypesV2.PO_HIGHER_RISK_COUNTRY]: 'Postbox in higher risk country',
    [RiskTypesV2.UBO_ADDRESS]: 'UBO address',
    [RiskTypesV2.PARENT_PERSON_PEP_CHECK]: 'PEP',
    [RiskTypesV2.PERSON_PEP_CORPORATE_CHECK]: 'Role in company',
    [RiskTypesV2.PERSON_PEP_POSITION_CHECK]: 'Political position and level',
    [RiskTypesV2.PERSON_PEP_GEOGRAPHY_CHECK]: 'Geography and type',
    [RiskTypesV2.PERSON_PEP_CHECK]: 'PEP',
}

export function getRiskTableColumns(
    props: ColumnProps & ColumnFunctions
): TableColumnsType<RiskCheckDataV2> {
    const { dataSource, onUpdateRiskCheck, analytics, companyId, userId } = props

    const [values, setValues] = useState<RiskCheckDataV2[]>([])
    const [notesState, setNotesState] = useState<string[]>([])

    const persistedOrder = useRef<RiskCheckDataV2[]>(dataSource)

    useEffect(() => {
        setValues(dataSource)
        setNotesState(dataSource.map((checkData) => checkData.notes))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const emptyState: Record<string, RiskCheckDataV2> = {}

        const riskTypeDict: Record<string, RiskCheckDataV2> = values.reduce((acc, check) => {
            acc[check.riskTypeId] = check
            return acc
        }, emptyState)

        const newState = persistedOrder.current.map((riskCheck) => {
            return riskTypeDict[riskCheck.riskTypeId] || { ...riskCheck }
        })

        setValues(newState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectRiskScore = (recordIndex: number) => (selectValue: RiskCheckScoreV2) => {
        setValues((prevState) => {
            return prevState.map((riskCheck, mapIndex) =>
                recordIndex === mapIndex ? { ...riskCheck, score: selectValue } : riskCheck
            )
        })
    }

    const handleAddNote = (recordIndex: number) => (event: ChangeEvent<HTMLTextAreaElement>) => {
        setNotesState((prevState) => {
            return prevState.map((note, mapIndex) =>
                recordIndex === mapIndex ? event.target.value : note
            )
        })
    }

    const handleUpdateCheck = async (recordIndex: number) => {
        setValues((prevState) => {
            return prevState.map((riskCheck, mapIndex) =>
                recordIndex === mapIndex
                    ? {
                          ...riskCheck,
                          isBeingUpdated: true,
                      }
                    : riskCheck
            )
        })
    }

    const handleApproveCheck = async (recordIndex: number) => {
        try {
            const check = values[recordIndex]
            const notes = notesState[recordIndex]
            const newState = values.map((riskCheck, mapIndex) =>
                recordIndex === mapIndex
                    ? { ...riskCheck, isBeingUpdated: false, notes }
                    : riskCheck
            )

            setValues(newState)

            await onUpdateRiskCheck(check.id, {
                score: check.score,
                notes: notes,
            })

            if (hasCompletedAllRiskChecksV2(values)) {
                analytics?.track('All Risk Checks Completed', {
                    companyId: companyId,
                    userId: userId,
                })
            }
        } catch (e) {
            message.error(`Something went wrong creating risk check: ${(e as Error).message}`)
        }
    }

    const isApproveButtonDisabled = (recordIndex: number) => {
        const { suggestedScore, score, childFactors } = values[recordIndex]
        const notes = notesState[recordIndex]

        if (score === RiskCheckScoreV2.NOT_ASSIGNED) return true
        if (suggestedScore !== score && notes.length < MIN_NOTE_LENGTH) return true
        if (childFactors && childFactors.some((child) => !child.hasApprovedScore)) return true

        return false
    }

    const isParentRiskCheck = (riskCheck: RiskCheckDataV2) => {
        return riskCheck.childFactors && riskCheck.childFactors.length > 0
    }

    return [
        {
            title: 'Risk Factors',
            dataIndex: 'riskType',
            render: (type) => (
                <Text strong>{riskFactorNamesMap[type as keyof typeof riskFactorNamesMap]}</Text>
            ),
        },
        {
            title: 'Factor ID',
            dataIndex: 'riskTypeId',
            width: '80px',
        },
        {
            title: 'Risk Level',
            render: (_, _record, recordIndex) => {
                return (
                    <Select
                        style={{ minWidth: `${inputWidth.small}` }}
                        showSearch
                        value={values[recordIndex]?.score}
                        onChange={handleSelectRiskScore(recordIndex)}
                        placeholder="Level of risk"
                        disabled={
                            !values[recordIndex]?.isBeingUpdated || isParentRiskCheck(_record)
                        }
                    >
                        {riskScoreOptions(_record).map((scoreOption) => (
                            <Option
                                value={scoreOption}
                                key={scoreOption}
                                data-testid="risk-score-option"
                            >
                                {deSnakify(scoreOption)}
                            </Option>
                        ))}
                    </Select>
                )
            },
        },
        {
            title: 'Mitigation actions',
            width: '30%',
            dataIndex: 'notes',
            render: (_, _record, recordIndex) => {
                return (
                    <TextArea
                        value={notesState[recordIndex]}
                        onChange={handleAddNote(recordIndex)}
                        placeholder="Write a note"
                        autoSize={{ maxRows: 5 }}
                        disabled={!values[recordIndex]?.isBeingUpdated}
                    />
                )
            },
        },
        {
            title: 'Owner',
            dataIndex: 'ownerName',
        },
        {
            title: 'Check date',
            dataIndex: 'checkDate',
            width: '120px',
        },
        {
            title: 'Action',
            dataIndex: 'riskType',
            key: 'action',
            width: '100px',
            render: (_, _record, recordIndex) => {
                return (
                    <ActionsContainer>
                        {values[recordIndex]?.isBeingUpdated ? (
                            <Button
                                type="link"
                                data-testid="approve"
                                onClick={() => handleApproveCheck(recordIndex)}
                                disabled={isApproveButtonDisabled(recordIndex)}
                            >
                                Approve
                            </Button>
                        ) : (
                            <Button
                                type="link"
                                data-testid="update"
                                danger
                                onClick={() => handleUpdateCheck(recordIndex)}
                            >
                                Update
                            </Button>
                        )}
                    </ActionsContainer>
                )
            },
        },
    ]
}
