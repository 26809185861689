import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, message, Popconfirm, Space, Typography } from 'antd'
import { useInvite } from 'services/kerberos/invites'

const { Paragraph, Text } = Typography

const RemoveInviteConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Remove invite?</Text>
        <Paragraph>Are you sure you want to remove this invite?</Paragraph>
    </Space>
)

export const RemoveInvite: FC<{ inviteId?: string }> = ({ inviteId }) => {
    const navigate = useNavigate()
    const {
        data: invite,
        mutations: { deleteInvite },
    } = useInvite(inviteId)

    if (!invite) {
        return null
    }

    return (
        <Popconfirm
            title={<RemoveInviteConfirmation />}
            onConfirm={async () => {
                try {
                    await deleteInvite()
                    message.success('Invite removed')

                    navigate(`/customer-success/search`)
                } catch (e) {
                    message.error('Can not remove invite')
                }
            }}
            okText="Yes"
            cancelText="No"
            placement="bottom"
            overlayStyle={{ width: '400px' }}
        >
            <Button danger data-testid="remove-invite">
                Remove Invite
            </Button>
        </Popconfirm>
    )
}
