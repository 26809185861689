import { ContentContainer } from 'components/layout-containers'
import type { Person } from 'types/styx'
import { bff } from 'pages/compliance/bff'
import { Alert, notification } from 'antd'
import type { PersonSearchResponse } from 'bff/moons/generated/styx-v1'
import { useEffect } from 'react'
import { useCursorPagination } from 'utils/use-cursor-pagination'
import type { PaginationData } from '@pleo-io/deimos'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import SearchSamePersonList from './search-same-person-list'

interface Props {
    person: Person
    onSuccessfulCopy: () => void
}

const SearchSamePersonContainer = ({ person, onSuccessfulCopy }: Props) => {
    const { paginationRequest, setCurrentPagination, ...paginationMutations } = useCursorPagination(
        {
            limit: 10,
            sorting: [],
        }
    )

    const { data, isLoading, error } = bff.searchSamePerson.getSamePersonByName.useQuery({
        firstName: person.name.givenName,
        lastName: person.name.familyName,
        middleName: person.name.middleName,
        pagination: paginationRequest,
    })

    const persons = data?.data as PersonSearchResponse[] | []
    const pagination = data?.pagination as PaginationData<string> | undefined

    const { mutateAsync: copyPersonAsync } = bff.searchSamePerson.copyPerson.useMutation()

    const copyPerson = async (personId: string, companyId: string) => {
        try {
            await copyPersonAsync({
                personId,
                copyInto: person.id,
                companyId,
            })

            notification.success({
                message: 'Person copied',
                description: 'The person has been copied successfully',
            })

            onSuccessfulCopy()
        } catch (e: any) {
            notification.error({
                message: 'Failed to copy person',
                description: e.message,
            })
        }
    }

    useEffect(() => {
        if (pagination) {
            setCurrentPagination({
                ...pagination,
                currentRequestPagination: pagination.currentRequestPagination || null,
            })
        }
    }, [pagination, setCurrentPagination])

    const loading = isLoading && !error

    return (
        <ContentContainer>
            {error && (
                <Alert
                    message="Error loading data. Please reload to try again."
                    type="error"
                    showIcon
                />
            )}
            <SearchSamePersonList
                loading={loading}
                currentPersonId={person.id}
                persons={persons}
                copyPerson={copyPerson}
            />
            {paginationRequest && pagination && (
                <CursorPaginationControls
                    isFetching={loading}
                    pagination={pagination}
                    onChangeLimit={paginationMutations.updateLimit}
                    limit={paginationRequest.limit}
                    onNextPage={paginationMutations.nextPage}
                    onPrevPage={paginationMutations.previousPage}
                    onFirstPage={paginationMutations.resetFirstPage}
                />
            )}
        </ContentContainer>
    )
}

export default SearchSamePersonContainer
