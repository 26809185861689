import type { FC } from 'react'
import { Table, Typography, Tag } from 'antd'
import dayjs from 'packages/dayjs'

import type { Transaction } from 'types/proof-of-funds'

const { Text } = Typography

const CompanyHistoryTable: FC<
    React.PropsWithChildren<{ dataSource: Transaction[]; loading: boolean }>
> = ({ dataSource, loading }) => {
    const statusTagColor = {
        APPROVED: 'green',
        PENDING: 'blue',
        INVESTIGATING: 'orange',
        REJECTED: 'red',
    }
    const columns = [
        {
            title: 'Account',
            dataIndex: 'senderBank',
            key: 'senderBank',
            render: (senderBank: string) => {
                return <Text>{senderBank}</Text>
            },
        },
        {
            title: 'Sender',
            dataIndex: 'senderInformation',
            key: 'senderInformation',
            render: (senderInformation: string) => {
                return <Text>{senderInformation}</Text>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status: keyof typeof statusTagColor) => (
                <Tag color={statusTagColor[status]}>{status}</Tag>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
        },
        {
            title: 'Performed',
            dataIndex: 'performedAt',
            key: 'performedAt',
            render: (performedAt: string) => dayjs(performedAt).format('lll'),
        },
    ]
    return (
        <Table
            loading={loading}
            data-testid="table"
            dataSource={dataSource}
            columns={columns}
            size="small"
            rowKey="id"
            pagination={{ pageSize: 5, showSizeChanger: false }}
            scroll={{ y: '50vh' }}
        />
    )
}

export default CompanyHistoryTable
