import type { FC } from 'react'
import { Collapse, Empty, Result } from 'antd'

import type { Management, Person } from 'types/styx'

import DirectorPanel from './director-panel/director-panel'
import type { ErrorResponse } from '@pleo-io/deimos'
import { getPersonName, getRoleNames } from 'pages/compliance/shared/utils'
import { isUnknownShareholder } from 'utils/is-shareholder'
import Skeleton from 'components/content-skeleton'
import { PanelHeader } from 'pages/compliance/shared/styled'
import type { CompanyStakeholderResponse } from 'bff/moons/generated/styx-v1'

interface Props {
    management?: Management | CompanyStakeholderResponse[]
    error?: ErrorResponse
}

const { Panel } = Collapse

export const DirectorAccordion: FC<React.PropsWithChildren<Props>> = ({ management, error }) => {
    const noManagement = management?.length === 0

    if (error) {
        return <Result title="Could not fetch management" subTitle={error.message} status="error" />
    }

    if (!management) {
        return <Skeleton data-testid="skeleton" />
    }

    if (noManagement) {
        return (
            <Empty description="There is no management currently associated with this company." />
        )
    }

    return (
        <Collapse>
            {management
                // We want management in a consistent order since the API returns them randomly - it's better for compliance workflows
                // slice first to create a new array, since sort mutates and creates errors in 'strict-mode'
                ?.slice()
                .sort((a, b) => a.id.localeCompare(b.id))
                .map((manager) => {
                    const stakeholder = manager as CompanyStakeholderResponse
                    const person = manager as Person
                    if (isUnknownShareholder(stakeholder)) {
                        return (
                            <Panel
                                key={person.id}
                                disabled={true}
                                header={
                                    <span data-testid="name-and-title">
                                        <strong>{getPersonName(person.name)}</strong>: Missing
                                        shareholder information
                                    </span>
                                }
                            />
                        )
                    } else {
                        return (
                            <Panel
                                key={person.id}
                                header={<PanelHeaderContainer person={person} />}
                            >
                                {<DirectorPanel person={person} />}
                            </Panel>
                        )
                    }
                })}
        </Collapse>
    )
}

export const PanelHeaderContainer: FC<React.PropsWithChildren<{ person: Person }>> = ({
    person,
}) => {
    const name = getPersonName(person.name)
    const roles = getRoleNames(person.roles)
    const isInScope = person.kycPerson ? 'In scope' : 'Not in scope'

    return (
        <PanelHeader>
            <span>
                <strong>{name}</strong>:
            </span>
            <span>{roles}</span>
            <span>{isInScope}</span>
        </PanelHeader>
    )
}

export default DirectorAccordion
