import { CompanySizeEnum } from 'types/company-size'
import type { OnboardingSource } from 'types/deimos-company'
import type { StyxCompany } from 'types/styx'

export const getRiskScore = (company: StyxCompany) => {
    if (!company.riskAssessment || !company.riskAssessment.result) {
        return undefined
    }
    return company.riskAssessment.result === 'FAIL' ? 'FAIL' : company.riskAssessment.score
}

export const getCompanySizeLabel = (size?: string) => {
    return size === CompanySizeEnum.SoleTrader ? 'Sole trader' : size ?? 'N/A'
}

export const onboardedViaExternal = (onboardedVia?: OnboardingSource) =>
    onboardedVia !== undefined && onboardedVia !== 'PLEO'
