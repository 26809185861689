import React from 'react'

import SnapshotTabs from 'pages/compliance/company/shared/snapshot-tabs/snapshot-tabs'
import { useClosedCompanyKycCheckpoints } from 'services/deimos/styx-company/styx-company'

import ReadonlyDirectors from 'pages/compliance/company/people/readonly-directors/readonly-directors'
import DirectorAccordion from 'pages/compliance/closed-company/people/director-accordion/director-accordion'
import { bff } from 'pages/compliance/bff'
import { useParams } from 'react-router-dom'
import { AdminVerificationRequestSection } from 'pages/compliance/company/shared/admin-verification-request-section/admin-verification-request-section'
import type { ErrorResponse } from '@pleo-io/deimos'

type PeopleProps = {
    companyId: string
    companyCountry: string
}

const People = ({ companyId, companyCountry }: PeopleProps) => {
    const [activeSnapshot, setActiveSnapshot] = React.useState<string | undefined>()
    const kycCheckpoints = useClosedCompanyKycCheckpoints()
    const { id: deimosCompanyId } = useParams()
    const snapshotQuery = activeSnapshot ? activeSnapshot : ''

    const { data, error } = bff.people.getClosedCompanyManagement.useQuery({
        id: deimosCompanyId,
        snapshot: snapshotQuery,
    })

    const errorResponse =
        error === null
            ? undefined
            : ({ message: error?.message, status: error?.data?.httpStatus } as ErrorResponse)

    const onFetchSnapshot = (snapshotDate?: string) => setActiveSnapshot(snapshotDate)

    return (
        <SnapshotTabs
            kycCheckpoints={kycCheckpoints}
            onFetchSnapshot={onFetchSnapshot}
            readonlyChildren={(snapshot) => (
                <>
                    <ReadonlyDirectors
                        management={data}
                        snapshot={snapshot}
                        error={errorResponse}
                    />

                    <AdminVerificationRequestSection
                        companyId={companyId}
                        companyCountry={companyCountry}
                        snapshot={snapshot}
                        readOnly
                    />
                </>
            )}
        >
            <DirectorAccordion management={data} />

            <AdminVerificationRequestSection
                companyId={companyId}
                companyCountry={companyCountry}
                readOnly
            />
        </SnapshotTabs>
    )
}

export default People
