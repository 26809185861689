import { Typography } from 'antd'
import type { FC } from 'react'

const { Text } = Typography

interface Props {
    label: string
    value: string
    copyable?: boolean
}

const InfoElemHorizontal: FC<React.PropsWithChildren<Props>> = ({ label, value, copyable }) => (
    <span>
        {label}:{' '}
        <Text copyable={copyable} type="secondary">
            {value}
        </Text>
    </span>
)

export default InfoElemHorizontal
