import { Button, Popconfirm, Alert } from 'antd'
import { Select, Form, Input } from 'formik-antd'
import * as Yup from 'yup'
import { Formik } from 'formik'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import * as supplierSelectors from 'store/modules/suppliers/selectors'
import type { UpdateSupplierStatusPayload } from 'services/deimos/suppliers'
import { actions as supplierActions } from 'store/modules/suppliers/slice'
import { SupplierStatus } from '../../../types/deimos-supplier'
import { type FC, type PropsWithChildren, useState, useCallback, useMemo } from 'react'

const formValidationSchema = Yup.object().shape({
    status: Yup.string().required(),
    note: Yup.string().notRequired(),
})

export const StatusForm: FC<PropsWithChildren<unknown>> = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const supplier = useSelector(supplierSelectors.selectSupplier)
    const [confirmVisible, setConfirmVisible] = useState(false)

    const updateSupplierStatus = useCallback(
        (payload: UpdateSupplierStatusPayload) => {
            dispatch(supplierActions.updateSupplierStatus({ supplierId: id, data: payload }))
        },
        [dispatch, id]
    )

    // We treat "DRAFT" as no value, so it cannot be selected as a status
    const initialStatus = supplier?.status === SupplierStatus.DRAFT ? undefined : supplier?.status

    const possibleStatuses = useMemo(() => getPossibleStatuses(initialStatus), [initialStatus])
    const showStatusWarning = possibleStatuses.length <= 1

    if (!supplier) {
        return null
    }

    return (
        <>
            {showStatusWarning && (
                <Alert
                    message="You can't change the supplier's status because it's missing information. Edit supplier to add missing info."
                    type="warning"
                    showIcon
                    style={{ marginBottom: `${spacing.space16}` }}
                />
            )}
            <Formik
                initialValues={{ status: initialStatus, note: supplier.note }}
                validationSchema={formValidationSchema}
                onSubmit={(values) => updateSupplierStatus(values as UpdateSupplierStatusPayload)}
            >
                {({ resetForm, submitForm, dirty, isValid }) => (
                    <StatusContainer as={Form} layout="vertical">
                        <Form.Item label="Supplier status" name="status">
                            <Select placeholder="UNVERIFIED" name="status">
                                {possibleStatuses.map((result) => (
                                    <Select.Option value={result} key={result}>
                                        {result}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Note" name="note">
                            <Input.TextArea name="note" rows={4} placeholder="Comment..." />
                        </Form.Item>
                        <Form.Item label="&nbsp;" name="buttons">
                            <Popconfirm
                                title="Are you sure want to update this suppliers' status?"
                                placement="topRight"
                                open={confirmVisible}
                                onCancel={() => {
                                    setConfirmVisible(false)
                                    resetForm()
                                }}
                                okButtonProps={{
                                    onClick: () => {
                                        submitForm()
                                        setConfirmVisible(false)
                                    },
                                    disabled: !dirty,
                                }}
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={!dirty || !isValid || showStatusWarning}
                                    onClick={() => setConfirmVisible(true)}
                                >
                                    Save
                                </Button>
                            </Popconfirm>
                        </Form.Item>
                    </StatusContainer>
                )}
            </Formik>
        </>
    )
}

const getPossibleStatuses = (initialStatus: SupplierStatus | undefined) => {
    let possibleSupplierStatusTransition: SupplierStatus[] = []
    switch (initialStatus) {
        case SupplierStatus.REQUIRES_VERIFICATION:
            possibleSupplierStatusTransition = [
                SupplierStatus.INCORRECT_BANK_DETAILS,
                SupplierStatus.VERIFIED,
                SupplierStatus.BLOCKED,
            ]
            break
        case SupplierStatus.INCORRECT_BANK_DETAILS_MANUAL:
            possibleSupplierStatusTransition = [
                SupplierStatus.UNVERIFIED,
                SupplierStatus.VERIFIED,
                SupplierStatus.REQUIRES_VERIFICATION,
                SupplierStatus.DRAFT,
            ]
            break
        default:
            possibleSupplierStatusTransition = [
                SupplierStatus.INCORRECT_BANK_DETAILS,
                SupplierStatus.REQUIRES_VERIFICATION,
                SupplierStatus.VERIFIED,
                SupplierStatus.BLOCKED,
                SupplierStatus.DEPRECATED,
            ]
    }
    return possibleSupplierStatusTransition
}

const StatusContainer = styled.div`
    display: grid;
    grid-gap: ${spacing.space24};
    grid-template-columns: 1fr 3fr auto;
`
