import * as yup from 'yup'
import type { RejectRefund } from 'services/deimos/proof-of-funds'
import { countries } from 'countries-list'
import { ProofOfFundsRejectionReason } from 'types/proof-of-funds'

export const refundModalValidationSchema = yup.object().shape({
    accountNumber: yup.string().when('iban', {
        is: (iban: string) => iban === undefined,
        then: (schema) => schema.required('Account number or IBAN are required'),
    }),
    iban: yup.string(),
    bankCode: yup
        .string()
        .when('bic', {
            is: (bic: string) => bic === undefined,
            then: (schema) => schema.required('Sort code or BIC are required'),
        })
        .when('accountNumber', {
            is: (accountNumber: string) => accountNumber !== undefined,
            then: (schema) => schema.required('Required'),
        }),
    bic: yup.string().when('iban', {
        is: (iban: string) => iban !== undefined,
        then: (schema) => schema.required('Required'),
    }),
    country: yup
        .string()
        .oneOf(
            Object.keys(countries).map((key) => key.toLowerCase()),
            'Lowercase characters (E.g. "gb")'
        )
        .required('Required'),
    rejectionReason: yup
        .string()
        .required('Required')
        .nullable()
        .oneOf([...Object.values(ProofOfFundsRejectionReason), null]),
    note: yup.string(),
})
// .required()

export type FormValues = yup.InferType<typeof refundModalValidationSchema>

export const mapValuesToRequest = (values: FormValues): RejectRefund => {
    return {
        bankAccountInfo: {
            iban: values.iban,
            bic: values.bic,
            accountNumber: values.accountNumber,
            bankCode: values.bankCode,
        },
        rejectionReason: values.rejectionReason || undefined,
        note: values.note,
        paymentPartyInfo: {
            country: values.country,
        },
    }
}
