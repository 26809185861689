import { useFormikContext } from 'formik'
import { useState, useEffect } from 'react'

/**
 * Return provided value with a delay. Used to debounce value changes which trigger other effects.
 */
function useDebounce<T>(value: T, delay: number): T {
    const [debouncedValue, setDebouncedValue] = useState<T>(value)

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedValue(value), delay)

        return () => {
            clearTimeout(timer)
        }
    }, [value, delay])

    return debouncedValue
}

/**
 * Implemented as component and not hook to simplify usage of context
 * i.e. to avoid unnecessary usage of hook in every field-component
 * @example
 * <Formik onSubmit={handleSubmit}>
 *     {({values: ValuesTypes}) => (
 *     <Form>
 *         <FormikInput name="fieldName" isInvalid={!values.fieldName} />
 *         <FormikAutoSubmit<ValuesTypes> />
 *     </Form>
 *     )}
 * <Formik>
 * */

export const FormikAutoSubmit = <T extends {}>() => {
    const { values, initialValues, submitForm } = useFormikContext<T>()
    const debouncedValues = useDebounce(values, 200)
    useEffect(() => {
        if (debouncedValues !== initialValues) {
            submitForm()
        }
    }, [debouncedValues, initialValues, submitForm])
    return null
}
