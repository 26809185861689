import { Divider, Spin } from 'antd'

import { useKycInformation } from './use-kyc-information'
import { MissingKycInfo } from './components/kyc-datapoints'
import { HighRiskKycInformation, StandardKycInformation } from './components/kyc-status'
import { Column, LoadingWrapper, SubHeading, Wrapper } from './kyc-overview.styled'

export const KycOverview = ({ companyId }: { companyId?: string }) => {
    const { standard, highRisk, isLoading, displayHighRisk } = useKycInformation(companyId)

    return (
        <Wrapper>
            {isLoading ? (
                <LoadingWrapper>
                    <Spin style={{ alignSelf: 'center' }} />
                </LoadingWrapper>
            ) : (
                <>
                    <Column>
                        <StandardKycInformation data={standard} />
                        {displayHighRisk && (
                            <>
                                <Divider />
                                <HighRiskKycInformation data={highRisk} />
                            </>
                        )}
                    </Column>
                    <Column>
                        <SubHeading>Missing Customer Datapoints</SubHeading>
                        <MissingKycInfo />
                    </Column>
                </>
            )}
        </Wrapper>
    )
}
