import { Space, Statistic } from 'antd'
import styled from 'styled-components'

import { bff } from './bff-hooks'
import type { CaseCountsResponse } from './index.bff'

const CaseCount = ({ title, value }: { title: string; value: number }) => {
    return (
        <CaseCountContainer>
            <Statistic
                title={title}
                value={value}
                style={{ minWidth: 168 }}
                valueStyle={{ fontSize: 16, fontWeight: 700 }}
            />
        </CaseCountContainer>
    )
}

export const CaseCountsBar = ({ activeTab }: { activeTab: string }) => {
    const { data: allCounts } = bff.caseCounts.getCounts.useQuery()
    const counts = getCountsForActiveTab({ allCounts, activeTab })
    return (
        <StyledSpace>
            {counts.map((count) => (
                <CaseCount key={count.title} {...count} />
            ))}
        </StyledSpace>
    )
}

function getCountsForActiveTab({
    allCounts,
    activeTab,
}: {
    allCounts?: CaseCountsResponse
    activeTab: string
}) {
    if (!allCounts) {
        return []
    }
    const tabCountsMap: { [key: string]: { title: string; value: number }[] } = {
        new: [
            { title: 'New', value: allCounts.newCasesCount },
            { title: 'Information Received', value: allCounts.informationReceivedCasesCount },
            { title: 'Overdue', value: allCounts.overdueCasesNewAndInformationReceivedCount },
        ],
        inProgress: [{ title: 'In Progress', value: allCounts.inProgressCasesCount }],
        waitingForCustomer: [
            { title: 'Waiting for Customer', value: allCounts.waitingForCustomerCasesCount },
        ],
        offboarding: [
            { title: 'Wallet Blocked', value: allCounts.walletBlockedCasesCount },
            { title: 'Offboarding Initiated', value: allCounts.offboardingInitiatedCasesCount },
        ],
    }

    return tabCountsMap[activeTab] || []
}

const StyledSpace = styled(Space)`
    margin-bottom: 16px;
    width: 100%;
`

const CaseCountContainer = styled.div`
    border: 1px solid #d9d9d9;
    padding: 8px;
    border-radius: 8px;
`
