import { CheckCircleOutlined, CopyOutlined } from '@ant-design/icons'
import { Button, Descriptions, Modal, Table, Typography } from 'antd'
import type { ColumnType } from 'antd/lib/table'
import type { CompanyResponseV2, PersonSearchResponse } from 'bff/moons/generated/styx-v1'
import { formatDateOfBirth, getPersonName } from 'pages/compliance/shared/utils'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

const { Text } = Typography
const { confirm } = Modal

interface Props {
    loading: boolean
    currentPersonId: string
    persons: PersonSearchResponse[]
    copyPerson: (personId: string, companyId: string) => Promise<void>
}

const SearchSamePersonList = ({ loading, currentPersonId, persons, copyPerson }: Props) => {
    const confirmationModal = (person: PersonSearchResponse) => {
        const name = person.name ? getPersonName(person.name) : 'Unknown'
        const companyId = person.company?.id || ''

        confirm({
            centered: true,
            title: `Are you sure? This will copy the person "${name}"`,
            icon: <CheckCircleOutlined />,
            content: <KYCInfo person={person} />,
            onOk: async () => await copyPerson(person.id, companyId),
            width: 1200,
        })
    }

    const columns: ColumnType<PersonSearchResponse>[] = [
        {
            title: 'Person Name',
            dataIndex: 'name',
            render: (name) => getPersonName(name),
        },
        {
            title: 'Date of birth',
            dataIndex: 'dateOfBirth',
            render: (record) => {
                return <Text>{formatDateOfBirth(record)}</Text>
            },
        },
        {
            title: 'Registry ID',
            dataIndex: 'registryId',
            render: (registryId) => registryId,
        },
        {
            title: 'Associated company',
            dataIndex: 'company',
            render: (company?: CompanyResponseV2) =>
                company ? (
                    <Link to={`/compliance/companies/${company.id}`}>{company.legalName}</Link>
                ) : (
                    '-'
                ),
        },
        {
            title: 'Copy this person',
            dataIndex: 'id',
            render: (id, record) => (
                <Button
                    key={id}
                    onClick={() => confirmationModal(record)}
                    disabled={record.id === currentPersonId}
                    icon={<CopyOutlined />}
                    data-testid={'copy-person-button'}
                >
                    Copy person
                </Button>
            ),
        },
    ]

    return (
        <Table
            rowKey="id"
            loading={loading}
            dataSource={persons}
            columns={columns}
            pagination={false}
        />
    )
}

const KYCInfo = ({ person }: { person: PersonSearchResponse }) => {
    return (
        <PersonDetails>
            <Descriptions bordered column={4} size="small" layout="vertical">
                <Descriptions.Item label="Registry ID">
                    {person.registryId || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="National ID no.">
                    {person.nationalId || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Gender">{person.gender || '-'}</Descriptions.Item>
                <Descriptions.Item label="Date of birth" data-testId="dob">
                    {person?.dateOfBirth?.month
                        ? dayjs()
                              .month(person.dateOfBirth.month - 1)
                              .format('MMM')
                        : '-'}
                    {person?.dateOfBirth?.day ? ` ${person.dateOfBirth.day}, ` : ' -, '}
                    {person?.dateOfBirth?.year ? person.dateOfBirth.year : '-'}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions bordered column={7} size="small" layout="vertical">
                <Descriptions.Item label="Street">
                    {person.address?.street || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Number">
                    {person.address?.houseNumber || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Floor">{person.address?.floor || '-'}</Descriptions.Item>
                <Descriptions.Item label="Locality">
                    {person.address?.locality || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Region">
                    {person.address?.region || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Postal code">
                    {person.address?.postalCode || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Country">
                    {person.address?.country || '-'}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions bordered column={1} size="small" layout="vertical">
                <Descriptions.Item label="Documents">
                    {person.documents?.map((doc) => <div key={doc}>{doc}</div>)}
                </Descriptions.Item>
            </Descriptions>
        </PersonDetails>
    )
}

const PersonDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space24};
`

export default SearchSamePersonList
