import React, { FC, useState } from 'react'
import { Button, Form, Input, Modal, Result, Table } from 'antd'
import { useCrrKvRiskScores } from 'services/themis/customer-risk-rating'
import Spinner from 'components/content-spinner'
import type { KVRiskScore, RiskScoreType } from 'types/customer-risk-rating'
import type { ColumnsType } from 'antd/lib/table'

export const CrrKvFactor: FC<
    React.PropsWithChildren<{
        type: RiskScoreType
        versionId?: string
        isActiveVersion: boolean
    }>
> = ({ type, versionId, isActiveVersion }) => {
    const { data, isValidating, error, mutations } = useCrrKvRiskScores(type, versionId)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedItem, setSelectedItem] = useState<KVRiskScore>()
    const [currentPage, setCurrentPage] = useState(1)

    function showEditModal(item: KVRiskScore) {
        setSelectedItem(item)
        setIsModalVisible(true)
    }

    function submitChanges() {
        mutations.updateKvRiskScore(selectedItem as KVRiskScore)
        setIsModalVisible(false)
    }

    if (isValidating) {
        return <Spinner />
    }

    if (error) {
        return <Result title="Error retrieving key values" subTitle={error.message} />
    }

    const columns: ColumnsType<KVRiskScore> = [
        {
            title: 'Name',
            dataIndex: 'key',
            sorter: (a: KVRiskScore, b: KVRiskScore) => a.key.localeCompare(b.key),
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Score',
            dataIndex: 'score',
        },
        {
            title: 'Overriding',
            dataIndex: 'overriding',
            render: (overriding: boolean) => <Input type="checkbox" checked={overriding} />,
        },
        {
            title: 'Action',
            render: (item: KVRiskScore) => (
                <Button type="link" onClick={() => showEditModal(item)} disabled={isActiveVersion}>
                    Edit
                </Button>
            ),
        },
    ]

    return (
        <>
            <Table
                size="small"
                dataSource={data}
                columns={columns}
                sortDirections={['ascend', 'ascend', 'ascend']}
                rowKey="id"
                pagination={{
                    current: currentPage,
                    onChange: (pageNumber) => setCurrentPage(pageNumber),
                }}
            />
            <Modal
                open={isModalVisible}
                title="Edit factor score"
                onCancel={() => setIsModalVisible(false)}
                onOk={submitChanges}
            >
                <Form layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                    <Form.Item label="Name">
                        <Input
                            type="text"
                            value={selectedItem?.key}
                            onChange={(e) =>
                                setSelectedItem({
                                    ...selectedItem,
                                    key: e.target.value,
                                } as KVRiskScore)
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Score">
                        <Input
                            type="number"
                            value={selectedItem?.score}
                            onChange={(e) =>
                                setSelectedItem({
                                    ...selectedItem,
                                    score: Number(e.target.value),
                                } as KVRiskScore)
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Overriding">
                        <Input
                            type="checkbox"
                            checked={selectedItem?.overriding}
                            onChange={(e) =>
                                setSelectedItem({
                                    ...selectedItem,
                                    overriding: e.target.checked,
                                } as KVRiskScore)
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CrrKvFactor
