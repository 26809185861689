import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React, { ReactNode } from 'react'
import { Result } from 'antd'

import config from 'config'
import { PlaceholderPage } from 'components/placeholder-page'
import { Team } from 'utils/teams'

// Initializes Bugsnag's static interface. The default behaviour is to have a single
// client accessible under the default export from @bugsnag/js.

Bugsnag.start({
    apiKey: config.bugsnag.apiKey,
    releaseStage: config.bugsnag.releaseStage,
    enabledReleaseStages: ['production'],
    plugins: [new BugsnagPluginReact(React)],
    // prevent leaking of access token from the oauth callback and accidental leaking of passwords
    redactedKeys: ['access_token', 'accessToken', /^password$/i],
})

// It's ok to use the non-null assertion here since we are sure the React plugin will be defined
// as we initialize it just above
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

export const ErrorHandlerProvider: React.FC<
    React.PropsWithChildren<{
        children: React.ReactChild
        team?: Team
        requiresPlaceholderPage?: Boolean
    }>
> = ({ children, team, requiresPlaceholderPage = false }) => {
    const url = window.location.href
    const isCustomerSuccess = url.includes('customer-success')
    return (
        <ErrorBoundary
            FallbackComponent={withTeamErrorPage(
                isCustomerSuccess ? Team.BOE : team,
                requiresPlaceholderPage
            )}
        >
            {children}
        </ErrorBoundary>
    )
}

// if `ErrorHandlerProvider` is used from root there won't be a placeholder therefore need to render one hence
// `requiresPlaceholderPage`.
export const withTeamErrorPage = (
    team: Team = Team.BOE,
    requiresPlaceholderPage: Boolean = false
) => {
    // eslint-disable-next-line react/display-name
    return () => {
        const error = (
            <Result
                status="error"
                title="Something went wrong"
                subTitle={`Please contact Team ${team}`}
            />
        )

        return requiresPlaceholderPage ? <PlaceholderPage>{error}</PlaceholderPage> : error
    }
}

// A high order component so its really easy for teams appropriately select the team and wrap there components
// with the team error handler.
export function withTeamErrorHandler<PropsType extends { children?: ReactNode }>(
    team: Team,
    WrappedComponent: React.FC<React.PropsWithChildren<PropsType>>
) {
    // eslint-disable-next-line react/display-name
    return (props: PropsType) => {
        return (
            <ErrorHandlerProvider team={team}>
                <WrappedComponent {...props} />
            </ErrorHandlerProvider>
        )
    }
}

export const logError = (error: any) => Bugsnag.notify(error)
