export interface RefreshCompanyStructureError {
    status: number
    type: RefreshCompanyStructureErrorType
    message: string
    nextSteps: string[]
}

export enum RefreshCompanyStructureErrorType {
    REGISTRY_PROVIDER_NOT_AVAILABLE = 'REGISTRY_PROVIDER_NOT_AVAILABLE',
    COMPANY_NOT_IN_REGISTRY = 'COMPANY_NOT_IN_REGISTRY',
    PARTIAL_STRUCTURE_IN_REGISTRY = 'PARTIAL_STRUCTURE_IN_REGISTRY',
}
