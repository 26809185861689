import { Divider, Space } from 'antd'
import type { FC } from 'react'
import { MaxFileSize } from 'types/styx'
import { ComplianceDocuments } from './compliance-notes-document/compliance-document'
import ComplianceNotes from './complianceNotes'

interface ComplianceNoteProps {
    companyId: string
}

const ComplianceNote: FC<React.PropsWithChildren<ComplianceNoteProps>> = ({ companyId }) => {
    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <ComplianceNotes companyId={companyId}></ComplianceNotes>
            <Divider></Divider>
            <ComplianceDocuments
                companyId={companyId}
                maxFileSize={MaxFileSize.NoteFile}
            ></ComplianceDocuments>
        </Space>
    )
}

export default ComplianceNote
