import { useEffect, useRef } from 'react'

// Store a reference to the previous values for deep equality, value can be any type
export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}
