import { Row, Col } from 'antd'
import { useParams } from 'react-router-dom'
import {
    createURLForDeimosStorage,
    createURLForPhobosQuery,
    createURLForDeimosExpense,
    createURLForVirtualAccountNumber,
    createURLForAllEmployees,
    createURLForEmployeeQuery,
    createURLForCupidWalletLoad,
    createURLForCupidInvoice,
    createURLForExportHistory,
    createURLForDeimosCardOrder,
    createURLForCompanyReviewStructure,
    createURLForTrustedBeneficiaries,
    createURLForBalanceMismatch,
    createURLForWalletWithdrawal,
    createURLForOffboarding,
    createURLForCupidPayer,
    createURLForDeimosTrasnactions,
} from '../utils'

interface MetabseLinkProps {
    companyName: string
}

export const MetabaseLinks = ({ companyName }: MetabseLinkProps) => {
    const { id } = useParams()

    return (
        <>
            <Row gutter={[8, 16]}>
                <Col span={6}>
                    <a href={createURLForDeimosStorage(id)} target="_blank" rel="noreferrer">
                        Deimos Storage
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForPhobosQuery(id)} target="_blank" rel="noreferrer">
                        Phobos Cards
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForDeimosExpense(id)} target="_blank" rel="noreferrer">
                        Deimos Expense
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForVirtualAccountNumber(id)} target="_blank" rel="noreferrer">
                        Virtual Bank Details
                    </a>
                </Col>
            </Row>
            <Row gutter={[8, 16]}>
                <Col span={6}>
                    <a href={createURLForAllEmployees(id)} target="_blank" rel="noreferrer">
                        All Employees
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForEmployeeQuery(id)} target="_blank" rel="noreferrer">
                        Employee Query
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForCupidWalletLoad(id)} target="_blank" rel="noreferrer">
                        Cupid Wallet Load
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForCupidInvoice(id)} target="_blank" rel="noreferrer">
                        Cupid Invoice
                    </a>
                </Col>
            </Row>
            <Row gutter={[8, 16]}>
                <Col span={6}>
                    <a href={createURLForExportHistory(id)} target="_blank" rel="noreferrer">
                        Export History
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForDeimosCardOrder(id)} target="_blank" rel="noreferrer">
                        Deimos Card Order
                    </a>
                </Col>
                <Col span={6}>
                    <a
                        href={createURLForCompanyReviewStructure(id)}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Company Review Structure
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForTrustedBeneficiaries(id)} target="_blank" rel="noreferrer">
                        Trusted Beneficiaries
                    </a>
                </Col>
            </Row>
            <Row gutter={[8, 16]}>
                <Col span={6}>
                    <a href={createURLForBalanceMismatch(id)} target="_blank" rel="noreferrer">
                        Balance Mismatch
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForWalletWithdrawal(id)} target="_blank" rel="noreferrer">
                        Wallet Withdrawal
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForOffboarding(id)} target="_blank" rel="noreferrer">
                        Offboarding
                    </a>
                </Col>
                <Col span={6}>
                    <a href={createURLForCupidPayer(companyName)} target="_blank" rel="noreferrer">
                        Cupid Payer
                    </a>
                </Col>
            </Row>
            <Row gutter={[8, 16]}>
                <Col span={6}>
                    <a href={createURLForDeimosTrasnactions(id)} target="_blank" rel="noreferrer">
                        Deimos Transactions
                    </a>
                </Col>
            </Row>
        </>
    )
}
