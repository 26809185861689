import type { FC } from 'react'
import { Formik } from 'formik'
import { InputNumber, Select, Form } from 'formik-antd'
import { Button } from 'antd'
import { InferType, mixed } from 'yup'
import { Currency } from 'types/currency'
import { ProviderName } from 'store/modules/support/card-orders/types'
import styled from 'styled-components'
const { Option, OptGroup } = Select

interface GhostWalletOption {
    label: string
    value: GhostWalletValue
    currency: Currency
    country?: string
    providerName: ProviderName
}

type GhostWalletValue =
    | 'DKK'
    | 'GBP'
    | 'SEK'
    | 'NOK'
    | 'EUR'
    | 'DKKDK'
    | 'GBPGB'
    | 'SEKSE'
    | 'NOKNO'
    | 'EURDE'
    | 'EURES'
    | 'EURIE'

export const GHOST_WALLET_OPTIONS: GhostWalletOption[] = [
    {
        label: 'DKK - Denmark - GPS',
        value: 'DKK',
        currency: Currency.DKK,
        providerName: ProviderName.GPS,
    },
    {
        label: 'GBP - United Kingdom - GPS',
        value: 'GBP',
        currency: Currency.GBP,
        providerName: ProviderName.GPS,
    },
    {
        label: 'SEK - Sweden - GPS',
        value: 'SEK',
        currency: Currency.SEK,
        providerName: ProviderName.GPS,
    },
    {
        label: 'EUR - Germany, Spain, Ireland - GPS',
        value: 'EUR',
        currency: Currency.EUR,
        providerName: ProviderName.GPS,
    },
    {
        label: 'DKK - Denmark - Enfuce',
        value: 'DKKDK',
        currency: Currency.DKK,
        country: 'DK',
        providerName: ProviderName.ENFUCE,
    },
    {
        label: 'GBP - United Kingdom - Enfuce',
        value: 'GBPGB',
        currency: Currency.GBP,
        country: 'GB',
        providerName: ProviderName.ENFUCE,
    },
    {
        label: 'SEK - Sweden - Enfuce',
        value: 'SEKSE',
        currency: Currency.SEK,
        country: 'SE',
        providerName: ProviderName.ENFUCE,
    },
    {
        label: 'EUR - Germany - Enfuce',
        value: 'EURDE',
        currency: Currency.EUR,
        country: 'DE',
        providerName: ProviderName.ENFUCE,
    },
    {
        label: 'EUR - Spain - Enfuce',
        value: 'EURES',
        currency: Currency.EUR,
        country: 'ES',
        providerName: ProviderName.ENFUCE,
    },
    {
        label: 'EUR - Ireland - Enfuce',
        value: 'EURIE',
        currency: Currency.EUR,
        country: 'IE',
        providerName: ProviderName.ENFUCE,
    },
    {
        label: 'NOK - Norway - Enfuce',
        value: 'NOKNO',
        currency: Currency.NOK,
        country: 'NO',
        providerName: ProviderName.ENFUCE,
    },
]

export interface FormValues {
    amount: number
    ghostWalletValue: GhostWalletValue
}

const mixedSchema = mixed()

interface Props {
    validationSchema: InferType<typeof mixedSchema>
    onSubmit: (values: FormValues) => void
    initialValues: FormValues
}

export const ExecuteGhostOrderForm: FC<React.PropsWithChildren<Props>> = ({
    validationSchema,
    onSubmit,
    initialValues,
}) => {
    return (
        <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            {({ isSubmitting, isValid }) => {
                return (
                    <Form>
                        <LayoutColumn>
                            <FormItem label="Select wallet" name="ghostWalletValue">
                                <Select name="ghostWalletValue" disabled={isSubmitting}>
                                    <OptGroup label={ProviderName.GPS}>
                                        {GHOST_WALLET_OPTIONS.filter(
                                            ({ providerName }) => providerName === ProviderName.GPS
                                        ).map(({ label, value }: GhostWalletOption) => (
                                            <Option key={value} value={value} title={label}>
                                                {label}
                                            </Option>
                                        ))}
                                    </OptGroup>
                                    <OptGroup label={ProviderName.ENFUCE}>
                                        {GHOST_WALLET_OPTIONS.filter(
                                            ({ providerName }) =>
                                                providerName === ProviderName.ENFUCE
                                        ).map(({ label, value }: GhostWalletOption) => (
                                            <Option key={value} value={value} title={label}>
                                                {label}
                                            </Option>
                                        ))}
                                    </OptGroup>
                                </Select>
                            </FormItem>
                            <FormItem name="amount" label="Cards to Order" hasFeedback>
                                <InputNumber
                                    data-testid="number-of-cards-input"
                                    name="amount"
                                    disabled={isSubmitting}
                                />
                            </FormItem>
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={!isValid}
                                loading={isSubmitting}
                                data-testid="submit-button"
                            >
                                Submit{'   '}
                                <span role="img" aria-label="ghost">
                                    👻
                                </span>
                            </Button>
                        </LayoutColumn>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default ExecuteGhostOrderForm

const FormItem = styled(Form.Item)`
    margin: 0;
`
const LayoutColumn = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
`
