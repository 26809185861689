import ky from 'ky/umd'
import { checkSession } from 'services/auth/auth'
import type { AfterResponseHook } from 'ky'

// Store promise for new access token request
let resolvePromise: (value: string | PromiseLike<string>) => void
let rejectPromise: (reason?: any) => void
const initPromise = () =>
    new Promise<string>((resolve, reject) => {
        resolvePromise = resolve
        rejectPromise = reject
    })
let waitingRequstsCount = 0
let promise: Promise<string> | undefined

/**
 * Deduplicates access token requests when multiple
 * requests fail at the same time due to expired token
 * @returns Fresh access token
 */
const getAccessToken = async (): Promise<string> => {
    // if another request for access token is already in progress, wait for it to resolve
    if (promise) {
        waitingRequstsCount += 1
        const result = await promise
        waitingRequstsCount -= 1
        return result
    }
    promise = initPromise()
    try {
        // fetch new access token
        const { accessToken } = await checkSession().json()
        // resolve deferred promise for "waiting" failed requests
        resolvePromise(accessToken)
        return accessToken
    } catch (error) {
        // reject deferred promise for "waiting" failed requests
        if (waitingRequstsCount > 0) {
            rejectPromise(error)
        }
        throw error
    } finally {
        // clear deferred promise for future token refresh
        promise = undefined
        waitingRequstsCount = 0
    }
}

export const refreshAccessToken: AfterResponseHook = async (request, options, response) => {
    if (response.status === 401) {
        try {
            const accessToken = await getAccessToken()
            request.headers.set('Authorization', `Bearer ${accessToken}`)
        } catch (error) {
            return
        }
        return ky(request)
    }
}
