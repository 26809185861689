import { FC, useState } from 'react'
import { Badge, Button, Card, Col, Modal, Popover, Row, Skeleton, Space, Typography } from 'antd'

import dayjs from 'packages/dayjs'
import UAParser from 'ua-parser-js'
import { useLoginEvents } from '../api/login-events'
import { LoginEventsTable, UserAgentSummary } from './login-events-table'

import styled from 'styled-components'
import type { LoginEvent } from 'bff/moons/generated/pandora-v1'
import { EVENT_NAME, type LoginEventDetailsResponse, type UserAgentDetails } from '../types'
import { AppStoreLink } from './app-store-link'
import { Params } from '../enums'

const { Text } = Typography

const LoginEventContent: FC<LoginEventDetailsResponse> = ({ eventData }) => {
    const { data } = eventData
    const lastEvent = data && data.length > 0 ? data[0] : undefined
    return lastEvent ? <LatestEventLogin lastEvent={lastEvent} /> : null
}

interface UADetailLineProps {
    label: string
    value?: string
}

const UADetailLine: FC<UADetailLineProps> = ({ label, value }) => (
    <Col span={24}>
        <span>
            {label}:{' '}
            {value ? <Text type="success">{value}</Text> : <Text type="secondary">Unknown</Text>}
        </span>
    </Col>
)
interface UserAgentDetailsProps {
    UADetails: UserAgentDetails
}

const UserAgentComponent: FC<UserAgentDetailsProps> = ({ UADetails }) => {
    const hasAppVersion = UADetails.ua.includes('Pleo/')
    const appVersion = hasAppVersion ? UADetails.ua.split('Pleo/')[1].split(' ')[0] : undefined
    return (
        <Row gutter={[0, 0]} style={{ width: '250px' }}>
            <UADetailLine label="OS name" value={UADetails.os.name} />
            <UADetailLine label="Device model" value={UADetails.device.model} />
            <UADetailLine label="Device vendor" value={UADetails.device.vendor} />
            <UADetailLine label="Type" value={UADetails.device.type} />
            <UADetailLine label="Browser" value={UADetails.browser.name} />
            <UADetailLine label="Browser version" value={UADetails.browser.version} />
            <UADetailLine label="Engine name" value={UADetails.engine.name} />
            <UADetailLine label="Engine version" value={UADetails.engine.version} />
            <UADetailLine label="App version" value={appVersion} />
        </Row>
    )
}

interface LatestEventLoginProps {
    lastEvent: LoginEvent
}

const LatestEventLogin: FC<LatestEventLoginProps> = ({ lastEvent }) => {
    const { name, payload, timestamp } = lastEvent
    const parsedPayload = JSON.parse(payload)
    const parser = new UAParser(parsedPayload.userAgent)
    const UADetails: UserAgentDetails = parser.getResult()

    const errorContent = (
        <>
            {dayjs(timestamp).format('lll')}
            {parsedPayload?.error}
        </>
    )

    const statusLine =
        name === EVENT_NAME.LOGIN_SUCCESS ? (
            <Popover content={dayjs(timestamp).format('lll')}>
                <Badge status="success" text="Last login successful" />
            </Popover>
        ) : name === EVENT_NAME.SMS_SENT ||
          name === EVENT_NAME.PASSCODE_RESET_EMAIL_SENT ||
          name === EVENT_NAME.TFA_RESET_EMAIL_SENT ||
          name === EVENT_NAME.PASSCODE_RESET ||
          name === EVENT_NAME.TFA_RESET ? (
            <Popover content={dayjs(timestamp).format('lll')}>
                <Badge status="default" text={name} />
            </Popover>
        ) : (
            <Popover content={errorContent}>
                <Badge status="error" text={name} />
            </Popover>
        )

    const content = <UserAgentComponent UADetails={UADetails} />

    return (
        <>
            <span style={{ paddingBottom: 4 }}>{statusLine}</span>
            <Popover content={content} title="Full user-agent" trigger="click">
                <Button style={{ whiteSpace: 'normal', height: 'auto' }}>
                    <UserAgentSummary UADetails={UADetails} />
                </Button>
            </Popover>
        </>
    )
}

interface Props {
    email?: string
}

export const LoginEvents: FC<Props> = ({ email }) => {
    const [isShowingEventTableModal, setIsShowingEventTableModal] = useState(false)
    const [event, setEvent] = useState<string | undefined>()
    const [fromDate, setFromDate] = useState<string | undefined>()
    const [toDate, setToDate] = useState<string | undefined>()
    const [currentPage, setCurrentPage] = useState('1')
    const [pageSize, setPageSize] = useState('10')

    const { data: mostRecentData, isValidating: isValidatingMostRecent } = useLoginEvents({
        email,
        limit: 1,
        offset: 0,
    })

    const handleFilterChange = (filter: Params, value: string | undefined) => {
        if (filter !== Params.CURRENT_PAGE) setCurrentPage('1')
        switch (filter) {
            case Params.EVENT:
                setEvent(value)
                break
            case Params.FROM_DATE:
                setFromDate(value)
                break
            case Params.TO_DATE:
                setToDate(value)
                break
            case Params.PAGE_SIZE:
                setPageSize(value || '10')
                break
            case Params.CURRENT_PAGE:
                setCurrentPage(value || '1')
                break
        }
    }

    const handleCloseModal = () => {
        setEvent('')
        setFromDate('')
        setToDate('')
        setCurrentPage('1')
        setPageSize('10')
        setIsShowingEventTableModal(false)
    }

    return (
        <Card
            title="Most recent login"
            style={{ margin: '0 0 24px 0' }}
            bodyStyle={{ padding: '16px 24px' }}
            type="inner"
        >
            {isValidatingMostRecent || !email ? (
                <Skeleton active />
            ) : mostRecentData && mostRecentData?.data ? (
                <StyledDiv>
                    <LoginEventContent eventData={mostRecentData} />
                    <Button
                        onClick={() => setIsShowingEventTableModal(true)}
                        style={{ whiteSpace: 'normal', height: 'auto', marginTop: 10 }}
                    >
                        View full login event table
                    </Button>
                </StyledDiv>
            ) : (
                <>No event login info found</>
            )}

            <Modal
                open={isShowingEventTableModal}
                onCancel={handleCloseModal}
                footer={null}
                width="1100px"
                centered
                title={
                    <Space
                        style={{
                            justifyContent: 'space-between',
                            width: '100%',
                            paddingRight: '2rem',
                        }}
                    >
                        Login events log
                        <AppStoreLink />
                    </Space>
                }
            >
                <LoginEventsTable
                    showDomainFilter={false}
                    showEmailFilter={false}
                    emailParam={email}
                    eventParam={event}
                    fromDateParam={fromDate}
                    toDateParam={toDate}
                    currentPageParam={currentPage}
                    pageSizeParam={pageSize}
                    onFilterChange={handleFilterChange}
                />
            </Modal>
        </Card>
    )
}

export const StyledDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
