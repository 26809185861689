import { useState } from 'react'
import type { ColumnsType } from 'antd/lib/table'
import { Table, Button, message } from 'antd'

import type { User } from 'types/user'
import type { UsersMutations } from 'services/auth/users'
import { ActionsContainer, ContentContainer } from 'components/layout-containers'
import { getUserName } from 'utils/user'

interface Props {
    users?: User[]
    setUsersQuery: (query: string) => void
    grantAdminAccess: UsersMutations['grantAdminAccess']
    revokeAdminAccess: UsersMutations['revokeAdminAccess']
}

const Users = ({ users, grantAdminAccess, revokeAdminAccess }: Props) => {
    const [activeUserId, setActiveUserId] = useState<string | null>(null)

    const onGrantBackofficeAccess = async (userId: string) => {
        try {
            setActiveUserId(userId)
            await grantAdminAccess(userId)
        } catch (e) {
            message.error(`Failed to grant access: ${(e as Error).message}`)
        } finally {
            setActiveUserId(null)
        }
    }

    const onRevokeBackofficeAccess = async (userId: string) => {
        try {
            setActiveUserId(userId)
            await revokeAdminAccess(userId)
        } catch (e) {
            message.error(`Failed to revoke access: ${(e as Error).message}`)
        } finally {
            setActiveUserId(null)
        }
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Name',
            render: (_: string, user: User) => getUserName(user),
            sorter: (a: User, b: User) => getUserName(a).localeCompare(getUserName(b)),
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'User ID',
            dataIndex: 'id',
        },
        {
            title: 'Actions',
            dataIndex: 'admin',
            render: (admin: boolean, { id }) => {
                const loading = activeUserId === id
                return (
                    <ActionsContainer>
                        {admin ? (
                            <Button
                                disabled={loading}
                                type="link"
                                danger
                                onClick={() => onRevokeBackofficeAccess(id)}
                            >
                                Remove backoffice access
                            </Button>
                        ) : (
                            <Button
                                disabled={loading}
                                type="link"
                                onClick={() => onGrantBackofficeAccess(id)}
                            >
                                Grant backoffice access
                            </Button>
                        )}
                    </ActionsContainer>
                )
            },
        },
    ]

    return (
        <ContentContainer>
            <Table dataSource={users} columns={columns} rowKey="id" />
        </ContentContainer>
    )
}

export default Users
