import { Select, type SelectProps } from 'formik-antd'
import { CompanySizeEnum } from 'types/company-size'
import type { FC } from 'react'
import { soleTraderSupportedCountries } from 'types/countries'
import { getCompanySizeLabel } from 'utils/company'

const { Option } = Select

interface Props extends SelectProps {
    countryCode?: string
}

const CompanySizeSelect: FC<React.PropsWithChildren<Props>> = ({ countryCode, ...props }) => {
    const companySize = Object.values(CompanySizeEnum)

    const isSoleTradersSupported = countryCode && soleTraderSupportedCountries.includes(countryCode)

    return (
        <Select {...props} optionFilterProp="label" data-testid={`companySize-select`}>
            {companySize.map((size, index) => (
                <Option
                    disabled={!isSoleTradersSupported && size === CompanySizeEnum.SoleTrader}
                    key={index}
                    value={size}
                    label={getCompanySizeLabel(size)}
                    data-testid={`companySize-option-${index}`}
                >
                    {getCompanySizeLabel(size)}
                </Option>
            ))}
        </Select>
    )
}

export default CompanySizeSelect
