import { Route, Routes } from 'react-router-dom'
import CompanyLookup from './company-lookup'
import CreditDetails from './details'

export default function CreditPage() {
    return (
        <Routes>
            <Route path="/" element={<CompanyLookup />} />
            <Route path="/:id/*" element={<CreditDetails />} />
        </Routes>
    )
}
