// Default argument for switch (status) so typescript notifies if we fail to cover a case
export function exhaustiveCheck(_param: never) {
    return null
}

// Alternate implementation for when an error is preferred over null
const exhaustiveCheckWithError = (param: never): never => {
    throw new TypeError(`Missing case for ${param}`)
}
export default exhaustiveCheckWithError
