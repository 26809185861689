import { Button, Descriptions } from 'antd'
import { Formik } from 'formik'
import { Input, Form, FormItem } from 'formik-antd'
import type { FC } from 'react'
import styled from 'styled-components'
import { validationSchema } from './validation-schema'

interface Props {
    onSubmit: (amount: number, note: string) => void
}

const labelCol = {
    sm: { span: 6 },
}

export const RequestBalanceAdjustmenttForm: FC<React.PropsWithChildren<Props>> = ({ onSubmit }) => {
    return (
        <Formik
            validationSchema={validationSchema}
            validateOnMount
            initialValues={{
                amount: 0,
                currency: '',
                note: '',
            }}
            onSubmit={({ amount, note }) => onSubmit(amount, note)}
        >
            {({ isValid, isSubmitting }) => {
                return (
                    <Form labelCol={labelCol}>
                        <Descriptions size="small">
                            <Descriptions.Item>
                                Positive value = credit. Negative (-) value = debit. <br></br>
                                Will add or subtract amount from company balance.
                            </Descriptions.Item>
                        </Descriptions>
                        <FormItem name="amount" label="Amount" hasFeedback>
                            <Input
                                data-testid="adjustment-amount-id"
                                name="amount"
                                placeholder="Adjustment Amount"
                            />
                        </FormItem>
                        <FormItem name="note" label="Note" hasFeedback>
                            <Input.TextArea
                                data-testid="adjustment-note-id"
                                name="note"
                                placeholder="Why is this adjustment being made?"
                            />
                        </FormItem>
                        <ButtonContainer>
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={!isValid}
                                data-testid="submit"
                                loading={isSubmitting}
                            >
                                Balance adjustment (internal and external)
                            </Button>
                        </ButtonContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default RequestBalanceAdjustmenttForm
