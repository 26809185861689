import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { ExportHistory as ExportHistoryResponse, JobStatus } from '@pleo-io/deimos'
import { Card, Divider, Space, Tag, Typography } from 'antd'
import dayjs from 'packages/dayjs'
import { capitalize, replace } from 'lodash'
import React, { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { fetcher } from 'services/deimos/request'
import useSWR from 'swr'
import { bff } from '../bff'

const { Text } = Typography

const pendingStatus = [
    JobStatus.CREATED,
    JobStatus.EXPORTING_EXPENSES,
    JobStatus.EXPORTING_INVOICES,
    JobStatus.EXPORTING_RECEIPTS,
]

const successStatus = [JobStatus.COMPLETED]

const failedStatus = [JobStatus.FAILED]

const StatusTag: React.FC<{ status: ExportHistoryResponse['status'] }> = ({ status }) => {
    const tagStatus = useMemo(() => {
        if (pendingStatus.includes(status)) {
            return {
                color: 'processing',
                icon: <SyncOutlined spin />,
            }
        }
        if (successStatus.includes(status)) {
            return {
                color: 'success',
                icon: <CheckCircleOutlined />,
            }
        }
        if (failedStatus.includes(status)) {
            return {
                color: 'error',
                icon: <CloseCircleOutlined />,
            }
        }
    }, [status])

    return (
        <Tag icon={tagStatus?.icon} color={tagStatus?.color}>
            {capitalize(replace(status, '_', ' '))}
        </Tag>
    )
}

export const ExportHistory = () => {
    const { id } = useParams()
    const { exportHistory } = useExportHistory(id)
    const mostRecentExport = exportHistory && exportHistory[0]
    const { data: exportHistoryBff } = bff.exportHistory.getEmployee.useQuery({
        employeeId: mostRecentExport?.employeeId,
    })

    return (
        <Card type="inner" title="Export history" style={{ marginBottom: '24px' }}>
            {mostRecentExport ? (
                <Space direction="vertical">
                    <Text type="secondary">Last export</Text>
                    <Space direction="vertical">
                        <StatusTag status={mostRecentExport.status} />
                        <Space>
                            <Text>
                                <Text type="secondary">Exported on </Text>
                                {dayjs(mostRecentExport.createdAt).format('lll')}
                                <Text type="secondary"> by </Text>
                                <Text>{exportHistoryBff?.employeeName}</Text>
                            </Text>
                        </Space>
                    </Space>
                    <Divider style={{ margin: 0 }} />
                    <Link to={`/customer-success/exports/${mostRecentExport.companyId}`}>
                        View all exports ({exportHistory.length})
                    </Link>
                </Space>
            ) : (
                <Text type="secondary">No exports</Text>
            )}
        </Card>
    )
}

const useExportHistory = (companyId?: string) => {
    const response = useSWR(companyId ? `rest/v1/exports/history/${companyId}` : null, fetcher, {
        revalidateOnFocus: false,
    })
    return { exportHistory: response.data, ...response }
}
