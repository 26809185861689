import request from './request'

export const deleteBankfeedError = async (companyId: string, expenseId?: string) => {
    if (!expenseId) return
    return request().delete(`rest/v1/companies/admin/${companyId}/bankfeed/errors/${expenseId}`)
}

export const replayBankfeed = (companyId: string, expenseId: string) => {
    return request().post(`rest/v1/companies/admin/${companyId}/bankfeed/${expenseId}/replay`)
}
