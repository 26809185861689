import type { ApplicationState } from 'bff/moons/generated/kale'

export const getMaxLimitByCurrency = (currency: string) => {
    switch (currency) {
        case 'EUR':
        case 'GBP':
            return 250_000
        case 'SEK':
            return 2_500_000
        case 'DKK':
            return 1_900_000
        default:
            return 0
    }
}

export const getMinLimitByCurrency = (currency: string) => {
    switch (currency) {
        case 'EUR':
        case 'GBP':
            return 2000
        case 'SEK':
            return 20000
        case 'DKK':
            return 15000
        default:
            return 0
    }
}

export const getOverdraftApplicationStatusColor = (status: ApplicationState | undefined) => {
    switch (status) {
        case 'APPROVED':
            return 'green'
        case 'PENDING':
        case 'PROCESSING':
            return 'yellow'
        case 'REJECTED':
        case 'ERROR':
            return 'red'
        case 'INFO_REQUIRED':
            return 'blue'
        default:
            return 'default'
    }
}
