import type { FC } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Typography } from 'antd'

import { selectCompanyCountry, selectCompanyId } from 'store/modules/support/company/selectors'
import { spacing } from 'theme/tokens'

import CompanyBalance from 'components/company-balance/company-balance'
import FeatureFlags from './feature-flags/feature-flags'
import { Settings } from './settings/settings'
import { useCompanyOffboardingStatus } from 'services/deimos/companies'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { ContentContainer } from '../../../../components/layout-containers'

import styled from 'styled-components'

const { Link } = Typography

const Overview: FC<React.PropsWithChildren<unknown>> = () => {
    const companyId = useSelector(selectCompanyId)
    const country = useSelector(selectCompanyCountry)
    const { data: companyOffboardingStatus } = useCompanyOffboardingStatus(companyId)
    const hasOffboardingTools = useHasPermissions(['offboarding-tools'])

    return (
        <>
            <ContentContainer>
                {hasOffboardingTools && !!companyOffboardingStatus ? (
                    <Alert
                        message={
                            <Link href={`/customer-success/offboarding/${companyId}`}>
                                To complete the offboarding of the company click here to be
                                redirected to the offboarding tool
                            </Link>
                        }
                        type="info"
                        closable
                        showIcon
                    />
                ) : null}
                <CompanyBalance companyId={companyId} />
                <WidgetSection>
                    <FeatureFlags companyId={companyId} />
                    <Settings companyId={companyId} countryCode={country} />
                </WidgetSection>
            </ContentContainer>
        </>
    )
}

export default Overview

const WidgetSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 349px;
    grid-auto-rows: auto;
    grid-gap: ${spacing.space24};
`
