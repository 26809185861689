import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    PlayCircleOutlined,
} from '@ant-design/icons'
import { List, Space, Typography } from 'antd'
import type { FC } from 'react'

const { Text } = Typography
const defaultHeight = 600

interface ProcessListProps {
    allItems: string[]
    currentItem: string
    failedItems: string[]
    successfulItems: string[]
    listHeight?: number
}

const ProcessList: FC<ProcessListProps> = ({
    allItems,
    currentItem,
    failedItems,
    successfulItems,
    listHeight,
}) => {
    function displayStatusListItem(
        item: string,
        current: string,
        failed: string[],
        successful: string[]
    ) {
        if (successful.includes(item)) {
            return (
                <Text type="success">
                    <Space>
                        <CheckCircleOutlined color="success" />
                        {item}
                    </Space>
                </Text>
            )
        }

        if (failed.includes(item)) {
            return (
                <Text type="danger">
                    <Space>
                        <CloseCircleOutlined color="danger" />
                        {item}
                    </Space>
                </Text>
            )
        }

        if (item === current) {
            return (
                <Text type="warning">
                    <Space>
                        <PlayCircleOutlined color="warning" />
                        {item}
                    </Space>
                </Text>
            )
        }

        return (
            <Text>
                <Space>
                    <ClockCircleOutlined />
                    {item}
                </Space>
            </Text>
        )
    }

    return (
        <List
            size="small"
            bordered
            dataSource={allItems}
            style={{ height: listHeight ?? defaultHeight, overflow: 'auto' }}
            renderItem={(item) => (
                <List.Item key={item}>
                    {displayStatusListItem(item, currentItem, failedItems, successfulItems)}
                </List.Item>
            )}
        />
    )
}

export default ProcessList
