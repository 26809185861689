import { Button } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem, Input, InputNumber } from 'formik-antd'
import type { FC } from 'react'
import styled from 'styled-components'
import { FormValues, validationSchema } from './validation-schema'

interface Props {
    onSubmit: (values: FormValues) => void
    buttonLabel: string
}

const labelCol = {
    sm: { span: 9 },
}

export const ConfirmAmountForm: FC<React.PropsWithChildren<Props>> = ({
    onSubmit,
    buttonLabel,
}) => {
    return (
        <Formik
            validationSchema={validationSchema}
            validateOnMount
            initialValues={{
                amount: 0,
                confirmedAmount: 0,
                note: '',
            }}
            onSubmit={onSubmit}
        >
            {({ isValid }) => {
                return (
                    <Form labelCol={labelCol}>
                        <FormItem name="amount" colon={true} label="Amount" hasFeedback>
                            <InputNumber name="amount" data-testid="amount" />
                        </FormItem>
                        <FormItem
                            name="confirmedAmount"
                            colon={true}
                            label="Confirm amount"
                            hasFeedback
                        >
                            <InputNumber name="confirmedAmount" data-testid="confirmAmount" />
                        </FormItem>
                        <FormItem name="note" colon={true} label="Reason" hasFeedback>
                            <Input.TextArea
                                data-testid="note"
                                name="note"
                                placeholder="Add company name and a reason for the amendment"
                            />
                        </FormItem>
                        <ButtonContainer>
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={!isValid}
                                data-testid="submit"
                            >
                                {buttonLabel}
                            </Button>
                        </ButtonContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default ConfirmAmountForm
