import type { FC } from 'react'
import {
    Input as AntInput,
    InputNumber as AntInputNumber,
    FormItem,
    FormItemProps,
    TextAreaProps,
} from 'formik-antd'

interface Props extends Omit<FormItemProps, 'children'> {
    name: string
    label?: string
    placeholder?: string
    size?: 'small' | 'middle' | 'large'
    testId?: string
    disabled?: boolean
}

export const Input: FC<React.PropsWithChildren<Props>> = ({
    testId,
    disabled = false,
    ...props
}) => {
    const inputProps = { name: props.name, placeholder: props.placeholder || props.label }
    return (
        <FormItem {...props} colon={false}>
            <AntInput
                data-testid={testId}
                {...inputProps}
                size={props.size || 'middle'}
                disabled={disabled}
            />
        </FormItem>
    )
}

export const InputNumber: FC<React.PropsWithChildren<Props>> = ({ testId, ...props }) => {
    const inputProps = { name: props.name, placeholder: props.placeholder || props.label }
    return (
        <FormItem {...props} colon={false}>
            <AntInputNumber data-testid={testId} {...inputProps} size={props.size || 'middle'} />
        </FormItem>
    )
}

export const TextArea: FC<React.PropsWithChildren<Props & TextAreaProps>> = ({
    testId,
    ...props
}) => {
    const inputProps = { ...props, name: props.name, placeholder: props.placeholder || props.label }
    return (
        <FormItem {...props} colon={false}>
            <AntInput.TextArea data-testid={testId} {...inputProps} />
        </FormItem>
    )
}

export default Input
