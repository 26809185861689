import { List, Typography } from 'antd'

import { telestoRequest, buildFetcher } from 'services/request'
import styled from 'styled-components'
import useSWR from 'swr'

const { Text } = Typography
const { Item } = List

const StyledText = styled(Text)`
    word-break: break-all;
`

const StyledListItemMeta = styled(Item.Meta)`
    margin: 0 !important;

    & .ant-list-item-meta-title {
        margin: 0;
        padding: 0;
        font-size: 1em;
    }
`

const useBankAccountInfo = (companyId?: string) => {
    const response = useSWR(
        companyId ? `rest/v1/companies/${companyId}/currencies` : null,
        buildFetcher(telestoRequest)
    )

    return response
}

export const BankAccount: React.FC<{
    companyId?: string
}> = ({ companyId }) => {
    const { data: bankAccount, error } = useBankAccountInfo(companyId)
    return (
        <>
            {!bankAccount ||
                (error && <Text>No bank account details found for this company.</Text>)}
            {bankAccount && (
                <List
                    split={false}
                    size="small"
                    key={bankAccount?.accountNumber}
                    itemLayout="vertical"
                >
                    {bankAccount?.bankName && (
                        <Item style={{ padding: '0 0 8px' }}>
                            <StyledListItemMeta title="Bank name" />
                            <Text copyable>{bankAccount?.bankName}</Text>
                        </Item>
                    )}
                    {bankAccount?.beneficiary && (
                        <Item style={{ padding: '0 0 8px' }}>
                            <StyledListItemMeta title="Beneficiary" />
                            <Text copyable>{bankAccount?.beneficiary}</Text>
                        </Item>
                    )}
                    {bankAccount?.iban && (
                        <Item style={{ padding: '0 0 8px' }}>
                            <StyledListItemMeta title="IBAN" />
                            <StyledText copyable>{bankAccount?.iban}</StyledText>
                        </Item>
                    )}
                    {bankAccount?.bic && (
                        <Item style={{ padding: '0 0 8px' }}>
                            <StyledListItemMeta title="BIC" />
                            <Text copyable>{bankAccount?.bic}</Text>
                        </Item>
                    )}
                    {bankAccount?.bankCode && (
                        <Item style={{ padding: '0 0 8px' }}>
                            <StyledListItemMeta title="Bank code" />
                            <Text copyable>{bankAccount?.bankCode}</Text>
                        </Item>
                    )}
                    {bankAccount?.accountNumber && (
                        <Item style={{ padding: '0 0 8px' }}>
                            <StyledListItemMeta title="Account number" />
                            <Text copyable>{bankAccount?.accountNumber}</Text>
                        </Item>
                    )}
                </List>
            )}
        </>
    )
}
