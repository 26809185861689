/**
 * This represents a payment direction.
 *
 * CREDIT means we are crediting an account with funds (increasing balance), DEBIT means balance decrease.
 */
export enum Direction {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT',
}

export interface Balance {
    currency: string
    updated: string
    value: number
}

export interface GetCompanyBalanceResponse {
    balance: { current: Balance; available: Balance; creditLimit: Balance }
    currency: string
    id: string
}
