import { Select, Typography, type SelectProps } from 'antd'
import type { FC } from 'react'

const { Text } = Typography

interface Props extends SelectProps {
    filterRule: string | undefined
    ruleList: { label: string; value: string }[]
}

const RuleFilterSelect: FC<React.PropsWithChildren<Props>> = ({
    filterRule,
    ruleList,
    ...props
}) => {
    return (
        <>
            <Text>Filter Rule:</Text>
            <Select
                {...props}
                showSearch
                placeholder="Select a rule"
                filterSort={(
                    optionA: { label: string; value: string },
                    optionB: { label: string; value: string }
                ) =>
                    (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={ruleList}
                allowClear
                data-testid="rule-name-selector"
                value={filterRule}
                style={{ width: '16rem' }}
            />
        </>
    )
}

export default RuleFilterSelect
