import { Button, Input, Modal, Space } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

interface Props {
    isOpen: boolean
    isLoading: boolean
    onCancel: () => void
    onSubmit: (reason: string) => void
}

export default function AccountClosingModal({ isOpen, onCancel, onSubmit, isLoading }: Props) {
    const [reason, setReason] = useState('')

    const handleCancel = () => {
        setReason('')
        onCancel()
    }

    return (
        <Modal open={isOpen} onCancel={handleCancel} footer={null} centered title="Closing Account">
            <StyledSpace direction="vertical" size="middle">
                <Input.TextArea
                    name="reason"
                    autoSize={{ minRows: 5, maxRows: 20 }}
                    placeholder="Reason of closing"
                    onChange={(event) => setReason(event.target.value)}
                    aria-label="Reason of closing"
                />
                <Button type="primary" onClick={() => onSubmit(reason)} loading={isLoading}>
                    Closing account
                </Button>
            </StyledSpace>
        </Modal>
    )
}

const StyledSpace = styled(Space)`
    width: 100%;
`
