import { CloseCircleTwoTone } from '@ant-design/icons'
import type {
    EmployeeBalance,
    GetPocketEmployeeEntriesResponse,
    SuccessResponse,
} from '@pleo-io/deimos'
import {
    Button,
    Card,
    Divider,
    notification,
    Skeleton,
    Typography,
    Checkbox as AntdCheckBox,
} from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { Formik, FormikHelpers } from 'formik'
import { Checkbox, Form } from 'formik-antd'
import { FC, useState } from 'react'
import dayjs from 'packages/dayjs'

import styled from 'styled-components'
import { fontSize, spacing } from 'theme/tokens'
import {
    useEmployeePocketEntries,
    useEmployeePocketBalance,
    markSettledPocketExpense,
} from './api/pocket-entries'

interface PocketDetailsProps {
    employeeId: string
    employeeBalance?: EmployeeBalance
    entries: GetPocketEmployeeEntriesResponse
}

const StyledText = styled(Typography.Text)`
    font-size: ${fontSize.size24};
    margin-top: ${spacing.space4};
`
const StyledCheckbox = styled(AntdCheckBox.Group)`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${spacing.space8};
`
const CheckAllContainer = styled.div`
    padding-bottom: ${spacing.space8};
`
const ButtonContainer = styled.div`
    display: inline-block;
    padding-left: ${spacing.space24};
`
const Padding = styled.div`
    padding-bottom: ${spacing.space24};
`

type FormValues = {
    expenseIds: string[]
    allExpenses: boolean
}

const initialValues: FormValues = {
    expenseIds: [],
    allExpenses: false,
}

const PocketDetails: FC<PocketDetailsProps> = ({
    entries: { records },
    employeeBalance,
    employeeId,
}) => {
    const [pocketRecords, setPocketRecords] = useState(records)
    const totalPocketValue = pocketRecords.reduce((total, it) => total + it?.amount?.value, 0)
    const balanceAmount = employeeBalance?.balance?.approved?.value || 0

    const isDirtyEmployee = balanceAmount !== totalPocketValue

    const handleSubmit = async (
        { expenseIds }: FormValues,
        formikHelpers: FormikHelpers<FormValues>
    ) => {
        if (!expenseIds.length) {
            return
        }

        try {
            const { success }: SuccessResponse = await markSettledPocketExpense(
                employeeId,
                expenseIds
            ).json()

            if (success) {
                notification.success({
                    message: `Successfully marked expenses as settled.`,
                })

                const otherRecords = pocketRecords.filter(
                    (it) => !expenseIds.includes(it.expenseId as string)
                )

                setPocketRecords(otherRecords)
            } else {
                notification.error({
                    message: 'Could not mark expenses as settled.',
                    placement: 'topRight',
                })
            }
        } catch (error) {
            notification.error({
                message: 'Could not mark expenses as settled.',
                description: (error as Error).message,
                placement: 'topRight',
            })

            formikHelpers.resetForm()
        }
    }

    return (
        <>
            <StyledText>
                Pocket Balance: {employeeBalance?.balance?.approved.currency || ''}{' '}
                {employeeBalance?.balance?.approved.value.toFixed(2) || 0}
            </StyledText>
            <Divider style={{ margin: '12px 0 8px 0' }} />
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ values, setValues, isSubmitting }) => {
                    const disableMarkSettledBtn = true

                    const onCheckAllChange = (e: CheckboxChangeEvent) => {
                        if (e.target.checked) {
                            const allExpenseIds = records.map((it) => it.expenseId as string)
                            setValues({ expenseIds: allExpenseIds, allExpenses: true })
                        } else {
                            setValues({ expenseIds: [], allExpenses: false })
                        }
                    }

                    const onChange = (checkedValues: unknown[]) => {
                        const newValues = { ...values, expenseIds: checkedValues as string[] }
                        setValues(newValues)
                    }

                    return (
                        <Form>
                            <div style={{ width: '600px' }}>
                                {pocketRecords.length ? (
                                    <div>
                                        <Padding>
                                            <p>
                                                Team Pocket is improving this functionality. If
                                                you'd like to settle a balance please click{' '}
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://linear.app/pleo/team/ORION/new?template=699eeac5-f552-4770-a901-97b5c756fc6c"
                                                >
                                                    here
                                                </a>{' '}
                                                to fill in the Linear ticket for us. Sorry for the
                                                inconvinience.
                                            </p>
                                            <label htmlFor="Submit button">
                                                Unsettled Pocket Entries
                                            </label>
                                            <ButtonContainer>
                                                <Button
                                                    name="Submit button"
                                                    htmlType="submit"
                                                    type="primary"
                                                    disabled={disableMarkSettledBtn}
                                                    data-testid="submit-btn"
                                                    loading={isSubmitting}
                                                >
                                                    Mark Settled Expenses
                                                </Button>
                                            </ButtonContainer>
                                            <p
                                                hidden={isDirtyEmployee}
                                                style={{ color: 'red' }}
                                                data-testid="not-diret-message"
                                            >
                                                Balance does not need settling
                                            </p>
                                        </Padding>
                                        <CheckAllContainer>
                                            <Checkbox
                                                name="unsettledPocketEntriesOptionAll"
                                                data-testid="unsettledPocketEntriesOptionAll"
                                                checked={values.allExpenses}
                                                onChange={onCheckAllChange}
                                            >
                                                <strong>All Pocket Entries</strong>
                                            </Checkbox>
                                        </CheckAllContainer>
                                    </div>
                                ) : (
                                    <span>
                                        <CloseCircleTwoTone />{' '}
                                        <span>
                                            <strong>No unsettled Pocket entries</strong>
                                        </span>
                                    </span>
                                )}

                                <StyledCheckbox
                                    onChange={onChange}
                                    data-testid="unsettledPocketEntriesOption"
                                    value={values.expenseIds}
                                >
                                    {pocketRecords.map((record) => (
                                        <div key={record.id}>
                                            <Checkbox
                                                name=""
                                                value={record.expenseId}
                                                data-testid={`unsettledPocketEntriesOption-${record.id}`}
                                            >
                                                {`${record.expense?.merchantName} on ${dayjs(
                                                    record.expense?.settledAt
                                                )}: (${
                                                    record.amount.currency
                                                } ${record.amount.value.toFixed(2)})`}
                                            </Checkbox>
                                        </div>
                                    ))}
                                </StyledCheckbox>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

const PocketNotEnabled = () => {
    return (
        <span>
            <CloseCircleTwoTone /> <span>Pocket Not Enabled for non Employee</span>
        </span>
    )
}

interface Props {
    employeeId?: string | null
}

export const Pocket: FC<Props> = ({ employeeId }) => {
    const { data: pocketBalance, isValidating: isLoadingBalance } =
        useEmployeePocketBalance(employeeId)
    const { data: pocketEntriesResult, isValidating: isLoadingEntries } =
        useEmployeePocketEntries(employeeId)

    if (!employeeId) {
        return <PocketNotEnabled />
    }

    return (
        <Card title="Pocket Expenses">
            {isLoadingBalance || isLoadingEntries ? (
                <Skeleton active />
            ) : pocketEntriesResult ? (
                <PocketDetails
                    employeeId={employeeId}
                    entries={pocketEntriesResult}
                    employeeBalance={pocketBalance}
                />
            ) : (
                <span>Loading...</span>
            )}
        </Card>
    )
}
