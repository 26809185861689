import request from './request'
import type { CursorPaginationCommand, CursorRequestParams } from 'types/cursor-pagination'
import { getCursorPaginatedResource } from './get-cursor-paginated-resource'
import type { PaginationData } from '@pleo-io/deimos'
import type { SupplierStatus } from '../../types/deimos-supplier'

export async function getSuppliers(
    command: CursorPaginationCommand | undefined,
    pagination: PaginationData<string>,
    requestParams: CursorRequestParams
) {
    return getCursorPaginatedResource(
        'rest/v1/admin/bill-invoices/suppliers',
        pagination,
        requestParams,
        command
    )
}

export async function getSupplier(id: string) {
    return request().get(`rest/v1/admin/bill-invoices/suppliers/${id}`)
}

export async function getInvoices(
    id: string,
    command: CursorPaginationCommand | undefined,
    pagination: PaginationData<string>,
    requestParams: CursorRequestParams
) {
    return getCursorPaginatedResource(
        `rest/v1/admin/bill-invoices/suppliers/${id}/bill-invoices`,
        pagination,
        requestParams,
        command
    )
}

export interface UpdateSupplierPayload {
    name?: string
    vatNumber?: string
    registryId?: string
    iban?: string
    address1?: string
    address2?: string
    city?: string
    countryCode?: string
    region?: string
    zipCode?: string
    bankCode?: string
    accountNumber?: string
    invoiceId?: string
    accounts?: [
        {
            updateType?: 'CREATE' | 'UPDATE' | 'DELETE'
            id?: string
            countryCode?: string
            bic?: string
            iban?: string
            bankCode?: string
            accountNumber?: string
        }
    ]
}

export async function putSupplier(supplierId: string, payload: UpdateSupplierPayload) {
    return request().put(`rest/v1/admin/bill-invoices/suppliers/${supplierId}`, {
        json: payload,
    })
}

export interface UpdateSupplierStatusPayload {
    note?: string
    status: SupplierStatus
}

export async function postSupplierStatus(supplierId: string, payload: UpdateSupplierStatusPayload) {
    return request().post(`rest/v1/admin/bill-invoices/suppliers/${supplierId}/status`, {
        json: payload,
    })
}

export async function postRejectInvoice(invoiceId: string) {
    return request().post(`rest/v1/admin/bill-invoices/${invoiceId}/reject`)
}
