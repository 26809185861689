import { CountryCode, isValidNumber } from 'libphonenumber-js'
import { isNil } from 'ramda'
import { addMethod, string, StringSchema, ref } from 'yup'

type Reference = ReturnType<typeof ref>

declare module 'yup' {
    export interface StringSchema {
        phone(countryCodeRef: Reference, message?: string): StringSchema
    }
}

addMethod<StringSchema>(string, 'phone', function (countryCodeRef: Reference, message?: string) {
    return this.test({
        name: 'phone',
        exclusive: true,
        message: message || 'Must be a valid phone number',
        test(value) {
            try {
                if (isNil(value) || value === '') {
                    return true
                }
                const resolvedCountryCode = this.resolve(countryCodeRef) as CountryCode
                return isValidNumber(value, resolvedCountryCode)
            } catch (e) {
                return false
            }
        },
    })
})
