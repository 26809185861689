import type { ApplicationState } from 'bff/moons/generated/kale'
import { getOverdraftApplicationStatusColor } from '../../helpers'
import { Tag } from 'antd'

interface Props {
    state?: ApplicationState
}

export default function OverdraftApplicationStateTag({ state }: Props) {
    return <Tag color={getOverdraftApplicationStatusColor(state)}>{state}</Tag>
}
