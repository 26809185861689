import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { deactivateEmployee } from 'services/deimos/employees'
import { Button, message, Popconfirm, Space, Typography } from 'antd'
import { bff } from '../bff-hooks'

const { Paragraph, Text } = Typography

const DeactivateEntityEmployeeConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Deactivate employee?</Text>
        <Paragraph>Are you sure you want to deactivate this employee?</Paragraph>
    </Space>
)

export const DeactivateEmployee: FC<{ employeeId?: string }> = ({ employeeId }) => {
    const navigate = useNavigate()
    const { data: employee, refetch } = bff.deactivateEmployee.getEmployee.useQuery({
        employeeId,
    })

    if (!employee) {
        return null
    }

    const deactivateEmployeeAction = async () => {
        await deactivateEmployee(employee.id)
    }

    return (
        <Popconfirm
            title={<DeactivateEntityEmployeeConfirmation />}
            onConfirm={async () => {
                try {
                    await deactivateEmployeeAction()
                    message.success('Employee deactivated')

                    navigate(`/customer-success/search`)
                    refetch()
                } catch (e) {
                    message.error('Can not deactivate employee')
                }
            }}
            okText="Yes"
            cancelText="No"
            placement="bottom"
            overlayStyle={{ width: '400px' }}
        >
            <Button danger data-testid="deactivate-entity-employee">
                Deactivate Employee
            </Button>
        </Popconfirm>
    )
}
