import { Button, Modal, Space, Table } from 'antd'
import type { FullWalletLoad } from 'bff/moons/generated/cupid-v1'
import type { FC } from 'react'
import { ExceptionOutlined } from '@ant-design/icons'
import { cashFlowBff } from '../bff'

const retryStatuses = ['APPROVED', 'BLOCKED', 'FAILED', 'QUEUED']

interface Props {
    data: FullWalletLoad[]
    reloadData: () => {}
}

const WalletLoads: FC<Props> = ({ data, reloadData }) => {
    const { mutate: retryWalletLoadMutation } = cashFlowBff.retryWalletLoad.useMutation({
        onSuccess: () => reloadData(),
    })

    const retryWalletLoadConfirmation = (walletLoadId: string) => {
        Modal.confirm({
            title:
                'The wallet load will be rescheduled for processing, meaning that its new status ' +
                'will be APPROVED, the attempts counter will be reset to zero, the scheduled date will be ' +
                'the current date if the wallet load scheduled date was not set or the existing scheduled date and ' +
                'an the wallet load event will be published. ' +
                'From the APPROVED state the system will pick up the wallet load and process it async. ' +
                'Do you want to continue?',
            icon: <ExceptionOutlined />,
            onOk() {
                retryWalletLoadMutation({ walletLoadId })
            },
            onCancel() {},
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Invoice Reference',
            dataIndex: 'invoiceReference',
            key: 'invoiceReference',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Scheduled At',
            dataIndex: 'scheduledAt',
            key: 'scheduledAt',
        },
        {
            title: 'Loaded At',
            dataIndex: 'loadedAt',
            key: 'loadedAt',
        },
        {
            title: 'Blocked Reason',
            dataIndex: 'blokedReason',
            key: 'blokedReason',
        },
        {
            title: 'Attempts Counter',
            dataIndex: 'attemptsCounter',
            key: 'attemptsCounter',
        },
        {
            title: 'Spend Source Type',
            dataIndex: 'spendSourceType',
            key: 'spendSourceType',
        },
        {
            title: 'Spend API Version',
            dataIndex: 'spendApiVersion',
            key: 'spendApiVersion',
        },
        {
            title: 'Spend Chain ID',
            dataIndex: 'spendChainId',
            key: 'spendChainId',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: FullWalletLoad) => (
                <Space size="middle">
                    {record.status && retryStatuses.includes(record.status) && (
                        <Button type="link" onClick={() => retryWalletLoadConfirmation(record.id)}>
                            Retry
                        </Button>
                    )}
                </Space>
            ),
        },
    ]
    return <Table dataSource={data} columns={columns} pagination={false} size="small" bordered />
}

export default WalletLoads
