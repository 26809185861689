import type { OverdraftApplication } from 'bff/moons/generated/kale'
import type {
    AccountState,
    AccountSubState,
    KalykeApiResponseAccountStateDataDto,
    KalykeApiResponseCollectionAccount,
} from 'bff/moons/generated/kalyke'
import type { OverdraftEligibilityV2 } from 'bff/moons/generated/narvi-v1'
import request, { fetcher } from 'services/deimos/request'
import useSWR from 'swr'

export const useOverdraftEligibility = (companyId: string | undefined) => {
    const result = useSWR<OverdraftEligibilityV2>(
        companyId ? `rest/v2/credit/eligibility/companies/${companyId}/overdraft` : null,
        fetcher
    )
    return {
        ...result,
        isLoading: !result.data && !result.error,
    }
}

export const useOverdraftApplication = (companyId: string | undefined) => {
    const result = useSWR<OverdraftApplication[]>(
        companyId ? `rest/v2/credit/applications/companies/${companyId}/overdraft` : null,
        fetcher
    )
    const applicationsSortedByNewest = (result.data ?? [])
        .slice()
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    return {
        ...result,
        data: applicationsSortedByNewest[0],
        isLoading: !result.data && !result.error,
    }
}

export const useOverdraftAccount = (companyId: string | undefined) => {
    const result = useSWR<KalykeApiResponseCollectionAccount>(
        companyId ? `rest/v1/credit-accounts/companies/${companyId}/accounts?type=OVERDRAFT` : null,
        fetcher
    )
    const accountsSortedByNewest = (result.data?.data ?? [])
        .slice()
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))

    const pendingRenewalAccount =
        accountsSortedByNewest[0]?.state === 'PENDING_RENEWAL'
            ? accountsSortedByNewest[0]
            : undefined
    const currentAccount = pendingRenewalAccount
        ? accountsSortedByNewest[1]
        : accountsSortedByNewest[0]

    return {
        ...result,
        data: { currentAccount, pendingRenewalAccount },
        isLoading: !result.data && !result.error,
    }
}

const OVERDRAFT_ENABLED_STATE: AccountState[] = ['ACTIVE', 'GRACE', 'OVERDUE', 'COLLECTIONS']

export const useIsOverdraftEnabled = (companyId: string | undefined) => {
    const result = useOverdraftAccount(companyId)

    return {
        ...result,
        isOverdraftEnabled:
            result.data?.currentAccount?.state &&
            OVERDRAFT_ENABLED_STATE.includes(result.data?.currentAccount?.state),
    }
}

export const updateOverdraftAccountState = ({
    accountId,
    state,
    subState,
}: {
    accountId: string
    state: AccountState
    subState?: AccountSubState
}) => {
    return request().put(`rest/v1/credit-accounts/accounts/${accountId}/state`, {
        json: {
            state,
            subState,
        } satisfies KalykeApiResponseAccountStateDataDto['data'],
    })
}

export const updateOverdraftEligibility = ({
    companyId,
    payload,
}: {
    companyId: string
    payload: OverdraftEligibilityV2
}) => {
    return request().put(`rest/v2/credit/eligibility/companies/${companyId}/overdraft`, {
        json: payload,
    })
}
