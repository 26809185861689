import { Result } from 'antd'

const SuccessResult = () => {
    return (
        <Result
            status="success"
            title="Data creation in progress!"
            subTitle="You will receive all the followup information shortly via slack"
        />
    )
}

export default SuccessResult
