import { Form, Input } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { useAppMarketplaceAPIContext } from '../contexts/app-markeplace-context'
import React, { useEffect } from 'react'
import { useDebounce } from 'utils/use-debounce'

export const AppSearchSection = () => {
    const { setSearchTerm } = useAppMarketplaceAPIContext()
    const [searchText, setSearchText] = React.useState('')
    const debouncedSearchText = useDebounce(searchText, 400)

    useEffect(() => {
        setSearchTerm(debouncedSearchText)
    }, [debouncedSearchText, setSearchTerm])

    return (
        <Form layout="vertical" style={{ padding: '16px 0' }}>
            <FormItem label="Search">
                <Input.Search
                    placeholder="app name"
                    onChange={(event) => setSearchText(event.target.value.trim())}
                />
            </FormItem>
        </Form>
    )
}
