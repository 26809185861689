import { Card, Modal } from 'antd'
import type { Person } from 'types/styx'
import SearchSamePersonContainer from './search-same-person-container'

interface Props {
    person: Person
    open: boolean
    onCancel: () => void
}

export const SearchSamePersonModal = ({ person, open, onCancel }: Props) => {
    const closeModal = () => {
        onCancel()
    }

    return (
        <Modal
            open={open}
            onCancel={() => closeModal()}
            title={`Persons matching ${person.name.givenName} ${person.name.familyName}`}
            footer={null}
            destroyOnClose
            width={1280}
        >
            <Card style={{ marginBottom: '1rem' }} type="inner">
                <SearchSamePersonContainer person={person} onSuccessfulCopy={closeModal} />
            </Card>
        </Modal>
    )
}
