import { ConfigProvider, theme } from 'antd'

import Spinner from 'components/content-spinner'
import SidebarTitle from 'components/sidebar-title/sidebar-title'
import { Routes } from 'react-router-dom'
import { useUser } from 'providers/user-context'
import { Content, Header, Layout, SIDEBAR_WIDTH, Sider } from './page-layout-components'
import { useColorScheme } from 'hooks/use-color-scheme'

interface PageProps {
    sideBar?: React.ReactNode
    header?: React.ReactNode
    children: React.ReactNode
    sideBarLabel?: string
    hideSidebarDropdown?: boolean
}

const Page = ({
    sideBar,
    sideBarLabel,
    hideSidebarDropdown = false,
    header,
    children,
}: PageProps) => {
    const user = useUser()
    const { shouldUseDarkMode, colorScheme } = useColorScheme()

    return (
        <ConfigProvider
            theme={{
                algorithm: shouldUseDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
            }}
        >
            {user.isLoading ? (
                <Spinner />
            ) : (
                <Layout $colorScheme={colorScheme}>
                    <Sider width={SIDEBAR_WIDTH} $colorScheme={colorScheme}>
                        <SidebarTitle label={sideBarLabel} hideDropdown={hideSidebarDropdown} />
                        <Routes>{sideBar}</Routes>
                    </Sider>
                    <Header $colorScheme={colorScheme}>
                        <Routes>{header}</Routes>
                    </Header>
                    <Content>
                        <Routes>{children}</Routes>
                    </Content>
                </Layout>
            )}
        </ConfigProvider>
    )
}

export default Page
