import styled from 'styled-components'
import { Col } from 'antd'

export const FormCol = styled(Col)`
    .ant-form-item {
        margin-bottom: 0;
    }

    .ant-select {
        width: 100%;
    }

    .ant-form-item-with-help .ant-form-item-explain {
        display: none;
    }
`
