import { Select, SelectProps } from 'formik-antd'
import { isEmpty } from 'lodash'
import type { FC } from 'react'
import { useStyxV2IndustryCodes } from 'services/styx/industry-codes'
import { notEmpty } from 'utils/not-empty'

const { Option } = Select

interface Props extends SelectProps {
    disabledValues: string[]
}

const IndustryCodesSelect: FC<React.PropsWithChildren<Props>> = ({ disabledValues, ...props }) => {
    const { industryCodes } = useStyxV2IndustryCodes()

    const sections = industryCodes.map((section) => section)
    const divisions = sections
        .map((section) => section.divisions)
        .flatMap((division) => division)
        .filter(notEmpty)
    const groups = divisions
        .map((division) => division.groups)
        .flatMap((group) => group)
        .filter(notEmpty)
    const classes = groups
        .map((group) => group.classes)
        .flatMap((classItem) => classItem)
        .filter(notEmpty)

    const mapped = classes
        .map((classItem) => {
            if (isEmpty(classItem.code)) {
                return
            }

            const code = classItem.code!
            const label = code + ' - ' + classItem.description

            return {
                label: label,
                value: code,
            }
        })
        .filter(notEmpty)

    return (
        <Select {...props} optionFilterProp="label" mode="multiple">
            {mapped.map((item) => (
                <Option
                    disabled={disabledValues.includes(item.value)}
                    key={item.value}
                    value={item.value}
                    label={item.label}
                    data-testid={`industryCodes-option-${item.value}`}
                >
                    {item.label}
                </Option>
            ))}
        </Select>
    )
}

export default IndustryCodesSelect
