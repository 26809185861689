import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'router/protected-route'
import { LoginEventsPage } from './screens/login-events'

export default function LoginEventsTab() {
    return (
        <Routes>
            <Route element={<ProtectedRoute />}>
                <Route path="/" element={<LoginEventsPage />} />
            </Route>
        </Routes>
    )
}
