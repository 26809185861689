import { Descriptions, Typography } from 'antd'
import dayjs from 'dayjs'
import type { FC } from 'react'
import type { Transaction } from 'types/transaction'

const { Text } = Typography

export const TransactionDetails: FC<
    React.PropsWithChildren<{
        transaction: Transaction
    }>
> = ({ transaction }) => (
    <Descriptions column={1} size="small">
        <Descriptions.Item label="Merchant name">{transaction.merchant.name}</Descriptions.Item>
        <Descriptions.Item label="Performed">
            {dayjs(transaction.performed).format('lll')}
        </Descriptions.Item>
        <Descriptions.Item label="Card ID">
            <Text copyable>{transaction.cardId}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Company ID">
            <Text copyable>{transaction.companyId}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Provider">{transaction.provider}</Descriptions.Item>
        <Descriptions.Item label="Amount">
            {transaction.amount.value + transaction.amount.currency}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
            {transaction.type} ({transaction.purchaseType})
        </Descriptions.Item>
    </Descriptions>
)
