import styled from 'styled-components'
import { color, fontSize, spacing } from 'theme/tokens'
import {
    QuestionCircleOutlined,
    CheckCircleOutlined,
    PauseCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space24};
`

export const StatusContainer = styled.div`
    display: flex;
    gap: ${spacing.space16};
    align-self: center;
`

export const IconWrapper = styled.span`
    font-size: ${fontSize.size30};
    text-align: center;
`

export const LiveIcon = styled(CheckCircleOutlined)`
    color: ${color.green};
`

export const BlockedIcon = styled(PauseCircleOutlined)`
    color: ${color.gold};
`

export const QuestionIcon = styled(QuestionCircleOutlined)`
    color: ${color.blue};
`

export const ClosedIcon = styled(CloseCircleOutlined)`
    color: ${color.red};
`
