import { countries } from 'countries-list'

const isoCountryCodes = Object.keys(countries)

export const validCountryCodes = [
    'AT',
    'BE',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FO',
    'FR',
    'GB',
    'IE',
    'IT',
    'LU',
    'NL',
    'NO',
    'PT',
    'SE',
]

export const validRegistryIdRegex = new RegExp(`^(${validCountryCodes.join('|')}).+`)

export const isoCountryCodeRegistryIdRegex = new RegExp(`^(${isoCountryCodes.join('|')}).+`)
