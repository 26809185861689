import {
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    Modal,
    Radio,
    Result,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Typography,
} from 'antd'
import { useState } from 'react'
import { Status } from 'types/proof-of-funds'
import AllSources from 'pages/compliance/company/sources-of-funds/all-transfers-table'
import SourcesOfFundsTable from 'pages/compliance/company/sources-of-funds/source-of-funds-table'
import type { CompanyRevenue } from 'types/styx'
import type { ColumnsType } from 'antd/es/table'
import { ContentContainer } from 'components/layout-containers'
import { DetailsContainer } from 'pages/compliance/shared/styled'
import { Field, FieldProps, Formik } from 'formik'
import { useRevenueRanges, useRevenueSources } from 'services/styx/company'
import { bff } from 'pages/compliance/bff'

const { Text } = Typography

type ProofOfFundsType = 'approved' | 'unapproved' | 'all'
const options = [
    { label: 'Approved sources', value: 'approved' },
    { label: 'Un-approved sources', value: 'unapproved' },
    { label: 'All sources', value: 'all' },
]

interface EditCompanyRevenueFormSchema {
    sourceOfRevenue: string[]
    range: number
    description?: string
}

const getProofOfFundsTable = (proofOfFundsType: ProofOfFundsType, companyId: string) => {
    switch (proofOfFundsType) {
        case 'approved':
            return (
                <SourcesOfFundsTable
                    companyId={companyId}
                    status={Status.APPROVED}
                    actionName="Un-approve"
                    actionStatus={Status.REJECTED}
                />
            )
        case 'unapproved':
            return (
                <SourcesOfFundsTable
                    companyId={companyId}
                    status={Status.REJECTED}
                    actionName="Re-approve"
                    actionStatus={Status.APPROVED}
                />
            )
        default:
            return <AllSources companyId={companyId} />
    }
}

type EditCompanyRevenueDataFormProps = {
    companyRevenue?: CompanyRevenue
    country?: string
    onSubmit: (value: CompanyRevenue) => Promise<void>
}

export const EditCompanyRevenueForm = ({
    companyRevenue,
    country,
    onSubmit,
}: EditCompanyRevenueDataFormProps) => {
    const { data: revenueSources } = useRevenueSources()
    const { data: revenueRanges } = useRevenueRanges(country)

    const indexedRevenueRanges = (revenueRanges || [])
        .sort((a, b) => (a.minimum || 0) - (b.minimum || 0))
        .map((item, index) => ({
            index: index,
            item: item,
            title: `Currency: ${item.currency || 'N/A'} - Minimum: ${
                item.minimum === undefined || isNaN(item.minimum) ? 'N/A' : item.minimum
            } - Maximum: ${
                item.maximum === undefined || isNaN(item.maximum) ? 'N/A' : item.maximum
            }`,
        }))

    const filteredRanges = indexedRevenueRanges.filter(
        (range) =>
            range.item.maximum == companyRevenue?.maximumRevenue ||
            range.item.minimum == companyRevenue?.minimumRevenue
    )

    const selectedValue = filteredRanges.length === 0 ? -1 : filteredRanges[0].index

    return (
        <Formik
            enableReinitialize
            onSubmit={async (values, actions) => {
                const selectedRange = indexedRevenueRanges.find(
                    (range) => range.index === values.range
                )

                await onSubmit({
                    sourceOfRevenue: values.sourceOfRevenue,
                    minimumRevenue: selectedRange?.item.minimum,
                    maximumRevenue: selectedRange?.item.maximum,
                    currency: selectedRange?.item.currency,
                    description: values.description,
                })

                actions.setSubmitting(false)
            }}
            initialValues={{
                sourceOfRevenue: companyRevenue?.sourceOfRevenue || [],
                range: selectedValue,
                description: companyRevenue?.description,
            }}
        >
            {({ isSubmitting, submitForm, values }) => (
                <Form>
                    <Form.Item label="Source of revenue">
                        <Field name="sourceOfRevenue">
                            {({
                                field,
                                form,
                            }: FieldProps<string[], EditCompanyRevenueFormSchema>) =>
                                revenueSources?.map((source) => (
                                    <Tag.CheckableTag
                                        key={source}
                                        checked={field.value.includes(source)}
                                        onChange={(checked) => {
                                            if (checked) {
                                                form.setFieldValue('sourceOfRevenue', [source])
                                            } else {
                                                form.setFieldValue('sourceOfRevenue', [])
                                            }

                                            if (source !== 'Other')
                                                form.setFieldValue('description', null)
                                        }}
                                    >
                                        {source}
                                    </Tag.CheckableTag>
                                ))
                            }
                        </Field>
                    </Form.Item>

                    {values.sourceOfRevenue.some((source) => source === 'Other') && (
                        <Form.Item label="Description">
                            <Field
                                name="description"
                                data-testid="description"
                                as={Input.TextArea}
                            />
                        </Form.Item>
                    )}

                    <Form.Item label="Range">
                        <Field name="range">
                            {({
                                field,
                                form,
                            }: FieldProps<number, EditCompanyRevenueFormSchema>) => (
                                <Select
                                    onChange={(index) => form.setFieldValue('range', Number(index))}
                                    value={
                                        indexedRevenueRanges.find(
                                            (range) => range.index === field.value
                                        )?.title
                                    }
                                >
                                    {indexedRevenueRanges?.map((value) => (
                                        <Select.Option key={value.index}>
                                            {value.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Field>
                    </Form.Item>

                    <Row justify="end">
                        <Button
                            onClick={() => submitForm()}
                            htmlType="submit"
                            type="primary"
                            loading={isSubmitting}
                        >
                            Save
                        </Button>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

type CompanyRevenueDataProps = {
    title: string
    companyRevenue?: CompanyRevenue
    country?: string
    onSubmitEdit: (value: CompanyRevenue) => Promise<void>
}

export const CompanyRevenueData = ({
    title,
    companyRevenue,
    country,
    onSubmitEdit,
}: CompanyRevenueDataProps) => {
    const [modalActive, setModalActive] = useState(false)

    let body
    if (!companyRevenue) {
        body = <Text>N/A</Text>
    } else {
        body = (
            <DetailsContainer>
                <Descriptions column={1} size="small">
                    <Descriptions.Item label="Sources">
                        {companyRevenue.sourceOfRevenue.map((source) => (
                            <Tag key={source}>{source}</Tag>
                        ))}
                    </Descriptions.Item>

                    {companyRevenue.sourceOfRevenue.some((source) => source === 'Other') && (
                        <Descriptions.Item label="Description">
                            {companyRevenue.description || 'N/A'}
                        </Descriptions.Item>
                    )}

                    <Descriptions.Item label="Minimum Revenue">
                        {companyRevenue.minimumRevenue || 'N/A'}
                    </Descriptions.Item>

                    <Descriptions.Item label="Maximum Revenue">
                        {companyRevenue.maximumRevenue || 'N/A'}
                    </Descriptions.Item>

                    <Descriptions.Item label="Currency">
                        {companyRevenue.currency || 'N/A'}
                    </Descriptions.Item>
                </Descriptions>
            </DetailsContainer>
        )
    }

    return (
        <>
            <Row justify={'space-between'}>
                <Col>
                    <h3>{title}</h3>
                </Col>
                <Col>
                    <Button onClick={() => setModalActive(true)}>Edit</Button>
                </Col>
            </Row>

            <Row>{body}</Row>

            <Modal
                title="Edit revenue data"
                open={!!modalActive}
                onCancel={() => setModalActive(false)}
                footer={null}
                width={900}
                centered
            >
                <EditCompanyRevenueForm
                    companyRevenue={companyRevenue}
                    country={country}
                    onSubmit={async (value) => {
                        await onSubmitEdit(value)
                        setModalActive(false)
                    }}
                />
            </Modal>
        </>
    )
}

type SourcesOfFundsProps = {
    companyId: string
}

const SourcesOfFunds = ({ companyId }: SourcesOfFundsProps) => {
    const [proofOfFundsType, setProofOfFundsType] = useState<ProofOfFundsType>('approved')

    return (
        <ContentContainer>
            <Card>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <BankVerificationEntities companyId={companyId} />

                    <Radio.Group
                        options={options}
                        optionType="button"
                        defaultValue={proofOfFundsType}
                        onChange={(event) => setProofOfFundsType(event.target.value)}
                    />

                    {getProofOfFundsTable(proofOfFundsType, companyId)}
                </Space>
            </Card>
        </ContentContainer>
    )
}

type BankVerificationDetails = {
    id: string
    bankName: string
    accountHolderName: string
    bankCode: string
    accountNumber: string
    adminFullName?: string
}

type BankVerificationEntitiesArgs = {
    companyId: string
}

export const BankVerificationEntities = ({ companyId }: BankVerificationEntitiesArgs) => {
    const { data: bankVerificationDetailsData, error: bankVerificationDetailsDataError } =
        bff.sourcesOfFunds.getBankVerificationDetails.useQuery({ companyId })

    const columns: ColumnsType<BankVerificationDetails> = [
        {
            title: 'Bank name',
            dataIndex: 'bankName',
            key: 'bankName',
        },
        {
            title: 'Bank code',
            dataIndex: 'bankCode',
            key: 'bankCode',
        },
        {
            title: 'Account #',
            dataIndex: 'accountNumber',
            key: 'accountNumber',
        },
        {
            title: 'Account holder',
            dataIndex: 'accountHolderName',
            key: 'accountHolderName',
        },
        {
            title: 'Admin',
            dataIndex: 'adminFullName',
            key: 'adminFullName',
        },
    ]

    if (bankVerificationDetailsDataError) {
        return (
            <Result
                title="Could not fetch bank details"
                subTitle={bankVerificationDetailsDataError.message}
                status="error"
            />
        )
    }

    if (!bankVerificationDetailsData || bankVerificationDetailsData.length === 0) {
        return null
    }

    return (
        <Space direction="vertical">
            <Text strong>Bank verifications :</Text>

            <Table
                columns={columns}
                dataSource={bankVerificationDetailsData}
                pagination={false}
                size="small"
                rowKey="id"
            />
        </Space>
    )
}

export default SourcesOfFunds
