import type { FC } from 'react'
import { Table, Card, Button } from 'antd'
import dayjs from 'packages/dayjs'
import type { ColumnProps } from 'antd/lib/table'

import { PageContentLayout } from 'components/layout-containers'
import type { CadmusReportMetadata } from 'types/cadmus-reports'

interface Props {
    data: CadmusReportMetadata[] | undefined
    onDownloadFile: (reportFileName: string, version: string) => Promise<void>
}

const ReportTable: FC<React.PropsWithChildren<Props>> = ({ data, onDownloadFile }) => {
    const formatDate = (date?: string) => (date ? dayjs(date).format('lll') : '-')

    const columns: ColumnProps<CadmusReportMetadata>[] = [
        {
            title: 'Report',
            dataIndex: 'reportFileName',
            render: (reportFileName: string) => reportFileName,
        },
        {
            title: 'Creation Date',
            dataIndex: 'created',
            defaultSortOrder: 'descend',
            sorter: (a, b) => dayjs(a.created).valueOf() - dayjs(b.created).valueOf(),
            render: (created?: string) => formatDate(created),
        },
        {
            title: 'Download Report',
            render: (reportMetadata: CadmusReportMetadata) => (
                <Button
                    onClick={() =>
                        onDownloadFile(reportMetadata.reportFileName, reportMetadata.version)
                    }
                    type="link"
                    data-testid="download-button"
                >
                    Download
                </Button>
            ),
        },
    ]

    return (
        <PageContentLayout>
            <Card title={'Revenue Recognition Reports'}>
                <Table
                    columns={columns}
                    rowKey={(row: CadmusReportMetadata) => row.reportFileName}
                    loading={!data}
                    dataSource={data}
                />
            </Card>
        </PageContentLayout>
    )
}

export default ReportTable
