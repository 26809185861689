import { Button, Space } from 'antd'

import { bff } from './bff-hooks'
import { useOddDetailContext } from './context'
import { MailOutlined } from '@ant-design/icons'
import { CustomActionId } from '../../pages/compliance/odd/types'

export const CustomActionsList = () => {
    const { caseId, sendRequestInformationModal, delayNotificationsModal, isLocked } =
        useOddDetailContext()

    const actions = bff.customActionsList.getOddCustomActions.useQuery(
        { caseType: 'ODD' },
        { enabled: !!caseId }
    )

    const customActionButtons = [
        {
            key: CustomActionId.SCHEDULE_REQUEST_INFO_EMAILS,
            render: (key: CustomActionId) => (
                <Button
                    key={key}
                    icon={<MailOutlined />}
                    disabled={isLocked}
                    onClick={sendRequestInformationModal.open}
                >
                    Request Information
                </Button>
            ),
        },
        {
            key: CustomActionId.DELAY_EMAIL_SCHEDULE,
            render: (key: CustomActionId) => (
                <Button
                    key={key}
                    icon={<MailOutlined />}
                    disabled={isLocked}
                    onClick={delayNotificationsModal.open}
                >
                    Delay Notifications
                </Button>
            ),
        },
    ]

    const availableActions = customActionButtons.filter(({ key }) => actions?.data?.includes(key))

    return (
        <Space
            style={{ width: '100%', display: 'flex', flexFlow: 'row wrap', marginRight: '1rem' }}
        >
            {availableActions &&
                availableActions.length > 0 &&
                availableActions.map(({ key, render }) => render(key))}
        </Space>
    )
}
