import type { Country } from 'countries-list'

const CountriesOfManufacturingContainer = ({
    countriesOfManufacturing,
}: {
    countriesOfManufacturing: Country[]
}) => {
    return (
        <>
            {countriesOfManufacturing
                .map((c) => c.name)
                .sort()
                .join(', ')}
        </>
    )
}

export default CountriesOfManufacturingContainer
