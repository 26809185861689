import { Space } from 'antd'
import type { FC } from 'react'

import { InvoiceLink } from './invoice-link'

interface DownloadsProps {
    id: string
}

export const DownloadInvoice: FC<React.PropsWithChildren<DownloadsProps>> = ({ id }) => {
    return (
        <Space>
            <InvoiceLink invoiceId={id} />
        </Space>
    )
}
