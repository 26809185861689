import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Typography } from 'antd'
import { BreakText } from 'components/layout-containers'
import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import type { Employee } from 'types/employee'

export const SHOWN_USERS_NUMBER = 2

const { Link, Text } = Typography

const UserList: FC<
    React.PropsWithChildren<{
        users?: Employee[]
        onUserClick: (employee: Employee) => void
    }>
> = ({ users, onUserClick }) => {
    const [showedUsers, setShowedUsers] = useState(SHOWN_USERS_NUMBER)
    const hasUsers = users?.length ?? 0
    const hasMoreUsers = showedUsers < (users?.length ?? 0)
    const hasLessUsers =
        (showedUsers === users?.length ?? false) && showedUsers > SHOWN_USERS_NUMBER
    const showMoreUsers = () => setShowedUsers(users?.length ?? 0)
    const showLessUsers = () => setShowedUsers(SHOWN_USERS_NUMBER)

    if (users === undefined) {
        return <Text type="secondary">Failed to load.</Text>
    }
    return (
        <>
            {hasUsers ? (
                <UserContainer>
                    {users.slice(0, showedUsers).map((user, index) => (
                        <InfoRowItem key={index} data-testid="user">
                            <Link onClick={() => onUserClick(user)} data-testid="user-name">
                                {user.firstName} {user.lastName ? user.lastName : ''}
                            </Link>
                            {window.innerWidth >= 2560 ? (
                                <Text>
                                    {`: `}
                                    <BreakText copyable type="secondary">
                                        {user.email}
                                    </BreakText>
                                </Text>
                            ) : (
                                <></>
                            )}
                        </InfoRowItem>
                    ))}
                    {hasMoreUsers && (
                        <StyledButton
                            size="small"
                            type="text"
                            icon={<DownOutlined />}
                            onClick={showMoreUsers}
                        >
                            show more
                        </StyledButton>
                    )}
                    {hasLessUsers && (
                        <StyledButton
                            size="small"
                            type="text"
                            icon={<UpOutlined />}
                            onClick={showLessUsers}
                        >
                            show less
                        </StyledButton>
                    )}
                </UserContainer>
            ) : (
                <Text type="secondary">None</Text>
            )}
        </>
    )
}

const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const InfoRowItem = styled.div`
    display: flex;
    align-items: baseline;
    gap: ${spacing.space4};
`

export const StyledButton = styled(Button)`
    padding: 0;
    width: fit-content;
`

export default UserList
