import React, { FC, useState, useEffect } from 'react'
import { Card, Divider, message, Button, Space, Descriptions } from 'antd'
import { PageContentLayout } from 'components/layout-containers'

import type { Expense } from 'types/expense'
import { useExpense } from 'services/deimos/expense'
import { SearchOutlined } from '@ant-design/icons'
import { Formik } from 'formik'
import { Input as AntInput, Form } from 'formik-antd'

import { inputWidth } from 'theme/tokens'

interface ExpenseInformationProps {
    expense?: Expense
    loading: boolean
}

const ExpenseInformation: FC<React.PropsWithChildren<ExpenseInformationProps>> = ({
    expense,
    loading,
    children,
}) => (
    <>
        <Descriptions
            title="Expense information"
            bordered
            column={{ xxl: 4, xl: 3, lg: 2, md: 1, sm: 1 }}
            size="small"
        >
            <Descriptions.Item label="Employee name">
                {`${expense?.employee?.firstName || ''} ${expense?.employee?.lastName || ''}`}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant name">
                {expense?.merchantName || ''}
            </Descriptions.Item>
            <Descriptions.Item label="Type">{expense?.purchaseType || ''}</Descriptions.Item>
            <Descriptions.Item label="Amount">
                {`${expense?.amount.currency ?? ''} ${expense?.amount.value ?? ''}`}
            </Descriptions.Item>
            <Descriptions.Item label="Employee email">
                {expense?.employee?.email || ''}
            </Descriptions.Item>
            <Descriptions.Item label="Category">{expense?.category?.name || ''}</Descriptions.Item>
            <Descriptions.Item label="Status">{expense?.status || ''}</Descriptions.Item>
            <Descriptions.Item label="Created (UTC)">
                {(expense && expense.createdAt) || ''}
            </Descriptions.Item>
            <Descriptions.Item label="Settled (UTC)">{expense?.settledAt || ''}</Descriptions.Item>
            <Descriptions.Item label="Updated (UTC)">
                {(expense && expense.updatedAt) || ''}
            </Descriptions.Item>
        </Descriptions>
        <Divider />
        {!loading && children}
    </>
)

const RebuildExpense = () => {
    const [expenseId, setExpenseId] = useState('')
    const [loading, setLoading] = useState(false)
    const { data: expense, error, mutations } = useExpense(expenseId)

    useEffect(() => {
        if (error) {
            message.error(error.message)
            setExpenseId('')
        }
    }, [error])

    async function rebuild() {
        setLoading(true)
        await mutations.rebuild()
        setLoading(false)
    }

    return (
        <PageContentLayout>
            <Card title="Rebuild expense">
                <Formik
                    initialValues={{ expenseId }}
                    onSubmit={(values) => setExpenseId(values.expenseId)}
                >
                    {({ values, isValidating }) => (
                        <Form layout="vertical">
                            <Form.Item name="expenseID" label="Expense ID">
                                <Space>
                                    <AntInput
                                        name="expenseId"
                                        placeholder="3d95410e-ce4e-4660-9297-c513bdb6b263"
                                        style={{ width: `${inputWidth.huge}` }}
                                    />
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={!!expenseId && !expense && isValidating}
                                        disabled={!values.expenseId}
                                    >
                                        <SearchOutlined />
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    )}
                </Formik>
                <ExpenseInformation expense={expense} loading={!expense && !!expenseId && !error}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={rebuild}
                        loading={loading}
                        disabled={!expenseId}
                    >
                        Rebuild Expense
                    </Button>
                </ExpenseInformation>
            </Card>
        </PageContentLayout>
    )
}

export default RebuildExpense
