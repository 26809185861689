export enum Velocity {
    sdd = 'sdd',
    fdd = 'fdd',
    edd = 'edd',
    hedd = 'hedd',
    hedd1 = 'hedd1',
}

export type Status = {
    [key in Velocity]?: boolean
}

export const VelocityPriority: { [key in Velocity]: number } = {
    sdd: 1,
    fdd: 2,
    edd: 3,
    hedd: 4,
    hedd1: 5,
}
