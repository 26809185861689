const options = {
    configure: undefined,
    groups: {
        root: {
            shape: 'box',
            borderWidth: 2,
            margin: 10,
            label: {
                color: '#000',
            },
        },
        company: {
            shape: 'box',
            borderWidth: 2,
            margin: 10,
        },
        person: {
            borderWidth: 1,
        },
        unknown: {
            color: '#C99',
        },
    },
    layout: {
        hierarchical: {
            enabled: true,
            nodeSpacing: 250,
            treeSpacing: 150,
        },
        improvedLayout: true,
        randomSeed: 1,
    },
    interaction: {
        hover: true,
        keyboard: {
            enabled: true,
            bindToWindow: false,
        },
        navigationButtons: true,
        dragView: true,
        zoomSpeed: 2,
        zoomView: false,
    },
    edges: {
        color: '#666',
        labelHighlightBold: true,
        scaling: {
            min: 200,
            max: 200,
        },
        smooth: {
            type: 'dynamic',
            enabled: false,
            roundness: 0.2,
        },
    },
    physics: {
        enabled: false,
        solver: 'repulsion',
    },
    nodes: {
        font: {
            enabled: true,
            multi: 'html',
            bold: {
                size: 14,
                color: '#000',
            },
            ital: {
                size: 11,
                color: '#4C4C4C',
            },
        },
    },
}

export default options
