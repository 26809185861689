import { Tag } from 'antd'
import type { App } from '../models'

export const AppAuthMethod = ({ method }: { method: App['authenticationMethod'] }) => {
    switch (method) {
        case 'API_KEY':
            return <Tag color="gold">API Key</Tag>

        case 'OAUTH2':
            return <Tag color="purple">OAuth</Tag>

        default:
            return null
    }
}

export const AppStatus = ({ status }: { status: App['status'] }) => {
    switch (status) {
        case 'PUBLISHED':
            return <Tag color="green">Published</Tag>
        case 'UNPUBLISHED':
            return <Tag color="#a0a0a0">Unpublished</Tag>
        case 'BETA':
            return <Tag color="blue">Beta</Tag>
        case 'UPCOMING':
            return <Tag color="blue">Upcoming</Tag>

        default:
            return null
    }
}
