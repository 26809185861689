import { getPersonName, getRoleNames, type NarrativeCategory } from 'pages/compliance/shared/utils'
import { useState, type FC, useEffect } from 'react'
import NarrativeContainer from 'pages/compliance/company/shared/narrative'
import { useManagement } from 'services/styx/management'
import { Form, Select } from 'antd'
import { isUboPerson } from 'utils/is-shareholder'
import { Spinner } from '@pleo-io/telescope-icons'
import type { Person } from 'types/styx'

const PersonNarrative: FC<
    React.PropsWithChildren<{
        label: string
        companyId: string
        category: NarrativeCategory
    }>
> = ({ label, companyId, category }) => {
    const { management, isValidating } = useManagement()
    const [personId, setPersonId] = useState<string>()
    const [isEmptyState, setIsEmptyState] = useState<boolean>(true)

    useEffect(() => {
        if (personId) {
            setIsEmptyState(false)
        }
    }, [personId])

    const slicedManagement = management?.slice().sort((a, b) => a.id.localeCompare(b.id)) ?? [] // Slice to create a new array
    const uboPersons = slicedManagement.filter((entity) => isUboPerson(entity)) as Person[] // Filter and cast to Person[]

    const uboPersonObjects = uboPersons.map((person) => ({
        label: getPersonName(person.name) + ' - ' + getRoleNames(person.roles),
        value: person.id,
    }))

    if (!management && isValidating) {
        return <Spinner />
    }

    return (
        <NarrativeContainer
            label={label}
            companyId={companyId}
            category={category}
            personId={personId}
            isEmptyState={isEmptyState}
        >
            <Form.Item name="personId">
                <Select
                    value={personId}
                    onSelect={setPersonId}
                    options={uboPersonObjects}
                    placeholder="Select a UBO and change input text below"
                    showSearch
                    optionFilterProp="label"
                    data-testid="narrative-select-person"
                />
            </Form.Item>
        </NarrativeContainer>
    )
}

export default PersonNarrative
