import React, { FC, useCallback, useState } from 'react'
import {
    AccountCategoryMutations,
    AccountOption,
    useAccountCategories,
    useCompanyAccountingAccountsOptions,
} from 'services/deimos/categories'
import { Button, Card, Typography, Dropdown, Spin, notification } from 'antd'
import { spacing } from 'theme/tokens'
import { Formik, FormikHelpers } from 'formik'
import { Form, Input, Select } from 'formik-antd'
import * as yup from 'yup'
import styled from 'styled-components'
import {
    Account,
    AccountCategory,
    AccountCategoryTypeKey,
    UpdateAccount,
    UpdateAccountCategory,
} from '@pleo-io/deimos'
import { Options, Info } from '@pleo-io/telescope-icons'

import { TaxCodeOptions, useGroupedTaxCodeOptions } from 'services/deimos/tax-code'
import { ContentContainer } from '../../../../components/layout-containers'
import { CategoryIcon } from './category-icon'
import {
    accountCategoriesMap,
    ActionError,
    getCategoryActions,
    getCategoryGroupActions,
    getCategoryTypeIcons,
    getErrorMessage,
    getFilteredCategoryGroups,
} from './helpers'
import { FormikAutoSubmit } from './validation/use-formik-auto-submit'
import { AccountNumber } from './account-number'

const { Title, Text, Link } = Typography

export type CategoryValues = Partial<UpdateAccount>
export type CategoryGroupValues = Partial<UpdateAccountCategory>

interface CategoryRowProps {
    category: Account
    accountsOptions: AccountOption[]
    taxCodeOptions: TaxCodeOptions
    mutations: AccountCategoryMutations
}

const CategoryRow: FC<React.PropsWithChildren<CategoryRowProps>> = ({
    category,
    accountsOptions,
    taxCodeOptions,
    mutations,
}) => {
    const { name, accountNumber, taxCodeId, externalId, externalName } = category
    const { updateAccount } = mutations

    const initialFormValues: CategoryValues = {
        name: name.trim() !== '' ? name : '',
        accountNumber: externalId ?? accountNumber ?? '',
        taxCodeId,
        externalId,
        externalName,
    }

    const validationSchema = yup.object().shape({
        name: yup.string().required('Name is required'),
        accountNumber: yup.string().required('Account is required'),
    })

    const handleSubmitCategoryChange = (
        payload: CategoryValues,
        { setSubmitting }: FormikHelpers<CategoryValues>
    ) => {
        notification.warning({
            message: 'Not editable (BX-2289)',
            description: 'Your changes have not been saved.',
        })

        // BX-2289: Enable once we have handled edge-cases.
        const enableCategoryManipulation = false
        if (enableCategoryManipulation) {
            updateAccount(category.id, {
                ...payload,
                name: payload.name || ' ',
                taxCodeId: payload.taxCodeId ?? null,
            })
        }

        setSubmitting(false)
    }

    const handleCategoryActionError = ({ errorMessage, type }: ActionError) => {
        notification.error({
            message: getErrorMessage({ errorMessage, type, name }),
        })
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validateOnMount
            onSubmit={handleSubmitCategoryChange}
        >
            <CategoryForm>
                <Category>
                    <AccountNumberColumn>
                        <AccountNumber accountsOptions={accountsOptions} category={category} />
                    </AccountNumberColumn>

                    <CategoryNameColumn>
                        <Input name="name" placeholder="Name..." />
                    </CategoryNameColumn>

                    <TaxCodeColumn>
                        <TaxCodeSelect
                            name="taxCodeId"
                            placeholder="Tax code..."
                            options={taxCodeOptions}
                        />
                    </TaxCodeColumn>

                    <Dropdown
                        menu={{
                            items: getCategoryActions({
                                category,
                                mutations,
                                onError: handleCategoryActionError,
                            }),
                        }}
                    >
                        <Button type="link" style={{ marginLeft: 'auto' }}>
                            <OptionsIcon />
                        </Button>
                    </Dropdown>
                </Category>

                <FormikAutoSubmit<CategoryValues> />
            </CategoryForm>
        </Formik>
    )
}

interface CategoryViewProps {
    categories: Account[]
    accountsOptions: AccountOption[]
    taxCodeOptions: TaxCodeOptions
    mutations: AccountCategoryMutations
    showHiddenCategories: boolean
}

const CategoryView: FC<React.PropsWithChildren<CategoryViewProps>> = ({
    categories,
    accountsOptions,
    taxCodeOptions,
    mutations,
    showHiddenCategories,
}) => {
    const filteredCategories = categories.filter((category) =>
        showHiddenCategories ? category.hidden : !category.hidden
    )

    return (
        <Card style={{ marginTop: 12 }}>
            <CategoryRows>
                {filteredCategories.map((category) => (
                    <CategoryRow
                        category={category}
                        accountsOptions={accountsOptions}
                        taxCodeOptions={taxCodeOptions}
                        mutations={mutations}
                        key={category.id}
                    />
                ))}
            </CategoryRows>
        </Card>
    )
}

interface CategoryGroupProps {
    companyId: string
    categoryGroup: AccountCategory
    accountsOptions: AccountOption[]
    taxCodeOptions: TaxCodeOptions
    showHiddenCategories: boolean
    mutations: AccountCategoryMutations
}

const CategoryGroup: FC<React.PropsWithChildren<CategoryGroupProps>> = ({
    categoryGroup,
    accountsOptions,
    taxCodeOptions,
    showHiddenCategories,
    mutations,
}) => {
    const { createAccount, updateCategory } = mutations
    const { name, id, accounts: categories = [], typeKey, hidden } = categoryGroup

    const hasCategories = categories.length !== 0

    const createNewAccount = () => {
        createAccount({ name: ' ', accountCategoryId: id })
    }

    const initialFormValues = {
        name: name?.trim() !== '' ? name : undefined,
        typeKey: typeKey,
    }

    const submitCategoryGroupChange = (payload: CategoryGroupValues) => {
        if (payload.name && payload.name !== categoryGroup.name) {
            return updateCategory(categoryGroup.id, { name: payload.name })
        }

        if (payload.typeKey === categoryGroup.typeKey) return

        const accountCategoryType = accountCategoriesMap().get(payload.typeKey!)
        const targetTypeKey = accountCategoryType?.typeKey

        updateCategory(categoryGroup.id, {
            typeKey: targetTypeKey,
            ...(categoryGroup.name.trim() === '' && { name: accountCategoryType?.label }),
        })
    }

    const handleSubmitCategoryGroupChange = (
        payload: CategoryGroupValues,
        { setSubmitting }: FormikHelpers<CategoryGroupValues>
    ) => {
        submitCategoryGroupChange(payload)
        setSubmitting(false)
    }

    const handleCategoryGroupChange = (targetTypeKey: AccountCategoryTypeKey) => {
        submitCategoryGroupChange({ name, typeKey: targetTypeKey })
    }

    const handleCategoryGroupActionError = ({ errorMessage, type }: ActionError) => {
        notification.error({
            message: getErrorMessage({ errorMessage, type, name }),
        })
    }

    return (
        <Card key={id}>
            <CategoryGroupHeader>
                <Formik
                    initialValues={initialFormValues}
                    onSubmit={handleSubmitCategoryGroupChange}
                >
                    <CategoryForm>
                        <Row>
                            <Dropdown
                                disabled
                                menu={{
                                    items: getCategoryTypeIcons({
                                        onChange: handleCategoryGroupChange,
                                    }),
                                }}
                            >
                                <Button type="link" style={{ marginLeft: 'auto' }}>
                                    <CategoryIcon imgkey={typeKey} />
                                </Button>
                            </Dropdown>

                            <Input name="name" disabled={hidden} placeholder="Name..." />

                            <FormikAutoSubmit<CategoryGroupValues> />
                        </Row>
                    </CategoryForm>
                </Formik>

                <Dropdown
                    menu={{
                        items: getCategoryGroupActions({
                            categoryGroup,
                            mutations,
                            onError: handleCategoryGroupActionError,
                        }),
                    }}
                >
                    <Button type="link" style={{ marginLeft: 'auto' }}>
                        <OptionsIcon />
                    </Button>
                </Dropdown>
            </CategoryGroupHeader>

            {hasCategories ? (
                <CategoryView
                    categories={categories}
                    accountsOptions={accountsOptions}
                    taxCodeOptions={taxCodeOptions}
                    mutations={mutations}
                    showHiddenCategories={showHiddenCategories}
                />
            ) : null}

            <Button onClick={createNewAccount} style={{ marginTop: 24, display: 'none' }}>
                Add category
            </Button>
        </Card>
    )
}

const CategoryGroups: FC<React.PropsWithChildren<{ companyId: string }>> = ({ companyId }) => {
    const [showHiddenCategories, setShowHiddenCategories] = useState(false)

    const toggleHiddenCategories = useCallback(() => {
        setShowHiddenCategories(!showHiddenCategories)
    }, [showHiddenCategories, setShowHiddenCategories])

    const {
        data: categoryGroups = [],
        mutations,
        isValidating: isValidatingCategoryGroups,
    } = useAccountCategories({ companyId })

    const { accountsOptions, isValidating: isValidatingAccountOptions } =
        useCompanyAccountingAccountsOptions({ companyId })

    const { data: taxCodeOptions, isValidating: isValidatingTaxCodeOptions } =
        useGroupedTaxCodeOptions({ companyId })

    const isValidating =
        isValidatingCategoryGroups || isValidatingAccountOptions || isValidatingTaxCodeOptions

    const { createCategory } = mutations

    const filteredCategoryGroups = getFilteredCategoryGroups({
        categoryGroups,
        showHiddenCategories,
    })

    const createCategoryGroup = () => {
        createCategory({ name: ' ', typeKey: AccountCategoryTypeKey.OTHER })
    }

    return (
        <ContentContainer>
            <Header>
                <Actions>
                    <Button onClick={toggleHiddenCategories}>
                        {showHiddenCategories ? 'Show active' : 'Show archived'}
                    </Button>

                    {showHiddenCategories ? null : (
                        <Button
                            type="primary"
                            ghost
                            style={{ marginLeft: 'auto', display: 'none' }}
                            onClick={createCategoryGroup}
                        >
                            Create category group
                        </Button>
                    )}
                </Actions>
            </Header>

            <Text>
                <Info /> <strong>Categories are not editable</strong> -{' '}
                <Link href="https://linear.app/pleo/issue/BX-2289/make-categories-editable-in-backoffice">
                    See more information here.
                </Link>
            </Text>

            {filteredCategoryGroups.length === 0 ? (
                <Card>
                    <InfoBox>
                        {showHiddenCategories ? (
                            <>
                                <Title level={4}>No archived categories</Title>
                                <Text>
                                    Archived categories will remain on expenses and included in
                                    exports but will no longer be selectable when entering expense
                                    details.
                                </Text>
                            </>
                        ) : (
                            <>
                                <Title level={4}>No active categories</Title>
                                <Text>
                                    Categories allow teams to automatically apply accounts, from
                                    your chart of accounts, by selecting them on an expense.
                                </Text>
                            </>
                        )}
                    </InfoBox>
                </Card>
            ) : null}

            {isValidating ? (
                <Spin />
            ) : (
                filteredCategoryGroups.map((categoryGroup) => (
                    <CategoryGroup
                        companyId={companyId}
                        categoryGroup={categoryGroup}
                        accountsOptions={accountsOptions}
                        taxCodeOptions={taxCodeOptions}
                        showHiddenCategories={showHiddenCategories}
                        mutations={mutations}
                        key={categoryGroup.id}
                    />
                ))
            )}
        </ContentContainer>
    )
}

export default CategoryGroups

const Header = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${spacing.space8};
    width: 100%;
`

const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing.space8};
    width: 100%;
    text-align: center;
    width: 300px;
    margin: 0 auto;
`

const CategoryRows = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${spacing.space16};
    width: 100%;
`

const CategoryForm = styled(Form)`
    width: 100%;

    // Start of temp styles to 'disable' inputs until editable
    input {
        pointer-events: none;
        border: none;
        outline: none;
        cursor: default;
    }
    .ant-select {
        pointer-events: none;
        cursor: default;
    }
    .ant-select-selector {
        border: none !important;
    }
    .ant-select-arrow {
        display: none;
    }
    // End of temp styles
`

const Row = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.space8};
    width: 100%;
`

const Actions = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: ${spacing.space8};
    width: 100%;
`

const CategoryGroupHeader = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.space8};
    width: 100%;
`

const AccountNumberColumn = styled.div`
    width: 250px;
`
const CategoryNameColumn = styled.div`
    flex: 2;
`
const TaxCodeColumn = styled.div`
    width: 250px;
`

const Category = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.space8};
    width: 100%;
`

const OptionsIcon = styled(Options)`
    color: rgb(179, 179, 179);
`

const TaxCodeSelect = styled(Select)`
    width: 100%;
`
