import useSWR from 'swr'
import type { ErrorResponse } from '@pleo-io/deimos'

import type {
    CreatePersonRequest,
    CreateCompanyRequest,
    AddDirectorPayload,
    AddShareholderPayload,
    EnrichedStyxCompany,
    UnknownEntity,
    Entity,
} from 'types/styx'

import request, { fetcher } from '../request'
import { useStyxCompany } from './styx-company'
import { removeCommonEntities, parseUnknownPersonName } from 'services/styx/utils'
import { useManagement } from 'services/styx/management'
import { useAuditTrail, useOwnershipGraph } from 'services/styx/ownership'
import { createPerson as createStyxPerson } from 'services/styx/person'

export const getCompanyEntities = (styxId: string, snapshot?: string) => {
    const searchParams = new URLSearchParams()

    if (snapshot) {
        searchParams.append('searchParams', snapshot)
    }

    return request().get(`rest/v1/styx/companies/${styxId}`, {
        searchParams,
    })
}

export const createStyxCompany = (body: CreateCompanyRequest): Promise<Entity> =>
    request()
        .post('rest/v1/styx/companies', {
            json: body,
        })
        .json()

export const addDirector = (styxId: string, body: AddDirectorPayload) =>
    request().post(`rest/v1/styx/companies/${styxId}/directors`, { json: body })

export const removeDirector = (styxId: string, directorId: string) =>
    request().delete(`rest/v1/styx/companies/${styxId}/directors/${directorId}`)

export const addCompanyShareholder = (styxCompanyId: string, body: AddShareholderPayload) =>
    request().post(`rest/v1/styx/companies/${styxCompanyId}/shareholders`, {
        json: body,
    })

export const removeCompanyShareholder = (styxCompanyId: string, shareholderId: string) =>
    request().delete(`rest/v1/styx/companies/${styxCompanyId}/shareholders/${shareholderId}`)

export function useCompanyEntities(styxId: string) {
    const { company } = useStyxCompany()
    const id = styxId || company?.id
    const response = useSWR<EnrichedStyxCompany, ErrorResponse>(
        id ? `rest/v1/styx/companies/${id}` : null,
        fetcher,
        { revalidateOnFocus: false, shouldRetryOnError: false }
    )

    const {
        mutate: revalidateManagement,
        mutations: { removeFromScope },
    } = useManagement()
    const { mutate: revalidateOwnershipGraph } = useOwnershipGraph()
    const { mutate: revalidateAuditTrail } = useAuditTrail()

    const enrichedCompany = response.data

    const unassigned = enrichedCompany
        ? [...enrichedCompany.unassignedCompanies, ...enrichedCompany.unassignedPersons]
        : []

    const assigned = enrichedCompany
        ? removeCommonEntities(
              enrichedCompany.stakeholderPersons,
              enrichedCompany.unassignedPersons
          )
        : []

    const shareholders =
        enrichedCompany?.shareholders.filter((shareholder) => shareholder.category === 'REGULAR') ??
        []

    const beneficialOwners =
        enrichedCompany?.shareholders.filter(
            (shareholder) => shareholder.category === 'BENEFICIAL_OWNER'
        ) ?? []

    const directors = enrichedCompany?.directors ?? []

    const unknownEntities = enrichedCompany
        ? ([...enrichedCompany.shareholders, ...enrichedCompany.directors].filter(
              ({ type }) => type === 'UNKNOWN'
          ) as UnknownEntity[])
        : []

    const allStakeholderPersons = enrichedCompany?.stakeholderPersons ?? []

    const auditTrail = useAuditTrail().data ?? []

    // Mutations
    const createPerson = async (body: CreatePersonRequest) => {
        const person = await createStyxPerson(body)

        response.mutate((data) => {
            if (data) {
                return {
                    ...data,
                    unassignedPersons: data?.unassignedPersons.concat(person),
                }
            }
        })
    }

    const createCompany = async (body: CreateCompanyRequest) => {
        const createdCompany = await createStyxCompany(body)
        response.mutate((data) => {
            if (data) {
                return {
                    ...data,
                    unassignedCompanies: data?.unassignedCompanies.concat(createdCompany),
                }
            }
        })
    }

    const addDirectors = async (entities: AddDirectorPayload[]) => {
        if (!id) {
            return
        }

        for (let i = 0; i < entities.length; i++) {
            const entity = entities[i]
            await addDirector(id, entity)
        }

        await response.mutate()

        // * Adding directors to ownership structure includes them as managers
        revalidateManagement()
    }

    const removeDirectors = async (directorIds: string[]) => {
        if (!id) {
            return
        }

        for (let i = 0; i < directorIds.length; i++) {
            const directorId = directorIds[i]
            await removeDirector(id, directorId)
        }

        await response.mutate()

        // * Removing directors to ownership structure removes them as managers
        revalidateManagement()
    }

    const addShareholder = async (body: AddShareholderPayload) => {
        if (!id) {
            return
        }

        await addCompanyShareholder(id, body)
        await response.mutate()
        await revalidateOwnershipGraph()
        await revalidateAuditTrail()
    }

    const removeShareholders = async (shareholderIds: string[]) => {
        if (!id) {
            return
        }

        for (let i = 0; i < shareholderIds.length; i++) {
            const shareholderId = shareholderIds[i]
            await removeCompanyShareholder(id, shareholderId)
        }

        await response.mutate()
        await revalidateOwnershipGraph()
        await revalidateAuditTrail()
    }

    const updateUnknownDirectors = async (convertedPerson: Entity) => {
        if (convertedPerson.type !== 'PERSON' || !id) {
            return
        }

        const commonDirectors = directors.filter(
            (director) => director.registryId === convertedPerson.registryId
        )

        // * An unknown entity can appear with multiple director positions
        // * This removes them all and replaces with our new person
        for (let i = 0; i < commonDirectors.length; i++) {
            await addDirector(id, {
                id: convertedPerson.id,
                legalPersonId: convertedPerson.id,
                position: commonDirectors[i].position,
                type: 'PERSON',
            })

            await removeDirector(id, commonDirectors[i].directorId)
        }

        await revalidateManagement()
    }

    const updateUnknownShareholder = async (convertedEntity: Entity) => {
        const entityShareholder = shareholders.find(
            (shareholder) => convertedEntity.registryId === shareholder.registryId
        )

        if (!entityShareholder || !id || convertedEntity.type === 'UNKNOWN') {
            return
        }

        await addCompanyShareholder(id, {
            legalPersonId: convertedEntity.id,
            type: convertedEntity.type,
            sharePercentage: entityShareholder.sharePercentage ?? 0,
            votingRights: entityShareholder.votingRights ?? 0,
        })
        await removeCompanyShareholder(id, entityShareholder.shareholderId)
        await revalidateOwnershipGraph()
        await revalidateAuditTrail()
    }

    const convertUnknownToPerson = async (unknownEntity: UnknownEntity) => {
        if (!enrichedCompany) {
            return
        }

        // When rootCompanyId isn't present, we are working with the rootCompany
        const rootCompanyId = enrichedCompany.rootCompanyId ?? enrichedCompany.id

        const entity = await createStyxPerson({
            name: parseUnknownPersonName(unknownEntity.name),
            rootCompanyId,
            registryId: unknownEntity.registryId,
        })

        await Promise.all([updateUnknownDirectors(entity), updateUnknownShareholder(entity)])
        await response.mutate()
    }

    const convertUnknownToCompany = async (unknownEntity: UnknownEntity) => {
        if (!enrichedCompany) {
            return
        }

        // When rootCompanyId isn't present, we are working with the rootCompany
        const rootCompanyId = enrichedCompany.rootCompanyId ?? enrichedCompany.id

        const entity = await createStyxCompany({
            registryId: unknownEntity.registryId,
            legalName: unknownEntity.name,
            rootCompanyId,
            address: {
                country: unknownEntity.registryId.slice(0, 2),
            },
        })

        await updateUnknownShareholder(entity)
        await response.mutate()
    }

    const removeStakeholderFromScope = async (subjectId: string) => {
        if (!enrichedCompany?.globalId) {
            return
        }

        await removeFromScope(subjectId)

        response.mutate((data) => {
            if (data) {
                return {
                    ...data,
                    stakeholderPersons: data?.stakeholderPersons.map((it) =>
                        it.id === subjectId ? { ...it, kycPerson: false } : it
                    ),
                }
            }
        })
    }

    return {
        ...response,
        derived: {
            unassigned,
            assigned,
            shareholders,
            beneficialOwners,
            directors,
            unknownEntities,
            allStakeholderPersons,
            auditTrail,
        },
        mutations: {
            createPerson,
            createCompany,
            addDirectors,
            removeDirectors,
            addShareholder,
            removeShareholders,
            convertUnknownToCompany,
            convertUnknownToPerson,
            removeStakeholderFromScope,
        },
    }
}

export type OwnershipMutations = ReturnType<typeof useCompanyEntities>['mutations']
