/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Phobos API
 * Phobos OpenAPI definitions
 * OpenAPI spec version: 36.3.1
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('phobos')

export type EchoStatusParams = {
    status?: number
}

export type FetchEnfuceTransactions1Params = {
    /**
     * List only transactions with AuthorizationType=FINANCIAL (transaction affecting the current balance). default to false
     */
    transactionsOnly?: boolean
    /**
     * From which date you want to list the transactions in ISO-8601 date-time format. If null, then toDate must also be null.
     */
    fromDate?: LocalDateTimeParam
    /**
     * To which date you want to list the transactions in ISO-8601 date-time format. If null, then fromDate must also be null.
     */
    toDate?: LocalDateTimeParam
    /**
     * Current page. default 0
     */
    page?: number
    /**
     * How many transactions should be returned per page, default is 15. Cannot be more than 30.
     */
    size?: number
}

export type ChangeBalanceOwnershipNewOwner =
    (typeof ChangeBalanceOwnershipNewOwner)[keyof typeof ChangeBalanceOwnershipNewOwner]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChangeBalanceOwnershipNewOwner = {
    ENFUCE: 'ENFUCE',
    ERSA: 'ERSA',
} as const

export type ChangeBalanceOwnershipParams = {
    newOwner?: ChangeBalanceOwnershipNewOwner
}

export type FetchEnfuceTransactionsParams = {
    transactionsOnly?: boolean
    fromDate?: LocalDateTimeParam
    toDate?: LocalDateTimeParam
    page?: number
    size?: number
}

export type GetCardsType = (typeof GetCardsType)[keyof typeof GetCardsType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCardsType = {
    VIRTUAL: 'VIRTUAL',
    PHYSICAL: 'PHYSICAL',
} as const

export type GetCardsStatusItem = (typeof GetCardsStatusItem)[keyof typeof GetCardsStatusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCardsStatusItem = {
    LIVE: 'LIVE',
    LOST: 'LOST',
    STOLEN: 'STOLEN',
    EXPIRED: 'EXPIRED',
    RESTRICTED: 'RESTRICTED',
    DEACTIVATED: 'DEACTIVATED',
    DESTROYED: 'DESTROYED',
} as const

export type GetCardsParams = {
    'card-ids'?: string[]
    'pan-end'?: string
    'pan-start'?: string
    status?: GetCardsStatusItem[]
    type?: GetCardsType
}

export type FetchUsageLimitsForCardsParams = {
    'card-ids'?: string[]
}

export interface WalletSyncRequestModel {
    createdAfter?: string
    createdBefore?: string
    delayBetweenBatchesInMillis?: number
    ids?: string[]
    toBackfillTopic: boolean
}

export type WalletModelProviderName =
    (typeof WalletModelProviderName)[keyof typeof WalletModelProviderName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletModelProviderName = {
    GPS: 'GPS',
    ENFUCE: 'ENFUCE',
    MARQETA: 'MARQETA',
} as const

export interface WalletModel {
    companyId: string
    currency: string
    id: string
    providerName: WalletModelProviderName
    velocity: string
}

export interface UpdateInternalPaymentRequestModel {
    companyId: string
    directedAmount: DirectedAmountModel
}

export interface UpdateCreditLimitRequestModel {
    creditLimit: Money
}

export type UpdateCompanyStatusRequestModelStatus =
    (typeof UpdateCompanyStatusRequestModelStatus)[keyof typeof UpdateCompanyStatusRequestModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCompanyStatusRequestModelStatus = {
    LIVE: 'LIVE',
    BLOCKED: 'BLOCKED',
    CLOSED: 'CLOSED',
} as const

export interface UpdateCompanyStatusRequestModel {
    reason?: string
    status: UpdateCompanyStatusRequestModelStatus
    updatedBy?: string
}

export interface UpdateCompanyRequestModel {
    address?: PostalAddressModel
    name?: string
    velocity?: string
}

export type UpdateCompanyCardFeesModelFeesCategory =
    (typeof UpdateCompanyCardFeesModelFeesCategory)[keyof typeof UpdateCompanyCardFeesModelFeesCategory]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCompanyCardFeesModelFeesCategory = {
    STANDARD: 'STANDARD',
    NONE: 'NONE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
} as const

export interface UpdateCompanyCardFeesModel {
    feesCategory: UpdateCompanyCardFeesModelFeesCategory
}

export type UpdateCompanyCardAtmFeesModelAtmFees =
    (typeof UpdateCompanyCardAtmFeesModelAtmFees)[keyof typeof UpdateCompanyCardAtmFeesModelAtmFees]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCompanyCardAtmFeesModelAtmFees = {
    STANDARD: 'STANDARD',
    NONE: 'NONE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
} as const

export interface UpdateCompanyCardAtmFeesModel {
    atmFees: UpdateCompanyCardAtmFeesModelAtmFees
}

export type UpdateCardRequestModelStatus =
    (typeof UpdateCardRequestModelStatus)[keyof typeof UpdateCardRequestModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCardRequestModelStatus = {
    LIVE: 'LIVE',
    LOST: 'LOST',
    STOLEN: 'STOLEN',
    EXPIRED: 'EXPIRED',
    RESTRICTED: 'RESTRICTED',
    DEACTIVATED: 'DEACTIVATED',
    DESTROYED: 'DESTROYED',
} as const

export interface UpdateCardRequestModel {
    name?: PersonNameModel
    status?: UpdateCardRequestModelStatus
}

export type UpdateCardRequestAdminModelStatus =
    (typeof UpdateCardRequestAdminModelStatus)[keyof typeof UpdateCardRequestAdminModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCardRequestAdminModelStatus = {
    LIVE: 'LIVE',
    LOST: 'LOST',
    STOLEN: 'STOLEN',
    EXPIRED: 'EXPIRED',
    RESTRICTED: 'RESTRICTED',
    DEACTIVATED: 'DEACTIVATED',
    DESTROYED: 'DESTROYED',
} as const

export type UpdateCardRequestAdminModelFees =
    (typeof UpdateCardRequestAdminModelFees)[keyof typeof UpdateCardRequestAdminModelFees]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCardRequestAdminModelFees = {
    STANDARD: 'STANDARD',
    NONE: 'NONE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
} as const

export type UpdateCardRequestAdminModelAtmFees =
    (typeof UpdateCardRequestAdminModelAtmFees)[keyof typeof UpdateCardRequestAdminModelAtmFees]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCardRequestAdminModelAtmFees = {
    STANDARD: 'STANDARD',
    NONE: 'NONE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
} as const

export interface UpdateCardRequestAdminModel {
    atmFees?: UpdateCardRequestAdminModelAtmFees
    companyId?: string
    fees?: UpdateCardRequestAdminModelFees
    name?: PersonNameModel
    status?: UpdateCardRequestAdminModelStatus
    velocity?: string
}

export type TransactionTypeAuthorizationType =
    (typeof TransactionTypeAuthorizationType)[keyof typeof TransactionTypeAuthorizationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionTypeAuthorizationType = {
    AUTH: 'AUTH',
    FINANCIAL: 'FINANCIAL',
} as const

export interface TransactionType {
    authorizationType: TransactionTypeAuthorizationType
    code: string
}

export type TransactionResponseModelPostingStatus =
    (typeof TransactionResponseModelPostingStatus)[keyof typeof TransactionResponseModelPostingStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionResponseModelPostingStatus = {
    POSTED: 'POSTED',
    POSTED_DISPUTE: 'POSTED_DISPUTE',
    CLOSED: 'CLOSED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
} as const

export interface TransactionResponseModel {
    cardId?: string
    feeAmount?: Money
    id: string
    linkId?: string
    postingDate?: string
    postingStatus?: TransactionResponseModelPostingStatus
    responseCode?: string
    settlementAmount?: Money
    transactionAmount?: Money
    transactionDate?: string
    transactionType: TransactionType
}

export type TransactionResponsePostingStatus =
    (typeof TransactionResponsePostingStatus)[keyof typeof TransactionResponsePostingStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionResponsePostingStatus = {
    POSTED: 'POSTED',
    POSTED_DISPUTE: 'POSTED_DISPUTE',
    CLOSED: 'CLOSED',
    PENDING: 'PENDING',
    REJECTED: 'REJECTED',
} as const

export interface TransactionResponse {
    cardId?: string
    feeAmount?: Money
    id: string
    linkId?: string
    merchant?: Merchant
    postingDate?: string
    postingStatus?: TransactionResponsePostingStatus
    responseCode?: string
    settlementAmount?: Money
    transactionAmount?: Money
    transactionDate?: string
    transactionType: TransactionType
}

export interface SyncRequestModel {
    persist: boolean
}

export type PublishCompanySpendAccountEventsRequestModelDelayBetweenBatchesUnitsItem = {
    isDateBased?: boolean
    isDurationEstimated?: boolean
    isTimeBased?: boolean
}

export type PublishCompanySpendAccountEventsRequestModelDelayBetweenBatches = {
    isNegative?: boolean
    isZero?: boolean
    nano?: number
    seconds?: number
    units?: PublishCompanySpendAccountEventsRequestModelDelayBetweenBatchesUnitsItem[]
}

export interface PublishCompanySpendAccountEventsRequestModel {
    companyIds?: string[]
    delayBetweenBatches?: PublishCompanySpendAccountEventsRequestModelDelayBetweenBatches
}

export interface ProvisionGooglePayResponseModel {
    digitizationData: string
}

/**
 * Type of device into which the digital wallet token will be provisioned.
 */
export type ProvisionGooglePayRequestModelDeviceType =
    (typeof ProvisionGooglePayRequestModelDeviceType)[keyof typeof ProvisionGooglePayRequestModelDeviceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProvisionGooglePayRequestModelDeviceType = {
    MOBILE_PHONE: 'MOBILE_PHONE',
    WATCH: 'WATCH',
    TABLET: 'TABLET',
} as const

export interface ProvisionGooglePayRequestModel {
    /** Version of the application making the provisioning request. */
    appVersion: string
    /** Unique identifier of the user’s Google device, as provided by Google during the provisioning process. */
    deviceId: string
    /** Type of device into which the digital wallet token will be provisioned. */
    deviceType: ProvisionGooglePayRequestModelDeviceType
    /** User’s Google Wallet account ID, as provided by Google during the provisioning process. */
    walletAccountId: string
}

export interface ProvisionApplePayResponseModel {
    activationData: string
    encryptedPassData: string
    ephemeralPublicKey: string
}

/**
 * Type of device into which the digital wallet token will be provisioned. Required in case of a Marqeta card.
 */
export type ProvisionApplePayRequestModelDeviceType =
    (typeof ProvisionApplePayRequestModelDeviceType)[keyof typeof ProvisionApplePayRequestModelDeviceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProvisionApplePayRequestModelDeviceType = {
    MOBILE_PHONE: 'MOBILE_PHONE',
    WATCH: 'WATCH',
    TABLET: 'TABLET',
} as const

export interface ProvisionApplePayRequestModel {
    /** Version of the application making the provisioning request. Required in case of a Marqeta card. */
    appVersion?: string
    /** Base64-encoded leaf and sub-CA certificates provided by Apple. The first element of the array should be the leaf certificate, followed by the sub-CA. */
    certificates: string[]
    /** Type of device into which the digital wallet token will be provisioned. Required in case of a Marqeta card. */
    deviceType?: ProvisionApplePayRequestModelDeviceType
    /** One-time-use nonce provided by Apple for security purposes. */
    nonce: string
    /** Apple-provided signature to the nonce. */
    nonceSignature: string
}

export type PostalAddressModelCountry =
    (typeof PostalAddressModelCountry)[keyof typeof PostalAddressModelCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostalAddressModelCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface PostalAddressModel {
    addressLine1?: string
    addressLine2?: string
    attention?: string
    country?: PostalAddressModelCountry
    locality?: string
    postalCode?: string
    region?: string
}

export interface PersonNameModel {
    familyName?: string
    givenName?: string
    middleName?: string
    prefix?: string
    suffix?: string
}

export interface Money {
    currency?: string
    value?: number
}

export type MerchantCountry = (typeof MerchantCountry)[keyof typeof MerchantCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MerchantCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface Merchant {
    city?: string
    country?: MerchantCountry
    mcc?: string
    name?: string
}

export interface LocalDateTimeParam {
    [key: string]: any
}

export type IssuerModel = (typeof IssuerModel)[keyof typeof IssuerModel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IssuerModel = {
    PLEO: 'PLEO',
    B4B: 'B4B',
    EVOLVE: 'EVOLVE',
    PATHWARD: 'PATHWARD',
} as const

export interface InternalPaymentSyncRequestModel {
    delayBetweenBatchesInMillis?: number
    ids?: string[]
}

export type InternalPaymentModelType =
    (typeof InternalPaymentModelType)[keyof typeof InternalPaymentModelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalPaymentModelType = {
    'balance-amendment': 'balance-amendment',
    'bill-invoice': 'bill-invoice',
    cashback: 'cashback',
    'load-v2': 'load-v2',
    'personal-transfer': 'personal-transfer',
    'pleo-invoice': 'pleo-invoice',
    'pleo-invoice-refund': 'pleo-invoice-refund',
    'pocket-bank-transfer-reimbursement': 'pocket-bank-transfer-reimbursement',
    'unload-v2': 'unload-v2',
    'wallet-withdrawal': 'wallet-withdrawal',
    'overdraft-fee': 'overdraft-fee',
    'overdraft-interest': 'overdraft-interest',
} as const

export type InternalPaymentModelStatus =
    (typeof InternalPaymentModelStatus)[keyof typeof InternalPaymentModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalPaymentModelStatus = {
    AUTHORIZED: 'AUTHORIZED',
    SETTLED: 'SETTLED',
    CANCELLED: 'CANCELLED',
} as const

export interface InternalPaymentModel {
    companyId: string
    directedAmount: DirectedAmountModel
    id: string
    receivedAt: string
    reference: string
    status: InternalPaymentModelStatus
    type: InternalPaymentModelType
}

export interface GetCardSensitiveDetailsRequestModel {
    /** Public RSA key in 'SPKI'/'X.509' format encoded in base64, length of 2048 */
    publicKey: string
}

export interface FetchTransactionFeesRequestModel {
    transactionIds: string[]
}

export interface ExceptionInfo {
    message?: string
    type: string
}

export interface EnfuceAndPhobosBalanceModel {
    currency: string
    enfuceBalance: BalanceModel
    id: string
    phobosBalance: BalanceModel
}

export interface EncryptedPanModel {
    encryptedPan: string
}

export type DirectedAmountModelDirection =
    (typeof DirectedAmountModelDirection)[keyof typeof DirectedAmountModelDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectedAmountModelDirection = {
    DEBIT: 'DEBIT',
    CREDIT: 'CREDIT',
} as const

export interface DirectedAmountModel {
    amount: Money
    direction: DirectedAmountModelDirection
}

export type DigitalWalletTokenModelDigitalWalletProvider =
    (typeof DigitalWalletTokenModelDigitalWalletProvider)[keyof typeof DigitalWalletTokenModelDigitalWalletProvider]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DigitalWalletTokenModelDigitalWalletProvider = {
    APPLE: 'APPLE',
    GOOGLE: 'GOOGLE',
    M4M: 'M4M',
    UNKNOWN: 'UNKNOWN',
} as const

export interface DigitalWalletTokenModel {
    digitalWalletProvider: DigitalWalletTokenModelDigitalWalletProvider
    tokenUniqueReferenceId: string
}

export interface DigitalPaymentsActivationDataResponseModel {
    activationData: string
}

export interface DeliveryModel {
    address?: PostalAddressModel
    phoneNumber?: string
}

export type CurrencyModel = string

export interface CreateOrUpdateResponse {
    description: string
    id: string
}

export type CreateCompanyRequestModelOnboardedVia =
    (typeof CreateCompanyRequestModelOnboardedVia)[keyof typeof CreateCompanyRequestModelOnboardedVia]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCompanyRequestModelOnboardedVia = {
    PLEO: 'PLEO',
    B4B: 'B4B',
} as const

export interface CreateCompanyRequestModel {
    address?: PostalAddressModel
    currency: string
    name: string
    onboardedVia?: CreateCompanyRequestModelOnboardedVia
    velocity: string
}

export type CreateCardRequestModelType =
    (typeof CreateCardRequestModelType)[keyof typeof CreateCardRequestModelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCardRequestModelType = {
    VIRTUAL: 'VIRTUAL',
    PHYSICAL: 'PHYSICAL',
} as const

export type CreateCardRequestModelFees =
    (typeof CreateCardRequestModelFees)[keyof typeof CreateCardRequestModelFees]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCardRequestModelFees = {
    STANDARD: 'STANDARD',
    NONE: 'NONE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
} as const

export type CreateCardRequestModelAtmFees =
    (typeof CreateCardRequestModelAtmFees)[keyof typeof CreateCardRequestModelAtmFees]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCardRequestModelAtmFees = {
    STANDARD: 'STANDARD',
    NONE: 'NONE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
} as const

export interface CreateCardRequestModel {
    atmFees?: CreateCardRequestModelAtmFees
    cardUserInfo?: CardUserInfoModel
    delivery?: DeliveryModel
    embossLine3?: string
    embossLine4?: string
    fees?: CreateCardRequestModelFees
    name?: PersonNameModel
    orderDetails?: CardCreationOrderDetailsModel
    type: CreateCardRequestModelType
}

export type CompanyStatusResponseModelStatus =
    (typeof CompanyStatusResponseModelStatus)[keyof typeof CompanyStatusResponseModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyStatusResponseModelStatus = {
    LIVE: 'LIVE',
    BLOCKED: 'BLOCKED',
    CLOSED: 'CLOSED',
} as const

export type CompanyStatusResponseModelProviderName =
    (typeof CompanyStatusResponseModelProviderName)[keyof typeof CompanyStatusResponseModelProviderName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompanyStatusResponseModelProviderName = {
    GPS: 'GPS',
    ENFUCE: 'ENFUCE',
    MARQETA: 'MARQETA',
} as const

export interface CompanyStatusResponseModel {
    companyId: string
    providerName: CompanyStatusResponseModelProviderName
    status: CompanyStatusResponseModelStatus
    statusReason?: string
}

export interface CompanyModel {
    address?: PostalAddressModel
    currency: string
    id: string
    name?: string
    velocity: string
}

export interface CompanyBalanceModel {
    balance: BalanceModel
    currency: string
    id: string
}

export type ChargedFeeResponseModelType =
    (typeof ChargedFeeResponseModelType)[keyof typeof ChargedFeeResponseModelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChargedFeeResponseModelType = {
    ATM: 'ATM',
    FX: 'FX',
} as const

export interface ChargedFeeResponseModel {
    amount: number
    transactionId: string
    type: ChargedFeeResponseModelType
}

export interface ChargebackSyncRequestModel {
    delayBetweenBatchesInMillis?: number
    ids?: string[]
}

export interface ChargebackResponseModel {
    chargebackTransactionId: string
    originalTransactionId: string
}

export interface ChargeFxFeeResultModel {
    cardId: string
    fxFeeAmount?: Money
    transactionId: string
}

export interface ChargeFxFeeRequestModel {
    fxFeeAmount: Money
    transactionId: string
}

export interface ChargeAtmFeeResultModel {
    atmFee?: AtmFeeModel
    cardId: string
    transactionId: string
}

export interface ChargeAtmFeeRequestModelV2 {
    atmFee: AtmFeeModel
    transactionId: string
}

export interface CardUserInfoModel {
    email: string
    phoneNumber?: string
}

export type CardUsageLimitResponseModelUsagePeriod =
    (typeof CardUsageLimitResponseModelUsagePeriod)[keyof typeof CardUsageLimitResponseModelUsagePeriod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardUsageLimitResponseModelUsagePeriod = {
    MONTHLY: 'MONTHLY',
    WEEKLY: 'WEEKLY',
    ROLLING_24H: 'ROLLING_24H',
} as const

export type CardUsageLimitResponseModelUsageMeans =
    (typeof CardUsageLimitResponseModelUsageMeans)[keyof typeof CardUsageLimitResponseModelUsageMeans]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardUsageLimitResponseModelUsageMeans = {
    ATM: 'ATM',
    ALL: 'ALL',
} as const

export interface CardUsageLimitResponseModel {
    amountPerPeriod: number
    amountPerTransaction?: number
    cardId: string
    deactivatedAt?: string
    id: string
    usageCountPerPeriod?: number
    usageMeans: CardUsageLimitResponseModelUsageMeans
    usagePeriod: CardUsageLimitResponseModelUsagePeriod
}

export type CardUsageLimitRequestModelUsagePeriod =
    (typeof CardUsageLimitRequestModelUsagePeriod)[keyof typeof CardUsageLimitRequestModelUsagePeriod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardUsageLimitRequestModelUsagePeriod = {
    MONTHLY: 'MONTHLY',
    WEEKLY: 'WEEKLY',
    ROLLING_24H: 'ROLLING_24H',
} as const

export type CardUsageLimitRequestModelUsageMeans =
    (typeof CardUsageLimitRequestModelUsageMeans)[keyof typeof CardUsageLimitRequestModelUsageMeans]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardUsageLimitRequestModelUsageMeans = {
    ATM: 'ATM',
    ALL: 'ALL',
} as const

export interface CardUsageLimitRequestModel {
    amountPerPeriod: number
    amountPerTransaction?: number
    usageCountPerPeriod?: number
    usageMeans: CardUsageLimitRequestModelUsageMeans
    usagePeriod: CardUsageLimitRequestModelUsagePeriod
}

export type CardSyncRequestModelStatusesItem =
    (typeof CardSyncRequestModelStatusesItem)[keyof typeof CardSyncRequestModelStatusesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardSyncRequestModelStatusesItem = {
    LIVE: 'LIVE',
    LOST: 'LOST',
    STOLEN: 'STOLEN',
    EXPIRED: 'EXPIRED',
    RESTRICTED: 'RESTRICTED',
    DEACTIVATED: 'DEACTIVATED',
    DESTROYED: 'DESTROYED',
} as const

export type CardSyncRequestModelAtmFeesItem =
    (typeof CardSyncRequestModelAtmFeesItem)[keyof typeof CardSyncRequestModelAtmFeesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardSyncRequestModelAtmFeesItem = {
    STANDARD: 'STANDARD',
    NONE: 'NONE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
} as const

export interface CardSyncRequestModel {
    atmFees?: CardSyncRequestModelAtmFeesItem[]
    delayBetweenBatchesInMillis?: number
    ids?: string[]
    statuses?: CardSyncRequestModelStatusesItem[]
    toBackfillTopic: boolean
    updatedAfter?: string
    updatedBefore?: string
}

export type CardSensitiveDetailsModelExpirationMonth =
    (typeof CardSensitiveDetailsModelExpirationMonth)[keyof typeof CardSensitiveDetailsModelExpirationMonth]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardSensitiveDetailsModelExpirationMonth = {
    JANUARY: 'JANUARY',
    FEBRUARY: 'FEBRUARY',
    MARCH: 'MARCH',
    APRIL: 'APRIL',
    MAY: 'MAY',
    JUNE: 'JUNE',
    JULY: 'JULY',
    AUGUST: 'AUGUST',
    SEPTEMBER: 'SEPTEMBER',
    OCTOBER: 'OCTOBER',
    NOVEMBER: 'NOVEMBER',
    DECEMBER: 'DECEMBER',
} as const

export type CardSensitiveDetailsModelExpiration = {
    isLeapYear?: boolean
    month?: CardSensitiveDetailsModelExpirationMonth
    monthValue?: number
    year?: number
}

export interface CardSensitiveDetailsModel {
    encryptedCvv: string
    encryptedPan: string
    expiration: CardSensitiveDetailsModelExpiration
    id: string
}

export interface CardPinModel {
    pin: string
}

export type CardModelType = (typeof CardModelType)[keyof typeof CardModelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardModelType = {
    VIRTUAL: 'VIRTUAL',
    PHYSICAL: 'PHYSICAL',
} as const

export type CardModelStatus = (typeof CardModelStatus)[keyof typeof CardModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardModelStatus = {
    LIVE: 'LIVE',
    LOST: 'LOST',
    STOLEN: 'STOLEN',
    EXPIRED: 'EXPIRED',
    RESTRICTED: 'RESTRICTED',
    DEACTIVATED: 'DEACTIVATED',
    DESTROYED: 'DESTROYED',
} as const

export type CardModelProviderName =
    (typeof CardModelProviderName)[keyof typeof CardModelProviderName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardModelProviderName = {
    GPS: 'GPS',
    ENFUCE: 'ENFUCE',
    MARQETA: 'MARQETA',
} as const

export type CardModelFees = (typeof CardModelFees)[keyof typeof CardModelFees]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardModelFees = {
    STANDARD: 'STANDARD',
    NONE: 'NONE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
} as const

export type CardModelAtmFees = (typeof CardModelAtmFees)[keyof typeof CardModelAtmFees]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardModelAtmFees = {
    STANDARD: 'STANDARD',
    NONE: 'NONE',
    STARTER: 'STARTER',
    ESSENTIAL: 'ESSENTIAL',
    ADVANCED: 'ADVANCED',
} as const

export interface CardModel {
    atmFees?: CardModelAtmFees
    companyId: string
    currency: string
    embossLine3?: string
    embossLine4?: string
    expiration: string
    fees?: CardModelFees
    id: string
    /** Determines if the card is capable of being used with Apple Pay */
    isAppleWalletCapable: boolean
    /**
     * Determines if the card is capable of being used in a digital wallet (e.g. Apple Pay, Google Pay, etc.). Deprecated, use isAppleWalletCapable and isGoogleWalletCapable instead
     * @deprecated
     */
    isDigitalWalletCapable: boolean
    /** Determines if the card is capable of being used with Google Pay */
    isGoogleWalletCapable: boolean
    /** This can be one of IssuerModel values */
    issuer: string
    pan: string
    providerName: CardModelProviderName
    status: CardModelStatus
    type: CardModelType
    velocity?: string
}

export type CardCreationOrderDetailsModelManufacturer =
    (typeof CardCreationOrderDetailsModelManufacturer)[keyof typeof CardCreationOrderDetailsModelManufacturer]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardCreationOrderDetailsModelManufacturer = {
    MEGACARD: 'MEGACARD',
    IDEMIA: 'IDEMIA',
    NITECREST: 'NITECREST',
} as const

export type CardCreationOrderDetailsModelDeliveryType =
    (typeof CardCreationOrderDetailsModelDeliveryType)[keyof typeof CardCreationOrderDetailsModelDeliveryType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardCreationOrderDetailsModelDeliveryType = {
    CUSTOM_1: 'CUSTOM_1',
    CUSTOM_2: 'CUSTOM_2',
    CUSTOM_3: 'CUSTOM_3',
    CUSTOM_4: 'CUSTOM_4',
    CUSTOM_5: 'CUSTOM_5',
    MAIL: 'MAIL',
    COURIER: 'COURIER',
} as const

export type CardCreationOrderDetailsModelCountry =
    (typeof CardCreationOrderDetailsModelCountry)[keyof typeof CardCreationOrderDetailsModelCountry]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardCreationOrderDetailsModelCountry = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface CardCreationOrderDetailsModel {
    amount?: number
    country?: CardCreationOrderDetailsModelCountry
    deliveryType?: CardCreationOrderDetailsModelDeliveryType
    id: string
    isFirstProviderOrder?: boolean
    manufacturer?: CardCreationOrderDetailsModelManufacturer
}

export interface CancelInternalPaymentRequestModel {
    companyId: string
}

export type BalanceModelCreditLimitType =
    (typeof BalanceModelCreditLimitType)[keyof typeof BalanceModelCreditLimitType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BalanceModelCreditLimitType = {
    RESERVE: 'RESERVE',
    OVERDRAFT: 'OVERDRAFT',
} as const

export interface BalanceModel {
    available: number
    creditLimit: number
    creditLimitType?: BalanceModelCreditLimitType
    current: number
}

export interface BalanceAdjustmentSyncRequestModel {
    delayBetweenBatchesInMillis?: number
    ids?: string[]
}

export interface BalanceAdjustmentRequestModel {
    amount: Money
    note: string
}

export interface AtmFeeModel {
    amount: Money
    type: string
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * @summary Retrieves the company's primary wallet
 */
export const getPrimaryWallet = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletModel>(
        { url: `/v3/companies/${companyId}/primary-wallet`, method: 'get' },
        options
    )
}

/**
 * @summary Retrieves the wallet details
 */
export const getWallet = (walletId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<WalletModel>({ url: `/v3/wallets/${walletId}`, method: 'get' }, options)
}

/**
 * @summary Publish company spend account events for selected wallets
 */
export const publishCompanySpendAccountEvents = (
    publishCompanySpendAccountEventsRequestModel: PublishCompanySpendAccountEventsRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v4/admin/balance-migration/publish/company-spend-account-events`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: publishCompanySpendAccountEventsRequestModel,
        },
        options
    )
}

/**
 * @summary Update the card details
 */
export const updateCard = (
    cardId: string,
    updateCardRequestAdminModel: UpdateCardRequestAdminModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel>(
        {
            url: `/v4/admin/cards/${cardId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCardRequestAdminModel,
        },
        options
    )
}

/**
 * @summary Gets the PAN of the card
 */
export const getCardPan = (
    cardId: string,
    getCardSensitiveDetailsRequestModel: GetCardSensitiveDetailsRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<EncryptedPanModel>(
        {
            url: `/v4/admin/cards/${cardId}/pan`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: getCardSensitiveDetailsRequestModel,
        },
        options
    )
}

/**
 * @summary Perform a balance adjustment. Positive (+) value = credit. Negative (-) value = debit. Please use the migrated endpoint in Prospero unless specific circumstances require otherwise. https://www.notion.so/pleo/Prospero-Balance-Adjustment-7c87830f1c9c4c2aaef3eb668f7bcc61
 */
export const performBalanceAdjustment = (
    companyId: string,
    balanceAdjustmentRequestModel: BalanceAdjustmentRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyBalanceModel>(
        {
            url: `/v4/admin/companies/${companyId}/balance-adjustments`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: balanceAdjustmentRequestModel,
        },
        options
    )
}

/**
 * @summary Change all of the company's active cards status to DESTROYED if not in a final state
 */
export const destroyActiveCards = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel[]>(
        { url: `/v4/admin/companies/${companyId}/cards/destroy-all-active`, method: 'post' },
        options
    )
}

/**
 * @summary Sync company cards from card processor into our DB
 */
export const syncEnfuce = (
    companyId: string,
    syncRequestModel: SyncRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v4/admin/companies/${companyId}/cards/sync`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: syncRequestModel,
        },
        options
    )
}

/**
 * @summary Update a company's active cards so that FX fees apply
 */
export const applyFxToCompanyLegacy = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel[]>(
        { url: `/v4/admin/companies/${companyId}/fx`, method: 'post' },
        options
    )
}

/**
 * @summary Update a company's active cards so that FX fees do not apply
 */
export const applyNoFxToCompanyLegacy = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel[]>(
        { url: `/v4/admin/companies/${companyId}/no-fx`, method: 'post' },
        options
    )
}

/**
 * @summary Retrieve the spending status of a company
 */
export const getCompanyStatus = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyStatusResponseModel>(
        { url: `/v4/admin/companies/${companyId}/status`, method: 'get' },
        options
    )
}

/**
 * @summary Update the status of a company to block/unblock all spending
 */
export const updateCompanyStatus = (
    companyId: string,
    updateCompanyStatusRequestModel: UpdateCompanyStatusRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyStatusResponseModel>(
        {
            url: `/v4/admin/companies/${companyId}/status`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCompanyStatusRequestModel,
        },
        options
    )
}

/**
 * @summary Get the wallets of the company. This endpoint will always return a list composed with the primary wallet. It was designed when we had multiple primary wallets per company.
 */
export const getWallets = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<WalletModel[]>(
        { url: `/v4/admin/companies/${companyId}/wallets`, method: 'get' },
        options
    )
}

/**
 * @summary Process Dangling Company Update Events
 */
export const processCompanyUpdateKafkaSqsDlq = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<void>({ url: `/v4/admin/kafka-dlq/styx-company`, method: 'post' }, options)
}

/**
 * @summary Sync balance adjustments from Phobos database to Kafka
 */
export const syncBalanceAdjustments = (
    balanceAdjustmentSyncRequestModel: BalanceAdjustmentSyncRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v4/admin/sync/balance-adjustments`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: balanceAdjustmentSyncRequestModel,
        },
        options
    )
}

/**
 * @summary Sync cards from Phobos database to Kafka
 */
export const syncCards = (
    cardSyncRequestModel: CardSyncRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v4/admin/sync/cards`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: cardSyncRequestModel,
        },
        options
    )
}

/**
 * @summary Sync chargebacks from Phobos database to Kafka
 */
export const syncChargebacks = (
    chargebackSyncRequestModel: ChargebackSyncRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v4/admin/sync/chargebacks`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: chargebackSyncRequestModel,
        },
        options
    )
}

/**
 * @summary Sync missing wallets from enfuce to Phobos. Used when wallets partially created. When wallets are missing in Phobos - we try to recreate them from client.
 */
export const syncEnfuceWallets = (
    companyId: string,
    syncRequestModel: SyncRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletModel[]>(
        {
            url: `/v4/admin/sync/enfuce/${companyId}/wallets`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: syncRequestModel,
        },
        options
    )
}

/**
 * @summary Sync internal payments from Phobos database to Kafka
 */
export const syncInternalPayments = (
    internalPaymentSyncRequestModel: InternalPaymentSyncRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v4/admin/sync/internal-payments`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: internalPaymentSyncRequestModel,
        },
        options
    )
}

/**
 * @summary Sync wallets from Phobos database to Kafka
 */
export const syncWallets = (
    walletSyncRequestModel: WalletSyncRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v4/admin/sync/wallets`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: walletSyncRequestModel,
        },
        options
    )
}

/**
 * @summary Perform a chargeback for the original transaction (will get the status SETTLED)
 */
export const performChargeBack = (
    transactionId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ChargebackResponseModel>(
        { url: `/v4/admin/transactions/${transactionId}/chargeback`, method: 'post' },
        options
    )
}

/**
 * @summary Undo a chargeback for the original transaction
 */
export const undoChargeBack = (
    transactionId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ChargebackResponseModel>(
        { url: `/v4/admin/transactions/${transactionId}/chargeback/undo`, method: 'post' },
        options
    )
}

/**
 * @summary Perform a refund for the original transaction (like a chargeback but with status CLEARED)
 */
export const performRefund = (
    transactionId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ChargebackResponseModel>(
        { url: `/v4/admin/transactions/${transactionId}/pleo-refund`, method: 'post' },
        options
    )
}

/**
 * @summary Clears the company's balance locks. Only use this if they're stuck after restarting pods
 */
export const clearCompanyBalanceLocks = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        { url: `/v4/admin/${companyId}/balance-locks/clear`, method: 'post' },
        options
    )
}

/**
 * @summary Returns the company balance from Enfuce and Phobos
 */
export const getEnfuceBalance = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<EnfuceAndPhobosBalanceModel>(
        { url: `/v4/admin/${companyId}/enfuce-and-phobos-balance`, method: 'get' },
        options
    )
}

/**
 * @summary fetch limits on cards
 */
export const fetchUsageLimitsForCards = (
    params: FetchUsageLimitsForCardsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardUsageLimitResponseModel[]>(
        { url: `/v4/cards/usage-limits`, method: 'get', params },
        options
    )
}

/**
 * @summary Deletes the specified card
 */
export const deleteCard = (cardId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<void>({ url: `/v4/cards/${cardId}`, method: 'delete' }, options)
}

/**
 * @summary Retrieves the card details
 */
export const getCard = (cardId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CardModel>({ url: `/v4/cards/${cardId}`, method: 'get' }, options)
}

/**
 * @summary Update the card details
 */
export const updateCard1 = (
    cardId: string,
    updateCardRequestModel: UpdateCardRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel>(
        {
            url: `/v4/cards/${cardId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCardRequestModel,
        },
        options
    )
}

/**
 * @summary Retrieves the encrypted card details (PAN, CVV) in RSA-OAEP using SHA-256 and MGF1 with SHA-256 padding, along with the expiration date
 */
export const getCardDetails = (
    cardId: string,
    getCardSensitiveDetailsRequestModel: GetCardSensitiveDetailsRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardSensitiveDetailsModel>(
        {
            url: `/v4/cards/${cardId}/details`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: getCardSensitiveDetailsRequestModel,
        },
        options
    )
}

/**
 * @summary Will create activation data according to TAV format version 2 with encrypted card data to be sent to the digital payment provider and then onwards to mastercard. Called when adding a card through the digital payment provider flow. Card must be LIVE. This endpoint is only for Enfuce cards. Trying to call this endpoint for Marqeta cards result in a 400 error.
 */
export const getActivationData = (cardId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DigitalPaymentsActivationDataResponseModel>(
        { url: `/v4/cards/${cardId}/digital-payment/activation-data`, method: 'get' },
        options
    )
}

/**
 * @summary Generate the needed data to be sent to Apple when adding a card to Apple Pay. Called when adding a card to the Apple Pay wallet through the Pleo app flow. Card must be LIVE.
 */
export const provisionApplePay = (
    cardId: string,
    provisionApplePayRequestModel: ProvisionApplePayRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProvisionApplePayResponseModel>(
        {
            url: `/v4/cards/${cardId}/digital-payment/provision/apple-pay`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: provisionApplePayRequestModel,
        },
        options
    )
}

/**
 * @summary Generate the needed data to be sent to Google when adding a card to Google Pay. Called when adding a card to the Google Pay wallet through the Pleo app flow. Card must be LIVE.
 */
export const provisionGooglePay = (
    cardId: string,
    provisionGooglePayRequestModel: ProvisionGooglePayRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ProvisionGooglePayResponseModel>(
        {
            url: `/v4/cards/${cardId}/digital-payment/provision/google-pay`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: provisionGooglePayRequestModel,
        },
        options
    )
}

/**
 * @summary Will return list of digital tokens for a given card. Card must be LIVE.
 */
export const getDigitalWalletTokens = (
    cardId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DigitalWalletTokenModel[]>(
        { url: `/v4/cards/${cardId}/digital-payment/tokens`, method: 'get' },
        options
    )
}

/**
 * @summary Charge ATM fee for the transaction. Positive (+) value = credit. Negative (-) value = debit.
 */
export const chargeAtmFee = (
    cardId: string,
    chargeAtmFeeRequestModelV2: ChargeAtmFeeRequestModelV2,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ChargeAtmFeeResultModel>(
        {
            url: `/v4/cards/${cardId}/fees/atm/charge`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: chargeAtmFeeRequestModelV2,
        },
        options
    )
}

/**
 * @summary Charge FX fee for the transaction. Positive (+) value = credit. Negative (-) value = debit.
 */
export const chargeFxFees = (
    cardId: string,
    chargeFxFeeRequestModel: ChargeFxFeeRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ChargeFxFeeResultModel>(
        {
            url: `/v4/cards/${cardId}/fees/fx`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: chargeFxFeeRequestModel,
        },
        options
    )
}

/**
 * @summary Retrieves the PIN of the card
 */
export const getPin = (cardId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CardPinModel>({ url: `/v4/cards/${cardId}/pin`, method: 'get' }, options)
}

/**
 * @summary Unblocks the PIN of the card
 */
export const unblockPin = (cardId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<void>({ url: `/v4/cards/${cardId}/pin/tries`, method: 'delete' }, options)
}

/**
 * @summary Add usage limit to card. Card must be LIVE or RESTRICTED
 */
export const addUsageLimitToCard = (
    cardId: string,
    cardUsageLimitRequestModel: CardUsageLimitRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardUsageLimitResponseModel>(
        {
            url: `/v4/cards/${cardId}/usage-limit`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: cardUsageLimitRequestModel,
        },
        options
    )
}

/**
 * @summary Reset usage limit on card to default. Returns the deactivated limits. Card must be LIVE or RESTRICTED
 */
export const resetUsageLimitOnCard = (
    cardId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardUsageLimitResponseModel[]>(
        { url: `/v4/cards/${cardId}/usage-limit/reset`, method: 'delete' },
        options
    )
}

/**
 * @summary Retrieves the company resource
 */
export const getCompany = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CompanyModel>({ url: `/v4/companies/${companyId}`, method: 'get' }, options)
}

/**
 * @summary Creates the company resource. The endpoint is idempotent. Multiple calls with the same companyId will not create multiple companies: the first call will create the company, and subsequent calls will return the same company.
 */
export const createCompany = (
    companyId: string,
    createCompanyRequestModel: CreateCompanyRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyModel>(
        {
            url: `/v4/companies/${companyId}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCompanyRequestModel,
        },
        options
    )
}

/**
 * @summary Updates the company resource
 */
export const updateCompany = (
    companyId: string,
    updateCompanyRequestModel: UpdateCompanyRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyModel>(
        {
            url: `/v4/companies/${companyId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCompanyRequestModel,
        },
        options
    )
}

/**
 * @summary Update ATM fees on company cards
 */
export const updateCompanyAtmCardFees = (
    companyId: string,
    updateCompanyCardAtmFeesModel: UpdateCompanyCardAtmFeesModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel[]>(
        {
            url: `/v4/companies/${companyId}/atm-fees`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCompanyCardAtmFeesModel,
        },
        options
    )
}

/**
 * @summary Retrieves the company balance
 */
export const getBalance = (companyId: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<CompanyBalanceModel>(
        { url: `/v4/companies/${companyId}/balance`, method: 'get' },
        options
    )
}

/**
 * @summary Lists all the cards of the company using the provided filters. If no filters are provided, all the cards for the company are returned.
 */
export const getCards = (
    companyId: string,
    params: GetCardsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel[]>(
        { url: `/v4/companies/${companyId}/cards`, method: 'get', params },
        options
    )
}

/**
 * @summary Creates a new card. For PHYSICAL cards, embossLine3 and embossLine4 are both required.
 */
export const createCard = (
    companyId: string,
    createCardRequestModel: CreateCardRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel>(
        {
            url: `/v4/companies/${companyId}/cards`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCardRequestModel,
        },
        options
    )
}

/**
 * @summary Creates a new card without binding it to a user. For PHYSICAL cards, embossLine3 and embossLine4 are both required. This is a temporary endpoint to help with the migration to remove the responsibility of granting card permissions in Phobos.
 */
export const createCardWithoutBinding = (
    companyId: string,
    createCardRequestModel: CreateCardRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel>(
        {
            url: `/v4/companies/${companyId}/create-cards-without-binding`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCardRequestModel,
        },
        options
    )
}

/**
 * @summary Updates credit limit for a company
 */
export const updateCompanyCreditLimit = (
    companyId: string,
    updateCreditLimitRequestModel: UpdateCreditLimitRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CompanyBalanceModel>(
        {
            url: `/v4/companies/${companyId}/credit-limits`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCreditLimitRequestModel,
        },
        options
    )
}

/**
 * @summary Update fees on company cards
 */
export const updateCompanyCardFees = (
    companyId: string,
    updateCompanyCardFeesModel: UpdateCompanyCardFeesModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardModel[]>(
        {
            url: `/v4/companies/${companyId}/fees`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCompanyCardFeesModel,
        },
        options
    )
}

/**
 * @summary Fetch company's transactions from Enfuce
 */
export const fetchEnfuceTransactions = (
    companyId: string,
    params: FetchEnfuceTransactionsParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<TransactionResponseModel[]>(
        { url: `/v4/companies/${companyId}/transactions`, method: 'get', params },
        options
    )
}

/**
 * @summary Only to be used with permission from FinCore! Updates the ownership of the companies balance. Possible values are newOwner=ENFUCE or newOwner=ERSA.
 */
export const changeBalanceOwnership = (
    companyId: string,
    params: ChangeBalanceOwnershipParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateOrUpdateResponse>(
        {
            url: `/v4/enfuce/companies/${companyId}/change-balance-ownership`,
            method: 'patch',
            params,
        },
        options
    )
}

/**
 * @summary Fetch the transactions directly from Enfuce in their original format (accountId = providerId of the wallet). This is an admin endpoint used for debugging of Enfuce transactions.
 */
export const fetchEnfuceTransactions1 = (
    accountId: string,
    params: FetchEnfuceTransactions1Params,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<TransactionResponse[]>(
        { url: `/v4/enfuce/transactions/account/${accountId}`, method: 'get', params },
        options
    )
}

/**
 * @summary Echoes the status code provided as a query parameter
 */
export const echoStatus = (
    params: EchoStatusParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>({ url: `/v4/faulty-resource/echo`, method: 'get', params }, options)
}

/**
 * @summary Simulates a failure with 503 status code
 */
export const fail = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>({ url: `/v4/faulty-resource/fail`, method: 'get' }, options)
}

/**
 * @summary Simulates a success
 */
export const success = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>({ url: `/v4/faulty-resource/success`, method: 'get' }, options)
}

/**
 * @summary Fetch fees by transaction ids
 */
export const fetchTransactionFees = (
    fetchTransactionFeesRequestModel: FetchTransactionFeesRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<ChargedFeeResponseModel[]>(
        {
            url: `/v4/fees`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: fetchTransactionFeesRequestModel,
        },
        options
    )
}

/**
 * @summary Retrieves an internal payment by type and reference
 */
export const get = (
    type:
        | 'balance-amendment'
        | 'bill-invoice'
        | 'cashback'
        | 'load-v2'
        | 'personal-transfer'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'pocket-bank-transfer-reimbursement'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'overdraft-fee'
        | 'overdraft-interest',
    reference: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<InternalPaymentModel>(
        { url: `/v4/internal-payments/${type}/${reference}`, method: 'get' },
        options
    )
}

/**
 * @summary Authorize Internal Payment with sufficient fund check
 */
export const authorize = (
    type:
        | 'balance-amendment'
        | 'bill-invoice'
        | 'cashback'
        | 'load-v2'
        | 'personal-transfer'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'pocket-bank-transfer-reimbursement'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'overdraft-fee'
        | 'overdraft-interest',
    reference: string,
    updateInternalPaymentRequestModel: UpdateInternalPaymentRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<InternalPaymentModel>(
        {
            url: `/v4/internal-payments/${type}/${reference}/authorize`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateInternalPaymentRequestModel,
        },
        options
    )
}

/**
 * @summary Cancel Internal Payment
 */
export const cancel = (
    type:
        | 'balance-amendment'
        | 'bill-invoice'
        | 'cashback'
        | 'load-v2'
        | 'personal-transfer'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'pocket-bank-transfer-reimbursement'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'overdraft-fee'
        | 'overdraft-interest',
    reference: string,
    cancelInternalPaymentRequestModel: CancelInternalPaymentRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<InternalPaymentModel>(
        {
            url: `/v4/internal-payments/${type}/${reference}/cancel`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: cancelInternalPaymentRequestModel,
        },
        options
    )
}

/**
 * @summary Force Authorize Internal Payment without sufficient fund check
 */
export const forceAuthorize = (
    type:
        | 'balance-amendment'
        | 'bill-invoice'
        | 'cashback'
        | 'load-v2'
        | 'personal-transfer'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'pocket-bank-transfer-reimbursement'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'overdraft-fee'
        | 'overdraft-interest',
    reference: string,
    updateInternalPaymentRequestModel: UpdateInternalPaymentRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<InternalPaymentModel>(
        {
            url: `/v4/internal-payments/${type}/${reference}/force-authorize`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateInternalPaymentRequestModel,
        },
        options
    )
}

/**
 * @summary Settle Internal Payment without sufficient fund check
 */
export const settle = (
    type:
        | 'balance-amendment'
        | 'bill-invoice'
        | 'cashback'
        | 'load-v2'
        | 'personal-transfer'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'pocket-bank-transfer-reimbursement'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'overdraft-fee'
        | 'overdraft-interest',
    reference: string,
    updateInternalPaymentRequestModel: UpdateInternalPaymentRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<InternalPaymentModel>(
        {
            url: `/v4/internal-payments/${type}/${reference}/settle`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateInternalPaymentRequestModel,
        },
        options
    )
}

/**
 * @summary Settle Internal Payment with Authorization and sufficient fund check
 */
export const settleWithAuthorization = (
    type:
        | 'balance-amendment'
        | 'bill-invoice'
        | 'cashback'
        | 'load-v2'
        | 'personal-transfer'
        | 'pleo-invoice'
        | 'pleo-invoice-refund'
        | 'pocket-bank-transfer-reimbursement'
        | 'unload-v2'
        | 'wallet-withdrawal'
        | 'overdraft-fee'
        | 'overdraft-interest',
    reference: string,
    updateInternalPaymentRequestModel: UpdateInternalPaymentRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<InternalPaymentModel>(
        {
            url: `/v4/internal-payments/${type}/${reference}/settle-with-authorization`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateInternalPaymentRequestModel,
        },
        options
    )
}

export type GetPrimaryWalletResult = NonNullable<Awaited<ReturnType<typeof getPrimaryWallet>>>
export type GetWalletResult = NonNullable<Awaited<ReturnType<typeof getWallet>>>
export type PublishCompanySpendAccountEventsResult = NonNullable<
    Awaited<ReturnType<typeof publishCompanySpendAccountEvents>>
>
export type UpdateCardResult = NonNullable<Awaited<ReturnType<typeof updateCard>>>
export type GetCardPanResult = NonNullable<Awaited<ReturnType<typeof getCardPan>>>
export type PerformBalanceAdjustmentResult = NonNullable<
    Awaited<ReturnType<typeof performBalanceAdjustment>>
>
export type DestroyActiveCardsResult = NonNullable<Awaited<ReturnType<typeof destroyActiveCards>>>
export type SyncEnfuceResult = NonNullable<Awaited<ReturnType<typeof syncEnfuce>>>
export type ApplyFxToCompanyLegacyResult = NonNullable<
    Awaited<ReturnType<typeof applyFxToCompanyLegacy>>
>
export type ApplyNoFxToCompanyLegacyResult = NonNullable<
    Awaited<ReturnType<typeof applyNoFxToCompanyLegacy>>
>
export type GetCompanyStatusResult = NonNullable<Awaited<ReturnType<typeof getCompanyStatus>>>
export type UpdateCompanyStatusResult = NonNullable<Awaited<ReturnType<typeof updateCompanyStatus>>>
export type GetWalletsResult = NonNullable<Awaited<ReturnType<typeof getWallets>>>
export type ProcessCompanyUpdateKafkaSqsDlqResult = NonNullable<
    Awaited<ReturnType<typeof processCompanyUpdateKafkaSqsDlq>>
>
export type SyncBalanceAdjustmentsResult = NonNullable<
    Awaited<ReturnType<typeof syncBalanceAdjustments>>
>
export type SyncCardsResult = NonNullable<Awaited<ReturnType<typeof syncCards>>>
export type SyncChargebacksResult = NonNullable<Awaited<ReturnType<typeof syncChargebacks>>>
export type SyncEnfuceWalletsResult = NonNullable<Awaited<ReturnType<typeof syncEnfuceWallets>>>
export type SyncInternalPaymentsResult = NonNullable<
    Awaited<ReturnType<typeof syncInternalPayments>>
>
export type SyncWalletsResult = NonNullable<Awaited<ReturnType<typeof syncWallets>>>
export type PerformChargeBackResult = NonNullable<Awaited<ReturnType<typeof performChargeBack>>>
export type UndoChargeBackResult = NonNullable<Awaited<ReturnType<typeof undoChargeBack>>>
export type PerformRefundResult = NonNullable<Awaited<ReturnType<typeof performRefund>>>
export type ClearCompanyBalanceLocksResult = NonNullable<
    Awaited<ReturnType<typeof clearCompanyBalanceLocks>>
>
export type GetEnfuceBalanceResult = NonNullable<Awaited<ReturnType<typeof getEnfuceBalance>>>
export type FetchUsageLimitsForCardsResult = NonNullable<
    Awaited<ReturnType<typeof fetchUsageLimitsForCards>>
>
export type DeleteCardResult = NonNullable<Awaited<ReturnType<typeof deleteCard>>>
export type GetCardResult = NonNullable<Awaited<ReturnType<typeof getCard>>>
export type UpdateCard1Result = NonNullable<Awaited<ReturnType<typeof updateCard1>>>
export type GetCardDetailsResult = NonNullable<Awaited<ReturnType<typeof getCardDetails>>>
export type GetActivationDataResult = NonNullable<Awaited<ReturnType<typeof getActivationData>>>
export type ProvisionApplePayResult = NonNullable<Awaited<ReturnType<typeof provisionApplePay>>>
export type ProvisionGooglePayResult = NonNullable<Awaited<ReturnType<typeof provisionGooglePay>>>
export type GetDigitalWalletTokensResult = NonNullable<
    Awaited<ReturnType<typeof getDigitalWalletTokens>>
>
export type ChargeAtmFeeResult = NonNullable<Awaited<ReturnType<typeof chargeAtmFee>>>
export type ChargeFxFeesResult = NonNullable<Awaited<ReturnType<typeof chargeFxFees>>>
export type GetPinResult = NonNullable<Awaited<ReturnType<typeof getPin>>>
export type UnblockPinResult = NonNullable<Awaited<ReturnType<typeof unblockPin>>>
export type AddUsageLimitToCardResult = NonNullable<Awaited<ReturnType<typeof addUsageLimitToCard>>>
export type ResetUsageLimitOnCardResult = NonNullable<
    Awaited<ReturnType<typeof resetUsageLimitOnCard>>
>
export type GetCompanyResult = NonNullable<Awaited<ReturnType<typeof getCompany>>>
export type CreateCompanyResult = NonNullable<Awaited<ReturnType<typeof createCompany>>>
export type UpdateCompanyResult = NonNullable<Awaited<ReturnType<typeof updateCompany>>>
export type UpdateCompanyAtmCardFeesResult = NonNullable<
    Awaited<ReturnType<typeof updateCompanyAtmCardFees>>
>
export type GetBalanceResult = NonNullable<Awaited<ReturnType<typeof getBalance>>>
export type GetCardsResult = NonNullable<Awaited<ReturnType<typeof getCards>>>
export type CreateCardResult = NonNullable<Awaited<ReturnType<typeof createCard>>>
export type CreateCardWithoutBindingResult = NonNullable<
    Awaited<ReturnType<typeof createCardWithoutBinding>>
>
export type UpdateCompanyCreditLimitResult = NonNullable<
    Awaited<ReturnType<typeof updateCompanyCreditLimit>>
>
export type UpdateCompanyCardFeesResult = NonNullable<
    Awaited<ReturnType<typeof updateCompanyCardFees>>
>
export type FetchEnfuceTransactionsResult = NonNullable<
    Awaited<ReturnType<typeof fetchEnfuceTransactions>>
>
export type ChangeBalanceOwnershipResult = NonNullable<
    Awaited<ReturnType<typeof changeBalanceOwnership>>
>
export type FetchEnfuceTransactions1Result = NonNullable<
    Awaited<ReturnType<typeof fetchEnfuceTransactions1>>
>
export type EchoStatusResult = NonNullable<Awaited<ReturnType<typeof echoStatus>>>
export type FailResult = NonNullable<Awaited<ReturnType<typeof fail>>>
export type SuccessResult = NonNullable<Awaited<ReturnType<typeof success>>>
export type FetchTransactionFeesResult = NonNullable<
    Awaited<ReturnType<typeof fetchTransactionFees>>
>
export type GetResult = NonNullable<Awaited<ReturnType<typeof get>>>
export type AuthorizeResult = NonNullable<Awaited<ReturnType<typeof authorize>>>
export type CancelResult = NonNullable<Awaited<ReturnType<typeof cancel>>>
export type ForceAuthorizeResult = NonNullable<Awaited<ReturnType<typeof forceAuthorize>>>
export type SettleResult = NonNullable<Awaited<ReturnType<typeof settle>>>
export type SettleWithAuthorizationResult = NonNullable<
    Awaited<ReturnType<typeof settleWithAuthorization>>
>
