import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { Modal, Button } from 'antd'
import type { VerificationResult } from 'types/styx'

interface Props {
    label: string
    result: VerificationResult
    falsePositive: boolean
}

const HandleVerification: FC<React.PropsWithChildren<Props>> = ({
    children,
    label,
    result,
    falsePositive,
}) => {
    const [modalActive, setModalActive] = useState(false)
    return (
        <>
            <VerificationButton
                data-testid="kyc-button"
                result={result}
                falsePositive={falsePositive}
                onClick={() => setModalActive(true)}
            >
                {label}: {result} {falsePositive ? ' (False positive)' : ''}
            </VerificationButton>
            <Modal
                data-testid="modal"
                title={`${label}`}
                footer={null}
                centered
                open={modalActive}
                onCancel={() => setModalActive(false)}
                width={600}
            >
                {children}
            </Modal>
        </>
    )
}

interface VerificationButtonProps {
    readonly result: VerificationResult
    readonly falsePositive: boolean
}

enum StatusColor {
    NOT_EXECUTED = 'blue',
    PASS = 'green',
    PENDING = 'volcano',
    FAIL = 'red',
}

export const VerificationButton = styled(Button)<VerificationButtonProps>`
    width: auto;
    ${({ theme: { colorPalette }, result, falsePositive }) => {
        const color = falsePositive
            ? colorPalette[StatusColor.PASS]
            : colorPalette[StatusColor[result]]

        return css`
            color: ${color?.[5]};
            border-color: ${color?.[5]};
            background: ${color?.[0]};
            &:hover,
            &:focus {
                color: ${color?.[3]};
                border-color: ${color?.[3]};
                background: ${color?.[0]};
            }
        `
    }}
`

export default HandleVerification
