import type { FC } from 'react'
import type { ColumnProps } from 'antd/lib/table'
import dayjs from 'packages/dayjs'
import { Table, Typography, Result } from 'antd'
import { DownloadInvoice } from './components/invoice-download'
import { formatMoneyIntl } from 'utils/money'

import type { Invoice } from 'bff/moons/generated/beyond'
import { Team } from 'utils/teams'

const { Text } = Typography

interface InvoiceTableProps {
    invoices?: Invoice[]
    error?: Error
    loading: boolean
}

const InvoiceTable: FC<React.PropsWithChildren<InvoiceTableProps>> = ({
    invoices,
    error,
    loading,
}) => {
    const columns: ColumnProps<Invoice>[] = [
        {
            title: 'Date issued',
            dataIndex: 'createdDate',
            render: (createdDate) => <>{dayjs(createdDate).format('ll')}</>,
            sorter: (a, b) => dayjs(a.createdDate).valueOf() - dayjs(b.createdDate).valueOf(),
            width: '10em',
        },
        {
            title: 'Due date',
            dataIndex: 'dueDate',
            render: (dueDate) => <>{dayjs(dueDate).format('ll')}</>,
            sorter: (a, b) => dayjs(a.dueDate).valueOf() - dayjs(b.dueDate).valueOf(),
            width: '10em',
        },
        {
            title: 'Number',
            dataIndex: 'number',
            render: (number: string) => (
                <Text code copyable>
                    {number}
                </Text>
            ),
        },
        {
            title: 'Summary',
            dataIndex: 'status',
        },
        {
            title: 'Amount',
            dataIndex: 'amounts',
            render: (amounts) => <>{formatMoneyIntl(amounts.total, { isMinorUnits: true })}</>,
        },
        {
            title: 'Download PDF',
            dataIndex: 'id',
            render: (id) => <DownloadInvoice id={id} />,
            width: '10em',
        },
    ]

    if (error) {
        return (
            <Result
                status="error"
                title="Failed to load content"
                subTitle={`If this issue persists, contact the ${Team.PurchaseExperience} team`}
            />
        )
    }

    return (
        <Table
            rowKey="id"
            dataSource={invoices}
            columns={columns}
            loading={loading}
            data-testid="invoices"
            size="middle"
            bordered
            pagination={false}
        />
    )
}

export default InvoiceTable
