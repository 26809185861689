import { useState } from 'react'

import { Button, Card, Modal, Popconfirm, Result, Skeleton, Tag, Tooltip, Upload } from 'antd'
import { Formik } from 'formik'
import { CheckCircleFilled, CloseCircleFilled, UploadOutlined } from '@ant-design/icons'
import { Input, Form, FormItem, Radio } from 'formik-antd'
import styled from 'styled-components'
import type { RcCustomRequestOptions } from 'types/custom-request'

import { useHasPermissions } from 'components/permission-guard/permission-guard'

import type {
    CustomerVulnerabilityResponse,
    DataResponseListCustomerVulnerabilityResponse,
} from 'bff/moons/generated/customer-vulnerability-tracker'

import { revokeValidationSchema, vulnerabilityValidationSchema } from './validationSchemas'

import { bff } from '../../bff'
import { Link } from 'react-router-dom'
import { uploadConsentForm } from 'services/customer-vulnerability-tracker/upload'

interface Props {
    userId: string
    setIsConsentModalShowing: (isConsentModalShowing: boolean) => void
    setIsVulnerabilityModalShowing: (isVulnerabilityModalShowing: boolean) => void
    setisRevokeConsentModalShowing: (isRevokeConsentModalShowing: boolean) => void
    hasWriteUkVulnerability: boolean
}

interface ContainerProps {
    userId: string
}

export const UserVulnerability = ({
    userId,
    setIsConsentModalShowing,
    setIsVulnerabilityModalShowing,
    setisRevokeConsentModalShowing,
    hasWriteUkVulnerability,
}: Props) => {
    const {
        data: userConsent,
        isLoading: userConsentIsLoading,
        isError: userConsentInError,
        error: userConsentError,
    } = bff.userVulnerability.getUserConsent.useQuery({
        userId,
    })
    const {
        data: userVulnerabilities,
        isLoading: userVulnerabilityIsLoading,
        isError: userVulnerabilityInError,
        error: userVulnerabilityError,
    } = bff.userVulnerability.getUserVulnerability.useQuery({
        userId,
    })
    const { data: vulnerabilityTypes } =
        bff.userVulnerability.getCompanyVulnerabilityTypes.useQuery()
    const { mutate: removeUserVulnerability } =
        bff.userVulnerability.removeVulnerability.useMutation()
    const hasConsent = userConsent?.data?.documentUrl
    const hasVulnerabilities = userVulnerabilities?.data && userVulnerabilities?.data?.length > 0

    const getEnrichedVulnerability = (
        unenrichedUserVulnerabilities: DataResponseListCustomerVulnerabilityResponse
    ) => {
        const enrichedVulnerabilities: any[] = []
        unenrichedUserVulnerabilities?.data?.map((vulnerability: CustomerVulnerabilityResponse) => {
            vulnerabilityTypes?.data?.find((vulnerabilityType) => {
                if (vulnerabilityType.id === vulnerability.vulnerabilityTypeId) {
                    enrichedVulnerabilities.push({
                        ...vulnerability,
                        label: vulnerabilityType.label,
                        description: vulnerabilityType.description,
                    })
                }
            })
        })
        return enrichedVulnerabilities
    }

    const enrichedUserVulnerabilities = userVulnerabilities
        ? getEnrichedVulnerability(userVulnerabilities)
        : []

    return (
        <Card type="inner" title="UK user vulnerability" bodyStyle={{ paddingTop: '32px' }}>
            {userConsentIsLoading ? (
                <Skeleton active />
            ) : userConsentInError ? (
                <Result
                    status="error"
                    title={userConsentError?.message}
                    subTitle="Unable to fetch consent"
                />
            ) : (
                <>
                    {hasConsent ? (
                        <>
                            <div>
                                <CheckCircleFilled style={{ color: 'green' }} /> Consent confirmed{' '}
                                <Link
                                    to={userConsent?.data?.documentUrl || ''}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Download here
                                </Link>
                            </div>
                            {hasWriteUkVulnerability && (
                                <Button
                                    style={{ marginTop: 5 }}
                                    onClick={() => setisRevokeConsentModalShowing(true)}
                                >
                                    Revoke Consent
                                </Button>
                            )}
                        </>
                    ) : (
                        <>
                            <div>No consent form found:</div>
                            {hasWriteUkVulnerability && (
                                <Button
                                    style={{ marginTop: 5 }}
                                    onClick={() => setIsConsentModalShowing(true)}
                                    type="primary"
                                >
                                    Upload consent
                                </Button>
                            )}
                        </>
                    )}
                </>
            )}
            <div style={{ marginTop: 10 }}>
                {userVulnerabilityIsLoading ? (
                    <Skeleton active />
                ) : userVulnerabilityInError ? (
                    <Result
                        status="error"
                        title={userVulnerabilityError?.message}
                        subTitle="Failed to fetch vulnerability status"
                    />
                ) : (
                    <>
                        <div style={{ marginTop: 5 }}>
                            {hasVulnerabilities ? (
                                <>
                                    Vulnerabilities:{' '}
                                    {enrichedUserVulnerabilities.map((userVulnerability) => (
                                        <Tooltip
                                            title={userVulnerability.description}
                                            key={userVulnerability.id}
                                        >
                                            <Tag
                                                closable={hasWriteUkVulnerability}
                                                onClose={(e) => {
                                                    e.preventDefault()
                                                }}
                                                closeIcon={
                                                    <Popconfirm
                                                        title={`Are you sure you want to remove ${userVulnerability.label} vulnerability?`}
                                                        onConfirm={() =>
                                                            removeUserVulnerability({
                                                                userId,
                                                                vulnerabilityId:
                                                                    userVulnerability.id,
                                                                deleteCustomerVulnerabilityRequest:
                                                                    {
                                                                        deletionReason: 'delete',
                                                                    },
                                                            })
                                                        }
                                                        okText="Yes"
                                                    >
                                                        <CloseCircleFilled />
                                                    </Popconfirm>
                                                }
                                            >
                                                {userVulnerability.label}
                                            </Tag>
                                        </Tooltip>
                                    ))}
                                </>
                            ) : (
                                <>No vulnerabilities</>
                            )}
                        </div>
                        {hasWriteUkVulnerability && (
                            <Button
                                style={{ marginTop: 5 }}
                                onClick={() => setIsVulnerabilityModalShowing(true)}
                                type="primary"
                            >
                                Add Vulnerability
                            </Button>
                        )}
                    </>
                )}
            </div>
        </Card>
    )
}

export const UserVulnerabilityContainer = ({ userId }: ContainerProps) => {
    const [isConsentModalShowing, setIsConsentModalShowing] = useState(false)
    const [isVulnerabilityModalShowing, setIsVulnerabilityModalShowing] = useState(false)
    const [isRevokeConsentModalShowing, setisRevokeConsentModalShowing] = useState(false)

    const { mutate: revokeConsent } = bff.userVulnerability.revokeConsent.useMutation()
    const { mutate: addUserVulnerability } =
        bff.userVulnerability.addUserVulnerability.useMutation()
    const { data: vulnerabilityTypes } =
        bff.userVulnerability.getCompanyVulnerabilityTypes.useQuery()

    const hasWriteUkVulnerability = useHasPermissions(['uk-vulnerability-write'])

    const handleSubmitVulnerability = (vulnerabilityTypeId: string, notes: string) => {
        addUserVulnerability({
            userId,
            createCustomerVulnerabilityRequest: { vulnerabilityTypeId, notes },
        })
    }
    const { refetch: refetchUserConsent } = bff.userVulnerability.getUserConsent.useQuery({
        userId,
    })

    const uploadConsentRequest = async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('request', JSON.stringify({ notes: 'Consent' }))

        try {
            const response = await uploadConsentForm(userId, formData).json()
            onSuccess && onSuccess({ response }, file as any)
            setIsConsentModalShowing(false)
        } catch (e) {
            onError && onError(e as Error)
        }
        await refetchUserConsent()
    }

    const handleRevokeConsent = (reason: string) => {
        revokeConsent({ userId, reason })
        setisRevokeConsentModalShowing(false)
    }

    return (
        <>
            <UserVulnerability
                userId={userId}
                setIsConsentModalShowing={setIsConsentModalShowing}
                setIsVulnerabilityModalShowing={setIsVulnerabilityModalShowing}
                setisRevokeConsentModalShowing={setisRevokeConsentModalShowing}
                hasWriteUkVulnerability={hasWriteUkVulnerability}
            />
            {hasWriteUkVulnerability && (
                <>
                    <Modal
                        title="Create consent"
                        open={isConsentModalShowing}
                        onCancel={() => setIsConsentModalShowing(false)}
                        footer={null}
                    >
                        <Upload
                            name="file"
                            accept=".pdf,.doc,.docx"
                            customRequest={uploadConsentRequest}
                        >
                            <Button>
                                <UploadOutlined /> Upload document
                            </Button>
                        </Upload>
                        <ButtonContainer>
                            <Button key="back" onClick={() => setIsConsentModalShowing(false)}>
                                Close
                            </Button>
                        </ButtonContainer>
                    </Modal>
                    <Modal
                        title="Create vulnerability"
                        open={isVulnerabilityModalShowing}
                        onCancel={() => setIsVulnerabilityModalShowing(false)}
                        footer={null}
                    >
                        <Formik
                            validationSchema={vulnerabilityValidationSchema}
                            validateOnChange
                            initialValues={{
                                vulnerabilityTypeId: '',
                                notes: '',
                            }}
                            onSubmit={({ vulnerabilityTypeId, notes }) =>
                                handleSubmitVulnerability(vulnerabilityTypeId, notes)
                            }
                        >
                            {({ isValid, isSubmitting }) => {
                                return (
                                    <Form>
                                        <div style={{ marginBottom: 5 }}>Vulnerability Type</div>
                                        <FormItem name="vulnerabilityTypeId">
                                            <Radio.Group name="vulnerabilityTypeId">
                                                {vulnerabilityTypes?.data?.map(
                                                    (vulnerabilityType) => (
                                                        <div
                                                            style={{ marginTop: 10 }}
                                                            key={vulnerabilityType.id}
                                                        >
                                                            <Radio
                                                                name="vulnerabilityTypeId"
                                                                value={vulnerabilityType.id}
                                                            />
                                                            <label style={{ marginRight: 5 }}>
                                                                {vulnerabilityType.label}
                                                            </label>
                                                        </div>
                                                    )
                                                )}
                                            </Radio.Group>
                                        </FormItem>
                                        <div style={{ marginBottom: 5 }}>Notes</div>
                                        <FormItem name="notes">
                                            <Input name="notes" />
                                        </FormItem>
                                        <ButtonContainer>
                                            <Button
                                                key="back"
                                                onClick={() =>
                                                    setIsVulnerabilityModalShowing(false)
                                                }
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                style={{ marginLeft: 10 }}
                                                htmlType="submit"
                                                type="primary"
                                                onClick={() =>
                                                    setIsVulnerabilityModalShowing(false)
                                                }
                                                disabled={!isValid || isSubmitting}
                                                loading={isSubmitting}
                                            >
                                                Submit
                                            </Button>
                                        </ButtonContainer>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Modal>
                    <Modal
                        title="Revoke consent"
                        open={isRevokeConsentModalShowing}
                        onCancel={() => setisRevokeConsentModalShowing(false)}
                        footer={null}
                    >
                        <Formik
                            validationSchema={revokeValidationSchema}
                            validateOnMount
                            validateOnChange
                            initialValues={{
                                reason: '',
                            }}
                            onSubmit={({ reason }) => handleRevokeConsent(reason)}
                        >
                            {({ isValid }) => {
                                return (
                                    <Form>
                                        <FormItem name="reason" label="Reason">
                                            <Input name="reason" />
                                        </FormItem>
                                        <ButtonContainer>
                                            <Button
                                                key="back"
                                                onClick={() =>
                                                    setisRevokeConsentModalShowing(false)
                                                }
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                disabled={!isValid}
                                            >
                                                Submit
                                            </Button>
                                        </ButtonContainer>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Modal>
                </>
            )}
        </>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default UserVulnerabilityContainer
