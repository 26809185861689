import type { FC } from 'react'
import { Formik } from 'formik'
import { Form, SubmitButton } from 'formik-antd'
import { message } from 'antd'

import { Input } from 'components/input/input'
import type { OwnershipMutations } from 'services/deimos/styx-company/ownership'

import { validationSchema, FormValues, mapValuesToRequest } from './validation-schema'
import * as s from './create-company-form.styles'

interface Props {
    onCreateCompany: OwnershipMutations['createCompany']
    rootCompanyId: string
}

const initialValues = {
    legalName: '',
    registryId: '',
    addressLine1: '',
    addressLine2: '',
    locality: '',
    region: '',
    postalCode: '',
    country: '',
}

const initialTouched = {
    legalName: true,
    registryId: true,
    country: true,
}

const CreateCompanyForm: FC<React.PropsWithChildren<Props>> = ({
    onCreateCompany,
    rootCompanyId,
}) => {
    const onSubmit = async (values: FormValues) => {
        try {
            await onCreateCompany(mapValuesToRequest(values, rootCompanyId))
        } catch (e) {
            message.error(`Failed to create company: ${(e as Error).message}`)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            initialTouched={initialTouched}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form layout="vertical">
                <s.InputGrid>
                    <label htmlFor="legalName">
                        Legal name{' '}
                        <Input name="legalName" placeholder="Legal name" id="legalName" />
                    </label>
                    <label htmlFor="registryId">
                        Registry id{' '}
                        <Input name="registryId" id="registryId" placeholder="Registry id" />
                    </label>
                    <label htmlFor="addressLine1">Address</label>
                    <s.SpanRowItem>
                        <Input name="addressLine1" id="addressLine1" placeholder="Address line 1" />
                    </s.SpanRowItem>
                    <s.SpanRowItem>
                        <Input name="addressLine2" placeholder="Address line 2" />
                    </s.SpanRowItem>
                    <Input name="locality" placeholder="Locality" />
                    <Input name="region" placeholder="Region" />
                    <Input name="postalCode" placeholder="Postal code" />
                    <Input name="country" placeholder="Country" />
                </s.InputGrid>
                <s.ButtonContainer>
                    <SubmitButton data-testid="submit" type="primary">
                        Save
                    </SubmitButton>
                </s.ButtonContainer>
            </Form>
        </Formik>
    )
}
export default CreateCompanyForm
