import type { FC, MouseEventHandler } from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { Button, Card, Descriptions, Modal, Typography, message } from 'antd'
import { RiskScore, ResultScore } from 'types/styx'
import { useRiskAssessment, useStyxCompany } from 'services/deimos/styx-company/styx-company'
import { getHandledErrorDetails } from './error-handling'
import type { RefreshCompanyStructureError } from 'types/refresh-company-structure'
import { useOwnershipGraph } from 'services/styx/ownership'
import { useManagement } from 'services/styx/management'

const { Text } = Typography

interface Props {
    currentRiskScore: RiskScore
    riskAssessmentResult?: ResultScore
    confirmRefresh: MouseEventHandler<HTMLElement>
    companyId: string
}

export const RefreshCompanyStructure: FC<React.PropsWithChildren<Props>> = ({
    currentRiskScore,
    riskAssessmentResult,
    confirmRefresh,
}) => {
    return (
        <Card title="Refresh Company Structure">
            <Container>
                <Descriptions column={1}>
                    <Descriptions.Item label="Current risk score">
                        <Text strong>{currentRiskScore}</Text>
                    </Descriptions.Item>
                    <Descriptions.Item label="Risk assessment result">
                        <Text strong>{riskAssessmentResult ?? 'Assessment incomplete'}</Text>
                    </Descriptions.Item>
                </Descriptions>
                <Button data-testid="refresh" onClick={confirmRefresh} type="primary">
                    Refresh
                </Button>
            </Container>
        </Card>
    )
}

const Container = styled.div`
    display: grid;
    row-gap: ${spacing.space24};
`

const RefreshCompanyStructureContainer: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    const { riskAssessment } = useRiskAssessment()
    const { mutate: revalidateManagement } = useManagement()
    const { mutate: revalidateOwnership } = useOwnershipGraph()
    const { mutations } = useStyxCompany()
    const currentRiskScore = riskAssessment?.score ?? RiskScore.NOT_ASSIGNED
    const riskAssessmentResult = riskAssessment?.result

    const onConfirmRefresh = async () => {
        try {
            await mutations.refreshStructure()

            /**
             * Ownership & Management need to be revalidated when company structure is refreshed
             * so any new directors or shareholders are appropriately visible to compliance
             */

            await revalidateManagement()
            await revalidateOwnership()
            message.success('Successfully refreshed company structure')
        } catch (e: any) {
            const errorDetails = await getHandledErrorDetails(e)

            if (errorDetails) {
                errorModal(errorDetails)
            } else {
                message.error(`Failed to refresh structure: ${(e as Error).message}`)
            }
        }
    }

    const errorModal = (handledErrorDetails?: RefreshCompanyStructureError) =>
        Modal.error({
            title: handledErrorDetails?.message ?? 'Failed to refresh structure',
            content:
                handledErrorDetails?.nextSteps.map((step, index) => (
                    <div key={index}>{step}</div>
                )) ?? 'Report error to team Atlas',
        })

    return (
        <RefreshCompanyStructure
            companyId={companyId}
            confirmRefresh={() =>
                Modal.confirm({
                    onOk: onConfirmRefresh,
                    centered: true,
                    title: 'Are you sure you want to refresh this company?',
                    content: (
                        <>
                            <p>Make sure to save all relevant information prior to refreshing.</p>
                            <br />
                            <p>
                                After refreshing the structure you won't be able to easily access
                                the previous information (documents, checks, date of births, etc.)
                            </p>
                            <br />
                            <p>This action is irreversible.</p>
                        </>
                    ),
                    okButtonProps: {
                        danger: true,
                    },
                    okText: 'Confirm refresh',
                })
            }
            currentRiskScore={currentRiskScore}
            riskAssessmentResult={riskAssessmentResult}
        />
    )
}

export default RefreshCompanyStructureContainer
