import React, { useEffect, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'packages/dayjs'
import type { ColumnProps } from 'antd/lib/table'
import { Card, Table, Input, Typography } from 'antd'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import type { FilterValue, SorterResult } from 'antd/lib/table/interface'

import type { Supplier, SupplierInvoice } from 'types/deimos-supplier'
import * as supplierSelectors from 'store/modules/suppliers/selectors'
import { actions as supplierActions } from 'store/modules/suppliers/slice'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import type { CursorPaginationCommand } from 'types/cursor-pagination'
import { DownloadInvoiceButton } from '../../../components/supplier/download-invoice'
import { serializeSorting } from 'services/deimos/get-cursor-paginated-resource'
import { ContentContainer, PageContentLayout } from 'components/layout-containers'
import { inputWidth } from 'theme/tokens'
import { withTeamErrorHandler } from 'providers/error-handler'
import { Team } from 'utils/teams'
import { PageOrder } from '@pleo-io/deimos'
import { SupplierStatus } from '../../../types/deimos-supplier'

const { Text } = Typography

type OnChangeHandler = (
    filter: Record<string, FilterValue | null>,
    sorter: SorterResult<Supplier>
) => void
interface Props {
    dataSource: Supplier[]
    fetching: boolean
    onChange: OnChangeHandler
    order: 'ascend' | 'descend'
    supplierStatusFilter: string[]
}

export const Suppliers: FC<React.PropsWithChildren<Props>> = ({
    dataSource,
    fetching,
    onChange,
    children,
    order,
    supplierStatusFilter,
}) => {
    const columns: ColumnProps<Supplier>[] = [
        {
            title: 'Supplier',
            dataIndex: 'name',
            render: (name, record) => (
                <Text copyable={{ text: name }}>
                    <Link to={`/compliance/suppliers/${record.id}`}>{name ?? 'UNKNOWN'}</Link>
                </Text>
            ),
        },
        {
            title: 'Pleo customer',
            dataIndex: 'firstInvoice',
            render: (firstInvoice: SupplierInvoice) =>
                firstInvoice?.companyId ? (
                    <Link to={`/compliance/companies/${firstInvoice.companyId}`}>
                        {firstInvoice.companyName ?? firstInvoice.companyId.slice(0, 7)}
                    </Link>
                ) : (
                    'Unknown'
                ),
        },
        {
            title: 'Supplier status',
            dataIndex: 'status',
            render: (supplierStatus) => supplierStatus,
            filterMultiple: false,
            filteredValue: supplierStatusFilter,
            filters: Object.values(SupplierStatus).map((state) => ({
                text: state,
                value: state,
            })),
        },
        {
            title: 'Invoice',
            dataIndex: 'firstInvoice',
            onCell: (_) => ({ onClick: (e) => e.stopPropagation() }),
            render: (firstInvoice) =>
                firstInvoice && <DownloadInvoiceButton invoice={firstInvoice} />,
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (createdAt) => dayjs(createdAt).format('lll'),
            sorter: true,
            sortOrder: order,
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
    ]

    return (
        <Card>
            <ContentContainer>
                <Table
                    data-testid="table"
                    dataSource={dataSource}
                    columns={columns}
                    loading={fetching}
                    rowKey="id"
                    pagination={false}
                    onChange={(_, filters, sorter) => {
                        onChange(filters, Array.isArray(sorter) ? sorter[0] : sorter)
                    }}
                />
                {children}
            </ContentContainer>
        </Card>
    )
}

const SuppliersContainer = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const initialParams = new URLSearchParams(location.search)
    const [searchTerm, setSearchTerm] = React.useState(initialParams.get('name') ?? '')
    const suppliers = useSelector(supplierSelectors.selectSuppliers)
    const status = useSelector(supplierSelectors.selectSuppliersStatus)
    const pagination = useSelector(supplierSelectors.selectSuppliersPagination)
    const requestParams = useSelector(supplierSelectors.selectSuppliersRequestParams)

    const fetchSuppliers = React.useCallback(
        (command?: CursorPaginationCommand) => {
            dispatch(supplierActions.fetchSuppliers(command))
        },
        [dispatch]
    )

    const onChange: OnChangeHandler = (filters, sorter) => {
        fetchSuppliers({
            filters: filters,
            sorting: [{ id: sorter.order === 'ascend' ? PageOrder.ASC : PageOrder.DESC }],
        })
    }

    // reset to default state when it's the first fetch, or when the location
    // changes to one without any query params (i.e. clicking on the link in menu)
    useEffect(() => {
        if (!pagination || !location.search) {
            fetchSuppliers()
        }
    }, [fetchSuppliers, pagination, location.search])

    useEffect(() => {
        fetchSuppliers({ filters: { name: [searchTerm] } })
    }, [fetchSuppliers, searchTerm])

    const queryParams = new URLSearchParams({
        limit: requestParams.limit?.toString() ?? '',
        sorting: serializeSorting(requestParams.sorting ?? []),
        status: requestParams.parameters?.status?.join(',') ?? '',
        ...(searchTerm ? { name: searchTerm } : {}),
        ...(requestParams.after ? { after: requestParams.after } : {}),
        ...(requestParams.before ? { before: requestParams.before } : {}),
    }).toString()

    const path = `${location.pathname}?${queryParams}`

    useEffect(() => {
        navigate(path)
    }, [navigate, path])

    return (
        <PageContentLayout>
            <Card>
                <Input
                    autoFocus
                    allowClear
                    value={searchTerm}
                    placeholder="Filter by supplier name..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: `${inputWidth.huge}` }}
                />
            </Card>
            <Suppliers
                dataSource={suppliers}
                fetching={status === 'fetching'}
                onChange={onChange}
                order={
                    requestParams.sorting?.find((sort) => !!sort.id)?.id === 'ASC'
                        ? 'ascend'
                        : 'descend'
                }
                supplierStatusFilter={requestParams.parameters?.status ?? []}
            >
                {pagination && (
                    <CursorPaginationControls
                        pagination={pagination}
                        limit={requestParams.limit}
                        onChangeLimit={(limit) => fetchSuppliers({ limit })}
                        isFetching={status === 'fetching'}
                        onNextPage={() => fetchSuppliers({ page: 'next' })}
                        onPrevPage={() => fetchSuppliers({ page: 'prev' })}
                        onFirstPage={() => fetchSuppliers({ page: 'first' })}
                    />
                )}
            </Suppliers>
        </PageContentLayout>
    )
}

export default withTeamErrorHandler(Team.Invoices, SuppliersContainer)
