import { Button, Modal, Typography } from 'antd'
import { Form, Input } from 'formik-antd'
import { Formik } from 'formik'
import * as s from './company-spending.styles'

import { CompanyStatusResponseModelProviderName } from 'bff/moons/generated/phobos'
import type { CompanySpendStatusResponseVModelStatus } from 'bff/moons/generated/ersa'

const { Text } = Typography

interface UpdateSpendingFormProps {
    nextSpendingStatus: CompanySpendStatusResponseVModelStatus
    confirmationMessage: string
    buttonText: string
    providerName: CompanyStatusResponseModelProviderName | null
    statusReason: string
    onUpdate: (status: CompanySpendStatusResponseVModelStatus, reason: string) => void
}

export const UpdateSpendingForm = ({
    nextSpendingStatus,
    confirmationMessage,
    buttonText,
    statusReason,
    providerName,
    onUpdate,
}: UpdateSpendingFormProps) => {
    return providerName !== CompanyStatusResponseModelProviderName.ENFUCE ? (
        <Text>
            The company's current provider ({providerName}) does not support updates to spending
            status.
        </Text>
    ) : (
        <Formik
            onSubmit={({ status, reason }) => onUpdate(status, reason)}
            initialValues={{ status: nextSpendingStatus, reason: statusReason }}
        >
            {({ submitForm }) => (
                <Form>
                    <s.Container>
                        <Input.TextArea
                            name="reason"
                            placeholder="Add a reason for updating spending status"
                        />
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                Modal.confirm({
                                    onOk: submitForm,
                                    centered: true,
                                    title: confirmationMessage,
                                    okButtonProps: {
                                        danger: true,
                                        disabled: false,
                                        htmlType: 'submit',
                                    },
                                    okText: buttonText,
                                })
                            }}
                            data-testid="update-spending-button"
                        >
                            {buttonText}
                        </Button>
                    </s.Container>
                </Form>
            )}
        </Formik>
    )
}
