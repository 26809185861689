import React, { FC, useState } from 'react'
import { Button, message } from 'antd'

import type { Person } from 'types/styx'
import KycTimeline from 'pages/compliance/shared/kyc-timeline/kyc-timeline'
import {
    hasIncompleteDateOfBirth,
    hasIncompleteAddress,
    getLatestKycCheck,
    isDanishOrSwedish,
} from 'pages/compliance/company/people/helpers'
import { useManagement } from 'services/styx/management'

interface Props {
    createEKycCheck: () => void
    expireEKycCheck: () => void
    person: Person
    hasPassed: boolean
}

export const EKyc: FC<React.PropsWithChildren<Props>> = ({
    person,
    createEKycCheck,
    expireEKycCheck,
    hasPassed,
    children,
}) => {
    const handleEkycCheck = () => {
        if (isDanishOrSwedish(person.address) && !person.nationalId) {
            return message.error('Missing national ID - cannot perform Ekyc check.')
        }
        if (!isDanishOrSwedish(person.address) && hasIncompleteDateOfBirth(person.dateOfBirth)) {
            return message.error('Invalid date of birth - cannot perform Ekyc check.')
        }
        if (hasIncompleteAddress(person.address)) {
            return message.error('Invalid address - cannot perform Ekyc check.')
        }

        createEKycCheck()
    }

    return (
        <div>
            {children}
            {hasPassed ? (
                <Button data-testid="expire" danger onClick={expireEKycCheck}>
                    Expire check
                </Button>
            ) : (
                <Button data-testid="new-check" type="primary" onClick={handleEkycCheck}>
                    Run new check
                </Button>
            )}
        </div>
    )
}

const EKycContainer: FC<React.PropsWithChildren<{ person: Person; refetchPeople: () => void }>> = ({
    person,
    refetchPeople,
}) => {
    const [fetching, setFetching] = useState(false)
    const { mutations } = useManagement()

    const latestCheck = getLatestKycCheck(person.ekycChecks)
    const hasPassed = latestCheck?.result === 'PASS' && !latestCheck?.expiresAt

    const createEKycCheck = async () => {
        try {
            setFetching(true)
            await mutations.createEkycCheck(person.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to create eKYC check: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    const expireEKycCheck = async () => {
        if (!latestCheck) {
            return
        }

        try {
            setFetching(true)
            await mutations.expiryEkycCheck(person.id, latestCheck?.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to expire eKYC check: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    return (
        <EKyc
            person={person}
            hasPassed={hasPassed}
            createEKycCheck={createEKycCheck}
            expireEKycCheck={expireEKycCheck}
        >
            <KycTimeline kycChecks={person.ekycChecks} fetching={fetching} />
        </EKyc>
    )
}

export default EKycContainer
