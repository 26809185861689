import {
    AccountBookOutlined,
    LinkOutlined,
    PlayCircleOutlined,
    SafetyOutlined,
} from '@ant-design/icons'
import { useColorScheme } from 'hooks/use-color-scheme'
import { SideMenu } from 'pages/page-layout-components'
import { Link, useParams } from 'react-router-dom'

const Menu = () => {
    const { id } = useParams()
    const { colorScheme } = useColorScheme()

    return (
        <SideMenu selectedKeys={[id]} $colorScheme={colorScheme} theme="dark">
            <SideMenu.Item key="rebuild-expense">
                <AccountBookOutlined />
                <span>Rebuild Expense</span>
                <Link to="../rebuild-expense" />
            </SideMenu.Item>
            <SideMenu.Item key="link-payment">
                <LinkOutlined />
                <span>Link Payment</span>
                <Link to="../link-payment" />
            </SideMenu.Item>
            <SideMenu.Item key="custom-spend-rules">
                <SafetyOutlined />
                <span>Custom Spend Rules</span>
                <Link to="../custom-spend-rules" />
            </SideMenu.Item>
            <SideMenu.Item key="replay-transaction">
                <PlayCircleOutlined />
                <span>Replay Transaction</span>
                <Link to="../replay-transaction" />
            </SideMenu.Item>
            <SideMenu.Item key="batch-balance-adjustment">
                <AccountBookOutlined />
                <span>Batch Balance Adjustment</span>
                <Link to="../batch-balance-adjustment" />
            </SideMenu.Item>
        </SideMenu>
    )
}

export default Menu
