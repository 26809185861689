import React, { FC, useState, useEffect } from 'react'
import dayjs from 'packages/dayjs'
import { Card, message, Button, Modal, Table } from 'antd'
import { Formik, Form } from 'formik'
import type { ColumnProps } from 'antd/es/table'
import * as Yup from 'yup'

import Input from 'components/input/input'
import { Transaction, Amount, Status } from 'types/transaction'
import { useChargebackTransactions } from 'services/deimos/transactions'

const validationSchema = Yup.object()
    .shape({
        id: Yup.string().required('Required'),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface Props {
    onSubmit: (values: FormValues) => void
}

export const ViewChargebackTransactions: FC<React.PropsWithChildren<Props>> = ({ onSubmit }) => (
    <Formik
        initialValues={{ id: '' }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
    >
        {({ errors }) => (
            <Form>
                <Card
                    title="View chargeback transactions"
                    actions={[
                        <Button
                            htmlType="submit"
                            type="link"
                            data-testid="submit"
                            disabled={!!errors.id}
                            key="get-button"
                        >
                            Get
                        </Button>,
                    ]}
                >
                    <Input name="id" placeholder="Card ID" testId="id" />
                </Card>
            </Form>
        )}
    </Formik>
)

interface TableProps {
    dataSource: Transaction[]
    fetching: boolean
}

export const ChargebackTransactionsTable: FC<React.PropsWithChildren<TableProps>> = ({
    dataSource,
    fetching,
}) => {
    const columns: ColumnProps<Transaction>[] = [
        {
            title: 'Performed at',
            dataIndex: 'performed',
            render: (performed: string) => dayjs(performed).format('lll'),
            sorter: (a, b) => dayjs(a.performed).valueOf() - dayjs(b.performed).valueOf(),
        },
        {
            title: 'Transaction ID',
            dataIndex: 'id',
        },
        {
            title: 'Merchant',
            dataIndex: 'merchant[name]',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount: Amount) => (
                <span>
                    {amount.value} {amount.currency}
                </span>
            ),
            sorter: (a, b) => a.amount.value - b.amount.value,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: Object.values(Status).map((value) => ({
                value,
                text: value,
            })),
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Provider',
            dataIndex: 'provider',
        },
    ]
    return (
        <Table
            dataSource={dataSource}
            loading={fetching}
            rowKey="id"
            columns={columns}
            size="small"
            pagination={{ pageSize: 5 }}
        />
    )
}

const ViewChargebackTransactionsContainer = () => {
    const [cardId, setCardId] = useState<string | null>(null)
    const { data, error } = useChargebackTransactions(cardId)

    const onSubmit = ({ id }: FormValues) => {
        setCardId(id)
    }

    useEffect(() => {
        if (error) {
            message.error(error.message)
            setCardId(null)
        }
    }, [error])

    return (
        <>
            <ViewChargebackTransactions onSubmit={onSubmit} />
            <Modal
                title="Chargeback transactions"
                open={!!cardId}
                onCancel={() => setCardId(null)}
                footer={null}
                width={800}
                centered
            >
                <ChargebackTransactionsTable dataSource={data ?? []} fetching={!data} />
            </Modal>
        </>
    )
}

export default ViewChargebackTransactionsContainer
