import type { ComponentType } from 'react'
import { Button, Space } from 'antd'
import { isFirstStep, isLastStep } from '../helpers'
import { useFormikContext } from 'formik'
import type { FormValuesProfile } from '../types'
import { isEmpty } from 'ramda'

type StepProps = {
    title: string
    Component: ComponentType
    goToNextStep: () => void
    goToPreviousStep: () => void
    index: number
    entity?: keyof FormValuesProfile
}

const StepContainer = ({
    title,
    entity,
    Component,
    goToNextStep,
    goToPreviousStep,
    index,
}: StepProps) => {
    const { submitForm, errors } = useFormikContext<FormValuesProfile>()

    const isEntityValid = () => {
        if (!errors || !entity) return true
        if (!errors[entity]) return true
        return isEmpty(errors[entity])
    }

    return (
        <Space key={title} direction={'vertical'}>
            <Component />
            <Space>
                {!isFirstStep(index) && (
                    <Button htmlType="button" ghost type={'primary'} onClick={goToPreviousStep}>
                        Prev
                    </Button>
                )}
                {!isLastStep(index) ? (
                    <Button
                        htmlType="button"
                        disabled={!isEntityValid()}
                        ghost
                        type={'primary'}
                        onClick={goToNextStep}
                    >
                        Next
                    </Button>
                ) : (
                    <Button htmlType="submit" type={'primary'} onClick={submitForm}>
                        Create
                    </Button>
                )}
            </Space>
        </Space>
    )
}

export default StepContainer
