import useSWR from 'swr'
import { kerberosRequest } from 'services/request'
import { fetcher } from './request'

export enum LockType {
    PHONE_OTP = 'PHONE_OTP',
    GOOGLE_AUTH = 'GOOGLE_AUTH',
    PASSCODE = 'PASSCODE',
}

export interface Lock {
    locked: boolean
    wait: number
}

export type Locks = {
    [LockType.PHONE_OTP]: {
        generations: Lock
    }
    [LockType.PASSCODE]: {
        tries: Lock
    }
    [LockType.GOOGLE_AUTH]: {
        tries: Lock
    }
}

export const useUserLocks = (userId?: string) => {
    const result = useSWR<Locks, Error>(userId ? `sca/users/${userId}/locks` : '', fetcher, {
        shouldRetryOnError: false,
    })

    const unlock = async (type: LockType) => {
        if (!userId || !result.data || !type) {
            return
        }
        const response = await resetLock(userId, type)
        result.mutate(response, false)
    }

    return {
        ...result,
        mutations: {
            unlock,
        },
    }
}

function resetLock(userId: string, type: LockType): Promise<Locks> {
    return kerberosRequest()
        .delete(`sca/users/${userId}/locks`, { searchParams: { type } })
        .then((response) => response.json())
}
