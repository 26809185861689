import { Typography } from 'antd'
import styled from 'styled-components'

const { Title, Text } = Typography

export const StyledSubTable = styled.div`
    .ant-table {
        margin-inline: 0;
    }
`

export const TableHeadingWrapper = styled.div`
    padding: 0 10px;
    margin-bottom: 0.75rem;
`

export const StyledTableHeading = styled(Title).attrs({
    level: 5,
})`
    text-align: left;
    margin: 0;
`

export const TableDescription = styled(Text).attrs({
    type: 'secondary',
})`
    text-align: left;
    min-height: 2rem;
    margin: 0;
`
