import useSWR from 'swr'
import type { ErrorResponse } from '@pleo-io/deimos'
import request, { fetcher } from 'services/themis/request'
import type {
    KVRiskScore,
    RangeRiskScore,
    RiskFactorSettings,
    RiskScoreType,
    RiskScores,
    RiskVersion,
} from 'types/customer-risk-rating'
import {
    getRiskScoreTable,
    getScoresChart,
    getVersionsChart,
} from 'pages/compliance/crr-configuration/utils'

export function useCrrFactors(riskVersionId?: string) {
    const versionId = riskVersionId ? `riskVersionId=${riskVersionId}` : ''
    const url = `rest/v1/admin/risk-assessment/risk-factor-settings?${versionId}`
    const {
        data: factors,
        error,
        mutate,
    } = useSWR<RiskFactorSettings[], ErrorResponse>(url, fetcher)

    async function updateFactor(modifiedFactorSettings: RiskFactorSettings) {
        await request().put(`rest/v1/admin/risk-assessment/risk-factor-settings`, {
            json: modifiedFactorSettings,
        })

        mutate()
    }

    return {
        factors,
        error,
        updateFactor,
    }
}

export function useCrrKvRiskScores(type: RiskScoreType, riskVersionId?: string) {
    const versionQuery = riskVersionId ? `&riskVersionId=${riskVersionId}` : ''
    const url = `rest/v1/admin/risk-assessment/kv-risk-scores?type=${type}${versionQuery}`
    const { data, error, isValidating, mutate } = useSWR<KVRiskScore[], ErrorResponse>(
        riskVersionId ? url : null,
        fetcher
    )

    async function updateKvRiskScore(modifiedKVSettings: KVRiskScore) {
        await request().put('rest/v1/admin/risk-assessment/kv-risk-scores', {
            json: modifiedKVSettings,
        })

        mutate()
    }

    return {
        data,
        error,
        isValidating,
        mutations: { updateKvRiskScore },
    }
}

export function useCrrRangeRiskScores(type: RiskScoreType, riskVersionId?: string) {
    const versionQuery = riskVersionId ? `&riskVersionId=${riskVersionId}` : ''
    const url = `rest/v1/admin/risk-assessment/range-risk-scores?type=${type}${versionQuery}`
    const { data, error, isValidating, mutate } = useSWR<RangeRiskScore[], ErrorResponse>(
        riskVersionId ? url : null,
        fetcher
    )

    async function updateRangeRiskScore(modifiedRangeSettings: RangeRiskScore[]) {
        modifiedRangeSettings.forEach(async (newRangeData) => {
            await request().put('rest/v1/admin/risk-assessment/range-risk-scores', {
                json: newRangeData,
            })
            mutate(modifiedRangeSettings)
        })
    }

    return {
        data,
        error,
        isValidating,
        mutations: { updateRangeRiskScore },
    }
}

export function useRiskVersions(riskVersionId?: string) {
    const url = `rest/v1/admin/risk-assessment/risk-versions/${riskVersionId ?? ''}`
    const { data, error, mutate } = useSWR<any | RiskScores | RiskScores[], ErrorResponse>(
        url,
        fetcher
    )

    async function createNewVersion(description: string) {
        await request().post('rest/v1/admin/risk-assessment/risk-versions', {
            json: {
                description: description,
            },
        })
        mutate()
    }

    async function makeActiveVersion(versionId: string) {
        await request().post(`rest/v1/admin/risk-assessment/risk-versions/${versionId}/activate`)
        mutate()
    }

    async function renameVersion(versionId: string, newName: string) {
        await request().put('rest/v1/admin/risk-assessment/risk-versions', {
            json: {
                id: versionId,
                description: newName,
            },
        })
        mutate()
    }

    return {
        data,
        error,
        mutations: { createNewVersion, makeActiveVersion, renameVersion },
    }
}

export function useTestRiskVersions(selectedVersions: RiskVersion[], sampleSet: string[]) {
    const {
        data: riskScores,
        error,
        isValidating,
        mutate,
    } = useSWR<RiskScores[], ErrorResponse>(
        `rest/v1/admin/risk-assessment/test-risk-versions`,
        async () =>
            await request()
                .post(`rest/v1/admin/risk-assessment/test-risk-versions`, {
                    json: {
                        riskVersions: selectedVersions.map((version) => version.id),
                        sampleSet: sampleSet[0],
                    },
                })
                .then((res) => res.json())
    )

    const riskScoreTable = getRiskScoreTable(riskScores)
    const scoreChart = getScoresChart(selectedVersions, riskScores)
    const versionChart = getVersionsChart(selectedVersions, riskScores)
    const data = {
        tables: {
            riskScore: riskScoreTable,
        },
        charts: {
            scores: scoreChart,
            versions: versionChart,
        },
    }

    return {
        data,
        error,
        isValidating,
        mutate,
    }
}

export function useRiskVersionsLatestUpdate() {
    const params = new URLSearchParams({ includeLatestUpdate: 'true' }).toString()

    const url = `rest/v1/admin/risk-assessment/risk-versions?${params}`
    const { data, error } = useSWR<any | RiskScores | RiskScores[], ErrorResponse>(url, fetcher)

    return {
        data,
        error,
    }
}
