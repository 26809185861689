import { Table, Button } from 'antd'
import type { ColumnType } from 'antd/lib/table/interface'

import { ActionsContainer } from 'components/layout-containers'
import { KycStatus, type B4BCompanyResponse, KycCheckpoint } from 'types/styx'
import { getEmojiFlag } from 'countries-list'
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useState } from 'react'

interface Props {
    companies: B4BCompanyResponse[]
    loading: boolean
    onDownloadClick: (
        companyId: string,
        registryId: string,
        downloadToggle: (id: string) => void
    ) => void
}

const renderFormattedTime = (timeStamp?: string) =>
    timeStamp ? dayjs(timeStamp).format('lll') : '-'

const ListB4BCompanies = ({ companies, loading, onDownloadClick }: Props) => {
    const [downloadToggle, setDownloadToggle] = useState<{ [key: string]: boolean }>({})

    const downloadToggleHandler = (id: string) => {
        setDownloadToggle((prevState) => {
            if (prevState[id]) {
                // 👇️ remove the key from the object if it's true and we toggle it to free up memory
                const copy = { ...prevState }
                delete copy[id]

                return copy
            }

            return {
                ...prevState,
                [id]: !prevState[id],
            }
        })
    }

    const columns: ColumnType<B4BCompanyResponse>[] = [
        {
            title: 'Country',
            dataIndex: 'address',
            render: (address) => `${getEmojiFlag(address.country)} ${address.country}`,
        },
        {
            title: 'Company name',
            dataIndex: 'legalName',
        },
        {
            title: 'Registration number',
            dataIndex: 'registryId',
            render: (registryId: string) => registryId || '-',
        },
        {
            title: 'KYC date',
            dataIndex: 'kycCheckpoints',
            render: (kycCheckpoints: KycCheckpoint[]) => {
                if (!kycCheckpoints || kycCheckpoints.length === 0) {
                    return '-'
                }

                const latestFddCheckpoint = kycCheckpoints
                    .filter((checkpoint) => checkpoint.level === KycStatus.FDD)
                    .reduce((acc: KycCheckpoint | null, checkpoint) => {
                        if (!acc) {
                            return checkpoint
                        }

                        return acc.performed > checkpoint.performed ? acc : checkpoint
                    }, null)

                return renderFormattedTime(latestFddCheckpoint?.performed)
            },
        },
        {
            title: 'Actions',
            render: (record: B4BCompanyResponse) => (
                <ActionsContainer>
                    <Button
                        loading={downloadToggle[record.id] === true}
                        onClick={() =>
                            onDownloadClick(record.id, record.registryId, downloadToggleHandler)
                        }
                        type="link"
                    >
                        <DownloadOutlined /> Download
                    </Button>
                </ActionsContainer>
            ),
        },
    ]

    return (
        <Table<B4BCompanyResponse>
            loading={loading}
            dataSource={companies}
            rowKey="id"
            columns={columns}
            pagination={false}
        />
    )
}

export default ListB4BCompanies
