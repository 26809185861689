import { PageContentLayout } from 'components/layout-containers'
import ChangeExpensesStatusContainer from './widgets/change-expenses-status-container'
import MarkExportAsFailedContainer from './widgets/mark-export-as-failed-container'
import { ExportHistory } from './widgets/export-history'
import { PageHeader } from '@ant-design/pro-layout'
import { Switch } from 'antd'
import { useState } from 'react'

const ExportsPage = () => {
    const [shouldUseDeimos, setShouldUseDeimos] = useState<boolean>(true)
    return (
        <PageContentLayout>
            <PageHeader
                extra={
                    <Switch
                        checkedChildren="Use Deimos"
                        unCheckedChildren="Use ExportAPI"
                        defaultChecked
                        onChange={(checked: boolean) => {
                            setShouldUseDeimos(checked)
                        }}
                    />
                }
            />
            <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ width: '66%', paddingRight: '1rem' }}>
                    <ExportHistory shouldUseDeimos={shouldUseDeimos} />
                </div>
                <div style={{ width: '33%' }}>
                    <ChangeExpensesStatusContainer shouldUseDeimos={shouldUseDeimos} />
                    <MarkExportAsFailedContainer shouldUseDeimos={shouldUseDeimos} />
                </div>
            </div>
        </PageContentLayout>
    )
}

export default ExportsPage
