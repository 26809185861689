import { useEffect, useState } from 'react'

import { bff } from '../../pages/product/group-permissions/bff'
import { Alert, notification } from 'antd'
import { useCursorPagination } from 'utils/use-cursor-pagination'
import type { PaginationData } from '@pleo-io/deimos'
import type { PermissionChangeRequest } from 'bff/moons/generated/pandora-v1'
import { defaultPageSize, defaultSorting } from './constants'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import { ContentContainer } from 'components/layout-containers'
import HistoricRequestPermissionsList from './historic-request-permissions-list'

const HistoricRequestPermissionsContainer = () => {
    const [buttonLoading, setButtonLoading] = useState<string | null>(null)

    const { paginationRequest, setCurrentPagination, ...paginationMutations } = useCursorPagination(
        {
            limit: defaultPageSize,
            sorting: defaultSorting,
        }
    )

    const { data, isLoading, error } =
        bff.permissions.listPermissionsChangeRequestsForUser.useQuery({
            pagination: paginationRequest,
        })

    const permissionChangeRequests = data?.data as PermissionChangeRequest[] | []
    const pagination = data?.pagination as PaginationData<string> | undefined

    const { data: oktaPermissions } = bff.permissions.listAllPermissions.useQuery(
        { params: { include_deleted: false } },
        { refetchOnWindowFocus: false }
    )

    const { mutateAsync: notifyUserAboutRequest } =
        bff.permissions.notifyUserAboutRequest.useMutation()

    const sendNotification = async (requestId: string) => {
        setButtonLoading(requestId)
        try {
            const { success, message } = await notifyUserAboutRequest({ requestId })
            notification[success ? 'success' : 'error']({
                message: success ? 'Notification sent' : 'Failed to send notification',
                description: message,
            })
        } catch (e: any) {
            notification.error({
                message: 'Failed to send notification',
                description: e.message,
            })
        } finally {
            setButtonLoading(null)
        }
    }

    useEffect(() => {
        if (pagination) {
            setCurrentPagination(pagination)
        }
    }, [pagination, setCurrentPagination])

    const loading = isLoading && !error

    return (
        <ContentContainer>
            {error && (
                <Alert
                    message="Error loading data. Please reload to try again."
                    type="error"
                    showIcon
                />
            )}
            <HistoricRequestPermissionsList
                loading={loading}
                permissionChangeRequests={permissionChangeRequests}
                oktaPermissions={oktaPermissions}
                sendNotification={sendNotification}
                buttonLoading={buttonLoading}
            />
            {paginationRequest && pagination && (
                <CursorPaginationControls
                    isFetching={loading}
                    pagination={pagination}
                    onChangeLimit={paginationMutations.updateLimit}
                    limit={paginationRequest.limit}
                    onNextPage={paginationMutations.nextPage}
                    onPrevPage={paginationMutations.previousPage}
                    onFirstPage={paginationMutations.resetFirstPage}
                />
            )}
        </ContentContainer>
    )
}

export default HistoricRequestPermissionsContainer
