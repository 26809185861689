import * as yup from 'yup'
import type { AddShareholderPayload, Entity, Node } from 'types/styx'

export const validationSchema = yup
    .object()
    .shape({
        selectedEntity: yup.string().required('Required'),
        sharePercentage: yup
            .number()
            .nullable()
            .typeError('Enter percentage as integer')
            .min(0, 'Percentage cannot be negative')
            .max(100, 'Percentage cannot be above 100'),
        votingRights: yup
            .number()
            .nullable()
            .typeError('Enter percentage as integer')
            .min(0, 'Percentage cannot be negative')
            .max(100, 'Percentage cannot be above 100'),
    })
    .required()

export type FormValues = yup.InferType<typeof validationSchema>

export const mapValuesToRequest = (
    values: FormValues,
    entities: Array<Entity | Node>
): AddShareholderPayload => {
    const entity = entities.find((e) => e.id === values.selectedEntity)

    return {
        legalPersonId: entity?.id ?? '',
        type: entity?.type ?? 'UNKNOWN',
        sharePercentage: values.sharePercentage ?? 0,
        votingRights: values.votingRights ?? 0,
    }
}
