import { Select, Space } from 'antd'
import { countries } from 'countries-list'
import { FormItem } from 'formik-antd'
import type { FC } from 'react'
import { useSupportedCountries } from 'services/dionysus/supported-countries'
import type { Alpha2Code, SupportedCountryResponse } from 'types/countries'

export const getCountry = (alpha2code: Alpha2Code) => countries[alpha2code as Alpha2Code]

type AdvancedSearchCountrySelectProps = {
    label: string
    name: string
    onChange: (value: any, fieldName: string) => void
    testId?: string
    widthOverride?: number
}

const AdvancedSearchCountrySelect: FC<AdvancedSearchCountrySelectProps> = ({
    label,
    name,
    onChange,
    testId,
    widthOverride,
}: AdvancedSearchCountrySelectProps) => {
    const { data: supportedCountries } = useSupportedCountries()
    return (
        <FormItem
            label={label}
            name={name}
            style={{ width: widthOverride ?? 200, margin: '0 10px' }}
        >
            <Select
                optionFilterProp="label"
                data-testid={testId ?? `advanced-search-country-select-${name}`}
                allowClear
                onChange={(value) => onChange(value, name)}
                placeholder="Country"
            >
                {supportedCountries?.map(({ countryCode }: SupportedCountryResponse) => (
                    <Select.Option
                        key={countryCode}
                        value={countryCode}
                        label={getCountry(countryCode).name}
                        data-testid={`advanced-search-country-select-option-${countryCode}`}
                    >
                        <Space>
                            {getCountry(countryCode).emoji}
                            {getCountry(countryCode).name}
                        </Space>
                    </Select.Option>
                ))}
            </Select>
        </FormItem>
    )
}

export default AdvancedSearchCountrySelect
