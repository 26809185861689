import { prosperoRequest } from 'services/request'
import { v5 as uuidv5 } from 'uuid'
import dayjs from 'packages/dayjs'

// a custom namespace(uuid) is required to avoid collisions with other uuids
const BALANCE_ADJUSTMENT_NAMESPACE = 'f5676e78-e6bb-4011-b55f-08f2c14df127'

export const adjustBalance = async (
    companyId: string,
    amountValue: number,
    amountCurrency: string,
    note: string
) => {
    const debitOrCredit = amountValue >= 0 ? 'CREDIT' : 'DEBIT'
    const absAmountValue = Math.abs(amountValue)

    const idempotencyKey = uuidv5(
        companyId +
            debitOrCredit +
            absAmountValue.toString() +
            amountCurrency +
            note +
            dayjs().format('YYYY-MM-DD'),
        BALANCE_ADJUSTMENT_NAMESPACE
    )
    const response = await prosperoRequest().post(`rest/v1/balance-adjustments`, {
        json: {
            companyId: companyId,
            note: note,
            amount: {
                direction: debitOrCredit,
                value: {
                    value: absAmountValue,
                    currency: amountCurrency,
                },
            },
            idempotencyKey: idempotencyKey,
        },
    })
    return response.json()
}
