import useSWR from 'swr'

import type { Expense } from 'types/expense'

import request, { fetcher } from './request'

export const useExpense = (expenseId: string) => {
    const response = useSWR<Expense, Error>(
        expenseId ? `rest/v1/expenses/${expenseId}` : null,
        fetcher
    )

    const uploadReceipt = async (formData: FormData): Promise<Expense> => {
        return request()
            .post(`rest/v1/expenses/${expenseId}/receipts`, { body: formData })
            .then((res) => res.json())
    }

    const rebuild = async () => {
        return request()
            .post(`rest/v1/admin/expenses/${expenseId}/rebuild`)
            .then((res) => res.json())
    }

    return { ...response, mutations: { uploadReceipt, rebuild } }
}
