import { Button, Table, Tag, Tooltip, Typography } from 'antd'
import type { ColumnProps } from 'antd/es/table'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import dayjs from 'dayjs'
import { getEmployeeRoleDisplay, getEmployeeTypes } from 'pages/customer-success/utils'
import type { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import type { Employee, OberonEmployee } from 'types/employee'
import { CompanyPartnerName, getOberonEmployeeName } from 'utils/employees'

const { Text } = Typography

const columns = (refetch: () => void, primaryCompanyId?: string): ColumnProps<OberonEmployee>[] => [
    {
        title: 'Name',
        render: (_: string, employee: OberonEmployee) => getOberonEmployeeName(employee),
        sorter: (a: OberonEmployee, b: OberonEmployee) => getOberonEmployeeName(a).localeCompare(getOberonEmployeeName(b)),
    },
    {
        title: 'Role type',
        dataIndex: 'admin',
        filters: ['Employee', 'Admin'].map((value = '') => ({
            value,
            text: value,
        })),
        onFilter: (value, employee: OberonEmployee) => {
            const employeeTypes = getEmployeeTypes(employee)
            return (
                (employeeTypes.isAdmin && value === 'Admin') ||
                (!employeeTypes.isAdmin && value === 'Employee')
            )
        },
        render: (_, employee: OberonEmployee) => getEmployeeRoleDisplay(employee),
    },
    {
        title: 'Company/Partner',
        dataIndex: ['companyId', 'partnerId'],
        render: (_, employee) => <CompanyPartnerName employee={employee} refetch={refetch} />,
    },
    {
        title: 'Is Spending Entity',
        dataIndex: 'companyId',
        render: (id) => (
            <>
                {id === primaryCompanyId ? (
                    <Tag color={'green'}>True</Tag>
                ) : (
                    <Tag color={'red'}>False</Tag>
                )}
            </>
        ),
    },
    {
        title: 'Employee ID',
        dataIndex: 'id',
        onCell: (_) => ({ onClick: (e) => e.stopPropagation() }),
        render: (id) => <Text copyable>{id}</Text>,
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('lll'),
        sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
    },
    {
        title: 'Actions',
        render: (record) => <Employee360ActionButton record={record} />,
    },
]

interface UserTableProps {
    employees: OberonEmployee[]
    refetch: () => void
    primaryCompanyId?: string
}

const UserTable = ({ employees, refetch, primaryCompanyId }: UserTableProps) => {
    const navigate = useNavigate()

    return (
        <TableStyleProvider>
            <Table
                rowKey="id"
                dataSource={employees}
                columns={columns(refetch, primaryCompanyId)}
                onRow={(employee) => ({
                    onClick: () => {
                        if (getEmployeeRoleDisplay(employee) !== 'Orphan Employee') {
                            navigate(`/customer-success/users/${employee.userId}/${employee.id}`)
                        }
                    },
                })}
            />
        </TableStyleProvider>
    )
}

interface Employee360ActionButtonProps {
    record: Employee
}

const Employee360ActionButton: FC<Employee360ActionButtonProps> = ({ record }) => {
    const hasEmployee360Permissions = useHasPermissions(['employee-360'])

    return (
        <Tooltip
            color="white"
            title={
                !hasEmployee360Permissions && (
                    <Typography.Text>
                        Permissions required:{' '}
                        <Typography.Text code style={{ whiteSpace: 'nowrap' }}>
                            employee-360
                        </Typography.Text>
                    </Typography.Text>
                )
            }
        >
            <Button
                disabled={!hasEmployee360Permissions}
                type="link"
                rel="no-referrer noopener"
                target="_blank"
                data-testid={`employee360-action-button-${record.id}`}
                href={`/customer-success/employee-360/${record.companyId}/${record.id}`}
                onClick={(e) => e.stopPropagation()}
            >
                Employee 360
            </Button>
        </Tooltip>
    )
}

const TableStyleProvider = styled('div')`
    .ant-table-tbody tr {
        cursor: pointer;
    }
`

export default UserTable
