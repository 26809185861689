import { Select as AntSelect } from 'antd'
import styled from 'styled-components'
import { inputWidth } from 'theme/tokens'

// * Passing any as a generic here.
// * Styled is preventing my antd Select from correctly picking up the SelectValue and it is defaulting it to unknown.
// * value is correctly typed in onChange handler, so nothing is actually typed as any.
export const Select = styled(AntSelect)<any>`
    width: ${inputWidth.large};
`
