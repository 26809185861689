import React, { FC, useEffect, useState } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import {
    Button,
    Card,
    Typography,
    Space,
    Table,
    Tooltip,
    Modal,
    Tag,
    Descriptions,
    Statistic,
} from 'antd'
import styled from 'styled-components'
import { color } from 'theme/tokens'
import { ContentContainer, PageContentLayout } from 'components/layout-containers'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { parse } from 'qs'
import dayjs from 'packages/dayjs'

import {
    GetFlagResponse,
    InvestigationStatus,
    RiskScore,
    GetAllFlagResponse,
    TriggeredRuleWithScore,
    FlagRow,
    Amount,
    FlagCategory,
} from '@pleo-io/deimos'
import mccCodeMap from 'utils/mcc-codes'
import type { Company, OnboardingSource } from 'types/deimos-company'
import getHighestKycStatus from 'utils/get-company-kyc-status'
import type { ColumnProps } from 'antd/es/table'
import { useArielFlagsPaginated } from 'services/deimos/transaction-monitoring'
import { Status } from 'types/card'
import { merchantNameSorter } from 'pages/compliance/transaction-monitoring/utils'
import { snakeCaseToTitleSnakeCase, snakeCaseToTitleCase } from 'utils/strings'
import { useCompanyOnboardedVia } from 'services/deimos/styx-company/styx-company'
import type { IndustryCodes } from 'types/styx'
import NaceCode from 'components/nace-codes/nace-code'
import { OnboardedViaTag } from 'pages/compliance/shared/company-onboarded-via-tag'
import { useIndustryCodes } from 'services/styx/industry-codes'

const { Text, Paragraph, Link: AntLink } = Typography

interface Props {
    goBack: () => void
    flag?: GetFlagResponse
    loading: boolean
    onUpdateCard: (cardId: string, status: Status) => void
    onRowSelection: (selectedRows: FlagRow[]) => void
    naceCodes?: IndustryCodes[]
    onboardedVia?: OnboardingSource
}

const getInvestigationStatus = (flag?: GetFlagResponse) => flag?.rows[0]?.flag.investigationStatus

const getFlag = (flags: GetAllFlagResponse, cardId: string) =>
    flags.find((flag) => {
        const hasUndefinedCardId = !flag.card?.id && cardId === 'undefined'

        return hasUndefinedCardId || flag.card?.id === cardId
    })

export const TransactionList: FC<React.PropsWithChildren<Props>> = ({
    goBack,
    flag,
    loading,
    children,
    onRowSelection,
    onUpdateCard,
    naceCodes,
    onboardedVia,
}) => {
    const cardStatus = flag?.card?.status
    const cardId = flag?.card?.id
    const isRestricted = cardStatus === 'RESTRICTED'
    const isLive = cardStatus === 'LIVE'
    const isRestrictedOrLive = isRestricted || isLive

    const columns: ColumnProps<FlagRow>[] = [
        {
            title: 'Flag ID',
            dataIndex: ['flag', 'id'],
        },
        {
            title: 'Performed',
            dataIndex: ['flag', 'createdAt'],
            render: (timestamp: string) => dayjs(timestamp).format('lll'),
        },
        {
            title: 'Merchant name',
            dataIndex: ['transaction', 'merchant', 'name'],
            key: 'merchantName',
            render: (merchantName: string) => (
                <AntLink
                    disabled={!merchantName}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.google.dk/search?q=${merchantName}`}
                >
                    {merchantName}
                </AntLink>
            ),
            sorter: merchantNameSorter,
        },
        {
            title: 'Amount',
            dataIndex: ['transaction', 'amount'],
            render: (amount: Amount) => `${amount?.value ?? ''} ${amount?.currency ?? ''}`,
        },
        {
            title: 'MCC',
            dataIndex: ['transaction', 'merchant', 'mcc'],
            width: '7%',
            render: (mcc: string) => (
                <Tooltip title={mccCodeMap[mcc]?.edited_description ?? 'Unknown'}>
                    <Text>{mcc}</Text>
                </Tooltip>
            ),
        },
        {
            title: 'Triggered Rules',
            dataIndex: ['flag', 'triggeredRules'],
            key: 'triggeredRules',
            width: '20%',
            render: (rules: TriggeredRuleWithScore[]) => (
                <Space direction="vertical">
                    {rules.map((rule, index) => (
                        <Tag key={index} color="red">
                            <div>{rule.ruleName}</div>
                            <div>{rule.score && `Score: ${rule.score}`}</div>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: 'Transaction ID',
            dataIndex: ['flag', 'transactionId'],
            ellipsis: {
                showTitle: false,
            },
            render: (id: string) => (
                <Paragraph style={{ marginBottom: 0 }} ellipsis copyable>
                    {id}
                </Paragraph>
            ),
        },
        {
            title: 'Merchant history',
            dataIndex: ['transaction', 'merchant', 'name'],
            key: 'merchantHistory',
            render: (merchantName: string) => (
                <AntLink
                    disabled={!merchantName}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://metabase.pleo.io/question/3972?merchant_name_1=${merchantName}`}
                >
                    Link to flag history
                </AntLink>
            ),
        },
    ]

    useEffect(() => {
        if (!loading && !flag?.rows.length) {
            goBack()
        }
    }, [loading, flag, goBack])

    const title = snakeCaseToTitleCase(String(getInvestigationStatus(flag)))

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={goBack}
                title={title}
                subTitle={<OnboardedViaTag onboardedVia={onboardedVia} />}
                extra={[
                    isLive && (
                        <Button onClick={() => cardId && onUpdateCard(cardId, Status.RESTRICTED)}>
                            Freeze
                        </Button>
                    ),
                    isRestricted && (
                        <Button onClick={() => cardId && onUpdateCard(cardId, Status.LIVE)}>
                            Un-freeze
                        </Button>
                    ),
                    isRestrictedOrLive && (
                        <Button onClick={() => cardId && onUpdateCard(cardId, Status.STOLEN)}>
                            Mark stolen
                        </Button>
                    ),
                    isRestrictedOrLive && (
                        <Button onClick={() => cardId && onUpdateCard(cardId, Status.LOST)}>
                            Mark lost
                        </Button>
                    ),
                    cardStatus && !isRestrictedOrLive && (
                        <Text type="secondary">
                            No cards actions available as card is marked as {cardStatus}
                        </Text>
                    ),
                ]}
            >
                <DetailsContainer>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Card ID">
                            <Text copyable>{flag?.card?.id}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="PAN">{flag?.card?.pan}</Descriptions.Item>
                        <Descriptions.Item label="Type">{flag?.card?.type}</Descriptions.Item>
                        <Descriptions.Item label="Card status">
                            {flag?.card?.status}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="User email">
                            <BreakText copyable>{flag?.employee?.email}</BreakText>
                        </Descriptions.Item>
                        <Descriptions.Item label="Company">
                            <AntLink href={`/compliance/companies/${flag?.company?.id}`}>
                                {flag?.company?.name}
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="KYC status">
                            {flag?.company && getHighestKycStatus(flag?.company as Company)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Type of business">
                            {flag?.areaOfBusiness ? (
                                <Paragraph
                                    style={{ maxWidth: 300, minWidth: 35 }}
                                    type="secondary"
                                    ellipsis={{
                                        rows: 1,
                                        expandable: true,
                                        symbol: 'more',
                                    }}
                                >
                                    {flag?.areaOfBusiness}
                                </Paragraph>
                            ) : (
                                'None'
                            )}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                disabled={!flag?.card?.id}
                                href={`https://metabase.pleo.io/dashboard/72?card_id=${flag?.card?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Fraud Investigation
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                disabled={!flag?.company?.id}
                                href={`https://metabase.pleo.io/question/3904?company_id=${flag?.company?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Companies transaction history
                            </AntLink>
                        </Descriptions.Item>
                        <Descriptions.Item label="Metabase">
                            <AntLink
                                disabled={!flag?.company?.id}
                                href={`https://metabase.pleo.io/question/3973?company_id=${flag?.company?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Company flag history
                            </AntLink>
                        </Descriptions.Item>
                    </Descriptions>
                    <Statistic
                        title="Risk score"
                        value={flag?.riskScore ? snakeCaseToTitleSnakeCase(flag.riskScore) : '-'}
                        valueStyle={
                            flag?.riskScore === RiskScore.HIGH ? { color: color.red } : undefined
                        }
                    />
                </DetailsContainer>
                {!!naceCodes?.length && (
                    <Descriptions>
                        <Descriptions.Item label="NACE">
                            <Paragraph
                                ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                                style={{ maxWidth: '100vw', lineHeight: '2' }}
                            >
                                {naceCodes.map((naceCode, index) => (
                                    <NaceCode key={index} naceCode={naceCode} />
                                ))}
                            </Paragraph>
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </PageHeader>
            <PageContentLayout>
                <Card>
                    <ContentContainer>
                        <Table
                            pagination={false}
                            loading={loading}
                            data-testid="transactions"
                            rowKey={(row) => row.flag.id}
                            rowSelection={{
                                type: 'checkbox',
                                onChange: (_, selectedFlagRows) => onRowSelection(selectedFlagRows),
                            }}
                            title={() => 'Transactions'}
                            columns={columns}
                            dataSource={flag?.rows}
                        />
                        {children}
                    </ContentContainer>
                </Card>
            </PageContentLayout>
        </>
    )
}

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    .ant-descriptions * {
        width: auto;
    }
`

const BreakText = styled(Text)`
    word-break: break-all;
`

const TransactionListContainer = () => {
    const [selectedRows, setSelectedRows] = useState<FlagRow[]>([])
    const params = useParams()
    const { search } = useLocation()
    const navigate = useNavigate()

    const { investigationStatus, to, from, pageSize, pageOffset } = parse(search, {
        ignoreQueryPrefix: true,
    })
    const goBack = () => navigate(-1)

    const [status] = React.useState<InvestigationStatus[]>([investigationStatus])
    const [category] = React.useState<FlagCategory[]>([FlagCategory.CARD_FRAUD])

    const {
        data: paginatedFlags,
        mutations,
        isValidating,
    } = useArielFlagsPaginated({
        to: to,
        from: from,
        status,
        category,
        pageSize,
        pageOffset,
    })

    const flags = paginatedFlags?.rows
    const flag = getFlag(flags ?? [], params.id)
    const { naceCodes } = useIndustryCodes(flag?.company?.id ?? undefined)
    const { onboardedVia } = useCompanyOnboardedVia(flag?.company?.id ?? undefined)

    const disabled = !selectedRows.length

    const onUpdateFlagInvestigationStatus = (nextStatus: InvestigationStatus) => {
        mutations.updateInvestigationStatus(selectedRows, nextStatus)
        setSelectedRows([])
    }

    return (
        <TransactionList
            onUpdateCard={(cardId, newStatus) => {
                Modal.confirm({
                    onOk: () => mutations.updateCard(cardId, newStatus),
                    centered: true,
                    title: `Update card status to ${newStatus}`,
                })
            }}
            goBack={goBack}
            flag={flag}
            loading={!flags && isValidating}
            onRowSelection={setSelectedRows}
            naceCodes={naceCodes}
            onboardedVia={onboardedVia}
        >
            <Space>
                <Button
                    type="primary"
                    disabled={disabled}
                    onClick={() =>
                        onUpdateFlagInvestigationStatus(InvestigationStatus.FALSE_POSITIVE)
                    }
                >
                    False positive
                </Button>
                <Button
                    type="primary"
                    disabled={disabled}
                    onClick={() =>
                        onUpdateFlagInvestigationStatus(InvestigationStatus.REQ_INVESTIGATION)
                    }
                >
                    Requires investigation
                </Button>
                <Button
                    type="primary"
                    disabled={disabled}
                    onClick={() => onUpdateFlagInvestigationStatus(InvestigationStatus.FRAUD)}
                >
                    Fraud
                </Button>
            </Space>
        </TransactionList>
    )
}

export default TransactionListContainer
