import { useFormikContext } from 'formik'
import type { FormValuesProfile } from '../types'
import { Input, Select, FormItem } from 'formik-antd'
import { Form, Checkbox, Space } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import useUpdateProfileFormValues from '../hooks/use-update-profile-form-values'
import SupportedCountrySelect from 'components/supported-countries-select'
import SupportedLanguageSelect from 'components/supported-language-select'

import { codes } from 'currency-codes'
const currencyOptions = codes().map((code: string) => {
    return {
        value: code,
        label: code,
    }
})

const CompanyStep = () => {
    const { values } = useFormikContext<FormValuesProfile>()
    const isMileageEnabled = !!values.company.mileage
    const { updateProperty } = useUpdateProfileFormValues('company')

    const handleMileageCheckbox = (event: CheckboxChangeEvent) => {
        const {
            target: { checked },
        } = event
        if (checked) return updateProperty({ mileage: values.company?.mileage ?? {} })
        return updateProperty({ mileage: null })
    }
    return (
        <Form layout="vertical">
            <Space>
                <FormItem name={'company.address.city'} label={'City:'}>
                    <Input name={'company.address.city'} value={values.company.address.city} />
                </FormItem>
                <FormItem name="company.address.country" label={'Country:'}>
                    <SupportedCountrySelect
                        name="company.address.country"
                        placeholder="Country"
                        value={values.company.address.country}
                    />
                </FormItem>
                <FormItem name="company.language" label={'Language'}>
                    <SupportedLanguageSelect name="company.language" />
                </FormItem>
            </Space>
            <Space>
                <FormItem name="mileage-checkbox" label={'Mileage:'}>
                    <Checkbox onChange={handleMileageCheckbox} defaultChecked={isMileageEnabled}>
                        Enabled
                    </Checkbox>
                </FormItem>
                <FormItem name={'company.mileage.rate'} label={'Rate:'}>
                    <Input
                        disabled={!isMileageEnabled}
                        name={'company.mileage.rate'}
                        value={values.company.mileage?.rate ?? 0}
                        suffix={`per km`}
                        type="number"
                    />
                </FormItem>
                <FormItem name={'company.mileage.currency'} label={'Currency:'}>
                    <Select
                        name={'company.mileage.currency'}
                        value={values.company.mileage?.currency ?? 'EUR'}
                        disabled={!isMileageEnabled}
                        options={currencyOptions}
                    />
                </FormItem>
            </Space>
            <FormItem name={'company.wallet.initialLoad'} label="Initial wallet load">
                <Input
                    name={'company.wallet.initialLoad'}
                    value={values.company.wallet.initialLoad}
                    min={0}
                    step="0.01"
                    type="number"
                />
            </FormItem>
        </Form>
    )
}

export default CompanyStep
