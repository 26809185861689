import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import PermissionGuard from 'components/permission-guard/permission-guard'
import { useIsLoggedOut } from 'providers/auth-context'
import AnyOfPermissionGuard from 'components/permission-guard/any-of-permission-guard'

interface Props {
    requiredPermissions?: string[]
    fallback?: JSX.Element
    /**
     * An optional property specifying the strategy for handling permissions.
     *
     * Possible values:
     * - 'all': All the permissions in requiredPermissions must be satisfied for the functionality to proceed.
     * - 'at-least-one': At least one of the permissions in requiredPermissions must be satisfied.
     *
     * @default undefined
     */
    allowStrategy?: 'all' | 'at-least-one'
}

const ProtectedRoute = ({ requiredPermissions = [], fallback, allowStrategy = 'all' }: Props) => {
    const isLoggedOut = useIsLoggedOut()
    const previousPath = useResumeSession()

    return (
        <>
            {isLoggedOut ? (
                <Navigate to="/login" />
            ) : previousPath ? (
                <Navigate to={previousPath} />
            ) : allowStrategy === 'at-least-one' ? (
                <AnyOfPermissionGuard permissions={requiredPermissions} fallback={fallback}>
                    <Outlet />
                </AnyOfPermissionGuard>
            ) : (
                <PermissionGuard requiredPermissions={requiredPermissions} fallback={fallback}>
                    <Outlet />
                </PermissionGuard>
            )}
        </>
    )
}

export default ProtectedRoute

const useResumeSession = () => {
    const location = useLocation()
    const isLoggedOut = useIsLoggedOut()
    const [previousPath, setPreviousPath] = useState<string | null>(
        localStorage.getItem('previousPath')
    )

    useEffect(() => {
        if (isLoggedOut) {
            localStorage.setItem('previousPath', location?.pathname)
            setPreviousPath(location?.pathname)
        } else if (previousPath && previousPath === location?.pathname) {
            localStorage.removeItem('previousPath')
            setPreviousPath(null)
        }
    }, [isLoggedOut, location?.pathname, previousPath])

    return previousPath
}
