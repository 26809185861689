import type { FC } from 'react'
import { Collapse, Empty, Divider, Tag, Typography } from 'antd'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

import InfoBlock from 'components/info-block/info-block'
import PersonAddress from 'components/person-address/person-address'
import type { Person, Management, UboResponse } from 'types/styx'
import { isUnknownShareholder } from 'utils/is-shareholder'
import { getRoleNames, getPersonName } from 'pages/compliance/shared/utils'
import { getLatestKycResult, getLatestKycCheck } from 'pages/compliance/company/people/helpers'
import { ReadOnlyDocumentTabs } from 'pages/compliance/company/shared/document-tabs/document-tabs'
import Skeleton from 'components/content-skeleton'
import { getLinkToPersonDocumentFile } from 'services/styx/person'
import type { CompanyStakeholderResponse } from 'bff/moons/generated/styx-v1'
import type { ErrorResponse } from '@pleo-io/deimos'

const { Panel } = Collapse
const { Text } = Typography

interface DirectorPanelProps {
    person: Person
    snapshot: string
}

export const DirectorPanel: FC<React.PropsWithChildren<DirectorPanelProps>> = ({
    person,
    snapshot,
}) => {
    const handleGetLinkToFile =
        (documentId: string) => async (fileId: string, download?: boolean) => {
            const { url }: { url: string } = await getLinkToPersonDocumentFile({
                subjectId: person.id,
                documentId,
                fileId,
                snapshot,
                download,
            }).json()

            return url
        }

    const ekycExpired = !!getLatestKycCheck(person.ekycChecks)?.expiresAt
    return (
        <div>
            <PanelContent>
                <Section>
                    <InfoBlock label="Previous PEP">
                        {getLatestKycResult(person.pepChecks) ?? 'Unchecked'}
                    </InfoBlock>
                    <InfoBlock label="Previous SIS">
                        {getLatestKycResult(person.sisChecks) ?? 'Unchecked'}
                    </InfoBlock>
                    <InfoBlock label="Previous IDV">
                        {getLatestKycResult(person.idvChecks) ?? 'Unchecked'}
                    </InfoBlock>
                    <InfoBlock label="Previous eKYC">
                        {ekycExpired
                            ? 'Expired'
                            : getLatestKycResult(person.ekycChecks) ?? 'Unchecked'}
                    </InfoBlock>
                </Section>
                <Section>
                    <InfoBlock label="Date of birth">
                        {person.dateOfBirth ? (
                            <span data-testid="dob">
                                <Text type="secondary">Day:</Text> {person.dateOfBirth.day}
                                <br />
                                <Text type="secondary">Month:</Text> {person.dateOfBirth.month}
                                <br />
                                <Text type="secondary">Year:</Text> {person.dateOfBirth.year}
                            </span>
                        ) : (
                            <span>UNSET</span>
                        )}
                    </InfoBlock>
                    <InfoBlock label="National ID No.">{person.nationalId || 'UNSET'}</InfoBlock>
                </Section>
                <Section>
                    <InfoBlock label="Address">
                        {person.address ? (
                            <PersonAddress address={person.address} testId="address" />
                        ) : (
                            <span>UNSET</span>
                        )}
                    </InfoBlock>
                </Section>
            </PanelContent>
            <Divider />
            <ReadOnlyDocumentTabs
                loading={false}
                snapshotDocuments={person.documents}
                snapshot={snapshot}
                handleGetLinkToFile={handleGetLinkToFile}
            />
        </div>
    )
}

const PanelContent = styled.div`
    display: flex;
    justify-content: space-around;
`

const Section = styled.section`
    display: grid;
    gap: ${spacing.space8};
`

interface PanelHeaderProps {
    person: Person | UboResponse | CompanyStakeholderResponse
}

export const PanelHeader: FC<React.PropsWithChildren<PanelHeaderProps>> = ({ person }) => {
    const stakeholder = person as CompanyStakeholderResponse
    const manager = person as Person
    return (
        <Row>
            <span>
                <strong>{getPersonName(manager.name)}</strong>:{' '}
                {isUnknownShareholder(stakeholder)
                    ? 'Missing shareholder information'
                    : getRoleNames(manager.roles)}
            </span>
            {!isUnknownShareholder(stakeholder) && manager.kycPerson ? (
                <Tag color="green">In scope</Tag>
            ) : (
                <Tag color="red">Out of scope</Tag>
            )}
        </Row>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`

interface Props {
    management?: Management | CompanyStakeholderResponse[]
    snapshot: string
    error?: ErrorResponse
}

export const ReadonlyDirectors: FC<React.PropsWithChildren<Props>> = ({ management, snapshot }) => {
    const noManagement = management?.length === 0

    if (!management) {
        return <Skeleton data-testid="skeleton" />
    }

    if (noManagement) {
        return (
            <Empty description="There is no management associated with this company during this period." />
        )
    }

    return (
        <Collapse data-testid="directors">
            {management.map((manager) => {
                const stakeholder = manager as CompanyStakeholderResponse
                const person = manager as Person
                return (
                    <Panel
                        key={person.id}
                        disabled={isUnknownShareholder(stakeholder)}
                        header={<PanelHeader person={person} />}
                    >
                        {!isUnknownShareholder(stakeholder) && (
                            <DirectorPanel person={person} snapshot={snapshot} />
                        )}
                    </Panel>
                )
            })}
        </Collapse>
    )
}

export default ReadonlyDirectors
