import { Card, Tooltip, Typography } from 'antd'
import styled from 'styled-components'
import { capitalize } from 'lodash'
import { Link } from 'react-router-dom'
import { useGetCompany } from 'pages/customer-success/company-360/api/use-company'

const { Text } = Typography

const Container = styled.div`
    display: flex;
    flex-flow: column;
`

const CompanyLink: React.FC<{ companyId: string }> = ({ companyId }) => {
    const { company } = useGetCompany(companyId)

    return <Link to={`/customer-success/company-360/${companyId}`}>{company?.name}</Link>
}

export const EmployeeDetails = ({
    role,
    companyId,
    email,
}: {
    role?: string
    companyId?: string
    email?: string
}) => {
    return (
        <Card type="inner" title="Employee" style={{ marginBottom: '24px' }}>
            <Container>
                {role && (
                    <Text strong>
                        <Tooltip title="Role">{capitalize(role)}</Tooltip>
                    </Text>
                )}
                {companyId && <CompanyLink companyId={companyId} />}
                {email && <Text>{email}</Text>}
            </Container>
        </Card>
    )
}
