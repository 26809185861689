import { Tabs, Divider, Descriptions } from 'antd'
import Skeleton from 'components/content-skeleton'
import type { FC } from 'react'
import { Order, Manufacturer, DisplayDeliveryType } from 'store/modules/support/card-orders/types'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import type { Company } from 'types/deimos-company'

import RecipientTable from '../common/recipient-table'
import { addressPreview, displayManufacturer } from '../utils'

const { TabPane } = Tabs

export interface ConfirmOrderProps {
    company?: Company
    companyEmbossLine?: string
    manufacturer: Manufacturer
    fee: boolean
    orders: Order[]
}

const ConfirmOrder: FC<React.PropsWithChildren<ConfirmOrderProps>> = ({
    company,
    companyEmbossLine,
    manufacturer,
    fee,
    orders,
}) => {
    if (!orders) {
        return <Skeleton />
    }
    const totalNumberOfCards =
        orders.length === 0
            ? 0
            : orders
                  .map((order) => +order.cardOwners.length)
                  .reduce((previous, current) => previous + current)
    return (
        <>
            <Descriptions size="small" column={3}>
                <Descriptions.Item label="Company">
                    {company?.name || 'Failed to retrieve'}
                </Descriptions.Item>
                <Descriptions.Item label="Manufacturer">
                    {displayManufacturer(manufacturer)}
                </Descriptions.Item>
                <Descriptions.Item label="Cards total">{totalNumberOfCards}</Descriptions.Item>
                <Descriptions.Item label="Emboss line">{companyEmbossLine}</Descriptions.Item>
                <Descriptions.Item label="Fee">{fee ? 'Yes' : 'No'}</Descriptions.Item>
            </Descriptions>
            <StyledDivider />
            <Tabs defaultActiveKey="0" centered>
                {orders.map((order, index) => (
                    <TabPane key={index} tab={`Card order ${index + 1}`}>
                        <FlexContainer>
                            <Descriptions size="small" column={3}>
                                <Descriptions.Item label="Recipient name">
                                    {order.attention}
                                </Descriptions.Item>
                                <Descriptions.Item label="Recipient tel.">
                                    {order.phone ?? '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Delivery type">
                                    {order.deliveryType
                                        ? DisplayDeliveryType[order.deliveryType]
                                        : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Address">
                                    {addressPreview(order.address)}
                                </Descriptions.Item>
                            </Descriptions>
                            <RecipientTable cardOrder={order} />
                        </FlexContainer>
                    </TabPane>
                ))}
            </Tabs>
        </>
    )
}

const StyledDivider = styled(Divider)`
    margin-top: ${spacing.space16};
    margin-bottom: 0;
`

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space16};
`

export default ConfirmOrder
