import React, { useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { RootRouter } from 'bff'
import { createBffHooks } from 'packages/bff/create-bff-hooks'
import { Operation, httpBatchLink, httpLink, splitLink } from '@trpc/client'
import config from 'config/index'
import { getAccessToken } from './bff-session'

const trpc = createBffHooks<RootRouter>()

export const BffProvider = ({ children }: { children: React.ReactNode }) => {
    const [queryClient] = useState(() => new QueryClient())
    const [trpcClient] = useState(() =>
        trpc.createClient({
            links: [
                splitLink({
                    condition: shouldEnableBatching,
                    true: httpBatchLink({
                        url: removeTrailingSlash(config.endpoints.bff),
                        //  fix a limit to the batch size to avoid hitting the max URL length, the request will be split in multiple batches in case this limit is reached
                        maxURLLength: 10000,
                        async headers() {
                            return getHeaders()
                        },
                    }),
                    false: httpLink({
                        url: removeTrailingSlash(config.endpoints.bff),
                        async headers() {
                            return getHeaders()
                        },
                    }),
                }),
            ],
        })
    )

    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </trpc.Provider>
    )
}

function removeTrailingSlash(str: string) {
    return str.replace(/\/+$/, '')
}

async function getHeaders() {
    const accessToken = getAccessToken()
    return accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
}

function shouldEnableBatching(op: Operation) {
    const { skipBatch: skipBatchFromContext } = op.context as any

    const isMockingRequests = !!window.Cypress || window.___msw?.active

    const skipBatching = isMockingRequests || !!skipBatchFromContext

    return !skipBatching
}
