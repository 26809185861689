import React, { FC, useRef } from 'react'
import { iframeResizer } from 'iframe-resizer'
import styled from 'styled-components'

interface Props {
    title: string
    url: string
}

const EmbeddedMetabaseDashboard: FC<React.PropsWithChildren<Props>> = ({ url, title }) => {
    const ref = useRef<HTMLIFrameElement | null>(null)

    const resize = () =>
        ref.current &&
        iframeResizer(
            {
                checkOrigin: false,
                bodyMargin: 0,
                interval: 0,
                heightCalculationMethod: 'bodyScroll',
            },
            ref.current
        )

    return (
        <Iframe
            title={title}
            src={`${url}#bordered=false&titled=false`}
            scrolling="0"
            frameBorder="0"
            ref={ref}
            onLoad={resize}
        />
    )
}

const Iframe = styled.iframe`
    width: 100%;
    min-height: 100vh;
`

export default EmbeddedMetabaseDashboard
