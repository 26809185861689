import type { FC } from 'react'
import { Badge, Card, Divider, Empty, Skeleton, Space, Typography } from 'antd'
import { useCompanySpendStatus } from '../api/use-company-spend-status'
import type { Company360Overview, CompanyOverviewStatus } from 'bff/moons/generated/pandora-v1'
import { AlertOutlined, CalendarOutlined } from '@ant-design/icons'
import { bff } from '../bff'
import { formatDate } from 'packages/dates/dates'

const renderStatus = (status: CompanyOverviewStatus | undefined) => {
    if (!status) {
        return null
    }

    const text = `Company status is ${status.toLowerCase()}`
    const mapStatusToTag = {
        Active: <Badge status="success" text={text} />,
        Delinquent: <Badge status="error" text={text} />,
        Churning: <Badge status="error" text={text} />,
        Deleted: <Badge status="error" text={text} />,
    }

    return mapStatusToTag[status as keyof typeof mapStatusToTag]
}

interface Props {
    company360?: Company360Overview
    companyId: string
    loading: boolean
}

export const CompanyStatus: FC<Props> = ({ company360, companyId, loading }) => {
    const { data: companySpendStatusData, error: companySpendStatusError } = useCompanySpendStatus()
    const { data: subscriptionStartDate } = bff.companyStatus.getSubscriptionStartDate.useQuery({
        params: { companyId, limit: 1 },
    })

    const formattedDate = formatDate(subscriptionStartDate)

    if (loading || (!companySpendStatusData && !companySpendStatusError))
        return <Skeleton title={false} />

    if (company360?.payments && company360?.companyOverview) {
        return (
            <Space direction="vertical">
                {companySpendStatusData?.status === 'LIVE' ? (
                    <Badge status="success" text="Company spending is live" />
                ) : (
                    <>
                        <Badge
                            status="error"
                            text={`Company spend status is ${
                                companySpendStatusData?.status
                                    ? companySpendStatusData.status.toLowerCase()
                                    : 'unknown'
                            }`}
                        />
                        {companySpendStatusData?.status === 'BLOCKED' && (
                            <Card bodyStyle={{ color: 'red' }} size="small">
                                <AlertOutlined /> {companySpendStatusData?.statusReason}
                            </Card>
                        )}
                    </>
                )}
                {renderStatus(company360?.companyOverview?.status)}
                {formattedDate && (
                    <>
                        <Divider style={{ margin: '8px 0' }} />
                        <Typography.Text>
                            <CalendarOutlined />
                            <div data-testid="subscriptionStartDate">
                                Customer since {formattedDate}
                            </div>
                        </Typography.Text>
                    </>
                )}
            </Space>
        )
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
}
