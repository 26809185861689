import dayjs from 'packages/dayjs'
import { sort } from 'ramda'

import type * as styx from 'types/styx'
import type { PepCheckResponse } from 'bff/moons/generated/styx-v1'

const COMPANY_NAME_LIMIT = 100

export const isDanishOrSwedish = (address?: Partial<styx.DetailedAddress>) =>
    address?.country === 'DK' || address?.country === 'SE'

export const hasMissingKycInfo = (
    subjectId: string,
    missingInfo: styx.MissingKycInformation = []
) => missingInfo.some((item) => item.subjectId === subjectId)

export const hasMissingPepInfo = (person: styx.Person) => {
    const latestPepCheck = getLatestPepCheck(person.enhancedPepChecks)
    if (latestPepCheck?.falsePositive) {
        return false
    }
    const highestMatch = latestPepCheck?.pepMatches?.sort(
        (a, b) => (b.matchScore ?? 0) - (a.matchScore ?? 0)
    )[0]
    const allPepTypeInfoAvailable =
        highestMatch?.pepTypes?.every(
            (pepType) => pepType.level && pepType.positionCountryCode && pepType.type
        ) ?? true

    return !allPepTypeInfoAvailable
}

export const hasIncompleteDateOfBirth = (dateOfBirth?: Partial<styx.DateOfBirth>): boolean =>
    !dateOfBirth || !dateOfBirth.day || !dateOfBirth.month || !dateOfBirth.year

export const hasIncompleteAddress = (address?: Partial<styx.DetailedAddress>): boolean =>
    !address ||
    !(address.country || '').trim() ||
    !(address.locality || '').trim() ||
    !(address.postalCode || '').trim() ||
    !(address.street || '').trim() ||
    !(address.houseNumber || '').trim()

export const getChecksSortedChronologically = (kycChecks: styx.KycCheck[]) =>
    sort((a, b) => dayjs(a.created).valueOf() - dayjs(b.created).valueOf(), kycChecks)

export const getPepChecksSortedChronologically = (pepChecks: PepCheckResponse[]) =>
    sort((a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(), pepChecks)

export const getLatestKycCheck = (
    kycChecks: styx.KycCheck[],
    processedValue: string | null = null
): styx.KycCheck | undefined => {
    if (processedValue) {
        kycChecks = kycChecks.filter(
            (check) => check.processedValue === processedValue.substring(0, COMPANY_NAME_LIMIT)
        )
    }
    const sortedChecks = getChecksSortedChronologically(kycChecks)
    const numberOfChecks = kycChecks.length

    return sortedChecks[numberOfChecks - 1]
}

export const getLatestPepCheck = (pepChecks: PepCheckResponse[]): PepCheckResponse | undefined => {
    if (!pepChecks) {
        return undefined
    }
    const filteredPepChecks = pepChecks.filter((check) => check.result !== 'MANUALLY_APPROVED')
    const sortedPepChecks = getPepChecksSortedChronologically(filteredPepChecks)
    const numberOfChecks = pepChecks.length

    return sortedPepChecks[numberOfChecks - 1]
}

export const getLatestLegacyLegalNameKycCheck = (
    kycChecks: styx.KycCheck[]
): styx.KycCheck | undefined => {
    kycChecks = kycChecks.filter((check) => check.processedValue === undefined)

    const sortedChecks = getChecksSortedChronologically(kycChecks)
    const numberOfChecks = kycChecks.length

    return sortedChecks[numberOfChecks - 1]
}

export const determineScreeningStatus = (
    kycChecks: styx.KycCheck[],
    companyName: string,
    companyTradingName: string | null
) => {
    let lastLegalNameScreening = getLatestKycCheck(kycChecks, companyName)
    const lastTradingNameScreening = getLatestKycCheck(kycChecks, companyTradingName)

    if (lastLegalNameScreening === undefined) {
        lastLegalNameScreening = getLatestLegacyLegalNameKycCheck(kycChecks)
    }

    if (companyTradingName === null) {
        if (lastLegalNameScreening?.falsePositive) {
            return 'PASS'
        }
        return lastLegalNameScreening?.result
    } else if (kycChecks.length === 0) {
        return 'PENDING'
    } else if (
        (lastLegalNameScreening?.result === 'PASS' || lastLegalNameScreening?.falsePositive) &&
        (lastTradingNameScreening?.result === 'PASS' || lastTradingNameScreening?.falsePositive)
    ) {
        return 'PASS'
    } else if (lastLegalNameScreening === undefined || lastTradingNameScreening === undefined) {
        return 'Inconclusive'
    } else {
        return 'FAIL'
    }
}

export const getLatestKycResult = (
    kycChecks: styx.KycCheck[]
): styx.KycCheck['result'] | undefined => {
    const mostRecentCheck = getLatestKycCheck(kycChecks)

    if (mostRecentCheck?.expiresAt) {
        return 'FAIL'
    } else if (mostRecentCheck?.falsePositive) {
        return 'PASS'
    } else {
        return mostRecentCheck?.result
    }
}

export const getLatestPepStatus = (pepChecks: styx.KycCheck[]) => {
    const latestPepResult = getLatestKycResult(pepChecks)

    if (latestPepResult) {
        const pepPass = latestPepResult === 'PASS'

        const hasPassed = pepPass
        const hasFailed = latestPepResult === 'FAIL'
        const isPending = !hasFailed && !hasPassed && latestPepResult === 'PENDING'

        if (hasPassed) {
            return 'PASS'
        } else if (hasFailed) {
            return 'FAIL'
        } else if (isPending) {
            return 'PENDING'
        } else {
            return latestPepResult
        }
    }
}

export const getLatestSisStatus = (sisChecks: styx.KycCheck[]) => {
    const latestSisResult = getLatestKycResult(sisChecks)

    if (latestSisResult) {
        const pepPass = latestSisResult === 'PASS'

        const hasPassed = pepPass
        const hasFailed = latestSisResult === 'FAIL'
        const isPending = !hasFailed && !hasPassed && latestSisResult === 'PENDING'

        if (hasPassed) {
            return 'PASS'
        } else if (hasFailed) {
            return 'FAIL'
        } else if (isPending) {
            return 'PENDING'
        } else {
            return latestSisResult
        }
    }
}

export const getCombinedPepSisStatus = (pepStatus?: string, sisStatus?: string) => {
    if (pepStatus && sisStatus) {
        const sisPass = sisStatus === 'PASS'
        const pepPass = pepStatus === 'PASS'

        const hasPassed = sisPass && pepPass
        const hasFailed = sisStatus === 'FAIL' || pepStatus === 'FAIL'
        const isPending =
            !hasFailed && !hasPassed && (pepStatus === 'PENDING' || sisStatus === 'PENDING')

        if (hasPassed) {
            return 'PASS'
        } else if (hasFailed) {
            return 'FAIL'
        } else if (isPending) {
            return 'PENDING'
        } else {
            return pepStatus
        }
    }
}

export const getDocument = (
    documents: styx.Document[],
    category: styx.DocumentCategory
): styx.Document | undefined => documents.find((document) => document.category === category)

export const isDocumentWithoutFiles = (document?: styx.Document): boolean =>
    !document || document?.files.length <= 0

export const getHasPassedRequiredKycChecks = (
    {
        pepChecks,
        sisChecks,
        ekycChecks,
        idvChecks,
    }: Record<'pepChecks' | 'sisChecks' | 'ekycChecks' | 'idvChecks', styx.KycCheck[]>,
    isVerified: boolean
) => {
    const latestPepStatus = getLatestPepStatus(pepChecks)
    const latestSisStatus = getLatestSisStatus(sisChecks)
    const latestPepSisStatus = getCombinedPepSisStatus(latestPepStatus, latestSisStatus)
    const latestEKycResult = getLatestKycResult(ekycChecks)
    const hasPassed = (result?: string) => result?.toUpperCase() === 'PASS'
    const hasPassingIdvCheck = idvChecks.some((check) => hasPassed(check.result))

    return (
        hasPassed(latestPepSisStatus) &&
        (hasPassed(latestEKycResult) || hasPassingIdvCheck || isVerified)
    )
}

export const getPersonMissingKycDetails = (
    missingInfo: styx.MissingKycInformation = [],
    subjectId: string
) => missingInfo.filter((info) => info.subjectId === subjectId)

export const removeCommonPersons = (
    shareholders: (styx.Person | styx.UboResponse)[] | undefined,
    management: (styx.Person | styx.UboResponse)[] | undefined
): (styx.Person | styx.UboResponse)[] => {
    if (!shareholders) return []
    if (!management) return shareholders
    const managerIds = new Set(management.map((manager) => manager.id))
    return shareholders.filter((shareholder) => !managerIds.has(shareholder.id))
}
