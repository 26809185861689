import type { FC } from 'react'

import { Select, Tooltip } from 'antd'

import { bff } from 'pages/customer-success/company/bff'
import type { PostalAddress } from 'bff/moons/generated/beyond'
import type { BillingAdmin } from 'pages/customer-success/company/billing/billing.bff'

interface Props {
    companyId: string
    billingAddress?: PostalAddress
}

const BillingAdminSelector: FC<React.PropsWithChildren<Props>> = ({
    companyId,
    billingAddress,
}) => {
    const { data: billingAdmins } = bff.billing.getCompanyBillingAdmins.useQuery({ companyId })
    const { mutate: updateBillingAddress, isLoading: isLoadingUpdate } =
        bff.billing.updateBillingAddress.useMutation()
    const { data: contactEmail, isLoading: isLoadingContact } =
        bff.billing.getCompanyContactEmail.useQuery({ companyId })

    if (!billingAdmins) {
        return null
    }

    return (
        <Select
            onChange={(email) => {
                updateBillingAddress({
                    updateCompanyBillingInformationRequest: {
                        addressLine1: billingAddress?.addressLine1 || '',
                        addressLine2: billingAddress?.addressLine2,
                        city: billingAddress?.city,
                        contactEmail: email,
                        countryCode: billingAddress?.country || 'UNDEFINED',
                        postalCode: billingAddress?.postalCode || '',
                        region: billingAddress?.region,
                        state: billingAddress?.state,
                    },
                    params: { companyId },
                })
            }}
            loading={isLoadingUpdate || isLoadingContact}
            value={contactEmail}
            placeholder="Select billing contact"
        >
            {billingAdmins.map(({ id, email, firstName, lastName }: BillingAdmin) => (
                <Select.Option value={email} key={id}>
                    <Tooltip title={`${firstName} ${lastName}`} placement="left">
                        {email}
                    </Tooltip>
                </Select.Option>
            ))}
        </Select>
    )
}

export default BillingAdminSelector
