import { useRef } from 'react'
import Item from 'antd/es/list/Item'
import { Button, Descriptions, Space } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import type { Team, FormValuesProfile } from '../../types'
import { Input } from 'formik-antd'
import { insertIntoArray } from '../../utils'
import { useFormikContext } from 'formik'
import useUpdateProfileFormValues from '../../hooks/use-update-profile-form-values'

type EditableTeamProps = Team & {
    index: number
    toggleEditing: (value: boolean) => void
    deleteTeam: (index: number) => void
}

const EditableTeam = ({
    name,
    size,
    reviewerCount,
    index,
    toggleEditing,
    deleteTeam,
}: EditableTeamProps) => {
    const { values } = useFormikContext<FormValuesProfile>()
    const { updateProperty } = useUpdateProfileFormValues('teams')
    const initialValues = useRef<Team>({ name, size, reviewerCount })

    const remove = () => {
        toggleEditing(false)
        deleteTeam(index)
    }

    const reset = () => {
        const updatedTeams = insertIntoArray<Team>(values.teams, initialValues.current, index)
        updateProperty(updatedTeams)
    }

    const cancel = () => {
        reset()
        toggleEditing(false)
    }

    const save = () => {
        toggleEditing(false)
    }

    return (
        <Item
            actions={[
                <Space key="action-buttons">
                    <Button type="primary" size="small" onClick={save}>
                        Done
                    </Button>
                    <Button type="default" size="small" onClick={cancel}>
                        Cancel
                    </Button>
                    <Button type="primary" danger size="small" onClick={remove}>
                        Delete
                    </Button>
                </Space>,
            ]}
        >
            <Descriptions
                column={2}
                title={
                    <Input
                        size="small"
                        name={`teams[${index}].name`}
                        value={name ?? ''}
                        type="text"
                    />
                }
            >
                <DescriptionsItem label="Size">
                    <Input
                        size="small"
                        name={`teams[${index}].size`}
                        value={size ?? 0}
                        type="number"
                    />
                </DescriptionsItem>
                <DescriptionsItem label="Reviewers">
                    <Input
                        size="small"
                        name={`teams[${index}].reviewerCount`}
                        value={reviewerCount ?? 0}
                        type="number"
                    />
                </DescriptionsItem>
            </Descriptions>
        </Item>
    )
}

export default EditableTeam
