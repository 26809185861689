import type { BulkUpdatePlanParams, PlanResponse } from 'bff/moons/generated/janus-v1'
import { difference } from 'lodash'
import { LimitLabels } from '../enums'
import { preparePlanForUpdate } from '../plan-table/utils'
import { bff } from '../../bff'

export type PlanDifferences = {
    name: string
    country: string
    featuresAdded: string[]
    featuresRemoved: string[]
    addonsAdded: string[]
    addonsRemoved: string[]
    limits: string
}

const filterForChangedPlans = (payload: BulkUpdatePlanParams[]) => (plan: PlanResponse) =>
    payload.findIndex(
        ({ planType, country }) => plan.type === planType && plan.country === country
    ) >= 0

const getIsNotNull = <T,>(value: T): value is Exclude<T, null> => value !== null

const useGetDifferencesForPlans = (
    payload: BulkUpdatePlanParams[],
    plans: PlanResponse[]
): {
    planDifferences: PlanDifferences[]
    isLoading: boolean
} => {
    const { data: entitlements = [], isLoading } = bff.features.getFeatures.useQuery()

    const getFeatureNameFromID = (featureID: string): string => {
        const [entitlement] = entitlements.filter((feature) => feature?.id === featureID)
        return entitlement?.name ?? ''
    }

    const differences = plans?.filter(filterForChangedPlans(payload))?.map((plan) => {
        const basePlan = preparePlanForUpdate(plan, [], [], {})
        const [payloadPlan] = payload.filter(({ planType }) => basePlan.planType === planType)
        const basePlanFeatureNames = basePlan?.featureIds?.map(getFeatureNameFromID)
        const basePlanAddonNames = basePlan?.addonIds?.map(getFeatureNameFromID)
        const payloadPlanFeatureNames = payloadPlan?.featureIds?.map(getFeatureNameFromID)
        const payloadPlanAddonNames = payloadPlan?.addonIds?.map(getFeatureNameFromID)
        const basePlanLimit = plan.limits.maxAllowedEmployees
        const payloadLimit = payloadPlan.limits.maxAllowedEmployees
        const hasLimit = basePlanLimit || payloadLimit
        const hasLimitChanged = basePlanLimit !== payloadLimit
        const limits = hasLimitChanged
            ? `${LimitLabels.MaxAllowedEmployees}: ${
                  basePlanLimit ? `${basePlanLimit} -> ` : ' '
              }${payloadLimit}`
            : ''

        const featuresAdded = difference(payloadPlanFeatureNames, basePlanFeatureNames)
        const featuresRemoved = difference(basePlanFeatureNames, payloadPlanFeatureNames)
        const addonsAdded = difference(payloadPlanAddonNames, basePlanAddonNames)
        const addonsRemoved = difference(basePlanAddonNames, payloadPlanAddonNames)

        if (
            !(
                featuresAdded.length ||
                featuresRemoved.length ||
                addonsAdded.length ||
                addonsRemoved.length
            )
        ) {
            return null
        }

        return {
            name: plan.name,
            country: plan.country,
            featuresAdded,
            featuresRemoved,
            addonsAdded,
            addonsRemoved,
            limits: hasLimit ? limits : '',
        }
    })

    const filtered = differences.filter(getIsNotNull)

    return {
        planDifferences: filtered,
        isLoading,
    }
}

export default useGetDifferencesForPlans
