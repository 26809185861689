import Item from 'antd/es/list/Item'
import { Button, Descriptions } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import type { Team } from '../../types'

type ReadableTeamProps = Team & {
    toggleEditing: (value: boolean) => void
}

const ReadableTeam = ({ name, size, reviewerCount, toggleEditing }: ReadableTeamProps) => {
    return (
        <Item
            actions={[
                <Button
                    key="edit-button"
                    type="primary"
                    size="small"
                    onClick={() => toggleEditing(true)}
                >
                    Edit
                </Button>,
            ]}
        >
            <Descriptions title={name} column={2}>
                <DescriptionsItem label="Size">{size}</DescriptionsItem>
                <DescriptionsItem label="Reviewers">{reviewerCount}</DescriptionsItem>
            </Descriptions>
        </Item>
    )
}

export default ReadableTeam
