import { Typography } from 'antd'
import type { FC } from 'react'
import styled from 'styled-components'

const { Text } = Typography

interface Props {
    label: string
    value: string
    copyable?: boolean
}

const InfoElemVertical: FC<React.PropsWithChildren<Props>> = ({ label, value, copyable }) => (
    <Container>
        {label}
        <Text copyable={copyable} type="secondary">
            {value}
        </Text>
    </Container>
)

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-break: break-all;
`

export default InfoElemVertical
