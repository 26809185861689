import { Formik } from 'formik'
import { Form, SubmitButton } from 'formik-antd'

import { Input } from 'components/input/input'
import type { FC } from 'react'
import { FormValues, validationSchema } from './validation-schema'

interface Props {
    onSubmit: (values: FormValues) => void
}

const DocumentForm: FC<React.PropsWithChildren<Props>> = ({ onSubmit }) => {
    return (
        <Formik
            initialValues={{
                title: '',
                description: '',
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            <Form layout="vertical">
                <Input name="title" label="Document Title" placeholder="title" testId="title" />
                <Input name="description" label="Description" placeholder="description" />
                <SubmitButton aria-label="submit">Save</SubmitButton>
            </Form>
        </Formik>
    )
}

export default DocumentForm
