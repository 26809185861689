import type { ProofOfFunds } from '@pleo-io/deimos'
import { Button, notification, Result, Table, Tag } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import { ActionsContainer } from 'components/layout-containers'
import React, { FC, useState } from 'react'
import {
    updateProofOfFunds,
    useGetLatestProofOfFundsPerSourceByStatus,
} from 'services/deimos/proof-of-funds'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import type { Status } from 'types/proof-of-funds'
import dayjs from 'packages/dayjs'

export interface ProofOfFundsAction {
    loading: boolean
    name: string
    onAction: (id: string) => Promise<void>
}

interface SourcesOfFundsTableProps {
    action: ProofOfFundsAction
    loading: boolean
    proofOfFunds: ProofOfFunds[]
}

const SourcesOfFundsTable: FC<React.PropsWithChildren<SourcesOfFundsTableProps>> = ({
    action,
    loading,
    proofOfFunds,
}) => {
    const columns: ColumnProps<ProofOfFunds>[] = [
        {
            title: 'Account',
            dataIndex: 'senderBank',
        },
        {
            title: 'Sender',
            dataIndex: 'senderInformation',
        },
        {
            title: 'Type',
            render: (_, { firstLoad, newSource }) => {
                return (
                    <Info>
                        {firstLoad && <Tag color="orange">First load</Tag>}
                        {newSource && <Tag color="volcano">New source</Tag>}
                        {!newSource && <Tag color="volcano">Past source</Tag>}
                    </Info>
                )
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount, { currency }) => (
                <span>
                    {amount} {currency}
                </span>
            ),
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: 'Performed at',
            dataIndex: 'performedAt',
            render: (performedAt) => dayjs(performedAt).format('lll'),
            sorter: (a, b) => dayjs(a.performedAt).valueOf() - dayjs(b.performedAt).valueOf(),
        },
        {
            title: 'Action',
            fixed: 'right',
            render: (_, { id }) => (
                <ActionsContainer>
                    <Button
                        type="link"
                        disabled={action.loading}
                        onClick={() => action.onAction(id)}
                    >
                        {action.name}
                    </Button>
                </ActionsContainer>
            ),
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={proofOfFunds}
            loading={loading}
            pagination={false}
            scroll={{ x: 'max-content' }}
            rowKey="id"
        />
    )
}

interface SourcesOfFundsTableContainerProps {
    actionName: string
    actionStatus: Status
    companyId: string
    status: Status
}

const SourcesOfFundsTableContainer: FC<
    React.PropsWithChildren<SourcesOfFundsTableContainerProps>
> = ({ actionName, actionStatus, companyId, status }) => {
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)
    const {
        data: proofOfFunds,
        error,
        isValidating: loading,
        mutate,
    } = useGetLatestProofOfFundsPerSourceByStatus(companyId, status)

    const updateStatus = async (id: string) => {
        try {
            setIsUpdatingStatus(true)
            await updateProofOfFunds(companyId, id, actionStatus)
            notification.info({ message: `Successfully ${actionName.toLowerCase()}d source` })
            mutate()
        } catch (e) {
            notification.error({
                description: `Unable to ${actionName.toLowerCase()} proof of funds`,
                message: (e as Error).message,
            })
        } finally {
            setIsUpdatingStatus(false)
        }
    }

    if (error) {
        return <Result status="500" title="Something went wrong" subTitle={error.message} />
    }

    return (
        <SourcesOfFundsTable
            action={{ name: actionName, loading: isUpdatingStatus, onAction: updateStatus }}
            loading={!proofOfFunds && loading}
            proofOfFunds={proofOfFunds ?? []}
        />
    )
}

export default SourcesOfFundsTableContainer

const Info = styled.div`
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${spacing.space8};
`
