import React, { FC, useState } from 'react'
import { PaperClipOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, message, Table, Tooltip } from 'antd'

import type { DocumentFile, DocumentFileRequest } from 'types/styx'
import type { ColumnProps } from 'antd/lib/table'
import { ActionsContainer } from 'components/layout-containers'
import dayjs from 'packages/dayjs'
import EditDocumentFileModal from '../document-files/edit-document-file-modal'

interface Props {
    getLinkToFile: (fileId: string, download?: boolean) => Promise<string>
    files: DocumentFile[]
    onRemoveFile?: (file: string) => Promise<boolean>
    editDocumentFile?: (fileId: string, documentFileRequest: DocumentFileRequest) => Promise<void>
}

const FileList: FC<React.PropsWithChildren<Props>> = ({
    getLinkToFile,
    files,
    onRemoveFile,
    editDocumentFile,
}) => {
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([])

    const hasSelectedFiles = selectedFileIds.length

    const sortedFiles = files
        // slice first to create a new array, otherwise we mutate the existing
        //and an error occurs from crashing the app since the array is frozen in strict mode
        .slice()
        // Sort based on ID since some files don't have a name and this is the only reliable property we have
        .sort((a, b) => a.id.localeCompare(b.id))

    const onPreview = async (fileId: string) => {
        try {
            const url = await getLinkToFile(fileId)
            window.open(url, '_blank', 'toolbar=0,location=0,menubar=0')
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const onDownload = async (fileId: string) => {
        try {
            const download = await getLinkToFile(fileId, true)
            window.open(download)
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const columns: ColumnProps<DocumentFile>[] = [
        {
            title: 'File name',
            dataIndex: 'fileName',
            key: 'fileName',
            ellipsis: {
                showTitle: false,
            },
            render: (fileName: string, { id }: any) => (
                <ActionsContainer>
                    <Tooltip placement="topLeft" title={fileName ?? id}>
                        <Button
                            onClick={() => onPreview(id)}
                            type="link"
                            data-testid="preview-button"
                        >
                            <PaperClipOutlined /> {fileName ?? id}
                        </Button>
                    </Tooltip>
                </ActionsContainer>
            ),
        },
        {
            title: 'Upload date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 180,
            render: (createdDate: string) =>
                createdDate ? dayjs(createdDate).format('lll') : 'No upload date available',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (description: string) => (
                <span data-testid="document-file-description">{description}</span>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            width: 230,
            render: (id: string, record: DocumentFile) => (
                <ActionsContainer>
                    <Button onClick={() => onDownload(id)} type="link">
                        <DownloadOutlined /> Download
                    </Button>
                    {editDocumentFile && (
                        <EditDocumentFileModal
                            documentFile={record}
                            editDocumentFile={editDocumentFile}
                        />
                    )}
                    {onRemoveFile && (
                        <Button onClick={() => onRemoveFile(id)} type="link" danger>
                            <DeleteOutlined /> Delete
                        </Button>
                    )}
                </ActionsContainer>
            ),
        },
    ].filter((column) => {
        return editDocumentFile || column.dataIndex !== 'description'
    }) as ColumnProps<DocumentFile>[]

    return (
        <>
            <Table
                dataSource={sortedFiles}
                rowKey="id"
                columns={columns}
                size="small"
                rowSelection={{
                    type: 'checkbox',
                    onChange: (selectedRowKeys) => {
                        // Need string cast since antd types selectedRowKeys as React.ReactText[]
                        setSelectedFileIds(selectedRowKeys as string[])
                    },
                }}
            />
            <Button
                disabled={!hasSelectedFiles}
                onClick={() => selectedFileIds.forEach((fileId) => onPreview(fileId))}
                type="link"
            >
                <PaperClipOutlined /> Preview selected
            </Button>
            <Button
                disabled={!hasSelectedFiles}
                onClick={() => selectedFileIds.forEach((fileId) => onDownload(fileId))}
                type="link"
            >
                <DownloadOutlined /> Download selected
            </Button>
        </>
    )
}

export default FileList
