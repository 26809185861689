import type { FC } from 'react'
import { useRiskVersionsLatestUpdate } from 'services/themis/customer-risk-rating'
import { PageHeader } from '@ant-design/pro-layout'
import { Card, Tabs, Table, Typography, Tag, Result } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import dayjs from 'packages/dayjs'
import { Link } from 'react-router-dom'
import Spinner from 'components/content-spinner'
import type { RiskVersion } from 'types/customer-risk-rating'
import { CrrCompareVersions } from './crr-compare-versions'

const { TabPane } = Tabs
const { Text } = Typography

export const CrrVersions: FC<React.PropsWithChildren<{ allVersions: RiskVersion[] }>> = ({
    allVersions,
}) => {
    const columns = [
        {
            title: 'Version',
            dataIndex: 'description',
            key: 'version',
            render: (version: string, record: RiskVersion) => (
                <Link to={`../crr-configuration/${record.id}`}>{version}</Link>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'status',
            render: (status: boolean) => (
                <Text>{status ? <Tag color="blue">Active</Tag> : <Tag>Inactive</Tag>}</Text>
            ),
        },
        {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt: string) => dayjs(updatedAt).format('lll'),
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => dayjs(createdAt).format('lll'),
        },
        {
            title: 'Created by',
            dataIndex: 'createdBy',
            key: 'createdBy',
            render: (createdBy: string) => (createdBy ? <Text>{createdBy}</Text> : <Text>-</Text>),
        },
    ]
    return <Table columns={columns} dataSource={allVersions} rowKey="id" />
}

const CrrVersionsContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const { data: allVersions, error } = useRiskVersionsLatestUpdate()

    if (error) {
        return <Result title="Error retrieving risk versions" />
    }

    if (!allVersions) {
        return <Spinner />
    }

    return (
        <>
            <PageHeader
                title="CRR Versions"
                ghost={false}
                style={{ position: 'sticky', top: 0 }}
            ></PageHeader>
            <PageContentLayout>
                <Card>
                    <Tabs>
                        <TabPane tab="Versions" key={1}>
                            <CrrVersions allVersions={allVersions} />
                        </TabPane>
                        <TabPane tab="Compare versions">
                            <CrrCompareVersions allVersions={allVersions} />
                        </TabPane>
                    </Tabs>
                </Card>
            </PageContentLayout>
        </>
    )
}

export default CrrVersionsContainer
