import { fetcher } from 'services/cupid/request'
import useSWR from 'swr'

export interface DirectDebitEligibility {
    status: EligibilityStatus
    companyId: string
}

export enum EligibilityStatus {
    ELIGIBLE = 'ELIGIBLE',
    INELIGIBLE = 'INELIGIBLE',
    NOT_FOUND = 'N/A',
}

export const useDirectDebitEligibility = (companyId: string | undefined) => {
    const result = useSWR<DirectDebitEligibility>(
        companyId ? `rest/v4/direct-debits/eligibility?companyId=${companyId}` : null,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    const isEligible = result.data?.status ?? EligibilityStatus.NOT_FOUND
    return {
        isEligible: isEligible,
        isLoading: !result.data && !result.error,
    }
}
