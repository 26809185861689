import { useState } from 'react'
import {
    Table,
    Button,
    Modal,
    message,
    Space,
    Form,
    Input,
    Typography,
    Select,
    Tooltip,
} from 'antd'
import type { ColumnFilterItem, ColumnsType } from 'antd/lib/table/interface'

import type { User } from 'types/user'
import type { UsersMutations } from 'services/auth/users'
import styled from 'styled-components'
import Permissions from 'components/permissions/admin-permissions'
import dayjs from 'packages/dayjs'
import { getUserName } from 'utils/user'
import { useNavigate } from 'react-router-dom'
import { CopyOutlined, HistoryOutlined } from '@ant-design/icons'
import { useHasPermissions } from 'components/permission-guard/permission-guard'

import { bff } from '../group-permissions/bff'

interface Props {
    users?: User[]
    setAdminPermission: UsersMutations['setAdminPermission']
    unsetAdminPermission: UsersMutations['unsetAdminPermission']
}

interface CreatePermissionValues {
    tag: string
    description: string
    ownerId: string
    isOwner: boolean
    teamId: string
    isHighSecurity: boolean
}

const Admins = ({ users, setAdminPermission, unsetAdminPermission }: Props) => {
    const [activeUser, setActiveUser] = useState<User | null>(null)
    const [createPermissionModalOpen, setCreatePermissionModalOpen] = useState(false)
    const [createPermissionValues, setCreatePermissionValues] = useState<
        CreatePermissionValues | undefined
    >()
    const canViewRolesHistory = useHasPermissions(['roles-history'])
    const navigate = useNavigate()

    const { data: oktaPermissions, isLoading: oktaPermissionsLoading } =
        bff.permissions.listAllPermissions.useQuery(
            { params: { include_deleted: false } },
            { refetchOnWindowFocus: false }
        )
    const { data: oktaUserGroups } = bff.permissions.getUserGroups.useQuery()

    const { mutate: createPermission } = bff.permissions.createPermission.useMutation()

    const formIsValid =
        createPermissionValues &&
        createPermissionValues?.tag?.length > 0 &&
        createPermissionValues?.description?.length > 0 &&
        createPermissionValues?.teamId?.length > 0

    const submitForm = async () => {
        if (!formIsValid) {
            return
        }

        const mapFormToPermission = () => ({
            description: createPermissionValues?.description,
            isHighSecurity: Boolean(createPermissionValues?.isHighSecurity),
            ownerId: createPermissionValues?.teamId,
            tag: createPermissionValues?.tag,
        })

        const newPermission = mapFormToPermission()

        await createPermission({
            options: newPermission,
        })

        setCreatePermissionModalOpen(false)
    }

    const adminPermissionFilters: ColumnFilterItem[] | undefined = oktaPermissions?.map(
        (permission) => ({
            value: permission?.tag,
            text: permission?.description,
        })
    )

    const addPermissionToActiveUser = (nextPermission: string) => {
        setActiveUser((prevState) => {
            if (prevState === null) {
                return prevState
            } else {
                return {
                    ...prevState,
                    permissions: prevState.permissions.concat(nextPermission),
                }
            }
        })
    }

    const removePermissionFromActiveUser = (permissionToRemove: string) => {
        setActiveUser((prevState) => {
            if (prevState === null) {
                return prevState
            } else {
                return {
                    ...prevState,
                    permissions: prevState.permissions.filter(
                        (permission) => permission !== permissionToRemove
                    ),
                }
            }
        })
    }

    const onPermissionChange = async (permissions: string[], checked: boolean) => {
        try {
            if (checked) {
                await permissions.forEach(async (permission): Promise<void> => {
                    addPermissionToActiveUser(permission)
                    await setAdminPermission(activeUser?.id!, permission)
                })
            } else {
                await permissions.forEach(async (permission): Promise<void> => {
                    removePermissionFromActiveUser(permission)
                    await unsetAdminPermission(activeUser?.id!, permission)
                })
            }
        } catch (e) {
            message.error(`Failed to update permissions: ${(e as Error).message}`)
        }
    }

    const columns: ColumnsType<User> = [
        {
            title: 'Name',
            render: (_: string, user: User) => getUserName(user),
            sorter: (a: User, b: User) => getUserName(a).localeCompare(getUserName(b)),
        },
        {
            title: 'Email',
            dataIndex: 'email',
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (createdAt) => dayjs(createdAt).format('lll'),
            sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            defaultSortOrder: 'descend',
            filters: adminPermissionFilters,
            sorter: (a, b) => a.permissions.length - b.permissions.length,
            onFilter: (value, record) => record.permissions.includes(String(value)),
            render: (_, record) => (
                <Space align="end">
                    <Button type="primary" onClick={() => setActiveUser(record)}>
                        Manage permissions ({record.permissions.length})
                    </Button>
                    {canViewRolesHistory && (
                        <Button
                            icon={<HistoryOutlined />}
                            onClick={() =>
                                navigate(
                                    `/product/user-provisioning?tab=roles-history&userId=${record.id}`
                                )
                            }
                        >
                            Roles history
                        </Button>
                    )}
                </Space>
            ),
        },
    ]

    const copyPermissions = () => {
        if (activeUser) {
            navigator.clipboard.writeText(activeUser.permissions.join(', '))
            message.success('Permissions copied to clipboard')
        }
    }

    const options = activeUser
        ? activeUser.permissions.map((permission) => ({
              label: permission,
              value: permission,
          }))
        : []

    const handleChange = (value: string[]) => {
        const newPermissions = value.filter(
            (permission) => !activeUser?.permissions.includes(permission)
        )
        onPermissionChange(newPermissions, true)
    }

    return (
        <>
            <Table
                dataSource={users}
                columns={columns}
                rowKey="id"
                loading={!users || oktaPermissionsLoading}
                footer={() => (
                    <Button type="primary" onClick={() => setCreatePermissionModalOpen(true)}>
                        Create new permission
                    </Button>
                )}
            />
            <StyledModal
                centered
                width={1000}
                footer={null}
                open={activeUser !== null}
                onCancel={() => setActiveUser(null)}
                title={
                    <>
                        View permissions{' '}
                        <Tooltip title="Copy permissions to clipboard">
                            <CopyOutlined onClick={() => copyPermissions()} />
                        </Tooltip>
                    </>
                }
            >
                {activeUser && (
                    <>
                        <Permissions
                            onPermissionChange={onPermissionChange}
                            permissions={activeUser.permissions}
                        />
                        <br />
                        Paste permissions here to add them to the user:
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            onChange={handleChange}
                            tokenSeparators={[', ']}
                            options={options}
                        />
                    </>
                )}
            </StyledModal>
            <Modal
                centered
                width={750}
                footer={null}
                open={createPermissionModalOpen}
                onCancel={() => setCreatePermissionModalOpen(false)}
                title="Create new permission"
                destroyOnClose
            >
                <Form
                    onValuesChange={(vals) => {
                        setCreatePermissionValues({
                            ...createPermissionValues,
                            ...vals,
                        })
                    }}
                    initialValues={{
                        tag: '',
                        description: '',
                        ownerId: '',
                        isOwner: false,
                        teamId: '',
                        isHighSecurity: false,
                    }}
                >
                    <Form.Item name="tag" label="Tag (name of permission)">
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        label="Select team"
                        name="teamId"
                        help={
                            <Typography.Text type="secondary">
                                Can't see the team you're looking for? Contact{' '}
                                <Typography.Link
                                    href="https://getpleo.slack.com/app_redirect?channel=team-business-operations-enablement"
                                    target="_blank"
                                >
                                    #team-business-operations-enablement
                                </Typography.Link>
                            </Typography.Text>
                        }
                    >
                        <Select
                            disabled={Boolean(
                                createPermissionValues && createPermissionValues.isOwner === true
                            )}
                            showSearch
                            options={oktaUserGroups?.map((group: any) => ({
                                value: group.id,
                                label: group.name,
                            }))}
                            optionFilterProp="label"
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item name="isHighSecurity" label="High security">
                        <Input type="checkbox" style={{ width: '20px' }} />
                    </Form.Item>
                    <Button type="primary" onClick={() => submitForm()} disabled={!formIsValid}>
                        Create permission
                    </Button>
                </Form>
            </Modal>
        </>
    )
}

const StyledModal = styled(Modal)`
    .ant-modal-content {
        height: 700px;
        overflow: auto;
    }
`

export default Admins
