import type { ErrorResponse, PaginatedResponse } from '@pleo-io/deimos'
import qs from 'qs'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import type { PersonSearchResponse } from 'types/styx'
import { useCursorPagination } from 'utils/use-cursor-pagination'
import { fetcher } from './request'
import { isEmpty, isNumber, omitBy } from 'lodash'

export interface PersonQuery {
    firstName?: string
    middleName?: string
    lastName?: string
    dateOfBirth?: number
    monthOfBirth?: number
    yearOfBirth?: number
    inScope?: string
}
export const defaultLimit = 10

export declare type GetPersonSearchSortingColumns = ''

export const useFindPerson = () => {
    const [query, setQuery] = useState<PersonQuery>({})
    const [persons, setPersons] = useState<PersonSearchResponse[]>([])
    const [limit, setLimit] = useState<number | undefined>(defaultLimit)

    const {
        paginationRequest,
        setCurrentPagination,
        resetFirstPage,
        updateLimit: cursorUpdateLimit,
        ...paginationMutations
    } = useCursorPagination({ limit: defaultLimit, sorting: [] })

    const searchQuery = (personQuery: PersonQuery) => {
        const person = omitBy(personQuery, (value) => {
            return isEmpty(value) && !isNumber(value)
        })
        setQuery(person)
        resetFirstPage()
    }

    const updateLimit = (updatedLimit: number) => {
        cursorUpdateLimit(updatedLimit)
        resetFirstPage()
    }

    const response = useSWR<
        PaginatedResponse<PersonSearchResponse, GetPersonSearchSortingColumns>,
        ErrorResponse
    >(
        `rest/v2/persons?${qs.stringify({
            ...paginationRequest,
            ...query,
        })}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    const pagination = response.data?.pagination
    const data = response.data?.data

    useEffect(() => {
        if (pagination) {
            setCurrentPagination(pagination)
            setLimit(paginationRequest.limit)
        }
    }, [pagination, setCurrentPagination, paginationRequest.limit])

    useEffect(() => {
        if (data) {
            setPersons(data)
        }
    }, [data])

    return {
        ...response,
        ...paginationMutations,
        loading: !response.data && !response.error,
        persons,
        pagination,
        query,
        searchQuery,
        limit,
        resetFirstPage,
        updateLimit,
    }
}
