import { notification } from 'antd'
import { deleteOffboarding } from 'services/deimos/companies'

export const onDeleteOffboarding = async (companyId: string) => {
    try {
        await deleteOffboarding(companyId)
        notification.info({ message: 'Successfully cancelled offboarding process' })
    } catch (e) {
        notification.error({ message: 'Failed to cancelled offboarding' })
    }
}
