import RefreshCompanyStructure from './components/refresh-company-structure/refresh-company-structure'
import RequestInformation from './components/request-information/request-information'
import CompanySpendingStatus from './components/company-spending/company-spending'

import { WidgetSection } from './compliance-info.styled'

export const CompanyActions = ({ companyId }: { companyId: string }) => {
    return (
        <>
            <WidgetSection style={{ marginBottom: '1rem' }}>
                <RefreshCompanyStructure companyId={companyId} />
                <RequestInformation companyId={companyId} />
                <CompanySpendingStatus companyId={companyId} />
            </WidgetSection>
        </>
    )
}
