import type { FC } from 'react'
import { Card } from 'antd'

import { useLocation, useNavigate, Routes, Route } from 'react-router-dom'

import ProtectedRoute from 'router/protected-route'
import Employees from 'components/employees/employees'

import Documents from './documents/documents'
import People from './people/people'
import RiskAssessment from './risk-assessment/risk-assessment'
import Ownership from './ownership/ownership'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import SourcesOfFunds from './sources-of-funds/sources-of-funds'
import ComplianceNote from './notes/complianceNote'
import { Entitlements } from 'pages/compliance/company/entitlements'
import { ComplianceInfo } from './compliance-info/compliance-info'
import { KycOverview } from './kyc-overview/kyc-overview'
import { peopleSectionLabel } from './utils'
import { ProofOfBusiness } from 'pages/compliance/company/proof-of-business/proof-of-business'
import { StpCheck } from 'pages/compliance/company/stp-check/stp-check'
import { OddCases } from './odd-cases/odd-cases'

const DEFAULT_TAB = 'stp-check'

const CompanyTabs: FC<
    React.PropsWithChildren<{
        companyId: string
        companyCountry?: string
        onboardingSource?: string
    }>
> = ({ companyId, companyCountry, onboardingSource }) => {
    const hasNotesPermissions = useHasPermissions(['aml'])

    const location = useLocation()
    const navigate = useNavigate()
    const defaultActiveKey = location.pathname.split('/').reverse()[0] ?? DEFAULT_TAB
    const activeTabKey = defaultActiveKey != companyId ? defaultActiveKey : DEFAULT_TAB

    const tabList = [
        { key: 'stp-check', tab: 'STP Check' },
        { key: 'kyc-overview', tab: 'KYC Overview' },
        { key: 'odd-cases', tab: 'ODD Cases' },
        { key: 'compliance-info', tab: 'Compliance Info' },
        { key: 'proof-of-business', tab: 'Proof of Business' },
        { key: 'documents', tab: 'Documents' },
        { key: 'people', tab: peopleSectionLabel(onboardingSource) },
        { key: 'risk-assessment', tab: 'Risk Assessment' },
        { key: 'ownership', tab: 'Ownership Structure' },
        { key: 'employees', tab: 'Employees' },
        { key: 'sources-of-funds', tab: 'Sources of funds' },
        { key: 'entitlements', tab: 'Entitlements' },
        ...(hasNotesPermissions ? [{ key: 'notes', tab: 'Notes' }] : []),
    ]

    return (
        <Card
            tabList={tabList}
            defaultActiveTabKey={defaultActiveKey}
            activeTabKey={activeTabKey}
            onTabChange={(tabKey: string) => {
                navigate(tabKey, { replace: true })
            }}
        >
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<StpCheck companyId={companyId} />} />
                    <Route path="stp-check" element={<StpCheck companyId={companyId} />} />
                    <Route path="kyc-overview" element={<KycOverview companyId={companyId} />} />
                    <Route path="odd-cases" element={<OddCases />} />
                    <Route
                        path="compliance-info"
                        element={<ComplianceInfo companyId={companyId} />}
                    />
                    <Route
                        path="proof-of-business"
                        element={<ProofOfBusiness companyId={companyId} />}
                    />
                    <Route path="documents" element={<Documents companyId={companyId} />} />
                    <Route
                        path="people"
                        element={<People companyId={companyId} companyCountry={companyCountry} />}
                    />
                    <Route path="risk-assessment" element={<RiskAssessment />} />
                    <Route path="ownership" element={<Ownership />} />
                    <Route path="employees" element={<Employees companyId={companyId} />} />
                    <Route
                        path="sources-of-funds"
                        element={<SourcesOfFunds companyId={companyId} />}
                    />
                    <Route path="Notes" element={<ComplianceNote companyId={companyId} />} />
                    <Route path="entitlements" element={<Entitlements companyId={companyId} />} />
                </Route>
            </Routes>
        </Card>
    )
}

export default CompanyTabs
