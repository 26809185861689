import useSWR from 'swr'

import type { Status, UpdateVelocity, Card } from 'types/card'

import request, { fetcher } from './request'

export function getCards(companyId: string, employeeId: string) {
    return request().get(`rest/v1/admin/cards`, { searchParams: { companyId, employeeId } })
}

export function getCard(cardId: string) {
    return request().get(`rest/v1/cards/${cardId}`)
}

export async function updateCard(cardId: string, status?: Status, velocity?: UpdateVelocity) {
    return request().put(`rest/v1/admin/cards/${cardId}`, { json: { status, velocity } })
}

export async function resetPinTries(cardId: string) {
    return request().delete(`rest/v1/cards/${cardId}/pin/tries`)
}

export const useCard = (cardId: string) => {
    const response = useSWR<Card, Error>(cardId ? `rest/v1/cards/${cardId}` : null, fetcher, {
        shouldRetryOnError: false,
    })

    const updateStatus = async (status: Status) => {
        if (cardId) {
            const card: Card = await updateCard(cardId, status).then((res) => res.json())
            return response.mutate(card, false)
        }
    }

    return { ...response, mutations: { updateStatus } }
}

interface PutCardParams {
    cardId: string
    status?: Status
    velocity?: UpdateVelocity
}

export const useCards = (companyId?: string | null, employeeId?: string) => {
    const url = `rest/v1/admin/cards?companyId=${companyId}&employeeId=${employeeId}`

    const result = useSWR<Card[], Error>(companyId && employeeId ? url : null, fetcher, {
        revalidateOnFocus: false,
    })

    const putCard = async ({ cardId, status, velocity }: PutCardParams) => {
        await updateCard(cardId, status, velocity)
        return await result.mutate()
    }

    return { ...result, mutations: { putCard } }
}
