import { type FC, useState, useEffect } from 'react'
import { Button, Card, Modal, Space, Typography } from 'antd'
import { useDepartment, useEmployeeTeam } from '../api/use-teams'
import type { Department } from '@pleo-io/deimos'
import { CloseCircleTwoTone } from '@ant-design/icons'
import { TeamMembers } from 'components/review-team'
import type { GetTeam } from 'types/team'
import styled from 'styled-components'

const { Text } = Typography

const TeamsContainer = styled.div`
    display: flex;
    flex-flow: column;
`

const ReviewerTeams = styled.div`
    display: flex;
    flex-flow: column;

    &:not(:empty) {
        &:first-child::before {
            content: 'Reviewer';
            font-weight: bold;
        }

        margin-bottom: 8px;
    }
`

const TeamReviewedByEmployee: FC<{
    teamId: string
    employeeId?: string
    onOpen: () => void
}> = ({ teamId, employeeId, onOpen }) => {
    const { department } = useDepartment(teamId)

    const isDepartmentReviewedByEmployee = isEmployeeDepartmentReviewer(
        department?.reviewers,
        employeeId
    )

    return isDepartmentReviewedByEmployee ? (
        <Space direction="vertical">
            <Button onClick={onOpen} type="link" style={{ padding: 0 }}>
                {department?.name}
            </Button>
        </Space>
    ) : null
}

export const ReviewStructure: FC<{ companyId?: string | null; employeeId?: string }> = ({
    companyId,
    employeeId,
}) => {
    const [isTeamModalOpen, setIsTeamModalOpen] = useState(false)
    const [currentTeam, setCurrentTeam] = useState<GetTeam | null>()

    useEffect(() => setIsTeamModalOpen(false), [employeeId, companyId])

    const { team, allTeams, error: teamError } = useEmployeeTeam(companyId, employeeId)

    const isLoading = !allTeams && !teamError

    const openTeam = (newTeam: GetTeam) => {
        setCurrentTeam(newTeam)
        setIsTeamModalOpen(true)
    }

    return (
        <>
            <Card title="Teams" type="inner" style={{ margin: '0 0 24px 0' }} loading={isLoading}>
                <TeamsContainer>
                    <ReviewerTeams>
                        {(allTeams?.data || []).map((t) => (
                            <TeamReviewedByEmployee
                                teamId={t?.id}
                                employeeId={employeeId}
                                onOpen={() => openTeam(t)}
                                key={t?.id}
                            />
                        ))}
                    </ReviewerTeams>
                    {team ? (
                        <>
                            <Text strong>Member</Text>
                            <Space direction="vertical">
                                <Text>
                                    <Button
                                        onClick={() => openTeam(team)}
                                        type="link"
                                        style={{ padding: 0 }}
                                    >
                                        {team.name}
                                    </Button>
                                </Text>
                            </Space>
                        </>
                    ) : (
                        <Text>
                            <CloseCircleTwoTone /> Not a member of a team
                        </Text>
                    )}
                </TeamsContainer>
            </Card>
            <Modal
                open={isTeamModalOpen}
                onCancel={() => setIsTeamModalOpen(false)}
                footer={null}
                title={
                    currentTeam?.name ? (
                        <>
                            {currentTeam.name}
                            <br />
                            <Text
                                type="secondary"
                                style={{ fontSize: '0.8rem', fontWeight: 'normal' }}
                            >
                                Department ID:
                                <Text copyable code>
                                    {currentTeam?.id}
                                </Text>
                            </Text>
                        </>
                    ) : (
                        'Team members'
                    )
                }
                bodyStyle={{ padding: 0 }}
                width={1000}
                destroyOnClose
                centered
            >
                {currentTeam && <TeamMembers team={currentTeam} />}
            </Modal>
        </>
    )
}

const isEmployeeDepartmentReviewer = (reviewers: Department['reviewers'], employeeId?: string) => {
    const ee = (reviewers || [])?.filter((reviewer) => reviewer.id === employeeId)
    if (ee.length > 0) return true
    return false
}
