export enum FormDataTypes {
    Feature = 'feature',
    AddOn = 'addOn',
    Limit = 'limit',
}

export enum FieldNameParts {
    Name,
    ColumnKey,
    ID,
    Type,
}

export enum FormAction {
    Submit = 'submit',
    Schedule = 'schedule',
}

export enum RecordDatatype {
    Feature = 'feature',
    Input = 'input',
}

export enum LimitLabels {
    MaxAllowedEmployees = 'Max Allowed Employees',
}

export enum InputTypes {
    Text = 'text',
    Number = 'number',
}
