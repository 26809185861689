import ViewChargebackTransactions from './widgets/view-chargeback-transactions'
import ViewPan from './widgets/view-pan'
import RefundTransactions from './widgets/refund-transactions'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { PageContentLayout } from 'components/layout-containers'
import ProcessTransactions, { TransactionType } from './widgets/process-transactions'
import { type FC, useEffect } from 'react'
import { useTransaction } from 'services/deimos/transactions'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'
import { bff } from '../bff'

const Chargebacks = () => {
    const {
        currentTransaction,
        failedTransaction,
        successfulTransaction,
        error: processTransactionsError,
        mutations,
    } = useTransaction(null)

    const useChargebackDocumentUrl = () => {
        const location = useLocation()
        const file = parse(location.search, { ignoreQueryPrefix: true }).file

        if (file) {
            const { data } = bff.chargebacks.getChargebackUrl.useQuery({ file })
            useEffect(() => {
                if (data) {
                    window.open(data, '_blank')
                    window.location.replace(location.pathname)
                }
            }, [location.pathname, data])
        }
    }
    useChargebackDocumentUrl()

    return (
        <PageContentLayout>
            <WidgetSection>
                <ViewChargebackTransactions />
                <ProcessTransactions
                    title="Process chargebacks"
                    transactionType={TransactionType.chargeback}
                    InfoPopoverText={<ChargebacksInfoPopover />}
                    process={mutations.processChargebacks}
                    undo={mutations.undoChargebacks}
                    currentTransaction={currentTransaction}
                    failedTransaction={failedTransaction}
                    successfulTransaction={successfulTransaction}
                    processTransactionsError={processTransactionsError}
                />
                <RefundTransactions />
                <ViewPan />
            </WidgetSection>
        </PageContentLayout>
    )
}

const ChargebacksInfoPopover: FC = () => {
    return (
        <>
            If a single transaction ID is entered, you will be presented with the transaction
            information.
            <br />
            <br />
            If multiple transaction IDs are pasted in, then it will automatically run through each
            ID and attempt to process the chargeback.
            <br />
            <br />
            Multiple IDs must be new-line separated, as this feature is designed to work with IDs
            being copied from rows in a Google spreadsheet and pasted into the input box.
            <br />
            <br />
            Please note: A maximum of 500 transactions can be pasted in at a time. Anything over
            this number will be ignored.
        </>
    )
}

const WidgetSection = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    grid-auto-rows: auto;
    grid-gap: ${spacing.space24};
`

export default Chargebacks
