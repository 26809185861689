export const STANDARD_DATAPOINTS = {
    hasCompanyName: 'Company name',
    hasTradingName: 'Trading name',
    hasRegistrationNumber: 'Registration number',
    hasIncorporationDate: 'Incorporation date',
    hasIncorporationCountry: 'Incorporation country',
    hasRegisteredAddress: 'Registered address',
    hasTradingAddress: 'Trading address',
    hasIncorporationType: 'Incorporation type',
    hasOrgStructure: 'Organisational structure',
    hasNaceCode: 'NACE code',
    hasSourceOfRevenue: 'Source of revenue',
    inScopePersonsDetails: 'In scope persons details',
    hasMinimumNumberOfDirectorsInScope: 'Minimum number of directors',
}

export const HIGH_RISK_DATAPOINTS = {
    hasCompanySourceOfWealthNarrative: 'Company source of wealth narrative',
    hasUBOSourceOfWealthNarrative: 'UBO source of wealth narrative',
    pepAnalysis: 'PEP analysis performed',
    adminDetails: 'Admin details',
}

export const PEOPLE_DATA_POINTS = ['inScopePersonsDetails', 'pepAnalysis', 'adminDetails']
