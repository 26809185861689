import type { FC } from 'react'
import { Select as AntSelect, SelectProps } from 'formik-antd'
import { times } from 'ramda'
import dayjs from 'packages/dayjs'
import type { LabeledValue } from 'antd/lib/select'

const { Option } = AntSelect

const dayOptions: LabeledValue[] = times(
    (index) => ({ label: (index + 1).toString(), value: index + 1 }),
    31
)
const monthOptions: LabeledValue[] = times(
    (index) => ({
        label: dayjs().month(index).format('MMMM'),
        value: index + 1,
    }),
    12
)
const currentYear = dayjs().year()
const yearOptions: LabeledValue[] = times(
    (index) => ({ label: (currentYear - index).toString(), value: currentYear - index }),
    currentYear - 1899
)

interface Props extends SelectProps {
    selectOptions: LabeledValue[]
}

const Select: FC<React.PropsWithChildren<Props>> = ({ selectOptions, ...rest }) => (
    <AntSelect {...rest} showSearch optionFilterProp="label">
        {selectOptions.map((option) => (
            <Option label={option.label} value={option.value} key={option.value}>
                {option.label}
            </Option>
        ))}
    </AntSelect>
)

export const DaySelect: FC<React.PropsWithChildren<SelectProps>> = (props) => (
    <Select selectOptions={dayOptions} {...props} />
)

export const MonthSelect: FC<React.PropsWithChildren<SelectProps>> = (props) => (
    <Select selectOptions={monthOptions} {...props} />
)

export const YearSelect: FC<React.PropsWithChildren<SelectProps>> = (props) => (
    <Select selectOptions={yearOptions} {...props} />
)
