import { partnershipsBff } from 'pages/partnerships/bff'

import type { CreateAppFormValues } from '../models/create-app-form'
import * as CreateAppForm from '../models/create-app-form'

import type { EditAppFormValues } from '../models/edit-app-form'
import * as EditAppForm from '../models/edit-app-form'

import type { Result } from '../../fp/result'

import type {
    AppMarketplaceAPIContext,
    AppMarketplaceContext,
} from '../contexts/app-markeplace-context'
import React from 'react'

export function useAppMarketplaceBackend(): {
    data: AppMarketplaceContext
    actions: AppMarketplaceAPIContext
} {
    const [searchTerm, setSearchTerm] = React.useState<string>('')
    const { data: apps, isLoading } = partnershipsBff.appMarketplace.getApps.useQuery({
        searchTerm,
    })

    const createAppMutation = partnershipsBff.appMarketplace.createAppClientPair.useMutation()
    const updateAppMutation = partnershipsBff.appMarketplace.updateApp.useMutation()

    const createApp = async (formValues: CreateAppFormValues) => {
        const app = CreateAppForm.toAppClientPair(formValues)
        return createAppMutation.mutateAsync(app)
    }

    const updateApp = async (formValues: EditAppFormValues): Promise<Result<string, {}>> => {
        const app = EditAppForm.toApp(formValues)
        return updateAppMutation.mutateAsync(app)
    }

    return {
        data: {
            apps: apps || [],
            isLoading: isLoading,
            isCreating: createAppMutation.isLoading,
            isUpdating: updateAppMutation.isLoading,
        },
        actions: {
            createApp,
            updateApp,
            setSearchTerm,
        },
    }
}
