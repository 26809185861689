import { initialValues } from './initialValues'

export const insertIntoArray = <T>(array: Array<T>, value: T, index: number) => {
    return [...array.slice(0, index), value, ...array.slice(index + 1)]
}

// We use parts of the initial profile to calculate ratios for scaling expenses
// in the profile steps. As it has no effect on rendering the UI so we've decided on this
// singleton over context or some kind of state.
// Temp typecast until we have the types from the moon
export const initialProfileValues = (function () {
    let profileValues = initialValues

    return {
        setProfileValues(updatedProfileValues: typeof initialValues) {
            profileValues = structuredClone(updatedProfileValues)
            return
        },
        getProfileValues() {
            return profileValues
        },
    }
})()
