import useSWR from 'swr'
import { buildFetcher, pandoraRequest } from 'services/request'
import type {
    PaginatedUAVTransactionResponse,
    TransactionCheckResult,
} from 'bff/moons/generated/pandora-v1'
import { useState } from 'react'
import type { TablePaginationConfig } from 'antd'
import dayjs from 'dayjs'

const fetcher = buildFetcher(pandoraRequest)

export const useTransactions = (employeeId?: string) => {
    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
    })
    const [range, setRange] = useState([])
    const [status, setStatus] = useState('')

    const params = new URLSearchParams()

    if (employeeId) {
        params.append('employeeId', employeeId)
    }

    if (status) {
        params.append('status', status)
    }

    if (pagination.current && pagination.pageSize) {
        params.append(
            'offset',
            `${pagination?.current * pagination?.pageSize - pagination?.pageSize}`
        )
    }

    if (pagination.pageSize) {
        params.append('limit', pagination.pageSize.toString())
    }

    if (range && range.length > 0) {
        params.append('from', dayjs(range[0]).utc().format())
        params.append('to', dayjs(range[1]).add(1, 'day').utc().format())
    }

    const {
        data: transactions,
        mutate,
        ...rest
    } = useSWR<PaginatedUAVTransactionResponse>(
        employeeId ? `transactions?${params.toString()}` : '',
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    return { transactions, setPagination, setRange, setStatus, ...rest }
}

// Inferred from here https://github.com/pleo-io/pandora/pull/659/files#diff-aa82d620a8a91c2dfd7647d784964308d3306897656e70254053cc28aded0c0fR5
// Update when real type is available
type TransactionChecksResponse = {
    checks: TransactionCheckResult[]
    transactionReason: string | null
}

export const useTransactionChecks = (transactionId: string) => {
    const { data: transactionChecks, ...rest } = useSWR<TransactionChecksResponse>(
        `transactions/check/${transactionId}`,
        fetcher
    )

    return {
        checks: transactionChecks?.checks,
        reason: transactionChecks?.transactionReason,
        ...rest,
    }
}
