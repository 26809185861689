import { Table } from 'antd'
import type { ColumnProps } from 'antd/lib/table'

import type { KycCaseRecordDto } from 'bff/moons/generated/business-proof-collector'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { accendDashboardUrl } from 'pages/compliance/company/proof-of-business/utils'
import { Link } from 'react-router-dom'
import { ExportOutlined } from '@ant-design/icons'

interface Props {
    kycCases: KycCaseRecordDto[]
    loading: boolean
}

const KycCaseList = ({ kycCases, loading }: Props) => {
    const columns: ColumnProps<KycCaseRecordDto>[] = [
        {
            title: 'Case id',
            dataIndex: 'externalId',
            render: (externalId) => externalId,
        },
        {
            title: 'Owner',
            dataIndex: 'ownerEmail',
            render: (record) => record,
        },
        {
            title: 'Check date',
            dataIndex: 'createdAt',
            render: (createdAt) => dayjs(createdAt).format('lll'),
        },
        {
            title: 'Action',
            dataIndex: 'externalId',
            render: (externalId, _, idx) => (
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={accendDashboardUrl(externalId)}
                    data-testid={`view-case-link-${idx}`}
                >
                    <GroupedActions>
                        <span>View case</span>
                        <ExportOutlined />
                    </GroupedActions>
                </Link>
            ),
        },
    ]

    return (
        <>
            <Table<KycCaseRecordDto>
                loading={loading}
                dataSource={kycCases}
                rowKey="id"
                columns={columns}
                pagination={false}
            />
        </>
    )
}

const GroupedActions = styled('div')`
    display: flex;
    align-items: center;
    vertical-align: middle;
    gap: 0.375rem;
`

export default KycCaseList
