import { useState, useEffect } from 'react'
import { dionysusRequest } from 'services/request'
import type { CreateBulkDemoAccountRequest } from 'types/demo-accounts'

const BASE_URL = 'rest/v1/demo-accounts/bulk'

function postDionysus(route: string, body?: Record<string, any>) {
    return dionysusRequest().post(route, {
        json: body,
    })
}

export const createBulkDemoAccounts = async (
    createBulkDemoAccountRequest: CreateBulkDemoAccountRequest
): Promise<any> => await postDionysus(BASE_URL, createBulkDemoAccountRequest)

export const useCreateBulkDemoAccounts = () => {
    const [createBulkDemoAccountRequest, setCreateBulkDemoAccountRequest] =
        useState<CreateBulkDemoAccountRequest | null>(null)
    const [isSuccessful, setIsSuccessful] = useState<Boolean | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        if (createBulkDemoAccountRequest) {
            setLoading(true)
            createBulkDemoAccounts(createBulkDemoAccountRequest)
                .then(() => setIsSuccessful(true))
                .catch((err) => {
                    setError(err)
                    setIsSuccessful(false)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [createBulkDemoAccountRequest])

    const dismissError = () => {
        setError(null)
        setIsSuccessful(null)
    }

    return {
        loading,
        error,
        data: {
            success: isSuccessful,
        },
        setCreateBulkDemoAccountRequest,
        dismissError,
    }
}
