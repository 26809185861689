import { buildFetcher, kerberosRequest } from 'services/request'
import useSWR from 'swr'
import type { User } from 'types/user'

const fetcher = buildFetcher(kerberosRequest)

export const useGetUser = (userId?: string | null, isAdmin?: boolean) => {
    const response = useSWR<User[]>(
        userId
            ? `api/v1/admin/users?userId=${userId}&query=&admin=${isAdmin ? 'true' : 'false'}`
            : null,
        fetcher
    )

    return {
        user: response.data?.[0],
        ...response,
    }
}
