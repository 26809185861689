import type { Amount } from '@pleo-io/deimos'
import type { Limit } from 'types/employee'
import request from './request'

// Requests

export function createEmployee(companyId: string, email: string, firstName?: string) {
    return request().post(`rest/v1/companies/${companyId}/employees`, {
        json: firstName ? { firstName, email } : { email },
    })
}

export function updateEmployeeRole(employeeId: string, role: string) {
    return request().post(`rest/v1/employees/${employeeId}`, { json: { role } })
}

export function deactivateEmployee(employeeId: string) {
    return request().post(`rest/v1/admin/employees/${employeeId}/deactivate`)
}

export function deleteBookkeeper(id: string, email: string) {
    return request().delete(`rest/v1/bookkeepers/${id}`, {
        searchParams: { email },
    })
}

export function resendInvite(companyId: string, employeeId: string) {
    return request().post(`rest/v1/companies/${companyId}/employees/${employeeId}/resend`)
}

export function inviteEmployee(companyId: string, email: string, firstName?: string) {
    return request().post(`rest/v1/companies/${companyId}/employees`, {
        json: firstName ? { firstName, email } : { email },
    })
}

export function restoreEmployee(employeeId: string, userId: string) {
    return request().post(`rest/v1/admin/employees/${employeeId}/restore`, { json: { userId } })
}

export function setCardAccess(employeeId?: string) {
    return request().post(`rest/v1/employees/${employeeId}`, {
        json: { permissions: ['cardAccess'] },
    })
}

export type EmployeeLimitAndWalletBalanceResponse = {
    limits: Limit[]
    walletBalance?: Amount
}
