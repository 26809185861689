import { Button, Descriptions, Modal, Space, Typography } from 'antd'
import type { FC } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { cashFlowBff } from './bff'

interface ItemProps {
    customValue?: any
}

const ValueOrDash: FC<ItemProps> = ({ customValue }) => {
    return <Typography.Text>{customValue ?? '-'}</Typography.Text>
}

interface Props {
    companyId: string
}

const WalletSettingsView: FC<Props> = ({ companyId }) => {
    const { data: walletSettings, refetch } = cashFlowBff.getWalletSettings.useQuery({
        companyId,
    })
    const { mutate: disableAutoTopUp } = cashFlowBff.disableAutoTopUp.useMutation()

    if (!walletSettings) {
        return <Typography.Text>No wallet settings available.</Typography.Text>
    }

    const isAtuEnabled =
        !!walletSettings.autoTopupStatus &&
        ['ENABLED', 'PENDING'].includes(walletSettings.autoTopupStatus)

    const disableAtu = () => {
        Modal.confirm({
            title: 'Do you want to disable ATU?',
            icon: <ExclamationCircleOutlined />,
            async onOk() {
                disableAutoTopUp({ companyId, walletSettings })
                refetch()
            },
            onCancel() {},
        })
    }

    return (
        <Space direction="vertical">
            {isAtuEnabled && (
                <Button onClick={disableAtu} type="primary" danger>
                    Disable ATU
                </Button>
            )}
            <Descriptions title="Auto Top Up" bordered size="small">
                <Descriptions.Item label="Threshold">
                    <ValueOrDash customValue={walletSettings.autoTopUpThreshold} />
                </Descriptions.Item>
                <Descriptions.Item label="Amount">
                    <ValueOrDash customValue={walletSettings.autoTopUpAmount} />
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                    <ValueOrDash customValue={walletSettings.autoTopupStatus} />
                </Descriptions.Item>
                <Descriptions.Item label="Status Updated At">
                    <ValueOrDash customValue={walletSettings.autoTopUpStatusUpdatedAt} />
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                    <ValueOrDash customValue={walletSettings.autoTopupType} />
                </Descriptions.Item>
                <Descriptions.Item label="Scheduled Frequency">
                    <ValueOrDash customValue={walletSettings.scheduledTopup?.frequency} />
                </Descriptions.Item>
                <Descriptions.Item label="Scheduled Top Up At">
                    <ValueOrDash customValue={walletSettings.scheduledTopup?.amount} />
                </Descriptions.Item>
                <Descriptions.Item label="Payment Rail">
                    <ValueOrDash customValue={walletSettings.paymentRail} />
                </Descriptions.Item>
            </Descriptions>

            <Descriptions title="Other" bordered size="small">
                <Descriptions.Item label="Wallet Dry Threshold">
                    <ValueOrDash customValue={walletSettings.calculatedDryThreshold} />
                </Descriptions.Item>
                <Descriptions.Item label="Updated At">
                    <ValueOrDash customValue={walletSettings.updatedAt} />
                </Descriptions.Item>
                <Descriptions.Item label="Company ID">
                    <ValueOrDash customValue={walletSettings.companyId} />
                </Descriptions.Item>
            </Descriptions>
        </Space>
    )
}

export default WalletSettingsView
