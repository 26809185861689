import React, { FC, useEffect } from 'react'
import { Card, Divider, message, Button, Space, Descriptions } from 'antd'
import { PageContentLayout } from 'components/layout-containers'

import type { Expense } from 'types/expense'
import { useExpense } from 'services/deimos/expense'
import { SearchOutlined } from '@ant-design/icons'
import { Formik } from 'formik'
import { Input as AntInput, Form } from 'formik-antd'

import UploadReceipt from './upload-receipt'
import { inputWidth } from 'theme/tokens'
import { useSearchParams } from 'react-router-dom'

interface ExpenseInformationProps {
    expense?: Expense
    loading: boolean
}

const ExpenseInformation: FC<React.PropsWithChildren<ExpenseInformationProps>> = ({
    expense,
    loading,
    children,
}) => (
    <>
        <Descriptions
            title="Expense information"
            bordered
            column={{ xxl: 4, xl: 3, lg: 2, md: 1, sm: 1 }}
            size="small"
        >
            <Descriptions.Item label="Employee name">
                {`${expense?.employee?.firstName || ''} ${expense?.employee?.lastName || ''}`}
            </Descriptions.Item>
            <Descriptions.Item label="Merchant name">
                {expense?.merchantName || ''}
            </Descriptions.Item>
            <Descriptions.Item label="Type">{expense?.purchaseType || ''}</Descriptions.Item>
            <Descriptions.Item label="Amount">
                {`${expense?.amount.currency ?? ''} ${expense?.amount.value ?? ''}`}
            </Descriptions.Item>
            <Descriptions.Item label="Employee email">
                {expense?.employee?.email || ''}
            </Descriptions.Item>
            <Descriptions.Item label="Category">{expense?.category?.name || ''}</Descriptions.Item>
            <Descriptions.Item label="Status">{expense?.status || ''}</Descriptions.Item>
        </Descriptions>
        <Divider />
        {!loading && children}
    </>
)

const ViewExpense: FC<React.PropsWithChildren<unknown>> = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const expenseId = searchParams.get('expense_id') || ''
    const { data: expense, error, mutations } = useExpense(expenseId)

    useEffect(() => {
        if (error) {
            message.error(error.message)
        }
    }, [error])

    useEffect(() => {
        if (expense) {
            setSearchParams({ expense_id: expense.id })
        }
    }, [expense, setSearchParams])

    return (
        <PageContentLayout>
            <Card title="View expense and upload receipt">
                <Formik
                    initialValues={{ expenseId }}
                    onSubmit={(values) => {
                        setSearchParams({ expense_id: values.expenseId })
                    }}
                >
                    {({ values, isValidating }) => (
                        <Form layout="vertical">
                            <Form.Item name="expenseID" label="Expense ID">
                                <Space>
                                    <AntInput
                                        data-testid="expense-id-input"
                                        name="expenseId"
                                        placeholder="3d95410e-ce4e-4660-9297-c513bdb6b263"
                                        style={{ width: `${inputWidth.huge}` }}
                                    />
                                    <Button
                                        data-testid="search-expense-button"
                                        type="primary"
                                        htmlType="submit"
                                        loading={!!expenseId && !expense && isValidating}
                                        disabled={!values.expenseId}
                                    >
                                        <SearchOutlined />
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    )}
                </Formik>
                <ExpenseInformation expense={expense} loading={!expense && !!expenseId && !error}>
                    <UploadReceipt expense={expense} onUploadReceipt={mutations.uploadReceipt} />
                </ExpenseInformation>
            </Card>
        </PageContentLayout>
    )
}

export default ViewExpense
