import { useState, useEffect } from 'react'

import {
    InvestigationStatus,
    FlagRow,
    GetFlagResponse,
    FlaggedBillTransaction,
    FlaggedCardTransaction,
    FlagCategory,
    TransactionFamily,
    FlaggedPersonalTransferTransaction,
    FlaggedEmployeeBankTransferTransaction,
    FlaggedWalletWithdrawalTransaction,
} from '@pleo-io/deimos'
import dayjs from 'packages/dayjs'

export const investigationStatuses: InvestigationStatus[] = [
    InvestigationStatus.NEW,
    InvestigationStatus.REQ_INVESTIGATION,
    InvestigationStatus.FALSE_POSITIVE,
    InvestigationStatus.FRAUD,
]

export enum DisplayInvestigationStatusAml {
    FRAUD = 'Money laundering suspicion',
    FALSE_POSITIVE = 'False positive',
    REQ_INVESTIGATION = 'Request investigation',
}

export enum DisplayInvestigationStatusFraud {
    FRAUD = 'Fraud',
    FALSE_POSITIVE = 'False positive',
    REQ_INVESTIGATION = 'Request investigation',
}

const flagCategoryToTransferFamily: {
    [key in FlagCategory]: TransactionFamily
} = {
    [FlagCategory.BILL_FRAUD]: TransactionFamily.BILL,
    [FlagCategory.PERSONAL_TRANSFER_FRAUD]: TransactionFamily.PERSONAL_TRANSFER,
    [FlagCategory.EMPLOYEE_BANK_TRANSFER_FRAUD]: TransactionFamily.EMPLOYEE_BANK_TRANSFER,
    [FlagCategory.CARD_FRAUD]: TransactionFamily.CARD,
    [FlagCategory.AML]: TransactionFamily.CARD,
    [FlagCategory.CARD_BALANCE_ALTERATION]: TransactionFamily.CARD,
    [FlagCategory.CHIP_AND_SIGNATURE]: TransactionFamily.CARD,
    [FlagCategory.COMPLIANCE]: TransactionFamily.CARD,
    [FlagCategory.MANUAL_KEY_ENTRY]: TransactionFamily.CARD,
    [FlagCategory.WALLET_BALANCE_ALTERATION]: TransactionFamily.CARD,
    [FlagCategory.WALLET_WITHDRAWAL_FRAUD]: TransactionFamily.WALLET_WITHDRAWAL,
}

export const getDateOfLatestFlag = (rows: FlagRow[]) =>
    rows.sort((a, b) => dayjs(b.flag.createdAt).valueOf() - dayjs(a.flag.createdAt).valueOf())[0]
        ?.flag.createdAt

export const companyNameSorter = (a: GetFlagResponse, b: GetFlagResponse) =>
    a.company?.name.localeCompare(b.company?.name ?? '') ?? 0

export const merchantNameSorter = (a: FlagRow, b: FlagRow) =>
    isFlagCardTransaction(a.transaction) && isFlagCardTransaction(b.transaction)
        ? a.transaction?.merchant.name?.localeCompare(b.transaction?.merchant.name ?? '') ?? 0
        : 0

export const isFlagCardTransaction = (
    transaction?:
        | FlaggedBillTransaction
        | FlaggedCardTransaction
        | FlaggedPersonalTransferTransaction
        | FlaggedEmployeeBankTransferTransaction
        | FlaggedWalletWithdrawalTransaction
): transaction is FlaggedCardTransaction => {
    return !!transaction && 'merchant' in transaction
}

export const getFlagFamily = (category: FlagCategory): TransactionFamily => {
    return flagCategoryToTransferFamily[category]
}

export const getEntityId = (flagResponse: GetFlagResponse) =>
    flagResponse.bill
        ? flagResponse.bill.billInvoiceId
        : flagResponse.personalTransfer
        ? flagResponse.personalTransfer.id
        : flagResponse.employeeBankTransfer
        ? flagResponse.employeeBankTransfer.id
        : flagResponse.walletWithdrawal
        ? flagResponse.walletWithdrawal.id
        : flagResponse.card?.id

export function useFilteredData<DataItem>({
    data,
    filter,
}: {
    filter: (input: string) => (item: DataItem) => boolean
    data?: DataItem[]
}) {
    const [dataFiltered, setDataFiltered] = useState(data)

    // save search value to save the filter when data prop updates.
    const [searchInput, setSearchInput] = useState<string | undefined>(undefined)

    const applySearchInput = (input?: string) => {
        if (!input) {
            return setDataFiltered(data)
        }
        const filteredData = data?.filter(filter(input))
        setDataFiltered(filteredData)
    }

    // when data prop changes, (re)apply the filter
    useEffect(() => {
        applySearchInput(searchInput)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const onSearch = (input: string) => {
        setSearchInput(input)
        applySearchInput(input)
    }
    return { onSearch, dataFiltered }
}

export const datePopoverText = (status: InvestigationStatus) => {
    if (status === InvestigationStatus.NEW || status === InvestigationStatus.REQ_INVESTIGATION) {
        return 'Oldest 50 records, sorted oldest > newest'
    } else {
        return 'Newest 50 records, sorted newest > oldest'
    }
}
