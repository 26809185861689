import { type FC, useState } from 'react'
import styled from 'styled-components'
import { InboxOutlined } from '@ant-design/icons'
import { Upload, message, Modal, Spin } from 'antd'

import type { ComplianceDocument, Document, DocumentFile, DocumentFileRequest } from 'types/styx'
import FileList from 'pages/compliance/company/shared/file-list/file-list'
import type { RcFile } from 'antd/lib/upload'

const { Dragger } = Upload
export interface DocumentFilesProps {
    document: Document | ComplianceDocument
    maxFileSize: number
    createDocumentFile: (formData: FormData) => Promise<DocumentFile | void>
    deleteDocumentFile?: (fileId: string) => Promise<Response | void>
    getLinkToFile: (fileId: string, download?: boolean) => Promise<string>
    editDocumentFile?: (fileId: string, documentFileRequest: DocumentFileRequest) => Promise<void>
}

const getFileSize = (file: string | Blob | RcFile) => {
    if (typeof file === 'string') {
        return 0
    }
    return file.size
}

export const DocumentFiles: FC<React.PropsWithChildren<DocumentFilesProps>> = ({
    document,
    createDocumentFile,
    deleteDocumentFile,
    getLinkToFile,
    maxFileSize,
    editDocumentFile,
}) => {
    const [isUploading, setIsUploading] = useState(false)

    const onRemoveFile = deleteDocumentFile
        ? (fileId: string) => {
              return new Promise<boolean>((resolve, reject) => {
                  Modal.confirm({
                      onOk: async () => {
                          try {
                              await deleteDocumentFile(fileId)
                              resolve(true)
                          } catch (e) {
                              message.error((e as Error).message)
                              return false
                          }
                      },
                      onCancel: () => {
                          reject(false)
                      },
                      centered: true,
                      title: 'Are you sure you want to remove this file?',
                      okButtonProps: {
                          danger: true,
                      },
                  })
              })
          }
        : undefined

    return (
        <DocumentWrapper data-testid="document">
            <Dragger
                showUploadList={false}
                accept="image/bmp, image/png, image/jpg, image/jpeg, application/pdf"
                multiple
                // Upload component requires async logic to be handled in customRequest prop
                customRequest={async ({ file, onError }) => {
                    const formData = new FormData()
                    // Deimos requires the name to be 'file'
                    formData.append('file', file)

                    const bytesToMb = (bytes: number) => bytes / (1024 * 1024)
                    try {
                        if (maxFileSize < getFileSize(file)) {
                            throw new Error(
                                `File too large - must be smaller than ${bytesToMb(maxFileSize)}mb`
                            )
                        }

                        setIsUploading(true)
                        await createDocumentFile(formData)
                    } catch (e) {
                        await message.error(`Could not upload file: ${(e as Error).message}`)
                        onError && onError(e as Error)
                    } finally {
                        setIsUploading(false)
                    }
                }}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
            <Spin spinning={isUploading}>
                <FileList
                    files={document.files}
                    getLinkToFile={getLinkToFile}
                    onRemoveFile={onRemoveFile}
                    editDocumentFile={editDocumentFile}
                />
            </Spin>
        </DocumentWrapper>
    )
}

const DocumentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
