import { Formik } from 'formik'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Card, Breadcrumb, Button, Typography } from 'antd'
import { Form } from 'formik-antd'
import { PageContentLayout } from 'components/layout-containers'
import { PageHeader } from '@ant-design/pro-layout'
import { useGetEmployees } from 'services/caliban/employee-search'
import type { GetEmployeesResponse } from 'services/caliban/employee-search'
import { FC, useState } from 'react'
import { debounce } from 'lodash'
import { spacing } from 'theme/tokens'
import AdvancedSearchInput from 'pages/customer-success/shared/advanced-search/advanced-search-input'
import {
    CustomColumn,
    ToggleTable,
} from 'pages/customer-success/shared/advanced-search/toggle-table'
import AdvancedSearchSelect from 'pages/customer-success/shared/advanced-search/advanced-search-select'
import { GetEmployeeResponseRole, GetEmployeeResponseType } from 'bff/moons/generated/oberon-v1'
import { uppercaseFirstLetter } from 'utils/strings'

const { Text } = Typography

const roleOptions = Object.keys(GetEmployeeResponseRole).map((key) => {
    return {
        label: uppercaseFirstLetter(key),
        value: key,
    }
})

const typeOptions = Object.keys(GetEmployeeResponseType).map((key) => {
    return {
        label: uppercaseFirstLetter(key),
        value: key,
    }
})

enum AvailableColumns {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    phone = 'phone',
    employeeId = 'id',
    userId = 'userId',
    jobTitle = 'jobTitle',
    companyId = 'companyId',
    partnerId = 'partnerId',
    role = 'role',
    type = 'type',
    verified = 'verified',
}

const columns = [
    {
        title: 'First name',
        dataIndex: AvailableColumns.firstName,
    },
    {
        title: 'Last name',
        dataIndex: AvailableColumns.lastName,
    },
    {
        title: 'Email',
        dataIndex: AvailableColumns.email,
        render: (email: string) => <CustomColumn value={email} />,
    },
    {
        title: 'Phone',
        dataIndex: AvailableColumns.phone,
        render: (phone: string) => <CustomColumn value={phone} />,
    },
    {
        title: 'Employee ID',
        dataIndex: AvailableColumns.employeeId,
        render: (id: string) => <CustomColumn value={id} fixedWidth />,
    },
    {
        title: 'User ID',
        dataIndex: AvailableColumns.userId,
        render: (id: string) => <CustomColumn value={id} fixedWidth />,
    },
    {
        title: 'Job title',
        dataIndex: AvailableColumns.jobTitle,
    },
    {
        title: 'Company ID',
        dataIndex: AvailableColumns.companyId,
        render: (id: string) => <CustomColumn value={id} fixedWidth />,
    },
    {
        title: 'Partner ID',
        dataIndex: AvailableColumns.partnerId,
        render: (id: string) => <CustomColumn value={id} fixedWidth />,
    },
    {
        title: 'Role',
        dataIndex: AvailableColumns.role,
        render: (role: string) => <Text>{role ? uppercaseFirstLetter(role) : '-'}</Text>,
    },
    {
        title: 'Type',
        dataIndex: AvailableColumns.type,
        render: (type: string) => <Text>{type ? uppercaseFirstLetter(type) : '-'}</Text>,
    },
    {
        title: 'Verified',
        dataIndex: AvailableColumns.verified,
        render: (verified: boolean) => (
            <Text>{verified === undefined ? '-' : verified ? 'Yes' : 'No'}</Text>
        ),
    },
]

const defaultVisibleColumns = [
    AvailableColumns.firstName,
    AvailableColumns.lastName,
    AvailableColumns.email,
    AvailableColumns.phone,
    AvailableColumns.employeeId,
    AvailableColumns.userId,
]

const PageBreadcrumb: FC = () => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/customer-success">Customer success</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/customer-success/employee-360" data-testid="employees-breadcrumb">
                    Employees
                </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Advanced employee search</Breadcrumb.Item>
        </Breadcrumb>
    )
}

const EmployeeAdvancedSearch: FC = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { data } = useGetEmployees()
    const [showMoreFields, setShowMoreFields] = useState(false)

    const debouncedSetSearchParams = debounce((value, fieldName) => {
        if (value) {
            searchParams.set(fieldName, value)
        } else {
            searchParams.delete(fieldName)
        }
        setSearchParams(searchParams)
    }, 300)

    const onChangeInput = (value: any, fieldName: string) => {
        if (searchParams.get('offset')) {
            searchParams.set('offset', '0')
        }
        debouncedSetSearchParams(value, fieldName)
    }

    const onTableRowClick = (record: any) => {
        navigate(`/customer-success/employee-360/${record.companyId}/${record.id}`)
    }

    const initialValues = {
        firstName: searchParams.get(AvailableColumns.firstName) || undefined,
        lastName: searchParams.get(AvailableColumns.lastName) || undefined,
        email: searchParams.get(AvailableColumns.email) || undefined,
        phone: searchParams.get(AvailableColumns.phone) || undefined,
        id: searchParams.get(AvailableColumns.employeeId) || undefined,
        userId: searchParams.get(AvailableColumns.userId) || undefined,
        companyId: searchParams.get(AvailableColumns.companyId) || undefined,
        partnerId: searchParams.get(AvailableColumns.partnerId) || undefined,
        jobTitle: searchParams.get(AvailableColumns.jobTitle) || undefined,
        role: searchParams.get(AvailableColumns.role) || undefined,
        type: searchParams.get(AvailableColumns.type) || undefined,
        verified: searchParams.get(AvailableColumns.verified) || undefined,
    }

    return (
        <PageContentLayout>
            <PageHeader title="Advanced employee search" breadcrumb={<PageBreadcrumb />} />
            <Card size="small" title="Search fields">
                <Formik
                    initialValues={initialValues}
                    onSubmit={() => setSearchParams(searchParams)}
                >
                    {() => (
                        <Form
                            layout="vertical"
                            style={{
                                alignContent: 'flex-start',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                rowGap: spacing.space24,
                            }}
                        >
                            <AdvancedSearchInput
                                key={AvailableColumns.firstName}
                                label="First name"
                                name={AvailableColumns.firstName}
                                onChange={onChangeInput}
                            />
                            <AdvancedSearchInput
                                key={AvailableColumns.lastName}
                                label="Last name"
                                name={AvailableColumns.lastName}
                                onChange={onChangeInput}
                            />
                            <AdvancedSearchInput
                                key={AvailableColumns.email}
                                label="Email"
                                name={AvailableColumns.email}
                                onChange={onChangeInput}
                                widthOverride={300}
                            />
                            <AdvancedSearchInput
                                key={AvailableColumns.phone}
                                label="Phone"
                                name={AvailableColumns.phone}
                                onChange={onChangeInput}
                            />
                            <AdvancedSearchInput
                                key={AvailableColumns.employeeId}
                                label="Employee ID"
                                name={AvailableColumns.employeeId}
                                onChange={onChangeInput}
                                widthOverride={300}
                            />
                            <AdvancedSearchInput
                                key={AvailableColumns.userId}
                                label="User ID"
                                name={AvailableColumns.userId}
                                onChange={onChangeInput}
                                widthOverride={300}
                            />
                            {showMoreFields && (
                                <>
                                    <AdvancedSearchInput
                                        key={AvailableColumns.jobTitle}
                                        label="Job title"
                                        name={AvailableColumns.jobTitle}
                                        onChange={onChangeInput}
                                    />
                                    <AdvancedSearchInput
                                        key={AvailableColumns.companyId}
                                        label="Company ID"
                                        name={AvailableColumns.companyId}
                                        onChange={onChangeInput}
                                        widthOverride={300}
                                    />
                                    <AdvancedSearchInput
                                        key={AvailableColumns.partnerId}
                                        label="Partner ID"
                                        name={AvailableColumns.partnerId}
                                        onChange={onChangeInput}
                                        widthOverride={300}
                                    />
                                    <AdvancedSearchSelect
                                        options={roleOptions}
                                        key={AvailableColumns.role}
                                        label="Role"
                                        name={AvailableColumns.role}
                                        onChange={onChangeInput}
                                        widthOverride={300}
                                        testId="role-select"
                                    />
                                    <AdvancedSearchSelect
                                        options={typeOptions}
                                        key={AvailableColumns.type}
                                        label="Type"
                                        name={AvailableColumns.type}
                                        onChange={onChangeInput}
                                        testId="type-select"
                                    />
                                </>
                            )}
                            <Button
                                style={{ alignSelf: 'flex-end' }}
                                type="link"
                                onClick={() => setShowMoreFields(!showMoreFields)}
                            >
                                {showMoreFields ? 'Show less' : 'Show more...'}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Card>
            <ToggleTable<GetEmployeesResponse>
                data={data}
                onTableRowClick={onTableRowClick}
                allColumns={columns}
                defaultColumns={defaultVisibleColumns}
            />
        </PageContentLayout>
    )
}

export default EmployeeAdvancedSearch
