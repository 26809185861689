import React, { FC, useState } from 'react'
import { Divider } from 'antd'
import type { ErrorResponse } from '@pleo-io/deimos'

import type { Document, KycCheckpoint } from 'types/styx'
import {
    ReadOnlyDocumentTabs,
    DocumentsTabs,
} from 'pages/compliance/closed-company/documents/document-tabs'
import SnapshotTabs from 'pages/compliance/company/shared/snapshot-tabs/snapshot-tabs'

import {
    getLinkToCompanyDocumentFile,
    useStyxClosedCompany,
    useClosedCompanyKycCheckpoints,
} from 'services/deimos/styx-company/styx-company'
import { useClosedCompanyDocumentsWithDateSnapshot } from 'services/styx/company'

interface DocumentHistoryProps {
    snapshotDocuments: Document[]
    loadingSnapshot: boolean
    kycCheckpoints: KycCheckpoint[]
    error?: ErrorResponse
    onFetchSnapshot: (snapshot?: string) => void
    handleGetLinkToFile: (
        documentId: string,
        snapshot?: string
    ) => (fileId: string) => Promise<string>
}

export const DocumentsHistory: FC<React.PropsWithChildren<DocumentHistoryProps>> = ({
    error,
    handleGetLinkToFile,
    snapshotDocuments,
    loadingSnapshot,
    onFetchSnapshot,
    kycCheckpoints,
    children,
}) => {
    return (
        <SnapshotTabs
            kycCheckpoints={kycCheckpoints}
            onFetchSnapshot={onFetchSnapshot}
            error={error}
            readonlyChildren={(snapshot) => (
                <ReadOnlyDocumentTabs
                    handleGetLinkToFile={handleGetLinkToFile}
                    snapshot={snapshot}
                    snapshotDocuments={snapshotDocuments}
                    loading={loadingSnapshot}
                />
            )}
        >
            {children}
            <Divider />
        </SnapshotTabs>
    )
}

const DocumentsHistoryContainer: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    const isClosedCompany = true
    const { isValidating: isValidatingClosedCompany, closedCompanySnapshot } =
        useStyxClosedCompany()
    const [snapshot, setSnapshot] = useState<string | undefined>(closedCompanySnapshot)
    const {
        data: snapshotDocuments,
        isValidating: isValidatingDocuments,
        error,
    } = useClosedCompanyDocumentsWithDateSnapshot(companyId, snapshot)
    const kycCheckpoints = useClosedCompanyKycCheckpoints()

    const handleFetchSnapshot = (snapshotDate?: string) =>
        setSnapshot(snapshotDate ?? closedCompanySnapshot)

    const handleGetLinkToFile =
        (documentId: string) => async (fileId: string, download?: boolean) => {
            const { url }: { url: string } = await getLinkToCompanyDocumentFile(
                companyId,
                documentId,
                fileId,
                snapshot,
                isClosedCompany,
                download
            ).json()

            return url
        }

    const isValidating = isValidatingClosedCompany || isValidatingDocuments

    return (
        <DocumentsHistory
            error={error}
            handleGetLinkToFile={handleGetLinkToFile}
            loadingSnapshot={isValidating}
            onFetchSnapshot={handleFetchSnapshot}
            kycCheckpoints={kycCheckpoints}
            snapshotDocuments={snapshotDocuments ?? []}
        >
            <DocumentsTabs
                companyId={companyId}
                handleGetLinkToFile={handleGetLinkToFile}
                documents={snapshotDocuments ?? []}
            />
        </DocumentsHistory>
    )
}

export default DocumentsHistoryContainer
