import type { RiskAssessment, SubmitRiskAssessmentRequestV2 } from 'types/styx'
import dayjs from 'dayjs'
import useSWR from 'swr'
import type { ErrorResponse } from '@pleo-io/deimos'
import request, { fetcher } from './request'
import { useParams } from 'react-router-dom'

export const submitB4BCompanyRiskAssessmentV2 = (
    companyId: string,
    body: SubmitRiskAssessmentRequestV2
) =>
    request().patch(`rest/v2/styx-companies/${companyId}/risk/b4b`, {
        json: { ...body, version: 'V2' },
    })

export const useCompanyRiskAssessment = (snapshot?: string, includeRiskChecks?: boolean) => {
    const { id: companyId } = useParams()
    const modifiedSnapshot = snapshot
        ? dayjs(snapshot).add(100, 'millisecond').toISOString()
        : undefined

    const snapshotQuery = snapshot ? `snapshot=${modifiedSnapshot}` : null
    const riskChecksQuery = includeRiskChecks ? `includeRiskChecks=${includeRiskChecks}` : null

    const queryParams = [snapshotQuery, riskChecksQuery].filter(Boolean).join('&')

    const url = `rest/v2/styx-companies/${companyId}/risk?${queryParams}`

    return useSWR<RiskAssessment, ErrorResponse>(url, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })
}

export const useClosedCompanyRiskAssessment = (snapshot?: string, includeRiskChecks?: boolean) => {
    const { id: companyId } = useParams()
    const snapshotQuery = snapshot ? `snapshot=${snapshot}` : null
    const riskChecksQuery = includeRiskChecks ? `includeRiskChecks=${includeRiskChecks}` : null

    const queryParams = [snapshotQuery, riskChecksQuery].filter(Boolean).join('&')

    const url = `rest/v2/styx-companies/${companyId}/risk/closed-company?${queryParams}`

    return useSWR<RiskAssessment, ErrorResponse>(url, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })
}

export const resetCompanyRiskAssessment = (companyId: string, note?: string) =>
    request().post(`rest/v2/styx-companies/${companyId}/risk/reset`, {
        json: { kycCheckpointNote: note },
    })

export const createCompanyRiskAssessmentV2 = (companyId: string) =>
    request().post(`rest/v2/styx-companies/${companyId}/risk`, { json: {} })

export const submitCompanyRiskAssessmentV2 = (
    companyId: string,
    body: SubmitRiskAssessmentRequestV2
) =>
    request().patch(`rest/v2/styx-companies/${companyId}/risk`, {
        json: body,
    })
