import useSWR from 'swr'
import request, { fetcher } from 'services/styx/request'
import { DataSource, type IndustryCodeRequest, type IndustryCodes } from 'types/styx'
import type { ErrorResponse } from '@pleo-io/deimos'
import type { NaceSection } from 'bff/moons/generated/styx-v1'
import { notEmpty } from 'utils/not-empty'

/**
 * @summary Get all NACE industry codes
 */
export function useStyxV2IndustryCodes() {
    const response = useSWR<NaceSection[]>(`rest/v2/industry-codes/`, fetcher, {
        shouldRetryOnError: false,
    })

    const industryCodes = response.data ?? []

    return {
        industryCodes,
    }
}

export const useCompanyIndustryCodes = (companyId?: string) => {
    const url = `rest/v2/styx-companies/${companyId}/industry-codes`
    const response = useSWR<IndustryCodes[], ErrorResponse>(companyId ? url : null, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })

    const insertCompanyIndustryCodes = async (industryCodes: string[]): Promise<void> => {
        if (!companyId) {
            return Promise.reject("Can't insert industry codes without a company id")
        }
        const insertIndustryCodePromises = industryCodes.map(async (industryCode) => {
            await insertCompanyIndustryCode(companyId, {
                code: industryCode,
                source: DataSource.DUE_DILIGENCE_AGENT_INPUT,
                type: 'NACE',
            })
        })
        await Promise.all(insertIndustryCodePromises)
        await response.mutate()
        return Promise.resolve()
    }

    const deleteCompanyIndustryCodes = async (industryCodes: string[]): Promise<void> => {
        if (!companyId) {
            return Promise.reject("Can't delete industry codes without a company id")
        }
        const deleteIndustryCodePromises = industryCodes.map(async (industryCode) => {
            await deleteCompanyIndustryCode(companyId, industryCode)
        })
        await Promise.all(deleteIndustryCodePromises)
        await response.mutate()
        return Promise.resolve()
    }

    return {
        ...response,
        naceCodes: response.data?.filter((code) => code.type === 'NACE') ?? [],
        mutations: { insertCompanyIndustryCodes, deleteCompanyIndustryCodes },
    }
}

const insertCompanyIndustryCode = (companyId: string, industryCode: IndustryCodeRequest) => {
    const url = `rest/v2/styx-companies/${companyId}/industry-codes`

    return request().put(url, {
        json: industryCode,
    })
}

const deleteCompanyIndustryCode = (companyId: string, industryCode: string) => {
    const url = `rest/v2/styx-companies/${companyId}/industry-codes/nace/${industryCode}`

    return request().delete(url)
}

export const useIndustryCodes = (companyId?: string) => {
    const url = `rest/v2/styx-companies/${companyId}/industry-codes`
    const response = useSWR<IndustryCodes[], ErrorResponse>(companyId ? url : null, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })

    return {
        ...response,
        naceCodes: response.data?.filter((code) => code.type === 'NACE') ?? [],
    }
}

export const getCodeFromNace = (codes: IndustryCodes[]) =>
    codes.map((naceCode) => naceCode.code).filter(notEmpty)
