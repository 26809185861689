import type { ChangeEvent } from 'react'
import { useUsers } from 'services/auth/users'
import { Card, Input } from 'antd'

import { ContentContainer, PageContentLayout } from 'components/layout-containers'

import Admins from './admins'
import Users from './users'
import { inputWidth } from 'theme/tokens'
import { RolesHistoryPage } from './components/roles-history/roles-history'
import { useSearchParams } from 'react-router-dom'
import { useHasPermissions } from 'components/permission-guard/permission-guard'

type TabKey = 'admins' | 'users' | 'roles-history'

const DEFAULT_TAB = 'admins'

const UserProvisioning = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const tab = searchParams.get('tab') || DEFAULT_TAB
    const hasRolesHistoryPermission = useHasPermissions(['roles-history'])

    const { users, setUsersQuery, mutations } = useUsers(tab === 'admins')

    const onTabChange = (key: string) => {
        searchParams.set('tab', key)
        setSearchParams(searchParams)
    }

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setUsersQuery(value)
    }

    const tabList = [
        { key: 'admins', tab: 'Current admins' },
        { key: 'users', tab: 'All users' },
        ...(hasRolesHistoryPermission ? [{ key: 'roles-history', tab: 'Roles history' }] : []),
    ]

    const tabContent = {
        admins: (
            <Admins
                users={users}
                setAdminPermission={mutations.setAdminPermission}
                unsetAdminPermission={mutations.unsetAdminPermission}
            />
        ),
        users: (
            <Users
                users={users}
                setUsersQuery={setUsersQuery}
                grantAdminAccess={mutations.grantAdminAccess}
                revokeAdminAccess={mutations.revokeAdminAccess}
            />
        ),
        'roles-history': <RolesHistoryPage />,
    }

    const shouldShowUserSearch = tab === 'users' || tab === 'admins'

    return (
        <PageContentLayout>
            <Card
                activeTabKey={tab}
                tabList={tabList}
                onTabChange={onTabChange}
                bodyStyle={{ padding: 0 }}
            >
                <ContentContainer>
                    {shouldShowUserSearch && (
                        <Input
                            placeholder="Search for users"
                            onChange={onSearch}
                            style={{ width: inputWidth.huge, margin: '1.5rem 1rem 0 1rem' }}
                        />
                    )}
                    {tabContent[tab as TabKey]}
                </ContentContainer>
            </Card>
        </PageContentLayout>
    )
}

export default UserProvisioning
