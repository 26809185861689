import { Card, Descriptions } from 'antd'
import { ContentContainer } from 'components/layout-containers'
import type { FC } from 'react'
import { useAgreements } from 'services/deimos/styx-company/styx-company'

export const DirectAgreement: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    const { data: agreements } = useAgreements(companyId)
    return (
        <>
            {agreements && (
                <Card title="Direct Debit Agreements" style={{ marginBottom: '1rem' }}>
                    <ContentContainer>
                        <Descriptions bordered size="small" column={2}>
                            <Descriptions.Item label="Status">
                                {agreements?.status ?? '-'}
                            </Descriptions.Item>
                        </Descriptions>
                    </ContentContainer>
                </Card>
            )}
        </>
    )
}
