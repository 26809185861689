import { useState } from 'react'
import { Avatar, Button, Tooltip, Typography } from 'antd'
import { bff } from './bff-hooks'
import { UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons'

const { Text } = Typography

type ToggleAssigneeButtonProps = {
    id: string
    hasAssignee: boolean
    caseHandlerInitials?: string
    firstName?: string
    lastName?: string
    isLocked?: boolean
}

export const ToggleAssigneeButton = ({
    id,
    hasAssignee,
    caseHandlerInitials,
    firstName,
    lastName,
    isLocked,
}: ToggleAssigneeButtonProps) => {
    const { mutate: unassign } = bff.assignCaseToMe.unassignCase.useMutation()
    const { mutate: assignCaseToMe } = bff.assignCaseToMe.assignCaseToMe.useMutation()

    const [isShowingToggleOption, showToggleOption] = useState(false)

    const buttonText = hasAssignee ? 'Unassign' : 'Assign to me'

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div
                onMouseEnter={() => {
                    if (isLocked) return
                    showToggleOption(true)
                }}
                onMouseLeave={() => {
                    if (isLocked) return
                    showToggleOption(false)
                }}
            >
                <Avatar
                    shape="square"
                    size="small"
                    onClick={async (e) => {
                        e?.stopPropagation()
                        if (isLocked) return
                        if (hasAssignee) {
                            unassign({ caseId: id })
                        } else {
                            assignCaseToMe({ caseId: id })
                        }
                    }}
                >
                    {isShowingToggleOption ? (
                        hasAssignee ? (
                            <Tooltip title={buttonText}>
                                <UserDeleteOutlined />
                            </Tooltip>
                        ) : (
                            <UserAddOutlined />
                        )
                    ) : (
                        <Tooltip title={`${firstName} ${lastName}`}>{caseHandlerInitials}</Tooltip>
                    )}
                    {!isShowingToggleOption && !hasAssignee && <UserAddOutlined />}
                </Avatar>
            </div>
            {hasAssignee ? (
                <Text color="default" style={{ margin: '0 5px' }}>
                    {firstName} {lastName}
                </Text>
            ) : (
                <Button
                    style={{ paddingLeft: '4px' }}
                    type="link"
                    onClick={(e) => {
                        e.stopPropagation()
                        if (isLocked) return
                        assignCaseToMe({ caseId: id })
                    }}
                    disabled={isLocked}
                >
                    Assign to me
                </Button>
            )}
        </div>
    )
}
