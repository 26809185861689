import { Tag } from 'antd'

export const RiskRatingTag = ({
    riskRating,
    isDraft,
}: {
    riskRating: string
    isDraft?: boolean
}) => {
    switch (riskRating) {
        case 'LOW':
            return (
                <Tag
                    color="green"
                    style={isDraft ? { borderStyle: 'dashed', background: 'transparent' } : {}}
                >
                    Low
                </Tag>
            )
        case 'MEDIUM_LOW':
            return (
                <Tag
                    color="cyan"
                    style={isDraft ? { borderStyle: 'dashed', background: 'transparent' } : {}}
                >
                    Medium-Low
                </Tag>
            )
        case 'MEDIUM':
            return (
                <Tag
                    color="blue"
                    style={isDraft ? { borderStyle: 'dashed', background: 'transparent' } : {}}
                >
                    Medium
                </Tag>
            )
        case 'MEDIUM_HIGH':
            return (
                <Tag
                    color="gold"
                    style={isDraft ? { borderStyle: 'dashed', background: 'transparent' } : {}}
                >
                    Medium-High
                </Tag>
            )
        case 'HIGH':
            return (
                <Tag
                    color="orange"
                    style={isDraft ? { borderStyle: 'dashed', background: 'transparent' } : {}}
                >
                    High
                </Tag>
            )
        case 'RESTRICTED':
            return (
                <Tag
                    color="red"
                    style={isDraft ? { borderStyle: 'dashed', background: 'transparent' } : {}}
                >
                    Restricted
                </Tag>
            )
        default:
            return (
                <Tag
                    color="blue"
                    style={isDraft ? { borderStyle: 'dashed', background: 'transparent' } : {}}
                >
                    {riskRating}
                </Tag>
            )
    }
}
