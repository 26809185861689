import { Formik } from 'formik'
import React, { FC, useState } from 'react'
import { Popconfirm } from 'antd'
import { Select, Form } from 'formik-antd'
import { inputWidth } from 'theme/tokens'
import { KycStatus } from 'types/styx'

interface Props {
    currentVelocity: KycStatus
    onSubmit: (values: { velocity: KycStatus }) => Promise<void>
}

const UpdateVelocityForm: FC<React.PropsWithChildren<Props>> = ({ currentVelocity, onSubmit }) => {
    const [confirmVisible, setConfirmVisible] = useState(false)
    const kycLevels = Object.values(KycStatus)
    const velocityIndex = kycLevels.findIndex((level) => level === currentVelocity)
    const maxVelocity = kycLevels.findIndex((level) => level === KycStatus.FDD)

    const options = kycLevels
        .filter(
            (_, index) =>
                index === velocityIndex ||
                (index === velocityIndex + 1 && velocityIndex + 1 <= maxVelocity)
        )
        .map((level) => ({
            label: level,
            value: level,
        }))

    return (
        <Formik
            initialValues={{ velocity: currentVelocity }}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({ resetForm, submitForm, isSubmitting }) => (
                <Form>
                    <Popconfirm
                        title="Are you sure want to update this company's velocity?"
                        open={confirmVisible}
                        onCancel={() => {
                            setConfirmVisible(false)
                            resetForm()
                        }}
                        okButtonProps={{
                            onClick: () => {
                                submitForm()
                                setConfirmVisible(false)
                            },
                            danger: true,
                            loading: isSubmitting,
                            disabled: isSubmitting,
                        }}
                    >
                        <Select
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            onChange={() => setConfirmVisible(true)}
                            style={{ width: `${inputWidth.tiny}` }}
                            size="small"
                            name="velocity"
                            options={options}
                            placement="bottomLeft"
                            data-testid="velocity-select"
                        />
                    </Popconfirm>
                </Form>
            )}
        </Formik>
    )
}
export default UpdateVelocityForm
