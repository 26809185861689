import type { FC } from 'react'
import { Button, Table, Typography } from 'antd'
import type { ColumnProps } from 'antd/es/table'
import dayjs from 'packages/dayjs'

import { ActionsContainer } from 'components/layout-containers'
import type { MkeMerchant, PostalAddress } from '../../../../bff/moons/generated/ariel'
import { bff as complianceBff } from '../../bff/bff'

const { Text } = Typography

interface Props {
    merchants: MkeMerchant[]
    loading: boolean
    onChange: (merchant: MkeMerchant) => void
}

export const MerchantAllowlist: FC<React.PropsWithChildren<Props>> = ({
    merchants,
    loading,
    onChange,
}) => {
    const columns: ColumnProps<MkeMerchant>[] = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'MCC',
            dataIndex: 'mcc',
        },
        {
            title: 'Created date',
            dataIndex: 'createdDate',
            render: (createdDate: string) => dayjs(createdDate).format('lll'),
        },
        {
            title: 'Address',
            dataIndex: 'postalAddress',
            render: (address: PostalAddress) => (
                <address>
                    {address &&
                        Object.values(address).map((addressLine, index) => (
                            <div key={index}>{addressLine}</div>
                        ))}
                </address>
            ),
        },
        {
            filters: [
                {
                    text: 'Hide "Not Investigating"',
                    value: 'false',
                },
            ],
            onFilter: (value, record) => value !== String(record.investigating),
            defaultFilteredValue: ['false'],
            title: 'Investigating',
            dataIndex: 'investigating',
            render: (investigating: boolean) =>
                investigating ? <Text type="danger">Yes</Text> : <Text>No</Text>,
        },
        {
            title: 'Action',
            render: (_, record) => (
                <ActionsContainer>
                    <Button
                        data-testid={`change-btn-${record.id}`}
                        type="link"
                        onClick={() => onChange(record)}
                    >
                        Change
                    </Button>
                </ActionsContainer>
            ),
        },
    ]
    return <Table columns={columns} dataSource={merchants} rowKey="id" loading={loading} />
}

const MerchantBlocklistContainer = () => {
    const { data: merchants } = complianceBff.merchantAllowlist.getAllowlistedMerchants.useQuery({})
    const { mutateAsync: updateAllowlistedMerchant } =
        complianceBff.merchantAllowlist.updateAllowlistedMerchant.useMutation()

    return (
        <MerchantAllowlist
            merchants={merchants ?? []}
            loading={!merchants}
            onChange={({ id, investigating }: MkeMerchant) =>
                updateAllowlistedMerchant({ id, investigating: !investigating })
            }
        />
    )
}

export default MerchantBlocklistContainer
