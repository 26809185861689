import React, { FC, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Select, Divider, Button } from 'antd'
import { spacing, inputWidth } from 'theme/tokens'

import Header from 'components/header/header'
import { deKebabify } from 'utils/strings'
import * as uuid from 'uuid'

import { actions as userActions } from 'store/modules/support/user/slice'
import type { Company } from 'types/deimos-company'
import type { Partner } from 'types/partner-portal'
import type { User } from 'types/user'
import { useCard } from 'services/deimos/cards'
import { useInvite } from '../../services/kerberos/invites'
import { useGetPartner } from 'services/calypso/partner'
import { bff } from 'components/bff-hooks'

const { Option, OptGroup } = Select

interface Props {
    companies?: Company[]
    onCompanySearch: (query: string) => void
    onPartnerSearch: (query: string) => void
    onUserSearch: (query: string) => void
    partners?: Partner[]
    users?: User[]
}

export const CompaniesUsersSearchHeader: FC<React.PropsWithChildren<Props>> = ({
    companies,
    onCompanySearch,
    onPartnerSearch,
    onUserSearch,
    partners,
    users,
}) => {
    const { id } = useParams()
    const title = deKebabify(id)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname

    const [cardIdQuery, setCardIdQuery] = useState('')
    const { data: card } = useCard(cardIdQuery)
    const userId = card?.employee?.userId

    const [companyIdQuery, setCompanyIdQuery] = useState('')
    const companyId = bff.components.companiesUsersSearchHeader.getCompanyId.useQuery(
        {
            companyIdQuery,
        },
        { enabled: !!companyIdQuery }
    ).data?.companyId

    const [employeeIdQuery, setEmployeeIdQuery] = useState('')

    const { data: employee } = bff.components.companiesUsersSearchHeader.getEmployeeId.useQuery(
        {
            employeeIdQuery,
        },
        { enabled: !!employeeIdQuery }
    )

    const employeeId = employee?.employeeId

    const [partnerIdQuery, setPartnerIdQuery] = useState('')
    const { data: partnerById } = useGetPartner(partnerIdQuery)
    const partnerId = partnerById?.id

    const [inviteIdQuery, setInviteIdQuery] = useState('')
    const { data: inviteById } = useInvite(inviteIdQuery)
    const inviteId = inviteById?.id

    const [company360, setCompany360] = useState('')

    const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
    const isOnSearchPage = path.includes('search')
    const isCardId = (cardId: string) => !!cardId.match(/^[0-9]{7,}$/)
    const shouldNotOpenSearch = isOnSearchPage || !searchValue
    const isCompany360 = (optionSelected?: string) =>
        optionSelected?.slice(0, 13) === 'customer_view'

    const onSearchChange = (elemId?: string) => {
        const selectedCompany = companies?.find((c) => c.id === elemId)
        const selectedPartner = partners?.find((p) => p.id === elemId)
        const selectedUser = users?.find((u) => u.id === elemId)
        const selectedCompany360 = isCompany360(elemId)

        if (selectedCompany) {
            navigate(`/customer-success/companies/${elemId}`)
        } else if (selectedCompany360) {
            const company360Id = elemId?.split(':')[1]
            navigate(`/customer-success/company-360/${company360Id}`)
        } else if (selectedUser) {
            dispatch(userActions.updatedUser(selectedUser))
            navigate(`/customer-success/users/${elemId}`)
        } else if (selectedPartner) {
            navigate(`/customer-success/partners/${elemId}`)
        }
    }

    const viewAllResultsClick = () => {
        navigate('/customer-success/search')
    }

    const clearSearch = () => {
        clearAllQueries()

        const emptySearch = ''
        onCompanySearch(emptySearch)
        onPartnerSearch(emptySearch)
        onUserSearch(emptySearch)
    }

    const handleSearch = (rawValue: string) => {
        const value = rawValue.trim()
        if (!value) {
            return
        }

        setSearchValue(value)
        if (uuid.validate(value)) {
            setCompanyIdQuery(value)
            setCardIdQuery(value)
            setPartnerIdQuery(value)
            setEmployeeIdQuery(value)
            setInviteIdQuery(value)
        } else if (isCardId(value)) {
            setCardIdQuery(value)
        } else {
            onCompanySearch(value)
            onPartnerSearch(value)
            onUserSearch(value)
        }
    }

    const clearAllQueries = () => {
        setSearchValue('')
        setCompanyIdQuery('')
        setCardIdQuery('')
        setPartnerIdQuery('')
        setCompany360('')
        setEmployeeIdQuery('')
        setInviteIdQuery('')
    }

    useEffect(() => {
        if (userId) {
            navigate(`/customer-success/users/${userId}`)
            clearAllQueries()
        }
        if (companyId) {
            navigate(`/customer-success/companies/${companyId}`)
            clearAllQueries()
        }
        if (partnerId) {
            navigate(`/customer-success/partners/${partnerId}`)
            clearAllQueries()
        }
        if (company360) {
            navigate(`/customer-success/company-360/${company360}`)
            clearAllQueries()
        }
        if (employeeId) {
            navigate(`/customer-success/employees/${employeeId}`)
            clearAllQueries()
        }
        if (inviteId) {
            navigate(`/customer-success/invites/${inviteId}`)
            clearAllQueries()
        }
    }, [dispatch, navigate, userId, companyId, partnerId, company360, employeeId, inviteId])

    return (
        <Header title={title}>
            <Select
                autoClearSearchValue={false}
                allowClear
                dropdownRender={(menu: any) =>
                    shouldNotOpenSearch ? (
                        <></>
                    ) : (
                        <div>
                            {menu}
                            <Divider style={{ margin: `${spacing.space4} 0` }} />
                            <Button
                                type="link"
                                style={{ width: '100%' }}
                                onClick={viewAllResultsClick}
                            >
                                View all results
                            </Button>
                        </div>
                    )
                }
                filterOption={false}
                value={searchValue}
                onClear={clearSearch}
                onChange={onSearchChange}
                onSearch={handleSearch}
                placeholder="Companies (name), Users (email/card ID), Partners (name), Employee (ID), Invite (ID)"
                suffixIcon={null}
                showSearch
                style={{ width: `${inputWidth.huge}` }}
            >
                <OptGroup label="Companies">
                    {companies?.slice(0, 5).map((company) => (
                        <Option key={company.id} value={company.id} disabled={!company.styxId}>
                            {company.name}
                        </Option>
                    ))}
                </OptGroup>
                <OptGroup label="People">
                    {users?.slice(0, 5).map((user) => (
                        <Option key={user.id} value={user.id}>
                            {user.data.profile?.name?.firstName} {user.data.profile?.name?.lastName}{' '}
                            ({user.email})
                        </Option>
                    ))}
                </OptGroup>
                <OptGroup label="Partners">
                    {partners?.slice(0, 5).map((partner) => (
                        <Option key={partner.id} value={partner.id}>
                            {partner.name}
                        </Option>
                    ))}
                </OptGroup>
                <OptGroup label="Company 360">
                    {companies?.slice(0, 5).map((company) => (
                        <Option
                            key={`customer_view:${company.id}`}
                            value={`customer_view:${company.id}`}
                            disabled={!company.styxId}
                        >
                            {company.name}
                        </Option>
                    ))}
                </OptGroup>
            </Select>
        </Header>
    )
}

export default CompaniesUsersSearchHeader
