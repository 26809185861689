import { useRef, useState } from 'react'
import type { ExpenseProfile, FormValuesProfile } from '../../types'
import useUpdateProfileFormValues from '../../hooks/use-update-profile-form-values'
import Item from 'antd/es/list/Item'
import { Button, Descriptions, Slider, Space } from 'antd'
import {
    calculateMerchantProfileRatios,
    getMerchantProfileRatio,
    totalExpenseCountReducer,
} from './helpers'
import DescriptionsItem from 'antd/es/descriptions/Item'
import { useFormikContext } from 'formik'
import { insertIntoArray } from '../../utils'
import { MAX_EXPENSES } from '../../limits'

type EditableExpenseProps = ExpenseProfile & {
    toggleEditing: (value: boolean) => void
    index: number
}

const EditableExpense = ({
    name,
    toggleEditing,
    expenseType,
    merchantProfiles,
    index,
}: EditableExpenseProps) => {
    const { values } = useFormikContext<FormValuesProfile>()
    const { updateProperty } = useUpdateProfileFormValues('expenses')
    const expensesCount = merchantProfiles.reduce(totalExpenseCountReducer, 0)
    const [sliderValue, setSliderValue] = useState<number>(expensesCount)
    const cachedValues = useRef<ExpenseProfile>({ name, expenseType, merchantProfiles })
    const merchantProfileRatios = calculateMerchantProfileRatios(expenseType)

    const reset = () => {
        const initialExpense = insertIntoArray<ExpenseProfile>(
            values.expenses,
            cachedValues.current,
            index
        )
        updateProperty(initialExpense)
    }

    const cancel = () => {
        reset()
        toggleEditing(false)
    }

    const updateMerchantProfileCount = () => {
        const getProfileRatio = getMerchantProfileRatio(merchantProfileRatios ?? [])
        return merchantProfiles.map((profile) => {
            const profileRatio = getProfileRatio(profile?.merchantCategoryCode)
            return {
                ...profile,
                count: Math.round(sliderValue * profileRatio!.ratio),
            }
        })
    }

    const save = () => {
        const updatedMerchantProfiles = updateMerchantProfileCount()
        const updatedExpense = {
            name,
            expenseType,
            merchantProfiles: updatedMerchantProfiles,
        }
        const updatedExpenses = insertIntoArray<ExpenseProfile>(
            values.expenses,
            updatedExpense,
            index
        )
        updateProperty(updatedExpenses)
        toggleEditing(false)
    }

    return (
        <Item
            actions={[
                <Space key="action-buttons">
                    <Button type="primary" size="small" onClick={save}>
                        Done
                    </Button>
                    <Button type="default" size="small" onClick={cancel}>
                        Cancel
                    </Button>
                </Space>,
            ]}
        >
            <Descriptions title={name} column={2}>
                <DescriptionsItem
                    label="Count"
                    contentStyle={{
                        display: 'block',
                    }}
                >
                    <Slider
                        key={`slider-${name}`}
                        min={0}
                        max={MAX_EXPENSES}
                        step={1}
                        value={sliderValue}
                        onChange={setSliderValue}
                    />
                </DescriptionsItem>
            </Descriptions>
        </Item>
    )
}

export default EditableExpense
