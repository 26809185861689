import { Route } from 'react-router-dom'
import { OddListPage } from './screens/odd-list-page'
import { OddDetailPage } from './screens/odd-detail-page'

export const oddRoutes = (
    <Route>
        <Route index element={<OddListPage />} />
        <Route path=":caseId" element={<OddDetailPage />} />
    </Route>
)
