import useSWR from 'swr'
import { fetcher } from '../oberon/request'

export type OrganizationEntity = {
    id: string
    companyId: string
    name: string
    address: { country: string }
}

export type OrganizationResponse = {
    id: string
    name: string
    companies: Array<OrganizationEntity>
}

export const useOrganizationById = (organizationId?: string) => {
    return useSWR<OrganizationResponse>(
        organizationId ? `rest/v1/organization/${organizationId}` : null,
        fetcher,
        {
            shouldRetryOnError: false,
        }
    )
}
