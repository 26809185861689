import * as Yup from 'yup'
import type { UpdatePersonRequest } from 'types/styx'

const nonRequiredAddressProps = ['street', 'floor', 'houseNumber', 'locality', 'region']

const isDefinedProp = (...args: string[]) => args.some((prop) => !!prop)
export const validationSchema = Yup.object()
    .shape(
        {
            registryId: Yup.string().nullable(),
            nationalId: Yup.string().nullable(),
            givenName: Yup.string().required('First name is required').nullable(),
            middleName: Yup.string().nullable(),
            familyName: Yup.string().required('Last name is required').nullable(),
            nationality: Yup.string().nullable(),
            citizenships: Yup.array().of(Yup.string().required()).default([]),
            day: Yup.number().min(1).max(31).nullable(),
            month: Yup.number().min(1).max(12).nullable(),
            year: Yup.number().min(1900).nullable(),
            floor: Yup.string().nullable(),
            street: Yup.string().nullable(),
            houseNumber: Yup.string().nullable(),
            locality: Yup.string().nullable(),
            postalCode: Yup.string().when([...nonRequiredAddressProps, 'country'], {
                // checking if any of the props are defined
                is: isDefinedProp,
                then: (schema) => schema.required('Postal code is required'),
            }),
            region: Yup.string().nullable(),
            country: Yup.string()
                .matches(/^[A-Z]{2}$/, 'Please use valid country code, e.g. DK')
                .when([...nonRequiredAddressProps, 'postalCode'], {
                    // checking if any of the props are defined
                    is: isDefinedProp,
                    then: (schema) => schema.required('Country is required'),
                }),
        },
        [['country', 'postalCode']]
    )
    .required()

export type FormValues = Yup.InferType<typeof validationSchema>

export const mapValuesToRequest = (values: FormValues): UpdatePersonRequest => {
    const dateOfBirth =
        values.month && values.year
            ? {
                  day: values.day,
                  month: values.month,
                  year: values.year,
              }
            : null
    const address =
        values.country && values.postalCode
            ? {
                  country: values.country,
                  locality: values.locality,
                  postalCode: values.postalCode,
                  houseNumber: values.houseNumber,
                  street: values.street,
                  floor: values.floor,
                  region: values.region,
              }
            : null
    const kycNationality = values.nationality
        ? {
              nationality: values.nationality,
              source: 'DUE_DILIGENCE_AGENT_INPUT',
          }
        : null

    return {
        registryId: values.registryId || null,
        nationalId: values.nationalId || '',
        name: {
            givenName: values.givenName,
            middleName: values.middleName || '',
            familyName: values.familyName,
        },
        dateOfBirth,
        address,
        kycNationality,
    }
}
