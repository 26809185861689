import type { CountryWithRating } from 'types/customer-risk-rating'
import type { ErrorResponse } from '@pleo-io/deimos'
import useSWR from 'swr'
import { fetcher } from 'services/deimos/request'

// Get all countries and their risk scores
// Countries are returned as 2 letter country codes
export const useCountriesRiskScores = () => {
    const response = useSWR<CountryWithRating[], ErrorResponse>(
        `rest/v1/risk/countries/`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    return response
}
