import { FC, useEffect, useState } from 'react'
import { Card, Button, Modal, message, Skeleton, Popover, Typography } from 'antd'

import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { FormItem, Input } from 'formik-antd'
import ProcessList from 'components/process-list/process-list'
import { useTransaction } from 'services/deimos/transactions'
import { TransactionDetails } from './transaction-details'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { copyTransactions, extractedTransactionIds } from './utils'

const { Text } = Typography

const validationSchema = Yup.object()
    .shape({
        ids: Yup.string().required('A transaction ID is required.'),
    })
    .required()

interface RefundTransactionsProps {
    onSubmit: (ids: string) => void
}

export const RefundTransactions: FC<RefundTransactionsProps> = ({ onSubmit }) => {
    const [textArea, setTextArea] = useState('')

    const buttonText = /\r|\n/.test(textArea) ? 'Process refunds' : 'Get transaction'
    const textRows = buttonText === 'Process refunds' ? 5 : 1

    const MultipleTransactionsInfo = () => (
        <Popover
            title="Processing refunds"
            content={
                <PopoverContent>
                    <Text>
                        If a single transaction ID is entered, you will be presented with the
                        transaction information.
                        <br />
                        <br />
                        If multiple transaction IDs are pasted in, then it will automatically run
                        through each ID and attempt to process the refunds.
                        <br />
                        <br />
                        Multiple IDs must be new-line separated, as this feature is designed to work
                        with IDs being copied from rows in a Google spreadsheet and pasted into the
                        input box.
                        <br />
                        <br />
                        Please note: A maximum of 500 transactions can be pasted in at a time.
                        Anything over this number will be ignored.
                    </Text>
                </PopoverContent>
            }
        >
            <InfoCircleOutlined data-testid="info" />
        </Popover>
    )
    return (
        <Formik
            initialValues={{ ids: '' }}
            onSubmit={(values) => onSubmit(values.ids)}
            validationSchema={validationSchema}
            validateOnMount
        >
            {({ isValid }) => (
                <Form>
                    <Card
                        title="Process refunds"
                        extra={<MultipleTransactionsInfo />}
                        actions={[
                            <Button
                                htmlType="submit"
                                type="link"
                                data-testid="submit"
                                disabled={!isValid}
                                key={buttonText}
                            >
                                {buttonText}
                            </Button>,
                        ]}
                    >
                        <FormItem name="ids">
                            <Input.TextArea
                                name="ids"
                                placeholder="Original transaction ID / Multiple transaction IDs"
                                rows={textRows}
                                onChange={(value) => setTextArea(value.target.value)}
                            />
                        </FormItem>
                    </Card>
                </Form>
            )}
        </Formik>
    )
}

const PopoverContent = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
`

const RefundTransactionsContainer: FC = () => {
    const [isProcessingRefund, setIsProcessingRefund] = useState(false)
    const [isProcessListVisible, setIsProcessListVisible] = useState(false)
    const [allTransactions, setAllTransactions] = useState<string[]>([])
    const [failedTransactions, setFailedTransactions] = useState<string[]>([])
    const [successfulTransactions, setSuccessfulTransactions] = useState<string[]>([])
    const [showSingleRefundModal, setShowSingleRefundModal] = useState(false)

    const {
        data: transaction,
        currentTransaction,
        failedTransaction,
        successfulTransaction,
        error,
        mutations,
    } = useTransaction(allTransactions[0])

    useEffect(() => {
        if (error) {
            message.error(error.message)
            setAllTransactions([])
        }
    }, [error])

    if (failedTransaction && !failedTransactions.includes(failedTransaction))
        setFailedTransactions([...failedTransactions, failedTransaction])

    if (successfulTransaction && !successfulTransactions.includes(successfulTransaction))
        setSuccessfulTransactions([...successfulTransactions, successfulTransaction])

    function onSubmit(ids: string) {
        const transactionIdArray = extractedTransactionIds(ids)
        setAllTransactions(transactionIdArray)

        if (transactionIdArray.length > 1) {
            processMultipleRefunds(transactionIdArray)
        }

        if (transactionIdArray.length === 1) {
            setShowSingleRefundModal(true)
        }
    }

    async function processSingleRefund(id: string) {
        try {
            setIsProcessingRefund(true)
            await mutations.processSingleRefund(id)
            setAllTransactions([])
            message.success('Successfully processed refund')
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setIsProcessingRefund(false)
        }
    }

    async function processMultipleRefunds(ids: string[]) {
        setIsProcessListVisible(true)
        await mutations.processMultipleRefunds(ids)
    }

    return (
        <>
            <RefundTransactions onSubmit={onSubmit} />
            <Modal
                key="refund-modal"
                title="Process multiple refunds"
                open={isProcessListVisible}
                closable={false}
                footer={[
                    <Button
                        key="failed"
                        id="copy-failed"
                        onClick={() => copyTransactions(failedTransactions)}
                        disabled={!failedTransaction.length}
                    >
                        Copy failed
                    </Button>,
                    <Button
                        key="successful"
                        id="copy-successful"
                        onClick={() => copyTransactions(successfulTransactions)}
                        disabled={!successfulTransactions.length}
                    >
                        Copy successful
                    </Button>,
                    <Button
                        type="primary"
                        key="close"
                        onClick={() => setIsProcessListVisible(false)}
                    >
                        Close
                    </Button>,
                ]}
            >
                <ProcessList
                    allItems={allTransactions}
                    currentItem={currentTransaction}
                    failedItems={failedTransactions}
                    successfulItems={successfulTransactions}
                />
            </Modal>
            <Modal
                key="transaction-modal"
                open={allTransactions.length === 1 && showSingleRefundModal === true}
                onCancel={() => {
                    setAllTransactions([])
                    setShowSingleRefundModal(false)
                }}
                title="Transaction details"
                width={500}
                footer={[
                    <Button
                        key="refund-button"
                        htmlType="button"
                        onClick={() => processSingleRefund(allTransactions[0])}
                        danger
                        loading={isProcessingRefund}
                        disabled={isProcessingRefund}
                    >
                        Refund
                    </Button>,
                ]}
            >
                {transaction ? <TransactionDetails transaction={transaction} /> : <Skeleton />}
            </Modal>
        </>
    )
}

export default RefundTransactionsContainer
