import { grey } from '@ant-design/colors'
import { Layout as AntLayout, Menu } from 'antd'
import config from 'config'
import type { ColorScheme } from 'hooks/use-user-preferences'
import styled, { css } from 'styled-components'
import { spacing } from 'theme/tokens'

const isProduction = config.environment === 'production'
const isDev = ['feature', 'local', 'product-dev'].includes(config.environment)

const { Content: AntContent, Header: AntHeader, Sider: AntSider } = AntLayout

export const SIDEBAR_WIDTH = 240

export const Layout = styled.div<{ $colorScheme: ColorScheme }>`
    display: grid;
    max-width: 100vw;
    height: 100vh;
    background-color: ${(props) => (props.$colorScheme === 'dark' ? grey[9] : '#f5f5f5')};
    grid-template-columns: ${SIDEBAR_WIDTH}px 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        'sider header'
        'sider main-content';
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 14px;
`

export const Sider = styled(AntSider)<{ $colorScheme: ColorScheme }>`
    grid-area: sider;
    height: 100%;
    overflow-y: auto;
    background: ${(props) => getSiderBackgroundColor(props.$colorScheme)};
`

export const Header = styled(AntHeader)<{ $colorScheme: ColorScheme }>`
    grid-area: header;
    background-color: ${(props) => (props.$colorScheme === 'dark' ? grey[9] : '#fff')};
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${spacing.space4};

    @media (max-width: 1100px) {
        justify-items: start;
        align-items: flex-start;
        flex-direction: column;
        line-height: normal;
        gap: ${spacing.space16};
        padding-top: ${spacing.space8};
        padding-bottom: ${spacing.space16};
    }
`

export const Content = styled(AntContent)`
    grid-area: main-content;
    overflow-y: auto;
    width: 100%;
    min-height: 100%;
`

export const SideMenu = styled(Menu)<{ $colorScheme: ColorScheme }>`
    padding: 0 0.5rem;
    &.ant-menu.ant-menu-dark {
        background: ${(props) => (props.$colorScheme === 'dark' ? grey[7] : 'initial')};
    }
    ${(props) =>
        !isProduction &&
        css`
            &.ant-menu.ant-menu-dark {
                background: ${getSiderBackgroundColor(props.$colorScheme)};
            }

            &.ant-menu-dark.ant-menu-dark .ant-menu-item-selected {
                background-color: #7363c5;
            }
        `}
`

const getSiderBackgroundColor = (colorScheme: ColorScheme) => {
    if (colorScheme === 'dark') return grey[7]
    if (isProduction) return '#001529'
    if (isDev) return '#000000'
    return '#43387a'
}
