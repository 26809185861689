import type { Currency } from './currency'
import type { ProviderName } from './provider-name'
import type { Velocity, Status } from './velocity'

export interface Timestamps {
    createdAt: string
    updatedAt: string | null
    deletedAt: string | null
}

export interface Address {
    addressLine1: string
    addressLine2?: string
    postalCode: string
    locality: string
    country: string
    region?: string
}

export interface DeimosWallet extends Timestamps {
    id: string
    companyId: string
}

export enum KycProperties {
    UNSET = 'N/A',
    SDD = 'sdd',
    FDD = 'fdd',
    EDD = 'edd',
    HEDD = 'hedd',
    HEDD1 = 'hedd1',
}

export type OnboardingSource = 'PLEO' | 'B4B'

export interface Company extends Timestamps {
    trial: unknown
    id: string
    styxId: string
    name: string
    registrationNumber: string
    address: Address
    status: {
        kyc?: {
            [value in KycProperties]?: boolean
        }
        [key: string]: unknown
    }
    wallets: Array<DeimosWallet>
    billingAdminId?: string
    organizationId?: string
    onboardedVia: OnboardingSource
}

export interface Balance {
    current: number
    available: number
    creditLimit: number
}

export interface Wallet {
    id: string
    companyId: string
    currency: Currency
    velocity: Velocity
    providerName: ProviderName
}

export interface AdminCompany {
    id: string
    ownPartnerId: string | null
    name: string
    address: Address
    balance: Balance
    currency: Currency
    status: Status
    selfOnboarding?: boolean
    organizationId?: string | null
}
