import { bff } from '../backend/bff'

export function useReimbursementsEnablement(companyId: string) {
    const { mutateAsync: enableReimbursements } =
        bff.reimbursementsEnablement.enableReimbursements.useMutation()
    const { mutateAsync: disableReimbursements } =
        bff.reimbursementsEnablement.disableReimbursements.useMutation()
    const { data: pocketEnabled } =
        bff.reimbursementsEnablement.getIsReimbursementsEnabled.useQuery({ companyId })
    return {
        pocketEnabled: Boolean(pocketEnabled),
        mutations: {
            enableReimbursements,
            disableReimbursements,
        },
    }
}
