import type { Address } from 'types/deimos-company'

export type PartnerAccessLevel = 'extended' | 'basic'

export enum PartnerTier {
    TRIAL = 'TRIAL',
    BRONZE = 'BRONZE',
    SILVER = 'SILVER',
    GOLD = 'GOLD',
    GOLD_DACH = 'GOLD_DACH',
    PLATINUM = 'PLATINUM',
    PLATINUM_DACH = 'PLATINUM_DACH',
}

interface PartnerProgramTier {
    type: PartnerTier
    users: {
        from: number
        to?: number
    }
}

export interface PartnerProgramInfoResponse {
    id: string
    ownCompanyId: string | null
    registrationNumber: string
    name: string
    tradingName: string
    address: Address
    onboardingPeriod: {
        daysToEnd: number
    }
    currentTier: PartnerProgramTier
    nextTier?: PartnerProgramTier
    calculatedTier?: PartnerProgramTier
    partnershipYear: {
        daysToEnd: number
        freeUsers: number
        paidUsers: number
        newClients: number
    }
}
