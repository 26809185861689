import { KycProperties } from 'types/deimos-company'
import type { Company } from '@pleo-io/deimos'
import * as R from 'ramda'

const companyKycStatuses = (company: Company) =>
    Object.keys(R.reject((v) => v === false, R.pathOr({}, ['status', 'kyc'], company)))

const companyHighestKycStatus = (company: Company) =>
    Object.values(KycProperties).reduce(
        (a, status) => (companyKycStatuses(company).includes(status) ? status : a),
        KycProperties.UNSET
    )

export default companyHighestKycStatus
