import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { companyReducer } from './company/slice'
import { userReducer } from './user/slice'

import type { SupportState } from 'store/modules/state'
import type { CurrentStatus } from 'types/status'

import type * as t from './types'

export const initialState: t.SupportStatusState = {
    company: 'default',
    employees: 'default',
    user: 'default',
    employee: 'default',
    billing: 'default',
    wallet: 'default',
    expense: 'default',
    featureFlags: 'default',
    walletLoads: 'default',
}

const { actions: generatedActions, reducer: statusReducer } = createSlice({
    name: 'support/status',
    initialState,
    reducers: {
        modifiedStatus: (
            state,
            {
                payload: { api, status },
            }: PayloadAction<{ api: t.CompanyDetailsApi; status: CurrentStatus }>
        ) => {
            state[api] = status
        },
        resetStatus: (_) => initialState,
    },
})

export const supportReducer = combineReducers<SupportState>({
    status: statusReducer,
    companyState: companyReducer,
    userPage: userReducer,
})

export const supportStatusReducer = statusReducer
export const actions = {
    ...generatedActions,
}
