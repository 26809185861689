import React from 'react'
import { invariant } from 'utils/invariant'
import type { PersistedApp } from '../models/app'

export type LoadAppContext = { isLoading: true } | { isLoading: false; app: PersistedApp | null }
export const LoadAppContext = React.createContext<LoadAppContext>(undefined as any)

export const useLoadAppContext = () => {
    const context = React.useContext(LoadAppContext)
    invariant(context, 'useLoadAppContext must be used within a LoadAppContext')

    return context
}

export const EditAppContext = React.createContext<PersistedApp>(undefined as any)

export const useEditAppContext = () => {
    const context = React.useContext(EditAppContext)
    invariant(context, 'useEditAppContext must be used within a EditAppContext')

    return context
}
