import { Typography } from 'antd'
import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
`

export const Column = styled.div`
    flex: 0 0 50%;
`

export const LoadingWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    min-height: 20rem;
`

export const SubHeading = styled(Typography.Title).attrs({
    level: 5,
})`
    margin: 0 0 0.75rem;
    text-align: left;
`
