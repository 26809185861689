import { Alert, Modal } from 'antd'
import type { UnderwritingApplicationFullDto } from 'bff/moons/generated/kale'
import { Formik } from 'formik'
import { Form, Input, SubmitButton } from 'formik-antd'
import { getHighApprovalLimit } from 'pages/customer-success/credit/helpers'
import styled from 'styled-components'
import formatCurrency from 'utils/format-currency'
import * as yup from 'yup'

const getValidationSchema = ({ minLimit, maxLimit }: { minLimit: number; maxLimit: number }) =>
    yup.object().shape({
        approvedLimit: yup.number().required('This field is required').min(minLimit).max(maxLimit),
    })

const convertToMajor = (value: number) => {
    return value / 100
}

interface Props {
    application: UnderwritingApplicationFullDto | null
    minLimit: number
    maxLimit: number
    onSubmit: (values: { approvedLimit: number }) => void
    onCancel: () => void
}

export default function HigherLimitApprovalModal({
    onCancel,
    application,
    maxLimit,
    minLimit,
    onSubmit,
}: Props) {
    if (application === null) return null
    const highApprovalLimit = getHighApprovalLimit(application.requestedLimit.currency)

    const formattedHighApprovalLimit = formatCurrency(
        getHighApprovalLimit(application.requestedLimit.currency),
        application.requestedLimit.currency
    )

    return (
        <Modal
            open={Boolean(application)}
            onCancel={onCancel}
            footer={null}
            centered
            title="Higher Limit Approval"
        >
            <StyledModalContent>
                <Formik
                    enableReinitialize
                    validationSchema={getValidationSchema({ maxLimit, minLimit })}
                    initialValues={{
                        approvedLimit: convertToMajor(application.requestedLimit.value),
                    }}
                    onSubmit={onSubmit}
                >
                    {({ isValid }) => (
                        <Form>
                            <Form.Item name="approvedLimit" label="Approved Limit">
                                <Input
                                    type="number"
                                    name="approvedLimit"
                                    suffix={application.requestedLimit.currency}
                                />
                            </Form.Item>
                            <SubmitButton disabled={!isValid} type="primary">
                                Save
                            </SubmitButton>
                        </Form>
                    )}
                </Formik>
                {application.requestedLimit.value >= highApprovalLimit && (
                    <Alert
                        message="Warning"
                        description={`Any limits greater or equal to ${formattedHighApprovalLimit} needs to be approved by head of credit`}
                        type="warning"
                        showIcon
                    />
                )}
            </StyledModalContent>
        </Modal>
    )
}

const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
