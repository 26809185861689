import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { spacing, inputWidth } from 'theme/tokens'
import { Card, Button, Form, Modal, Select, Space } from 'antd'

import type { Node, UnknownEntity } from 'types/styx'
import { useStyxCompany } from 'services/deimos/styx-company/styx-company'
import { useCompanyEntities, OwnershipMutations } from 'services/deimos/styx-company/ownership'

import AddDirectorForm from './add-director-form/add-director-form'
import RemoveDirectorForm from './remove-director-form/remove-director-form'
import AddShareholderForm from './add-shareholder-form/add-shareholder-form'
import RemoveShareholderForm from './remove-shareholder-form/remove-shareholder-form'
import CreatePersonForm from './create-person-form/create-person-form'
import CreateCompanyForm from './create-company-form/create-company-form'
import ConvertUnknownForm from './convert-unknowns-form/convert-unknowns-form'
import Stakeholders from './stakeholders'
import { ContentContainer } from 'components/layout-containers'
import { useCompanyShareholders } from 'services/styx/ownership'

const { Option } = Select
interface Props {
    fetching: boolean
    shareholderCompanies: Node[]
    handleCreatePerson: () => void
    handleCreateCompany: () => void
    handleConvertUnknown: () => void
    selectedShareholderCompanyId?: string
    getSelectedShareholderCompany: (styxId: string) => void
}

export const EditOwnership: FC<React.PropsWithChildren<Props>> = ({
    handleCreatePerson,
    handleCreateCompany,
    handleConvertUnknown,
    children,
    fetching,
    shareholderCompanies,
    selectedShareholderCompanyId,
    getSelectedShareholderCompany,
}) => (
    <Card title="Edit Ownership Structure" loading={fetching}>
        <ContentContainer>
            <Form layout="vertical">
                <Row>
                    <Form.Item
                        label="Select shareholder company"
                        colon={false}
                        style={{ margin: 0 }}
                    >
                        <Select
                            value={selectedShareholderCompanyId}
                            disabled={fetching}
                            loading={fetching}
                            onChange={getSelectedShareholderCompany}
                            style={{ width: `${inputWidth.large}` }}
                            placeholder="Select a company"
                        >
                            {shareholderCompanies.map(({ name, id }) => (
                                <Option key={id} value={id}>
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Button onClick={handleConvertUnknown}>Convert unknown</Button>
                </Row>
            </Form>
            <Content>{children}</Content>
            <Space>
                <Button onClick={handleCreatePerson}>Create person</Button>
                <Button onClick={handleCreateCompany}>Create company</Button>
            </Space>
        </ContentContainer>
    </Card>
)

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, auto));
    gap: ${spacing.space24};
`

const EditOwnershipContainer = () => {
    const [activeShareholderCompany, setActiveShareholderCompany] = useState('')
    const {
        data: selectedShareholderCompany,
        derived: {
            unassigned,
            shareholders,
            beneficialOwners,
            assigned,
            directors,
            unknownEntities,
            allStakeholderPersons,
            auditTrail,
        },
        mutations,
    } = useCompanyEntities(activeShareholderCompany)
    const [createPersonVisible, setCreatePersonVisible] = useState(false)
    const [createCompanyVisible, setCreateCompanyVisible] = useState(false)
    const [convertUnknownVisible, setConvertUnknownVisible] = useState(false)
    const { company } = useStyxCompany()
    const styxId = company?.id!
    const shareholderCompanies = useCompanyShareholders()

    const getSelectedShareholderCompany = (selectedStyxId: string) =>
        setActiveShareholderCompany(selectedStyxId)

    const onCreatePerson: OwnershipMutations['createPerson'] = async (body) => {
        await mutations.createPerson(body)
        setCreatePersonVisible(false)
    }

    const onCreateCompany: OwnershipMutations['createCompany'] = async (body) => {
        await mutations.createCompany(body)
        setCreateCompanyVisible(false)
    }

    const convertUnknownEntity = async (
        entityType: 'PERSON' | 'COMPANY',
        unknownEntity: UnknownEntity
    ) => {
        switch (entityType) {
            case 'COMPANY':
                await mutations.convertUnknownToCompany(unknownEntity)
                setConvertUnknownVisible(false)
                break
            case 'PERSON':
                await mutations.convertUnknownToPerson(unknownEntity)
                setConvertUnknownVisible(false)
                break
        }
    }

    return (
        <>
            <EditOwnership
                selectedShareholderCompanyId={selectedShareholderCompany?.id}
                shareholderCompanies={shareholderCompanies}
                getSelectedShareholderCompany={getSelectedShareholderCompany}
                fetching={!selectedShareholderCompany}
                handleCreatePerson={() => setCreatePersonVisible(true)}
                handleCreateCompany={() => setCreateCompanyVisible(true)}
                handleConvertUnknown={() => setConvertUnknownVisible(true)}
            >
                <AddDirectorForm
                    disabled={!selectedShareholderCompany}
                    unassigned={unassigned}
                    assigned={assigned}
                    onAddDirectors={mutations.addDirectors}
                />
                <RemoveDirectorForm
                    disabled={!selectedShareholderCompany}
                    directors={selectedShareholderCompany?.directors}
                    onRemoveDirectors={mutations.removeDirectors}
                />
                <AddShareholderForm
                    disabled={!selectedShareholderCompany}
                    unassigned={unassigned}
                    assigned={assigned}
                    onAddShareholder={mutations.addShareholder}
                    companies={shareholderCompanies}
                />
                <RemoveShareholderForm
                    disabled={!selectedShareholderCompany}
                    shareholders={selectedShareholderCompany?.shareholders}
                    onRemoveShareholders={mutations.removeShareholders}
                />
            </EditOwnership>
            <Stakeholders
                shareholders={shareholders}
                beneficialOwners={beneficialOwners}
                directors={directors}
                unassigned={unassigned}
                allStakeholderPersons={allStakeholderPersons}
                removeStakeholderFromScope={mutations.removeStakeholderFromScope}
                auditTrail={auditTrail}
            />
            <Modal
                footer={null}
                title="Create a Person"
                open={createPersonVisible}
                onCancel={() => setCreatePersonVisible(false)}
                width={600}
                centered
                destroyOnClose
            >
                <CreatePersonForm onCreatePerson={onCreatePerson} rootCompanyId={styxId} />
            </Modal>
            <Modal
                footer={null}
                title="Create a Company"
                open={createCompanyVisible}
                onCancel={() => setCreateCompanyVisible(false)}
                destroyOnClose
            >
                <CreateCompanyForm onCreateCompany={onCreateCompany} rootCompanyId={styxId} />
            </Modal>
            <Modal
                footer={null}
                title="Convert unknown entity"
                open={convertUnknownVisible}
                onCancel={() => setConvertUnknownVisible(false)}
                destroyOnClose
            >
                <ConvertUnknownForm
                    selectedShareholderCompanyId={selectedShareholderCompany?.id}
                    companies={shareholderCompanies}
                    disabled={!selectedShareholderCompany}
                    unknownEntities={unknownEntities}
                    getUnknownEntities={getSelectedShareholderCompany}
                    convertUnknownEntity={convertUnknownEntity}
                />
            </Modal>
        </>
    )
}

export default EditOwnershipContainer
