import { useSelector } from 'react-redux'
import { useCompanyBalance } from 'services/deimos/companies'
import { usePleoReserveEligibility } from 'services/deimos/pleo-reserve'
import { selectCompanyId } from 'store/modules/support/company/selectors'

export const usePleoReserveEligibleLimit = () => {
    const companyId = useSelector(selectCompanyId)
    const pleoReserve = usePleoReserveEligibility(companyId)

    const value = pleoReserve.data?.terms?.reserveLimit
    const currency = pleoReserve.data?.terms?.currency

    if (value && currency) {
        return {
            value,
            currency,
        }
    }
}

export const usePleoReserveActiveLimit = () => {
    const companyId = useSelector(selectCompanyId)
    const companyBalance = useCompanyBalance(companyId)
    const pleoReserve = usePleoReserveEligibility(companyId)

    const value = companyBalance.data?.balance?.creditLimit?.value
    const currency = pleoReserve.data?.terms?.currency

    if (pleoReserve.data?.enabled && value && currency) {
        return {
            value,
            currency,
        }
    }
}
