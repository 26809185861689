import { useState } from 'react'
import { Card, Skeleton } from 'antd'
import Cards from 'components/employees/cards/cards'
import { useParams } from 'react-router-dom'
import { TravelInsurance } from 'components/travel-insurance/travel-insurance'
import { Pocket } from 'components/pocket/mark-pocket-expense-settled'
import { bff } from '../../backend/bff-hooks'

enum TabKey {
    CARDS = 'CARDS',
    TRAVEL_INSURANCE = 'TRAVEL_INSURANCE',
    POCKET = 'POCKET',
}

const tabList = [
    { key: TabKey.CARDS, tab: 'Cards' },
    { key: TabKey.TRAVEL_INSURANCE, tab: 'Travel insurance' },
    { key: TabKey.POCKET, tab: 'Pocket' },
]

export const CardTabs = ({ employeeId }: { employeeId?: string }) => {
    const [activeTabKey, setActiveTabKey] = useState<TabKey>(TabKey.CARDS)

    const { employeeId: entityEmployeeId } = useParams()
    const { data: employee, isLoading: employeeIsLoading } = bff.cardsTabs.getEmployee.useQuery({
        employeeId,
        entityEmployeeId,
    })

    const tabContent = {
        [TabKey.CARDS]: (
            <>
                {employeeIsLoading ? (
                    <Skeleton active />
                ) : (
                    <Cards
                        employeeId={entityEmployeeId || employeeId}
                        companyId={employee?.companyId}
                        cardAccess={employee?.hasCardAccess}
                    />
                )}
            </>
        ),
        [TabKey.TRAVEL_INSURANCE]: <TravelInsurance employeeId={employeeId} />,
        [TabKey.POCKET]: <Pocket employeeId={employeeId} />,
    }

    // Hide the cards tab if the user is an entity employee but is not the spendingEntity
    if (entityEmployeeId && !employee?.isSpendingEntityEmployee) {
        return null
    } else {
        return (
            <Card tabList={tabList} onTabChange={(key) => setActiveTabKey(key as TabKey)}>
                {tabContent[activeTabKey]}
            </Card>
        )
    }
}
