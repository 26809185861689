/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Eirene API
 * Eirene OpenAPI definitions

This moon provide several endpoints to enable employees and admins to perform reimbursements. A more comprehensive description of the different endpoints and how they are expected to be used can be found [here](https://www.notion.so/pleo/Reimbursement-related-endpoints-14df1a4ed5724efb9428e859b5b64082)
 * OpenAPI spec version: 17.7.0
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('eireneV1')

export type GetBackOfficeEmployeeBankTransfersParams = {
    status?: BankTransferStatusModel
    page?: number
    limit?: number
}

export type WalletReimbursementTypeResponseModel =
    (typeof WalletReimbursementTypeResponseModel)[keyof typeof WalletReimbursementTypeResponseModel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletReimbursementTypeResponseModel = {
    BANK_TRANSFER: 'BANK_TRANSFER',
    CARD: 'CARD',
} as const

export interface WalletReimbursementEnabledResponseModel {
    key: string
    value: string
}

export type WalletReimbursementDestinationResponseModelType =
    (typeof WalletReimbursementDestinationResponseModelType)[keyof typeof WalletReimbursementDestinationResponseModelType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletReimbursementDestinationResponseModelType = {
    CARD: 'CARD',
    IBAN_BANK_ACCOUNT: 'IBAN_BANK_ACCOUNT',
    DOMESTIC_BANK_ACCOUNT: 'DOMESTIC_BANK_ACCOUNT',
} as const

export type WalletReimbursementDestinationResponseModelCardScheme =
    (typeof WalletReimbursementDestinationResponseModelCardScheme)[keyof typeof WalletReimbursementDestinationResponseModelCardScheme]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WalletReimbursementDestinationResponseModelCardScheme = {
    MASTERCARD: 'MASTERCARD',
    VISA: 'VISA',
} as const

export interface WalletReimbursementDestinationResponseModel {
    cardScheme?: WalletReimbursementDestinationResponseModelCardScheme
    countryCode?: string
    createdAt: string
    fastFunds: boolean
    highFailureRate: boolean
    id: string
    last4Digits: string
    type: WalletReimbursementDestinationResponseModelType
}

export type SetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType =
    (typeof SetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType)[keyof typeof SetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType = {
    ADMIN_ONLY: 'ADMIN_ONLY',
    ADMIN_AND_EMPLOYEE: 'ADMIN_AND_EMPLOYEE',
    DISABLED: 'DISABLED',
} as const

export interface SetWalletReimbursementPermissionTypeResponseModel {
    walletReimbursementEnabled: boolean
    walletReimbursementPermissionType: SetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType
}

export type SetWalletReimbursementPermissionTypeRequestModelWalletReimbursementPermissionType =
    (typeof SetWalletReimbursementPermissionTypeRequestModelWalletReimbursementPermissionType)[keyof typeof SetWalletReimbursementPermissionTypeRequestModelWalletReimbursementPermissionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetWalletReimbursementPermissionTypeRequestModelWalletReimbursementPermissionType = {
    ADMIN_ONLY: 'ADMIN_ONLY',
    ADMIN_AND_EMPLOYEE: 'ADMIN_AND_EMPLOYEE',
    DISABLED: 'DISABLED',
} as const

export interface SetWalletReimbursementPermissionTypeRequestModel {
    walletReimbursementPermissionType: SetWalletReimbursementPermissionTypeRequestModelWalletReimbursementPermissionType
}

export interface SetWalletReimbursementEnabledRequestModel {
    enabled: boolean
}

export type ScreeningStatusResponseModel =
    (typeof ScreeningStatusResponseModel)[keyof typeof ScreeningStatusResponseModel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScreeningStatusResponseModel = {
    PASSED: 'PASSED',
    PASSED_MANUALLY: 'PASSED_MANUALLY',
    FAILED: 'FAILED',
    FAILED_MANUALLY: 'FAILED_MANUALLY',
    ERRORED: 'ERRORED',
    NOT_SCREENED: 'NOT_SCREENED',
} as const

export interface ScreeningResultsResponseModel {
    bank: ScreeningStatusResponseModel
    bankAccount: ScreeningStatusResponseModel
    reviewNote?: string
}

export type ReviewReimbursementScreeningRequestModelEmployeeReviewResult =
    (typeof ReviewReimbursementScreeningRequestModelEmployeeReviewResult)[keyof typeof ReviewReimbursementScreeningRequestModelEmployeeReviewResult]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReviewReimbursementScreeningRequestModelEmployeeReviewResult = {
    PASS: 'PASS',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    FAILURE_CONFIRMED: 'FAILURE_CONFIRMED',
} as const

export type ReviewReimbursementScreeningRequestModelBankReviewResult =
    (typeof ReviewReimbursementScreeningRequestModelBankReviewResult)[keyof typeof ReviewReimbursementScreeningRequestModelBankReviewResult]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReviewReimbursementScreeningRequestModelBankReviewResult = {
    PASS: 'PASS',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    FAILURE_CONFIRMED: 'FAILURE_CONFIRMED',
} as const

export interface ReviewReimbursementScreeningRequestModel {
    bankReviewResult: ReviewReimbursementScreeningRequestModelBankReviewResult
    employeeReviewResult: ReviewReimbursementScreeningRequestModelEmployeeReviewResult
    note: string
}

export type ReviewBankTransferScreeningRequestModelBankReviewResult =
    (typeof ReviewBankTransferScreeningRequestModelBankReviewResult)[keyof typeof ReviewBankTransferScreeningRequestModelBankReviewResult]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReviewBankTransferScreeningRequestModelBankReviewResult = {
    PASS: 'PASS',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    FAILURE_CONFIRMED: 'FAILURE_CONFIRMED',
} as const

export type ReviewBankTransferScreeningRequestModelBankAccountReviewResult =
    (typeof ReviewBankTransferScreeningRequestModelBankAccountReviewResult)[keyof typeof ReviewBankTransferScreeningRequestModelBankAccountReviewResult]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReviewBankTransferScreeningRequestModelBankAccountReviewResult = {
    PASS: 'PASS',
    FALSE_POSITIVE: 'FALSE_POSITIVE',
    FAILURE_CONFIRMED: 'FAILURE_CONFIRMED',
} as const

export interface ReviewBankTransferScreeningRequestModel {
    bankAccountReviewResult: ReviewBankTransferScreeningRequestModelBankAccountReviewResult
    bankReviewResult: ReviewBankTransferScreeningRequestModelBankReviewResult
    note: string
}

export type ResultErrorStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ResultError = {
    localizedMessage?: string
    message?: string
    stackTrace?: ResultErrorStackTraceItem[]
}

export type ResultDetails = { [key: string]: { [key: string]: any } }

export interface Result {
    details?: ResultDetails
    error?: ResultError
    healthy: boolean
    message?: string
    time: string
}

export interface PostalAddressModel {
    address1: string
    city: string
    countryCode: string
    zipCode: string
}

export interface OffsetPaginatedResponseBackOfficeEmployeeBankTransferResponseModel {
    count: number
    data: BackOfficeEmployeeBankTransferResponseModel[]
}

export interface Money {
    currency?: string
    value?: number
}

export type GetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType =
    (typeof GetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType)[keyof typeof GetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType = {
    ADMIN_ONLY: 'ADMIN_ONLY',
    ADMIN_AND_EMPLOYEE: 'ADMIN_AND_EMPLOYEE',
    DISABLED: 'DISABLED',
} as const

export interface GetWalletReimbursementPermissionTypeResponseModel {
    walletReimbursementPermissionType: GetWalletReimbursementPermissionTypeResponseModelWalletReimbursementPermissionType
}

export interface GetWalletReimbursementEnablementResponseModel {
    enabled: boolean
}

export interface GetReimbursementRefundDetailsResponseModel {
    id: string
    refundExpenseId: string
    refundedAmount: Money
    reimbursementAmount: Money
    reimbursementExpenseId: string
    reimbursementRefundedAt: string
    reimbursementRequestedAt: string
}

export type GetReimbursementDetailsResponseModelStatus =
    (typeof GetReimbursementDetailsResponseModelStatus)[keyof typeof GetReimbursementDetailsResponseModelStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReimbursementDetailsResponseModelStatus = {
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE',
    FAILED: 'FAILED',
    REFUNDED: 'REFUNDED',
} as const

export type GetReimbursementDetailsResponseModelPaymentMethod =
    (typeof GetReimbursementDetailsResponseModelPaymentMethod)[keyof typeof GetReimbursementDetailsResponseModelPaymentMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReimbursementDetailsResponseModelPaymentMethod = {
    BANK_TRANSFER: 'BANK_TRANSFER',
    CARD: 'CARD',
    CASH: 'CASH',
    IN_PERSON: 'IN_PERSON',
    IN_SALARY: 'IN_SALARY',
    OTHER: 'OTHER',
} as const

export type GetReimbursementDetailsResponseModelFundsOrigin =
    (typeof GetReimbursementDetailsResponseModelFundsOrigin)[keyof typeof GetReimbursementDetailsResponseModelFundsOrigin]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReimbursementDetailsResponseModelFundsOrigin = {
    WALLET: 'WALLET',
    NON_WALLET: 'NON_WALLET',
} as const

export type GetReimbursementDetailsResponseModelDirection =
    (typeof GetReimbursementDetailsResponseModelDirection)[keyof typeof GetReimbursementDetailsResponseModelDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetReimbursementDetailsResponseModelDirection = {
    EMPLOYEE_TO_COMPANY: 'EMPLOYEE_TO_COMPANY',
    COMPANY_TO_EMPLOYEE: 'COMPANY_TO_EMPLOYEE',
} as const

export interface GetReimbursementDetailsResponseModel {
    amount: Money
    companyId: string
    direction: GetReimbursementDetailsResponseModelDirection
    expenseId: string
    fundsOrigin: GetReimbursementDetailsResponseModelFundsOrigin
    id: string
    initiatedByEmployeeId: string
    isSelfReimbursed: boolean
    paymentMethod: GetReimbursementDetailsResponseModelPaymentMethod
    refundExpenseId?: string
    requestedAt: string
    sourceId: string
    status: GetReimbursementDetailsResponseModelStatus
    targetEmployeeId: string
    updatedAt: string
    walletReimbursementDestination?: WalletReimbursementDestinationResponseModel
}

export type GetAvailableEmployeeInitiatedReimbursementTypesResponseModelLimit = {
    [key: string]: number
}

export interface GetAvailableEmployeeInitiatedReimbursementTypesResponseModel {
    bankTransferReimbursementsEnabled: boolean
    canEditBankAccounts: boolean
    canEditCards: boolean
    cardReimbursementsEnabled: boolean
    limit: GetAvailableEmployeeInitiatedReimbursementTypesResponseModelLimit
}

export interface GetAvailableAdminInitiatedReimbursementTypesResponseModel {
    bankTransferReimbursementsEnabled: boolean
    cardReimbursementsEnabled: boolean
    hasBankAccount: boolean
    hasCard: boolean
    nonWalletReimbursementsEnabled: boolean
}

export interface EmployeeDetails {
    employeeAddress?: PostalAddressModel
    employeeDateOfBirth?: string
    employeeId: string
    employeeName: string
}

export interface EmployeeBankTransferResponseModel {
    id: string
}

export interface EmployeeBankTransferCreationRequestModel {
    amount: number
    currency: string
}

export interface EmployeeBankAccountDetailsResponseModel {
    bankDetails: BankAccountDetailsModel
    employeeAddress: PostalAddressModel
    employeeDateOfBirth: string
    employeeId: string
    employeeName: string
}

export interface EmployeeAndBankScreeningStatusResponseModel {
    bank: ScreeningStatusResponseModel
    employee: ScreeningStatusResponseModel
    reviewNote?: string
}

export interface CreateNonWalletReimbursementResponse {
    expenseId: string
    id: string
}

export type CreateNonWalletReimbursementRequestPaymentMethod =
    (typeof CreateNonWalletReimbursementRequestPaymentMethod)[keyof typeof CreateNonWalletReimbursementRequestPaymentMethod]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateNonWalletReimbursementRequestPaymentMethod = {
    BANK_TRANSFER: 'BANK_TRANSFER',
    CASH: 'CASH',
    IN_PERSON: 'IN_PERSON',
    IN_SALARY: 'IN_SALARY',
    OTHER: 'OTHER',
} as const

export type CreateNonWalletReimbursementRequestDirection =
    (typeof CreateNonWalletReimbursementRequestDirection)[keyof typeof CreateNonWalletReimbursementRequestDirection]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateNonWalletReimbursementRequestDirection = {
    EMPLOYEE_TO_COMPANY: 'EMPLOYEE_TO_COMPANY',
    COMPANY_TO_EMPLOYEE: 'COMPANY_TO_EMPLOYEE',
} as const

export interface CreateNonWalletReimbursementRequest {
    amount: number
    currency: string
    direction: CreateNonWalletReimbursementRequestDirection
    expenseIds?: string[]
    note?: string
    paymentMethod: CreateNonWalletReimbursementRequestPaymentMethod
}

export interface CreateEmployeeInitiatedWalletReimbursementResponse {
    id: string
}

export interface CreateEmployeeInitiatedWalletReimbursementRequest {
    amount: number
    currency: string
    reimbursementDestinationId: string
}

export interface CreateAdminInitiatedWalletReimbursementResponse {
    expenseId: string
    id: string
}

export interface CreateAdminInitiatedWalletReimbursementRequest {
    amount: number
    currency: string
    expenseIds: string[]
    note?: string
    reimbursementDestinationId: string
}

export interface CompanyResponseModel {
    id?: string
    name?: string
}

export type CardResponseModelCardScheme =
    (typeof CardResponseModelCardScheme)[keyof typeof CardResponseModelCardScheme]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CardResponseModelCardScheme = {
    MASTERCARD: 'MASTERCARD',
    VISA: 'VISA',
} as const

export interface CardResponseModel {
    cardScheme?: CardResponseModelCardScheme
    createdAt: string
    id: string
    last4Digits: string
}

export interface CardCreateRequestModel {
    token: string
}

export type BankTransferStatusModel =
    (typeof BankTransferStatusModel)[keyof typeof BankTransferStatusModel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankTransferStatusModel = {
    REQUESTED: 'REQUESTED',
    AUTHORIZED: 'AUTHORIZED',
    PENDING_SCREENING_REVIEW: 'PENDING_SCREENING_REVIEW',
    SCREENED: 'SCREENED',
    INITIATED: 'INITIATED',
    FAILED: 'FAILED',
    SENT: 'SENT',
    SETTLED: 'SETTLED',
    PAYMENT_RETURNED: 'PAYMENT_RETURNED',
    REFUNDED: 'REFUNDED',
} as const

export interface BankDetails {
    countryCode: string
    name: string
}

export interface BankAccountResponseModel {
    accountNumber?: string
    bankCode?: string
    bic?: string
    countryCode: string
    createdAt: string
    iban?: string
    id: string
}

export type BankAccountDetailsModelCountryCode =
    (typeof BankAccountDetailsModelCountryCode)[keyof typeof BankAccountDetailsModelCountryCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankAccountDetailsModelCountryCode = {
    UNDEFINED: 'UNDEFINED',
    AC: 'AC',
    AD: 'AD',
    AE: 'AE',
    AF: 'AF',
    AG: 'AG',
    AI: 'AI',
    AL: 'AL',
    AM: 'AM',
    AN: 'AN',
    AO: 'AO',
    AQ: 'AQ',
    AR: 'AR',
    AS: 'AS',
    AT: 'AT',
    AU: 'AU',
    AW: 'AW',
    AX: 'AX',
    AZ: 'AZ',
    BA: 'BA',
    BB: 'BB',
    BD: 'BD',
    BE: 'BE',
    BF: 'BF',
    BG: 'BG',
    BH: 'BH',
    BI: 'BI',
    BJ: 'BJ',
    BL: 'BL',
    BM: 'BM',
    BN: 'BN',
    BO: 'BO',
    BQ: 'BQ',
    BR: 'BR',
    BS: 'BS',
    BT: 'BT',
    BU: 'BU',
    BV: 'BV',
    BW: 'BW',
    BY: 'BY',
    BZ: 'BZ',
    CA: 'CA',
    CC: 'CC',
    CD: 'CD',
    CF: 'CF',
    CG: 'CG',
    CH: 'CH',
    CI: 'CI',
    CK: 'CK',
    CL: 'CL',
    CM: 'CM',
    CN: 'CN',
    CO: 'CO',
    CP: 'CP',
    CR: 'CR',
    CS: 'CS',
    CU: 'CU',
    CV: 'CV',
    CW: 'CW',
    CX: 'CX',
    CY: 'CY',
    CZ: 'CZ',
    DE: 'DE',
    DG: 'DG',
    DJ: 'DJ',
    DK: 'DK',
    DM: 'DM',
    DO: 'DO',
    DZ: 'DZ',
    EA: 'EA',
    EC: 'EC',
    EE: 'EE',
    EG: 'EG',
    EH: 'EH',
    ER: 'ER',
    ES: 'ES',
    ET: 'ET',
    EU: 'EU',
    EZ: 'EZ',
    FI: 'FI',
    FJ: 'FJ',
    FK: 'FK',
    FM: 'FM',
    FO: 'FO',
    FR: 'FR',
    FX: 'FX',
    GA: 'GA',
    GB: 'GB',
    GD: 'GD',
    GE: 'GE',
    GF: 'GF',
    GG: 'GG',
    GH: 'GH',
    GI: 'GI',
    GL: 'GL',
    GM: 'GM',
    GN: 'GN',
    GP: 'GP',
    GQ: 'GQ',
    GR: 'GR',
    GS: 'GS',
    GT: 'GT',
    GU: 'GU',
    GW: 'GW',
    GY: 'GY',
    HK: 'HK',
    HM: 'HM',
    HN: 'HN',
    HR: 'HR',
    HT: 'HT',
    HU: 'HU',
    IC: 'IC',
    ID: 'ID',
    IE: 'IE',
    IL: 'IL',
    IM: 'IM',
    IN: 'IN',
    IO: 'IO',
    IQ: 'IQ',
    IR: 'IR',
    IS: 'IS',
    IT: 'IT',
    JE: 'JE',
    JM: 'JM',
    JO: 'JO',
    JP: 'JP',
    KE: 'KE',
    KG: 'KG',
    KH: 'KH',
    KI: 'KI',
    KM: 'KM',
    KN: 'KN',
    KP: 'KP',
    KR: 'KR',
    KW: 'KW',
    KY: 'KY',
    KZ: 'KZ',
    LA: 'LA',
    LB: 'LB',
    LC: 'LC',
    LI: 'LI',
    LK: 'LK',
    LR: 'LR',
    LS: 'LS',
    LT: 'LT',
    LU: 'LU',
    LV: 'LV',
    LY: 'LY',
    MA: 'MA',
    MC: 'MC',
    MD: 'MD',
    ME: 'ME',
    MF: 'MF',
    MG: 'MG',
    MH: 'MH',
    MK: 'MK',
    ML: 'ML',
    MM: 'MM',
    MN: 'MN',
    MO: 'MO',
    MP: 'MP',
    MQ: 'MQ',
    MR: 'MR',
    MS: 'MS',
    MT: 'MT',
    MU: 'MU',
    MV: 'MV',
    MW: 'MW',
    MX: 'MX',
    MY: 'MY',
    MZ: 'MZ',
    NA: 'NA',
    NC: 'NC',
    NE: 'NE',
    NF: 'NF',
    NG: 'NG',
    NI: 'NI',
    NL: 'NL',
    NO: 'NO',
    NP: 'NP',
    NR: 'NR',
    NT: 'NT',
    NU: 'NU',
    NZ: 'NZ',
    OM: 'OM',
    PA: 'PA',
    PE: 'PE',
    PF: 'PF',
    PG: 'PG',
    PH: 'PH',
    PK: 'PK',
    PL: 'PL',
    PM: 'PM',
    PN: 'PN',
    PR: 'PR',
    PS: 'PS',
    PT: 'PT',
    PW: 'PW',
    PY: 'PY',
    QA: 'QA',
    RE: 'RE',
    RO: 'RO',
    RS: 'RS',
    RU: 'RU',
    RW: 'RW',
    SA: 'SA',
    SB: 'SB',
    SC: 'SC',
    SD: 'SD',
    SE: 'SE',
    SF: 'SF',
    SG: 'SG',
    SH: 'SH',
    SI: 'SI',
    SJ: 'SJ',
    SK: 'SK',
    SL: 'SL',
    SM: 'SM',
    SN: 'SN',
    SO: 'SO',
    SR: 'SR',
    SS: 'SS',
    ST: 'ST',
    SU: 'SU',
    SV: 'SV',
    SX: 'SX',
    SY: 'SY',
    SZ: 'SZ',
    TA: 'TA',
    TC: 'TC',
    TD: 'TD',
    TF: 'TF',
    TG: 'TG',
    TH: 'TH',
    TJ: 'TJ',
    TK: 'TK',
    TL: 'TL',
    TM: 'TM',
    TN: 'TN',
    TO: 'TO',
    TP: 'TP',
    TR: 'TR',
    TT: 'TT',
    TV: 'TV',
    TW: 'TW',
    TZ: 'TZ',
    UA: 'UA',
    UG: 'UG',
    UK: 'UK',
    UM: 'UM',
    US: 'US',
    UY: 'UY',
    UZ: 'UZ',
    VA: 'VA',
    VC: 'VC',
    VE: 'VE',
    VG: 'VG',
    VI: 'VI',
    VN: 'VN',
    VU: 'VU',
    WF: 'WF',
    WS: 'WS',
    XI: 'XI',
    XU: 'XU',
    XK: 'XK',
    YE: 'YE',
    YT: 'YT',
    YU: 'YU',
    ZA: 'ZA',
    ZM: 'ZM',
    ZR: 'ZR',
    ZW: 'ZW',
} as const

export interface BankAccountDetailsModel {
    accountNumber?: string
    bankCode?: string
    bic?: string
    countryCode: BankAccountDetailsModelCountryCode
    iban?: string
    name: string
}

export interface BankAccountCreateRequestModel {
    accountNumber?: string
    bankCode?: string
    bic?: string
    countryCode: string
    employeeAddress: PostalAddressModel
    employeeDateOfBirth: string
    employeeName: string
    iban?: string
    isSingleUse?: boolean
}

export type BackOfficeReimbursementStatus =
    (typeof BackOfficeReimbursementStatus)[keyof typeof BackOfficeReimbursementStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BackOfficeReimbursementStatus = {
    PENDING: 'PENDING',
    PENDING_SCREENING_REVIEW: 'PENDING_SCREENING_REVIEW',
    SCREENED: 'SCREENED',
    FAILED: 'FAILED',
    COMPLETE: 'COMPLETE',
    REFUNDED: 'REFUNDED',
} as const

export interface AmountModel {
    currency: string
    value: number
}

export interface BackOfficeReimbursementResponseModel {
    amount: AmountModel
    bankDetails: BankDetails
    company: CompanyResponseModel
    employeeDetails: EmployeeDetails
    id: string
    reimbursementType: WalletReimbursementTypeResponseModel
    requestedAt: string
    screeningStatus: EmployeeAndBankScreeningStatusResponseModel
    status: BackOfficeReimbursementStatus
}

export interface BackOfficeEmployeeBankTransferResponseModel {
    amount: AmountModel
    bankAccountDetails: EmployeeBankAccountDetailsResponseModel
    company: CompanyResponseModel
    createdAt: string
    id: string
    screeningStatus: ScreeningResultsResponseModel
    status: BankTransferStatusModel
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * @summary Returns the health of the service
 */
export const check = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<Result>({ url: `/health`, method: 'get' }, options)
}

/**
 * Result is paginated
 * @summary Get bank transfers
 */
export const getBackOfficeEmployeeBankTransfers = (
    params: GetBackOfficeEmployeeBankTransfersParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<OffsetPaginatedResponseBackOfficeEmployeeBankTransferResponseModel>(
        { url: `/v1/back-office/bank-transfers`, method: 'get', params },
        options
    )
}

/**
 * @summary Get bank transfer
 */
export const getBackOfficeEmployeeBankTransfer = (
    bankTransferId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BackOfficeEmployeeBankTransferResponseModel>(
        { url: `/v1/back-office/bank-transfers/${bankTransferId}`, method: 'get' },
        options
    )
}

/**
 * @summary Review bank transfer screening result
 */
export const reviewBankTransferScreeningResult = (
    bankTransferId: string,
    reviewBankTransferScreeningRequestModel: ReviewBankTransferScreeningRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BackOfficeEmployeeBankTransferResponseModel>(
        {
            url: `/v1/back-office/bank-transfers/${bankTransferId}/screening-review`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: reviewBankTransferScreeningRequestModel,
        },
        options
    )
}

/**
 * @summary Delete bank account
 */
export const deleteBankAccount = (
    bankAccountId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/bank-details/bank-accounts/${bankAccountId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Get employee bank accounts
 */
export const getEmployeeBankAccounts = (
    employeeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BankAccountResponseModel[]>(
        { url: `/v1/bank-details/employees/${employeeId}/bank-accounts`, method: 'get' },
        options
    )
}

/**
 * @summary Create bank account
 */
export const createBankAccount = (
    employeeId: string,
    bankAccountCreateRequestModel: BankAccountCreateRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BankAccountResponseModel>(
        {
            url: `/v1/bank-details/employees/${employeeId}/bank-accounts`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: bankAccountCreateRequestModel,
        },
        options
    )
}

/**
 * @summary Create wallet bank transfer self reimbursement
 */
export const createPocketReimbursement = (
    bankAccountId: string,
    employeeBankTransferCreationRequestModel: EmployeeBankTransferCreationRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<EmployeeBankTransferResponseModel>(
        {
            url: `/v1/bank-transfers/bank-accounts/${bankAccountId}/pocket-reimbursements`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: employeeBankTransferCreationRequestModel,
        },
        options
    )
}

/**
 * It is only available to admin, bookkeepers, and users with settlePocketExpense scope
 * @summary Delete a non wallet reimbursement
 */
export const deleteNonWalletReimbursement = (
    companyId: string,
    expenseId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/reimbursements/${companyId}/non-wallet/${expenseId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Get wallet reimbursement permission type for company
 */
export const getWalletReimbursementEnablementForCompany = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetWalletReimbursementEnablementResponseModel>(
        { url: `/v1/reimbursements/${companyId}/settings/wallet/enablement`, method: 'get' },
        options
    )
}

/**
 * @summary Get wallet reimbursement permission type for company
 */
export const getWalletReimbursementPermissionTypeForCompany = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetWalletReimbursementPermissionTypeResponseModel>(
        { url: `/v1/reimbursements/${companyId}/settings/wallet/permission-type`, method: 'get' },
        options
    )
}

/**
 * @summary Set wallet reimbursement permission type for company
 */
export const setWalletReimbursementPermissionTypeForCompany = (
    companyId: string,
    setWalletReimbursementPermissionTypeRequestModel: SetWalletReimbursementPermissionTypeRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<SetWalletReimbursementPermissionTypeResponseModel>(
        {
            url: `/v1/reimbursements/${companyId}/settings/wallet/permission-type`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: setWalletReimbursementPermissionTypeRequestModel,
        },
        options
    )
}

/**
 * It is only available to admin, bookkeepers, and users with settlePocketExpense scope
 * @summary Create admin-initiated bank-transfer wallet reimbursement
 */
export const createAdminInitiatedBankTransferReimbursementToEmployee = (
    companyId: string,
    employeeId: string,
    createAdminInitiatedWalletReimbursementRequest: CreateAdminInitiatedWalletReimbursementRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateAdminInitiatedWalletReimbursementResponse>(
        {
            url: `/v1/reimbursements/${companyId}/to/${employeeId}/bank-transfer`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createAdminInitiatedWalletReimbursementRequest,
        },
        options
    )
}

/**
 * It is only available to admin, bookkeepers, and users with settlePocketExpense scope
 * @summary Create admin-initiated card wallet reimbursement
 */
export const createAdminInitiatedCardReimbursementToEmployee = (
    companyId: string,
    employeeId: string,
    createAdminInitiatedWalletReimbursementRequest: CreateAdminInitiatedWalletReimbursementRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateAdminInitiatedWalletReimbursementResponse>(
        {
            url: `/v1/reimbursements/${companyId}/to/${employeeId}/card-reimbursement`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createAdminInitiatedWalletReimbursementRequest,
        },
        options
    )
}

/**
 * @summary Get allowed admin initiated reimbursement types for company/employee
 */
export const getAllowedAdminInitiatedReimbursementTypes = (
    companyId: string,
    employeeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetAvailableAdminInitiatedReimbursementTypesResponseModel>(
        { url: `/v1/reimbursements/${companyId}/to/${employeeId}/types`, method: 'get' },
        options
    )
}

/**
 * @summary Get allowed employee initiated reimbursement types for company
 */
export const getAllowedEmployeeInitiatedReimbursementTypes = (
    companyId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetAvailableEmployeeInitiatedReimbursementTypesResponseModel>(
        { url: `/v1/reimbursements/${companyId}/types`, method: 'get' },
        options
    )
}

/**
 * @summary Set wallet-reimbursement-enabled for company
 */
export const setWalletReimbursementEnabled = (
    companyId: string,
    setWalletReimbursementEnabledRequestModel: SetWalletReimbursementEnabledRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletReimbursementEnabledResponseModel>(
        {
            url: `/v1/reimbursements/${companyId}/wallet-reimbursement-enabled`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: setWalletReimbursementEnabledRequestModel,
        },
        options
    )
}

/**
 * @summary Create bank account
 */
export const apiCreateBankAccount = (
    companyId: string,
    employeeId: string,
    bankAccountCreateRequestModel: BankAccountCreateRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BankAccountResponseModel>(
        {
            url: `/v1/reimbursements/${companyId}/${employeeId}/bank-accounts`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: bankAccountCreateRequestModel,
        },
        options
    )
}

/**
 * @summary Delete bank account
 */
export const apiDeleteBankAccount = (
    companyId: string,
    employeeId: string,
    bankAccountId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        {
            url: `/v1/reimbursements/${companyId}/${employeeId}/bank-accounts/${bankAccountId}`,
            method: 'delete',
        },
        options
    )
}

/**
 * @summary Create employee-initiated bank-transfer wallet reimbursement
 */
export const createEmployeeInitiatedBankTransferReimbursement = (
    companyId: string,
    employeeId: string,
    createEmployeeInitiatedWalletReimbursementRequest: CreateEmployeeInitiatedWalletReimbursementRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateEmployeeInitiatedWalletReimbursementResponse>(
        {
            url: `/v1/reimbursements/${companyId}/${employeeId}/bank-transfer`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createEmployeeInitiatedWalletReimbursementRequest,
        },
        options
    )
}

/**
 * @summary Create employee-initiated card wallet reimbursement
 */
export const createEmployeeInitiatedCardReimbursement = (
    companyId: string,
    employeeId: string,
    createEmployeeInitiatedWalletReimbursementRequest: CreateEmployeeInitiatedWalletReimbursementRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateEmployeeInitiatedWalletReimbursementResponse>(
        {
            url: `/v1/reimbursements/${companyId}/${employeeId}/card`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createEmployeeInitiatedWalletReimbursementRequest,
        },
        options
    )
}

/**
 * @summary Create card
 */
export const createCard = (
    companyId: string,
    employeeId: string,
    cardCreateRequestModel: CardCreateRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CardResponseModel>(
        {
            url: `/v1/reimbursements/${companyId}/${employeeId}/cards`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: cardCreateRequestModel,
        },
        options
    )
}

/**
 * @summary Delete card
 */
export const deleteCard = (
    companyId: string,
    employeeId: string,
    cardId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<unknown>(
        { url: `/v1/reimbursements/${companyId}/${employeeId}/cards/${cardId}`, method: 'delete' },
        options
    )
}

/**
 * @summary Create a non wallet reimbursement
 */
export const createNonWalletReimbursementForEmployee = (
    companyId: string,
    employeeId: string,
    createNonWalletReimbursementRequest: CreateNonWalletReimbursementRequest,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CreateNonWalletReimbursementResponse>(
        {
            url: `/v1/reimbursements/${companyId}/${employeeId}/non-wallet`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createNonWalletReimbursementRequest,
        },
        options
    )
}

/**
 * @summary Get reimbursement refund details given companyId/employeeId/refundExpenseId
 */
export const getReimbursementRefundDetails = (
    companyId: string,
    employeeId: string,
    refundExpenseId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetReimbursementRefundDetailsResponseModel>(
        {
            url: `/v1/reimbursements/${companyId}/${employeeId}/refund/${refundExpenseId}`,
            method: 'get',
        },
        options
    )
}

/**
 * @summary Get wallet reimbursement targets for company/employee
 */
export const getWalletReimbursementTargetsForEmployee = (
    companyId: string,
    employeeId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<WalletReimbursementDestinationResponseModel[]>(
        { url: `/v1/reimbursements/${companyId}/${employeeId}/targets`, method: 'get' },
        options
    )
}

/**
 * @summary Get reimbursement details given companyId/employeeId/expenseId
 */
export const getReimbursementDetails = (
    companyId: string,
    employeeId: string,
    expenseId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<GetReimbursementDetailsResponseModel>(
        { url: `/v1/reimbursements/${companyId}/${employeeId}/${expenseId}`, method: 'get' },
        options
    )
}

/**
 * @summary Get all reimbursements that are pending screening review
 */
export const v2getAllReimbursementsPendingScreeningReview = (
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BackOfficeReimbursementResponseModel[]>(
        { url: `/v2/back-office/reimbursement/pending-screening-review`, method: 'get' },
        options
    )
}

/**
 * @summary Get reimbursement detail for back office
 */
export const v2getBackOfficeReimbursementDetail = (
    reimbursementSourceId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BackOfficeReimbursementResponseModel>(
        { url: `/v2/back-office/reimbursement/${reimbursementSourceId}`, method: 'get' },
        options
    )
}

/**
 * @summary Review reimbursement screening result
 */
export const v2reviewScreeningResult = (
    reimbursementSourceId: string,
    reviewReimbursementScreeningRequestModel: ReviewReimbursementScreeningRequestModel,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<BackOfficeReimbursementResponseModel>(
        {
            url: `/v2/back-office/reimbursement/${reimbursementSourceId}/screening-review`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: reviewReimbursementScreeningRequestModel,
        },
        options
    )
}

export type CheckResult = NonNullable<Awaited<ReturnType<typeof check>>>
export type GetBackOfficeEmployeeBankTransfersResult = NonNullable<
    Awaited<ReturnType<typeof getBackOfficeEmployeeBankTransfers>>
>
export type GetBackOfficeEmployeeBankTransferResult = NonNullable<
    Awaited<ReturnType<typeof getBackOfficeEmployeeBankTransfer>>
>
export type ReviewBankTransferScreeningResultResult = NonNullable<
    Awaited<ReturnType<typeof reviewBankTransferScreeningResult>>
>
export type DeleteBankAccountResult = NonNullable<Awaited<ReturnType<typeof deleteBankAccount>>>
export type GetEmployeeBankAccountsResult = NonNullable<
    Awaited<ReturnType<typeof getEmployeeBankAccounts>>
>
export type CreateBankAccountResult = NonNullable<Awaited<ReturnType<typeof createBankAccount>>>
export type CreatePocketReimbursementResult = NonNullable<
    Awaited<ReturnType<typeof createPocketReimbursement>>
>
export type DeleteNonWalletReimbursementResult = NonNullable<
    Awaited<ReturnType<typeof deleteNonWalletReimbursement>>
>
export type GetWalletReimbursementEnablementForCompanyResult = NonNullable<
    Awaited<ReturnType<typeof getWalletReimbursementEnablementForCompany>>
>
export type GetWalletReimbursementPermissionTypeForCompanyResult = NonNullable<
    Awaited<ReturnType<typeof getWalletReimbursementPermissionTypeForCompany>>
>
export type SetWalletReimbursementPermissionTypeForCompanyResult = NonNullable<
    Awaited<ReturnType<typeof setWalletReimbursementPermissionTypeForCompany>>
>
export type CreateAdminInitiatedBankTransferReimbursementToEmployeeResult = NonNullable<
    Awaited<ReturnType<typeof createAdminInitiatedBankTransferReimbursementToEmployee>>
>
export type CreateAdminInitiatedCardReimbursementToEmployeeResult = NonNullable<
    Awaited<ReturnType<typeof createAdminInitiatedCardReimbursementToEmployee>>
>
export type GetAllowedAdminInitiatedReimbursementTypesResult = NonNullable<
    Awaited<ReturnType<typeof getAllowedAdminInitiatedReimbursementTypes>>
>
export type GetAllowedEmployeeInitiatedReimbursementTypesResult = NonNullable<
    Awaited<ReturnType<typeof getAllowedEmployeeInitiatedReimbursementTypes>>
>
export type SetWalletReimbursementEnabledResult = NonNullable<
    Awaited<ReturnType<typeof setWalletReimbursementEnabled>>
>
export type ApiCreateBankAccountResult = NonNullable<
    Awaited<ReturnType<typeof apiCreateBankAccount>>
>
export type ApiDeleteBankAccountResult = NonNullable<
    Awaited<ReturnType<typeof apiDeleteBankAccount>>
>
export type CreateEmployeeInitiatedBankTransferReimbursementResult = NonNullable<
    Awaited<ReturnType<typeof createEmployeeInitiatedBankTransferReimbursement>>
>
export type CreateEmployeeInitiatedCardReimbursementResult = NonNullable<
    Awaited<ReturnType<typeof createEmployeeInitiatedCardReimbursement>>
>
export type CreateCardResult = NonNullable<Awaited<ReturnType<typeof createCard>>>
export type DeleteCardResult = NonNullable<Awaited<ReturnType<typeof deleteCard>>>
export type CreateNonWalletReimbursementForEmployeeResult = NonNullable<
    Awaited<ReturnType<typeof createNonWalletReimbursementForEmployee>>
>
export type GetReimbursementRefundDetailsResult = NonNullable<
    Awaited<ReturnType<typeof getReimbursementRefundDetails>>
>
export type GetWalletReimbursementTargetsForEmployeeResult = NonNullable<
    Awaited<ReturnType<typeof getWalletReimbursementTargetsForEmployee>>
>
export type GetReimbursementDetailsResult = NonNullable<
    Awaited<ReturnType<typeof getReimbursementDetails>>
>
export type VgetAllReimbursementsPendingScreeningReviewResult = NonNullable<
    Awaited<ReturnType<typeof v2getAllReimbursementsPendingScreeningReview>>
>
export type VgetBackOfficeReimbursementDetailResult = NonNullable<
    Awaited<ReturnType<typeof v2getBackOfficeReimbursementDetail>>
>
export type VreviewScreeningResultResult = NonNullable<
    Awaited<ReturnType<typeof v2reviewScreeningResult>>
>
