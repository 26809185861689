import type { Department } from '@pleo-io/deimos'
import { useCallback } from 'react'
import { buildFetcher, deimosRequest, oberonRequest } from 'services/request'
import useSWR from 'swr'
import type { GetTeam, GetTeamResponse } from 'types/team'

const isEmployeeInTeam = (team: GetTeam, employeeId: string) => {
    if (!team?.members) return false
    const filter = team.members.filter((member) => member.id === employeeId)
    if (filter.length > 0) return true
    return false
}

export const useEmployeeTeam = (companyId?: string | null, employeeId?: string) => {
    const { data: allTeams, ...rest } = useSWR<GetTeamResponse>(
        companyId ? `rest/v1/teams?companyId=${companyId}` : null,
        buildFetcher(oberonRequest)
    )

    const teamPresentIn = useCallback(() => {
        if (!employeeId || !allTeams || allTeams.data.length === 0) {
            return null
        }
        const teams = allTeams.data.filter((team) => isEmployeeInTeam(team, employeeId))
        return teams[0]
    }, [allTeams, employeeId])

    return { team: teamPresentIn(), allTeams, ...rest }
}

export const useDepartment = (teamId?: string) => {
    const { data: department, ...rest } = useSWR<Department>(
        teamId ? `rest/v1/departments/${teamId}?includeReviewerMembers=true` : null,
        buildFetcher(deimosRequest)
    )

    return { department, ...rest }
}
