import { Table } from 'antd'
import { useNavigate } from 'react-router-dom'

import {
    oddStatusColumn,
    oddTriggerColumn,
    dueDateColumn,
    oddStartedColumn,
} from 'features/odd/columns'
import { TableStyleProvider } from 'features/odd/table'

import { bff } from '../../bff'
import { useCompanyContext } from '../context'

export const OddCases = () => {
    const { companyId } = useCompanyContext()
    const navigate = useNavigate()
    const { data: oddCases, isLoading } = bff.oddCases.getOddCasesForCompany.useQuery({ companyId })

    const handleRowClick = (id: string) => {
        navigate(`/compliance/odd/${id}`)
    }

    return (
        <TableStyleProvider>
            <Table
                loading={isLoading}
                dataSource={oddCases?.data}
                columns={[oddTriggerColumn, oddStatusColumn, oddStartedColumn, dueDateColumn]}
                onRow={({ id }) => ({ onClick: () => handleRowClick(id) })}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                    total: oddCases?.pagination?.total,
                }}
            />
        </TableStyleProvider>
    )
}
