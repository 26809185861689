import { FC, useCallback, useEffect, useState } from 'react'
import dayjs from 'packages/dayjs'
import type { ColumnProps } from 'antd/lib/table'
import { Table, Button, Popconfirm, notification, Typography } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import CursorPaginationControls from 'components/cursor-pagination-controls'
import { SupplierInvoice, InvoiceStatus } from 'types/deimos-supplier'
import * as supplierSelectors from 'store/modules/suppliers/selectors'
import { actions as supplierActions } from 'store/modules/suppliers/slice'
import formatCurrency from 'utils/format-currency'
import type { CursorPaginationCommand } from 'types/cursor-pagination'
import { DownloadInvoiceButton } from './download-invoice'
import { postRejectInvoice } from 'services/deimos/suppliers'
import { ContentContainer } from 'components/layout-containers'
import { PageOrder } from '@pleo-io/deimos'

const { Text } = Typography

type InvoicesListProps = {
    supplierId: string
}

export const InvoicesList: FC<React.PropsWithChildren<InvoicesListProps>> = ({ supplierId }) => {
    const dispatch = useDispatch()
    const invoices = useSelector(supplierSelectors.selectInvoices)
    const status = useSelector(supplierSelectors.selectInvoicesStatus)
    const pagination = useSelector(supplierSelectors.selectInvoicesPagination)
    const requestParams = useSelector(supplierSelectors.selectInvoicesRequestParams)

    const fetchForCurrentSupplier = useCallback(
        (command?: CursorPaginationCommand) => {
            dispatch(
                supplierActions.fetchSupplierInvoices({
                    supplierId,
                    command,
                })
            )
        },
        [supplierId, dispatch]
    )

    useEffect(() => {
        fetchForCurrentSupplier()
    }, [fetchForCurrentSupplier])

    const columns: ColumnProps<SupplierInvoice>[] = [
        {
            title: 'Date added',
            dataIndex: 'createdAt',
            render: (createdAt) => dayjs(createdAt).format('lll'),
            sorter: true,
            sortOrder:
                requestParams.sorting?.find((sort) => !!sort.id)?.id === 'ASC'
                    ? 'ascend'
                    : 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
        },
        {
            title: 'Invoice ID',
            dataIndex: 'id',
            render: (billId) => <Text copyable>{billId}</Text>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount, { currency }) => (
                <span>
                    {currency
                        ? formatCurrency(amount, currency, { currencyDisplay: 'code' })
                        : amount}
                </span>
            ),
        },
        {
            title: 'Company',
            dataIndex: 'companyId',
            render: (companyId, { companyName }) =>
                companyId ? (
                    <Link to={`/compliance/companies/${companyId}`}>
                        {companyName ?? companyId.slice(0, 7)}
                    </Link>
                ) : (
                    'Unknown'
                ),
        },
        {
            title: 'File',
            dataIndex: 'fileExtension',
            render: (_, invoice) => <DownloadInvoiceButton invoice={invoice} />,
        },
        {
            title: 'Original',
            dataIndex: 'fileExtension',
            render: (_, invoice) => <DownloadInvoiceButton invoice={invoice} original />,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_status) => _status,
        },
        {
            title: 'Reject',
            dataIndex: 'id',
            render: (invoiceId, invoice) =>
                invoice.status === InvoiceStatus.FAILED_INTERNAL_VALIDATION ? (
                    'Rejected'
                ) : (
                    <RejectInvoiceButton invoiceId={invoiceId} onReject={fetchForCurrentSupplier} />
                ),
        },
    ]

    return (
        <ContentContainer>
            <Table
                data-testid="table"
                dataSource={invoices}
                columns={columns}
                loading={status === 'fetching'}
                rowKey="id"
                pagination={false}
                onChange={(_, filters, sorters) => {
                    const sorter = Array.isArray(sorters) ? sorters[0] : sorters
                    fetchForCurrentSupplier({
                        filters,
                        sorting: [
                            { id: sorter.order === 'ascend' ? PageOrder.ASC : PageOrder.DESC },
                        ],
                    })
                }}
            />
            {pagination && (
                <CursorPaginationControls
                    isFetching={status === 'fetching'}
                    pagination={pagination}
                    onChangeLimit={(limit) => fetchForCurrentSupplier({ limit })}
                    limit={requestParams.limit}
                    onNextPage={() => fetchForCurrentSupplier({ page: 'next' })}
                    onPrevPage={() => fetchForCurrentSupplier({ page: 'prev' })}
                    onFirstPage={() => fetchForCurrentSupplier({ page: 'first' })}
                />
            )}
        </ContentContainer>
    )
}

const RejectInvoiceButton: FC<
    React.PropsWithChildren<{ invoiceId: string; onReject: () => void }>
> = ({ invoiceId, onReject }) => {
    const [confirmVisible, setConfirmVisible] = useState(false)
    const [isRejecting, setIsRejecting] = useState(false)

    return (
        <Popconfirm
            title="Are you sure want to reject this invoice?"
            placement="topRight"
            open={confirmVisible}
            onCancel={() => {
                setConfirmVisible(false)
            }}
            okButtonProps={{
                onClick: async () => {
                    setIsRejecting(true)
                    setConfirmVisible(false)
                    try {
                        await postRejectInvoice(invoiceId)
                        onReject()
                    } catch (e) {
                        notification.error({
                            message: 'Failed to search for suppliers.',
                            description: (e as Error).message,
                        })
                    } finally {
                        setIsRejecting(false)
                    }
                },
            }}
        >
            <Button onClick={() => setConfirmVisible(true)} loading={isRejecting}>
                Reject
            </Button>
        </Popconfirm>
    )
}
