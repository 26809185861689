import { notification } from 'antd'
import type { PaginatedResponseFullWalletLoad } from 'bff/moons/generated/cupid-v1'
import { useParams } from 'react-router-dom'
import { fetcher } from 'services/cupid/request'
import { retryWalletLoad, setWalletLoadStatus } from 'services/deimos/wallet'
import useSWR from 'swr'
import type { InvoiceStatus } from 'types/wallet-invoice'

export const useWalletLoads = () => {
    const { id: companyId } = useParams()

    const response = useSWR<PaginatedResponseFullWalletLoad>(
        companyId ? `rest/v4/companies/${companyId}/wallet-loads` : null,
        fetcher
    )

    const handleRetryWalletLoad = async (loadId: string) => {
        return retryWalletLoad([loadId])
            .then(() => {
                notification.success({
                    message: 'Wallet load retry successful!',
                })
                response.mutate()
            })
            .catch((error) => {
                notification.error({
                    message: 'Retrying wallet load failed.',
                    description: error.message,
                })
            })
    }

    const setInvoiceStatus = async (loadId: string, status: InvoiceStatus) => {
        return setWalletLoadStatus(loadId, status)
            .then(() => {
                notification.success({
                    message: 'Load successfully cancelled!',
                })
                response.mutate()
            })
            .catch((error) => {
                notification.error({
                    message: 'Load cancelation failed.',
                    description: error.message,
                })
            })
    }

    return {
        walletLoads: response.data?.result,
        isLoading: !response.data && !response.error,
        handleRetryWalletLoad,
        setInvoiceStatus,
        ...response,
    }
}
