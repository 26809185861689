import useSWR from 'swr'

import { fetcher } from './request'
import { kerberosRequest } from 'services/request'
import { PartnerAccessLevel, DisconnectedPartnerCompany } from 'types/partner-portal'

export async function connectPartnerClient(
    partnerId: string,
    companyId: string,
    level: PartnerAccessLevel = PartnerAccessLevel.BASIC
) {
    const response = await kerberosRequest().post(`api/v1/partners/${partnerId}/connect`, {
        json: { companyId, level },
    })
    return await response.json()
}

export async function disconnectPartnerClient(partnerId: string, companyId: string) {
    const response = await kerberosRequest().delete(`api/v1/partners/${partnerId}/disconnect`, {
        json: { companyId },
    })
    return await response.json()
}

export async function assignPartnerBookkeeper(
    partnerId: string,
    userId: string,
    companyId: string
) {
    const response = await kerberosRequest().post(`api/v1/partners/${partnerId}/assign`, {
        json: { userId, companyId },
    })
    return await response.json()
}

export async function unassignPartnerBookkeeper(
    partnerId: string,
    userId: string,
    companyId: string
) {
    const response = await kerberosRequest().delete(`api/v1/partners/${partnerId}/unassign`, {
        json: { userId, companyId },
    })
    return await response.json()
}

// SWR

export const useGetDisconnectedPartnerClients = (partnerId: string) => {
    const result = useSWR<DisconnectedPartnerCompany[], Error>(
        partnerId ? `api/v1/partners/${partnerId}/clients?disconnected=true` : '',
        fetcher,
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        }
    )

    return result
}
