import type { EmployeeRole } from 'types/employee'
import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
    userRole: Yup.string().required('Required'),
})

export type FormValues = {
    email: string
    userRole: EmployeeRole
}
