import styled from 'styled-components'
import { spacing } from '../../../../../theme/tokens'
import { FormItem } from 'formik-antd'

export const FormLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 ${spacing.space24};

    & .ant-input-number {
        width: 100%;
    }
`

export const FormItemLong = styled(FormItem)`
    grid-column-start: span 2;
`
