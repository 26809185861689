import { useState } from 'react'
import ReadableTeam from './readable-team'
import type { Team } from '../../types'
import EditableTeam from './editable-team'

type ListItemProps = Team & {
    index: number
    deleteTeam: (index: number) => void
}

const ListItem = ({ name, size, reviewerCount, index, deleteTeam }: ListItemProps) => {
    const [isEditing, setIsEditing] = useState(false)

    return isEditing ? (
        <EditableTeam
            name={name}
            size={size}
            reviewerCount={reviewerCount}
            deleteTeam={deleteTeam}
            toggleEditing={setIsEditing}
            index={index}
        />
    ) : (
        <ReadableTeam
            name={name}
            size={size}
            reviewerCount={reviewerCount}
            toggleEditing={setIsEditing}
        />
    )
}

export default ListItem
