import { Form, Select } from 'antd'
import {
    getDemoProfile,
    useGetDemoProfiles,
} from '../../../../../../../../services/dionysus/demo-accounts'
import { mapProfilesToOptions } from './helpers'
import { useFormikContext } from 'formik'
import { initialProfileValues } from '../../utils'
import type { initialValues } from '../../initialValues'
import { Input, FormItem } from 'formik-antd'
import type { FormValuesProfile } from '../../types'
import { useUser } from 'providers/user-context'
import { isPast } from 'utils/dayjs'
import DatePicker from 'packages/form/date-picker'

const ProfileStep = () => {
    const { data: demoProfiles } = useGetDemoProfiles()
    const { setValues } = useFormikContext()
    const options = mapProfilesToOptions(demoProfiles ?? [])
    const { values } = useFormikContext<FormValuesProfile>()
    const { email, firstName } = useUser()
    const handleChange = async (selectedProfileId: string) => {
        const profile = await getDemoProfile(selectedProfileId)
        setValues({
            ...values,
            ...profile,
            selectedProfileId,
        })
        // Temp unknown typecast until we have the types from the moon
        initialProfileValues.setProfileValues(profile as unknown as typeof initialValues)
    }

    return (
        <Form layout="vertical">
            <FormItem
                name="meta.selectedProfileId"
                label={'Select profile that fits your target market:'}
            >
                <Select
                    style={{ width: 250 }}
                    onChange={handleChange}
                    options={options}
                    defaultValue={values.selectedProfileId}
                />
            </FormItem>
            <FormItem
                name="meta.companyName"
                label={'Company name:'}
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Input
                    name="meta.companyName"
                    value={values.meta.companyName}
                    placeholder={`${firstName} Company`}
                    required
                />
            </FormItem>
            <FormItem name="meta.requesterEmail" label={"Who should we message once it's created:"}>
                <Input
                    name="meta.requesterEmail"
                    value={values.meta.requesterEmail}
                    placeholder={email ?? ''}
                    required
                />
            </FormItem>
            <FormItem name="meta.expiresAt" label={'When should we delete this profile:'}>
                <DatePicker name="meta.expiresAt" disabledDate={isPast} allowClear />
            </FormItem>
        </Form>
    )
}

export default ProfileStep
