import * as yup from 'yup'

export const validationSchema = yup
    .object()
    .shape({
        selectedShareholders: yup
            .array()
            .required('Required')
            .min(1, 'Select one or more shareholders to remove'),
    })
    .required()

export type FormValues = yup.InferType<typeof validationSchema>
