import type {
    Department,
    DepartmentReviewerThresholdsRangeResponse,
    ReviewerEmployee,
} from '@pleo-io/deimos'
import { Avatar, Divider, Popover, Space, Typography } from 'antd'

import { Link } from 'react-router-dom'
import { buildFetcher, oberonRequest } from 'services/request'
import styled from 'styled-components'
import useSWR from 'swr'
import type { GetTeam, TeamMember as TeamMemberType } from 'types/team'
import { blue } from '@ant-design/colors'
import { InfoCircleOutlined } from '@ant-design/icons'
import { formatMoney } from 'utils/money'
import { useColorScheme } from 'hooks/use-color-scheme'

const { Text } = Typography

const TeamMemberWrapper = styled.div`
    width: 100%;
    justify-content: space-between;
    padding: 8px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
`

const Grid = styled.div`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
`

const Flex = styled.div`
    display: flex;
    align-items: center;

    * {
        flex-shrink: 0;
    }
`

const Heading = styled(Text)`
    padding: 16px 24px;
    display: inline-block;
    font-weight: bold;
`

const MembersWrapper = styled.div`
    max-height: 200px;
    overflow: auto;
    max-width: 100%;
    padding: 0 16px 16px 16px;
`

const getTeamMemberStyle = () => {
    const { shouldUseDarkMode } = useColorScheme()

    return shouldUseDarkMode
        ? { background: 'none', outline: 'aliceblue 1px solid', marginTop: '1px' }
        : { background: blue[0] }
}

const getInfoStyle = () => {
    const { shouldUseDarkMode } = useColorScheme()

    return shouldUseDarkMode ? { color: 'white' } : { color: blue[5] }
}

const TeamMember: React.FC<{
    teamMember: TeamMemberType | ReviewerEmployee
    companyId: GetTeam['companyId']
}> = ({ teamMember, companyId }) => {
    return (
        <Link to={`/customer-success/employee-360/${companyId}/${teamMember.id}`}>
            <TeamMemberWrapper style={getTeamMemberStyle()}>
                <Flex>
                    <Avatar
                        size="small"
                        style={{ marginRight: '8px', backgroundColor: blue[5] }}
                    >{`${teamMember?.firstName && teamMember.firstName[0]}${
                        teamMember?.lastName && teamMember.lastName[0]
                    }`}</Avatar>
                    <Text
                        style={{
                            maxWidth: '150px',
                        }}
                        ellipsis={{ tooltip: `${teamMember.firstName} ${teamMember.lastName}` }}
                    >{`${teamMember.firstName} ${teamMember.lastName}`}</Text>
                </Flex>
            </TeamMemberWrapper>
        </Link>
    )
}

const Thresholds: React.FC<{
    thresholds?: DepartmentReviewerThresholdsRangeResponse
}> = ({ thresholds }) => {
    return (
        <Space direction="vertical">
            <Text strong>Card</Text>
            {thresholds?.cardReview.enabled
                ? `${formatMoney(thresholds?.cardReview.minThreshold) || 'No minimum'} -
            ${formatMoney(thresholds?.cardReview.maxThreshold) || 'No maximum'}`
                : 'Not enabled'}
            <Text strong>Pocket</Text>
            {thresholds?.pocketReview.enabled
                ? `${formatMoney(thresholds?.pocketReview.minThreshold) || 'No minimum'} -
            ${formatMoney(thresholds?.pocketReview.maxThreshold) || 'No maximum'}`
                : 'Not enabled'}
        </Space>
    )
}

const TeamReviewer: React.FC<{
    teamReviewer: ReviewerEmployee
    companyId: GetTeam['companyId']
}> = ({ teamReviewer, companyId }) => {
    return (
        <Link to={`/customer-success/employee-360/${companyId}/${teamReviewer.id}`}>
            <TeamMemberWrapper style={getTeamMemberStyle()}>
                <Flex>
                    <Avatar
                        size="small"
                        style={{ marginRight: '8px', backgroundColor: blue[5] }}
                    >{`${teamReviewer?.firstName && teamReviewer.firstName[0]}${
                        teamReviewer?.lastName && teamReviewer.lastName[0]
                    }`}</Avatar>
                    <Text
                        style={{
                            maxWidth: '150px',
                        }}
                        ellipsis={{ tooltip: `${teamReviewer.firstName} ${teamReviewer.lastName}` }}
                    >{`${teamReviewer.firstName} ${teamReviewer.lastName}`}</Text>
                </Flex>
                <Popover
                    title="Reviewer thresholds"
                    content={
                        <Thresholds
                            thresholds={
                                teamReviewer.thresholds as DepartmentReviewerThresholdsRangeResponse
                            }
                        />
                    }
                >
                    <InfoCircleOutlined
                        onClick={(e: any) => e.preventDefault()}
                        style={getInfoStyle()}
                    />
                </Popover>
            </TeamMemberWrapper>
        </Link>
    )
}

export const TeamMembers: React.FC<{ team: GetTeam }> = ({ team }) => {
    const { department } = useDepartment(team?.id)

    return (
        <>
            <Heading>Reviewers</Heading>
            <MembersWrapper>
                {(department?.reviewers || []).length > 0 ? (
                    <Grid>
                        {department?.reviewers
                            ?.sort((a, b) => sortAlphabetically(a, b))
                            .map((member) => (
                                <TeamReviewer
                                    key={`${member?.id}-reviewer`}
                                    teamReviewer={member}
                                    companyId={team?.companyId}
                                />
                            ))}
                    </Grid>
                ) : (
                    <Text>This team has no reviewers</Text>
                )}
            </MembersWrapper>
            <Divider style={{ margin: '8px 0' }} />
            <Heading>Members</Heading>
            <MembersWrapper>
                {(team?.members || []).length > 0 ? (
                    <Grid>
                        {team?.members
                            ?.sort((a, b) => sortAlphabetically(a, b))
                            .map((member) => (
                                <TeamMember
                                    key={`${member?.id}-member`}
                                    teamMember={member}
                                    companyId={team?.companyId}
                                />
                            ))}
                    </Grid>
                ) : (
                    <Text>This team has no members</Text>
                )}
            </MembersWrapper>
        </>
    )
}

const sortAlphabetically = (
    a: TeamMemberType | ReviewerEmployee,
    b: TeamMemberType | ReviewerEmployee
) => {
    if (!a?.lastName || !b?.lastName) return 2
    if (a.lastName < b.lastName) return -1
    if (a.lastName > b.lastName) return 1
    return 0
}

export const useDepartment = (teamId?: string) => {
    const { data: department, ...rest } = useSWR<Department>(
        teamId
            ? `rest/v1/departments/${teamId}?includeReviewerMembers=true&includeReviewerThresholdsRange=true`
            : null,
        buildFetcher(oberonRequest)
    )

    return { department, ...rest }
}
