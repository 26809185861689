import type { FC } from 'react'
import { Table, Typography, Result } from 'antd'
import dayjs from 'packages/dayjs'
import type { RiskAssessment } from 'types/styx'
import { useStyxClosedCompany } from 'services/deimos/styx-company/styx-company'
import { hasCompletedAllRiskChecks } from 'pages/compliance/shared/risk-assessment/utils'
interface Props {
    companyName: string
    riskAssessment?: RiskAssessment
}

const { Text } = Typography

export const AssessmentResult: FC<React.PropsWithChildren<Props>> = ({ riskAssessment }) => {
    const dataSource = riskAssessment ? [riskAssessment] : []

    if (!hasCompletedAllRiskChecks(riskAssessment?.riskChecks)) {
        return <Result status="info" title="Risk checks Incomplete" />
    }

    return (
        <Table
            title={() => <Text strong>Assessment Result</Text>}
            rowKey="riskAssessmentId"
            pagination={false}
            dataSource={dataSource}
            columns={[
                {
                    title: 'Total score',
                    dataIndex: 'score',
                },
                {
                    title: 'Result',
                    key: 'result',
                },
                {
                    title: 'Notes',
                    dataIndex: 'notes',
                },
                {
                    title: 'Date',
                    dataIndex: 'assessmentDate',
                    key: 'date',
                    render: (assessmentDate) =>
                        assessmentDate ? dayjs(assessmentDate).format('lll') : 'No assessment date',
                },
                { title: 'Owner', dataIndex: 'ownerName' },
            ]}
        />
    )
}

export const AssessmentResultContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const { company, riskAssessment } = useStyxClosedCompany()
    const companyName = company?.legalName ?? ''

    return (
        <>
            <AssessmentResult companyName={companyName} riskAssessment={riskAssessment} />
        </>
    )
}

export default AssessmentResultContainer
