import request from './request'
import type { CursorPaginationCommand, CursorRequestParams } from 'types/cursor-pagination'
import type { PageOrder, PaginationData } from '@pleo-io/deimos'

// Handles fetching a cursor paginated resource according to current pagination and request
// settings and optionally a command object that defines the changes that user requested
export function getCursorPaginatedResource(
    path: string,
    pagination: PaginationData<string>,
    requestParams: CursorRequestParams,
    command: CursorPaginationCommand | undefined
) {
    // start with the current request params (and merge in the filters from
    // "parameters" object) filter out any empty values and remove the
    // "parameters" itself
    const entries = Object.entries({
        ...requestParams,
        ...requestParams.parameters,
    }).filter(([key, value]) => !!value && key !== 'parameters')

    // using any since URLSearchParams in ts only accepts strings
    // (in reality it casts values to string)
    const searchParams = new URLSearchParams(entries as any)

    // handle page command - got to next/previous page by using cursors
    // or clear any cursor settings to go to the first page
    if (command?.page === 'next') {
        searchParams.set('after', pagination.endCursor!)
        searchParams.delete('before')
    }
    if (command?.page === 'prev') {
        searchParams.set('before', pagination.startCursor!)
        searchParams.delete('after')
    }
    if (command?.page === 'first') {
        searchParams.delete('after')
        searchParams.delete('before')
    }

    // handle order and limit, for limit we clear the cursors as well
    // to always start from the first page and avoid e.g. first page
    // with just a few items
    if (command?.sorting) {
        searchParams.set('sorting', JSON.stringify(command.sorting))
    } else if (searchParams.has('sorting')) {
        searchParams.set('sorting', JSON.stringify(requestParams.sorting))
    }

    if (command?.limit) {
        searchParams.set('limit', command.limit.toString())
        searchParams.delete('after')
        searchParams.delete('before')
    }

    // handle setting filters - each filter is just another query param
    // with values merged to a comma separated string. If the filter
    // is an empty array - clear the filter altogether
    if (command?.filters) {
        for (const filter of Object.keys(command.filters)) {
            const value = command.filters[filter]?.join(',')
            if (value?.length) {
                searchParams.set(filter, value)
            } else {
                searchParams.delete(filter)
            }
            searchParams.delete('after')
            searchParams.delete('before')
        }
    }
    return request().get(path, { searchParams })
}

export const parseSorting = (sortingParam: string) =>
    sortingParam.split('|').map((sort: string) => {
        const [key, value] = sort.split(':')
        return { [key]: value as PageOrder }
    })

export const serializeSorting = (sorting: CursorRequestParams['sorting']) =>
    sorting
        .map((sort) =>
            Object.keys(sort)
                .map((key) => `${key}:${sort[key]}`)
                .join('')
        )
        .join('|')
