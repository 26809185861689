import { PageContentLayout } from 'components/layout-containers'
import { Alert, Card, Col, Empty, Row } from 'antd'
import { CompanyAndDateSelector } from '../components/company-date-selector'
import { useCompanyReviewHistory } from '../api/use-company-review-history'
import { formatDate } from 'packages/dates/dates'
import { useMemo } from 'react'
import { ReviewStructureDelta } from '../components/review-structure-delta'
import { ReviewStructureTree } from '../components/review-structure-tree'

export const CompanyReviewStructure = () => {
    const { reviewHistory, companyId, at, diffWith, isLoading, error } = useCompanyReviewHistory()

    const hasDeltas = useMemo(() => {
        return (
            diffWith &&
            reviewHistory?.deltas &&
            (Object.keys(reviewHistory.deltas.companiesAdded).length > 0 ||
                Object.keys(reviewHistory.deltas.companiesChanged).length > 0 ||
                Object.keys(reviewHistory.deltas.companiesRemoved).length > 0)
        )
    }, [diffWith, reviewHistory?.deltas])

    return (
        <PageContentLayout>
            <CompanyAndDateSelector />
            {error && <Alert message={error.message} type="error" />}
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card
                        type="inner"
                        title={at && `Review structure at ${formatDate(at)}`}
                        loading={isLoading}
                    >
                        {reviewHistory?.structure ? (
                            <ReviewStructureTree reviewHistory={reviewHistory} />
                        ) : (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    companyId
                                        ? 'No review structure found for this company'
                                        : 'Enter a company ID to view the review structure'
                                }
                            />
                        )}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        type="inner"
                        title={
                            at &&
                            diffWith &&
                            `Changes from ${formatDate(at)} to ${formatDate(diffWith)}`
                        }
                        loading={isLoading}
                    >
                        {hasDeltas ? (
                            <ReviewStructureDelta deltas={reviewHistory?.deltas} />
                        ) : (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    diffWith
                                        ? 'No comparison data to display for selected dates'
                                        : 'Select a date above to compare the review structure at different points in time'
                                }
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </PageContentLayout>
    )
}
