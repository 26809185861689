import useSWR from 'swr'
import { fetcher } from 'services/styx/request'
import type { ErrorResponse } from '@pleo-io/deimos'

export const useIncorporationTypes = () => {
    const url = `rest/v1/incorporation-types`

    const response = useSWR<string[], ErrorResponse>(url, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })

    return response
}
