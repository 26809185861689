import { DayjsDatePicker } from 'packages/form/date-picker'
import { FC, useEffect, useState } from 'react'
import { inputWidth, spacing } from 'theme/tokens'
import { Tabs, Tooltip, Result } from 'antd'
import dayjs from 'packages/dayjs'
import styled from 'styled-components'
import type { ErrorResponse } from '@pleo-io/deimos'
import type { KycCheckpoint, RiskAssessment, RiskAssessmentV2 } from 'types/styx'

const { TabPane } = Tabs

interface Props {
    error?: ErrorResponse
    kycCheckpoints: KycCheckpoint[]
    onFetchSnapshot: (snapshot?: string) => void
    readonlyChildren: (snapshot: string) => JSX.Element
    riskAssessment?: RiskAssessment | RiskAssessmentV2
}

const SnapshotTabs: FC<React.PropsWithChildren<Props>> = ({
    children,
    error,
    kycCheckpoints,
    onFetchSnapshot,
    readonlyChildren,
    riskAssessment,
}) => {
    const [customSnapshot, setCustomSnapshot] = useState(dayjs().toISOString())
    const [activeTab, setActiveTab] = useState<string>()
    const hasRiskAssessmentResult = !!riskAssessment?.result
    const firstTabTitle = riskAssessment ? 'Draft' : 'Current'
    const showFirstTab = !riskAssessment || !hasRiskAssessmentResult

    useEffect(() => {
        // if a new risk assessment has been created, set the active tab to latest snapshot
        if (activeTab === firstTabTitle && !showFirstTab) {
            setActiveTab(kycCheckpoints[0]?.performed)

            // if the current active tab is not known, then set it to the first tab
        } else if (!activeTab && showFirstTab) {
            setActiveTab(firstTabTitle)
        } else if (!activeTab && kycCheckpoints.length > 0) {
            const initialSnapshot = kycCheckpoints[0].performed
            setActiveTab(initialSnapshot)
            onFetchSnapshot(initialSnapshot)

            // if the active tab is 'Current' or 'Draft' then clear the snapshot value
        } else if (activeTab === firstTabTitle) {
            onFetchSnapshot(undefined)

            // if not the 'custom' tab, fetch the snapshot for the active tab
        } else if (activeTab !== 'custom') {
            onFetchSnapshot(activeTab)
        }
    }, [kycCheckpoints, onFetchSnapshot, showFirstTab, activeTab, firstTabTitle, riskAssessment])

    return (
        <Tabs
            activeKey={activeTab}
            defaultActiveKey={showFirstTab ? firstTabTitle : kycCheckpoints[0]?.performed}
            onChange={(snapshot: string) => {
                setActiveTab(snapshot)
            }}
            type="card"
        >
            {showFirstTab && (
                <TabPane tab={firstTabTitle} key={firstTabTitle}>
                    {children}
                </TabPane>
            )}
            {kycCheckpoints.map((checkpoint) => {
                const snapshot = checkpoint.performed
                const tabLabel = `${checkpoint.level} — ${dayjs(snapshot).format('lll')}`
                return (
                    <TabPane
                        tab={
                            <Tooltip title={checkpoint.notes}>
                                <span>{tabLabel}</span>
                            </Tooltip>
                        }
                        key={snapshot}
                    >
                        {error ? <SnapshotErrorResult error={error} /> : readonlyChildren(snapshot)}
                    </TabPane>
                )
            })}
            <TabPane tab="Custom snapshot" key="custom">
                <Padding>
                    <DayjsDatePicker
                        showTime
                        onChange={(dayjsTimestamp) => {
                            if (dayjsTimestamp && dayjsTimestamp.isValid()) {
                                const snapshot = dayjsTimestamp.toISOString()
                                setCustomSnapshot(snapshot)
                                onFetchSnapshot(snapshot)
                            }
                        }}
                        style={{ width: `${inputWidth.large}` }}
                    />
                </Padding>
                {error ? <SnapshotErrorResult error={error} /> : readonlyChildren(customSnapshot)}
            </TabPane>
        </Tabs>
    )
}

const Padding = styled.div`
    padding-left: ${spacing.space24};
    padding-bottom: ${spacing.space24};
`

const getResultStatus = (status: number) => {
    if (status === 404 || status === 500) {
        return status
    } else {
        return 'error'
    }
}

const SnapshotErrorResult = ({ error }: { error: ErrorResponse }) => (
    <Result
        title="Could not fetch this snapshot"
        subTitle={error.message}
        status={getResultStatus(error.status)}
    />
)

export default SnapshotTabs
