import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

export const GoogleButtonIcon = styled.div`
    float: left;
    padding: 11px;
`

export const GoogleButtonIconInner = styled.div`
    width: 18px;
    height: 18px;
`

export const GoogleButton = styled.a`
    position: relative;
    display: block;
    width: auto;
    width: 200px;
    height: 42px;
    box-sizing: border-box;
    margin: auto;
    overflow: hidden;
    color: #262626;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    background-color: #fff;
    background-image: none;
    border: 1px solid #eeeeee;
    border-radius: 1px;
    border-radius: 30px;
    outline: none;
    box-shadow: 0 8px 12px -4px rgba(0, 0, 0, 0.03);
    transition: 0.2s ease-in;
    color: #757575;
    background-color: #fff;
    &:hover {
        color: #4790e5;
        background-color: #fafafa;
        transition: 0.2s ease-in;
    }
    &:active {
        color: #6d6d6d;
        background-color: #eee;
    }
`

export const GoogleButtonSVG = styled.svg`
    display: block;
`

export const GoogleButtonContents = styled.span`
    margin-right: 6px;
    font-family: Roboto, arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.21px;
    vertical-align: top;
`
