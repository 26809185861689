import type { MCCProfile } from '../../types'
import { initialProfileValues } from '../../utils'

export const totalExpenseCountReducer = (count: number, merchantProfile: MCCProfile) =>
    count + merchantProfile.count

type RatioProfile = {
    ratio: number
    id: string
}

const expenseProfileRatioReducer =
    (totalExpenses: number) => (ratioProfiles: RatioProfile[], currentProfile: MCCProfile) => {
        const ratio = currentProfile.count / totalExpenses
        return [
            ...ratioProfiles,
            {
                id: currentProfile?.merchantCategoryCode ?? '',
                ratio,
            },
        ]
    }

export const calculateMerchantProfileRatios = (expenseType: string) => {
    const initialValues = initialProfileValues.getProfileValues()
    if (!initialValues) return null
    const initialExpenses = initialValues.expenses.find(
        (initialExpense) => initialExpense.expenseType === expenseType
    )
    const initialCount = initialExpenses?.merchantProfiles.reduce(totalExpenseCountReducer, 0)
    return initialExpenses?.merchantProfiles.reduce(
        expenseProfileRatioReducer(initialCount ?? 0),
        []
    )
}

export const getMerchantProfileRatio =
    (merchantProfileRatios: RatioProfile[]) => (merchantCategoryCode?: string) => {
        if (!merchantCategoryCode)
            return {
                ratio: 1,
                id: '',
            }
        return merchantProfileRatios.find(({ id }: RatioProfile) => id === merchantCategoryCode)
    }
