import { bff } from '../backend/bff'

export function useEntitlementsOverride(companyId: string, countryCode: string) {
    const { mutateAsync: addAdvancedOverrides } =
        bff.entitlementsOverride.addAdvancedOverride.useMutation()
    const { mutateAsync: deleteAllOverrides } =
        bff.entitlementsOverride.deleteAllOverride.useMutation()
    const { data: advancedEntitlementsOverride } =
        bff.entitlementsOverride.getIsAdvancedOverrideEnabled.useQuery({ companyId, countryCode })
    return {
        advancedEntitlementsOverride: Boolean(advancedEntitlementsOverride),
        mutations: {
            addAdvancedOverrides,
            deleteAllOverrides,
        },
    }
}
