import { Button, Modal, Space } from 'antd'
import { Field, FieldProps, Formik } from 'formik'
import type { FC } from 'react'
import { useAmlAnswers, useAmlAverageAmountRanges } from 'services/styx/aml-answers'
import { useStyxV2Company } from 'services/styx/company'
import type { AmlAmountRange } from 'types/styx'
import * as Yup from 'yup'
import { Form, Select } from 'formik-antd'

const { Option } = Select

interface AmlAverageAmountRangesProps {
    companyId: string
}

interface AmlAverageAmountFormSchema {
    range: number
}

const validationSchema = Yup.object()
    .shape({
        range: Yup.number().required(),
    })
    .required()

export const AmlAverageAmountRanges: FC<AmlAverageAmountRangesProps> = ({ companyId }) => {
    const { company } = useStyxV2Company()

    const { data: averageAmountRanges } = useAmlAverageAmountRanges(company?.address?.country)
    const { amlAnswers, isLoading, putAverageAmountRange } = useAmlAnswers(companyId)

    const indexedAverageAmountRanges = (averageAmountRanges || [])
        .sort((a, b) => (a.minimum || 0) - (b.minimum || 0))
        .map((item, index) => ({
            index: index,
            item: item,
            title: `Currency: ${item.currency || 'N/A'} - Minimum: ${
                item.minimum === undefined || isNaN(item.minimum)
                    ? 'N/A'
                    : item.minimum.toLocaleString()
            } - Maximum: ${
                item.maximum === undefined || isNaN(item.maximum)
                    ? 'N/A'
                    : item.maximum.toLocaleString()
            }`,
            active: item.active,
        }))

    const filteredRanges = indexedAverageAmountRanges.filter(
        (range) =>
            range.item.maximum == amlAnswers?.averageAmountRange?.maximum &&
            range.item.minimum == amlAnswers?.averageAmountRange?.minimum
    )

    const initialValue = filteredRanges.length === 0 ? null : filteredRanges[0].index

    const handleSelect = async (value: AmlAmountRange) => {
        await putAverageAmountRange(value)
    }

    return (
        <Formik
            enableReinitialize
            validateOnMount
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
                actions.setSubmitting(true)

                const selectedRange = indexedAverageAmountRanges.find(
                    (range) => range.index === values.range
                )

                await handleSelect({
                    minimum: selectedRange?.item.minimum,
                    maximum: selectedRange?.item.maximum,
                    currency: selectedRange?.item.currency,
                })

                actions.setSubmitting(false)
            }}
            initialValues={{
                range: initialValue,
            }}
        >
            {({ isSubmitting, submitForm, isValid }) => (
                <Form layout="horizontal">
                    <Space.Compact style={{ width: '100%' }}>
                        <Form.Item name="range" style={{ width: '100%', marginBottom: 0 }}>
                            <Field name="range">
                                {({
                                    field,
                                    form,
                                }: FieldProps<number, AmlAverageAmountFormSchema>) => (
                                    <Select
                                        name="range"
                                        placeholder="Select average amount range"
                                        disabled={isLoading || initialValue != null}
                                        onChange={(index) =>
                                            form.setFieldValue('range', Number(index))
                                        }
                                        value={
                                            indexedAverageAmountRanges.find(
                                                (range) => range.index === field.value
                                            )?.title
                                        }
                                        data-testid="average-amount-range-select"
                                    >
                                        {indexedAverageAmountRanges
                                            .filter((value) => value.active)
                                            .map((value) => (
                                                <Option
                                                    key={value.index}
                                                    data-testid={`average-amount-range-option-${value.item.minimum}-${value.item.maximum}`}
                                                >
                                                    {value.title}
                                                </Option>
                                            ))}
                                    </Select>
                                )}
                            </Field>
                        </Form.Item>
                        <Button
                            disabled={isLoading || initialValue != null || !isValid}
                            onClick={() => showConfirm(submitForm)}
                            htmlType="submit"
                            type="primary"
                            loading={isSubmitting}
                            data-testid="average-amount-range-save"
                        >
                            Save
                        </Button>
                    </Space.Compact>
                </Form>
            )}
        </Formik>
    )
}

const showConfirm = (fn: () => Promise<void>) => {
    Modal.confirm({
        title: 'Are you sure you want to save?',
        content: 'This information can not be changed later.',
        async onOk() {
            fn()
        },
        onCancel() {},
    })
}
