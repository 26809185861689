import request, { fetcher } from 'services/deimos/request'
import useSWR from 'swr'
import type {
    OverrideProductEligibilityRequest,
    OverrideProductEligibilityRequestEligibilityEnum,
    OverrideProductEligibilityRequestStateEnum,
    PleoReserveEligibility,
    ProductMetadataResponse,
} from '@pleo-io/narvi-ts-models'
import type { OverrideProductAmountRequest } from '@pleo-io/kale-ts-models'

export const usePleoReserveEligibility = (companyId: string | undefined) => {
    const result = useSWR<PleoReserveEligibility>(
        companyId
            ? `rest/v1/credit-portfolio/companies/${companyId}/eligibility/product/pleo-reserve`
            : null,
        fetcher
    )

    return {
        ...result,
        isLoading: !result.data && !result.error,
    }
}

export const usePleoReserveMetadata = () => {
    const result = useSWR<ProductMetadataResponse>(
        `rest/v1/credit-portfolio/product/metadata`,
        fetcher
    )
    return {
        ...result,
        isLoading: !result.data && !result.error,
        data: result.data?.productMetadata.find((meta) => meta.productName === 'RESERVE'),
    }
}

export const overridePleoReserveEligibility = async ({
    companyId,
    eligibility,
    state,
    limit,
    currency,
    updateReason,
    region,
}: {
    companyId: string
    eligibility: OverrideProductEligibilityRequestEligibilityEnum
    state: OverrideProductEligibilityRequestStateEnum
    limit: number
    currency: string
    updateReason: string
    region: string
}) => {
    return request().post(`rest/v1/credit-portfolio/companies/${companyId}/eligibility/override`, {
        json: {
            name: 'RESERVE',
            eligibility,
            state,
            limit,
            currency,
            updateReason,
            region,
        } as OverrideProductEligibilityRequest,
    })
}

export const overridePleoReserveLimit = ({
    companyId,
    amount,
    updateReason,
}: {
    companyId: string
    amount: number
    updateReason: string
}) => {
    return request().post(`rest/v1/pleo-reserve/companies/${companyId}/override-product-amount`, {
        json: {
            amount,
            updateReason,
        } as OverrideProductAmountRequest,
    })
}
