import type { DirectDebitAgreementModel } from 'bff/moons/generated/cupid-v1'
import { fetcher } from 'services/deimos/request'
import useSWR from 'swr'

export const useDirectDebitAgreement = (companyId: string | undefined) => {
    const result = useSWR<DirectDebitAgreementModel>(
        companyId ? `wallet-management/rest/v4/companies/${companyId}/agreements` : null,
        fetcher
    )
    return {
        ...result,
        isLoading: result.data === undefined && !result.error,
    }
}
