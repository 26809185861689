import { fetcher } from 'services/deimos/request'
import useSWR from 'swr'

export interface EmployeeInsuranceResponse {
    id: string
    thirdParty: string
    planCode: string
    employeeId: string
    companyId: string
    country: string
    policyScheme: string
    personTitle: string
    relationship: string
    insurerActivatedAt: string
    insuranceEndDate: string
    createdAt: string
    updatedAt: string
}

export function useEmployeeInsurance(employeeId?: string | null) {
    const result = useSWR<EmployeeInsuranceResponse, Error>(
        employeeId ? `rest/v1/addons/insurances/employees/${employeeId}` : null,
        fetcher,
        { revalidateOnFocus: false }
    )

    return result
}
