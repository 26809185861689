import { Alert, Button, Modal, Space, Typography } from 'antd'
import type { CreateMarketplaceAppResponse } from 'pages/partnerships/bff/types.bff'

import { useState } from 'react'

export const ClientDetailsModal = ({
    isOpen,
    onOk,
    clientData,
}: {
    isOpen: boolean
    onOk: () => void
    clientData: CreateMarketplaceAppResponse['client']
}) => {
    const [hasCopiedSecret, setHasCopiedSecret] = useState(false)

    return (
        <Modal
            title="App created successfully"
            open={isOpen}
            footer={[
                <Button key="ok" type="primary" onClick={onOk} disabled={!hasCopiedSecret}>
                    OK
                </Button>,
            ]}
            centered
            closable={false}
        >
            <Space direction="vertical" size="large" style={{ padding: '16px 0' }}>
                <Space direction="vertical" size="small">
                    <CopyableText label="Client ID" value={clientData?.client_id ?? ''} />
                    <CopyableText
                        label="Client Secret"
                        value={clientData?.client_secret ?? ''}
                        onCopy={() => setHasCopiedSecret(true)}
                    />
                </Space>
                {!hasCopiedSecret && (
                    <Alert
                        type="warning"
                        message="Client secret will only be shown once. Please copy and store it in a secure location
                    before continuing."
                        showIcon
                    />
                )}
            </Space>
        </Modal>
    )
}

const CopyableText = ({
    label,
    value,
    onCopy,
}: {
    label: string
    value: string
    onCopy?: () => void
}) => {
    return (
        <Typography.Text copyable={{ text: value, onCopy }}>
            {label}: <Typography.Text code>{value}</Typography.Text>
        </Typography.Text>
    )
}

export const ClientDetailsModalContainer = ({
    onClose,
    clientData,
}: {
    onClose: () => void
    clientData: CreateMarketplaceAppResponse['client']
}) => {
    return <ClientDetailsModal isOpen={!!clientData} onOk={onClose} clientData={clientData!} />
}
