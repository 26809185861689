import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { Button, Typography, Divider, Modal, message, Space, Descriptions } from 'antd'

import type { DocumentCategory, DocumentFileRequest } from 'types/styx'
import { Person, MissingKycInformation, MaxFileSize } from 'types/styx'
import { CreatePersonDocument } from 'pages/compliance/company/shared/create-document'
import { deSnakify } from 'utils/strings'
import {
    getLatestKycResult,
    getPersonMissingKycDetails,
    getLatestKycCheck,
} from 'pages/compliance/company/people/helpers'
import HandleKycCheck from 'pages/compliance/shared/handle-kyc-check/handle-kyc-check'
import type { FormValues as EditDocumentFormValues } from 'pages/compliance/company/shared/document-form/validation-schema'

import EKycContainer from './ekyc'
import IdvContainer from './idv'
import Verification from './verification'

import EditPersonForm from './edit-person-form/edit-person-form'
import { FormValues, mapValuesToRequest } from './edit-person-form/validation-schema'
import HandleVerification from 'pages/compliance/shared/handle-verification/handle-verification'
import { useManagement } from 'services/styx/management'
import { useMissingInformation } from 'services/deimos/kyc'
import { DocumentsTabs } from 'pages/compliance/company/shared/document-tabs/document-tabs'
import dayjs from 'packages/dayjs'
import { useCountriesRiskScores } from 'services/themis/countries'
import type { CountryWithRating } from 'types/customer-risk-rating'
import { getCountryDetailsFromCodeOrNationality } from 'utils/countries'
import PepContainer from './pep'
import SisContainer from './sis'
import {
    filterCitizenships,
    flattenKycCitizenship,
    isNotDueDiligenceAgentInput,
    mapValuesToCitizenship,
    transformCitizenshipsToKycCitizenships,
} from 'utils/kyc-citizenship'
import { showNotSupportedDocumentCategoryModal } from 'pages/compliance/company/shared/show-not-supported-document-category-modal'
import {
    CountryRiskStatus,
    CountryWithSource,
} from 'pages/compliance/company/people/director-accordion/director-panel/country-risk-status'
import { getLinkToPersonDocumentFile } from 'services/styx/person'

const { Text } = Typography

interface Props {
    person: Person
    onEdit: () => void
    missingKycDetails: MissingKycInformation
    countriesRiskScores?: CountryWithRating[]
    refetchPeople: () => void
}

const mapToCountryWithSource = (countryCode: string, source?: string) => {
    const countryDetails = getCountryDetailsFromCodeOrNationality(countryCode)
    return {
        ...countryDetails,
        source: source,
    } as CountryWithSource
}

export const DirectorPanel: FC<React.PropsWithChildren<Props>> = ({
    person,
    onEdit,
    missingKycDetails,
    children,
    countriesRiskScores,
    refetchPeople,
}) => {
    const nationalites =
        person.kycNationality?.nationality
            ?.split(',')
            .map((value) => mapToCountryWithSource(value)) ?? []

    const kycCitizenships = flattenKycCitizenship(person).map((citizenship) =>
        mapToCountryWithSource(citizenship.citizenship, citizenship.source)
    )

    return (
        <>
            <Row>
                <Space>
                    <HandleKycCheck label="PEP" status={getLatestKycResult(person.pepChecks)}>
                        <PepContainer person={person} refetchPeople={refetchPeople} />
                    </HandleKycCheck>
                    <HandleKycCheck label="SIS" status={getLatestKycResult(person.sisChecks)}>
                        <SisContainer person={person} refetchPeople={refetchPeople} />
                    </HandleKycCheck>
                    <HandleKycCheck label="IDV" status={getLatestKycResult(person.idvChecks)}>
                        <IdvContainer person={person} refetchPeople={refetchPeople} />
                    </HandleKycCheck>
                    <HandleKycCheck
                        expired={getLatestKycCheck(person.ekycChecks)?.expiresAt}
                        label="eKYC"
                        status={getLatestKycResult(person.ekycChecks)}
                    >
                        <EKycContainer person={person} refetchPeople={refetchPeople} />
                    </HandleKycCheck>
                    {person.verification ? (
                        <HandleVerification
                            label="Verification"
                            falsePositive={person.verification.falsePositive}
                            result={person.verification.result}
                        >
                            <Verification verification={person.verification} />
                        </HandleVerification>
                    ) : null}
                </Space>
                <Space>
                    <Button onClick={onEdit}>Edit person</Button>
                </Space>
            </Row>
            <Divider />
            <PersonDetails>
                {!!missingKycDetails.length && (
                    <Descriptions bordered size="small" layout="vertical">
                        <Descriptions.Item label="Missing KYC information">
                            <Text type="danger">
                                {missingKycDetails
                                    .map((detail) => deSnakify(detail.name))
                                    .join(', ')}
                            </Text>
                        </Descriptions.Item>
                    </Descriptions>
                )}
                {kycCitizenships.length > 0 && (
                    <Descriptions bordered size="small" layout="vertical">
                        <Descriptions.Item label="Citizenships">
                            <CountryRiskStatus
                                countriesWithSource={kycCitizenships}
                                countriesRiskScores={countriesRiskScores}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                )}
                <Descriptions bordered column={4} size="small" layout="vertical">
                    <Descriptions.Item label="National ID no.">
                        {person.nationalId || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Date of birth" data-testId="dob">
                        {person?.dateOfBirth?.month
                            ? dayjs()
                                  .month(person.dateOfBirth.month - 1)
                                  .format('MMM')
                            : '-'}
                        {person?.dateOfBirth?.day ? ` ${person.dateOfBirth.day}, ` : ' -, '}
                        {person?.dateOfBirth?.year ? person.dateOfBirth.year : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Nationality">
                        <CountryRiskStatus
                            countriesWithSource={nationalites}
                            countriesRiskScores={countriesRiskScores}
                        />
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions bordered column={7} size="small" layout="vertical">
                    <Descriptions.Item label="Street">
                        {person.address?.street || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Number">
                        {person.address?.houseNumber || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Floor">
                        {person.address?.floor || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Locality">
                        {person.address?.locality || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Region">
                        {person.address?.region || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Postal code">
                        {person.address?.postalCode || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Country">
                        {person.address?.country || '-'}
                    </Descriptions.Item>
                </Descriptions>
            </PersonDetails>
            <Divider />
            {children}
        </>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${spacing.space8};
`

const PersonDetails = styled.div`
    display: flex;
    gap: ${spacing.space24};
    flex-wrap: wrap;
`

const DirectorPanelContainer: FC<
    React.PropsWithChildren<{ person: Person; refetchPeople: () => void }>
> = ({ person, refetchPeople }) => {
    const [modalActive, setModalActive] = useState(false)
    const { data: missingKycInformation, mutate: revalidateMissingInfo } = useMissingInformation()
    const { mutations } = useManagement()
    const { data: countriesRiskScores } = useCountriesRiskScores()

    const subjectId = person.id

    const handleSubmit = async (values: FormValues) => {
        const citizenships = mapValuesToCitizenship(
            values.citizenships,
            filterCitizenships(person, isNotDueDiligenceAgentInput)
        )

        try {
            await mutations.updatePerson(person.id, {
                ...mapValuesToRequest(values),
                kycCitizenships: transformCitizenshipsToKycCitizenships(citizenships),
            })
            await revalidateMissingInfo()
            refetchPeople()
            setModalActive(false)
            message.success('Saved successfully')
        } catch (e) {
            message.error(`Failed to update person: ${(e as Error).message}`)
        }
    }

    const handleCreateDocumentFile = (documentId: string) => async (file: FormData) => {
        await mutations.createDocumentFile(subjectId, documentId, file)
        refetchPeople()
    }

    const handleDeleteDocumentFile = (documentId: string) => async (fileId: string) => {
        await mutations.deleteDocumentFile(subjectId, documentId, fileId)
        refetchPeople()
    }

    const handleEditDocumentFile =
        (documentId: string) =>
        async (fileId: string, documentFileRequest: DocumentFileRequest) => {
            await mutations.editDocumentFile(subjectId, documentId, fileId, documentFileRequest)
            refetchPeople()
        }

    const handleGetLinkToFile =
        (documentId: string) => async (fileId: string, download?: boolean) => {
            const { url }: { url: string } = await getLinkToPersonDocumentFile({
                subjectId,
                documentId,
                fileId,
                download,
            }).json()

            return url
        }

    const onDocumentDelete = async (documentId: string) => {
        await mutations.deleteDocument(subjectId, documentId)
        refetchPeople()
    }

    const handleEditDocument =
        (documentId: string) =>
        async ({ title, category, description, hidden }: EditDocumentFormValues) => {
            const body = {
                title,
                category: category as DocumentCategory,
                description,
                hidden,
            }

            try {
                await mutations.updateDocument(subjectId, documentId, body)
                refetchPeople()
            } catch (e) {
                if (
                    e instanceof Error &&
                    e.message.includes('not supported for personal documents')
                ) {
                    await showNotSupportedDocumentCategoryModal('update', e.message)
                }
            }
        }

    return (
        <>
            <DirectorPanel
                countriesRiskScores={countriesRiskScores}
                person={person}
                onEdit={() => setModalActive(true)}
                missingKycDetails={getPersonMissingKycDetails(missingKycInformation, person.id)}
                refetchPeople={refetchPeople}
            >
                <DocumentsTabs
                    handleDeleteDocumentFile={handleDeleteDocumentFile}
                    handleCreateDocumentFile={handleCreateDocumentFile}
                    handleEditDocumentFile={handleEditDocumentFile}
                    handleGetLinkToFile={handleGetLinkToFile}
                    onDocumentDelete={onDocumentDelete}
                    handleEditDocument={handleEditDocument}
                    documents={person.documents}
                    maxFileSize={MaxFileSize.PersonFile}
                    createDocumentButton={
                        <CreatePersonDocument subjectId={person.id} refetchPeople={refetchPeople} />
                    }
                />
            </DirectorPanel>
            <Modal
                title="Edit person"
                open={modalActive}
                onCancel={() => setModalActive(false)}
                footer={null}
                width={696}
                centered
            >
                <EditPersonForm person={person} onSubmit={handleSubmit} />
            </Modal>
        </>
    )
}

export default DirectorPanelContainer
