import { useUser } from 'providers/user-context'
import { useRiskAssessment } from 'services/deimos/styx-company/styx-company'
import {
    RiskCheck,
    RiskCheckData,
    RiskCheckDataV2,
    RiskCheckV2,
    RiskCheckScoreV2,
    RiskTypes,
} from 'types/styx'

export function useRiskAssessmentOwner() {
    const user = useUser()
    return {
        ownerId: user.id ?? '',
        ownerName: user.email ?? '',
    }
}

export function useIsNewComplianceOfficer() {
    const { riskAssessment } = useRiskAssessment()
    const currentOfficer = useRiskAssessmentOwner()
    return riskAssessment?.ownerId !== currentOfficer.ownerId
}

export function hasCompletedAllRiskChecks(riskChecks?: RiskCheck[] | RiskCheckData[]) {
    return riskChecks?.length === Object.keys(RiskTypes).length
}

export function hasCompletedAllRiskChecksV2(riskChecks?: RiskCheckDataV2[] | RiskCheckV2[]) {
    if (!riskChecks) return false

    let isCompleted = true

    riskChecks?.forEach((riskCheck) => {
        if (riskCheck?.score === RiskCheckScoreV2.NOT_ASSIGNED || riskCheck?.score === undefined)
            isCompleted = false
    })

    return isCompleted
}

export function sortRiskCheckForm<T extends RiskCheckDataV2 | RiskCheck>(checks: T[]): T[] {
    const scoreOrder = {
        FAIL: 0,
        PASS: 1,
        NOT_ASSIGNED: 2,
        RESTRICTED: 3,
        PENDING: 3,
        HIGH: 4,
        MEDIUM_HIGH: 5,
        MEDIUM: 6,
        MEDIUM_LOW: 7,
        LOW: 8,
    }

    const sortedData = [...checks].sort((a, b) => {
        if (a.score === b.score) {
            return a.riskType.localeCompare(b.riskType)
        }
        if (!a.score || !b.score) return 0
        return scoreOrder[a.score] - scoreOrder[b.score]
    })
    return sortedData
}
