import type { FC } from 'react'
import { ContentContainer } from 'components/layout-containers'
import ReimbursementsTable from './reimbursementsTable/reimbursementsTable'

const Reimbursements: FC<React.PropsWithChildren<{ companyId: string }>> = ({ companyId }) => (
    <ContentContainer>
        <ReimbursementsTable companyId={companyId} />
    </ContentContainer>
)

export default Reimbursements
