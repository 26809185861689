import dayjs, { Dayjs } from 'packages/dayjs'
import type { Invoice } from 'types/wallet-invoice'
import { Status, Velocity, VelocityPriority } from 'types/velocity'
import { EmployeeRoleType, OberonEmployee, OberonEmployeeRole } from 'types/employee'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

export const orderByMostRecentDate = (i1: Invoice, i2: Invoice) => {
    const date1 = dayjs(i1.created)
    const date2 = dayjs(i2.created)
    return date1 === date2 ? 0 : date1 < date2 ? 1 : -1
}
export const disableFutureDate = (current: Dayjs) => current && current.valueOf() > Date.now()
export const isDateBefore = (currentDate: Dayjs) => dayjs(currentDate).isBefore(dayjs(), 'day')
export const isPastOrToday = (currentDate: Dayjs) =>
    currentDate.valueOf() < dayjs().add(1, 'days').startOf('day').valueOf()
export const getHighestVelocity = (status: Status): Velocity | undefined => {
    let highestVelocity: Velocity | undefined = undefined
    Object.entries(status).forEach(([velocity, value]) => {
        if (
            value &&
            (!highestVelocity ||
                VelocityPriority[velocity as Velocity] > VelocityPriority[highestVelocity])
        ) {
            highestVelocity = velocity as Velocity
        }
    })
    return highestVelocity
}

export const getEmployeeTypes = (employee: OberonEmployee) => {
    if (!employee || !employee.role) {
        return {}
    }
    const isAdmin = employee.role === OberonEmployeeRole.OWNER
    const isEmployee = employee.role === OberonEmployeeRole.MEMBER
    const isBookkeeper = employee.role === OberonEmployeeRole.BOOKKEEPER_BASIC || OberonEmployeeRole.BOOKKEEPER_EXTENDED

    return {
        isAdmin,
        isEmployee,
        isBookkeeper,
    }
}

export const getEmployeeRoleDisplay = (employee: OberonEmployee): EmployeeRoleType => {
    switch (`${employee.role}-${employee.type}`) {
        case 'OWNER-COMPANY':
            return 'Admin'
        case 'MEMBER-COMPANY':
            return 'Employee'
        case 'BOOKKEEPER_EXTENDED-BOOKKEEPER':
            return 'Bookkeeper (extended)'
        case 'BOOKKEEPER_BASIC-BOOKKEEPER':
        case 'BOOKKEEPER-BOOKKEEPER':
            return 'Bookkeeper (basic)'
        case 'null-COMPANY':
        case 'undefined-COMPANY':
            // Incomplete company signup
            return 'Orphan Employee'
        case 'OWNER-PARTNER':
            return 'Partner Admin'
        case 'MEMBER-PARTNER':
            return 'Partner Member'
        default:
            return 'Unknown'
    }
}

// Includes an example of warning status alert
export const StatusPages: any = {
    /* "company-360": {
        type: 'warning',
        description: 'The company 360 endpoint is currently experiencing a slowdown, the page may take longer to load than usual.'
    } */
}

export const useStatusAlert = () => {
    const [isShowingAlert, setIsShowingAlert] = useState(false)
    const location = useLocation()

    const subPath =
        location.pathname.split('/').length > 2 ? location.pathname.split('/')[2] : undefined

    useEffect(() => {
        if (subPath && StatusPages[subPath]) {
            setIsShowingAlert(true)
        } else {
            setIsShowingAlert(false)
        }
    }, [setIsShowingAlert, location, subPath])

    return { isShowingAlert, statusDetails: subPath ? StatusPages[subPath] : undefined }
}
