import { Space, Typography } from 'antd'

export default function OverdraftSection({
    children,
    title,
}: {
    children: React.ReactNode
    title: string
}) {
    return (
        <Space direction="vertical" style={{ margin: '8px 0' }}>
            <Typography.Title level={5} style={{ margin: '8px 0 4px 0' }}>
                {title}
            </Typography.Title>
            {children}
        </Space>
    )
}
