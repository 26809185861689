import type { FC } from 'react'
import { Result } from 'antd'

import Spinner from 'components/content-spinner'
import EmbeddedMetabaseDashboard from 'components/embedded-metabase-dashboard'
import { useSettlementReconciliationDashboard } from 'services/deimos/settlements'

interface Props {
    settlementDate: string
}

const SettlementReconciliationDashboard: FC<React.PropsWithChildren<Props>> = ({
    settlementDate,
}) => {
    const { data, error } = useSettlementReconciliationDashboard(settlementDate)

    if (error) {
        return <Result status="500" title="Something went wrong" subTitle={error.message} />
    }

    if (!data) {
        return <Spinner />
    }

    return <EmbeddedMetabaseDashboard url={data.url} title="Settlement Reconciliation" />
}

export default SettlementReconciliationDashboard
