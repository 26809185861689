import type { Address } from 'types/deimos-company'

export interface Amount {
    value: number
    currency: string
}

export interface Merchant {
    id: string
    name: string
}

export interface Mastercard {
    de22?: {
        panEntryMethod?: string
    }
    de61?: {
        transaction?: {
            type: string
        }
    }
}

export enum Status {
    DECLINED = 'DECLINED',
    REFUNDED = 'REFUNDED',
    APPROVED = 'APPROVED',
    SETTLED = 'SETTLED',
    CLEARED = 'CLEARED',
}

export interface Transaction {
    id: string
    amount: Amount
    beta?: {}
    bill: Amount
    cardId: string
    companyId: string
    createdAt: string
    employeeId: string
    fees: Amount[]
    link: string
    address: Address
    mastercard?: Mastercard
    merchant: Merchant
    other?: {} | null
    performed: string
    pos?: {} | null
    provider: 'GPS' | 'ENFUCE'
    purchaseType?: 'ONLINE' | 'ATM' | 'IN_STORE' | string | null
    reason?: {} | null
    received: string
    status: Status
    target?: {
        id: string
        cardId?: string
        type?: 'CARD' | string
    }
    type:
        | 'LOAD'
        | 'PRESENTMENT'
        | 'AUTHORIZATION'
        | 'CHARGE_BACK'
        | 'AUTHORIZATION_REVERSAL'
        | string
    updatedAt: string
    walletId: string
}
