import { Button, Card } from 'antd'
import { useOddDetailContext } from './context'
import { bff } from './bff-hooks'

const Notes: React.FC = () => {
    const { caseId, notesModal } = useOddDetailContext()
    const { data: notes } = bff.notes.getNotes.useQuery({ caseId })
    return (
        <Card
            title="Notes"
            style={{ position: 'sticky', top: 16 }}
            styles={{ body: { padding: '8px 24px' } }}
            actions={[
                <Button onClick={notesModal.open} key="add-edit-notes">
                    {!notes ? 'Add' : 'Edit'} Notes
                </Button>,
            ]}
        >
            <div>{notes}</div>
        </Card>
    )
}

export default Notes
