import { PageOrder } from '@pleo-io/deimos'
import type { SortablePermissionChangeRequestProperty, Sorting } from './types'

export const defaultPageSize = 10

export const sortableProperties = ['id', 'created_at'] as const
export const orderingProperties = [
    'ASC',
    'ASC_NULLS_FIRST',
    'ASC_NULLS_LAST',
    'DESC',
    'DESC_NULLS_FIRST',
    'DESC_NULLS_LAST',
] as const

export const defaultSortedProperty: SortablePermissionChangeRequestProperty = 'created_at'
export const defaultPageOrder: PageOrder = PageOrder.DESC

export const defaultSorting = [{ [defaultSortedProperty]: defaultPageOrder }] as Sorting
