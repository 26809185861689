import { useRef } from 'react'
import { Button, Descriptions, Form, Space } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import { Checkbox, Input, FormItem } from 'formik-antd'
import { insertIntoArray } from '../../utils'
import type { Employee, FormValuesProfile } from '../../types'
import Item from 'antd/es/list/Item'
import { useFormikContext } from 'formik'
import useUpdateProfileFormValues from '../../hooks/use-update-profile-form-values'
import { MAX_EMPLOYEES } from '../../limits'

type EditableEmployeeProps = Employee & {
    toggleEditing: (value: boolean) => void
    index: number
}

const EditableEmployee = ({
    name,
    count,
    limits,
    mileage,
    toggleEditing,
    virtualCard,
    index,
}: EditableEmployeeProps) => {
    const { values } = useFormikContext<FormValuesProfile>()
    const { updateProperty } = useUpdateProfileFormValues('employees')
    const initialValues = useRef<Employee>({ name, count, limits, mileage, virtualCard })

    const reset = () => {
        const updatedEmployees = insertIntoArray<Employee>(
            values.employees,
            initialValues.current,
            index
        )
        updateProperty(updatedEmployees)
    }

    const cancel = () => {
        reset()
        toggleEditing(false)
    }

    const save = () => {
        toggleEditing(false)
    }

    return (
        <Form>
            <Item
                actions={[
                    <Space key="action-buttons">
                        <Button type="primary" size="small" onClick={save}>
                            Done
                        </Button>
                        <Button size="small" onClick={cancel}>
                            Cancel
                        </Button>
                    </Space>,
                ]}
            >
                <Descriptions column={2} title={name}>
                    <DescriptionsItem label="Count">
                        <FormItem name={`employees[${index}].count`}>
                            <Input
                                size="small"
                                name={`employees[${index}].count`}
                                type="number"
                                value={count}
                                min={0}
                                max={MAX_EMPLOYEES}
                            />
                        </FormItem>
                    </DescriptionsItem>
                    <DescriptionsItem label="Card Limit">
                        <FormItem name={`employees[${index}].limits.card.value`}>
                            <Input
                                size="small"
                                name={`employees[${index}].limits.card.value`}
                                type="number"
                                value={limits.card.value ?? 0}
                            />
                        </FormItem>
                    </DescriptionsItem>
                    <DescriptionsItem label="Virtual Cards">
                        <Checkbox name={`employees[${index}].virtualCard`} />
                    </DescriptionsItem>
                </Descriptions>
            </Item>
        </Form>
    )
}

export default EditableEmployee
