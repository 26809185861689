import * as Yup from 'yup'

export const validationSchema = Yup.object()
    .shape({
        title: Yup.string().required(),
        description: Yup.string().required(),
    })
    .required()

export type FormValues = Yup.InferType<typeof validationSchema>
