import type { FC } from 'react'

import { ProofOfFundsRejectionReason, Transaction } from 'types/proof-of-funds'
import { refundModalValidationSchema, FormValues, mapValuesToRequest } from './validation-schema'
import { Button, Col, Empty, Row, Space } from 'antd'
import { Form, Formik } from 'formik'
import { FormItem, Input, Select } from 'formik-antd'
import type { RejectRefund } from 'services/deimos/proof-of-funds'
import styled from 'styled-components'
import { deSnakify } from 'utils/strings'
import TransactionDescription from '../transaction-description/transaction-description'

const { Option } = Select

const RejectRefunds: FC<{
    onSubmit: (pofId: string, refundDetails: RejectRefund) => void
    transaction?: Transaction
}> = ({ onSubmit, transaction }) => {
    if (!transaction) return <Empty description="There is no transaction details to display" />

    const initialValues: FormValues = {
        bankCode: transaction.bankAccountInfo?.bankCode || undefined,
        accountNumber: transaction.bankAccountInfo?.accountNumber || undefined,
        iban: transaction.bankAccountInfo?.iban || undefined,
        bic: transaction.bankAccountInfo?.bic || undefined,
        rejectionReason: null,
        note: '',
        country: '',
    }

    return (
        <Formik
            onSubmit={(values: FormValues) => onSubmit(transaction.id, mapValuesToRequest(values))}
            validationSchema={refundModalValidationSchema}
            validateOnMount
            initialValues={initialValues}
        >
            {({ isValid }) => (
                <Form>
                    <Space direction="vertical" size="large">
                        <TransactionDescription transaction={transaction} />
                        <Row gutter={24}>
                            <Col span={6}>
                                <FormItem name="accountNumber" label="Account number">
                                    <Input name="accountNumber" />
                                </FormItem>
                            </Col>
                            <Col span={5}>
                                <FormItem name="iban" label="IBAN">
                                    <Input name="iban" />
                                </FormItem>
                            </Col>
                            <Col span={5}>
                                <FormItem name="bankCode" label="Sort code">
                                    <Input name="bankCode" />
                                </FormItem>
                            </Col>
                            <Col span={5}>
                                <FormItem name="bic" label="BIC">
                                    <Input name="bic" />
                                </FormItem>
                            </Col>
                            <Col span={3}>
                                <FormItem name="country" label="Country">
                                    <Input name="country" data-testid="country-code" />
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem name="rejectionReason" label="Rejection reason">
                                    <Select
                                        name="rejectionReason"
                                        placeholder="Select a rejection reason"
                                        style={{ width: '100%' }}
                                    >
                                        {Object.keys(ProofOfFundsRejectionReason).map((item) => (
                                            <Option value={item} key={item}>
                                                {deSnakify(item)}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem name="note" label="Note">
                                    <Input.TextArea name="note" />
                                </FormItem>
                            </Col>
                        </Row>
                        <ButtonContainer>
                            <Button
                                htmlType="submit"
                                type="primary"
                                data-testid="submit"
                                disabled={!isValid}
                            >
                                Refund
                            </Button>
                        </ButtonContainer>
                    </Space>
                </Form>
            )}
        </Formik>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`
export default RejectRefunds
