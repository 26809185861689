import { Button, List, Modal, Space } from 'antd'
import type {
    CommentDto,
    DocumentFullDto,
    StateChangeFullDto,
    UnderwritingApplicationFullDto,
} from 'bff/moons/generated/kale'
import styled from 'styled-components'
import OverdraftApplicationStateTag from './overdraft-application-state-tag'
import { isSameDay } from '../../helpers'
import DocumentsList from './documents-list'
import { EyeOutlined } from '@ant-design/icons'
import { useState } from 'react'
import dayjs from 'dayjs'

interface Props {
    application: UnderwritingApplicationFullDto
    companyId: string
}

export default function OverdraftApplicationStateTrail({ application, companyId }: Props) {
    const [infoComment, setInfoComment] = useState<CommentDto | null>(null)

    const submittedDocumentsByDate = application.stateTrail.reduce<
        Record<string, DocumentFullDto[]>
    >((acc, stateChange) => {
        if (stateChange.state === 'PENDING') {
            acc[stateChange.createdAt] = []
        }
        return acc
    }, {})

    application.documents.forEach((document) => {
        const createdAt = document.createdAt
        const submissionDates = Object.keys(submittedDocumentsByDate)
        submissionDates.forEach((submissionDate) => {
            if (isSameDay(createdAt, submissionDate)) {
                submittedDocumentsByDate[submissionDate].push(document)
            }
        })
    })

    const handleCommentClick = (stateId: string) => {
        const infoRequestStates = application.stateTrail.filter(
            ({ state }) => state === 'INFO_REQUIRED'
        )
        const currentIndex = infoRequestStates.findIndex(({ id }) => id === stateId)

        const currentComment = application.comments[currentIndex]

        setInfoComment(currentComment)
    }

    return (
        <div data-testid="state-trail">
            <List
                itemLayout="horizontal"
                dataSource={application.stateTrail}
                renderItem={(stateChange) => (
                    <List.Item>
                        <StateItem
                            stateChange={stateChange}
                            stateDocuments={submittedDocumentsByDate[stateChange.createdAt]}
                            companyId={companyId}
                            onCommentClick={handleCommentClick}
                        />
                    </List.Item>
                )}
            />
            <InformationRequestDetailsModal
                comment={infoComment}
                onCancel={() => setInfoComment(null)}
            />
        </div>
    )
}

interface StateItemProps {
    stateChange: StateChangeFullDto
    stateDocuments: DocumentFullDto[] | undefined
    companyId: string
    onCommentClick: (id: string) => void
}

function StateItem({ stateChange, stateDocuments, companyId, onCommentClick }: StateItemProps) {
    const bankStatements = stateDocuments?.filter(({ type }) => type === 'BANK_STATEMENT')
    const financialStatements = stateDocuments?.filter(({ type }) => type === 'FINANCIAL_STATEMENT')

    return (
        <StyledSpace direction="vertical" size="small" data-testid="application-state-item">
            <StyledTitleContainer>
                <OverdraftApplicationStateTag state={stateChange.state} />
                <span>{dayjs(stateChange.createdAt).format('YYYY-MM-DD')}</span>
                {stateChange.state === 'INFO_REQUIRED' && (
                    <Button
                        size="small"
                        type="link"
                        icon={<EyeOutlined />}
                        onClick={() => onCommentClick(stateChange.id)}
                    >
                        See comment
                    </Button>
                )}
            </StyledTitleContainer>
            {bankStatements && bankStatements.length > 0 && (
                <DocumentsList
                    companyId={companyId}
                    title="Bank statements"
                    documents={bankStatements}
                />
            )}
            {financialStatements && financialStatements.length > 0 && (
                <DocumentsList
                    companyId={companyId}
                    title="Financial statements"
                    documents={financialStatements}
                />
            )}
        </StyledSpace>
    )
}

interface InformationRequestDetailsModalProps {
    comment: CommentDto | null
    onCancel: () => void
}

function InformationRequestDetailsModal({
    comment,
    onCancel,
}: InformationRequestDetailsModalProps) {
    return (
        <Modal open={Boolean(comment)} onCancel={onCancel} footer={null} centered title="Comment">
            <StyledContent>{comment?.content}</StyledContent>
        </Modal>
    )
}

const StyledSpace = styled(Space)`
    width: 50%;
    min-width: 350px;
`

const StyledTitleContainer = styled.div`
    font-size: 1.25rem;
    display: flex;
    align-items: center;
`

const StyledContent = styled.p`
    white-space: pre-wrap;
`
