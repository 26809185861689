import { Card } from 'antd'
import { useLocation, useNavigate, Routes, Route } from 'react-router-dom'
import ProtectedRoute from 'router/protected-route'

import { PageContentLayout } from 'components/layout-containers'

import EntitlementDashboard from './entitlement-dashboard'

const Entitlements = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const defaultActiveKey = location.pathname.split('/').pop() ?? 'entitlements_view'

    const tabList = [{ key: 'entitlements_dashboard', tab: 'Entitlements Dashboard' }]

    return (
        <PageContentLayout>
            <Card
                tabList={tabList}
                defaultActiveTabKey={defaultActiveKey}
                onTabChange={(tabKey: string) => {
                    navigate(tabKey, { replace: true })
                }}
            >
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route path="/" element={<EntitlementDashboard />} />
                    </Route>
                </Routes>
            </Card>
        </PageContentLayout>
    )
}

export default Entitlements
