import { useState } from 'react'
import { Steps } from 'antd'
import { generatePayload, steps } from './helpers'
import { Formik } from 'formik'
import type { FormValuesProfile } from './types'
import { FormOverrides } from './generate-profile-demo-form.styles'
import { initialValues } from './initialValues'
import { createDemoProfile } from 'services/dionysus/demo-accounts'
import { validation } from './validation'
import StepContainer from './step-container'
import AccountDetails from '../../account-details/account-details'
import ErrorResult from '../../error-result/error-result'
import Loading from '../../loading-result/loading-result'
import type { CompanyProfileResponse } from 'types/demo-accounts'

const GenerateProfileDemoForm = () => {
    const [currentStep, setCurrentStep] = useState(0)
    const [accountDetails, setAccountDetails] = useState<CompanyProfileResponse | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const goToNextStep = () => setCurrentStep(Math.min(currentStep + 1, steps.length))

    const goToPreviousStep = () => setCurrentStep(Math.max(currentStep - 1, 0))

    const handleSubmit = async (values: FormValuesProfile) => {
        const payload = generatePayload(values)
        setLoading(true)
        try {
            const response = await createDemoProfile(payload).catch((err) => setError(err.message))
            if (!response) throw new Error('Failed to create demo profile')
            setAccountDetails(response)
        } catch (e: any) {
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }

    const isCurrentStep = (index: number) => index === currentStep

    const stepItems = steps.map(({ title, description, Component, entity }, index) => ({
        title,
        description: isCurrentStep(index) ? (
            <StepContainer
                Component={Component}
                title={title}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                index={index}
                entity={entity}
            />
        ) : (
            description
        ),
    }))

    if (accountDetails) {
        return (
            <AccountDetails
                email={accountDetails.firstAdminEmail}
                companyId={accountDetails.companyId}
                passcode={'1234'}
            />
        )
    }

    const getContent = () => {
        if (error) {
            return <ErrorResult goBack={() => setError(null)} errorMessage={error} />
        }

        if (loading) {
            return <Loading />
        }

        return <Steps direction="vertical" current={currentStep} items={stepItems} />
    }

    return (
        <FormOverrides>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
                validateOnMount
            >
                {getContent()}
            </Formik>
        </FormOverrides>
    )
}

export default GenerateProfileDemoForm
