import { Button, Col, Input, message as antdMessage, Modal, Row, Space, Typography } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import CompanyAddress from 'components/company-address/company-address'
import Spinner from 'components/content-spinner'
import { Formik } from 'formik'
import { Form, FormItem, Input as FormikInput } from 'formik-antd'
import * as Yup from 'yup'
import { bff } from '../bff'
import dayjs from 'packages/dayjs'

import DatePicker from 'packages/form/date-picker'

const { Search } = Input
const { Text } = Typography

const validationSchema = Yup.object().shape({
    startAttributionDate: Yup.date().required('Please provide the start date of this attribution'),
    endAttributionDate: Yup.date().nullable(),
    message: Yup.string().required('Please provide any additional notes about this attribution'),
})

export const AddAttribution = () => {
    const { id: partnerId } = useParams()
    const [showAddAttribution, setShowAddAttribution] = useState(false)
    const [companyIdSearch, setCompanyIdSearch] = useState('')

    const {
        data: companyDetails,
        isLoading,
        isError,
    } = bff.addAttribution.getCompanyInfo.useQuery(
        { companyId: companyIdSearch },
        { enabled: !!companyIdSearch }
    )

    const { mutateAsync: addNewAttribution } = bff.addAttribution.addNewAttribution.useMutation({
        onSuccess: () => {
            antdMessage.success(`Partner attribution has been added successfully.`)
            setShowAddAttribution(false)
        },
        onError: (error) => {
            antdMessage.error(`Could not add the attribution: ${error.message}`)
        },
    })

    return (
        <>
            <Button onClick={() => setShowAddAttribution(true)}>Add attribution</Button>
            <Modal
                title="Add attribution"
                open={showAddAttribution}
                onCancel={() => setShowAddAttribution(false)}
                afterClose={() => setCompanyIdSearch('')}
                footer={null}
            >
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Search
                        placeholder="Company ID"
                        allowClear
                        onSearch={(companyId) => setCompanyIdSearch(companyId)}
                        style={{ width: 400 }}
                        enterButton
                        aria-label="search-company"
                    />
                    {isLoading && !!companyIdSearch && !isError && <Spinner />}
                    {isError && <Text type="danger">Cannot find company</Text>}
                    {companyDetails && (
                        <>
                            <Col style={{ marginTop: '24px', marginBottom: '24px' }}>
                                <Text strong>{companyDetails.name}</Text>
                                {companyDetails.address && (
                                    <CompanyAddress
                                        address={{
                                            addressLine1: companyDetails.address.addressLine1 || '',
                                            addressLine2: companyDetails.address.addressLine2,
                                            locality: companyDetails.address.locality || '',
                                            postalCode: companyDetails.address.postalCode || '',
                                            country: companyDetails.address.country || '',
                                        }}
                                    />
                                )}
                            </Col>
                            <Formik
                                initialValues={{
                                    startAttributionDate: dayjs().format('YYYY-MM-DD'),
                                    endAttributionDate: null,
                                    message: '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={async ({
                                    startAttributionDate,
                                    endAttributionDate,
                                    message,
                                }) =>
                                    await addNewAttribution({
                                        partnerId,
                                        companyId: companyDetails.id,
                                        startAttributionDate,
                                        endAttributionDate,
                                        message,
                                    })
                                }
                                validateOnMount
                            >
                                {({ isValid, isSubmitting }) => (
                                    <Form layout="vertical">
                                        <Row>
                                            <Col span={8}>
                                                <FormItem
                                                    name="startAttributionDate"
                                                    label="Start Date"
                                                >
                                                    <DatePicker
                                                        name="startAttributionDate"
                                                        aria-label="Start Date"
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col span={8}>
                                                <FormItem
                                                    name="endAttributionDate"
                                                    label="End Date"
                                                >
                                                    <DatePicker
                                                        name="endAttributionDate"
                                                        aria-label="End Date"
                                                    />
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <FormItem name="message" label="Notes">
                                            <FormikInput.TextArea
                                                rows={3}
                                                name="message"
                                                placeholder="Please provide any additional notes about this attribution"
                                            />
                                        </FormItem>
                                        <Row justify="end">
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                loading={isSubmitting}
                                                disabled={!isValid}
                                            >
                                                Confirm attribution
                                            </Button>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )}
                </Space>
            </Modal>
        </>
    )
}
