import { Card, Descriptions, Skeleton, Tag } from 'antd'
import { ContentContainer } from 'components/layout-containers'
import { bff } from 'pages/compliance/bff'
import type { FC } from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

interface EntitlementsProps {
    companyId: string
}

export const EntitlementsTags: FC<{ entitlements?: string[]; color: string }> = ({
    entitlements,
    color,
}) => {
    return entitlements?.length ? (
        <TagGridContainer>
            {entitlements?.map((ent, index) => (
                <Tag key={ent + index} color={color}>
                    {ent}
                </Tag>
            ))}
        </TagGridContainer>
    ) : (
        <div>N/A</div>
    )
}

export const Entitlements: FC<React.PropsWithChildren<EntitlementsProps>> = ({ companyId }) => {
    const { data, isLoading } = bff.entitlements.getCompanyEntitlements.useQuery({
        companyId,
    })
    if (isLoading) return <Skeleton active />

    const entitlements = data?.includedFeatures?.map((feature: any) => feature.name) ?? []

    return (
        <ContentContainer>
            <Card title="Entitlements">
                <Descriptions column={1}>
                    <Descriptions.Item label="Subscription plan">
                        {data?.planName ?? 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Subscription plan type">
                        {data?.planType ?? 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Subscription plan country">
                        {data?.planCountry ?? 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Included Features">
                        <EntitlementsTags entitlements={entitlements} color={'blue'} />
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        </ContentContainer>
    )
}

const TagGridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    row-gap: ${spacing.space8};
`
