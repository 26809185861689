import React, { FC, useEffect, useState } from 'react'
import { Button, Input, message, Space, Table } from 'antd'
import dayjs from 'packages/dayjs'
import { ActionsContainer, PointerContainer } from 'components/layout-containers'
import { withTeamErrorHandler } from 'providers/error-handler'
import { Team } from 'utils/teams'
import { deleteBankfeedError, replayBankfeed } from 'services/deimos/bankfeed'
import type { ColumnProps } from 'antd/lib/table'
import { bff } from '../../bff'
import type { BankFeedErrorResponse } from 'bff/moons/generated/io-v1'

interface Props {
    pageLoaded?: boolean
    companyId: string
    replayExpenseId: string | null
    setTitle: (title: string) => void
    setError: (error: string) => void
    setSuccess: (success: string) => void
    children?: React.ReactNode
}

const BankFeedErrors: FC<React.PropsWithChildren<Props>> = ({
    companyId,
    setTitle,
    setError,
    setSuccess,
    replayExpenseId = null,
}) => {
    const [bankFeedReplayExpenseId, setReplayExpenseId] = useState<string | null>(replayExpenseId)
    const {
        error: bankfeedErrorError,
        data: bankfeedErrorData,
        isLoading,
    } = bff.bankfeed.fetchErrors.useQuery({ companyId })
    const { mutateAsync: mutateBankfeedRetry } = bff.bankfeed.retry.useMutation()

    useEffect(() => {
        setTitle('Bank Feed Errors')
    })

    useEffect(() => {
        if (bankfeedErrorError) {
            setError(bankfeedErrorError.message)
        }

        if (bankfeedErrorData) {
            setSuccess('Bank feed errors successfully fetched')
        }
    }, [bankfeedErrorError, bankfeedErrorData, setError, setSuccess])

    const handleInitiateRetry = async (expenseId?: string) => {
        if (!expenseId) {
            setError('Missing expense ID')
            return
        }
        try {
            await mutateBankfeedRetry({ companyId, expenseId })
            setSuccess(
                `Bank feed retry initiated successfully for expense ID ${expenseId}. This bank feed will be re-run in 30 minutes time.`
            )
        } catch (e) {
            message.error((e as Error).message)
            setError((e as Error).message)
        }
    }

    const handleDeleteError = async (expenseId?: string) => {
        try {
            await deleteBankfeedError(companyId, expenseId)
            setSuccess(
                `Bank feed error deleted successfully for expense ID ${expenseId} and will no longer be retried.`
            )
        } catch (e) {
            message.error((e as Error).message)
            setError((e as Error).message)
        }
    }

    const replayBankFeed = async () => {
        if (!bankFeedReplayExpenseId) {
            setError('No expense ID input')
            return
        }

        try {
            await replayBankfeed(companyId, bankFeedReplayExpenseId)
            setSuccess(
                `Bank feed replay initiated successfully for expense ID ${bankFeedReplayExpenseId}.`
            )
        } catch (e) {
            message.error((e as Error).message)
            setError((e as Error).message)
        }
    }

    const columns: ColumnProps<BankFeedErrorResponse>[] = [
        {
            title: 'Expense ID',
            dataIndex: 'expenseId',
        },
        {
            title: 'Error Date',
            dataIndex: 'errorDate',
            render: (errorDate) => dayjs(errorDate).format('lll'),
            sorter: (a, b) => dayjs(a.errorDate).valueOf() - dayjs(b.errorDate).valueOf(),
        },
        {
            title: 'Last Retry Date',
            dataIndex: 'lastRetryDate',
            render: (lastRetryDate) => dayjs(lastRetryDate).format('lll'),
            sorter: (a, b) => dayjs(a.lastRetryDate).valueOf() - dayjs(b.lastRetryDate).valueOf(),
        },
        {
            title: 'Reason For Failure',
            dataIndex: 'failureReason',
        },
        {
            title: 'Expense Amount',
            dataIndex: 'expenseDetails',
            render: (expenseDetails) => `${expenseDetails.currency} ${expenseDetails.amount}`,
        },
        {
            title: 'Expense Date',
            dataIndex: 'expenseDetails',
            render: (expenseDetails) => expenseDetails.date,
            sorter: (a, b) =>
                dayjs(a.expenseDetails?.date).valueOf() - dayjs(b.expenseDetails?.date).valueOf(),
        },
        {
            title: 'Merchant',
            dataIndex: 'expenseDetails',
            render: (expenseDetails) => expenseDetails.merchantName,
        },
        {
            title: 'Action',
            render: (_, record) => (
                <ActionsContainer>
                    <Button type="link" onClick={() => handleInitiateRetry(record?.expenseId)}>
                        Retry
                    </Button>
                    <Button type="link" danger onClick={() => handleDeleteError(record?.expenseId)}>
                        Delete
                    </Button>
                </ActionsContainer>
            ),
        },
    ]

    return (
        <PointerContainer>
            <Space>
                <Input
                    placeholder="Expense ID"
                    type="text"
                    onChange={(value) => setReplayExpenseId(value.target.value)}
                    data-testid="replayExpenseId"
                />
                <Button type="primary" onClick={replayBankFeed} data-testid="submit">
                    Replay Bankfeed For Expense
                </Button>
            </Space>
            <Table
                loading={isLoading}
                pagination={{ defaultPageSize: 20 }}
                rowKey="id"
                dataSource={bankfeedErrorData}
                columns={columns}
            />
        </PointerContainer>
    )
}

export default withTeamErrorHandler<Props>(Team.Demeter, BankFeedErrors)
