import React, { FC, useState } from 'react'
import _ from 'lodash'
import { useCrrRangeRiskScores } from 'services/themis/customer-risk-rating'
import type { RangeRiskScore, RiskScoreType } from 'types/customer-risk-rating'
import Spinner from 'components/content-spinner'
import { Button, Input, InputNumber, Result, Slider, Space, Typography } from 'antd'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import {
    getSliderRowPosition,
    SliderRowPosition,
    topSliderMoved,
    middleSliderMoved,
    bottomSlidersMoved,
} from './crr-range-sliders'

const { Text } = Typography

const Grid = styled.div`
    display: grid;
    grid-template-columns: 10fr 2fr 2fr 2fr 1fr;
    gap: ${spacing.space4};
    align-items: center;
`

interface CrrRangeFactorProps {
    data: RangeRiskScore[]
    mutations: {
        updateRangeRiskScore: (modifiedRangeSettings: RangeRiskScore[]) => Promise<void>
    }
    isActiveVersion: boolean
}

export const CrrRangeFactor: FC<React.PropsWithChildren<CrrRangeFactorProps>> = ({
    data,
    mutations,
    isActiveVersion,
}) => {
    // Make a deep copy of the initial values for editing and sort the ranges in ascending order
    const [modifiedRangeData, setModifiedRangeData] = useState(
        _.cloneDeep(data).sort((a, b) => (a.lowerBound > b.lowerBound ? 1 : -1))
    )

    const [isModified, setIsModified] = useState(false)

    function rangeModified(currentRange: number, rangeValues: number[]) {
        setIsModified(true)
        let newRanges = modifiedRangeData
        const numOfRanges = newRanges.length - 1

        newRanges[currentRange].lowerBound = rangeValues[0]
        newRanges[currentRange].upperBound = rangeValues[1]

        const sliderRowPosition = getSliderRowPosition(currentRange, numOfRanges)!

        switch (sliderRowPosition) {
            case SliderRowPosition.TOP:
                newRanges = topSliderMoved(newRanges, numOfRanges)
                break

            case SliderRowPosition.MIDDLE:
                newRanges = middleSliderMoved(newRanges, numOfRanges, currentRange)
                break

            case SliderRowPosition.BOTTOM:
                newRanges = bottomSlidersMoved(newRanges, numOfRanges, currentRange)
                break
        }

        setModifiedRangeData(_.cloneDeep(newRanges))
    }

    function scoreModified(index: number, newScore: number) {
        setIsModified(true)
        const newData = modifiedRangeData
        newData[index].score = newScore
        setModifiedRangeData(_.cloneDeep(newData))
    }

    function overridingModified(index: number, checked: boolean) {
        setIsModified(true)
        const newData = modifiedRangeData
        newData[index].overriding = checked
        setModifiedRangeData(_.cloneDeep(newData))
    }

    function discardChanges() {
        setModifiedRangeData(
            _.cloneDeep(data).sort((a, b) => (a.lowerBound > b.lowerBound ? 1 : -1))
        )
        setIsModified(false)
    }

    async function submitChanges() {
        await mutations.updateRangeRiskScore(modifiedRangeData)
        setIsModified(false)
    }

    return (
        <>
            <Grid>
                <Text>Range</Text>
                <Text>Start</Text>
                <Text>End</Text>
                <Text>Score</Text>
                <Text>Override</Text>
                {modifiedRangeData.map((item, index) => (
                    <React.Fragment key={`fragment-${index}`}>
                        <Slider
                            key={`slider-${index}`}
                            range
                            max={modifiedRangeData[modifiedRangeData.length - 1].upperBound}
                            value={[item.lowerBound, item.upperBound]}
                            defaultValue={[item.lowerBound, item.upperBound]}
                            onChange={(value) => rangeModified(index, value)}
                            disabled={isActiveVersion}
                        />

                        <InputNumber
                            key={`start-${index}`}
                            data-testid={`start-${index}`}
                            size="small"
                            defaultValue={item.lowerBound}
                            value={item.lowerBound}
                            onChange={(value) =>
                                rangeModified(index, [Number(value), item.upperBound])
                            }
                            style={{ maxWidth: 200 }}
                            disabled={isActiveVersion}
                        />

                        <InputNumber
                            key={`end-${index}`}
                            data-testid={`end-${index}`}
                            size="small"
                            defaultValue={item.upperBound}
                            value={item.upperBound}
                            onChange={(value) =>
                                rangeModified(index, [item.lowerBound, Number(value)])
                            }
                            disabled={isActiveVersion}
                        />

                        <InputNumber
                            key={`score-${index}`}
                            data-testid={`score-${index}`}
                            size="small"
                            defaultValue={item.score}
                            value={item.score}
                            onChange={(value) => scoreModified(index, Number(value))}
                            disabled={isActiveVersion}
                        />
                        <Input
                            key={`overriding-${index}`}
                            data-testid={`overriding-${index}`}
                            size="small"
                            type="checkbox"
                            defaultChecked={item.overriding}
                            checked={item.overriding}
                            onChange={(e) => overridingModified(index, e.target.checked)}
                            disabled={isActiveVersion}
                        />
                    </React.Fragment>
                ))}
            </Grid>
            {isModified && (
                <Space>
                    <Button onClick={discardChanges} style={{ marginLeft: 'auto' }}>
                        Discard
                    </Button>
                    <Button onClick={submitChanges} type="primary">
                        Submit
                    </Button>
                </Space>
            )}
        </>
    )
}

interface CrrRangeFactorContainerProps {
    type: RiskScoreType
    versionId?: string
    isActiveVersion: boolean
}

const CrrRangeFactorContainer: FC<React.PropsWithChildren<CrrRangeFactorContainerProps>> = ({
    type,
    versionId,
    isActiveVersion,
}) => {
    const { data, isValidating, error, mutations } = useCrrRangeRiskScores(type, versionId)

    if (error) {
        return (
            <Result
                title="Error retrieving range values. Try refreshing the page."
                subTitle={error.message}
            />
        )
    }

    if (isValidating || !data?.length) {
        return <Spinner />
    }

    return <CrrRangeFactor data={data} mutations={mutations} isActiveVersion={isActiveVersion} />
}

export default CrrRangeFactorContainer
