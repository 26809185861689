import { Table, Popover, Button, Popconfirm, TableColumnsType, Tag, Typography } from 'antd'
import dayjs from 'packages/dayjs'

import { InvoiceStatus } from 'types/wallet-invoice'

import { ContentContainer } from 'components/layout-containers'
import { deSnakify } from 'utils/strings'
import { useWalletLoads } from './api/use-wallet-loads'
import type { FullWalletLoad } from 'bff/moons/generated/cupid-v1'
import { formatMoneyIntl } from 'utils/money'
import { useMemo } from 'react'

const { Text } = Typography

const CANCELLABLE_INVOICE_STATUS = [
    'PENDING',
    'QUEUED',
    'APPROVED',
    'MANUALLY_APPROVED',
    'BLOCKED',
    'FAILED',
]

const RETRYABLE_WALLET_LOAD = ['APPROVED', 'BLOCKED', 'FAILED', 'QUEUED']

const reasonPopover = (walletLoad: FullWalletLoad) => {
    if (walletLoad?.blockedReason) {
        return <span>{deSnakify(walletLoad?.blockedReason)}</span>
    }
}

const WalletLoads = () => {
    const { walletLoads, isLoading, handleRetryWalletLoad, setInvoiceStatus } = useWalletLoads()

    const statusFilters = useMemo(
        () =>
            Array.from(new Set(walletLoads?.map((walletLoad) => walletLoad.status))).map((a) => ({
                text: a || '',
                value: a || '',
            })),
        [walletLoads]
    )

    const columns: TableColumnsType<FullWalletLoad> = [
        {
            title: 'Loaded at',
            dataIndex: 'loadedAt',
            render: (loadedAt) => (
                <span>{loadedAt ? dayjs(loadedAt).utc().format('lll') : '-'}</span>
            ),
        },
        {
            title: 'Invoice reference',
            dataIndex: 'invoiceReference',
            render: (invoiceReference) => <Text copyable>{invoiceReference}</Text>,
        },

        {
            title: 'Wallet Load ID',
            dataIndex: 'id',
            render: (id) => <Text copyable>{id}</Text>,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount, row) =>
                formatMoneyIntl(
                    { value: amount, currency: row.currency },
                    { currencyDisplay: 'code' }
                ),
        },
        {
            title: 'Payment system',
            dataIndex: 'paymentSystem',
            render: (paymentSystem) => <Tag>{paymentSystem}</Tag>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status, walletLoad) => {
                switch (status) {
                    case InvoiceStatus.PENDING:
                    case InvoiceStatus.BLOCKED:
                        return (
                            <Popover content={reasonPopover(walletLoad)}>
                                <span>{status}</span>
                            </Popover>
                        )

                    default:
                        return <span>{status}</span>
                }
            },
            filters: statusFilters,
            filterSearch: true,
            onFilter: (searchValue, walletLoad) =>
                walletLoad?.status ? walletLoad?.status.includes(searchValue.toString()) : false,
        },
        {
            title: '',
            dataIndex: 'id',
            render: (id, walletLoad) => {
                return (
                    <>
                        {walletLoad?.status &&
                            CANCELLABLE_INVOICE_STATUS.includes(walletLoad.status) &&
                            id !== undefined && (
                                <Popconfirm
                                    title="Are you sure to cancel this wallet load?"
                                    onConfirm={() => setInvoiceStatus(id, InvoiceStatus.CANCELLED)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="link">Cancel</Button>
                                </Popconfirm>
                            )}
                        {walletLoad?.status &&
                            RETRYABLE_WALLET_LOAD.includes(walletLoad.status) &&
                            id !== undefined && (
                                <Popconfirm
                                    title="Do you want to retry this wallet load?"
                                    onConfirm={() => handleRetryWalletLoad(id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="link">Retry</Button>
                                </Popconfirm>
                            )}
                    </>
                )
            },
        },
    ]

    return (
        <ContentContainer>
            <Table dataSource={walletLoads} loading={isLoading} columns={columns} rowKey="id" />
        </ContentContainer>
    )
}

export default WalletLoads
