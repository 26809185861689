import { Typography, type TableColumnsType, Table } from 'antd'
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

import { PEOPLE_DATA_POINTS } from '../constants'
import type { KycVerificationDetail, Person } from '../types'
import {
    StyledSubTable,
    StyledTableHeading,
    TableDescription,
    TableHeadingWrapper,
} from './kyc-status.styled'

const KycItemLabel = ({ isError }: { isError?: boolean }) => {
    return isError ? (
        <ExclamationCircleOutlined style={{ color: '#fb6060' }} />
    ) : (
        <CheckCircleOutlined style={{ color: '#52c41a' }} />
    )
}

const columns: TableColumnsType<KycVerificationDetail> = [
    {
        title: 'isMissing',
        dataIndex: 'isMissing',
        key: 'isMissing',
        width: '39px',
        align: 'center',
        render: (isMissing) => <KycItemLabel isError={isMissing} />,
    },
    {
        title: 'Label',
        dataIndex: 'label',
        key: 'label',
        sorter: (a, b) => (a.isMissing === b.isMissing ? 0 : a.isMissing ? -1 : 1),
        defaultSortOrder: 'ascend',
        render: (label, row) => (
            <Typography.Text type={row.isMissing ? 'danger' : 'success'}>{label}</Typography.Text>
        ),
    },
]

const expandedRowRender = (rowData: KycVerificationDetail) => {
    const nationalityCitizenshipColumn =
        rowData.isGbCompany === true
            ? {
                  title: 'Citizenship',
                  dataIndex: 'hasCitizenship',
                  key: 'hasCitizenship',
                  render: (hasCitizenship: any) => <KycItemLabel isError={!hasCitizenship} />,
              }
            : {
                  title: 'Nationality',
                  dataIndex: 'hasNationality',
                  key: 'hasNationality',
                  render: (hasNationality: any) => <KycItemLabel isError={!hasNationality} />,
              }
    const basePersonColumns: TableColumnsType<Person> = [
        {
            title: 'Name',
            dataIndex: 'personName',
            key: 'personName',
        },
    ]

    const inScopePersonsDetailsColumns: TableColumnsType<Person> = [
        ...basePersonColumns,
        {
            title: 'Date of Birth',
            dataIndex: 'hasDateOfBirth',
            key: 'hasDateOfBirth',
            render: (hasDateOfBirth) => <KycItemLabel isError={!hasDateOfBirth} />,
        },
        {
            title: 'Address',
            dataIndex: 'hasAddress',
            key: 'hasAddress',
            render: (hasAddress) => <KycItemLabel isError={!hasAddress} />,
        },
        nationalityCitizenshipColumn,
    ]

    const pepAnalysisColumns: TableColumnsType<Person> = [
        ...basePersonColumns,
        {
            title: 'PEP analysis performed',
            dataIndex: 'pepAnalysisPerformed',
            key: 'pepAnalysisPerformed',
            render: (pepAnalysis) => <KycItemLabel isError={!pepAnalysis} />,
        },
    ]

    const adminDetailsColumns: TableColumnsType<Person> = [
        ...basePersonColumns,
        {
            title: 'Date of Birth',
            dataIndex: 'hasDateOfBirth',
            key: 'hasDateOfBirth',
            render: (hasDateOfBirth) => <KycItemLabel isError={!hasDateOfBirth} />,
        },
        {
            title: 'Address',
            dataIndex: 'hasAddress',
            key: 'hasAddress',
            render: (hasAddress) => <KycItemLabel isError={!hasAddress} />,
        },
    ]

    const getColumns = () => {
        switch (rowData?.key) {
            case 'inScopePersonsDetails':
                return inScopePersonsDetailsColumns
            case 'pepAnalysis':
                return pepAnalysisColumns
            case 'adminDetails':
                return adminDetailsColumns
            default:
                return []
        }
    }

    return rowData?.people ? (
        <StyledSubTable>
            <Table
                columns={getColumns()}
                dataSource={rowData?.people}
                pagination={false}
                size="small"
                rowKey="personId"
            />
        </StyledSubTable>
    ) : null
}

const expandableConfig = {
    expandedRowRender,
    expandedRowKeys: PEOPLE_DATA_POINTS,
    indentSize: 0,
    showExpandColumn: false,
}

export const StandardKycInformation = ({ data }: { data: KycVerificationDetail[] }) => {
    return (
        <>
            <TableHeading title="KYC Status" />
            <Table
                dataSource={data}
                rowKey="key"
                columns={columns}
                pagination={false}
                size="small"
                bordered
                showHeader={false}
                expandable={expandableConfig}
            />
        </>
    )
}

export const HighRiskKycInformation = ({ data }: { data: KycVerificationDetail[] }) => {
    return (
        <>
            <TableHeading
                title={'Applicable for High Risk customers only'}
                description={
                    'Ensure you have sign off from senior management for high risk customers'
                }
            />
            <Table
                dataSource={data}
                rowKey="key"
                columns={columns}
                pagination={false}
                size="small"
                bordered
                showHeader={false}
                locale={{
                    emptyText: null,
                }}
                expandable={expandableConfig}
            />
        </>
    )
}

const TableHeading = ({ title, description }: { title: string; description?: string }) => {
    return (
        <TableHeadingWrapper>
            <StyledTableHeading>{title}</StyledTableHeading>
            {description && <TableDescription>{description}</TableDescription>}
        </TableHeadingWrapper>
    )
}
