import { Button, Descriptions } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import type { Employee } from '../../types'
import Item from 'antd/es/list/Item'

const readableBooleanField = (value: boolean) => (value ? 'Enabled' : 'Disabled')

type ReadableEmployeeProps = Employee & {
    toggleEditing: (value: boolean) => void
}

const ReadableEmployee = ({
    name,
    count,
    limits,
    virtualCard,
    toggleEditing,
}: ReadableEmployeeProps) => {
    return (
        <Item
            actions={[
                <Button
                    type="primary"
                    size="small"
                    onClick={() => toggleEditing(true)}
                    key="edit-button"
                >
                    Edit
                </Button>,
            ]}
        >
            <Descriptions column={2} title={name}>
                <DescriptionsItem label="Count">{count}</DescriptionsItem>
                <DescriptionsItem label="Card Limit">
                    {limits.card.value ?? 'Not set'}
                </DescriptionsItem>
                <DescriptionsItem label="Virtual Cards">
                    {readableBooleanField(virtualCard)}
                </DescriptionsItem>
            </Descriptions>
        </Item>
    )
}

export default ReadableEmployee
