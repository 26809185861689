import qs from 'qs'
import { kerberosRequest } from 'services/request'
import type { PartnerRole } from 'types/employee'
import type { RoleType } from 'types/permissions'

export async function updatePartnerEmployeeRole(
    partnerId: string,
    userId: string,
    role: PartnerRole
) {
    const response = await kerberosRequest().put(`api/v1/permissions/partner/${partnerId}`, {
        json: { userId, type: role },
    })
    return await response.json()
}

export async function deletePermissionByResourceId({
    resource,
    resourceId,
    userId,
    type,
}: {
    userId: string
    resource: string
    resourceId: string
    type: RoleType | RoleType[]
}) {
    const response = await kerberosRequest().delete(
        `api/v1/permissions/${resource}/${resourceId}?${qs.stringify({ userId, type })}`
    )
    return await response.json()
}
