import type { FC } from 'react'
import { Space, Typography, Input } from 'antd'

const { Text } = Typography
const { Search } = Input

interface FilterCompanyListProps {
    label: string
    placeholder: string
    defaultValue: string
    onSearch: (name: string) => void
}

const FilterCompanyList: FC<React.PropsWithChildren<FilterCompanyListProps>> = ({
    label,
    defaultValue,
    placeholder,
    onSearch,
}) => {
    return (
        <Space>
            <Text>{label}</Text>
            <Search
                id={label}
                allowClear={true}
                defaultValue={defaultValue}
                placeholder={placeholder}
                onSearch={onSearch}
                style={{ width: '35ch' }}
            ></Search>
        </Space>
    )
}

export default FilterCompanyList
