import { useState } from 'react'
import {
    message,
    Button,
    Card,
    Empty,
    Image,
    List,
    Modal,
    Statistic,
    Typography,
    Row,
    Col,
} from 'antd'
import hubspotLogo from '../images/hubspot.svg'
import { ArrowUpOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { bff } from '../bff'

const RemoveHubspotConnectionButton = ({
    companyId,
    isConnected,
}: {
    companyId?: string
    isConnected?: boolean
}) => {
    const { mutate: removeHubspotConnection } =
        bff.externalConnections.removeHubspotConnection.useMutation()

    return (
        <Button
            type="link"
            onClick={() =>
                Modal.confirm({
                    centered: true,
                    icon: <ExclamationCircleOutlined />,
                    content: `Are you sure? This will remove the hubspot connection for "${companyId}"`,
                    onOk: () => {
                        try {
                            removeHubspotConnection({ companyId })
                            message.success('Successfully removed connection!')
                        } catch (err) {
                            message.error(
                                `Failed to remove connection. Error: ${(err as Error).message}`
                            )
                        }
                    },
                })
            }
            disabled={!isConnected}
            style={{ color: !isConnected ? 'rgba(0, 0, 0, 0.25)' : 'red' }}
        >
            Remove connection <ExclamationCircleOutlined />
        </Button>
    )
}

const Hubspot = ({ companyId }: { companyId?: string }) => {
    const { data } = bff.externalConnections.getHubspot.useQuery({ companyId })

    const [showModal, setShowModal] = useState(false)
    const hasRemoveConnectionPermission = useHasPermissions(['remove-hubspot-connection'])
    const actions = [
        <Button
            type="link"
            onClick={() => setShowModal(true)}
            disabled={!data?.isConnected}
            key="view-deals-button"
        >
            View deals
        </Button>,
    ]
    if (hasRemoveConnectionPermission) {
        actions.push(
            <RemoveHubspotConnectionButton companyId={companyId} isConnected={data?.isConnected} />
        )
    }
    return (
        <>
            <Card
                type="inner"
                data-testid="hubspot"
                title={
                    <>
                        <Image src={hubspotLogo} width={16} height={16} style={{ marginTop: 2 }} />{' '}
                        HubSpot
                    </>
                }
                extra={
                    <Typography.Link href={data?.companyLink} disabled={!data?.isConnected}>
                        View in HubSpot <ArrowUpOutlined rotate={45} />
                    </Typography.Link>
                }
                actions={actions}
            >
                <Statistic value={data?.numberOfDeals} title="Deals" />
            </Card>
            <Modal
                open={showModal}
                onCancel={() => setShowModal(false)}
                onOk={() => () => setShowModal(false)}
                footer={null}
                title="HubSpot deals"
                bodyStyle={{ padding: '16px 8px' }}
            >
                {data ? (
                    <>
                        <List size="small" pagination={{ pageSize: 3, total: data?.numberOfDeals }}>
                            {data.deals?.map((deal) => (
                                <>
                                    <List.Item>
                                        <List.Item.Meta title="Lead source" />
                                        {deal.leadSource}
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Meta title="Stage" />
                                        {deal.stage}
                                    </List.Item>
                                    <List.Item>
                                        <List.Item.Meta title="Pipeline" />
                                        {deal.pipeline}
                                    </List.Item>
                                </>
                            ))}
                        </List>
                    </>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </Modal>
        </>
    )
}

export const ExternalConnections = ({ companyId }: { companyId?: string }) => {
    return (
        <Row gutter={16} style={{ padding: '0 24px 16px 0' }}>
            <Col span={12}>
                <Hubspot companyId={companyId} />
            </Col>
        </Row>
    )
}
