import useSWR from 'swr'
import request, { fetcher } from 'services/styx/request'
import type { ErrorResponse } from '@pleo-io/deimos'
import { DataSource, type CompanyBusinessOffering } from 'types/styx'

export const useCompanyBusinessOffering = (companyId?: string) => {
    const url = `rest/v2/styx-companies/${companyId}/business-offering`

    const response = useSWR<CompanyBusinessOffering[], ErrorResponse>(
        companyId ? url : null,
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        }
    )

    const insertCompanyBusinessOffering = async (businessOffering: string[]): Promise<void> => {
        if (!companyId) {
            return Promise.reject("Can't insert business offering without a company id")
        }
        const insertIndustryCodePromises = businessOffering.map((item) => {
            request().post(`rest/v2/styx-companies/${companyId}/business-offering`, {
                json: {
                    name: item,
                    source: DataSource.DUE_DILIGENCE_AGENT_INPUT,
                },
            })
        })
        await Promise.all(insertIndustryCodePromises)
        await response.mutate()
        return Promise.resolve()
    }

    const deleteCompanyBusinessOffering = async (businessOffering: string[]): Promise<void> => {
        if (!companyId) {
            return Promise.reject("Can't delete business offering without a company id")
        }
        const deleteIndustryCodePromises = businessOffering.map((item) => {
            request().delete(`rest/v2/styx-companies/${companyId}/business-offering/${item}`)
        })
        await Promise.all(deleteIndustryCodePromises)
        await response.mutate()
        return Promise.resolve()
    }

    return { ...response, insertCompanyBusinessOffering, deleteCompanyBusinessOffering }
}

export const useBusinessOfferings = () => {
    const url = 'rest/v1/business-offering'

    const response = useSWR<string[], ErrorResponse>(url, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })

    return response
}
