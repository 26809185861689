import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Tag, message } from 'antd'
import HandleKycCheck from 'pages/compliance/shared/handle-kyc-check/handle-kyc-check'
import KycTimeline from 'pages/compliance/shared/kyc-timeline/kyc-timeline'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { RDC, type KycCheck, KycResult } from 'types/styx'
import { bff } from 'pages/compliance/bff'

interface CompanyKycScreeningProps {
    label: string
    companyId: string
    name: string
    data: KycCheck[]
    result: KycResult
    loading: boolean
}

const displayWarning = (result: KycResult) => {
    return result == 'Inconclusive' ? (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
            Lacking screening for legal or trading name of the company. <br /> Please run new check
            to get a conclusive result.
        </Tag>
    ) : null
}

export const CompanyKycScreening = ({
    label,
    companyId,
    name,
    data,
    result,
    loading,
}: CompanyKycScreeningProps) => {
    const { mutateAsync: createScreening, isLoading } =
        bff.companyKycScreening.createKycScreening.useMutation()

    const { mutateAsync: toggleCheckFalsePositive } =
        bff.companyKycScreening.toggleCheckFalsePositive.useMutation()

    const handleCreateScreening = async () => {
        try {
            await createScreening({ companyId })
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const handleToggleFalsePositive = async (check: KycCheck, checked: boolean) => {
        try {
            await toggleCheckFalsePositive({ checkId: check.id, falsePositive: checked })
        } catch (e) {
            message.error(`Failed to toggle false positive status: ${(e as Error).message}`)
        }
    }

    return (
        <HandleKycCheck label={label} status={result}>
            <KycTimeline
                kycChecks={data}
                fetching={loading}
                toggleFalsePositive={handleToggleFalsePositive}
                showRecurringMonitoring={true}
            />
            <Column>
                <Row>
                    <a href={`${RDC.SEARCH_URL}${name}`} target="_blank" rel="noopener noreferrer">
                        Search in RDC
                    </a>
                    <Button
                        data-testid="create-new-screening"
                        type="primary"
                        onClick={handleCreateScreening}
                        loading={isLoading}
                    >
                        Run new check
                    </Button>
                </Row>
                {displayWarning(result)}
            </Column>
        </HandleKycCheck>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

const Column = styled.div`
    display: grid;
    gap: ${spacing.space24};
`
