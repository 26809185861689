export enum SupportedLanguage {
    DA = 'da',
    SV = 'sv',
    EN = 'en',
    DE = 'de',
    ES = 'es',
    FR = 'fr',
}

export const SUPPORTED_LANGUAGE_LIST = [
    SupportedLanguage.EN,
    SupportedLanguage.DE,
    SupportedLanguage.ES,
    SupportedLanguage.SV,
    SupportedLanguage.FR,
    SupportedLanguage.DA,
] as const
