import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'router/protected-route'

import EmployeeSearch from './screens/employee-search/employee-search'
import CompanySearch from './screens/company-search/company-search'
import EmployeeAdvancedSearch from './screens/advanced-search/advanced-search'
import EmployeeDetails from './screens/employee-details/employee-details'

export default function Customer() {
    return (
        <Routes>
            <Route element={<ProtectedRoute />}>
                <Route path="/" element={<CompanySearch />} />
                <Route path="/advanced-search" element={<EmployeeAdvancedSearch />} />
                <Route path="/:companyId" element={<EmployeeSearch />} />
                <Route path="/:companyId/:employeeId" element={<EmployeeDetails />} />
            </Route>
        </Routes>
    )
}
