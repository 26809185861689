import React from 'react'

import { useManagement } from 'services/styx/management'
import SnapshotTabs from 'pages/compliance/company/shared/snapshot-tabs/snapshot-tabs'
import { useKycCheckpoints, useStyxCompany } from 'services/deimos/styx-company/styx-company'
import ReadonlyDirectors from './readonly-directors/readonly-directors'
import DirectorAccordion from './director-accordion/director-accordion'
import { Card } from 'antd'
import { removeCommonPersons } from 'pages/compliance/company/people/helpers'
import { AdminVerificationRequestSection } from '../shared/admin-verification-request-section/admin-verification-request-section'
import { spacing } from 'theme/tokens'
import styled from 'styled-components'
import {
    companyShareholderSharePercentageThreshold,
    shareholderSharePercentageThreshold,
} from 'services/styx/ownership'
import { bff } from 'pages/compliance/bff'
import type { ErrorResponse } from '@pleo-io/deimos'
import CompanyShareholderList from './company-shareholders/company-shareholder-list'

type PeopleProps = {
    companyId: string
    companyCountry?: string
}
const People = ({ companyId, companyCountry }: PeopleProps) => {
    const [activeSnapshot, setActiveSnapshot] = React.useState<string | undefined>()
    const { company } = useStyxCompany()
    const snapshotQuery = activeSnapshot ? activeSnapshot : ''
    const { shareholders } = useManagement()
    const kycCheckpoints = useKycCheckpoints()
    const onFetchSnapshot = (snapshotDate?: string) => setActiveSnapshot(snapshotDate)

    const isOnboardedViaB4B = company?.onboardedVia === 'B4B'

    const {
        data,
        error,
        refetch: refetchPeople,
    } = bff.people.getCompanyManagement.useQuery({
        id: company?.globalId,
        styxId: company?.id,
        snapshot: snapshotQuery,
    })

    const errorResponse =
        error === null
            ? undefined
            : ({ message: error?.message, status: error?.data?.httpStatus } as ErrorResponse)

    const deduplicatedShareholders = removeCommonPersons(shareholders, data)

    return (
        <SnapshotTabs
            kycCheckpoints={kycCheckpoints}
            error={errorResponse}
            onFetchSnapshot={onFetchSnapshot}
            readonlyChildren={(snapshot) => (
                <>
                    <ReadonlyDirectors
                        management={data}
                        snapshot={snapshot}
                        error={errorResponse}
                    />

                    <AdminVerificationRequestSection
                        companyId={companyId}
                        companyCountry={companyCountry}
                        snapshot={snapshot}
                        readOnly
                    />
                </>
            )}
        >
            <Card title="Management">
                <DirectorAccordion
                    management={data}
                    refetchPeople={refetchPeople}
                    error={errorResponse}
                />
            </Card>

            {deduplicatedShareholders.length > 0 && (
                <CardWithMargin
                    title={`Remaining Significant Shareholders (with ownership >= ${shareholderSharePercentageThreshold}%)`}
                >
                    <DirectorAccordion
                        management={deduplicatedShareholders}
                        refetchPeople={refetchPeople}
                        error={errorResponse}
                    />
                </CardWithMargin>
            )}

            {isOnboardedViaB4B && (
                <CardWithMargin
                    title={`Company Significant Shareholders (with ownership >= ${companyShareholderSharePercentageThreshold}%)`}
                >
                    <CompanyShareholderList companyId={companyId} />
                </CardWithMargin>
            )}

            <AdminVerificationRequestSection
                companyId={companyId}
                companyCountry={companyCountry}
            />
        </SnapshotTabs>
    )
}

const CardWithMargin = styled(Card)`
    margin-top: ${spacing.space16};
`

export default People
