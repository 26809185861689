import { countries } from 'countries-list'
import { Select, SelectProps } from 'formik-antd'
import type { FC } from 'react'

const { Option } = Select

type Alpha2Code = keyof typeof countries

interface Props extends SelectProps {
    disabledValues: string[]
}

const CitizenshipsSelect: FC<React.PropsWithChildren<Props>> = ({ disabledValues, ...props }) => {
    const countriesByAlpha2Code = Object.keys(countries)

    return (
        <Select
            {...props}
            optionFilterProp="label"
            mode="multiple"
            data-testid="citizenships-select"
        >
            {countriesByAlpha2Code.map((alpha2code) => (
                <Option
                    disabled={disabledValues.includes(alpha2code)}
                    key={alpha2code}
                    value={alpha2code}
                    label={countries[alpha2code as Alpha2Code].name}
                    data-testid={`citizenships-option-${alpha2code}`}
                >
                    {countries[alpha2code as Alpha2Code].name}
                </Option>
            ))}
        </Select>
    )
}

export default CitizenshipsSelect
