import type { FC } from 'react'
import { message } from 'antd'
import { Formik, FormikHelpers } from 'formik'
import { Form, Select, FormItem, SubmitButton } from 'formik-antd'

import type { Shareholder } from 'types/styx'
import type { OwnershipMutations } from 'services/deimos/styx-company/ownership'
import { getEntityName } from 'pages/compliance/shared/utils'
import KycPopover from 'pages/compliance/company/ownership/edit-ownership/kyc-popover'

import { FormValues, validationSchema } from './validation-schema'

const { Option, OptGroup } = Select

interface Props {
    shareholders?: Shareholder[]
    disabled: boolean
    onRemoveShareholders: OwnershipMutations['removeShareholders']
}

const RemoveShareholderForm: FC<React.PropsWithChildren<Props>> = ({
    shareholders,
    onRemoveShareholders,
    disabled,
}) => {
    const onSubmit = async (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        try {
            await onRemoveShareholders(values.selectedShareholders)
            resetForm()
        } catch (e) {
            message.error(`Failed to remove shareholders: ${(e as Error).message}`)
        }
    }

    const initialValues: FormValues = { selectedShareholders: [] }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            <Form layout="vertical">
                <FormItem name="selectedShareholders" label="Remove shareholders">
                    <Select
                        optionFilterProp="label"
                        disabled={disabled}
                        name="selectedShareholders"
                        mode="multiple"
                        placeholder="Select shareholders to remove"
                    >
                        <OptGroup label="All shareholders">
                            {(shareholders || []).map((shareholder) => (
                                <Option
                                    label={getEntityName(shareholder)}
                                    key={shareholder?.shareholderId}
                                    value={shareholder?.shareholderId}
                                >
                                    <KycPopover entity={shareholder}>
                                        {getEntityName(shareholder)}
                                    </KycPopover>
                                </Option>
                            ))}
                        </OptGroup>
                    </Select>
                </FormItem>
                <SubmitButton data-testid="submit">Remove</SubmitButton>
            </Form>
        </Formik>
    )
}

export default RemoveShareholderForm
