import type { Employee } from 'types/employee'
import { Role, type Person, type UboResponse } from 'types/styx'

function isPerson(person: Person | UboResponse | undefined): person is Person {
    return person !== undefined && 'userId' in person
}

export function isDirector(
    employee: Employee | null | undefined,
    management: (Person | UboResponse)[] | undefined
) {
    if (!management || !employee) return false

    const manager = management.find((m): m is Person => isPerson(m) && m.userId === employee.userId)

    return manager?.roles?.includes(Role.DIRECTOR)
}

export interface DirectorInfo {
    id?: string
    name: string
    email: string
}

export function getDirectorsInfo(
    management: (Person | UboResponse)[] | undefined,
    employees: Employee[] | null | undefined
): DirectorInfo[] {
    if (!management) return []

    const directors = management.filter(
        (m): m is Person => isPerson(m) && Boolean(m.roles?.includes(Role.DIRECTOR))
    )

    const info = directors.map((d) => {
        const employee = employees?.find(({ userId }) => userId === d.userId)
        return {
            name: [d.name.givenName, d.name.middleName ?? '', d.name.familyName]
                .filter(Boolean) // we filter out all empty values
                .join(' '),
            email: employee ? employee.email : '',
            id: d.userId,
        }
    })

    return info
}
