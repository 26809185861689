import type { Department } from '@pleo-io/deimos'
import { buildFetcher, oberonRequest } from 'services/request'
import useSWR from 'swr'

export interface TeamMember {
    id: string
    firstName?: string
    lastName?: string
    avatar?: string
    companyName?: string
}

export interface GetTeam {
    id: string
    name: string
    code?: string
    companyId?: string
    organizationId?: string
    members?: TeamMember[]
}

interface GetTeamResponse {
    data: GetTeam[]
    pagination: any
}

export const useCompanyTeams = (companyId?: string) => {
    const { data: teams, ...rest } = useSWR<GetTeamResponse>(
        companyId ? `rest/v1/teams?companyId=${companyId}` : null,
        buildFetcher(oberonRequest)
    )

    return { teams, ...rest }
}

export const useDepartment = (teamId?: string) => {
    const { data: department, ...rest } = useSWR<Department>(
        teamId ? `rest/v1/departments/${teamId}?includeReviewerMembers=true` : null,
        buildFetcher(oberonRequest)
    )

    return { department, ...rest }
}
