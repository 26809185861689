import { Button, Modal, Select, Space } from 'antd'
import type { AccountState } from 'bff/moons/generated/kalyke'
import { useState } from 'react'
import styled from 'styled-components'

interface Props {
    isOpen: boolean
    isLoading: boolean
    onCancel: () => void
    onSubmit: (state: AccountState) => void
}

export default function AccountStateChangeModal({ isOpen, isLoading, onCancel, onSubmit }: Props) {
    const [state, setState] = useState<AccountState>('ACTIVE')

    const options = [
        {
            value: 'ACTIVE',
            label: 'Active',
        },
        {
            value: 'GRACE',
            label: 'Grace',
        },
        {
            value: 'OVERDUE',
            label: 'Overdue',
        },
        {
            value: 'COLLECTIONS',
            label: 'Collections',
        },
        {
            value: 'PENDING',
            label: 'Pending',
        },
        {
            value: 'PENDING_RENEWAL',
            label: 'Pending Renewal',
        },
        {
            value: 'CLOSED',
            label: 'Closed',
        },
        {
            value: 'INACTIVE',
            label: 'Inactive',
        },
    ]

    return (
        <Modal
            open={isOpen}
            onCancel={onCancel}
            footer={null}
            centered
            title="Change account state"
            data-testid="account-state-change-modal"
        >
            <StyledSpace direction="vertical" size="middle">
                <Select
                    defaultValue={state}
                    style={{ width: '100%' }}
                    onChange={(value) => setState(value)}
                    options={options}
                    aria-label="Select account state"
                />
                <Button type="primary" onClick={() => onSubmit(state)} loading={isLoading}>
                    Change state
                </Button>
            </StyledSpace>
        </Modal>
    )
}

const StyledSpace = styled(Space)`
    width: 100%;
`
