import { Collapse } from 'antd'
import { bff } from 'pages/compliance/bff'

const { Panel } = Collapse

export const StpCheckAuditContainer = ({ companyId }: { companyId: string }) => {
    const { data: stpCheckAuditData } = bff.stpCheck.getStpCheckAudit.useQuery(
        { companyId },
        { enabled: !!companyId }
    )

    return (
        <Collapse>
            <Panel
                key={companyId}
                header={<div>{'STP Check Evidence Audit'}</div>}
                data-testid="stp-check-audit-panel"
            >
                <div data-testid="stp-check-audit-data">
                    <pre>
                        {stpCheckAuditData &&
                            JSON.stringify(JSON.parse(stpCheckAuditData), null, 2)}
                    </pre>
                </div>
            </Panel>
        </Collapse>
    )
}
