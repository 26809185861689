import { Alert, Button, Checkbox, Divider, List, Modal, Typography } from 'antd'
import { MethodState, useSecurity } from './use-security-resets'
import React, { FC, useState } from 'react'
import { CheckCircleTwoTone } from '@ant-design/icons'

interface Props {
    userId: string
    isVisible: boolean
    onClose: () => void
}

const isReady = (methodState: MethodState) => ['initial', 'error'].includes(methodState)

interface ResetItemProps {
    methodState: MethodState
    onClick: () => void
    title: React.ReactNode
    description?: React.ReactNode
    isIdentityConfirmed: boolean
    buttonText: string
}

const ResetItem: FC<ResetItemProps> = ({
    methodState,
    onClick,
    title,
    description,
    isIdentityConfirmed,
    buttonText,
}) => {
    return (
        <List.Item
            actions={[
                <Button
                    disabled={!isIdentityConfirmed || !isReady(methodState)}
                    onClick={onClick}
                    icon={methodState === 'success' && <CheckCircleTwoTone />}
                    key={buttonText}
                >
                    {buttonText}
                </Button>,
            ]}
        >
            <List.Item.Meta title={title} description={description} />
            {methodState === 'error' && (
                <Typography.Text type="danger">
                    Something went wrong, please try again.
                </Typography.Text>
            )}
        </List.Item>
    )
}

export const SecurityResetModal: FC<Props> = ({ userId, isVisible, onClose }) => {
    const [isIdentityConfirmed, setIsIdentityConfirmed] = useState(false)

    const { mutations, methodState } = useSecurity(userId)

    return (
        <Modal open={isVisible} title="Security resets" onCancel={onClose} footer={null}>
            <Alert
                message={
                    <>
                        <Typography.Paragraph>
                            To reset a user's security methods, you acknowledge that you have
                            confirmed the identity of the user and given them access to their
                            account.
                        </Typography.Paragraph>
                        <Checkbox onChange={(e) => setIsIdentityConfirmed(e.target.checked)}>
                            <Typography.Text strong>
                                I have confirmed the user's identity
                            </Typography.Text>
                        </Checkbox>
                    </>
                }
            />
            <Divider />
            <List itemLayout="vertical">
                <ResetItem
                    methodState={methodState.resetTwoFactor}
                    onClick={mutations.resetUserTwoFactor}
                    title="Two factor authentication"
                    isIdentityConfirmed={isIdentityConfirmed}
                    buttonText="Reset two factor"
                />
                <ResetItem
                    methodState={methodState.resetPasscode}
                    onClick={mutations.resetUserPasscode}
                    title="Passcode"
                    isIdentityConfirmed={isIdentityConfirmed}
                    buttonText="Reset passcode"
                />
                <ResetItem
                    methodState={methodState.deleteRefreshTokens}
                    onClick={mutations.deleteUserRefreshTokens}
                    title="Refresh token"
                    description="This will sign the user out of all their trusted devices and browsers."
                    isIdentityConfirmed={isIdentityConfirmed}
                    buttonText="Delete refresh tokens"
                />
            </List>
            <Divider />
            <Button onClick={onClose}>Done</Button>
        </Modal>
    )
}
