import type { PaginatedLoginEventResponse } from 'bff/moons/generated/pandora-v1'

interface BrowserDetails {
    major?: string
    name?: string
    version?: string
}

interface CPUDetails {
    architecture?: string
}

interface DeviceDetails {
    model?: string
    type?: string
    vendor?: string
}

interface EngineDetails {
    name?: string
    version?: string
}

interface OSDetails {
    name?: string
    version?: string
}

export interface UserAgentDetails {
    browser: BrowserDetails
    cpu: CPUDetails
    device: DeviceDetails
    engine: EngineDetails
    os: OSDetails
    ua: string
}

export interface LoginEventDetailsResponse {
    eventData: PaginatedLoginEventResponse
}

export enum EVENT_NAME {
    LOGIN_SUCCESS = 'login-success',
    SMS_SENT = 'sms-sent',
    LOGIN_AUTHENTICATION_FAIL = 'login-authentication-failure',
    PASSCODE_RESET_EMAIL_SENT = 'passcode-reset-email-sent',
    TFA_RESET_EMAIL_SENT = 'tfa-reset-email-sent',
    PASSCODE_RESET = 'passcode-reset',
    TFA_RESET = 'tfa-reset',
}
