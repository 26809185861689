import { Row, Tag } from 'antd'
import type { Account } from 'bff/moons/generated/kalyke'
import { formatDate } from 'packages/dates/dates'
import styled from 'styled-components'

interface Props {
    account: Account
}

export default function AccountCardHeader({ account }: Props) {
    const title = `${account.accountType} - ${formatDate(account.createdAt)}`

    const getStateColor = () => {
        switch (account.state) {
            case 'ACTIVE':
                return 'green'
            case 'GRACE':
                return 'yellow'
            case 'OVERDUE':
                return 'orange'
            case 'COLLECTIONS':
                return 'red'
            case 'PENDING_RENEWAL':
                return 'blue'
            default:
                return 'default'
        }
    }

    return (
        <Row align="middle" justify="space-between" data-testid={`account-${account.id}-header`}>
            <StyledCardHeader>
                <h3>{title}</h3>
                <Tag color={getStateColor()}>{account.state}</Tag>
            </StyledCardHeader>
        </Row>
    )
}

const StyledCardHeader = styled.div`
    display: flex;
    column-gap: 2rem;
    align-items: center;
    font-size: 1rem;
    font-weight: semibold;
`
