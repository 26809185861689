import { Button, Card, Form, Space } from 'antd'

import { useState } from 'react'

import type { CreateMarketplaceAppResponse } from 'pages/partnerships/bff/types.bff'
import { useToggle } from 'pages/partnerships/utils'
import {
    useAppMarketplaceAPIContext,
    useAppMarketplaceContext,
} from '../contexts/app-markeplace-context'
import type { CreateAppFormValues } from '../models/create-app-form'
import { AppAuthenticationMethod } from './auth-method-input'
import { ClientDetailsModalContainer } from './client-details-modal'
import {
    AppCategoryInput,
    AppNameInput,
    AppSlugInput,
    AppTypeInput,
} from './shared-app-form-inputs'

export function CreateAppSection() {
    const [isFormVisible, toggleForm] = useToggle(false)

    return (
        <Card title="Create a new app listing">
            {isFormVisible ? (
                <CreateAppSection.Form onClose={toggleForm} />
            ) : (
                <CreateAppSection.CTA onClick={toggleForm} />
            )}
        </Card>
    )
}

const CreateAppForm = ({ onClose }: { onClose: () => void }) => {
    const { createApp } = useAppMarketplaceAPIContext()
    const [form] = Form.useForm<CreateAppFormValues>()

    const [createClientResponse, setCreateClientResponse] =
        useState<CreateMarketplaceAppResponse['client']>()

    const handleSubmit = async (values: CreateAppFormValues) => {
        const result = await createApp(values)

        if (result?.client) {
            setCreateClientResponse(result.client)
            return
        }

        onClose()
    }

    return (
        <>
            <ClientDetailsModalContainer onClose={onClose} clientData={createClientResponse} />
            <Form form={form} onFinish={handleSubmit} requiredMark={false} layout="vertical">
                <CreateAppForm.Body />
                <CreateAppForm.Actions onClose={onClose} />
            </Form>
        </>
    )
}
CreateAppSection.Form = CreateAppForm

const CreateAppCTA = ({ onClick }: { onClick: () => void }) => (
    <Button type="primary" onClick={onClick}>
        Create new app
    </Button>
)
CreateAppSection.CTA = CreateAppCTA

const CreateAppFormBody = () => (
    <Space direction="vertical" style={{ marginTop: '16px', width: '100%' }} size="small">
        <CreateAppFormBody.NameInput />
        <CreateAppFormBody.SlugInput
            alertMessage="Slug is used in the URL of the app listing. It should be unique and contain only lowercase letters, numbers, and hyphens/underscores."
            alertType="info"
        />

        <CreateAppFormBody.TypeInput />
        <CreateAppFormBody.CategoryInput />

        <CreateAppFormBody.AuthenticationMethod />
    </Space>
)

CreateAppForm.Body = CreateAppFormBody
CreateAppFormBody.NameInput = AppNameInput
CreateAppFormBody.SlugInput = AppSlugInput
CreateAppFormBody.TypeInput = AppTypeInput
CreateAppFormBody.CategoryInput = AppCategoryInput
CreateAppFormBody.AuthenticationMethod = AppAuthenticationMethod

const Actions = ({ onClose }: { onClose: () => void }) => (
    <Space direction="horizontal" size="middle">
        <Button type="default" onClick={onClose}>
            Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={useAppMarketplaceContext().isCreating}>
            Create
        </Button>
    </Space>
)
CreateAppForm.Actions = Actions
