import { useReducer } from 'react'
import { Typography, Table, type TableColumnProps, Modal } from 'antd'
import type { GetOrganizationSimplifiedResponseModel } from 'bff/moons/generated/oberon-v1'

import { OrganizationCompanies } from './organization-companies'
import { TableStyleProvider } from '../styles'
import { bff } from '../bff'
import { useQueryFromParams } from '../lib/use-query-from-params'

const { Text } = Typography

interface OrganizationModal {
    open: boolean
    organizationId: string | null
}

const organizationModalReducer = (
    state: OrganizationModal,
    action: { type: 'open' | 'close'; organizationId?: string }
) => {
    switch (action.type) {
        case 'close':
            return { open: false, organizationId: null }
        case 'open':
            return { open: true, organizationId: action?.organizationId || null }
    }
}

const organizationColumns: TableColumnProps<GetOrganizationSimplifiedResponseModel>[] = [
    {
        title: 'Organization Name',
        dataIndex: 'name',
    },
    {
        title: 'Organization ID',
        dataIndex: 'id',
        onCell: () => ({ onClick: (e) => e.stopPropagation() }),
        render: (id) => <Text copyable>{id}</Text>,
        align: 'right',
        width: '29%',
    },
]

export const OrganizationResultsTable = () => {
    const { query } = useQueryFromParams()
    const { data } = bff.organizationResultsTable.getOrganizationSearchResults.useQuery({
        query,
    })

    const [organizationModalState, organizationModalDispatch] = useReducer(
        organizationModalReducer,
        {
            open: false,
            organizationId: null,
        }
    )

    return (
        <>
            <TableStyleProvider>
                <Table
                    dataSource={data}
                    columns={organizationColumns}
                    rowKey="id"
                    onRow={(organization) => ({
                        onClick: () =>
                            organizationModalDispatch({
                                type: 'open',
                                organizationId: organization.id,
                            }),
                    })}
                />
            </TableStyleProvider>
            <Modal
                open={organizationModalState.open}
                onCancel={() => organizationModalDispatch({ type: 'close' })}
                width={840}
                footer={null}
                bodyStyle={{ padding: 0 }}
                title="Companies in this organization"
            >
                <OrganizationCompanies organizationId={organizationModalState.organizationId} />
            </Modal>
        </>
    )
}
