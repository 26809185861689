import { useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { Table } from 'antd'
import TableCell from '../cells'
import TableRow from '../row'
import { allKey, fieldNameDelimiter } from './constants'
import { FormDataTypes, RecordDatatype } from '../enums'
import type { FormikStructure, PlanTableProps } from '../types'
import Footer from '../../footer'
import { PlanLimitsResponse } from '@pleo-io/janus-ts-models'
import useUpdateTable from '../hooks/use-update-table'
import { generateColumns, generateDataSource, useGetScheduledPlans } from './helpers'
import { bff } from '../../bff'

const PlanTable = ({
    header,
    isEditing,
    plans,
    currentView,
    onSubmit,
    onCancel,
}: PlanTableProps) => {
    const { data: entitlements } = bff.features.getFeatures.useQuery()
    const limits = PlanLimitsResponse.getAttributeTypeMap()
    const { resetForm } = useFormikContext()
    const {
        getPlanColumns,
        getFieldValue,
        updateFieldValue,
        generateDefaultParameters,
        getDataTypeHelpers,
    } = useUpdateTable()
    const scheduledPlans = useGetScheduledPlans(plans ?? [])
    const hasPlans = plans?.length > 0

    useEffect(() => {
        if (!isEditing) return
        resetForm()
    }, [isEditing, resetForm])

    const onFieldUpdate = (
        updatedFieldName: string,
        updatedFieldValue: string | boolean,
        touchedValues: FormikStructure,
        type: RecordDatatype
    ) => {
        const [featureName, featureColumnKey, featureID, dataType] =
            updatedFieldName.split(fieldNameDelimiter)
        const defaultHelperParams = generateDefaultParameters({
            featureName,
            featureColumnKey,
            featureID,
            dataType: dataType as FormDataTypes,
            updatedFieldValue,
        })
        const dataTypeHelpers = getDataTypeHelpers(type)
        const shouldEnabledFeature = dataType === FormDataTypes.AddOn
        const shouldDisabledAddon = dataType === FormDataTypes.Feature

        const comparePlansForSameValue = (planColumnKeys: string[]) => {
            return planColumnKeys.every((planColumnKey) => {
                const fieldValue = getFieldValue({
                    ...defaultHelperParams,
                    touchedValues,
                    dataSource,
                    columnKey: planColumnKey,
                })
                return dataTypeHelpers.compareValue(fieldValue, updatedFieldValue)
            })
        }

        const handleAllOnForRow = () => {
            const planColumns = getPlanColumns(columns)
            const otherPlanKeys = planColumns
                .map(({ dataIndex }) => dataIndex)
                .filter((dataIndex) => dataIndex !== featureColumnKey)

            const otherPlansHaveSameValue = comparePlansForSameValue(otherPlanKeys)
            const shouldToggleAllColumn = updatedFieldValue && otherPlansHaveSameValue

            if (shouldToggleAllColumn) {
                updateFieldValue({
                    ...defaultHelperParams,
                    columnKey: allKey,
                    updatedValue: type === RecordDatatype.Input ? updatedFieldValue : true,
                })

                if (dataTypeHelpers.shouldCheckAddon && shouldEnabledFeature) {
                    updateFieldValue({
                        ...defaultHelperParams,
                        columnKey: allKey,
                        fieldDataType: FormDataTypes.Feature,
                        updatedValue: true,
                    })
                }
            } else {
                updateFieldValue({
                    ...defaultHelperParams,
                    columnKey: allKey,
                    updatedValue: dataTypeHelpers.falseValue,
                })

                if (dataTypeHelpers.shouldCheckAddon && shouldDisabledAddon) {
                    updateFieldValue({
                        ...defaultHelperParams,
                        columnKey: allKey,
                        fieldDataType: FormDataTypes.AddOn,
                        updatedValue: false,
                    })
                }
            }
        }

        const updateColumnsWithValueFromAll = () => {
            const planColumns = getPlanColumns(columns)
            planColumns.forEach(({ dataIndex }) => {
                updateFieldValue({
                    ...defaultHelperParams,
                    columnKey: dataIndex,
                })
                if (dataTypeHelpers.shouldCheckAddon) {
                    handleAddOnEnabledForFeature(dataIndex)
                }
            })
        }

        const handleAddOnEnabledForFeature = (planColumnKey: string) => {
            if (shouldEnabledFeature && updatedFieldValue) {
                updateFieldValue({
                    ...defaultHelperParams,
                    columnKey: planColumnKey,
                    fieldDataType: FormDataTypes.Feature,
                    updatedValue: true,
                })
            }

            if (shouldDisabledAddon && !updatedFieldValue) {
                updateFieldValue({
                    ...defaultHelperParams,
                    columnKey: planColumnKey,
                    fieldDataType: FormDataTypes.AddOn,
                })
            }
        }

        const updateAllColumns = () => {
            updateColumnsWithValueFromAll()
            if (dataTypeHelpers.shouldCheckAddon) {
                handleAddOnEnabledForFeature(allKey)
            }
        }

        const updateIndividualColumn = () => {
            handleAllOnForRow()
            if (dataTypeHelpers.shouldCheckAddon) {
                handleAddOnEnabledForFeature(featureColumnKey)
            }
        }

        if (featureColumnKey === allKey) {
            updateAllColumns()
        } else {
            updateIndividualColumn()
        }
    }

    const columns = useMemo(() => {
        return hasPlans ? generateColumns(currentView, plans, isEditing, onFieldUpdate) : []

        // Disabling so we can use stringified version of deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPlans, JSON.stringify(plans), currentView, isEditing])

    const dataSource = useMemo(() => {
        return hasPlans && entitlements
            ? generateDataSource(entitlements, limits, plans, currentView, scheduledPlans)
            : []

        // Disabling so we can use stringified version of deps
        // eslint-disable-next-line eslint-comments/disable-enable-pair
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [
        hasPlans,
        JSON.stringify(entitlements),
        JSON.stringify(plans),
        JSON.stringify(limits),
        JSON.stringify(scheduledPlans),
    ])
    // eslint-disable-next-line eslint-comments/disable-enable-pair, eslint-comments/no-duplicate-disable
    /* eslint-disable react-hooks/exhaustive-deps */

    const footer = () =>
        isEditing ? <Footer onCancel={onCancel} onSubmit={onSubmit} /> : undefined

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            loading={false}
            rowKey={(record) => record.id}
            title={header}
            sticky={true}
            footer={footer}
            pagination={{
                pageSize: 100,
            }}
            scroll={{ x: 'max-content' }}
            components={{
                body: {
                    cell: TableCell,
                    row: TableRow,
                },
            }}
        />
    )
}

export default PlanTable
