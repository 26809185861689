import { Divider, Empty, List, Skeleton, Space, Statistic, Typography } from 'antd'
import { bff } from '../bff'

const { Text } = Typography

export const MostRecentInvoice = ({ companyId }: { companyId: string }) => {
    const { data, isLoading } = bff.mostRecentInvoice.getMostRecentInvoice.useQuery({
        companyId,
        limit: 1,
    })

    const doesInvoiceExist = !!data?.createdDate

    return (
        <>
            {isLoading && <Skeleton active />}
            {!isLoading && doesInvoiceExist && (
                <>
                    <Statistic title={`Invoice due on ${data.dueDate}`} value={data?.total || ''} />
                    <Text type="secondary">Not final until billing cycle is over.</Text>
                    <Divider style={{ margin: '8px 0' }} />
                    <List itemLayout="horizontal" split={false} size="small">
                        <List.Item style={{ padding: '8px 0' }}>
                            <List.Item.Meta title={'Total without tax'} />
                            <Text>{data.totalWithoutTax}</Text>
                        </List.Item>
                        <List.Item style={{ padding: '8px 0' }}>
                            <List.Item.Meta title={'Tax'} />
                            <Text>{data.tax}</Text>
                        </List.Item>
                        <List.Item style={{ padding: '8px 0' }}>
                            <List.Item.Meta title={'Total'} />
                            <Text>{data.total}</Text>
                        </List.Item>
                        <Divider style={{ margin: '8px 0' }} />
                        <List.Item style={{ padding: '8px 0' }}>
                            <List.Item.Meta
                                title="Estimated total"
                                description={`Autopay on ${data.dueDate}`}
                            />
                            <Text>{data?.total}</Text>
                        </List.Item>
                    </List>
                    <Space style={{ justifyContent: 'space-between', width: '100%' }}>
                        <Text type="secondary">{data.status}</Text>
                        <Text type="secondary" copyable>
                            {data.number}
                        </Text>
                    </Space>
                </>
            )}
            {!isLoading && !doesInvoiceExist && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No invoices" />
            )}
        </>
    )
}
