export enum FeatureFlagsEnum {
    departmentsSync = 'Departments Sync',
    xeroMerchantAsContact = 'Xero Export Merchant As Contact',
    inventio = 'Inventio',
    spreadsheetReimport = 'Spreadsheet Re-import',
    callisto = '[Internal] Callisto testing',
    suggestedTags = 'Suggested Tags',
    recarding = '[Internal] Recarding',
    managePlans = 'Manage plans',
    directDebit = 'Direct Debit',
    fetchForOffice365 = 'Fetch For Office 365',
    representationAdmin = '[Admin] Representation',
    economicProject = 'Visma e-conomic Projects',
    billInvoices = 'Pleo Invoices',
    kafka_exports = '[Internal][Beta] ERP exports V2',
    vismadotnet = 'Visma.NET Integration',
    procountor = 'Procountor Integration',
}

export type Feature = keyof typeof FeatureFlagsEnum

export type FeatureFlags = {
    [key in Feature]?: boolean
}
