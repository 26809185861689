import styxRequest from './request'
import type { KycCheck } from 'types/styx'

export const manuallyApprovePepCheck = (personId: string) =>
    styxRequest().post(`rest/v2/kyc-checks/pep-checks/person/${personId}/manually-approve`).json()

export const updateKycFalsePositive = (
    checkIdId: string,
    falsePositive: boolean
): Promise<KycCheck> =>
    styxRequest()
        .patch(`rest/v2/kyc-checks/${checkIdId}/falsepositive`, {
            json: { falsePositive: falsePositive },
        })
        .json()
