import * as Yup from 'yup'

export const validationSchema = Yup.object()
    .shape({
        amount: Yup.number()
            .required('Amount is required')
            .positive('Amount must be greater than 0'),
        confirmedAmount: Yup.number()
            .required('Confirm Amount is required')
            .when('amount', ([amount], schema) =>
                schema.test(
                    'confirmAmount equal to amount',
                    'Different than amount',
                    (value) => amount === value
                )
            ),
        note: Yup.string().required('Required'),
    })
    .required()

export type FormValues = Yup.InferType<typeof validationSchema>
