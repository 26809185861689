import * as yup from 'yup'
import { ResultScore, RiskScoreV2 } from 'types/styx'

export const b4bValidationSchema = yup.object().shape({
    score: yup
        .string()
        .required()
        .nullable()
        .oneOf([...Object.values(RiskScoreV2)]),
    result: yup
        .string()
        .required()
        .nullable()
        .oneOf([...Object.values(ResultScore), null]),
    notes: yup.string().nullable(),
})

export type B4BFormValues = yup.InferType<typeof b4bValidationSchema>

export const validationSchema = yup.object().shape({
    result: yup
        .string()
        .required()
        .nullable()
        .oneOf([...Object.values(ResultScore), null]),
    notes: yup.string().nullable(),
})

export type FormValues = yup.InferType<typeof validationSchema>
