import type { FC } from 'react'
import { Popover } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import { hasKycCheck } from 'pages/compliance/shared/utils'
import type { Entity, Director } from 'types/styx'

interface Props {
    entity: Entity | Director
}

const KycPopover: FC<React.PropsWithChildren<Props>> = ({ entity, children }) => {
    const hasEKyc = hasKycCheck(entity, 'eKyc')
    const hasIdv = hasKycCheck(entity, 'idv')
    const hasPepSis = hasKycCheck(entity, 'pep/sis')
    const showPopover = hasEKyc || hasIdv || hasPepSis

    return showPopover ? (
        <Popover
            placement="left"
            trigger="hover"
            content={
                <div>
                    {hasEKyc && (
                        <span>
                            eKYC: <PlusOutlined />{' '}
                        </span>
                    )}
                    {hasIdv && (
                        <span>
                            IDV: <PlusOutlined />{' '}
                        </span>
                    )}
                    {hasPepSis && (
                        <span>
                            PEP/SIS: <PlusOutlined />{' '}
                        </span>
                    )}
                </div>
            }
        >
            <span>{children}</span>
        </Popover>
    ) : (
        <>{children}</>
    )
}

export default KycPopover
