import { useState } from 'react'
import { Table, Card, Button, message, Space, Modal } from 'antd'
import dayjs, { Dayjs } from 'packages/dayjs'
import type { ColumnProps } from 'antd/lib/table'
import type { TransactionExportObject } from '@pleo-io/deimos'

import { PageContentLayout } from 'components/layout-containers'
import { useTransactionsExport } from 'services/deimos/transactions'
import { isFuture } from 'utils/dayjs'
import { DayjsDatePicker } from 'packages/form/date-picker'

const FILE_PREFIX = '_transactions-export/'

interface Props {
    onCreateExport: (to: string, from: string) => Promise<void>
}

const CreateExportConfirmationContent = () => (
    <div>
        <p>Creating transaction exports can take up to 15mins.</p>
        <br />
        <p>
            It is resource intensive, so you should exercise caution when initiating exports and
            refrain from doing it more than necessary.
        </p>
        <br />
        <p>If your export does not appear after 15mins, please contact #team-atlas.</p>
    </div>
)

const CreateExport = ({ onCreateExport }: Props) => {
    const [exportDate, setExportDate] = useState<{ to: string; from: string } | null>(null)

    const onDateChange = (date: Dayjs | null) => {
        if (dayjs.isDayjs(date)) {
            const startOfMonth = date.utc().startOf('month').toISOString()
            const endOfMonth = date.utc().endOf('month').toISOString()

            setExportDate({ from: startOfMonth, to: endOfMonth })
        } else {
            setExportDate(null)
        }
    }

    const handleCreateExport = () => {
        if (!exportDate) {
            return
        }

        Modal.confirm({
            onOk: async () => {
                const { to, from } = exportDate
                try {
                    await onCreateExport(to, from)
                    message.success('Successfully initiated export.')
                } catch (e) {
                    message.error(`Failed to create export: ${(e as Error).message}`)
                }
            },
            centered: true,
            title: 'Are you sure you want to export for this period?',
            content: <CreateExportConfirmationContent />,
            okButtonProps: {
                danger: true,
                disabled: false,
                htmlType: 'submit',
            },
        })
    }

    return (
        <Space>
            <DayjsDatePicker disabledDate={isFuture} onChange={onDateChange} picker="month" />
            <Button onClick={handleCreateExport} disabled={!exportDate} type="primary">
                Create export
            </Button>
        </Space>
    )
}

const TransactionExports = () => {
    const { data, mutations } = useTransactionsExport()

    const onDownloadFile = async (key?: string) => {
        if (!key) {
            return
        }

        try {
            const url = await mutations.downloadFile(key)
            window.open(url)
        } catch (e) {
            message.error(`Failed to download: ${(e as Error).message}`)
        }
    }

    const columns: ColumnProps<TransactionExportObject>[] = [
        { title: 'Name', dataIndex: 'key', render: (key?: string) => key?.split(FILE_PREFIX)[1] },
        {
            title: 'Last modified',
            dataIndex: 'lastModified',
            defaultSortOrder: 'descend',
            sorter: (a, b) => dayjs(a.lastModified).valueOf() - dayjs(b.lastModified).valueOf(),
            render: (lastModified?: string) => (lastModified ? dayjs(lastModified).fromNow() : '-'),
        },
        {
            title: 'Actions',
            dataIndex: 'key',
            render: (key?: string) => (
                <Button onClick={() => onDownloadFile(key)} type="link">
                    Download
                </Button>
            ),
        },
    ]
    return (
        <PageContentLayout>
            <Card title={<CreateExport onCreateExport={mutations.createExport} />}>
                <Table columns={columns} rowKey="key" loading={!data} dataSource={data} />
            </Card>
        </PageContentLayout>
    )
}

export default TransactionExports
