import { countries } from 'countries-list'
import useSWR from 'swr'
import { fetcher } from './request'
import type { ErrorResponse } from 'types/api-error'

type CountriesOfManufacturing = keyof typeof countries

export const useCountriesOfManufacturing = (deimosCompanyId?: string) => {
    const response = useSWR<{ countryCodes: CountriesOfManufacturing[] }, ErrorResponse>(
        deimosCompanyId
            ? `rest/v2/styx-companies/${deimosCompanyId}/manufacturing-countries`
            : null,
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        }
    )

    return response.data?.countryCodes.map((c) => countries[c])
}
