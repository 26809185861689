import type {
    OwnershipStructureGraph,
    ShareholderNode,
    SimplifiedCompanyEntity,
    SimplifiedPersonEntity,
    SimplifiedUnknownEntity,
} from 'types/styx'
import dayjs from 'packages/dayjs'

export const shortenName = (name: string): string => {
    if (name.length > 25) {
        return name.substring(0, 25) + '...'
    }
    return name
}

const buildLabel = (min: number | undefined, exact: number | undefined): String => {
    if (min === undefined && exact === undefined) return 'N/A'
    else if (min === undefined) return `${exact}%`
    else if (min !== exact) return `${min}-${exact}%`
    else return `${exact}%`
}

export const percentageLabel = (shareholderNode: ShareholderNode): string => {
    const { minSharePercentage, sharePercentage, minVotingRights, votingRights } = shareholderNode

    const shareLabel = buildLabel(minSharePercentage, sharePercentage)
    const votingLabel = buildLabel(minVotingRights, votingRights)
    return `s:${shareLabel}\nv:${votingLabel}`
}

export const createCompanyGraph = (
    structure: ShareholderNode[],
    companyId: string,
    level = 1,
    inData: OwnershipStructureGraph = { nodes: [], edges: [], hasLoops: false }
): OwnershipStructureGraph => {
    const formatDate = (date?: string) => (date ? dayjs.utc(date).local().format('lll') : '-')
    const data: OwnershipStructureGraph = {
        nodes: inData.nodes,
        edges: inData.edges,
        hasLoops: false,
    }

    // Check if we already have data for this company
    if (data.nodes.find((n) => n.id === companyId)) {
        return data
    }

    const companyShareholder = structure.find((node) => node.entity.id === companyId)!
    const companyShareholderEntity = companyShareholder.entity as SimplifiedCompanyEntity
    const companyShareholderSource =
        companyShareholder.source === 'DUE_DILIGENCE_AGENT_INPUT'
            ? 'DD_AGENT'
            : companyShareholder.source || '-'

    data.nodes.push({
        level,
        type: 'COMPANY',
        id: companyShareholderEntity.id,
        country: companyShareholderEntity.address.country,
        name: companyShareholderEntity.legalName,
        label: `<b>${shortenName(
            companyShareholderEntity.legalName
                .replaceAll('(', '（')
                .replaceAll(')', '）')
                .replaceAll('&', '＆')
                .toUpperCase()
        )}</b> \n <i>Source: ${companyShareholderSource}</i> \n <i>Created at: ${formatDate(
            companyShareholder.auditingTime
        )}</i>`,
        group: level === 1 ? 'root' : 'company',
    })

    const shareholders = structure.filter(
        (node) => node.parentEntityId === companyShareholderEntity.id
    )

    for (const shareholder of shareholders) {
        // We do not show beneficial owners in the graph
        if (shareholder.shareholderCategory === 'BENEFICIAL_OWNER') {
            continue
        }

        data.edges.push({
            from: shareholder.entity.id,
            to: companyId,
            arrows: 'from',
            label: percentageLabel(shareholder),
        })

        // We don't need to process entities that have already been processed
        if (data.nodes.find((n) => n.id === shareholder.entity.id)) {
            continue
        }
        const source =
            shareholder.source === 'DUE_DILIGENCE_AGENT_INPUT'
                ? 'DD_AGENT'
                : shareholder.source || '-'
        let node
        if (shareholder.legalType === 'COMPANY') {
            const newData = createCompanyGraph(structure, shareholder.entity.id, level + 1, data)
            data.nodes = newData.nodes
            data.edges = newData.edges
        } else if (shareholder.legalType === 'PERSON') {
            const person = shareholder.entity as SimplifiedPersonEntity
            const name = [person.name.firstName, person.name.middleName, person.name.lastName]
                .join(' ')
                .replace(/\s{2}/g, ' ')
            node = {
                level: level + 1,
                id: shareholder.entity.id,
                type: shareholder.legalType,
                name,
                label: `<b>${shortenName(
                    name
                ).toUpperCase()}</b> \n <i>Source: ${source}</i> \n <i>Created at: ${formatDate(
                    shareholder.auditingTime
                )}</i>`,
                country: person.address?.country,
                group: 'person',
            }
        } else if (shareholder.legalType === 'UNKNOWN') {
            const unknown = shareholder.entity as SimplifiedUnknownEntity
            node = {
                level: level + 1,
                id: shareholder.entity.id,
                type: shareholder.legalType,
                name: unknown.name || shareholder.legalType,
                label: `<b>${
                    unknown.name.toUpperCase() || shareholder.legalType.toUpperCase()
                }</b> \n <i>Source: ${source}</i> \n <i>Created at: ${formatDate(
                    shareholder.auditingTime
                )}</i>`,
                group: 'unknown',
                country: '',
            }
        } else {
            node = {
                level: level + 1,
                id: shareholder.entity.id,
                type: shareholder.legalType,
                name: shareholder.legalType,
                label: `<b>${
                    shareholder.legalType
                }</b> \n <i>Source: ${source}</i> \n <i>Created at: ${formatDate(
                    shareholder.auditingTime
                )}</i>`,
                group: 'unknown',
                country: '',
            }
        }
        node && data.nodes.push(node)
    }

    return data
}
