import { ExceptionOutlined } from '@ant-design/icons'
import { Button, Modal, Space, Table } from 'antd'
import type { DirectDebitAgreementModel } from 'bff/moons/generated/cupid-v1'
import type { FC } from 'react'
import { cashFlowBff } from '../bff'

interface Props {
    companyId: string
    data: DirectDebitAgreementModel[]
    reloadData: () => {}
}

const DirectDebitAgreements: FC<Props> = ({ data, companyId, reloadData }) => {
    const { mutate: cancelDirectDebitAgreementMutation } =
        cashFlowBff.cancelDirectDebitAgreement.useMutation({
            onSuccess: () => reloadData(),
        })
    const cancelDirectDebitAgreementConfirmation = () => {
        Modal.confirm({
            title: '!!! This action will cancel only the first found active DD agreement, which might not be the selected one. Do you want to continue?',
            icon: <ExceptionOutlined />,
            onOk() {
                cancelDirectDebitAgreementMutation({ companyId })
            },
            onCancel() {},
        })
    }

    const columns = [
        {
            title: 'Reference',
            dataIndex: 'reference',
            key: 'reference',
        },
        {
            title: 'Bank Identifier Number',
            dataIndex: 'bankIdentifierNumber',
            key: 'bankIdentifierNumber',
        },
        {
            title: 'Bank Account Number',
            dataIndex: 'bankAccountNumber',
            key: 'bankAccountNumber',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'First Disposal Date',
            dataIndex: 'firstDisposalDate',
            key: 'firstDisposalDate',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, record: DirectDebitAgreementModel) => (
                <Space size="middle">
                    {record.status === 'READY' && (
                        <Button type="link" onClick={cancelDirectDebitAgreementConfirmation}>
                            Cancel
                        </Button>
                    )}
                </Space>
            ),
        },
    ]

    return <Table dataSource={data} columns={columns} pagination={false} size="small" bordered />
}

export default DirectDebitAgreements
