import React from 'react'
import { invariant } from 'utils/invariant'

import type { CreateMarketplaceAppResponse } from '../../bff/types.bff'
import type { Result } from '../../fp/result'
import type { PersistedApp } from '../models'
import type { CreateAppFormValues } from '../models/create-app-form'
import type { EditAppFormValues } from '../models/edit-app-form'

export type AppMarketplaceContext = {
    readonly apps: PersistedApp[]
    readonly isLoading: boolean
    readonly isCreating: boolean
    readonly isUpdating: boolean
}

export type AppMarketplaceAPIContext = {
    createApp: (input: CreateAppFormValues) => Promise<CreateMarketplaceAppResponse | undefined>
    updateApp: (input: EditAppFormValues) => Promise<Result<string, {}>> // FIXME: Change to App
    setSearchTerm: (searchTerm: string) => void
}

export const AppMarketplaceContext = React.createContext<AppMarketplaceContext>(undefined as any)
AppMarketplaceContext.displayName = 'AppMarketplaceContext'

export const useAppMarketplaceContext = () => {
    const context = React.useContext(AppMarketplaceContext)
    invariant(context, 'useAppMarketplaceContext must be used within a AppMarketplaceProvider')

    return context
}

export const AppMarketplaceAPIContext = React.createContext<AppMarketplaceAPIContext>(
    undefined as any
)
AppMarketplaceAPIContext.displayName = 'AppMarketplaceAPIContext'

export const useAppMarketplaceAPIContext = () => {
    const context = React.useContext(AppMarketplaceAPIContext)
    invariant(
        context,
        'useAppMarketplaceAPIContext must be used within a AppMarketplaceAPIProvider'
    )

    return context
}
