import type { FC } from 'react'

import UserDropdown from 'components/user-dropdown/user-dropdown'

import styled from 'styled-components'
import { fontSize } from 'theme/tokens'
import { Typography } from 'antd'

const { Text } = Typography

interface Props {
    title: string
}

const PageHeader: FC<React.PropsWithChildren<Props>> = ({ children, title }) => {
    return (
        <>
            <Title strong>{title}</Title>
            <CenterAlign>{children}</CenterAlign>
            <RightAlign>
                <UserDropdown />
            </RightAlign>
        </>
    )
}

const Title = styled(Text)`
    font-size: ${fontSize.size16};
`

const CenterAlign = styled.div`
    justify-self: center;
`

const RightAlign = styled.div`
    justify-self: end;
`

export default PageHeader
