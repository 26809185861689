import type { ErrorResponse } from '@pleo-io/deimos'
import { Button, Result } from 'antd'
import { deSnakify } from 'utils/strings'

interface CompanyErrorResultProps {
    error: ErrorResponse
    goBack: () => void
}

const CompanyErrorResult = ({ error, goBack }: CompanyErrorResultProps) => {
    if (error.status === 404) {
        return (
            <Result
                status="404"
                title="Company not found"
                extra={<Button onClick={goBack}>Go back</Button>}
            />
        )
    } else {
        return (
            <Result
                status="500"
                title="Something went wrong"
                subTitle={error.message && deSnakify(error.message)}
                extra={<Button onClick={goBack}>Go back</Button>}
            />
        )
    }
}

export default CompanyErrorResult
