import { TeamOutlined } from '@ant-design/icons'
import { Card, Modal, Typography } from 'antd'
import { TeamMembers } from 'components/review-team'

import styled from 'styled-components'
import { GetTeam, useCompanyTeams } from '../api/use-teams'
import { type FC, useState } from 'react'

const { Text, Link: AntdLink } = Typography

const TeamWrapper = styled.div`
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-flow: row nowrap;

    & a {
        min-width: 5rem;
    }
`

const Team: FC<{
    team: GetTeam
    onTeamModal: (teamId: GetTeam) => void
}> = ({ team, onTeamModal }) => {
    return (
        <TeamWrapper>
            <Text>
                <Text>{`${team.name}`}</Text>
            </Text>
            <AntdLink onClick={() => onTeamModal(team)}>
                <TeamOutlined /> Members
            </AntdLink>
        </TeamWrapper>
    )
}

export const CompanyTeams: FC<{ companyId?: string }> = ({ companyId }) => {
    const { teams, error } = useCompanyTeams(companyId)
    const [isTeamModalOpen, setIsTeamModalOpen] = useState(false)
    const [currentTeam, setCurrentTeam] = useState<GetTeam | null>(null)

    const loading = !teams && !error

    const onTeamOpen = (team: GetTeam) => {
        setCurrentTeam(team)
        setIsTeamModalOpen(true)
    }

    return (
        <Card
            type="inner"
            title="Teams"
            loading={loading}
            bodyStyle={{ padding: 0 }}
            style={{ marginBottom: '24px' }}
        >
            {teams?.data.map((team) => (
                <Team key={team.id} team={team} onTeamModal={onTeamOpen} />
            ))}
            {!teams ||
                (teams.data.length === 0 && (
                    <Text style={{ padding: '16px 24px', display: 'inline-block' }}>
                        No teams found for this company.
                    </Text>
                ))}

            <Modal
                open={isTeamModalOpen}
                onCancel={() => setIsTeamModalOpen(false)}
                title={
                    <>
                        {currentTeam?.name}
                        <br />
                        <Text type="secondary" style={{ fontSize: '0.8rem', fontWeight: 'normal' }}>
                            Department ID:
                            <Text copyable code>
                                {currentTeam?.id}
                            </Text>
                        </Text>
                    </>
                }
                footer={null}
                bodyStyle={{ padding: 0 }}
                width={1000}
                centered
            >
                {currentTeam && <TeamMembers team={currentTeam} />}
            </Modal>
        </Card>
    )
}
