import type {
    CreatePersonRequest,
    DocumentRequest,
    Entity,
    UpdatePersonRequest,
    UpdatePersonResponse,
    Document,
    IdvCheck,
    KycCheck,
    PepCheck,
    SisCheck,
    EkycCheck,
    DocumentFile,
    DocumentFileRequest,
} from 'types/styx'
import styxRequest from './request'

export const createPerson = (body: CreatePersonRequest): Promise<Entity> =>
    styxRequest()
        .post('rest/v1/persons/', {
            json: body,
        })
        .json()

export const updatePerson = (
    subjectId: string,
    body: UpdatePersonRequest
): Promise<UpdatePersonResponse> =>
    styxRequest()
        .patch(`rest/v1/persons/${subjectId}`, {
            json: body,
        })
        .json()

// A document is a collection of files
export const createPersonDocument = (subjectId: string, body: DocumentRequest): Promise<Document> =>
    styxRequest()
        .post(`rest/v1/persons/${subjectId}/documents`, {
            json: { ...body, isHidden: body.hidden },
        })
        .json()

export const deletePersonDocument = (subjectId: string, documentId: string) =>
    styxRequest().delete(`rest/v1/persons/${subjectId}/documents/${documentId}`).json()

export const updatePersonDocument = (
    subjectId: string,
    documentId: string,
    body: DocumentRequest
): Promise<Document> =>
    styxRequest()
        .patch(`rest/v1/persons/${subjectId}/documents/${documentId}`, {
            json: { ...body, isHidden: body.hidden },
        })
        .json()

interface GetFileLinkRequest {
    subjectId: string
    documentId: string
    fileId: string
    snapshot?: string
    download?: boolean
}

export const getLinkToPersonDocumentFile = ({
    subjectId,
    documentId,
    fileId,
    snapshot,
    download = false,
}: GetFileLinkRequest) =>
    styxRequest().get(`rest/v1/persons/${subjectId}/documents/${documentId}/files/${fileId}/link`, {
        searchParams: snapshot ? { snapshot } : {},
        headers: { 'Content-Disposition': download ? 'attachment' : 'inline' },
    })

export const createPersonIdvCheck = (subjectId: string): Promise<IdvCheck> =>
    styxRequest().post(`rest/v1/persons/${subjectId}/idvchecks`).json()

export const resumePersonIdvCheck = (subjectId: string, checkId: string): Promise<IdvCheck> =>
    styxRequest().post(`rest/v1/persons/${subjectId}/idvchecks/${checkId}`).json()

export const updatePersonIdvCheck = (
    subjectId: string,
    checkId: string,
    result: KycCheck['result']
): Promise<IdvCheck> =>
    styxRequest()
        .patch(`rest/v1/persons/${subjectId}/idvchecks/${checkId}`, {
            json: { result },
        })
        .json()

export const createPersonPepSisScreening = (subjectId: string): Promise<{ checks: KycCheck[] }> =>
    styxRequest().post(`rest/v1/persons/${subjectId}/screening`).json()

export const createPersonEkycCheck = (subjectId: string): Promise<EkycCheck> =>
    styxRequest().post(`rest/v1/persons/${subjectId}/ekyc/checks`).json()

export const expireEkycCheck = (subjectId: string, checkId: string): Promise<EkycCheck> =>
    styxRequest()
        .patch(`rest/v1/persons/${subjectId}/ekyc/checks/${checkId}`, {
            json: { expiresAt: new Date().toISOString() },
        })
        .json()

export const createPersonDocumentFile = (
    subjectId: string,
    documentId: string,
    file: FormData
): Promise<DocumentFile> =>
    styxRequest()
        .post(`rest/v1/persons/${subjectId}/documents/${documentId}/files`, {
            body: file,
        })
        .json()

export const deletePersonDocumentFile = (subjectId: string, documentId: string, fileId: string) =>
    styxRequest()
        .delete(`rest/v1/persons/${subjectId}/documents/${documentId}/files/${fileId}`)
        .json()

export const editPersonDocumentFile = (
    subjectId: string,
    documentId: string,
    fileId: string,
    documentFileRequest: DocumentFileRequest
) =>
    styxRequest()
        .put(`rest/v1/persons/${subjectId}/documents/${documentId}/files/${fileId}`, {
            json: documentFileRequest,
        })
        .json()

export const getPersonPepChecks = (subjectId: string): Promise<PepCheck[]> =>
    styxRequest().get(`rest/v1/persons/${subjectId}/pepchecks`).json()

export const getPersonSisChecks = (subjectId: string): Promise<SisCheck[]> =>
    styxRequest().get(`rest/v1/persons/${subjectId}/sischecks`).json()

export const setKycScope = (personId: string, inScope: boolean) =>
    styxRequest()
        .patch(`rest/v1/persons/${personId}`, {
            json: { kycPerson: inScope },
        })
        .json()
