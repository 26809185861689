import { Button, Card } from 'antd'
import FilterByCompany from 'pages/customer-success/shared/filter-by-company'
import React, { FC, useState } from 'react'
import type { ModalActionType, OrderFilters } from 'store/modules/support/card-orders/types'
import * as s from './card-orders.styles'
import CustomCardOrder from './custom-order/custom-order-form'
import FilterByDate from './filters/filter-by-date'
import CardOrdersModal from './modals/card-orders-modal'
import CardOrdersTable from './tables/card-orders-table'
import { initialFilters } from './utils'
import { ContentContainer, PageContentLayout } from 'components/layout-containers'

const CardOrders: FC<React.PropsWithChildren<unknown>> = () => {
    const [customOrderActive, setCustomOrderActive] = useState(false)
    const [actionActive, setActionActive] = useState<ModalActionType | null>(null)
    const [filters, setFilters] = useState<OrderFilters>(initialFilters)

    return (
        <PageContentLayout>
            <Card>
                <s.ButtonContainer>
                    <Button type="primary" onClick={() => setCustomOrderActive(true)}>
                        Create order
                    </Button>
                </s.ButtonContainer>
            </Card>
            {customOrderActive && <CustomCardOrder setCustomOrderActive={setCustomOrderActive} />}
            <CardOrdersModal actionActive={actionActive} closeModal={() => setActionActive(null)} />
            <Card>
                <ContentContainer>
                    <s.FiltersContainer>
                        <FilterByDate filters={filters} setFilters={setFilters} />
                        <FilterByCompany
                            onCompanyChange={(companyId) => setFilters({ ...filters, companyId })}
                        />
                    </s.FiltersContainer>
                    <CardOrdersTable filters={filters} setFilters={setFilters} />
                </ContentContainer>
            </Card>
        </PageContentLayout>
    )
}

export default CardOrders
