import { createContext, ReactNode, useContext } from 'react'
import { useParams } from 'react-router-dom'

interface CompanyContext {
    companyId: string
}

const CompanyContext = createContext<CompanyContext | undefined>(undefined)

export const useCompanyContext = () => {
    const context = useContext(CompanyContext)
    if (!context) {
        throw new Error('useCompanyContext must be used within a CompanyContextProvider')
    }
    return context
}

export const CompanyContextProvider = ({ children }: { children: ReactNode }) => {
    const { id: companyId } = useParams()

    return (
        <CompanyContext.Provider
            value={{
                companyId,
            }}
        >
            {children}
        </CompanyContext.Provider>
    )
}
