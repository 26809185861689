import { Button, Space } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import dayjs from 'packages/dayjs'
import { DayjsDatePicker } from 'packages/form/date-picker'
import { bff } from '../../bff'

const dateFormat = 'YYYY-MM-DDTHH:mm:ss'
interface InitValues {
    startDate: string | null
    endDate: string | null
}

const defaultValues = { startDate: null, endDate: null }
interface Props {
    companyId: string
    setError: (error: string) => void
    setSuccess: (success: string) => void
    setTitle: (title: string) => void
    initialValues?: InitValues
}

export const XeroBankfeed: FC<React.PropsWithChildren<Props>> = ({
    companyId,
    setError,
    setTitle,
    setSuccess,
    initialValues = defaultValues,
}) => {
    const [startDate, setStartDate] = useState<string | null>(initialValues.startDate)
    const [endDate, setEndDate] = useState<string | null>(initialValues.endDate)
    const { mutateAsync: mutateTriggerXeroBankfeed } = bff.bankfeed.xero.trigger.useMutation()

    useEffect(() => {
        setTitle('Xero bank feed')
    })

    const triggerBankFeed = async () => {
        if (!startDate) {
            setError('No start date was set')
            return
        }
        if (!endDate) {
            setError('No end date was set')
            return
        }
        if (dayjs(endDate).isBefore(startDate, 'day')) {
            setError('Start date has to be before end date')
            return
        }
        if (dayjs(endDate).isSame(startDate, 'day')) {
            setError('Start date and end date cannot be the same')
            return
        }

        if (dayjs(startDate).isBefore(dayjs().subtract(1, 'year'), 'day')) {
            setError('Start date can maximum be one year ago')
            return
        }

        const response = await mutateTriggerXeroBankfeed({
            companyId,
            query: { to: endDate, from: startDate },
        })

        if (response.status === 'CREATED') {
            setSuccess('Triggered xero bank feed successfully')
        } else {
            setError('Something went wrong while trying to trigger xero bank feed')
        }
    }

    return (
        <Space>
            <DayjsDatePicker
                placeholder="Start date"
                value={startDate ? dayjs(startDate).utc() : undefined}
                onChange={(value) => setStartDate(value?.format(dateFormat) ?? null)}
                data-testid="startDate"
            />
            <DayjsDatePicker
                placeholder="End date"
                value={endDate ? dayjs(endDate).utc() : undefined}
                onChange={(value) => setEndDate(value?.format(dateFormat) ?? null)}
                data-testid="endDate"
            />
            <Button type="primary" onClick={triggerBankFeed} data-testid="submit">
                Trigger bankfeed
            </Button>
        </Space>
    )
}

export default XeroBankfeed
