import type { ErrorResponse } from '@pleo-io/deimos'
import { fetcher, fetcher as styxFetcher } from 'services/styx/request'
import type {
    KycCheck,
    Management,
    Manager,
    ManagerKycCheck,
    OwnershipStructureGraph,
    Person,
    ShareholderAuditEvent,
    UboResponse,
} from 'types/styx'
import { createCompanyGraph } from 'services/styx/ownership-graph-utils'
import useSWR from 'swr'
import { useStyxV2Company } from 'services/styx/company'

const managerKycCheckToKycCheck = (managerCheck: ManagerKycCheck): KycCheck => {
    return {
        id: managerCheck.id,
        created: managerCheck.createdAt,
        updated: managerCheck.updatedAt,
        expiresAt: managerCheck.expiresAt,
        result: managerCheck.result,
        transactionMessage: managerCheck.transactionMessage,
        provider: managerCheck.provider,
        providerId: managerCheck.providerId,
        request: managerCheck.request,
        response: managerCheck.response,
        falsePositive: managerCheck.falsePositive,
        recurringMonitoring: managerCheck.recurringMonitoring,
    }
}

export const newManagementFetcher = (
    deimosCompanyId?: string
): ((arg: unknown) => Promise<Management>) => {
    return async (_key: unknown) => {
        const managers = (await styxFetcher(
            `rest/v2/styx-companies/${deimosCompanyId}/management?includeFileCreationDate=true`
        )) as Manager[]

        const nonMissingManagers = managers.filter((manager) => !manager.missing)

        const foreignManagers = nonMissingManagers
            .filter((manager) => manager.foreign)
            .map<UboResponse>((manager) => {
                return {
                    id: manager.id,
                    name: manager.name,
                    missing: manager.missing!,
                    foreign: manager.foreign!,
                    excluded: manager.excluded!,
                    ownershipPercentage: manager.ownershipPercentage!,
                    ownershipPercentageRange: {
                        upper: manager.ownershipPercentageRange!.upper.toString(),
                        lower: manager.ownershipPercentageRange!.lower.toString(),
                    },
                }
            })

        const personManagers = nonMissingManagers
            .filter((manager) => manager.entityType == 'PERSON')
            .map<Person>((manager) => {
                const verification = manager.verifications?.sort(
                    (a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
                )[0]

                return {
                    rootCompanyId: manager.rootCompanyId,
                    id: manager.id,
                    registryId: manager.registryId,
                    address: manager.address,
                    name: manager.name,
                    dateOfBirth: manager.dateOfBirth,
                    kycNationality: manager.kycNationality,
                    kycCitizenships: manager.kycCitizenships,
                    roles: manager.roles,
                    kycPerson: manager.kycPerson,
                    isAdmin: manager.isAdmin,
                    verifications: manager.verifications?.map((it) => it.id),
                    verification: verification,
                    userId: manager.userId,
                    missing: manager.missing,
                    foreign: manager.foreign,
                    excluded: manager.excluded,
                    documents: manager.documents,
                    nationalId: manager.nationalId,
                    idvChecks: manager.idvChecks.map(managerKycCheckToKycCheck),
                    ekycChecks: manager.ekycChecks.map(managerKycCheckToKycCheck),
                    pepChecks: manager.pepChecks.map(managerKycCheckToKycCheck),
                    enhancedPepChecks: manager.enhancedPepChecks,
                    sisChecks: manager.sisChecks.map(managerKycCheckToKycCheck),
                    position: manager.position,
                    ownershipPercentage: manager.ownershipPercentage,
                }
            })

        return [...personManagers, ...foreignManagers]
    }
}

export const shareholderSharePercentageThreshold = 10
export const shareholdersFetcher = (
    deimosCompanyId?: string
): ((arg: unknown) => Promise<Management>) => {
    return async (_key: unknown) => {
        const shareholders = (await styxFetcher(
            `rest/v2/styx-companies/${deimosCompanyId}/person-shareholders?sharePercentageThreshold=${shareholderSharePercentageThreshold}&includeFileCreationDate=true`
        )) as Manager[]

        return shareholders.map<Person>((shareholder) => {
            const verification = shareholder.verifications?.sort(
                (a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt))
            )[0]

            return {
                rootCompanyId: shareholder.rootCompanyId,
                id: shareholder.id,
                registryId: shareholder.registryId,
                address: shareholder.address,
                name: shareholder.name,
                dateOfBirth: shareholder.dateOfBirth,
                kycNationality: shareholder.kycNationality,
                kycCitizenships: shareholder.kycCitizenships,
                roles: shareholder.roles,
                kycPerson: shareholder.kycPerson,
                isAdmin: shareholder.isAdmin,
                verifications: shareholder.verifications?.map((it) => it.id),
                verification: verification,
                userId: shareholder.userId,
                missing: shareholder.missing,
                foreign: shareholder.foreign,
                excluded: shareholder.excluded,
                documents: shareholder.documents,
                nationalId: shareholder.nationalId,
                idvChecks: shareholder.idvChecks.map(managerKycCheckToKycCheck),
                ekycChecks: shareholder.ekycChecks.map(managerKycCheckToKycCheck),
                pepChecks: shareholder.pepChecks.map(managerKycCheckToKycCheck),
                enhancedPepChecks: shareholder.enhancedPepChecks,
                sisChecks: shareholder.sisChecks.map(managerKycCheckToKycCheck),
                position: shareholder.position,
                ownershipPercentage: shareholder.ownershipPercentage,
            }
        })
    }
}

export function useOwnershipGraph() {
    const { company } = useStyxV2Company()
    return useSWR<OwnershipStructureGraph, ErrorResponse>(
        company?.styxId ? `${company.styxId}/shareholder-structure` : null,
        async (_key) => {
            const structure = await fetcher(
                `rest/v2/styx-companies/${company?.styxId}/shareholder-structure`
            )
            const ownershipStructureGraph = createCompanyGraph(structure, company?.styxId!)

            const graph: Graph = {}
            ownershipStructureGraph.edges.forEach((edge) => {
                if (edge.from && edge.to) {
                    if (graph[edge.from]) {
                        graph[edge.from].children.push(edge.to)
                    } else {
                        graph[edge.from] = { children: [edge.to] }
                    }
                }
            })
            ownershipStructureGraph.hasLoops = anyLoops(graph)

            return ownershipStructureGraph
        },
        { revalidateOnFocus: false, shouldRetryOnError: false }
    )
}

interface Graph {
    [key: string]: {
        children: any[]
    }
}

const hasLoops = (graph: Graph, name: string, path: string[] = []): boolean =>
    path.includes(name)
        ? true
        : (graph?.[name]?.children ?? []).some((c) => hasLoops(graph, c, [...path, name]))

const anyLoops = (graph: Graph): boolean => Object.keys(graph).some((k) => hasLoops(graph, k))

export function useAuditTrail() {
    const { company } = useStyxV2Company()
    return useSWR<ShareholderAuditEvent[], ErrorResponse>(
        company?.id ? `rest/v2/styx-companies/${company?.id}/shareholder-structure/audit` : null,
        fetcher,
        { revalidateOnFocus: false, shouldRetryOnError: false }
    )
}

export function useCompanyShareholders() {
    const { data } = useOwnershipGraph()
    return data?.nodes.filter((node) => node.type === 'COMPANY') ?? []
}

export const companyShareholderSharePercentageThreshold = 25
