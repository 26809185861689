import type { FC } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal, notification, message } from 'antd'

import { deactivateCompany } from 'services/deimos/styx-company/styx-company'

const { confirm } = Modal

const DeactivateCompanyButton: FC<
    React.PropsWithChildren<{ companyId: string; onSuccess: () => void }>
> = ({ companyId, onSuccess }) => {
    const handleCompanyDeactivation = async () => {
        try {
            const res = await deactivateCompany(companyId)
            const parsedRes: {
                success: boolean
                message: string
            } = await res.json()

            if (parsedRes.success) {
                onSuccess()
                message.success(parsedRes.message)
            } else {
                message.warning(parsedRes.message)
            }
        } catch (e) {
            notification.error({
                description: 'Unable to deactivate company',
                message: (e as Error).message,
            })
        }
    }
    return (
        <Button
            danger
            onClick={() =>
                confirm({
                    centered: true,
                    icon: <ExclamationCircleOutlined />,
                    title: 'Are you sure?',
                    content:
                        'This will deactivate (not delete) the company and destroy any cards connected to it.',
                    onOk: handleCompanyDeactivation,
                })
            }
        >
            Deactivate
        </Button>
    )
}

export default DeactivateCompanyButton
