import { Input, Space, Typography } from 'antd'
import { Formik } from 'formik'
import { Form, SubmitButton } from 'formik-antd'
import type { FC } from 'react'
import * as Yup from 'yup'
import { inputWidth } from 'theme/tokens'

const { Title } = Typography

const validationSchema = Yup.object()
    .shape({
        expenseId: Yup.string().required('Expense ID is a required field'),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

const initialValues: FormValues = {
    expenseId: '',
}

export interface UpdateVisibility {
    expenseId: string
    hidden: boolean
}

interface Props {
    fetching: boolean
    updateVisibility: (payload: UpdateVisibility) => void
}

const HideExpense: FC<React.PropsWithChildren<Props>> = ({ fetching, updateVisibility }) => (
    <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
        validateOnMount
    >
        {({ values: { expenseId }, handleSubmit, errors, handleChange }) => {
            const disabled = !!errors.expenseId
            return (
                <Form layout="inline">
                    <Space direction="vertical" size="middle">
                        <Title level={5}>Hide expenses</Title>
                        <Input
                            name="expenseId"
                            placeholder="Expense ID"
                            style={{ width: inputWidth.huge }}
                            onChange={handleChange}
                        />
                        <Space>
                            <SubmitButton
                                type="primary"
                                loading={fetching}
                                onClick={() => {
                                    handleSubmit()
                                    updateVisibility({ expenseId, hidden: false })
                                }}
                                disabled={disabled}
                            >
                                Show
                            </SubmitButton>
                            <SubmitButton
                                type="primary"
                                loading={fetching}
                                onClick={() => {
                                    handleSubmit()
                                    updateVisibility({ expenseId, hidden: true })
                                }}
                                disabled={disabled}
                            >
                                Hide
                            </SubmitButton>
                        </Space>
                    </Space>
                </Form>
            )
        }}
    </Formik>
)

export default HideExpense
