import { AppstoreOutlined } from '@ant-design/icons'
import type { FC } from 'react'
import { Link, Navigate, Route, Routes, useParams } from 'react-router-dom'

import Header from 'components/header/header'
import Page from 'pages/page-layout'
import ProtectedRoute from 'router/protected-route'
import { deKebabify } from 'utils/strings'

import { FallbackForbidden } from 'components/fallback-forbidden'
import { useColorScheme } from 'hooks/use-color-scheme'
import { SideMenu } from 'pages/page-layout-components'
import AppMarketplace from './app-marketplace/screens/app-marketplace'
import { ViewEditApp } from './app-marketplace/screens/view-edit-app'

const Navigation: FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const { colorScheme } = useColorScheme()

    return (
        <SideMenu $colorScheme={colorScheme} selectedKeys={[id]} theme="dark">
            <SideMenu.Item key="app-marketplace">
                <AppstoreOutlined />
                <span>App marketplace</span>
                <Link to="../app-marketplace" />
            </SideMenu.Item>
        </SideMenu>
    )
}

const PageHeader = () => {
    const { id: selectedSidebarItem } = useParams()
    const headerTitle = deKebabify(selectedSidebarItem)
    return <Header title={headerTitle}></Header>
}

const DEFAULT_ROUTE = 'app-marketplace'

const Partnerships = () => {
    return (
        <Page
            sideBarLabel="Partnerships"
            sideBar={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id/*" element={<Navigation />} />
                </Route>
            }
            header={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id/*" element={<PageHeader />} />
                </Route>
            }
        >
            <Route
                element={
                    <ProtectedRoute
                        requiredPermissions={['endymion', 'app-marketplace']}
                        fallback={<FallbackForbidden goBackLink=".." />}
                        allowStrategy="all"
                    />
                }
            >
                <Route path="/" element={<Navigate to={DEFAULT_ROUTE} />} />
                <Route path="app-marketplace/*" element={<AppMarketplaceRouter />} />
            </Route>
        </Page>
    )
}

const AppMarketplaceRouter = () => (
    <Routes>
        <Route element={<ProtectedRoute />}>
            <Route path="/" element={<AppMarketplace />} />
            <Route path="apps/:slug" element={<ViewEditApp />} />
        </Route>
    </Routes>
)

export default Partnerships
