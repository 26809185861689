import React, { useState } from 'react'
import type { FC } from 'react'
import { Select, Button, Row, Col, Typography, Table, Divider, Result } from 'antd'
import type { RiskVersion, RiskCharts } from 'types/customer-risk-rating'
import { useTestRiskVersions } from 'services/themis/customer-risk-rating'
import Spinner from 'components/content-spinner'
import { Chart } from 'react-google-charts'
import { color } from 'theme/tokens'
import type { ErrorResponse } from '@pleo-io/deimos'

const { Option } = Select
const { Text } = Typography
const sampleSetId = '57245594-e804-4cf9-8fa8-36f35a5cf63a' // Only one that exists at this stage

export const CrrCompareVersions: FC<React.PropsWithChildren<{ allVersions: RiskVersion[] }>> = ({
    allVersions,
}) => {
    const [showComparisonTable, setShowComparisonTable] = useState(false)
    const [selectedVersions, setSelectedVersions] = useState<RiskVersion[]>([])
    const { data, error, isValidating, mutate } = useTestRiskVersions(selectedVersions, [
        sampleSetId,
    ])

    function generateTable() {
        if (selectedVersions.length) {
            setShowComparisonTable(true)
            mutate()
        }
    }

    return (
        <>
            <Row gutter={[24, 12]}>
                <Col span={19}>
                    <Text>Select versions to compare</Text>
                </Col>
            </Row>
            <Row gutter={[24, 24]}>
                <Col span={19}>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        maxTagCount={7}
                        onChange={(value: string) =>
                            setSelectedVersions([...value].map((item) => JSON.parse(String(item))))
                        }
                        placeholder="Click to select versions"
                    >
                        {allVersions.map((item) => (
                            <Option
                                key={item.id}
                                value={JSON.stringify({
                                    id: item.id,
                                    description: item.description,
                                })}
                            >
                                {item.description}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={5}>
                    <Button type="primary" onClick={generateTable} block>
                        Compare scores
                    </Button>
                </Col>
            </Row>
            {showComparisonTable && (
                <ComparisonTable
                    riskScoreTable={data.tables.riskScore}
                    selectedVersions={selectedVersions}
                    isValidating={isValidating}
                    charts={data.charts}
                    error={error}
                />
            )}
        </>
    )
}

export const ComparisonTable: FC<
    React.PropsWithChildren<{
        riskScoreTable: any
        selectedVersions: RiskVersion[]
        isValidating: boolean
        charts?: RiskCharts
        error?: ErrorResponse
    }>
> = ({ riskScoreTable, selectedVersions, isValidating, charts, error }) => {
    const chartOptions = {
        legend: { position: 'top' },
        colors: [
            color.blue,
            color.red,
            color.yellow,
            color.green,
            color.purple,
            color.cyan,
            color.magenta,
            color.gray,
            color.gold,
            color.volcano,
        ],
    }

    const columns = selectedVersions.map((version) => ({
        title: version.description,
        dataIndex: version.id,
    }))

    if (error) {
        return <Result title="Error retrieving data" subTitle={error.message} />
    }

    if (isValidating) {
        return <Spinner />
    }

    return (
        <>
            <Table columns={columns} dataSource={riskScoreTable} rowKey="companyId" />
            <Divider />
            {charts?.scores && (
                <Chart chartType="Bar" data={charts.scores} options={chartOptions} />
            )}
            <Divider />
            {charts?.versions && (
                <Chart chartType="Bar" data={charts.versions} options={chartOptions} />
            )}
        </>
    )
}
