import type { FC } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Input, Form } from 'formik-antd'
import { Button } from 'antd'
import { spacing } from 'theme/tokens'

const validationSchema = Yup.object()
    .shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        middleName: Yup.string(),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface Props {
    loading: boolean
    onSubmit: (values: FormValues) => void
}

const NameFilterForm: FC<React.PropsWithChildren<Props>> = ({ onSubmit, loading }) => {
    return (
        <Formik validationSchema={validationSchema} initialValues={{}} onSubmit={onSubmit}>
            {({ resetForm }) => (
                <Form>
                    <Form.Item name="firstName" label="First name">
                        <Input
                            name="firstName"
                            placeholder="First name"
                            data-testid="firstname-input"
                        />
                    </Form.Item>
                    <Form.Item name="middleName" label="Middle name">
                        <Input
                            name="middleName"
                            placeholder="Middle name"
                            data-testid="middlename-input"
                        />
                    </Form.Item>
                    <Form.Item name="lastName" label="Last name">
                        <Input
                            name="lastName"
                            placeholder="Last name"
                            data-testid="lastname-input"
                        />
                    </Form.Item>
                    <Row>
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            data-testid="search-button"
                        >
                            Search
                        </Button>
                        <Button
                            type="default"
                            onClick={() => resetForm()}
                            data-testid="clear-button"
                        >
                            Clear
                        </Button>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

const Row = styled.div`
    display: flex;
    gap: ${spacing.space8};
`

export default NameFilterForm
