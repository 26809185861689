import {
    CompanyStep,
    ConfirmStep,
    EmployeesStep,
    ExpensesStep,
    ProfileStep,
    TeamsStep,
} from './steps'
import type { FormValuesProfile } from './types'
import type { CreateDemoAccountFromCompanyProfileRequest } from '@pleo-io/dionysus-ts-models'

export type Step = {
    title: string
    description: string
    Component: () => JSX.Element
    entity?: keyof FormValuesProfile
}

export const steps: Step[] = [
    {
        title: 'Base Profile',
        description: 'Select pre-filled company profile',
        Component: ProfileStep,
        entity: 'meta',
    },
    {
        title: 'Company Information',
        description: 'Basic company information',
        Component: CompanyStep,
        entity: 'company',
    },
    {
        title: 'Employees',
        description: 'Control employee settings',
        Component: EmployeesStep,
        entity: 'employees',
    },
    {
        title: 'Teams',
        description: 'Configure teams',
        Component: TeamsStep,
        entity: 'teams',
    },
    {
        title: 'Expenses',
        description: 'Setup expenses',
        Component: ExpensesStep,
        entity: 'expenses',
    },
    {
        title: 'Confirm',
        description: 'Create demo account',
        Component: ConfirmStep,
    },
]

export const isFirstStep = (step: number) => step === 0

export const isLastStep = (step: number) => step === steps.length - 1

// Backend format is XX_YY
// Frontend format is XX-YY
export const parseLanguageCode = (languageCode: string) => languageCode.replace('-', '_')

export const generatePayload = (
    values: FormValuesProfile
): CreateDemoAccountFromCompanyProfileRequest => {
    const { meta, ...profile } = values
    const payload = {
        ...meta,
        companyProfile: profile,
    }
    payload.companyProfile.company.language = parseLanguageCode(
        payload.companyProfile.company.language
    )
    //Typecast due to a type mismatch from the yup validation and the generated type,
    //specifically the company.country is a CountryEnum that the backend uses and
    //is not compatible with the yup equivalent
    return payload as unknown as CreateDemoAccountFromCompanyProfileRequest
}
