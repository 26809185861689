import React, { useCallback, useEffect, useState } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Breadcrumb, Button, Card, Form, Input, Space, Table, Typography } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import { useGetCompany } from 'services/deimos/companies'
import type { Employee } from '@pleo-io/deimos'
import type { ColumnProps } from 'antd/lib/table'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { useEmployees } from '../../api/use-employees'

const { Text } = Typography

const companyColumns: ColumnProps<Employee>[] = [
    {
        title: 'Name',
        dataIndex: 'firstName',
        render: (firstName, row) => <Text>{`${firstName} ${row.lastName}`}</Text>,
    },
    {
        title: 'Email address',
        dataIndex: 'email',
    },
    {
        title: 'Employee ID',
        dataIndex: 'id',
        render: (id) => (
            <Text copyable onClick={(e) => e?.stopPropagation()}>
                {id}
            </Text>
        ),
        align: 'right',
    },
]

const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;

    .ant-pagination {
        margin-right: 16px;
    }

    & .ant-table-tbody tr {
        cursor: pointer;
    }
`

const lowerCaseAndIncludes = (target: string, searchValue: string) =>
    !!target.toLowerCase().includes(searchValue.toLowerCase())

const EmployeeSearch = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const { companyId } = useParams()
    const { data: employees } = useEmployees(companyId)
    const { data: company } = useGetCompany(companyId)
    const [filteredEmployees, setFilteredEmployees] = useState<Employee[]>([])
    const navigate = useNavigate()

    const query = searchParams.get('query') || ''

    const handleSearch = (values: { searchQuery?: string }) => {
        const searchValue = values.searchQuery
        searchParams.set('query', searchValue || '')
        setSearchParams(searchParams)
    }

    const isIdMatch = useCallback((id: string | null) => id && !!id.includes(query), [query])

    const isNameMatch = useCallback(
        (firstName: string, lastName: string) =>
            lowerCaseAndIncludes(firstName, query) ||
            lowerCaseAndIncludes(lastName, query) ||
            lowerCaseAndIncludes(`${firstName.toLowerCase()} ${lastName.toLowerCase()}`, query),
        [query]
    )

    useEffect(() => {
        if (query.length === 0 && employees) {
            setFilteredEmployees(employees)
        }
        if (query && employees) {
            setFilteredEmployees(
                employees.filter(
                    (employee) =>
                        isNameMatch(employee.firstName, employee.lastName) || isIdMatch(employee.id)
                )
            )
        }
    }, [query, employees, isIdMatch, isNameMatch])

    const onRowClick = (mouseEvent: React.MouseEvent, employeeId: Employee['id']) => {
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            window.open(`/customer-success/employee-360/${companyId}/${employeeId}`)
        } else {
            navigate(`/customer-success/employee-360/${companyId}/${employeeId}`)
        }
    }

    return (
        <PageContentLayout>
            <PageHeader
                title={company?.name ? `${company.name}'s employees` : null}
                breadcrumb={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success'}>Customer Success</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success/employee-360'}>Employees</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/customer-success/employee-360/${company?.id}`}>
                                {company?.name}
                            </Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <Card bodyStyle={{ padding: 0 }}>
                <Form
                    layout="vertical"
                    style={{ padding: '16px 16px' }}
                    onFinish={handleSearch}
                    initialValues={{ searchQuery: query }}
                >
                    <Space.Compact>
                        <Form.Item
                            help="Search by first name, last name, full name or employee ID"
                            name="searchQuery"
                            style={{ minWidth: '30rem', maxWidth: '100%' }}
                        >
                            <Input name="searchQuery" autoFocus allowClear />
                        </Form.Item>
                        <Button name="submit" type="primary" htmlType="submit">
                            Search
                        </Button>
                    </Space.Compact>
                </Form>
                <TableStyleProvider>
                    <Table
                        dataSource={filteredEmployees}
                        columns={companyColumns}
                        rowKey="id"
                        onRow={(employee) => ({
                            onClick: (mouseEvent) => onRowClick(mouseEvent, employee?.id),
                        })}
                    />
                </TableStyleProvider>
            </Card>
        </PageContentLayout>
    )
}

export default EmployeeSearch
