import type { PageOrder, Amount } from '@pleo-io/deimos'
import type { CursorRequestParams } from 'types/cursor-pagination'
import type { SupportedCountry } from './countries'
import type { SupportedLanguage } from './supported-language'
import type { Velocity } from './velocity'

interface CustomExpenseDetails {
    merchantName: string
    // Must be the currency of the country of company being created
    currency: string
    amount: number
    date: string
}

interface ExpensePresetDetails {
    country: SupportedCountry
    industry?: string
}

export enum CreateDemoAccountType {
    BASIC = 'Basic',
    EXTENDED = 'Extended',
    PROFILE = 'Profile',
}

export interface GetPrecreatedDemoAccountRequest {
    country: SupportedCountry
    companyName: string
    requesterEmail?: string
}

export interface CreateDemoAccountRequest {
    country: SupportedCountry
    companyName: string
    initialWalletLoadAmount: number
    expenses?: {
        randomExpenseCount?: number
        customExpenseDetails?: CustomExpenseDetails[]
        expensePreset?: ExpensePresetDetails
    }
    memberEmployeesCount?: Number
    language: SupportedLanguage
    requesterEmail?: string
    expiresAt?: string | null
}

export interface CreateBulkDemoAccountRequest {
    country: SupportedCountry
    companyName: string
    requesterEmail: string
    amount: number
}

export type Role = 'OWNER' | 'MEMBER'

export interface DemoAccountModel {
    id: string
    companyId: string
    requesterEmail?: string
    expiresAt?: string
    createdAt: string
    updatedAt: string
    deletedAt?: string
    name: string
}

const sortableProperties = ['CREATED_AT', 'UPDATED_AT', 'EXPIRES_AT'] as const

export type SortableDemoAccountProperty = (typeof sortableProperties)[number]

export type Sorting = [Record<SortableDemoAccountProperty, PageOrder>]

export function toDemoAccountsSorting(property: SortableDemoAccountProperty, pageOrder: PageOrder) {
    return [{ [property]: pageOrder }] as Sorting
}

export type DemoAccountPaginationRequest = CursorRequestParams<SortableDemoAccountProperty>

export interface CompanyResponse {
    id: string
    name: string
    country: SupportedCountry
    velocity?: Velocity
    balance?: Amount
}

export interface EmployeeResponse {
    role: Role
    email: string
    passcode: string
}

interface ExpensesResponse {
    createdExpenseIds: string[]
    totalCreated: number
}
export interface DemoAccountResponse {
    company: CompanyResponse
    employees: EmployeeResponse[]
    expiresAt?: string
    expenses: ExpensesResponse
}

export type CompanyProfileResponse = {
    companyId: string
    firstAdminEmail: string
}
