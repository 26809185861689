import { Button, Card, Checkbox, Space, Table, Typography } from 'antd'
import { useState, type FC, useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

const { Text } = Typography

export const CustomColumn: FC<{ value: string; fixedWidth?: boolean }> = ({
    value,
    fixedWidth,
}) => {
    return (
        <Text
            style={fixedWidth ? { maxWidth: '100px' } : undefined}
            ellipsis
            copyable={value ? true : false}
        >
            {value}
        </Text>
    )
}

interface ColumnType {
    title: string
    dataIndex: string
    visible: boolean
}

interface ToggleColumnsProps<T> {
    allColumns: any[]
    defaultColumns: string[]
    data?: T
    onTableRowClick: (record: any) => void
}

export const ToggleTable = <T extends { data?: any; pagination?: any }>({
    allColumns,
    defaultColumns,
    data,
    onTableRowClick,
}: ToggleColumnsProps<T>) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [visibleColumns, setVisibleColumns] = useState<ColumnType[]>(
        allColumns.map((column: any) => ({
            ...column,
            visible: defaultColumns.includes(column.dataIndex),
        }))
    )

    const handleCheckboxChange = useCallback(
        (dataIndex: string, checked: boolean) => {
            setVisibleColumns(
                visibleColumns.map((col) => {
                    if (col.dataIndex === dataIndex) {
                        return { ...col, visible: checked }
                    }
                    return col
                })
            )
        },
        [visibleColumns]
    )

    const handleShowAllColumns = () => {
        setVisibleColumns(
            visibleColumns.map((col) => {
                return { ...col, visible: true }
            })
        )
    }

    const handleResetToShowDefaultColumns = () => {
        setVisibleColumns(
            visibleColumns.map((col) => {
                return { ...col, visible: defaultColumns.includes(col.dataIndex) }
            })
        )
    }

    const ColumnFilterButtons = () => {
        return (
            <Space>
                <Button size="small" onClick={handleShowAllColumns}>
                    Show all
                </Button>
                <Button size="small" onClick={handleResetToShowDefaultColumns}>
                    Reset to default
                </Button>
            </Space>
        )
    }

    useEffect(() => {
        if (!searchParams.get('limit')) {
            searchParams.set('limit', data?.pagination?.limit || 10)
        }

        if (!searchParams.get('offset')) {
            searchParams.set('offset', data?.pagination?.offset || 0)
        }

        setSearchParams(searchParams)
    }, [data, searchParams, setSearchParams])

    return (
        <>
            <Card title="Columns to display" size="small" extra={<ColumnFilterButtons />}>
                <GridContainer>
                    {visibleColumns.map((column) => (
                        <Checkbox
                            key={column.dataIndex}
                            checked={column.visible}
                            onChange={(e) =>
                                handleCheckboxChange(column.dataIndex, e.target.checked)
                            }
                        >
                            {column.title}
                        </Checkbox>
                    ))}
                </GridContainer>
            </Card>
            <Table
                dataSource={data?.data}
                columns={visibleColumns.filter((col) => col.visible)}
                onRow={(record) => ({
                    onClick: () => onTableRowClick(record),
                    style: { cursor: 'pointer' },
                })}
                pagination={{
                    showSizeChanger: true,
                    total: data?.pagination?.total || 0,
                    pageSize: Number(searchParams.get('limit')) || 10,
                    current:
                        Number(searchParams.get('offset')) /
                            (Number(searchParams.get('limit')) || 10) +
                            1 || 1,
                    onChange(page, pageSize) {
                        searchParams.set('offset', String((page - 1) * pageSize))
                        searchParams.set('limit', String(pageSize))
                        setSearchParams(searchParams)
                    },
                }}
            />
        </>
    )
}

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: ${spacing.space16};
    justify-items: start;

    .ant-checkbox-wrapper {
        margin: 0;
        padding: 0;
    }
`
