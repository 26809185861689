import * as yup from 'yup'
import type { AddDirectorPayload, Entity, Role } from 'types/styx'

export const validationSchema = yup
    .object()
    .shape({
        selectedEntities: yup.array().of(yup.string()).required('Required'),
        role: yup.string().required('Required'),
    })
    .required()

export type FormValues = yup.InferType<typeof validationSchema>

export const mapValuesToRequest = (
    values: FormValues,
    entityList: Entity[]
): AddDirectorPayload[] =>
    entityList
        .filter(({ id }) => values.selectedEntities.includes(id))
        .map((entity) => ({
            id: entity.id,
            legalPersonId: entity.id,
            position: values.role as Role,
            type: entity.type,
        }))
