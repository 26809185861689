import * as yup from 'yup'

import { validRegistryIdRegex } from 'utils/supported-country-codes'
import type { CreateCompanyRequest } from 'types/styx'
import { identity, pickBy } from 'ramda'

export const validationSchema = yup
    .object()
    .shape({
        legalName: yup.string().required('Legal name is required'),
        registryId: yup
            .string()
            .matches(
                validRegistryIdRegex,
                'Valid registry ID is composed of the country 2 letter code plus identifier e.g. DK155555'
            ),
        addressLine1: yup.string(),
        addressLine2: yup.string(),
        postalCode: yup.string(),
        locality: yup.string(),
        region: yup.string(),
        country: yup
            .string()
            .matches(/^[A-Z]{2}$/, 'Please use valid country code, e.g. DK')
            .required('Country code is required'),
    })
    .required()

export type FormValues = yup.InferType<typeof validationSchema>

export const mapValuesToRequest = (
    { legalName, registryId, ...rest }: FormValues,
    rootCompanyId: string
): CreateCompanyRequest => ({
    rootCompanyId,
    legalName,
    registryId: registryId || null,
    // removes empty strings since they cause bad request
    address: { ...pickBy(identity, rest) },
})
