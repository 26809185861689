import { FC, useEffect } from 'react'
import { message, Table, Typography } from 'antd'

import type { ColumnProps } from 'antd/lib/table'
import type { DisconnectedPartnerCompany } from 'types/partner-portal'
import { useGetDisconnectedPartnerClients } from 'services/kerberos/partners'
import dayjs from 'packages/dayjs'

import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { withPermissionGuard } from 'components/permission-guard/permission-guard'
import { bff } from 'pages/customer-success/company-360/bff'
const { Text } = Typography

const DEFAULT_ROW_COUNT = 10

interface Props {
    partnerId: string
}

const Company: FC<{ companyId: string }> = ({ companyId }) => {
    const { data: companyByIdData } = bff.clientsRouter.getCompanyById.useQuery({ companyId })
    return <Text>{companyByIdData?.data.name}</Text>
}

export const DisconnectedPartnerClients: FC<Props> = ({ partnerId }) => {
    const {
        data: clientsData,
        error,
        isValidating,
    } = useGetDisconnectedPartnerClients(partnerId) || []

    useEffect(() => {
        if (error) {
            message.error(error.message)
        }
    }, [error])

    const needsPagination = clientsData && clientsData.length > DEFAULT_ROW_COUNT

    const columns: ColumnProps<DisconnectedPartnerCompany>[] = [
        {
            title: 'Company ID',
            dataIndex: 'companyId',
            render: (companyId) => <Text copyable>{companyId}</Text>,
            width: '35%',
        },
        {
            title: 'Company name',
            dataIndex: 'companyId',
            render: (id: string) => <Company companyId={id} />,
            width: '35%',
        },
        {
            title: 'Access level',
            dataIndex: 'level',
            width: '11%',
        },
        {
            title: 'Disconnected at',
            dataIndex: 'deletedAt',
            render: (deletedAt?: string) => (deletedAt ? dayjs(deletedAt).format('lll') : ''),
        },
    ]
    return (
        <Content>
            <Table
                dataSource={clientsData}
                columns={columns}
                loading={isValidating}
                rowKey="id"
                size="small"
                pagination={needsPagination && { pageSize: DEFAULT_ROW_COUNT }}
            />
        </Content>
    )
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space24};
`

export default withPermissionGuard(DisconnectedPartnerClients, ['company-role'])
