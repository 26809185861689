import { Descriptions, Popover, Row, Typography } from 'antd'
import type { FC } from 'react'
import { color } from 'theme/tokens'
import type { CountryWithRating } from 'types/customer-risk-rating'
import { CountryRiskScore } from 'types/styx'
import { getCitizenshipSourceLabel, type Citizenship } from 'utils/kyc-citizenship'
import { deSnakify } from 'utils/strings'

const { Text } = Typography

export interface CountryWithSource {
    countryCode?: string
    countryName?: string
    nationality?: string
    source?: string
}

interface Props {
    countriesRiskScores?: CountryWithRating[]
    countriesWithSource: CountryWithSource[]
}

export const CountryRiskStatus: FC<React.PropsWithChildren<Props>> = ({
    countriesRiskScores,
    countriesWithSource,
}) => {
    const riskColorCoding = (countryCode?: string) => {
        const countryRating = countriesRiskScores?.find(
            (countryRiskScore) => countryRiskScore.country === countryCode
        )
        switch (countryRating?.rating) {
            case CountryRiskScore.LOW:
                return color.green
            case CountryRiskScore.MEDIUM:
                return color.blue
            case CountryRiskScore.HIGH:
                return color.orange
            case CountryRiskScore.RESTRICTED:
                return color.volcano
            default:
                return undefined
        }
    }

    const popoverContent = (countryCode?: string, countryName?: string, source?: string) => {
        const countryRating = countriesRiskScores?.find(
            (countryRiskScore) => countryRiskScore.country === countryCode
        )
        return (
            <Row style={{ maxWidth: '250px' }}>
                <Descriptions column={1} size="small" data-testid="country-risk-popover-content">
                    <Descriptions.Item label="Country">
                        <span data-testid="country-risk-name">{countryName}</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Risk score">
                        <span data-testid="country-risk-score">
                            {deSnakify(countryRating?.rating || '')}
                        </span>
                    </Descriptions.Item>
                    {source && (
                        <Descriptions.Item label="Source">
                            <span data-testid="country-risk-source">{source}</span>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </Row>
        )
    }

    return (
        <>
            {countriesWithSource.map((countryWithSource, index) => {
                const hasSource = countryWithSource.source !== undefined
                const sourceLabel = getCitizenshipSourceLabel({
                    citizenship: countryWithSource.countryCode,
                    source: countryWithSource.source,
                } as Citizenship)

                const source = hasSource ? sourceLabel : undefined

                return (
                    <Popover
                        key={index}
                        content={popoverContent(
                            countryWithSource.countryCode,
                            countryWithSource.countryName,
                            source
                        )}
                        data-testid="country-risk-popover"
                    >
                        <Text
                            style={{
                                color: riskColorCoding(countryWithSource.countryCode),
                            }}
                            data-testid={`country-risk-text-${countryWithSource.countryCode}`}
                        >
                            {index > 0
                                ? `, ${countryWithSource.nationality || '-'}`
                                : countryWithSource.nationality || '-'}
                        </Text>
                    </Popover>
                )
            })}
        </>
    )
}
