import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Currency } from 'types/currency'
import type * as deimos from 'types/deimos-company'
import { Feature, FeatureFlags, FeatureFlagsEnum } from 'types/feature-flags'
import type { ToggleFeatureFlagPayload } from './types'
import type { CompanyState } from 'store/modules/state'

export const initialiseFeatureFlags = (payload: FeatureFlags) => {
    const featureFlags: FeatureFlags = {}
    Object.keys(FeatureFlagsEnum).forEach((feature) => (featureFlags[feature as Feature] = false))
    if (payload)
        Object.keys(payload).forEach(
            (feature) => (featureFlags[feature as Feature] = payload[feature as Feature])
        )
    return featureFlags
}

export const initialState: CompanyState = {
    company: {
        id: '',
        ownPartnerId: null,
        name: '',
        address: {
            addressLine1: '',
            postalCode: '',
            locality: '',
            country: '',
        },
        balance: {
            current: 0,
            available: 0,
            creditLimit: 0,
        },
        currency: Currency.DKK,
        status: {},
    },
    featureFlags: initialiseFeatureFlags({}),
}

const { actions: generatedActions, reducer } = createSlice({
    name: 'company',
    initialState,
    reducers: {
        resetCompany: (_) => initialState,
        updatedCompany: (state, { payload }: PayloadAction<deimos.AdminCompany>) => {
            state.company = payload
        },
        updatedFeatureFlags: (state, { payload }: PayloadAction<FeatureFlags>) => {
            state.featureFlags = initialiseFeatureFlags(payload)
        },
        setFeatureFlag: (state, { payload }: PayloadAction<ToggleFeatureFlagPayload>) => {
            state.featureFlags[payload.feature as Feature] = payload.active
        },
    },
})

type DeimosId = string
const fetchCompany = createAction<DeimosId>('company/fetchCompany')
const fetchFeatureFlags = createAction<DeimosId>('company/fetchFeatureFlags')
const toggleFeatureFlag = createAction<ToggleFeatureFlagPayload>('company/toggleFeatureFlag')

export const companyReducer = reducer
export const actions = {
    ...generatedActions,
    fetchCompany,
    fetchFeatureFlags,
    toggleFeatureFlag,
}
