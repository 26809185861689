import { useFormikContext } from 'formik'
import type { FormValuesProfile } from '../../types'
import { List } from 'antd'
import ListItem from './list-item'

const EmployeesStep = () => {
    const { values } = useFormikContext<FormValuesProfile>()

    return <List grid={{ column: 1 }} dataSource={values.employees} renderItem={ListItem} />
}

export default EmployeesStep
