import { Empty, Pagination, Space, Tag, Timeline as AntdTimeline, Typography } from 'antd'
import type { TimelineType } from './types'
import { useTimeline } from './use-timeline'

export const Timeline: React.FC<{ id?: string | null; type: TimelineType }> = ({ id, type }) => {
    const { timelineData, pagination } = useTimeline(type, id)

    return (
        <>
            {!timelineData ||
                (timelineData.length === 0 && (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                ))}
            {timelineData && (
                <>
                    <AntdTimeline>
                        {timelineData.map((timelineItem, index) => (
                            <AntdTimeline.Item key={index}>
                                <div>
                                    <Tag style={{ alignSelf: 'flex-start' }}>
                                        {timelineItem.timestamp}
                                    </Tag>
                                    <Space direction="vertical">
                                        {timelineItem.label}
                                        {timelineItem?.description && (
                                            <Typography.Text type="secondary">
                                                {timelineItem.description}
                                            </Typography.Text>
                                        )}
                                    </Space>
                                </div>
                            </AntdTimeline.Item>
                        ))}
                    </AntdTimeline>
                    <Pagination
                        current={pagination.current}
                        total={pagination.count}
                        onChange={pagination.onChange}
                    />
                </>
            )}
        </>
    )
}
