import * as yup from 'yup'

export const validationSchema = yup
    .object()
    .shape({
        selectedDirectors: yup
            .array()
            .min(1, 'Select one or more directors to remove')
            .required('Required'),
    })
    .required()

export type FormValues = yup.InferType<typeof validationSchema>
