import { memo } from 'react'
import { Input } from 'formik-antd'
import type { FormikStructure, TableCellProps } from '../types'
import { FormDataTypes, InputTypes } from '../enums'
import { useField, useFormikContext } from 'formik'
import { constructFieldName } from '../utils'

const inputTypeProps = {
    [InputTypes.Text]: {
        type: 'text',
    },
    [InputTypes.Number]: {
        type: 'number',
        min: 0,
    },
}

const EditableInputCell = ({ editing, dataIndex, record, onFieldUpdate }: TableCellProps) => {
    const { values } = useFormikContext<FormikStructure>()
    const fieldName = constructFieldName(record.key, dataIndex, record.id, FormDataTypes.Limit)
    const [field] = useField(fieldName)
    const limitValue = record[dataIndex]
    const inputType = isNaN(field.value ?? limitValue) ? InputTypes.Text : InputTypes.Number

    const getLimitQualifier = () => {
        if (record.id === 'invoiceFxFees') {
            return '%'
        }
        return 'Users'
    }

    return (
        <td>
            {editing ? (
                <Input
                    name={fieldName}
                    defaultValue={limitValue?.toString()}
                    onChange={({ target: { value } }) => {
                        const updatedValues = {
                            ...values,
                            [fieldName]: value,
                        }
                        onFieldUpdate(fieldName, value, updatedValues, record.type)
                    }}
                    {...inputTypeProps[inputType]}
                />
            ) : (
                limitValue && (
                    <p>
                        {limitValue.toString()} {getLimitQualifier()}{' '}
                    </p>
                )
            )}
        </td>
    )
}

export default memo(EditableInputCell)
