import { Card, notification, message } from 'antd'
import { Formik, Form } from 'formik'
import { SubmitButton } from 'formik-antd'
import * as Yup from 'yup'

import { withPermissionGuard } from 'components/permission-guard/permission-guard'
import { bff } from '../../bff'
import Input from '../../../../components/input/input'
import { decryptPayload, generateKeyPair } from '../../../../utils/crypto'

const validationSchema = Yup.object()
    .shape({
        id: Yup.string().required('Required'),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

export const ViewPan = () => {
    const { mutateAsync: fetchCardPan } = bff.viewPan.getEncryptedCardPan.useMutation()

    const onSubmit = async ({ id }: FormValues) => {
        try {
            const keyPair = await generateKeyPair()
            if (keyPair.privateKey == undefined) {
                message.error('Unable to generate Key pair')
                return
            }

            const encryptedCardPan = await fetchCardPan({
                cardId: id,
                publicKey: keyPair.publicKey,
            })
            const decryptedPan = await decryptPayload(
                encryptedCardPan.encryptedPan,
                keyPair.privateKey
            )

            delete keyPair.privateKey

            await navigator.clipboard.writeText(decryptedPan!)
            message.success('Copied pan to clipboard')
        } catch (e) {
            notification.error({
                description: 'Unable to retrieve pan',
                message: (e as Error).message,
            })
        }
    }

    return (
        <Formik
            initialValues={{ id: '' }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnMount
        >
            {({ errors }) => (
                <Form>
                    <Card
                        title="View card PAN"
                        actions={[
                            <SubmitButton
                                data-testid="submit"
                                type="link"
                                key="get-button"
                                disabled={!!errors.id}
                            >
                                Get
                            </SubmitButton>,
                        ]}
                    >
                        <Input name="id" placeholder="Card ID" testId="id" />
                    </Card>
                </Form>
            )}
        </Formik>
    )
}

export default withPermissionGuard(ViewPan, ['card:pan'])
