import * as Yup from 'yup'

import { isoCountryCodeRegistryIdRegex } from 'utils/supported-country-codes'

export const validationSchema = Yup.object()
    .shape({
        legalName: Yup.string().required(),
        tradingName: Yup.string(),
        registryId: Yup.string()
            .matches(
                isoCountryCodeRegistryIdRegex,
                'Valid registry ID is composed of the country 2 letter code plus identifier e.g. DK155555'
            )
            .required(),
        domainName: Yup.string(),
        addressLine1: Yup.string().required(),
        addressLine2: Yup.string(),
        locality: Yup.string().required(),
        region: Yup.string(),
        postalCode: Yup.string().required(),
        country: Yup.string().required().length(2),
        tradingAddressLine1: Yup.string(),
        tradingAddressLine2: Yup.string(),
        tradingLocality: Yup.string(),
        tradingRegion: Yup.string(),
        tradingPostalCode: Yup.string(),
        tradingCountry: Yup.string().length(2),
        industryCodes: Yup.array().of(Yup.string().required()).default([]),
        companySize: Yup.string(),
        incorporationDate: Yup.string(),
        incorporationType: Yup.string(),
        countriesOfOperation: Yup.array().of(Yup.string()),
        companyBusinessOffering: Yup.array().of(Yup.string().required()).default([]),
    })
    .required()

export type FormValues = Yup.InferType<typeof validationSchema>
