import { memo } from 'react'
import { AddOn, FeatureIcons, Off, On, Scheduled } from './feature-cell.styles'

type FeatureCellProps = {
    isEnabled: boolean
    isAddon: boolean
    isScheduled: boolean
}

const FeatureCell = ({ isEnabled, isAddon, isScheduled }: FeatureCellProps) => (
    <FeatureIcons>
        {isEnabled ? <On /> : <Off />}
        {isAddon && <AddOn />}
        {isScheduled && <Scheduled />}
    </FeatureIcons>
)

export default memo(FeatureCell)
