export enum RegistryBaseUrl {
    DK = 'https://datacvr.virk.dk/data/visenhed?enhedstype=virksomhed&id=',
    UK = 'https://beta.companieshouse.gov.uk/company/',
    IE = 'https://core.cro.ie/',
    DE = 'https://www.handelsregister.de/rp_web/normalesuche.xhtml',
    ES = 'https://librebor.me/borme/busqueda/?page=1&type=all&q=',
    PrefixSE = 'https://www5.infotorg.se/f30/view.htm?count=1&id=F16',
    SuffixSE = '00000&viewFrom=TRL',
    NO = 'https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=',
    FR = 'https://data.inpi.fr/entreprises/',
    AT = 'https://www.firmenbuchgrundbuch.at/abfrageservices/firmenbuch/fn/',
    FI = 'https://virre.prh.fi/novus/home?execution=e1s1',
    NL = 'https://www.kvk.nl/zoeken/?source=all&q=',
    BE = 'https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=',
}
