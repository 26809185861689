import { useNavigate } from 'react-router-dom'
import { Alert, Skeleton, Space, Table, Typography } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import { getEmojiFlag } from 'countries-list'
import styled from 'styled-components'
import dayjs from 'packages/dayjs'

import { bff } from '../bff'
import type { GetCompanyOrganizationModel } from 'bff/moons/generated/oberon-v1'

const { Text } = Typography

const organizationCompanyColumns: ColumnProps<GetCompanyOrganizationModel>[] = [
    {
        title: 'Country',
        dataIndex: 'address',
        render: (address) => (
            <Space>
                <Text>{getEmojiFlag(address.country)}</Text>
                <Text>{address.country}</Text>
            </Space>
        ),
    },
    {
        title: 'Company name',
        dataIndex: 'name',
    },
    {
        title: 'Company ID',
        dataIndex: 'companyId',
        onCell: () => ({ onClick: (e) => e.stopPropagation() }),
        render: (companyId) => <Text copyable>{companyId}</Text>,
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('lll'),
    },
]

const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;

    .ant-pagination {
        margin-right: 16px;
    }

    & .ant-table-tbody tr {
        cursor: pointer;
    }
`

export const OrganizationCompanies = ({ organizationId }: { organizationId?: string | null }) => {
    const navigate = useNavigate()

    const { data, error, isLoading } = bff.organizationCompanies.getOrganizationCompanies.useQuery({
        organizationId,
    })

    const hasCompanies = !error && data?.companies && data.companies.length > 0

    return (
        <>
            {isLoading ? (
                <Skeleton />
            ) : hasCompanies ? (
                <TableStyleProvider>
                    <Table
                        dataSource={data.companies}
                        columns={organizationCompanyColumns}
                        onRow={(company) => ({
                            onClick: () => navigate(`${company?.companyId}`),
                        })}
                    />
                </TableStyleProvider>
            ) : (
                <Space>
                    <Alert message="No companies found" style={{ margin: '16px' }} />
                </Space>
            )}
        </>
    )
}
