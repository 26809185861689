import type { FC } from 'react'
import styled from 'styled-components'
import { Button, Divider } from 'antd'
import { Input, Form, Select } from 'formik-antd'
import { Formik } from 'formik'
import { inputWidth, spacing } from 'theme/tokens'
import DatePicker from 'packages/form/date-picker'

import type { StyxCompany, StyxCompanyV2 } from 'types/styx'
import CountrySelect from 'components/country-select/country-select'

import { validationSchema, FormValues } from './validation-schema'
import IndustryCodesSelect from 'components/industry-codes-select/industry-codes-select'
import CompanySizeSelect from 'components/company-size-select/company-size-select'
import dayjs, { Dayjs } from 'dayjs'
import { useIncorporationTypes } from 'services/styx/incorporation-types'
import Spinner from 'components/content-spinner'

const { Option } = Select

interface Props {
    company: StyxCompany
    companyV2: StyxCompanyV2
    industryCodes: string[]
    companyBusinessOffering: string[]
    immutableIndustryCodes: string[]
    immutableBusinessOfferings: string[]
    businessOfferings: string[]
    onSubmit: (values: FormValues) => void
}

const isFuture = (currentDate: Dayjs) => currentDate.valueOf() > dayjs().valueOf()
const dateFormat = 'YYYY-MM-DD'

export const EditDetailsForm: FC<React.PropsWithChildren<Props>> = ({
    company,
    companyV2,
    industryCodes,
    immutableIndustryCodes,
    onSubmit,
    children,
    businessOfferings,
    immutableBusinessOfferings,
    companyBusinessOffering,
}) => {
    const { data: incorporationTypes } = useIncorporationTypes()

    if (!incorporationTypes) {
        return <Spinner />
    }

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
                legalName: company.legalName,
                registryId: company.registryId,
                domainName: company.domainName,
                addressLine1: company?.address?.addressLine1 || '',
                addressLine2: company?.address?.addressLine2 || '',
                locality: company?.address?.locality || '',
                region: company?.address?.region || '',
                postalCode: company?.address?.postalCode || '',
                country: company?.address?.country || '',
                tradingName: companyV2.tradingName,
                tradingAddressLine1: companyV2.tradingAddress?.addressLine1,
                tradingAddressLine2: companyV2.tradingAddress?.addressLine2,
                tradingLocality: companyV2.tradingAddress?.locality,
                tradingRegion: companyV2.tradingAddress?.region,
                tradingPostalCode: companyV2.tradingAddress?.postalCode,
                tradingCountry: companyV2.tradingAddress?.country,
                industryCodes: industryCodes,
                companySize: companyV2.companySize,
                incorporationDate: companyV2.incorporationDate
                    ? dayjs.utc(companyV2.incorporationDate).format(dateFormat)
                    : undefined,
                incorporationType: companyV2.incorporationType,
                countriesOfOperation: companyV2.countriesOfOperation,
                companyBusinessOffering: companyBusinessOffering,
            }}
        >
            {({ isValid }) => {
                return (
                    <Form>
                        <SectionContainer>
                            <Form.Item
                                name="legalName"
                                label="Company name"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="legalName"
                                    data-testid="legalname"
                                    placeholder="Company name"
                                />
                            </Form.Item>
                            <Form.Item
                                name="registryId"
                                label="Registry ID"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="registryId"
                                    data-testid="registryid"
                                    placeholder="Registry ID"
                                />
                            </Form.Item>
                            <Form.Item
                                name="domainName"
                                label="Domain name"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="domainName"
                                    data-testid="domainname"
                                    placeholder="Domain name"
                                />
                            </Form.Item>
                            <Form.Item
                                name="tradingName"
                                label="Trading name"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="tradingName"
                                    data-testid="tradingname"
                                    placeholder="Trading name"
                                />
                            </Form.Item>
                            <Form.Item
                                name="companySize"
                                label="Number of employees"
                                hasFeedback
                                colon={false}
                            >
                                <CompanySizeSelect
                                    name="companySize"
                                    placeholder="Select the number of employees"
                                    countryCode={company?.address?.country}
                                />
                            </Form.Item>
                            <Form.Item
                                name="incorporationDate"
                                label="Incorporation Date"
                                hasFeedback
                                colon={false}
                            >
                                <DatePicker
                                    name="incorporationDate"
                                    disabledDate={isFuture}
                                    format={dateFormat}
                                    data-testid="incorporation-date-picker"
                                />
                            </Form.Item>
                            <Form.Item
                                name="incorporationType"
                                label="Incorporation Type"
                                hasFeedback
                                colon={false}
                            >
                                <Select
                                    name="incorporationType"
                                    data-testid="incorporationType"
                                    placeholder="Incorporation Type"
                                >
                                    {incorporationTypes.map((type, index) => (
                                        <Option
                                            key={type}
                                            value={type}
                                            data-testid={`incorporationType-option-${index}`}
                                        >
                                            {type}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </SectionContainer>
                        <Divider dashed style={{ marginTop: 0 }} />
                        <SectionContainer>
                            <Form.Item
                                name="addressLine1"
                                label="Address line 1"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="addressLine1"
                                    data-testid="addressline1"
                                    placeholder="Address line 1"
                                />
                            </Form.Item>
                            <Form.Item
                                name="addressLine2"
                                label="Address line 2"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="addressLine2"
                                    data-testid="addressline2"
                                    placeholder="Address line 2"
                                />
                            </Form.Item>
                            <Form.Item name="locality" label="Locality" hasFeedback colon={false}>
                                <StyledInput
                                    name="locality"
                                    data-testid="locality"
                                    placeholder="Locality"
                                />
                            </Form.Item>
                            <Form.Item name="region" label="Region" hasFeedback colon={false}>
                                <StyledInput
                                    name="region"
                                    data-testid="region"
                                    placeholder="Region"
                                />
                            </Form.Item>
                            <Form.Item
                                name="postalCode"
                                label="Postal Code"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="postalCode"
                                    data-testid="postcode"
                                    placeholder="Postal Code"
                                />
                            </Form.Item>
                            <Form.Item name="country" label="Country" hasFeedback colon={false}>
                                <CountrySelect
                                    name="country"
                                    placeholder="Country"
                                    style={{ width: `${inputWidth.large}` }}
                                    showSearch
                                />
                            </Form.Item>
                        </SectionContainer>
                        <Divider dashed style={{ marginTop: 0 }} />
                        <SectionContainer>
                            <Form.Item
                                name="tradingAddressLine1"
                                label="Trading Address line 1"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="tradingAddressLine1"
                                    data-testid="tradingaddressline1"
                                    placeholder="Address line 1"
                                />
                            </Form.Item>
                            <Form.Item
                                name="tradingAddressLine2"
                                label="Trading Address line 2"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="tradingAddressLine2"
                                    data-testid="tradingaddressline2"
                                    placeholder="Address line 2"
                                />
                            </Form.Item>
                            <Form.Item
                                name="tradingLocality"
                                label="Trading Locality"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="tradingLocality"
                                    data-testid="tradinglocality"
                                    placeholder="Locality"
                                />
                            </Form.Item>
                            <Form.Item
                                name="tradingRegion"
                                label="Trading Region"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="tradingRegion"
                                    data-testid="tradingregion"
                                    placeholder="Region"
                                />
                            </Form.Item>
                            <Form.Item
                                name="tradingPostalCode"
                                label="Trading Postal Code"
                                hasFeedback
                                colon={false}
                            >
                                <StyledInput
                                    name="tradingPostalCode"
                                    data-testid="tradingpostcode"
                                    placeholder="Trading Postal Code"
                                />
                            </Form.Item>
                            <Form.Item
                                name="tradingCountry"
                                label="Trading Country"
                                hasFeedback
                                colon={false}
                            >
                                <CountrySelect
                                    name="tradingCountry"
                                    placeholder="Trading Country"
                                    style={{ width: `${inputWidth.large}` }}
                                    showSearch
                                    allowClear
                                />
                            </Form.Item>
                        </SectionContainer>
                        <Divider dashed style={{ marginTop: 0 }} />
                        <Form.Item
                            name="industryCodes"
                            label="Industry Codes"
                            hasFeedback
                            colon={false}
                        >
                            <IndustryCodesSelect
                                name="industryCodes"
                                placeholder="Industry Code"
                                showSearch
                                disabledValues={immutableIndustryCodes}
                                data-testid="industryCodes-select"
                            />
                        </Form.Item>
                        <Form.Item
                            name="countriesOfOperation"
                            label="Countries Of Operation"
                            hasFeedback
                            colon={false}
                        >
                            <CountrySelect
                                name="countriesOfOperation"
                                showSearch
                                allowClear
                                mode="multiple"
                            />
                        </Form.Item>
                        <Form.Item
                            name="companyBusinessOffering"
                            label="Business offering"
                            hasFeedback
                            colon={false}
                        >
                            <Select
                                name="companyBusinessOffering"
                                optionFilterProp="label"
                                mode="multiple"
                                placeholder="Business offering"
                                showSearch
                                data-testid="company-business-offering-select-id"
                            >
                                {businessOfferings.map((item, index) => (
                                    <Select.Option
                                        disabled={immutableBusinessOfferings.includes(item)}
                                        key={item}
                                        value={item}
                                        label={item}
                                        data-testid={`business-offering-${index}-id`}
                                    >
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <ButtonContainer>
                            {children}
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={!isValid}
                                data-testid="submit"
                            >
                                Save
                            </Button>
                        </ButtonContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}

const SectionContainer = styled.div`
    display: grid;
    grid-gap: 0 ${spacing.space24};
    grid-template-columns: 1fr 1fr;
`

const StyledInput = styled(Input)`
    width: ${inputWidth.medium};
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export default EditDetailsForm
