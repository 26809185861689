import useSWR from 'swr'
import type { ErrorResponse } from '@pleo-io/deimos'
import qs from 'qs'
import { useParams } from 'react-router-dom'

import { fetcher } from './request'
import type {
    KycInformationName,
    KycInformationType,
    KycInformationSubject,
    MissingKycInformation,
} from 'types/styx'

type ReviewQuery = {
    name: KycInformationName
    type: KycInformationType
    subject: KycInformationSubject
    subjectId: string
}

export const useMissingInformation = (query?: ReviewQuery) => {
    const stringifiedQuery = qs.stringify(query)
    const { id: deimosCompanyId } = useParams()
    return useSWR<MissingKycInformation, ErrorResponse>(
        deimosCompanyId
            ? `rest/v1/reviews/companies/${deimosCompanyId}/missing-information?${stringifiedQuery}`
            : null,
        fetcher,
        { revalidateOnFocus: false }
    )
}
