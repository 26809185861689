import type { ToggleFeatureFlagPayload } from 'store/modules/support/company/types'
import useSWR from 'swr'
import dayjs from 'packages/dayjs'
import type { Company } from 'types/deimos-company'
import request, { fetcher } from './request'
import { Direction, GetCompanyBalanceResponse } from 'types/company-balance'
import { useState } from 'react'
import { useDebounce } from 'utils/use-debounce'
import * as uuid from 'uuid'
import type {
    Amount,
    CompanyOffboardingState,
    CompanyOverview,
    InitiateOffboardingRequest,
    OffboardingStepName,
    PaginatedOffboardingCompanies,
    EmployeeType,
} from '@pleo-io/deimos'
import useSWRInfinite from 'swr/infinite'

export interface ExpenseExportFilters {
    fromDate?: string
    untilDate?: string
}

export interface ExpenseExportParams {
    companyId: string
    filters?: ExpenseExportFilters
    includeExpenses?: boolean
    includeReceipts?: boolean
    compressedReceipts?: boolean
    types?: string[]
}

function formatSearchString(text: string) {
    return text.replace(/\s/g, '%20').replace(/\+/g, '%2B')
}

export function findCompanies(query: string) {
    return request().get('rest/v1/admin/companies', {
        searchParams: { query },
    })
}

export const useGetCompanies = (query: string) =>
    useSWR(`rest/v1/admin/companies - query=${query}`, () =>
        findCompanies(query).then((res) => res.json())
    )

// This is a workaround for when the companyIds array is too large for a single request.
// Deimos API does not support pagination for this endpoint, so we have to do it ourselves.
// See this discussion for more details: https://getpleo.slack.com/archives/C02MGTA26GJ/p1701960556609679
// https://linear.app/pleo/issue/WALLE-4278/back-office-unloads-page-not-working

const COMPANY_ID_PAGE_SIZE = 25
export const useGetCompaniesByIds = (ids: string[] = []) => {
    const getKey = (pageIndex: number, previousPageData: string[]) => {
        if (previousPageData && !previousPageData.length) {
            return null
        }

        const start = pageIndex * COMPANY_ID_PAGE_SIZE
        const end = start + COMPANY_ID_PAGE_SIZE
        const pageIds = ids.slice(start, end)

        return pageIds.length ? `rest/v1/companies?companyIds=${pageIds.join(',')}` : null
    }

    const { data, error, isValidating } = useSWRInfinite<Company>(getKey, fetcher, {
        initialSize: ids.length / COMPANY_ID_PAGE_SIZE,
    })

    return { data: data ? data.flat(1) : [], error, isValidating }
}

export function getCompany(deimosCompanyId: string) {
    return request().get(`rest/v1/companies/${deimosCompanyId}`)
}

export const useGetCompany = (deimosCompanyId?: string | null) =>
    useSWR<Company>(
        deimosCompanyId && uuid.validate(deimosCompanyId)
            ? `rest/v1/companies/${deimosCompanyId}`
            : '',
        (url) =>
            request()
                .get(url)
                .then((res) => res.json()),
        { shouldRetryOnError: false }
    )

export const useClosedCompaniesByRegistration = (registrationNumber: string) => {
    const response = useSWR(
        `rest/v1/deleted-companies?registrationNumber=${registrationNumber}`,
        fetcher,
        { shouldRetryOnError: false }
    )
    return response
}

// Returns snapshot time for closed company
export const useClosedCompanySnapshot = (companyId: string) => {
    const { data } = useSWR(`rest/v1/companies/${companyId}/deleted`, fetcher, {
        shouldRetryOnError: false,
    })

    return data?.deletedAt ? dayjs(data?.deletedAt).subtract(2, 'minutes').toISOString() : undefined
}

export function getFeatureFlags(deimosCompanyId: string) {
    return request().get(`rest/v1/admin/companies/${deimosCompanyId}/feature-flags`)
}

export function getEmployees(
    deimosCompanyId: string,
    includeDeleted: boolean,
    type?: EmployeeType
) {
    return request().get(`rest/v1/companies/${deimosCompanyId}/employees`, {
        searchParams: type
            ? { includeLimits: 'false', includeDeleted: String(includeDeleted), type }
            : { includeLimits: 'false', includeDeleted: String(includeDeleted) },
    })
}

export function useEmployees(deimosCompanyId?: string) {
    const { data } = useSWR(
        deimosCompanyId ? `rest/v1/companies/${deimosCompanyId}/employees` : null,
        fetcher,
        {
            shouldRetryOnError: false,
        }
    )

    return data
}

export function setFeature(payload: ToggleFeatureFlagPayload) {
    return request().put(`rest/v1/admin/companies/${payload.companyId}/features`, {
        json: {
            feature: payload.feature,
            active: payload.active,
        },
    })
}

export const useCompanyBalance = (companyId: string | null) => {
    const result = useSWR<GetCompanyBalanceResponse, Error>(
        companyId && `rest/v1/companies/${companyId}/balance/current-available`,
        fetcher,
        { revalidateOnFocus: false, shouldRetryOnError: false }
    )

    const creditBalance = async (amount: Amount, note: string) => {
        return await amendBalance(amount, Direction.CREDIT, note)
    }

    const debitBalance = async (amount: Amount, note: string) => {
        return await amendBalance(amount, Direction.DEBIT, note)
    }

    const amendBalance = async (amount: Amount, direction: Direction, note: string) => {
        await request().post('rest/v1/admin/balance-amendments', {
            json: {
                companyId,
                amount: {
                    direction: direction,
                    value: amount,
                },
                note,
            },
        })
        return result.mutate()
    }

    const emptyBalance = async () => {
        await request().post(`rest/v1/admin/balance-amendments/empty-balance`, {
            json: { companyId },
        })
        return result.mutate()
    }

    return {
        ...result,
        mutations: {
            creditBalance,
            debitBalance,
            emptyBalance,
        },
    }
}

const registerCompanyInStyx = (companyId: string, manual = false): Promise<{ success: true }> =>
    request()
        .post(`rest/v1/companies/${companyId}/styx`, {
            searchParams: { manual: String(manual) },
        })
        .json()

export const useSearchCompanies = () => {
    const [query, setQuery] = useState('')
    const [countries, setCountries] = useState<string[]>([])
    const debouncedQuery = useDebounce(query, 500)
    const countriesQuery = countries.reduce(
        (prev, current) => `${prev}&${encodeURIComponent(`countries[]`)}=${current}`,
        ''
    )
    const { data: companyById } = useGetCompany(uuid.validate(query) ? query : '')
    const { data: companies, mutate } = useSWR<Company[]>(
        `rest/v1/admin/companies?query=${debouncedQuery}${countriesQuery}`,
        fetcher,
        { revalidateOnFocus: false }
    )

    const registerInStyx = async (companyId: string, manual = false) => {
        await registerCompanyInStyx(companyId, manual)
        return mutate()
    }

    return { setQuery, query, setCountries, companies, companyById, registerInStyx }
}

export type DisplayedStates = 'CLOSING' | 'CLOSED' | 'AUTO_OFFBOARDING'

export const offboardingStepNameDisplay: Record<OffboardingStepName, string> = {
    DEACTIVATE_USERS: 'Users',
    CANCEL_SUBSCRIPTION: 'Subscription',
    FINAL_WALLET_UNLOAD_REQUEST: 'Wallet unload request',
    UNSUBSCRIBE_FROM_ACCOUNTING_SERVICES: 'Accounting',
    UNSUBSCRIBE_FROM_KYC_SERVICES: 'KYC',
    CANCEL_DIRECT_DEBIT_AGREEMENT: 'Direct Debit',
    DISABLE_AUTO_TOPUP: 'Auto Top-Up',
    WALLET_IS_EMPTY: 'Wallet is Empty',
    DEACTIVATE_WALLET: 'Deactivate wallet',
    DEACTIVATE_VIBAN: 'VIBAN',
    CREDIT_PENDING_INVOICES: 'Credit pending Invoices',
    DESTROY_CARDS: 'Cards',
    DISABLE_TRAVELPERK_INTEGRATION: 'Travelperk intergration',
}

export const offboardingStepDescriptions: Record<OffboardingStepName, string> = {
    DEACTIVATE_USERS: 'Deactivates all users including admins and bookkeepers.',
    CANCEL_SUBSCRIPTION:
        'Cancels the current subscription and issues and tries to charge the last invoice.',
    FINAL_WALLET_UNLOAD_REQUEST: 'Creates the final wallet unload request.',
    UNSUBSCRIBE_FROM_ACCOUNTING_SERVICES: 'Unsubscribes from accounting services.',
    UNSUBSCRIBE_FROM_KYC_SERVICES: 'Unsubscribes from KYC services. (RDC)',
    CANCEL_DIRECT_DEBIT_AGREEMENT: 'Cancels the direct debit agreement.',
    DISABLE_AUTO_TOPUP: 'Disables auto top-up.',
    WALLET_IS_EMPTY:
        'Warning! The step will become completed when: 1) Compliance approve the last full wallet unload. ' +
        "2) All company's pending transactions are resolved. (This can take up to 2 weeks)",
    DEACTIVATE_WALLET:
        'Warning! After this step the card transactions for the company will be blocked.',
    DEACTIVATE_VIBAN: 'Deactivates VIBAN',
    CREDIT_PENDING_INVOICES: 'Credits the final invoice for the customer.',
    DESTROY_CARDS: 'Destroy all company cards',
    DISABLE_TRAVELPERK_INTEGRATION: 'Removes the travelperk intergration',
}

export const offboardingStepSupportTeam: Record<OffboardingStepName, string> = {
    CANCEL_SUBSCRIPTION: 'Billing & Revenue Infrastructure (Loki)',
    WALLET_IS_EMPTY: 'N/A',
    FINAL_WALLET_UNLOAD_REQUEST: 'Funds Management (Mercury)',
    CREDIT_PENDING_INVOICES: 'Billing & Revenue Infrastructure (Loki)',
    DESTROY_CARDS: 'Admin & CFO Experience (Huginn)',
    DEACTIVATE_WALLET: 'Funds Management (Mercury)',
    DEACTIVATE_VIBAN: 'Payment Infrastructure / Financial Core',
    CANCEL_DIRECT_DEBIT_AGREEMENT: 'Funds Management (Mercury)',
    DISABLE_AUTO_TOPUP: 'Funds Management (Mercury)',
    UNSUBSCRIBE_FROM_ACCOUNTING_SERVICES: 'Connectivity (Demeter)',
    DEACTIVATE_USERS: 'Admin & CFO Experience (Huginn)',
    UNSUBSCRIBE_FROM_KYC_SERVICES: 'Compliance Technology (Atlas)',
    DISABLE_TRAVELPERK_INTEGRATION: 'Partnerships',
}

export const useOffboardingCompanies = (
    offboardingState: CompanyOffboardingState,
    defaultInitiator?: string | null,
    selfOffboardingOnly?: boolean
) => {
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [initiatedBy, setInitiatedBy] = useState(defaultInitiator ?? '')
    const [companyName, setCompanyName] = useState('')

    const initiatedByFilter = initiatedBy ? `&initiatedBy=${initiatedBy}` : ''
    const companyNameFilter = companyName ? `&companyName=${formatSearchString(companyName)}` : ''

    const selfOffboardingOnlyFilter = selfOffboardingOnly
        ? `&selfOffboardingOnly=${selfOffboardingOnly}`
        : ''

    const result = useSWR<PaginatedOffboardingCompanies>(
        `rest/v1/offboarding/companies?offboardingState=${offboardingState}&page=${page}&limit=${limit}${initiatedByFilter}${companyNameFilter}${selfOffboardingOnlyFilter}`,
        fetcher,
        { shouldRetryOnError: false }
    )

    return {
        ...result,
        pagination: { setLimit, setPage, page, limit },
        initiatedBy,
        setInitiatedBy,
        companyName,
        setCompanyName,
    }
}

export const useOffboardingCompanyOverview = (companyId: string) => {
    const url = `rest/v1/offboarding/companies/${companyId}/offboarding-overview`
    const result = useSWR<CompanyOverview>(companyId ? url : null, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })

    const runOffboardingStep = async (offboardingStep: OffboardingStepName) => {
        const response = await request().post(
            `rest/v1/offboarding/companies/${companyId}/offboarding-steps/${offboardingStep}`
        )
        const companyOverview = response.json()
        await result.mutate(companyOverview)
    }

    const runCloseCompany = async () => {
        await request().delete(`rest/v1/offboarding/companies/${companyId}`)
    }

    const runAllAvailableSteps = async () => {
        const response = await request().post(
            `rest/v1/offboarding/companies/${companyId}/offboarding-steps/all`
        )
        const companyOverview = response.json()
        await result.mutate(companyOverview)
    }

    return { ...result, mutations: { runOffboardingStep, runCloseCompany, runAllAvailableSteps } }
}

export const useCompanyOffboardingStatus = (companyId?: string) => {
    const url = `rest/v1/offboarding/companies/${companyId}/offboarding-status`
    const response = useSWR(companyId ? url : null, fetcher, { revalidateOnFocus: false })
    return response
}

export const initiateOffboarding = (
    companyId: string,
    initiateOffboardingRequest: InitiateOffboardingRequest
) =>
    request().post(`rest/v1/offboarding/companies/${companyId}/initiate-offboarding`, {
        json: { ...initiateOffboardingRequest },
    })

export const updateOffboardingStatus = (
    companyId: string,
    initiateOffboardingRequest: InitiateOffboardingRequest
) =>
    request().patch(`rest/v1/offboarding/companies/${companyId}/offboarding-status`, {
        json: { ...initiateOffboardingRequest },
    })

export const exportExpenses = ({
    companyId,
    filters = {},
    includeExpenses = true,
    includeReceipts = true,
    compressedReceipts = false,
    types = ['SETTLED', 'REFUNDS', 'LOADS', 'PENDING', 'OTHER', 'HIDDEN', 'MANUAL_EXPENSE'],
}: ExpenseExportParams) =>
    request().get(
        `rest/v1/companies/${companyId}/expenses/admin-export?includeExpenses=${includeExpenses}&includeReceipts=${includeReceipts}&compressedReceipts=${compressedReceipts}&types=${types.join(
            ','
        )}${filters?.fromDate ? `&from=${filters?.fromDate}` : ''}${
            filters?.untilDate ? `&to=${filters?.untilDate}` : ''
        }`
    )

export const deleteOffboarding = (companyId: string): Promise<void> =>
    request().delete(`rest/v1/offboarding/companies/${companyId}/offboarding-status`).json()

export const useRelatedCompaniesByRegistration = (registrationNumber?: string) => {
    const url = `rest/v1/companies?registrationNumber=${registrationNumber}`
    const { data: companies } = useSWR<Company[]>(registrationNumber ? url : null, fetcher, {
        revalidateOnFocus: false,
    })
    return companies || []
}

export const getBalanceStatement = async (
    companyId: string,
    date: string,
    check = true
): Promise<ArrayBuffer | null> => {
    const dateFilter = `date=${date}`
    const checkFilter = check ? '&check=true' : ''

    try {
        const statement = await request()
            .get(`rest/v1/companies/${companyId}/balance-statement?${dateFilter}${checkFilter}`)
            .arrayBuffer()

        return Promise.resolve(statement)
    } catch (error) {
        if (isMismatchError(error)) {
            return Promise.resolve(null)
        }
        throw error
    }
}

const isMismatchError = (error: any): boolean => {
    if (error.message && error.message === 'unavailable') {
        return true
    }
    return false
}
