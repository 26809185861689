import { PageHeader } from '@ant-design/pro-layout'
import {
    Breadcrumb,
    Button,
    Card,
    Form,
    Input,
    Space,
    Table,
    TableColumnProps,
    Typography,
} from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import type { Company } from '@pleo-io/deimos'
import { getEmojiFlag } from 'countries-list'
import dayjs from 'packages/dayjs'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import getCompanyKycStatus from 'utils/get-company-kyc-status'
import { KycStatus } from 'types/styx'
import { useSearchCompanies } from '../../api/use-search-get-companies'

const { Text } = Typography

const companyColumns: TableColumnProps<Company>[] = [
    {
        title: 'Country',
        dataIndex: 'address',
        render: (address) => (
            <Space>
                <Text>{getEmojiFlag(address.country)}</Text>
                <Text>{address.country}</Text>
            </Space>
        ),
    },
    {
        title: 'Company name',
        dataIndex: 'name',
        render: (companyName) => <Text>{companyName}</Text>,
    },
    {
        title: 'KYC Status',
        dataIndex: 'status',
        render: (_, company) => getCompanyKycStatus(company).toUpperCase(),
        filters: Object.values(KycStatus).map((status) => ({
            text: status.toUpperCase(),
            value: status,
        })),
        onFilter: (status, company) => status === getCompanyKycStatus(company).toUpperCase(),
        filterMultiple: true,
    },
    {
        title: 'Company ID',
        dataIndex: 'id',
        onCell: (_) => ({ onClick: (e) => e.stopPropagation() }),
        render: (companyId) => <Text copyable>{companyId}</Text>,
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('lll'),
    },
]

const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;

    .ant-pagination {
        margin-right: 16px;
    }

    & .ant-table-tbody tr {
        cursor: pointer;
    }
`

export const CompanyResultsTable = ({ query }: { query: string | null }) => {
    const navigate = useNavigate()
    const { companies, companyById, loading } = useSearchCompanies(query)

    const filteredCompanies = companyById ? [companyById] : companies

    const onRowClick = (mouseEvent: React.MouseEvent, companyId: Company['id']) => {
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            window.open(`/customer-success/employee-360/${companyId}`)
        } else {
            navigate(`/customer-success/employee-360/${companyId}`)
        }
    }

    return (
        <TableStyleProvider>
            <Table
                dataSource={filteredCompanies}
                columns={companyColumns}
                rowKey="id"
                onRow={(company) => ({
                    onClick: (mouseEvent) => onRowClick(mouseEvent, company?.id),
                })}
                loading={loading}
            />
        </TableStyleProvider>
    )
}

const CompanySearch = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const query = searchParams.get('query') || ''

    const handleSearch = (values: { searchQuery?: string }) => {
        const searchValue = values.searchQuery
        searchParams.set('query', searchValue || '')
        setSearchParams(searchParams)
    }

    return (
        <PageContentLayout>
            <PageHeader
                title="View employees by company"
                breadcrumb={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success'}>Customer Success</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success/employee-360'}>Employees</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
                extra={[
                    <Button key="1" type="primary">
                        <Link to={'/customer-success/employee-360/advanced-search'}>
                            Advanced employee search
                        </Link>
                    </Button>,
                ]}
            />
            <Card bodyStyle={{ padding: 0 }}>
                <Form
                    layout="vertical"
                    style={{ padding: '16px 16px' }}
                    onFinish={handleSearch}
                    initialValues={{ searchQuery: query }}
                >
                    <Space.Compact style={{ width: '50%' }}>
                        <Form.Item
                            help="Company name or ID"
                            name="searchQuery"
                            style={{ minWidth: '30rem', maxWidth: '100%' }}
                        >
                            <Input allowClear autoFocus spellCheck={false} />
                        </Form.Item>
                        <Button name="submit" type="primary" htmlType="submit">
                            Search
                        </Button>
                    </Space.Compact>
                </Form>
                <CompanyResultsTable query={query} />
            </Card>
        </PageContentLayout>
    )
}

export default CompanySearch
