import styled from 'styled-components'

export const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;

    .ant-pagination {
        margin-right: 16px;
    }

    & .ant-table-tbody tr {
        cursor: pointer;
    }
`
