import React, { FC, useState } from 'react'
import { Modal, Space, Popover, Table, Tag, Tooltip, Button, Typography, Card } from 'antd'
import {
    PaginatedOffboardingCompanies,
    OffboardingCompany,
    CompanyOffboardingState,
} from '@pleo-io/deimos'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import type { ColumnProps } from 'antd/lib/table'
import { getEmojiFlag } from 'countries-list'
import { Link } from 'react-router-dom'
import { DisplayOffboardingReason, categoryTagColor } from 'components/company-offboarding/utils'
import { deSnakify } from 'utils/strings'
import dayjs from 'packages/dayjs'
import { ActionsContainer, PageContentLayout } from 'components/layout-containers'
import ExpenseExportForm from 'components/expense-export/expense-export-form'
import { useOffboardingCompanies } from 'services/deimos/companies'
import FilterCompanyList from 'components/filter-company-list/filter-company-list'
import { formatTimestamp } from 'packages/dates/dates'

const { Text } = Typography

export const ClosedCompaniesList: FC<
    React.PropsWithChildren<{
        paginatedCompanies: PaginatedOffboardingCompanies
        setLimit: (limit: number) => void
        setPage: (page: number) => void
        currentPage: number
        limit: number
        loading: boolean
    }>
> = ({ paginatedCompanies, setLimit, setPage, currentPage, limit, loading }) => {
    const { totalCount, companies } = paginatedCompanies
    const hasExportExpenses = useHasPermissions(['admin-expenses'])
    const hasStyxPermission = useHasPermissions(['styx'])
    const [exportExpenseModalVisible, setExportExpenseModalVisible] = useState(false)
    const [selectedCompanyId, setSelectedCompanyId] = useState('')

    const closedColumns: ColumnProps<OffboardingCompany>[] = [
        {
            title: 'Country',
            dataIndex: 'country',
            render: (country) => (
                <Space size={4}>
                    {getEmojiFlag(country)} {country}
                </Space>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (name, record) => (
                <>
                    {hasStyxPermission ? (
                        <Link to={`/compliance/closed-companies/${record.id}`}>{name}</Link>
                    ) : (
                        <Text>{name}</Text>
                    )}
                </>
            ),
        },
        {
            title: 'Category',
            dataIndex: ['status', 'category'],
            render: (category, record) => (
                <>
                    {category ? (
                        <Popover
                            title="Reason"
                            content={record.status?.reasons?.map((reason, index) => (
                                <div key={index}>{DisplayOffboardingReason[reason]}</div>
                            ))}
                        >
                            <Tag color={categoryTagColor(category)}>{deSnakify(category)}</Tag>
                        </Popover>
                    ) : (
                        '-'
                    )}
                </>
            ),
        },
        {
            title: 'KYC Status',
            dataIndex: 'kycLevel',
        },
        {
            title: 'Company ID',
            dataIndex: 'id',
        },
        {
            title: 'Closed by',
            dataIndex: ['status', 'completedBy'],
        },
        {
            title: 'Notes',
            dataIndex: 'status',
            render: (status) => {
                if (status?.notes && status?.notes.includes('https')) {
                    if (status?.notes.split(' ').length === 1) {
                        return <a href={status?.notes}>{status?.notes}</a>
                    } else {
                        const wordArray = status?.notes.split(' ')

                        const finalComponent: any = []

                        wordArray.map((word: string, index: string) => {
                            if (word.includes('https')) {
                                finalComponent.push(
                                    <>
                                        <a
                                            key={index}
                                            href={word}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                        >
                                            {word}
                                        </a>{' '}
                                    </>
                                )
                            } else {
                                finalComponent.push(
                                    <>
                                        <Text key={index}>{word} </Text>{' '}
                                    </>
                                )
                            }
                        })

                        return <div>{finalComponent}</div>
                    }
                } else return <Text>{status?.notes}</Text>
            },
        },
        {
            title: 'Reasons',
            dataIndex: 'status',
            render: (status) =>
                status?.reasons.map((reason: string, index: string) => (
                    <Tag key={index}>
                        <Text>{reason}</Text>
                    </Tag>
                )),
        },
        {
            title: 'Initiated at',
            dataIndex: 'status',
            render: (status) => (
                <Text>{status?.initiatedAt ? formatTimestamp(status?.initiatedAt) : ''}</Text>
            ),
        },
        {
            title: 'Initiated by',
            dataIndex: 'status',
            render: (status) => <Text>{status?.initiatedBy}</Text>,
        },
        {
            title: 'Closed at',
            dataIndex: 'deletedAt',
            defaultSortOrder: 'descend',
            render: (date?: string) => (date ? formatTimestamp(date) : ''),
            sorter: (a, b) =>
                dayjs(a.deletedAt || new Date()).valueOf() -
                dayjs(b.deletedAt || new Date()).valueOf(),
        },
        {
            title: 'Actions',
            fixed: 'right',
            dataIndex: 'action',
            render: (_, record) => (
                <ActionsContainer>
                    <Tooltip
                        title="Generates a zip file containing all data relevant to the company"
                        mouseEnterDelay={1}
                    >
                        <Button
                            disabled={!hasExportExpenses}
                            type="link"
                            onClick={() => {
                                setSelectedCompanyId(record.id)
                                setExportExpenseModalVisible(!exportExpenseModalVisible)
                            }}
                        >
                            Export expenses
                        </Button>
                    </Tooltip>
                </ActionsContainer>
            ),
        },
    ]

    return (
        <>
            <Table
                dataSource={companies}
                columns={closedColumns}
                rowKey="id"
                pagination={{
                    onChange: (page, pageSize) => {
                        if (pageSize) {
                            setLimit(pageSize)
                            setPage(page)
                        }
                    },
                    current: currentPage,
                    total: totalCount,
                    pageSize: limit,
                }}
                loading={loading}
            />
            <Modal
                title="Export Expenses"
                open={exportExpenseModalVisible}
                onCancel={() => setExportExpenseModalVisible(false)}
                footer={null}
                width={'auto'}
                centered
            >
                <ExpenseExportForm
                    companyId={selectedCompanyId}
                    closeModal={() => setExportExpenseModalVisible(false)}
                />
            </Modal>
        </>
    )
}

const ClosedCompaniesListContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const {
        data: paginatedClosedCompanies,
        pagination: {
            setLimit: setClosedLimit,
            setPage: setClosedPage,
            page: closedPage,
            limit: closedLimit,
        },
        setCompanyName,
        companyName,
        isValidating: isClosedCompaniesValidating,
    } = useOffboardingCompanies(CompanyOffboardingState.CLOSED)

    return (
        <PageContentLayout>
            <Card>
                <Space direction="vertical" size="large" style={{ width: '100%' }}>
                    <FilterCompanyList
                        label="Filter by company name:"
                        placeholder="Closed company name"
                        defaultValue={companyName}
                        onSearch={(company: string) => setCompanyName(company)}
                    />
                    <ClosedCompaniesList
                        currentPage={closedPage}
                        limit={closedLimit}
                        paginatedCompanies={
                            paginatedClosedCompanies || { companies: [], totalCount: 0 }
                        }
                        loading={isClosedCompaniesValidating}
                        setPage={(page) => setClosedPage(page)}
                        setLimit={(limit) => setClosedLimit(limit)}
                    />
                </Space>
            </Card>
        </PageContentLayout>
    )
}

export default ClosedCompaniesListContainer
