import type { Currency } from 'types/currency'
import type { Address, Company, KycProperties } from 'types/deimos-company'

export type ModalActionType = 'ghost' | 'tracking'

export interface ShippingAddress extends Address {
    attention: string
    company: string
    phone?: string
}

export interface Kyc {
    sdd: boolean
    fdd: boolean
    edd: boolean
    hedd: boolean
    hedd1: boolean
}

export interface KycDate {
    sdd?: string
    fdd?: string
    edd?: string
    hedd?: string
    hedd1?: string
}

export interface Info {
    sdd: boolean
}

export interface Status {
    readyForExportSwitchDate?: string
    wallet?: unknown
    terms?: unknown
    kyc?: Kyc
    kycDate?: KycDate
    info?: Info
    selfOnboarding?: boolean
    featureFlags?: unknown
}

export interface Avatar {
    url: string
}

export interface State {
    getstarted: string
}

export interface Employee {
    id: string
    userId: string
    companyId: string
    firstName: string
    lastName: string
    email: string
    phone: string
    avatar: Avatar
    jobTitle: string
    status: unknown
    code: unknown
    role: string
    createdAt: string
    updatedAt: string
    deletedAt: string | null
}

export enum CardOrderStatus {
    CREATED = 'CREATED',
    PROCESSING = 'PROCESSING',
    SENT = 'SENT',
    RECEIVED = 'RECEIVED',
    UNDELIVERED = 'UNDELIVERED',
}

export enum ProcessingStatus {
    TO_PROCESS = 'TO_PROCESS',
    PROCESSING = 'PROCESSING',
    ERROR = 'ERROR',
}

export interface ProcessingResult {
    failureAmount?: number
    successAmount?: number
}

export interface RecardingStatus {
    aboutToBeSwitched: boolean
    daysToSwitchDate: number | null
    recardingOrderCreated: boolean
    switchDate: string | null
    triggerDate: string | null
}
export interface CardOrder {
    id: string
    companyId: string
    employeeId: string
    amount: number
    address: ShippingAddress
    embossLine: string
    status: CardOrderStatus
    shippingLabel?: unknown
    deliveryEstimateMin: unknown
    deliveryEstimateMax: unknown
    processingStatus: ProcessingStatus | null
    processingResult: ProcessingResult
    providerName: ProviderName
    createdAt: string
    updatedAt: string | null
    deletedAt: string | null
    company?: Company
    employee?: Employee
    emboss3?: string
    emboss4?: string
    manufacturer: Manufacturer
    deliveryType?: DeliveryType
    trackingId: string
    deliveryStatus: DeliveryStatus
    supplierId: string
    names?: string[]
    cardOwners: Array<{ employeeId: string | null; name: string }>
    free: boolean
}

export interface GetCardOrders {
    cardOrders: CardOrder[]
    total: number
}

export enum DeliveryType {
    STANDARD = 'STANDARD',
    EXPRESS = 'EXPRESS',
}

export enum DisplayDeliveryType {
    STANDARD = 'Standard',
    EXPRESS = 'Express',
}

export enum Manufacturer {
    MEGACARD = 'MEGACARD',
    NITECREST = 'NITECREST',
    IDEMIA = 'IDEMIA',
}

export const ActiveManufacturers = [Manufacturer.IDEMIA, Manufacturer.NITECREST] as const
export type ActiveManufacturer = (typeof ActiveManufacturers)[number]

export enum DisplayManufacturer {
    MEGACARD = 'MegaCard',
    NITECREST = 'NiteCrest',
    IDEMIA = 'Idemia',
}

export interface CardOrderDateColumn {
    formatted: string
    date: string
}

export enum DeliveryStatus {
    NOT_RECEIVED = 'NOT_RECEIVED',
    IN_PRODUCTION = 'IN_PRODUCTION',
    SHIPPING_LABEL_CREATED = 'SHIPPING_LABEL_CREATED',
    IN_DELIVERY = 'IN_DELIVERY',
    ON_HOLD = 'ON_HOLD',
    INCORRECT_CARDORDER_ID = 'INCORRECT_CARDORDER_ID',
    UNKNOWN = 'UNKNOWN',
}

export const displayDeliveryStatus = (deliveryStatus?: DeliveryStatus) => {
    switch (deliveryStatus) {
        case DeliveryStatus.NOT_RECEIVED:
            return 'Not received'
        case DeliveryStatus.IN_PRODUCTION:
            return 'In production'
        case DeliveryStatus.SHIPPING_LABEL_CREATED:
            return 'Shipping label created'
        case DeliveryStatus.IN_DELIVERY:
            return 'In delivery'
        case DeliveryStatus.ON_HOLD:
            return 'On hold'
        case DeliveryStatus.INCORRECT_CARDORDER_ID:
            return 'Incorrect card order ID'
        case DeliveryStatus.UNKNOWN:
        default:
            return 'Unknown'
    }
}

export interface CardOrderTableData {
    address: ShippingAddress
    amount: number
    companyId: string
    companyName: string
    companyCountry: string
    createdAt: string
    updatedAt: string
    providerName: ProviderName
    processingStatus: ProcessingStatus | null
    id: string
    status: CardOrderStatus
    kycStatus: KycProperties
    trackingId: string
    deliveryStatus: DeliveryStatus
    manufacturer: Manufacturer
    deliveryType?: DeliveryType
    supplierId: string
}

export interface OrderReadyForUpdate {
    amount: number
    address: ShippingAddress
    emboss3?: string
    emboss4?: string
    embossLine?: string
    batch?: string
    manufacturer: Manufacturer
    deliveryType: DeliveryType
    free: boolean
    names?: string[]
    cardOwners: Array<{ employeeId: string | null; name: string }>
}

export interface CustomOrders {
    companyId: string
    orders: OrderReadyForUpdate[]
}

export interface CustomOrderFormValues {
    companyId: string
    companyEmbossLine?: string
    manufacturer: ActiveManufacturer
    fee: boolean
    orders: Order[]
}

export interface Order {
    attention: string
    address: Address
    employeeEmbossLine: string
    phone?: string
    deliveryType: DeliveryType
    names?: string[]
    cardOwners: Array<{ employeeId: string | null; name: string }>
}

export enum CardOrderMethod {
    BULK_COURIER_DELIVERY = 'BULK_COURIER_DELIVERY',
    BULK_PACKAGED_DELIVERY = 'BULK_PACKAGED_DELIVERY',
    AIRMAIL = 'AIRMAIL',
    STANDARD_MAIL = 'STANDARD_MAIL',
}

export enum ValidCurrencies {
    DKK = 'DKK',
    GBP = 'GBP',
    SEK = 'SEK',
    NOK = 'NOK',
    EUR = 'EUR',
}

export enum ProviderName {
    ENFUCE = 'ENFUCE',
    GPS = 'GPS',
}

export enum CSVHeaders {
    AMOUNT = 'Amount of cards',
    EMBOSSEMPLOYEE = 'Name(s) to be printed on cards',
    EMBOSSCOMPANY = 'Company name on card',
    ATTENTION = 'Attention, name',
    PHONE = 'Recipient phone number',
    ADDRESSLINE1 = 'Addresse line 1 (street name and number)',
    ADDRESSLINE2 = 'Address line 2',
    LOCALITY = 'City',
    POSTALCODE = 'Zip code',
    COUNTRY = 'Country code',
}

export interface SingleCardOrder {
    cardholder: {
        firstName: string
        lastName: string
    }
    address: Address
    cardOrderId: string
    companyId: string
    embossLine3?: string
    embossLine4?: string
    providerName?: ProviderName
}

export interface GhostWalletOrderBody {
    amount: number
    country?: string
    currency: Currency
}

export type OrderCardBody = SingleCardOrder

export type OrderId = string

export interface OrderFilters {
    companyId?: string
    status?: CardOrderStatus[]
    kycStatus?: KycProperties[]
    hasWalletLoad?: string[]
    providerName?: ProviderName[]
    dateRange?: {
        to: string
        from: string
    }
}
