import { Alert, Button, Card, List, Spin, Tooltip } from 'antd'
import { useIsReviewMigrationEnabled } from '../bff'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

type ListItemProps = {
    title: React.ReactNode
    serviceName: string
    isMigrated: boolean
    isLoading: boolean
    isError: boolean
    onRetry: () => void
}

const ListItem: React.FC<ListItemProps> = ({
    title,
    serviceName,
    isMigrated,
    isLoading,
    isError,
    onRetry,
}) => {
    return (
        <List.Item style={{ padding: '8px 0' }}>
            <Title style={{ marginBottom: '4px' }}>{title}</Title>
            {isLoading ? (
                <Aligned>
                    <Text>Loading data...</Text>
                    <Spin />
                </Aligned>
            ) : isError ? (
                <Alert
                    message="Error loading migration info."
                    action={
                        <Button size="small" danger onClick={onRetry}>
                            Retry
                        </Button>
                    }
                />
            ) : (
                <>
                    <Text>
                        <Bold>Service:</Bold> {isMigrated ? serviceName : 'Deimos'}
                    </Text>
                    <Text>
                        <Bold>Status:</Bold> {isMigrated ? 'Migrated 🟢' : 'Not migrated 🔘'}
                    </Text>
                </>
            )}
        </List.Item>
    )
}

const ReviewFlowsListItem: React.FC<{
    companyId: string
}> = ({ companyId }) => {
    const {
        data: isUsingReviewManagement = false,
        isFetching: isFetchingReviewManagement,
        isError: isErrorReviewManagement,
        refetch: refetchReviewManagement,
    } = useIsReviewMigrationEnabled({ companyId })

    return (
        <ListItem
            title="Review Flows"
            serviceName="Review Management"
            isLoading={isFetchingReviewManagement}
            isMigrated={isUsingReviewManagement}
            isError={isErrorReviewManagement}
            onRetry={refetchReviewManagement}
        />
    )
}

const DeimosMigrationCardTitle = () => {
    return (
        <Tooltip title="Shows status of migration away from Deimos for certain Pleo Flows for the Company.">
            <Aligned>
                <Title>Deimos Migration Status</Title>
                <InfoCircleOutlined />
            </Aligned>
        </Tooltip>
    )
}

/**
 * Component displaying Deimos migration statuses for a target company.
 *
 * @param companyId - The ID of the company.
 */
export const DeimosMigrationStatus = ({ companyId }: { companyId: string }) => {
    return (
        <Card
            type="inner"
            data-testid="deimos-migration-status"
            style={{ marginBottom: '24px' }}
            title={<DeimosMigrationCardTitle />}
        >
            <List size="small" bordered={false} itemLayout="vertical">
                <ReviewFlowsListItem companyId={companyId} />
            </List>
        </Card>
    )
}

const Title = styled.div`
    font-size: 14px;
    font-weight: 600;
`

const Text = styled.div`
    font-size: 13px;
    margin-left: 0px;
    margin-bottom: 4px;
`

const Bold = styled.span`
    font-weight: 600;
`

const Aligned = styled.div`
    justify-self: 'flex-start';
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
`
