import { useState } from 'react'
import { Card, Table, Button, Tag } from 'antd'
import { PlusSquareOutlined, MinusOutlined } from '@ant-design/icons'
import type { SortOrder, ColumnType } from 'antd/lib/table/interface'
import type {
    AuditEventAction,
    EntityType,
    Entity,
    Shareholder,
    Director,
    ShareholderAuditEvent,
    SimplifiedUnknownEntity,
    SimplifiedCompanyEntity,
    SimplifiedPersonEntity,
} from 'types/styx'
import EntityIcon from 'pages/compliance/company/shared/entity-icon/entity-icon'
import { getEntityName, hasKycCheck } from 'pages/compliance/shared/utils'
import dayjs from 'packages/dayjs'

interface Props {
    unassigned: Entity[]
    shareholders: Shareholder[]
    beneficialOwners: Shareholder[]
    directors: Director[]
    allStakeholderPersons: Entity[]
    removeStakeholderFromScope: (id: string) => Promise<void>
    auditTrail: ShareholderAuditEvent[]
}

const Stakeholders = ({
    unassigned,
    shareholders,
    beneficialOwners,
    directors,
    allStakeholderPersons,
    removeStakeholderFromScope,
    auditTrail,
}: Props) => {
    const [sortOrder, setSortOrder] = useState('desc')
    const [activeTab, setActiveTab] = useState('unassigned')
    const onTabChange = (key: string) => setActiveTab(key)

    const tabList = [
        { key: 'unassigned', tab: 'Unassigned Entities' },
        { key: 'shareholders', tab: 'Shareholders' },
        { key: 'beneficial-owners', tab: 'Beneficial Owners' },
        { key: 'directors', tab: 'Directors' },
        { key: 'all-stakeholders', tab: 'All Stakeholders' },
        { key: 'audit-trail', tab: 'Audit Log' },
    ]

    const isActiveTab = (key: string) => activeTab === key

    const unassignedColumns = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type: EntityType) => (
                <span>
                    <EntityIcon type={type} /> {type}
                </span>
            ),
        },
        {
            title: 'Name',
            key: 'name',
            render: getEntityName,
        },
        {
            title: 'eKYC',
            key: 'id',
            render: (_: unknown, record: Entity) =>
                hasKycCheck(record, 'eKyc') ? <PlusSquareOutlined /> : <MinusOutlined />,
        },
        {
            title: 'IDV',
            key: 'id',
            render: (_: unknown, record: Entity) =>
                hasKycCheck(record, 'idv') ? <PlusSquareOutlined /> : <MinusOutlined />,
        },
        {
            title: 'PEP/SIS',
            key: 'id',
            render: (_: unknown, record: Entity) =>
                hasKycCheck(record, 'pep/sis') ? <PlusSquareOutlined /> : <MinusOutlined />,
        },
    ]

    const shareholderColumns = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type: EntityType) => (
                <span>
                    <EntityIcon type={type} /> {type}
                </span>
            ),
        },
        {
            title: 'Name',
            key: 'name',
            render: getEntityName,
        },
        {
            title: 'Percentage of shares',
            dataIndex: 'sharePercentage',
            render: (percentage?: number) => (percentage ? `${percentage}%` : '-'),
        },
        {
            title: 'Voting rights',
            dataIndex: 'votingRights',
            render: (percentage?: number) => (percentage ? `${percentage}%` : '-'),
        },
    ]

    const directorColumns = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type: EntityType) => (
                <span>
                    <EntityIcon type={type} /> {type}
                </span>
            ),
        },
        {
            title: 'Name',
            key: 'name',
            render: getEntityName,
        },
        {
            title: 'Position',
            dataIndex: 'position',
        },
    ]

    const allStakeholderPersonsColumns: ColumnType<Entity>[] = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type: EntityType) => (
                <span>
                    <EntityIcon type={type} /> {type}
                </span>
            ),
        },
        {
            title: 'Name',
            key: 'name',
            render: getEntityName,
        },
        {
            title: 'Scope',
            key: 'scope',
            render: (_, record) => {
                if ('kycPerson' in record) {
                    const isInScope = record.kycPerson

                    return (
                        <Button
                            disabled={!isInScope}
                            type="primary"
                            danger={isInScope}
                            onClick={() => removeStakeholderFromScope(record.id)}
                        >
                            Remove from scope
                        </Button>
                    )
                }
                return '-'
            },
        },
    ]

    const auditTrailColumns = [
        {
            title: 'Type',
            dataIndex: 'legalType',
            render: (legalType?: string, event?: ShareholderAuditEvent) =>
                event && event.node ? (
                    <span>
                        <EntityIcon type={event.node.legalType} /> {event.node.legalType}
                    </span>
                ) : (
                    '-'
                ),
        },
        {
            title: 'Source',
            dataIndex: 'source',
            render: (source?: string, event?: ShareholderAuditEvent) =>
                event && event.node
                    ? event.node.source === 'DUE_DILIGENCE_AGENT_INPUT'
                        ? 'DD_AGENT'
                        : event.node.source || '-'
                    : '-',
        },
        {
            title: 'Name',
            key: 'name',
            render: (name?: string, event?: ShareholderAuditEvent) => {
                if (event && event.node) {
                    const { legalType, entity } = event.node
                    switch (legalType) {
                        case 'PERSON': {
                            const personEntity = entity as SimplifiedPersonEntity
                            return personEntity.name
                                ? Object.values(personEntity.name).join(' ')
                                : '-'
                        }
                        case 'COMPANY': {
                            const companyEntity = entity as SimplifiedCompanyEntity
                            return companyEntity.legalName
                        }
                        case 'UNKNOWN': {
                            const unknownEntity = entity as SimplifiedUnknownEntity
                            return unknownEntity.name
                        }
                        default:
                            return '-'
                    }
                } else {
                    return '-'
                }
            },
        },
        {
            title: 'Parent company',
            dataIndex: 'parentEntityName',
            render: (parentEntityName?: string) => (parentEntityName ? `${parentEntityName}` : '-'),
        },
        {
            title: 'Shares',
            dataIndex: 'sharePercentage',
            render: (sharePercentage?: string, event?: ShareholderAuditEvent) =>
                event && event.node ? event.node.sharePercentage : '-',
        },
        {
            title: 'Voting rights',
            dataIndex: 'votingRights',
            render: (votingRights?: string, event?: ShareholderAuditEvent) =>
                event && event.node ? event.node.votingRights : '-',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (action?: AuditEventAction) => {
                switch (action) {
                    case 'CREATE':
                        return <Tag color="green">Create</Tag>
                    case 'UPDATE':
                        return <Tag color="orange">Update</Tag>
                    case 'DELETE':
                        return <Tag color="red">Delete</Tag>
                }
            },
        },
        {
            title: 'Updated at',
            dataIndex: 'actionTime',
            render: (actionTime: string | undefined) =>
                actionTime ? dayjs.utc(actionTime).local().format('lll') : '-',
            sorter: (a: ShareholderAuditEvent, b: ShareholderAuditEvent) => {
                if (a.actionTime && b.actionTime) {
                    return dayjs(a.actionTime).unix() - dayjs(b.actionTime).unix()
                }
                return 0
            },
            sortOrder: sortOrder as SortOrder,
            onHeaderCell: () => ({
                onClick: () => {
                    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc')
                },
            }),
        },
        {
            title: 'Updated by',
            dataIndex: 'userEmail',
            render: (userEmail?: string) => (userEmail ? `${userEmail}` : '-'),
        },
    ]

    return (
        <Card tabList={tabList} onTabChange={onTabChange}>
            {isActiveTab('unassigned') && (
                <Table dataSource={unassigned} rowKey="id" columns={unassignedColumns} />
            )}
            {isActiveTab('shareholders') && (
                <Table
                    dataSource={shareholders}
                    rowKey="shareholderId"
                    columns={shareholderColumns}
                />
            )}
            {isActiveTab('beneficial-owners') && (
                <Table
                    dataSource={beneficialOwners}
                    rowKey="shareholderId"
                    columns={shareholderColumns}
                />
            )}
            {isActiveTab('directors') && (
                <Table dataSource={directors} rowKey="directorId" columns={directorColumns} />
            )}
            {isActiveTab('all-stakeholders') && (
                <Table
                    dataSource={allStakeholderPersons}
                    rowKey="id"
                    columns={allStakeholderPersonsColumns}
                />
            )}
            {isActiveTab('audit-trail') && (
                <Table dataSource={auditTrail} rowKey="shareholderId" columns={auditTrailColumns} />
            )}
        </Card>
    )
}

export default Stakeholders
