import React, { FC, useState, useEffect } from 'react'
import { Upload, message, Modal } from 'antd'
import type { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface'
import type { Expense } from 'types/expense'

import { Document, Page } from 'react-pdf'

import { getReceiptUrl } from 'services/deimos/receipts'
import { ReceiptType } from '@pleo-io/deimos'
import type { RcCustomRequestOptions } from 'types/custom-request'

import { bff } from '../bff-hooks'

const ReceiptPreview: FC<React.PropsWithChildren<{ src: string }>> = ({ src }) => {
    // The receipts can be uploaded in pdf or image types and
    // we don't know what we're getting from the s3 bucket.
    // We assume it's a pdf and in case we get an error, we
    // fallback to displaying an image.
    const [pdfError, setPdfError] = useState(false)
    const [pageCount, setPageCount] = React.useState(0)
    const pageNumbers = Array.from(Array(pageCount), (_, index) => index + 1)

    return (
        <>
            {!pdfError ? (
                // @ts-ignore
                <Document
                    file={src}
                    onLoadSuccess={({ numPages }) => setPageCount(numPages)}
                    onLoadError={() => setPdfError(true)}
                >
                    {pageNumbers.map((pageNumber) => (
                        // @ts-ignore
                        <Page key={`page_${pageNumber}`} pageNumber={pageNumber} />
                    ))}
                </Document>
            ) : (
                <img src={src} alt="Receipt preview" />
            )}
        </>
    )
}

interface Props {
    expense?: Expense
    onUploadReceipt: (formData: FormData) => Promise<Expense>
}

const UploadReceipt: FC<React.PropsWithChildren<Props>> = ({ expense, onUploadReceipt }) => {
    const receipts = expense?.receipts ?? []
    const [fileList, setFileList] = useState<UploadFile[]>([])

    const { mutate: deleteReceipt } = bff.components.uploadReceipt.deleteReceipt.useMutation()

    useEffect(() => {
        const initFileList = async () => {
            const files = []
            for (let index = 0; index < receipts.length; index++) {
                const url = await getReceiptUrl({
                    receiptId: receipts[index].id,
                    type: ReceiptType.ORIGINAL,
                })
                // We need to cast the object to UploadFile since we are missing two
                // properties on it, size and type. They are mandatory but they don't
                // break functionality if missing.
                files.push({
                    /**
                     * uid for the file will be the receiptId,
                     * it will be useful to remove the receipt
                     */
                    uid: receipts[index].id,
                    name: `image${index}.png`,
                    status: 'done',
                    url,
                } as UploadFile)
            }
            setFileList(files)
        }
        initFileList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(receipts)])

    const [visible, setVisible] = useState(false)
    const [source, setSource] = useState('')

    const onChange = ({ fileList: newFileList }: UploadChangeParam) => {
        setFileList(newFileList)
    }
    const onDownload = async (file: UploadFile) => {
        const link = document.createElement('a')
        link.href = file.url!
        link.target = '_blank'
        link.click()
    }
    const onPreview = async (file: UploadFile) => {
        setSource(file.url!)
        setVisible(true)
    }
    const uploadReceipt = async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
        try {
            const formData = new FormData()
            formData.append('file', file)
            const resolvedResult = await onUploadReceipt(formData)
            onSuccess && onSuccess({ resolvedResult }, file as any)
        } catch (e) {
            onError && onError(e as Error)
            message.error((e as Error).message)
        }
    }
    const onRemove = async (file: UploadFile) => {
        return new Promise<boolean>((resolve, reject) => {
            Modal.confirm({
                onOk: async () => {
                    try {
                        const receiptId = file.uid
                        deleteReceipt({ accountingEntryId: expense!.id, receiptId })
                        resolve(true)
                        message.info('Successfully removed receipt.')
                    } catch (e) {
                        resolve(false)
                        message.error((e as Error).message)
                    }
                },
                onCancel: () => reject(),
                centered: true,
                title: 'Delete receipt',
                content: 'Are you sure you want to delete this receipt?',
            })
        })
    }

    return (
        <>
            <Upload
                disabled={!expense}
                listType="picture-card"
                multiple
                showUploadList={{ showDownloadIcon: true }}
                fileList={fileList}
                onChange={onChange}
                onDownload={onDownload}
                onPreview={onPreview}
                customRequest={uploadReceipt}
                onRemove={onRemove}
            >
                + Upload
            </Upload>
            <Modal
                title="Preview"
                open={visible}
                onCancel={() => setVisible(false)}
                footer={null}
                width={800}
                centered
                destroyOnClose
            >
                <ReceiptPreview src={source} />
            </Modal>
        </>
    )
}

export default UploadReceipt
