import type { FC } from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { Button } from 'antd'
import { DaySelect, MonthSelect, YearSelect } from 'components/date-of-birth-pickers'

const validationSchema = Yup.object()
    .shape({
        dateOfBirth: Yup.number(),
        monthOfBirth: Yup.number(),
        yearOfBirth: Yup.number(),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface Props {
    loading: boolean
    onSubmit: (values: FormValues) => void
}

const BirthdateFilterForm: FC<React.PropsWithChildren<Props>> = ({ onSubmit, loading }) => {
    return (
        <Formik validationSchema={validationSchema} initialValues={{}} onSubmit={onSubmit}>
            {({ resetForm }) => (
                <Form>
                    <Form.Item name="dateOfBirth" label="Date of Birth">
                        <DaySelect
                            name="dateOfBirth"
                            placeholder="Select date"
                            data-testid="date-of-birth-select"
                        />
                    </Form.Item>
                    <Form.Item name="monthOfBirth" label="Month of Birth">
                        <MonthSelect
                            name="monthOfBirth"
                            placeholder="Select month"
                            data-testid="month-of-birth-select"
                        />
                    </Form.Item>
                    <Form.Item name="yearOfBirth" label="Year of Birth">
                        <YearSelect
                            name="yearOfBirth"
                            placeholder="Select year"
                            data-testid="year-of-birth-select"
                        />
                    </Form.Item>
                    <Row>
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            data-testid="search-button"
                        >
                            Search
                        </Button>
                        <Button
                            type="default"
                            onClick={() => resetForm()}
                            data-testid="clear-button"
                        >
                            Clear
                        </Button>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

const Row = styled.div`
    display: flex;
    gap: ${spacing.space8};
`

export default BirthdateFilterForm
