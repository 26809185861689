import { Checkbox, Col, Form, Input, Radio, Row } from 'antd'
import { createRequiredErrorMessage } from 'pages/partnerships/utils'
import { useState } from 'react'
import { exhaustiveCheck } from 'utils/exhaustive-check'
import { OAUTH_SCOPES } from '../constants'
import type { App } from '../models/app'
import { APP_AUTHENTICATION_METHODS } from '../models/app-form-options'

export const AppAuthenticationMethod = ({
    initialValue,
}: {
    initialValue?: App['authenticationMethod']
}) => {
    const [authenticationMethod, setAuthenticationMethod] = useState<
        null | App['authenticationMethod']
    >(initialValue ?? null)

    return (
        <>
            <AppAuthenticationMethod.AuthMethodInput
                setAuthenticationMethod={setAuthenticationMethod}
            />
            <AppAuthenticationMethod.AuthClientConfig authenticationMethod={authenticationMethod} />
        </>
    )
}

const AppAuthMethodInput = ({
    setAuthenticationMethod,
}: {
    setAuthenticationMethod: (value: App['authenticationMethod']) => void
}) => (
    <Form.Item
        name="authenticationMethod"
        label="Authentication method"
        rules={[
            {
                required: true,
                message: createRequiredErrorMessage('Authentication method'),
            },
        ]}
    >
        <Radio.Group onChange={(event) => setAuthenticationMethod(event.target.value)}>
            {APP_AUTHENTICATION_METHODS.map(({ label, value }) => (
                <Radio key={value} value={value}>
                    {label}
                </Radio>
            ))}
        </Radio.Group>
    </Form.Item>
)
AppAuthenticationMethod.AuthMethodInput = AppAuthMethodInput

const ClientConfig = ({
    authenticationMethod,
}: {
    authenticationMethod: App['authenticationMethod'] | null
}) => {
    switch (authenticationMethod) {
        case 'OAUTH2':
            return <ClientConfig.OAuth2 />
        case 'API_KEY':
            return <ClientConfig.Scope />

        case 'NONE':
        case null:
            return null

        default:
            return exhaustiveCheck(authenticationMethod)
    }
}
AppAuthenticationMethod.AuthClientConfig = ClientConfig

const OAuth2Config = () => (
    <>
        <ClientConfig.Scope />
        <ClientConfig.RedirectUris />
    </>
)
ClientConfig.OAuth2 = OAuth2Config

const APIKeyConfig = () => <ClientConfig.Scope />
ClientConfig.APIKey = APIKeyConfig

const RedirectUrisInput = () => (
    <Form.Item
        name="redirectUris"
        label="Redirect URIs"
        rules={[
            {
                required: true,
                message: createRequiredErrorMessage('Redirect URIs'),
            },
        ]}
    >
        <Input.TextArea rows={4} autoSize={{ minRows: 3 }} placeholder="New-line separated URLs" />
    </Form.Item>
)
ClientConfig.RedirectUris = RedirectUrisInput

const ScopeSelectorInput = () => (
    <Form.Item
        name="scope"
        label="Scopes"
        rules={[
            {
                required: true,
                message: createRequiredErrorMessage('Scopes'),
            },
        ]}
    >
        <Checkbox.Group>
            <Row>
                {OAUTH_SCOPES.map((scope) => (
                    <Col key={scope} span={6}>
                        <Checkbox value={scope}>{scope}</Checkbox>
                    </Col>
                ))}
            </Row>
        </Checkbox.Group>
    </Form.Item>
)
ClientConfig.Scope = ScopeSelectorInput
