import { useCallback, type FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Switch } from 'antd'
import { spacing } from 'theme/tokens'

import { Feature, FeatureFlags as FeatureFlagsType, FeatureFlagsEnum } from 'types/feature-flags'
import type { CurrentStatus } from 'types/status'
import { selectFeatureFlags } from 'store/modules/support/company/selectors'
import { actions, actions as companyActions } from 'store/modules/support/company/slice'
import { selectFeatureFlagsStatus } from 'store/modules/support/selectors'
import type { ToggleFeatureFlagPayload } from 'store/modules/support/company/types'

import styled from 'styled-components'
import ErrorState from 'components/error-state/error-state'
import { createTRPCReact } from '@trpc/react-query'
import type { FeatureFlagsRouter } from './backend/index.bff'

interface Props {
    companyId: string
    featureFlagsStatus: CurrentStatus
    featureFlagsForCompany?: FeatureFlagsType
    toggleFeatureFlag: (payload: ToggleFeatureFlagPayload) => void
    onRetry: () => void
}

export const FeatureFlags: FC<React.PropsWithChildren<Props>> = ({
    companyId,
    featureFlagsStatus,
    featureFlagsForCompany,
    toggleFeatureFlag,
    onRetry,
}) => {
    const isEnabled = (featureFlag: string) =>
        featureFlagsForCompany ? featureFlagsForCompany[featureFlag as Feature] : false

    switch (featureFlagsStatus) {
        case 'error':
            return (
                <Card title="Feature flags">
                    <ErrorState onRetry={onRetry} />
                </Card>
            )
        case 'fetching':
            return <Card title="Feature flags" loading={true} />
        case 'default':
        case 'resolved':
            return (
                <Card title="Feature flags">
                    <FeatureFlagsListContainer>
                        {Object.keys(FeatureFlagsEnum)
                            .filter((feature) => feature !== 'billInvoices')
                            .map((feature, index) => (
                                <FeatureFlagContainer key={index}>
                                    <Switch
                                        size="small"
                                        data-testid={`${feature}Switch`}
                                        checked={isEnabled(feature)}
                                        onChange={(checked: boolean) => {
                                            const payload = {
                                                companyId: companyId,
                                                feature: feature,
                                                active: checked,
                                            }
                                            toggleFeatureFlag(payload)
                                        }}
                                    />
                                    {FeatureFlagsEnum[feature as keyof typeof FeatureFlagsEnum]}
                                </FeatureFlagContainer>
                            ))}
                        <InvoiceFeatureFlag companyId={companyId} />
                    </FeatureFlagsListContainer>
                </Card>
            )
    }
}

const InvoiceFeatureFlag = ({ companyId }: { companyId: string }) => {
    const { data: invoiceEnabled, refetch: refetchInvoiceEnabled } =
        featureFlagsBff.getInvoiceEnabled.useQuery({ companyId: companyId })

    const { mutateAsync: enableInvoice } = featureFlagsBff.toggleInvoiceEnabled.useMutation({
        onSuccess: () => refetchInvoiceEnabled(),
    })
    const { mutateAsync: disableInvoice } = featureFlagsBff.toggleInvoiceDisabled.useMutation({
        onSuccess: () => refetchInvoiceEnabled(),
    })

    const toggleInvoice = useCallback(() => {
        if (!invoiceEnabled) {
            return enableInvoice({ companyId: companyId })
        }
        return disableInvoice({ companyId: companyId })
    }, [invoiceEnabled, companyId, enableInvoice, disableInvoice])

    return (
        <FeatureFlagContainer key={'invoice-toggle'}>
            <Switch
                size="small"
                data-testid={`billInvoiceSwitch`}
                checked={invoiceEnabled}
                onChange={toggleInvoice}
            />
            {FeatureFlagsEnum.billInvoices}
        </FeatureFlagContainer>
    )
}

interface ContainerProps {
    companyId: string
}

const featureFlagsBff = createTRPCReact<FeatureFlagsRouter>().featureFlags

export const FeatureFlagsContainer: FC<React.PropsWithChildren<ContainerProps>> = ({
    companyId,
}) => {
    const dispatch = useDispatch()
    const featureFlagsStatus = useSelector(selectFeatureFlagsStatus)
    const featureFlagsForCompany = useSelector(selectFeatureFlags)

    const toggleFeatureFlag = (payload: ToggleFeatureFlagPayload) => {
        dispatch(actions.toggleFeatureFlag(payload))
    }

    const fetchFeatureFlags = () => dispatch(companyActions.fetchFeatureFlags(companyId))

    return (
        <FeatureFlags
            companyId={companyId}
            featureFlagsStatus={featureFlagsStatus}
            featureFlagsForCompany={featureFlagsForCompany}
            toggleFeatureFlag={toggleFeatureFlag}
            onRetry={fetchFeatureFlags}
        />
    )
}

export default FeatureFlagsContainer

const FeatureFlagsListContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: ${spacing.space8} ${spacing.space24};
`

const FeatureFlagContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.space8};
`
