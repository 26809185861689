import React, { FC, useState } from 'react'
import styled, { css } from 'styled-components'
import { Modal, Button } from 'antd'

import type { KycCheck } from 'types/styx'

interface Props {
    label: string
    status?: KycCheck['result']
    expired?: string
}

const HandleKycCheck: FC<React.PropsWithChildren<Props>> = ({
    children,
    label,
    status,
    expired,
}) => {
    const [modalActive, setModalActive] = useState(false)

    let width: number
    if (label == 'PEP') {
        width = 1000
    } else {
        width = 500
    }

    return (
        <>
            <KycButton
                data-testid="kyc-button"
                status={status}
                expired={expired}
                onClick={() => setModalActive(true)}
            >
                {label}: {expired ? 'Expired' : status?.replace('_', ' ') ?? 'Unchecked'}
            </KycButton>
            <Modal
                data-testid="modal"
                title={`${label} check`}
                footer={null}
                centered
                open={modalActive}
                onCancel={() => setModalActive(false)}
                width={width}
            >
                {children}
            </Modal>
        </>
    )
}

interface KycButtonProps {
    readonly status?: KycCheck['result']
    readonly expired?: string
}

const statusColor = {
    fail: 'red',
    error: 'red',
    pass: 'green',
    refer: 'volcano',
    expired: 'volcano',
    inconclusive: 'orange',
    insufficient_information: 'orange',
    manually_approved: 'green',
}

export const KycButton = styled(Button)<KycButtonProps>`
    width: auto;
    ${({ theme: { colorPalette }, status, expired }) => {
        const isNotPending = status !== 'PENDING' && status !== 'Pending'
        const lowercaseStatus = expired
            ? 'expired'
            : (status?.toLowerCase() as keyof typeof statusColor)

        return (
            lowercaseStatus &&
            isNotPending &&
            css`
                color: ${colorPalette[statusColor[lowercaseStatus]]?.[5]};
                border-color: ${colorPalette[statusColor[lowercaseStatus]]?.[5]};
                background: ${colorPalette[statusColor[lowercaseStatus]]?.[0]};
                &:hover,
                &:focus {
                    color: ${colorPalette[statusColor[lowercaseStatus]]?.[3]};
                    border-color: ${colorPalette[statusColor[lowercaseStatus]]?.[3]};
                    background: ${colorPalette[statusColor[lowercaseStatus]]?.[0]};
                }
            `
        )
    }}
`

export default HandleKycCheck
