import { Button, Modal } from 'antd'
import ProcessList from 'components/process-list/process-list'
import type { FC } from 'react'
import { copyTransactions } from './utils'

interface ProcessTransactionsModalProps {
    isModalOpen: { get: boolean; set: (value: boolean) => void }
    title: string
    allTransactions: string[]
    currentTransaction: string
    failedTransactions: string[]
    successfulTransactions: string[]
}

export const ProcessTransactionsModal: FC<ProcessTransactionsModalProps> = ({
    isModalOpen,
    title,
    allTransactions,
    currentTransaction,
    failedTransactions,
    successfulTransactions,
}) => {
    return (
        <Modal
            key={title}
            title={title}
            open={isModalOpen.get}
            closable={false}
            footer={[
                <Button
                    key="failed"
                    id="copy-failed"
                    data-testid="copy-failed"
                    onClick={() => copyTransactions(failedTransactions)}
                    disabled={!failedTransactions.length}
                >
                    Copy failed
                </Button>,
                <Button
                    key="successful"
                    id="copy-successful"
                    data-testid="copy-successful"
                    onClick={() => copyTransactions(successfulTransactions)}
                    disabled={!successfulTransactions.length}
                >
                    Copy successful
                </Button>,
                <Button
                    type="primary"
                    key="close"
                    data-tesid="close-modal"
                    onClick={() => isModalOpen.set(false)}
                >
                    Close
                </Button>,
            ]}
        >
            <ProcessList
                allItems={allTransactions}
                currentItem={currentTransaction}
                failedItems={failedTransactions}
                successfulItems={successfulTransactions}
            />
        </Modal>
    )
}
