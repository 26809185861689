/**
 * `Result<E, A>` is the type used for returning and propagating errors.
 *
 * It is an disjoint union of types `Ok<A>`, representing success and
 * containing a value, and `Err<E>`, representing error and containing
 * an error value.
 */
export type Result<E, A> = Err<E> | Ok<A>

type Err<E> = {
    readonly _tag: 'Err'
    readonly error: E
}

type Ok<A> = {
    readonly _tag: 'Ok'
    readonly value: A
}

export const err = <E = never, A = never>(error: E): Result<E, A> => ({ _tag: 'Err', error })
export const ok = <E = never, A = never>(value: A): Result<E, A> => ({ _tag: 'Ok', value })

export const isErr = <E = never, A = never>(result: Result<E, A>): result is Err<E> =>
    result._tag === 'Err'
export const isOk = <E = never, A = never>(result: Result<E, A>): result is Ok<A> =>
    result._tag === 'Ok'

/**
 * Pattern matches on a `Result`.
 *
 * @param onErr handler for the error case
 * @param onOk handler for the success case
 */
export const match: <E = never, A = never, R = never>(
    onErr: (error: E) => R,
    onOk: (value: A) => R
) => (result: Result<E, A>) => R = //prettier-ignore
    (onErr, onOk) => (result) => (isErr(result) ? onErr(result.error) : onOk(result.value))
