import type { EmployeeBalance, GetPocketEmployeeEntriesResponse } from '@pleo-io/deimos'
import request, { fetcher } from 'services/deimos/request'
import useSWR from 'swr'

export function useEmployeePocketBalance(employeeId?: string | null) {
    const result = useSWR<EmployeeBalance, Error>(
        employeeId ? `rest/v1/employees/${employeeId}/pocket/balances` : null,
        fetcher,
        { revalidateOnFocus: false }
    )

    return result
}

export function useEmployeePocketEntries(employeeId?: string | null) {
    const result = useSWR<GetPocketEmployeeEntriesResponse, Error>(
        employeeId
            ? `rest/v2/employees/${employeeId}/pocket/entries?hasSettledTransfer=false&excludeTransfers=true&status=APPROVED&include[]=expense`
            : null,
        fetcher,
        { revalidateOnFocus: false }
    )

    return result
}

export const markSettledPocketExpense = (employeeId: string, expenseIds: string[]) =>
    request().post(
        `rest/v1/admin/mark-settled-pocket-entries?employeeId=${employeeId}&expenseIds[]=${expenseIds.join(
            '&expenseIds[]='
        )}`
    )
