import { notification } from 'antd'
import type { FC } from 'react'
import { createGhostWalletOrder } from 'services/deimos/card-orders'
import * as Yup from 'yup'
import { ExecuteGhostOrderForm, FormValues, GHOST_WALLET_OPTIONS } from './execute-ghost-form'

export const validationSchema = Yup.object().shape({
    amount: Yup.number().required().positive('You must order at least 1 card.'),
})

interface Props {
    closeModal: () => void
}
const ExecuteGhostOrderFormContainer: FC<React.PropsWithChildren<Props>> = ({ closeModal }) => {
    const onSubmit = ({ amount, ghostWalletValue }: FormValues) => {
        const { country, currency } = GHOST_WALLET_OPTIONS.find(
            ({ value }) => value === ghostWalletValue
        )!
        try {
            createGhostWalletOrder({ amount, country, currency })
            notification.info({
                message: `Ghost wallet order successfully created.`,
            })
            closeModal()
        } catch (e) {
            notification.error({
                message: `Something went wrong while creating the ghost wallet order.`,
                description: (e as Error).message,
            })
        }
    }

    return (
        <ExecuteGhostOrderForm
            initialValues={{ amount: 0, ghostWalletValue: 'DKK' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        />
    )
}

export default ExecuteGhostOrderFormContainer
