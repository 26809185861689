import { Button, Col, Descriptions, List, Modal, Row, Space, Tag, Typography } from 'antd'
import { FC, useState } from 'react'
import { isDueDiligenceAgentInput, isUserInput } from 'services/styx/utils'
import styled from 'styled-components'
import { fontSize, spacing } from 'theme/tokens'
import type { IndustryCodes } from 'types/styx'

const MAX_ITEMS_TO_DISPLAY_IN_HEADER = 4

const { Text } = Typography

interface NaceCodeContainerProps {
    naceCodes: IndustryCodes[]
}

const NaceCodeContainer: FC<NaceCodeContainerProps> = ({ naceCodes }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const isModalRequired = naceCodes.length > MAX_ITEMS_TO_DISPLAY_IN_HEADER

    return (
        <>
            <Space direction="vertical">
                <Descriptions.Item label="NACE">
                    <NaceGridContainer>
                        {naceCodes.slice(0, MAX_ITEMS_TO_DISPLAY_IN_HEADER).map((code, index) => (
                            <NaceCodeItem key={index} naceCode={code} />
                        ))}
                    </NaceGridContainer>
                </Descriptions.Item>
                {isModalRequired && (
                    <Row justify="center">
                        <Button type="link" onClick={() => setIsModalOpen(true)}>
                            Show all NACE codes...
                        </Button>
                    </Row>
                )}
            </Space>
            <Modal
                title="All company NACE codes"
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                style={{ maxHeight: '80vh' }}
            >
                <List
                    style={{ maxHeight: '60vh', overflow: 'auto' }}
                    size="small"
                    dataSource={naceCodes}
                    renderItem={(code, index) => (
                        <List.Item key={index}>
                            <NaceCodeItem naceCode={code} />
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    )
}

interface NaceCodeItemProps {
    naceCode: IndustryCodes
}

export const NaceCodeItem: FC<NaceCodeItemProps> = ({ naceCode }) => {
    const color = isUserInput(naceCode)
        ? 'yellow'
        : isDueDiligenceAgentInput(naceCode)
        ? 'green'
        : 'blue'

    const label = isUserInput(naceCode)
        ? '(User provided) '
        : isDueDiligenceAgentInput(naceCode)
        ? '(Manually added) '
        : ''

    return (
        <Row wrap={false}>
            <Col>
                <Tag color={color}>{naceCode.code}</Tag>
            </Col>
            <Col>
                <Text type="secondary" style={{ fontSize: fontSize.size12 }}>
                    {label}
                    {naceCode.description}
                </Text>
            </Col>
        </Row>
    )
}

const NaceGridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: ${spacing.space8};
    column-gap: ${spacing.space24};
`

export default NaceCodeContainer
