import React, { memo } from 'react'
import type { TableCellProps } from '../types'
import EditableFeatureCell from './editable-feature-cell'
import EditableInputCell from './editable-input-cell'
import { RecordDatatype } from '../enums'

const getEditableComponent = (recordType: RecordDatatype) => {
    const editingComponents = {
        [RecordDatatype.Feature]: EditableFeatureCell,
        [RecordDatatype.Input]: EditableInputCell,
    }
    return editingComponents[recordType]
}

const TableCell: React.FC<TableCellProps> = ({
    editing,
    editable,
    dataIndex,
    title,
    record,
    children,
    onFieldUpdate,
}) => {
    if (!editable) {
        return <td>{children}</td>
    }

    const Component = getEditableComponent(record.type)

    return (
        <Component
            editing={editing}
            dataIndex={dataIndex}
            title={title}
            record={record}
            onFieldUpdate={onFieldUpdate}
        />
    )
}

export default memo(TableCell)
