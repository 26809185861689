import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Space, Typography } from 'antd'
import type { FC } from 'react'
import styled from 'styled-components'
import type { Bookkeeper } from 'types/employee'
import { getBookkeeperInfo } from 'pages/customer-success/company/utils'

const { Link, Text } = Typography

const BookkeepersDropdown: FC<
    React.PropsWithChildren<{
        onBookkeeperClick: (bookkeeper: Bookkeeper) => void
        bookkeepers: Bookkeeper[]
        bookkeepersError: boolean
    }>
> = ({ onBookkeeperClick, bookkeepers, bookkeepersError }) => (
    <Dropdown
        menu={{
            items: bookkeepers.map((bookkeeper, index) => {
                return {
                    key: index,
                    label: (
                        <Space size={4}>
                            <Link onClick={() => onBookkeeperClick(bookkeeper)}>
                                {getBookkeeperInfo(bookkeeper)}
                            </Link>
                            <Text type="secondary">
                                {bookkeeper.displayedRole && `(${bookkeeper.displayedRole})`}
                            </Text>
                        </Space>
                    ),
                }
            }),
        }}
        disabled={bookkeepersError || bookkeepers.length === 0}
    >
        <StyledButton type="link" size="small" onClick={(e) => e.preventDefault()}>
            Bookkeepers
            {bookkeepersError && ': Failed to load'}
            {!bookkeepersError && bookkeepers.length === 0 ? ': None' : <DownOutlined />}
        </StyledButton>
    </Dropdown>
)

const StyledButton = styled(Button)`
    padding: 0;
    height: 22px;
`

export default BookkeepersDropdown
