import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'

export const FallbackForbidden = ({ goBackLink }: { goBackLink: string }) => (
    <Result
        status="403"
        title="403"
        subTitle="Sorry, you don't have permission to access this page."
        extra={
            <Button type="primary">
                <Link to={goBackLink}>Go back</Link>
            </Button>
        }
    />
)
