import type { User } from '@pleo-io/deimos'
import { useReducer } from 'react'
import {
    deleteRefreshTokens,
    resetPasscode,
    resetPinAndInactivateRefreshToken,
    resetTwoFactor,
} from 'services/auth/users'
import { kerberosRequest, buildFetcher } from 'services/request'
import useSWR from 'swr'

const fetcher = buildFetcher(kerberosRequest)

type Method =
    | 'resetPasscode'
    | 'resetPinAndInactivateRefreshToken'
    | 'resetTwoFactor'
    | 'deleteRefreshTokens'

export type MethodState = 'initial' | 'loading' | 'success' | 'error'

export function getUser(userId: string) {
    return kerberosRequest().get('api/v1/admin/users', {
        searchParams: { query: '', admin: 'false', userId },
    })
}

export const useSecurity = (userId: string) => {
    const result = useSWR<User[]>(
        userId ? `api/v1/admin/users?query=&admin=false&userId=${userId}` : null,
        fetcher,
        {
            revalidateOnFocus: true,
        }
    )

    const reducer = (
        state: Record<Method, MethodState>,
        action: { type: Method; payload: MethodState }
    ) => ({
        ...state,
        [action.type]: action.payload,
    })

    const initialState: Record<Method, MethodState> = {
        resetTwoFactor: 'initial',
        resetPasscode: 'initial',
        deleteRefreshTokens: 'initial',
        resetPinAndInactivateRefreshToken: 'initial',
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const resetUserTwoFactor = async () => {
        try {
            await resetTwoFactor(userId)
            dispatch({ type: 'resetTwoFactor', payload: 'success' })
        } catch (e) {
            dispatch({ type: 'resetTwoFactor', payload: 'error' })
        }
        result.mutate()
    }

    const resetUserPasscode = async () => {
        try {
            await resetPasscode(userId)
            dispatch({ type: 'resetPasscode', payload: 'success' })
        } catch (e) {
            dispatch({ type: 'resetPasscode', payload: 'error' })
        }
        result.mutate()
    }

    const deleteUserRefreshTokens = async () => {
        try {
            await deleteRefreshTokens(userId)
            dispatch({ type: 'deleteRefreshTokens', payload: 'success' })
        } catch (e) {
            dispatch({ type: 'deleteRefreshTokens', payload: 'error' })
        }
        result.mutate()
    }

    const resetUserAuth = async () => {
        try {
            await resetPinAndInactivateRefreshToken(userId)
            dispatch({ type: 'resetPinAndInactivateRefreshToken', payload: 'success' })
        } catch (e) {
            dispatch({ type: 'resetPinAndInactivateRefreshToken', payload: 'error' })
        }
        result.mutate()
    }

    return {
        ...result,
        methodState: state,
        mutations: {
            resetUserTwoFactor,
            resetUserPasscode,
            deleteUserRefreshTokens,
            resetUserAuth,
        },
    }
}
