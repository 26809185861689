export enum AutoTopUpStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    DISABLED_BY_USER = 'DISABLED_BY_USER',
    PENDING = 'PENDING',
}

export interface CompanyTopUpSettings {
    autoTopupStatus?: AutoTopUpStatus
    autoTopUpAmount?: number
    autoTopUpThreshold?: number
    dryThreshold?: number
}
