import { Alert, Button, Card, Divider, Space } from 'antd'
import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'packages/dayjs'

import { selectCompanyId } from 'store/modules/support/company/selectors'
import { getXml } from 'services/deimos/export'
import { DayjsDatePicker } from 'packages/form/date-picker'

const dateFormat = 'YYYY-MM-DD'

export const ReceiptsXml: FC<React.PropsWithChildren<unknown>> = () => {
    const companyId = useSelector(selectCompanyId)
    const [fetching, setFetching] = useState<boolean>(false)
    const [startDate, setStartDate] = useState<string | undefined>()
    const [endDate, setEndDate] = useState<string | undefined>()
    const [xmlUrl, setXmlUrl] = useState<string | undefined>()
    const [error, setError] = useState<string | undefined>()

    const fetchXml = async () => {
        if (!startDate || !endDate || !companyId || fetching) {
            return
        }
        setFetching(true)
        setError(undefined)
        setXmlUrl(undefined)
        try {
            const response = await getXml(companyId, startDate, endDate)
            const body = await response.json()
            setXmlUrl(body.url)
        } catch (e) {
            setError((e as Error).message)
        }
        setFetching(false)
    }

    return (
        <Card title="Receipts XML download">
            <Space>
                <DayjsDatePicker
                    placeholder="Start date"
                    value={startDate ? dayjs(startDate).utc() : undefined}
                    onChange={(value) => setStartDate(value?.format(dateFormat))}
                />
                <DayjsDatePicker
                    placeholder="End date"
                    value={endDate ? dayjs(endDate).utc() : undefined}
                    onChange={(value) => setEndDate(value?.format(dateFormat))}
                />
                <Button
                    type="primary"
                    disabled={fetching || !startDate || !endDate || !companyId}
                    onClick={fetchXml}
                >
                    {fetching ? 'Generating XML...' : 'Generate XML'}
                </Button>
                {error && (
                    <>
                        <Divider />
                        <Alert message={error} type="error" showIcon />
                    </>
                )}
                {xmlUrl && (
                    <>
                        <Divider />
                        <a href={xmlUrl} target="_blank" rel="noopener noreferrer">
                            Download XML File
                        </a>
                    </>
                )}
            </Space>
        </Card>
    )
}

export default ReceiptsXml
