import type { BankAccountInfo } from '@pleo-io/deimos'

export enum Status {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    INVESTIGATING = 'INVESTIGATING',
    PRE_APPROVED = 'PRE_APPROVED',
    PRE_APPROVED_LEGAL_NAME_CHECK = 'PRE_APPROVED_LEGAL_NAME_CHECK',
    PRE_APPROVED_ACRONYM_CHECK = 'PRE_APPROVED_ACRONYM_CHECK',
    PRE_APPROVED_WORD_BY_WORD_CHECK = 'PRE_APPROVED_WORD_BY_WORD_CHECK',
    REJECTED_REFUND = 'REJECTED_REFUND',
}

export enum ProofOfFundsRefundStatus {
    INITIATING = 'INITIATING',
    INITIATED = 'INITIATED',
    RECEIVED = 'RECEIVED',
    FAILED = 'FAILED',
}

export enum ProofOfFundsRejectionReason {
    WRONG_COMPANY = 'WRONG_COMPANY',
    EMONEY_ACCOUNT = 'EMONEY_ACCOUNT',
    PERSONAL_ACCOUNT = 'PERSONAL_ACCOUNT',
    OUTSIDE_ACCEPTANCE_ZONES = 'OUTSIDE_ACCEPTANCE_ZONES',
    FAILED_TO_PROVIDE_INFORMATION = 'FAILED_TO_PROVIDE_INFORMATION',
    OTHER = 'OTHER',
}

export interface Transaction {
    amount: number
    companyId: string
    currency: string
    id: string
    ownerEmail: string
    ownerId: string
    performedAt: string
    senderBank: string
    senderInformation: string
    companyName?: string
    status: Status
    transactionId: string
    updatedAt: string
    newSource: boolean
    firstLoad: boolean
    invoiceId: string
    refundStatus?: ProofOfFundsRefundStatus
    refundId?: string
    bankAccountInfo?: BankAccountInfo
}
