import { useState } from 'react'
import { Card } from 'antd'

import AllowList from './merchant-allowlist/merchant-allowlist'
import BlockList from './merchant-blocklist/merchant-blocklist'
import CompanyBlocklist from './merchant-company-blocklist/merchant-company-blocklist'
import { PageContentLayout } from 'components/layout-containers'

const tabList = [
    {
        key: 'blocklist',
        tab: 'Blocklist',
    },
    {
        key: 'companyBlocklist',
        tab: 'Company Blocklist',
    },
    {
        key: 'allowlist',
        tab: 'Allowlist',
    },
]

const contentList = {
    allowlist: <AllowList />,
    blocklist: <BlockList />,
    companyBlocklist: <CompanyBlocklist />,
}

type Key = keyof typeof contentList

const Merchants = () => {
    const [activeKey, setActiveKey] = useState<Key>('blocklist')
    return (
        <PageContentLayout>
            <Card
                defaultActiveTabKey={activeKey}
                tabList={tabList}
                onTabChange={(key) => setActiveKey(key as Key)}
            >
                {contentList[activeKey]}
            </Card>
        </PageContentLayout>
    )
}

export default Merchants
