import { Tree, Typography } from 'antd'
import type {
    CompanyDelta,
    CompanyReviewStructureResponse,
    EmployeeDelta,
    TeamDelta,
} from '../types'
import type { DataNode } from 'antd/es/tree'
import useSWR from 'swr'
import { buildFetcher, oberonRequest } from 'services/request'
import type { GetTeamResponse } from 'bff/moons/generated/oberon-v1'
import {
    ExclamationOutlined,
    UserAddOutlined,
    UserDeleteOutlined,
    UserSwitchOutlined,
    UsergroupAddOutlined,
    UsergroupDeleteOutlined,
} from '@ant-design/icons'
import { useMemo } from 'react'
import { bff } from '../bff-hooks'

type DeltaEvent = 'ADDED' | 'CHANGED' | 'REMOVED'

const teamIcons = {
    ADDED: <UsergroupAddOutlined />,
    CHANGED: <ExclamationOutlined />,
    REMOVED: <UsergroupDeleteOutlined />,
}

const employeeIcons = {
    ADDED: <UserAddOutlined />,
    CHANGED: <UserSwitchOutlined />,
    REMOVED: <UserDeleteOutlined />,
}

const colors = {
    ADDED: 'green',
    CHANGED: 'orange',
    REMOVED: 'red',
}

export const ReviewStructureDelta = ({
    deltas,
}: {
    deltas?: CompanyReviewStructureResponse['deltas']
}) => {
    const treeData = useMemo(() => mapDeltasToTreeData(deltas), [deltas])

    return <Tree treeData={treeData} showIcon virtual selectable={false} />
}

const mapDeltasToTreeData = (deltas?: CompanyReviewStructureResponse['deltas']) => {
    if (!deltas) return undefined

    const dataNodes: DataNode[] = [
        ...Object.values(deltas.companiesRemoved),
        ...Object.values(deltas.companiesAdded),
        ...Object.values(deltas.companiesChanged),
    ].map((company, index) => ({
        key: company?.companyId || `company-${index}`,
        title: company?.companyId,
        children: mapCompanyDelta(company),
    }))

    return dataNodes
}

const mapTeamToDataNode = (team: Partial<TeamDelta>, deltaEvent: DeltaEvent) => {
    return {
        key: team.teamId || `team-${deltaEvent}-${team.teamId}`,
        title: <TeamTitle teamId={team.teamId} />,
        icon: teamIcons[deltaEvent],
        children: mapTeamDelta(team),
        style: { color: colors[deltaEvent] },
    }
}

const mapEmployeeToDataNode = (employee: Partial<EmployeeDelta>, deltaEvent: DeltaEvent) => {
    return {
        key: employee.employeeId || `employee-${deltaEvent}-${employee.employeeId}`,
        title: <EmployeeTitle employeeId={employee.employeeId} />,
        icon: employeeIcons[deltaEvent],
        style: { color: colors[deltaEvent] },
    }
}

const flattenAndMapCompanyDelta = (
    deltaEvent: DeltaEvent,
    companyDelta?: CompanyDelta['teamsAdded']
) => {
    return Object.values(companyDelta || {}).map((team) => mapTeamToDataNode(team, deltaEvent))
}

const flattenAndMapTeamDelta = (
    deltaEvent: DeltaEvent,
    teamDelta?: TeamDelta['employeesAdded']
) => {
    return Object.values(teamDelta || {}).map((employee) =>
        mapEmployeeToDataNode(employee, deltaEvent)
    )
}

const mapCompanyDelta = (companyDelta: Partial<CompanyDelta>) => {
    const mappedCompanyDeltas = [
        ...flattenAndMapCompanyDelta('ADDED', companyDelta.teamsAdded),
        ...flattenAndMapCompanyDelta('CHANGED', companyDelta.teamsChanged),
        ...flattenAndMapCompanyDelta('REMOVED', companyDelta.teamsRemoved),
    ]

    return mappedCompanyDeltas
}

const mapTeamDelta = (teamDelta: Partial<TeamDelta>) => {
    const mappedTeamDeltas = [
        ...flattenAndMapTeamDelta('ADDED', teamDelta.employeesAdded),
        ...flattenAndMapTeamDelta('CHANGED', teamDelta.employeesChanged),
        ...flattenAndMapTeamDelta('REMOVED', teamDelta.employeesRemoved),
    ]

    return mappedTeamDeltas
}

const EmployeeTitle = ({ employeeId }: { employeeId?: string }) => {
    const { data: employee } = bff.reviewStructureDelta.getEmployee.useQuery({ employeeId })

    return <Typography.Text>{employee?.title}</Typography.Text>
}

const TeamTitle = ({ teamId }: { teamId?: string }) => {
    const { data: team } = useSWR<GetTeamResponse>(
        teamId ? `rest/v1/teams/${teamId}` : null,
        buildFetcher(oberonRequest),
        { shouldRetryOnError: false, revalidateOnFocus: false }
    )

    return <Typography.Text>{team?.name || teamId}</Typography.Text>
}
