import { createContext, ReactNode, useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { bff } from './bff-hooks'
import { Button, Result } from 'antd'
import Spinner from 'components/content-spinner'

interface ModalMetadata {
    isOpen: boolean
    open: () => void
    close: () => void
}

interface OddDetailContext {
    caseId: string
    isLocked?: boolean
    companyId?: string
    conclusionModal: ModalMetadata
    offboardingModal: ModalMetadata
    sendRequestInformationModal: ModalMetadata
    delayNotificationsModal: ModalMetadata
    historyModal: ModalMetadata
    orgModal: ModalMetadata
    notesModal: ModalMetadata
}

const OddDetailContext = createContext<OddDetailContext | undefined>(undefined)

export const useOddDetailContext = () => {
    const context = useContext(OddDetailContext)
    if (!context) {
        throw new Error('useOddDetailContext must be used within a OddDetailContextProvider')
    }
    return context
}

export const OddDetailProvider = ({ children }: { children: ReactNode }) => {
    const { caseId } = useParams()
    const navigate = useNavigate()
    const [isConclusionModalOpen, setIsConclusionModalOpen] = useState(false)
    const [isOffboardingModalOpen, setIsOffboardingModalOpen] = useState(false)
    const [isSendRequestInformationModalOpen, setIsSendRequestInformationModalOpen] =
        useState(false)
    const [isDelayNotificationsModalOpen, setIsDelayNotificationsModalOpen] = useState(false)
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
    const [isOrgModalOpen, setIsOrgModalOpen] = useState(false)
    const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)
    const { data, refetch, error, isLoading } = bff.context.getOddContext.useQuery(
        {
            caseId,
        },
        { refetchOnWindowFocus: false }
    )

    const conclusionModal = {
        isOpen: isConclusionModalOpen,
        open: () => setIsConclusionModalOpen(true),
        close: () => setIsConclusionModalOpen(false),
    }

    const offboardingModal = {
        isOpen: isOffboardingModalOpen,
        open: () => setIsOffboardingModalOpen(true),
        close: () => setIsOffboardingModalOpen(false),
    }

    const sendRequestInformationModal = {
        isOpen: isSendRequestInformationModalOpen,
        open: () => setIsSendRequestInformationModalOpen(true),
        close: () => setIsSendRequestInformationModalOpen(false),
    }

    const delayNotificationsModal = {
        isOpen: isDelayNotificationsModalOpen,
        open: () => setIsDelayNotificationsModalOpen(true),
        close: () => setIsDelayNotificationsModalOpen(false),
    }

    const historyModal = {
        isOpen: isHistoryModalOpen,
        open: () => setIsHistoryModalOpen(true),
        close: () => setIsHistoryModalOpen(false),
    }

    const orgModal = {
        isOpen: isOrgModalOpen,
        open: () => setIsOrgModalOpen(true),
        close: () => setIsOrgModalOpen(false),
    }

    const notesModal = {
        isOpen: isNotesModalOpen,
        open: () => setIsNotesModalOpen(true),
        close: () => setIsNotesModalOpen(false),
    }

    if (error) {
        const is404 = error.data?.httpStatus === 404
        return (
            <Result
                title={is404 ? 'Case not found' : 'Something went wrong'}
                subTitle={
                    is404
                        ? error.shape?.message
                        : 'Please try again, if the problem persists please let us know at #team-business-operations-enablement.'
                }
                status={is404 ? '404' : '500'}
                extra={[
                    <Button key="back" type="primary" onClick={() => navigate('/compliance/odd')}>
                        Back to ODD List
                    </Button>,
                    !is404 && (
                        <Button key="retry" onClick={() => refetch()}>
                            Retry
                        </Button>
                    ),
                ]}
            />
        )
    }

    if (!isLoading) {
        return (
            <OddDetailContext.Provider
                value={{
                    caseId,
                    isLocked: data?.isLocked,
                    companyId: data?.companyId,
                    conclusionModal,
                    offboardingModal,
                    sendRequestInformationModal,
                    delayNotificationsModal,
                    historyModal,
                    orgModal,
                    notesModal,
                }}
            >
                {children}
            </OddDetailContext.Provider>
        )
    }

    return <Spinner />
}
