import { memo } from 'react'
import { Checkbox, Space } from 'antd'
import { Switch } from 'formik-antd'
import FeatureCell from './feature-cell'
import type { Feature, TableCellProps, FormikStructure } from '../types'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { FormDataTypes } from '../enums'
import { useField, useFormikContext } from 'formik'
import { constructFieldName } from '../utils'

const MemoizedSwitch = memo(Switch)
const MemoizedCheckbox = memo(Checkbox)

const EditableFeatureCell = ({ editing, dataIndex, record, onFieldUpdate }: TableCellProps) => {
    const { handleChange, values } = useFormikContext<FormikStructure>()
    const addOnName = constructFieldName(record.key, dataIndex, record.id, FormDataTypes.AddOn)
    const [addOnField] = useField(addOnName)
    const { isEnabled, isScheduled, isAddOn } = record[dataIndex] as Feature
    const baseFieldName = `${record.key}-${dataIndex}`
    const featureName = constructFieldName(record.key, dataIndex, record.id, FormDataTypes.Feature)

    return (
        <td key={baseFieldName}>
            {editing ? (
                <Space>
                    <MemoizedSwitch
                        name={featureName}
                        defaultChecked={isEnabled}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={(switchStatus) => {
                            const updatedValues = {
                                ...values,
                                [featureName]: switchStatus,
                            }
                            onFieldUpdate(featureName, switchStatus, updatedValues, record.type)
                        }}
                    />
                    <MemoizedCheckbox
                        name={addOnName}
                        checked={addOnField?.value ?? isAddOn}
                        onChange={(event) => {
                            handleChange(event)
                            const updatedValues = {
                                ...values,
                                [featureName]: event.target.checked,
                            }
                            onFieldUpdate(
                                addOnName,
                                event.target.checked,
                                updatedValues,
                                record.type
                            )
                        }}
                    >
                        paid
                    </MemoizedCheckbox>
                </Space>
            ) : (
                <FeatureCell isEnabled={isEnabled} isAddon={isAddOn} isScheduled={isScheduled} />
            )}
        </td>
    )
}

export default memo(EditableFeatureCell)
