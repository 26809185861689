import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { fetcher } from 'services/deimos/request'
import useSWR from 'swr'
import type { CompanyReviewStructureResponse } from '../types'

export const useCompanyReviewHistory = () => {
    const [searchParams] = useSearchParams()

    const companyId = searchParams.get('company')
    const at = searchParams.get('at')
    const diffWith = searchParams.get('diffWith')

    const dateParams = useMemo(() => {
        const dateSearchParams = new URLSearchParams()
        at ? dateSearchParams.set('at', at) : dateSearchParams.delete('at')
        diffWith ? dateSearchParams.set('diffWith', diffWith) : dateSearchParams.delete('diffWith')
        dateSearchParams.sort()
        return dateSearchParams
    }, [at, diffWith])

    const response = useSWR<CompanyReviewStructureResponse>(
        companyId ? `review-histories/companies/${companyId}?${dateParams.toString()}` : null,
        fetcher,
        { revalidateOnFocus: false, shouldRetryOnError: false }
    )

    return {
        reviewHistory: response.data,
        at: dateParams.get('at'),
        companyId,
        diffWith: dateParams.get('diffWith'),
        isLoading: !!companyId && !response.data && !response.error,
        ...response,
    }
}
