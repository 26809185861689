import type {UserRole} from './user-role'

export interface User {
    id: string
    email: string
    language: string | null
    verified: boolean
    active: boolean
    acceptedTerms: boolean
    data: UserData
    createdAt: string
    deletedAt?: string
    admin: boolean
    permissions: string[]
    roles: UserRole[]
    hasPasscode?: boolean | null
    phone?: string | null
    phoneVerified?: boolean
    scaEnabled?: boolean
    scaSecondFactor?: UserTFAType | null
    verifyToken: string | null
    primaryCompanyId?: string
}

interface UserName {
    lastName: string
    firstName: string
}

interface UserProfile {
    id: string | null
    raw: {}
    name?: UserName
    type: string
    email: string
    phone: string
    picture: string | null
}

interface UserData {
    promo: string | null
    profile: UserProfile
    changeEmail?: string
}

export enum UserTFAType {
    PHONE = 'phone',
    GOOGLE_AUTH = 'googleAuth',
}

export interface GetUser {
    id: string
    primaryCompanyId: string
}
