import type { FC } from 'react'
import { Table } from 'antd'
import { chunk } from '../utils'
import type { CardOrder, Order } from 'store/modules/support/card-orders/types'

const RecipientTable: FC<React.PropsWithChildren<{ cardOrder: CardOrder | Order }>> = ({
    cardOrder,
}) => {
    const cardOrderListOfNames = cardOrder?.cardOwners?.map(({ name }) => name) ?? cardOrder?.names

    return (
        <>
            {cardOrderListOfNames && (
                <Table
                    dataSource={chunk(cardOrderListOfNames)}
                    rowKey="column1"
                    size="small"
                    pagination={cardOrderListOfNames.length > 8 * 3 && { pageSize: 8 }}
                >
                    {cardOrderListOfNames.length >= 1 && (
                        <Table.Column
                            title="Name on card"
                            dataIndex="column1"
                            width="calc(100%/3)"
                        />
                    )}
                    {cardOrderListOfNames.length >= 2 && (
                        <Table.Column
                            title="Name on card"
                            dataIndex="column2"
                            width="calc(100%/3)"
                        />
                    )}
                    {cardOrderListOfNames.length >= 3 && (
                        <Table.Column
                            title="Name on card"
                            dataIndex="column3"
                            width="calc(100%/3)"
                        />
                    )}
                </Table>
            )}
        </>
    )
}

export default RecipientTable
