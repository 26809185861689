import { useGetCompany } from 'services/deimos/companies'
import { useGetPartner } from 'services/calypso/partner'
import { deactivateEmployee } from 'services/deimos/employees'
import { deletePermissionByResourceId } from 'services/kerberos/permissions'
import { EmployeeRole, DisplayRole, Employee, OberonEmployee, OberonEmployeeRole, PartnerRole } from 'types/employee'
import { Button, Popconfirm, Tooltip, Typography, notification } from 'antd'
import { useEffect, useState } from 'react'
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { RoleType } from 'types/user-role'

const { Text } = Typography

export const employeeUserRoleMap: Record<EmployeeRole, RoleType> = {
    [EmployeeRole.OWNER]: RoleType.OWNER,
    [EmployeeRole.MEMBER]: RoleType.MEMBER,
    [EmployeeRole.BOOKKEEPER]: RoleType.BOOKKEEPER,
    [EmployeeRole.BOOKKEEPER_BASIC]: RoleType.BOOKKEEPER_BASIC,
    [EmployeeRole.BOOKKEEPER_EXTENDED]: RoleType.BOOKKEEPER_EXTENDED,
    [EmployeeRole.BOOKKEEPER_MANAGED]: RoleType.BOOKKEEPER_MANAGED,
}

export const isEmployeeCompanyAdmin = (employee: Employee) => employee.role === 'owner'
export const getUserRole = (isAdmin: boolean) => (isAdmin ? EmployeeRole.OWNER : EmployeeRole.MEMBER)
export const getUserDisplayRole = (isAdmin: boolean) =>
    isAdmin ? DisplayRole.ADMIN : DisplayRole.EMPLOYEE
export const getUserDisplayRoleByEmployeeRole = (role: EmployeeRole | null) => {
    switch (role) {
        case EmployeeRole.OWNER:
            return DisplayRole.ADMIN
        case EmployeeRole.MEMBER:
            return DisplayRole.EMPLOYEE
        case EmployeeRole.BOOKKEEPER_BASIC:
            return DisplayRole.BOOKKEEPER_BASIC
        case EmployeeRole.BOOKKEEPER_EXTENDED:
            return DisplayRole.BOOKKEEPER_EXTENDED
        default:
            return '-'
    }
}
export const getUserDisplayRoleByOberonEmployeeRole = (role?: OberonEmployeeRole | null) => {
    switch (role) {
        case OberonEmployeeRole.OWNER:
            return DisplayRole.ADMIN
        case OberonEmployeeRole.MEMBER:
            return DisplayRole.EMPLOYEE
        case OberonEmployeeRole.BOOKKEEPER_BASIC:
            return DisplayRole.BOOKKEEPER_BASIC
        case OberonEmployeeRole.BOOKKEEPER_EXTENDED:
            return DisplayRole.BOOKKEEPER_EXTENDED
        default:
            return '-'
    }
}

export const partnerEmployeeRoleToOberonEmployeeRole = (role: PartnerRole) => ({
    [PartnerRole.OWNER]: OberonEmployeeRole.OWNER,
    [PartnerRole.MEMBER]: OberonEmployeeRole.MEMBER,
}[role])

export const getEmployeeName = (employee: Employee) => `${employee.firstName} ${employee.lastName}`
export const getOberonEmployeeName = (employee: OberonEmployee) => `${employee.firstName} ${employee.lastName}`
export const filterEmployeesByNameOrEmail = (employees: Employee[], filter: string) =>
    employees.filter(
        (e) =>
            getEmployeeName(e).toLowerCase().includes(filter.toLowerCase()) ||
            e.email.toLowerCase().includes(filter.toLowerCase())
    )
export const filterOberonEmployeesByNameOrEmail = (employees: OberonEmployee[], filter: string) =>
    employees.filter(
        (e) =>
            getOberonEmployeeName(e).toLowerCase().includes(filter.toLowerCase()) ||
            e.email.toLowerCase().includes(filter.toLowerCase())
    )

export const CompanyPartnerName = ({
    employee,
    refetch,
}: {
    employee: OberonEmployee
    refetch: () => void
}) => {
    const { data: company } = useGetCompany(employee?.companyId)
    const { data: partner } = useGetPartner(employee?.partnerId)
    const [companyName, setCompanyName] = useState('-')
    const [partnerName, setPartnerName] = useState('-')

    useEffect(() => {
        if (company) setCompanyName(company.name)
        if (partner) setPartnerName(partner.name)
    }, [company, partner])

    const handleDeactivateOrphanEmployee = async () => {
        try {
            const responses = await Promise.allSettled([
                deactivateEmployee(employee.id),
                // Delete associated permissions, since Deimos does not check this when (orphan) employees do not have a companyId.
                ...['employee', 'cardAccess'].map((resource) => {
                    return deletePermissionByResourceId({
                        resource,
                        resourceId: employee.id,
                        userId: employee.userId,
                        type: 'owner',
                    })
                }),
            ])

            const rejectedReasons = responses.reduce((acc: string[], response, index) => {
                if (response.status === 'rejected') {
                    acc.push(`[${index + 1}]: ${response.reason}`)
                }
                return acc
            }, [])

            if (rejectedReasons.length > 0) {
                const errorMessage = `
                    ${rejectedReasons.length} errors while deactivating orphan employee:\n
                    ${rejectedReasons.join('\n')}
                `
                throw new Error(errorMessage)
            }

            refetch()
            notification.success({
                message: 'Orphan employee deactivated',
            })
        } catch (error) {
            notification.error({
                message: error as unknown as React.ReactNode,
                duration: null,
            })
        }
    }

    const { type, companyId } = employee
    const title = `Orphan employees are users with incomplete company signup and do not have a 
        company associated. Partner users with an orphan employee record cannot create or be 
        invited to the partner own company. We must delete the orphan employee record to allow 
        them to continue.`
    if (type === 'COMPANY' && companyId === null) {
        return (
            <Tooltip title={title} placement="top">
                <Popconfirm
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    okButtonProps={{ danger: true, 'aria-label': 'Confirm Delete' }}
                    onConfirm={handleDeactivateOrphanEmployee}
                    okText="Delete"
                    placement="bottom"
                    title="Confirm delete orphan employee"
                >
                    <Button
                        aria-label="Delete orphan employee"
                        danger
                        icon={<InfoCircleOutlined />}
                    >
                        Delete
                    </Button>
                </Popconfirm>
            </Tooltip>
        )
    }

    if (type === 'COMPANY' || type === 'BOOKKEEPER') {
        return <Text>{companyName}</Text>
    }
    if (type === 'PARTNER') {
        return <Text>{partnerName}</Text>
    }
    return <Text>Unknown</Text>
}
