import type { FC } from 'react'
import styled from 'styled-components'
import { Timeline, Badge, Typography, Space, Checkbox, Descriptions, Card } from 'antd'
import dayjs from 'packages/dayjs'

import { color, spacing } from 'theme/tokens'
import type { KycCheck } from 'types/styx'
import { snakeCaseToTitleCase, uppercaseFirstLetter } from 'utils/strings'
import { getChecksSortedChronologically } from 'pages/compliance/company/people/helpers'
import { CheckSquareOutlined } from '@ant-design/icons'

export const TimelineContainer = styled.div`
    max-height: 450px;
    overflow: auto;
    padding: 2px;
`

export enum TimelineTypes {
    PEP = 'pep',
    DEFAULT = 'default',
}

const TimeDisplay = styled.span`
    color: ${color.gray};
    margin-left: ${spacing.space24};
    font-size: smaller;
    font-weight: normal;
`

const PepTimeDisplay = styled.span`
    color: ${color.gray};
    font-size: smaller;
    font-weight: normal;
`

const { Text } = Typography

interface Props {
    kycChecks: KycCheck[]
    title?: string
    fetching: boolean
    toggleFalsePositive?: (check: KycCheck, checked: boolean) => void
    timeLineType?: TimelineTypes
    setSelectedPepCheck?: (check: String) => void
    showRecurringMonitoring?: boolean
}

const KycTimeline: FC<React.PropsWithChildren<Props>> = ({
    kycChecks,
    title = 'History',
    fetching,
    toggleFalsePositive,
    timeLineType = TimelineTypes.DEFAULT,
    setSelectedPepCheck,
    showRecurringMonitoring = false,
}) => {
    const showFetchingMessage = fetching && 'Updating Kyc check...'
    const noChecks = kycChecks.length <= 0 && 'No checks yet...'
    const sortedChecks = getChecksSortedChronologically(kycChecks)
    const kycTimelineStatusColour = {
        pass: 'green',
        fail: 'red',
        error: 'red',
        inconclusive: 'gray',
        refer: 'blue',
        expired: 'blue',
        pending: 'gray',
    }

    const recurringStatusBadge = (checkData: KycCheck) => {
        if (!showRecurringMonitoring) return <></>
        if (checkData.recurringMonitoring) {
            return <Badge color="green" text="Recurring monitoring" />
        } else {
            return (
                <>
                    <Badge color="orange" text="No recurring monitoring" />
                    {checkData.transactionMessage ? (
                        <Text>{checkData.transactionMessage}</Text>
                    ) : (
                        <Text></Text>
                    )}
                </>
            )
        }
    }

    const toggleSelectedPepCheck = (check: KycCheck) => {
        setSelectedPepCheck && setSelectedPepCheck(check.id)
    }

    const processedValue = (value: string | undefined) => {
        if (value != null || value != undefined) {
            return (
                <Descriptions>
                    <Descriptions.Item label="Processed value" style={{ padding: 0 }}>
                        {value ? <Text copyable>{value}</Text> : <Text>-</Text>}
                    </Descriptions.Item>
                </Descriptions>
            )
        } else {
            return null
        }
    }

    const isManuallyApproved = (checkData: KycCheck): boolean => {
        return checkData?.result === 'MANUALLY_APPROVED'
    }

    return (
        <TimelineContainer>
            <h3>{title}</h3>
            <br />
            <Timeline
                pending={showFetchingMessage || noChecks}
                items={sortedChecks.map((check: KycCheck) => {
                    const status = check.expiresAt
                        ? 'expired'
                        : (check.result.toLowerCase() as keyof typeof kycTimelineStatusColour)

                    let children
                    if (timeLineType === TimelineTypes.PEP) {
                        children = (
                            <PepTimelineItem
                                check={check}
                                status={status}
                                processedValue={processedValue}
                                isManuallyApproved={isManuallyApproved}
                                recurringStatusBadge={recurringStatusBadge}
                                toggleSelectedPepCheck={toggleSelectedPepCheck}
                                toggleFalsePositive={toggleFalsePositive}
                            />
                        )
                    } else {
                        children = (
                            <DefaultTimelineItem
                                check={check}
                                status={status}
                                processedValue={processedValue}
                                isManuallyApproved={isManuallyApproved}
                                recurringStatusBadge={recurringStatusBadge}
                                toggleFalsePositive={toggleFalsePositive}
                            />
                        )
                    }

                    return {
                        color: status ? kycTimelineStatusColour[status] : undefined,
                        dot: isManuallyApproved(check) ? <CheckSquareOutlined /> : undefined,
                        key: check.id,
                        children: children,
                    }
                })}
            />
        </TimelineContainer>
    )
}

const DefaultTimelineItem: FC<
    React.PropsWithChildren<{
        check: KycCheck
        status: string
        processedValue: (value: string | undefined) => JSX.Element | null
        isManuallyApproved: (checkData: KycCheck) => boolean
        recurringStatusBadge: (checkData: KycCheck) => JSX.Element
        toggleFalsePositive?: (check: KycCheck, checked: boolean) => void
    }>
> = ({
    check,
    status,
    processedValue,
    isManuallyApproved,
    recurringStatusBadge,
    toggleFalsePositive,
}) => {
    return (
        <>
            <h4 data-testid="status">
                {uppercaseFirstLetter(snakeCaseToTitleCase(status))}
                <TimeDisplay>{`${dayjs(check.created).format('lll')}`}</TimeDisplay>
            </h4>
            <Space direction="vertical">
                {processedValue(check.processedValue)}
                {!isManuallyApproved(check) && recurringStatusBadge(check)}
                {toggleFalsePositive && !isManuallyApproved(check) && (
                    <Checkbox
                        checked={check.falsePositive}
                        onChange={(e) => toggleFalsePositive(check, e.target.checked)}
                    >
                        False positive
                    </Checkbox>
                )}
                {isManuallyApproved(check) && (
                    <Text keyboard>by {check.providerId ?? 'unknown'}</Text>
                )}
            </Space>
        </>
    )
}

const PepTimelineItem: FC<
    React.PropsWithChildren<{
        check: KycCheck
        status: string
        processedValue: (value: string | undefined) => JSX.Element | null
        isManuallyApproved: (checkData: KycCheck) => boolean
        recurringStatusBadge: (checkData: KycCheck) => JSX.Element
        toggleSelectedPepCheck: (check: KycCheck) => void
        toggleFalsePositive?: (check: KycCheck, checked: boolean) => void
    }>
> = ({
    check,
    status,
    processedValue,
    isManuallyApproved,
    recurringStatusBadge,
    toggleSelectedPepCheck,
    toggleFalsePositive,
}) => {
    return (
        <Card
            size="small"
            title={uppercaseFirstLetter(snakeCaseToTitleCase(status))}
            hoverable
            onClick={() => toggleSelectedPepCheck(check)}
        >
            <Space direction="vertical">
                <PepTimeDisplay>{`${dayjs(check.created).format('lll')}`}</PepTimeDisplay>
                {processedValue(check.processedValue)}
                {!isManuallyApproved(check) && recurringStatusBadge(check)}
                {toggleFalsePositive && !isManuallyApproved(check) && (
                    <Checkbox
                        checked={check.falsePositive}
                        onChange={(e) => toggleFalsePositive(check, e.target.checked)}
                    >
                        False positive
                    </Checkbox>
                )}
                {isManuallyApproved(check) && (
                    <Text keyboard>by {check.providerId ?? 'unknown'}</Text>
                )}
            </Space>
        </Card>
    )
}

export default KycTimeline
