import { Descriptions, notification } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import type { FC } from 'react'
import { useCardOrder } from 'services/deimos/card-orders'
import { useGetCompany } from 'services/deimos/companies'
import { CardOrderStatus, DisplayDeliveryType } from 'store/modules/support/card-orders/types'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import RecipientTable from '../common/recipient-table'
import { addressPreview, displayManufacturer } from '../utils'

const CardOrderOverview: FC<
    React.PropsWithChildren<{
        modalActive: boolean
        cardOrderId: string
        closeModal: () => void
    }>
> = ({ modalActive, cardOrderId, closeModal }) => {
    const { data: cardOrder, mutations } = useCardOrder(cardOrderId)
    const { data: company } = useGetCompany(cardOrder?.companyId)

    if (!cardOrder) {
        return null
    }

    const onSendToProcessing = async () => {
        try {
            await mutations.sendToProcessing()
            notification.info({ message: 'Successfully sent to processing' })
        } catch (e) {
            notification.error({
                message: 'Failed to send to processing',
                description: (e as Error).message,
            })
        }
    }

    const totalNumberOfCards = cardOrder.names?.length || 0
    const canBeSentToProcessing = cardOrder.status === CardOrderStatus.CREATED
    const overviewButtonAction = () => {
        onSendToProcessing()
        closeModal()
    }

    return (
        <Modal
            title="Card order overview"
            open={modalActive}
            width="auto"
            centered
            onCancel={() => closeModal()}
            okText={'Send to processing'}
            onOk={overviewButtonAction}
            okButtonProps={{ disabled: !canBeSentToProcessing }}
        >
            <ContentContainer>
                <DescriptionsContainer>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Company" span={2}>
                            {company?.name || 'Failed to retrieve'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Company emboss line">
                            {cardOrder.embossLine}
                        </Descriptions.Item>
                        <Descriptions.Item label="Address" span={2}>
                            {addressPreview(cardOrder.address)}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small" column={1}>
                        {cardOrder.deliveryType && (
                            <Descriptions.Item label="Delivery type" span={2}>
                                {DisplayDeliveryType[cardOrder.deliveryType]}
                            </Descriptions.Item>
                        )}
                        <Descriptions.Item label="Recipient name">
                            {cardOrder.address.attention}
                        </Descriptions.Item>
                        <Descriptions.Item label="Recipient tel.">
                            {cardOrder.address.phone ?? '-'}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small" column={1}>
                        <Descriptions.Item label="Manufacturer">
                            {displayManufacturer(cardOrder.manufacturer)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Cards total">
                            {totalNumberOfCards}
                        </Descriptions.Item>
                        <Descriptions.Item label="Fee">
                            {cardOrder.free ? 'No' : 'Yes'}
                        </Descriptions.Item>
                    </Descriptions>
                </DescriptionsContainer>
                <RecipientTable cardOrder={cardOrder} />
            </ContentContainer>
        </Modal>
    )
}

const ContentContainer = styled.div`
    max-width: 900px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .ant-descriptions * {
        width: auto;
    }
`

const DescriptionsContainer = styled.div`
    display: flex;
    gap: ${spacing.space32};
    min-width: 500px;
`

export default CardOrderOverview
