import React, { FC, useState } from 'react'
import { Formik } from 'formik'
import { Form, Select } from 'formik-antd'
import * as Yup from 'yup'
import { Button, Divider, message, Space } from 'antd'

import type { Person, KycCheck } from 'types/styx'
import {
    isDocumentWithoutFiles,
    getDocument,
    getLatestKycCheck,
} from 'pages/compliance/company/people/helpers'
import KycTimeline from 'pages/compliance/shared/kyc-timeline/kyc-timeline'
import { useManagement } from 'services/styx/management'
import { inputWidth } from 'theme/tokens'

const { Option } = Select

const validationSchema = Yup.object()
    .shape({
        result: Yup.string().required(),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

const UpdateIdvResultForm: FC<
    React.PropsWithChildren<{ onSubmit: (values: FormValues) => void }>
> = ({ onSubmit }) => {
    return (
        <Formik initialValues={{ result: '' }} onSubmit={onSubmit}>
            {({ values }) => (
                <Form>
                    <Space>
                        <Select
                            name="result"
                            style={{ width: `${inputWidth.large}` }}
                            placeholder="Manually update IDV result"
                        >
                            <Option value="PASS">PASS</Option>
                            <Option value="FAIL">FAIL</Option>
                        </Select>
                        <Button htmlType="submit" disabled={!values.result}>
                            Update result
                        </Button>
                    </Space>
                </Form>
            )}
        </Formik>
    )
}

interface Props {
    person: Person
    createIdvCheck: () => void
    resumeLatestCheck: () => void
    onUpdateIdvCheck: (values: FormValues) => void
}

export const Idv: FC<React.PropsWithChildren<Props>> = ({
    person,
    createIdvCheck,
    resumeLatestCheck,
    onUpdateIdvCheck,
    children,
}) => {
    const isMissingProofOfId = isDocumentWithoutFiles(getDocument(person.documents, 'PROOF_OF_ID'))
    const isMissingProofOfAddress = isDocumentWithoutFiles(
        getDocument(person.documents, 'PROOF_OF_ADDRESS')
    )

    const handleIdvCheck = () => {
        if (isMissingProofOfId && isMissingProofOfAddress) {
            return message.error(
                'IDV check could not be submitted: Proof of ID and Proof of Address missing'
            )
        }
        createIdvCheck()
    }

    return (
        <div>
            {children}
            <Space>
                <Button data-testid="new-check" onClick={handleIdvCheck}>
                    Run new check
                </Button>
                <Button data-testid="resume-check" type="primary" onClick={resumeLatestCheck}>
                    Resume latest
                </Button>
            </Space>
            <Divider />
            <UpdateIdvResultForm onSubmit={onUpdateIdvCheck} />
        </div>
    )
}

const IdvContainer: FC<React.PropsWithChildren<{ person: Person; refetchPeople: () => void }>> = ({
    person,
    refetchPeople,
}) => {
    const [fetching, setFetching] = useState(false)
    const { mutations } = useManagement()

    const latestIdv = getLatestKycCheck(person.idvChecks)

    const createIdvCheck = async () => {
        try {
            setFetching(true)
            await mutations.createIdvCheck(person.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to create IDV check: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    const resumeIdvCheck = async () => {
        if (!latestIdv) {
            return
        }

        try {
            setFetching(true)
            await mutations.resumeIdvCheck(person.id, latestIdv.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to resume IDV check: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    const onUpdateIdvCheck = async (values: FormValues) => {
        if (!latestIdv) {
            return
        }

        try {
            setFetching(true)
            await mutations.updateIdvCheck(
                person.id,
                latestIdv.id,
                values.result as KycCheck['result']
            )
            refetchPeople()
        } catch (e) {
            message.error(`Failed to update IDV check: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    return (
        <Idv
            person={person}
            createIdvCheck={createIdvCheck}
            resumeLatestCheck={resumeIdvCheck}
            onUpdateIdvCheck={onUpdateIdvCheck}
        >
            <KycTimeline kycChecks={person.idvChecks} fetching={fetching} />
        </Idv>
    )
}

export default IdvContainer
