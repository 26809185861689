import type { FC } from 'react'
import { Typography, message } from 'antd'
import { Formik, FormikHelpers } from 'formik'
import { Form, Select, FormItem, SubmitButton } from 'formik-antd'

import type { Director } from 'types/styx'
import { getDirectorName } from 'pages/compliance/shared/utils'
import KycPopover from 'pages/compliance/company/ownership/edit-ownership/kyc-popover'
import { validationSchema, FormValues } from './validation-schema'
import type { OwnershipMutations } from 'services/deimos/styx-company/ownership'

const { Option, OptGroup } = Select
const { Text } = Typography

interface Props {
    directors?: Director[]
    disabled: boolean
    onRemoveDirectors: OwnershipMutations['removeDirectors']
}

const RemoveDirectorForm: FC<React.PropsWithChildren<Props>> = ({
    directors = [],
    onRemoveDirectors,
    disabled,
}) => {
    const onSubmit = async (
        { selectedDirectors }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ) => {
        try {
            await onRemoveDirectors(selectedDirectors)
            resetForm()
            message.success(`Removed ${selectedDirectors.length} directors!`)
        } catch (e) {
            message.error(`Failed to remove directors: ${(e as Error).message}`)
        }
    }

    const initialValues: FormValues = { selectedDirectors: [] }

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            <Form layout="vertical">
                <FormItem name="selectedDirectors" label="Remove directors">
                    <Select
                        optionFilterProp="label"
                        disabled={disabled}
                        name="selectedDirectors"
                        mode="multiple"
                        placeholder="Select directors to remove"
                    >
                        <OptGroup label="All directors">
                            {directors.map((director) => (
                                <Option
                                    label={getDirectorName(director)}
                                    key={director.directorId}
                                    value={director.directorId}
                                >
                                    <KycPopover entity={director}>
                                        {getDirectorName(director)} -{' '}
                                        <Text type="secondary">{director.position}</Text>
                                    </KycPopover>
                                </Option>
                            ))}
                        </OptGroup>
                    </Select>
                </FormItem>
                <SubmitButton data-testid="submit">Remove</SubmitButton>
            </Form>
        </Formik>
    )
}

export default RemoveDirectorForm
