import type { Amount } from 'types/billing'

export interface Invoice {
    id: string
    callbackId: string
    walletId: string
    companyId: string
    created: string
    reference: string
    amount: Amount
    receivedSum: number
    status: InvoiceStatus
    paymentSystem: PaymentSystem
    walletLoadIds: string[]
    automatic: false
    blockedReason?: string
}
export enum InvoiceStatus {
    PENDING = 'PENDING',
    LOADED = 'LOADED',
    QUEUED = 'QUEUED',
    APPROVED = 'APPROVED',
    MANUALLY_APPROVED = 'MANUALLY_APPROVED',
    CANCELLED = 'CANCELLED',
    BLOCKED = 'BLOCKED',
    FAILED = 'FAILED',
}

export enum PaymentSystem {
    TRUSTLY = 'TRUSTLY',
    INTERNAL = 'INTERNAL',
    INPAY = 'INPAY',
    TELESTO = 'TELESTO',
}
