import useSWR from 'swr'
import { reject, isNil } from 'ramda'
import { useParams } from 'react-router-dom'
import type { ErrorResponse } from '@pleo-io/deimos'

import type {
    DocumentFileRequest,
    DocumentRequest,
    KycCheck,
    Management,
    Person,
    UpdatePersonRequest,
} from 'types/styx'
import { isUbo } from 'utils/is-shareholder'
import { getLatestKycCheck } from 'pages/compliance/company/people/helpers'
import { newManagementFetcher, shareholdersFetcher } from 'services/styx/ownership'
import {
    createPersonDocument,
    deletePersonDocument,
    updatePersonDocument,
    updatePerson as updateStyxPerson,
    createPersonIdvCheck,
    resumePersonIdvCheck,
    updatePersonIdvCheck,
    createPersonPepSisScreening,
    createPersonEkycCheck,
    expireEkycCheck,
    createPersonDocumentFile,
    deletePersonDocumentFile,
    editPersonDocumentFile,
    getPersonPepChecks,
    getPersonSisChecks,
    setKycScope,
} from 'services/styx/person'
import { manuallyApprovePepCheck, updateKycFalsePositive } from 'services/styx/kyc-checks'

const removeFromKycScope = (subjectId: string) => setKycScope(subjectId, false)

const addToKycScope = (subjectId: string) => setKycScope(subjectId, true)

const toggleKycFalsePositive = (checkId: string, falsePositive: boolean): Promise<KycCheck> =>
    updateKycFalsePositive(checkId, falsePositive)

const createPersonMutator =
    (subjectId: string, mutatePerson: (person: Person) => Person) => (management?: Management) =>
        management?.map((it) => (it.id === subjectId && !isUbo(it) ? mutatePerson(it) : it))

export const useManagement = () => {
    const { id: deimosCompanyId } = useParams()

    const response = useSWR<Management, ErrorResponse>(
        deimosCompanyId ? `management-${deimosCompanyId}` : null,
        newManagementFetcher(deimosCompanyId),
        {
            shouldRetryOnError: false,
        }
    )

    const shareholdersResponse = useSWR<Management, ErrorResponse>(
        deimosCompanyId ? `shareholders-${deimosCompanyId}` : null,
        shareholdersFetcher(deimosCompanyId),
        {
            shouldRetryOnError: false,
        }
    )

    /**
     * Always filter out missing from management
     *
     * Due to legacy reasons, these entities are still returned from the API
     * "missing" is true if this is a missing shareholder
     *
     * A missing shareholder is defined as the percentage of shares
     * and voting rights that are not defined in the registry.
     * If these shares belonged to someone, they would ensure that
     * the sum of all shareholders would add up to 100%.
     *
     * These are generally entities that compliance will manually assign as
     * shareholders while editing ownership structure, which removes them
     * from the company management.
     */

    const management = response.data?.filter((it) => !it.missing)
    const shareholders = shareholdersResponse.data?.filter((it) => !it.missing)

    // check if any of the management have been manually approved PEP
    const hasManuallyApprovedPep = management?.some(
        (person) =>
            'pepChecks' in person &&
            getLatestKycCheck(person.pepChecks)?.result === 'MANUALLY_APPROVED'
    )

    const updatePerson = async (subjectId: string, body: UpdatePersonRequest) => {
        await updateStyxPerson(subjectId, body)

        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                ...reject(
                    isNil,
                    // No idea why this started being an issue
                    // @ts-expect-error Type 'UpdatePersonRequest' is not assignable to type 'Record<string, unknown>'
                    body
                ),
            }))
        )

        shareholdersResponse.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                ...reject(
                    isNil,
                    // No idea why this started being an issue
                    // @ts-expect-error Type 'UpdatePersonRequest' is not assignable to type 'Record<string, unknown>'
                    body
                ),
            }))
        )
    }

    const addToScope = async (subjectId: string) => {
        await addToKycScope(subjectId)

        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                kycPerson: true,
            }))
        )
    }

    const removeFromScope = async (subjectId: string) => {
        await removeFromKycScope(subjectId)

        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                kycPerson: false,
            }))
        )
    }

    const createEkycCheck = async (subjectId: string) => {
        const ekycCheck = await createPersonEkycCheck(subjectId)

        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                ekycChecks: [...it.ekycChecks, ekycCheck],
            }))
        )
    }

    const expiryEkycCheck = async (subjectId: string, checkId: string) => {
        const eKycCheck = await expireEkycCheck(subjectId, checkId)
        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                ekycChecks: it.ekycChecks.map((check) =>
                    check.id === eKycCheck.id ? eKycCheck : check
                ),
            }))
        )
    }

    const createDocument = async (subjectId: string, body: DocumentRequest) => {
        const document = await createPersonDocument(subjectId, body)

        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                documents: [...it.documents, document],
            }))
        )
    }

    const deleteDocument = async (subjectId: string, documentId: string) => {
        await deletePersonDocument(subjectId, documentId)

        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                documents: it.documents.filter((document) => document.id !== documentId),
            }))
        )
    }

    const updateDocument = async (subjectId: string, documentId: string, body: DocumentRequest) => {
        const document = await updatePersonDocument(subjectId, documentId, body)

        response.mutate(
            createPersonMutator(subjectId, (person) => ({
                ...person,
                documents: person.documents.map((it) => (it.id === documentId ? document : it)),
            }))
        )
    }

    const createDocumentFile = async (subjectId: string, documentId: string, file: FormData) => {
        const documentFile = await createPersonDocumentFile(subjectId, documentId, file)

        response.mutate(
            createPersonMutator(subjectId, (person) => ({
                ...person,
                documents: person.documents.map((document) =>
                    document.id === documentId
                        ? { ...document, files: [...document.files, documentFile] }
                        : document
                ),
            }))
        )
    }

    const deleteDocumentFile = async (subjectId: string, documentId: string, fileId: string) => {
        await deletePersonDocumentFile(subjectId, documentId, fileId)
        await response.mutate()
    }

    const editDocumentFile = async (
        subjectId: string,
        documentId: string,
        fileId: string,
        documentFileRequest: DocumentFileRequest
    ) => {
        await editPersonDocumentFile(subjectId, documentId, fileId, documentFileRequest)
        await response.mutate()
    }

    const toggleKycCheckFalsePositive = async (checkId: string, falsePositive: boolean) => {
        await toggleKycFalsePositive(checkId, falsePositive)
        await response.mutate()
    }

    const createIdvCheck = async (subjectId: string) => {
        const idvCheck = await createPersonIdvCheck(subjectId)

        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                idvChecks: [...it.idvChecks, idvCheck],
            }))
        )
    }

    const resumeIdvCheck = async (subjectId: string, checkId: string) => {
        const idvCheck = await resumePersonIdvCheck(subjectId, checkId)

        response.mutate(
            createPersonMutator(subjectId, (person) => ({
                ...person,
                idvChecks: person.idvChecks.map((it) => (it.id === checkId ? idvCheck : it)),
            }))
        )
    }

    const updateIdvCheck = async (
        subjectId: string,
        checkId: string,
        result: KycCheck['result']
    ) => {
        const idvCheck = await updatePersonIdvCheck(subjectId, checkId, result)

        response.mutate(
            createPersonMutator(subjectId, (person) => ({
                ...person,
                idvChecks: person.idvChecks.map((it) => (it.id === checkId ? idvCheck : it)),
            }))
        )
    }

    const createPepSisScreening = async (subjectId: string) => {
        await createPersonPepSisScreening(subjectId)

        const [pepChecks, sisChecks] = await Promise.all([
            getPersonPepChecks(subjectId),
            getPersonSisChecks(subjectId),
        ])

        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                pepChecks,
                sisChecks,
            }))
        )
    }

    const setPepAsManuallyApproved = async (subjectId: string) => {
        await manuallyApprovePepCheck(subjectId)

        response.mutate(
            createPersonMutator(subjectId, (it) => ({
                ...it,
                pepChecks: it.pepChecks,
            }))
        )
    }

    return {
        ...response,
        management,
        shareholders,
        hasManuallyApprovedPep,
        mutations: {
            updatePerson,
            addToScope,
            removeFromScope,
            createEkycCheck,
            expiryEkycCheck,
            createDocument,
            deleteDocument,
            updateDocument,
            createDocumentFile,
            deleteDocumentFile,
            editDocumentFile,
            toggleKycCheckFalsePositive,
            createIdvCheck,
            resumeIdvCheck,
            updateIdvCheck,
            createPepSisScreening,
            setPepAsManuallyApproved,
        },
    }
}
