import styled from 'styled-components'

export const FormOverrides = styled.div`
    max-width: 600px;

    form {
        .ant-space.ant-space-horizontal.ant-space-align-center {
            width: 100%;
        }

        .ant-space-item {
            width: 100%;
        }
        .ant-picker {
            width: 100%;
        }
        .ant-form-item {
            margin-bottom: 8px;
        }
    }

    .ant-descriptions-item-container {
        align-items: center;
    }

    .ant-descriptions-item + .ant-descriptions-item {
        padding-left: 1em;
    }

    .ant-descriptions-item-content .ant-form-item {
        width: 100%;
        margin-bottom: 0;
    }
`
