export interface UserRole {
    id: string
    userId: string
    parentResource: string | null
    parentResourceId: string | null
    resource: string
    resourceId: string
    type: RoleType
    createdAt: string
}

/**
 * Kerberos role types are in lowercase with hyphens
 */
export enum RoleType {
    OWNER = 'owner',
    MEMBER = 'member',
    BOOKKEEPER = 'bookkeeper',
    BOOKKEEPER_BASIC = 'bookkeeper-basic',
    BOOKKEEPER_EXTENDED = 'bookkeeper-extended',
    BOOKKEEPER_MANAGED = 'bookkeeper-managed',
}
