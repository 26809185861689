import type { ChangeEvent } from 'react'
import { useUsers } from 'services/auth/users'
import { Card, Input } from 'antd'

import { ContentContainer, PageContentLayout } from 'components/layout-containers'

import Users from './users'
import { inputWidth } from 'theme/tokens'

const ExternalUserProvisioning = () => {
    const { users, setUsersQuery, mutations } = useUsers()

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setUsersQuery(event.target.value)
    }

    return (
        <PageContentLayout>
            <Card>
                <ContentContainer>
                    <Input
                        placeholder="Search for users"
                        onChange={onSearch}
                        style={{ width: inputWidth.huge }}
                    />
                    <Users
                        users={users}
                        setUsersQuery={setUsersQuery}
                        grantReclaimVatPermission={mutations.grantReclaimVatPermission}
                        revokeReclaimVatPermission={mutations.revokeReclaimVatPermission}
                    />
                </ContentContainer>
            </Card>
        </PageContentLayout>
    )
}

export default ExternalUserProvisioning
