import React, { FC, useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { inputWidth } from 'theme/tokens'
import { BreakText, ContentContainer } from 'components/layout-containers'
import { Card, Input, Table } from 'antd'
import type { SortOrder } from 'antd/lib/table/interface'
import type { ColumnProps } from 'antd/es/table'

import { DisplayRole, OberonEmployee, OberonEmployeeRole } from 'types/employee'
import {
    filterOberonEmployeesByNameOrEmail,
    getOberonEmployeeName,
    getUserDisplayRoleByOberonEmployeeRole,
} from 'utils/employees'
import { bff } from 'pages/compliance/bff'

interface Props {
    companyId: string
    employees: OberonEmployee[]
    fetchingEmployees: boolean
}

export const Employees: FC<React.PropsWithChildren<Props>> = ({ employees, fetchingEmployees }) => {
    const columns: ColumnProps<OberonEmployee>[] = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            render: (_: string, employee: OberonEmployee) => getOberonEmployeeName(employee),
            sorter: (a: OberonEmployee, b: OberonEmployee) =>
                getOberonEmployeeName(a).localeCompare(getOberonEmployeeName(b)),
            defaultSortOrder: 'ascend' as SortOrder,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            filters: Object.values(DisplayRole).map((value) => ({
                value,
                text: value,
            })),
            onFilter: (value, employee) => getUserDisplayRoleByOberonEmployeeRole(employee.role) === value,
            render: (role: OberonEmployeeRole) => getUserDisplayRoleByOberonEmployeeRole(role),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (email) => <BreakText>{email}</BreakText>,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
        },
    ]
    return (
        <ContentContainer>
            <Table
                loading={fetchingEmployees}
                dataSource={employees}
                columns={columns}
                pagination={{ pageSizeOptions: ['10', '20', '50', '100', '200'] }}
                rowKey="id"
            />
        </ContentContainer>
    )
}

export const EmployeesContainer: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    const [filter, setFilter] = useState('')
    const { data: employees, isLoading } = bff.closedCompanyEmployeesRouter.getEmployees.useQuery({
        companyId,
    })
    const handleFilter = (event: ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)
    const filteredEmployees = employees ? filterOberonEmployeesByNameOrEmail(employees, filter) : []

    return (
        <Card>
            <ContentContainer>
                <Row>
                    <Input
                        placeholder="Filter by employee name"
                        onChange={handleFilter}
                        style={{ width: `${inputWidth.medium}` }}
                    />
                </Row>
                <Employees
                    companyId={companyId}
                    employees={filteredEmployees}
                    fetchingEmployees={isLoading}
                />
            </ContentContainer>
        </Card>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`

export default EmployeesContainer
