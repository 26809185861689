import type { Employee } from 'types/employee'
import { Button, List, Modal, Space, Tag } from 'antd'
import {
    DirectorInfo,
    getDirectorsInfo,
    isDirector,
} from './higher-limit-application-section.helpers'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectEmployees } from 'store/modules/employees/selectors'
import type { Person, UboResponse } from 'types/styx'

interface Props {
    management: (Person | UboResponse)[] | undefined
    requestingEmployee: Employee | null | undefined
}

export default function HigherLimitDirectorsInfo({ requestingEmployee, management }: Props) {
    const employees = useSelector(selectEmployees)

    const [directorsInfo, setDirectorsInfo] = useState<DirectorInfo[] | null>(null)

    return (
        <>
            <Space direction="horizontal">
                {isDirector(requestingEmployee, management) ? (
                    <Tag color="green">Yes</Tag>
                ) : (
                    <Tag color="red">No</Tag>
                )}
                <Button
                    icon={<InfoCircleOutlined />}
                    type="link"
                    onClick={() => setDirectorsInfo(getDirectorsInfo(management, employees))}
                >
                    Directors
                </Button>
            </Space>
            <DirectorsInfoModal
                directorsInfo={directorsInfo}
                onCancel={() => setDirectorsInfo(null)}
            />
        </>
    )
}

interface DirectorsInfoModalProps {
    directorsInfo: DirectorInfo[] | null
    onCancel: () => void
}

function DirectorsInfoModal({ directorsInfo, onCancel }: DirectorsInfoModalProps) {
    return (
        <Modal
            open={Boolean(directorsInfo)}
            onCancel={onCancel}
            footer={null}
            centered
            title="Directors"
        >
            <List
                itemLayout="horizontal"
                dataSource={directorsInfo ?? []}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta title={item.name} description={item.email} />
                    </List.Item>
                )}
            />
        </Modal>
    )
}
