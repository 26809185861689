import { useState } from 'react'
import useSWR from 'swr'
import qs from 'qs'

import { kerberosRequest, buildFetcher } from 'services/request'
import type { GetUser, User } from 'types/user'
import { useDebounce } from 'utils/use-debounce'

const fetcher = buildFetcher(kerberosRequest)

export function deleteUser(userId: string) {
    return kerberosRequest().delete(`api/v1/users/${userId}`)
}

export function getUsers(query: string) {
    return kerberosRequest().get('api/v1/admin/users', {
        searchParams: { query },
    })
}

export function getUser(userId: string) {
    return kerberosRequest().get('api/v1/admin/users', {
        searchParams: { query: '', admin: 'false', userId },
    })
}

const getDeletedUser = (url: string, employeeId: string) =>
    kerberosRequest()
        .get(url, {
            searchParams: { employeeId },
        })
        .then((res) => res.json())

export const useGetDeletedUser = (employeeId: string) =>
    useSWR<User, Error>(
        employeeId ? ['api/v1/admin/users/deleted', employeeId] : '',
        getDeletedUser,
        {
            shouldRetryOnError: false,
        }
    )

export function resetPasscode(userId: string) {
    return kerberosRequest().post(`sca/reset/${userId}/passcode`)
}

export function resetTwoFactor(userId: string) {
    return kerberosRequest().post(`sca/reset/${userId}/tfa`)
}

export function resetPinAndInactivateRefreshToken(userId: string) {
    return kerberosRequest().post(
        `api/v1/admin/user/${userId}/reset-pin-and-inactivate-refresh-token`
    )
}

export function deleteRefreshTokens(userId: string) {
    return kerberosRequest().post(`rest/v1/admin/user/${userId}/delete-refresh-tokens`)
}

export function resendChangeEmail(userId: string) {
    return kerberosRequest().post(`api/v1/users/${userId}/email-change-verification`)
}

export function changeEmail(userId: string, email: string) {
    return kerberosRequest().put(`api/v1/users/${userId}`, { json: { email } })
}

export function setUserAdmin(userId: string) {
    return kerberosRequest().post(`api/v1/admin/users/${userId}`)
}

export function unsetUserAdmin(userId: string) {
    return kerberosRequest().delete(`api/v1/admin/users/${userId}`)
}

export function setUserAdminPermission(userId: string, resourceId: string) {
    return kerberosRequest().post(`api/v1/admin/users/${userId}/permissions`, {
        json: { resourceId },
    })
}

export function unsetUserAdminPermission(userId: string, resourceId: string) {
    return kerberosRequest().delete(`api/v1/admin/users/${userId}/permissions`, {
        searchParams: { resourceId },
    })
}

export function setReclaimVatPermission(userId: string, resourceId: string) {
    return kerberosRequest().post(`api/v1/permissions/reclaimVAT/${resourceId}`, {
        json: { userId, type: 'member' },
    })
}

export function unsetReclaimVatPermission(userId: string, resourceId: string) {
    return kerberosRequest().delete(`api/v1/permissions/reclaimVAT/${resourceId}?userId=${userId}`)
}

export function useGetUser(userId?: string | null) {
    const { data, error, isValidating } = useSWR<GetUser>(
        userId ? `rest/v1/admin/user/${userId}` : null,
        fetcher,
        {
            onErrorRetry: (requestError, key, config, revalidate, { retryCount }) => {
                if (requestError.message === 'user_not_found') return

                revalidate({ retryCount })
            },
        }
    )

    return { data, error, isValidating }
}

export function useUsers(includeBackofficeAdmins?: boolean) {
    const [query, setQuery] = useState('')
    const debouncedQuery = useDebounce(query, 500)
    const { data: users, mutate } = useSWR<User[]>(
        `api/v1/admin/users?${qs.stringify({
            query: debouncedQuery,
            admin: includeBackofficeAdmins,
        })}`,
        fetcher
    )

    const setAdminPermission = async (userId: string, resourceId: string) => {
        await setUserAdminPermission(userId, resourceId)
        mutate()
    }

    const unsetAdminPermission = async (userId: string, resourceId: string) => {
        await unsetUserAdminPermission(userId, resourceId)
        mutate()
    }

    const grantAdminAccess = async (userId: string) => {
        await setUserAdmin(userId)
        mutate()
    }

    const revokeAdminAccess = async (userId: string) => {
        await unsetUserAdmin(userId)
        mutate()
    }

    const grantReclaimVatPermission = async (userId: string, resourceId: string) => {
        await setReclaimVatPermission(userId, resourceId)
        mutate()
    }

    const revokeReclaimVatPermission = async (userId: string, resourceId: string) => {
        await unsetReclaimVatPermission(userId, resourceId)
        mutate()
    }
    return {
        users,
        setUsersQuery: setQuery,
        mutations: {
            setAdminPermission,
            unsetAdminPermission,
            revokeAdminAccess,
            grantAdminAccess,
            grantReclaimVatPermission,
            revokeReclaimVatPermission,
        },
    }
}

export type UsersMutations = ReturnType<typeof useUsers>['mutations']
