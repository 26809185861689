import styled, { css } from 'styled-components'
import { Alert } from 'antd'
import { CalendarOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { green, red, yellow } from '@ant-design/colors'

const AlertBase = css`
    display: inline-flex;
    padding: 4px 10px;
    .ant-alert-message {
        color: inherit;
    }
`

export const On = styled(Alert).attrs({
    type: 'success',
    message: 'On',
    icon: <CheckOutlined />,
    showIcon: true,
})`
    ${AlertBase};
    color: ${green[5]};
`
export const Off = styled(Alert).attrs({
    type: 'error',
    message: 'Off',
    icon: <CloseOutlined />,
    showIcon: true,
})`
    ${AlertBase};
    color: ${red[5]};
`
export const AddOn = styled(Alert).attrs({
    type: 'warning',
    message: '$',
    showIcon: false,
})`
    ${AlertBase};
    color: ${yellow[5]};
    margin-left: 1em;
`
export const Scheduled = styled.div.attrs({
    children: <CalendarOutlined />,
})`
    margin-left: 1em;
`
export const FeatureIcons = styled.div`
    display: flex;
    align-items: center;
`
