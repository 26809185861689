import { EditOutlined } from '@ant-design/icons'
import { Alert, Button, Col, message, Modal, Row, Space, Statistic, Typography } from 'antd'
import ErrorState from 'components/error-state/error-state'
import { ContentContainer } from 'components/layout-containers'
import { Formik } from 'formik'
import { Form, FormItem, Input, Select } from 'formik-antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { usePartnerProgramInfo } from 'services/deimos/partners'
import { PartnerTier } from 'types/partner'
import * as Yup from 'yup'

const { Text } = Typography

const tierNames: Record<PartnerTier, string> = {
    [PartnerTier.TRIAL]: 'New partner',
    [PartnerTier.BRONZE]: 'Bronze',
    [PartnerTier.SILVER]: 'Silver',
    [PartnerTier.GOLD]: 'Gold',
    [PartnerTier.GOLD_DACH]: 'Gold',
    [PartnerTier.PLATINUM]: 'Platinum',
    [PartnerTier.PLATINUM_DACH]: 'Platinum',
}

export const PartnerProgram = () => {
    const { id } = useParams()
    const [showOverrideTier, setShowOverrideTier] = useState(false)
    const { data, isValidating, error, mutate } = usePartnerProgramInfo(id)

    if (error?.response.status === 404) {
        return (
            <ContentContainer>
                <Space direction="vertical" size="middle">
                    <Alert
                        description="This partner has not been enrolled in the Partner Program yet."
                        type="info"
                    />
                </Space>
            </ContentContainer>
        )
    }

    if (!data && error && !isValidating) {
        return (
            <ContentContainer>
                <ErrorState onRetry={() => mutate()} />
            </ContentContainer>
        )
    }

    return (
        <ContentContainer>
            <Space direction="vertical" size="middle">
                {data?.calculatedTier && (
                    <Alert
                        description={
                            data.onboardingPeriod.daysToEnd > 0
                                ? `This partner's tier has an override. Prior to this, they would be on the New Partner tier.`
                                : `This partner tier has an override. Without the override, they would be a ${
                                      tierNames[data.calculatedTier.type]
                                  } tier partner.`
                        }
                        type="warning"
                    />
                )}
                <Row justify="space-between" gutter={[24, 24]}>
                    <Col span={8}>
                        <Row align="bottom">
                            <Statistic
                                loading={!data}
                                title="Current Tier"
                                value={data?.currentTier ? tierNames[data.currentTier.type] : '-'}
                                suffix={
                                    <Button
                                        onClick={() => setShowOverrideTier(true)}
                                        type="link"
                                        style={{ padding: 4 }}
                                    >
                                        <EditOutlined /> override
                                    </Button>
                                }
                            />
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="Partnership year"
                            value={data?.partnershipYear.daysToEnd || 0}
                            suffix={<Text style={{ fontSize: 16 }}> days left</Text>}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="Onboarding period"
                            value={data?.onboardingPeriod.daysToEnd || 0}
                            suffix={<Text style={{ fontSize: 16 }}> days left</Text>}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="Free users"
                            value={data?.partnershipYear.freeUsers || 0}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="Paid users"
                            value={data?.partnershipYear.paidUsers || 0}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="New clients"
                            value={data?.partnershipYear.newClients || 0}
                        />
                    </Col>
                </Row>
            </Space>
            <OverrideTier close={() => setShowOverrideTier(false)} visible={showOverrideTier} />
        </ContentContainer>
    )
}

const validationSchema = Yup.object().shape({
    newTier: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
})

function OverrideTier({ close, visible }: { close: () => void; visible: boolean }) {
    const { id } = useParams()
    const { mutations } = usePartnerProgramInfo(id)

    const override = async (values: { newTier: PartnerTier; message: string }) => {
        try {
            await mutations.override(values)
            message.success('Partner tier successfully overridden.')
            close()
        } catch (error) {
            if (error instanceof Error) {
                message.error(`Could not override partner tier: ${error.message}`)
            } else {
                message.error('Could not override partner tier. Please try again later.')
            }
        }
    }

    return (
        <Modal title="Override Partner Tier" open={visible} onCancel={close} footer={null}>
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                <Alert
                    description={`You will override the existing partner tiers for the current partnership year`}
                    type="warning"
                />
                <Formik
                    initialValues={{
                        newTier: PartnerTier.BRONZE,
                        message: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={override}
                    validateOnMount
                >
                    {({ isValid, isSubmitting }) => (
                        <Form layout="vertical">
                            <FormItem name="newTier" label="Select new tier">
                                <Select name="newTier" style={{ marginTop: 8, width: '100%' }}>
                                    <Select.Option value={PartnerTier.BRONZE}>Bronze</Select.Option>
                                    <Select.Option value={PartnerTier.SILVER}>Silver</Select.Option>
                                    <Select.Option value={PartnerTier.GOLD}>Gold</Select.Option>
                                    <Select.Option value={PartnerTier.PLATINUM}>
                                        Platinum
                                    </Select.Option>
                                </Select>
                            </FormItem>
                            <FormItem name="message" label="Reason for overriding">
                                <Input.TextArea
                                    rows={3}
                                    name="message"
                                    placeholder="Why was an override needed?"
                                />
                            </FormItem>
                            <Row justify="end">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={isSubmitting}
                                    disabled={!isValid}
                                >
                                    Override
                                </Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Space>
        </Modal>
    )
}
