import { useState, useEffect } from 'react'
import { Result, Typography } from 'antd'
import { ClockCircleFilled } from '@ant-design/icons'
import { useJoke } from './loading-joke'

const { Text, Paragraph } = Typography

const formatTime = (currentTimeInSeconds: number) => {
    const minute = Math.floor(currentTimeInSeconds / 60)
    const seconds = String(currentTimeInSeconds - 60 * minute).padStart(2, '0')

    return `${minute}:${seconds}`
}

const useTimer = () => {
    const [seconds, setSeconds] = useState(0)
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds(seconds + 1)
        }, 1000)
        return () => clearInterval(interval)
    }, [seconds])

    return { seconds }
}

const Loading = () => {
    const { seconds } = useTimer()
    const joke = useJoke()
    return (
        <Result
            icon={<ClockCircleFilled />}
            title="Creating demo account!"
            subTitle="This usually takes around 2 minutes."
            extra={[
                <div key="joke">
                    <Paragraph>{joke.setup}</Paragraph>
                    <Paragraph type="secondary">{joke.punchline}</Paragraph>
                </div>,
                <Text strong key="timer">
                    {formatTime(seconds)}
                </Text>,
            ]}
        />
    )
}

export default Loading
