import type { FC } from 'react'

import { usePermissions } from 'providers/user-context'

interface Props {
    permissions?: string[]
    fallback?: JSX.Element
}

export const useHasPermissions = (permissions: string[] = []) => {
    const userPermissions = usePermissions()
    return permissions.some((permission) => userPermissions.includes(permission))
}

const AnyOfPermissionGuard: FC<React.PropsWithChildren<Props>> = ({
    children,
    permissions = [],
    fallback,
}) => {
    const hasPermission = useHasPermissions(permissions)

    if (hasPermission) {
        return <>{children}</>
    }

    return fallback ?? null
}

export default AnyOfPermissionGuard
