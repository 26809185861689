import { Button, Card, Form, Input, Tabs } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import { CompanyResultsTable } from '../../components/company-results-table'
import { OrganizationResultsTable } from '../../components/organization-results-table'
import { Link, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { PageHeader } from '@ant-design/pro-layout'

const DEFAULT_TAB = 'companies'

const tabItems = [
    {
        label: 'Companies',
        key: 'companies',
        children: <CompanyResultsTable />,
    },
    {
        label: 'Organizations',
        key: 'organizations',
        children: <OrganizationResultsTable />,
    },
]

export const Company360Dashboard = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const query = searchParams.get('query') || ''
    const tab = searchParams.get('tab') || DEFAULT_TAB

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value
        searchParams.set('query', searchValue)
        setSearchParams(searchParams)
    }

    const onTabChange = (activeKey: string) => {
        searchParams.set('tab', activeKey)
        setSearchParams(searchParams)
    }

    // Force the tab back to default if it's set incorrectly
    useEffect(() => {
        if (!tabItems.map((t) => t.key).includes(tab)) {
            searchParams.set('tab', DEFAULT_TAB)
            setSearchParams(searchParams)
        }
    }, [searchParams, setSearchParams, tab])

    return (
        <PageContentLayout>
            <PageHeader
                extra={
                    <Button type="primary">
                        <Link to={'/customer-success/company-360/advanced-search'}>
                            Advanced company search
                        </Link>
                    </Button>
                }
            />
            <Card styles={{ body: { padding: 0 } }}>
                <Form
                    layout="vertical"
                    style={{ padding: '16px 16px 0' }}
                    initialValues={{ searchQuery: query }}
                >
                    <Form.Item name="searchQuery" label={<strong>Search by name or ID</strong>}>
                        <Input
                            onChange={handleSearch}
                            autoFocus
                            data-testid="company-360-search-input"
                        />
                    </Form.Item>
                </Form>
                <Tabs
                    items={tabItems}
                    activeKey={tab}
                    onChange={onTabChange}
                    tabBarStyle={{ padding: '0 16px', margin: 0 }}
                    defaultActiveKey="companies"
                />
            </Card>
        </PageContentLayout>
    )
}
