import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import styled from 'styled-components'

export const AppStoreLink = () => {
    return (
        <Popover content="This will be the same for Android">
            <StyledLink
                href="https://apps.apple.com/app/pleo/id1110743278"
                target="_blank"
                rel="noreferrer"
            >
                View Current Pleo App Version on the App Store <InfoCircleOutlined />
            </StyledLink>
        </Popover>
    )
}

const StyledLink = styled.a`
    font-weight: normal;
    font-size: 0.875rem;
`
