import { ImportOutlined as AntImportOutlined } from '@ant-design/icons'
import { Button as AntButton, Card as AntCard } from 'antd'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

export const FormContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: ${spacing.space24};
`

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: ${spacing.space24};
`

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

export const RecipientsFormContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(7, auto);
    grid-gap: ${spacing.space24};
    margin-bottom: ${spacing.space24};
`

export const AddOrderButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-content: stretch;
`

export const AddOrderButton = styled(AntButton)`
    width: 100%;
`

export const ImportCSVContainer = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: ${spacing.space24};
    margin-left: auto;
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: ${spacing.space24};
`

export const Card = styled(AntCard)`
    margin-top: -${spacing.space24};
    border-top: 0;
`

export const ListItem = styled.div`
    margin-top: ${spacing.space8};
`

export const Button = styled(AntButton)``

export const DeleteButton = styled(AntButton)`
    align-self: center;
`

export const ImportOutlined = styled(AntImportOutlined)`
    color: #08c;
`

export const Error = styled.div`
    color: red;
`
