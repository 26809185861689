import type { CompanyStakeholderResponse } from 'bff/moons/generated/styx-v1'

interface Props {
    shareholder: CompanyStakeholderResponse
}

export const CompanyShareholderDetails: React.FC<Props> = ({ shareholder }) => {
    return (
        <>
            <p>Legal Name: {shareholder.legalName}</p>
            <p>Ownership Percentage: {shareholder.ownershipPercentage}%</p>
            <p>Country: {shareholder.address?.country}</p>
        </>
    )
}
