import { useParams } from 'react-router-dom'
import { deimosRequest, buildFetcher } from 'services/request'
import useSWR from 'swr'

const fetcher = buildFetcher(deimosRequest)

export enum ProviderName {
    GPS = 'GPS',
    ENFUCE = 'ENFUCE',
}

export type SpendingStatus = 'LIVE' | 'BLOCKED' | 'CLOSED' | null

export interface CompanySpendingState {
    companyId: string | null
    status: SpendingStatus
    providerName: ProviderName | null
    error?: string
    statusReason: string
}

export function useCompanySpendStatus() {
    const { id: companyId } = useParams()

    const result = useSWR<CompanySpendingState>(
        companyId ? `rest/v1/admin/companies/${companyId}/status` : '',
        fetcher
    )
    return result
}
