import { Route, Routes } from 'react-router-dom'
import { CompanyReviewStructure } from './screens/company-review-structure'

export const CompanyReviewStructurePage = () => {
    return (
        <Routes>
            <Route path="/" element={<CompanyReviewStructure />} />
        </Routes>
    )
}
