import { countries } from 'countries-list'
import type { Alpha2Code } from 'types/countries'
import nationalities from 'i18n-nationality'
import en from 'i18n-nationality/langs/en.json'

nationalities.registerLocale(en)

export function getCountryCode(countryName?: string): string | null {
    for (const code in countries) {
        if (countries[code as Alpha2Code].name === countryName) {
            return code
        }
    }
    return null
}

export function getCountryName(countryCode?: string): string | null {
    return countryCode ? countries[countryCode as Alpha2Code]?.name ?? null : null
}

export function getCountryDetailsFromCodeOrNationality(countryCodeOrNationality: string) {
    const isCode = countryCodeOrNationality.length === 2
    const countryCode = isCode
        ? countryCodeOrNationality
        : nationalities.getAlpha2Code(countryCodeOrNationality, 'en')
    const countryName = getCountryName(countryCode) ?? ''
    const nationality = isCode ? nationalities.getName(countryCode, 'en') : countryCodeOrNationality

    return { countryCode, countryName, nationality }
}

export const useCountriesList = () => {
    return [
        { countryCode: 'AT' },
        { countryCode: 'DK' },
        { countryCode: 'FI' },
        { countryCode: 'FR' },
        { countryCode: 'DE' },
        { countryCode: 'IE' },
        { countryCode: 'ES' },
        { countryCode: 'SE' },
        { countryCode: 'GB' },
        { countryCode: 'EE' },
        { countryCode: 'NL' },
        { countryCode: 'BE' },
        { countryCode: 'LU' },
        { countryCode: 'PT' },
        { countryCode: 'IT' },
        { countryCode: 'NO' },
        { countryCode: 'US' },
    ] as { countryCode: Alpha2Code }[]
}
