import config from 'config'
import ky from 'ky/umd'

const clientId = config.auth.oauth.clientId

// duplicate definition in order to avoid circular dependencies
const kerberosRequest = () =>
    ky.extend({
        prefixUrl: config.endpoints.auth,
    })

export const getGoogleLoginUrl = () => {
    const redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    const url = new URL('sca/admin/login', config.endpoints.auth)
    url.searchParams.append('clientId', clientId)
    url.searchParams.append('redirectUri', redirectUri)
    url.searchParams.append('setSession', 'true')

    return url.toString()
}

export const logout = () =>
    kerberosRequest().post('sca/admin/logout', {
        credentials: 'include',
    })

export const checkSession = (idToken?: string): Partial<any> =>
    kerberosRequest().get('sca/admin/session', {
        credentials: 'include',
        searchParams: idToken ? { idToken } : {},
    })
