import { Card, Descriptions, Space, Popover, Table, Typography, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import type { FC } from 'react'

import type {
    Subscription,
    Company,
    Pricing,
    SubscriptionRatePlan,
} from 'bff/moons/generated/beyond'

import { formatMoneyIntl } from 'utils/money'

const { Text } = Typography

interface OverviewProps {
    subscription: Subscription
    company?: Company
}

export const OverviewBeyondPlan: FC<OverviewProps> = ({ subscription, company }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Charge Type',
            dataIndex: 'chargeType',
            key: 'chargeType',
        },
        {
            title: 'Price',
            dataIndex: 'pricing',
            key: 'pricing',
            render: (value: Pricing) => {
                if (value.price?.value !== undefined && value.price?.currency) {
                    return formatMoneyIntl(value.price, { isMinorUnits: true })
                }
                return 'Unknown'
            },
        },
        {
            title: 'Charge Model',
            dataIndex: 'pricing',
            key: 'chargeModel',
            render: (value: Pricing) => {
                if (value?.chargeModel) {
                    return value.chargeModel
                }
                return 'Unknown'
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
    ]

    const addonChargesColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Charge Type',
            dataIndex: 'chargeType',
            key: 'chargeType',
        },
        {
            title: 'Pricing',
            dataIndex: 'pricing',
            key: 'pricing',
            render: (value: any) => {
                return value.chargeModel
            },
        },
        {
            title: 'Discount Details',
            dataIndex: 'discountDetails',
            key: 'discountDetails',
            render: (value: any) => {
                if (value?.appliedRatePlans && value?.appliedRatePlans?.length > 0) {
                    return (
                        <>
                            <p>Duration - {value?.discountDurationInMonth} Month(s)</p>
                            <p>Discount - {value?.discountPercent}%</p>
                        </>
                    )
                }
                return 'No discount'
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
    ]

    const addonChargesColumnsWithCashback = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Charge Type',
            dataIndex: 'chargeType',
            key: 'chargeType',
        },
        {
            title: 'Pricing',
            dataIndex: 'pricing',
            key: 'pricing',
            render: (value: any) => {
                return value.chargeModel
            },
        },
        {
            title: 'Discount Details',
            dataIndex: 'discountDetails',
            key: 'discountDetails',
            render: (value: any) => {
                if (value?.appliedRatePlans && value?.appliedRatePlans?.length > 0) {
                    return (
                        <>
                            <p>Duration - {value?.discountDurationInMonth} Month(s)</p>
                            <p>Discount - {value?.discountPercent}%</p>
                        </>
                    )
                }
                return 'No discount'
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Cashback Percentage',
            dataIndex: 'cashbackPercentage',
            key: 'cashbackPercentage',
            render: (value: number) => {
                return value ? `${(value * 100).toFixed(2)}%` : 'No cashback or not applicable'
            },
        },
    ]

    const expandedRowRenderTiers = (record: any) => {
        const tierColumns = [
            {
                title: 'Tier',
                dataIndex: 'tier',
                key: 'tier',
            },
            {
                title: 'Price Format',
                dataIndex: 'priceFormat',
                key: 'priceFormat',
            },
            {
                title: 'Price',
                dataIndex: 'price',
                key: 'price',
                render: (value: any) => {
                    return formatMoneyIntl(value, { isMinorUnits: true })
                },
            },
            {
                title: 'Ending Unit',
                dataIndex: 'endingUnit',
                key: 'endingUnit',
            },
        ]
        return <Table columns={tierColumns} dataSource={record.pricing.tiers} pagination={false} />
    }

    const expandedRowRender = (record: SubscriptionRatePlan) => (
        <Table
            size="small"
            columns={
                record.type !== 'CASHBACK' ? addonChargesColumns : addonChargesColumnsWithCashback
            }
            dataSource={record.charges}
            pagination={false}
            expandable={{
                expandedRowRender: (val) => expandedRowRenderTiers(val),
                rowExpandable: (val) =>
                    Boolean(val?.pricing?.tiers && val?.pricing?.tiers.length > 0),
            }}
            rowKey={(val) => val.id || ''}
        />
    )

    const getBilledUsers = () => {
        const charges = subscription?.addonRatePlans?.find(
            (addon) => addon.type === 'ADDITIONAL_USERS'
        )?.charges

        if (charges && charges.length > 0 && charges[0].quantity !== undefined) {
            if (charges[0].quantity.toString() === '0') {
                return (
                    <Tooltip overlay="This value is expected to be '0' during trial">
                        <Text>{charges[0].quantity.toString()}</Text> <InfoCircleOutlined />
                    </Tooltip>
                )
            }

            return (
                <Tooltip overlay="Total users including free">
                    <Text>{charges[0].quantity.toString()}</Text> <InfoCircleOutlined />
                </Tooltip>
            )
        }

        return 'Unknown'
    }

    return (
        <>
            <Descriptions size="small">
                <Descriptions.Item label="Plan">
                    {subscription.platformRatePlan?.productName}
                </Descriptions.Item>
                <Descriptions.Item label="VAT ID">
                    <Space>
                        {company?.taxInfo?.vatId || 'Unknown'}
                        <Popover
                            content={`Last verification date: ${
                                company?.taxInfo?.vatLastVerificationDate || 'N/A'
                            }`}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Start date">
                    {subscription.subscriptionStartDate || 'Unknown'}
                </Descriptions.Item>
                <Descriptions.Item label="In Trial Period">
                    {subscription.isInTrialPeriod ? 'Yes' : 'No'}
                </Descriptions.Item>
                {subscription.isInTrialPeriod && (
                    <>
                        <Descriptions.Item label="Trial start date">
                            {subscription.trialPeriod?.inclusiveStartDate}
                        </Descriptions.Item>
                        <Descriptions.Item label="Trial end date">
                            {subscription.trialPeriod?.exclusiveEndDate}
                        </Descriptions.Item>
                    </>
                )}
                <Descriptions.Item label="Billing Period Type">
                    {subscription.billingPeriodType === 'MONTH' ? 'Monthly' : 'Yearly'}
                </Descriptions.Item>
                <Descriptions.Item label="Billed Users">{getBilledUsers()}</Descriptions.Item>
            </Descriptions>
            <Table
                title={() => 'Charges'}
                dataSource={subscription.platformRatePlan?.charges || []}
                columns={columns}
            />

            {subscription.addonRatePlans && subscription.addonRatePlans.length > 0 && (
                <>
                    <Text style={{ paddingLeft: 16 }}>Addons</Text>
                    {subscription.addonRatePlans.map((addon) => (
                        <Card key={addon.id} title={addon.name} style={{ marginTop: 16 }}>
                            {expandedRowRender(addon)}
                        </Card>
                    ))}
                </>
            )}
        </>
    )
}
