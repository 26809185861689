import type { FC } from 'react'
import { Button } from 'antd'
import { ReactComponent as FailedState } from 'resources/failed_to_connect_bigCard.svg'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'

interface Props {
    onRetry: () => void
}

const ErrorState: FC<React.PropsWithChildren<Props>> = ({ onRetry }) => (
    <ErrorContainer>
        <FailedState data-testid="error" />
        <ButtonContainer>
            <Button onClick={onRetry}>Retry</Button>
        </ButtonContainer>
    </ErrorContainer>
)

export default ErrorState

const ErrorContainer = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    grid-gap: ${spacing.space16};
`

const ButtonContainer = styled.div`
    width: 60px;
`
