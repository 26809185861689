import { Button } from 'antd'
import { Formik } from 'formik'
import { Input, Form, FormItem } from 'formik-antd'
import SupportedCountrySelect from 'components/supported-countries-select'

import { supportedCountries, SupportedCountry } from 'types/countries'
import { FormItemLong, FormLayout } from '../form.styles'
import ExpiresAtTooltip from '../expires-at-tooltip/expires-at-tooltip'
import { validationSchema } from './validation'
import type * as Yup from 'yup'

interface Props {
    onSubmit: (values: FormValuesBasic) => void
}

export type FormValuesBasic = Yup.InferType<typeof validationSchema> & {
    country: SupportedCountry
}

const GenerateBasicDemoForm = ({ onSubmit }: Props) => {
    return (
        <Formik
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
                companyName: '',
                country: supportedCountries[0],
            }}
        >
            <Form layout="vertical">
                <FormLayout>
                    <FormItemLong name="companyName" label="Company name" hasFeedback>
                        <Input name="companyName" placeholder="Company name" />
                    </FormItemLong>
                    <FormItem name="country" label="Country">
                        <SupportedCountrySelect name="country" placeholder="Country" />
                    </FormItem>
                </FormLayout>
                <ExpiresAtTooltip>
                    <Button type="primary" htmlType="submit">
                        Publish
                    </Button>
                </ExpiresAtTooltip>
            </Form>
        </Formik>
    )
}

export default GenerateBasicDemoForm
