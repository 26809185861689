import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'router/protected-route'

import { Company360Details } from './screens/details/details'
import { Company360Dashboard } from './screens/dashboard/dashboard'
import CompanyAdvancedSearch from './screens/advanced-search/advanced-search'

export default function Customer() {
    return (
        <Routes>
            <Route element={<ProtectedRoute />}>
                <Route path="/" element={<Company360Dashboard />} />
                <Route path="/advanced-search" element={<CompanyAdvancedSearch />} />
                <Route path="/:id" element={<Company360Details />} />
            </Route>
        </Routes>
    )
}
