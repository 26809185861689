import useSWR, { mutate } from 'swr'
import useSWRInfinite from 'swr/infinite'

import oberonRequest, { fetcher } from '../oberon/request'

type OrganizationRequestStatus = 'PENDING' | 'COMPLETED' | 'DECLINED'

export type OrganizationRequest = {
    id: string
    type: 'ADD' | 'REMOVE' | 'NEW'
    organizationId: string
    organizationName: string
    requesterEmail: string
    companyId: string
    registryId: string
    status: OrganizationRequestStatus
    createdAt: string
    updatedAt: string
}

type GetOrganizationRequestResponse = {
    data: OrganizationRequest[]
    pagination: {
        hasPreviousPage: boolean
        hasNextPage: boolean
        currentRequestPagination: {
            limit: number
            parameters: {}
        }
        startCursor: string
        endCursor: string
    }
}

const ORGANIZATION_REQUEST_COUNT_URL =
    'rest/v1/organization-grouping-request/actions/count?status=PENDING'

export const usePendingOrganizationRequestActionsCount = ({
    bypassHook = false,
}: {
    bypassHook?: boolean
}) => {
    return useSWR(bypassHook ? undefined : ORGANIZATION_REQUEST_COUNT_URL, fetcher, {
        shouldRetryOnError: false,
        revalidateOnMount: true,
    })
}

const PAGE_SIZE = 25

export const useOrganizationRequestActions = ({
    statuses,
    limit = PAGE_SIZE,
    bypassHook = false,
}: {
    limit?: number
    statuses: OrganizationRequestStatus[]
    bypassHook?: boolean
}) => {
    const getKey = (_pageIndex: number, previousPageData: GetOrganizationRequestResponse) => {
        if (previousPageData && !previousPageData.data) return null

        const params = new URLSearchParams()

        params.append(
            'limit',
            `${limit ?? previousPageData?.pagination.currentRequestPagination.limit}`
        )
        statuses.forEach((requestStatus) => {
            params.append('status', requestStatus)
        })

        if (previousPageData?.pagination) {
            params.append('after', previousPageData?.pagination.endCursor)
        }

        const query = params.toString()

        return bypassHook ? null : `rest/v1/organization-grouping-request/actions?${query}`
    }

    const result = useSWRInfinite<GetOrganizationRequestResponse>(getKey, fetcher, {
        shouldRetryOnError: false,
        revalidateOnMount: true,
    })

    const data = (result.data ?? []).map((d) => d.data).flat()
    const pagination = result.data?.[result?.data.length - 1].pagination
    const isLoadingInitialData = !result.data && !result.error
    const isLoadingMore = !!(
        result.size > 0 &&
        result.data &&
        typeof result.data[result.size - 1] === 'undefined'
    )

    const fetchNextPage = () => result.setSize?.((result.size ?? 0) + 1)
    const fetchPreviousPage = () => result.setSize?.((result.size ?? 0) - 1)
    const fetchFirstPage = () => result.setSize?.(1)

    return {
        ...result,
        data,
        pagination,
        isLoadingMore,
        isLoadingInitialData,
        fetchFirstPage,
        fetchNextPage,
        fetchPreviousPage,
    }
}

const updateOrganizationRequestAction = (
    id: string,
    status: OrganizationRequestStatus,
    reviewerEmail?: string | null
) => {
    return oberonRequest().post(`rest/v1/organization-grouping-request/actions/${id}`, {
        json: { status, reviewerEmail },
    })
}

export const usePendingOrganizationRequestActions = ({
    limit,
    bypassHook = false,
}: {
    limit?: number
    bypassHook?: boolean
}) => {
    const result = useOrganizationRequestActions({ statuses: ['PENDING'], limit, bypassHook })

    const approve = async (id: string, reviewerEmail?: string | null) => {
        await updateOrganizationRequestAction(id, 'COMPLETED', reviewerEmail)
        return mutate(ORGANIZATION_REQUEST_COUNT_URL)
    }

    const decline = async (id: string, reviewerEmail?: string | null) => {
        await updateOrganizationRequestAction(id, 'DECLINED', reviewerEmail)
        return mutate(ORGANIZATION_REQUEST_COUNT_URL)
    }

    return {
        ...result,
        mutation: {
            approve,
            decline,
        },
    }
}

export const useProcessedOrganizationRequestActions = ({
    bypassHook = false,
    limit,
}: {
    limit?: number
    bypassHook?: boolean
}) => {
    return useOrganizationRequestActions({ statuses: ['DECLINED', 'COMPLETED'], limit, bypassHook })
}
