import { Col, List, Modal, Row, Typography } from 'antd'
import ErrorState from 'components/error-state/error-state'
import Spinner from 'components/content-spinner'
import StatusForm from 'components/card-status-form'
import { Card } from './card'
import { handleResetPinTries } from './utils'
import EmptyState from '../../empty-state/empty-state'
import { useCardsUtils } from './hooks'

import { bff } from '../../bff-hooks'
import type { Status } from '../../../types/card'

const { Link } = Typography

interface Props {
    companyId?: string | null
    employeeId?: string
    cardAccess?: boolean
}

const Cards = ({ companyId, employeeId = '', cardAccess }: Props) => {
    const {
        data: employeeCards,
        isError,
        isLoading,
        refetch: refetchCards,
    } = bff.components.employeeCards.getEmployeeCards.useQuery({ employeeId, companyId })

    const {
        changeStatusModalActive,
        currentCardInfo,
        setChangeStatusModalActive,
        onChangeStatus,
        onChangeStatusSubmit,
        onCardAccess,
    } = useCardsUtils(companyId, employeeId)

    const noCards = employeeCards && employeeCards.length < 1

    if (isLoading) return <Spinner />

    if (isError) return <ErrorState onRetry={() => refetchCards()} />

    return (
        <>
            <List>
                {noCards && cardAccess && <EmptyState />}
                {!cardAccess && (
                    <List.Item>
                        This user does not have card access enabled. Would you like to{' '}
                        <Link onClick={onCardAccess}>enable it</Link>?
                    </List.Item>
                )}
                <Row gutter={8}>
                    {employeeCards?.map((card, index) => (
                        <Col span={12} key={index} style={{ marginBottom: 8 }}>
                            <Card
                                card={card}
                                onChangeStatus={() => onChangeStatus(card)}
                                onResetPinTries={() => handleResetPinTries(card)}
                            />
                        </Col>
                    ))}
                </Row>
            </List>
            <Modal
                title="Change status"
                open={changeStatusModalActive}
                onCancel={() => setChangeStatusModalActive(false)}
                centered
                okText="Save"
                footer={null}
            >
                <StatusForm
                    initialStatus={currentCardInfo.status as Status}
                    onSubmit={onChangeStatusSubmit}
                />
            </Modal>
        </>
    )
}

export default Cards
