import type { FC } from 'react'

import { useLocation, useNavigate, Routes, Route } from 'react-router-dom'
import { Card, Typography } from 'antd'
import Employees from 'components/employees/employees'
import { PermissionTag } from 'components/permissions/permission-tag'

import CardOrdersTable from 'pages/customer-success/card-orders/tables/card-orders-table'
import ProtectedRoute from 'router/protected-route'
import Billing from './billing/billing'
import Reimbursements from './reimbursements/reimbursements'
import Overview from './overview/overview'
import WalletLoads from './wallet-loads/wallet-loads'
import ReceiptsXml from './receipts-xml/receipts-xml'
import Bankfeed from './bankfeed/bankfeed'
import Credit from './credit/credit'
import Categories from './categories/categories'
import BulkEmailUpdate from './bulk-email-update/bulk-email-update'

import { CardOrderStatus } from 'store/modules/support/card-orders/types'
import CashFlow from './cash-flow/cash-flow'

const CompanyTabs: FC<React.PropsWithChildren<{ companyId: string }>> = ({ companyId }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const defaultActiveKey = location.pathname.split('/').reverse()[0] ?? 'overview'
    const statuses = Object.keys(CardOrderStatus) as CardOrderStatus[]

    const tabList = [
        { key: 'overview', tab: 'Overview' },
        { key: 'employees', tab: 'Employees' },
        { key: 'orders', tab: 'Card Orders' },
        { key: 'billing', tab: 'Billing' },
        { key: 'reimbursements', tab: 'Reimbursements' },
        { key: 'walletLoads', tab: 'Wallet Loads' },
        { key: 'receiptsXml', tab: 'Receipts XML' },
        { key: 'bankfeed', tab: 'Bankfeeds' },
        { key: 'credit', tab: 'Credit' },
        { key: 'categories', tab: 'Categories' },
        { key: 'bulk-email-update', tab: 'Bulk Email Update' },
        { key: 'cash-flow', tab: 'Ca$h Flow' },
    ]

    return (
        <Card
            tabList={tabList}
            defaultActiveTabKey={defaultActiveKey}
            onTabChange={(tabKey: string) => {
                navigate(tabKey, { replace: true })
            }}
        >
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Overview />} />
                    <Route path="overview" element={<Overview />} />
                    <Route path="employees" element={<Employees companyId={companyId} />} />
                    <Route
                        path="orders"
                        element={
                            <CardOrdersTable
                                filters={{ companyId, status: statuses }}
                                setFilters={() => {}}
                            />
                        }
                    />
                    <Route path="billing" element={<Billing />} />
                    <Route
                        path="reimbursements"
                        element={<Reimbursements companyId={companyId} />}
                    />
                    <Route path="receiptsXml" element={<ReceiptsXml />} />
                    <Route path="bankfeed" element={<Bankfeed />} />
                    <Route path="categories" element={<Categories companyId={companyId} />} />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            requiredPermissions={['cupid']}
                            fallback={<PermissionTag permission={'cupid'} />}
                        />
                    }
                >
                    <Route path="walletLoads" element={<WalletLoads />} />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            requiredPermissions={['bulk-email-update']}
                            fallback={<PermissionTag permission="bulk-email-update" />}
                        />
                    }
                >
                    <Route
                        path="bulk-email-update"
                        element={<BulkEmailUpdate companyId={companyId} />}
                    />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            requiredPermissions={['funds-mgmt-eng', 'funds-mgmt-risk-ops']}
                            fallback={
                                <>
                                    <PermissionTag permission="funds-mgmt-eng" />
                                    <Typography> or </Typography>
                                    <PermissionTag permission="funds-mgmt-risk-ops" />
                                </>
                            }
                            allowStrategy="at-least-one"
                        />
                    }
                >
                    <Route path="cash-flow" element={<CashFlow companyId={companyId} />} />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            requiredPermissions={['credit-ops']}
                            fallback={<span>You don't have required permissions.</span>}
                        />
                    }
                >
                    <Route path="credit" element={<Credit companyId={companyId} />} />
                </Route>
            </Routes>
        </Card>
    )
}

export default CompanyTabs
