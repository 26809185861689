import request from './request'

import type { DetectedFraud, ManualFlagRequest } from 'types/ariel'

export const manuallyFlagTransaction = ({
    transactionId,
    category,
    notes,
    investigationStatus,
    family,
}: ManualFlagRequest) => {
    return request().post(
        `rest/v1/external/frauds/transactions/${family}/${encodeURIComponent(
            transactionId
        )}/frauds`,
        {
            json: { category, notes: notes ? notes : null, investigationStatus },
        }
    )
}

export const updateFlagInvestigationStatus = (flag: DetectedFraud) =>
    request().put('rest/v1/external/frauds/detected', { json: { ...flag } })
