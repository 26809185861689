import type { FC } from 'react'
import styled from 'styled-components'
import { Divider as AntDivider, Tag } from 'antd'
import { fontSize, fontWeight, spacing } from 'theme/tokens'

import Dropdown from 'components/navigation-dropdown/navigation-dropdown'
import LogoSidebar from 'components/logo/logo-sidebar'
import config from 'config'

const isProduction = config.environment === 'production'
const isDev = ['feature', 'local', 'product-dev'].includes(config.environment)

interface Props {
    label?: string
    hideDropdown?: boolean
}

const SidebarTitle: FC<React.PropsWithChildren<Props>> = ({ label, hideDropdown = false }) => {
    return (
        <Container>
            <LogoArea />
            <Divider />
            <Label data-testid="label">{!hideDropdown && <Dropdown label={label} />}</Label>
        </Container>
    )
}

export const Container = styled.div`
    margin: 1rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: ${spacing.space4};
`

export const Subtitle = styled.p`
    font-size: ${fontSize.size20};
    font-weight: ${fontWeight.weight500};
`

export const Divider = styled(AntDivider)`
    margin: 0;
    background-color: #fff;
`

export const Label = styled.p`
    align-self: flex-start;
`

export const LogoArea = () => {
    return (
        <div>
            <LogoSidebar />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Subtitle>Back Office</Subtitle>
                {!isProduction && <EnvironmentTag />}
            </div>
        </div>
    )
}

const EnvironmentTag = () => <StyledStagingTag>{isDev ? 'Dev' : 'Staging'}</StyledStagingTag>

const StyledStagingTag = styled(Tag)`
    background-color: transparent;
    color: #ffebed;
    margin: 0;
    font-weight: 600;
    border-color: currentcolor;
    text-transform: uppercase;
    letter-spacing: 0.02rem;
`

export default SidebarTitle
