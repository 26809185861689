import type { State } from 'store/modules/state'
import { createSelector } from '@reduxjs/toolkit'

export const selectStatus = (state: State) => state.support.status
export const selectPageStatus = createSelector([selectStatus], (status) => {
    const values = Object.entries(status)
        .filter(([key, _]) => ['company', 'billing'].includes(key))
        .map(([_, value]) => value)

    return values.includes('fetching') ? 'fetching' : status['company'] || 'default'
})
export const selectCompanyStatus = (state: State) => state.support.status['company']
export const selectUserStatus = (state: State) => state.support.status['user']
export const selectEmployeeStatus = (state: State) => state.support.status['employee']
export const selectEmployeesStatus = (state: State) => state.support.status['employees']
export const selectBillingStatus = (state: State) => state.support.status['billing']
export const selectExpenseStatus = (state: State) => state.support.status['expense']
export const selectFeatureFlagsStatus = (state: State) => state.support.status['featureFlags']
