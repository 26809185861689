import type { Money } from '@pleo-io/hydra-ts-models'

export const formatMoney = (money?: Money | null) =>
    money ? `${money.value} ${money.currency}` : undefined

interface Options {
    isMinorUnits?: boolean
    hideCurrency?: boolean
    currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name'
}

export const formatMoneyIntl = (money?: Money, options?: Partial<Options>) => {
    if (money?.value === undefined) {
        return null
    }

    let workingValue = money?.value

    if (options?.isMinorUnits) {
        workingValue = workingValue / 100
    }

    return new Intl.NumberFormat('en-GB', {
        style: options?.hideCurrency ? 'decimal' : 'currency',
        currency: money?.currency,
        currencyDisplay: options?.currencyDisplay || 'symbol',
        minimumFractionDigits: 2,
    }).format(workingValue)
}
