import { MessageOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Modal, Table, TableColumnsType, Tag, Tooltip, Typography } from 'antd'
import type { CashbackResponse } from 'bff/moons/generated/tarvos'
import { ActionsContainer } from 'components/layout-containers'
import dayjs from 'packages/dayjs'
import { useState } from 'react'
import styled from 'styled-components'
import { formatMoneyIntl } from 'utils/money'
import CashbackContributorTable from './cashback-contributor-table'

const { Text } = Typography

const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;
`

const FirstColumn = styled.div`
    padding-left: 16px;
`

const LastColumn = styled.div`
    padding-right: 16px;
`

export const CashbackTable = ({
    cashback,
    companyName,
    error,
}: {
    cashback?: CashbackResponse[]
    companyName?: string
    error?: Error
}) => {
    const [overviewModalActive, setOverviewModalActive] = useState(false)
    const [overviewCashback, setOverviewCashback] = useState<CashbackResponse | null>(null)

    const onOverview = (cashbackRecord: CashbackResponse) => {
        setOverviewCashback(cashbackRecord)
        setOverviewModalActive(true)
    }

    const columns: TableColumnsType<CashbackResponse> = [
        {
            key: 'expensesFrom',
            dataIndex: 'expensesFrom',
            title: <FirstColumn>Expenses from</FirstColumn>,
            render: (expensesFrom) => (
                <FirstColumn>{dayjs(expensesFrom, 'YYYY-MM-DD').format('MMM D, YYYY')}</FirstColumn>
            ),
        },
        {
            key: 'expensesTo',
            dataIndex: 'expensesTo',
            title: 'Expenses to',
            render: (expensesTo) => dayjs(expensesTo, 'YYYY-MM-DD').format('MMM D, YYYY'),
        },
        {
            key: 'cashbackAmount',
            dataIndex: 'cashbackAmount',
            render: (cashbackAmount) => formatMoneyIntl(cashbackAmount),
            title: 'Cashback amount',
        },
        {
            key: 'createdAt',
            dataIndex: 'createdAt',
            title: 'Created at',
            render: (createdAt) => dayjs(createdAt).utc().format('MMM D, YYYY h:mm A'),
        },
        {
            key: 'status',
            dataIndex: 'status',
            title: 'Status',
            render: (status, row) => (
                <div>
                    <Tag>{status}</Tag>
                    {row?.failureReason && (
                        <Tooltip title={row.failureReason}>
                            <MessageOutlined />
                        </Tooltip>
                    )}
                </div>
            ),
        },
        {
            key: 'expenseId',
            dataIndex: 'expenseId',
            title: <LastColumn>Expense ID</LastColumn>,
            render: (expenseId) =>
                expenseId && (
                    <Text copyable ellipsis style={{ maxWidth: '10rem' }}>
                        {expenseId}
                    </Text>
                ),
        },
        {
            title: 'Contributors',
            fixed: 'right',
            dataIndex: 'action',
            render: (_: unknown, cashbackEntity: CashbackResponse) => (
                <LastColumn>
                    <ActionsContainer>
                        <Button
                            data-testid="actions-button"
                            type="link"
                            onClick={() => onOverview(cashbackEntity)}
                        >
                            Open
                        </Button>
                    </ActionsContainer>
                </LastColumn>
            ),
        },
    ]

    return (
        <Card
            title={companyName ? `${companyName}'s cashback` : 'Cashback'}
            bodyStyle={{ padding: 0, margin: 0 }}
        >
            {error && (
                <Alert
                    showIcon
                    message={error.name}
                    description={`${error.message}. Please try again later or contact #team_loki`}
                    type="error"
                    style={{ margin: '1rem' }}
                />
            )}
            <TableStyleProvider>
                <Table
                    columns={columns}
                    dataSource={cashback}
                    size="small"
                    loading={!cashback && !error}
                    rowKey={'id'}
                    data-testid="cashback-table"
                />
            </TableStyleProvider>
            <Modal
                title="Cashback contributor overview"
                open={overviewModalActive}
                width="auto"
                centered
                footer={null}
                onCancel={() => setOverviewModalActive(false)}
            >
                <CashbackContributorTable
                    cashbackContributor={overviewCashback?.contributors ?? []}
                />
            </Modal>
        </Card>
    )
}
