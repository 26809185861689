import HideExpenseContainer from './widgets/hide-expense.container'
import LinkExpensesContainer from './widgets/link-expenses.container'

import { PageContentLayout } from 'components/layout-containers'
import { Card, Divider } from 'antd'

const Transactions = () => {
    return (
        <PageContentLayout>
            <Card>
                <HideExpenseContainer />
                <Divider />
                <LinkExpensesContainer />
            </Card>
        </PageContentLayout>
    )
}

export default Transactions
