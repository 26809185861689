import dayjs from 'packages/dayjs'

/**
 *
 * @param timestampValue
 * @returns The timestampValue in the format of "Sep 19, 2023" in the user's local timezone
 */

export const formatDate = (timestampValue?: string | null) => {
    if (!timestampValue) return null

    return dayjs(timestampValue).format('MMM D, YYYY')
}

/**
 *
 * @param timestampValue
 * @returns The timestampValue in the format of "Sep 19, 2023 07:55" in the user's local timezone
 * @example AM time: formatTimestamp('2023-09-19T07:55:00.000Z') // 'Sep 19, 2023 07:55'
 * @example PM time: formatTimestamp('2023-09-19T19:55:00.000Z') // 'Sep 19, 2023 19:55'
 */
export const formatTimestamp = (timestampValue?: string | null) => {
    if (!timestampValue) return null

    return dayjs(timestampValue).format('MMM D, YYYY HH:mm')
}
