import type { Account } from '@pleo-io/deimos'
import { Input, Select } from 'formik-antd'
import type { AccountOption } from 'services/deimos/categories'
import styled from 'styled-components'

interface Props {
    accountsOptions: AccountOption[]
    category: Account
}

export const AccountNumber = ({ accountsOptions }: Props) => {
    if (accountsOptions.length > 0) {
        return (
            <AccountNumberSelect
                name="accountNumber"
                placeholder="Account..."
                options={accountsOptions}
            />
        )
    }

    return <Input name="accountNumber" placeholder="Account..." />
}

const AccountNumberSelect = styled(Select)`
    width: 100%;
`
