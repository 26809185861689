import type { FC } from 'react'
import { Tag, Tabs, Tooltip, Switch, Typography, Popover } from 'antd'
import { spacing } from 'theme/tokens'
import styled from 'styled-components'
import {
    compliancePermissions,
    customerSuccessPermissions,
    financePermissions,
    flagPermissions,
    genericPermissions,
    partnershipsPermissions,
} from 'types/permission-groups'

import { highSecurityPermissions } from 'types/high-security-permissions'

import { withPermissionGuard } from 'components/permission-guard/permission-guard'
import { InfoCircleOutlined } from '@ant-design/icons'

import { bff } from '../../pages/product/group-permissions/bff'

const { Link, Text, Title } = Typography

interface Props {
    permissions: string[]
    onPermissionChange: (permission: string[], checked: boolean) => void
}

const UnknownPermissionsInfo = () => (
    <Popover
        title="What are unknown permissions?"
        content={
            <PopoverContent>
                <Text>
                    These are permissions that have been manually added to the user by a developer
                    and aren't displayed correctly in the backoffice. They should be added to one of
                    the permission groups in <Text code>types/permission-groups.ts</Text>. Please
                    see Notion document:{' '}
                    <Link
                        href="https://www.notion.so/pleo/Back-Office-permissions-b1b36b02c9914ca2a1c323bf0d4b9e97"
                        target="blank"
                    >
                        How to add a new permission to the Back Office
                    </Link>
                    .
                </Text>
            </PopoverContent>
        }
    >
        <InfoCircleOutlined data-testid="info" />
    </Popover>
)

function userHasPermissions(usersPermissions: string[], switchesPermissions: string[]) {
    const switchPermissionCount = switchesPermissions.length
    let userPermissionCount = 0

    switchesPermissions.forEach((sPermission) => {
        usersPermissions.forEach((uPermission) => {
            if (sPermission === uPermission) userPermissionCount++
        })
    })
    return userPermissionCount === switchPermissionCount ? true : false
}

interface RawPermissionProps {
    permissions: any
    onPermissionChange: any
    switchesPermission: any
    index: any
    label: string
    containerKeyPrefix: string
    switchKeyPrefix: string
    description?: string
    isOld?: boolean
}

export const RawPermissionSwitch = ({
    permissions,
    onPermissionChange,
    switchesPermission,
    index,
    label,
    containerKeyPrefix,
    switchKeyPrefix,
    description,
    isOld,
}: RawPermissionProps) => {
    const isChecked = permissions.includes(switchesPermission)
    const onChange = (checked: boolean) => onPermissionChange([switchesPermission], checked)
    const tooltipContent = !isOld ? description : null
    return (
        <PermissionContainer key={`${containerKeyPrefix}${index}`}>
            <Tooltip title={tooltipContent}>
                <Switch
                    size="small"
                    data-testid={`${switchKeyPrefix}${index}`}
                    checked={isChecked}
                    onChange={onChange}
                />{' '}
                <StyledLabel onClick={() => onChange(!isChecked)}>{label}</StyledLabel>
            </Tooltip>
            {highSecurityPermissions.includes(switchesPermission) && (
                <Tag color="red">High Security</Tag>
            )}
        </PermissionContainer>
    )
}

interface PermissionSwitchProps {
    permissions: any
    onPermissionChange: any
    switchesPermissions: any
    index: any
    containerKeyPrefix: string
    switchKeyPrefix: string
    description?: string
}

export const PermissionSwitch = ({
    permissions,
    onPermissionChange,
    switchesPermissions,
    index,
    containerKeyPrefix,
    switchKeyPrefix,
}: PermissionSwitchProps) => {
    const isChecked = userHasPermissions(permissions, switchesPermissions.permissions)
    const onChange = (checked: boolean) =>
        onPermissionChange(switchesPermissions.permissions, checked)
    return (
        <PermissionContainer key={`${containerKeyPrefix}${index}`}>
            <Tooltip
                title={
                    <>
                        {switchesPermissions.permissions.map((switchesPermission: string) => (
                            <>{switchesPermission} </>
                        ))}
                    </>
                }
            >
                <Switch
                    size="small"
                    data-testid={`${switchKeyPrefix}${index}`}
                    checked={isChecked}
                    onChange={onChange}
                />{' '}
                <StyledLabel onClick={() => onChange(!isChecked)}>
                    {switchesPermissions.description}
                </StyledLabel>
            </Tooltip>
            {switchesPermissions.permissions.map((permission: any) => {
                if (highSecurityPermissions.includes(permission)) {
                    return (
                        <Tag color="red" key={permission}>
                            High Security
                        </Tag>
                    )
                }
                return null
            })}
        </PermissionContainer>
    )
}

const PopoverContent = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
`

export const AdminPermissions: FC<React.PropsWithChildren<Props>> = ({
    permissions,
    onPermissionChange,
}) => {
    const { data: oktaPermissions } = bff.permissions.listAllPermissions.useQuery(
        { params: { include_deleted: false } },
        { refetchOnWindowFocus: false }
    )

    const oktaPermissionTags = oktaPermissions?.map((permission) => permission?.tag) ?? []

    const getUnknownPermissions = (userPermissions: string[]) =>
        userPermissions.filter((permission) => !oktaPermissionTags.includes(permission as any))
    const unknownPermissions = getUnknownPermissions(permissions ?? [])
    const hasUnknownPermissions = unknownPermissions.length > 0

    return (
        <>
            <Title level={5} style={{ marginBottom: spacing.space16 }}>
                General
            </Title>
            <PermissionsListContainer>
                {genericPermissions.map((switchesPermissions, index) => (
                    <PermissionSwitch
                        permissions={permissions}
                        onPermissionChange={onPermissionChange}
                        switchesPermissions={switchesPermissions}
                        index={index}
                        containerKeyPrefix="generic-"
                        switchKeyPrefix="generic-switch-"
                        key={`generic-switch-${switchesPermissions.description}`}
                    />
                ))}
            </PermissionsListContainer>
            <StyledTitle level={5}>Functional</StyledTitle>
            <Tabs
                tabPosition="top"
                items={[
                    {
                        key: 'customer-success',
                        label: 'Customer success',
                        children: (
                            <PermissionsListContainer>
                                {customerSuccessPermissions.map((switchesPermissions, index) => (
                                    <PermissionSwitch
                                        permissions={permissions}
                                        onPermissionChange={onPermissionChange}
                                        switchesPermissions={switchesPermissions}
                                        index={index}
                                        containerKeyPrefix="customerSuccess-"
                                        switchKeyPrefix="customer-success-switch-"
                                        key={`customer-success-switch-${switchesPermissions.description}`}
                                    />
                                ))}
                            </PermissionsListContainer>
                        ),
                    },
                    {
                        key: 'compliance',
                        label: 'Compliance',
                        children: (
                            <PermissionsListContainer>
                                {compliancePermissions.map((switchesPermissions, index) => (
                                    <PermissionSwitch
                                        permissions={permissions}
                                        onPermissionChange={onPermissionChange}
                                        switchesPermissions={switchesPermissions}
                                        index={index}
                                        containerKeyPrefix="compliance-"
                                        switchKeyPrefix="compliance-switch-"
                                        key={`compliance-switch-${switchesPermissions.description}`}
                                        description={switchesPermissions.description}
                                    />
                                ))}
                            </PermissionsListContainer>
                        ),
                    },
                    {
                        key: 'finance',
                        label: 'Finance',
                        children: (
                            <PermissionsListContainer>
                                {financePermissions.map((switchesPermissions, index) => (
                                    <PermissionSwitch
                                        permissions={permissions}
                                        onPermissionChange={onPermissionChange}
                                        switchesPermissions={switchesPermissions}
                                        index={index}
                                        containerKeyPrefix="finance-"
                                        switchKeyPrefix="finance-switch-"
                                        key={`finance-switch-${switchesPermissions.description}`}
                                    />
                                ))}
                            </PermissionsListContainer>
                        ),
                    },
                    {
                        key: 'raw',
                        label: 'Raw',
                        children: (
                            <PermissionsListContainer>
                                {oktaPermissions?.map((oktaPermission: any, index) => (
                                    <RawPermissionSwitch
                                        permissions={permissions}
                                        onPermissionChange={onPermissionChange}
                                        switchesPermission={oktaPermission.tag}
                                        index={index}
                                        label={oktaPermission.tag}
                                        containerKeyPrefix="admin-"
                                        switchKeyPrefix="raw-switch-"
                                        key={`raw-switch-${oktaPermission.tag}`}
                                        description={oktaPermission.description}
                                    />
                                ))}
                            </PermissionsListContainer>
                        ),
                    },
                    {
                        key: 'old',
                        label: 'Old permissions grouping',
                        children: (
                            <PermissionsListContainer>
                                {oktaPermissionTags.map((switchesPermission, index) => (
                                    <RawPermissionSwitch
                                        permissions={permissions}
                                        onPermissionChange={onPermissionChange}
                                        switchesPermission={switchesPermission}
                                        index={index}
                                        label={
                                            oktaPermissions?.find(
                                                (p) => p.tag === switchesPermission
                                            )?.description || ''
                                        }
                                        containerKeyPrefix="oldAdmin-"
                                        switchKeyPrefix="old-switch-"
                                        key={`old-switch-${switchesPermission}`}
                                        isOld
                                    />
                                ))}
                            </PermissionsListContainer>
                        ),
                    },
                    {
                        key: 'flags',
                        label: 'Flags',
                        children: (
                            <PermissionsListContainer>
                                {flagPermissions.map((switchesPermissions, index) => (
                                    <PermissionSwitch
                                        permissions={permissions}
                                        onPermissionChange={onPermissionChange}
                                        switchesPermissions={switchesPermissions}
                                        index={index}
                                        containerKeyPrefix="flag-"
                                        switchKeyPrefix="flag-"
                                        key={`flag-${switchesPermissions}`}
                                    />
                                ))}
                            </PermissionsListContainer>
                        ),
                    },
                    {
                        key: 'partnerships',
                        label: 'Partnerships',
                        children: (
                            <PermissionsListContainer>
                                {partnershipsPermissions.map((switchesPermissions, index) => (
                                    <PermissionSwitch
                                        permissions={permissions}
                                        onPermissionChange={onPermissionChange}
                                        switchesPermissions={switchesPermissions}
                                        index={index}
                                        containerKeyPrefix="flag-"
                                        switchKeyPrefix="flag-"
                                        key={`flag-${switchesPermissions}`}
                                    />
                                ))}
                            </PermissionsListContainer>
                        ),
                    },
                    {
                        key: 'unknown',
                        label: hasUnknownPermissions && (
                            <Text>
                                Undefined permissions <UnknownPermissionsInfo />
                            </Text>
                        ),
                        children: (
                            <PermissionsListContainer>
                                {unknownPermissions.map((unknownPermission, index) => (
                                    <Text code key={`unknown-${index}`}>
                                        {unknownPermission}
                                    </Text>
                                ))}
                            </PermissionsListContainer>
                        ),
                    },
                ]}
            />
        </>
    )
}

export default withPermissionGuard(AdminPermissions, ['super'])

const PermissionsListContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: ${spacing.space8} ${spacing.space24};
`

const PermissionContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${spacing.space8};
`

const StyledTitle = styled(Title)`
    margin-bottom: 0;
    margin-top: ${spacing.space24};
`

const StyledLabel = styled.label`
    cursor: pointer;
`
