import { Typography, Space } from 'antd'

import Spinner from 'components/content-spinner'
import type { Person } from 'types/styx'
import { deSnakify } from 'utils/strings'
import { useManagement } from 'services/styx/management'
import { useMissingInformation } from 'services/deimos/kyc'

const { Text } = Typography

export const MissingKycInfo = () => {
    const { data: missingKycInformation, isValidating: missingInfoIsValidating } =
        useMissingInformation()
    const { management, shareholders, isValidating: managementIsValidating } = useManagement()
    const persons = [...(management || []), ...(shareholders || [])]
    const missingItems: { name: string; missingDetail: string }[] = []

    missingKycInformation?.forEach((doc) => {
        if (doc.subject === 'COMPANY') {
            missingItems.push({ name: 'Company', missingDetail: deSnakify(doc.name) })
        }

        if (doc.subject === 'PERSON') {
            const person = persons.filter((p) => p.id === doc.subjectId)[0] as unknown as Person

            missingItems.push({
                name: `${person?.name?.givenName} ${person?.name?.familyName}`,
                missingDetail: deSnakify(doc.name),
            })
        }
    })

    if (missingInfoIsValidating || managementIsValidating) {
        return <Spinner />
    }

    if (!missingItems.length) {
        return <Text>No items to display</Text>
    }

    return (
        <Space size="small" direction="vertical">
            {missingItems?.map((item, index) => (
                <Space key={index}>
                    <Text>{item.name}</Text>
                    <Text type="danger">{item.missingDetail}</Text>
                </Space>
            ))}
        </Space>
    )
}
