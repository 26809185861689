import type { FC } from 'react'
import { Link, useParams, Navigate, Route } from 'react-router-dom'
import { FormOutlined, TeamOutlined, ToolOutlined, UserSwitchOutlined } from '@ant-design/icons'

import ProtectedRoute from 'router/protected-route'
import Page from 'pages/page-layout'
import Header from 'components/header/header'
import { deKebabify } from 'utils/strings'

import DemoAccounts from './demo-accounts/demo-accounts'
import BulkDemoAccounts from './bulk-demo-accounts/bulk-demo-accounts'
import UserProvisioning from './user-provisioning/user-provisioning'
import ExternalUserProvisioning from './external-user-provisioning/external-user-provisioning'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { GroupPermissions } from './group-permissions/screens/group-permissions'
import { PermissionRequests } from './group-permissions/screens/permission-requests'
import { SideMenu } from 'pages/page-layout-components'
import { useColorScheme } from 'hooks/use-color-scheme'

const Navigation: FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const isSuperAdmin = useHasPermissions(['super']) // Can grant backoffice access & modify permissions
    const canBulkCreateDeoms = useHasPermissions(['bulk-demo-accounts'])
    const { colorScheme } = useColorScheme()

    return (
        <SideMenu $colorScheme={colorScheme} selectedKeys={[id]} theme="dark">
            <SideMenu.Item key="demo-accounts">
                <ToolOutlined />
                <span>Demo Accounts</span>
                <Link to="../demo-accounts" />
            </SideMenu.Item>
            {canBulkCreateDeoms && (
                <SideMenu.Item key="bulk-demo-accounts">
                    <ToolOutlined />
                    <span>Bulk Demo Accounts</span>
                    <Link to="../bulk-demo-accounts" />
                </SideMenu.Item>
            )}
            {isSuperAdmin && (
                <SideMenu.Item key="user-provisioning">
                    <UserSwitchOutlined />
                    <span>Backoffice Users</span>
                    <Link to="../user-provisioning" />
                </SideMenu.Item>
            )}
            {isSuperAdmin && (
                <SideMenu.Item key="external-user-provisioning">
                    <UserSwitchOutlined />
                    <span>External Users</span>
                    <Link to="../external-user-provisioning" />
                </SideMenu.Item>
            )}
            {isSuperAdmin && (
                <SideMenu.Item key="group-permissions">
                    <TeamOutlined />
                    <span>Group Permissions</span>
                    <Link to="../group-permissions" />
                </SideMenu.Item>
            )}
            {isSuperAdmin && (
                <SideMenu.Item key="permission-requests">
                    <FormOutlined />
                    <span>Permission Requests</span>
                    <Link to="../permission-requests" />
                </SideMenu.Item>
            )}
        </SideMenu>
    )
}

const PageHeader = () => {
    const { id: selectedSidebarItem } = useParams()
    const headerTitle = deKebabify(selectedSidebarItem)
    return <Header title={headerTitle}></Header>
}

const Product = () => {
    return (
        <Page
            sideBarLabel="Product"
            sideBar={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id/*" element={<Navigation />} />
                </Route>
            }
            header={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id" element={<PageHeader />} />
                </Route>
            }
        >
            <>
                <Route element={<ProtectedRoute />}>
                    <Route path="*" element={<Navigate to="demo-accounts" />} />
                    <Route path="demo-accounts" element={<DemoAccounts />} />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            requiredPermissions={['bulk-demo-accounts']}
                            fallback={<Navigate to="demo-accounts" />}
                        />
                    }
                >
                    <Route path="bulk-demo-accounts" element={<BulkDemoAccounts />} />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            requiredPermissions={['super']}
                            fallback={<Navigate to="demo-accounts" />}
                        />
                    }
                >
                    <Route path="user-provisioning" element={<UserProvisioning />} />
                    <Route
                        path="external-user-provisioning"
                        element={<ExternalUserProvisioning />}
                    />
                    <Route path="group-permissions" element={<GroupPermissions />} />
                    <Route path="permission-requests" element={<PermissionRequests />} />
                </Route>
            </>
        </Page>
    )
}

export default Product
