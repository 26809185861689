import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import CompliancePage from 'pages/compliance/compliance'
import FinancePage from 'pages/finance/finance'
import LoginPage from 'pages/login/login'
import Product from 'pages/product/product'
import CustomerSuccessPage from 'pages/customer-success/customer-success'
import PaymentOpsPage from 'pages/payment-ops/payment-ops'

import ProtectedRoute from './protected-route'
import { useInitialRoute } from './get-initial-route'
import B4BPage from 'pages/compliance/b4b/b4b'
import Partnerships from 'pages/partnerships/partnerships'

const Router = () => {
    const initialRoute = useInitialRoute()

    return (
        <Routes>
            <Route path="/" element={<Navigate replace to={initialRoute()} />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="support/*" element={<Navigate replace to="/customer-success" />} />
            <Route element={<ProtectedRoute />}>
                <Route path="b4b/*" element={<B4BPage />} />
                <Route path="compliance/*" element={<CompliancePage />} />
                <Route path="finance/*" element={<FinancePage />} />
                <Route path="customer-success/*" element={<CustomerSuccessPage />} />
                <Route path="payment-ops/*" element={<PaymentOpsPage />} />
                <Route path="product/*" element={<Product />} />
                <Route path="partnerships/*" element={<Partnerships />} />
            </Route>
        </Routes>
    )
}

const RouterProvider = () => {
    return (
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    )
}

export default RouterProvider
