import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Alert, Pagination, Table, Tag } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import type { Employee } from 'types/employee'
import dayjs from 'packages/dayjs'
import { capitalizeFirstLetterAndLowercaseElse } from 'utils/strings'
import { selectEmployees } from 'store/modules/employees/selectors'
import config from 'config'

import type { ChargeableEvent } from 'bff/moons/generated/beyond'

import { bff } from '../../bff'
import { formatMoneyIntl } from 'utils/money'

const stripeEndpoint = config.endpoints.stripe

const DEFAULT_PAGE_SIZE = 10

interface ReimbursementsType {
    reimbursements: ChargeableEvent[] | undefined
    isLoading: boolean
    employees: Employee[]
    totalCount: number
    limit: number
    offset: number
    onChangePageSize: (pageSize: number) => void
    onChangeOffset: (offset: number) => void
}

export const Reimbursements: FC<React.PropsWithChildren<ReimbursementsType>> = ({
    reimbursements,
    isLoading,
    employees,
    totalCount,
    onChangePageSize,
    onChangeOffset,
}) => {
    const handleChangePage = (newPage: number, pageSize: number) => {
        const newOffset = newPage > 1 ? (newPage - 1) * pageSize : 0
        onChangeOffset(newOffset)
    }

    const columns: ColumnProps<ChargeableEvent>[] = [
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type) => <>{capitalizeFirstLetterAndLowercaseElse(type)}</>,
            width: 100,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            sorter: (a, b) => dayjs(a.quantity).valueOf() - dayjs(b.quantity).valueOf(),
            width: 100,
        },
        {
            title: 'Monetary Amount',
            dataIndex: 'monetaryAmount',
            render: ({ value, currency }) => (
                <>
                    {formatMoneyIntl(
                        {
                            value,
                            currency,
                        },
                        {
                            isMinorUnits: true,
                        }
                    )}
                </>
            ),
            width: 100,
        },
        {
            title: 'Employee Id',
            dataIndex: 'employeeId',
            render: (employeeId) => {
                if (!employees || !employees.length) {
                    return null
                }
                const employeeMatches = employees.filter(
                    (employee: Employee) => employee.id === employeeId
                )
                const { userId } =
                    employeeMatches && employeeMatches.length
                        ? employeeMatches[0]
                        : { userId: undefined }
                return userId ? (
                    <Link to={`/customer-success/users/${userId}`}>{userId}</Link>
                ) : null
            },
            width: 150,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (createdAt) => <>{dayjs(createdAt).format('ll')}</>,
            sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
            width: 100,
        },
        {
            title: 'Features',
            key: 'features',
            render: (rowData) => (
                <>
                    {rowData?.credit && <Tag color="blue">Credit</Tag>}
                    {rowData?.free && <Tag color="green">Free</Tag>}
                </>
            ),
            width: 50,
        },
        {
            title: 'Invoice Item Id',
            dataIndex: 'invoiceItemId',
            render: (invoiceItemId) => (
                <a target="blank" href={`${stripeEndpoint}/search?query=${invoiceItemId}`}>
                    {invoiceItemId}
                </a>
            ),
            width: 100,
        },
    ]

    return (
        <>
            <Table
                scroll={{ x: 1000 }}
                loading={isLoading}
                rowKey="id"
                dataSource={reimbursements}
                columns={columns}
                data-testid="reimbursements"
                pagination={false}
            />
            <Pagination
                showQuickJumper
                defaultCurrent={1}
                total={totalCount}
                onChange={(newPage, pageSize) => {
                    handleChangePage(newPage, Number(pageSize))
                    onChangePageSize(Number(pageSize))
                }}
            />
        </>
    )
}

const ReimbursementsTable: FC<React.PropsWithChildren<{ companyId: string }>> = ({ companyId }) => {
    const {
        data: reimbursementsData,
        isLoading,
        isError,
    } = bff.reimbursements.getChargeables.useQuery({
        params: { companyId },
    })

    const employees = useSelector(selectEmployees)

    const [limit, setLimit] = useState(DEFAULT_PAGE_SIZE)
    const [offset, setOffset] = useState(0)

    const onChangePageSize = (pageSize: number) => {
        setLimit(pageSize)
    }

    const onChangeOffset = (newOffset: number) => {
        setOffset(newOffset)
    }

    return (
        <>
            {!isError ? (
                <Reimbursements
                    reimbursements={reimbursementsData?.data}
                    isLoading={isLoading}
                    employees={employees}
                    totalCount={reimbursementsData?.cursorPageInfo?.total || 0}
                    onChangePageSize={onChangePageSize}
                    onChangeOffset={onChangeOffset}
                    limit={limit}
                    offset={offset}
                />
            ) : (
                <Alert
                    type="error"
                    showIcon
                    message="Unable to retrieve reimbursements data. Please try again later."
                />
            )}
        </>
    )
}

export default ReimbursementsTable
