import type { Company360Overview } from 'bff/moons/generated/pandora-v1'
import { List, Skeleton, Typography } from 'antd'

import styled from 'styled-components'

const { Text } = Typography
const { Item } = List

const StyledText = styled(Text)`
    word-break: break-all;
`

const StyledListItemMeta = styled(Item.Meta)`
    margin: 0 !important;

    & .ant-list-item-meta-title {
        margin: 0;
        padding: 0;
        font-size: 1em;
    }
`

export const BankAccount: React.FC<{
    bankAccount?: Company360Overview['bankAccount']
    loading?: boolean
}> = ({ bankAccount, loading }) => {
    return (
        <>
            {(!bankAccount || bankAccount.length === 0) && (
                <Text>No bank account details found for this company.</Text>
            )}
            {loading && <Skeleton />}
            {bankAccount &&
                bankAccount.map((account) => (
                    <List
                        split={false}
                        size="small"
                        key={account.accountNumber}
                        itemLayout="vertical"
                    >
                        {account.bankName && (
                            <Item style={{ padding: '0 0 8px' }}>
                                <StyledListItemMeta title="Bank name" />
                                <Text copyable>{account.bankName}</Text>
                            </Item>
                        )}
                        {account.beneficiary && (
                            <Item style={{ padding: '0 0 8px' }}>
                                <StyledListItemMeta title="Beneficiary" />
                                <Text copyable>{account.beneficiary}</Text>
                            </Item>
                        )}
                        {account.iban && (
                            <Item style={{ padding: '0 0 8px' }}>
                                <StyledListItemMeta title="IBAN" />
                                <StyledText copyable>{account.iban}</StyledText>
                            </Item>
                        )}
                        {account.bic && (
                            <Item style={{ padding: '0 0 8px' }}>
                                <StyledListItemMeta title="BIC" />
                                <Text copyable>{account.bic}</Text>
                            </Item>
                        )}
                        {account.bankCode && (
                            <Item style={{ padding: '0 0 8px' }}>
                                <StyledListItemMeta title="Bank code" />
                                <Text copyable>{account.bankCode}</Text>
                            </Item>
                        )}
                        {account.accountNumber && (
                            <Item style={{ padding: '0 0 8px' }}>
                                <StyledListItemMeta title="Account number" />
                                <Text copyable>{account.accountNumber}</Text>
                            </Item>
                        )}
                    </List>
                ))}
        </>
    )
}
