import { Button, Result, Typography } from 'antd'
import config from 'config'

const { Text } = Typography

interface Props {
    email: string
    passcode: string
    companyId?: string
}

const SMS_OTP = config.auth.smsOtp
const APP_STAGING_URL = 'https://app.staging.pleo.io'

const AccountDetails = ({ email, passcode, companyId }: Props) => {
    return (
        <Result
            status="success"
            title="Successfully created demo account"
            subTitle={
                <div>
                    The demo can be accessed at{' '}
                    <a href={APP_STAGING_URL} target="_blank" rel="noopener noreferrer">
                        app.staging.pleo.io
                    </a>{' '}
                    where you can login with the details below:
                </div>
            }
            extra={[
                <div key="email">
                    <Text>Email: </Text>
                    <Text type="secondary" copyable>
                        {email}
                    </Text>
                </div>,
                <div key="passcode">
                    <Text>Passcode: </Text>
                    <Text type="secondary" copyable>
                        {passcode}
                    </Text>
                </div>,
                <div key="smsOtp">
                    <Text>SMS One-time password: </Text>
                    <Text type="secondary" copyable>
                        {SMS_OTP}
                    </Text>
                </div>,
                <br key="break" />,
                <Button type="primary" key="login-button">
                    <a href={APP_STAGING_URL} target="_blank" rel="noopener noreferrer">
                        Login to demo account
                    </a>
                </Button>,
                companyId ? (
                    <Button href={`/customer-success/companies/${companyId}`} key="supportPage">
                        Backoffice support
                    </Button>
                ) : null,
                companyId ? (
                    <Button href={`/compliance/companies/${companyId}`} key="compliancePage">
                        Backoffice compliance
                    </Button>
                ) : null,
            ]}
        />
    )
}

export default AccountDetails
