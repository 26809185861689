import * as Yup from 'yup'

export const validationSchema = Yup.object()
    .shape({
        title: Yup.string().required(),
        category: Yup.string().required(),
        description: Yup.string(),
        hidden: Yup.boolean(),
    })
    .required()

export type FormValues = Yup.InferType<typeof validationSchema>
