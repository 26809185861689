import { Card, notification } from 'antd'
import { Formik, FormikHelpers } from 'formik'
import { Form, Input, ResetButton, SubmitButton } from 'formik-antd'
import { useEffect, type FC } from 'react'
import { CARD_BODY_HEIGHT } from './constants'
import { markExportAsFailed } from 'services/deimos/export'
import { ContentContainer } from 'components/layout-containers'
import { exportRequest } from 'services/request'

type FormValues = {
    exportHistoryId: string | null
    shouldUseDeimos?: boolean
}

const initialValues: FormValues = {
    exportHistoryId: null,
    shouldUseDeimos: true,
}

export const markExportAsFailedExportAPI = async (exportHistoryId: string) => {
    const jobId = exportHistoryId.trim()

    return await exportRequest().post(`rest/v0/export-job-events`, {
        json: {
            jobId,
            event: 'failed',
            failureReasonType: 'canceled_by_user',
            failureReason: 'Marked as failed by customer success team',
        },
    })
}

const handleSubmit = async (
    { exportHistoryId, shouldUseDeimos }: FormValues,
    formikHelpers: FormikHelpers<FormValues>
) => {
    if (!exportHistoryId) {
        return notification.error({
            message: 'ExportHistoryId is required',
            placement: 'topRight',
        })
    }

    try {
        const response = shouldUseDeimos
            ? await markExportAsFailed(exportHistoryId)
            : await markExportAsFailedExportAPI(exportHistoryId)

        if (response?.status === 204 || response?.json()?.changed) {
            notification.success({
                message: `Successfully marked export as failed`,
                placement: 'topRight',
                style: {
                    whiteSpace: 'pre',
                },
            })
        } else {
            notification.info({
                message: `Export was already marked as failed`,
                placement: 'topRight',
                style: {
                    whiteSpace: 'pre',
                },
            })
        }
    } catch (error) {
        notification.error({
            message: 'Could not change export.',
            description: (error as Error).message,
            placement: 'topRight',
        })
    }

    formikHelpers.resetForm()
}

const MarkExportAsFailedComponent: FC<{ shouldUseDeimos: boolean }> = ({ shouldUseDeimos }) => {
    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, isSubmitting, setValues }) => {
                const exportHistoryIdProvided = !!values.exportHistoryId

                const disableSubmit = !exportHistoryIdProvided

                useEffect(() => {
                    setValues({ ...values, shouldUseDeimos })
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [values.exportHistoryId, setValues])

                return (
                    <Form>
                        <Card
                            bodyStyle={{ height: CARD_BODY_HEIGHT }}
                            style={{ height: '100%', marginBottom: '1rem' }}
                            type="inner"
                            title="Mark export as failed"
                            actions={[
                                <SubmitButton
                                    type="link"
                                    size="small"
                                    loading={isSubmitting}
                                    disabled={disableSubmit || isSubmitting}
                                    data-testid="mark-export-as-failed-button"
                                    key="mark-export-as-failed-button"
                                >
                                    Mark as failed
                                </SubmitButton>,
                                <ResetButton
                                    type="link"
                                    danger
                                    size="small"
                                    data-testid="reset-button"
                                    key="reset-button"
                                >
                                    Reset form
                                </ResetButton>,
                            ]}
                        >
                            <ContentContainer>
                                <Input
                                    name="exportHistoryId"
                                    allowClear
                                    placeholder="ExportHistory ID"
                                    data-testid="export-history-id"
                                />
                            </ContentContainer>
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default MarkExportAsFailedComponent
