import {
    ExportOutlined,
    SearchOutlined,
    ShoppingCartOutlined,
    TransactionOutlined,
    AccountBookOutlined,
    ScissorOutlined,
    KeyOutlined,
    GlobalOutlined,
    FileTextOutlined,
    TeamOutlined,
    PaperClipOutlined,
    LinkOutlined,
    ReconciliationOutlined,
    ApartmentOutlined,
    EuroOutlined,
} from '@ant-design/icons'
import type { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { SideMenu } from 'pages/page-layout-components'
import { useColorScheme } from 'hooks/use-color-scheme'

const Menu: FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const hasOffboardingTools = useHasPermissions(['offboarding-tools'])
    const hasLoginEvents = useHasPermissions(['login-events'])
    const hasEmployee360 = useHasPermissions(['employee-360'])
    const hasHydra = useHasPermissions(['hydra'])
    const hasCreditOpsPermission = useHasPermissions(['credit-ops'])
    const { colorScheme } = useColorScheme()

    const menuItems = [
        { key: 'search', icon: <SearchOutlined />, label: <Link to="../search">Search</Link> },
        {
            key: 'company-360',
            icon: <GlobalOutlined />,
            label: <Link to="../company-360">Company 360</Link>,
        },
        ...(hasEmployee360
            ? [
                  {
                      key: 'employee-360',
                      icon: <TeamOutlined />,
                      label: <Link to="../employee-360">Employee 360</Link>,
                  },
              ]
            : []),
        {
            key: 'card-orders',
            icon: <ShoppingCartOutlined />,
            label: <Link to="../card-orders">Card Orders</Link>,
        },
        {
            key: 'view-expense',
            icon: <AccountBookOutlined />,
            label: <Link to="../view-expense">View Expense</Link>,
        },
        {
            key: 'transactions',
            icon: <TransactionOutlined />,
            label: <Link to="../transactions">Transactions</Link>,
        },
        { key: 'exports', icon: <ExportOutlined />, label: <Link to="../exports">Exports</Link> },
        {
            key: 'entitlements',
            icon: <KeyOutlined />,
            label: <Link to="../entitlements">Entitlements</Link>,
        },
        {
            key: 'invoices',
            icon: <FileTextOutlined />,
            label: <Link to="../invoices">Invoices</Link>,
        },
        {
            key: 'custom-spend-rules',
            icon: <LinkOutlined />,
            label: <Link to="../custom-spend-rules">Custom Spend Rules</Link>,
        },
        {
            key: 'review-structure',
            icon: <ApartmentOutlined />,
            label: <Link to="../review-structure">Review Structure</Link>,
        },
        ...(hasHydra
            ? [
                  {
                      key: 'cashback',
                      icon: <ReconciliationOutlined />,
                      label: <Link to="../cashback">Cashback</Link>,
                  },
              ]
            : []),
        ...(hasLoginEvents
            ? [
                  {
                      key: 'login-events',
                      icon: <PaperClipOutlined />,
                      label: <Link to="../login-events">Login events</Link>,
                  },
              ]
            : []),
        ...(hasOffboardingTools
            ? [
                  {
                      key: 'offboarding',
                      icon: <ScissorOutlined />,
                      label: <Link to="../offboarding">Offboarding</Link>,
                  },
              ]
            : []),
        ...(hasCreditOpsPermission
            ? [
                  {
                      key: 'credit',
                      icon: <EuroOutlined />,
                      label: <Link to="../credit">Credit</Link>,
                  },
              ]
            : []),
    ]

    return (
        <SideMenu items={menuItems} selectedKeys={[id]} theme="dark" $colorScheme={colorScheme} />
    )
}

export default Menu
