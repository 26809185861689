import useSWR from 'swr'
import type {
    GetAllSettlementsResponse,
    GetAllSettlementsReconciliationResponse,
} from '@pleo-io/deimos'

import request, { fetcher } from './request'

const BASE_SETTLEMENTS_ROUTE = `rest/v1/settlements`

export const useSettlementsByDay = (date: string) => {
    const response = useSWR<GetAllSettlementsResponse, Error>(
        `${BASE_SETTLEMENTS_ROUTE}?date=${date}`,
        fetcher,
        {
            revalidateOnFocus: false,
        }
    )

    const cancelSettlement = async (settlementId: string) => {
        await request().post(`${BASE_SETTLEMENTS_ROUTE}/${settlementId}/cancel`)

        return response.mutate()
    }

    const markAsPaidManually = async (settlementId: string) => {
        await request().post(`${BASE_SETTLEMENTS_ROUTE}/${settlementId}/paid-manually`)

        return response.mutate()
    }

    const setNote = async (settlementId: string, note: string) => {
        await request().post(`${BASE_SETTLEMENTS_ROUTE}/${settlementId}/note`, { json: { note } })

        return response.mutate()
    }

    return { ...response, mutations: { cancelSettlement, setNote, markAsPaidManually } }
}

export const useSettlementReconciliationSummary = (settlementDate: string) =>
    useSWR<GetAllSettlementsReconciliationResponse, Error>(
        settlementDate ? `rest/v1/settlements-reconciliation?date=${settlementDate}` : null,
        fetcher,
        { revalidateOnFocus: false }
    )

export const useSettlementReconciliationDashboard = (settlementDate: string) =>
    useSWR<{ url: string }, Error>(
        settlementDate ? `rest/v1/finance/settlements?settlementDate=${settlementDate}` : null,
        fetcher,
        { revalidateOnFocus: false }
    )
