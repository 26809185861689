import { CopyOutlined } from '@ant-design/icons'
import { fontSize, spacing } from 'theme/tokens'
import styled from 'styled-components'

export const CopyIcon = styled(CopyOutlined)`
    font-size: ${fontSize.size14};
    display: flex;
    justify-content: 'center';
`

export const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: ${spacing.space24};
    .ant-descriptions * {
        width: auto;
    }
`

export const RegNumberRowContent = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`

export const PanelHeader = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: ${spacing.space8};
`
