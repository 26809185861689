import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Typography } from 'antd'
import type { FC } from 'react'
import styled from 'styled-components'
import type { CompanyPartner } from 'types/partner-portal'

const { Link } = Typography

const PartnersDropdown: FC<
    React.PropsWithChildren<{
        onPartnerClick: (partner: CompanyPartner) => void
        partners: CompanyPartner[]
        partnersError: boolean
    }>
> = ({ onPartnerClick, partners, partnersError }) => (
    <Dropdown
        overlay={
            <Menu>
                {partners.map((partner, index) => (
                    <Menu.Item key={index}>
                        <Link onClick={() => onPartnerClick(partner)}>{partner.name}</Link>
                    </Menu.Item>
                ))}
            </Menu>
        }
        disabled={partnersError || partners.length === 0}
    >
        <StyledButton type="link" size="small" onClick={(e) => e.preventDefault()}>
            Partners
            {partnersError && ': Failed to load'}
            {!partnersError && partners.length === 0 ? ': None' : <DownOutlined />}
        </StyledButton>
    </Dropdown>
)

const StyledButton = styled(Button)`
    padding: 0;
    height: 22px;
`

export default PartnersDropdown
