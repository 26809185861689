import type { CashbackResponse } from 'bff/moons/generated/tarvos'
import dayjs from 'packages/dayjs'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { buildFetcher, tarvosRequest } from 'services/request'
import useSWR from 'swr'
import { useGetCompany } from './use-company'

export const useCompanyCashback = () => {
    const { id: companyId } = useParams()
    const { company } = useGetCompany(companyId)

    const companyCreatedOn = dayjs(company?.createdAt).format('YYYY-MM-DD')

    const startOfCurrentMonth = useMemo(
        () => dayjs().startOf('month').utc().format('YYYY-MM-DD'),
        []
    )

    const now = useMemo(() => dayjs().utc().format('YYYY-MM-DD'), [])

    const currentMonthParams = new URLSearchParams({
        from: startOfCurrentMonth,
        to: now,
    })

    const totalParams = new URLSearchParams({
        from: companyCreatedOn,
        to: now,
    })

    const currentMonthResponse = useSWR<CashbackResponse[]>(
        companyId
            ? `rest/v1/cashback/history/${companyId}/for-expense-period?${currentMonthParams}`
            : null,
        buildFetcher(tarvosRequest),
        { revalidateOnFocus: false }
    )

    const totalResponse = useSWR<CashbackResponse[]>(
        companyId
            ? `rest/v1/cashback/history/${companyId}/for-expense-period?${totalParams}`
            : null,
        buildFetcher(tarvosRequest),
        { revalidateOnFocus: false }
    )

    return {
        currentMonthCashbackAmount: getTotalValue(currentMonthResponse.data),
        totalCashbackAmount: getTotalValue(totalResponse.data),
    }
}

const getTotalValue = (cashbackResponse?: CashbackResponse[]) => {
    return {
        value: cashbackResponse?.reduce((a, b) => a + (b?.cashbackAmount?.value || 0), 0),
        currency: cashbackResponse?.find((a) => a.cashbackAmount?.currency !== undefined)
            ?.cashbackAmount?.currency,
    }
}
