import { FormDataTypes, RecordDatatype } from '../enums'
import { useFormikContext } from 'formik'
import type { Column, Feature, FormikStructure, Limit, Record } from '../types'
import { defaultLimitValue } from '../plan-table/constants'
import { constructFieldName } from '../utils'

type FieldValue = string | boolean | number

type FieldValues = {
    fieldName: string
    columnKey: string
    fieldFeatureID: string
    fieldDataType: FormDataTypes
}

type DefaultParameters = {
    featureName: FieldValues['fieldName']
    featureColumnKey: FieldValues['columnKey']
    featureID: FieldValues['fieldFeatureID']
    dataType: FieldValues['fieldDataType']
    updatedFieldValue: FieldValue
}

type UpdateFieldValues = FieldValues & {
    updatedValue: FieldValue
}

type GetFieldValue = FieldValues & {
    touchedValues: FormikStructure
    dataSource: Record[]
}

type DataTypeHelpers = {
    [key in RecordDatatype]: {
        falseValue: string | boolean
        shouldCheckAddon: boolean
        compareValue: (value: FieldValue | undefined, updatedValue?: FieldValue) => boolean
    }
}

const useUpdateTable = () => {
    const { setFieldValue } = useFormikContext()

    const updateFieldValue = ({
        fieldName,
        columnKey,
        fieldFeatureID,
        fieldDataType,
        updatedValue,
    }: UpdateFieldValues) => {
        const fieldNameToUpdate = constructFieldName(
            fieldName,
            columnKey,
            fieldFeatureID,
            fieldDataType as FormDataTypes
        )
        setFieldValue(fieldNameToUpdate, updatedValue)
    }

    const getPlanColumns = (columns: Column[]): Column[] => {
        const [, , ...planColumns] = columns
        return planColumns
    }

    const getSourceValue = (
        dataSource: Record[],
        fieldName: string,
        columnKey: string,
        fieldDataType: FormDataTypes
    ): boolean | Limit | undefined => {
        const record = dataSource.find((source) => source.key === fieldName)
        if (!record) return
        if (record.type === RecordDatatype.Input) {
            return record[columnKey] as Limit
        } else {
            const columnData = record[columnKey] as Feature
            return columnData[fieldDataType === FormDataTypes.AddOn ? 'isAddOn' : 'isEnabled']
        }
    }

    const getFieldValue = ({
        fieldName,
        columnKey,
        fieldFeatureID,
        fieldDataType,
        touchedValues,
        dataSource,
    }: GetFieldValue) => {
        const fieldNameToCheck = constructFieldName(
            fieldName,
            columnKey,
            fieldFeatureID,
            fieldDataType as FormDataTypes
        )
        if (touchedValues[fieldNameToCheck] !== undefined) {
            return touchedValues[fieldNameToCheck]
        }
        return getSourceValue(dataSource, fieldName, columnKey, fieldDataType as FormDataTypes)
    }

    const generateDefaultParameters = ({
        featureName,
        featureColumnKey,
        featureID,
        dataType,
        updatedFieldValue,
    }: DefaultParameters) => ({
        fieldName: featureName,
        columnKey: featureColumnKey,
        fieldFeatureID: featureID,
        fieldDataType: dataType,
        updatedValue: updatedFieldValue,
    })

    const dataTypeHelpers: DataTypeHelpers = {
        [RecordDatatype.Input]: {
            falseValue: defaultLimitValue,
            shouldCheckAddon: false,
            compareValue: (value, updatedValue) => {
                if (typeof value === 'string') {
                    return value.toString() === updatedValue?.toString()
                }
                return Number(value) === Number(updatedValue)
            },
        },
        [RecordDatatype.Feature]: {
            falseValue: false,
            shouldCheckAddon: true,
            compareValue: (value) => !!value,
        },
    }

    const getDataTypeHelpers = (dataTypes: RecordDatatype) => {
        return dataTypeHelpers[dataTypes]
    }

    return {
        getFieldValue,
        getPlanColumns,
        updateFieldValue,
        generateDefaultParameters,
        getDataTypeHelpers,
    }
}

export default useUpdateTable
