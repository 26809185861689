import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'antd'
import { selectCompanyId } from 'store/modules/support/company/selectors'
import EventAuditLogs from './event-audit-logs/event-audit-logs'
import Invoices from './invoices/invoices'
import Subscription from './subscription/subscription'
import { ContentContainer } from 'components/layout-containers'

const Billing: FC<React.PropsWithChildren<unknown>> = () => {
    const companyId = useSelector(selectCompanyId)

    const tabList = [
        { key: 'invoices', tab: 'Invoices' },
        { key: 'billingEventAuditLogs', tab: 'Billing Audit Trail' },
        { key: 'cashbackEventAuditLogs', tab: 'Cashback Audit Trail' },
    ]

    const contentList = {
        invoices: <Invoices companyId={companyId} />,
        billingEventAuditLogs: <EventAuditLogs companyId={companyId} feature="BILLING" />,
        cashbackEventAuditLogs: <EventAuditLogs companyId={companyId} feature="CASHBACK" />,
    }

    type Key = keyof typeof contentList

    const [activeKey, setActiveKey] = useState<Key>('invoices')

    return (
        <ContentContainer>
            <Subscription />
            <Card
                defaultActiveTabKey={activeKey}
                tabList={tabList}
                onTabChange={(key) => setActiveKey(key as Key)}
            >
                {companyId ? contentList[activeKey] : null}
            </Card>
        </ContentContainer>
    )
}

export default Billing
