import React, { FC, useEffect } from 'react'
import { Card, message, Tag } from 'antd'

import Graph from './graph'
import { useOwnershipGraph } from 'services/styx/ownership'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const OwnershipGraph: FC<React.PropsWithChildren<unknown>> = () => {
    const { data: structure, error } = useOwnershipGraph()

    useEffect(() => {
        if (error) {
            message.error(error.message)
        }
    }, [error])

    return (
        <>
            {structure?.hasLoops && (
                <Tag icon={<ExclamationCircleOutlined />} color="orange">
                    There are ownership loops in the company structure.
                </Tag>
            )}
            <Card title="Ownership Graph" loading={!error && !structure}>
                {structure && <Graph dataSet={structure} />}
            </Card>
        </>
    )
}

export default OwnershipGraph
