import type { FC } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Input, Form, Select } from 'formik-antd'
import { Button } from 'antd'
import { FlagCategory } from '@pleo-io/deimos'

const { Option } = Select

const validationSchema = Yup.object()
    .shape({
        transactionId: Yup.string().required('Required'),
        note: Yup.string(),
        investigationStatus: Yup.string().required('Required'),
        category: Yup.string().required('Required'),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface Props {
    statuses: [string, string][]
    allowBill?: boolean
    onSubmit: (values: FormValues) => void
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

enum FlagCategories {
    AML = 'Card',
    BILL_FRAUD = 'Invoice',
    PERSONAL_TRANSFER_FRAUD = 'Personal Card Transfer',
    EMPLOYEE_BANK_TRANSFER = 'Personal Bank Transfer',
    WALLET_WITHDRAWAL_FRAUD = 'Wallet Withdrawal',
}

const ManuallyFlagTransactionForm: FC<React.PropsWithChildren<Props>> = ({
    statuses,
    allowBill,
    onSubmit,
}) => (
    <Formik
        onSubmit={onSubmit}
        initialValues={{
            transactionId: '',
            note: '',
            investigationStatus: 'FRAUD',
            category: allowBill ? FlagCategory.AML : FlagCategory.CARD_FRAUD,
        }}
        validationSchema={validationSchema}
    >
        {({ isValid }) => (
            <Form {...layout}>
                <Form.Item name="transactionId" label="Transaction ID">
                    <Input.TextArea
                        data-testid="transaction-textarea"
                        name="transactionId"
                        placeholder="Transaction ID / multiple IDs"
                    />
                </Form.Item>
                {allowBill && (
                    <Form.Item name="category" label="Category">
                        <Select name="category" placeholder="Select flag category">
                            {Object.entries(FlagCategories).map((category) => (
                                <Option key={category[0]} value={category[0]}>
                                    {category[1]}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                <Form.Item name="investigationStatus" label="Destination">
                    <Select name="investigationStatus" placeholder="Select flag destination">
                        {statuses.map((status) => (
                            <Option key={status[0]} value={status[0]}>
                                {status[1]}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="notes" label="Notes">
                    <Input.TextArea name="notes" placeholder="Notes" />
                </Form.Item>
                <RowReverse>
                    <Button disabled={!isValid} type="primary" htmlType="submit">
                        Save
                    </Button>
                </RowReverse>
            </Form>
        )}
    </Formik>
)

const RowReverse = styled.div`
    display: flex;
    flex-direction: row-reverse;
`

export default ManuallyFlagTransactionForm
