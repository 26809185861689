import { useState } from 'react'
import { creditBff } from '../bff'
import { Modal, notification } from 'antd'
import type { AccountState } from 'bff/moons/generated/kalyke'

const { confirm } = Modal

const closingErrorMessages: { [key: string]: string } = {
    INTEREST_ACCRUED_NOT_ZERO: 'Interest accrued is not zero',
    INTEREST_OVERDUE_NOT_ZERO: 'Interest overdue is not zero',
}

export function useCloseAccount(companyId: string, accountId: string) {
    const [isClosingModalOpen, setIsClosingModalOpen] = useState(false)

    const { mutate, isLoading: isCloseLoading } = creditBff.closeAccount.useMutation({
        onSuccess: (response) => {
            setIsClosingModalOpen(false)
            if (response?.validationError) {
                notification.error({
                    message: 'Failed to close account',
                    description: (
                        <p>
                            <strong>Reason: </strong>
                            {response.validationError.data.errorCodes
                                .map((code) => closingErrorMessages[code])
                                .join(', ')}
                        </p>
                    ),
                })
            } else {
                notification.success({
                    message: 'Account closed successfully!',
                })
            }
        },
        onError: () => {
            notification.error({
                message: 'Failed to close the account!',
            })
        },
    })

    const closeAccount = async (reason: string) => {
        mutate({ companyId, accountId, reason })
    }

    return {
        isClosingModalOpen,
        setIsClosingModalOpen,
        isCloseLoading,
        closeAccount,
    }
}

export function useWriteOffAccount(companyId: string, accountId: string) {
    const { mutate, isLoading: isWritingOff } = creditBff.writeOffAccount.useMutation({
        onSuccess: (response) => {
            if (response?.validationError) {
                notification.error({
                    message: 'Failed to write off account',
                    description: (
                        <p>
                            <strong>Reason: </strong>
                            {response.validationError.data.message}
                        </p>
                    ),
                })
            } else {
                notification.success({
                    message: 'Account has been written off',
                })
            }
        },
        onError: () => {
            notification.error({
                message: 'Failed to write off account',
            })
        },
    })

    const writeOffAccount = () => {
        confirm({
            centered: true,
            title: 'Are you sure you want to Write off and Close account?',
            onOk() {
                mutate({ companyId, accountId })
            },
        })
    }

    return {
        isWritingOff,
        writeOffAccount,
    }
}

export function useChangeAccountState(accountId: string) {
    const [isChangeStateModalOpen, setIsChangeStateModalOpen] = useState(false)

    const { mutate, isLoading: isChangeStateLoading } = creditBff.changeAccountState.useMutation({
        onSuccess: (response) => {
            if (response?.validationError) {
                notification.error({
                    message: response.validationError.message,
                })
            } else {
                setIsChangeStateModalOpen(false)
                notification.success({
                    message: 'Account state changed successfully',
                })
            }
        },
        onError: () => {
            notification.error({
                message: 'Failed to change account state',
            })
        },
    })

    const changeAccountState = (state: AccountState) => {
        mutate({ accountId, state })
    }

    return {
        changeAccountState,
        isChangeStateLoading,
        isChangeStateModalOpen,
        setIsChangeStateModalOpen,
    }
}
