import type { FC } from 'react'
import { Navigate } from 'react-router'
import { Route } from 'react-router-dom'
import ProtectedRoute from 'router/protected-route'

import CompaniesUsersSearchHeader from 'components/companies-users-search-header/companies-users-search-header'
import LinkPayment from 'components/link-payment/link-payment'
import RebuildExpense from 'components/rebuild-expense/rebuild-expense'
import Page from 'pages/page-layout'
import Menu from './sidebar-menu/sidebar-menu'

import CustomSpendRules from 'components/custom-spend-rules/custom-spend-rules'
import { FallbackForbidden } from 'components/fallback-forbidden'
import { useUsers } from 'services/auth/users'
import { useSearchPartners } from 'services/calypso/partner'
import { useSearchCompanies } from 'services/deimos/companies'
import ReplayTransaction from '../../components/replay-transaction/replay-transaction'
import BatchBalanceAdjustment from 'components/batch-balance-adjustment/batch-balance-adjustment'

const SupportPage: FC<React.PropsWithChildren<unknown>> = () => {
    const { partners, setQuery: setPartnerQuery } = useSearchPartners()
    const { companies, setQuery: setCompanyQuery } = useSearchCompanies()
    const { users, setUsersQuery } = useUsers()

    return (
        <Page
            sideBarLabel="Payment Ops"
            sideBar={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id/*" element={<Menu />} />
                </Route>
            }
            header={
                <Route element={<ProtectedRoute />}>
                    <Route
                        path=":id/*"
                        element={
                            <CompaniesUsersSearchHeader
                                companies={companies}
                                onCompanySearch={setCompanyQuery}
                                onPartnerSearch={setPartnerQuery}
                                onUserSearch={setUsersQuery}
                                partners={partners}
                                users={users}
                            />
                        }
                    />
                </Route>
            }
        >
            <>
                <Route element={<ProtectedRoute />}>
                    <Route path="*" element={<Navigate to="rebuild-expense" />} />
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            requiredPermissions={['payops']}
                            fallback={<FallbackForbidden goBackLink="/customer-success" />}
                        />
                    }
                >
                    <Route path="rebuild-expense" element={<RebuildExpense />} />
                    <Route path="link-payment" element={<LinkPayment />} />
                    <Route path="custom-spend-rules" element={<CustomSpendRules />} />
                    <Route path="replay-transaction" element={<ReplayTransaction />} />
                    <Route path="batch-balance-adjustment" element={<BatchBalanceAdjustment />} />
                </Route>
            </>
        </Page>
    )
}

export default SupportPage
