import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Alert, Tabs, Empty, Tooltip, Space, Button } from 'antd'
import { EyeTwoTone } from '@ant-design/icons'

import { deSnakify } from 'utils/strings'
import Spinner from 'components/content-spinner'
import EditDocumentPopover from 'pages/compliance/company/shared/document-files/edit-document-popover'
import type { FormValues } from 'pages/compliance/company/shared/document-form/validation-schema'
import * as styx from 'types/styx'
import { DocumentFiles } from 'pages/compliance/company/shared/document-files/document-files'
import FileList from 'pages/compliance/company/shared/file-list/file-list'
import { RequestDocument } from '../document-files/request-document'
import { color } from 'theme/tokens'

const { TabPane } = Tabs

interface Props {
    documents: styx.Document[]
    maxFileSize: number
    companyId?: string
    canRequestDocument?: boolean
    canAutoRetrieve?: boolean
    isAutoRetrieving?: boolean
    createDocumentButton?: React.ReactNode
    onAutoRetrieve?: () => void
    handleEditDocument: (documentId: string) => (values: FormValues) => void
    handleGetLinkToFile: (
        documentId: string,
        snapshot?: string
    ) => (fileId: string) => Promise<string>
    onDocumentDelete: (documentId: string) => Promise<void>
    handleDeleteDocumentFile: (documentId: string) => (fileId: string) => Promise<Response | void>
    handleCreateDocumentFile: (
        documentId: string
    ) => (formData: FormData) => Promise<styx.DocumentFile | void>
    handleEditDocumentFile: (
        documentId: string
    ) => (fileId: string, documentFileRequest: styx.DocumentFileRequest) => Promise<void>
}

interface DocumentButtonProps {
    document: styx.Document
    companyId?: string
    canBeRequested: Boolean
    canAutoRetrieve?: boolean
    isAutoRetrieving?: boolean
    onAutoRetrieve?: () => void
    onDocumentDelete: (documentId: string) => Promise<void>
    handleEditDocument: (documentId: string) => (values: FormValues) => void
}

const DefaultDocumentButtons: FC<React.PropsWithChildren<DocumentButtonProps>> = ({
    canBeRequested,
    companyId,
    document,
    onDocumentDelete,
    handleEditDocument,
}) => {
    return (
        <Space>
            {canBeRequested && (
                <RequestDocument companyId={companyId!} category={document.category} />
            )}
            <EditDocumentPopover
                document={document}
                onDocumentDelete={() => onDocumentDelete(document.id)}
                onEdit={handleEditDocument(document.id)}
            />
        </Space>
    )
}

const AutoRetrievableDocumentButtons: FC<React.PropsWithChildren<DocumentButtonProps>> = ({
    canBeRequested,
    canAutoRetrieve,
    isAutoRetrieving,
    companyId,
    document,
    handleEditDocument,
    onAutoRetrieve,
    onDocumentDelete,
}) => (
    <Space>
        {canBeRequested && <RequestDocument companyId={companyId!} category={document.category} />}
        {canAutoRetrieve && (
            <Tooltip title="Retrieves Company registration certificate, Ownership, Proof of directors, Business registry extract, Proof of address & Financial statement.">
                <Button
                    data-testid="auto-retrieve"
                    onClick={onAutoRetrieve}
                    loading={isAutoRetrieving}
                    type="primary"
                >
                    Auto-retrieve
                </Button>
            </Tooltip>
        )}
        <EditDocumentPopover
            document={document}
            onDocumentDelete={() => onDocumentDelete(document.id)}
            onEdit={handleEditDocument(document.id)}
        />
    </Space>
)

const DocumentButtonsContainer: FC<React.PropsWithChildren<DocumentButtonProps>> = (props) => {
    switch (props.document.category) {
        case 'PROOF_OF_ADDRESS':
            return <AutoRetrievableDocumentButtons {...props} />
        case 'FINANCIAL_STATEMENT':
            return <AutoRetrievableDocumentButtons {...props} />
        case 'BUSINESS_REGISTRY_EXTRACT':
            return <AutoRetrievableDocumentButtons {...props} />
        case 'OWNERSHIP':
            return <AutoRetrievableDocumentButtons {...props} />
        case 'COMPANY_REGISTRATION_CERTIFICATE':
            return <AutoRetrievableDocumentButtons {...props} />
        case 'PROOF_OF_DIRECTORS':
            return <AutoRetrievableDocumentButtons {...props} />
        default:
            return <DefaultDocumentButtons {...props} />
    }
}

export const DocumentsTabs: FC<React.PropsWithChildren<Props>> = ({
    canAutoRetrieve,
    canRequestDocument,
    companyId,
    documents,
    isAutoRetrieving,
    maxFileSize,
    createDocumentButton,
    handleCreateDocumentFile,
    handleDeleteDocumentFile,
    handleEditDocumentFile,
    handleEditDocument,
    handleGetLinkToFile,
    onAutoRetrieve,
    onDocumentDelete,
}) => {
    const [activeKey, setActiveKey] = useState('')
    const hasNoDocuments = documents.length < 1

    if (hasNoDocuments) {
        return (
            <Space align="center" direction="vertical" style={{ width: '100%' }} size={16}>
                <Empty data-testid="empty" description="There are currently no documents." />
                {createDocumentButton}
            </Space>
        )
    }

    return (
        <Tabs
            tabPosition="left"
            defaultActiveKey={activeKey}
            onTabClick={(key: string) => setActiveKey(key)}
            tabBarExtraContent={createDocumentButton ? { right: createDocumentButton } : undefined}
            tabBarStyle={{ maxWidth: '20rem' }}
        >
            {documents.map((document) => {
                const title = document.title ?? deSnakify(document.category)
                const canBeRequested = (companyId &&
                    canRequestDocument &&
                    styx.RequestableDocuments.some(
                        (requestableDocument) => requestableDocument === document.category
                    )) as boolean
                return (
                    <TabPane
                        tab={<TabName>{`${title} (${document.files.length})`}</TabName>}
                        key={document.id}
                    >
                        <Content>
                            <DocumentTitle>
                                <h2>
                                    {title}{' '}
                                    {!document.hidden && (
                                        <Tooltip title="Document is visible to users.">
                                            <EyeTwoTone twoToneColor={color.gold} />
                                        </Tooltip>
                                    )}
                                </h2>
                                <DocumentButtonsContainer
                                    document={document}
                                    onDocumentDelete={onDocumentDelete}
                                    handleEditDocument={handleEditDocument}
                                    canBeRequested={canBeRequested}
                                    companyId={companyId}
                                    canAutoRetrieve={canAutoRetrieve}
                                    onAutoRetrieve={onAutoRetrieve}
                                    isAutoRetrieving={isAutoRetrieving}
                                />
                            </DocumentTitle>
                            <div>
                                {document.description ? (
                                    <Alert
                                        message={document.description}
                                        type="info"
                                        style={{ marginTop: 10, marginBottom: 10 }}
                                    />
                                ) : (
                                    'No description'
                                )}
                            </div>
                            <DocumentFiles
                                maxFileSize={maxFileSize}
                                document={document}
                                createDocumentFile={handleCreateDocumentFile(document.id)}
                                editDocumentFile={handleEditDocumentFile(document.id)}
                                deleteDocumentFile={handleDeleteDocumentFile(document.id)}
                                getLinkToFile={handleGetLinkToFile(document.id)}
                            />
                        </Content>
                    </TabPane>
                )
            })}
        </Tabs>
    )
}

interface ReadOnlyProps {
    handleGetLinkToFile: (
        documentId: string,
        snapshot?: string
    ) => (fileId: string) => Promise<string>
    snapshot: string
    snapshotDocuments: styx.Document[]
    loading: boolean
}

export const ReadOnlyDocumentTabs: FC<React.PropsWithChildren<ReadOnlyProps>> = ({
    snapshotDocuments,
    handleGetLinkToFile,
    loading,
    snapshot,
}) => {
    const hasNoDocuments = snapshotDocuments.length < 1

    if (loading) {
        return <Spinner />
    }

    if (hasNoDocuments) {
        return (
            <Empty
                data-testid="empty"
                description="There are currently no documents for this company."
            />
        )
    }

    return (
        <Tabs tabPosition="left">
            {snapshotDocuments.map((document) => {
                const title = document.title ?? deSnakify(document.category)
                return (
                    <TabPane tab={`${title} (${document.files.length})`} key={document.id}>
                        <Content>
                            <DocumentTitle>
                                <h2>
                                    {title}{' '}
                                    {!document.hidden && (
                                        <Tooltip title="Document is visible to users.">
                                            <EyeTwoTone twoToneColor={color.gold} />
                                        </Tooltip>
                                    )}
                                </h2>
                            </DocumentTitle>
                            <div>{document.description || 'No description'}</div>
                            <FileList
                                files={document.files}
                                getLinkToFile={handleGetLinkToFile(document.id, snapshot)}
                            />
                        </Content>
                    </TabPane>
                )
            })}
        </Tabs>
    )
}

const Content = styled.div`
    min-height: 250px;
`

const DocumentTitle = styled.div`
    display: flex;
    justify-content: space-between;
`

const TabName = styled.p`
    white-space: normal;
    text-align: left;
`
