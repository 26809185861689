import React, { FC, useState } from 'react'
import { Card, Divider, message } from 'antd'
import type { ErrorResponse } from '@pleo-io/deimos'

import type { DocumentCategory, DocumentFileRequest } from 'types/styx'
import { Document, KycCheckpoint, MaxFileSize } from 'types/styx'
import {
    ReadOnlyDocumentTabs,
    DocumentsTabs,
} from 'pages/compliance/company/shared/document-tabs/document-tabs'
import type { FormValues as EditDocumentFormValues } from 'pages/compliance/company/shared/document-form/validation-schema'
import SnapshotTabs from 'pages/compliance/company/shared/snapshot-tabs/snapshot-tabs'

import {
    useKycCheckpoints,
    useStyxCompany,
    getLinkToCompanyDocumentFile,
} from 'services/deimos/styx-company/styx-company'
import { CreateCompanyDocument } from 'pages/compliance/company/shared/create-document'
import { useCompanyDocumentsWithDateSnapshot } from 'services/styx/company'
import { ContentContainer } from 'components/layout-containers'
import { showNotSupportedDocumentCategoryModal } from '../shared/show-not-supported-document-category-modal'

interface DocumentHistoryProps {
    snapshotDocuments: Document[]
    loadingSnapshot: boolean
    kycCheckpoints: KycCheckpoint[]
    error?: ErrorResponse
    onFetchSnapshot: (snapshot?: string) => void
    handleGetLinkToFile: (
        documentId: string,
        snapshot?: string
    ) => (fileId: string) => Promise<string>
}

export const DocumentsHistory: FC<React.PropsWithChildren<DocumentHistoryProps>> = ({
    error,
    handleGetLinkToFile,
    snapshotDocuments,
    loadingSnapshot,
    onFetchSnapshot,
    kycCheckpoints,
    children,
}) => {
    return (
        <SnapshotTabs
            kycCheckpoints={kycCheckpoints}
            onFetchSnapshot={onFetchSnapshot}
            error={error}
            readonlyChildren={(snapshot) => (
                <ReadOnlyDocumentTabs
                    handleGetLinkToFile={handleGetLinkToFile}
                    snapshot={snapshot}
                    snapshotDocuments={snapshotDocuments}
                    loading={loadingSnapshot}
                />
            )}
        >
            {children}
            <Divider />
        </SnapshotTabs>
    )
}

const DocumentsHistoryContainer: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    const isClosedCompany = false
    const [snapshot, setSnapshot] = useState<string | undefined>()
    const [isAutoRetrieving, setIsAutoRetrieving] = useState(false)
    const { company } = useStyxCompany(snapshot)
    const { documents, mutations, error } = useCompanyDocumentsWithDateSnapshot(companyId, snapshot)
    const kycCheckpoints = useKycCheckpoints()

    const isDanishCompany = company?.address?.country === 'DK'
    const isGermanCompany = company?.address?.country === 'DE'
    const countriesWhichCanAutoRetrieve = [
        'AT',
        'BE',
        'DE',
        'DK',
        'EE',
        'ES',
        'FR',
        'GB',
        'IE',
        'IT',
        'LU',
        'NL',
        'NO',
        'PT',
        'US',
    ]

    const canAutoRetrieve = countriesWhichCanAutoRetrieve.includes(
        company?.address?.country ? company?.address?.country.toUpperCase() : ''
    )

    // snapshot date comes from the tab key
    const handleFetchSnapshot = (snapshotDate?: string) => setSnapshot(snapshotDate)

    const onAutoRetrieve = async () => {
        try {
            setIsAutoRetrieving(true)
            if (isDanishCompany) {
                await mutations.createDocument(
                    {
                        category: 'FINANCIAL_STATEMENT',
                        title: 'Financial statement',
                    },
                    true
                )
            }
            await mutations.createDocument(
                {
                    category: 'PROOF_OF_ADDRESS',
                    title: 'Proof of address',
                },
                true
            )
            await mutations.createDocument(
                {
                    category: 'OWNERSHIP',
                    title: 'Ownerhsip',
                },
                true
            )
            await mutations.createDocument(
                {
                    category: 'COMPANY_REGISTRATION_CERTIFICATE',
                    title: 'Company registration certificate',
                },
                true
            )
            await mutations.createDocument(
                {
                    category: 'PROOF_OF_DIRECTORS',
                    title: 'Proof of directors',
                },
                true
            )
            if (!isDanishCompany) {
                await mutations.createDocument(
                    {
                        category: 'BUSINESS_REGISTRY_EXTRACT',
                        title: 'Business registry extract',
                    },
                    true
                )
            }
        } catch (e) {
            message.error(`Failed to retrieve documents: ${(e as Error).message}`)
        } finally {
            setIsAutoRetrieving(false)
        }
    }

    const handleCreateDocumentFile = (documentId: string) => async (file: FormData) => {
        await mutations.createDocumentFile(documentId, file)
    }

    const handleEditDocumentFile =
        (documentId: string) =>
        async (fileId: string, documentFileRequest: DocumentFileRequest) => {
            await mutations.editDocumentFile(documentId, fileId, documentFileRequest)
        }

    // Deletes the whole document, included all files
    const onDocumentDelete = async (documentId: string) => {
        await mutations.deleteDocument(documentId)
    }

    // Deletes a single file within a document
    const handleDeleteDocumentFile = (documentId: string) => async (fileId: string) => {
        await mutations.deleteDocumentFile(documentId, fileId)
    }

    const handleGetLinkToFile =
        (documentId: string) => async (fileId: string, download?: boolean) => {
            const { url }: { url: string } = await getLinkToCompanyDocumentFile(
                companyId,
                documentId,
                fileId,
                snapshot,
                isClosedCompany,
                download
            ).json()

            return url
        }

    const handleEditDocument =
        (documentId: string) =>
        async ({ title, category, description, hidden }: EditDocumentFormValues) => {
            const body = {
                title,
                category: category as DocumentCategory,
                description,
                hidden,
            }

            try {
                await mutations.editDocument(documentId, body)
            } catch (e) {
                if (
                    e instanceof Error &&
                    e.message.includes('not supported for company documents')
                ) {
                    await showNotSupportedDocumentCategoryModal('update', e.message)
                }
            }
        }

    return (
        <ContentContainer>
            <Card>
                <DocumentsHistory
                    error={error}
                    handleGetLinkToFile={handleGetLinkToFile}
                    loadingSnapshot={!documents}
                    onFetchSnapshot={handleFetchSnapshot}
                    kycCheckpoints={kycCheckpoints}
                    snapshotDocuments={documents ?? []}
                >
                    <DocumentsTabs
                        canRequestDocument={isGermanCompany}
                        canAutoRetrieve={canAutoRetrieve}
                        companyId={companyId}
                        isAutoRetrieving={isAutoRetrieving}
                        onAutoRetrieve={onAutoRetrieve}
                        handleDeleteDocumentFile={handleDeleteDocumentFile}
                        handleCreateDocumentFile={handleCreateDocumentFile}
                        handleEditDocumentFile={handleEditDocumentFile}
                        handleGetLinkToFile={handleGetLinkToFile}
                        onDocumentDelete={onDocumentDelete}
                        handleEditDocument={handleEditDocument}
                        documents={documents ?? []}
                        maxFileSize={MaxFileSize.CompanyFile}
                        createDocumentButton={<CreateCompanyDocument globalId={companyId} />}
                    />
                </DocumentsHistory>
            </Card>
        </ContentContainer>
    )
}

export default DocumentsHistoryContainer
