import type { ErrorResponse } from '@pleo-io/deimos'
import type { PaginatedResponse } from '@pleo-io/deimos/dist/contracts/commons/cursor-pagination'
import qs from 'qs'
import { useEffect, useState } from 'react'
import useSWR from 'swr'
import type { B4BCompanyResponse } from 'types/styx'
import { useCursorPagination } from 'utils/use-cursor-pagination'
import request, { fetcher } from 'services/styx/request'
import { message } from 'antd'
import { downloadFileFromBlob } from 'utils/download-file-blob'

export const defaultLimit = 10

export interface B4BCompanyQuery {
    legalName?: string
    registryId?: string
}

const B4B_COMPANIES_URL = 'rest/v2/external/companies/b4b-companies'
const OFFBOARDED_B4B_COMPANIES_URL = 'rest/v2/external/companies/b4b-companies/offboarded'

export declare type GetB4BCompaniesSortingColumns = ''

export const useB4BCompanies = () => {
    return fetchB4BCompanies(B4B_COMPANIES_URL)
}

export const useB4BClosedCompanies = () => {
    return fetchB4BCompanies(OFFBOARDED_B4B_COMPANIES_URL)
}

const fetchB4BCompanies = (url: string) => {
    const [query, setQuery] = useState<B4BCompanyQuery>({})
    const [companies, setCompanies] = useState<B4BCompanyResponse[]>([])
    const [limit, setLimit] = useState<number | undefined>(defaultLimit)

    const {
        paginationRequest,
        setCurrentPagination,
        resetFirstPage,
        updateLimit: currentPaginationUpdateLimit,
        ...paginationMutations
    } = useCursorPagination({ limit: defaultLimit, sorting: [] })

    const searchQuery = (b4bCompanyQuery: B4BCompanyQuery) => {
        setQuery(b4bCompanyQuery)
        resetFirstPage()
    }

    const updateLimit = (updatedLimit: number) => {
        currentPaginationUpdateLimit(updatedLimit)
        resetFirstPage()
    }

    const kycLevel = {
        kycLevel: 'FDD',
    }

    const response = useSWR<
        PaginatedResponse<B4BCompanyResponse, GetB4BCompaniesSortingColumns>,
        ErrorResponse
    >(
        `${url}?${qs.stringify({
            ...paginationRequest,
            ...kycLevel,
            ...query,
        })}`,
        fetcher
    )

    const pagination = response.data?.pagination
    const data = response.data?.data

    useEffect(() => {
        if (pagination) {
            setCurrentPagination(pagination)
            setLimit(paginationRequest.limit)
        }
    }, [pagination, setCurrentPagination, paginationRequest.limit])

    useEffect(() => {
        if (data) {
            setCompanies(data)
        }
    }, [data])

    return {
        ...response,
        ...paginationMutations,
        loading: !response.data && !response.error,
        companies,
        pagination,
        query,
        searchQuery,
        limit,
        updateLimit,
        resetFirstPage,
    }
}

export const downloadPdfDocument = async (
    companyId: string,
    registryId: string,
    downloadToggle: (companyId: string) => void
) => {
    try {
        downloadToggle(companyId)
        const res = await request().get(
            `rest/v2/external/reviews/b4b-companies/${companyId}/documents`
        )
        const blob = await res.blob()

        if (!blob) {
            return
        }

        const filename = `company_${registryId}.zip`
        downloadFileFromBlob(blob, filename)
    } catch (e) {
        message.error((e as Error).message)
    } finally {
        downloadToggle(companyId)
    }
}
