import type { FC } from 'react'
import { Table, Card, Typography, Empty, Descriptions } from 'antd'
import RiskCheckTip from 'pages/compliance/company/risk-assessment/risk-check-tip/risk-check-tip'
import { deSnakify } from 'utils/strings'
import type * as styx from 'types/styx'
import { sortRiskCheckForm } from '../utils'
import Spinner from 'components/content-spinner'
import { useStyxCompany } from 'services/deimos/styx-company/styx-company'
import { riskFactorNamesMap } from 'pages/compliance/company/risk-assessment/risk-check-form-v2/columns'

const { Text } = Typography

interface Props {
    riskAssessment?: styx.RiskAssessment | null
    isValidating?: boolean
}

const RiskCheckSnapshot: FC<React.PropsWithChildren<Props>> = ({
    riskAssessment,
    isValidating,
}) => {
    const loading = !riskAssessment
    const { company } = useStyxCompany()
    const onboardedViaB4B = company?.onboardedVia === 'B4B'

    if (isValidating) return <Spinner />

    if (!riskAssessment?.riskChecks) return <Empty description="No Assessment for Snapshot" />
    const snapshotInfoHeader = () => {
        if (!riskAssessment?.score) return <Text strong>No Assessment for Snapshot</Text>

        return (
            <Descriptions>
                <Descriptions.Item label="Score">{riskAssessment?.score}</Descriptions.Item>
                <Descriptions.Item label="Result">{riskAssessment?.result}</Descriptions.Item>
                <Descriptions.Item label="Owner">{riskAssessment?.ownerName}</Descriptions.Item>
                <Descriptions.Item label="Notes">{riskAssessment?.notes}</Descriptions.Item>
            </Descriptions>
        )
    }

    return (
        <Card extra={snapshotInfoHeader()}>
            {!onboardedViaB4B && (
                <Table
                    loading={loading}
                    dataSource={sortRiskCheckForm<styx.RiskCheck>(riskAssessment?.riskChecks)}
                    pagination={false}
                    rowKey="riskType"
                    columns={[
                        {
                            title: 'Risk type',
                            dataIndex: 'riskType',
                            render: (type) => (
                                <>
                                    <Text strong>
                                        {riskFactorNamesMap[
                                            type as keyof typeof riskFactorNamesMap
                                        ] || deSnakify(type)}
                                    </Text>{' '}
                                    <RiskCheckTip riskType={type} />
                                </>
                            ),
                            width: 200,
                        },
                        {
                            title: 'Factor ID',
                            dataIndex: 'riskTypeId',
                            key: 'id',
                        },
                        {
                            title: 'Level of risk',
                            dataIndex: 'score',
                            key: 'id',
                        },
                        {
                            title: 'Notes',
                            dataIndex: 'notes',
                            key: 'id',
                        },
                        {
                            title: 'Owner',
                            dataIndex: 'ownerName',
                        },
                    ]}
                />
            )}
        </Card>
    )
}

export default RiskCheckSnapshot
