import { Card, Breadcrumb, Space } from 'antd'
import { debounce } from 'lodash'
import type { FC } from 'react'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { PageContentLayout } from 'components/layout-containers'
import { PageHeader } from '@ant-design/pro-layout'
import { spacing } from 'theme/tokens'
import { useGetCompanies } from 'services/caliban/company-search'
import type { GetCompaniesResponse } from 'services/caliban/company-search'
import {
    CustomColumn,
    ToggleTable,
} from 'pages/customer-success/shared/advanced-search/toggle-table'
import AdvancedSearchInput from 'pages/customer-success/shared/advanced-search/advanced-search-input'
import AdvancedSearchCountrySelect, {
    getCountry,
} from 'pages/customer-success/shared/advanced-search/advanced-search-country-select'
import type { Alpha2Code } from 'types/countries'

enum AvailableColumns {
    companyName = 'name',
    country = 'country',
    companyId = 'id',
    registrationNumber = 'registrationNumber',
}

const columns = [
    {
        title: 'Company name',
        dataIndex: AvailableColumns.companyName,
    },
    {
        title: 'Country',
        dataIndex: AvailableColumns.country,
        render: (countryCode: Alpha2Code) => (
            <Space>
                {getCountry(countryCode)?.emoji}
                {getCountry(countryCode)?.name}
            </Space>
        ),
    },
    {
        title: 'Company ID',
        dataIndex: AvailableColumns.companyId,
        render: (companyId: string) => <CustomColumn value={companyId} />,
    },
    {
        title: 'Registration number',
        dataIndex: AvailableColumns.registrationNumber,
        render: (registrationNumber: string) => <CustomColumn value={registrationNumber} />,
    },
]

const defaultVisibleColumns = [
    AvailableColumns.companyName,
    AvailableColumns.country,
    AvailableColumns.companyId,
    AvailableColumns.registrationNumber,
]

const PageBreadcrumb: FC = () => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to="/customer-success/company-360">Company 360</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Advanced company search</Breadcrumb.Item>
        </Breadcrumb>
    )
}

const CompanyAdvancedSearch: FC = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { data } = useGetCompanies()

    const debouncedSetSearchParams = debounce((value, fieldName) => {
        if (value) {
            searchParams.set(fieldName, value)
        } else {
            searchParams.delete(fieldName)
        }
        setSearchParams(searchParams)
    }, 300)

    const onChangeInput = (value: any, fieldName: string) => {
        if (searchParams.get('offset')) {
            searchParams.set('offset', '0')
        }
        debouncedSetSearchParams(value, fieldName)
    }

    const onTableRowClick = (record: any) => {
        navigate(`/customer-success/company-360/${record.id}`)
    }

    const initialValues = {
        name: searchParams.get(AvailableColumns.companyName) || undefined,
        country: searchParams.get(AvailableColumns.country) || undefined,
        id: searchParams.get(AvailableColumns.companyId) || undefined,
        registrationNumber: searchParams.get(AvailableColumns.registrationNumber) || undefined,
    }

    return (
        <PageContentLayout>
            <PageHeader title="Advanced company search" breadcrumb={<PageBreadcrumb />} />
            <Card size="small" title="Search fields">
                <Formik
                    initialValues={initialValues}
                    onSubmit={() => setSearchParams(searchParams)}
                >
                    {() => (
                        <Form
                            layout="vertical"
                            style={{
                                alignContent: 'flex-start',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                rowGap: spacing.space24,
                            }}
                        >
                            <AdvancedSearchInput
                                key={AvailableColumns.companyName}
                                label="Company name"
                                name={AvailableColumns.companyName}
                                onChange={onChangeInput}
                            />

                            <AdvancedSearchCountrySelect
                                key={AvailableColumns.country}
                                label="Country"
                                name={AvailableColumns.country}
                                onChange={onChangeInput}
                                testId="country-select"
                            />
                            <AdvancedSearchInput
                                key={AvailableColumns.companyId}
                                label="Company ID"
                                name={AvailableColumns.companyId}
                                onChange={onChangeInput}
                                widthOverride={300}
                            />
                            <AdvancedSearchInput
                                key={AvailableColumns.registrationNumber}
                                label="Registration number"
                                name={AvailableColumns.registrationNumber}
                                onChange={onChangeInput}
                                widthOverride={300}
                            />
                        </Form>
                    )}
                </Formik>
            </Card>
            <ToggleTable<GetCompaniesResponse>
                data={data}
                onTableRowClick={onTableRowClick}
                allColumns={columns}
                defaultColumns={defaultVisibleColumns}
            />
        </PageContentLayout>
    )
}

export default CompanyAdvancedSearch
