import type { RangeRiskScore } from 'types/customer-risk-rating'

export enum SliderRowPosition {
    TOP = 'TOP',
    MIDDLE = 'MIDDLE',
    BOTTOM = 'BOTTOM',
}

// sliderRowPosition is not the value of the slider but whether the slider being checked is either:
// - the very top slider
// - one of the sliders in the middle section
// - the very bottom slider
export function getSliderRowPosition(
    currentRange: number,
    numOfRanges: number
): SliderRowPosition | undefined {
    if (currentRange === 0) return SliderRowPosition.TOP
    if (currentRange > 0 && currentRange < numOfRanges) return SliderRowPosition.MIDDLE
    if (currentRange === numOfRanges) return SliderRowPosition.BOTTOM
}

export function topSliderMoved(ranges: RangeRiskScore[], numOfRanges: number) {
    // Ensure lowerBound is 0
    ranges[0].lowerBound = 0

    // Ensure upperBound is not less than 0
    if (ranges[0].upperBound < 0) ranges[0].upperBound = 0

    // Ensure upperBound is not more than (highest range upperBound - numOfRanges)
    if (ranges[0].upperBound > ranges[numOfRanges].upperBound - numOfRanges)
        ranges[0].upperBound = ranges[numOfRanges].upperBound - numOfRanges

    // For each next range ensure no gaps or overlaps
    for (let i = 1; i < numOfRanges + 1; i++) {
        ranges[i].lowerBound = ranges[i - 1].upperBound + 1
        if (ranges[i].upperBound < ranges[i].lowerBound) ranges[i].upperBound = ranges[i].lowerBound
    }

    return ranges
}

export function middleSliderMoved(
    ranges: RangeRiskScore[],
    numOfRanges: number,
    currentRange: number
) {
    // Ensure lowerBound is 0
    ranges[0].lowerBound = 0

    // Ensure upperBound is not less than 0
    if (ranges[0].upperBound < 0) ranges[0].upperBound = 0

    // Ensure upperBound is not more than (highest range upperBound - numOfRanges)
    if (ranges[0].upperBound > ranges[numOfRanges].upperBound - numOfRanges)
        ranges[0].upperBound = ranges[numOfRanges].upperBound - numOfRanges

    // For each range going backward, ensure no gaps or overlaps
    for (let i = currentRange - 1; i >= 0; i--) {
        ranges[i].upperBound = ranges[i + 1].lowerBound - 1
        if (ranges[i].upperBound < ranges[i].lowerBound) ranges[i].lowerBound = ranges[i].upperBound
    }

    // For each range going forward, ensure no gaps or overlaps
    for (let i = currentRange + 1; i < numOfRanges + 1; i++) {
        ranges[i].lowerBound = ranges[i - 1].upperBound + 1
        if (ranges[i].upperBound < ranges[i].lowerBound) ranges[i].upperBound = ranges[i].lowerBound
    }

    return ranges
}

export function bottomSlidersMoved(
    ranges: RangeRiskScore[],
    numOfRanges: number,
    currentRange: number
) {
    // Ensure lowerBound is not less than numOfRanges
    if (ranges[numOfRanges].lowerBound < numOfRanges) ranges[numOfRanges].lowerBound = numOfRanges

    // Ensure upperBound is not less than numOfRanges
    if (ranges[numOfRanges].upperBound < numOfRanges) ranges[numOfRanges].upperBound = numOfRanges

    // For each range going backwards, ensure no gaps or overlaps
    for (let i = currentRange - 1; i >= 0; i--) {
        ranges[i].upperBound = ranges[i + 1].lowerBound - 1
        if (ranges[i].upperBound < ranges[i].lowerBound) ranges[i].lowerBound = ranges[i].upperBound
    }

    return ranges
}
