import useSWR from 'swr'
import type { ListCadmusReportsResponse } from 'types/cadmus-reports'
import request, { fetcher } from './request'

export const useCashbackReportList = () => {
    return useSWR<ListCadmusReportsResponse>(
        'rest/v1/finance-tooling/cashback/reports?include_history=false',
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        }
    )
}

export const getCashbackPresignedLink = async (fileName: string, version: string) => {
    return request().get(`rest/v1/finance-tooling/cashback/reports/${fileName}/${version}`)
}
