import { App, Button, Form, Popconfirm, Select, Space, Typography } from 'antd'
import { countries } from 'countries-list'
import { useState } from 'react'
import { useAmlAnswers } from 'services/deimos/styx-company/styx-company'

const MAX_COUNTRIES = 5

export const AmlCountries = ({ companyId }: { companyId: string }) => {
    const { amlAnswers, isLoading, putFrequentlyUsedInCountries } = useAmlAnswers(companyId)
    const { notification } = App.useApp()

    const hasNoCountries = amlAnswers?.frequentlyUsedInCountries?.length === 0

    const [selectedCountries, setSelectedCountries] = useState<string[]>([])

    const handleSelect = (value: string) => {
        if (selectedCountries.includes(value)) {
            setSelectedCountries(selectedCountries.filter((country) => country !== value))
        } else {
            if (selectedCountries.length < MAX_COUNTRIES) {
                setSelectedCountries([...selectedCountries, value])
            } else {
                notification.error({
                    message: `You can only select up to ${MAX_COUNTRIES} countries`,
                })
            }
        }
    }

    const handleDeselect = (value: string) => {
        setSelectedCountries(selectedCountries.filter((country) => country !== value))
    }

    const handleSaveCountries = async () => {
        await putFrequentlyUsedInCountries(selectedCountries)
    }

    return (
        <>
            {!isLoading && hasNoCountries ? (
                <Form layout="horizontal">
                    <Space.Compact style={{ width: '100%' }}>
                        <Form.Item
                            name="frequentlyUsedInCountries"
                            data-testid="frequentlyUsedInCountries"
                            style={{ width: '100%', marginBottom: 0 }}
                        >
                            <Select
                                mode="multiple"
                                value={selectedCountries}
                                style={{ width: '100%' }}
                                placeholder="Select countries"
                                options={Object.keys(countries).map((country) => ({
                                    value: country,
                                    label: `${
                                        countries[country as keyof typeof countries].name
                                    } (${country})`,
                                }))}
                                optionFilterProp="label"
                                onSelect={(selection) => handleSelect(selection)}
                                onDeselect={(selection) => handleDeselect(selection)}
                                onClear={() => setSelectedCountries([])}
                                allowClear
                            />
                        </Form.Item>
                        <Popconfirm
                            title="Are you sure you want to save?"
                            description="This information can not be changed later."
                            onConfirm={handleSaveCountries}
                            okText="Yes"
                            cancelText="No"
                            disabled={selectedCountries.length === 0}
                            data-testid="frequentlyUsedInCountries-popconfirm"
                        >
                            <Button
                                disabled={selectedCountries.length === 0}
                                data-testid="frequentlyUsedInCountries-save"
                            >
                                Save
                            </Button>
                        </Popconfirm>
                    </Space.Compact>
                </Form>
            ) : (
                <FrequentlyUsedInCountries
                    frequentlyUsedInCountries={amlAnswers?.frequentlyUsedInCountries}
                />
            )}
        </>
    )
}

const FrequentlyUsedInCountries = ({
    frequentlyUsedInCountries,
}: {
    frequentlyUsedInCountries?: string[]
}) => {
    return (
        <Typography.Text>
            {frequentlyUsedInCountries
                ?.map(
                    (country) => `${countries[country as keyof typeof countries].name} (${country})`
                )
                .join(', ')}
        </Typography.Text>
    )
}
