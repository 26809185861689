import { array, number, object, string, boolean } from 'yup'
import { MAX_EMPLOYEES, MAX_EXPENSES } from './limits'

const mileage = object({
    rate: string(),
    currency: string(),
})

const company = object({
    address: object({
        country: string().required(),
        city: string().required(),
    }),
    language: string().required(),
    wallet: object({
        initialLoad: number().min(0),
        loadHistory: array().of(
            object({
                time: string(),
                amount: number(),
            })
        ),
    }),
    mileage,
})

const employees = array()
    .of(
        object({
            name: string().required(),
            count: number().max(MAX_EMPLOYEES).required(),
            limits: object({
                card: object({
                    value: number().required(),
                    type: string().required(),
                }),
                perPurchase: string().required(),
            }).required(),
            virtualCard: boolean().required(),
            physicalCard: boolean().nullable(),
        }).required()
    )
    .required()

const teams = array()
    .of(
        object({
            name: string().required(),
            size: number().required(),
            reviewerCount: number().required(),
        })
    )
    .required()

const expenses = array()
    .of(
        object({
            name: string().required(),
            expenseType: string().required(),
            merchantProfiles: array()
                .of(
                    object({
                        count: number().max(MAX_EXPENSES).required(),
                        currency: string(),
                        personal: boolean(),
                        amountMin: number(),
                        amountMax: number(),
                        merchantCategoryCode: string(),
                    }).required()
                )
                .required(),
        }).required()
    )
    .required()

const additionalExpenses = boolean().default(false)

const meta = object({
    companyName: string().required(),
    requesterEmail: string().required(),
    expiresAt: string().required(),
})

export const validation = object({
    company,
    employees,
    teams,
    expenses,
    additionalExpenses,
    meta,
    selectedProfileId: string(),
})
