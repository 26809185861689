import React, { FC, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Tooltip, Modal } from 'antd'

import RegistrySearch from 'components/registry-search/registry-search'

interface Props {
    country: string
    onUpdateRegistryId: (registryId: string) => void
}

const RegistryIdSearch: FC<React.PropsWithChildren<Props>> = ({ country, onUpdateRegistryId }) => {
    const [visible, setVisible] = useState(false)
    const [registryId, setRegistryId] = useState('')

    return (
        <>
            <Tooltip title="Search registry for registry ID">
                <SearchOutlined onClick={() => setVisible(true)} />
            </Tooltip>
            <Modal
                okText="Update registry ID"
                onOk={() => {
                    onUpdateRegistryId(registryId)
                    setVisible(false)
                }}
                open={visible}
                width={800}
                onCancel={() => setVisible(false)}
                title={`Update Registry ID from ${country} Registry`}
            >
                <RegistrySearch
                    country={country}
                    handleSearchChange={(id) => {
                        setRegistryId(id)
                    }}
                />
            </Modal>
        </>
    )
}

export default RegistryIdSearch
