import type {
    Account,
    AccountCategory,
    AccountingAccount,
    CreateAccount,
    CreateAccountCategory,
    CreateAccountCategoryResponse,
    CreateAccountResponse,
    ErrorResponse,
    GetAllAccountCategoryResponse,
    GetAllAccountingAccountResponse,
    SuccessResponse,
    UpdateAccount,
    UpdateAccountCategory,
    UpdateAccountCategoryResponse,
    UpdateAccountResponse,
} from '@pleo-io/deimos'

import useSWR from 'swr'
import request, { fetcher } from './request'

function createAccountCategory(
    companyId: string,
    payload: Partial<CreateAccountCategory>
): Promise<CreateAccountCategoryResponse> {
    return request()
        .post(`rest/v1/companies/${companyId}/account-categories`, {
            json: payload,
        })
        .json()
}

function deleteAccountCategory(id: string): Promise<SuccessResponse> {
    return request().delete(`rest/v1/account-categories/${id}`).json()
}

function updateAccountCategory(
    id: string,
    payload: Partial<UpdateAccountCategory>
): Promise<UpdateAccountCategoryResponse> {
    return request()
        .put(`rest/v1/account-categories/${id}`, {
            json: payload,
        })
        .json()
}

function createAccountCategoryAccount(
    companyId: string,
    payload: Partial<CreateAccount>
): Promise<CreateAccountResponse> {
    return request()
        .post(`rest/v1/companies/${companyId}/accounts`, {
            json: payload,
        })
        .json()
}

function updateAccountCategoryAccount(
    id: string,
    payload: Partial<UpdateAccount>
): Promise<UpdateAccountResponse> {
    return request()
        .put(`rest/v1/accounts/${id}`, {
            json: payload,
        })
        .json()
}

function deleteAccountCategoryAccount(id: string): Promise<SuccessResponse> {
    return request().delete(`rest/v1/accounts/${id}`).json()
}

export type AccountCategoryMutations = {
    createCategory: (payload: Partial<CreateAccountCategory>) => Promise<void>
    updateCategory: (id: string, payload: Partial<UpdateAccountCategory>) => Promise<void>
    archiveCategory: (id: string) => Promise<void>
    unarchiveCategory: (id: string) => Promise<void>
    deleteCategory: (id: string) => Promise<void>
    createAccount: (payload: Partial<CreateAccount>) => Promise<void>
    updateAccount: (id: string, payload: Partial<UpdateAccount>) => Promise<void>
    archiveAccount: (id: string) => Promise<void>
    unarchiveAccount: (id: string) => Promise<void>
    deleteAccount: (id: string) => Promise<void>
}

export type UseAccountCategories = {
    data?: GetAllAccountCategoryResponse | undefined
    error?: Error | undefined
    isValidating: boolean
    mutations: AccountCategoryMutations
}

export function useAccountCategories({
    companyId,
    includeDepartments,
}: {
    companyId: string
    includeDepartments?: boolean
}): UseAccountCategories {
    const departmentsAppendix = includeDepartments ? '&includeDepartments=true' : ''

    const url = companyId
        ? `rest/v1/companies/${companyId}/account-categories?eager=true&sortBy=createdAt&order=DESC${departmentsAppendix}`
        : null

    const result = useSWR<GetAllAccountCategoryResponse, Error>(url, fetcher, {
        revalidateOnFocus: false,
    })

    async function createCategory(payload: Partial<CreateAccountCategory>) {
        const data = result?.data ?? []
        if (!data.length || !companyId) {
            return
        }

        const response = await createAccountCategory(companyId, payload)
        result.mutate([response, ...data], false)
    }

    async function deleteCategory(id: string) {
        const data = result?.data ?? []
        const index = data.findIndex((category: AccountCategory) => category.id === id) ?? -1

        if (!data.length || index === -1) {
            return
        }

        await deleteAccountCategory(id)
        result.mutate([...data.slice(0, index), ...data.slice(index + 1)], false)
    }

    async function updateCategory(id: string, payload: Partial<UpdateAccountCategory>) {
        const data = result?.data ?? []
        const index = data.findIndex((category: AccountCategory) => category.id === id)

        if (!data.length || index === -1) {
            return
        }

        const response = await updateAccountCategory(id, payload)
        const updatedCategory = { ...data[index], ...response }

        result.mutate([...data.slice(0, index), updatedCategory, ...data.slice(index + 1)], false)
    }

    async function archiveCategory(id: string) {
        await updateCategory(id, { hidden: true })
    }

    async function unarchiveCategory(id: string) {
        await updateCategory(id, { hidden: false })
    }

    async function createAccount(payload: Partial<CreateAccount>) {
        const data = result?.data ?? []
        const index = data.findIndex(
            (category: AccountCategory) => category.id === payload.accountCategoryId
        )

        if (!data.length || index === -1 || !companyId) {
            return
        }

        const response = await createAccountCategoryAccount(companyId, payload)
        const accounts = data[index]?.accounts ?? []

        result.mutate(
            [
                ...data.slice(0, index),
                {
                    ...data[index],
                    accounts: [...accounts, response],
                },
                ...data.slice(index + 1),
            ],
            false
        )
    }

    async function updateAccount(id: string, payload: Partial<UpdateAccount>) {
        const data = result?.data ?? []
        const { accountIndex, accountCategoryIndex } = findAccountItem(id, data)

        if (!data.length || accountIndex === -1 || accountCategoryIndex === -1) {
            return
        }

        const accounts = data[accountCategoryIndex].accounts || []
        const response = await updateAccountCategoryAccount(id, payload)

        result.mutate(
            [
                ...data.slice(0, accountCategoryIndex),
                {
                    ...data[accountCategoryIndex],
                    accounts: [
                        ...accounts.slice(0, accountIndex),
                        {
                            ...response,
                        },
                        ...accounts.slice(accountIndex + 1),
                    ],
                },
                ...data.slice(accountCategoryIndex + 1),
            ],
            false
        )
    }

    async function archiveAccount(id: string) {
        const data = result?.data ?? []
        const { accountIndex, accountCategoryIndex } = findAccountItem(id, data)

        if (!data.length || accountIndex === -1 || accountCategoryIndex === -1) {
            return
        }

        const accounts = data[accountCategoryIndex].accounts || []
        await updateAccountCategoryAccount(id, { hidden: true })

        result.mutate([
            ...data.slice(0, accountCategoryIndex),
            {
                ...data[accountCategoryIndex],
                accounts: accounts.slice(0).filter((account: Account) => account.id !== id),
            },
            ...data.slice(accountCategoryIndex + 1),
        ])
    }

    async function unarchiveAccount(id: string) {
        const data = result?.data ?? []
        const { accountIndex, accountCategoryIndex } = findAccountItem(id, data)

        if (!data.length || accountIndex === -1 || accountCategoryIndex === -1) {
            return
        }

        const accounts = data[accountCategoryIndex].accounts || []
        await updateAccountCategoryAccount(id, { hidden: false })

        result.mutate([
            ...data.slice(0, accountCategoryIndex),
            {
                ...data[accountCategoryIndex],
                accounts: accounts.slice(0).filter((account: Account) => account.id !== id),
            },
            ...data.slice(accountCategoryIndex + 1),
        ])
    }

    async function deleteAccount(id: string) {
        const data = result?.data ?? []
        const { accountIndex, accountCategoryIndex } = findAccountItem(id, data)

        if (!data.length || accountIndex === -1 || accountCategoryIndex === -1) {
            return
        }

        const accounts = data[accountCategoryIndex].accounts || []
        await deleteAccountCategoryAccount(id)

        result.mutate(
            [
                ...data.slice(0, accountCategoryIndex),
                {
                    ...data[accountCategoryIndex],
                    accounts: [
                        ...accounts.slice(0, accountIndex),
                        ...accounts.slice(accountIndex + 1),
                    ],
                },
                ...data.slice(accountCategoryIndex + 1),
            ],
            false
        )
    }

    return {
        ...result,
        mutations: {
            createCategory,
            updateCategory,
            archiveCategory,
            unarchiveCategory,
            deleteCategory,
            createAccount,
            updateAccount,
            archiveAccount,
            unarchiveAccount,
            deleteAccount,
        },
    }
}

export const findAccountItem = (id: string, accountCategories: AccountCategory[]) => {
    const targetCategory = accountCategories.find(({ accounts = [] }) => {
        return accounts.findIndex((account) => account.id === id) !== -1
    })

    const accountCategoryIndex = accountCategories.indexOf(targetCategory!)
    const accountIndex = targetCategory?.accounts?.findIndex((account) => account.id === id) ?? -1

    return {
        accountCategoryIndex,
        accountIndex,
    }
}

export function useCompanyAccountingAccounts({ companyId }: { companyId: string }) {
    const requestKey = `rest/v1/companies/${companyId}/accounting/accounts`

    return useSWR<GetAllAccountingAccountResponse, ErrorResponse>(requestKey, fetcher, {
        revalidateOnFocus: false,
        shouldRetryOnError: false,
    })
}

export interface AccountOption {
    label: string
    value: string
    data: AccountingAccount
}

export function useCompanyAccountingAccountsOptions({ companyId }: { companyId: string }) {
    const result = useCompanyAccountingAccounts({ companyId })
    const data = result?.data ?? []

    const accountsOptions: AccountOption[] = getAccountsOptions(data).sort((a, b) =>
        a.label.localeCompare(b.label)
    )

    return { ...result, accountsOptions }
}

const getAccountsOptions = (accounts: AccountingAccount[]) =>
    accounts.map((account) => ({
        label: account.code ? `${account.code} - ${account.name}` : account.name,
        value: account.id || account.code,
        data: account,
    }))
