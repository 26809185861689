import type { FC, ReactNode } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'
import { fontSize } from 'theme/tokens'

const { Text } = Typography

interface Props {
    label: string
    copyable?: boolean
    testId?: string
    icon?: ReactNode
}

const InfoBlock: FC<React.PropsWithChildren<Props>> = ({
    label,
    copyable,
    testId,
    children,
    icon,
}) => (
    <div data-testid={testId}>
        <Label type="secondary">
            {label} {icon ? icon : null}
        </Label>
        <br />
        <Text copyable={copyable}>{children}</Text>
    </div>
)

const Label = styled(Text)`
    font-size: ${fontSize.size12};
`

export default InfoBlock
