import type { RiskScoreRow, RiskScores, RiskVersion } from 'types/customer-risk-rating'
import { deSnakify } from 'utils/strings'

export function getRiskScoreTable(riskScores?: RiskScores[]) {
    if (!riskScores?.length) return

    const table: RiskScoreRow[] = []

    for (const company of riskScores!) {
        const row: RiskScoreRow = { companyId: company.companyId }
        for (const version in company.riskScoreResults) {
            row[version] = deSnakify(company.riskScoreResults[version])
        }
        table.push(row)
    }

    return table
}

export function getScoresChart(allVersions: RiskVersion[], riskScores?: RiskScores[]) {
    if (!riskScores?.length) return

    const scores = ['Low', 'Medium low', 'Medium high', 'High']
    const versionNames = allVersions.map((version) => version.description)
    const versionIds = allVersions.map((version) => version.id)
    const headings = ['Scores', ...versionNames]

    const companyRiskScoreMatch = function (risk: RiskScores, versionId: string, score: string) {
        return (
            risk.riskScoreResults[versionId] &&
            deSnakify(risk.riskScoreResults[versionId]) === score
        )
    }

    const scoreCounts = scores.map((score) => {
        const versionsScoreCounts = versionIds.map((versionId) => {
            return riskScores.filter((risk) => companyRiskScoreMatch(risk, versionId, score)).length
        })

        return [score, ...versionsScoreCounts]
    })

    return [headings, ...scoreCounts]
}

export function getVersionsChart(allVersions: RiskVersion[], riskScores?: RiskScores[]) {
    if (!riskScores?.length) return

    const scores = ['Low', 'Medium low', 'Medium high', 'High']
    const headings = ['Version', ...scores]

    function companyRiskScoreMatchCount(company: RiskScores, score: string, versionId: string) {
        const countArray = Object.keys(company.riskScoreResults).filter(
            (key) => key === versionId && deSnakify(company.riskScoreResults[versionId]) === score
        )

        return countArray.length
    }

    const scoreCounts = allVersions.map((version) => {
        const versionScoreCounts = scores.flatMap((score) =>
            riskScores
                .map((company) => companyRiskScoreMatchCount(company, score, version.id))
                .reduce((acc, value) => acc + value)
        )

        return [version.description, ...versionScoreCounts]
    })

    return [headings, ...scoreCounts]
}
