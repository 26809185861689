import { absurd } from 'utils/absurd'
import type { App, AppClientPair, OAuth2Client } from './app'

interface CreateAppFormValuesBase {
    /** A human-readable name for identification purposes */
    name: string
    /** A unique human-readable identifier for URL building and cross-environment identification */
    slug: string
    /** The category to which the application belongs to (e.g. "PEOPLE_MANAGEMENT" or "ACCOUNTING") */
    category: App['category']
    /** The authentication method used by the app to access Pleo's APIs (e.g. "OAUTH2" or "API_KEY" or "NONE") */
    authenticationMethod: App['authenticationMethod']
    /** The application's type (e.g. "THIRD_PARTY" or "DIRECT" or "MANUAL") */
    type: App['type']
}

/** The form values in the Create App screen */
export type CreateAppFormValues = CreateAppFormValuesBase &
    (
        | { authenticationMethod: 'NONE' }
        | { authenticationMethod: 'API_KEY'; scope: string[] }
        | { authenticationMethod: 'OAUTH2'; scope: string[]; redirectUris: string }
    )

export function toAppClientPair(formValues: CreateAppFormValues): AppClientPair {
    switch (formValues.authenticationMethod) {
        case 'NONE':
            return {
                type: 'NONE',
                app: toBaseApp(formValues, 'NONE'),
            }

        case 'API_KEY':
            return {
                type: 'API_KEY',
                app: toBaseApp(formValues, 'API_KEY'),
                client: { scope: toScope(formValues.scope) },
            }

        case 'OAUTH2':
            return {
                type: 'OAUTH2',
                app: toBaseApp(formValues, 'OAUTH2'),
                client: {
                    name: formValues.name,
                    scope: toScope(formValues.scope),
                    redirectUri: toRedirectUri(formValues.redirectUris),
                },
            }

        default:
            return absurd(formValues)
    }
}

const toBaseApp = (
    appData: CreateAppFormValuesBase,
    authenticationMethod: App['authenticationMethod']
): App => ({
    authenticationMethod,
    category: appData.category,
    name: appData.name,
    slug: appData.slug,
    type: appData.type,
})

const toScope = (formValue: string[]): OAuth2Client['scope'] => formValue.join(' ')

const toRedirectUri = (formValue: string): OAuth2Client['redirectUri'] =>
    formValue
        .split('\n')
        .map((uri) => uri.trim())
        .filter(Boolean)
