import { useState } from 'react'
import { Card, Button, Table, message, Space } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import { Formik } from 'formik'
import { Input as AntInput, Form, Select } from 'formik-antd'
import { inputWidth } from 'theme/tokens'
import type { Rule } from 'types/custom-spend-rule'
import type { ColumnProps } from 'antd/lib/table'

import { getRulesByCompanyId } from 'services/skoll/rules'
import { useUser } from 'providers/user-context'
import * as Yup from 'yup'
import { createBffHooks } from 'packages/bff/create-bff-hooks'
import type { ComponentsRouter } from 'components/index.bff'

const bff = createBffHooks<ComponentsRouter>().components.customSpend

const getRulesValidationSchema = Yup.object().shape({
    companyId: Yup.string().required('Company ID is required'),
    cardIds: Yup.array().min(1),
})

const archiveRulesValidationSchema = Yup.object().shape({
    ruleId: Yup.string().required('Rule ID is required'),
})

let ruleList: Rule[] = []

const columns: ColumnProps<Rule>[] = [
    {
        title: 'RuleId',
        dataIndex: 'id',
    },
    {
        title: 'Rule Name',
        dataIndex: 'name',
    },
]

export const CustomSpendRules = () => {
    const [isLoading, setLoading] = useState(false)
    const { email } = useUser()
    const rules: Rule[] = []
    const { mutateAsync: createCustomSpendRule, isLoading: createCustomSpendRuleIsLoading } =
        bff.createCustomSpendRule.useMutation()
    const { mutateAsync: archiveCustomSpendRule, isLoading: archiveCustomSpendRuleIsLoading } =
        bff.archiveCustomSpendRule.useMutation()
    const { mutateAsync: validateAndResolveRequest } = bff.validateAndResolveRequest.useMutation()

    async function getRules(companyId: string) {
        try {
            setLoading(true)
            ruleList = await getRulesByCompanyId(companyId)
        } catch (e) {
            message.error(`Error fetching rules for company ${companyId}: ${(e as Error).message}`)
        } finally {
            setLoading(false)
        }
    }

    async function createRules(companyId: string, cardIds: string[], ruleType: string) {
        if (cardIds.length > 0) {
            cardIds.map(async (cardId) => createRule(companyId, cardId, ruleType))
        } else {
            createRule(companyId, null, ruleType)
        }
    }

    async function createRule(companyId: string, cardId: string | null, ruleType: string) {
        try {
            await validateAndResolveRequest({ companyId, cardId })
            createCustomSpendRule({ companyId, email: email ? email : '', cardId, ruleType })
            message.success(
                `Created ${ruleType} rule for companyId ${companyId} on ${cardId || 'all cards'}`
            )
        } catch (e) {
            message.error(`Error creating rule for company ${companyId}: ${(e as Error).message}`)
            return
        }
    }

    async function archiveRule(ruleId: string) {
        try {
            setLoading(true)
            await archiveCustomSpendRule({ ruleId })
            message.success(`Archived rule with id ${ruleId}`)
        } catch (e) {
            message.error(`Error archiving rule ${ruleId}`)
            return
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageContentLayout>
            <Card title="Company rules">
                <Formik
                    validationSchema={getRulesValidationSchema}
                    validateOnMount
                    initialValues={{ companyId: '' }}
                    onSubmit={({ companyId }) => {
                        getRules(companyId)
                    }}
                >
                    {({ values }) => (
                        <Form layout="inline">
                            <Form.Item name="companyId" label="Company ID">
                                <AntInput
                                    name="companyId"
                                    placeholder="50e7973e-e312-4e74-9ba7-561925c4229f"
                                    style={{ width: `${inputWidth.large}` }}
                                />
                            </Form.Item>
                            <Form.Item name="submit">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={isLoading}
                                    disabled={!values.companyId}
                                >
                                    Get company rules
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                </Formik>
                <Space style={{ width: '100%' }} direction="vertical">
                    <Table
                        loading={!rules}
                        pagination={{ defaultPageSize: 5 }}
                        rowKey="id"
                        dataSource={ruleList}
                        columns={columns}
                    />
                </Space>
            </Card>

            <Card title="Create rule">
                <Formik
                    validationSchema={getRulesValidationSchema}
                    validateOnMount
                    initialValues={{ companyId: '', cardIds: [], ruleType: 'BLOCK' }}
                    onSubmit={({ companyId, cardIds, ruleType }) => {
                        createRules(companyId, cardIds, ruleType)
                    }}
                >
                    {({ values }) => (
                        <Form layout="inline">
                            <Form.Item name="companyId" label="Company ID">
                                <AntInput
                                    name="companyId"
                                    placeholder="1af958ec-2139-4425-8efb-9c16b8e27c1c"
                                    style={{ width: `${inputWidth.medium}` }}
                                />
                            </Form.Item>
                            <Form.Item name="cardIds" label="Card IDs">
                                <Select
                                    mode="tags"
                                    name="cardIds"
                                    placeholder="371491a6-2993-44a7-80e5-edd7b0a52327"
                                    tokenSeparators={[',', ' ', '\n', "'"]}
                                    style={{ width: `${inputWidth.medium}` }}
                                />
                            </Form.Item>
                            <Form.Item name="ruleType" label="Rule Type">
                                <Select name="ruleType" style={{ width: `${inputWidth.medium}` }}>
                                    <Select.Option value={'BLOCK'}>BLOCK</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="submit">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={createCustomSpendRuleIsLoading}
                                    disabled={!values.companyId}
                                >
                                    Create Rule
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                </Formik>
            </Card>

            <Card title="Archive rule">
                <Formik
                    validationSchema={archiveRulesValidationSchema}
                    validateOnMount
                    initialValues={{ ruleId: '' }}
                    onSubmit={({ ruleId }) => {
                        archiveRule(ruleId)
                    }}
                >
                    {({ values }) => (
                        <Form layout="inline">
                            <Form.Item name="ruleId" label="Rule ID">
                                <AntInput
                                    name="ruleId"
                                    placeholder="C6AYSTF89H"
                                    style={{ width: `${inputWidth.large}` }}
                                />
                            </Form.Item>
                            <Form.Item name="submit">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={archiveCustomSpendRuleIsLoading}
                                    disabled={!values.ruleId}
                                >
                                    Archive rule
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                </Formik>
            </Card>
        </PageContentLayout>
    )
}

export default CustomSpendRules
