import type { PaginatedLoginEventResponse } from 'bff/moons/generated/pandora-v1'
import { fetcher } from 'services/pandora/request'
import useSWR from 'swr'

interface LoginEventsProps {
    email?: string
    limit?: number
    offset?: number
    from?: string
    to?: string
    eventName?: string
    domain?: string
}

export function useLoginEvents(params: LoginEventsProps) {
    const { email, limit, offset, from, to, eventName, domain } = params

    let paramString = ''

    if (email) {
        const hasPlus = email.includes('+')
        paramString += `&email=${hasPlus ? encodeURIComponent(email) : email}`
    }

    if (limit) {
        paramString += `&limit=${limit}`
    }

    if (offset) {
        paramString += `&offset=${offset}`
    }

    if (from) {
        paramString += `&from=${from}`
    }

    if (to) {
        paramString += `&to=${to}`
    }

    if (eventName) {
        paramString += `&eventName=${eventName}`
    }

    if (domain) {
        paramString += `&domain=${domain}`
    }

    if (paramString) {
        paramString = paramString.replace(paramString[0], '?')
    }

    const result = useSWR<PaginatedLoginEventResponse, Error>(
        `login-events${paramString}`,
        fetcher,
        { revalidateOnFocus: false }
    )

    return result
}
