import React, { FC, useState } from 'react'

import type { FormValues } from './validation-schema'
import { PlusOutlined } from '@ant-design/icons'
import { Popover, Button, message } from 'antd'
import { useCompanyComplianceDocuments } from 'services/deimos/styx-company/styx-company'
import DocumentForm from './document-form'

interface Props {
    companyId: string
}

export const CreateComplianceDocument: FC<React.PropsWithChildren<Props>> = ({ companyId }) => {
    const [visible, setVisibility] = useState(false)

    const { mutations } = useCompanyComplianceDocuments(companyId)

    const createComplianceDocument = async ({ title, description }: FormValues) => {
        try {
            await mutations.createDocument(title, description)
        } catch (e) {
            message.error(`Failed to create document: ${(e as Error).message}`)
        }
    }

    const onSubmit = (values: FormValues) => {
        setVisibility(false)
        createComplianceDocument(values)
    }

    return (
        <Popover
            title="Create new document"
            placement="right"
            trigger="click"
            open={visible}
            onVisibleChange={() => setVisibility(!visible)}
            destroyTooltipOnHide
            content={<DocumentForm onSubmit={onSubmit} />}
        >
            <Button data-testid="button" icon={<PlusOutlined />}>
                Add document
            </Button>
        </Popover>
    )
}
