import type { FC } from 'react'

import * as Yup from 'yup'
import { Formik } from 'formik'
import { Input, Form } from 'formik-antd'
import { Button } from 'antd'

const validationSchema = Yup.object()
    .shape({
        firstName: Yup.string().required('Required'),
        email: Yup.string().email().required('Required'),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface Props {
    onSubmit: (values: FormValues) => void
}

const InvitePartnerEmployeeForm: FC<React.PropsWithChildren<Props>> = ({ onSubmit }) => {
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{ firstName: '', email: '' }}
            validationSchema={validationSchema}
        >
            {({ isValid }) => (
                <Form layout="vertical">
                    <Form.Item name="firstName" label="First name">
                        <Input name="firstName" placeholder="First name" />
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                        <Input name="email" placeholder="Email" />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" disabled={!isValid}>
                        Send Invite
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

export default InvitePartnerEmployeeForm
