import KycCaseContainer from 'pages/compliance/company/proof-of-business/components/kyc-case-container'
import { PageContentLayout } from 'components/layout-containers'
import { Card, Col, Row } from 'antd'
import { CreateKycCaseButton } from 'pages/compliance/company/proof-of-business/components/create-kyc-case-button'
import { bff } from 'pages/compliance/bff'
import BusinessExplanationContainer from 'pages/compliance/company/shared/business-explanation'
import NarrativeContainer from 'pages/compliance/company/shared/narrative'

export const ProofOfBusiness = ({ companyId }: { companyId: string }) => {
    const { mutateAsync: createKycCase } = bff.proofOfBusiness.createKycCase.useMutation()

    return (
        <PageContentLayout>
            <Card title="Company Proof of Business" headStyle={{ backgroundColor: '#f0f0f0' }}>
                <Row>
                    <Col span={12}>
                        <BusinessExplanationContainer />
                    </Col>
                    <Col span={12}>
                        <NarrativeContainer
                            label="Industry Narrative"
                            companyId={companyId}
                            category={'COMPANY_INDUSTRY'}
                        />
                    </Col>
                </Row>
            </Card>
            <Card
                title="Proof of Business Cases"
                extra={<CreateKycCaseButton companyId={companyId} onClick={createKycCase} />}
                style={{ marginBottom: '1rem' }}
                headStyle={{ backgroundColor: '#f0f0f0' }}
            >
                <KycCaseContainer companyId={companyId} />
            </Card>
        </PageContentLayout>
    )
}
