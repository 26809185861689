import { useParams } from 'react-router-dom'
import { buildFetcher, exportRequest } from 'services/request'
import type { CursorPaginatedResponseExportJob } from 'bff/moons/generated/export-dispatcher'
import useSWRInfinite from 'swr/infinite'
import { PAGE_SIZE } from './constants'
import { JobStatus } from '@pleo-io/deimos'

const statusMap: { [key: string]: JobStatus } = {
    failed: JobStatus.FAILED,
    pending: JobStatus.CREATED,
    completed: JobStatus.COMPLETED,
    in_progress: JobStatus.EXPORTING_EXPENSES,
    completed_with_errors: JobStatus.FAILED,
}

export const useExportHistoryExportAPI = ({ limit = PAGE_SIZE }: { limit?: number }) => {
    const { companyId } = useParams()

    const getKey = (_pageIndex: number, previousPageData: CursorPaginatedResponseExportJob) => {
        if (!companyId) return null

        if (previousPageData && !previousPageData.data) return null

        const params = new URLSearchParams()

        params.append('company_id', companyId)

        params.append(
            'limit',
            `${limit ?? previousPageData?.pagination.currentRequestPagination.limit}`
        )

        if (previousPageData?.pagination) {
            params.append('after', previousPageData?.pagination.endCursor!)
        }

        const query = params.toString()

        return `rest/v0/export-jobs?${query}`
    }

    const result = useSWRInfinite<CursorPaginatedResponseExportJob>(
        getKey,
        buildFetcher(exportRequest),
        {
            revalidateOnFocus: false,
            shouldRetryOnError: false,
        }
    )

    const data = (result.data ?? []).map((d) => d.data).flat()
    const pagination = result.data?.[result?.data.length - 1].pagination
    const isLoadingInitialData = !result.data && !result.error
    const isLoadingMore = !!(
        result.size > 0 &&
        result.data &&
        typeof result.data[result.size - 1] === 'undefined'
    )

    const fetchNextPage = () => result.setSize?.((result.size ?? 0) + 1)
    const fetchPreviousPage = () => result.setSize?.((result.size ?? 0) - 1)
    const fetchFirstPage = () => result.setSize?.(1)

    const exportHistory = data?.map((d) => ({
        ...d,
        employeeId: d.createdBy,
        status: statusMap[d.status],
    }))

    return {
        exportHistory,
        pagination,
        isLoadingMore,
        isLoadingInitialData,
        fetchFirstPage,
        fetchNextPage,
        fetchPreviousPage,
    }
}
