import { Card, Divider } from 'antd'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import { ContentContainer, PageContentLayout } from 'components/layout-containers'
import ListB4BCompanies from 'pages/compliance/b4b/components/list-b4b-companies'
import { downloadPdfDocument, useB4BCompanies } from 'services/styx/b4b-companies'
import SearchForm from 'pages/compliance/b4b/components/search-form'

export const B4BCompanies = () => {
    const {
        companies,
        loading,
        nextPage,
        previousPage,
        updateLimit,
        pagination,
        resetFirstPage,
        limit,
        searchQuery,
    } = useB4BCompanies()

    return (
        <PageContentLayout>
            <Card title="Companies onboarded via B4B">
                <ContentContainer>
                    <SearchForm
                        loading={loading}
                        onSubmit={(values) => {
                            searchQuery(values)
                        }}
                    />
                    <Divider />
                    <ListB4BCompanies
                        onDownloadClick={downloadPdfDocument}
                        loading={loading}
                        companies={companies}
                    />
                    {pagination && (
                        <CursorPaginationControls
                            isFetching={!companies}
                            pagination={pagination}
                            onChangeLimit={updateLimit}
                            limit={limit}
                            onNextPage={nextPage}
                            onPrevPage={previousPage}
                            onFirstPage={resetFirstPage}
                        />
                    )}
                </ContentContainer>
            </Card>
        </PageContentLayout>
    )
}
