import { message } from 'antd'
import { eireneRequest } from 'services/request'
import useSWR from 'swr'
import type {
    BackOfficeReimbursementResponseModel,
    ReviewReimbursementScreeningRequestModel,
} from 'bff/moons/generated/eirene-v1'

function getEirene(route: string) {
    return eireneRequest()
        .get(route)
        .then((res) => res.json())
}

export const defaultPageSize = 10
const BASE_URL = 'rest/v2/reimbursements/back-office/reimbursement'

export const useEmployeeBankTransfers = () => {
    const response = useSWR<BackOfficeReimbursementResponseModel[]>(
        `${BASE_URL}/pending-screening-review`,
        getEirene
    )

    return {
        ...response,
        mutations: {},
        loading: !response.data && !response.error,
        bankTransfers: response.data || [],
    }
}

const putScreeningReview = async ({
    id,
    ...payload
}: ReviewReimbursementScreeningRequestModel & { id: string }) => {
    try {
        await eireneRequest().put(`${BASE_URL}/${id}/screening-review`, {
            json: payload,
        })

        message.success('Successfully update screening review.')
    } catch (e) {
        if ((e as Error)?.message) {
            message.error((e as Error).message)
        } else {
            message.error(`Could not update screening review for reimbursement ${id}`)
        }
    }
}

export const useEmployeeBankTransfer = (id: string) => {
    const response = useSWR<BackOfficeReimbursementResponseModel>(`${BASE_URL}/${id}`, getEirene)
    const updateScreeningReview = async (payload: ReviewReimbursementScreeningRequestModel) => {
        await putScreeningReview({ id, ...payload })
        await response.mutate()
    }
    return { ...response, mutations: { updateScreeningReview } }
}
