import { PageHeader } from '@ant-design/pro-layout'
import { Breadcrumb, Skeleton } from 'antd'
import dayjs from 'packages/dayjs'
import { useParams, useSearchParams, Link } from 'react-router-dom'
import { PageContentLayout } from 'components/layout-containers'
import { useGetCompany } from 'services/deimos/companies'

import { useCompanyCashback } from '../api/use-company-cashback'
import { CompanyAndDateSelector } from '../components/company-date-selector'
import { CashbackTable } from '../components/cashback-table'

const now = dayjs().utc().format('YYYY-MM-DD')
const oneYearAgo = dayjs().utc().subtract(1, 'year').format('YYYY-MM-DD')
const defaultDates = [oneYearAgo, now]

const serializeSearchParams = (searchParams: URLSearchParams) => [
    searchParams.get('from') || defaultDates[0],
    searchParams.get('to') || defaultDates[1],
]

export const Cashback = () => {
    const { companyId: companyIdFromParams } = useParams()
    const { data: company } = useGetCompany(companyIdFromParams)
    const [searchParams] = useSearchParams()
    const { cashback, error: cashbackError } = useCompanyCashback(
        serializeSearchParams(searchParams)
    )

    return (
        <PageContentLayout>
            <PageHeader
                title="Cashback"
                breadcrumb={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success'}>Customer Success</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success/cashback'}>Cashback</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {company?.name || (
                                <Skeleton
                                    paragraph={false}
                                    active
                                    title={{ width: '10em' }}
                                    style={{ marginTop: '4px' }}
                                />
                            )}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            <CompanyAndDateSelector defaultDates={defaultDates} />
            <CashbackTable companyName={company?.name} cashback={cashback} error={cashbackError} />
        </PageContentLayout>
    )
}
