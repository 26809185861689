import { combineReducers } from 'redux'
import { all } from 'redux-saga/effects'

import { supportCompanySaga } from './support/company/sagas'
import { employeesSaga } from './employees/sagas'
import { employeesReducer } from './employees/slice'
import { supportUserSaga } from './support/user/sagas'
import { supportReducer } from './support/slice'
import { suppliersSaga } from './suppliers/sagas'
import { suppliersReducer } from './suppliers/slice'

export const rootReducer = combineReducers({
    support: supportReducer,
    employees: employeesReducer,
    suppliers: suppliersReducer,
})

export function* rootSaga() {
    yield all([supportCompanySaga(), employeesSaga(), supportUserSaga(), suppliersSaga()])
}
