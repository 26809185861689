import { FormItem, Input } from 'formik-antd'
import type { FC } from 'react'

type AdvancedSearchInputProps = {
    label: string
    name: string
    onChange: (e: any, fieldName: string) => void
    widthOverride?: number
}

const AdvancedSearchInput: FC<AdvancedSearchInputProps> = ({
    label,
    name,
    onChange,
    widthOverride,
}: AdvancedSearchInputProps) => {
    return (
        <FormItem
            label={label}
            name={name}
            style={{ width: widthOverride ?? 200, margin: '0 10px' }}
        >
            <Input
                name={name}
                placeholder={label}
                allowClear
                onChange={(e) => {
                    onChange(e.target.value, name)
                }}
            />
        </FormItem>
    )
}

export default AdvancedSearchInput
