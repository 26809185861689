import {
    KycCheckpoint,
    KycStatus,
    Entity,
    CreatePersonRequest,
    StyxCompany,
    DataSource,
    ItemWithDataSource,
} from 'types/styx'
import dayjs from 'packages/dayjs'

export const sortKycChecksDescending = (checks: KycCheckpoint[]) =>
    checks.sort((a, b) => dayjs(b.performed).valueOf() - dayjs(a.performed).valueOf())

/**
 * Why `shouldShowManualSignupFlag`?
 *
 * When a company is manually created, their velocity is set to UNSET and a note is added to the initial KYC check indicating this.
 * Usually, companies are manually created when a new customer cannot find their company in the registry search during onboarding.
 * This is often due to a typo or some other user error. This is a problem for compliance since we do not
 * automatically pull in the company structure here, meaning they need to create it by hand.
 *
 * When a manual signup is "automatically fixed" this means that compliance has correctly identified the company in the registry,
 * updated the company with the correct registryId (see pages/compliance/company/registry-id-search/registry-id-search.tsx), and has
 * triggered a company structure refresh. This creates a new UNSET kycCheckpoint, at which point we want to hide the Manual Signup flag,
 * since compliance rely on it as an indicator as to whether or not they need to action something.
 *
 * In some cases, we cannot fix manual sign-ups in this way. It's important then to continue to display the Manual signup flag as a future
 * indicator to the team in charge of annually reviewing the company, since they will not be able to refresh the company structure here.
 */
export const shouldShowManualSignupFlag = (checks: KycCheckpoint[] = []) => {
    const manuallyCreatedCompany =
        sortKycChecksDescending(checks).reverse()[0]?.notes === 'Manual company creation.'

    const automaticallyFixedManualSignup =
        checks.filter((check) => check.level === KycStatus.UNSET).length > 1

    return manuallyCreatedCompany && !automaticallyFixedManualSignup
}

export const removeCommonEntities = (subject: Entity[], toFilter: Entity[]) =>
    subject.filter(
        (entity: Entity) => !toFilter.some((unassigned: Entity) => unassigned.id === entity.id)
    )

export const parseUnknownPersonName = (name?: string): CreatePersonRequest['name'] => {
    const nameParts = name?.trim().split(' ') ?? []

    const parsedName = nameParts.reduce<CreatePersonRequest['name']>(
        (nameObj, namePart, index) => {
            const isGivenName = index === 0
            const isFamilyName = index === nameParts.length - 1

            if (isGivenName) {
                nameObj.givenName = namePart
            } else if (isFamilyName) {
                nameObj.familyName = namePart
            } else if (!nameObj.middleName) {
                nameObj.middleName = namePart
            } else {
                nameObj.middleName = nameObj.middleName.concat(` ${namePart}`)
            }

            return nameObj
        },
        { givenName: null, middleName: null, familyName: null }
    )

    return parsedName
}

export function getCompanyVelocity(company?: StyxCompany) {
    if (company?.hedd1) return KycStatus.HEDD1
    if (company?.hedd) return KycStatus.HEDD
    if (company?.edd) return KycStatus.EDD
    if (company?.fdd) return KycStatus.FDD
    if (company?.sdd) return KycStatus.SDD
    return KycStatus.UNSET
}

export const isUserInput = (item: ItemWithDataSource) => item.source === DataSource.USER_INPUT
export const isDueDiligenceAgentInput = (item: ItemWithDataSource) =>
    item.source === DataSource.DUE_DILIGENCE_AGENT_INPUT
