import { Button, Typography, type TableColumnsType } from 'antd'
import { useNavigate } from 'react-router-dom'
import type { App, PersistedApp } from '../models'
import {
    APP_AUTHENTICATION_METHODS,
    APP_CATEGORY_OPTIONS,
    APP_STATUS_OPTIONS,
} from '../models/app-form-options'

import dayjs from 'dayjs'
import { AppAuthMethod, AppStatus } from '../components/app-list-table-cells'
import { DEFAULT_TABLE_ROW_COUNT } from '../constants'

export function useAppListTableConfig(apps: PersistedApp[]) {
    const navigate = useNavigate()

    const needsPagination = apps.length > DEFAULT_TABLE_ROW_COUNT

    const columns: TableColumnsType<PersistedApp> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: PersistedApp, b: PersistedApp) => a.name.localeCompare(b.name),
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (method: App['category']) =>
                APP_CATEGORY_OPTIONS.find(({ value }) => value === method)?.label,
            filters: APP_CATEGORY_OPTIONS.map(({ label, value }) => ({
                text: label,
                value,
            })),
            onFilter: (value, record) => record.category === value,
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
            render: (slug: string) => <Typography.Text code>{slug}</Typography.Text>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: App['status']) => <AppStatus status={status} />,
            filters: APP_STATUS_OPTIONS.map(({ label, value }) => ({ text: label, value: value! })),
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Authentication method',
            dataIndex: 'authenticationMethod',
            key: 'authenticationMethod',
            render: (method: App['authenticationMethod']) => <AppAuthMethod method={method} />,
            filters: APP_AUTHENTICATION_METHODS.map(({ label, value }) => ({
                text: label,
                value,
            })),
            onFilter: (value, record) => record.authenticationMethod === value,
        },
        {
            title: 'Last updated',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt: string) => {
                const daysAgo = dayjs().diff(dayjs(updatedAt), 'day')

                return `${daysAgo} days ago`
            },
            sorter: (a, b) => dayjs(a.updatedAt).valueOf() - dayjs(b.updatedAt).valueOf(),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (app: PersistedApp) => {
                return (
                    <Button type="link" onClick={() => navigate(`apps/${app.slug}`)}>
                        Edit
                    </Button>
                )
            },
        },
    ]

    return { columns, needsPagination }
}
