import useSWR from 'swr'
import qs from 'qs'
import type { RequestedCompanyDocumentResponse, ErrorResponse } from '@pleo-io/deimos'

import type {
    StyxCompanyUpdateRequest as UpdateRequest,
    QueriedPerson,
    StyxCompany,
    Document,
    RiskAssessmentOwner,
    RiskCheckRequest,
    DirectDebitAgreements,
    ComplianceNote,
    ComplianceDocument,
    SubmitRiskAssessmentRequestV2,
    UpdateRiskCheck,
    DocumentCategory,
} from 'types/styx'
import { cleanQueryParams } from 'utils/query-params'
import { DocumentRequest, KycStatus } from 'types/styx'
import { useUser } from 'providers/user-context'

import request, { fetcher } from '../request'
import { useParams } from 'react-router-dom'
import {
    sortKycChecksDescending,
    shouldShowManualSignupFlag,
    getCompanyVelocity,
} from 'services/styx/utils'
import { useClosedCompanySnapshot } from '../companies'
import type { AmlAnswersResponse } from 'bff/moons/generated/styx-v1'
import { notification } from 'antd'
import { styxRequest } from 'services/request'
import type { OnboardingSource } from 'types/deimos-company'
import {
    createCompanyRiskAssessmentV2,
    resetCompanyRiskAssessment,
    submitB4BCompanyRiskAssessmentV2,
    submitCompanyRiskAssessmentV2,
} from 'services/styx/risk-assessment'
import { updateRiskAssessmentCheck } from 'services/styx/company'

export function updateDeimosCompanyFromStyx(deimosId: string) {
    return request().put(`rest/v1/companies/${deimosId}`)
}

function updateStyxCompany(styxId: string, body: UpdateRequest) {
    return request().patch(`rest/v1/styx/companies/${styxId}`, {
        json: body,
    })
}

const updateCompanyVelocity = (companyId: string, velocity: KycStatus) =>
    request().put(`rest/v1/admin/companies/${companyId}/velocity`, {
        json: { velocity },
    })

const refreshCompanyStructure = (companyId: string) =>
    request().post(`rest/v2/styx-reviews/companies/${companyId}/structure`)

function createCompanyDocumentFile(companyId: string, documentId: string, file: FormData) {
    return request().post(`rest/v1/companies/${companyId}/styx/documents/${documentId}/files`, {
        body: file,
    })
}

function deleteCompanyDocument(companyId: string, documentId: string) {
    return request().delete(`rest/v1/companies/${companyId}/styx/documents/${documentId}`)
}

const requestCompanyDocument = (
    companyId: string,
    category: DocumentCategory
): Promise<RequestedCompanyDocumentResponse> =>
    request()
        .post(`rest/v1/companies/${companyId}/documents/requests`, { json: { category } })
        .then((res) => res.json())

function deleteCompanyDocumentFile(companyId: string, documentId: string, fileId: string) {
    return request().delete(
        `rest/v1/companies/${companyId}/styx/documents/${documentId}/files/${fileId}`
    )
}

function updateCompanyDocument(companyId: string, documentId: string, body: DocumentRequest) {
    return request().patch(`rest/v1/companies/${companyId}/styx/documents/${documentId}`, {
        json: { ...body, isHidden: body.hidden },
    })
}

function createCompanyDocument(companyId: string, body: DocumentRequest, autoRetrieve = false) {
    return request().post(`rest/v1/companies/${companyId}/styx/documents`, {
        json: { ...body, isHidden: body.hidden },
        searchParams: { autoRetrieve: autoRetrieve.toString() },
    })
}

const createCompanyRiskAssessment = (companyId: string, body: RiskAssessmentOwner) =>
    request().post(`rest/v1/companies/${companyId}/styx/risk`, { json: body })

const createRiskAssessmentCheck = (companyId: string, body: RiskCheckRequest) =>
    request().post(`rest/v1/companies/${companyId}/styx/risk/checks`, { json: body })

export function useStyxCompany(snapshot?: string) {
    let snapshotQuery: string

    if (snapshot) {
        snapshotQuery = `${qs.stringify({ snapshot })}`
    } else {
        snapshotQuery = ''
    }

    const user = useUser()
    const { id: deimosCompanyId } = useParams()
    const baseUrl = `rest/v1/companies/${deimosCompanyId}/styx?${snapshotQuery}`

    const response = useSWR<StyxCompany, ErrorResponse>(deimosCompanyId ? baseUrl : null, fetcher, {
        shouldRetryOnError: false,
    })

    const company = response.data

    const editCompany = async (body: UpdateRequest) => {
        if (!company) {
            return
        }

        response.mutate({ ...company, ...body })

        await updateStyxCompany(company.id, body)

        response.mutate()

        try {
            // This is a long-running call, so we handle it separately from the update
            await updateDeimosCompanyFromStyx(company.deimosId)
        } catch {
            throw new Error('Unable to sync Deimos with Styx - please sync manually')
        }
    }

    const updateVelocity = async (velocity: KycStatus) => {
        if (!company) {
            return
        }

        const currentRiskResult = company.riskAssessment?.result

        // * This is a long api call so we fail fast and throw our own errors
        if (currentRiskResult === 'FAIL') {
            throw new Error('Company has failed last risk assessment')
        }

        if (!currentRiskResult && velocity !== KycStatus.SDD) {
            throw new Error('Company must first pass risk assessment.')
        }

        await updateCompanyVelocity(company.deimosId, velocity)
        response.mutate()
    }

    const refreshStructure = async () => {
        await refreshCompanyStructure(deimosCompanyId)
        await updateDeimosCompanyFromStyx(deimosCompanyId)
        response.mutate()
    }

    const resetRiskAssessment = async (note?: string) => {
        await resetCompanyRiskAssessment(deimosCompanyId, note)
        await response.mutate()
    }

    const createDocumentFile = async (documentId: string, file: FormData) => {
        await createCompanyDocumentFile(deimosCompanyId, documentId, file)
        response.mutate()
    }

    const requestDocument = async (
        category: DocumentCategory
    ): Promise<RequestedCompanyDocumentResponse | undefined> => {
        if (!company) {
            return
        }

        const res = await requestCompanyDocument(company.deimosId, category)
        await response.mutate()

        return res
    }

    const deleteDocument = async (documentId: string) => {
        await deleteCompanyDocument(deimosCompanyId, documentId)
        response.mutate()
    }

    const deleteDocumentFile = async (documentId: string, fileId: string) => {
        await deleteCompanyDocumentFile(deimosCompanyId, documentId, fileId)
        response.mutate()
    }

    const editDocument = async (documentId: string, body: DocumentRequest) => {
        await updateCompanyDocument(deimosCompanyId, documentId, body)
        response.mutate()
    }

    const createDocument = async (body: DocumentRequest, autoRetrieve = false) => {
        await createCompanyDocument(deimosCompanyId, body, autoRetrieve)
        response.mutate()
    }

    const createRiskAssessment = async () => {
        if (!company || !user.email || !user.id) {
            return
        }

        await createCompanyRiskAssessment(company.deimosId, {
            ownerId: user.id,
            ownerName: user.email,
        })

        response.mutate()
    }

    const createRiskAssessmentV2 = async () => {
        if (!company) {
            return
        }

        await createCompanyRiskAssessmentV2(company.deimosId)

        await response.mutate()
    }

    const createRiskCheck = async (body: Pick<RiskCheckRequest, 'notes' | 'score' | 'type'>) => {
        if (!company || !user.email || !user.id) {
            return
        }

        await createRiskAssessmentCheck(company.deimosId, {
            ...body,
            ownerId: user.id,
            ownerName: user.email,
        })

        response.mutate()
    }

    const updateRiskCheck = async (checkId: string, body: UpdateRiskCheck) => {
        if (!company) {
            return
        }

        await updateRiskAssessmentCheck(company.deimosId, checkId, body)

        response.mutate()
    }

    const submitRiskAssessmentV2 = async (body: SubmitRiskAssessmentRequestV2) => {
        if (!company?.riskAssessment || !user.email || !user.id) {
            return
        }

        response.mutate({
            ...company,
            // Update so that we show a change in owner immediately
            riskAssessment: { ...company.riskAssessment, ownerName: user.email },
        })

        await submitCompanyRiskAssessmentV2(company.deimosId, body)

        response.mutate()
    }

    const submitB4BRiskAssessmentV2 = async (body: SubmitRiskAssessmentRequestV2) => {
        if (!company?.riskAssessment || !user.email || !user.id) {
            return
        }

        response.mutate({
            ...company,
            // Update so that we show a change in owner immediately
            riskAssessment: { ...company.riskAssessment, ownerName: user.email },
        })
        await submitB4BCompanyRiskAssessmentV2(company.deimosId, body)

        response.mutate()
    }

    return {
        company,
        error: response.error,
        isValidating: response.isValidating,
        mutations: {
            editCompany,
            updateVelocity,
            refreshStructure,
            createDocumentFile,
            deleteDocument,
            deleteDocumentFile,
            editDocument,
            createDocument,
            requestDocument,
            createRiskAssessment,
            createRiskAssessmentV2,
            createRiskCheck,
            updateRiskCheck,
            submitRiskAssessmentV2,
            submitB4BRiskAssessmentV2,
            revalidate: response.mutate,
            resetRiskAssessment,
        },
        kycStatus: getCompanyVelocity(company),
        isManualSignup: shouldShowManualSignupFlag(company?.kycCheckpoints),
    }
}

export function useCompanyDocuments() {
    const {
        company,
        isValidating,
        mutations: {
            createDocumentFile,
            deleteDocument,
            deleteDocumentFile,
            editDocument,
            createDocument,
            requestDocument,
        },
    } = useStyxCompany()

    return {
        documents: company?.documents ?? [],
        isValidating,
        mutations: {
            createDocumentFile,
            deleteDocument,
            deleteDocumentFile,
            editDocument,
            createDocument,
            requestDocument,
        },
    }
}

export function useKycCheckpoints() {
    const { company } = useStyxCompany()
    const checkpoints = company?.kycCheckpoints ?? []
    return sortKycChecksDescending(checkpoints)
}

export function useRiskAssessment(snapshot?: string) {
    const {
        company,
        mutations: {
            createRiskAssessment,
            createRiskAssessmentV2,
            createRiskCheck,
            updateRiskCheck,
            submitRiskAssessmentV2,
            resetRiskAssessment,
        },
    } = useStyxCompany(snapshot)

    return {
        riskAssessment: company?.riskAssessment,
        mutations: {
            createRiskAssessment,
            createRiskAssessmentV2,
            createRiskCheck,
            updateRiskCheck,
            submitRiskAssessmentV2,
            resetRiskAssessment,
        },
    }
}

export function useAmlAnswers(companyId: string) {
    const response = useSWR<AmlAnswersResponse>(
        `rest/v2/aml-answers/companies/${companyId}`,
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        }
    )

    const putFrequentlyUsedInCountries = async (frequentlyUsedInCountries: string[]) => {
        try {
            await styxRequest().put(`rest/v2/aml-answers/companies/${companyId}`, {
                json: {
                    continents: response.data?.continents,
                    categories: response.data?.categories,
                    amountRange: response.data?.amountRange,
                    frequentlyUsedInCountries,
                },
            })
            response.mutate()
        } catch (e) {
            notification.error({ message: 'Failed to update countries frequently used in' })
        }
    }

    return {
        amlAnswers: response?.data,
        isLoading: !response?.data && !response?.error,
        putFrequentlyUsedInCountries,
        ...response,
    }
}

export function getLinkToCompanyDocumentFile(
    companyId: string,
    documentId: string,
    fileId: string,
    snapshot?: string,
    isClosedCompany?: boolean,
    download = false
) {
    const deletedApi = isClosedCompany ? 'deleted/' : ''
    const url = `rest/v1/companies/${companyId}/${deletedApi}styx/documents/${documentId}/files/${fileId}/link`
    return request().get(url, {
        searchParams: snapshot
            ? { snapshot, download: String(download) }
            : { download: String(download) },
    })
}

export function getCompanyDocuments(companyId: string, snapshot?: string) {
    return request().get(`rest/v1/companies/${companyId}/styx/documents`, {
        searchParams: snapshot ? { snapshot } : {},
    })
}

export const useCompanyDocumentsSnapshot = (companyId?: string, snapshot?: string) => {
    const snapshotQuery = qs.stringify({ snapshot })

    return useSWR<Document[]>(
        companyId && snapshot
            ? `rest/v1/companies/${companyId}/styx/documents?${snapshotQuery}`
            : null,
        fetcher,
        { shouldRetryOnError: false, revalidateOnFocus: false }
    )
}

export const getCompanyZipFileUrl = (deimosCompanyId: string, snapshot?: string | null) =>
    request().get(
        `rest/v1/styx/reviews/companies/${deimosCompanyId}/documents${
            snapshot ? '?snapshot=' + snapshot : ''
        }`
    )

export interface PersonQuery {
    firstName?: string
    middleName?: string
    lastName?: string
    registryId?: string
}

export const useFindPerson = (query: PersonQuery) => {
    const cleanQuery = qs.stringify(cleanQueryParams(query))
    const result = useSWR<QueriedPerson[], Error>(
        cleanQuery ? `rest/v1/styx/persons?${cleanQuery}` : null,
        fetcher
    )

    return result
}

export const deactivateCompany = (companyId: string) =>
    request().delete(`rest/v1/companies/${companyId}`)

//
// Closed company hooks and functions. I'll extract these out to a separate file during clean-up
//

export function useStyxClosedCompany(snapshot?: string) {
    const { id: deimosCompanyId } = useParams()
    const closedCompanySnapshot = useClosedCompanySnapshot(deimosCompanyId)
    const snapshotQuery = snapshot ? `snapshot=${snapshot}` : `snapshot=${closedCompanySnapshot}`
    const url =
        snapshot || closedCompanySnapshot
            ? `rest/v1/companies/${deimosCompanyId}/deleted/styx?${snapshotQuery}`
            : null

    const {
        data: company,
        error,
        mutate,
        isValidating,
    } = useSWR<StyxCompany, ErrorResponse>(deimosCompanyId ? url : null, fetcher, {
        shouldRetryOnError: false,
    })

    return {
        company,
        error,
        mutations: {
            revalidate: mutate,
        },
        closedCompanySnapshot,
        riskAssessment: company?.riskAssessment,
        isValidating,
        documents: company?.documents ?? [],
        kycStatus: getCompanyVelocity(company),
        isManualSignup: shouldShowManualSignupFlag(company?.kycCheckpoints),
    }
}

export function useClosedCompanyKycCheckpoints() {
    const { company } = useStyxClosedCompany()
    const checkpoints = company?.kycCheckpoints ?? []
    return sortKycChecksDescending(checkpoints)
}

export function useAgreements(companyId: string) {
    const url = `wallet-management/rest/v3/companies/${companyId}/agreements`
    return useSWR<DirectDebitAgreements, ErrorResponse>(companyId ? url : null, fetcher)
}

const createComplianceNote = (companyId: string, text: string) =>
    request().post(`rest/v1/admin/aml/companies/${companyId}/notes`, {
        json: { note: text },
    })

const updateComplianceNote = (companyId: string, noteId: string, text: string) =>
    request().patch(`rest/v1/admin/aml/companies/${companyId}/notes/${noteId}`, {
        json: { note: text },
    })

export const useComplianceNotes = (companyId?: string) => {
    const response = useSWR<ComplianceNote[]>(
        companyId ? `rest/v1/admin/aml/companies/${companyId}/notes` : null,
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        }
    )

    const createNote = async (note: string) => {
        if (!companyId) {
            return
        }
        await createComplianceNote(companyId, note)
        response.mutate()
    }

    const updateNote = async (noteId: string, note: string) => {
        if (!companyId) {
            return
        }
        await updateComplianceNote(companyId, noteId, note)
        response.mutate()
    }

    return { ...response, mutations: { createNote, updateNote } }
}

const createComplianceDocument = (companyId: string, title: string, description: string) =>
    request().post(`rest/v1/admin/aml/companies/${companyId}/documents`, {
        json: { title, description },
    })

const createComplianceDocumentFile = (companyId: string, documentId: string, file: FormData) =>
    request().post(`rest/v1/admin/aml/companies/${companyId}/documents/${documentId}/files`, {
        body: file,
    })

export const useCompanyComplianceDocuments = (companyId?: string) => {
    const response = useSWR<ComplianceDocument[]>(
        companyId ? `rest/v1/admin/aml/companies/${companyId}/documents` : null,
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        }
    )

    const createDocument = async (title: string, description: string) => {
        if (!companyId) {
            return
        }
        await createComplianceDocument(companyId, title, description)
        response.mutate()
    }

    const createDocumentFile = async (documentId: string, file: FormData) => {
        if (!companyId) {
            return
        }
        await createComplianceDocumentFile(companyId, documentId, file)
        response.mutate()
    }

    return { ...response, mutations: { createDocument, createDocumentFile } }
}

export const getLinkToComplianceDocumentFile = (
    companyId: string,
    documentId: string,
    fileId: string,
    download = false
) => {
    const url = `rest/v1/admin/aml/companies/${companyId}/documents/${documentId}/files/${fileId}/link`
    return request().get(url, {
        searchParams: { download: String(download) },
    })
}

export const useCompanyOnboardedVia = (companyId?: string) => {
    const url = `rest/v1/companies/${companyId}/styx`
    const response = useSWR<StyxCompany, ErrorResponse>(companyId ? url : null, fetcher, {
        shouldRetryOnError: false,
    })

    const company = response.data
    const onboardedVia = company?.onboardedVia as OnboardingSource

    return {
        onboardedVia,
        error: response.error,
        isValidating: response.isValidating,
    }
}
