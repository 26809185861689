import type { WalletUnloadResponse, WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'
import { useWalletUnloads } from 'services/deimos/wallet'
import { useGetCompaniesByIds } from 'services/deimos/companies'
import type { Company, OnboardingSource } from 'types/deimos-company'

import { getCountryForWalletUnload } from './utils'

type WalletUnloadWithInfoToFilter = WalletUnloadResponse & {
    countryCode: string
    onboardedVia: OnboardingSource
}

export const useWalletUnloadsFiltered = (
    status: WalletUnloadResponseStatus,
    filters: {
        countryFilter: string[]
        onboardingViaFilter: OnboardingSource[]
    }
) => {
    const { data: walletUnloads, error, mutations } = useWalletUnloads(status)
    const walletUnloadCompanyIds = walletUnloads?.map((unload) => unload.companyId)
    const { data: companies } = useGetCompaniesByIds(walletUnloadCompanyIds)
    const companyMap = companies.reduce(
        (map, company) => {
            map[company.id] = company
            return map
        },
        {} as Record<string, Company>
    )

    const unloadsWithInfoToFilter = walletUnloads?.map((unload) => {
        const companyDetails = companyMap[unload.companyId]

        return {
            ...unload,
            countryCode: getCountryForWalletUnload(unload, companyDetails),
            onboardedVia: companyDetails?.onboardedVia,
        }
    })

    const filteredUnloads =
        unloadsWithInfoToFilter?.filter(
            (unload: WalletUnloadWithInfoToFilter) =>
                (!filters.countryFilter.length ||
                    filters.countryFilter.includes(unload.countryCode)) &&
                (!filters.onboardingViaFilter.length ||
                    filters.onboardingViaFilter.includes(unload.onboardedVia))
        ) ?? []

    return { data: filteredUnloads, error, mutations }
}
