import type { FC } from 'react'
import { Table } from 'antd'
import type { FullInvoice } from 'bff/moons/generated/cupid-v1'

const columns = [
    {
        title: 'Reference',
        dataIndex: 'reference',
        key: 'reference',
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
    },
    {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Received Amount',
        dataIndex: 'receivedAmount',
        key: 'receivedAmount',
    },
    {
        title: 'Payment System',
        dataIndex: 'paymentSystem',
        key: 'paymentSystem',
    },
    {
        title: 'Projected Loaded At',
        dataIndex: 'projectedLoadedAt',
        key: 'projectedLoadedAt',
    },
    {
        title: 'Callback ID',
        dataIndex: 'callbackId',
        key: 'callbackId',
    },
    {
        title: 'Sender Bank',
        dataIndex: 'senderBank',
        key: 'senderBank',
    },
    {
        title: 'Sender Information',
        dataIndex: 'senderInformation',
        key: 'senderInformation',
    },
    {
        title: 'Initiated By',
        dataIndex: 'initiatedBy',
        key: 'initiatedBy',
    },
]

interface Props {
    data: FullInvoice[]
}

const Invoices: FC<Props> = ({ data }) => (
    <Table dataSource={data} columns={columns} pagination={false} size="small" bordered />
)

export default Invoices
