import React, { FC, useState } from 'react'
import { Button, Table, Tag, Typography, Modal, message } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import dayjs from 'packages/dayjs'

import { ProofOfFundsRefundStatus, Status, Transaction } from 'types/proof-of-funds'

import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { ActionsContainer } from 'components/layout-containers'
import { PAGE_LIMIT, ProofOfFundsResponse, RejectRefund } from 'services/deimos/proof-of-funds'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import RejectRefunds from '../reject-refunds/reject-refunds'
import TransactionDescription from '../transaction-description/transaction-description'

const { Text } = Typography
interface Props {
    fetching: boolean
    dataSource: Transaction[]
    page: number
    total: number
    setPage: (newPage: number) => void
    onUpdateTransaction: (
        transaction: Transaction,
        status: Status
    ) => Promise<ProofOfFundsResponse | undefined>
    onShowHistory: (companyId: string) => void
    onRejectRefund: (pofId: string, refundDetails: RejectRefund) => Promise<Response>
}

const TransactionTable: FC<React.PropsWithChildren<Props>> = ({
    fetching,
    dataSource,
    page,
    total,
    setPage,
    onUpdateTransaction,
    onShowHistory,
    onRejectRefund,
}) => {
    const hasPofRefunds = useHasPermissions(['proof-of-funds-refund'])
    const isPending = (record: Transaction) => record.status === Status.PENDING
    const isNotReceived = (record: Transaction) =>
        record.refundStatus !== ProofOfFundsRefundStatus.RECEIVED
    const isNotFailed = (record: Transaction) => record.status !== Status.REJECTED_REFUND

    const [isRefundDetailsModalVisible, setIsRefundDetailsModalVisible] = useState(false)
    const [currentTransaction, setCurrentTransaction] = useState<Transaction>()

    const confirmUpdateTransaction = (transaction: Transaction, nextStatus: Status) => {
        Modal.confirm({
            title: `Are you sure you want to mark this source of funds as ${nextStatus}?`,
            width: 750,
            content: <TransactionDescription transaction={transaction} />,
            onOk: async () => {
                try {
                    await onUpdateTransaction(transaction, nextStatus)
                } catch (e) {
                    message.error(`Failed to mark as ${nextStatus}: "${(e as Error).message}"`)
                }
            },
        })
    }

    const onSubmitRefund = async (pofId: string, refundDetails: RejectRefund) => {
        try {
            await onRejectRefund(pofId, refundDetails)
            message.success(`Successfully rejected refund`)
            setIsRefundDetailsModalVisible(false)
        } catch (e) {
            message.error(`Failed to initiate the refund reject: "${(e as Error).message}"`)
        }
    }

    const columns: ColumnProps<Transaction>[] = [
        {
            title: 'Transaction ID',
            dataIndex: 'transactionId',
            render: (transactionId) => {
                return (
                    <Info>
                        <Text>{transactionId}</Text>
                    </Info>
                )
            },
        },
        {
            title: 'Account',
            dataIndex: 'senderInformation',
            render: (_, transaction) => {
                return (
                    <Info>
                        <Text>{transaction.senderBank}</Text>
                    </Info>
                )
            },
        },
        {
            title: 'Sender',
            dataIndex: 'senderInformation',
            render: (senderInformation) => {
                return (
                    <Info>
                        <Text>{senderInformation}</Text>
                    </Info>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'senderInformation',
            render: (_, transaction) => {
                return (
                    <Info>
                        {transaction.firstLoad && <Tag color="orange">First load</Tag>}
                        {transaction.newSource && <Tag color="volcano">New source</Tag>}
                        {transaction.refundStatus === ProofOfFundsRefundStatus.INITIATING && (
                            <Tag color="processing">Initiating refund</Tag>
                        )}
                        {transaction.refundStatus === ProofOfFundsRefundStatus.INITIATED && (
                            <Tag color="processing">Refund initiated</Tag>
                        )}
                        {transaction.refundStatus === ProofOfFundsRefundStatus.FAILED && (
                            <Tag color="error">Refund failed</Tag>
                        )}
                        {transaction.refundStatus === ProofOfFundsRefundStatus.RECEIVED && (
                            <Tag color="green">Refund received</Tag>
                        )}
                    </Info>
                )
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount, { currency }) => (
                <span>
                    {amount} {currency}
                </span>
            ),
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: 'Company',
            dataIndex: 'companyName',
            render: (companyName) => companyName ?? 'Unknown name',
        },
        {
            title: 'Risk',
            dataIndex: 'riskAssessment',
        },
        {
            title: 'Performed at',
            dataIndex: 'performedAt',
            render: (performedAt) => dayjs(performedAt).format('lll'),
            sorter: (a, b) => dayjs(a.performedAt).valueOf() - dayjs(b.performedAt).valueOf(),
        },
        {
            title: 'Action',
            fixed: 'right',
            render: (_, transaction) => (
                <ActionsContainer style={{ maxWidth: '10vw' }}>
                    {isNotFailed(transaction) && isNotReceived(transaction) && (
                        <Button
                            type="link"
                            onClick={() => confirmUpdateTransaction(transaction, Status.APPROVED)}
                        >
                            Approve
                        </Button>
                    )}
                    {isPending(transaction) && isNotReceived(transaction) && (
                        <Button
                            data-testid="investigation-button"
                            type="link"
                            onClick={() =>
                                confirmUpdateTransaction(transaction, Status.INVESTIGATING)
                            }
                        >
                            Investigate
                        </Button>
                    )}
                    <Button type="link" onClick={() => onShowHistory(transaction.companyId)}>
                        See previous transfers
                    </Button>
                    <Button
                        type="link"
                        danger
                        onClick={() => {
                            confirmUpdateTransaction(transaction, Status.REJECTED)
                        }}
                    >
                        Reject
                    </Button>
                    {isNotReceived(transaction) && hasPofRefunds && (
                        <Button
                            data-testid="refund-button"
                            type="link"
                            danger
                            onClick={() => {
                                setCurrentTransaction(transaction)
                                setIsRefundDetailsModalVisible(true)
                            }}
                        >
                            Refund
                        </Button>
                    )}
                </ActionsContainer>
            ),
        },
    ]

    return (
        <>
            <Table
                data-testid="table"
                dataSource={dataSource}
                columns={columns}
                loading={fetching}
                rowKey="id"
                pagination={{
                    current: page,
                    pageSize: PAGE_LIMIT,
                    total,
                    onChange: (newPage: number) => setPage(newPage),
                }}
                scroll={{ x: 'max-content' }}
            />
            <Modal
                title="Refund details"
                open={isRefundDetailsModalVisible}
                closable
                onCancel={() => setIsRefundDetailsModalVisible(false)}
                width={800}
                footer={null}
                destroyOnClose
            >
                <RejectRefunds onSubmit={onSubmitRefund} transaction={currentTransaction} />
            </Modal>
        </>
    )
}

const Info = styled.div`
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${spacing.space8};
`

export default TransactionTable
