import type { FC } from 'react'
import type { GetBillInvoiceResponse } from '@pleo-io/deimos'
import { Descriptions, Typography } from 'antd'
import { useGetCompany } from 'services/deimos/companies'

const { Text } = Typography
const { Item } = Descriptions

export const BillInvoiceOwnerInformation: FC<{
    employee?: GetBillInvoiceResponse['employee']
    companyId?: string
}> = ({ employee, companyId }) => {
    const { data: company } = useGetCompany(companyId)
    return (
        <Descriptions bordered column={{ xxl: 3, xl: 2, md: 1, sm: 1 }} size="small">
            <Item label="Employee ID">
                <Text copyable={Boolean(employee?.id)}>{employee?.id ?? '-'}</Text>
            </Item>
            <Item label="Employee email">
                <Text copyable={Boolean(employee?.email)}>{employee?.email ?? '-'}</Text>
            </Item>
            <Item label="Employee name">
                <Text copyable={Boolean(employee?.firstName ?? employee?.lastName)}>
                    {employee?.firstName} {employee?.lastName}
                </Text>
            </Item>
            <Item label="Company ID">
                <Text copyable={Boolean(companyId)}>{companyId ?? '-'}</Text>
            </Item>
            <Item label="Company Name">
                <Text copyable={Boolean(company?.name)}>{company?.name ?? '-'}</Text>
            </Item>
            <Item label="Company Country">
                <Text>{company?.address?.country ?? '-'}</Text>
            </Item>
        </Descriptions>
    )
}
