import { Button, Modal, Result } from 'antd'
import Spinner from 'components/content-spinner'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { selectCompany } from 'store/modules/support/company/selectors'
import { actions as companyActions } from 'store/modules/support/company/slice'
import { selectPageStatus } from 'store/modules/support/selectors'
import { actions } from 'store/modules/support/slice'
import { PageContentLayout } from 'components/layout-containers'
import type { AdminCompany } from 'types/deimos-company'
import type { CurrentStatus } from 'types/status'
import CompanyTabs from './company-tabs'

import OffboardingForm from 'components/company-offboarding/offboarding-form'
import ExpenseExportForm from 'components/expense-export/expense-export-form'

import { useCompanyOffboardingStatus } from 'services/deimos/companies'

import type { CompanyOffboardingStatus } from '@pleo-io/deimos'
import { bff } from './bff'
import type { SubscriptionListResponse } from 'bff/moons/generated/beyond'
import { CustomerSuccessPageHeader } from './page-header/page-header'

interface Props {
    billingSubscription: SubscriptionListResponse
    company: AdminCompany
    status: CurrentStatus
    companyOffboardingStatus: CompanyOffboardingStatus | undefined
    revalidateOffboardingStatus: () => void
}

export const Company: FC<React.PropsWithChildren<Props>> = ({
    billingSubscription,
    company,
    status,
    children,
    companyOffboardingStatus,
    revalidateOffboardingStatus,
}) => {
    const [offboardingModalActive, setOffboardingModalActive] = useState(false)
    const [exportExpenseModalVisible, setExportExpenseModalVisible] = useState(false)
    const offboardingText = companyOffboardingStatus ? 'Edit offboarding' : 'Initiate offboarding'
    const navigate = useNavigate()
    const goBack = () => navigate(-1)

    switch (status) {
        case 'fetching':
            return <Spinner />
        case 'error':
            return (
                <Result
                    status="500"
                    title="Something went wrong"
                    subTitle="We had trouble fetching this company"
                    extra={
                        <Button type="primary" onClick={goBack}>
                            Go back
                        </Button>
                    }
                />
            )
        case 'resolved':
        case 'default':
            return (
                <>
                    <CustomerSuccessPageHeader
                        status={status}
                        company={company}
                        billingSubscription={billingSubscription}
                        revalidateOffboardingStatus={revalidateOffboardingStatus}
                        setExportExpenseModalVisible={setExportExpenseModalVisible}
                        offboardingModalActive={offboardingModalActive}
                        setOffboardingModalActive={setOffboardingModalActive}
                        offboardingText={offboardingText}
                        companyOffboardingStatus={companyOffboardingStatus}
                        exportExpenseModalVisible={exportExpenseModalVisible}
                    />
                    <PageContentLayout>{children}</PageContentLayout>
                    <Modal
                        title={offboardingText}
                        open={offboardingModalActive}
                        onCancel={() => setOffboardingModalActive(false)}
                        footer={null}
                        width={'auto'}
                        centered
                    >
                        <OffboardingForm
                            offboardingStatus={companyOffboardingStatus}
                            companyId={company.id}
                            closeModal={() => setOffboardingModalActive(false)}
                            revalidateCompany={revalidateOffboardingStatus}
                        />
                    </Modal>
                    <Modal
                        title="Export Expenses"
                        open={exportExpenseModalVisible}
                        onCancel={() => setExportExpenseModalVisible(false)}
                        footer={null}
                        width={'auto'}
                        centered
                    >
                        <ExpenseExportForm
                            companyId={company.id}
                            closeModal={() => setExportExpenseModalVisible(false)}
                        />
                    </Modal>
                </>
            )
    }
}

const CompanyContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const pageStatus = useSelector(selectPageStatus)
    const company = useSelector(selectCompany)

    const { data: companyOffboardingStatus, mutate: revalidateCompanyOffboardingStatus } =
        useCompanyOffboardingStatus(company?.id)

    const { data: subscription } = bff.subscription.getCompanySubscription.useQuery({
        params: { companyId: company?.id },
    })

    useEffect(() => {
        dispatch(actions.resetStatus())
        dispatch(companyActions.fetchCompany(id))
        dispatch(companyActions.fetchFeatureFlags(id))
    }, [dispatch, id])

    return (
        <Company
            billingSubscription={subscription as SubscriptionListResponse}
            company={company}
            status={pageStatus}
            companyOffboardingStatus={companyOffboardingStatus}
            revalidateOffboardingStatus={revalidateCompanyOffboardingStatus}
        >
            <CompanyTabs companyId={id} />
        </Company>
    )
}

export default CompanyContainer
