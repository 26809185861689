import type { App } from './app'

export const APP_CATEGORY_OPTIONS: { label: string; value: App['category'] }[] = [
    { label: 'Accounting', value: 'ACCOUNTING_SYSTEM' },
    { label: 'People Management', value: 'USER_MANAGEMENT' },
    { label: 'Travel', value: 'TRAVEL' },
    { label: 'Project Management', value: 'PROJECT_MANAGEMENT' },
    { label: 'Expense Automation', value: 'EXPENSE_AUTOMATION' },
    { label: 'Productivity', value: 'PRODUCTIVITY' },
    { label: 'SSO Authentication', value: 'SSO_AUTHENTICATION' },
]

export const APP_TYPE_OPTIONS: { label: string; value: App['type'] }[] = [
    { label: 'Third Party', value: 'THIRD_PARTY' },
    { label: 'Direct', value: 'DIRECT' },
    { label: 'Manual', value: 'MANUAL' },
]

export const APP_AUTHENTICATION_METHODS: { label: string; value: App['authenticationMethod'] }[] = [
    { label: 'None', value: 'NONE' },
    { label: 'API Key', value: 'API_KEY' },
    { label: 'OAuth2', value: 'OAUTH2' },
]

export const APP_STATUS_OPTIONS: { label: string; value: App['status'] }[] = [
    { label: 'Published', value: 'PUBLISHED' },
    { label: 'Unpublished', value: 'UNPUBLISHED' },
    { label: 'Beta', value: 'BETA' },
    { label: 'Upcoming', value: 'UPCOMING' },
]
