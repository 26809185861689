import type { FC } from 'react'
import { Typography } from 'antd'

import type { DetailedAddress } from 'types/styx'

const { Text } = Typography

const PersonAddress: FC<React.PropsWithChildren<{ address: DetailedAddress; testId?: string }>> = ({
    address,
    testId,
}) => (
    <address data-testid={testId}>
        <Text type="secondary">Street:</Text> {address.street}
        <br />
        <Text type="secondary">Number:</Text> {address.houseNumber}
        <br />
        {address.floor && (
            <>
                <Text type="secondary">Floor:</Text> {address.floor}
                <br />
            </>
        )}
        <Text type="secondary">Locality:</Text> {address.locality}
        <br />
        {address.region && (
            <>
                <Text type="secondary">Region:</Text> {address.region}
                <br />
            </>
        )}
        <Text type="secondary">Postal code:</Text> {address.postalCode}
        <br />
        <Text type="secondary">Country:</Text> {address.country}
    </address>
)

export default PersonAddress
