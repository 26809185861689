import type { FC } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Input, Form } from 'formik-antd'
import { Button } from 'antd'

const validationSchema = Yup.object()
    .shape({
        legalName: Yup.string(),
        registryId: Yup.string(),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface Props {
    loading: boolean
    onSubmit: (values: FormValues) => void
}

const SearchForm: FC<React.PropsWithChildren<Props>> = ({ onSubmit, loading }) => {
    return (
        <Formik validationSchema={validationSchema} initialValues={{}} onSubmit={onSubmit}>
            {({ resetForm }) => (
                <Form layout="vertical">
                    <Column>
                        <Form.Item name="legalName">
                            <Input name="legalName" placeholder="Company name" />
                        </Form.Item>
                        <Form.Item name="registryId">
                            <Input name="registryId" placeholder="Registry ID" />
                        </Form.Item>
                        <Row>
                            <Button htmlType="submit" type="primary" loading={loading}>
                                Search
                            </Button>
                            <Button type="default" onClick={() => resetForm()}>
                                Reset
                            </Button>
                        </Row>
                    </Column>
                </Form>
            )}
        </Formik>
    )
}

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 30%;
`

const Row = styled.div`
    display: flex;
    gap: 0.5rem;
`

export default SearchForm
