import type { ReactElement } from 'react'
import { Container } from './footer.styles'
import { Button, Space } from 'antd'
import { useFormikContext } from 'formik'
import type { FormikStructure } from '../table/types'
import { FormAction } from '../table/enums'

type FooterProps = {
    onCancel: () => void
    onSubmit: (formValues: FormikStructure, action: FormAction, resetForm: () => void) => void
}

const Footer = ({ onCancel, onSubmit }: FooterProps): ReactElement => {
    const { resetForm, dirty, values } = useFormikContext<FormikStructure>()

    const handleCancel = () => {
        resetForm()
        onCancel()
    }

    return (
        <Container>
            <Button onClick={handleCancel}>Cancel</Button>
            <Space>
                <Button
                    onClick={() => onSubmit(values, FormAction.Schedule, resetForm)}
                    disabled={!dirty}
                >
                    Schedule changes
                </Button>
                <Button
                    type={'primary'}
                    disabled={!dirty}
                    onClick={() => onSubmit(values, FormAction.Submit, resetForm)}
                >
                    Save changes
                </Button>
            </Space>
        </Container>
    )
}

export default Footer
