import type { FC } from 'react'
import { Table, Typography } from 'antd'
import { indexBy, prop } from 'ramda'
import dayjs from 'packages/dayjs'
import { RiskScore } from '@pleo-io/deimos'
import RiskCheckTip from 'pages/compliance/company/risk-assessment/risk-check-tip/risk-check-tip'
import { deSnakify } from 'utils/strings'
import * as styx from 'types/styx'
import { useStyxClosedCompany } from 'services/deimos/styx-company/styx-company'
import { riskFactorNamesMap } from 'pages/compliance/company/risk-assessment/risk-check-form-v2/columns'

const { Text } = Typography

interface RiskCheckData {
    riskType: styx.RiskTypes
    score?: RiskScore | styx.ResultScore
    notes: string
    ownerName: string
    id: string
    hasExistingCheck: boolean
    checkDate: string
}
interface Props {
    currentRiskScore: RiskScore
    dataSource: RiskCheckData[]
}

export const RiskCheckForm: FC<React.PropsWithChildren<Props>> = ({
    dataSource,
    currentRiskScore,
}) => {
    return (
        <Table
            title={() => (
                <>
                    <Text>Current risk score: </Text>{' '}
                    <Text strong>{deSnakify(currentRiskScore)}</Text>
                </>
            )}
            dataSource={dataSource}
            pagination={false}
            rowKey="riskType"
            columns={[
                {
                    title: 'Risk type',
                    dataIndex: 'riskType',
                    render: (type) => (
                        <>
                            <Text strong>
                                {riskFactorNamesMap[type as keyof typeof riskFactorNamesMap] ||
                                    deSnakify(type)}
                            </Text>
                            <RiskCheckTip riskType={type} />
                        </>
                    ),
                },
                {
                    title: 'Level of risk',
                    render: (_, _record, recordIndex) => {
                        const { hasExistingCheck, score } = dataSource[recordIndex]
                        return <Text>{hasExistingCheck ? score : 'no scrore'}</Text>
                    },
                },
                {
                    title: 'Notes',
                    dataIndex: 'notes',
                    render: (_, _record, recordIndex) => {
                        const { hasExistingCheck, notes } = dataSource[recordIndex]
                        return <Text>{hasExistingCheck ? notes : 'no notes'}</Text>
                    },
                },
                {
                    title: 'Owner',
                    dataIndex: 'ownerName',
                },
                {
                    title: 'Check date',
                    dataIndex: 'checkDate',
                },
            ]}
        />
    )
}

const RiskAssessmentFormContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const { riskAssessment } = useStyxClosedCompany()

    const currentRiskScore = riskAssessment?.score ?? RiskScore.NOT_ASSIGNED
    const riskTypes = Object.values(styx.RiskTypes)
    const riskCheckByType = indexBy(prop('riskType'), riskAssessment?.riskChecks ?? [])
    const dataSource = riskTypes.map((type) => ({
        riskType: type as styx.RiskTypes,
        score: riskCheckByType[type]?.score ?? undefined,
        notes: riskCheckByType[type]?.notes ?? '',
        ownerName: riskCheckByType[type]?.ownerName ?? '',
        hasExistingCheck: !!riskCheckByType[type],
        id: riskCheckByType[type]?.id ?? '',
        checkDate: dayjs(riskCheckByType[type]?.checkDate ?? undefined).format('lll'),
    }))

    return (
        <RiskCheckForm
            key={riskAssessment?.assessmentDate}
            dataSource={dataSource}
            currentRiskScore={currentRiskScore}
        />
    )
}

export default RiskAssessmentFormContainer
