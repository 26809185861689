import { useState } from 'react'
import type {
    CardOrder,
    CardOrderStatus,
    CustomOrders,
    GetCardOrders,
    GhostWalletOrderBody,
    OrderFilters,
    OrderReadyForUpdate,
} from 'store/modules/support/card-orders/types'
import useSWR from 'swr'
import deimosRequest, { fetcher } from './request'

export const getOrders = (
    body: OrderFilters,
    query: { limit: number; page: number }
): Promise<Response> => {
    return deimosRequest().post('rest/v1/cardOrders/', {
        json: body,
        searchParams: query,
    })
}

export const flagOrderForProcessing = (cardOrderId: string): Promise<Response> => {
    return deimosRequest().post(`rest/v1/cardOrders/${cardOrderId}/processing`)
}

export const useCardOrder = (cardOrderId?: string) => {
    const result = useSWR<CardOrder>(
        cardOrderId ? `rest/v1/cardOrders/${cardOrderId}` : '',
        fetcher
    )

    const sendToProcessing = async () => {
        if (!cardOrderId) return
        await flagOrderForProcessing(cardOrderId)
    }

    return { ...result, mutations: { sendToProcessing } }
}

export const updateOrderStatus = (
    cardOrderId: string,
    status: CardOrderStatus
): Promise<Response> => {
    return deimosRequest().put(`rest/v1/cardOrders/${cardOrderId}`, {
        json: { status },
    })
}

export const createBatchOrder = (
    companyId: string,
    order: OrderReadyForUpdate
): Promise<Response> => {
    return deimosRequest().post(`rest/v1/companies/${companyId}/cardOrders/batch`, {
        json: order,
    })
}

export const useOrders = (body: OrderFilters) => {
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    const result = useSWR<GetCardOrders>(
        `rest/v1/cardOrders/ - filters=${JSON.stringify({ body, limit, page })}`,
        () => getOrders(body, { limit, page }).then((res) => res.json())
    )

    const updateCardOrderStatus = async (cardOrderId: string, status: CardOrderStatus) => {
        await updateOrderStatus(cardOrderId, status).then((res) => res.json())
        result.mutate()
    }

    const createOrders = async (customOrders: CustomOrders) => {
        const timestamp = new Date().toISOString()

        for (const order of customOrders.orders) {
            await createBatchOrder(customOrders.companyId, {
                ...order,
                batch: timestamp,
            })
        }
    }

    return { ...result, mutations: { updateCardOrderStatus, createOrders, setLimit, setPage } }
}

export const createGhostWalletOrder = (body: GhostWalletOrderBody): Promise<Response> => {
    return deimosRequest().post('rest/v1/cardOrders/ghostWallet', {
        json: body,
    })
}

/**
 * Upload a spreadsheet of tracking codes associated with existing
 * card orders
 *
 * @param data FormData containing a 'file' element
 */
export const uploadCardOrderTrackingCodeFile = (data: FormData) =>
    deimosRequest().post(`rest/v1/admin/card-orders/upload-tracking-codes`, { body: data })
