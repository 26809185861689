import type { FC } from 'react'
import { Form, Select, SubmitButton, Input } from 'formik-antd'
import * as yup from 'yup'
import {
    selectCompanyCountry,
    selectCompanyCurrency,
    selectCompanyId,
} from 'store/modules/support/company/selectors'
import { useSelector } from 'react-redux'
import { Formik, FormikHelpers, useFormikContext } from 'formik'
import { Modal, notification, Space, Typography } from 'antd'
import { updateOverdraftEligibility, useOverdraftEligibility } from 'services/deimos/overdraft'
import { getMaxLimitByCurrency, getMinLimitByCurrency } from './heplers'

const { Text } = Typography

const getValidationSchema = ({ minLimit, maxLimit }: { minLimit: number; maxLimit: number }) =>
    yup.object().shape({
        eligible: yup.boolean().required('This field is required'),
        limit: yup.number().required('This field is required').min(minLimit).max(maxLimit),
        activationFeePercent: yup.number().required('This field is required'),
        interestRate: yup.number().required('This field is required'),
    })

type FormValues = {
    eligible: boolean
    limit: number
    activationFeePercent: number | undefined
    interestRate: number | undefined
}

type OverdraftEligibilityModalProps = {
    isVisible: boolean
    closeModal: () => void
}

export const OverdraftEligibilityModal: FC<OverdraftEligibilityModalProps> = ({
    closeModal,
    isVisible,
}) => {
    const country = useSelector(selectCompanyCountry)
    const currency = useSelector(selectCompanyCurrency)
    const companyId = useSelector(selectCompanyId)
    const overdraftEligibility = useOverdraftEligibility(companyId)

    const onSubmit = async (formValues: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
        try {
            await updateOverdraftEligibility({
                companyId,
                payload: {
                    eligibility: {
                        productName: 'OVERDRAFT',
                        eligible: formValues.eligible,
                        appliedPolicy:
                            overdraftEligibility.data?.eligibility.appliedPolicy ?? 'UNKNOWN',
                    },
                    terms: {
                        eligibleForHigherLimit:
                            overdraftEligibility.data?.terms?.eligibleForHigherLimit ?? false,
                        limit: formValues.limit,
                        activationFeePercent: formValues.activationFeePercent!,
                        interestRate: formValues.interestRate!,
                        currency,
                        region: country,
                        // these are not implemented on BE so we won't allow to change it from Backoffice
                        yearlyRepayments:
                            overdraftEligibility.data?.terms?.yearlyRepayments ?? false,
                    },
                },
            })
            notification.success({
                message: 'Eligibility state change successful!',
            })
            overdraftEligibility.mutate()
            resetForm()
            closeModal()
        } catch (error) {
            notification.error({
                message: 'Eligibility state change failed!',
            })
        }
    }
    const initialValues: FormValues = {
        limit: overdraftEligibility.data?.terms?.limit ?? getMinLimitByCurrency(currency),
        eligible: overdraftEligibility.data?.eligibility?.eligible ?? false,
        activationFeePercent: overdraftEligibility.data?.terms?.activationFeePercent,
        interestRate: overdraftEligibility.data?.terms?.interestRate,
    }
    if (!isVisible || overdraftEligibility.isLoading) {
        return null
    }
    return (
        <Modal
            open={isVisible}
            onCancel={closeModal}
            footer={false}
            centered
            title="Overdraft eligibility"
        >
            <Formik
                enableReinitialize
                validationSchema={getValidationSchema({
                    minLimit: getMinLimitByCurrency(currency),
                    maxLimit: getMaxLimitByCurrency(currency),
                })}
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                <OverdraftEligibilityFormContent />
            </Formik>
        </Modal>
    )
}

const OverdraftEligibilityFormContent = () => {
    const { dirty, isValid, values } = useFormikContext<FormValues>()
    const currency = useSelector(selectCompanyCurrency)
    return (
        <Form layout="vertical">
            <Form.Item name="eligible" label="Eligibility">
                <Select name="eligible">
                    <Select.Option value={true}>ELIGIBLE</Select.Option>
                    <Select.Option value={false}>INELIGIBLE</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name="limit" label="Limit">
                <Input name="limit" suffix={currency} disabled={!values.eligible} />
            </Form.Item>
            <Form.Item name="activationFeePercent" label="Activation Fee">
                <Input name="activationFeePercent" suffix="%" disabled={!values.eligible} />
            </Form.Item>
            <Form.Item name="interestRate" label="Interest Rate">
                <Input name="interestRate" suffix="%" disabled={!values.eligible} />
            </Form.Item>
            <Space direction="vertical">
                <Text type="secondary">
                    Customer's eligibility resets daily based on current eligibility criteria
                </Text>
                <SubmitButton disabled={!dirty || !isValid}>Submit</SubmitButton>
            </Space>
        </Form>
    )
}
