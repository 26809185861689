import { Button } from 'antd'
import { bff } from 'pages/compliance/bff'
import { useUser } from 'providers/user-context'

// Will be replaced by the actual type from the OpenAPI schema
interface CreateKycCasePayloadProps {
    companyId: string
    ownerEmail: string
    legalName: string
    domainName?: string
    tradingName?: string
    tradingAddress?: string
    registrationNumber?: string
    industryCodes?: string[]
}
interface CreateKycCaseButtonProps {
    companyId: string
    onClick: (data: CreateKycCasePayloadProps) => void
}

export const CreateKycCaseButton = ({ companyId, onClick }: CreateKycCaseButtonProps) => {
    const { email } = useUser()
    const { data: companyInfo, isLoading } = bff.proofOfBusiness.getCompanyInfo.useQuery({
        deimosCompanyId: companyId,
    })

    const payload = {
        ownerEmail: email || '',
        companyId: companyInfo?.companyId || '',
        legalName: companyInfo?.companyName || '',
        domainName: companyInfo?.domainName,
        tradingName: companyInfo?.tradingName,
        address: companyInfo?.address,
        registrationNumber: companyInfo?.registrationNumber,
        industryCodes: companyInfo?.industryCodes,
    }

    return (
        <Button disabled={isLoading} onClick={() => onClick(payload)}>
            Create KYC Case
        </Button>
    )
}
