import React, { useState, FC } from 'react'
import { Button, Modal, message } from 'antd'

import type { DocumentRequest, DocumentCategory } from 'types/styx'
import CreateDocumentForm from 'pages/compliance/company/shared/document-form/document-form'
import type { FormValues } from 'pages/compliance/company/shared/document-form/validation-schema'
import { useManagement } from 'services/styx/management'
import { PlusOutlined } from '@ant-design/icons'
import { useCompanyDocumentsWithDateSnapshot } from 'services/styx/company'
import { showNotSupportedDocumentCategoryModal } from './show-not-supported-document-category-modal'

interface Props {
    createDocument: (values: FormValues) => void
}

export const CreateDocument: FC<React.PropsWithChildren<Props>> = ({ createDocument }) => {
    const [visible, setVisibility] = useState(false)

    const onSubmit = (values: FormValues) => {
        setVisibility(false)
        createDocument(values)
    }

    return (
        <>
            <Button
                data-testid="button"
                icon={<PlusOutlined />}
                onClick={() => setVisibility(true)}
            >
                Add document
            </Button>
            <Modal
                title="Create new document"
                open={visible}
                onCancel={() => setVisibility(false)}
                footer={null}
                destroyOnClose
            >
                <CreateDocumentForm onSubmit={onSubmit} />
            </Modal>
        </>
    )
}

export const CreatePersonDocument: FC<
    React.PropsWithChildren<{ subjectId: string; refetchPeople: () => void }>
> = ({ subjectId, refetchPeople }) => {
    const { mutations } = useManagement()

    const createPersonDocument = async ({ title, description, category, hidden }: FormValues) => {
        try {
            const body: DocumentRequest = {
                title,
                category: category as DocumentCategory,
                description: description ? description : undefined,
                hidden,
            }

            await mutations.createDocument(subjectId, body)
            refetchPeople()
        } catch (e) {
            if (e instanceof Error && e.message.includes('not supported for personal documents')) {
                await showNotSupportedDocumentCategoryModal('create', e.message)
            } else {
                message.error(`Failed to create document: ${(e as Error).message}`)
            }
        }
    }
    return <CreateDocument createDocument={createPersonDocument} />
}

export const CreateCompanyDocument = ({ globalId }: { globalId: string }) => {
    const { mutations } = useCompanyDocumentsWithDateSnapshot(globalId)
    const createCompanyDocument = async ({ category, description, title, hidden }: FormValues) => {
        try {
            const body = {
                category: category as DocumentCategory,
                description: description ? description : undefined,
                title,
                hidden,
            }

            await mutations.createDocument(body)
        } catch (e) {
            if (e instanceof Error && e.message.includes('not supported for company documents')) {
                await showNotSupportedDocumentCategoryModal('create', e.message)
            } else {
                message.error(`Failed to create document: ${(e as Error).message}`)
            }
        }
    }
    return <CreateDocument createDocument={createCompanyDocument} />
}
