import type React from 'react'
import { Link } from 'react-router-dom'
import { Popover, Spin, Table, Typography } from 'antd'
import styled from 'styled-components'

import {
    CheckCircleFilled,
    ExclamationCircleFilled,
    InfoCircleOutlined,
    PaperClipOutlined,
} from '@ant-design/icons'

import type { WalletUnloadResponse } from 'bff/moons/generated/cupid-v1'
import { WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'
import dayjs from 'packages/dayjs'
import { color, spacing } from 'theme/tokens'
import { useWalletUnloadDocumentUrl } from 'services/deimos/wallet'
import { useCompanyBalance, useGetCompany } from 'services/deimos/companies'
import { useGetLatestProofOfFunds } from 'services/deimos/proof-of-funds'
import InfoElemHorizontal from 'components/info-elem/info-elem-horizontal'
import type { OnboardingSource } from 'types/deimos-company'

import Actions from './actions'
import { getBankInfoForDisplay } from './common'
import {
    bankDetailsMismatches,
    getAmountForWalletUnload,
    getBankInformation,
    getTypeForWalletUnload,
    removeSpaces,
} from './utils'

const { Text } = Typography

type WalletUnloadWithInfoToFilter = WalletUnloadResponse & {
    countryCode: string
    onboardedVia: OnboardingSource
}

const SenderBankContainer = styled.span`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${spacing.space4};
    white-space: nowrap;
`

const Column = styled.div`
    display: grid;
`

const CompanyInformation: React.FC<
    React.PropsWithChildren<{ walletUnload: WalletUnloadResponse }>
> = ({ walletUnload }) => {
    const { data: company } = useGetCompany(walletUnload.companyId)
    const { senderBank, senderInformation, error } = useGetLatestProofOfFunds(
        walletUnload.companyId
    )

    const { iban, accountNumber, bankCode } = getBankInformation(
        walletUnload,
        company,
        senderBank,
        senderInformation
    )

    return (
        <Column>
            {walletUnload.companyId ? (
                <Link to={`/customer-success/companies/${walletUnload.companyId}/billing`}>
                    {company?.name || 'Failed to retrieve company'}
                </Link>
            ) : (
                <Text>No company ID</Text>
            )}
            {error?.message === 'not_found' && <Text>Company not found</Text>}
            {error?.meta?.type === 'ENTITY_NOT_FOUND_EXCEPTION' && <Text>No bank information</Text>}
            {iban && <InfoElemHorizontal label="IBAN" value={iban} />}
            {!iban && (
                <>
                    {accountNumber && (
                        <InfoElemHorizontal label="Account no." value={accountNumber} />
                    )}
                    {bankCode && <InfoElemHorizontal label="Sort code" value={bankCode} />}
                </>
            )}
            <SenderBankContainer>
                <Text>Sender bank</Text>
                <Popover
                    title="Sender bank"
                    content={getBankInfoForDisplay(senderBank, senderInformation)}
                >
                    <InfoCircleOutlined />
                </Popover>
            </SenderBankContainer>
        </Column>
    )
}

const OnboardedViaInfo: React.FC<
    React.PropsWithChildren<{ walletUnload: WalletUnloadResponse }>
> = ({ walletUnload }) => {
    const { data: company } = useGetCompany(walletUnload.companyId)

    let contentText
    if (!walletUnload.companyId) {
        contentText = 'No company ID'
    } else {
        contentText = company ? company.onboardedVia : 'No company data'
    }

    return (
        <Column>
            <Text>{contentText}</Text>
        </Column>
    )
}

const RequesterInformation: React.FC<
    React.PropsWithChildren<{ walletUnload: WalletUnloadResponse }>
> = ({ walletUnload }) => {
    const { data: company } = useGetCompany(walletUnload.companyId)
    return (
        <Column>
            <Text>{company?.name || 'Not found'}</Text>
            {walletUnload.account.iban && (
                <InfoElemHorizontal label="IBAN" value={walletUnload.account.iban} />
            )}
            {walletUnload.account.accountNumber && (
                <InfoElemHorizontal
                    label="Account no."
                    value={walletUnload.account.accountNumber}
                />
            )}
            {walletUnload.account.bankCode && (
                <InfoElemHorizontal label="Sort code" value={walletUnload.account.bankCode} />
            )}
        </Column>
    )
}

const State: React.FC<React.PropsWithChildren<{ walletUnload: WalletUnloadResponse }>> = ({
    walletUnload,
}) => {
    const { data: company } = useGetCompany(walletUnload.companyId)
    const {
        senderBank,
        senderInformation,
        error: latestPoFError,
    } = useGetLatestProofOfFunds(walletUnload.companyId)
    const { data: companyBalance, error: balanceError } = useCompanyBalance(walletUnload.companyId)

    if (
        !walletUnload ||
        !company ||
        (!senderBank && !senderInformation && !latestPoFError) ||
        (!companyBalance && !balanceError)
    ) {
        return <Spin size="small" />
    }

    const { iban, accountNumber, bankCode } = getBankInformation(
        walletUnload,
        company,
        senderBank,
        senderInformation
    )
    const hasIban = !!walletUnload.account.iban
    const hasAccountNumber = !!walletUnload.account.accountNumber
    const hasSortCode = !!walletUnload.account.bankCode

    const ibanMismatch =
        removeSpaces(iban).toLowerCase() !== removeSpaces(walletUnload.account.iban).toLowerCase()
    const accountMismatch =
        removeSpaces(accountNumber).toLowerCase() !==
        removeSpaces(walletUnload.account.accountNumber).toLowerCase()
    const sortCodeMismatch =
        removeSpaces(bankCode).toLowerCase() !==
        removeSpaces(walletUnload.account.bankCode).toLowerCase()

    const mismatchMessages = bankDetailsMismatches(
        hasIban,
        hasAccountNumber,
        hasSortCode,
        ibanMismatch,
        accountMismatch,
        sortCodeMismatch
    )

    const available = companyBalance?.balance.available.value
    const current = companyBalance?.balance.current.value
    if (available !== current) {
        mismatchMessages.push('Wallet balance mismatch')
    } else if (balanceError) {
        mismatchMessages.push('Failed to check wallet balance')
    }

    return (
        <>
            {mismatchMessages.length === 0 ? (
                <CheckCircleFilled style={{ color: color.green }} />
            ) : (
                <Popover
                    title="Warning"
                    content={mismatchMessages.map((m, i) => (
                        <div key={i}>{m}</div>
                    ))}
                >
                    <ExclamationCircleFilled style={{ color: color.gold }} />
                </Popover>
            )}
        </>
    )
}

const Amount: React.FC<React.PropsWithChildren<{ walletUnload: WalletUnloadResponse }>> = ({
    walletUnload,
}) => {
    const { data: companyBalance } = useCompanyBalance(walletUnload.companyId)
    return (
        <Column>
            <Text>
                {getTypeForWalletUnload(walletUnload)}: {getAmountForWalletUnload(walletUnload)}
            </Text>
            <Text>
                Available balance: {companyBalance?.balance.available.value ?? 'Failed to retrieve'}
            </Text>
        </Column>
    )
}

const RequestSource: React.FC<React.PropsWithChildren<{ walletUnload: WalletUnloadResponse }>> = ({
    walletUnload,
}) => {
    return <Text>{walletUnload.requestSource}</Text>
}

const ID: React.FC<React.PropsWithChildren<{ walletUnload: WalletUnloadResponse }>> = ({
    walletUnload,
}) => {
    return <Text>{walletUnload.id}</Text>
}

const Documents: React.FC<React.PropsWithChildren<{ documentUrls: string[] }>> = ({
    documentUrls,
}) => (
    <>
        {documentUrls.length === 0 && <div>No documents</div>}
        {documentUrls.map((documentUrl, index) => (
            <Document documentUrl={documentUrl} key={index} />
        ))}
    </>
)

const Country: React.FC<
    React.PropsWithChildren<{ walletUnload: WalletUnloadWithInfoToFilter }>
> = ({ walletUnload }) => <Text>{walletUnload.countryCode}</Text>

const Reason: React.FC<React.PropsWithChildren<{ walletUnload: WalletUnloadResponse }>> = ({
    walletUnload,
}) => {
    return (
        <>
            <div>{dayjs(walletUnload.updatedAt).format('DD-MM-YY')}</div>
            <Popover title="Investigation reason" content={walletUnload.statusReason}>
                <Text style={{ color: color.gold }}>Details</Text>
            </Popover>
        </>
    )
}

const Document: React.FC<React.PropsWithChildren<{ documentUrl: string }>> = ({ documentUrl }) => {
    const file = documentUrl.match(/file=(.*)/)?.[1]
    const name = documentUrl.match(/_(.*)/)?.[1]
    const { data } = useWalletUnloadDocumentUrl(file)
    return (
        <div>
            <PaperClipOutlined />{' '}
            <a href={data?.url} target="_">
                {name}
            </a>
        </div>
    )
}

const STATE_COLUMN_STATUSES: Array<WalletUnloadResponseStatus> = [
    WalletUnloadResponseStatus.NEW,
    WalletUnloadResponseStatus.UNDER_INVESTIGATION,
]

const ACTIONABLE_STATUSES: Array<WalletUnloadResponseStatus> = [
    WalletUnloadResponseStatus.NEW,
    WalletUnloadResponseStatus.UNLOADED,
    WalletUnloadResponseStatus.UNDER_INVESTIGATION,
    WalletUnloadResponseStatus.EXPORTED,
]

const REASON_COLUMN_STATES: Array<WalletUnloadResponseStatus> = [
    WalletUnloadResponseStatus.UNDER_INVESTIGATION,
    WalletUnloadResponseStatus.CANCELLED,
]

export const renderColumns = (status: WalletUnloadResponseStatus) => {
    const hasStateColumn = STATE_COLUMN_STATUSES.includes(status)
    const isActionable = ACTIONABLE_STATUSES.includes(status)
    const hasReasonColumn = REASON_COLUMN_STATES.includes(status as WalletUnloadResponseStatus)

    return (
        <>
            <Table.Column<WalletUnloadResponse>
                title="Date"
                dataIndex="createdAt"
                render={(date) => <Text>{dayjs(date).format('DD-MM-YY')}</Text>}
                sorter={(a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf()}
            />
            <Table.Column<WalletUnloadResponse>
                title="Unload ID"
                dataIndex="id"
                render={(_, walletUnload) => <ID walletUnload={walletUnload} />}
            />
            {hasStateColumn && (
                <Table.Column<WalletUnloadResponse>
                    title="Status"
                    dataIndex="companyId"
                    render={(_, walletUnload) => <State walletUnload={walletUnload} />}
                />
            )}
            <Table.Column<WalletUnloadWithInfoToFilter>
                title="Country"
                dataIndex="countryCode"
                render={(_, walletUnload) => <Country walletUnload={walletUnload} />}
            />
            <Table.Column<WalletUnloadResponse>
                title="Company information"
                dataIndex="companyId"
                render={(_, walletUnload) => <CompanyInformation walletUnload={walletUnload} />}
            />
            <Table.Column<WalletUnloadResponse>
                title="Onboarded via"
                dataIndex="onboardedVia"
                render={(_, walletUnload) => <OnboardedViaInfo walletUnload={walletUnload} />}
            />
            <Table.Column<WalletUnloadResponse>
                title="Requester information"
                dataIndex="beneficiary"
                render={(_, walletUnload) => <RequesterInformation walletUnload={walletUnload} />}
            />
            <Table.Column<WalletUnloadResponse>
                title="Type and amount"
                dataIndex="fullAmount"
                render={(_, walletUnload) => <Amount walletUnload={walletUnload} />}
            />
            <Table.Column<WalletUnloadResponse>
                title="Source"
                dataIndex="requestSource"
                render={(_, walletUnload) => <RequestSource walletUnload={walletUnload} />}
            />
            <Table.Column<WalletUnloadResponse>
                title="User documents"
                dataIndex="documentUrls"
                render={(documentUrls) => <Documents documentUrls={documentUrls} />}
            />
            {hasReasonColumn && (
                <Table.Column<WalletUnloadResponse>
                    title="Reason"
                    dataIndex="statusReason"
                    width={110}
                    render={(_, walletUnload) => <Reason walletUnload={walletUnload} />}
                />
            )}
            {isActionable && (
                <Table.Column<WalletUnloadResponse>
                    title="Actions"
                    dataIndex="id"
                    fixed="right"
                    render={(_, walletUnload) => (
                        <Actions walletUnload={walletUnload} status={status} />
                    )}
                />
            )}
        </>
    )
}
