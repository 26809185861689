import { List, Skeleton } from 'antd'
import capitalize from 'lodash/capitalize'
import { bff } from '../bff'
import type { ReactNode } from 'react'
import styled from 'styled-components'

const ListItem: React.FC<{ title?: string; content?: ReactNode }> = ({ title, content }) => {
    return (
        <List.Item style={{ padding: '8px 0' }}>
            <ListItemTitle>{title}</ListItemTitle>
            <div>{content ?? '-'}</div>
        </List.Item>
    )
}

export const BillingInformation = ({ companyId }: { companyId: string }) => {
    const { data, isLoading } = bff.billingInformation.getBillingInformation.useQuery({ companyId })
    if (isLoading) return <Skeleton active />

    return (
        <List size="small" bordered={false} itemLayout="vertical">
            <ListItem title="Billing contact" content={data?.billingContact} />
            <ListItem title="Plan" content={capitalize(data?.activePlanName) || null} />
            <ListItem title="Previous plan" content={capitalize(data?.previousPlanName) || null} />
            <ListItem title="Term" content={capitalize(data?.paymentScheduleType) || null} />
            <ListItem
                title="First invoice"
                content={
                    <Invoice issued={data?.firstInvoiceDate} paid={data?.firstPaidInvoiceDate} />
                }
            />
            <ListItem title="Next invoice date" content={data?.nextInvoiceDate} />
            <ListItem title="Next invoice amount" content={data?.nextInvoiceAmount} />
            <ListItem title="Oldest open invoice date" content={data?.oldestOpenInvoiceDate} />
            <ListItem title="Total unpaid amount due" content={data?.totalUnpaidAmountDue} />
            <ListItem title="Total unpaid invoices" content={data?.totalUnpaidInvoices} />
            <ListItem
                title="Paid add ons"
                content={data?.addons?.length === 0 ? null : data?.addons}
            />
            <ListItem title="CSM" content={data?.csm} />
        </List>
    )
}

const Invoice = ({ issued, paid }: { issued?: string | null; paid?: string | null }) => {
    if (!issued && !paid) return <div>-</div>
    return (
        <div>
            <div>{`Invoiced on ${issued ?? '-'}`}</div>
            <div>{`Paid on ${paid ?? '-'}`}</div>
        </div>
    )
}

const ListItemTitle = styled.span`
    font-weight: bold;
`
