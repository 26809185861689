import useSWR from 'swr'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'

import request, { fetcher } from './request'
import { fetcher as julietFetcher } from './juliet-request'
import type { WalletUnloadResponse, WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'
import { bff as complianceBff } from 'pages/compliance/bff/bff'
import { defineClientContext } from 'pages/compliance/bff/operational-context'
import { AutoTopUpStatus, CompanyTopUpSettings } from 'types/company-top-up-settings'
import type { InvoiceStatus } from 'types/wallet-invoice'

export const useUnloadDocumentUrl = () => {
    const location = useLocation()
    const file = parse(location.search, { ignoreQueryPrefix: true }).file

    const response = useSWR<{ url: string }, Error>(
        file ? `rest/v1/unload-document?file=${file}` : null,
        fetcher
    )

    const url = response.data?.url
    useEffect(() => {
        if (url) {
            window.open(url, '_blank')
            window.location.replace(location.pathname)
        }
    }, [location.pathname, url])
}

export const useWalletUnloadDocumentUrl = (file?: string) =>
    useSWR<{ url: string }, Error>(file ? `rest/v1/unload-document?file=${file}` : null, fetcher)

export function updateStatusForWalletUnload(
    walletUnloadId: string,
    newStatus: WalletUnloadResponseStatus
) {
    return request()
        .patch(`funds-management/rest/v4/unloads/${walletUnloadId}/status`, {
            json: { newStatus },
        })
        .then((res) => res.json())
}

export function updateWalletUnloadById(walletUnloadId: string, walletUnload: WalletUnloadResponse) {
    return request()
        .put(`funds-management/rest/v4/unloads/${walletUnloadId}`, { json: walletUnload })
        .then((res) => res.json())
}

export function exportWalletUnloadsById(walletUnloadIds: string[]) {
    return request()
        .post(`funds-management/rest/v4/unloads/export`, { json: { ids: walletUnloadIds } })
        .then((res) => res.json())
}

export const useWalletUnloads = (status: WalletUnloadResponseStatus) => {
    const { data, error, isFetching, refetch } =
        complianceBff.walletUnloads.getWalletUnloadsByStatus.useQuery(
            {
                status,
            },
            {
                trpc: {
                    context: defineClientContext({
                        skipBatch: true,
                    }),
                },
            }
        )

    // for consistency with the original piece of code, while Deimos & BFF migration is in progress
    const result = {
        data,
        error: error === null ? undefined : error,
        mutate: refetch,
        isValidating: isFetching,
    }

    const updateWalletUnloadStatus = async (
        walletUnloadId: string,
        newStatus: WalletUnloadResponseStatus
    ) => {
        await updateStatusForWalletUnload(walletUnloadId, newStatus)
        result.mutate()
    }

    const updateWalletUnload = async (
        walletUnloadId: string,
        walletUnload: WalletUnloadResponse
    ) => {
        await updateWalletUnloadById(walletUnloadId, walletUnload)
        result.mutate()
    }

    const exportWalletUnloads = async (walletUnloadIds: string[]) => {
        const response = await exportWalletUnloadsById(walletUnloadIds)
        result.mutate()
        return response
    }
    return {
        ...result,
        mutations: { updateWalletUnloadStatus, updateWalletUnload, exportWalletUnloads },
    }
}

export const useWalletUnloadSummaries = () => useSWR('rest/v4/unloads/summaries', julietFetcher)

export const useWalletUnloadSummaryDocumentUrl = (id: string) =>
    useSWR(`rest/v4/unloads/summaries/${id}/url`, julietFetcher)

export const setWalletLoadStatus = async (loadId: string, status: InvoiceStatus) => {
    return request()
        .put(`funds-management/rest/v4/admin/loads/${loadId}/${status}`)
        .then((res) => res.json())
}

export const retryWalletLoad = async (walletLoadIds: string[]) => {
    return request().post(`funds-management/rest/v4/admin/loads/retry`, {
        json: {
            walletLoadIds,
        },
    })
}

export const useWalletAutoTopUp = (companyId: string) => {
    const result = useSWR<CompanyTopUpSettings>(
        `wallet-topup/rest/v1/companies/${companyId}/settings`,
        fetcher
    )

    async function disableAutoTopUp() {
        const payload: CompanyTopUpSettings = {
            ...result.data,
            autoTopupStatus: AutoTopUpStatus.DISABLED,
        }

        await request().put(`wallet-topup/rest/v1/companies/${companyId}/settings`, {
            json: payload,
        })
        return result.mutate()
    }

    return {
        ...result,
        mutations: { disableAutoTopUp },
    }
}
