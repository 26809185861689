import type { GetAllTaxCodeResponse, TaxCode } from '@pleo-io/deimos'
import qs from 'qs'
import useSWR, { SWRConfiguration } from 'swr'
import { fetcher } from './request'

function getQueryParams<Q extends {}>(q: Q) {
    const queryParams = qs.stringify(q)
    return queryParams ? `?${queryParams}` : ''
}

export function useTaxCodes({
    companyId,
    config,
    bypassHook = false,
}: {
    companyId: string
    config?: SWRConfiguration
    bypassHook?: boolean
}) {
    const query = getQueryParams({
        companyId,
        force: true,
    })

    const result = useSWR<GetAllTaxCodeResponse, Error>(
        bypassHook ? null : `rest/v1/companies/${companyId}/tax-codes${query}`,
        fetcher,
        config
    )

    return {
        ...result,
    }
}

export type TaxCodeOption = {
    rate?: TaxCode['rate']
    name?: TaxCode['name']
    hidden: boolean
    disabled: boolean
    value: string
    label: string
    groupId?: string
    groupLabel: boolean
}

export type TaxCodeOptions = Array<TaxCodeOption>

export function useGroupedTaxCodeOptions({ companyId }: { companyId: string }): {
    data: TaxCodeOptions
    isValidating: boolean
} {
    const { data = [], isValidating } = useTaxCodes({ companyId })
    return {
        data: getGroupedTaxCodes(data),
        isValidating,
    }
}

type GroupedTaxCodes = {
    [key: string]: TaxCode[]
}

function groupTaxCodesByType(data: TaxCode[]): GroupedTaxCodes {
    const groups: GroupedTaxCodes = {}

    data.forEach((item: TaxCode) => {
        if (!groups[item.type]) {
            groups[item.type] = []
        }
        groups[item.type].push(item)
    })

    return groups
}

export function getGroupedTaxCodes(taxCodes: TaxCode[]) {
    const groupedByType = groupTaxCodesByType(taxCodes)

    return Object.keys(groupedByType)
        .sort((a, b) => {
            if (a !== 'reverse' && b === 'reverse') {
                return -1
            }
            if (a === 'reverse' && b !== 'reverse') {
                return 1
            }

            return 0
        })
        .map((type) => {
            const name = type === 'reverse' ? `Reverse tax codes` : undefined

            const items = groupedByType[type].map((taxCode: TaxCode) => ({
                value: taxCode.id,
                name: taxCode.name,
                label: `${taxCode.name} (${timesHundred(taxCode.rate)}%)`,
                groupId: taxCode.type,
                hidden: !taxCode.enabled,
                rate: taxCode.rate,
                groupLabel: false,
                disabled: false,
            }))

            return { name, items }
        })
        .map((taxCode) => [
            {
                label: taxCode.name || '',
                value: taxCode.name || '',
                disabled: true,
                hidden: !taxCode.name,
                groupLabel: true,
            },
            ...taxCode.items,
        ])
        .reduce((acc, taxCode) => [...acc, ...taxCode], [])
        .filter((taxCode) => taxCode.label !== '')
}

const timesHundred = (number: number) => number * 100
