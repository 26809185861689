import type { State } from 'store/modules/state'

export const selectSupplier = (state: State) => state.suppliers.currentSupplier
export const selectSuppliers = (state: State) => state.suppliers.suppliers
export const selectInvoices = (state: State) => state.suppliers.currentSupplierInvoices

export const selectSuppliersPagination = (state: State) => state.suppliers.suppliersPagination
export const selectInvoicesPagination = (state: State) =>
    state.suppliers.currentSupplierInvoicesPagination

export const selectSuppliersRequestParams = (state: State) => state.suppliers.suppliersRequestParams
export const selectInvoicesRequestParams = (state: State) =>
    state.suppliers.currentSupplierInvoicesRequestParams

export const selectSupplierStatus = (state: State) => state.suppliers.currentSupplierStatus
export const selectSuppliersStatus = (state: State) => state.suppliers.suppliersStatus
export const selectInvoicesStatus = (state: State) => state.suppliers.currentSupplierInvoicesStatus
