import * as yup from 'yup'

import type { CreatePersonRequest } from 'types/styx'

const nonRequiredAddressProps = ['street', 'floor', 'houseNumber', 'locality', 'region']

const isDefinedProp = (...args: string[]) => args.find((prop) => !!prop)
export const validationSchema = yup
    .object()
    .shape(
        {
            registryId: yup.string().nullable(),
            nationalId: yup.string().nullable(),
            givenName: yup.string().required('First name is required').nullable(),
            middleName: yup.string().nullable(),
            familyName: yup.string().nullable(),
            nationality: yup
                .string()
                .matches(/^[A-Z]{2}$/, 'Please use valid country code, e.g. DK')
                .nullable(),
            day: yup.number().min(1).max(31).nullable(),
            month: yup
                .number()
                .min(1)
                .max(12)
                .nullable()
                .when(['day', 'year'], {
                    is: isDefinedProp,
                    then: (schema) => schema.required('Month is required for DOB'),
                }),
            year: yup
                .number()
                .min(1900)
                .nullable()
                .when(['day', 'month'], {
                    is: isDefinedProp,
                    then: (schema) => schema.required('Year is required for DOB'),
                }),
            floor: yup.string().nullable(),
            street: yup.string().nullable(),
            houseNumber: yup.string().nullable(),
            locality: yup.string().nullable(),
            postalCode: yup.string().when([...nonRequiredAddressProps, 'country'], {
                // checking if any of the props are defined
                is: isDefinedProp,
                then: (schema) => schema.required('Postal code is required'),
            }),
            region: yup.string().nullable(),
            country: yup
                .string()
                .matches(/^[A-Z]{2}$/, 'Please use valid country code, e.g. DK')
                .when([...nonRequiredAddressProps, 'postalCode'], {
                    // checking if any of the props are defined
                    is: isDefinedProp,
                    then: (schema) => schema.required('Country is required'),
                }),
        },
        [
            ['country', 'postalCode'],
            ['month', 'year'],
        ]
    )
    .required()

export type FormValues = yup.InferType<typeof validationSchema>

export const mapValuesToRequest = (
    values: FormValues,
    rootCompanyId: string
): CreatePersonRequest => {
    const dateOfBirth =
        values.month && values.year
            ? {
                  day: values.day,
                  month: values.month,
                  year: values.year,
              }
            : null
    const address =
        values.country && values.postalCode
            ? {
                  country: values.country,
                  locality: values.locality,
                  postalCode: values.postalCode,
                  houseNumber: values.houseNumber,
                  street: values.street,
                  floor: values.floor,
                  region: values.region,
              }
            : null
    const kycNationality = values.nationality
        ? {
              nationality: values.nationality,
              source: 'DUE_DILIGENCE_AGENT_INPUT',
          }
        : null
    return {
        rootCompanyId,
        registryId: values.registryId || null,
        nationalId: values.nationalId || null,
        name: {
            givenName: values.givenName,
            middleName: values.middleName || null,
            familyName: values.familyName || null,
        },
        dateOfBirth,
        address,
        kycNationality,
    }
}
