import * as Yup from 'yup'

export const validationSchema = Yup.object()
    .shape({
        companyName: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        numberOfEmployees: Yup.number().required('Required'),
        walletBalance: Yup.number().required('Required'),
        numberOfExpenses: Yup.number().required('Required'),
        language: Yup.string().required('Required'),
        expiresAt: Yup.string().nullable().notRequired(),
    })
    .required()
