import * as yup from 'yup'
import type { UnknownEntity } from 'types/styx'

export const validationSchema = yup.object().shape({
    selectedEntity: yup.string().required('Required'),
    entityType: yup.mixed().oneOf(['PERSON', 'COMPANY']).required(),
})

export interface FormValues {
    selectedEntity: string
    entityType: 'COMPANY' | 'PERSON'
}

export const mapValuesToRequest = (values: FormValues, unknowns: UnknownEntity[]) =>
    unknowns.find((unknown) => unknown.id === values.selectedEntity)
