import { FC, useState } from 'react'
import styled from 'styled-components'
import { DeleteOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'

import type { Document } from 'types/styx'
import EditDocumentForm from 'pages/compliance/company/shared/document-form/document-form'
import type { FormValues } from 'pages/compliance/company/shared/document-form/validation-schema'

interface Props {
    document: Document
    disabled?: boolean
    onDocumentDelete: () => void
    onEdit: (values: FormValues) => void
}

const EditDocumentPopover: FC<React.PropsWithChildren<Props>> = ({
    document,
    onDocumentDelete,
    onEdit,
    disabled,
}) => {
    const [editVisible, setEditVisible] = useState(false)
    return (
        <>
            <Button onClick={() => setEditVisible(!editVisible)}>Edit document</Button>
            <Modal
                title="Edit document"
                open={editVisible}
                onCancel={() => setEditVisible(false)}
                footer={null}
                destroyOnClose
            >
                <FormContainer>
                    <EditDocumentForm
                        initialValues={{
                            title: document.title,
                            category: document.category,
                            description: document.description,
                            hidden: document.hidden,
                        }}
                        onSubmit={(values) => {
                            setEditVisible(false)
                            onEdit(values)
                        }}
                    />
                    <Button
                        disabled={disabled}
                        size="small"
                        danger
                        onClick={() => {
                            setEditVisible(false)
                            return new Promise((resolve, reject) => {
                                Modal.confirm({
                                    onOk: () => {
                                        onDocumentDelete()
                                        resolve(true)
                                    },
                                    onCancel: () => {
                                        reject(false)
                                    },
                                    centered: true,
                                    okText: 'Delete',
                                    title: 'Are you sure you want to delete this document?',
                                    okButtonProps: {
                                        danger: true,
                                    },
                                    icon: <DeleteOutlined />,
                                })
                            })
                        }}
                    >
                        <DeleteOutlined />
                    </Button>
                </FormContainer>
            </Modal>
        </>
    )
}

const FormContainer = styled.div`
    display: flex;
    .ant-btn-danger {
        align-self: flex-end;
    }
`

export default EditDocumentPopover
