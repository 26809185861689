import { useFormikContext } from 'formik'
import type { FormValuesProfile } from '../types'

const ConfirmStep = () => {
    const { values } = useFormikContext<FormValuesProfile>()
    return (
        <p>
            Click the button below to create {values.meta.companyName}, we will return the email
            once the company is created and we'll send a message to {values.meta.requesterEmail} on
            slack once its filled with delicious expenses.
        </p>
    )
}

export default ConfirmStep
