import { useState } from 'react'
import type { ExpenseProfile } from '../../types'
import ReadableExpense from './readable-expense'
import EditableExpense from './editable-expense'

type ListItemProps = ExpenseProfile & {
    index: number
}

const ListItem = ({ name, merchantProfiles, expenseType, index }: ListItemProps) => {
    const [isEditing, setIsEditing] = useState(false)

    return isEditing ? (
        <EditableExpense
            key={name}
            expenseType={expenseType}
            merchantProfiles={merchantProfiles}
            toggleEditing={setIsEditing}
            name={name}
            index={index}
        />
    ) : (
        <ReadableExpense
            name={name}
            expenseType={expenseType}
            merchantProfiles={merchantProfiles}
            toggleEditing={setIsEditing}
        />
    )
}

export default ListItem
