export enum CompanySizeEnum {
    SoleTrader = '0',
    Size_1 = '1',
    Size_2 = '2-4',
    Size_5 = '5-9',
    Size_10 = '10-49',
    Size_50 = '50-99',
    Size_100 = '100-249',
    Size_250 = '250-499',
    Size_500 = '500+',
}
