import { useEffect, useState } from 'react'
import { Card, Modal } from 'antd'

import { useUser } from 'providers/user-context'
import {
    useDemoAccounts,
    defaultLimit,
    useCreateDemoAccount,
    useDemoAccount,
} from 'services/dionysus/demo-accounts'
import CursorPaginationControls from 'components/cursor-pagination-controls'
import { ContentContainer, PageContentLayout } from 'components/layout-containers'

import ListDemoAccounts from './list-demo-accounts/list-demo-accounts'
import GenerateDemo from './generate-demo/generate-demo'
import DemoAccount from './demo-account/demo-account'
import {
    formValuesToCreateDemoAccountRequest,
    formValuesToGetPreCreatedDemoAccountRequest,
} from './helpers'
import type { FormValuesExtended, FormValuesBasic } from './generate-demo/generate-demo-form'

const DemoAccounts = () => {
    const [activeDemoAccountId, setActiveDemoAccountId] = useState<string | null>(null)

    const { email: requesterEmail } = useUser()
    const {
        accountDetails,
        companyDetails,
        loading: generatingAccount,
        setCreateDemoAccountRequest,
        setCreatePreCreatedDemoAccountRequest,
        error,
        dismissError,
    } = useCreateDemoAccount()

    const {
        demoAccounts,
        loading: loadingAccounts,
        nextPage,
        previousPage,
        updateLimit,
        pagination,
        updateSorting,
        firstPage,
        mutate: updateDemoAccountsList,
        mutations,
    } = useDemoAccounts(requesterEmail)

    const { data: demoAccount } = useDemoAccount(activeDemoAccountId)

    useEffect(() => {
        if (accountDetails) {
            updateDemoAccountsList()
        }
    }, [accountDetails, updateDemoAccountsList])

    const limit = pagination?.currentRequestPagination.limit ?? defaultLimit

    const goBack = () => dismissError()
    const onChangeLimit = (newLimit: number) => updateLimit(newLimit)
    const onSubmitExtended = async (values: FormValuesExtended) => {
        if (requesterEmail) {
            const createDemoAccountRequest = formValuesToCreateDemoAccountRequest(
                values,
                requesterEmail
            )

            setCreateDemoAccountRequest(createDemoAccountRequest)
        }
    }
    const onSubmitBasic = async (values: FormValuesBasic) => {
        if (requesterEmail) {
            const createDemoAccountRequest = formValuesToGetPreCreatedDemoAccountRequest(
                values,
                requesterEmail
            )

            setCreatePreCreatedDemoAccountRequest(createDemoAccountRequest)
        }
    }

    const onViewCompany = (demoAccountId: string) => {
        setActiveDemoAccountId(demoAccountId)
    }

    const onCancelModal = () => setActiveDemoAccountId(null)

    return (
        <>
            <PageContentLayout>
                <Card title="Generate Demo Account">
                    <GenerateDemo
                        accountDetails={accountDetails}
                        companyDetails={companyDetails}
                        loading={generatingAccount}
                        goBack={goBack}
                        error={error}
                        onSubmitExtended={onSubmitExtended}
                        onSubmitBasic={onSubmitBasic}
                    />
                </Card>
                <Card title="Demo Accounts">
                    <ContentContainer>
                        <ListDemoAccounts
                            updateExpiresAt={mutations.updateExpiresAt}
                            onViewCompany={onViewCompany}
                            loading={loadingAccounts}
                            demoAccounts={demoAccounts}
                            updateSorting={updateSorting}
                        />
                        {pagination && (
                            <CursorPaginationControls
                                isFetching={!demoAccounts}
                                pagination={pagination}
                                onChangeLimit={onChangeLimit}
                                limit={limit}
                                onNextPage={nextPage}
                                onPrevPage={previousPage}
                                onFirstPage={firstPage}
                            />
                        )}
                    </ContentContainer>
                </Card>
            </PageContentLayout>
            <Modal
                open={Boolean(activeDemoAccountId)}
                onCancel={onCancelModal}
                footer={false}
                width={800}
                centered
            >
                <DemoAccount demoAccount={demoAccount} />
            </Modal>
        </>
    )
}

export default DemoAccounts
