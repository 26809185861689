import React, { FC, useState } from 'react'
import { Button, message, notification } from 'antd'
import type { DocumentCategory } from 'types/styx'
import { useCompanyDocuments } from 'services/deimos/styx-company/styx-company'

interface Props {
    companyId: string
    category: DocumentCategory
}

export const RequestDocument: FC<React.PropsWithChildren<Props>> = ({ category }) => {
    const [disabled, setDisabled] = useState(false)
    const { mutations } = useCompanyDocuments()

    const requestDocuments = async () => {
        setDisabled(true)
        try {
            const res = await mutations.requestDocument(category)
            message.info(`Requested document: ${res?.status ?? 'PENDING'} - ${res?.providerId}`)
        } catch (e) {
            notification.error({
                message: 'Failed to request document',
                description: (e as Error).message,
            })
        } finally {
            setDisabled(false)
        }
    }

    return (
        <Button type="link" onClick={requestDocuments} disabled={disabled}>
            Request document
        </Button>
    )
}
