import { Alert, AlertProps, Form, Input, Radio, Select, Space } from 'antd'
import { createRequiredErrorMessage } from 'pages/partnerships/utils'
import { APP_CATEGORY_OPTIONS, APP_TYPE_OPTIONS } from '../models/app-form-options'

export const AppNameInput = () => (
    <Form.Item
        label="Display name"
        name="name"
        rules={[{ required: true, message: createRequiredErrorMessage('Display name') }]}
    >
        <Input type="text" />
    </Form.Item>
)

export const AppSlugInput = ({
    alertMessage,
    alertType,
}: {
    alertMessage?: string
    alertType?: AlertProps['type']
}) => (
    <Space size="small" direction="vertical" style={{ width: '100%', marginBottom: '24px' }}>
        <Form.Item
            label="Slug"
            name="slug"
            rules={[
                { required: true, message: createRequiredErrorMessage('Slug') },
                {
                    pattern: /^(?!.*-.*_)(?!.*_.*-)(?![-_])[a-z0-9]+([-_][a-z0-9]+)*(?<![-_])$/,
                    message:
                        'Only lowercase letters, numbers, and hyphens or undescores (but not both) are allowed. Slug must start and end with a letter or number.',
                },
            ]}
            style={{ marginBottom: 'unset' }}
        >
            <Input type="text" />
        </Form.Item>
        {alertMessage && <Alert message={alertMessage} type={alertType || 'info'} showIcon />}
    </Space>
)

export const AppTypeInput = () => (
    <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true, message: createRequiredErrorMessage('Type') }]}
    >
        <Radio.Group>
            {APP_TYPE_OPTIONS.map(({ label, value }) => (
                <Radio key={value} value={value}>
                    {label}
                </Radio>
            ))}
        </Radio.Group>
    </Form.Item>
)

export const AppCategoryInput = () => (
    <Form.Item
        name="category"
        label="Category"
        rules={[
            {
                required: true,
                message: createRequiredErrorMessage('Category'),
            },
        ]}
    >
        <Select>
            {APP_CATEGORY_OPTIONS.map(({ label, value }) => (
                <Select.Option key={value} value={value}>
                    {label}
                </Select.Option>
            ))}
        </Select>
    </Form.Item>
)

export const FormTextInput = ({
    label,
    name,
    required = false,
    description,
    readOnly = false,
    hidden = false,
    placeholder,
}: {
    label: string
    name: string
    required?: boolean
    readOnly?: boolean
    description?: string
    placeholder?: string
    hidden?: boolean
}) => (
    <Form.Item
        hidden={hidden}
        label={label}
        extra={description}
        name={name}
        rules={[
            {
                required: required,
                message: createRequiredErrorMessage(label),
            },
        ]}
    >
        <Input type="text" readOnly={readOnly} placeholder={placeholder} />
    </Form.Item>
)
