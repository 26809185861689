import { FC, useEffect } from 'react'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { PageContentLayout } from 'components/layout-containers'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Card, Collapse, Descriptions, Result, Typography } from 'antd'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { actions as supplierActions } from 'store/modules/suppliers/slice'
import * as supplierSelectors from 'store/modules/suppliers/selectors'

import Spinner from 'components/content-spinner'
import type { CurrentStatus } from 'types/status'
import formatCurrency from 'utils/format-currency'

import { InvoicesList } from '../../components/supplier/invoices-list'
import { StatusForm } from '../../pages/compliance/supplier/status-form'
import { DownloadInvoiceButton } from './download-invoice'
import { SupplierStatus } from '../../types/deimos-supplier'
import type { Supplier } from 'types/deimos-supplier'

const { Text } = Typography

const EDITABLE_STATUSES = [
    SupplierStatus.DRAFT,
    SupplierStatus.INCORRECT_BANK_DETAILS,
    SupplierStatus.REQUIRES_VERIFICATION,
    SupplierStatus.VERIFIED,
]

interface Props {
    status: CurrentStatus
    supplier: Supplier | null
    onEdit?: () => void
    canUpdateStatus?: boolean
}

export const SupplierDetails: FC<React.PropsWithChildren<Props>> = ({
    status,
    supplier,
    onEdit,
    canUpdateStatus = false,
}) => {
    switch (status) {
        case 'fetching':
            return <Spinner />
        case 'error':
            return (
                <Result
                    status="500"
                    title="Something went wrong"
                    subTitle="We had trouble fetching the supplier"
                    extra={
                        <Link to="/compliance/suppliers">
                            <ArrowLeftOutlined /> Go back
                        </Link>
                    }
                />
            )
        case 'default':
        case 'resolved':
            if (!supplier) {
                return null
            }
            return (
                <>
                    <PageHeader
                        ghost={false}
                        onBack={() => window.history.back()}
                        title={supplier.name}
                        extra={
                            onEdit
                                ? [
                                      <Button
                                          onClick={onEdit}
                                          type="default"
                                          disabled={!EDITABLE_STATUSES.includes(supplier.status)}
                                          key="edit-information-button"
                                      >
                                          Edit information
                                      </Button>,
                                  ]
                                : undefined
                        }
                    >
                        <DetailsContainer>
                            <Descriptions column={1} size="small">
                                <Descriptions.Item label="Status">
                                    <Text mark>{supplier.status}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="Bank name">
                                    <Text copyable={Boolean(supplier.bankAccounts?.[0]?.bankName)}>
                                        {supplier.bankAccounts?.[0]?.bankName ?? '‒'}
                                    </Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="Bank code">
                                    <Text copyable={Boolean(supplier.bankAccounts?.[0]?.bankCode)}>
                                        {supplier.bankAccounts?.[0]?.bankCode}
                                    </Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="Account no.">
                                    <Text
                                        copyable={Boolean(
                                            supplier.bankAccounts?.[0]?.accountNumber
                                        )}
                                    >
                                        {supplier.bankAccounts?.[0]?.accountNumber}
                                    </Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="IBAN">
                                    {supplier.bankAccounts?.[0]?.iban && (
                                        <Text copyable>{supplier.bankAccounts?.[0]?.iban}</Text>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions column={1} size="small">
                                <Descriptions.Item label="First amount">
                                    {supplier.firstInvoice?.amount &&
                                    supplier.firstInvoice?.currency
                                        ? formatCurrency(
                                              supplier.firstInvoice?.amount,
                                              supplier.firstInvoice?.currency,
                                              { currencyDisplay: 'code' }
                                          )
                                        : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label="VAT amount">
                                    {formatCurrency(0, 'GBP', { currencyDisplay: 'code' })}{' '}
                                    (unavailable)
                                </Descriptions.Item>
                                <Descriptions.Item label="Supplier ID">
                                    <Text copyable>{supplier.id}</Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="First invoice">
                                    {supplier.firstInvoice ? (
                                        <DownloadInvoiceButton invoice={supplier.firstInvoice} />
                                    ) : (
                                        '-'
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions column={1} size="small">
                                <Descriptions.Item label="BIC">
                                    <Text copyable={Boolean(supplier.bankAccounts?.[0]?.bic)}>
                                        {supplier.bankAccounts?.[0]?.bic ?? '‒'}
                                    </Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="VAT number">
                                    <Text copyable={!!supplier.vatNumber}>
                                        {supplier.vatNumber ?? '‒'}
                                    </Text>
                                </Descriptions.Item>
                                <Descriptions.Item label="Registry ID">
                                    <Text copyable={!!supplier.registryId}>
                                        {supplier.registryId ?? '‒'}
                                    </Text>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions column={1} size="small">
                                <Descriptions.Item label="Address">
                                    <InfoContainer>
                                        <Text>{supplier.address?.address1}</Text>
                                        <Text>{supplier.address?.address2}</Text>
                                        <Text>{`${supplier.address?.zipCode} ${supplier.address?.city}`}</Text>
                                        <Text>{`${supplier.address?.region}, ${supplier.address?.countryCode}`}</Text>
                                    </InfoContainer>
                                </Descriptions.Item>
                            </Descriptions>
                        </DetailsContainer>
                    </PageHeader>
                    <PageContentLayout>
                        <Card>
                            <Collapse defaultActiveKey={[canUpdateStatus ? 'status' : 'invoices']}>
                                {canUpdateStatus && (
                                    <Collapse.Panel header="Status" key="status">
                                        <StatusForm />
                                    </Collapse.Panel>
                                )}
                                <Collapse.Panel header="Previous Invoices" key="invoices">
                                    <InvoicesList supplierId={supplier.id} />
                                </Collapse.Panel>
                            </Collapse>
                        </Card>
                    </PageContentLayout>
                </>
            )
    }
}

const DetailsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: ${spacing.space24};
    .ant-descriptions * {
        width: auto;
    }
`

const InfoContainer = styled.div`
    display: grid;
`

export const useSupplier = (supplierId: string) => {
    const dispatch = useDispatch()
    const supplier = useSelector(supplierSelectors.selectSupplier)
    const status = useSelector(supplierSelectors.selectSupplierStatus)

    useEffect(() => {
        dispatch(supplierActions.fetchSupplier(supplierId))
        return () => {
            dispatch(supplierActions.clearSupplier())
        }
    }, [dispatch, supplierId])

    return {
        supplier,
        status,
        dispatch,
    }
}
