import { Table } from 'antd'
import type { ProofOfFundsBasicResponse } from 'bff/moons/generated/styx-v1'
import type { FC } from 'react'

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
    },
    {
        title: 'Sender Bank',
        dataIndex: 'senderBank',
        key: 'senderBank',
    },
    {
        title: 'Bank Name',
        dataIndex: 'bankName',
        key: 'bankName',
    },
    {
        title: 'Account Number',
        dataIndex: ['bankAccountInfo', 'accountNumber'],
        key: 'bankAccountInfo.accountNumber',
    },
    {
        title: 'Bank Code',
        dataIndex: ['bankAccountInfo', 'bankCode'],
        key: 'bankAccountInfo.bankCode',
    },
]

interface Props {
    data: ProofOfFundsBasicResponse[]
}

const ProofOfFundsSection: FC<Props> = ({ data }) => (
    <Table dataSource={data} columns={columns} pagination={false} size="small" bordered />
)

export default ProofOfFundsSection
