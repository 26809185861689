import dayjs from 'packages/dayjs'
import { useCallback, useState } from 'react'
import { buildFetcher, pandoraRequest } from 'services/request'
import useSWR from 'swr'
import type { TimelineResponse } from 'types/timeline'
import type { TimelineType } from './types'

interface TimelineData {
    label: string
    timestamp: string
    description?: string
}

interface TimelineSource {
    timelineData: TimelineData[]
    pagination: {
        count?: number
        current: number
        onChange: (newPage: number) => void
    }
}

const mapTimelineResponseToTimelineData = (timelineResponse: TimelineResponse): TimelineData[] =>
    timelineResponse.map((timelineItem) => ({
        label: timelineItem.engagement,
        timestamp: dayjs(timelineItem.engagement_at).format('L'),
        description: timelineItem.note,
    }))

export const useTimeline = (timelineType: TimelineType, id?: string | null): TimelineSource => {
    const [currentPage, setCurrentPage] = useState(1)

    const response = useSWR<TimelineResponse>(
        id ? `timeline/${timelineType}/${id}` : null,
        buildFetcher(pandoraRequest),
        { revalidateOnFocus: false }
    )

    const getMappedData = useCallback(() => {
        if (!response.data) return []
        return mapTimelineResponseToTimelineData(response.data).slice(
            (currentPage - 1) * 10,
            currentPage * 10
        )
    }, [response, currentPage])

    return {
        timelineData: getMappedData(),
        pagination: {
            count: response.data?.length,
            current: currentPage,
            onChange: (newPage: number) => setCurrentPage(newPage),
        },
    }
}
