import type { FC } from 'react'
import styled from 'styled-components'
import { inputWidth, spacing } from 'theme/tokens'
import { Button } from 'antd'
import { Input, Form } from 'formik-antd'
import { Formik } from 'formik'
import * as Yup from 'yup'

import type { Supplier } from 'types/deimos-supplier'
import type { CurrentStatus } from 'types/status'
import CountrySelect from 'components/country-select/country-select'
import { InvoicePDFPreview } from './invoice-pdf-preview'

const draftValidationSchema = Yup.object()
    .shape({
        name: Yup.string().required(),
        address1: Yup.string(),
        address2: Yup.string(),
        city: Yup.string(),
        region: Yup.string(),
        zipCode: Yup.string(),
        countryCode: Yup.string().matches(/^[A-Z]{2}$/, 'Please use valid country code, e.g. DK'),
        email: Yup.string(),
        vatNumber: Yup.string(),
        registryId: Yup.string(),
        iban: Yup.string(),
        bic: Yup.string(),
        bankCode: Yup.string(),
        accountNumber: Yup.string(),
    })
    .required()

type FormValues = Partial<Yup.InferType<typeof draftValidationSchema>>

interface EditDetailsFormProps {
    supplier: Supplier
    onSubmit: (values: FormValues) => void
    onCancel: () => void
    status: CurrentStatus
}

export const EditDetailsForm: FC<React.PropsWithChildren<EditDetailsFormProps>> = ({
    supplier,
    status,
    onSubmit,
    onCancel,
}) => {
    return (
        <ModalLayout hasFirstInvoice={Boolean(supplier.firstInvoice)}>
            {supplier.firstInvoice && <InvoicePDFPreview invoiceId={supplier.firstInvoice.id} />}
            <Formik
                enableReinitialize
                validationSchema={draftValidationSchema}
                onSubmit={onSubmit}
                initialValues={{
                    address1: supplier.address?.address1 || undefined,
                    address2: supplier.address?.address2,
                    city: supplier.address?.city,
                    region: supplier.address?.region,
                    zipCode: supplier.address?.zipCode,
                    countryCode: supplier.address?.countryCode,
                    name: supplier.name || undefined,
                    vatNumber: supplier.vatNumber || undefined,
                    email: supplier.email || undefined,
                    registryId: supplier.registryId || undefined,
                    iban: supplier.bankAccounts?.[0]?.iban || undefined,
                    bic: supplier.bankAccounts?.[0]?.bic || undefined,
                    bankCode: supplier.bankAccounts?.[0]?.bankCode || undefined,
                    accountNumber: supplier.bankAccounts?.[0]?.accountNumber || undefined,
                }}
            >
                {() => {
                    return (
                        <>
                            <FormLayout layout="vertical">
                                <Column>
                                    <Form.Item
                                        name="name"
                                        colon={false}
                                        hasFeedback
                                        required
                                        label="Name"
                                    >
                                        <Input name="name" placeholder="Name" />
                                    </Form.Item>
                                    <Form.Item
                                        name="address1"
                                        colon={false}
                                        hasFeedback
                                        label="Address line 1"
                                    >
                                        <Input name="address1" placeholder="Address line 1" />
                                    </Form.Item>
                                    <Form.Item
                                        name="address2"
                                        colon={false}
                                        hasFeedback
                                        label="Address line 2"
                                    >
                                        <Input name="address2" placeholder="Address line 2" />
                                    </Form.Item>
                                    <Form.Item
                                        name="city"
                                        colon={false}
                                        hasFeedback
                                        label="Locality"
                                    >
                                        <Input name="city" placeholder="Locality" />
                                    </Form.Item>
                                    <Inline>
                                        <Form.Item
                                            name="region"
                                            colon={false}
                                            hasFeedback
                                            label="Region"
                                        >
                                            <Input name="region" placeholder="Region" />
                                        </Form.Item>
                                        <Form.Item
                                            name="zipCode"
                                            colon={false}
                                            hasFeedback
                                            label="ZIP"
                                        >
                                            <Input name="zipCode" placeholder="Postal Code" />
                                        </Form.Item>
                                        <Form.Item
                                            name="countryCode"
                                            colon={false}
                                            hasFeedback
                                            label="Country"
                                        >
                                            <CountrySelect
                                                name="countryCode"
                                                placeholder="Country"
                                                style={{ minWidth: `${inputWidth.small}` }}
                                                showSearch
                                            />
                                        </Form.Item>
                                    </Inline>
                                    <Form.Item name="email" colon={false} hasFeedback label="Email">
                                        <Input name="email" placeholder="Email" />
                                    </Form.Item>
                                </Column>
                                <Column>
                                    <Form.Item
                                        name="vatNumber"
                                        colon={false}
                                        hasFeedback
                                        label="VAT number"
                                    >
                                        <Input name="vatNumber" placeholder="VAT number" />
                                    </Form.Item>
                                    <Form.Item
                                        name="registryId"
                                        colon={false}
                                        hasFeedback
                                        label="Registry ID"
                                    >
                                        <Input name="registryId" placeholder="Registry ID" />
                                    </Form.Item>
                                    <Form.Item name="iban" colon={false} hasFeedback label="IBAN">
                                        <Input name="iban" placeholder="IBAN" />
                                    </Form.Item>
                                    <Form.Item name="bic" colon={false} hasFeedback label="BIC">
                                        <Input name="bic" placeholder="BIC" />
                                    </Form.Item>
                                    <Inline>
                                        <Form.Item
                                            name="bankCode"
                                            colon={false}
                                            hasFeedback
                                            label="Bank code"
                                        >
                                            <Input
                                                name="bankCode"
                                                placeholder="Account bank code"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="accountNumber"
                                            colon={false}
                                            hasFeedback
                                            label="Acc. number"
                                        >
                                            <Input
                                                name="accountNumber"
                                                placeholder="Account number"
                                            />
                                        </Form.Item>
                                    </Inline>
                                </Column>
                                <ButtonContainer>
                                    <Button type="default" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        loading={status === 'fetching'}
                                        data-testid="submit"
                                    >
                                        Save
                                    </Button>
                                </ButtonContainer>
                            </FormLayout>
                        </>
                    )
                }}
            </Formik>
        </ModalLayout>
    )
}

const ModalLayout = styled.div<{ hasFirstInvoice: boolean }>`
    display: grid;
    grid-template-columns: ${(props) => (props.hasFirstInvoice ? '2fr 3fr' : '3fr 2fr')};
    gap: ${spacing.space32};
`
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${spacing.space8};
    grid-column: 1/3;
`
const FormLayout = styled(Form)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 ${spacing.space24};
`
const Column = styled.div``
const Inline = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${spacing.space24};
`

export default EditDetailsForm
