/**
 * Use invariant() to assert state which your program assumes to be true.
 * Use it only for cases where the condition should never be false, if the assumptions holds.
 * It ensures that a given condition is truthy. If it is not, an InvariantError is thrown.
 * It also correctly narrows the TS type for the asserted variable.
 *
 * The invariant message will be stripped in production, but the invariant
 * will remain to ensure logic does not differ in production.
 *
 * @param condition - The condition to be checked.
 * @param message - Message explaining your assumption.
 * @throws InvariantError when the condition is not true.
 * @example
 * // The following will not throw an error:
 * invariant(2 + 2 === 4, 'Math should work')
 * // The following will throw an error with the specified message:
 * invariant(2 + 2 === 5, 'Math should work')
 * @example
 * const value: Person | null = { name: 'Alex' };
 * invariant(value, 'Expected value to be a person');
 * // type of value has been narrowed to 'Person'
 * @returns {void}
 */
export function invariant(condition: unknown, message: string): asserts condition {
    if (condition) {
        return
    }

    // In production we strip the message but still throw
    if (process.env.NODE_ENV === 'production') {
        throw new InvariantError()
    }

    throw new InvariantError(message)
}

export class InvariantError extends Error {
    constructor(message?: string) {
        super(message)
        Object.setPrototypeOf(this, new.target.prototype)

        this.name = 'InvariantError'
    }
}
