import type { FC } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { Form, Select } from 'formik-antd'
import { Button } from 'antd'

const { Option } = Select

const validationSchema = Yup.object()
    .shape({
        inScope: Yup.string(),
    })
    .required()

type FormValues = Yup.InferType<typeof validationSchema>

interface Props {
    loading: boolean
    onSubmit: (values: FormValues) => void
}

const InScopeFilterForm: FC<React.PropsWithChildren<Props>> = ({ onSubmit, loading }) => {
    return (
        <Formik validationSchema={validationSchema} initialValues={{}} onSubmit={onSubmit}>
            {({ resetForm }) => (
                <Form layout="vertical">
                    <Form.Item name="inScope" label="Person in scope" hasFeedback colon={false}>
                        <Select
                            name="inScope"
                            data-testid="in-scope-select"
                            placeholder="Is person in scope?"
                        >
                            <Option key="true" value="true" data-testid={`in-scope-yes`}>
                                Yes
                            </Option>
                            <Option key="false" value="false" data-testid={`in-scope-no`}>
                                No
                            </Option>
                        </Select>
                    </Form.Item>
                    <Row>
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            data-testid="search-button"
                        >
                            Search
                        </Button>
                        <Button
                            type="default"
                            onClick={() => resetForm()}
                            data-testid="clear-button"
                        >
                            Clear
                        </Button>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}

const Row = styled.div`
    display: flex;
    gap: 0.5rem;
`

export default InScopeFilterForm
