import type { FormValuesProfile } from '../types'
import { useFormikContext } from 'formik'
import { merge } from 'lodash'

const useUpdateProfileFormValues = (entity: keyof FormValuesProfile) => {
    const { values, setValues } = useFormikContext<FormValuesProfile>()

    const updateProfileEntity = <T>(updatedEntity: T) => ({
        ...values,
        [entity]: merge(values[entity], updatedEntity, {}),
    })

    const updateProperty = (updatedProperty: {}) => {
        setValues(updateProfileEntity(updatedProperty))
    }

    return {
        updateProperty,
    }
}
export default useUpdateProfileFormValues
