import type { FC } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover, Typography } from 'antd'
import styled from 'styled-components'

import { RiskTypes, RiskScore, ResultScore, RiskTypesV2 } from 'types/styx'
import { deSnakify } from 'utils/strings'

const { Paragraph, Text } = Typography

export const riskTypeTipsMap = {
    [RiskTypes.BUSINESS_PRODUCTS_OR_SERVICES]: {
        [RiskScore.LOW]:
            'The customer provides a service that can be validated from at least 3 independent sources (not from the customer itself)',
        [RiskScore.MEDIUM_LOW]:
            'The customer provides a service that can be validated from at least 1 independent source (not from the customer itself)',
        [RiskScore.MEDIUM_HIGH]:
            'The customer provides a services that can not be validated from any independent source OR their service is considered a high risk service (Online gambling or casinos, adult materials, products, or services, Forex trading)',
    },
    [RiskTypes.FUNDING_SOURCES]: {
        [RiskScore.LOW]: 'Wire transfer',
        OTHER: "at the moment we don't support other funding options",
    },
    [RiskTypes.COMPANY_JURISDICTION]: {
        [RiskScore.LOW]: 'Company is not from a prohibited risk jurisdiction',
        [RiskScore.HIGH]: 'Company IS from a prohibited risk jurisdiction',
    },
    [RiskTypes.CASH_FLOW]: {
        [RiskScore.LOW]: 'Cash & Cash equivalent in % of total Balance > 10%',
        [RiskScore.MEDIUM_LOW]: 'Cash & Cash equivalent in % of total Balance < 10%',
    },
    [RiskTypes.END_USER_VERIFICATION]: {
        [RiskScore.LOW]: 'In person, video call or phone call',
        [RiskScore.MEDIUM_LOW]: 'Email, other channels in writing',
        [RiskScore.MEDIUM_HIGH]: 'Full online/self service signup flow',
    },
    [RiskTypes.INCORPORATED_DATE]: {
        [RiskScore.LOW]: '> 2 years ago',
        [RiskScore.MEDIUM_LOW]: '< 2 years ago',
    },
    [RiskTypes.OWNERSHIP_STRUCTURE]: {
        [RiskScore.LOW]:
            '5 or fewer direct or indirect owners, only Danish entity/private individual owners',
        [RiskScore.MEDIUM_LOW]:
            '5 or more direct or indirect owners and/or non-Danish entity/private individual owners',
        [RiskScore.HIGH]: 'Entity/private individual owners from prohibited jurisdictions',
    },
    [RiskTypes.PEP_AND_SANCTIONS]: {
        [ResultScore.FAIL]:
            'A company is marked as a FAIL if one or more Directors or UBOs is listed as PEP or Sanctions.',
    },
    [RiskTypes.SALES_CHANNEL]: {
        [RiskScore.LOW]:
            'A lead through a known personal reference or known referral OR Sales through an in person / phone / video meeting',
        [RiskScore.MEDIUM_LOW]:
            'Unknown where lead is coming from BUT contact in writing to qualify the lead, however NO in person/video sales meeting',
        [RiskScore.MEDIUM_HIGH]:
            'Unknown where the lead is coming from and NO contact in writing or in person/video sales meeting',
    },
    [RiskTypes.SUPPORT_CHANNEL]: {
        [RiskScore.LOW]: 'Personal meeting, Video or Phone',
        [RiskScore.MEDIUM_LOW]: 'E-mail/chat only',
    },
    [RiskTypes.UBOS_AND_DIRECTOR_ORIGIN_COUNTRY]: {
        [RiskScore.LOW]: 'EU',
        [RiskScore.MEDIUM_LOW]: 'Outside EU',
        [RiskScore.HIGH]: 'Prohibited list',
    },
    [RiskTypesV2.ADVERSE_MEDIA_COMPANY_KYC_CHECKS]: '',
    [RiskTypesV2.COMPANY_LEGAL_FORM]: '',
    [RiskTypesV2.COMPANY_LEGAL_NAME_CHANGE]: '',
    [RiskTypesV2.COMPANY_SANCTIONS]: '',
    [RiskTypesV2.COMPLEX_OWNERSHIP_AND_CONTROL_STRUCTURE]: '',
    [RiskTypesV2.COUNTRY_COMPANY_INCORPORATION]: '',
    [RiskTypesV2.COUNTRY_RELATED_PERSONS_ADDRESS]: '',
    [RiskTypesV2.DATE_COMPANY_INCORPORATION]: '',
    [RiskTypesV2.INDUSTRY_CODE_COMPANY_INDUSTRIES]: '',
    [RiskTypesV2.PERSON_PEP_CHECK]: '',
    [RiskTypesV2.PO_HIGHER_RISK_COUNTRY]: '',
    [RiskTypesV2.UBO_ADDRESS]: '',
    [RiskTypesV2.PARENT_PERSON_PEP_CHECK]: '',
    [RiskTypesV2.PERSON_PEP_CORPORATE_CHECK]: '',
    [RiskTypesV2.PERSON_PEP_POSITION_CHECK]: '',
    [RiskTypesV2.PERSON_PEP_GEOGRAPHY_CHECK]: '',
}

const RiskCheckTip: FC<React.PropsWithChildren<{ riskType: RiskTypes | RiskTypesV2 }>> = ({
    riskType,
}) => {
    const riskTypeTips = riskTypeTipsMap[riskType]
    const riskLevels = Object.keys(riskTypeTips)

    return (
        <Popover
            title={deSnakify(riskType)}
            content={riskLevels.map((riskLevel) => {
                const description = riskTypeTips[riskLevel as keyof typeof riskTypeTips]
                return (
                    <PopoverContent key={riskLevel}>
                        <Text strong>{riskLevel}: </Text>
                        <Paragraph>{description}</Paragraph>
                    </PopoverContent>
                )
            })}
        >
            <InfoCircleOutlined data-testid="info" />
        </Popover>
    )
}

const PopoverContent = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
`

export default RiskCheckTip
