import { OrganizationEntity, useOrganizationById } from 'services/oberon/organization'
import { TreeSelect } from 'antd'
import { getEmojiFlag } from 'countries-list'
import { useState } from 'react'

export const RelatedByOrganization = ({
    organizationId,
    entityId,
    onChangeEntity,
    treeSelectProps,
}: {
    treeSelectProps?: {
        size?: 'large' | 'middle' | 'small'
        bordered?: boolean
        placeholder?: string
    }
    organizationId?: string
    entityId?: string
    onChangeEntity?: (entityId: string) => void
}) => {
    const [value, setValue] = useState(entityId)
    const { data, error } = useOrganizationById(organizationId)

    if (!data && !error) {
        return null
    }

    const onChange = (newValue: string) => {
        if (organizationId === newValue || entityId === newValue) {
            return
        }
        setValue(newValue)
        onChangeEntity?.(newValue)
    }

    return (
        <TreeSelect
            style={{ minWidth: 250, maxWidth: '100%' }}
            bordered={treeSelectProps?.bordered}
            placeholder={treeSelectProps?.placeholder ?? 'View organization tree'}
            dropdownMatchSelectWidth={false}
            treeLine={{ showLeafIcon: false }}
            size={treeSelectProps?.size ?? 'middle'}
            treeDefaultExpandAll
            onChange={onChange}
            value={value}
            treeData={[
                {
                    title: `Org: ${data?.name}`,
                    value: data?.id,
                    children: data?.companies.map((cmp: OrganizationEntity) => ({
                        title: `${getEmojiFlag(cmp.address.country)} ${cmp.name}`,
                        value: cmp.companyId,
                    })),
                },
            ]}
        />
    )
}
