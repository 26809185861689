import Loading from './loading-result/loading-result'
import ErrorResult from './error-result/error-result'
import AccountDetails from './account-details/account-details'
import type { EmployeeResponse, CompanyResponse } from 'types/demo-accounts'
import { Tabs } from 'antd'
import { ContentContainer } from 'components/layout-containers'
import type { FormValuesBasic, FormValuesExtended } from './generate-demo-form'
import {
    GenerateBasicDemoForm,
    GenerateExtendedDemoForm,
    GenerateProfileDemoForm,
} from './generate-demo-form'

const { TabPane } = Tabs

interface Props {
    accountDetails: EmployeeResponse | null
    companyDetails: CompanyResponse | null
    error: Error | null
    goBack: () => void
    loading: boolean
    onSubmitExtended: (values: FormValuesExtended) => Promise<void>
    onSubmitBasic: (values: FormValuesBasic) => Promise<void>
}

const GenerateDemo = ({
    accountDetails,
    companyDetails,
    error,
    goBack,
    loading,
    onSubmitExtended,
    onSubmitBasic,
}: Props) => {
    if (accountDetails) {
        return (
            <AccountDetails
                email={accountDetails.email}
                passcode={accountDetails.passcode}
                companyId={companyDetails?.id}
            />
        )
    }

    if (error) {
        return <ErrorResult goBack={goBack} errorMessage={error.message} />
    }

    if (loading) {
        return <Loading />
    }
    return (
        <ContentContainer>
            <Tabs>
                <TabPane tab="Extended" key="extended">
                    <GenerateExtendedDemoForm onSubmit={onSubmitExtended} />
                </TabPane>
                <TabPane tab="Advanced (profile)" key="profile">
                    <GenerateProfileDemoForm />
                </TabPane>
                <TabPane tab="Basic (deprecated)" key="basic">
                    <GenerateBasicDemoForm onSubmit={onSubmitBasic} />
                </TabPane>
            </Tabs>
        </ContentContainer>
    )
}

export default GenerateDemo
