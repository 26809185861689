import React, { FC, useEffect } from 'react'
import { Link, useParams, Navigate, useNavigate, Route } from 'react-router-dom'
import { Badge } from 'antd'
import {
    CheckSquareOutlined,
    MonitorOutlined,
    PropertySafetyOutlined,
    GlobalOutlined,
    UserOutlined,
    InteractionOutlined,
    ShopOutlined,
    CrownOutlined,
    BankOutlined,
    AlertOutlined,
    AccountBookOutlined,
    RollbackOutlined,
    CloseCircleOutlined,
    SettingOutlined,
    ScheduleOutlined,
} from '@ant-design/icons'

import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { SearchHeaderSelect, SearchHeaderInput } from 'components/search-header/search-header'
import ProtectedRoute from 'router/protected-route'

import ViewExpense from 'components/view-expense/view-expense'
import Page from 'pages/page-layout'
import type { Company as ICompany } from 'types/deimos-company'

import Companies from './companies/companies'
import Company from './company/company'
import ProofOfFunds from './proof-of-funds/proof-of-funds'
import PersonSearch from './person-search/person-search'
import Chargebacks from './chargebacks/chargebacks'
import Aml from './transaction-monitoring/aml/aml'
import Fraud from './transaction-monitoring/fraud/fraud'
import AmlTransactionList from './transaction-monitoring/aml/transaction-list/transaction-list'
import FraudTransactionList from './transaction-monitoring/fraud/transaction-list/transaction-list'
import Merchants from './merchants/merchants'
import Suppliers from './suppliers/suppliers'
import Supplier from './supplier/supplier'
import EmployeeBankTransfers from './employee-bank-transfers/employee-bank-transfers'
import EmployeeBankTransfer from './employee-bank-transfers/employee-bank-transfer'
import WalletUnloads from './wallet-unloads/wallet-unloads'
import { useSearchCompanies } from 'services/deimos/companies'
import ClosedCompaniesListContainer from 'components/closed-companies/closed-companies-list'
import ClosedCompanyContainer from './closed-company/closed-company'
import CrrVersions from './crr-configuration/crr-versions'
import CrrConfiguration from './crr-configuration/crr-configuration'
import Requests from './requests/requests'
import { usePendingOrganizationRequestActionsCount } from 'services/oberon/organization-request'
import { SideMenu } from 'pages/page-layout-components'
import { useColorScheme } from 'hooks/use-color-scheme'
import { oddRoutes } from './odd/routes'

const Navigation: FC<React.PropsWithChildren<unknown>> = () => {
    const { id } = useParams()
    const hasPhobosPermissions = useHasPermissions(['phobos'])
    const hasStyxPermission = useHasPermissions(['styx'])
    const hasArielAmlPermissions = useHasPermissions(['ariel:aml'])
    const hasArielFraudPermissions = useHasPermissions(['ariel:card'])
    const hasArielPermissions = useHasPermissions(['ariel'])
    const hasMetisBillsPermissions = useHasPermissions(['metis:invoices'])
    const hasCupidPermissions = useHasPermissions(['cupid'])
    const hasManageCrr = useHasPermissions(['manage-risk-factors'])
    const hasOddPermissions = useHasPermissions(['case-manager-odd'])
    const { colorScheme } = useColorScheme()

    const hasManageOrganizationRequestPermissions = useHasPermissions([
        'oberon:organization-requests',
    ])

    const organizatioRequestCount = usePendingOrganizationRequestActionsCount({
        bypassHook: !hasManageOrganizationRequestPermissions,
    })

    return (
        <SideMenu $colorScheme={colorScheme} selectedKeys={[id]} theme="dark">
            {hasStyxPermission && (
                <SideMenu.Item key="companies">
                    <GlobalOutlined />
                    <span>Companies</span>
                    <Link to="../companies" />
                </SideMenu.Item>
            )}
            {hasOddPermissions && (
                <SideMenu.Item key="odd">
                    <CheckSquareOutlined />
                    <span>ODD</span>&nbsp;&nbsp;
                    <Link to="../odd" />
                </SideMenu.Item>
            )}
            {hasStyxPermission && (
                <SideMenu.Item key="closed-companies">
                    <CloseCircleOutlined />
                    <span>Closed companies</span>
                    <Link to="../closed-companies" />
                </SideMenu.Item>
            )}
            {hasStyxPermission && (
                <SideMenu.Item key="proof-of-funds">
                    <PropertySafetyOutlined />
                    <span>Proof of Funds</span>
                    <Link to="../proof-of-funds" />
                </SideMenu.Item>
            )}
            {hasStyxPermission && (
                <SideMenu.Item key="person-search">
                    <UserOutlined />
                    <span>Person Search</span>
                    <Link to="../person-search" />
                </SideMenu.Item>
            )}
            <SideMenu.Item key="view-expense">
                <AccountBookOutlined />
                <span>View Expense</span>
                <Link to="../view-expense" />
            </SideMenu.Item>
            {hasArielPermissions && (
                <SideMenu.Item key="merchants">
                    <ShopOutlined />
                    <span>Merchants</span>
                    <Link to="../merchants" />
                </SideMenu.Item>
            )}
            {hasPhobosPermissions && (
                <SideMenu.Item key="chargebacks">
                    <InteractionOutlined />
                    <span>Chargebacks</span>
                    <Link to="../chargebacks" />
                </SideMenu.Item>
            )}
            {hasArielAmlPermissions && (
                <SideMenu.Item key="aml-transaction-monitoring">
                    <MonitorOutlined />
                    <span>AML</span>
                    <Link to="../aml-transaction-monitoring" />
                </SideMenu.Item>
            )}
            {hasArielFraudPermissions && (
                <SideMenu.Item key="fraud-transaction-monitoring">
                    <AlertOutlined />
                    <span>Fraud</span>
                    <Link to="../fraud-transaction-monitoring" />
                </SideMenu.Item>
            )}
            {hasMetisBillsPermissions && (
                <SideMenu.Item key="suppliers">
                    <CrownOutlined />
                    <span>Suppliers</span>
                    <Link to="../suppliers" />
                </SideMenu.Item>
            )}
            <SideMenu.Item key="reimbursements">
                <BankOutlined />
                <span>Reimbursements</span>
                <Link to="../reimbursements" />
            </SideMenu.Item>
            {hasCupidPermissions && (
                <SideMenu.Item key="wallet-unloads">
                    <RollbackOutlined />
                    <span>Wallet Unloads</span>
                    <Link to="../wallet-unloads" />
                </SideMenu.Item>
            )}
            {hasManageCrr && (
                <SideMenu.Item key="crr-configuration">
                    <SettingOutlined />
                    <span>CRR configuration</span>
                    <Link to="../crr-configuration" />
                </SideMenu.Item>
            )}
            {hasManageOrganizationRequestPermissions && (
                <SideMenu.Item key="requests">
                    <ScheduleOutlined />
                    <span>Requests</span>&nbsp;&nbsp;
                    {organizatioRequestCount?.data?.total > 0 && (
                        <Badge count={organizatioRequestCount?.data?.total} />
                    )}
                    <Link to="../requests" />
                </SideMenu.Item>
            )}
        </SideMenu>
    )
}

const useHandleRedirect = () => {
    const hasStyxPermission = useHasPermissions(['styx'])
    const hasMetisBillsPermissions = useHasPermissions(['metis:invoices'])

    if (hasStyxPermission) {
        return 'companies'
    } else if (hasMetisBillsPermissions) {
        return 'suppliers'
    } else {
        return '/customer-success'
    }
}

const CompliancePage: FC<React.PropsWithChildren<unknown>> = () => {
    const redirectPath = useHandleRedirect()
    const navigate = useNavigate()
    const { companies, companyById, setQuery, setCountries, registerInStyx } = useSearchCompanies()

    const navigateToCompany = ({ id, styxId }: ICompany) => {
        if (styxId) {
            navigate(`companies/${id}`)
        }
    }

    const onSearchChange = async (companyId: string) => {
        const company = companies?.find(({ id }) => id === companyId)

        if (company && !company.styxId) {
            await registerInStyx(companyId)
        } else if (company) {
            navigateToCompany(company)
        }
    }

    useEffect(() => {
        if (companyById) {
            navigate(`companies/${companyById.id}`)
            setQuery('')
        }
    }, [companyById, navigate, setQuery])

    return (
        <Page
            sideBarLabel="Compliance"
            sideBar={
                <Route element={<ProtectedRoute />}>
                    <Route path=":id/*" element={<Navigation />} />
                </Route>
            }
            header={
                <Route element={<ProtectedRoute />}>
                    <Route path="companies/*" element={<SearchHeaderInput onSearch={setQuery} />} />
                    <Route
                        path="companies/:companyId/*"
                        element={
                            <SearchHeaderSelect
                                onSearch={setQuery}
                                companies={companies}
                                onSearchChange={onSearchChange}
                            />
                        }
                    />
                    <Route
                        path="closed-companies/*"
                        element={<SearchHeaderInput onSearch={setQuery} />}
                    />
                    <Route
                        path=":id/*"
                        element={
                            <SearchHeaderSelect
                                onSearch={setQuery}
                                companies={companies}
                                onSearchChange={onSearchChange}
                            />
                        }
                    />
                </Route>
            }
        >
            <Route element={<ProtectedRoute />}>
                <Route path="*" element={<Navigate to={redirectPath} />} />
                <Route
                    path="companies/*"
                    element={
                        <Companies
                            companies={companies}
                            registerInStyx={registerInStyx}
                            onRowClick={navigateToCompany}
                            setCountries={setCountries}
                        />
                    }
                />
                <Route path="companies/:id/*" element={<Company />} />
                <Route path="closed-companies/*" element={<ClosedCompaniesListContainer />} />
                <Route path="closed-companies/:id/*" element={<ClosedCompanyContainer />} />
                <Route path="proof-of-funds" element={<ProofOfFunds />} />
                <Route path="person-search" element={<PersonSearch />} />
                <Route path="merchants" element={<Merchants />} />
                <Route path="chargebacks" element={<Chargebacks />} />
                <Route path="view-expense" element={<ViewExpense />} />
                <Route path="aml-transaction-monitoring/*" element={<Aml />} />
                <Route path="aml-transaction-monitoring/:id" element={<AmlTransactionList />} />
                <Route path="fraud-transaction-monitoring/*" element={<Fraud />} />
                <Route path="fraud-transaction-monitoring/:id" element={<FraudTransactionList />} />
                <Route path="suppliers/*" element={<Suppliers />} />
                <Route path="suppliers/:id" element={<Supplier />} />
                <Route path="requests/*" element={<Requests />} />
                <Route path="reimbursements/*" element={<EmployeeBankTransfers />} />
                <Route path="reimbursements/:id" element={<EmployeeBankTransfer />} />
                <Route
                    path="employee-bank-transfers/*"
                    element={<Navigate to="/compliance/reimbursements" replace />}
                />
                <Route path="employee-bank-transfers/:id" element={<EmployeeBankTransfer />} />
                <Route path="wallet-unloads" element={<WalletUnloads />} />
                <Route path="crr-configuration" element={<CrrVersions />} />
                <Route path="crr-configuration/:id" element={<CrrConfiguration />} />
                <Route path="odd">{oddRoutes}</Route>
            </Route>
        </Page>
    )
}

export default CompliancePage
