import { Buffer } from 'buffer'

export const useMetabaseTransactionLink = (transactionId?: string) => {
    const query = {
        dataset_query: {
            query: {
                'source-table': 4471,
                filter: ['and', ['=', ['field', 69666, null], transactionId]],
            },
            type: 'query',
            database: 42,
        },
        display: 'table',
        visualization_settings: {},
    }

    const queryAsString = JSON.stringify(query)
    const encodedString = Buffer.from(queryAsString).toString('base64')

    return `https://metabase.pleo.io/question#${encodedString}`
}
