import useSWR from 'swr'
import { buildFetcher, deimosRequest } from 'services/request'
import { useSearchParams } from 'react-router-dom'
import type { Company } from 'bff/moons/generated/caliban'

export interface GetCompaniesResponse {
    data?: Company[]
    pagination: {
        total: number
        offset: number
        limit: number
    }
}

export const useGetCompanies = () => {
    const [searchParams] = useSearchParams()

    let builtQuery = ''
    for (const [key, value] of searchParams) {
        if (key !== 'limit' && key !== 'offset') {
            builtQuery += `field_values=${key},${value}&`
        }
    }

    const limit = searchParams.get('limit')
    const offset = searchParams.get('offset')

    const url = `rest/v1/search/companies?limit=${limit}&offset=${offset}&${builtQuery}`
    return useSWR<GetCompaniesResponse, Error>(
        searchParams && builtQuery ? url : null,
        buildFetcher(deimosRequest)
    )
}
