import type { FC } from 'react'
import { Typography } from 'antd'
import { FilePdfOutlined } from '@ant-design/icons'
import { getInvoicePdf } from 'services/beyond/pdf'

const { Link } = Typography

interface InvoiceLinkProps {
    invoiceId: string
}

export const InvoiceLink: FC<React.PropsWithChildren<InvoiceLinkProps>> = ({ invoiceId }) => {
    const fetcher = async () => {
        const result = await getInvoicePdf(invoiceId).blob()

        const blob = new Blob([result], { type: 'application/pdf;charset=UTF-8' })
        return window.URL.createObjectURL(blob)
    }

    const handleOpen = async () => {
        const url = await fetcher()

        window.open(url)
    }

    return (
        <Link onClick={async () => handleOpen()}>
            <FilePdfOutlined /> Invoice
        </Link>
    )
}
