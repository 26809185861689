import React, { FC, useState } from 'react'
import { Button, Form, Card, Input, Space, Collapse } from 'antd'
import { deSnakify } from 'utils/strings'
import _ from 'lodash'
import styled from 'styled-components'
import CrrKvFactor from './crr-kv-factor'
import { RiskFactorSettings, RiskScoreFormat } from 'types/customer-risk-rating'
import CrrRangeFactor from './crr-range-factor'
import { spacing } from 'theme/tokens'
import Spinner from 'components/content-spinner'
import { useParams } from 'react-router-dom'

interface CrrFactorProps {
    factorSettings: RiskFactorSettings
    updateFactor: (settings: RiskFactorSettings) => Promise<void>
    isActiveVersion: boolean
}

const StyledSpace = styled(Space)`
    display: flex;
    width: 100;
    justify-items: right;
    margin-bottom: ${spacing.space24};
`

const { Panel } = Collapse

const CrrFactor: FC<React.PropsWithChildren<CrrFactorProps>> = ({
    factorSettings,
    updateFactor,
    isActiveVersion,
}) => {
    const [modifiedSettings, setModifiedSettings] = useState(_.cloneDeep(factorSettings))
    const [isModified, setIsModified] = useState(false)
    const { id: versionId } = useParams()

    function discardChanges() {
        setModifiedSettings(_.cloneDeep(factorSettings))
        setIsModified(false)
    }

    function submitChanges() {
        updateFactor(_.cloneDeep(modifiedSettings))
        setIsModified(false)
    }

    function weightModified(newWeight: number) {
        setModifiedSettings(_.cloneDeep({ ...modifiedSettings, weight: newWeight }))
        setIsModified(true)
    }

    function displayRiskFactor(settings: RiskFactorSettings) {
        switch (settings.riskScoreFormat) {
            case RiskScoreFormat.KEY_VALUE:
                return (
                    <CrrKvFactor
                        key={`${settings.riskScoreFormat}-${settings.id}`}
                        type={settings.riskScoreType}
                        versionId={versionId}
                        isActiveVersion={isActiveVersion}
                    />
                )

            case RiskScoreFormat.RANGE:
                return (
                    <CrrRangeFactor
                        key={`${settings.riskScoreFormat}-${settings.id}`}
                        type={settings.riskScoreType}
                        versionId={versionId}
                        isActiveVersion={isActiveVersion}
                    />
                )

            default:
                return <Spinner />
        }
    }

    return (
        <Card title={deSnakify(modifiedSettings.name)} size="small">
            <Form>
                <Form.Item label={'Weight'} colon>
                    <Input
                        min={0}
                        max={100}
                        type="number"
                        data-testid="weight-value"
                        style={{ maxWidth: '12ch' }}
                        value={modifiedSettings.weight}
                        onChange={(e) => weightModified(Number(e.target.value))}
                        disabled={isActiveVersion}
                    />
                </Form.Item>

                {isModified && (
                    <StyledSpace>
                        <Button style={{ marginLeft: 'auto' }} onClick={discardChanges}>
                            Discard
                        </Button>
                        <Button onClick={submitChanges} type="primary">
                            Submit
                        </Button>
                    </StyledSpace>
                )}
            </Form>
            <Collapse accordion>
                <Panel
                    header={`View / edit scores (${deSnakify(factorSettings.riskScoreType)})`}
                    key="1"
                >
                    {displayRiskFactor(factorSettings)}
                </Panel>
            </Collapse>
        </Card>
    )
}

export default CrrFactor
