import type { CountryRiskScore } from './styx'
import type { CountryCode } from 'bff/moons/generated/metis-v1'

export enum RiskScoreType {
    COUNTRY_CODE = 'COUNTRY_CODE',
    CONTINENT = 'CONTINENT',
    DOMAIN = 'DOMAIN',
    INDUSTRY_CODE = 'INDUSTRY_CODE',
    ADVERSE_MEDIA = 'ADVERSE_MEDIA',
    USERS = 'USERS',
    YEARS = 'YEARS',
    ADDRESS = 'ADDRESS',
    PEP = 'PEP',
}

export enum RiskScoreFormat {
    KEY_VALUE = 'KEY_VALUE',
    RANGE = 'RANGE',
}

export enum RiskFactorCategory {
    INDUSTRY_CODE = 'INDUSTRY_CODE',
    COUNTRY = 'COUNTRY',
    CUSTOMER = 'CUSTOMER',
    CHANNEL = 'CHANNEL',
    OVERRIDE = 'OVERRIDE',
    KYC_CHECK = 'KYC_CHECK',
}

export interface RiskFactorSettings {
    id: string
    name: string
    category: string
    weight: number
    defaultScore: number
    riskScoreType: RiskScoreType
    riskScoreFormat: RiskScoreFormat
}

export interface RangeRiskScore {
    id: string
    type: RiskScoreType
    lowerBound: number
    upperBound: number
    score: number
    overriding: boolean
}

export interface KVRiskScore {
    id: string
    type: RiskScoreType
    key: string
    score: number
    overriding: boolean
    riskVersionId?: string
}

export interface RiskVersion {
    id: string
    description: string
    createdBy?: string
    createdAt?: string
    updatedAt?: string
    isActive?: boolean
}

export interface RiskScoreRow {
    companyId: string
    [key: string]: string
}

export interface RiskCharts {
    scores?: ((string | RiskVersion)[] | React.ReactText[])[]
    versions?: (string | number)[][]
}

export interface RiskScores {
    companyId: string
    riskScoreResults: {
        [key: string]: string
    }
}

export interface CountryWithRating {
    country: string
    rating: CountryRiskScore
}

export interface KycEstimate {
    country: CountryCode
    segment: string
    riskRating: string
    days: number
}
