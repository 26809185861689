import type { OnboardingSource } from 'types/deimos-company'
import { WalletUnloadResponseStatus } from 'bff/moons/generated/cupid-v1'

import { useWalletUnloadsFiltered } from './use-wallet-unloads-filtered'
import type { WalletUnloadsStatusDisplay } from './utils'

type WalletUnloadsTabsCount = {
    [key in WalletUnloadsStatusDisplay]: number
}

export const useWalletUnloadsCount = (filters: {
    countryFilter: string[]
    onboardingViaFilter: OnboardingSource[]
}) => {
    const { data: walletUnloadsNew } = useWalletUnloadsFiltered(
        WalletUnloadResponseStatus.NEW,
        filters
    )
    const { data: walletUnloadsInvestigation } = useWalletUnloadsFiltered(
        WalletUnloadResponseStatus.UNDER_INVESTIGATION,
        filters
    )
    const { data: walletUnloadsUnloaded } = useWalletUnloadsFiltered(
        WalletUnloadResponseStatus.UNLOADED,
        filters
    )
    const { data: walletUnloadsCancelled } = useWalletUnloadsFiltered(
        WalletUnloadResponseStatus.CANCELLED,
        filters
    )
    const { data: walletUnloadsExported } = useWalletUnloadsFiltered(
        WalletUnloadResponseStatus.EXPORTED,
        filters
    )
    const { data: walletUnloadsFailed } = useWalletUnloadsFiltered(
        WalletUnloadResponseStatus.FAILED,
        filters
    )

    if (
        !walletUnloadsNew ||
        !walletUnloadsInvestigation ||
        !walletUnloadsUnloaded ||
        !walletUnloadsCancelled ||
        !walletUnloadsExported ||
        !walletUnloadsFailed
    ) {
        return {
            New: 0,
            Investigation: 0,
            Unloaded: 0,
            Rejected: 0,
            Exported: 0,
            Failed: 0,
        } as WalletUnloadsTabsCount
    }

    return {
        New: walletUnloadsNew?.length,
        Investigation: walletUnloadsInvestigation.length,
        Unloaded: walletUnloadsUnloaded.length,
        Rejected: walletUnloadsCancelled.length,
        Exported: walletUnloadsExported.length,
        Failed: walletUnloadsFailed.length,
    } as WalletUnloadsTabsCount
}
