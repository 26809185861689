import { Tag, Tooltip } from 'antd'
import type { IndustryCode } from 'bff/moons/generated/styx-v1'
import type { FC } from 'react'

interface Props {
    naceCode: Partial<IndustryCode>
}

const NaceCode: FC<Props> = ({ naceCode }) => {
    return (
        <Tooltip placement="bottom" title={naceCode.description ?? 'Unknown'}>
            <Tag key={naceCode.code} color="blue">
                {naceCode.code ?? 'Unknown'}
            </Tag>
        </Tooltip>
    )
}

export default NaceCode
