import { Select } from 'antd'
import { FormItem } from 'formik-antd'
import type { FC } from 'react'

type AdvancedSearchSelectProps = {
    label: string
    name: string
    onChange: (value: any, fieldName: string) => void
    options: { label: string; value: string }[]
    testId?: string
    widthOverride?: number
}

const AdvancedSearchSelect: FC<AdvancedSearchSelectProps> = ({
    label,
    name,
    onChange,
    options,
    testId,
    widthOverride,
}: AdvancedSearchSelectProps) => {
    return (
        <FormItem
            label={label}
            name={name}
            style={{ width: widthOverride ?? 200, margin: '0 10px' }}
        >
            <Select
                data-testid={testId ?? `advanced-search-select-${name}`}
                placeholder={label}
                allowClear
                options={options}
                onChange={(value) => {
                    onChange(value, name)
                }}
            />
        </FormItem>
    )
}

export default AdvancedSearchSelect
