import type { PayloadAction } from '@reduxjs/toolkit'
import { notification } from 'antd'
import { call, put, takeLatest } from 'redux-saga/effects'
import { getCompany, setFeature, getFeatureFlags } from 'services/deimos/companies'
import { actions } from '../slice'
import { actions as companyActions } from './slice'
import type { ToggleFeatureFlagPayload } from './types'

export function* fetchCompany({ payload: deimosId }: PayloadAction<string>): any {
    try {
        yield put(actions.modifiedStatus({ status: 'fetching', api: 'company' }))

        const result = yield call(getCompany, deimosId)
        const resolvedRes = yield result.json()

        yield put(companyActions.updatedCompany(resolvedRes))
        yield put(actions.modifiedStatus({ status: 'resolved', api: 'company' }))
    } catch (e) {
        yield put(actions.modifiedStatus({ status: 'error', api: 'company' }))
        yield put(companyActions.resetCompany())
        yield call(notification.error, {
            message: 'Unable to fetch Deimos company.',
            description: (e as Error).message,
        })
    }
}
export function* fetchFeatureFlags({ payload: deimosId }: PayloadAction<string>): any {
    try {
        yield put(actions.modifiedStatus({ status: 'fetching', api: 'featureFlags' }))

        const result = yield call(getFeatureFlags, deimosId)
        const resolvedRes = yield result.json()

        yield put(companyActions.updatedFeatureFlags(resolvedRes.featureFlags))
        yield put(actions.modifiedStatus({ status: 'resolved', api: 'featureFlags' }))
    } catch (e) {
        yield put(actions.modifiedStatus({ status: 'error', api: 'featureFlags' }))
    }
}

export function* toggleFeatureFlag({ payload }: PayloadAction<ToggleFeatureFlagPayload>) {
    try {
        yield call(setFeature, payload)
        yield put(companyActions.setFeatureFlag(payload))
    } catch (e) {
        yield call(notification.error, {
            message: 'Unable to set feature flag.',
            description: (e as Error).message,
        })
    }
}

export function* supportCompanySaga() {
    yield takeLatest(companyActions.fetchCompany, fetchCompany)
    yield takeLatest(companyActions.fetchFeatureFlags, fetchFeatureFlags)
    yield takeLatest(companyActions.toggleFeatureFlag, toggleFeatureFlag)
}
