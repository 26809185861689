import useSWR from 'swr'
import { fetcher as styxFetcher } from 'services/styx/request'
import { fetcher as hydraFetcher } from 'services/hydra/request'
import { mapKycResponse } from './map-kyc-response'
import { useStyxCompany } from 'services/deimos/styx-company/styx-company'

export const useKycInformation = (companyId?: string) => {
    const { companySubscription, isLoading: isCompanySubscriptionLoading } =
        useCompanySubscription(companyId)
    const { kycVerificationDetails, isLoading: isKycVerificationDetailsLoading } =
        useKycVerificationDetails(companyId)
    const { company } = useStyxCompany()

    const hasPlan = !!companySubscription?.subscription
    const hasLowOrMediumRiskScore = ['LOW', 'MEDIUM'].includes(company?.riskAssessment?.score || '')

    const { standard: standardWithoutHasProductPlan, highRisk } = mapKycResponse(
        kycVerificationDetails,
        company
    )

    return {
        standard: [
            ...standardWithoutHasProductPlan,
            {
                label: 'Products to be used',
                key: 'productsToBeUsed',
                isMissing: !hasPlan,
                isGbCompany: undefined,
            },
        ],
        highRisk,
        isLoading: isCompanySubscriptionLoading || isKycVerificationDetailsLoading,
        displayHighRisk: !hasLowOrMediumRiskScore,
    }
}

// API Calls

// Hydra
const useCompanySubscription = (companyId?: string) => {
    const response = useSWR(companyId ? `companies/${companyId}` : null, hydraFetcher)

    return {
        companySubscription: response.data,
        isLoading: !response.data && !response.error,
        ...response,
    }
}

// Styx
const useKycVerificationDetails = (companyId?: string) => {
    const response = useSWR(
        companyId ? `rest/v2/styx-companies/${companyId}/kyc-verification-details` : null,
        styxFetcher,
        { shouldRetryOnError: false }
    )

    return {
        kycVerificationDetails: response.data,
        isLoading: !response.data && !response.error,
        isError: response.error,
        ...response,
    }
}
