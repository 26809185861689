import React, { FC, useState } from 'react'
import useSWR, { mutate, useSWRConfig, Cache } from 'swr'

import { setRequestToken } from 'services/request'
import * as api from 'services/auth/auth'

import config from 'config'
import { usePrevious } from 'utils/use-previous'
import Spinner from 'components/content-spinner'
import { sessionStorage } from './session-storage'

const SESSION_ROUTE = `${config.endpoints.auth}/sca/admin/session`

enum SessionStatus {
    Unknown = 'unknown',
    LoggedIn = 'logged-in',
    LoggedOut = 'logged-out',
}

interface AccessTokenResponse {
    accessToken: string
    ssoReauthUrl?: string
    sso?: boolean
}

async function fetchSession(idToken?: string) {
    const response: Partial<AccessTokenResponse> = await api.checkSession(idToken).json()

    if (response?.ssoReauthUrl) {
        window.location.href = window.location?.pathname
            ? `${response.ssoReauthUrl}?redirectUri=${window.location?.pathname}`
            : response.ssoReauthUrl
        return
    }

    if (!response.accessToken) {
        return { accessToken: null }
    }

    setRequestToken(response.accessToken)
    return response
}

type SessionContextAPI = {
    accessToken?: null | string
    sessionStatus: SessionStatus
    logout: () => Promise<void>
}

const SessionContext = React.createContext<SessionContextAPI>({
    accessToken: null,
    logout: () => Promise.resolve(),
    sessionStatus: SessionStatus.Unknown,
})

export const SessionProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
    const hash = window.location.hash
    let idToken: string | undefined
    if (hash) {
        const params = new URLSearchParams(hash.slice(1))
        idToken = params.get('idToken') ?? undefined
    }
    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const { cache } = useSWRConfig()

    const { data } = useSWR<{ accessToken?: string | null } | undefined, Error>(
        SESSION_ROUTE,
        () => fetchSession(idToken),
        {
            revalidateOnFocus: true,
        }
    )
    const accessToken = data?.accessToken

    const sessionStatus = getSessionStatus(isLoggingOut, accessToken)
    const previousSessionStatus = usePrevious(sessionStatus)

    sessionStorage.setItem('accessToken', accessToken ?? '')

    // Whenever we go from logged in state to logged out state,
    // we want to perform a full clean-up of session data.
    // This will usually happen when the user's session expires,
    // or they log out in another tab.
    useCleanupSession(sessionStatus, previousSessionStatus)

    async function logout() {
        try {
            setIsLoggingOut(true)
            await api.logout()
            cleanupSession(cache)
        } finally {
            setIsLoggingOut(false)
        }
    }

    const sessionContextValue = {
        accessToken,
        logout,
        sessionStatus,
    }

    return (
        <SessionContext.Provider value={sessionContextValue}>
            {sessionStatus === SessionStatus.Unknown || isLoggingOut ? <Spinner /> : children}
        </SessionContext.Provider>
    )
}

async function cleanupSession(cache: Cache) {
    cache.set(SESSION_ROUTE, { accessToken: null })
    sessionStorage.setItem('accessToken', 'null')
    await mutate(SESSION_ROUTE, { accessToken: null }, false)
}

function useCleanupSession(sessionStatus: SessionStatus, previousSessionStatus?: SessionStatus) {
    const { cache } = useSWRConfig()
    const wasLoggedIn = previousSessionStatus === SessionStatus.LoggedIn
    const isLoggedOut = sessionStatus === SessionStatus.LoggedOut

    const shouldCleanup = wasLoggedIn && isLoggedOut
    React.useEffect(() => {
        if (shouldCleanup) {
            cleanupSession(cache)
        }
    }, [previousSessionStatus, sessionStatus, shouldCleanup, cache])
}

function getSessionStatus(isLoggingOut: boolean, accessToken?: string | null) {
    if (accessToken) {
        return SessionStatus.LoggedIn
    } else if (accessToken === null && !isLoggingOut) {
        return SessionStatus.LoggedOut
    } else {
        return SessionStatus.Unknown
    }
}

export function useLogout() {
    const { logout } = React.useContext(SessionContext)

    return logout
}

export function useAccessToken() {
    const { accessToken } = React.useContext(SessionContext)
    return accessToken
}

export function useIsLoggedOut() {
    const { sessionStatus } = React.useContext(SessionContext)
    return sessionStatus === SessionStatus.LoggedOut
}

export function useIsLoggedIn() {
    const { sessionStatus } = React.useContext(SessionContext)
    return sessionStatus === SessionStatus.LoggedIn
}
