import { QuestionCircleFilled } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'
import { useHasPermissions } from 'components/permission-guard/permission-guard'

export const PermissionTag = ({ permission }: { permission: string }) => {
    const hasPermission = useHasPermissions([permission])

    return !hasPermission ? (
        <Tag color="orange" icon={<QuestionCircleFilled />} style={{ cursor: 'help' }}>
            <Tooltip title={permission}>Permissions required</Tooltip>
        </Tag>
    ) : null
}
