import { usePreferences } from 'providers/user-context'
import { useEffect, useState } from 'react'
import type { ColorScheme } from './use-user-preferences'

export const useColorScheme = (): {
    shouldUseDarkMode: boolean
    colorScheme: ColorScheme
} => {
    const preferences = usePreferences()
    const [shouldUseDarkMode, setUseDarkMode] = useState(false)

    useEffect(() => {
        if (
            preferences?.userPreferences.colorScheme === 'dark' ||
            (preferences?.userPreferences.colorScheme === 'system' &&
                window.matchMedia('(prefers-color-scheme: dark)').matches)
        ) {
            setUseDarkMode(true)
        }
        if (
            preferences?.userPreferences.colorScheme === 'light' ||
            (preferences?.userPreferences.colorScheme === 'system' &&
                window.matchMedia('(prefers-color-scheme: light)').matches)
        ) {
            setUseDarkMode(false)
        }
        return
    }, [preferences])

    return {
        shouldUseDarkMode,
        colorScheme: shouldUseDarkMode ? 'dark' : 'light',
    }
}
