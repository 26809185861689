import { useNavigate } from 'react-router-dom'
import { Typography, Space, type TableColumnProps, Table } from 'antd'
import { getEmojiFlag } from 'countries-list'
import dayjs from 'packages/dayjs'
import { TableStyleProvider } from '../styles'

import { bff } from '../bff'
import type { CompanyResultsResponse } from '../index.bff'
import { useQueryFromParams } from '../lib/use-query-from-params'

const { Text } = Typography

const companyColumns: TableColumnProps<CompanyResultsResponse[0]>[] = [
    {
        title: 'Country',
        dataIndex: 'country',
        render: (country) => (
            <Space>
                <Text>{getEmojiFlag(country)}</Text>
                <Text>{country}</Text>
            </Space>
        ),
    },
    {
        title: 'Company name',
        dataIndex: 'companyName',
        render: (companyName) => <Text>{companyName}</Text>,
    },
    {
        title: 'Company ID',
        dataIndex: 'companyId',
        render: (companyId) => (
            <Text onClick={(e) => e.stopPropagation()} style={{ display: 'inline-block' }} copyable>
                {companyId}
            </Text>
        ),
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('lll'),
    },
]

export const CompanyResultsTable = () => {
    const navigate = useNavigate()
    const { query } = useQueryFromParams()
    const { data, isLoading } = bff.companyResultsTable.getCompanySearchResults.useQuery({ query })

    const onRowClick = (mouseEvent: React.MouseEvent, companyId: string) => {
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            window.open(`/customer-success/company-360/${companyId}`)
        } else {
            navigate(`/customer-success/company-360/${companyId}`)
        }
    }

    return (
        <TableStyleProvider>
            <Table
                dataSource={data}
                columns={companyColumns}
                rowKey="companyId"
                onRow={(company) => ({
                    onClick: (mouseEvent) => onRowClick(mouseEvent, company.companyId),
                })}
                loading={isLoading}
            />
        </TableStyleProvider>
    )
}
