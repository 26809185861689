import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import { Card, Descriptions, Divider, Skeleton, Typography } from 'antd'
import dayjs from 'packages/dayjs'
import type { FC } from 'react'
import styled from 'styled-components'
import { EmployeeInsuranceResponse, useEmployeeInsurance } from './api/travel-insurance'

interface TravelInsuranceDetailsProps {
    insurance: EmployeeInsuranceResponse
}

const StyledDescriptions = styled(Descriptions)`
    .ant-descriptions-view,
    .ant-descriptions-row {
        border: none;
    }
`

const TravelInsuranceDetails: FC<TravelInsuranceDetailsProps> = ({ insurance }) => {
    const dateFormat = 'll'
    return (
        <>
            <span>
                <CheckCircleTwoTone />{' '}
                <span>{`Has travel insurance from ${insurance.thirdParty}`}</span>
            </span>
            <Divider style={{ margin: '12px 0 8px 0' }} />
            <StyledDescriptions
                colon={false}
                labelStyle={{
                    fontWeight: 'bold',
                    paddingLeft: 0,
                    border: 'none',
                    background: 'none',
                }}
                column={1}
                contentStyle={{ textAlign: 'right', paddingRight: 0, border: 'none' }}
                bordered
                size="small"
            >
                <Descriptions.Item label="Plan code">
                    <Typography.Text copyable>{insurance.planCode}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="Activated on">
                    {dayjs(insurance.insurerActivatedAt).format(dateFormat)}
                </Descriptions.Item>
                <Descriptions.Item label="Ending on">
                    {dayjs(insurance.insuranceEndDate).format(dateFormat)}
                </Descriptions.Item>
            </StyledDescriptions>
        </>
    )
}

const NotInsured = () => {
    return (
        <span>
            <CloseCircleTwoTone /> <span>Not insured</span>
        </span>
    )
}

export const TravelInsurance: FC<{ employeeId?: string }> = ({ employeeId }) => {
    const { data, isValidating } = useEmployeeInsurance(employeeId)

    return (
        <Card
            title="Travel insurance"
            style={{ marginTop: 24 }}
            bodyStyle={{ padding: '16px 24px' }}
            type="inner"
        >
            {(!data && isValidating) || !employeeId ? (
                <Skeleton active />
            ) : data ? (
                <TravelInsuranceDetails insurance={data} />
            ) : (
                <NotInsured />
            )}
        </Card>
    )
}
