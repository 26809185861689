import type { FC } from 'react'
import { Card, Table, TableColumnsType, Typography } from 'antd'
import type { CashbackContributorResponse } from 'bff/moons/generated/tarvos'
import { formatMoneyIntl } from 'utils/money'
import dayjs from 'dayjs'
import { TableStyleProvider } from 'features/odd/table'

const { Text } = Typography

const CashbackContributorTable: FC<
    React.PropsWithChildren<{ cashbackContributor: CashbackContributorResponse[] }>
> = ({ cashbackContributor }) => {
    const columns: TableColumnsType<CashbackContributorResponse> = [
        {
            key: 'companyId',
            dataIndex: 'companyId',
            title: 'Company ID',
            render: (companyId) =>
                companyId && (
                    <Text copyable ellipsis style={{ maxWidth: '10rem' }}>
                        {companyId}
                    </Text>
                ),
        },
        {
            key: 'invoiceTotal',
            dataIndex: 'invoiceTotal',
            title: 'Converted invoice total',
            render: (invoiceTotal) => formatMoneyIntl(invoiceTotal),
        },
        {
            key: 'expenseTotal',
            dataIndex: 'expenseTotal',
            title: 'Expense total',
            render: (expensesTotal) => formatMoneyIntl(expensesTotal),
        },
        {
            key: 'amount',
            dataIndex: 'amount',
            title: 'Converted expense total',
            render: (amount) => formatMoneyIntl(amount),
        },
        {
            key: 'effectiveDate',
            dataIndex: 'effectiveDate',
            title: 'Effective date of exchange rate',
            render: (effectiveDate) => dayjs(effectiveDate).format('MMM D, YYYY'),
        },
        {
            key: 'baseCurrencyCode',
            dataIndex: 'baseCurrencyCode',
            title: 'Base currency',
            render: (baseCurrencyCode) => baseCurrencyCode,
        },
        {
            key: 'quoteCurrencyCode',
            dataIndex: 'quoteCurrencyCode',
            title: 'Quote currency',
            render: (quoteCurrencyCode) => quoteCurrencyCode,
        },
        {
            key: 'rate',
            dataIndex: 'rate',
            title: 'Exchange rate',
            render: (rate) => rate,
        },
        {
            key: 'bpsMargin',
            dataIndex: 'bpsMargin',
            title: 'BPS margin',
            render: (bpsMargin) => bpsMargin,
        },
    ]

    return (
        <Card>
            <TableStyleProvider>
                <Table
                    columns={columns}
                    dataSource={cashbackContributor}
                    size="small"
                    rowKey={'id'}
                    data-testid="cashback-contributor-table"
                />
            </TableStyleProvider>
        </Card>
    )
}

export default CashbackContributorTable
