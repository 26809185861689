import { Descriptions } from 'antd'
import dayjs from 'packages/dayjs'
import type { Transaction } from 'types/proof-of-funds'

const TransactionDescription = ({ transaction }: { transaction: Transaction }) => (
    <Descriptions bordered layout="vertical">
        <Descriptions.Item label="Sender Info">
            <p>{transaction.senderBank}</p>
            <p>{transaction.senderInformation}</p>
        </Descriptions.Item>
        <Descriptions.Item label="Amount">
            {transaction.amount} {transaction.currency}
        </Descriptions.Item>
        <Descriptions.Item label="Performed">
            {dayjs(transaction.performedAt).format('lll')}
        </Descriptions.Item>
    </Descriptions>
)

export default TransactionDescription
