import {
    CopyFilled,
    LockOutlined,
    SafetyCertificateOutlined,
    UserOutlined,
} from '@ant-design/icons'

import { PageHeader } from '@ant-design/pro-layout'
import {
    Layout,
    Button,
    Skeleton,
    Typography,
    Card,
    notification,
    Space,
    Tooltip,
    Breadcrumb,
    Tag,
} from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import { SecurityResetModal } from 'components/security-reset-modal/security-reset-modal'
import { TravelInsurance } from 'components/travel-insurance/travel-insurance'
import { LoginEvents } from 'pages/customer-success/login-events/components/login-events'
import { FC, useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Cards from 'components/employees/cards/cards'
import { useGetCompany } from 'services/deimos/companies'
import { Transactions } from '../../components/transactions'
import { ReviewStructure } from '../../components/review-structure'
import { EmployeeDetails } from '../../components/employee-details'
import { Timeline } from 'components/timeline/timeline'
import UserVulnerability from 'pages/customer-success/employee/components/user-vulnerability/user-vulnerability'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { bff } from '../../bff'
import { capitalizeFirstLetter } from 'utils/strings'

interface PageHeaderExtrasProps {
    employeeId?: string
    userId?: string
    onSecurityModalOpen: () => void
}

const { Title, Text, Link: AntdLink } = Typography

const Extra: FC<PageHeaderExtrasProps> = ({ onSecurityModalOpen, employeeId, userId }) => {
    const navigate = useNavigate()

    const copyEmployeeId = useCallback(() => {
        if (employeeId) {
            navigator.clipboard.writeText(employeeId)
            notification.success({ message: 'Copied Employee ID' })
        }
    }, [employeeId])

    const handleKeyPress = useCallback(
        (event: any) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'i') {
                copyEmployeeId()
            }
        },
        [copyEmployeeId]
    )

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress, { passive: true })
        return () => window.removeEventListener('keydown', handleKeyPress)
    })

    return (
        <Space direction="vertical" align="end">
            <Tooltip
                color="white"
                title={
                    <Text>
                        Copy Employee ID <Text keyboard>⌘</Text>+<Text keyboard>I</Text>
                    </Text>
                }
            >
                <AntdLink type="secondary" onClick={() => copyEmployeeId()}>
                    {employeeId} <CopyFilled />
                </AntdLink>
            </Tooltip>
            <Space direction="horizontal">
                <Button onClick={() => navigate(`/customer-success/users/${userId}`)}>
                    View user
                </Button>
                <Button disabled={!userId} onClick={onSecurityModalOpen}>
                    <LockOutlined />
                    Security
                </Button>
            </Space>
        </Space>
    )
}

const OneLineSkeleton = () => (
    <Skeleton paragraph={false} active title={{ width: '10em' }} style={{ marginTop: '4px' }} />
)

const Employee = () => {
    const { employeeId } = useParams()
    const { data: employee, isLoading: isLoadingEmployee } =
        bff.employeeDetails.getEmployee.useQuery({
            employeeId,
        })
    const { data: company } = useGetCompany(employee?.companyId)
    const [isSecurityModalVisible, setIsSecurityModalVisible] = useState(false)
    const hasReadUkVulnerability = useHasPermissions(['uk-vulnerability-read'])

    const name = !isLoadingEmployee ? (
        employee?.name
    ) : (
        <Skeleton active title={{ width: 200 }} paragraph={false} />
    )

    return (
        <PageContentLayout>
            <PageHeader
                title={
                    <Space direction="vertical" size={0}>
                        <Title level={3} style={{ margin: 0 }}>
                            {name}
                        </Title>
                        {employee && (
                            <Space>
                                <Tag color={employee?.isActive ? 'success' : 'default'}>
                                    {capitalizeFirstLetter(employee?.state.toLocaleLowerCase())}
                                </Tag>
                                <Tag color={employee?.isVerified ? 'blue' : 'default'}>
                                    {employee?.isVerified ? (
                                        <>
                                            <SafetyCertificateOutlined /> Verified
                                        </>
                                    ) : (
                                        'Unverified'
                                    )}
                                </Tag>
                            </Space>
                        )}
                    </Space>
                }
                extra={
                    <Extra
                        employeeId={employee?.employeeId}
                        userId={employee?.userId}
                        onSecurityModalOpen={() => setIsSecurityModalVisible(true)}
                    />
                }
                breadcrumb={
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success'}>Customer Success</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={'/customer-success/employee-360'}>Employees</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to={`/customer-success/employee-360/${employee?.companyId}`}>
                                {company?.name}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {name ? (
                                <>
                                    <UserOutlined /> {name}
                                </>
                            ) : (
                                <OneLineSkeleton />
                            )}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                }
            />
            {employee?.userId && (
                <SecurityResetModal
                    userId={employee.userId}
                    isVisible={isSecurityModalVisible}
                    onClose={() => setIsSecurityModalVisible(false)}
                />
            )}
            <Layout>
                <Layout.Content style={{ margin: '0 24px 24px 0' }}>
                    <Transactions employeeId={employee?.employeeId} />
                    <Card title="Cards" type="inner" style={{ margin: '0 0 24px ' }}>
                        <Cards
                            employeeId={employee?.employeeId}
                            companyId={employee?.companyId}
                            cardAccess={employee?.hasCardAccess}
                        />
                    </Card>
                    <Card
                        type="inner"
                        title="Employee timeline"
                        style={{ margin: '0 0 24px' }}
                        bodyStyle={{ paddingTop: '32px' }}
                        data-testid="employee-timeline"
                    >
                        <Timeline id={employee?.userId} type="user" />
                    </Card>
                    {hasReadUkVulnerability && (
                        <UserVulnerability userId={employee?.userId || ''} />
                    )}
                </Layout.Content>
                <Layout.Sider width={'25%'} style={{ background: 'none' }}>
                    <EmployeeDetails
                        companyId={employee?.companyId}
                        role={employee?.role}
                        email={employee?.email}
                    />
                    <LoginEvents email={employee?.email} />
                    <ReviewStructure
                        companyId={employee?.companyId}
                        employeeId={employee?.employeeId}
                    />
                    <TravelInsurance employeeId={employee?.employeeId} />
                </Layout.Sider>
            </Layout>
        </PageContentLayout>
    )
}

export default Employee
