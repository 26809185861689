const acronyms = ['aml', 'ubo']

const shouldBeAllCaps = (str: string) => acronyms.includes(str)

export const uppercaseFirstLetter = (string: string) =>
    `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`

export const deSnakify = (upperSnakeCasedString: string) =>
    `${upperSnakeCasedString.charAt(0)}${upperSnakeCasedString
        .split('_')
        .join(' ')
        .toLowerCase()
        .slice(1)}`

export const deKebabify = (params: string) =>
    params
        .split('-')
        .map((word) => (shouldBeAllCaps(word) ? word.toUpperCase() : uppercaseFirstLetter(word)))
        .join(' ')

export const camelCaseToSentenceCase = (word: string) =>
    word.replace(/([A-Z])/g, (match) => ` ${match}`).replace(/^./, (match) => match.toUpperCase())

export const randomString = () => Math.random().toString(36)

export const snakeCaseToTitleCase = (SNAKE_CASE: string) =>
    SNAKE_CASE.toLowerCase()
        .split('_')
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(' ')

export const snakeCaseToTitleSnakeCase = (SNAKE_CASE: string) =>
    SNAKE_CASE.toLowerCase()
        .split('_')
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join('-')

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const capitalizeFirstLetterAndLowercaseElse = (str: string) =>
    str ? capitalizeFirstLetter(str.toLowerCase()) : ''
