import * as Yup from 'yup'

const uuidRegex =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/

export const validationSchema = Yup.object()
    .shape({
        name: Yup.string().required(),
        tradingName: Yup.string().notRequired(),
        registrationNumber: Yup.string(),
        addressLine1: Yup.string().required(),
        addressLine2: Yup.string().notRequired(),
        locality: Yup.string().required(),
        region: Yup.string().notRequired(),
        postalCode: Yup.string().required(),
        country: Yup.string().required().length(2),
        ownCompanyId: Yup.string().matches(uuidRegex, 'Invalid uuid').nullable(),
        type: Yup.string().notRequired(),
    })
    .required()

export type FormValues = Yup.InferType<typeof validationSchema>
