import { Alert, Card, Skeleton, Statistic } from 'antd'
import styled from 'styled-components'
import formatCurrency from 'utils/format-currency'
import { creditBff } from '../bff'
import { useParams } from 'react-router-dom'
import type { Account } from 'bff/moons/generated/kalyke'
import { convertToMajorCurrency } from 'utils/minor-currency'

const BALANCE_TITLES: { [key: string]: string } = {
    RECEIVABLES_PRINCIPAL: 'Principal',
    RECEIVABLES_INTEREST: 'Interest',
    RECEIVABLES_PRINCIPAL_OVERDUE: 'Principal Overdue',
    RECEIVABLES_INTEREST_OVERDUE: 'Interest Overdue',
    REALIZED_INTEREST: 'Realized Interest',
}

interface Props {
    account: Account
}

export default function AccountBalances({ account }: Props) {
    const { id } = useParams()

    const { data, isLoading, isError } = creditBff.getAccountBalances.useQuery({
        companyId: id,
        currency: account.currency,
    })

    if (isLoading) {
        return <Skeleton active />
    }

    if (isError) {
        return <Alert message="There was an error fetching Account balances." type="error" />
    }
    return (
        <>
            {Object.keys(data.accountBalances).map((accountType) => {
                const accountBalance = data.accountBalances[accountType]
                return (
                    <StyledStatisticCard key={accountType}>
                        <Statistic
                            title={BALANCE_TITLES[accountType]}
                            value={formatCurrency(
                                convertToMajorCurrency(accountBalance.amount),
                                accountBalance.currency
                            )}
                        />
                    </StyledStatisticCard>
                )
            })}
        </>
    )
}

const StyledStatisticCard = styled(Card)`
    min-width: 150px;
`
