import Item from 'antd/es/list/Item'
import { Button, Descriptions } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import type { ExpenseProfile } from '../../types'
import { totalExpenseCountReducer } from './helpers'

type ReadableExpenseProps = ExpenseProfile & {
    toggleEditing: (value: boolean) => void
}

const ReadableExpense = ({ name, toggleEditing, merchantProfiles }: ReadableExpenseProps) => {
    const count = merchantProfiles.reduce(totalExpenseCountReducer, 0)
    return (
        <Item
            actions={[
                <Button
                    type="primary"
                    size="small"
                    onClick={() => toggleEditing(true)}
                    key="edit-button"
                >
                    Edit
                </Button>,
            ]}
        >
            <Descriptions title={name} column={2}>
                <DescriptionsItem label="Count">{count}</DescriptionsItem>
            </Descriptions>
        </Item>
    )
}

export default ReadableExpense
