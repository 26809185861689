import type { FC } from 'react'
import styled from 'styled-components'
import { DownOutlined } from '@ant-design/icons'
import { MenuProps, Dropdown as AntDropdown } from 'antd'
import { useNavigate } from 'react-router-dom'

const Dropdown: FC<React.PropsWithChildren<{ label?: string }>> = ({ label }) => {
    const navigate = useNavigate()

    const menuItems: MenuProps['items'] = [
        {
            key: 'customer-success',
            label: 'Customer Success',
            onClick: () => navigate('/customer-success'),
        },
        {
            key: 'compliance',
            label: 'Compliance',
            onClick: () => navigate('/compliance'),
        },
        {
            key: 'finance',
            label: 'Finance',
            onClick: () => navigate('/finance'),
        },
        {
            key: 'product',
            label: 'Product',
            onClick: () => navigate('/product'),
        },
        {
            key: 'payment-ops',
            label: 'Payment Ops',
            onClick: () => navigate('/payment-ops'),
        },
        {
            key: 'partnerships',
            label: 'Partnerships',
            onClick: () => navigate('/partnerships'),
        },
    ]

    return (
        <AntDropdown
            menu={{
                items: menuItems,
            }}
        >
            <NavigationLabel data-testid="link">
                {label} <DownOutlined />
            </NavigationLabel>
        </AntDropdown>
    )
}

export const NavigationLabel = styled.span`
    cursor: pointer;
`

export default Dropdown
