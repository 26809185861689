import { Space, Tag, Tooltip } from 'antd'
import { fontSize } from 'theme/tokens'
import { ThunderboltOutlined } from '@ant-design/icons'

import { bff } from 'pages/compliance/bff'

interface PartnerReferralTagProps {
    deimosCompanyId: string
}

export const PartnerReferralTag = ({ deimosCompanyId }: PartnerReferralTagProps) => {
    const { data } = bff.partnerReferralTagRouter.getInfo.useQuery({
        deimosCompanyId,
    })

    if (!data?.partnerTier) {
        return null
    }

    const isVip = ['GOLD', 'PLATINUM'].includes(data.partnerTier)
    const title = `The company has been referred by a Partner with ${data.partnerTier} status`

    return (
        <Space>
            <Tooltip title={title}>
                <Tag
                    icon={isVip && <ThunderboltOutlined />}
                    color="purple"
                    data-testid={'partner-referral-tag'}
                    style={{ fontSize: fontSize.size12 }}
                >
                    {data.partnerTier} partner referral
                </Tag>
            </Tooltip>
        </Space>
    )
}
