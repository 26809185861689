import type { FC } from 'react'

import { usePermissions } from 'providers/user-context'

interface Props {
    requiredPermissions?: string[]
    fallback?: JSX.Element
}

export const useHasPermissions = (requiredPermissions: string[] = []) => {
    const userPermissions = usePermissions()
    const isMissingPermissions = requiredPermissions.some(
        (permission) => !userPermissions.includes(permission)
    )

    return !isMissingPermissions
}

const PermissionGuard: FC<React.PropsWithChildren<Props>> = ({
    children,
    requiredPermissions = [],
    fallback,
}) => {
    const hasPermission = useHasPermissions(requiredPermissions)

    if (hasPermission) {
        return <>{children}</>
    }

    return fallback ?? null
}
export const withPermissionGuard =
    <Prop extends {}>(
        Component: React.ComponentType<React.PropsWithChildren<Prop>>,
        requiredPermissions: string[] = []
    ): React.FC<React.PropsWithChildren<Prop>> =>
    // eslint-disable-next-line react/display-name
    (props) =>
        (
            <PermissionGuard requiredPermissions={requiredPermissions}>
                <Component {...props} />
            </PermissionGuard>
        )

export default PermissionGuard
