import React, { FC, type ReactElement, useState } from 'react'
import {
    Button,
    Col,
    Popconfirm,
    Row,
    Space,
    Table,
    message,
    Descriptions,
    Popover,
    List,
} from 'antd'

import type { Person, KycCheck } from 'types/styx'
import { getLatestKycCheck } from 'pages/compliance/company/people/helpers'
import KycTimeline, { TimelineTypes } from 'pages/compliance/shared/kyc-timeline/kyc-timeline'
import { RDC } from 'types/styx'
import { useManagement } from 'services/styx/management'
import { KycButton } from 'pages/compliance/shared/handle-kyc-check/handle-kyc-check'
import { HistoryContainer } from './sis'
import { QuestionCircleOutlined } from '@ant-design/icons'
import type { PepCheckResponse, PepMatchResponse } from 'bff/moons/generated/styx-v1'
import styled from 'styled-components'

interface Props {
    personFullName: string
    isFalsePositivePep: boolean
    runKycScreening: () => void
    handlePepFalsePositive: (checked: boolean) => void
    setPepAsManuallyApproved: () => void
    latestPep: KycCheck | undefined
    selectedPepCheck: PepCheckResponse | undefined
}

export const Pep: FC<React.PropsWithChildren<Props>> = ({
    personFullName,
    runKycScreening,
    children,
    setPepAsManuallyApproved,
    latestPep,
    selectedPepCheck,
}) => {
    return (
        <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12}>
                    <HistoryContainer>{children}</HistoryContainer>
                </Col>
                <Col span={12}>
                    {selectedPepCheck ? (
                        <PepDetailsContainer selectedPepCheck={selectedPepCheck} />
                    ) : (
                        <p>Select a PEP check to view additional information</p>
                    )}
                </Col>
            </Row>
            <Space direction="vertical">
                <a
                    data-testid="search-rdc"
                    href={`${RDC.SEARCH_URL}${personFullName}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Search in RDC
                </a>
                <Space size="large">
                    {latestPep?.result === 'MANUALLY_APPROVED' ? (
                        <KycButton status={latestPep?.result}>PEP: MANUALLY APPROVED</KycButton>
                    ) : (
                        <Popconfirm
                            title="Manually approve PEP?"
                            disabled={!latestPep}
                            description={
                                <>
                                    <br />
                                    <p>Are you sure you want to manually approve this PEP?</p>
                                    <p>This action cannot be undone!</p>
                                    <br />
                                </>
                            }
                            onConfirm={() => setPepAsManuallyApproved()}
                            okText="Confirm"
                            okButtonProps={{ danger: true }}
                            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        >
                            <Button disabled={!latestPep} data-testid="manually-approve-pep-btn">
                                Confirm as PEP
                            </Button>
                        </Popconfirm>
                    )}

                    <Button data-testid="new-check" type="primary" onClick={runKycScreening}>
                        Re-run KYC screening (RDC)
                    </Button>
                </Space>
            </Space>
        </>
    )
}

const Div = styled.div`
    max-height: 450px;
    overflow: auto;
    padding: 2px;
`

const PepDetailsContainer: FC<
    React.PropsWithChildren<{ selectedPepCheck: PepCheckResponse | undefined }>
> = ({ selectedPepCheck }) => {
    const matchesSortedByScore: PepMatchResponse[] | undefined = selectedPepCheck?.pepMatches?.sort(
        (a, b) => (b.matchScore || 0) - (a.matchScore || 0)
    )

    if (!matchesSortedByScore || matchesSortedByScore.length === 0) {
        matchesSortedByScore?.push({
            fullName: 'N/A',
            datesOfBirth: [],
            addresses: [],
            pepTypes: [],
            matchScore: 0,
        })
    }

    return (
        <Div>
            {matchesSortedByScore?.at(0) ? (
                <PepDetails pepMatchResponse={matchesSortedByScore?.at(0)!} />
            ) : (
                <p>Select a PEP check to view additional information</p>
            )}
        </Div>
    )
}

const renderAsMultipleOrSingle = (list: string[] | undefined): ReactElement<any, any> | string => {
    if (list?.length && list?.length > 1) {
        return (
            <Popover
                content={
                    <List dataSource={list} renderItem={(item) => <List.Item>{item}</List.Item>} />
                }
            >
                Multiple
            </Popover>
        )
    } else if (list) {
        return list?.at(0) || 'N/A'
    } else {
        return 'N/A'
    }
}

const PepDetails: FC<React.PropsWithChildren<{ pepMatchResponse: PepMatchResponse }>> = ({
    pepMatchResponse,
}) => {
    const tableColumns = [
        { title: 'Type', dataIndex: 'type', key: 'type' },
        { title: 'Level', dataIndex: 'level', key: 'level' },
        { title: 'Country', dataIndex: 'positionCountryCode', key: 'positionCountryCode' },
    ]

    const personalData = {
        fullName: pepMatchResponse.fullName,
        dateOfBirth: renderAsMultipleOrSingle(pepMatchResponse.datesOfBirth),
        address: renderAsMultipleOrSingle(pepMatchResponse.addresses),
    }

    const tableData = pepMatchResponse?.pepTypes?.map((type) => ({
        type: type.type || 'N/A',
        level: type.level || 'N/A',
        positionCountryCode: (
            <Popover content={type.position || 'N/A'}>{type.positionCountryCode || 'N/A'}</Popover>
        ),
    }))

    return (
        <>
            <Descriptions title={personalData.fullName} layout="vertical">
                <Descriptions.Item label="Match score">
                    {pepMatchResponse.matchScore}%
                </Descriptions.Item>
                <Descriptions.Item label="Dates of birth">
                    {personalData.dateOfBirth}
                </Descriptions.Item>
                <Descriptions.Item label="Addresses">{personalData.address}</Descriptions.Item>
            </Descriptions>
            <Table
                dataSource={tableData}
                columns={tableColumns}
                pagination={{ hideOnSinglePage: true }}
            ></Table>
        </>
    )
}

const PepContainer: FC<React.PropsWithChildren<{ person: Person; refetchPeople: () => void }>> = ({
    person,
    refetchPeople,
}) => {
    const [fetching, setFetching] = useState(false)
    const { mutations } = useManagement()
    const latestPep = getLatestKycCheck(person.pepChecks)
    const [selectedPepCheck, setSelectedPepCheck] = useState<String>(latestPep ? latestPep.id : '')
    const isFalsePositivePep = !!latestPep && !!latestPep.falsePositive

    const personFullName = [person.name.givenName, person.name.middleName, person.name.familyName]
        .filter((name) => !!name)
        .join('+')

    const handlePepFalsePositive = async (checked: boolean) => {
        if (!latestPep) {
            return
        }

        try {
            setFetching(true)
            await mutations.toggleKycCheckFalsePositive(latestPep.id, checked)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to mark PEP check as false positive: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    const runKycScreening = async () => {
        try {
            setFetching(true)
            await mutations.createPepSisScreening(person.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to create screening: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    const handleToggleFalsePositive = async (check: KycCheck, checked: boolean) => {
        try {
            await mutations.toggleKycCheckFalsePositive(check.id, checked)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to toggle false positive status: ${(e as Error).message}`)
        }
    }

    const setPepAsManuallyApproved = async () => {
        if (!latestPep) return

        try {
            setFetching(true)
            await mutations.setPepAsManuallyApproved(person.id)
            refetchPeople()
        } catch (e) {
            message.error(`Failed to mark PEP check as manually approved: ${(e as Error).message}`)
        } finally {
            setFetching(false)
        }
    }

    return (
        <Pep
            personFullName={personFullName}
            isFalsePositivePep={isFalsePositivePep}
            handlePepFalsePositive={handlePepFalsePositive}
            runKycScreening={runKycScreening}
            setPepAsManuallyApproved={setPepAsManuallyApproved}
            latestPep={latestPep}
            selectedPepCheck={person.enhancedPepChecks.find(
                (check) => check.id === selectedPepCheck
            )}
        >
            <KycTimeline
                kycChecks={person.pepChecks}
                title="PEP History"
                fetching={fetching}
                toggleFalsePositive={handleToggleFalsePositive}
                setSelectedPepCheck={setSelectedPepCheck}
                timeLineType={TimelineTypes.PEP}
                showRecurringMonitoring={true}
            />
        </Pep>
    )
}

export default PepContainer
