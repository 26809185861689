import { Button, Modal, Timeline } from 'antd'
import type {
    CommentDto,
    StateChangeFullDto,
    UnderwritingApplicationFullDto,
} from 'bff/moons/generated/kale'
import dayjs from 'dayjs'
import { useUserById } from 'utils/user'
import { getOverdraftApplicationStatusColor } from '../../heplers'
import { useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

function AdminUserName({ userId }: { userId: string }) {
    const { data, error } = useUserById(userId)

    if (error) {
        return <span>Unknown</span>
    }

    if (!data) {
        return <span>Loading...</span>
    }

    return <span> {data?.[0]?.email ?? userId}</span>
}

interface Props {
    application: UnderwritingApplicationFullDto
}

export default function HigherLimitApplicationStateTimeline({ application }: Props) {
    const [infoComment, setInfoComment] = useState<CommentDto | null>(null)

    const handleInformationRequiredClick = (stateId: string) => {
        const infoRequestStates = application.stateTrail.filter(
            ({ state }) => state === 'INFO_REQUIRED'
        )
        const currentIndex = infoRequestStates.findIndex(({ id }) => id === stateId)

        const currentComment = application.comments[currentIndex]

        setInfoComment(currentComment)
    }

    const renderStateText = ({
        state,
        createdAt,
        id,
    }: Pick<StateChangeFullDto, 'state' | 'createdAt' | 'id'>) => {
        const tsText = `on ${dayjs(createdAt).format('YYYY-MM-DD')} at ${dayjs(createdAt).format('HH:mm')} by`
        if (state === 'INFO_REQUIRED') {
            return (
                <>
                    <Button
                        size="small"
                        type="link"
                        icon={<InfoCircleOutlined />}
                        onClick={() => handleInformationRequiredClick(id)}
                    >
                        {state}
                    </Button>
                    {tsText}
                </>
            )
        }
        return `${state} ${tsText}`
    }

    return (
        <>
            <Timeline key={application.id}>
                {application.stateTrail.map(({ state, createdAt, id, isCustomer, userId }) => (
                    <Timeline.Item color={getOverdraftApplicationStatusColor(state)} key={id}>
                        {renderStateText({ createdAt, state, id })}
                        {isCustomer ? <span>Customer</span> : <AdminUserName userId={userId} />}
                    </Timeline.Item>
                ))}
            </Timeline>
            <InformationRequestDetailsModal
                comment={infoComment}
                onCancel={() => setInfoComment(null)}
            />
        </>
    )
}

interface InformationRequestDetailsModalProps {
    comment: CommentDto | null
    onCancel: () => void
}

function InformationRequestDetailsModal({
    comment,
    onCancel,
}: InformationRequestDetailsModalProps) {
    return (
        <Modal open={Boolean(comment)} onCancel={onCancel} footer={null} centered title="Comment">
            <StyledContent>{comment?.content}</StyledContent>
        </Modal>
    )
}

const StyledContent = styled.p`
    white-space: pre-wrap;
`
