import React, { FC, Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PageHeader } from '@ant-design/pro-layout'

import {
    Button,
    Typography,
    Popconfirm,
    Space,
    Descriptions,
    Divider,
    Tooltip,
    Skeleton,
} from 'antd'

import type { AdminCompany, Company } from 'types/deimos-company'

import { User, UserTFAType } from 'types/user'

import type { CurrentStatus } from 'types/status'

import { getUserTypeDisplay, getUserTypes } from 'utils/user'

import { LockType, useUserLocks } from 'services/kerberos/user-locks'
import { deactivateEmployee, deleteBookkeeper } from 'services/deimos/employees'
import { useGetCompany } from 'services/deimos/companies'
import { useFetchStatusEmployee } from 'services/callisto/employee-fetch'
import { EmployeeType } from '@pleo-io/deimos'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { bff } from '../backend/bff-hooks'
import { formatTimestamp } from 'packages/dates/dates'
import { RoleType } from 'types/user-role'

const { Link, Paragraph, Text } = Typography

interface CompanyNameProps {
    company: AdminCompany | Company
    onCompanyClick: (companyId: string) => void
}

interface FetchStatusProps {
    fetchStatus?: 'gmail' | 'office365' | 'none'
}
const CompanyName: FC<React.PropsWithChildren<CompanyNameProps>> = ({
    company,
    onCompanyClick,
}) => <Link onClick={() => onCompanyClick(company.id)}>{company.name}</Link>

const NoCompanyForUser = () => <Text>None associated with this user</Text>

const NotFailedCompany: FC<React.PropsWithChildren<CompanyNameProps>> = ({
    company,
    onCompanyClick,
}) =>
    company.name === '' ? (
        <NoCompanyForUser />
    ) : (
        <CompanyName company={company} onCompanyClick={() => onCompanyClick(company.id)} />
    )

interface FailedCompanyProps {
    companyFetchRetry: () => void
}
const FailedCompany: FC<React.PropsWithChildren<FailedCompanyProps>> = ({ companyFetchRetry }) => (
    <Space>
        <Text>Failed to retrieve company</Text>
        <Button onClick={companyFetchRetry} type="link" size="small">
            Retry
        </Button>
    </Space>
)

interface CompanyProps extends CompanyNameProps, FailedCompanyProps {
    companyFetchStatus: string
}
const CompanyDetails: FC<React.PropsWithChildren<CompanyProps>> = ({
    companyFetchStatus,
    company,
    onCompanyClick,
    companyFetchRetry,
}) =>
    companyFetchStatus === 'error' ? (
        <FailedCompany companyFetchRetry={companyFetchRetry} />
    ) : (
        <NotFailedCompany company={company} onCompanyClick={() => onCompanyClick(company.id)} />
    )

const ResetPasscodeConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Reset passcode?</Text>
        <Paragraph>
            By resetting the passcode, you acknowledge that you have confirmed the identity of the
            user and given them access to their account.
        </Paragraph>
    </Space>
)

const ResetTwoFactorConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Reset two-factor?</Text>
        <Paragraph>
            By resetting the two-factor, you acknowledge that you have confirmed the identity of the
            user and given them access to their account.
        </Paragraph>
    </Space>
)

const DeleteRefreshTokenConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Delete users refresh token?</Text>
        <Paragraph>This will sign the user out of all their trusted devices and browsers</Paragraph>
    </Space>
)

const ResendChangeEmailVerification: FC<React.PropsWithChildren<{ email?: string }>> = ({
    email,
}) => (
    <Space direction="vertical">
        <Text strong>Resend change email verification?</Text>
        <Paragraph>
            You will resend the verification to the pending email ({email}) of the user.
        </Paragraph>
    </Space>
)

const ResendInvitationConfirmation = () => (
    <Text strong>Are you sure you want to resend invite?</Text>
)

const DeactivateEntityEmployeeConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Deactivate employee?</Text>
        <Paragraph>Are you sure you want to deactivate this employee?</Paragraph>
    </Space>
)

const FetchStatus = ({ fetchStatus }: FetchStatusProps) =>
    fetchStatus && fetchStatus.toLowerCase() !== 'none' ? (
        <span>Connected via {fetchStatus.toLowerCase()}</span>
    ) : (
        <span>Not connected</span>
    )

const CreatedDate = ({
    isEntityEmployee,
    entityEmployeeCreatedAt,
    isEntityEmployeeLoading,
    userCreatedAt,
}: {
    isEntityEmployee: boolean
    entityEmployeeCreatedAt?: string
    isEntityEmployeeLoading: boolean
    userCreatedAt?: string
}) => {
    if (isEntityEmployee) {
        return isEntityEmployeeLoading ? (
            <Skeleton.Input active size="small" />
        ) : (
            <Text>{formatTimestamp(entityEmployeeCreatedAt)}</Text>
        )
    }

    return userCreatedAt ? <Text>{formatTimestamp(userCreatedAt)}</Text> : null
}

const PhoneNumber = ({
    isEntityEmployee,
    entityEmployeePhoneNumber,
    isEntityEmployeeLoading,
    userPhoneNumber,
}: {
    isEntityEmployee: boolean
    entityEmployeePhoneNumber?: string
    isEntityEmployeeLoading: boolean
    userPhoneNumber?: string | null
}) => {
    if (isEntityEmployee) {
        return isEntityEmployeeLoading ? (
            <Skeleton.Input active size="small" />
        ) : (
            <Text copyable={!!entityEmployeePhoneNumber}>
                {entityEmployeePhoneNumber ?? 'None'}
            </Text>
        )
    }

    return userPhoneNumber ? <Text copyable={!!userPhoneNumber}>{userPhoneNumber}</Text> : null
}

const EmployeeId = ({
    isEntityEmployee,
    entityEmployeeId,
    isEntityEmployeeLoading,
    userEmployeeId,
}: {
    isEntityEmployee: boolean
    entityEmployeeId?: string
    isEntityEmployeeLoading: boolean
    userEmployeeId?: string
}) => {
    if (isEntityEmployee) {
        return isEntityEmployeeLoading ? (
            <Skeleton.Input active size="small" />
        ) : (
            <Text copyable={!!entityEmployeeId}>{entityEmployeeId ?? 'None'}</Text>
        )
    }

    return userEmployeeId ? <Text copyable={!!userEmployeeId}>{userEmployeeId}</Text> : null
}

const EmployeeRoleDisplay = ({
    isEntityEmployee,
    entityEmployeeRole,
    isEntityEmployeeLoading,
    userRole,
}: {
    isEntityEmployee: boolean
    entityEmployeeRole?: string
    isEntityEmployeeLoading: boolean
    userRole?: string
}) => {
    if (isEntityEmployee) {
        return isEntityEmployeeLoading ? (
            <Skeleton.Input active size="small" />
        ) : (
            <Text>{entityEmployeeRole ?? '-'}</Text>
        )
    }

    return userRole ? <Text>{userRole}</Text> : null
}

interface Props {
    goBack: () => void
    user: User | null
    employeeId?: string
    isOrganizationUser?: boolean
    partnerEmployeeId: string
    partner: any
    partnerId: string
    company: AdminCompany
    companyFetchStatus: CurrentStatus
    primaryCompanyId?: string
    companyFetchRetry: () => void
    resetPasscode: () => void
    resetTwoFactor: () => void
    resendChangeEmail: () => void
    resendInvite: () => void
    editInformationOnClick: () => void
    onCompanyClick: (companyId: string) => void
    onPartnerClick: () => void
    deleteToken: () => void
}

export const UserDetails: FC<React.PropsWithChildren<Props>> = ({
    children,
    goBack,
    user,
    employeeId,
    isOrganizationUser,
    partnerEmployeeId,
    partner,
    partnerId,
    company,
    companyFetchStatus,
    primaryCompanyId,
    companyFetchRetry,
    resetPasscode,
    resetTwoFactor,
    resendChangeEmail,
    resendInvite,
    deleteToken,
    editInformationOnClick,
    onCompanyClick,
    onPartnerClick,
}) => {
    const { employeeId: entityEmployeeId } = useParams()
    const { data: entityEmployee, isLoading: isLoadingEntityEmployee } =
        bff.userDetails.getEmployee.useQuery({
            employeeId: entityEmployeeId ?? employeeId,
        })
    const { data: employeeWithFetch } = useFetchStatusEmployee(employeeId)
    const companyId = entityEmployee?.companyId
    const { data: entityEmployeeCompany, isValidating: entityEmployeeCompanyIsValidating } =
        useGetCompany(companyId)
    const isSpendingEntityEmployee = entityEmployee?.isSpendingEntity
    const navigate = useNavigate()
    const hasEmployee360Permissions = useHasPermissions(['employee-360'])
    // we hide the header actions if the employee is an entity employee of an organization user
    const showHeaderActions = Boolean(!entityEmployeeId)

    const userLocks = useUserLocks(user?.id)
    const email = user?.email ?? ''
    const isEmailPendingChange = !!(user?.data.changeEmail && user.verifyToken)
    const isUserVerified = !!user?.verified
    const displayHasPasscode = user?.hasPasscode ? 'Yes' : 'No'
    const userTypes = getUserTypes(user)
    const bookkeeperCompanies = (user?.roles ?? [])
        .filter(
            (role) =>
                role.resource === 'company' &&
                [RoleType.BOOKKEEPER_BASIC, RoleType.BOOKKEEPER_EXTENDED].includes(role.type)
        )
        .map((role) => role.resourceId)

    const navigateToEmployee360 = () => {
        if (userTypes.isBookkeeper && bookkeeperCompanies.length === 1) {
            const firstBookkeeperCompanyId = bookkeeperCompanies[0]

            navigate(`/customer-success/employee-360/${firstBookkeeperCompanyId}/${employeeId}`)
            return
        }

        navigate(`/customer-success/employee-360/${companyId || company.id}/${employeeId}`)
    }

    const headerActions = () => {
        const actions = []
        if (isUserVerified) {
            if (isEmailPendingChange) {
                actions.push(
                    <Popconfirm
                        title={<ResendChangeEmailVerification email={user?.data.changeEmail} />}
                        onConfirm={resendChangeEmail}
                        okText="Yes"
                        cancelText="No"
                        placement="bottom"
                        overlayStyle={{ width: '400px' }}
                        key="resend-change-email-confirmation"
                    >
                        <Button data-testid="reset-email-button">Resend change email</Button>
                    </Popconfirm>
                )
            }
            actions.push(
                <Popconfirm
                    title={<DeleteRefreshTokenConfirmation />}
                    onConfirm={deleteToken}
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                    overlayStyle={{ width: '400px' }}
                    key="delete-refresh-token-confirmation"
                >
                    <Button data-testid="delete-refresh-token-button">Delete refresh token</Button>
                </Popconfirm>,
                <Divider type="vertical" key="header-actions-divider" />,
                <Popconfirm
                    title={<ResetPasscodeConfirmation />}
                    onConfirm={resetPasscode}
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                    overlayStyle={{ width: '400px' }}
                    key="reset-passcode-confirmation"
                >
                    <Button data-testid="reset-passcode-button">Reset passcode</Button>
                </Popconfirm>,
                <Popconfirm
                    title={<ResetTwoFactorConfirmation />}
                    onConfirm={resetTwoFactor}
                    okText="Yes"
                    cancelText="No"
                    placement="bottom"
                    overlayStyle={{ width: '400px' }}
                    key="reset-two-factor-confirmation"
                >
                    <Button data-testid="reset-phone-number-button">Reset two-factor</Button>
                </Popconfirm>
            )
        } else {
            actions.push(
                <Popconfirm
                    title={<ResendInvitationConfirmation />}
                    onConfirm={resendInvite}
                    okText="Yes"
                    cancelText="No"
                    overlayStyle={{ width: '400px' }}
                    key="resend-invite-confirmation"
                >
                    <Button data-testid="resend-invite-button">Resend invite</Button>
                </Popconfirm>
            )
        }
        actions.push(
            <Button
                onClick={editInformationOnClick}
                data-testid="edit-info-button"
                key="edit-information"
            >
                Edit information
            </Button>
        )

        !isOrganizationUser &&
            actions.unshift(
                <Tooltip
                    color="white"
                    title={
                        !hasEmployee360Permissions && (
                            <Typography.Text>
                                Permissions required:{' '}
                                <Typography.Text code style={{ whiteSpace: 'nowrap' }}>
                                    employee-360
                                </Typography.Text>
                            </Typography.Text>
                        )
                    }
                    key="employee-360-tooltipw"
                >
                    <Button
                        data-testid="header-employee-360-button"
                        onClick={navigateToEmployee360}
                        type="primary"
                        disabled={!hasEmployee360Permissions}
                    >
                        Employee 360
                    </Button>
                </Tooltip>
            )

        return actions
    }

    const deactivateEntityEmployee = () => {
        if (!entityEmployee) {
            return null
        }

        return (
            <Popconfirm
                title={<DeactivateEntityEmployeeConfirmation />}
                onConfirm={async () => {
                    if (entityEmployee.type === EmployeeType.BOOKKEEPER) {
                        // We want to explictely delete a bookkeeper.
                        // This ensure the roles are cleaned up in kerberos, the user record soft deleted in kerberos, and the employee record soft deleted in deimos
                        await deleteBookkeeper(entityEmployeeId, entityEmployee.email)
                    } else {
                        await deactivateEmployee(entityEmployeeId)
                    }
                }}
                okText="Yes"
                cancelText="No"
                placement="bottom"
                overlayStyle={{ width: '400px' }}
            >
                <Button danger data-testid="deactivate-entity-employee">
                    Deactivate Employee
                </Button>
            </Popconfirm>
        )
    }

    let SCAStatus = 'No'
    switch (user?.scaSecondFactor) {
        case UserTFAType.GOOGLE_AUTH:
            SCAStatus = 'Google Auth TOTP'
            break
        case UserTFAType.PHONE:
            SCAStatus = 'Phone SMS OTP'
            break
    }

    const msDisplay = (ms: number) => {
        if (ms < 1_000) {
            return `${ms} ms.`
        } else if (ms < 60 * 1_000) {
            return `${Math.round(ms / 1000)} sec.`
        } else if (ms < 60 * 60 * 1_000) {
            return `~${Math.round(ms / 1000 / 60)} min.`
        } else if (ms < 24 * 60 * 60 * 1_000) {
            return `~${Math.round(ms / 1000 / 60 / 60)} hours`
        } else {
            return `~${Math.round(ms / 1000 / 60 / 60 / 24)} days`
        }
    }

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={goBack}
                title={
                    <>
                        {entityEmployee?.firstName || user?.data?.profile?.name?.firstName}{' '}
                        {entityEmployee?.lastName || user?.data?.profile?.name?.lastName}{' '}
                        {!isUserVerified && '(Pending)'}
                    </>
                }
                extra={showHeaderActions ? headerActions() : deactivateEntityEmployee()}
            >
                <Descriptions size="small">
                    <Descriptions.Item label="Created">
                        <CreatedDate
                            isEntityEmployee={!!entityEmployeeId}
                            entityEmployeeCreatedAt={entityEmployee?.createdAt}
                            isEntityEmployeeLoading={isLoadingEntityEmployee}
                            userCreatedAt={user?.createdAt}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                        <Text copyable>{email}</Text>
                    </Descriptions.Item>
                    {isEmailPendingChange && (
                        <Descriptions.Item label="New Email">
                            <Text copyable={{ text: user.data.changeEmail }}>
                                {user.data.changeEmail} (Pending)
                            </Text>
                        </Descriptions.Item>
                    )}

                    <Descriptions.Item label="Phone no.">
                        <PhoneNumber
                            isEntityEmployee={!!entityEmployeeId}
                            entityEmployeePhoneNumber={entityEmployee?.phone}
                            isEntityEmployeeLoading={isLoadingEntityEmployee}
                            userPhoneNumber={user?.phone}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Passcode">{displayHasPasscode}</Descriptions.Item>
                    <Descriptions.Item label="Phone verified">
                        {user?.phoneVerified ? 'Yes' : 'No'}
                    </Descriptions.Item>
                    <Descriptions.Item label="SCA">{SCAStatus}</Descriptions.Item>

                    {userLocks.data?.PASSCODE.tries.locked && (
                        <Descriptions.Item label="Passcode">
                            Locked for {msDisplay(userLocks.data?.PASSCODE.tries.wait)}
                            <Fragment>&nbsp;</Fragment>{' '}
                            <Link onClick={() => userLocks.mutations.unlock(LockType.PASSCODE)}>
                                [Unlock]
                            </Link>
                        </Descriptions.Item>
                    )}
                    {userLocks.data?.PHONE_OTP.generations.locked && (
                        <Descriptions.Item label="Phone OTP">
                            Locked for {msDisplay(userLocks.data?.PHONE_OTP.generations.wait)}
                            <Fragment>&nbsp;</Fragment>
                            <Link onClick={() => userLocks.mutations.unlock(LockType.PHONE_OTP)}>
                                [Unlock]
                            </Link>
                        </Descriptions.Item>
                    )}
                    {userLocks.data?.GOOGLE_AUTH.tries.locked && (
                        <Descriptions.Item label="GoogleAuth TOTP">
                            Locked for {msDisplay(userLocks.data?.GOOGLE_AUTH.tries.wait)}
                            <Fragment>&nbsp;</Fragment>{' '}
                            <Link onClick={() => userLocks.mutations.unlock(LockType.GOOGLE_AUTH)}>
                                [Unlock]
                            </Link>
                        </Descriptions.Item>
                    )}
                </Descriptions>
                {!isOrganizationUser && company && (
                    <Descriptions size="small">
                        <Descriptions.Item label="Role">
                            <EmployeeRoleDisplay
                                isEntityEmployee={!!entityEmployeeId}
                                entityEmployeeRole={entityEmployee?.displayRole}
                                isEntityEmployeeLoading={isLoadingEntityEmployee}
                                userRole={user ? getUserTypeDisplay(user) : '-'}
                            />
                        </Descriptions.Item>
                        {(userTypes.isAdmin || userTypes.isEmployee) &&
                            (companyFetchStatus === 'fetching' ||
                            entityEmployeeCompanyIsValidating ? (
                                <Descriptions.Item label="Company">
                                    <Skeleton.Input active size="small" />
                                </Descriptions.Item>
                            ) : (
                                <Descriptions.Item label="Company">
                                    <CompanyDetails
                                        companyFetchStatus={companyFetchStatus}
                                        company={entityEmployeeCompany ?? company}
                                        onCompanyClick={onCompanyClick}
                                        companyFetchRetry={companyFetchRetry}
                                    />
                                </Descriptions.Item>
                            ))}
                        <Descriptions.Item label="Fetch status">
                            <FetchStatus fetchStatus={employeeWithFetch} />
                        </Descriptions.Item>
                        {userTypes.isBookkeeper && (
                            <Descriptions.Item label="Companies">
                                <Space direction="vertical">
                                    {bookkeeperCompanies.map((bookkeeperCompanyId) => (
                                        <Text copyable key={bookkeeperCompanyId}>
                                            {bookkeeperCompanyId}
                                        </Text>
                                    ))}
                                </Space>
                            </Descriptions.Item>
                        )}
                        {(userTypes.isPartnerAdmin || userTypes.isPartnerEmployee) && partnerId && (
                            <Descriptions.Item label="Partner">
                                <Link disabled={!partner?.id} onClick={onPartnerClick}>
                                    {partner?.name ?? '-'}
                                </Link>
                            </Descriptions.Item>
                        )}
                        {isLoadingEntityEmployee ? (
                            <Skeleton.Input active size="small" />
                        ) : (
                            entityEmployee && (
                                <Descriptions.Item label="Spending Entity">
                                    {entityEmployee.isSpendingEntity ? 'Yes' : 'No'}
                                </Descriptions.Item>
                            )
                        )}
                        {isLoadingEntityEmployee ? (
                            <Skeleton.Input active size="small" />
                        ) : (
                            entityEmployee &&
                            isSpendingEntityEmployee && (
                                <Descriptions.Item label="Spending Entity Employee">
                                    <Text>True</Text>
                                </Descriptions.Item>
                            )
                        )}
                    </Descriptions>
                )}
                <Descriptions size="small">
                    <Descriptions.Item label="User ID">
                        {user?.id ? (
                            <Paragraph
                                copyable
                                ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                                style={{ margin: 0, maxWidth: 160 }}
                            >
                                {user.id}
                            </Paragraph>
                        ) : (
                            '-'
                        )}
                    </Descriptions.Item>
                    {!isOrganizationUser && (userTypes.isAdmin || userTypes.isEmployee) && (
                        <Descriptions.Item label="Employee ID">
                            <EmployeeId
                                isEntityEmployee={!!entityEmployeeId}
                                entityEmployeeId={entityEmployeeId}
                                isEntityEmployeeLoading={isLoadingEntityEmployee}
                                userEmployeeId={employeeId}
                            />
                        </Descriptions.Item>
                    )}
                    {(userTypes.isPartnerAdmin || userTypes.isPartnerEmployee) && (
                        <Descriptions.Item label="Partner employee ID">
                            <Paragraph
                                copyable
                                ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                                style={{ margin: 0, maxWidth: 160 }}
                            >
                                {partnerEmployeeId}
                            </Paragraph>
                        </Descriptions.Item>
                    )}
                    {(userTypes.isPartnerAdmin || userTypes.isPartnerEmployee) && partnerId && (
                        <Descriptions.Item label="Partner">
                            <Paragraph
                                copyable
                                ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                                style={{ margin: 0, maxWidth: 160 }}
                            >
                                {partnerId}
                            </Paragraph>
                        </Descriptions.Item>
                    )}
                    {primaryCompanyId && (
                        <Descriptions.Item label="Primary Company ID">
                            <Paragraph
                                copyable
                                ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                                style={{ margin: 0, maxWidth: 160 }}
                            >
                                {primaryCompanyId}
                            </Paragraph>
                        </Descriptions.Item>
                    )}
                </Descriptions>
            </PageHeader>
            {children}
        </>
    )
}
