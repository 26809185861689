import React, { FC, useState } from 'react'
import { Empty } from 'antd'
import SnapshotTabs from 'pages/compliance/company/shared/snapshot-tabs/snapshot-tabs'
import type * as styx from 'types/styx'
import AssessmentResult from 'pages/compliance/closed-company/risk-assessment/assessment-result'
import AssessmentSnapshot from 'pages/compliance/shared/risk-assessment/assessment-snapshot/assessment-snapshot'
import RiskCheckForm from 'pages/compliance/closed-company/risk-assessment/risk-check-form'
import {
    useStyxClosedCompany,
    useClosedCompanyKycCheckpoints,
} from 'services/deimos/styx-company/styx-company'
import { ContentContainer } from 'components/layout-containers'
import Spinner from 'components/content-spinner'
import { useClosedCompanyRiskAssessment } from 'services/styx/risk-assessment'
interface Props {
    loading: boolean
    riskAssessment?: styx.RiskAssessment
}

export const RiskAssessment: FC<React.PropsWithChildren<Props>> = ({ riskAssessment, loading }) => {
    if (loading) {
        return <Spinner />
    }

    if (!riskAssessment) {
        return <Empty description="There is no risk assessment for this company." />
    }

    return (
        <ContentContainer>
            <AssessmentResult />
            <RiskCheckForm />
        </ContentContainer>
    )
}

const RiskAssessmentContainer: FC<React.PropsWithChildren<unknown>> = () => {
    const [snapshot, setSnapshot] = useState<string | undefined>()
    const { riskAssessment, isValidating } = useStyxClosedCompany()
    const { data: snapshotAssessment, error } = useClosedCompanyRiskAssessment(
        snapshot,
        true
    )
    const kycCheckpoints = useClosedCompanyKycCheckpoints()

    const onFetchSnapshot = (snapshotDate?: string) => {
        setSnapshot(snapshotDate)
    }

    return (
        <SnapshotTabs
            error={error}
            kycCheckpoints={kycCheckpoints}
            onFetchSnapshot={onFetchSnapshot}
            readonlyChildren={() => <AssessmentSnapshot riskAssessment={snapshotAssessment} />}
        >
            <RiskAssessment loading={isValidating} riskAssessment={riskAssessment} />
        </SnapshotTabs>
    )
}

export default RiskAssessmentContainer
