import dayjs from 'packages/dayjs'

const twoWeekFromNow = dayjs().add(2, 'week').toISOString()

export const initialValues = {
    company: {
        address: {
            country: '',
            city: '',
        },
        language: '',
        mileage: {
            rate: '',
            currency: '',
        },
        wallet: {
            initialLoad: 1000,
            loadHistory: [],
        },
        autoTopUp: null,
    },
    employees: [
        {
            name: 'Admin',
            count: 1,
            limits: {
                card: {
                    type: 'MONTHLY',
                    value: 10000,
                },
                perPurchase: 'LV1',
            },
            virtualCard: true,
            physicalCard: null,
        },
        {
            name: 'Employee',
            count: 1,
            limits: {
                card: {
                    type: 'MONTHLY',
                    value: 1000,
                },
                perPurchase: 'LV1',
            },
            virtualCard: true,
            physicalCard: null,
        },
    ],
    teams: [],
    expenses: [
        {
            name: 'Online',
            expenseType: 'ONLINE_PRESENTMENT',
            merchantProfiles: [],
        },
        {
            name: 'In store',
            expenseType: 'PHYSICAL_PRESENTMENT',
            merchantProfiles: [],
        },
        {
            name: 'Mileage',
            expenseType: 'MILEAGE',
            merchantProfiles: [],
        },
        {
            name: 'Per Diem',
            expenseType: 'PER_DIEM',
            merchantProfiles: [],
        },
        {
            name: 'Pocket',
            expenseType: 'POCKET',
            merchantProfiles: [],
        },
    ],
    additionalExpenses: false,
    meta: {
        companyName: '',
        requesterEmail: '',
        expiresAt: twoWeekFromNow,
    },
    selectedProfileId: '',
}
