import { createBffHooks } from 'packages/bff/create-bff-hooks'
import type { EntitlementsRouter } from './index.bff'

export const bff = createBffHooks<EntitlementsRouter>({
    /**
     * The default in createBffHooks is to invalidate the entire query cache,
     * for performance, we don't use this behaviour here as it's unneccessary.
     */
    unstable_overrides: {
        useMutation: {
            async onSuccess(opts) {
                await opts.originalFn()
            },
        },
    },
}).entitlements
