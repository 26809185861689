import type { FC } from 'react'
import {
    BillBankDetailsError,
    BillBankDetailsErrorType,
    GetBillInvoiceResponse,
} from '@pleo-io/deimos'
import { Descriptions, Typography } from 'antd'
import { Link } from 'react-router-dom'

const { Text } = Typography
const { Item } = Descriptions

const getBankDetailsErrorText = (error: BillBankDetailsError): string => {
    switch (error.type) {
        case BillBankDetailsErrorType.MISSING_FIELD:
        case BillBankDetailsErrorType.SEMANTIC:
        case BillBankDetailsErrorType.DOMESTIC:
            return `${error.type}(${error.source})`
        default:
            return error.type
    }
}

export const BillInvoiceSupplierInformation: FC<{
    invoice?: GetBillInvoiceResponse
}> = ({ invoice }) => {
    const supplier = invoice?.supplier
    const bankDetailsErrors = supplier?.bankDetailsErrors ?? []
    const bankAccount = supplier?.bankAccounts?.[0]
    return (
        <Descriptions bordered column={{ xxl: 3, xl: 2, md: 1, sm: 1 }} size="small">
            <Item label="Supplier ID">
                <Link to={`/customer-success/invoices/supplier/${supplier?.id}`}>
                    <Text copyable>{supplier?.id ?? ''}</Text>
                </Link>
            </Item>
            <Item label="Supplier Name">
                <Text copyable={Boolean(supplier?.name)}>{supplier?.name ?? '-'}</Text>
            </Item>
            <Item label="Status">{supplier?.status ?? '-'}</Item>
            <Item label="Bank details errors">
                {bankDetailsErrors.length === 0 && '-'}
                {bankDetailsErrors.map((error) => getBankDetailsErrorText(error)).join(', ')}
            </Item>
            <Item label="Country code">{supplier?.address?.countryCode ?? '-'}</Item>
            <Item label="IBAN">
                <Text copyable={Boolean(bankAccount?.iban)}>{bankAccount?.iban ?? '-'}</Text>
            </Item>
            <Item label="BIC">
                {/* bankAccount.bic is a standard 8-char BIC, but the invoice.bic has a full 11-char one (when applicable) */}
                <Text copyable={Boolean(bankAccount?.bic)}>{bankAccount?.bic ?? '-'}</Text>
            </Item>
            <Item label="Account number">
                <Text copyable={Boolean(bankAccount?.accountNumber)}>
                    {bankAccount?.accountNumber ?? '-'}
                </Text>
            </Item>
            <Item label="Bank code">
                <Text copyable={Boolean(bankAccount?.bankCode)}>
                    {bankAccount?.bankCode ?? '-'}
                </Text>
            </Item>
            <Item label="Bank name">
                <Text copyable={Boolean(bankAccount?.bankName)}>
                    {bankAccount?.bankName ?? '-'}
                </Text>
            </Item>
        </Descriptions>
    )
}
