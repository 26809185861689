import type { FC } from 'react'
import { message } from 'antd'
import { Formik } from 'formik'
import { Form, Select, Radio, SubmitButton } from 'formik-antd'

import type { UnknownEntity, Node } from 'types/styx'
import { getEntityName } from 'pages/compliance/shared/utils'

import { validationSchema, FormValues, mapValuesToRequest } from './validation-schema'

const { Option, OptGroup } = Select

interface Props {
    companies: Node[]
    selectedShareholderCompanyId?: string
    unknownEntities: UnknownEntity[]
    getUnknownEntities: (styxId: string) => void
    disabled: boolean
    convertUnknownEntity: (
        entityType: 'PERSON' | 'COMPANY',
        unknownEntity: UnknownEntity
    ) => Promise<void>
}

const ConvertUnknownsForm: FC<React.PropsWithChildren<Props>> = ({
    companies,
    unknownEntities,
    getUnknownEntities,
    disabled,
    convertUnknownEntity,
    selectedShareholderCompanyId,
}) => {
    const onSubmit = async (values: FormValues) => {
        try {
            const unknownEntity = mapValuesToRequest(values, unknownEntities)

            if (!unknownEntity) {
                return
            }

            await convertUnknownEntity(values.entityType, unknownEntity)
            message.success(`Successfully converted ${unknownEntity.name} to ${values.entityType}`)
        } catch (e) {
            message.error(`Failed to convert unknown: ${(e as Error).message}`)
        }
    }
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{
                company: selectedShareholderCompanyId ?? '',
                selectedEntity: '',
                entityType: 'PERSON',
            }}
            onSubmit={onSubmit}
        >
            <Form layout="vertical">
                <Form.Item name="company" label="Select shareholder company">
                    <Select
                        showSearch
                        optionFilterProp="label"
                        onChange={getUnknownEntities}
                        name="company"
                        defaultValue={selectedShareholderCompanyId}
                        placeholder="Select a company"
                    >
                        {companies.map(({ name, id }) => (
                            <Option label={name} key={id} value={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="selectedEntity" label="Entity to convert">
                    <Select
                        showSearch
                        optionFilterProp="label"
                        name="selectedEntity"
                        placeholder="Select entity to convert"
                        disabled={disabled}
                    >
                        <OptGroup label="Unknown Entities">
                            {unknownEntities.map((entity) => (
                                <Option
                                    label={getEntityName(entity)}
                                    key={entity.id}
                                    value={entity.id}
                                >
                                    {getEntityName(entity)}
                                </Option>
                            ))}
                        </OptGroup>
                    </Select>
                </Form.Item>
                <Form.Item name="entityType" label="Select entity type">
                    <Radio.Group name="entityType" disabled={disabled}>
                        <Radio.Button value="PERSON">Person</Radio.Button>
                        <Radio.Button value="COMPANY">Company</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <div>
                    <SubmitButton data-testid="submit">Convert entity</SubmitButton>
                </div>
            </Form>
        </Formik>
    )
}

export default ConvertUnknownsForm
