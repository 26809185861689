import useSWR from 'swr'
import request, { fetcher } from 'services/styx/request'
import type { ErrorResponse } from '@pleo-io/deimos'
import type { AmlAnswersResponse, AmlAmountRange } from 'types/styx'
import { notification } from 'antd'

const legacyAmountRanges = [
    { minimum: 0, maximum: 100000, currency: 'GBP', active: false },
    { minimum: 100000, maximum: 350000, currency: 'GBP', active: false },
    { minimum: 350000, currency: 'GBP', active: false },
]

export const useAmlAverageAmountRanges = (country?: string) => {
    const url = `rest/v2/aml-answers/average-amount-ranges?country=${country}`

    const response = useSWR<AmlAmountRange[], ErrorResponse>(url, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })

    const amountRanges = (response.data ?? []).map((range) => ({ ...range, active: true }))

    return {
        ...response,
        data: [...amountRanges, ...legacyAmountRanges],
    }
}

export function useAmlAnswers(companyId: string) {
    const response = useSWR<AmlAnswersResponse>(
        `rest/v2/aml-answers/companies/${companyId}`,
        fetcher,
        {
            shouldRetryOnError: false,
            revalidateOnFocus: false,
        }
    )

    const putAverageAmountRange = async (averageAmountRange: AmlAmountRange) => {
        try {
            await request().put(`rest/v2/aml-answers/companies/${companyId}`, {
                json: {
                    continents: response.data?.continents,
                    categories: response.data?.categories,
                    amountRange: response.data?.amountRange,
                    frequentlyUsedInCountries: response.data?.frequentlyUsedInCountries,
                    averageAmountRange,
                },
            })
            response.mutate()
        } catch (e) {
            notification.error({ message: 'Failed to update average amount range' })
        }
    }

    return {
        amlAnswers: response?.data,
        isLoading: !response?.data && !response?.error,
        putAverageAmountRange,
        ...response,
    }
}
