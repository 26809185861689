import type { ProofOfFunds } from '@pleo-io/deimos'
import { Result, Table, Tag } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import type { FC } from 'react'
import { useGetCompanyProofOfFunds } from 'services/deimos/proof-of-funds'
import { Status } from 'types/proof-of-funds'
import dayjs from 'packages/dayjs'

interface AllSourcesTableProps {
    loading: boolean
    proofOfFunds: ProofOfFunds[]
}

const AllSourcesTable: FC<React.PropsWithChildren<AllSourcesTableProps>> = ({
    loading,
    proofOfFunds,
}) => {
    const columns: ColumnProps<ProofOfFunds>[] = [
        {
            title: 'Account',
            dataIndex: 'senderBank',
        },
        {
            title: 'Sender',
            dataIndex: 'senderInformation',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            filters: Object.keys(Status).map((status) => ({ value: status, text: status })),
            onFilter: (value, proofOfFund) => proofOfFund.status === value,
            render: (status) => {
                switch (status) {
                    case Status.APPROVED:
                        return <Tag color="green">Approved</Tag>
                    case Status.REJECTED:
                        return <Tag color="red">Rejected</Tag>
                    case Status.PENDING:
                        return <Tag color="blue">Pending</Tag>
                    case Status.INVESTIGATING:
                        return <Tag color="orange">Investigating</Tag>
                }
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            render: (amount, { currency }) => (
                <span>
                    {amount} {currency}
                </span>
            ),
            sorter: (a, b) => a.amount - b.amount,
        },
        {
            title: 'Performed at',
            dataIndex: 'performedAt',
            render: (performedAt) => dayjs(performedAt).format('lll'),
            sorter: (a, b) => dayjs(a.performedAt).valueOf() - dayjs(b.performedAt).valueOf(),
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={proofOfFunds}
            loading={loading}
            pagination={false}
            rowKey="id"
            scroll={{ x: 'max-content' }}
        />
    )
}

interface AllSourcesProps {
    companyId: string
}

const AllSources: FC<React.PropsWithChildren<AllSourcesProps>> = ({ companyId }) => {
    const {
        data: proofOfFunds,
        error,
        isValidating: loading,
    } = useGetCompanyProofOfFunds(companyId)

    if (error) {
        return <Result status="500" title="Something went wrong" subTitle={error.message} />
    }

    return <AllSourcesTable proofOfFunds={proofOfFunds ?? []} loading={!proofOfFunds && loading} />
}

export default AllSources
