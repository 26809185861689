import type { FC } from 'react'
import ChangeExpensesStatusComponent from './change-expenses-status'

interface ChangeExpensesStatusContainerProps extends React.PropsWithChildren<unknown> {
    shouldUseDeimos: boolean
}

const ChangeExpensesStatusContainer: FC<ChangeExpensesStatusContainerProps> = ({
    shouldUseDeimos,
}) => <ChangeExpensesStatusComponent shouldUseDeimos={shouldUseDeimos} />

export default ChangeExpensesStatusContainer
