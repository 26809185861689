import { Typography } from 'antd'
import type { TextProps } from 'antd/lib/typography/Text'
import type { FC } from 'react'
import styled from 'styled-components'
import type { Address } from 'types/deimos-company'

const { Text } = Typography

const CompanyAddress: FC<React.PropsWithChildren<{ address: Address; textProps?: TextProps }>> = ({
    address,
    textProps,
}) => (
    <AddressContainer>
        <Text {...textProps}>
            {address.addressLine1 ?? '-'}
            {address.addressLine2 ? ` ${address.addressLine2} ` : ''}
        </Text>
        <Text {...textProps}>
            {address.locality ?? '-'}
            {address.region ? `, ${address.region} ` : ''}
        </Text>
        <Text {...textProps}>
            {address.postalCode ? `${address.postalCode}, ` : ''}
            {address.country ?? '-'}
        </Text>
    </AddressContainer>
)

const AddressContainer = styled.address`
    display: grid;
`

export default CompanyAddress
