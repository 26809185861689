import type { FC } from 'react'
import { Formik } from 'formik'
import { Status, IrreversibleStatus, ChangeStatus } from 'types/card'
import { Button, Modal } from 'antd'

import styled from 'styled-components'
import { spacing, inputWidth } from 'theme/tokens'
import { FormItem, Form, Select } from 'formik-antd'

const { Option } = Select

interface Props {
    initialStatus: Status
    onSubmit: (values: FormValues) => void
}

export type FormValues = {
    status: Status
}

const CardStatusForm: FC<React.PropsWithChildren<Props>> = ({ initialStatus, onSubmit }) => (
    <Formik initialValues={{ status: initialStatus }} onSubmit={onSubmit} enableReinitialize>
        {({ setFieldValue, values, isSubmitting }) => (
            <Form>
                <FormItem name="status" label="Status" colon>
                    <Select
                        name="status"
                        defaultValue={initialStatus}
                        style={{ width: `${inputWidth.large}` }}
                        onSelect={(value: string) => {
                            if (IrreversibleStatus.includes(value as Status)) {
                                return
                            }
                            Modal.confirm({
                                centered: true,
                                title: 'Irreversible card status',
                                content: (
                                    <p>
                                        After setting the card status to {value}, it cannot be
                                        undone. Are you sure?
                                    </p>
                                ),
                                onCancel: () => {
                                    setFieldValue('status', values.status)
                                },
                            })
                        }}
                    >
                        {ChangeStatus.map((status) => (
                            <Option key={status} value={status}>
                                {status}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
                <ButtonContainer>
                    <Button type="primary" htmlType="submit" loading={isSubmitting}>
                        Save
                    </Button>
                </ButtonContainer>
            </Form>
        )}
    </Formik>
)

export default CardStatusForm

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: ${spacing.space16};
`
