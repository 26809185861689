import useSWR from 'swr'
import request from './request'

export interface StorageItemResponse<T = any> {
    key: T
    value: string | null
}

interface StorageItemPayload<T = any> {
    key: T
    value: string
    companyId: string
    membership: string
}

/**
 * @deprecated
 * As the exploration to migrate Deimo’s storage DB is underway, it is recommended not to add new keys to the storage.
 * If you have a requirement to add new keys, please reach out to the #killing-deimos-general channel.
 */
function setStorageItem({ key, value, companyId, membership }: StorageItemPayload) {
    return request().put(`rest/v1/admin/companies/${companyId}/storage/${key}`, {
        json: { value, membership },
    })
}

function deleteStorageItem({ key, companyId, membership }: Omit<StorageItemPayload, 'value'>) {
    return request().delete(`rest/v1/admin/companies/${companyId}/storage/${key}`, {
        json: { membership },
    })
}

const storageFetcher = (url: string) =>
    request()
        .get(url)
        .then((res) => res.json())

export function useDeimosStorage({ companyId, key }: { companyId: string; key: string }) {
    const result = useSWR<StorageItemResponse<typeof key> | null, Error>(
        companyId ? `rest/v1/admin/companies/${companyId}/storage/${key}` : '',
        storageFetcher
    )
    /**
     * @deprecated
     * As the exploration to migrate Deimo’s storage DB is underway, it is recommended not to add new keys to the storage.
     * If you have a requirement to add new keys, please reach out to the #killing-deimos-general channel.
     */
    async function setItem(value: string) {
        await setStorageItem({ key, value, companyId, membership: 'owner' })
        result.mutate()
    }
    async function deleteItem() {
        await deleteStorageItem({ key, companyId, membership: 'owner' })
        result.mutate()
    }

    return { ...result, mutations: { setItem, deleteItem } }
}
