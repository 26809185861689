import useSWR from 'swr'
import { useState } from 'react'
import { message } from 'antd'
import type { GetAllFlagPaginatedResponse, FlagRow, UnifiedFlagsQuery } from '@pleo-io/deimos'
import type { Status } from 'types/card'
import request from 'services/deimos/request'
import type {
    FlagCategory,
    ManualFlagRequest,
    MultipleManualFlags,
    InvestigationStatus,
} from 'types/ariel'
import {
    manuallyFlagTransaction,
    updateFlagInvestigationStatus,
} from 'services/ariel/transaction-monitoring'

const flagFetcher = async (
    url: string,
    to: string,
    from: string,
    statuses: InvestigationStatus[],
    categories?: FlagCategory[],
    pageSize?: number,
    pageOffset?: number
) => {
    const searchParams = new URLSearchParams()
    searchParams.append('to', to)
    searchParams.append('from', from)

    if (statuses) {
        statuses.forEach((status) => searchParams.append(`status[]`, status))
    }
    if (categories) {
        categories.forEach((category) => searchParams.append(`category[]`, category))
    }
    if (pageSize) {
        searchParams.append('pageSize', pageSize.toString())
    }
    if (pageOffset) {
        searchParams.append('pageOffset', pageOffset.toString())
    }

    const res = await request().get(url, {
        searchParams,
    })
    return await res.json()
}

export const useArielFlagsPaginated = (query: UnifiedFlagsQuery) => {
    const response = useSWR<GetAllFlagPaginatedResponse, Error>(
        [
            `rest/v1/admin/ariel/fraud/unified`,
            query.to,
            query.from,
            query.status,
            query.category,
            query.pageSize,
            query.pageOffset,
        ],
        flagFetcher,
        { shouldRetryOnError: false }
    )
    const [currentTransaction, setCurrentTransaction] = useState<string>('')
    const [failedTransaction, setFailedTransaction] = useState<string>('')
    const [successfulTransaction, setSuccessfulTransaction] = useState<string>('')

    const updateCard = async (cardId: string, status: Status) => {
        try {
            await request().put(`rest/v1/admin/cards/${cardId}`, { json: { status } })
            response.mutate()
            message.success(`Successfully updated card status to ${status}!`)
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const createManualFlag = async (manualFlagRequest: ManualFlagRequest) => {
        try {
            await manuallyFlagTransaction(manualFlagRequest)
            response.mutate()
            message.success('Successfully flagged transaction!')
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const createMultipleManualFlags = async (multipleManualFlags: MultipleManualFlags) => {
        for (const transactionId of multipleManualFlags.transactionIds) {
            try {
                setCurrentTransaction(transactionId)
                await manuallyFlagTransaction({
                    category: multipleManualFlags.category,
                    family: multipleManualFlags.family,
                    investigationStatus: multipleManualFlags.investigationStatus,
                    transactionId: transactionId,
                    notes: multipleManualFlags.notes,
                })
                setSuccessfulTransaction(transactionId)
                response.mutate()
            } catch (e) {
                setFailedTransaction(transactionId)
            }
        }
    }

    const updateInvestigationStatus = async (
        flagRows: FlagRow[],
        nextStatus: InvestigationStatus
    ) => {
        try {
            for (let i = 0; i < flagRows.length; i++) {
                await updateFlagInvestigationStatus({
                    ...flagRows[i].flag,
                    investigationStatus: nextStatus,
                })
            }

            response.mutate()
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    return {
        ...response,
        currentTransaction,
        failedTransaction,
        successfulTransaction,
        mutations: {
            createManualFlag,
            createMultipleManualFlags,
            updateInvestigationStatus,
            updateCard,
        },
    }
}
