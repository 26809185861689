import { Modal, Space, Typography } from 'antd'

export const showNotSupportedDocumentCategoryModal = async (
    action: 'create' | 'update',
    errorMessage: string
) => {
    return Modal.error({
        title: `Failed to ${action} document`,
        content: (
            <Space direction="vertical">
                <Typography.Text>{errorMessage}</Typography.Text>
                <Typography.Text strong>Please use category OTHER instead.</Typography.Text>
            </Space>
        ),
    })
}
