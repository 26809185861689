import { UploadOutlined } from '@ant-design/icons'
import { Button, message, Upload } from 'antd'
import type { UploadChangeParam } from 'antd/lib/upload'
import Papa from 'papaparse'
import type { FC } from 'react'
import type { CustomOrderFormValues } from 'store/modules/support/card-orders/types'
import type { RcCustomRequestOptions } from 'types/custom-request'
import { mapCSVToValues } from './validation-schema'

const onChange = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`)
    } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed: ${info.file.error?.message}`)
    }
}

interface Props {
    companyId: string
    isGPS: boolean
    disabled: boolean
    setValues: (values: CustomOrderFormValues) => void
}

const ImportCSV: FC<React.PropsWithChildren<Props>> = ({
    companyId,
    isGPS,
    disabled,
    setValues,
}) => (
    <Upload
        accept=".csv"
        onChange={onChange}
        showUploadList={false}
        // Use customRequest to override POST behavior
        customRequest={async ({ file, onSuccess, onError }: RcCustomRequestOptions) => {
            try {
                Papa.parse(file as any, {
                    header: true,
                    complete: function (results) {
                        const values = mapCSVToValues(results.data, companyId, isGPS)
                        setValues(values)

                        onSuccess && onSuccess(results, file as any)
                    },
                })
            } catch (e) {
                onError && onError(e as Error)
            }
        }}
    >
        <Button disabled={disabled} type="link">
            <UploadOutlined /> Import CSV
        </Button>
    </Upload>
)

export default ImportCSV
