import { Card, Modal } from 'antd'
import HistoricRequestPermissionsContainer from 'components/historic-request-permissions/historic-request-permissions-container'

export const HistoricRequestPermissionsModal = ({
    open,
    onCancel,
}: {
    open: boolean
    onCancel: () => void
}) => {
    const handleOnCancel = () => {
        onCancel()
    }

    return (
        <Modal
            open={open}
            onCancel={() => handleOnCancel()}
            title="Previous Permission Requests"
            footer={null}
            destroyOnClose
            width={1280}
        >
            <Card style={{ marginBottom: '1rem' }} type="inner">
                <HistoricRequestPermissionsContainer />
            </Card>
        </Modal>
    )
}
