import type ky from 'ky'
import {
    RefreshCompanyStructureError,
    RefreshCompanyStructureErrorType,
} from 'types/refresh-company-structure'

export const refreshStructureErrorMap: RefreshCompanyStructureError[] = [
    {
        status: 500,
        type: RefreshCompanyStructureErrorType.REGISTRY_PROVIDER_NOT_AVAILABLE,
        message: 'Company registry provider is not available at the moment',
        nextSteps: ['Try again later.'],
    },
    {
        status: 404,
        type: RefreshCompanyStructureErrorType.COMPANY_NOT_IN_REGISTRY,
        message: 'Company registry not found in external registry provider',
        nextSteps: [
            'Please check registry ID is correct.',
            'If ID is correct, create manual risk assessment.',
        ],
    },
]

export const getHandledErrorDetails = async (
    unknownError: any
): Promise<RefreshCompanyStructureError | undefined> => {
    if (unknownError?.name === 'HTTPError') {
        const kyError = unknownError as ky.HTTPError
        const kyErrorResponseJson = await kyError.response?.json()
        const errorResponse = {
            status: kyError.response?.status,
            type: kyErrorResponseJson?.type,
            message: kyErrorResponseJson?.message,
        }

        if (errorResponse) {
            for (const error of refreshStructureErrorMap) {
                if (errorResponse.status === error.status && errorResponse.type === error.type) {
                    return error
                }
            }
        }
    }

    return undefined
}
