import { deimosRequest, buildFetcher } from 'services/request'
import useSWR from 'swr'
import type { Company } from 'types/deimos-company'

const fetcher = buildFetcher(deimosRequest)

export function useGetCompany(companyId: string) {
    const { data: company, ...rest } = useSWR<Company>(
        companyId ? `rest/v1/companies/${companyId}` : null,
        fetcher
    )
    return { company, ...rest }
}
