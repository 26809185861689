import { Type } from '../../../types/card'
import { notification } from 'antd'
import { resetPinTries } from '../../../services/deimos/cards'
import { encryptQueryAndReturnMetabaseURL } from 'pages/customer-success/company-360/utils'
import type { EmployeeCards } from '../../index.bff'

export const handleResetPinTries = async (card: EmployeeCards) => {
    if (card.cardType === Type.VIRTUAL) {
        notification.error({
            message: `Cannot reset PIN tries on a virtual card.`,
        })
        return false
    }
    try {
        await resetPinTries(card.id)
        notification.info({
            message: `Card PIN tries successfully reset.`,
        })
    } catch (e) {
        notification.error({
            message: `Failed to reset card PIN tries.`,
            description: (e as Error).message,
        })
    }
}

export const notifyStatusChange = (
    type: 'status' | 'velocity',
    success: boolean,
    value: string
) => {
    if (type === 'status') {
        if (!success) {
            notification.error({
                message: `Failed to update card status.`,
                description: value,
            })
            return
        }
        notification.info({
            message: `Card velocity successfully updated to ${value}`,
        })
    }
    if (type === 'velocity') {
        if (!success) {
            notification.error({
                message: `Failed to update card velocity.`,
                description: value,
            })
            return
        }
        notification.info({
            message: `Card velocity successfully updated to ${value}`,
        })
    }
}

export const notifyCardAccess = (success: boolean, errorMessage?: string) => {
    if (success) {
        notification.info({
            message: 'Access granted!',
        })
        return
    }
    notification.error({
        message: 'Unable to grant access.',
        description: errorMessage,
    })
}

export const buildCardStatusMBLink = (cardId: string) => {
    const preformattedJson = {
        dataset_query: {
            database: 12,
            query: {
                'source-table': 3643,
                filter: ['and', ['=', ['field', 52638, null], `${cardId}`]],
            },
            type: 'query',
        },
        display: 'table',
        visualization_settings: {},
    }
    return encryptQueryAndReturnMetabaseURL(preformattedJson)
}

export const buildArielMBLink = (cardId: string) => {
    const preformattedJson = {
        dataset_query: {
            type: 'query',
            query: {
                'source-table': 3616,
                filter: ['and', ['=', ['field', 52313, null], `${cardId}`]],
            },
            database: 12,
        },
        display: 'table',
        visualization_settings: {},
    }
    return encryptQueryAndReturnMetabaseURL(preformattedJson)
}

export const buildDeimosTransactionsMBLink = (cardId: string) => {
    const preformattedJson = {
        dataset_query: {
            database: 8,
            type: 'query',
            query: {
                'source-table': 2804,
                'order-by': [
                    [
                        'desc',
                        [
                            'field',
                            42799,
                            {
                                'base-type': 'type/DateTimeWithLocalTZ',
                            },
                        ],
                    ],
                ],
                filter: [
                    '=',
                    [
                        'field',
                        42791,
                        {
                            'base-type': 'type/Text',
                        },
                    ],
                    cardId,
                ],
            },
        },
        display: 'table',
        visualization_settings: {},
    }
    return encryptQueryAndReturnMetabaseURL(preformattedJson)
}
