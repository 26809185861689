import { Tree } from 'antd'
import type { CompanyReviewStructureResponse } from '../types'
import { TeamOutlined, UserOutlined } from '@ant-design/icons'
import type { DataNode } from 'antd/es/tree'

const mapReviewStrucutreToTreeData = (
    reviewStructure?: CompanyReviewStructureResponse
): DataNode[] | undefined => {
    if (!reviewStructure) return undefined
    return (reviewStructure?.structure || []).map((company, companyIndex) => ({
        key: `company-${company.companyId || companyIndex}`,
        title: company.name,
        children: company.teams.map((team, teamIndex) => ({
            key: `company-${company.companyId || companyIndex}-team-${team.teamId || teamIndex}`,
            title: team.name,
            icon: <TeamOutlined />,
            children: team.employees.map((employee, employeeIndex) => ({
                key: `company-${company.companyId || companyIndex}-team-${
                    team.teamId || teamIndex
                }-employee-${employee.employeeId || employeeIndex}`,
                title: `${employee.firstName} ${employee.lastName}`,
                icon: <UserOutlined />,
                checkable: true,
            })),
        })),
    }))
}

export const ReviewStructureTree = ({
    reviewHistory,
}: {
    reviewHistory?: CompanyReviewStructureResponse
}) => {
    return (
        <Tree
            treeData={mapReviewStrucutreToTreeData(reviewHistory)}
            defaultExpandedKeys={
                reviewHistory?.structure &&
                reviewHistory.structure.map((company) => company.companyId)
            }
            showIcon
            selectable={false}
        />
    )
}
