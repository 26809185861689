import { presetPalettes, red, green, orange, PalettesProps } from '@ant-design/colors'

// copy of grey neutral pallet from https://ant.design/docs/spec/colors#Neutral-Color-Palette
// the generated pallet from @ant-design/colors doesn't match components 🤷‍♂️
// * https://github.com/ant-design/ant-design-colors/issues/39
const grey = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#e8e8e8',
    '#d9d9d9',
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#000000',
]

export const theme = {
    colors: {
        danger: red[4],
        warn: orange[4],
        success: green[4],
        secondary: grey[5],
    },
    colorPalette: {
        ...presetPalettes,
        grey,
    } as PalettesProps,
}

export type Theme = typeof theme
type Colors = keyof typeof theme.colors
// * https://ant.design/docs/spec/colors#Base-Color-Palettes
type PalletColors =
    | 'red'
    | 'volcano'
    | 'gold'
    | 'orange'
    | 'yellow'
    | 'lime'
    | 'green'
    | 'cyan'
    | 'blue'
    | 'geekblue'
    | 'purple'
    | 'magenta'
    | 'grey'
type PalletRange = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9

export const getColor = (name: Colors) => (props: { theme: Theme }) => props.theme.colors[name]
export const getColorFromPallet =
    (palletColor: PalletColors, index: PalletRange = 5) =>
    (
        props: {
            theme: Theme
        } = { theme }
    ) =>
        props.theme.colorPalette[palletColor][index]
