import { useParams } from 'react-router-dom'
import { creditBff } from '../bff'
import { Alert, Collapse, Skeleton, Space } from 'antd'
import type { Account } from 'bff/moons/generated/kalyke'
import AccountCardHeader from '../components/account-card-header'
import AccountDetails from '../components/account-deatils'

export default function CreditOverview() {
    const { id } = useParams()

    const { data, isLoading, isError } = creditBff.getOverview.useQuery({ companyId: id })

    if (isLoading) {
        return <Skeleton active />
    }

    if (isError) {
        return <Alert message="There was an error fetching Credit Overview." type="error" />
    }

    return (
        <Space direction="vertical">
            {data.accounts.data.map((account, index) => (
                <AccountItem account={account} key={account.id} isMostRecent={index === 0} />
            ))}
        </Space>
    )
}

interface AccountItemProps {
    account: Account
    isMostRecent?: boolean
}

function AccountItem({ account, isMostRecent = false }: AccountItemProps) {
    return (
        <Collapse defaultActiveKey={isMostRecent ? account.id : undefined}>
            <Collapse.Panel key={account.id} header={<AccountCardHeader account={account} />}>
                <AccountDetails account={account} isMostRecent={isMostRecent} />
            </Collapse.Panel>
        </Collapse>
    )
}
