import { PageContentLayout } from 'components/layout-containers'
import { AppListSection } from '../components/app-list'
import { CreateAppSection } from '../components/create-app-form'
import { useAppMarketplaceBackend } from '../hooks/use-app-marketplace-backend'
import { AppMarketplaceAPIContext, AppMarketplaceContext } from '../contexts/app-markeplace-context'

const AppMarketplace = () => {
    const { data, actions } = useAppMarketplaceBackend()

    return (
        <AppMarketplaceContext.Provider value={data}>
            <AppMarketplaceAPIContext.Provider value={actions}>
                <PageContentLayout>
                    <CreateAppSection />
                    <AppListSection />
                </PageContentLayout>
            </AppMarketplaceAPIContext.Provider>
        </AppMarketplaceContext.Provider>
    )
}

export default AppMarketplace
