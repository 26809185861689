import { useNavigate, useParams } from 'react-router-dom'
import { creditBff } from '../bff'
import { useCompanyBalance } from 'services/deimos/companies'
import { Alert, Button, Col, Descriptions, Row, Skeleton, Space, Typography } from 'antd'
import { ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons'
import { fontSize } from 'theme/tokens'
import styled from 'styled-components'
import formatCurrency from 'utils/format-currency'
import { getEmojiFlag } from 'countries-list'
import { formatDate } from 'packages/dates/dates'

const { Text } = Typography

export default function CreditHeader() {
    const navigate = useNavigate()
    const { id } = useParams()
    const { data, isError, isLoading } = creditBff.getHeader.useQuery({ companyId: id })
    // TODO  migrate this to BFF once it is possible to use. Follow this: WALLE-5020
    const { data: companyBalanceData, error: companyBalanceError } = useCompanyBalance(id)
    const isCompanyBalanceLoading = !companyBalanceData && !companyBalanceError

    if (isLoading || isCompanyBalanceLoading) {
        return <Skeleton active />
    }

    if (isError || companyBalanceError) {
        return <Alert message="There was an error fetching Credit data." type="error" />
    }

    const handleBack = () => {
        navigate(-1)
    }

    const { company, overdraftEligibility, reserveEligibility, currentActiveAccount, fddDate } =
        data

    const emojiFlag = getEmojiFlag(company?.address?.country || 'GB')

    const currentActiveProduct = currentActiveAccount && currentActiveAccount.accountType
    const currentActiveLimit =
        currentActiveAccount &&
        formatCurrency(currentActiveAccount.principalAmount, currentActiveAccount.currency)

    return (
        <HeaderContainer>
            <Row justify="start" align="middle">
                <Col>
                    <Button type="link" onClick={handleBack} icon={<ArrowLeftOutlined />}></Button>
                </Col>
                <Col>
                    <Space>
                        {emojiFlag}
                        <Text
                            copyable={{ icon: [<CopyIcon key="copy-icon" />] }}
                            style={{ whiteSpace: 'normal' }}
                        >
                            {company?.legalName}
                        </Text>
                    </Space>
                </Col>
            </Row>
            <Descriptions column={3} bordered>
                <Descriptions.Item label="Company ID" span={2}>
                    <Text copyable>{company?.id}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="FDD date">{formatDate(fddDate)}</Descriptions.Item>
                <Descriptions.Item label="Current balance">
                    {companyBalanceData?.balance.current.value &&
                        formatCurrency(
                            companyBalanceData.balance.current.value,
                            companyBalanceData.balance.current.currency
                        )}
                </Descriptions.Item>
                <Descriptions.Item label="Overdraft Eligible limit">
                    {overdraftEligibility?.eligibility.eligible && overdraftEligibility.terms
                        ? formatCurrency(
                              overdraftEligibility.terms?.limit,
                              overdraftEligibility.terms?.currency
                          )
                        : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Reserve Eligible limit">
                    {reserveEligibility?.eligibility === 'ELIGIBLE' && reserveEligibility.terms
                        ? formatCurrency(
                              reserveEligibility.terms.reserveLimit,
                              reserveEligibility.terms.currency
                          )
                        : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Available balance">
                    {companyBalanceData?.balance.available.value &&
                        formatCurrency(
                            companyBalanceData.balance.available.value,
                            companyBalanceData.balance.available.currency
                        )}
                </Descriptions.Item>
                <Descriptions.Item label="Current active product">
                    {currentActiveProduct || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Current active limit">
                    {currentActiveLimit || '-'}
                </Descriptions.Item>
            </Descriptions>
        </HeaderContainer>
    )
}

const CopyIcon = styled(CopyOutlined)`
    font-size: ${fontSize.size14};
    display: flex;
    justify-content: 'center';
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`
